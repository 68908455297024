import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {Observable} from 'rxjs';
import {NotificationsSettings} from './notifications-settings';

/**
 * Service to manage the app notifications.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationsSettingsService {
  constructor(private http: HttpClient,
              private authService: AuthService) {
  }

  /**
   * Gets the user notifications settings.
   * @returns an Observable with the user notifications settings
   */
  getNotificationsSettings(): Observable<NotificationsSettings[]> {
    const userId = this.authService.getCurrentUserId();
    return this.http.get<NotificationsSettings[]>(`/web/users/${userId}/notification-settings`);
  }

  /**
   * Updates the notification settings when the user changes it.
   * @param setting the settings to be updated
   * @returns an empty Observable
   */
  updateNotificationSettings(setting: NotificationsSettings): Observable<void> {
    return this.http.put<void>(`/web/users/${setting.userId}/notification-settings`, setting);
  }
}

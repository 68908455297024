<ul *ngIf="categories.length">
  <li *ngFor="let category of categories">
    <button mat-button type="button" size="small" class="block-left"
            [color]="category.id === active ? 'accent' : null"
            *ngIf="category.links.length || category._permissions?.manage"
            (click)="navigate.emit(category)">
      <mat-icon svgIcon="folder" size="small"></mat-icon>
      <span>{{category.id != 'personal' ? category.name : ('LAUNCHPAD.CATEGORY.PERSONAL' | translate)}}</span>
    </button>
  </li>
</ul>

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {CalloutWidget} from '@widgets/callout/callout-widget';
import * as _ from 'lodash';

/**
 * The callout widget's settings component.
 *
 * This component is used only in the context of the widget settings modal dialog.
 */
@Component({
  templateUrl: './callout-widget-settings.component.html',
  styleUrls: ['./callout-widget-settings.component.scss']
})
export class CalloutWidgetSettingsComponent extends WidgetSettingsComponent<CalloutWidget> implements OnInit {

  styles: {
    name: string,
    clazz: string,
    value: string
  }[] = [
    {name: 'WIDGET.CALLOUT.SETTINGS.STYLE.SUCCESS', clazz: 'btn-success', value: 'alert-success'},
    {name: 'WIDGET.CALLOUT.SETTINGS.STYLE.INFO', clazz: 'btn-info', value: 'alert-info'},
    {name: 'WIDGET.CALLOUT.SETTINGS.STYLE.WARNING', clazz: 'btn-warning', value: 'alert-warning'},
    {name: 'WIDGET.CALLOUT.SETTINGS.STYLE.DANGER', clazz: 'btn-danger', value: 'alert-danger'}
  ];

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    const style = _.get(this.widget.settings, '_callout.alertClass', this.styles[0].value);

    // set initial form controls
    this.parentForm.addControl('md_text', new FormControl(this.widget.settings.md_text,
      [Validators.required, Validators.maxLength(255)]));
    this.parentForm.addControl('_callout', this.formBuilder.group({
      alertClass: this.formBuilder.control(style)
    }));
  }
}

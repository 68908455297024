import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Sender} from '@domain/sender/sender';
import {TimelineItem} from '@domain/timeline-item/timeline-item';

@Component({
  selector: 'coyo-timeline-item-header',
  templateUrl: './timeline-item-header.component.html',
  styleUrls: ['./timeline-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemHeaderComponent {

  /**
   * The timeline item.
   */
  @Input() item: TimelineItem;
  /**
   * The original author of the item.
   */
  @Input() originalAuthor: Sender;

  /**
   * Indicates if item has shares.
   */
  @Input() hasShares: boolean;

  /**
   * Indicates if item is public share.
   */
  @Input() publicShare: boolean;

  /**
   * Indicates if item recipient of the item is not author.
   */
  @Input() recipientNotAuthor: boolean;

}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {AnimationItem} from 'lottie-web/build/player/lottie_light';

export const LOTTIE = new InjectionToken<any>('Lottie');

@Component({
  selector: 'coyo-file-preview-animation',
  templateUrl: './file-preview-animation.component.html',
  styleUrls: ['./file-preview-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePreviewAnimationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('lottieContainer') lottieContainer: ElementRef;

  private animation: AnimationItem;
  private reverse: boolean = false;

  constructor(@Inject(LOTTIE) private Lottie: any) {
  }

  ngAfterViewInit(): void {
    this.animation = this.Lottie.loadAnimation({
      container: this.lottieContainer.nativeElement,
      path: '/assets/animations/file_preview_generating_animation.json',
      autoplay: false,
      loop: false
    });
    this.animation.goToAndPlay(23, true);
    this.animation.addEventListener('complete', () => this.reverseAnimation());
  }

  ngOnDestroy(): void {
    this.stopAnimation();
  }

  private reverseAnimation(): void {
    this.reverse = !this.reverse;
    this.animation.setDirection(this.reverse ? -1 : 1);
    this.animation.play();
  }

  private stopAnimation(): void {
    this.animation.stop();
  }

}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {AppService} from '@domain/apps/app.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {LatestFilesWidget} from '@widgets/latest-files/latest-files-widget';
import {LatestFilesWidgetSettings} from '@widgets/latest-files/latest-files-widget-settings';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';

/**
 * The latest-files widget settings component.
 */
@Component({
  selector: 'coyo-latest-files-widget-settings',
  templateUrl: './latest-files-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestFilesWidgetSettingsComponent extends WidgetSettingsComponent<LatestFilesWidget>
  implements OnInit {

  constructor(private changeDetectorRef: ChangeDetectorRef, private appService: AppService) {
    super();
  }

  ngOnInit(): void {
    const selectedApp = _.get(this.widget.settings, '_app', null);
    const fileCount = _.get(this.widget.settings, '_fileCount', 5);
    this.parentForm.addControl('app', new FormControl(null,
      [Validators.required]));
    this.parentForm.addControl('fileCount', new FormControl(fileCount,
      [Validators.required, Validators.min(1), Validators.max(20), CoyoValidators.naturalNumber]));
    this.changeDetectorRef.detectChanges();
    if (selectedApp) {
      this.appService.get(selectedApp.id, {context: {senderId: selectedApp.senderId}}).subscribe(result =>
        this.parentForm.patchValue({app: result}));
    }
  }

  onBeforeSave(settings ?: any): Observable<LatestFilesWidgetSettings> {
    return of({
      _app: {id: settings['app'].id, senderId: settings['app'].senderId},
      _fileCount: settings['fileCount']
    });
  }
}

<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>{{'PREVIEW.LINK.EXTERNAL.MODAL.WARNING1' | translate}}
  <a href="{{data.url}}" target="_blank" rel="noopener">{{data.title}}</a>
  {{'PREVIEW.LINK.EXTERNAL.MODAL.WARNING2' | translate}}
</div>
<div mat-dialog-actions>
  <a mat-flat-button type="button" [attr.href]="data.url" target="_blank" rel="noopener" (click)="close()">{{'YES' | translate}}</a>
  <button (click)="close()" mat-flat-button>{{'NO' | translate }}</button>
</div>

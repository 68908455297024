(function (angular) {
  'use strict';

  BlogArticleModel.$inject = ["appResourceFactory"];
  angular
      .module('coyo.apps.blog')
      .factory('BlogArticleModel', BlogArticleModel);

  /**
   * @ngdoc service
   * @name coyo.domain.BlogArticleModel
   *
   * @description
   * Domain model representation of the blog article configuration endpoint. Creates a new BlogArticleModel object.
   *
   * @requires coyo.apps.api.appResourceFactory
   * @requires restSerializer
   */
  function BlogArticleModel(appResourceFactory) {
    var BlogArticle = appResourceFactory({
      appKey: 'blog',
      url: '/articles/{{id}}',
      name: 'blogArticle'
    });

    // class members
    angular.extend(BlogArticle, {

      /**
       * @ngdoc function
       * @name coyo.domain.BlogArticleModel#count
       * @methodOf coyo.domain.BlogArticleModel
       *
       * @description
       * Counts all blog articles for the given app grouped by month and year.
       *
       * @returns {promise} An $http promise
       */
      count: function (app, includePublished, includeScheduled, includeDrafts) {
        return this.$get(this.$url({
          senderId: app.senderId,
          appId: app.id
        }, 'count'), {
          includePublished: includePublished,
          includeScheduled: includeScheduled,
          includeDrafts: includeDrafts
        });
      },

      canPublishAsSender: function (app) {
        return this.$get(this.$url({
          senderId: app.senderId,
          appId: app.id
        }, 'publishAsSender'));
      }
    });

    // instance members
    angular.extend(BlogArticle.prototype, {

      /**
       * @ngdoc function
       * @name coyo.domain.BlogArticleModel#buildLayoutName
       * @methodOf coyo.domain.BlogArticleModel
       *
       * @description
       * Build the localised layout name of a blog article.
       *
       * @returns {string} The layout name
       */
      buildLayoutName: function (appId) {
        return 'app-blog-' + appId + '-' + this.id;
      },

      getContext: function (params) {
        return this.$http({
          method: 'GET',
          url: this.$url() + '/context',
          data: this,
          params: params
        });
      }

    });

    return BlogArticle;
  }

})(angular);

import {FileUploadEvent} from './file-upload-event';

/**
 * Event that is emitted when a FileUpload receives a HTTP progress event
 */
export class FileUploadProgressEvent extends FileUploadEvent {
  constructor(files: FileList | File[], readonly fileId: string, readonly fileIndex: number, readonly progress: number, readonly totalProgress: number) {
    super(files);
  }
}

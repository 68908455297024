<div class="panel panel-default external-search-results">
  <div class="panel-heading">
    <h4 class="search-results-headline"
        [class.pointer]="externalSearchResults.searchResultCountString != '0'"
        (click)="toggleResultsList()">
      <mat-icon [svgIcon]="externalSearchIcon" size="large"></mat-icon>
      <strong>{{('MODULE.SEARCH.EXTERNAL.PROVIDER.' + externalSearchResults.searchProvider) | translate}}</strong>
      <span *ngIf="!showResults && externalSearchResults.searchResultCountString != '0'" class="summary">
        &nbsp;{{"MODULE.SEARCH.EXTERNAL.HEADLINE.RESULTS.SUMMARY" | translate: {count: externalSearchResults.searchResultCountString, term: searchQuery} }}
      </span>
    </h4>
    <a [href]="externalSearchURL$ | async" target="_blank">
      {{"MODULE.SEARCH.EXTERNAL.HEADLINE.RESULTS" | translate: {count: externalSearchResults.searchResultCountString} }}
      <mat-icon svgIcon="chevron-right"></mat-icon>
    </a>
  </div>
  <div class="panel-body"
       *ngIf="showResults && externalSearchResults.searchResults && externalSearchResults.searchResults.length">
    <div class="external-search-results-list">
      <div *ngFor="let result of externalSearchResults.searchResults" class="external-search-result-wrapper">
        <coyo-search-result-external [searchResult]="result"
                                     [searchProvider]="externalSearchResults.searchProvider"></coyo-search-result-external>
      </div>
    </div>
  </div>
</div>

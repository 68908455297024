(function (angular) {
  'use strict';

  AdminMessagingSettingsController.$inject = ["$injector", "SettingsModel", "settings"];
  angular.module('coyo.admin.settings')
      .controller('AdminMessagingSettingsController', AdminMessagingSettingsController);

  function AdminMessagingSettingsController($injector, SettingsModel, settings) {
    var vm = this;
    vm.$onInit = onInit;
    vm.save = save;
    vm.generateAdminGeneralSettingsObject = generateAdminGeneralSettingsObject;
    vm.generateAdminGeneralSettingsObjectHelper = generateAdminGeneralSettingsObjectHelper;
    vm.onDeleteChannelsPeriodChange = onDeleteChannelsPeriodChange;
    vm.onDeleteMessagesPeriodChange = onDeleteMessagesPeriodChange;
    vm.transferObject = {};

    function onInit() {
      // Load the current settings from db
      return settings.get().then(function (currentSettingsFromBackEnd) {
        settings = currentSettingsFromBackEnd;
        vm.generateAdminGeneralSettingsObject(currentSettingsFromBackEnd);

        _initValues(vm.transferObject);
        _parsePeriods(settings);
      });
    }

    function save() {
      return vm.transferObject.update().then(function () {
        _parsePeriods(vm.transferObject);
        SettingsModel.retrieve(true); // reset settings cache
        $injector.get('ngxNotificationService').success('ADMIN.SETTINGS.SAVE.SUCCESS');
      });
    }

    function _parsePeriods(settings) {
      if (settings.deleteChannelsPeriod) {
        vm.transferObject.deleteChannelsPeriod = parseInt(settings.deleteChannelsPeriod);
      }
      if (settings.deleteMessagesPeriod) {
        vm.transferObject.deleteMessagesPeriod = parseInt(settings.deleteMessagesPeriod);
      }
    }

    function onDeleteChannelsPeriodChange() {
      var period = parseInt(vm.transferObject.deleteChannelsPeriod, 10);
      if (period) {
        vm.transferObject.deleteChannelsPeriod = Math.abs(period);
      } else {
        _.unset(vm.transferObject, 'deleteChannelsPeriod');
      }
    }

    function onDeleteMessagesPeriodChange() {
      var period = parseInt(vm.transferObject.deleteMessagesPeriod, 10);
      if (period) {
        vm.transferObject.deleteMessagesPeriod = Math.abs(period);
      } else {
        _.unset(vm.transferObject, 'deleteMessagesPeriod');
      }
    }

    function _initValues(settings) {
      if (_.isUndefined(settings.deleteChannelsPeriod)) {
        _.set(vm.transferObject, 'deleteChannelsPeriod', 24);
      }
      if (_.isUndefined(settings.deleteMessagesPeriod)) {
        _.set(vm.transferObject, 'deleteMessagesPeriod', 72);
      }
      if (_.isUndefined(settings.chatCron)) {
        vm.transferObject.chatCron = '0 0 * * * *'; // Once every hour
      }
    }

    /**
     * This method creates a deep copy of settings, but only with the keys that this component really has.
     * The new created copy will be stored in the variable transferObject.
     * Now we can communicate with the backend server via the transferObject variable, and only transfer the data that we need.
     *
     * @param {settings} - settings object, over this object we have a communication to the back-end-server
     */
    function generateAdminGeneralSettingsObject(settings) {
      vm.transferObject = vm.generateAdminGeneralSettingsObjectHelper(_.cloneDeep(settings));
    }

    /**
     * Helper function for generateAdminGeneralSettingsObject.
     * In first step we clear the deep copy variable.
     * In second step we fill the new copy with the necessary keys
     *
     * @param {settingsDeepCopy} - the deep copy object from settings
     * @returns {*}
     */
    function generateAdminGeneralSettingsObjectHelper(settingsDeepCopy) {
      _(settingsDeepCopy).keys().forEach(function (key) {
        delete settingsDeepCopy[key];
      });

      settingsDeepCopy.chatCron = settings.chatCron;
      settingsDeepCopy.deleteMessagesPeriod = settings.deleteMessagesPeriod;
      settingsDeepCopy.deleteMessagesActive = settings.deleteMessagesActive;
      settingsDeepCopy.deleteChannelsPeriod = settings.deleteChannelsPeriod;
      settingsDeepCopy.deleteChannelsActive = settings.deleteChannelsActive;
      return settingsDeepCopy;
    }

  }

})(angular);

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {LocalStorageService} from '@core/storage/local-storage/local-storage.service';
import {Store} from '@ngxs/store';
import {WidgetEditService} from '@widgets/api/widget-edit/widget-edit.service';
import {WidgetState} from '@widgets/api/widget.state';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, pluck} from 'rxjs/operators';

/**
 * The WidgetLayoutBarComponent displays a bar at the bottom of the screen when the global
 * edit mode is enabled. The bar contains buttons to save or discard changes to the layout as well
 * as a help-button.
 */
@Component({
  selector: 'coyo-widget-layout-save-bar',
  templateUrl: './widget-layout-save-bar.component.html',
  styleUrls: ['./widget-layout-save-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetLayoutSaveBarComponent implements OnInit {
  globalEditMode: Observable<boolean>;

  constructor(
    private store: Store,
    private localStorageService: LocalStorageService,
    private widgetEditService: WidgetEditService) {
  }

  /**
   * Returns the value of messagingSidebar.compact in the local storage
   */
  get compact(): boolean {
    return this.localStorageService.getValue('messagingSidebar.compact', false);
  }

  ngOnInit(): void {
    this.globalEditMode = this.store.select(WidgetState)
      .pipe(
        pluck('editScopes'),
        pluck('global'),
        map(editScope => editScope ? editScope.editMode : false),
        distinctUntilChanged()
      );
  }

  /**
   * Saves the current state of the global widget layout
   */
  save(): void {
    this.widgetEditService.save();
  }

  /**
   * Discards any changes made to the global widget layout
   */
  cancel(): void {
    this.widgetEditService.cancelEdit();
  }
}

import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Pageable} from '@domain/pagination/pageable';
import {SubscriptionService} from '@domain/subscription/subscription.service';
import {User} from '@domain/user/user';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

export class SubscriberInfoDataSource extends DataSource<User | null> {
  private static readonly VIEW_DEBOUNCE_TIME: number = 250;
  private static readonly PAGE_SIZE: number = 50;

  private dataStream: BehaviorSubject<(User | null)[]> = new BehaviorSubject<(User | null)[]>([]);
  private requests: Map<number, Subscription> = new Map<number, Subscription>();
  private viewSubscription: Subscription;
  private termSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private termSubscription: Subscription;

  readonly subscriberCount: number;

  constructor(private subscriptionService: SubscriptionService,
              private targetId: string,
              subscriberCount: number) {
    super();
    this.subscriberCount = subscriberCount;
  }

  connect(collectionViewer: CollectionViewer): Observable<(User | null)[]> {
    this.viewSubscription = collectionViewer.viewChange
      .pipe(debounceTime(SubscriberInfoDataSource.VIEW_DEBOUNCE_TIME))
      .subscribe(range => {
        const pageStart = Math.floor(range.start / SubscriberInfoDataSource.PAGE_SIZE);
        const pageEnd = Math.floor(range.end / SubscriberInfoDataSource.PAGE_SIZE);
        for (let i = pageStart; i <= pageEnd; i++) {
          this.loadPage(i);
        }
      });

    this.termSubscription = this.termSubject
      .subscribe(term => {
        this.clearRequests();
        this.loadPage(0, term ? this.dataStream.getValue().length : this.subscriberCount);
      });

    return this.dataStream.asObservable();
  }

  disconnect(): void {
    this.clearRequests();
    this.viewSubscription.unsubscribe();
    this.termSubscription.unsubscribe();
  }

  setTerm(term: string): void {
    this.termSubject.next(term);
  }

  private loadPage(pageNumber: number, reset: number = -1): void {
    if (this.requests.has(pageNumber)) {
      return;
    }

    if (reset >= 0) {
      this.dataStream.next(this.assertLength([], reset));
    }

    const term = this.termSubject.getValue();
    const pageable = new Pageable(pageNumber, SubscriberInfoDataSource.PAGE_SIZE);
    const pageStart = pageNumber * SubscriberInfoDataSource.PAGE_SIZE;
    const request = this.subscriptionService.getSubscribersByTargetId(this.targetId, pageable, term)
      .subscribe(page => {
        const data = this.assertLength(this.dataStream.getValue(), page.totalElements);
        data.splice(pageStart, page.content.length, ...page.content);
        this.dataStream.next(data);
      }, () => this.requests.delete(pageNumber));
    this.requests.set(pageNumber, request);
  }

  private assertLength(data: User[], length: number): User[] {
    if (length > data.length) {
      return _.concat(data, Array(length - data.length));
    } else if (length < data.length) {
      return data.slice(0, length);
    }
    return [...data];
  }

  private clearRequests(): void {
    this.requests.forEach(subscription => subscription.unsubscribe());
    this.requests.clear();
  }
}

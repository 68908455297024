import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BaseModel} from '@domain/base-model/base-model';
import {Likeable} from '@domain/like/likeable';

/**
 * A button to like an entity.
 */
@Component({
  selector: 'coyo-like-container',
  templateUrl: './like-container.component.html',
  styleUrls: ['./like-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikeContainerComponent {

  /**
   * The subscription author.
   */
  @Input() author: BaseModel;

  /**
   * The subscription target.
   */
  @Input() target: Likeable;

  /**
   * Flag to render information as text (no icon)
   */
  @Input() textual: boolean;

  /**
   * Flag to show condensed information only
   */
  @Input() condensed: boolean;

  /**
   * Flag if the initial like request should be skipped
   */
  @Input() skipInitRequest: boolean = false;

  constructor() {
  }
}

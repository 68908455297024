import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {ExtractUrlsDirective} from '@shared/forms/extract-urls/extract-urls.directive';
import {FileUploadModule} from 'ng2-file-upload';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {CoyoCommonsModule} from '../commons/commons.module';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {DatePickerComponent} from './date-picker/date-picker.component';
import './date-picker/date-picker.component.downgrade';
import {DurationPickerComponent} from './duration-picker/duration-picker.component';
import './duration-picker/duration-picker.component.downgrade';
import {FieldErrorsMessageComponent} from './field-errors-message/field-errors-message.component';
import {FieldErrorsDirective} from './field-errors/field-errors.directive';
import {SaveIndicatorComponent} from './save-indicator/save-indicator.component';
import {TimePickerComponent} from './time-picker/time-picker.component';
import './time-picker/time-picker.component.downgrade';
import {ValidationErrorsComponent} from './validation-errors/validation-errors.component';

/**
 * Module exporting components that are needed for forms
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    FileUploadModule,
    FormsModule,
    NgSelectModule,
    PopoverModule,
    ReactiveFormsModule,
    TimepickerModule,
    TranslateModule
  ],
  declarations: [
    CheckboxComponent,
    DatePickerComponent,
    DurationPickerComponent,
    ExtractUrlsDirective,
    FieldErrorsDirective,
    FieldErrorsMessageComponent,
    SaveIndicatorComponent,
    TimePickerComponent,
    ValidationErrorsComponent
  ],
  exports: [
    CheckboxComponent,
    DatePickerComponent,
    DurationPickerComponent,
    ExtractUrlsDirective,
    FieldErrorsDirective,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    SaveIndicatorComponent,
    TimePickerComponent,
    ValidationErrorsComponent
  ]
})
export class CoyoFormsModule {
}

import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {LOCAL_FILE_LIBRARY, StorageType} from '@domain/attachment/storage';

/**
 * Root file library file picker item for a user showing 'My files', 'Landing pages', 'Pages' and 'Workspaces'
 * as children.
 */
export class FileLibraryRootFilePickerItem implements FilePickerItem {
  readonly id: string = 'FILE_LIBRARY.ROOT.NAME';
  readonly isFolder: boolean = true;
  readonly lastModified: number;
  readonly mimeType: string | null;
  readonly name: string = 'FILE_LIBRARY.ROOT.NAME';
  readonly sizeInBytes: number | null;
  readonly storageType: StorageType = LOCAL_FILE_LIBRARY;
  readonly hideHeader: boolean = true;
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {UrlService} from '@core/http/url/url.service';
import {SocketService} from '@core/socket/socket.service';
import {LocalStorageService} from '@core/storage/local-storage/local-storage.service';
import {DomainService} from '@domain/domain/domain.service';
import {SettingsService} from '@domain/settings/settings.service';
import * as _ from 'lodash';
import {concat, Observable, of, Subject} from 'rxjs';
import {bufferTime, filter, first, map, mergeMap, pluck, share, tap} from 'rxjs/operators';
import {PresenceStatus} from './presence-status';
import {User} from './user';

/**
 * Service to retrieve and manage users.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService extends DomainService<User, User> {
  readonly requestPresenceStatusSubject: Subject<User> = new Subject<User>();
  readonly bulkedPresenceStatusRequest: Observable<{ [id: string]: PresenceStatus }> = this.requestPresenceStatusSubject
    .pipe(bufferTime(150), filter(users => users && users.length > 0))
    .pipe(map(users => _.uniqBy(users, 'id')))
    .pipe(mergeMap(users => this.http.get<{ [id: string]: PresenceStatus }>('/web/users/presence-status', {
      params: {
        userIds: users.map(usr => usr.id).join(',')
      }
    })), share());

  constructor(@Inject(HttpClient) protected http: HttpClient,
              @Inject(UrlService) protected urlService: UrlService,
              private socketService: SocketService,
              private authService: AuthService,
              private settingsService: SettingsService,
              private localStorageService: LocalStorageService) {
    super(http, urlService);
  }

  /**
   * Updates the first name of the current user.
   *
   * @param firstName the user's first name
   * @return an observable holding the updated user
   */
  setFirstName(firstName: string): Observable<User> {
    return this.setUserName({firstName});
  }

  /**
   * Updates the last name of the current user.
   *
   * @param lastName the user's last name
   * @return an observable holding the updated user
   */
  setLastName(lastName: string): Observable<User> {
    return this.setUserName({lastName});
  }

  /**
   * Updates the email address of the current user.
   *
   * @param email the user's email address
   * @return an empty observable
   */
  setEmail(email: string): Observable<void> {
    return this.http.post<void>(this.buildUrl({
      path: '/{id}/email',
      context: {id: this.authService.getCurrentUserId()}
    }), {email});
  }

  /**
   * Changes the password of the current user.
   *
   * @param oldPassword The old password
   * @param newPassword The new password
   * @param handleError Flag if errors should be handled by the default error handler. Default true.
   *
   * @returns The request observable
   */
  setPassword(oldPassword: string, newPassword: string, handleError: boolean = true): Observable<void> {
    const options = handleError ? {} : {
      headers: {
        handleErrors: 'false'
      }
    };
    return this.http.post<void>(this.buildUrl({
      path: '/{id}/password',
      context: {id: this.authService.getCurrentUserId()}
    }), {oldPassword, newPassword}, options);
  }

  /**
   * Updates the timezone of the current user.
   *
   * @param timezone the user's timezone
   * @return an empty observable
   */
  setTimezone(timezone: string): Observable<void> {
    return this.http.put<void>(this.buildUrl({
      path: '/{id}/timezone',
      context: {id: this.authService.getCurrentUserId()}
    }), {timezone});
  }

  /**
   * Updates the visited tour topics for the current user.
   *
   * @param topics the list of topics
   * @return an empty observable
   */
  setVisitedTourTopics(...topics: string[]): Observable<void> {
    return this.http.put<void>(this.buildUrl({
      path: '/{id}/tour',
      context: {id: this.authService.getCurrentUserId()}
    }), {visited: topics});
  }

  /**
   * Updates the language of the current user.
   *
   * @param language the language to be set
   * @return an empty observable
   */
  setLanguage(language: string): Observable<void> {
    return this.http.put<void>(this.buildUrl({
      path: '{id}/language',
      context: {id: this.authService.getCurrentUserId()}
    }), {language}).pipe(tap(() => {
      this.localStorageService.setValue('userLanguage', language);
    }));
  }

  /**
   * Retrieves the current user online count.
   *
   * @return the current user online count
   */
  getUserOnlineCount(): Observable<number> {
    return this.http.get<{ count: number }>(this.buildUrl({
      path: '/online/count'
    })).pipe(map(result => Math.max(1, result.count)));
  }

  /**
   * Retrieves the presence status of a user. Optionally, you may provide a $scope, which will result in an ongoing
   * subscription to changes of the presence status and additional calls to the provided callback method. We need
   * a $scope so that we can terminate the subscription when the $scope is destroyed.
   * Internally, the requests are collected for a digest cycle and then a bulk request is sent to the backend.
   *
   * @param user The user to get the presence status for
   * @return an observable
   */
  getPresenceStatus$(user: User): Observable<PresenceStatus> {
    const destination = '/topic/user.presenceStatusChanged';
    setTimeout(() => {
      // emit user after returning the observable so we can subscribe before the value is emitted
      this.requestPresenceStatusSubject.next(user);
    });

    return this.bulkedPresenceStatusRequest
      .pipe(
        filter(result => result[user.id] !== undefined),
        pluck(user.id),
        first(),
        mergeMap((status: PresenceStatus) =>
          concat(of(status), this.getSocketSubscription$(destination, user, status))
        )
      );
  }

  /**
   * Tries to select the best language for the current user from the given list
   *
   * @param user The user to select a language for
   * @param languages A list of languages
   * @return The best language to select for the user from the given list or null if none seems suitable
   */
  getBestSuitableLanguage(user: User, languages: string[]): Observable<string | null> {
    if (languages.indexOf(user.language) >= 0) {
      return of(user.language);
    }
    return this.settingsService.retrieveByKey('defaultLanguage').pipe(map(defaultLanguage => {
      if (languages.indexOf(defaultLanguage) >= 0) {
        return defaultLanguage;
      }
      return null;
    }));
  }

  protected getBaseUrl(): string {
    return '/web/users';
  }

  private getSocketSubscription$(destination: string, user: User, initialStatus: PresenceStatus): Observable<PresenceStatus> {
    return this.socketService.listenTo$(destination, null, user.id, initialStatus.subscriptionInfo.token)
      .pipe(map(event => event.content as PresenceStatus));
  }

  private setUserName(body: { firstName: string } | { lastName: string }): Observable<User> {
    return this.http.put<User>(this.buildUrl({
      path: '/{id}/name',
      context: {id: this.authService.getCurrentUserId()}
    }), body);
  }
}

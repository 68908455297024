import {Pipe, PipeTransform} from '@angular/core';
import {Category} from '@app/filter/category-filter/category';

@Pipe({
  name: 'isEdited'
})
export class IsEditedPipe implements PipeTransform {

  /**
   * Returns true if the given editedCategory is in edit mode, else false.
   *
   * @param editedCategory A copy of the edited category
   * @param editAtIndex    If the index is > -1 an entry with this index is in a edit mode
   * @param category       The original category to compare
   *
   * @returns a boolean that indicates whether the current category is in edit mode or not
   */
  transform(editedCategory: Category, editAtIndex: number, category: Category): boolean {
    return editAtIndex >= 0 && (!category || category.id === editedCategory.id);
  }

}

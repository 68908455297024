import {NgModule} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import './integration-api/integration-api.service.downgrade';

/**
 * Module for providing the functionality around the integration systems.
 */
@NgModule({
    providers: [
        {provide: JwtHelperService, useValue: new JwtHelperService()}
    ]
})
export class IntegrationModule {}

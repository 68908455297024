import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {POLL_WIDGET} from '@widgets/poll/poll-widget-config';
import {PollWidgetsState} from '@widgets/poll/poll-widget.state';
import {VotersModalState} from '@widgets/poll/voters-modal/voters-modal.state';
import {PollWidgetSettingsComponent} from './poll-widget-settings/poll-widget-settings.component';
import {PollWidgetComponent} from './poll-widget/poll-widget.component';
import {VotersModalComponent} from './voters-modal/voters-modal.component';

@NgModule({
  imports: [
    NgxsModule.forFeature([PollWidgetsState, VotersModalState]),
    CoyoCommonsModule,
    CoyoFormsModule,
    HelpModule,
    SenderUIModule
  ],
  declarations: [
    PollWidgetComponent,
    PollWidgetSettingsComponent,
    VotersModalComponent
  ],
  providers: [{
    provide: WIDGET_CONFIGS,
    useValue: POLL_WIDGET,
    multi: true
  }]
})
export class PollWidgetModule {
}

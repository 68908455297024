<!--suppress ALL -->
<div class="single-blog-article-widget">
  <ng-container *ngIf="article$ | async; let article; else loading">
    <a [href]="getLink(article.articleTarget)">
      <coyo-image-reference class="article-teaser-image"
                            *ngIf="article.teaserImageWideFileId"
                            [fileId]="article.teaserImageWideFileId"
                            [senderId]="article.teaserImageWideSenderId"
                            size-definitions="{'default': 'XL', 'screen-sm': 'XXL'}">
      </coyo-image-reference>
    </a>
    <div>
      <div class="article-meta">
        <a class="article-sender"
           [href]="getLink(article.senderTarget)">{{article.senderName}}</a>
        <span class="article-time"
              *ngIf="article.published">{{article.published | date}}</span>
      </div>
      <h3 class="article-title pointer">
        <a [href]="getLink(article.articleTarget)">
          {{article.title}}
        </a>
      </h3>

      <p class="teaser-text">
        {{article.teaserText}}
      </p>
      <div class="small text-muted">
        <a class="teaser-more" [href]="getLink(article.articleTarget)" [translate]="'WIDGETS.BLOGARTICLE.READMORE'"></a>
        <span [translate]="'WIDGETS.BLOGARTICLE.IN'"></span>
        <a class="blog-widget-info-sender" [href]="getLink(article.appTarget)">{{article.appDisplayname}}</a>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
  </ng-template>
</div>

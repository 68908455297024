(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name commons.optimistic
   *
   * @description
   * A module containing services and classes to easily work with optimistic ui operations.
   *
   * What's an optimistic ui?
   * We consider a certain operation will succeed without any error. Thus we don't need to wait for the server
   * to tell us about the success and let the user wait for this. We can instead print the expected result directly
   * (and revoke it if an unexpected error occurred).
   *
   * Example:
   * Sending a chat message non-optimistically would mean to send the message to the server, wait for it's response and
   * than add the message to the message channel.
   *
   * Sending a chat message optimistically would mean to first add the message to the channel, than sending the
   * message to the server and wait for the response in the background. If an error occurs, we need to handle that and
   * revoke the printed result in some way or offer an alternative (retry sending the message or the like).
   */
  angular
      .module('commons.optimistic', []);

})(angular);

<div class="search-result-external">
  <a href="{{searchResult.webViewLink}}" class="search-result external" target="_blank"
     [attr.aria-label]="searchResult.name">
    <coyo-file-icon-by-mime-type
      *ngIf="searchProvider === 'GOOGLE'" [mimeType]="searchResult.mimeType" [storage]="'G_SUITE'" [size]="'large'"
      class="result-icon">
    </coyo-file-icon-by-mime-type>
    <coyo-file-icon-by-mime-type
      *ngIf="searchProvider === 'MICROSOFT'" [mimeType]="searchResult.mimeType" [storage]="'OFFICE_365'"
      class="result-icon">
    </coyo-file-icon-by-mime-type>
    <span class="result-text" title="{{searchResult.name}}">
      <span class="first-part">{{searchResult.name | slice: 0:-7}}</span>
      <span class="last-part">{{searchResult.name | slice: -7}}</span>
      <span class="full-name">{{searchResult.name}}</span>
    </span>
    <span class="size-text" *ngIf="isFolder();  else elseBlock">
      {{searchResult.size | fileSize}}</span>
    <ng-template #elseBlock><span class="size-text">- -</span></ng-template>
  </a>
</div>

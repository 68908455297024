import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {BirthdayWidget} from '@widgets/birthday/birthday-widget';
import {BirthdayWidgetSettingsComponent} from '@widgets/birthday/birthday-widget-settings/birthday-widget-settings.component';
import {BirthdayWidgetComponent} from '@widgets/birthday/birthday-widget/birthday-widget.component';

/**
 * The configuration for the blog article widget.
 */
export const BIRTHDAY_WIDGET_CONFIG: WidgetConfig<BirthdayWidget> = {
  key: 'birthday',
  name: 'WIDGET.BIRTHDAY.NAME',
  description: 'WIDGET.BIRTHDAY.DESCRIPTION',
  defaultTitle: 'WIDGET.BIRTHDAY.NAME',
  icon: 'zmdi-cake',
  categories: WidgetCategory.DYNAMIC,
  component: BirthdayWidgetComponent,
  settings: {
    component: BirthdayWidgetSettingsComponent,
    skipOnCreate: false
  },
  whitelistExternal: false
};

(function (angular) {
  'use strict';

  FileDetailsController.$inject = ["DocumentModel"];
  angular
      .module('commons.ui')
      .component('coyoFileDetails', fileDetails())
      .controller('FileDetailsController', FileDetailsController);

  /**
   * @ngdoc directive
   * @name  commons.ui.coyoFileDetails:coyoFileDetails
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Renders a table with file details
   *
   * @param {object} sender
   * The sender the file belongs to
   *
   * @param {object} file
   * The file to be displayed
   *
   * @param {string} fileAuthor
   * The file author
   *
   * @param {string} refreshFileFunc
   * Function to trigger reload of file in parent component
   *
   * @requires coyo.domain.DocumentModel
   */
  function fileDetails() {
    return {
      templateUrl: 'app/commons/ui/components/file-details/file-details.html',
      bindings: {
        sender: '<',
        file: '<',
        fileAuthor: '<',
        updateFileFunc: '&',
        fileDetailsDialogId: '<'
      },
      controller: 'FileDetailsController'
    };
  }

  /**
   * Controller for managing file details view and changing file information.
   *
   * @requires DocumentModel
   *
   * @constructor
   */
  function FileDetailsController(DocumentModel) {
    var vm = this;

    vm.saveDescription = saveDescription;
    vm.resetDescription = resetDescription;

    function saveDescription() {
      return DocumentModel.setDescription(vm.sender.id, vm.file.id, vm.file.description).then(function (document) {
        vm.isEditable = false;
        vm.file = document;
        vm.updateFileFunc();
      });
    }

    function resetDescription() {
      vm.isEditable = false;
    }
  }

})(angular);

<button type="button" mat-icon-button [matMenuTriggerFor]="menu" size="small"
        data-test="timeline-item-context-menu" [attr.aria-label]="'CONTEXT_MENU.SHOW' | translate">
  <mat-icon svgIcon="more-horizontal"></mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <button type="button" mat-menu-item (click)="onClickShowOriginalAuthors()"
          *ngIf="item._permissions.accessoriginalauthor
          && item.author.id !== currentUserId
          && !item.data.article?.app?.settings?.hideAuthors
          && item.itemType != 'blog'">
    <mat-icon svgIcon="user"></mat-icon>
    <span>{{ 'MODULE.TIMELINE.ORIGINAL_AUTHOR.' + (!showOriginalAuthor ? '' : 'UN') + 'REVEAL' | translate }}</span>
  </button>
  <button type="button" mat-menu-item (click)="openEditModal()"
          data-test="button-timeline-post-edit" *ngIf="canEdit()">
    <mat-icon svgIcon="pencil"></mat-icon>
    <span>{{ 'EDIT' | translate }}</span>
  </button>
  <button type="button" mat-menu-item [cdkCopyToClipboard]="deepLink"
          (copied)="copyLinkSuccessNotification()">
    <mat-icon svgIcon="link"></mat-icon>
    <span>{{ 'MODULE.TIMELINE.COPY_LINK' | translate }}</span>
  </button>
  <button type="button" mat-menu-item (click)="openReportForm()"
          data-test="button-timeline-post-report" *ngxPermissionsOnly="'CREATE_REPORTS'">
    <mat-icon svgIcon="warning-octagon"></mat-icon>
    <span>{{ 'MODULE.TIMELINE.REPORT' | translate }}</span>
  </button>
  <button type="button" mat-menu-item (click)="unsticky.emit()"
          data-test="button-timeline-post-sticky" *ngIf="item.stickyExpiry && item._permissions.sticky">
    <mat-icon svgIcon="pin"></mat-icon>
    <span>{{ 'MODULE.TIMELINE.UNSTICKY' | translate}}</span>
  </button>
  <button type="button" mat-menu-item (click)="delete.emit()"
          data-test="delete-post-button" *ngIf="item._permissions.delete">
    <mat-icon svgIcon="delete"></mat-icon>
    <span>{{ 'DELETE' | translate }}</span>
  </button>
</mat-menu>

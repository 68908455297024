(function () {
  'use strict';

  angular
      .module('commons.tour')
      .directive('ngxTourStep', ngxTourStep);

  function ngxTourStep() {
    return {
      restrict: 'A',
      bindToController: {
        step: '@ngxTourStep',
        topic: '@ngxTourTopic',
        order: '<ngxTourOrder',
        title: '<ngxTourTitle',
        text: '<ngxTourText',
        condition: '<ngxTourIf',
        selector: '@?ngxTourSelector',
        placement: '@?ngxTourPlacement',
        options: '<?ngxTourOptions'
      },
      controller: ["$injector", "$element", "$attrs", "$timeout", function ($injector, $element, $attrs, $timeout) {
        var vm = this;
        var ngxTourService = $injector.get('ngxTourService');

        vm.$onInit = onInit;
        vm.$onDestroy = onDestroy;

        function onInit() {
          // wait until element has been fully compiled
          // in order to correctly execute querySelector
          $timeout(function () {
            var condition = !$attrs.ngxTourIf || vm.condition;
            var added = condition && ngxTourService.addStep({
              name: vm.step,
              topic: vm.topic,
              order: vm.order,
              options: angular.extend({
                title: vm.title,
                text: vm.text,
                attachTo: {
                  element: vm.selector
                    ? $element[0].querySelector(vm.selector)
                    : $element[0],
                  on: vm.placement || 'bottom'
                }
              }, vm.options)
            });

            // start the tour if the steps has been added
            if (added) {
              ngxTourService.startTour();
            }
          });
        }

        function onDestroy() {
          ngxTourService.removeStep(vm.topic, vm.step);
        }
      }]
    };
  }

})();

import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'coyo-aspect-ratio-chooser',
  templateUrl: './aspect-ratio-chooser.component.html',
  styleUrls: ['./aspect-ratio-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AspectRatioChooserComponent),
    multi: true
  }]
})
export class AspectRatioChooserComponent implements ControlValueAccessor {

  readonly wide: string = '3:1';
  readonly narrow: string = '1:1';

  value: string;

  onChange: (newVal: string) => void;
  onTouched: () => void;

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  selectWideOption(): void {
    this.value = this.wide;
    this.onChange(this.value);
  }

  selectNarrowOption(): void {
    this.value = this.narrow;
    this.onChange(this.value);
  }
}

<ng-container *ngIf="visibleApps$ | async; let visibleApps">
  <div class="app-row" *ngFor="let apps of visibleApps.apps; trackBy: trackByIndex">
    <div *ngFor="let app of apps; trackBy: trackByName" [style.width]="getWidthPercentage(visibleApps.columns)">
      <a *ngIf="app" [attr.href]="app.url" class="launchpad-app" target="_blank">
        <mat-icon [svgIcon]="app.icon"></mat-icon>
        <div class="app-row-message-badge">
          <div class="unread-messages" *ngIf="app.name === OUTLOOK && (hasUnreadOutlookEmails$ | async)"></div>
          <span>{{app.name}}</span>
        </div>
      </a>
    </div>
  </div>
</ng-container>
<button mat-button mat-link-button type="button" size="small" class="btn-show-all"
        (click)="showExtended.next(!showExtended.getValue())">
  <span *ngIf="!(showExtended | async)" translate="LAUNCHPAD.MANAGE.SHOW_ALL"></span>
  <span *ngIf="showExtended | async" translate="LAUNCHPAD.MANAGE.SHOW_LESS"></span>
  <mat-icon svgIcon="chevron-down" size="small" class="right" [class.extended]="showExtended | async"></mat-icon>
</button>

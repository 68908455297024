import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {LockInfo} from '@app/file-picker/lock-info';
import {LOCAL_FILE_LIBRARY, StorageType} from '@domain/attachment/storage';
import {File} from '@domain/file/file';

/**
 * File library file picker item
 */
export class FileLibraryFilePickerItem implements FilePickerItem {
  readonly id: string;
  readonly isFolder: boolean;
  readonly isAppRoot: boolean;
  readonly lastModified: number;
  readonly mimeType: string;
  readonly name: string;
  readonly sizeInBytes: number;
  readonly storageType: StorageType = LOCAL_FILE_LIBRARY;
  readonly childCount?: number;
  readonly permissions: { [permission: string]: boolean };
  readonly hasPublicLink: boolean;
  readonly hasActivePublicLink: boolean;
  readonly lock?: LockInfo;

  constructor(readonly file: File) {
    this.id = file.id;
    this.isFolder = !!file.folder;
    this.lastModified = file.modified;
    this.mimeType = file.contentType;
    this.name = file.name;
    this.sizeInBytes = file.length;
    this.storageType = file.storage;
    this.childCount = file.childCount;
    this.permissions = file._permissions;
    this.hasPublicLink = file.hasPublicLink;
    this.hasActivePublicLink = file.activePublicLink;
    this.isAppRoot = file.appRoot;
    if (file.locked) {
      this.lock = {
        locked: file.locked,
        lockDate: file.lockDate,
        lockHolder: file.lockHolder
      };
    }
  }
}

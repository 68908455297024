import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {from, Observable, of, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';

/**
 * Filter entry component.
 *
 * @description
 * Renders a single entry for a filter.
 * It is possible to add an icon and a text as well as projected content.
 *
 * @example
 * <coyo-filter-entry text="..." icon="..." [active]="...">...</coyo-filter-entry>
 */
@Component({
  selector: 'coyo-filter-entry',
  templateUrl: './filter-entry.component.html',
  styleUrls: ['./filter-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterEntryComponent implements OnInit, OnDestroy {

  /**
   * Indicates whether this filter is active or not.
   */
  @Input()
  active: boolean = false;

  /**
   * A given icon name that should be displayed.
   */
  @Input()
  icon?: string;

  /**
   * A given icon color that should be displayed.
   */
  @Input()
  iconColor?: string;

  /**
   * A given badge that should be displayed.
   */
  @Input()
  badge?: string | number;

  /**
   * A given text that should be displayed.
   */
  @Input()
  text?: string;

  /**
   * A callback function that will be executed on an entry click event.
   */
  @Input()
  callback?: <T>(...args: unknown[]) => Promise<T | void> | void;

  /**
   * The callback parameters.
   */
  @Input()
  callbackParams?: unknown | unknown[];

  iconCssColor: string;
  loading$: Observable<boolean>;

  private isLoading: Subject<boolean>;

  constructor() { }

  ngOnInit(): void {
    this.isLoading = new Subject<boolean>();
    this.loading$ = this.isLoading.asObservable().pipe(startWith(false));
    this.iconCssColor = this.iconColor ? 'color: ' + this.iconColor + ';' : '';
  }

  ngOnDestroy(): void {
    this.isLoading.complete();
  }

  /**
   * Executes callback on entry click.
   */
  onClick(): void {
    if (this.callback) {
      this.isLoading.next(true);
      const params = Array.isArray(this.callbackParams) ? this.callbackParams : [this.callbackParams];
      const observable = from(this.callback(...params) || of({}));
      observable.subscribe(() => {
        this.isLoading.next(false);
      });
    }
  }

}

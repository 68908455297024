(function (angular) {
  'use strict';

  ContextMenuController.$inject = ["$scope", "$rootScope", "$timeout", "$element", "utilService", "sidebarService"];
  angular
      .module('commons.ui')
      .directive('coyoContextMenu', contextMenu)
      .controller('ContextMenuController', ContextMenuController);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoContextMenu:coyoContextMenu
   * @restrict 'E'
   *
   * @description
   * Renders a context menu.
   *
   * The context menu is a bootstrap dropdown in desktop view and a slide-up menu in mobile view.
   *
   * @param {boolean} alignRight
   * If true the menu will be right-aligned (useful when the menu is placed at the right side of the screen)
   *
   * @param {string=} toggleElement
   * jqLite selector of the element that is used to toggle the menu. If set, no icon will be rendered.
   *
   * @param {string} title
   * Translation key of the menu title.
   *
   * @param {boolean=} titleDesktop
   * If true, the menu title will be displayed on desktop as well at the top of the menu (defaults to only show on mobile).
   *
   * @require $scope
   * @require $timeout
   * @require commons.ui.utilService
   *
   * @example
   * <example module="example">
   *   <file name="index.html">
   *     <div>
   *       <coyo-context-menu title="Title above mobile menu">
   *         <li>Item 1</li>
   *         <li>Item 2</li>
   *       </coyo-context-menu>
   *     </div>
   *   </file>
   *   <file name="script.js">
   *     angular
   *       .module('example', []);
   *   </file>
   * </example>
   */
  function contextMenu() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'app/commons/ui/components/context-menu/context-menu.html',
      scope: {},
      bindToController: {
        alignRight: '<',
        toggleElement: '@',
        title: '@',
        titleDesktop: '<?',
        titleAria: '@?',
        focusVar: '=?',
        onToggleMenu: '<'
      },
      transclude: true,
      controller: 'ContextMenuController',
      controllerAs: '$ctrl'
    };
  }

  function ContextMenuController($scope, $rootScope, $timeout, $element, utilService, sidebarService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.toggleMenu = toggleMenu;
    vm.closeMenu = closeMenu;
    vm.onKeyDown = onKeyDown;

    var unsubscribe = $rootScope.$on('screenSize:changed', function (event, screenSize, oldScreenSize) {
      if ((oldScreenSize.isSm || oldScreenSize.isXs) && (screenSize.isMd || screenSize.isLg) && vm.open) {
        $rootScope.showBackdrop = false;
      } else if ((screenSize.isSm || screenSize.isXs) && (oldScreenSize.isMd || oldScreenSize.isLg) && vm.open) {
        $rootScope.showBackdrop = true;
      }
    });

    $scope.$on('$destroy', unsubscribe);

    function toggleMenu() {
      if (vm.onToggleMenu) {
        _setMenuOpenStatus(!vm.open);
        _setAriaAttributes();
        vm.onToggleMenu();
      } else {
        _setMenuOpenStatus(!vm.open);
        _setAriaAttributes();
      }
    }

    function closeMenu() {
      _setMenuOpenStatus(false);
    }

    function onKeyDown($event) {
      _handleEscapeKey($event);
    }

    function _handleEscapeKey($event) {
      if ($event.keyCode === 27) {
        closeMenu();
      }
    }

    function _setMenuOpenStatus(status) {
      $timeout(function () { // delay opening to avoid firing click-outside event
        vm.open = status;
        _onMenuOpenStatusChanged();
      });
    }

    function _onMenuOpenStatusChanged() {
      if (($rootScope.screenSize.isSm || $rootScope.screenSize.isXs || $rootScope.showBackdrop) && !openSidebars()) {
        $rootScope.showBackdrop = vm.open;
      }
      _setAriaAttributes();
      $timeout(function () {
        vm.focusVar = !vm.open;
      });
    }

    function openSidebars() {
      return sidebarService.getOpened().length > 0;
    }

    function _onMenuToggleClick() {
      vm.toggleMenu();
      $scope.$apply(); // native event handling
    }

    function _findToggleDomElement() {
      if (vm.toggleElement) {
        vm.toggleDOMElement = angular.element(document).querySelectorAll(vm.toggleElement);
      } else {
        vm.toggleDOMElement = $element.querySelectorAll('.context-menu-toggle');
      }
    }

    function _setAriaAttributes() {
      if (vm.toggleDOMElement) {
        vm.toggleDOMElement.attr('aria-haspopup', true)
            .attr('aria-expanded', vm.open)
            .attr('aria-owns', 'context-menu-list-' + $scope.$id);
      }
    }

    function _setMenuItemClickListeners() {
      var selectableListItemsSelector = 'li:not(.context-menu-title)';
      $element.querySelectorAll(selectableListItemsSelector).bind('click', closeMenu);
    }

    function onInit() {
      vm.open = false;
      vm.titleAria = vm.titleAria || vm.title;

      $timeout(function () {
        _findToggleDomElement();
        _setAriaAttributes();
        _setMenuItemClickListeners();
        if (vm.toggleElement) {
          vm.toggleDOMElement.on('click', _onMenuToggleClick);

          $scope.$on('$destroy', function () {
            vm.toggleDOMElement.off('click', _onMenuToggleClick);
          });
        }
      });
    }
  }
})(angular);

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Document} from '@domain/file/document';
import {WidgetComponent} from '@widgets/api/widget-component';
import {ImageWidget} from '@widgets/image/image-widget';

/**
 * The image widget component.
 * Renders a preselected image.
 */
@Component({
  selector: 'coyo-image-widget',
  templateUrl: './image-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageWidgetComponent extends WidgetComponent<ImageWidget> implements OnInit, OnChanges {

  image: Document;

  url: string = '';

  linkTarget: '_blank' | '_self' = '_self';

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    if (this.widget && this.widget.settings && this.widget.settings._image) {
      this.image = this.widget.settings._image;
    }

    if (this.widget && this.widget.settings && this.widget.settings._url) {
      this.url = this.widget.settings._url;
    }

    if (this.widget && this.widget.settings && this.widget.settings._linkTarget) {
      this.linkTarget = this.determineLinkTargetType(this.widget.settings._linkTarget);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget && changes.widget.currentValue.settings) {
      this.image = changes.widget.currentValue.settings._image;
      this.url = changes.widget.currentValue.settings._url;
      this.linkTarget = this.determineLinkTargetType(changes.widget.currentValue.settings._linkTarget);
    }
  }

  private determineLinkTargetType(value: boolean): '_blank' | '_self' {
    return value ? '_blank' : '_self';
  }
}

<aside *ngFor="let section of sections$ | async; last as isLast"
       [attr.aria-label]="'WIDGET.SUBSCRIPTIONS.' + section.i18n + '.ARIA' | translate">
  <ng-container *ngIf="section.state$ | async as state">
    <h3 class="widget-title" [translate]="'WIDGET.SUBSCRIPTIONS.' + section.i18n"></h3>
    <ul *ngIf="state.content.length">
      <li *ngFor="let sender of state.content">
        <a mat-button [attr.href]="getLink(sender)" class="block-left btn-sender">
          <mat-icon [svgIcon]="getIcon(section, sender)" class="senderIcon"></mat-icon>
          <span class="senderName">{{ sender.displayName }}</span>
        </a>
        <button mat-icon-button *ngIf="section.favorite" (click)="toggleFavorite(section, sender, $event)" size="small" class="btn-fav"
                [matTooltip]="(isFavorite(section, sender) ? 'WIDGET.SUBSCRIPTIONS.FAVORITE.UNSET' : 'WIDGET.SUBSCRIPTIONS.FAVORITE.SET') | translate">
          <mat-icon [svgIcon]="(isFavorite(section, sender) ? 'star-crossed' : 'star')"></mat-icon>
        </button>
      </li>
    </ul>
    <button mat-button mat-link-button color="accent" class="btn-more"
            *ngIf="!state.isLoading && !state.last" (click)="load(section)"
            [attr.aria-label]="'WIDGET.SUBSCRIPTIONS.MORE.' + section.i18n + '.ARIA' | translate">
      <mat-icon svgIcon="chevron-down" color="accent"></mat-icon>
      {{('WIDGET.SUBSCRIPTIONS.MORE.' + section.i18n | translate)}}
    </button>
    <ui-skeleton [skeletons]="skeletons" *ngIf="state.isLoading"></ui-skeleton>
    <p class="empty" *ngIf="!state.isLoading && !state.content.length"
       [translate]="'WIDGET.SUBSCRIPTIONS.EMPTY.' + section.i18n"></p>
  </ng-container>
  <hr *ngIf="!isLast" aria-hidden="true">
</aside>

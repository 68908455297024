import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {EntityId} from '@domain/entity-id/entity-id';
import {Guest} from '@domain/guest/guest';
import {UserChooserSelectionConfig} from '@domain/guest/user-chooser-selection-config';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Observable} from 'rxjs';

/**
 * Service to search for invites.
 */
@Injectable({
  providedIn: 'root'
})
export class UserChooserService extends DomainService<Guest, Guest> {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(UrlService) protected urlService: UrlService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/users/chooser';
  }

  /**
   * Returns a page of searched guests.
   *
   * @param searchTerm  A search term for guest search
   * @param config      The types that the endpoint should return as config
   * @param entityId    Entity id of an existing sender or null if the sender is not created yet.
   * @param pageable    The pagination information
   * @returns an observable with the search result
   */
  searchGuests(searchTerm: string, config: UserChooserSelectionConfig, entityId: null | EntityId, pageable: Pageable): Observable<Page<Guest>> {
    const types = this.setupTypes(config);
    const searchPathParams = `/search?term=${searchTerm}&types=${types}`;
    const path = !!entityId ? `/${entityId.typeName}s/${entityId.id}${searchPathParams}` : searchPathParams;
    return this.getPage(pageable, {path});
  }

  private setupTypes(selectionTypes: UserChooserSelectionConfig): string {
    const types = [];
    for (const key in selectionTypes) {
      if (selectionTypes.hasOwnProperty(key) && !!selectionTypes[key]) {
        types.push(this.removeLastCharacter(key));
      }
    }
    return types.join(',');
  }

  private removeLastCharacter(term: string): string {
    return term.toLowerCase().slice(0, -1);
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {Sender} from '@domain/sender/sender';
import {Share} from '@domain/share/share';
import {ShareService} from '@domain/share/share.service';
import {TimelineItemType} from '@domain/timeline-item/timeline-item-type';
import {User} from '@domain/user/user';
import {DeleteConfirmationService} from '@shared/dialog/delete-confirmation/delete-confirmation.service';
import * as _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

interface ShareState {
  isLoading: boolean;
  ribbonType: string;
  currentUser: User;
  shownAuthor: Sender;
  originalAuthor: User;
  i18nAuthor: 'NONE' | 'YOU' | 'OTHER';
  i18nRecipient: 'NONE' | 'YOU' | 'OTHER';
  canToggleAuthor: boolean;
  canMakeUnsticky: boolean;
  canDeleteShare: boolean;
}

/**
 * Renders a list with all shares for the timeline item and shows a ribbon on the top of the timeline item.
 */
@Component({
  selector: 'coyo-timeline-share',
  templateUrl: './timeline-share.component.html',
  styleUrls: ['./timeline-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineShareComponent implements OnChanges {

  /**
   * The timeline share.
   */
  @Input() share: Share;

  /**
   * Show the ribbon if needed. Default is false.
   */
  @Input() showRibbon?: boolean = false;

  /**
   * The type of the share target.
   */
  @Input() itemType: TimelineItemType;

  /**
   * Event if a share was deleted.
   */
  @Output() shareDeleted: EventEmitter<Share> = new EventEmitter<Share>();

  shareState: BehaviorSubject<ShareState> = new BehaviorSubject({
    isLoading: true,
    ribbonType: null,
    currentUser: null,
    shownAuthor: null,
    originalAuthor: null,
    i18nAuthor: 'NONE' as any,
    i18nRecipient: 'NONE' as any,
    canToggleAuthor: false,
    canMakeUnsticky: false,
    canDeleteShare: false
  });

  shareState$: Observable<ShareState> = this.shareState.asObservable();

  constructor(private authService: AuthService,
              private shareService: ShareService,
              private deleteConfirmationService: DeleteConfirmationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.share && (changes.share.isFirstChange() || changes.share.currentValue !== changes.share.previousValue)) {
      this.authService.getUser().subscribe(user => this.updateState({
        isLoading: false,
        ribbonType: this.getRibbonType(this.share.unread),
        currentUser: user,
        shownAuthor: this.share.author,
        i18nAuthor: this.getI18nAuthor(user, this.share.author),
        i18nRecipient: this.getI18nRecipient(user, this.share.author),
        canToggleAuthor: this.canToggleAuthor(user, this.share.author),
        canMakeUnsticky: this.canMakeUnsticky(),
        canDeleteShare: this.canDeleteShare()
      }));
    }
  }

  /**
   * Toggle the original author name visibility.
   */
  toggleAuthor(): void {
    const shownAuthor = this.shareState.getValue().shownAuthor;
    const originalAuthor = this.shareState.getValue().originalAuthor;
    if (!originalAuthor) {
      if (this.shareState.getValue().isLoading) {
        return;
      }

      this.updateState({isLoading: true});
      this.shareService.getOriginalAuthor(this.share.id)
        .pipe(finalize(() => this.updateState({isLoading: false})))
        .subscribe(user => this.updateState({
            shownAuthor: user,
            originalAuthor: user,
            i18nAuthor: this.getI18nAuthor(this.shareState.getValue().currentUser, user),
            i18nRecipient: this.getI18nRecipient(this.shareState.getValue().currentUser, user)
          })
        );
    } else {
      const author = _.get(shownAuthor, 'id') === _.get(this.share.author, 'id') ? originalAuthor : this.share.author;
      this.updateState({
        shownAuthor: author,
        i18nAuthor: this.getI18nAuthor(this.shareState.getValue().currentUser, author),
        i18nRecipient: this.getI18nRecipient(this.shareState.getValue().currentUser, author)
      });
    }
  }

  /**
   * Marks a share as read.
   */
  markShareAsRead(): void {
    if (this.shareState.getValue().isLoading) {
      return;
    }

    this.updateState({isLoading: true});
    this.shareService.post(null, {
      context: {id: this.share.id},
      permissions: ['*'],
      path: '/{id}/read'
    }).pipe(finalize(() => this.updateState({
      isLoading: false
    }))).subscribe(updatedShare => {
      this.updateState({
        ribbonType: this.getRibbonType(updatedShare.unread)
      });
    });
  }

  /**
   * Makes a share unsticky.
   */
  makeUnsticky(): void {
    if (this.shareState.getValue().isLoading) {
      return;
    }

    this.updateState({isLoading: true});
    this.shareService.post(null, {
      context: {id: this.share.id},
      permissions: ['*'],
      path: '/{id}/unsticky'
    }).pipe(finalize(() => this.updateState({isLoading: false}))).subscribe();
  }

  /**
   * Deletes a share with confirmation.
   */
  deleteShare(): void {
    if (this.shareState.getValue().isLoading) {
      return;
    }
    this.deleteConfirmationService.open(
      'MODULE.TIMELINE.SHARE.DIALOG.DELETE.TITLE',
      'MODULE.TIMELINE.SHARE.DIALOG.DELETE.CONFIRM',
      'YES',
      'NO'
    ).subscribe(doDelete => {
      if (doDelete) {
        this.updateState({isLoading: true});
        this.shareService.delete(this.share.id)
          .pipe(finalize(() => this.updateState({isLoading: false})))
          .subscribe(() => this.shareDeleted.emit(this.share));
      }
    });
  }

  private getI18nAuthor(currentUser: User, authorShown: Sender): 'NONE' | 'YOU' | 'OTHER' {
    if (authorShown) {
      return authorShown.id === currentUser.id ? 'YOU' : 'OTHER';
    }
    return 'NONE';
  }

  private getI18nRecipient(currentUser: User, authorShown: Sender): 'NONE' | 'YOU' | 'OTHER' {
    if (this.share.recipient && (!authorShown || authorShown.id !== this.share.recipient.id)) {
      return this.share.recipient.id === currentUser.id ? 'YOU' : 'OTHER';
    }
    return 'NONE';
  }

  private canToggleAuthor(currentUser: User, authorShown: Sender): boolean {
    return authorShown && authorShown.id !== currentUser.id
      && this.share._permissions
      && this.share._permissions.accessoriginalauthor;
  }

  private canMakeUnsticky(): boolean {
    return this.share.stickyExpiry
      && this.share._permissions
      && this.share._permissions.sticky;
  }

  private canDeleteShare(): boolean {
    return this.share._permissions
      && this.share._permissions.delete;
  }

  private updateState(data: Partial<ShareState>): void {
    const value = this.shareState.getValue();
    this.shareState.next({...value, ...data});
  }

  private getRibbonType(sticky: boolean): string {
    return sticky ? 'sticky' : undefined;
  }
}

import {Injectable, Type} from '@angular/core';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {FileLibraryRootFilePickerItem} from '@app/file-library/file-library-root-file-picker-item/file-library-root-file-picker-item';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {LandingPagesRootFilePickerItem} from '@app/file-library/landing-page-root-file-picker-item/landing-pages-root-file-picker-item';
import {FilePickerData} from '@app/file-picker/file-picker-data';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemServiceSelectorService} from '@app/file-picker/file-picker-item-service-selector.service';
import {ToggleSelection} from '@app/file-picker/file-picker-selection/file-picker-selection.actions';
import {
  FilePickerItemStateModel,
  FilePickerStateModel,
  FilePickerStatesModel
} from '@app/file-picker/file-picker-state/file-picker-state-model';
import {
  CloseFilePicker,
  CreateFolder,
  DeleteFilePickerItem,
  FinishRenamingFilePickerItem,
  InitializeFilePicker,
  LoadFileAuthors,
  LoadMore,
  MoveFileToFolder,
  OpenFolder,
  OpenInRoot,
  OpenParent,
  OpenRoot,
  RefreshItem,
  ToggleRenamingFilePickerItem,
  UpdateFile,
  UpdatePublicLinkStatus,
  UploadFiles
} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerUploadItem} from '@app/file-picker/file-picker-upload-item/file-picker-upload-item';
import {insertAt} from '@core/ngxs/state-operators/insertAt';
import {prepend} from '@core/ngxs/state-operators/prepend';
import {remove} from '@core/ngxs/state-operators/remove';
import {FileUploadEvent} from '@domain/file/file/events/file-upload-event';
import {FileUploadFileAbortedEvent} from '@domain/file/file/events/file-upload-file-aborted-event';
import {FileUploadFileFinishedEvent} from '@domain/file/file/events/file-upload-file-finished-event';
import {FileUploadNextFileEvent} from '@domain/file/file/events/file-upload-next-file-event';
import {FileUploadProgressEvent} from '@domain/file/file/events/file-upload-progress-event';
import {Page} from '@domain/pagination/page';
import {Action, State, StateContext} from '@ngxs/store';
import {iif, patch, removeItem, updateItem} from '@ngxs/store/operators';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {EMPTY, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

/**
 * State class holding file picker items for all file pickers
 */
@State<FilePickerStatesModel>({
  name: 'filePicker',
  defaults: {
    pickers: {},
    items: {}
  }
})
@Injectable()
export class FilePickerState {

  constructor(private readonly filePickerItemServiceSelectorService: FilePickerItemServiceSelectorService,
              private readonly notificationService: NotificationService) {
  }

  static getCurrentLocation(state: FilePickerStatesModel, pickerId: string): FilePickerItemStateModel | null {
    const breadcrumbs = state.pickers[pickerId]?.breadcrumbs;
    if (breadcrumbs && breadcrumbs.length > 0) {
      return state.items[breadcrumbs[breadcrumbs.length - 1]];
    }
    return null;
  }

  @Action(InitializeFilePicker)
  initialize(ctx: StateContext<FilePickerStatesModel>, action: InitializeFilePicker): Observable<Page<FilePickerItem>> {
    const currentState = ctx.getState();

    const breadcrumbs: FilePickerItemStateModel[] = [this.getItemStateModel(currentState, action.filePickerData.rootFolder)];
    if (action.filePickerData.firstOpenFolder) {
      breadcrumbs.push(this.getItemStateModel(currentState, action.filePickerData.firstOpenFolder));
    }
    this.resetState(ctx, action.filePickerId, breadcrumbs, action.filePickerData);
    return this.loadFolder(ctx, action.filePickerId, breadcrumbs[1] ?? breadcrumbs[0]);
  }

  @Action(CloseFilePicker)
  close(ctx: StateContext<FilePickerStatesModel>, action: CloseFilePicker): Observable<never> {
    ctx.setState(patch({pickers: remove(action.filePickerId)}));

    return EMPTY;
  }

  @Action(OpenFolder)
  openFolder(ctx: StateContext<FilePickerStatesModel>, action: OpenFolder): Observable<Page<FilePickerItem>> {
    const currentState = ctx.getState();
    const currentPickerState = currentState.pickers[action.filePickerId] ?? this.newState();
    const folderIndex = currentPickerState.breadcrumbs.indexOf(action.item.id);
    const itemModel = this.getItemStateModel(currentState, action.item);
    if (folderIndex < 0) {
      this.resetState(
        ctx,
        action.filePickerId,
        [...currentPickerState.breadcrumbs.map(id => currentState.items[id]), itemModel],
        currentPickerState.options
      );
    } else {
      this.resetState(
        ctx,
        action.filePickerId,
        currentPickerState.breadcrumbs.slice(0, folderIndex + 1).map(id => currentState.items[id]),
        currentPickerState.options
      );
    }
    return this.loadFolder(ctx, action.filePickerId, itemModel);
  }

  @Action(OpenRoot)
  openRoot(ctx: StateContext<FilePickerStatesModel>, action: OpenRoot): Observable<Page<FilePickerItem>> {
    const currentState = ctx.getState();
    const currentPickerState = currentState.pickers[action.filePickerId];
    if (!currentPickerState.breadcrumbs?.length) {
      throw(new Error('OpenRoot can only be used with an initialized state'));
    }
    const root = currentState.items[currentPickerState.breadcrumbs[0]];
    this.resetState(ctx, action.filePickerId, [root], currentPickerState.options);
    return this.loadFolder(ctx, action.filePickerId, root);
  }

  @Action(OpenInRoot)
  openInRoot(ctx: StateContext<FilePickerStatesModel>, action: OpenInRoot): Observable<Page<FilePickerItem>> {
    const currentState = ctx.getState();
    const currentPickerState = currentState.pickers[action.filePickerId];
    if (!currentPickerState?.breadcrumbs?.length) {
      throw(new Error('OpenInRoot can only be used with an initialized state'));
    }
    const itemModel = this.getItemStateModel(currentState, action.item);
    this.resetState(ctx, action.filePickerId, [currentState.items[currentPickerState.breadcrumbs[0]], itemModel], currentPickerState.options);
    return this.loadFolder(ctx, action.filePickerId, itemModel);
  }

  @Action(OpenParent)
  openParent(ctx: StateContext<FilePickerStatesModel>, action: OpenParent): Observable<Page<FilePickerItem>> {
    const currentState = ctx.getState();
    const currentPickerState = currentState.pickers[action.filePickerId];
    if (!currentPickerState.breadcrumbs?.length || currentPickerState.breadcrumbs.length < 2) {
      throw(new Error('OpenRoot can only be used with an initialized state with more than one breadcrumb element'));
    }
    const breadcrumbs = currentPickerState.breadcrumbs.slice(0, currentPickerState.breadcrumbs.length - 1);
    this.resetState(ctx, action.filePickerId, breadcrumbs.map(id => currentState.items[id]), currentPickerState.options);
    return this.loadFolder(ctx, action.filePickerId, currentState.items[breadcrumbs[breadcrumbs.length - 1]]);
  }

  @Action(FinishRenamingFilePickerItem)
  renameFilePickerItem(ctx: StateContext<FilePickerStatesModel>, action: FinishRenamingFilePickerItem): Observable<FilePickerItem> {
    const currentState = ctx.getState();
    const itemModel = currentState.items[action.itemId];
    const periodIndex = itemModel.item.name.lastIndexOf('.');
    const newName = periodIndex < 0 ? action.newName : `${action.newName}.${itemModel.item.name.substring(periodIndex + 1)}`;
    const service = this.filePickerItemServiceSelectorService.get(itemModel.item);
    return service
      .rename(itemModel.item, newName)
      .pipe(
        tap(newItem => {
            ctx.setState(
              patch<FilePickerStatesModel>({
                items: patch(
                  {[newItem.id]: patch({item: newItem, capabilities: service.getCapabilities(newItem)})}
                ),
                pickers: patch<{ [id: string]: FilePickerStateModel }>({
                  [action.pickerId]: patch<FilePickerStateModel>(
                    {
                      renaming: null
                    }
                  )
                })
              })
            );
          }
        ));
  }

  @Action(ToggleRenamingFilePickerItem)
  toggleRenamingFilePickerItem(ctx: StateContext<FilePickerStatesModel>, action: ToggleRenamingFilePickerItem): Observable<never> {
    ctx.setState(patch<FilePickerStatesModel>({
        pickers: patch<{ [id: string]: FilePickerStateModel }>({
          [action.pickerId]: patch<FilePickerStateModel>(
            {
              renaming: action.itemId
            }
          )
        })
      })
    );
    return EMPTY;
  }

  @Action(CreateFolder)
  createFolder(ctx: StateContext<FilePickerStatesModel>, action: CreateFolder): Observable<FilePickerItem> {
    const state = ctx.getState();
    const parent = FilePickerState.getCurrentLocation(state, action.pickerId);
    const service = this.filePickerItemServiceSelectorService.get(parent.item);
    return service
      .createFolder(parent.item, action.initialName)
      .pipe(
        tap(newItem =>
          ctx.setState(
            patch<FilePickerStatesModel>({
              items: patch({[newItem.id]: {item: newItem, capabilities: service.getCapabilities(newItem)}}),
              pickers: patch<{ [id: string]: FilePickerStateModel }>({
                [action.pickerId]: patch<FilePickerStateModel>(
                  {
                    renaming: newItem.id,
                    itemIds: prepend([newItem.id])
                  }
                )
              })
            })
          )
        )
      );
  }

  @Action(UpdateFile)
  updateFile(ctx: StateContext<FilePickerStatesModel>, action: UpdateFile): Observable<FileUploadEvent> {
    const state = ctx.getState();
    const itemId = action.filePickerItem.id;
    const currentIndex = state.pickers[action.pickerId].itemIds.indexOf(itemId);
    const itemServiceDto = this.filePickerItemServiceSelectorService.get(action.filePickerItem)
      .update(action.filePickerItem, action.file);

    // remove original file from view
    ctx.setState(
      patch<FilePickerStatesModel>({
        pickers: patch<{ [id: string]: FilePickerStateModel }>({
          [action.pickerId]: patch<FilePickerStateModel>(
            {
              itemIds: removeItem(element => element === itemId)
            }
          )
        })
      })
    );
    return this.performUpload(ctx, action.pickerId, itemServiceDto.uploadEvent, itemServiceDto.itemType, currentIndex);
  }

  @Action(UploadFiles)
  uploadFiles(ctx: StateContext<FilePickerStatesModel>, action: UploadFiles): Observable<FileUploadEvent> {
    const state = ctx.getState();
    const cropSettings = state.pickers[action.pickerId].options?.cropSettings;
    const itemServiceDto = this.filePickerItemServiceSelectorService
      .get(action.parentItem)
      .upload(action.parentItem, action.files, cropSettings);
    return this.performUpload(ctx, action.pickerId, itemServiceDto.uploadEvent, itemServiceDto.itemType);
  }

  @Action(DeleteFilePickerItem)
  deleteFilePickerItem(ctx: StateContext<FilePickerStatesModel>, action: DeleteFilePickerItem): Observable<FilePickerItem> {
    const state = ctx.getState();
    const itemStateModel = state.items[action.itemId];
    const service = this.filePickerItemServiceSelectorService.get(itemStateModel.item);
    return service
      .delete(itemStateModel.item)
      .pipe(
        tap(deletedItem =>
          ctx.setState(
            patch<FilePickerStatesModel>({
              items: remove(deletedItem.id),
              pickers: patch<{ [id: string]: FilePickerStateModel }>({
                [action.pickerId]: patch<FilePickerStateModel>(
                  {
                    itemIds: removeItem(id => id === deletedItem.id)
                  }
                )
              })
            })
          )
        )
      );
  }

  @Action(LoadMore)
  loadMore(ctx: StateContext<FilePickerStatesModel>, action: LoadMore): Observable<Page<FilePickerItem>> {
    const nextPageIdx = ctx.getState().pickers[action.filePickerId].pageIdx + 1;
    const filePickerItem = action.filePickerItem;
    if (nextPageIdx >= ctx.getState().pickers[action.filePickerId].totalPages ||
      filePickerItem instanceof FileLibraryRootFilePickerItem ||
      filePickerItem instanceof LandingPagesRootFilePickerItem) {
      return of({} as Page<FilePickerItem>);
    }
    this.setLoadingState(ctx, action.filePickerId, true);
    return this.loadFolder(ctx, action.filePickerId, filePickerItem, nextPageIdx);
  }

  @Action(LoadFileAuthors)
  loadFileAuthors(ctx: StateContext<FilePickerStatesModel>, action: LoadFileAuthors): Observable<any> {
    const state = ctx.getState();
    if (!state.pickers[action.pickerId]?.options?.showAuthors || !state.pickers[action.pickerId]?.itemIds?.length) {
      return EMPTY;
    } else {
      const location = FilePickerState.getCurrentLocation(state, action.pickerId);
      const ids = state.pickers[action.pickerId].itemIds.filter(
        id => this.canShowAuthor(state, id));
      if (!ids.length) {
        return EMPTY;
      }
      return this.filePickerItemServiceSelectorService.get(location.item)
        .getAuthors(location.item, state.pickers[action.pickerId].options.appId, ids)
        .pipe(tap(authorsObject => {
          const currentState = ctx.getState();
          const items = Object.values<FilePickerItemStateModel>(currentState.items)
            .filter(model => !!authorsObject[model.item.id])
            .map(model => ({...model, author: authorsObject[model.item.id]}))
            .reduce((prev, current) =>
              ({...prev, [current.item.id]: current}), {} as { [key: string]: FilePickerItemStateModel });
          return ctx.setState(patch<FilePickerStatesModel>({
            items: patch(items)
          }));
        }));
    }
  }

  @Action(MoveFileToFolder)
  moveFileToFolder(ctx: StateContext<FilePickerStatesModel>, action: MoveFileToFolder): Observable<FilePickerItem> {
    const destinationItemStateModel = ctx.getState().items[action.folderId];
    if (!destinationItemStateModel.item.isFolder) {
      return EMPTY;
    }

    // tslint:disable-next-line:no-bitwise
    if (destinationItemStateModel.item.isFolder && (destinationItemStateModel.capabilities & FilePickerItemServiceCapability.MOVE_FILE)) {
      const itemService = this.filePickerItemServiceSelectorService.get(destinationItemStateModel.item);
      return itemService.moveFile(ctx.getState().items[action.filePickerItemId].item, action.folderId)
        .pipe(tap(updatedDestination => {
          let newItem = updatedDestination;
          if (ctx.getState().pickers[action.filePickerId].breadcrumbs[0] === updatedDestination.id) {
            newItem = Object.create(updatedDestination);
            Object.defineProperties(newItem, {
              name: {
                value: 'APP.FILE_LIBRARY.BREADCRUMB.START'
              }
            });
          }
          ctx.setState(patch<FilePickerStatesModel>({
              pickers: patch<{ [id: string]: FilePickerStateModel }>({
                [action.filePickerId]: patch<FilePickerStateModel>(
                  {
                    itemIds: removeItem(id => id === action.filePickerItemId)
                  }
                )
              }),
              items: patch<{ [key: string]: FilePickerItemStateModel }>({
                [updatedDestination.id]: {...destinationItemStateModel, item: newItem}
              })
            })
          );
        }));
    }

    return EMPTY;
  }

  @Action(UpdatePublicLinkStatus)
  updatePublicLinkStatus(ctx: StateContext<FilePickerStatesModel>, action: UpdatePublicLinkStatus): void {
    const itemObject = ctx.getState().items[action.filePickerItemId]?.item;

    const newItem: any = Object.create(itemObject);
    Object.defineProperties(newItem, {
      hasPublicLink: {
        value: action.hasPublicLink
      },
      hasActivePublicLink: {
        value: action.hasPublicLink && action.active
      }
    });

    ctx.setState(
      patch<FilePickerStatesModel>({
        items: patch({
          [action.filePickerItemId]: patch({
            item: newItem
          })
        })
      })
    );
  }

  @Action(RefreshItem)
  refreshItem(ctx: StateContext<FilePickerStatesModel>, action: RefreshItem): Observable<void> {
    const item = ctx.getState().items[action.filePickerItemId]?.item;
    if (!item) {
      return EMPTY;
    }
    return this.filePickerItemServiceSelectorService
      .get(item)
      .getUpdatedItem(item)
      .pipe(
        switchMap(updatedItem => {
          if (ctx.getState().pickers[action.filePickerId].options?.showAuthors) {
            return this.filePickerItemServiceSelectorService
              .get(item)
              .getAuthors(
                FilePickerState.getCurrentLocation(ctx.getState(), action.filePickerId).item,
                ctx.getState().pickers[action.filePickerId].options?.appId,
                [action.filePickerItemId]
              )
              .pipe(
                map(authors => ({
                  item: updatedItem,
                  author: authors[action.filePickerItemId]
                }))
              );
          } else {
            return of({item: updatedItem});
          }
        }),
        map(updatedItem => {
          ctx.setState(
            patch<FilePickerStatesModel>({
              items: patch({
                [action.filePickerItemId]: patch(updatedItem)
              })
            })
          );
        }));
  }

  private performUpload(
    ctx: StateContext<FilePickerStatesModel>,
    filePickerId: string, fileUpload: Observable<FileUploadEvent>,
    itemType: Type<FilePickerItem>, insertAtIndex?: number
  ): Observable<any> {

    return fileUpload.pipe(map(
      event => {
        if (event instanceof FileUploadNextFileEvent) {
          this.handleFileUploadNextFileEvent(event, ctx, filePickerId, insertAtIndex);
        } else if (event instanceof FileUploadFileFinishedEvent) {
          this.handleFileUploadFileFinishedEvent(event, ctx, filePickerId, itemType);
        } else if (event instanceof FileUploadFileAbortedEvent) {
          this.handleFileUploadFileAbortedEvent(event, ctx, filePickerId);
        } else if (event instanceof FileUploadProgressEvent) {
          this.handleFileUploadProgressEvent(event, ctx);
        }
      })
    );
  }

  private handleFileUploadNextFileEvent(
    event: FileUploadNextFileEvent,
    ctx: StateContext<FilePickerStatesModel>,
    filePickerId: string,
    insertAtIndex?: number): FilePickerStatesModel {

    const state = ctx.getState();
    const file = event.files[event.fileIndex];
    const newItem = new FilePickerUploadItem(event.fileId, file);
    const service = this.filePickerItemServiceSelectorService.get(newItem);
    const insertAtIndexOrAfterFolders = iif(
      !!insertAtIndex,
      insertAt(insertAtIndex, [newItem.id]),
      insertAt(itemId => !state.items[itemId].item.isFolder, [newItem.id]));

    return ctx.setState(
      patch<FilePickerStatesModel>({
        items: patch(
          {
            [newItem.id]: {
              item: newItem, capabilities: service.getCapabilities(newItem)
            }
          }
        ),
        pickers: patch<{ [id: string]: FilePickerStateModel }>({
          [filePickerId]: patch<FilePickerStateModel>(
            {
              itemIds: insertAtIndexOrAfterFolders
            }
          )
        })
      })
    );
  }

  private handleFileUploadFileFinishedEvent(
    event: FileUploadFileFinishedEvent,
    ctx: StateContext<FilePickerStatesModel>,
    filePickerId: string,
    itemType: Type<FilePickerItem>): Observable<any> {

    // add finished upload item and replace id if it is in the current file picker context
    const newItem = new itemType(event.file);
    const service = this.filePickerItemServiceSelectorService.get(newItem);

    ctx.setState(
      patch<FilePickerStatesModel>({
        items: patch({[newItem.id]: {item: newItem, capabilities: service.getCapabilities(newItem)}}),
        pickers: patch<{ [id: string]: FilePickerStateModel }>({
          [filePickerId]: patch<FilePickerStateModel>(
            {
              itemIds: iif(
                itemIds => itemIds.indexOf(event.fileId) < 0,
                [newItem.id],
                updateItem(element => element === event.fileId, newItem.id))
            }
          )
        })
      })
    );
    // remove temporary item
    ctx.setState(
      patch<FilePickerStatesModel>({
        items: remove(event.fileId)
      })
    );
    // load authors
    if (ctx.getState().pickers[filePickerId].options?.showAuthors) {
      return ctx.dispatch(new LoadFileAuthors(filePickerId));
    }

    ctx.dispatch(new ToggleSelection(new FileLibraryFilePickerItem(event.file)));
    return EMPTY;
  }

  private handleFileUploadFileAbortedEvent(
    event: FileUploadFileAbortedEvent,
    ctx: StateContext<FilePickerStatesModel>,
    filePickerId: string): FilePickerStatesModel {

    this.notificationService.error('FILE_LIBRARY.ERROR.FILE_UPLOAD',
      event.files[event.fileIndex].name,
      {filename: event.files[event.fileIndex].name});

    // remove temp item
    return ctx.setState(
      patch<FilePickerStatesModel>({
        items: remove(event.fileId),
        pickers: patch<{ [id: string]: FilePickerStateModel }>({
          [filePickerId]: patch<FilePickerStateModel>(
            {
              itemIds: removeItem(element => element === event.fileId)
            }
          )
        })
      })
    );
  }

  private handleFileUploadProgressEvent(
    event: FileUploadProgressEvent,
    ctx: StateContext<FilePickerStatesModel>): FilePickerStatesModel {

    return ctx.setState(
      patch<FilePickerStatesModel>({
        items: patch<{ [filePickerItemId: string]: FilePickerItemStateModel }>(
          {
            [event.fileId]: patch<FilePickerItemStateModel>({progress: event.progress})
          }
        )
      })
    );
  }

  private canShowAuthor(state: FilePickerStatesModel, itemId: string): boolean {
    const model = state.items[itemId];
    const service = this.filePickerItemServiceSelectorService.get(model.item);
    return !model.author
      && !model.item.isFolder
      // tslint:disable-next-line:no-bitwise
      && !!(service.getCapabilities(model.item) & FilePickerItemServiceCapability.AUTHOR);
  }

  private setLoadingState(ctx: StateContext<FilePickerStatesModel>, id: string, loading: boolean): void {
    ctx.setState(
      patch<FilePickerStatesModel>({
        pickers: patch<FilePickerStateModel>({
          [id]: patch({
            loading: loading
          })
        }),
      })
    );
  }

  private resetState(
    ctx: StateContext<FilePickerStatesModel>,
    id: string,
    breadCrumbs: FilePickerItemStateModel[],
    options: FilePickerData): FilePickerStateModel {
    const newState: FilePickerStateModel = {
      breadcrumbs: breadCrumbs.map(item => item.item.id) ?? [],
      itemIds: [],
      loading: true,
      pageIdx: 0,
      totalPages: 0,
      totalItems: 0,
      renaming: null,
      options
    };

    const itemsObject = breadCrumbs.toMap(item => item.item.id);
    ctx.setState(
      patch<FilePickerStatesModel>({
          pickers: patch({
            [id]: newState
          }),
          items: patch(itemsObject)
        }
      )
    );
    return newState;
  }

  private getItemStateModel(currentState: FilePickerStatesModel, filePickerItem: FilePickerItem): FilePickerItemStateModel {
    return currentState.items[filePickerItem.id] ??
      {
        item: filePickerItem,
        capabilities: this.filePickerItemServiceSelectorService.get(filePickerItem).getCapabilities(filePickerItem)
      };
  }

  private loadFolder(ctx: StateContext<FilePickerStatesModel>,
                     id: string,
                     filePickerItem: FilePickerItemStateModel,
                     pageIdx: number = 0): Observable<Page<FilePickerItem>> {
    return this.filePickerItemServiceSelectorService.get(filePickerItem.item).getChildren(filePickerItem.item, pageIdx).pipe(tap(page => {
        const items = page.content;
        const itemsObject = items.map<FilePickerItemStateModel>(item => ({
          item,
          capabilities: this.filePickerItemServiceSelectorService.get(item).getCapabilities(item)
        })).toMap(model => model.item.id);

        const currentState = ctx.getState();
        const currentPickerState = currentState.pickers[id];
        const currentLocation = FilePickerState.getCurrentLocation(currentState, id);
        // required to stop rendering late items into the wrong location
        if (currentLocation.item.id === filePickerItem.item.id) {
          ctx.setState(
            patch<FilePickerStatesModel>({
              pickers: patch<FilePickerStateModel>({
                [id]: patch({
                  itemIds: currentPickerState.itemIds.concat(
                    items
                      .map(item => item.id)
                      .filter(itemId => !currentPickerState.itemIds.includes(itemId))),
                  loading: false,
                  totalPages: page.totalPages,
                  totalItems: page.totalElements,
                  pageIdx: pageIdx
                })
              }),
              items: patch(itemsObject)
            })
          );

          if (ctx.getState().pickers[id].options?.showAuthors) {
            ctx.dispatch(new LoadFileAuthors(id));
          }
        }
      })
    );
  }

  private newState(): FilePickerStateModel {
    return {
      breadcrumbs: [],
      itemIds: [],
      loading: true,
      pageIdx: 0,
      totalPages: 0,
      totalItems: 0,
      renaming: null,
      options: null
    };
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {SuggestPagesWidget} from '@widgets/suggest-pages/suggest-pages-widget';
import {SuggestPagesWidgetSettingsComponent} from './suggest-pages-widget-settings/suggest-pages-widget-settings.component';
import {SuggestPagesWidgetComponent} from './suggest-pages-widget/suggest-pages-widget.component';

/**
 * The configuration for the suggest-pages widget.
 */
export const SUGGEST_PAGES_WIDGET: WidgetConfig<SuggestPagesWidget> = {
  key: 'suggestpages',
  name: 'WIDGETS.SUGGEST_PAGES.NAME',
  description: 'WIDGETS.SUGGEST_PAGES.DESCRIPTION',
  icon: 'zmdi-layers',
  component: SuggestPagesWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  defaultTitle: 'WIDGETS.SUGGEST_PAGES.NAME',
  settings: {
    component: SuggestPagesWidgetSettingsComponent,
    skipOnCreate: false
  },
  renderOptions: {
    printable: false
  },
  whitelistExternal: false
};

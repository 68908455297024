import {Component, forwardRef, Input, OnInit, Provider} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {v4 as uuid} from 'uuid';

const checkboxValueProvider: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

/**
 * Renders a styled checkbox that has configurable values for 'true' and 'false'. If not defined the default boolean
 * values are used.
 */
@Component({
  selector: 'coyo-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [checkboxValueProvider]
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {

  ngModel: any;
  private onChange: any;
  private onTouched: any;
  private _uniqueId: string = `coyo-checkbox-${uuid()}`;

  /**
   * Id that's mainly used to build reference between label and checkbox.
   * Defaults to a generated uuid, if no value is provided.
   */
  @Input() id: string = this._uniqueId;

  /**
   * Id to be used in HTML on the checkbox input and label element.
   */
  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  /**
   * Text which is displayed to the user.
   * This should be a translation key.
   */
  @Input() label: string | null = null;

  /**
   * Position of the label relative to the checkbox. Possible values are left or right.
   */
  @Input() labelPosition: 'left' | 'right' = 'right';

  /**
   * Text provided to screen readers when focusing the native input element.
   * This should be a translation key.
   */
  @Input() ariaLabel: string | null = null;

  /**
   * Text which is displayed next to the checkbox in muted color to provide more information to the user.
   * This should be a translation key.
   */
  @Input() hint: string | null = null;

  /**
   * Whether the checkbox is disabled.
   */
  @Input() disabled: boolean = false;

  /**
   * Whether the checkbox is required.
   */
  @Input() required: boolean = false;

  /**
   * Name value that gets applied to the input element.
   */
  @Input() name: string | null = null;

  /**
   * Tabindex of the checkbox. 0 should be fine in most cases.
   */
  @Input() tabIndex: number = 0;

  /**
   * The value that is used as the true value. Default is the boolean true.
   */
  @Input() trueValue: any = true;

  /**
   * The value that is used as the false value. Default is the boolean false.
   */
  @Input() falseValue: any = false;

  /**
   * The value for filepicker partially check condition. Default is boolean false.
   */
  @Input() partiallyChecked: boolean = false;

  constructor() {
  }

  /**
   * Toggles the checkbox value.
   */
  toggle(): void {
    this.ngModel = this.ngModel === this.trueValue ? this.falseValue : this.trueValue;

    if (this.onTouched) {
      this.onTouched();
    }

    if (this.onChange) {
      this.onChange(this.ngModel);
    }
  }

  /**
   * Returns the current state of the checkbox.
   *
   * @returns the checkbox state
   */
  get isChecked(): boolean {
    return this.ngModel === this.trueValue;
  }

  /**
   * Returns partially checked state. Usually used for parent checkboxes, when not all children are selected/deselected.
   *
   * @returns true when partiallyChecked.
   */
  get isPartiallyChecked(): boolean {
    return !this.isChecked && this.partiallyChecked;
  }

  /**
   * Triggered when the native checkbox element get clicked.
   */
  onInputClick(): void {
    this.toggle();
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(value: any): void {
    this.ngModel = value;
  }

  ngOnInit(): void {
    this.ariaLabel = this.ariaLabel || this.label;
  }
}

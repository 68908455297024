<div class="page-widget">
  <h3 class="widget-title">
    {{title || ("WIDGETS.SUGGEST_PAGES.NAME" | translate)}}
  </h3>
  <ng-container *ngIf="(state$ | async) as state">
    <div *ngIf="!state.hasPages && !state.loading" class="text-muted" translate="WIDGETS.SUGGEST_PAGES.NO_PAGE_FOUND"></div>
    <ul>
      <li *ngFor="let page of state.pages">
        <a mat-button [uiParams]="{ idOrSlug: page.id }" href uiSref="main.page.show" class="block-left">
          <mat-icon svgIcon="page"></mat-icon>
          <span>{{ page.name }}</span>
        </a>
      </li>
    </ul>
    <ng-container *ngIf="state.loading">
      <ui-skeleton *ngFor="let number of getNeededSkeletons()" [skeletons]="skeletons"></ui-skeleton>
    </ng-container>
  </ng-container>
</div>

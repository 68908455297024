import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {SelectionEntry} from '@app/filter/selection-filter/selection-entry';
import * as _ from 'lodash';

/**
 * Component for filter participation status on event
 */
@Component({
  selector: 'coyo-event-filter-participation-status',
  templateUrl: './event-filter-participation-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventFilterParticipationStatusComponent implements OnDestroy {

  /**
   * A given selection list.
   */
  @Input()
  filterModel: SelectionEntry[] = [];

  /**
   * Decides whether there can be only one option selected.
   */
  @Input()
  singleSelect: boolean = false;

  /**
   * The selection changes event with a selected status list
   */
  @Output()
  filterModelChange: EventEmitter<string[]> = new EventEmitter<[]>();

  constructor() {
  }

  ngOnDestroy(): void {
    this.filterModelChange.complete();
  }

  onFilterModelChange(selected: SelectionEntry[]): void {
    this.filterModelChange.emit(_.map(selected, 'key'));
  }
}

import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LightBoxComponent} from '@widgets/media/light-box/light-box.component';
import {MediaWidgetFile, MediaWidgetFileSettings} from '@widgets/media/media-widget-file';

/**
 * Service to open the lightbox
 */
@Injectable({
  providedIn: 'root'
})
export class LightBoxService {

  constructor(private dialog: MatDialog) { }

  openLightBox(currentIndex: number, mediaFiles: MediaWidgetFile[]): void {
    const mediaWidgetFileSettings: MediaWidgetFileSettings = {
      currentIndex: currentIndex,
      mediaFiles: mediaFiles
    };
    this.dialog.open<LightBoxComponent>(LightBoxComponent, {
      width: '1180px',
      height: '726px',
      panelClass: 'lightbox',
      hasBackdrop: true,
      data: mediaWidgetFileSettings
    });
  }
}

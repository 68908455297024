<a [attr.href]="$senderLink | async" [ngClass]="['sender-avatar', avatarSize, 'event-avatar']" [class.no-link]="noLink" *ngIf="sender">
  <coyo-event-date-overlay [date]="sender.startDate"
                           *ngIf="showOverlay"></coyo-event-date-overlay>
  <coyo-avatar-image *ngIf="!hasError"
                     (error)="hasError = true"
                     [imageUrl]="sender?.imageUrls?.avatar"
                     [avatarSize]="avatarSize"></coyo-avatar-image>
  <span class="sender-avatar-empty" *ngIf="!sender.imageUrls.avatar || hasError" [style.background-color]="sender.color">
    {{sender.displayNameInitials}}
  </span>
</a>

import {Injectable, NgZone} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EventSettingsParticipantsDialogData} from '@app/events/event-settings/event-settings-participants-dialog-data';
import {MatDialogSize} from '@coyo/ui';
import {EntityId} from '@domain/entity-id/entity-id';
import {UserChooserSelection} from '@shared/sender-ui/user-chooser/user-chooser-selection';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {EventSettingsDeleteConfirmationModalComponent} from './event-settings-delete-confirmation-modal/event-settings-delete-confirmation-modal.component';
import {EventSettingsInviteUsersModalComponent} from './event-settings-invite-users-modal/event-settings-invite-users-modal.component';
import {EventSettingsSelectAdminsModalComponent} from './event-settings-select-admins-modal/event-settings-select-admins-modal.component';

/**
 * Renders a modal with the user chooser as content.
 */
@Injectable({
  providedIn: 'root'
})
export class EventSettingsModalService {

  constructor(
    private ngZone: NgZone,
    private dialog: MatDialog
  ) {}

  /**
   * Opens a modal with the user chooser.
   * Used in angular.js context.
   *
   * @param entityId        Entity id of an existing sender
   * @param canInviteGroups Flag for the invite groups permission
   * @param canInvitePages Flag for the invite groups permission
   * @param canInviteWorkspaces Flag for the invite workspaces permission
   * @return Promise after http call finished. Content is a list of ids of the selected guests.
   */
  openUserSelection(entityId: EntityId, canInviteGroups: boolean, canInvitePages: boolean, canInviteWorkspaces: boolean): Promise<UserChooserSelection> {
    return this.ngZone.run(() => {
      const ref: MatDialogRef<EventSettingsInviteUsersModalComponent> = this.dialog
        .open<EventSettingsInviteUsersModalComponent, EventSettingsParticipantsDialogData>(EventSettingsInviteUsersModalComponent, {
          width: '80%',
          maxWidth: MatDialogSize.Large,
          data: {entityId, canInviteGroups, canInvitePages, canInviteWorkspaces},
          panelClass: 'event-settings-user-chooser-modal'
        });
      return ref.afterClosed().pipe(first()).toPromise();
    });
  }

  /**
   * Opens a modal with a user chooser to select admins.
   *
   * @param entityId Entity id of an existing sender
   * @return Observable with the selected admins.
   */
  openAdminSelection(entityId: EntityId): Observable<UserChooserSelection> {
    const ref: MatDialogRef<EventSettingsSelectAdminsModalComponent> = this.dialog
      .open<EventSettingsSelectAdminsModalComponent, EventSettingsParticipantsDialogData>(EventSettingsSelectAdminsModalComponent, {
        width: '80%',
        maxWidth: MatDialogSize.Large,
        data: {entityId, canInviteGroups: false, canInvitePages: false, canInviteWorkspaces: false},
        panelClass: 'event-settings-admin-chooser-modal'
      });
    return ref.afterClosed();
  }

  /**
   * Opens a modal for event deletion confirmation.
   *
   * @return Observable with the decision.
   */
  openDeleteEventConfirmation(): Observable<boolean> {
    const ref: MatDialogRef<EventSettingsDeleteConfirmationModalComponent> = this.dialog
      .open<EventSettingsDeleteConfirmationModalComponent, void>(EventSettingsDeleteConfirmationModalComponent, {
        maxWidth: MatDialogSize.Small,
        panelClass: 'event-settings-delete-event-confirmation-modal'
      });
    return ref.afterClosed();
  }
}

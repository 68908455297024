(function (angular) {
  'use strict';

  fileAuthorService.$inject = ["FileAuthorModel", "$q"];
  angular
      .module('commons.ui')
      .factory('fileAuthorService', fileAuthorService);

  function fileAuthorService(FileAuthorModel, $q) {

    return {
      loadFileAuthors: loadFileAuthors,
      loadVersionAuthors: loadVersionAuthors
    };

    /*
     * Loads the authors for the given file ID's
     *
     * @param {string} senderId The sender ID
     * @param {string} appIdOrSlug The app ID or slug
     * @param {array} fileIds The file ID's
     * @param {boolean} showAuthors indicates if author should be shown (from app settings)
     *
     * @returns promise
     */
    function loadFileAuthors(senderId, appIdOrSlug, fileIds, showAuthors) {
      var deferred = $q.defer();
      if (showAuthors && appIdOrSlug && fileIds.length) {
        deferred.resolve(FileAuthorModel.getFileAuthors(senderId, appIdOrSlug, fileIds));
      } else {
        deferred.resolve({});
      }
      return deferred.promise;
    }

    /*
     * Loads the authors for the given file ID's
     *
     * @param {string} senderId The sender ID
     * @param {array} fileId The file ID
     * @param {string} appIdOrSlug The app ID or slug
     * @param {boolean} showAuthors indicates if author should be shown (from app settings)
     * @param {boolean} canManageSender indicates if user is admin of the file app
     *
     * @returns promise
     */
    function loadVersionAuthors(senderId, fileId, appIdOrSlug, showAuthors, canManageSender) {
      var deferred = $q.defer();
      if ((canManageSender ? true : showAuthors) && appIdOrSlug) {
        deferred.resolve(FileAuthorModel.getVersionAuthors(senderId, appIdOrSlug, fileId));
      }
      return deferred.promise;
    }
  }
})(angular);

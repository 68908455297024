import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FloatingHeaderDirective} from '@shared/floating-modules/floating-header/floating-header.directive';

/**
 * Module exporting floating-header directive.
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FloatingHeaderDirective
  ],
  exports: [
    FloatingHeaderDirective
  ]
})
export class FloatingHeaderModule {}

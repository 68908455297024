<div [formGroup]="parentForm" mat-dialog-content>
  <div class="image-preview" *ngIf="(state$ | async)?.image as image">
    <div class="image-reference-container">
      <coyo-image-reference
        [fileId]="image.id"
        [senderId]="image.senderId"
        [sizeDefinitions]="{ default: 'XL' }"
      >
      </coyo-image-reference>
    </div>
  </div>

  <div [class.has-error]="isInvalid('_image')">
    <button
      mat-flat-button
      id="_image"
      name="_image"
      class="form-control"
      [attr.aria-label]="
        'WIDGET.IMAGE.SETTINGS.UPLOAD_IMAGE.ARIA_LABEL' | translate
      "
      (click)="selectImage()"
    >
      <mat-icon matPrefix svgIcon="upload"></mat-icon>
      <span *ngIf="!(state$ | async)?.image">{{
        "WIDGET.IMAGE.SETTINGS.UPLOAD_IMAGE_BUTTON_TEXT" | translate
      }}</span>
      <span *ngIf="(state$ | async)?.image">{{
        "WIDGET.IMAGE.SETTINGS.UPLOAD_NEW_IMAGE_BUTTON_TEXT" | translate
      }}</span>
    </button>
  </div>

  <coyo-divider></coyo-divider>

  <mat-form-field appearance="outline" [class.has-error]="isInvalid('_url')">
    <mat-label>{{ "WIDGET.IMAGE.SETTINGS.URL" | translate }}</mat-label>
    <input
      matInput
      type="url"
      name="_url"
      id="_url"
      formControlName="_url"
      placeholder="{{ 'WIDGET.IMAGE.SETTINGS.URL.PLACEHOLDER' | translate }}"
    />
    <mat-hint align="start">{{
      "WIDGET.IMAGE.SETTINGS.URL.HELP_TEXT" | translate
    }}</mat-hint>
  </mat-form-field>

  <mat-checkbox
    labelPosition="after"
    id="_linkTarget"
    name="_linkTarget"
    formControlName="_linkTarget"
    [aria-label]="'WIDGET.IMAGE.SETTINGS.TARGET'"
    >{{ "WIDGET.IMAGE.SETTINGS.TARGET" | translate }}
  </mat-checkbox>

  <div class="space-dummy"></div>
</div>

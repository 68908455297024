import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable, throwError} from 'rxjs';
import {UrlService} from '../url/url.service';

/**
 * Http interceptor that prepends the backendUrl to http requests.
 */
@Injectable()
export class BackendUrlInterceptor implements HttpInterceptor {

  constructor(private urlService: UrlService,
              private log: NGXLogger) {
  }

  /**
   * Prepends the backendUrl to the url of every http request to the '/web' path.
   * Throws an error if backendUrl is not set.
   *
   * @param req the request
   * @param next the next http handler in the chain
   * @return an Observable of the http event
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest = req;
    if (req.url.indexOf('/web') === 0) {
      if (!this.urlService.isBackendUrlSet()) {
        const error = 'Aborting XHR backend request to "' + req.url + '" because no backend URL is defined.';
        this.log.error(error);
        return throwError(error);
      } else {
        newRequest = newRequest.clone({
          url: this.urlService.join(this.urlService.getBackendUrl(), req.url),
          withCredentials: true
        });
      }
    }
    return next.handle(newRequest);
  }
}

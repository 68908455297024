import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {User} from '@domain/user/user';
import {createSelector} from '@ngxs/store';
import {FileDetailsDialogStatesModel} from '@shared/files/file-details-modal/state/file-details-dialog-states-model';
import {FileDetailsState} from '@shared/files/file-details-modal/state/file-details.state';

export class FileDetailsStateSelectors {
  static getFile(
    fileDetailsModalId: string,
    fileId: string
  ): (state: FileDetailsDialogStatesModel) => FilePreview | null {
    return createSelector([FileDetailsState], state => state[fileDetailsModalId].files[fileId]);
  }

  static getFileAuthor(
    fileDetailsModalId: string,
    fileId: string
  ): (state: FileDetailsDialogStatesModel) => User | null {
    return createSelector([FileDetailsState], state => state[fileDetailsModalId].authors[fileId]);
  }
}

import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {App} from '@apps/api/app';
import {AppComponent} from '@apps/api/app.component';
import {ContentAppSettings} from '@apps/content/content-app.settings';
import {AuthService} from '@core/auth/auth.service';
import {AppService} from '@domain/apps/app.service';
import {EntityId} from '@domain/entity-id/entity-id';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {UserService} from '@domain/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationDialogService} from '@shared/dialog/confirmation-dialog/confirmation-dialog.service';
import {LanguagePickerLanguage} from '@shared/multilanguage/language-picker/language';
import {Locale} from '@shared/multilanguage/locale';
import {StateService, Transition} from '@uirouter/core';
import { NG1_ROOTSCOPE} from '@upgrade/upgrade.module';
import {WidgetEditService} from '@widgets/api/widget-edit/widget-edit.service';
import {IScope} from 'angular';
import * as _ from 'lodash';
import {BehaviorSubject, EMPTY, Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, first, map, mergeMap, share, switchMap} from 'rxjs/operators';

/**
 * ContentAppComponent is a container app widget layouts
 */
@Component({
  templateUrl: './content-app.component.html',
  styleUrls: ['./content-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentAppComponent extends AppComponent<ContentAppSettings> implements OnInit, OnDestroy {

  /**
   * Edit mode on or off
   */
  @Input() editMode: boolean;

  /**
   * Sender input resolved in the state config
   */
  @Input() sender: Sender;
  languageSubject$: ReplaySubject<string>;
  currentLanguage$: Observable<string>;
  layoutLanguage$: Observable<string>;
  isDefaultLanguage$: Observable<boolean>;
  isSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentLocale$: Observable<Locale>;
  translations: string[];
  availableLanguages: string[];
  availableTranslations: LanguagePickerLanguage[];
  layoutName: string;
  locales: Locale[];
  parent: EntityId;

  constructor(
    private readonly transition: Transition,
    private readonly translateService: TranslateService,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly appService: AppService,
    private readonly userService: UserService,
    private readonly senderService: SenderService,
    private readonly authService: AuthService,
    private readonly stateService: StateService,
    private readonly widgetEditService: WidgetEditService,
    @Inject(NG1_ROOTSCOPE) private rootScoop: IScope) {
    super();
  }

  ngOnInit(): void {
    if (this.transition.params()['created']) {
      this.edit();
      return;
    }
    this.translations = this.collectTranslations();
    this.locales = this.generateLocales();
    this.availableLanguages = this.senderService.getAvailableLanguages(this.sender);
    this.languageSubject$ = new ReplaySubject<string>(1);
    this.currentLanguage$ = this.languageSubject$.pipe(distinctUntilChanged());
    this.layoutLanguage$ = this.selectLayoutLanguage$();
    this.currentLocale$ = this.currentLanguage$.pipe(map(language => Locale.fromLanguage(language)));
    this.isDefaultLanguage$ = this.layoutLanguage$.pipe(map(language => !!this.filterTranslationLanguages(language)));
    this.parent = {id: this.app.id, typeName: 'app'};
    this.layoutName = `app-content-${this.app.id}`;
    this.getCurrentLanguage();
    this.updateAvailableTranslations();
    if (this.editMode) {
      this.enableEditMode();
    }
  }

  ngOnDestroy(): void {
    this.widgetEditService.cancelEdit(this.app.id);
    this.isSaving$.complete();
  }

  localeSwitched(locale: Locale): void {
    this.languageSubject$.next(locale.language.toUpperCase());
  }

  languageSwitched(language: string): void {
    const translation = this.getTranslation(language);
    const defaultTranslation = this.getTranslation(this.sender.defaultLanguage);
    if (this.filterLayoutLanguage(language)) {
      this.switchToLanguage(defaultTranslation).subscribe({complete: () => this.switchToLanguage(translation)});
    } else {
      this.switchToLanguage(translation);
    }
  }

  edit(): void {
    this.stateService.go('^.edit');
  }

  save(): void {
    if (this.editMode) {
      this.isSaving$.next(true);
      this.updateTranslationSettings()
        .pipe(mergeMap(() => this.widgetEditService.save(this.app.id))).subscribe({
          error: () => {
            this.isSaving$.next(false);
          },
          complete: () => {
            this.rootScoop.$emit('app:updated', this.app, true);
            this.stateService.go('^.content');
            this.isSaving$.next(false);
          }
        });
    }
  }

  delete(language: string): void {
    if (this.editMode && this.filterLayoutLanguage(language) !== null) {
      this.getTranslation(language).valid = true;
      this.widgetEditService.markLayoutLanguageForDeletion(this.app.id, this.layoutName, language)
        .subscribe(
          {
            complete: () => {
              this.app.settings._translatedContent = this.app.settings._translatedContent?.filter(elem => elem !== language.toUpperCase());
              this.translations = this.collectTranslations();
              this.availableLanguages = this.senderService.getAvailableLanguages(this.sender);
              this.updateAvailableTranslations();
              this.switchToLanguage(this.getTranslation(this.sender.defaultLanguage));
            }
          }
        );
    }
  }

  cancel(): void {
    this.widgetEditService.cancelEdit(this.app.id)
      .pipe(first())
      .subscribe(value => {
        this.stateService.go('^.content');
      });
  }

  private switchToLanguage(translation: LanguagePickerLanguage): Observable<void> {
    const language = translation.language;
    if (!translation.created) {
      const action$ = this.confirmationDialogService.open(
        'COMMONS.TRANSLATIONS.APPLY.MODAL.TITLE',
        'COMMONS.TRANSLATIONS.APPLY.MODAL.TEXT',
        {language: this.translateService.instant(`LANGUAGE.LANGUAGES.${language}`)}
      ).pipe(switchMap(result => result ?
        this.widgetEditService
          .duplicateLayoutForLanguage(this.app.id, this.layoutName, language) :
        this.widgetEditService
          .initializeEmptyLayoutForLanguage(this.app.id, this.layoutName, language, this.parent)
      ), share());
      action$.subscribe({
        complete: () => {
          translation.created = true;
          translation.valid = true;
          this.languageSubject$.next(language);
        }
      });
      return action$;
    } else {
      this.languageSubject$.next(language);
      return EMPTY;
    }
  }

  private updateTranslationSettings(): Observable<App<ContentAppSettings>> {
    this.app.settings._translatedContent = this.availableTranslations.filter(l => l.created).map(l => l.language.toUpperCase());
    return this.appService.updateApp(this.sender.id, this.app);
  }

  private selectLayoutLanguage$(): Observable<string> {
    return this.currentLanguage$.pipe(map(this.filterLayoutLanguage.bind(this)));
  }

  private getCurrentLanguage(): void {
    this.authService.getUser().pipe(
      switchMap(currentUser => this.userService.getBestSuitableLanguage(currentUser, this.translations)),
      map(language => language ?? this.sender.defaultLanguage))
      .subscribe(language => {
          this.languageSubject$.next(language ?? null);
        }
      );
  }

  private generateLocales(): Locale[] {
    return this.translations.filter(translation => translation).map(translation => Locale.fromLanguage(translation));
  }

  private collectTranslations(): string[] {
    return _.uniq([
      this.sender.defaultLanguage,
      ...(this.app.settings?._translatedContent ?? [])
        .filter(this.filterTranslationLanguages.bind(this))
    ].filter(translation => translation).map(translation => translation.toUpperCase()));
  }

  private enableEditMode(): void {
    this.widgetEditService.enableEditMode(this.app.id);
  }

  private updateAvailableTranslations(): void {
    this.availableTranslations = this.availableLanguages.map(language => {
      const isLanguageTranslated = this.translations.indexOf(language) !== -1;
      return ({
        language,
        valid: isLanguageTranslated,
        created: isLanguageTranslated,
      });
    });
  }

  private filterTranslationLanguages(language: string): boolean {
    return language && language !== this.sender.defaultLanguage && language !== 'NONE';
  }

  private filterLayoutLanguage(language: string | null): string | null {
    return language === this.sender?.defaultLanguage ? null : language;
  }

  private getTranslation(language: string): LanguagePickerLanguage {
    return this.availableTranslations.filter(t => t.language === language)[0];
  }
}

(function (angular) {
  'use strict';

  FileDetailsModalController.$inject = ["$document", "$q", "$rootScope", "$scope", "$filter", "FileModel", "SenderModel", "CapabilitiesModel", "$timeout", "backendUrlService", "coyoEndpoints", "externalFileHandlerService"];
  angular
      .module('commons.ui')
      .component('coyoLegacyFileDetailsModal', fileDetailsModal())
      .controller('FileDetailsModalController', FileDetailsModalController);

  function fileDetailsModal() {
    return {
      templateUrl: 'app/commons/ui/components/file-details/file-details-modal.html',
      bindings: {
        files: '<',
        currentIndex: '<',
        linkToFileLibrary: '<',
        showAuthors: '<',
        fileAuthors: '<',
        appIdOrSlug: '<',
        filesTotal: '<',
        loadNext: '&',
        currentUser: '<',
        close: '&',
        fileDetailsDialogId: '<'
      },
      controller: 'FileDetailsModalController'
    };
  }

  /**
   * @ngdoc controller
   * @name commons.ui.ManageTranslationsModalController
   *
   * @description
   * The controller of the manage translation modal.
   *
   * @requires $document
   * @requires $q
   * @requires $rootScope
   * @requires $filter
   * @requires $scope
   * @requires $uibModalInstance
   * @requires $timeout
   * @requires FileModel
   * @requires SenderModel
   * @requires CapabilitiesModel
   * @requires backendUrlService
   * @requires coyoEndpoints
   * @requires externalFileHandlerService
   */
  function FileDetailsModalController($document, $q, $rootScope, $scope, $filter, FileModel,
                                      SenderModel, CapabilitiesModel, $timeout, backendUrlService,
                                      coyoEndpoints, externalFileHandlerService) {
    var vm = this;
    vm.$onInit = onInit;
    vm.next = next;
    vm.previous = previous;
    vm.hasPrevious = hasPrevious;
    vm.hasNext = hasNext;
    vm.updateFile = updateFile;
    vm.allowPDFPreview = allowPDFPreview;
    vm.closeModal = closeModal;

    /**
     * Rerenders the current file's details with loading information again from the backend.
     * Remembers that this file was updated so that this information can be passed on when the modal closes.
     */
    function updateFile() {
      _setCurrentFile(vm.file, true).then(function (result) {
        vm.updated.push(result);
      });
    }

    function hasNext() {
      return vm.currentIndex < vm.filesTotal - 1;
    }

    /**
     * Display next image from files array if there is one.
     */
    function next() {
      if (hasNext()) {
        vm.currentIndex++;
        if (vm.currentIndex >= vm.files.length) {
          vm.loadNext(vm.currentIndex).then(function (result) {
            vm.files.push(result.file);
            if (vm.fileAuthors && result.author) {
              vm.fileAuthors[result.file.id] = result.author;
            }
            _setCurrentFile(vm.files[vm.currentIndex]);
          });
        } else {
          _setCurrentFile(vm.files[vm.currentIndex]);
        }
      }
    }

    function hasPrevious() {
      return vm.currentIndex > 0;
    }

    /**
     * Display previous image from files array if there is one.
     */
    function previous() {
      if (hasPrevious()) {
        vm.currentIndex--;
        _setCurrentFile(vm.files[vm.currentIndex]);
      }
    }

    function allowPDFPreview() {
      vm.viewPdfFullScreen = true;
      vm.previewOptions = Object.assign({}, vm.previewOptions, {showPdfMobile: true});
      vm.showPDFLoadingHint = false;
    }

    function disallowPdfPreview() {
      vm.previewOptions = Object.assign({}, vm.previewOptions, {showPdfMobile: false});
    }

    function _setCurrentFile(file, forceReload) {
      delete vm.file;
      vm.externalFile = file && externalFileHandlerService.isExternalFile(file);
      disallowPdfPreview();

      if (!forceReload && _isAllFileDataAvailable(file) || vm.externalFile) {
        if (!vm.externalFile) {
          vm.file = file;
          vm.file.type = $filter('fileTypeName')(vm.file.contentType);
          vm.previewUrl = file.previewUrl || coyoEndpoints.sender.preview;
          _determinePdfHintDisplay(vm.file);
          vm.downloadUrl = _getDownloadUrl(file);
          return $q.resolve(vm.file);
        } else {
          return _populateExternalFileInformation(file, forceReload || vm.externalFile);
        }
      } else {
        var getFileWithPermissionsPromise = FileModel.getWithPermissions({senderId: file.senderId, id: file.id}, {},
            ['*']);
        var getSenderWithPermissionsPromise = SenderModel.getWithPermissions(file.senderId, {},
            ['manage', 'createFile']);
        return $q.all([getFileWithPermissionsPromise, getSenderWithPermissionsPromise]).then(function (result) {
          vm.file = result[0];
          vm.file.sender = result[1];
          vm.file.type = $filter('fileTypeName')(vm.file.contentType);
          vm.previewUrl = vm.file.previewUrl || coyoEndpoints.sender.preview;
          vm.files[vm.currentIndex] = angular.extend(vm.files[vm.currentIndex], vm.file);
          _determinePdfHintDisplay(vm.file);
          return vm.file;
        });
      }
    }

    function _getDownloadUrl(file) {
      return backendUrlService.getUrl()
          + vm.previewUrl.replace('{{groupId}}', file.groupId || file.senderId).replace('{{id}}', file.id);
    }

    function _isAllFileDataAvailable(file) {
      return file.attachment || (file._permissions && file.contentType && file.sender);
    }

    function _bindKeyDown() {
      $document.on('keydown', _onKeyDown);
    }

    function _unbindKeyDown() {
      $document.off('keydown', _onKeyDown);
    }

    function closeModal() {
      vm.close(vm.updated);
    }

    function _onKeyDown(event) {
      if (event.target.type && event.target.type.includes('text') && event.target.value && event.which !== 27) {
        return;
      }
      if (event.which === 37) {
        $scope.$apply(function () {
          event.preventDefault();
          previous();
        });
      } else if (event.which === 39) {
        $scope.$apply(function () {
          event.preventDefault();
          next();
        });
      } else if (event.which === 27) {
        $scope.$apply(function () {
          event.preventDefault();
          closeModal();
        });
      }
    }

    function _isPdfFile(file) {
      return file.contentType === 'application/pdf';
    }

    function _isImageFile(file) {
      return file.contentType.startsWith('image');
    }

    function _populateExternalFileInformation(attachment, forceReload) {
      if (!attachment.externalFileUrl || forceReload) {
        attachment.externalFileUrlLoading = true;
        return externalFileHandlerService
            .getExternalFileDetails(attachment)
            .then(function (fileDetails) {
              attachment.externalFileUrl = fileDetails.externalUrl;
              attachment.length = fileDetails.fileSize;
              attachment.canEdit = fileDetails.canEdit;
              if (fileDetails.exportLinks) {
                attachment.exportLinks = fileDetails.exportLinks;
                attachment.previewUrl = fileDetails.previewUrl;
              } else if (_isImageFile(attachment) || _isPdfFile(attachment)) {
                attachment.previewUrl = fileDetails.previewUrl;
              }
              vm.previewUrl = attachment.previewUrl;
              vm.file = attachment;
              vm.file.type = $filter('fileTypeName')(attachment.contentType);
              _determinePdfHintDisplay(attachment);
              return attachment;
            })
            .catch(function (e) {
              if (e.status === 404) {
                attachment.length = undefined;
                vm.fileErrors[attachment.id] = 'FILE_DETAILS.EXTERNAL_FILE_NOT_AVAILABLE';
              }
              vm.file = attachment;
              return attachment;
            })
            .finally(function () {
              delete attachment.externalFileUrlLoading;
              $timeout(function () {
                $scope.$digest();
              });
            });
      } else {
        return $q.reject();
      }
    }

    function _determinePdfHintDisplay(file) {
      var isMobile = $rootScope.screenSize.isXs || $rootScope.screenSize.isSm;
      var isExternalPdf = _isPdfFile(file) && vm.externalFile;
      if (!isMobile || !vm.previewUrl || isExternalPdf) {
        vm.showPDFLoadingHint = false;
        return;
      }

      var previewContentType = _setContentTypeToPdfIfGoogleNativeFile(file.contentType);
      CapabilitiesModel.pdfAvailable(previewContentType).then(function (available) {
        vm.showPDFLoadingHint = available;
      });
    }

    /**
     * This method is necessary in order to handle Google native file types like docs, spreadsheets and presentation
     * like PDF's, so that they are rendered by our PDF viewer.
     *
     * @param {string} contentType
     * @return {string} contentType
     * @private
     */
    function _setContentTypeToPdfIfGoogleNativeFile(contentType) {
      return externalFileHandlerService.isGoogleMediaType(contentType) ? 'application/pdf' : contentType;
    }

    function onInit() {
      vm.updated = [];
      vm.viewPdfFullScreen = false;
      vm.previewOptions = {
        showPdfDesktop: true,
        allowGifPlayback: false,
        showPdfMobile: false
      };
      vm.fileErrors = {};

      if (angular.isArray(vm.files)) {
        vm.files = angular.copy(vm.files);
      } else {
        vm.files = [vm.files];
      }

      vm.filesTotal = (vm.filesTotal && (vm.filesTotal > vm.files.length) && vm.loadNext) ? vm.filesTotal : vm.files.length;
      vm.currentIndex = vm.currentIndex || 0;

      $timeout(function () {
        _setCurrentFile(vm.files[vm.currentIndex]);
      });
      _bindKeyDown();

      $scope.$on('$destroy', _unbindKeyDown);
    }
  }
})(angular);

import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {MentionDetails} from '@domain/mention/mention-details';
import {MentionService} from '@domain/mention/mention.service';
import {Subscription} from 'rxjs';

/**
 * A pipe to asynchronously resolve mentions in a given text.
 */
@Pipe({
  name: 'mention',
  pure: false
})
export class MentionPipe implements PipeTransform, OnDestroy {
  private static readonly REGEXP: RegExp = /(^|[^-0-9a-zA-Z])@([-0-9a-zA-Z]+)/g;
  private text: string | null = null;
  private subscription: Subscription | null = null;
  private latestValue: string = null;
  private latestReturnedValue: string = null;

  constructor(
    private cd: ChangeDetectorRef,
    private mentionService: MentionService) {
  }

  /**
   * Resolves the given mentions and replaces their occurrences in the given text.
   *
   * @param text the source text containing mentions
   * @return the HTML-enriched result
   */
  transform(text: string): any {
    if (!this.text) {
      this.text = text;

      const mentions = this.extract(text);
      if (mentions.length) {
        this.subscription = this.mentionService.getDetails(...mentions).subscribe(details => {
          this.latestValue = this.compile(text, details);
          this.cd.markForCheck();
        });
      }

      this.latestValue = this.text;
      this.latestReturnedValue = this.latestValue;
      return this.latestValue;
    }

    if (text !== this.text) {
      this.dispose();
      return this.transform(text);
    }

    if (this.latestValue === this.latestReturnedValue) {
      return this.latestReturnedValue;
    }

    this.latestReturnedValue = this.latestValue;
    return this.latestValue;
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  private extract(text: string): string[] {
    const buffer: Set<string> = new Set();
    let match = MentionPipe.REGEXP.exec(text);
    while (match != null) {
      buffer.add(match[2]);
      match = MentionPipe.REGEXP.exec(text);
    }
    return Array.from(buffer);
  }

  private compile(text: string, details: { [slug: string]: MentionDetails }): string {
    return text.replace(MentionPipe.REGEXP, (mention, prefix, slug) => {
      const detail = details[slug];
      return detail ? `${prefix}<a href="${detail.link}">${detail.name}</a>` : mention;
    });
  }

  private dispose(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    this.text = null;
    this.latestValue = null;
    this.latestReturnedValue = null;
  }
}

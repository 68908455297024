import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Widget} from '@domain/widget/widget';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {Observable, Subject} from 'rxjs';

/**
 * A component that renders the settings form of a specific widget.
 */
@Component({
  template: ''
})
export abstract class WidgetSettingsComponent<WidgetType extends Widget<WidgetSettings>> {

  /**
   * The corresponding widget this settings component belongs to.
   */
  @Input() widget: WidgetType;

  /**
   * The parent settings form component.
   */
  @Input() parentForm: FormGroup;

  /**
   * The callback for the submit action.
   */
  @Input() onSubmit: Subject<any>;

  /**
   * Called when the submit button is pressed. Can be used to alter the value that is going to be saved.
   * Returning an error observable will result in the submit action being skipped.
   * @param settings the current settings before save
   * @returns an observable that emits the new updated values
   */
  onBeforeSave(settings?: any): Observable<any> {
    return null;
  }

  /**
   * Checks if the control with the given name is invalid.
   *
   * @param controlName the name of the control
   * @returns true if the control is valid, false otherwise
   */
  isInvalid(controlName: string): boolean {
    const control = this.parentForm.controls[controlName];
    return control && !control.valid && control.dirty;
  }
}

(function (angular) {
  'use strict';

  MessagingNavbarItemController.$inject = ["$scope", "$timeout", "$rootScope", "authService", "sidebarService", "socketService", "socketReconnectDelays", "MessageModel"];
  angular
      .module('coyo.messaging')
      .controller('MessagingNavbarItemController', MessagingNavbarItemController);

  function MessagingNavbarItemController($scope, $timeout, $rootScope, authService, sidebarService, socketService,
                                         socketReconnectDelays, MessageModel) {
    var vm = this;
    vm.$onInit = onInit;

    vm.openMessagingSidebar = openMessagingSidebar;

    function openMessagingSidebar() {
      sidebarService.open('messaging');
    }

    function _handleChannelStatusUpdated(event) {
      $scope.$apply(function () {
        vm.unreadCount += _.get(event, 'content.unreadCount', 0) - _.get(event, 'content.oldUnreadCount', 0);
      });
    }

    function _resetUnreadCount() {
      vm.unreadCount = 0;
    }

    function _reconnectHandler(currentUser) {
      $timeout(function () {
        MessageModel.getUnreadCount(currentUser.id).then(function (result) {
          vm.unreadCount = result.data;
        });
      }, socketReconnectDelays.UNREAD_MESSAGE_COUNT_RELOAD_DELAY);
    }

    function onInit() {
      // Load unread count initially and then subscribe to changes
      var unsubscribeStatusUpdatedFn, unsubscribeReconnectFn, unsubscribeResetUnreadCountFn;
      authService.onGlobalPermissions('USE_MESSAGING', function (hasPermission, currentUser) {
        if (hasPermission) {
          MessageModel.getUnreadCount(currentUser.id).then(function (result) {
            vm.unreadCount = result.data;
            unsubscribeStatusUpdatedFn =
                socketService.subscribe('/user/topic/messaging', _handleChannelStatusUpdated, 'channelStatusUpdated');
          });
          unsubscribeResetUnreadCountFn = $rootScope.$on('messaging:resetUnreadCount', function () {
            _resetUnreadCount();
          });
          unsubscribeReconnectFn = $rootScope.$on('socketService:reconnected', function () {
            _reconnectHandler(currentUser);
          });
        } else if (unsubscribeStatusUpdatedFn) {
          unsubscribeStatusUpdatedFn();
        }
      });

      $scope.$on('$destroy', function () {
        if (unsubscribeStatusUpdatedFn) {
          unsubscribeStatusUpdatedFn();
        }
        if (unsubscribeResetUnreadCountFn) {
          unsubscribeResetUnreadCountFn();
        }
        if (unsubscribeReconnectFn) {
          unsubscribeReconnectFn();
        }
      });
    }
  }

})(angular);

import {Pipe, PipeTransform} from '@angular/core';

/**
 * Provides the String.prototype.replace() method as a pipe. Fails gracefully
 * to an empty string if the input is nullish.
 */
@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, searchValue: string | RegExp, replaceValue: string): string {
    return value ? value.replace(searchValue, replaceValue) : '';
  }
}

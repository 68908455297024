import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Skeleton} from '@coyo/ui';

/**
 * Skeleton component for notification items
 */
@Component({
  selector: 'coyo-notification-item-skeleton',
  template: '<ui-skeleton [skeletons]="skeletons[className]"></ui-skeleton>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemSkeletonComponent {
  /**
   * Defines which skeleton to load.
   */
  @Input() className: string;

  skeletons: {[key: string]: Skeleton[]} = {
    discussion: [
      { top: 20, left: 20, width: 40, height: 40, radius: '50%' },
      { top: 20, left: 80, width: '70%', height: 15 },
      { top: 40, left: 80, width: '60%', height: 15 },
      { top: 65, left: 80, width: '65%', height: 60 },
      { top: 135, left: 80, width: '25%', height: 10 }
    ],
    activity: [
      { top: 20, left: 20, width: 30, height: 30, radius: '50%' },
      { top: 20, left: 60, width: '70%', height: 15 },
      { top: 40, left: 60, width: '60%', height: 15 },
      { top: 60, left: 60, width: '25%', height: 10 }
    ]
  };
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@core/auth/auth.service';
import {User} from '@domain/user/user';
import {UserService} from '@domain/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {combineLatest, from, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * A form element to change the current user's first or last name.
 */
@Component({
  selector: 'coyo-settings-name',
  templateUrl: './settings-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsNameComponent implements OnInit {
  readonly MAX_LENGTH: number = 250;
  private currentValue: string;

  form: FormGroup;
  request$: Observable<User>;

  /**
   * The field name to be edited.
   */
  @Input() fieldName: 'firstName' | 'lastName';

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private permissionsService: NgxPermissionsService,
              private translateService: TranslateService,
              private notificationService: NotificationService) {
  }

  get propertyName(): string {
    return this.fieldName.toLocaleLowerCase();
  }

  ngOnInit(): void {
    const field = [{value: '', disabled: true}, [CoyoValidators.notBlank, Validators.maxLength(this.MAX_LENGTH)]];
    this.form = this.formBuilder.group(this.fieldName === 'firstName' ? {firstName: field} : {lastName: field});

    combineLatest([
      this.authService.getUser(),
      from(this.permissionsService.hasPermission('MANAGE_ACCOUNT_SETTINGS'))
    ]).subscribe(([user, canManage]) => {
      this.updateUser(user);
      this.setDisabled(!canManage || user.remoteUser);
    });
  }

  submit(): void {
    const value = this.form.get(this.fieldName).value;
    if (value !== this.currentValue && this.form.valid) {
      this.request$ = this.save(value)
        .pipe(tap(user => this.updateUser(user)))
        .pipe(tap(user => this.notificationService.success(this.translateService.instant('SETTINGS.ACCOUNT.NAME.SUCCESS', {name: user.displayName}))));
    }
  }

  private save(value: string): Observable<User> {
    return this.fieldName === 'firstName'
      ? this.userService.setFirstName(value)
      : this.userService.setLastName(value);
  }

  private updateUser(user: User): void {
    this.currentValue = user[this.propertyName];
    this.form.patchValue(this.fieldName === 'firstName'
      ? {firstName: user[this.propertyName]}
      : {lastName: user[this.propertyName]});
  }

  private setDisabled(disabled: boolean): void {
    return disabled
      ? this.form.controls[this.fieldName].disable()
      : this.form.controls[this.fieldName].enable();
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

/**
 * Represents a feature state as returned by the backend.
 */
interface FeatureState {
  name: string;
  categories?: string[];
  available: boolean;
  states: { [id: string]: boolean };
}

/**
 * Response for feature states from the backend.
 */
interface FeatureStateResponse {
  [id: string]: FeatureState;
}

/**
 * Service to check if a certain feature is enabled based on the backend response.
 */
@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  private featureStatesRequest: Observable<FeatureStateResponse>;

  constructor(private http: HttpClient) {
  }

  /**
   * Returns a feature state for the given feature id.
   *
   * @param id The ID of the feature to check.
   * @returns The feature state.
   */
  getFeatureState(id: string): Observable<FeatureState> {
    this.loadStates();
    return this.featureStatesRequest.pipe(map(states => states[id] || {
      name: id,
      available: false,
      states: {}
    }));
  }

  /**
   * Reload the feature toggle states.
   */
  reload(): void {
    this.featureStatesRequest = null;
    this.loadStates();
  }

  /**
   * Returns if the given feature is available.
   *
   * @param id The ID of the feature to check
   * @returns True if available
   */
  isFeatureAvailable(id: string): Observable<boolean> {
    return this.getFeatureState(id).pipe(map(state => state.available));
  }

  /**
   * Returns a set of features that are available and belong to the given category.
   *
   * @param category The category to filter for
   * @returns Array of feature names that are available.
   */
  getAvailableFeatures(category: string): Observable<string[]> {
    this.loadStates();
    return this.featureStatesRequest.pipe(
      map(featureStateMap => Object.values(featureStateMap)),
      map(featureStates => featureStates.filter(state => state.available)),
      map(featureStates => this.filterValidFeatureStates(featureStates, category)),
      map(featureStates => featureStates.map(fs => fs.name))
    );
  }

  /**
   * Returns a set of features that belong to the given category.
   *
   * @param category The category to filter for
   * @returns Array of feature names that are in the given category.
   */
  getCategoryFeatures(category: string): Observable<string[]> {
    this.loadStates();
    return this.featureStatesRequest.pipe(
      map(featureStateMap => Object.values(featureStateMap)),
      map(featureStates => this.filterValidFeatureStates(featureStates, category)),
      map(featureStates => featureStates.map(fs => fs.name))
    );
  }

  /**
   * Sets the enabled state for a toggleable feature
   *
   * @param feature The name of the feature
   * @param enabled The desired enabled state of the feature
   *
   * @returns An observable that completes when the request is completed
   */
  setFeatureEnabled(feature: string, enabled: boolean): Observable<void> {
    const options = {
      params: {
        feature,
        enabled: enabled.toString()
      }
    };
    return this.http.put<void>('/web/feature', null, options).pipe(
      map(
        () => this.reload()
      ));
  }

  private loadStates(): void {
    if (!this.featureStatesRequest) {
      this.featureStatesRequest = this.http.get<FeatureStateResponse>('/web/features').pipe(shareReplay({
        bufferSize: 1,
        refCount: false
      }));
    }
  }

  private filterValidFeatureStates(featureStates: FeatureState[], category: string): FeatureState[] {
    return featureStates.filter(featureState => this.isValidFeatureState(featureState, category));
  }

  private isValidFeatureState(featureState: FeatureState, category: string): boolean {
    return featureState.categories &&
      featureState.categories.indexOf(category) >= 0;
  }
}

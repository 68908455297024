import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * An internal directive to display a message of a failed validation check.
 */
@Component({
  selector: 'coyo-field-errors-message',
  templateUrl: './field-errors-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldErrorsMessageComponent {

  /**
   * The key of a failed validation check.
   */
  @Input() key: string;

  /**
   * The value of a failed validation check.
   */
  @Input() value: any;
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'coyo-page-visibility',
  templateUrl: './page-visibility.component.html',
  styleUrls: ['./page-visibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageVisibilityComponent {

  /**
   * The visibility state.
   */
  @Input() visibility: string;
}

import {NgModule} from '@angular/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import './ngx.cropper.component.downgrade';

/**
 * Module providing the image crop component to COYO
 */
@NgModule({
  imports: [
    ImageCropperModule
  ]
})
export class ImageCropModule {
}

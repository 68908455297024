import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {HashtagWidget} from '@widgets/hashtag/hashtag-widget';
import * as _ from 'lodash';

/**
 * The hashtag widget's settings component.
 *
 * This component is used only in the context of the widget settings modal dialog.
 */
@Component({
  templateUrl: './hashtag-widget-settings.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HashtagWidgetSettingsComponent extends WidgetSettingsComponent<HashtagWidget> implements OnInit {

  items: {
    id: string;
    text: string;
  }[] = [
    {id: 'ONE_DAY', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.ONE_DAY'},
    {id: 'ONE_WEEK', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.ONE_WEEK'},
    {id: 'TWO_WEEKS', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.TWO_WEEKS'},
    {id: 'ONE_MONTH', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.ONE_MONTH'},
    {id: 'TWO_MONTHS', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.TWO_MONTHS'},
    {id: 'THREE_MONTHS', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.THREE_MONTHS'},
    {id: 'SIX_MONTHS', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.SIX_MONTHS'},
    {id: 'ONE_YEAR', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.ONE_YEAR'},
    {id: '', text: 'WIDGET.HASHTAG.SETTINGS.PERIOD.UNLIMITED'}
  ];

  translatedItems: any[] = [];
  initialItem: any = {};

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.translateService.get(_.map(this.items, 'text')).subscribe(data => {
      this.translatedItems = _.map(this.items, i => ({id: i.id, text: data[i.text]}));
    });
    this.initialItem = this.widget.settings._period || this.translatedItems[3];
    this.parentForm.addControl('_period', new FormControl(this.initialItem));
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {TimelineItemService} from '@domain/timeline-item/timeline-item.service';
import {User} from '@domain/user/user';
import {Observable, of} from 'rxjs';
import {Share} from './share';
import {Shareable} from './shareable';

/**
 * Service for all request methods to the share domain.
 */
@Injectable({
  providedIn: 'root'
})
export class ShareService extends DomainService<Share, Share> {

  constructor(protected http: HttpClient,
              protected urlService: UrlService,
              private timelineItemService: TimelineItemService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/shares';
  }

  /**
   * Gets all shares for a target (e.g. a timeline item).
   *
   * @param typeName the type name.
   * @param targetId the target id.
   * @return the requested shares.
   */
  getShares(typeName: string, targetId: string): Observable<Share[]> {
    const url = this.getUrl({typeName, targetId}, '/{typeName}/{targetId}');
    return this.http.get<Share[]>(url, {params: {_permissions: '*'}});
  }

  /**
   * Returns the original author of the share.
   *
   * @param shareId the id of the share.
   * @return the author.
   */
  getOriginalAuthor(shareId: string): Observable<User> {
    return this.http.get<User>(this.getUrl({id: shareId}, '/{id}/originalauthor'));
  }

  /**
   * Gets the share count of the given target
   *
   * @param target the target
   * @return an observable with the number of shares
   */
  getShareCount(target: Shareable): Observable<number> {
    if (target.shares) {
      return of(target.shares.length);
    } else if (target.itemId) {
      return this.timelineItemService.getShareCount(target.itemId);
    } else {
      return of(0);
    }
  }
}

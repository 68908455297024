import {Injectable} from '@angular/core';
import {Order} from '@domain/pagination/order';
import {Pageable} from '@domain/pagination/pageable';
import {Search} from '@domain/pagination/search';

/**
 * A util service to create `Pageable` and `Search` instances in a downgradable manner
 * so that we can use it in AngularJS contexts.
 *
 * Note: This should not be used in Angular 2+ contexts.
 */
@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  /**
   * Creates a new `Pageable` instance.
   *
   * @param page the page number
   * @param pageSize the page size
   * @param offset the offset
   * @param sort the sorting
   * @return a pageable instance
   */
  pageable(page: number = 0,
           pageSize: number = 20,
           offset: number = null,
           ...sort: Order[]): Pageable {
    return new Pageable(page, pageSize, offset, ...sort);
  }

  /**
   * Creates a new `Search` instance.
   *
   * @param term the search term
   * @param filters a list of filters
   * @param aggregations a list aggregations
   * @param searchFields a list of search fields
   * @param newSearch boolean flag to indicate a new search
   * @return a search instance
   */
  search(term: string = '',
         filters: { [key: string]: string[]; } = {},
         aggregations: { [key: string]: number; } = {},
         searchFields: string[] = [],
         newSearch: boolean = true): Search {
    return new Search(term, filters, aggregations, searchFields, newSearch);
  }
}

<div class="header">
  <h2 class="launchpad-category-header">{{category.id != 'personal' ? category.name : ('LAUNCHPAD.CATEGORY.PERSONAL' | translate)}}</h2>
  <button mat-flat-button type="button" size="small" class="btn-add-link"
          *ngIf="!category.links.length" (click)="add.emit()">
    {{'LAUNCHPAD.CATEGORY.ADD_LINK' | translate}}
  </button>
</div>
<ul *ngIf="category.links.length">
  <li *ngFor="let link of category.links">
    <coyo-launchpad-link [link]="link"
                         [color]="category | launchpadCategoryColor"
                         [canManage]="category._permissions?.manage"
                         [attr.data-id]="link.id"
                         (edit)="edit.emit(link)"
                         (delete)="delete.emit(link)"></coyo-launchpad-link>
  </li>
</ul>
<p class="empty" *ngIf="category.id !== 'personal' && !category.links.length">
  <strong [translate]="'LAUNCHPAD.CATEGORY.EMPTY.HINT'"></strong>&nbsp;{{'LAUNCHPAD.CATEGORY.EMPTY.MESSAGE' | translate}}
</p>

import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Provider,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgSelectComponent} from '@ng-select/ng-select';
import {Store} from '@ngxs/store';
import {SelectUiSettings} from '@shared/select-ui/select-ui.settings';
import {Destroy, Load, LoadMore} from '@shared/select-ui/state/select-ui-component.actions';
import {SelectUiComponentStateModel} from '@shared/select-ui/state/select-ui-component.state';
import {UuidService} from '@shared/uuid/uuid.service';
import {Observable, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

const selectUiValueProvider: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectUiComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

/**
 * Base-component for select components
 *
 * Considering the timeouts in the proxied ControlValueAccessor methods and NgOnInit:
 * Unfortunately the NgSelect is not yet available when the calls
 * are coming in, so the timeout is required to delay the calls by one cycle.
 */
@Component({
  selector: 'coyo-select',
  templateUrl: './select-ui.component.html',
  providers: [selectUiValueProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectUiComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {

  /**
   * Flag to disable the select
   */
  @Input() disabled?: boolean = false;

  /**
   * Component and service settings
   */
  @Input() settings: SelectUiSettings<T>;

  /**
   * Template reference for empty search result
   */
  @Input() noResultTemplate: TemplateRef<any>;

  /**
   * Template reference for selected items
   */
  @Input() selectedTemplate: TemplateRef<any>;

  /**
   * Template reference for list items
   */
  @Input() itemTemplate: TemplateRef<any>;

  /**
   * Redux state of the component
   */
  state$: Observable<SelectUiComponentStateModel>;

  /**
   * NgSelect typeahead subject for filtering
   */
  typeahead$: Subject<string>;

  /**
   * Ng Select child component
   */
  @ViewChild(NgSelectComponent)
  ngSelect: NgSelectComponent;

  /**
   * Component UUID for redux identification
   */
  private readonly id: string;

  constructor(private store: Store, uuidService: UuidService) {
    this.id = uuidService.getUuid();
  }

  ngOnInit(): void {
    this.state$ = this.store.select(state => state.selectUiComponent[this.id]);
    setTimeout(() => {
      this.typeahead$ = new Subject();
      this.store.dispatch(new Load(this.id, this.settings, ''));
      this.typeahead$
        .pipe(debounceTime(this.settings.debounceTime))
        .subscribe(input => this.store.dispatch(new Load(this.id, this.settings, input)));
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new Destroy(this.id));
  }

  onScroll(event: { start: number; end: number; }): void {
    if (event.end > event.start && event.end + this.settings.scrollOffsetTrigger > this.ngSelect.items.length) {
      this.store.dispatch(new LoadMore(this.id, this.settings));
    }
  }

  registerOnChange(fn: any): void {
    setTimeout(() => this.ngSelect.registerOnChange(fn), 0);
  }

  registerOnTouched(fn: any): void {
    setTimeout(() => this.ngSelect.registerOnTouched(fn), 0);
  }

  setDisabledState(isDisabled: boolean): void {
    setTimeout(() => this.ngSelect.setDisabledState(isDisabled), 0);
  }

  writeValue(value: any): void {
    if (value && this.settings.bind) {
      this.settings.bind.initFn(value).subscribe(v => this.ngSelect.writeValue(v));
    } else {
      setTimeout(() => this.ngSelect.writeValue(value), 0);
    }
  }

  /**
   * This scroll handler updates the location of the dropdown panel when the host is scrolling.
   * Required because the dropdown panel is attached to a fixed container and thus not informed about
   * scrolling events of its host component.
   * @param event The scroll event
   */
  @HostListener('window:scroll', ['$event'])
  onGlobalScroll(event: Event): void {
    if (this.ngSelect && this.ngSelect.isOpen && this.ngSelect.dropdownPanel) {
      if (event.target !== this.ngSelect.dropdownPanel.scrollElementRef.nativeElement) {
        this.ngSelect.dropdownPanel.adjustPosition();
      }
    }
  }

  /**
   * The panel does not want to stick to the component either, this is also required
   */
  @HostListener('window:resize')
  onGlobalResize(): void {
    if (this.ngSelect && this.ngSelect.dropdownPanel) {
      this.ngSelect.dropdownPanel.adjustPosition();
      this.adjustDropdownHeight();
    }
  }
  /**
   * This calculates the available space for the dropdown panel and sets it accordingly
   */
  private adjustDropdownHeight(): void {
    if (this.ngSelect && this.ngSelect.dropdownPanel && this.ngSelect.dropdownPanel.contentElementRef.nativeElement) {
        const dropdown = (this.ngSelect.dropdownPanel.contentElementRef.nativeElement as HTMLDivElement).parentElement;
        const y = this.ngSelect.element.getBoundingClientRect().bottom + 3;
        dropdown.style.maxHeight = (window.innerHeight - y) + 'px';
    }
  }
}

(function (angular) {
  'use strict';

  nl2br.$inject = ["$sanitize"];
  angular
      .module('commons.ui')
      .filter('nl2br', nl2br);

  /**
   * @ngdoc filter
   * @name commons.ui:nl2br
   * @function
   *
   * @description
   * nl2br linky-compatible angular filter
   *
   * @see https://gist.github.com/habovh/3dcb42d18975375d5247
   */
  function nl2br($sanitize) {
    var tag = '<br />';
    return function (text) {
      if (!angular.isString(text)) {
        return text;
      }

      // ngSanitize's linky filter changes \r and \n to &#10; and &#13; respectively
      var nl2brText = text.replace(/(\r\n|\n\r|\r|\n|&#10;&#13;|&#13;&#10;|&#10;|&#13;)/g, tag + '$1');
      return $sanitize(nl2brText);
    };
  }

})(angular);

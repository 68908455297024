import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {SenderService} from '@domain/sender/sender/sender.service';
import {FindOptions, SelectSenderOptions} from '@shared/sender-ui/select-sender/select-sender-options';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {UpcomingEventsWidget} from '@widgets/upcoming-events/upcoming-events-widget';
import {UpcomingEventsWidgetSettings} from '@widgets/upcoming-events/upcoming-events-widget-settings.model';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {startWith} from 'rxjs/operators';

/**
 * Widget settings for upcoming events widget.
 */
@Component({
  selector: 'coyo-upcoming-events-widget-settings',
  templateUrl: './upcoming-events-widget-settings.component.html',
  styleUrls: ['./upcoming-events-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcomingEventsWidgetSettingsComponent extends WidgetSettingsComponent<UpcomingEventsWidget> implements OnInit {

  readonly senderSelectOptions: SelectSenderOptions = {
    senderTypes: ['page', 'workspace'],
    findOptions: FindOptions.ALL
  };

  constructor(private formBuilder: FormBuilder, private senderService: SenderService) {
    super();
  }

  ngOnInit(): void {
    const settings = this.widget.settings;

    this.parentForm.addControl('_fetchUpcomingEvents',
      this.formBuilder.control(settings._fetchUpcomingEvents || 'ALL', Validators.required));
    this.parentForm.addControl('_numberOfDisplayedEvents',
      this.formBuilder.control(settings._numberOfDisplayedEvents || 5, [Validators.required, Validators.min(1), Validators.max(20)]));
    this.parentForm.addControl('_displayOngoingEvents',
      this.formBuilder.control(settings._displayOngoingEvents || false));
    this.parentForm.addControl('_selectedSender',
      this.formBuilder.control(null));

    if (settings._sender) {
      this.senderService.get(settings._sender).subscribe(sender =>
        this.parentForm.patchValue({_selectedSender: sender}));
    }

    this.parentForm.controls['_fetchUpcomingEvents'].valueChanges
      .pipe(startWith(this.parentForm.controls['_fetchUpcomingEvents'].value))
      .subscribe(value => {
        if (value === 'SELECTED') {
          this.parentForm.controls['_selectedSender'].setValidators(Validators.required);
          this.parentForm.controls['_selectedSender'].updateValueAndValidity();
        } else {
          this.parentForm.controls['_selectedSender'].clearValidators();
          this.parentForm.controls['_selectedSender'].updateValueAndValidity();
        }
      });
  }

  onBeforeSave(settings?: any): Observable<UpcomingEventsWidgetSettings> {
    return of(_.omit({
      ...settings,
      _sender: _.get(this.parentForm.controls, '_selectedSender.value.id', null)
    }, '_selectedSender'));
  }
}

/**
 * This class is supposed to generalize the approach to i18n language & locale identification.
 * Please feel free to expand and fix this class as you see fit. Try to keep to standards as best
 * as possible.
 */
export class Locale {

  private static readonly languageToCountry: { [key: string]: string } = {
    en: 'GB',
    hy: 'AM',
    da: 'DK',
    cs: 'CZ',
    el: 'GR',
    et: 'EE',
    ja: 'JP',
    sv: 'SE',
    sr: 'RS',
    sl: 'SI',
    zh: 'CN'
  };

  private static readonly languageCodeMatch: RegExp = /[a-z]{2}/;
  private static readonly countryCodeMatch: RegExp = /[A-Z]{2}/;
  private static readonly localeCodeMatch: RegExp = /[a-z]{2}_[A-Z]{2}/;

  /**
   * ISO 639-1 2-character language code (lower-case)
   */
  readonly language: string;
  /**
   * ISO 3166-1 2-character country code (upper-case)
   */
  readonly country: string;

  constructor(language: string, country: string) {
    if (!Locale.languageCodeMatch.test(language)) {
      throw (new Error(`Invalid language code '${language}'`));
    }
    if (!Locale.countryCodeMatch.test(country)) {
      throw (new Error(`Invalid country code '${country}'`));
    }
    this.language = language;
    this.country = country;
  }

  /**
   * Performs a language to country mapping. Prone to cause issues with widely used languages.
   * Use with care. Best not to use it at all.
   *
   * @param language the language string to use for the Locale
   *
   * @returns a Locale for the given language
   */
  static fromLanguage(language: string): Locale {
    if (!language || language.length !== 2) {
      return null;
    }
    const country = Locale.languageToCountry[language.toLowerCase()] ?? language.toUpperCase();
    return new Locale(language.toLowerCase(), country);
  }

  /**
   * Parses a string in the format of what toString() generates. E.g. de_DE or en_GB
   *
   * @param str the locale string
   *
   * @returns A Locale object parsed from the given string
   */
  static parse(str: string): Locale {
    if (!Locale.localeCodeMatch.test(str)) {
      throw (new Error(`Invalid locale code '${str}'`));
    }
    const codes = str.split('_');
    return new Locale(codes[0], codes[1]);
  }

  equals(other: Locale | string): boolean {
    return this.toString() === other.toString();
  }

  /**
   * This should eventually be conforming to some standard like the IETF language tag, currently will return
   * [language]_[COUNTRY]
   *
   * @returns a unique string representation of the locale
   */
  toString(): string {
    return `${this.language}_${this.country}`;
  }
}

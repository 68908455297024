import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TimelineItem} from '@domain/timeline-item/timeline-item';
import {WINDOW} from '@root/injection-tokens';
import {Ng1AuthService} from '@root/typings';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {NG1_AUTH_SERVICE} from '@upgrade/upgrade.module';
import {TimelineItemEditModalData} from '../timeline-item-edit-modal/timeline-item-edit-modal-data';
import {TimelineItemEditModalComponent} from '../timeline-item-edit-modal/timeline-item-edit-modal.component';
import {TimelineItemReportModalData} from '../timeline-item-report-modal/timeline-item-report-modal-data';
import {TimelineItemReportModalComponent} from '../timeline-item-report-modal/timeline-item-report-modal.component';

/**
 * Context menu of timeline items.
 */
@Component({
  selector: 'coyo-timeline-item-context-menu',
  templateUrl: './timeline-item-context-menu.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemContextMenuComponent implements OnInit, OnChanges, OnDestroy {
  private editDialogRef: MatDialogRef<TimelineItemEditModalComponent, boolean>;
  private reportDialogRef: MatDialogRef<TimelineItemReportModalComponent, boolean>;

  /**
   * The timeline item
   */
  @Input() item: TimelineItem;

  /**
   * Emitted when clicked on show author
   */
  @Output() showAuthor: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Emitted when clicked on delete item
   */
  @Output() delete: EventEmitter<void> = new EventEmitter();

  /**
   * Emitted when clicked on remove sticky
   */
  @Output() unsticky: EventEmitter<void> = new EventEmitter();

  currentUserId: string;

  deepLink: string;

  private showOriginalAuthor: boolean = false;

  constructor(@Inject(WINDOW) private windowService: Window,
              @Inject(NG1_AUTH_SERVICE) private authService: Ng1AuthService,
              private dialog: MatDialog,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.currentUserId = this.authService.getCurrentUserId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.deepLink = this.getTimelineItemLink();
  }

  ngOnDestroy(): void {
    if (this.editDialogRef) {
      this.editDialogRef.close();
    }
    if (this.reportDialogRef) {
      this.reportDialogRef.close();
    }
  }

  /**
   * Emits the show original author event and toggles the flag
   */
  onClickShowOriginalAuthors(): void {
    this.showOriginalAuthor = !this.showOriginalAuthor;
    this.showAuthor.emit(this.showOriginalAuthor);
  }

  /**
   * Creates a success toast after the link of the timeline item is added to clipboard
   */
  copyLinkSuccessNotification(): void {
    this.notificationService.success('MODULE.TIMELINE.COPY_LINK.SUCCESS');
  }

  /**
   * Opens the timeline item edit modal.
   */
  openEditModal(): void {
    this.editDialogRef = this.dialog.open<TimelineItemEditModalComponent, TimelineItemEditModalData>(TimelineItemEditModalComponent, {
      autoFocus: true,
      data: {
        itemId: this.item.id
      }
    });
    this.editDialogRef.afterClosed().subscribe(success => {
      if (success) {
        this.notificationService.success('MODULE.TIMELINE.EDIT.MODAL.SUCCESS');
      }
    });
  }

  /**
   * Opens the report form.
   */
  openReportForm(): void {
    this.reportDialogRef = this.dialog.open<TimelineItemReportModalComponent, TimelineItemReportModalData>(TimelineItemReportModalComponent, {
      autoFocus: true,
      data: {
        targetId: this.item.id,
        targetType: this.item.typeName
      }
    });
    this.reportDialogRef.afterClosed().subscribe(success => {
      if (success) {
        this.notificationService.success('MODULE.REPORT.SUCCESS');
      }
    });
  }

  canEdit(): boolean {
    return this.item._permissions.edit && this.item.itemType === 'post';
  }

  /**
   * Creates a link for the timeline item
   *
   * @return The deep link of the timeline item
   */
  private getTimelineItemLink(): string {
    return this.windowService.location.protocol + '//' + this.windowService.location.host + '/timeline/item/' + this.item.id;
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {FacegameAvatarTeaserComponent} from '@widgets/facegame/facegame-avatar-teaser/facegame-avatar-teaser.component';
import {FACEGAME_WIDGET} from '@widgets/facegame/facegame-widget-config';
import {FacegameWidgetSettingsComponent} from '@widgets/facegame/facegame-widget-settings/facegame-widget-settings.component';
import {FacegameWidgetComponent} from '@widgets/facegame/facegame-widget/facegame-widget.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

/**
 * Module providing the subscription widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    HelpModule,
    TooltipModule,
    SenderUIModule
  ],
  declarations: [
    FacegameWidgetComponent,
    FacegameWidgetSettingsComponent,
    FacegameAvatarTeaserComponent
  ],
  entryComponents: [
    FacegameWidgetComponent,
    FacegameWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: FACEGAME_WIDGET, multi: true}
  ],
})
export class FacegameWidgetModule {}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {Sender} from '@domain/sender/sender';
import {Share} from '@domain/share/share';
import {TimelineItem} from '@domain/timeline-item/timeline-item';
import {TimelineItemTarget} from '@domain/timeline-item/timeline-item-target';
import {TimelineItemTargetService} from '@domain/timeline-item/timeline-item-target.service';
import {TimelineItemService} from '@domain/timeline-item/timeline-item.service';
import * as _ from 'lodash';
import {BehaviorSubject, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

interface TimelineItemComponentState {
  loading: boolean;
  item: TimelineItem;
  skipInitRequest: boolean;
  ribbonType: string;
  context: Sender;
  target: TimelineItemTarget;
  author: Sender;
  originalAuthor: Sender;
  relevantShare: Share;
  hasShares: boolean;
  publicShare: boolean;
}

/**
 * Displays a timeline item.
 */
@Component({
  selector: 'coyo-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemComponent implements OnInit, OnChanges {

  /**
   * The timeline item
   */
  @Input() timelineItem: TimelineItem;

  /**
   * The context id
   */
  @Input() contextId: string;

  /**
   * The type of the timeline
   */
  @Input() type: 'personal' | 'sender' = 'personal';

  state$: BehaviorSubject<TimelineItemComponentState>;

  constructor(private timelineItemService: TimelineItemService,
              private timelineItemTargetService: TimelineItemTargetService,
              private authService: AuthService) {
  }

  /**
   * Initialize the component
   */
  ngOnInit(): void {
    this.state$ = new BehaviorSubject<TimelineItemComponentState>({
      loading: false,
      item: this.timelineItem,
      skipInitRequest: this.type !== 'personal' && this.timelineItem.isNew,
      ribbonType: this.getRibbonType(this.isSticky(this.timelineItem), this.timelineItem.isNew),
      context: this.timelineItem.recipients && this.timelineItem.recipients.length ? this.timelineItem.recipients[0] : null,
      target: null,
      author: null,
      originalAuthor: null,
      relevantShare: null,
      hasShares: false,
      publicShare: false
    });
    this.setRelevantShare(this.timelineItem.relevantShare);
    const target$ = this.timelineItemTargetService.determineTarget(this.timelineItem);
    const author$ = this.authService.getUser();

    target$.subscribe(target => {
      const state: TimelineItemComponentState = {
        ...this.state$.getValue(),
        target: target
      };
      this.state$.next(state);
    });

    author$.subscribe(author => {
      const state: TimelineItemComponentState = {
        ...this.state$.getValue(),
        author: author
      };
      this.state$.next(state);
    });
  }

  /**
   * Handle changes
   * @param changes changes of the input parameters
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.state$ && changes.timelineItem) {
      const oldState = this.state$.getValue();

      const item = this.timelineItem;
      this.state$.next({
        ...oldState,
        item: item,
        skipInitRequest: this.type !== 'personal' && item.isNew,
        ribbonType: this.getRibbonType(this.isSticky(item), item.isNew),
        hasShares: item.shares && item.shares.length > 0,
        context: item.recipients && item.recipients.length ? item.recipients[0] : null
      });
      this.setRelevantShare(item.relevantShare);
    }
  }

  /**
   * Mark the timeline item as read.
   */
  markAsRead(): void {
    if (this.isBusy()) {
      return;
    }
    this.setBusy();
    this.timelineItemService.markAsRead(this.state$.getValue().item)
      .subscribe(item => {
        const oldState = this.state$.getValue();
        this.state$.next({
          ...oldState,
          item: item,
          ribbonType: this.getRibbonType(this.isSticky(item), item.isNew),
          loading: false
        });
      });
  }

  /**
   * Refreshes the ribbon type according to the current timeline item properties
   * @param unread If the timeline item is unread
   * @param isNew If the timeline item is new
   * @returns the ribbon type
   */
  getRibbonType(unread: boolean, isNew: boolean): string {
    return unread ? 'sticky' : isNew ? 'new' : undefined;
  }

  /**
   * Remove the stickyness of the timeline item.
   */
  unsticky(): void {
    if (this.isBusy()) {
      return;
    }
    this.setBusy();
    this.timelineItemService.unsticky(this.state$.getValue().item)
      .subscribe(item => {
        const oldState = this.state$.getValue();
        this.state$.next({
          ...oldState,
          item: item,
          ribbonType: this.getRibbonType(this.isSticky(item), item.isNew),
          loading: false
        });
      });
  }

  private setBusy(isBusy: boolean = true): void {
    const oldState = this.state$.getValue();
    this.state$.next({...oldState, loading: isBusy});
  }

  private isBusy(): boolean {
    return this.state$.getValue().loading;
  }

  /**
   * Sets whether the original author should be shown
   * @param showAuthor the flag
   */
  setShowOriginalAuthor(showAuthor: boolean): void {
    const oldState = this.state$.getValue();
    (showAuthor ? this.timelineItemService.getOriginalAuthor(oldState.item) : of(null)).subscribe(originalAuthor => {
      this.state$.next({
        ...oldState,
        originalAuthor: originalAuthor
      });
    });
  }

  /**
   * Delete the timeline item.
   */
  remove(): void {
    if (this.isBusy()) {
      return;
    }
    this.setBusy();
    this.timelineItemService.delete(this.timelineItem.id).subscribe(() => {
      this.setBusy(false);
    });
  }

  /**
   * Delete shares callback.
   * @param shares the shares
   */
  deleteShare(shares: Share[]): void {
    const relevantShare = this.state$.getValue().relevantShare;
    const isRelevantShareDeleted = relevantShare &&
      shares.map(share => share.id).indexOf(relevantShare.id) !== -1;

    if (isRelevantShareDeleted) {
      this.emitRelevantShare();
    }
  }

  /**
   * Emits the relevant share by asking the backend,
   * since the business logic for emitting relevant shares is and should stay at a central point.
   */
  emitRelevantShare(): void {
    this.timelineItemService.getRelevantShare(this.state$.getValue().target, this.contextId, this.type)
      .pipe(catchError(() => of(null)))
      .subscribe(newShare => this.setRelevantShare(newShare));
  }

  /**
   * Sets the relevant share
   * @param share the share
   */
  setRelevantShare(share: Share): void {
    const oldState = this.state$.getValue();
    oldState.item.relevantShare = share;
    this.state$.next({
      ...oldState,
      relevantShare: share,
      publicShare: share && !_.isEmpty(share.recipient)
        ? share.recipient.public
        : false
    });
  }

  /**
   * Sets the author for the functional user feature.
   * @param newAuthor the new author
   */
  setAuthor(newAuthor: Sender): void {
    const oldState = this.state$.getValue();
    this.state$.next({
      ...oldState,
      author: newAuthor
    });
  }

  /**
   * Checks if the recipient is not the author.
   * @param state The TimelineItemComponentState data
   * @returns true if the recipient is not the author else false
   */
  isRecipientNotTheAuthor(state: TimelineItemComponentState): boolean {
    return state.item
      && state.item.recipients
      && state.item.recipients.length
      && state.item.recipients[0].id !== state.item.author.id;
  }

  private isSticky(item: TimelineItem): boolean {
    return item.unread;
  }
}

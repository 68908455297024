import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SessionInfo} from '@core/token/session-info';
import {TimeService} from '@shared/time/time/time.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';

interface TokenResponse {
  token: string;
}

/**
 * Service for getting a authorization token that can be used for microservice requests. Please be aware that the token
 * will be added to the token header of your requests automatically by {@link ServiceInterceptor}
 */
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  static readonly INVALIDATE_TIME: number = 60000;

  private cachedToken$: Observable<string>;

  private refreshTime: number;

  constructor(private http: HttpClient, private timeService: TimeService) {
  }

  /**
   * Generates a authentication token for the current user that can be used to call endpoints of the micro services
   *
   * @param forceRefresh Flag that forces a request to the authorization server instead of using the cached token.
   *
   * @return the authentication token for the current user
   */
  getToken(forceRefresh: boolean = false): Observable<string> {
    if (this.cachedToken$ && !forceRefresh && this.timeService.getCurrentTimeMillis() < this.refreshTime) {
      return this.cachedToken$;
    } else {
      // as we don't want to parse the jwt token in the frontend (which would need another lib) we just get another
      // token after 10 minutes
      this.refreshTime = this.timeService.getCurrentTimeMillis() + TokenService.INVALIDATE_TIME;
      this.cachedToken$ = this.http.get<TokenResponse>('/web/authorization/token')
        .pipe(map(response => response.token))
        .pipe(shareReplay({bufferSize: 1, refCount: false})).pipe(catchError(err => {
          this.cachedToken$ = null;
          return throwError(err);
        }));
      return this.cachedToken$;
    }
  }

  // TODO: use tokens for authentication asap, currently not possible for the backend
  /**
   * Get the session info.
   * @return an observable that emits the session info
   */
  getSessionInfo(): Observable<SessionInfo> {
    return this.http.get<SessionInfo>('/web/authorization/session')
      .pipe(shareReplay({bufferSize: 1, refCount: false}));
  }
}

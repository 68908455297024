(function (angular) {
  'use strict';

  WikiArticleViewController.$inject = ["$rootScope", "$scope", "$state", "$stateParams", "$log", "$timeout", "$q", "$injector", "$window", "moment", "modalService", "wikiArticleRevisionsModal", "socketService", "WikiArticleModel", "wikiArticleService", "app", "article", "editMode", "currentUser", "SettingsModel", "sender", "wikiArticleTranslationService"];
  angular
      .module('coyo.apps.wiki')
      .controller('WikiArticleViewController', WikiArticleViewController);

  /**
   * Controller for viewing and inline-editing a wiki article.
   *
   * @requires $injector
   * @requires $rootScope
   * @requires $scope
   * @requires $state
   * @requires $stateParams
   * @requires $log
   * @requires $timeout
   * @requires $q
   * @requires $injector
   * @requires $window
   * @requires moment
   * @requires modalService
   * @requires coyo.apps.wiki.wikiArticleRevisionsModal
   * @requires commons.sockets.socketService
   * @requires coyo.apps.wiki.WikiArticleModel
   * @requires coyo.apps.wiki.wikiArticleService
   * @requires app
   * @requires article
   * @requires editMode
   * @requires currentUser
   * @requires coyo.domain.SettingsModel
   * @requires sender
   * @requires coyo.apps.wiki.wikiArticleTranslationService
   */
  function WikiArticleViewController($rootScope, $scope, $state, $stateParams, $log, $timeout, $q, $injector, $window,
                                     moment, modalService, wikiArticleRevisionsModal, socketService,
                                     WikiArticleModel, wikiArticleService, app, article, editMode, currentUser,
                                     SettingsModel, sender, wikiArticleTranslationService) {
    var vm = this;
    vm.$onInit = init;
    vm.app = app;
    vm.article = article;
    vm.sender = sender;
    vm.editMode = editMode;
    vm.loading = true;
    vm.moment = moment;
    vm.simpleMode = true;
    vm.currentUser = currentUser;

    vm.edit = edit;
    vm.print = print;
    vm.printSubArticlePreview = printSubArticlePreview;
    vm.setAsHomeArticle = setAsHomeArticle;
    vm.cancel = cancel;
    vm.save = save;
    vm.deleteArticle = deleteArticle;
    vm.removeLock = removeLock;
    vm.showRevisions = showRevisions;
    vm.openRevision = openRevision;
    vm.fetchByLanguage = fetchByLanguage;
    vm.onLanguageChange = _onLanguageChange;
    vm.onLanguageDeleted = _onLanguageDeleted;
    vm.updateValidity = _updateValidity;
    vm.isTranslationRequired = _isTranslationRequired;
    vm.addShare = addShare;
    vm.deleteShares = deleteShares;
    vm.updateLayoutName = updateLayoutName;
    vm.getLayoutLanguageKey = getLayoutLanguageKey;

    function removeLock() {
      modalService.confirm({
        title: 'APP.WIKI.MODAL.UNLOCK.TITLE',
        text: 'APP.WIKI.MODAL.UNLOCK.TEXT',
        translationContext: {
          lockHolder: vm.article.lockHolder.displayName
        }
      }).result.then(function () {
        wikiArticleService.unlock(vm.article).then(function () {
          _refreshLockInformation();
        });
      });
    }

    function deleteArticle(article) {
      wikiArticleService.deleteArticle(vm.app, article).then(function (result) {
        if (result) {
          $state.go('^');
        }
      });
    }

    function edit() {
      $timeout(function () {
        // timeout so languages are initialized when navigating to edit mode from the list view
        var langs = Object.keys(vm.languages).filter(function (key) {
          return vm.isTranslationRequired(key);
        }).map(function (key) {
          return vm.getLayoutLanguageKey(key);
        });
        $injector.get('ngxWidgetEditService').enableEditMode(vm.app.id);
        $injector.get('ngxWidgetEditService').prepareNewRevision(vm.app.id, vm.layoutName, buildLayoutName('temp'), langs, vm.article)
            .toPromise()
            .then(function () {
              vm.editMode = true;
              vm.saveRevision = true;
              updateLayoutName('temp');
              return wikiArticleService.lock(vm.article, currentUser);
            }).then(function (lock) {
              angular.extend(vm.article, lock);
            }).catch(_refreshLockInformation);
      });
    }

    function addShare(share) {
      $scope.$apply(function () {
        var article = angular.copy(vm.article);
        if (!article.shares) {
          article.shares = [];
        }
        article.shares.push(share);
        vm.article = article;
      });
    }

    function deleteShares(deletedShares) {
      $scope.$apply(function () {
        var article = angular.copy(vm.article);
        deletedShares.forEach(function (share) {
          var idx = _.findIndex(article.shares, {id: share.id});
          if (idx > -1) {
            article.shares.splice(idx, 1);
          }
        });
        vm.article = article;
      });
    }

    function updateLayoutName(appendix) {
      vm.layoutName = buildLayoutName(appendix);
    }

    function buildLayoutName(appendix) {
      return 'app-wiki-' + vm.app.id + '-' + vm.article.id + '-' + appendix;
    }

    function getLayoutLanguageKey(lang) {
      if (!!lang && !!sender.defaultLanguage && lang !== 'NONE' && sender.defaultLanguage !== lang) {
        return lang;
      }
      return null;
    }

    function print() {
      $window.print();
    }

    function printSubArticlePreview() {
      vm.article.deepCountChildren().then(function (childrenCount) {
        wikiArticleService.confirmWikiPrintPreview(childrenCount + 1).then(function () {
          wikiArticleService.printPreviewWithSubArticles(vm.app, vm.article);
        });
      });
    }

    function setAsHomeArticle() {
      var modaltext = vm.app.settings.home
        ? 'APP.WIKI.ARTICLE.HOME_ARTICLE.MODAL.TEXT.HOME_SET'
        : 'APP.WIKI.ARTICLE.HOME_ARTICLE.MODAL.TEXT.HOME_NOT_SET';
      modalService.confirm({
        title: 'APP.WIKI.ARTICLE.HOME_ARTICLE.MODAL.TITLE',
        text: modaltext,
        translationContext: {wikiName: vm.app.displayName},
        close: {title: 'YES'},
        dismiss: {title: 'NO'}
      }).result.then(function () {
        vm.app.settings.home = vm.article.id;
        vm.app.save().then(function (app) {
          $rootScope.$emit('app:updated', app);
          vm.app = app;
          $injector.get('ngxNotificationService').success('APP.WIKI.ARTICLE.HOME_ARTICLE.NOTIFICATION.SUCCESS');
        });
      });
    }

    function cancel() {
      vm.loading = true;
      wikiArticleService.releaseLock(vm.article, currentUser, false).finally(function () {
        $injector.get('ngxWidgetEditService').cancelEdit(vm.app.id);
        _reloadInViewMode();
      });
    }

    function save() {
      wikiArticleTranslationService.prepareTranslations(vm);

      var deferred = $q.defer();
      vm.loading = true;

      vm.article.update().then(function (savedArticle) {
        vm.saveRevision = false;
        updateLayoutName(savedArticle.revisionNumber);
        $timeout(function () { // wait to sync id to widget layout and slots
          vm.editMode = false;
          $injector.get('ngxWidgetEditService').save(vm.app.id).toPromise().then(function () {
            wikiArticleService.releaseLock(savedArticle, currentUser, true).then(function () {
              deferred.resolve();
              _reloadInViewMode();
            });
          }).catch(function () {
            $injector.get('ngxWidgetEditService').enableEditMode(vm.app.id);
            deferred.reject();
          }).finally(function () {
            updateLayoutName(savedArticle.revisionNumber);
            vm.loading = false;
          });
        });
      }).catch(function () {
        deferred.reject();
        vm.loading = false;
      });

      return deferred.promise;
    }

    function showRevisions() {
      wikiArticleRevisionsModal.open(vm.article).then(openRevision);
    }

    function openRevision(revision) {
      $state.go($state.current, {revision: revision, editMode: false}, {reload: $state.current});
    }

    /******************* Helper methods *******************/

    function _refreshLockInformation() {
      var context = {
        senderId: vm.app.senderId,
        appId: vm.app.id,
        id: vm.article.id
      };
      return WikiArticleModel.getWithPermissions(context, {}, ['edit', 'delete', 'share']).then(function (article) {
        vm.article.locked = article.locked;
        vm.article.lockDate = article.lockDate;
        vm.article.lockHolder = article.lockHolder;
      });
    }

    function _reloadParentArticles() {
      vm.parentArticles = [];
      _loadParentArticles(article);
    }

    function _loadParentArticles(article) {
      if (!article.parentId || _.find(vm.parentArticles, {id: article.parentId})) {
        return;
      }
      var context = {
        senderId: app.senderId,
        appId: app.id,
        id: article.parentId
      };

      WikiArticleModel.getWithPermissions(context, {origin: true}, ['edit', 'delete', 'share'])
          .then(function (article) {
            vm.parentArticles.unshift(article);
            _loadParentArticles(article);
          });
    }

    function _handleRemoved(event) {
      $log.debug('[WikiArticleService] Received event for wiki article lock removed', event);
      var previousHolder = event.content.previousHolder;

      // I had the lock (and I was in edit mode) -> show note that lock got removed
      if (vm.editMode && previousHolder === currentUser.id) {
        modalService.note({
          title: 'APP.WIKI.MODAL.LOCK.REMOVED.TITLE',
          text: 'APP.WIKI.MODAL.LOCK.REMOVED.TEXT'
        });
        _refreshLockInformation();
      }
    }

    function _handleReleased(event) {
      // someone else had the lock (and I wasn't in edit mode) -> update article
      $log.debug('[WikiArticleService] Received event for wiki article lock released', event);
      if (event.content.changed) {
        vm.article.latestRevision = false;
      }
      if (!vm.editMode) {
        _refreshLockInformation();
      }
    }

    function _handleLocked(event) {
      $log.debug('[WikiArticleService] Received event for wiki article lock set', event);
      if (!vm.editMode) {
        _refreshLockInformation();
      }
    }

    function _reloadInViewMode() {
      var stateParams = angular.extend(angular.copy($stateParams), {
        editMode: false
      });
      delete stateParams.revision;
      $state.transitionTo($state.current, stateParams, {
        reload: $state.current, inherit: false, notify: true
      });
    }

    /* ===== PRIVATE METHODS ===== */

    function init() {
      vm.layoutName = vm.article.buildLayoutName(vm.app.id);
      if (vm.article.wikiArticles > 0) {
        WikiArticleModel.getSubArticles(vm.app, vm.article.id, true).then(function (subArticles) {
          vm.subArticles = subArticles;
        });
      }

      if (vm.editMode && (!wikiArticleService.isLocked(vm.article)
          || wikiArticleService.hasLock(article, currentUser))) {
        edit();
      } else {
        vm.editMode = false;
      }
      vm.loading = false;

      $scope.$watch(function () {
        return vm.article.parentId;
      }, function (newVal) {
        if (newVal === vm.article.id) {
          vm.article.parentId = null;
        }
      });

      _reloadParentArticles();

      var lock = '/topic/item.lock',
          unsubscribeSocketSubscriptionLocked = socketService.subscribe(lock, _handleLocked, 'set', article.id, article.subscriptionInfo.token),
          unsubscribeSocketSubscriptionUnlocked = socketService.subscribe(lock, _handleRemoved, 'removed', article.id, article.subscriptionInfo.token),
          unsubscribeSocketSubscriptionReleased = socketService.subscribe(lock, _handleReleased, 'released', article.id, article.subscriptionInfo.token);

      $scope.$on('$destroy', function () {
        unsubscribeSocketSubscriptionLocked();
        unsubscribeSocketSubscriptionUnlocked();
        unsubscribeSocketSubscriptionReleased();
        if (vm.editMode) {
          wikiArticleService.releaseLock(vm.article, currentUser);
        }
        $injector.get('ngxWidgetEditService').cancelEdit(vm.app.id);
      });
      wikiArticleTranslationService.initLanguages(vm, currentUser, $stateParams.currentLanguage);
    }

    function _onLanguageDeleted(language) {
      return wikiArticleTranslationService.onLanguageDeleted($scope, vm, language);
    }

    function _onLanguageChange(copyFromDefault) {
      wikiArticleTranslationService.onLanguageChange(vm, copyFromDefault);
    }

    function _updateValidity(key, value) {
      wikiArticleTranslationService.updateValidity(vm, key, value);
    }

    function _isTranslationRequired(language) {
      return wikiArticleTranslationService.isTranslationRequired(vm, language);
    }

    function fetchByLanguage(language) {
      vm.preferredLanguage = language;
      if (language !== 'NONE' && !_.isEmpty(vm.languages[language].translations) && vm.languages[language].active) {
        vm.languageInitialised[language] = true;
      }
      vm.currentLanguage = null;
      $timeout().then(function () {
        vm.currentLanguage = language;
      });
    }
  }
})(angular);

<div [formGroup]="parentForm" class="upcoming-events-widget-settings">
  <div class="form-group row">
    <label class="col-sm-3 control-label">
      {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.UPCOMING.EVENTS.DISPLAY.LABEL' | translate}}
    </label>
    <div class="col-sm-9">
      <div class="radio fetchEvents">
        <label>
          <input formControlName="_fetchUpcomingEvents" name="_fetchUpcomingEvents" type="radio" value="ALL">
          {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.UPCOMING.EVENTS_ALL' | translate }}
        </label>
        <span class="text-muted">
        {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.UPCOMING.HELP.EVENTS_ALL' | translate}}</span>
      </div>
      <div class="radio fetchEvents">
        <label>
          <input formControlName="_fetchUpcomingEvents" name="_fetchUpcomingEvents" type="radio" value="SUBSCRIBED">
          {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.UPCOMING.EVENTS_SUBSCRIBED' | translate }}
        </label>
        <span class="text-muted">
        {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.UPCOMING.HELP.EVENTS_SUBSCRIBED' | translate}}</span>
      </div>
      <div class="radio fetchEvents">
        <label>
          <input formControlName="_fetchUpcomingEvents" name="_fetchUpcomingEvents" type="radio" value="SELECTED">
          {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.UPCOMING.EVENTS_SELECTED' | translate }}
        </label>
        <span class="text-muted">
        {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.UPCOMING.HELP.EVENTS_SELECTED' | translate}}</span>
      </div>
      <p>{{parentForm['_selectedSender'] | json}}</p>
      <div *ngIf="parentForm.controls['_fetchUpcomingEvents'].value === 'SELECTED'"
           [class.has-error]="parentForm.controls['_selectedSender']?.invalid"
           class="col-sm-9 event-sender-chooser">
        <coyo-select-sender [options]="senderSelectOptions"
                            data-test="share-with-sender-select"
                            formControlName="_selectedSender"
                            id="_selectedSender"
                            name="_selectedSender"
                            placeholder="MODULE.TIMELINE.SHARE.DIALOG.SELECT_SENDER.PLACEHOLDER"></coyo-select-sender>
      </div>
    </div>
  </div>
  <div [class.has-error]="parentForm.controls['_numberOfDisplayedEvents']?.invalid" class="form-group row">
    <label class="col-sm-3 control-label">
      {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.UPCOMING.EVENT_NUMBER.LABEL' | translate}}</label>
    <div class="col-sm-9">
      <input class="form-control" formControlName="_numberOfDisplayedEvents" name="_numberOfDisplayedEvents"
             min="1" max="20"
             type="number">
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 control-label" for="_displayOngoingEvents">
      {{'WIDGETS.UPCOMING.EVENTS.SETTINGS.DISPLAY.ONGOING' | translate}}
    </label>
    <div class="col-sm-9">
      <div class="checkbox">
        <mat-checkbox formControlName="_displayOngoingEvents"
                      id="_displayOngoingEvents"
                      name="_displayOngoingEvents">
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>

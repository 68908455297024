import {ChangeDetectionStrategy, Component} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {ButtonWidget} from '../button-widget';

/**
 * This widget displays a button with text and link.
 */
@Component({
  templateUrl: './button-widget.component.html',
  styleUrls: ['./button-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonWidgetComponent extends WidgetComponent<ButtonWidget> {

  /**
   * Returns the target of the link button or '_blank' by default.
   *
   * @return the link target
   */
  getLinkTarget(): string {
    // returns undefined to prevent the user from being re-routed to the homepage
    // when the link is not being opened in a new tab/window
    return this.widget.settings._linkTarget === '_self' ? undefined : this.widget.settings._linkTarget || '_blank';
  }
}

(function (angular) {
  'use strict';

  RenderBirthdayController.$inject = ["$scope", "$rootScope", "$filter", "birthdayService"];
  angular
      .module('commons.ui')
      .controller('RenderBirthdayController', RenderBirthdayController);

  /**
   * The birthday value controller
   *
   * @constructor
   */
  function RenderBirthdayController($scope, $rootScope, $filter, birthdayService) {
    var vm = this,
        dateString,
        fullDateString,
        havingYear;

    vm.displayValue = undefined;

    vm.$onInit = init;

    function init() {
      var unregisterWatch = $scope.$watch(function () {
        return vm.date;
      }, function (newDate) {
        if (newDate) {
          dateString = newDate;
          havingYear = birthdayService.hasYear(dateString);
          fullDateString = getFullDateString(dateString);
          formatDisplayValue();
        }
      });
      $scope.$on('$destroy', unregisterWatch);
    }

    function getFullDateString(dateString) {
      if (havingYear) {
        return dateString;
      } else {
        return birthdayService.getReferenceLeapYear() + '-' + dateString;
      }
    }

    function formatDisplayValue() {
      var format = vm.format;
      if (havingYear) {
        if (vm.formatIncludingYear) {
          format = vm.formatIncludingYear;
        } else if (!vm.format) {
          format = $rootScope.dateFormat.medium;
        }
      } else if (!vm.format) {
        format = $filter('translate')('DATE_FORMAT_MEDIUM_WITHOUT_YEAR');
      }
      vm.displayValue = $filter('amDateFormat')(fullDateString, format);
    }

  }

})(angular);

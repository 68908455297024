<div [formGroup]="parentForm">
  <div [class.has-error]="isInvalid('selectedApp')" class="form-group">
    <label class="col-sm-2 control-label" translate="WIDGETS.WIKI.SETTINGS.WIKI_APP.LABEL"></label>
    <div class="col-sm-9">
      <coyo-select-app [multiselect]="false"
                       data-test="share-with-sender-select" formControlName="selectedApp"
                       id="selectedApp"
                       key="wiki"
                       placeholder="COMMONS.SHARES.PLACEHOLDER">
      </coyo-select-app>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.WIKI.SETTINGS.WIKI_APP.HELP"></coyo-help>
    </div>
  </div>
  <div [class.has-error]="isInvalid('articleCount')" class="form-group">
    <label class="col-sm-2 control-label" for="articleCount"
           translate="WIDGETS.WIKI.SETTINGS.ARTICLE_COUNT.LABEL"></label>
    <div class="col-sm-9">
      <input
        class="form-control"
        formControlName="articleCount"
        id="articleCount"
        max="20"
        min="1"
        name="articleCount"
        step="1"
        type="number">
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.WIKI.SETTINGS.ARTICLE_COUNT.HELP"></coyo-help>
    </div>
  </div>
</div>

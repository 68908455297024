import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {WINDOW} from '@root/injection-tokens';
import {PDFDocumentProxy, PDFSource} from 'ng2-pdf-viewer';
import {SettingPreset} from '../setting-preset';
import {settingPresets} from '../setting-presets';

/**
 * A PDF viewer.
 */
@Component({
  selector: 'coyo-pdf-viewer',
  templateUrl: './pdf-viewer.component.html'
})
export class PdfViewerComponent implements OnInit, OnChanges {

  /**
   * The source of the pdf
   */
  @Input() src: string;

  /**
   * If the top navigation toolbar of the PDF viewer should be rendered
   */
  @Input() showToolbar: boolean;

  /**
   * Emitted after the pdf is fully loaded
   */
  @Output() loadingCompleted: EventEmitter<void> = new EventEmitter();

  /**
   * Emitted if error occurred during loading the pdf
   */
  @Output() loadingFailed: EventEmitter<void> = new EventEmitter();

  pdfSrc: string | Uint8Array | PDFSource | { withCredentials: boolean };
  loading: boolean;
  currentPage: number;
  maxPages: number;
  rotation: number;
  settingPresets: SettingPreset[];
  activeSettings: SettingPreset;
  externalFileLoadingTimeoutId: number;

  constructor(@Inject(UrlService) protected urlService: UrlService,
              @Inject(WINDOW) private windowService: Window) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.currentPage = 1;
    this.maxPages = 1;
    this.rotation = 0;
    this.settingPresets = settingPresets;
    this.activeSettings = this.settingPresets[0];
    this.setPdfSource();
    this.windowService['pdfWorkerSrc'] = '/pdf.worker.min.js';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src.currentValue) {
      this.setPdfSource();
    }
  }

  /**
   * Called after the pdf is fully loaded
   *
   * @param pdf
   * The loaded pdf
   *
   */
  afterLoadComplete(pdf: PDFDocumentProxy): void {
    this.maxPages = pdf.numPages;
    this.loading = false;
    this.loadingCompleted.emit();
  }

  onError(): void {
    this.loading = false;
    this.loadingFailed.emit();
  }

  /**
   * Updates the current page of the pdf
   *
   * @param pageNumber
   * The new page
   */
  pageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  /**
   * Updates the rotation of the pdf
   *
   * @param rotation
   * The new rotation value in degrees
   */
  updateRotation(rotation: number): void {
    this.rotation = rotation;
  }

  /**
   * Updates settings of the pdf
   *
   * @param setting
   * The new setting
   */
  settingSelected(setting: SettingPreset): void {
    this.activeSettings = setting;
  }

  private setPdfSource(): void {
    const isExternalFileUrl = this.isExternalFileUrl();
    this.pdfSrc = {
      url: this.src,
      withCredentials: !isExternalFileUrl
    };
  }

  private isExternalFileUrl(): boolean {
    return !this.urlService.isRelativePath(this.src) && !this.urlService.isAbsoluteBackendUrl(this.src);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {Skeleton} from '@coyo/ui/lib/ui/skeleton/skeleton'; // TODO
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {LatestBlogArticlesWidget} from '@widgets/latest-blog-articles/latest-blog-articles-widget';
import {Load} from '@widgets/latest-blog-articles/latest-blog-articles-widget/latest-blog-articles-widget.actions';
import {LatestBlogArticlesWidgetStateModel} from '@widgets/latest-blog-articles/latest-blog-articles-widget/latest-blog-articles-widget.state';
import {Reset} from '@widgets/new-colleagues/new-colleagues-widget/new-colleagues.actions';
import * as _ from 'lodash';
import {Observable} from 'rxjs';

/**
 * The latest-blog-articles widget component.
 */
@Component({
  selector: 'coyo-latest-blog-articles-widget',
  templateUrl: './latest-blog-articles-widget.component.html',
  styleUrls: ['./latest-blog-articles-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestBlogArticlesWidgetComponent extends WidgetComponent<LatestBlogArticlesWidget>
  implements OnInit, OnChanges, OnDestroy {
  state$: Observable<LatestBlogArticlesWidgetStateModel>;
  skeletonsLg: Skeleton[] = [
    {top: 0, left: 0, width: 210, height: 70},
    {top: 0, left: 234, width: 100, height: 22},
    {top: 0, left: 'calc(100% - 100px)', width: 100, height: 22},
    {top: 30, left: 234, width: 320, height: 22},
  ];

  skeletonsSm: Skeleton[] = [
    {top: 0, left: 0, width: '100%', height: 125},
    {top: 149, left: 30, width: 180, height: 22},
    {top: 179, left: 30, width: 100, height: 22},
    {top: 179, left: 'calc(100% - 130px)', width: 100, height: 22},
  ];

  private id: string;

  constructor(cd: ChangeDetectorRef, private store: Store) {
    super(cd);
  }

  ngOnInit(): void {
    this.id = this.widget.id || this.widget.tempId;
    this.state$ = this.store.select(state => state.latestBlogArticles[this.id]);
    this.store.dispatch(new Load(this.id, this.widget.settings));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const previousSettings = _.get(changes.widget, 'previousValue.settings');
    const currentSettings = _.get(changes.widget, 'currentValue.settings');
    if (!!currentSettings &&
      (!_.isEqual(previousSettings, currentSettings))) {
      this.store.dispatch(new Load(this.id, this.widget.settings));
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new Reset(this.widget.id));
  }

  /**
   * @returns The title stored in the widget settings by the widget slot (if it exists)
   */
  getTitle(): string | undefined {
    return _.get(this.widget, 'settings._title');
  }

  /**
   * @returns An array with the length of the max. number or articles to be displayed according to the widget settings
   */
  getNeededSkeletons(): number[] {
    return new Array(this.widget.settings._articleCount).map((x, i) => i);
  }
}

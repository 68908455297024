import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Share} from '@domain/share/share';
import {ShareService} from '@domain/share/share.service';
import {Shareable} from '@domain/share/shareable';
import {TimelineItemType} from '@domain/timeline-item/timeline-item-type';
import {SharesModalResults} from '@shared/social/shares/shares-modal/shares-modal-results';
import {SharesModalComponent} from '@shared/social/shares/shares-modal/shares-modal.component';
import {map} from 'rxjs/operators';
import {SharesModalData} from '../shares-modal/shares-modal-data';

/**
 * Component showing the share count of a target.
 */
@Component({
  selector: 'coyo-share-info',
  templateUrl: './share-info.component.html',
  styleUrls: ['./share-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareInfoComponent implements OnDestroy {
  private dialogRef: MatDialogRef<SharesModalComponent, SharesModalResults>;

  /**
   * The count target.
   */
  @Input() target: Shareable;

  /**
   * The number of shares.
   */
  @Input() count: number;

  /**
   * Only show the number.
   */
  @Input() condensed: boolean;

  /**
   * Emits when a share is deleted by the shares modal.
   */
  @Output() sharesDeleted: EventEmitter<Share[]> = new EventEmitter<Share[]>();

  constructor(private dialog: MatDialog,
              private shareService: ShareService) {
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  /**
   * Opens a modal listing all shares.
   */
  openSharesModal(): void {
    this.shareService.getShares(this.target.typeName, this.target.id)
      .pipe(map(shares => shares.sort((s1, s2) => s1.created < s2.created ? 1 : -1)))
      .subscribe(shares => {
        this.dialogRef = this.dialog.open<SharesModalComponent, SharesModalData>(SharesModalComponent, {
          data: {
            shares: shares,
            itemType: this.target.itemType as TimelineItemType
          }
        });
        this.dialogRef.afterClosed().subscribe(result => {
          if (result && result.deletedShares && result.deletedShares.length > 0) {
            this.sharesDeleted.emit(result.deletedShares);
          }
        });
      });
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Skeleton} from '@coyo/ui';
import {UserList} from '@shared/sender-ui/user-list/user-list';

@Component({
  selector: 'coyo-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent {
  /**
   * Received the userList to handle the information in the list
   */
  @Input() state: UserList;

  /**
   * Enables or disables the "show more" button
   */
  @Input() enableShowMore: boolean = true;

  /**
   * Emit the loadMore action to the widget when the loadMore button is pressed
   */
  @Output() load: EventEmitter<Event> = new EventEmitter();

  skeletons: Skeleton[] = [
    { top: 16, left: 0, width: 40, height: 40 }, { top: 20, left: 56, width: 120, height: 14 }, { top: 42, left: 56, width: 150, height: 12 },
    { top: 89, left: 0, width: 40, height: 40 }, { top: 93, left: 56, width: 120, height: 14 }, { top: 115, left: 56, width: 150, height: 12 },
    { top: 162, left: 0, width: 40, height: 40 }, { top: 166, left: 56, width: 120, height: 14 }, { top: 188, left: 56, width: 150, height: 12 }
  ];

  onLoadMore(): void {
    this.load.emit();
  }
}

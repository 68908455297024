import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {EntityId} from '@domain/entity-id/entity-id';
import {WidgetResponse} from '@widgets/api/widget-layout/widget-response';
import {EMPTY, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WidgetService extends DomainService<any, WidgetResponse> {

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  orderSlot(widgetIds: string[], slotName: string, parent?: EntityId): Observable<void> {
    const body: OrderRequestBody = {
      widgetIds: widgetIds
    };

    if (parent) {
      body.parentId = parent.id;
      body.parentType = parent.typeName;
    }
    return this.put('action/order', body, {
      context: {
        name: slotName
      }
    }).pipe(switchMap(() => EMPTY));
  }

  protected getBaseUrl(): string {
    return '/web/widgets/{name}';
  }
}

interface OrderRequestBody {
  widgetIds: string[];
  parentId?: string;
  parentType?: string;
}

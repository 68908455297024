import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

/**
 * Component responsible for rendering the external workspace member icon overlay for avatar images
 */
@Component({
  selector: 'coyo-external-icon',
  templateUrl: './external-icon.component.html',
  styleUrls: ['./external-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalIconComponent implements OnInit {

  /**
   * The size of the avatar
   */
  @Input() avatarSize: string;

  constructor() { }

  ngOnInit(): void {
  }

}

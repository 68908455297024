(function (angular) {
  'use strict';

  UserChooserController.$inject = ["userChooserModalService"];
  angular.module('commons.ui')
      .component('coyoUserChooser', userChooser())
      .controller('UserChooserController', UserChooserController);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoUserChooser:coyoUserChooser
   * @restrict 'E'
   * @element OWN
   * @scope
   *
   * @description
   * Displays a button which opens a user chooser modal via the {@link commons.ui.userChooserModalService} modal on
   * click. Within this modal users and/or groups can be selected. The button acts as a form field with it's own
   * `ngModel`.
   * Note: when using required, ng-model-options="{allowInvalid: true}" has to be applied, too.
   *
   * @param {object} ngModel
   * This directive requires an ngModel to store the selected users/groups.
   *
   * @param {boolean} loading
   * A flag which implies whether a spinner should be displayed or not.
   *
   * @param {string} [btnTitle=USER_CHOOSER.BUTTON_TITLE.SELECT]
   * The message key for the button title.
   *
   * @param {string} [btnTitleSingle=USER_CHOOSER.BUTTON_TITLE.SELECT.SINGLE]
   * The message key for the button title, if single=true.
   *
   * @param {boolean} [usersOnly=false]
   * Set this parameter to only select users in the chooser.
   *
   * @param {boolean} [groupsOnly=false]
   * Set this parameter to only select groups in the chooser.
   *
   * @param {boolean} [internalOnly=undefined]
   * Set this parameter to true only display internal users in the chooser. Or false to display externals only.
   *
   * @param {string} [usersField=userIds|users]
   * The field name to store the selected users/userIDs in.
   *
   * @param {string} [groupsField=groupIds|groups]
   * The field name to store the selected groups/groupIDs in.
   *
   * @param {number} [min=0]
   * The minimum number of selected users
   *
   * @param {boolean} [single=false]
   * Activates single selection
   *
   * @param {function} [onSelectionChanged=noop]
   * Function to be called when the chooser modal was closed and changes on the selection were made
   *
   * @requires commons.ui.userChooserModalService
   */
  function userChooser() {
    return {
      templateUrl: 'app/commons/ui/components/user-chooser/user-chooser.html',
      require: {
        'ngModel': '^ngModel'
      },
      bindings: {
        loading: '=',
        btnTitle: '@',
        btnTitleSingle: '@',
        usersOnly: '<?',
        groupsOnly: '<?',
        internalOnly: '<?',
        usersField: '@',
        groupsField: '@',
        min: '<?',
        single: '<?',
        onSelectionChanged: '&?'
      },
      controller: 'UserChooserController'
    };
  }

  function UserChooserController(userChooserModalService) {
    var vm = this;

    vm.$onInit = onInit;
    vm.openChooser = openChooser;

    function openChooser($event) {
      $event.preventDefault();
      if (!vm.loading) {
        vm.ngModel.$setTouched(true);
        userChooserModalService.open(angular.copy(vm.ngModel.$modelValue), vm.settings).then(_onUserChooserClosed);
      }
    }

    function _onUserChooserClosed(selection) {
      var selectedUsersAndGroups = _.pick(selection, [vm.settings.usersField, vm.settings.groupsField]);
      vm.ngModel.$setViewValue(angular.extend(vm.ngModel.$viewValue, selectedUsersAndGroups));
      vm.ngModel.$validate();

      if (selection.hasChanges) {
        vm.onSelectionChanged();
      }
    }

    function _assert(condition, message) {
      if (!condition) {
        throw new Error('[UserChooser] ' + message);
      }
    }

    function _setupValidators() {
      vm.ngModel.$render = function () {
        _checkPrerequisites();
        _setupMinValidator();
        _setupSingleValidator();
      };
      _setupEmptyValidator();
    }

    function _checkPrerequisites() {
      var model = vm.ngModel.$viewValue;
      _assert(vm.usersOnly !== true || vm.groupsOnly !== true, '"usersOnly" and "groupsOnly" must not both be set.');
      _assert(angular.isObject(model) && !angular.isArray(model), 'ngModel must be an object.');
      var onSelectionChangedSetProperly = _.isUndefined(vm.onSelectionChanged) || _.isFunction(vm.onSelectionChanged);
      _assert(onSelectionChangedSetProperly, 'onSelectionChange, if defined, must be a function.');
      if (model[vm.settings.usersField]) {
        _assert(angular.isArray(model[vm.settings.usersField]), 'ngModel.' + vm.settings.usersField
            + ' must be an array.');
        _assert(_.every(model[vm.settings.usersField], angular.isString), 'ngModel.' + vm.settings.usersField
            + ' must be an array of strings');
      }
      if (model[vm.settings.groupsField]) {
        _assert(angular.isArray(model[vm.settings.groupsField]), 'ngModel.' + vm.settings.groupsField
            + ' must be an array.');
        _assert(_.every(model[vm.settings.groupsField], angular.isString), 'ngModel.' + vm.settings.groupsField
            + ' must be an array of strings');
      }
    }

    function _setupMinValidator() {
      vm.ngModel.$validators.min = function (value) {
        if (!vm.min) {
          return true;
        } else if (vm.usersOnly === true) {
          return _validateMin(value, vm.settings.usersField);
        } else if (vm.groupsOnly === true) {
          return _validateMin(value, vm.settings.groupsField);
        } else {
          return _validateMin(value, vm.settings.usersField) || _validateMin(value, vm.settings.groupsField);
        }
      };
    }

    function _setupSingleValidator() {
      vm.ngModel.$validators.single = function (value) {
        if (!vm.single) {
          return true;
        } else if (vm.usersOnly === true) {
          return _validateSingle(value, vm.settings.usersField);
        } else if (vm.groupsOnly === true) {
          return _validateSingle(value, vm.settings.groupsField);
        } else {
          return _validateSingle(value, vm.settings.usersField) && _validateSingle(value, vm.settings.groupsField);
        }
      };
    }

    function _setupEmptyValidator() {
      vm.ngModel.$isEmpty = function (value) {
        if (angular.isUndefined(value)) {
          return true;
        } else if (vm.usersOnly === true) {
          return _checkEmpty(value, vm.settings.usersField);
        } else if (vm.groupsOnly === true) {
          return _checkEmpty(value, vm.settings.groupsField);
        } else {
          return _checkEmpty(value, vm.settings.usersField) && _checkEmpty(value, vm.settings.groupsField);
        }
      };
    }

    function _validateMin(value, key) {
      return angular.isArray(value[key]) && value[key].length >= vm.min;
    }

    function _validateSingle(value, key) {
      return !angular.isArray(value[key]) || value[key].length <= 1;
    }

    function _checkEmpty(value, key) {
      return angular.isUndefined(value[key]) || angular.isArray(value[key]) && value[key].length === 0;
    }

    function onInit() {
      var defaultButtonTitle = 'USER_CHOOSER.BUTTON_TITLE.SELECT';
      vm.btnTitle = vm.btnTitle || defaultButtonTitle;
      if (!vm.btnTitleSingle) {
        vm.btnTitleSingle =
            vm.btnTitle === defaultButtonTitle ? 'USER_CHOOSER.BUTTON_TITLE.SELECT.SINGULAR' : vm.btnTitle;
      }
      vm.onSelectionChanged = vm.onSelectionChanged || _.noop;
      vm.settings = {
        usersOnly: vm.usersOnly === true,
        groupsOnly: vm.groupsOnly === true,
        internalOnly: vm.internalOnly,
        usersField: vm.usersField || 'userIds',
        groupsField: vm.groupsField || 'groupIds',
        single: vm.single === true
      };

      if (_.isEmpty(vm.ngModel.$modelValue)) {
        vm.ngModel.$modelValue = {};
        if (!vm.settings.groupsOnly) {
          vm.ngModel.$modelValue[vm.settings.usersField] = [];
        }
        if (!vm.settings.usersOnly) {
          vm.ngModel.$modelValue[vm.settings.groupsField] = [];
        }
      }
      _setupValidators();
    }
  }

})(angular);

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '@core/auth/auth.service';
import {SettingsService} from '@domain/settings/settings.service';
import {UserService} from '@domain/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {NgxPermissionsService} from 'ngx-permissions';
import {ToastrService} from 'ngx-toastr';
import {combineLatest, from, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * A form element to change the current user's email address.
 */
@Component({
  selector: 'coyo-settings-email',
  templateUrl: './settings-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsEmailComponent implements OnInit {
  private initialValue: string;
  private currentValue: string;

  form: FormGroup;
  request$: Observable<void>;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private settingsService: SettingsService,
              private permissionsService: NgxPermissionsService,
              private translateService: TranslateService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [{value: '', disabled: true}, [CoyoValidators.notBlank], [CoyoValidators.pattern(this.settingsService, 'emailPattern')]]
    });

    combineLatest([
      this.authService.getUser(),
      from(this.permissionsService.hasPermission('MANAGE_ACCOUNT_SETTINGS'))
    ]).subscribe(([user, canManage]) => {
      this.initialValue = user.email;
      this.currentValue = user.email;
      this.form.patchValue({email: user.email});
      this.setDisabled(!canManage || user.remoteUser);
    });
  }

  /**
   * Submits the email form.
   */
  submit(): void {
    const email = this.form.get('email').value;
    if (email !== this.initialValue && email !== this.currentValue && this.form.valid) {
      this.request$ = this.userService.setEmail(email)
        .pipe(tap(() => this.currentValue = email))
        .pipe(tap(() => this.toastr.success(this.translateService.instant('SETTINGS.ACCOUNT.EMAIL.SUCCESS', {email}))));
    }
  }

  private setDisabled(disabled: boolean): void {
    return disabled
      ? this.form.controls.email.disable()
      : this.form.controls.email.enable();
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {CalloutWidget} from '@widgets/callout/callout-widget';
import {CalloutWidgetSettingsComponent} from './callout-widget-settings/callout-widget-settings.component';
import {CalloutWidgetComponent} from './callout-widget/callout-widget.component';

/**
 * The configuration for the callout widget.
 */
export const CALLOUT_WIDGET: WidgetConfig<CalloutWidget> = {
  key: 'callout',
  name: 'WIDGET.CALLOUT.NAME',
  description: 'WIDGET.CALLOUT.DESCRIPTION',
  icon: 'zmdi-alert-triangle',
  component: CalloutWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    skipOnCreate: false,
    component: CalloutWidgetSettingsComponent
  },
  renderOptions: {
    printable: true,
    panels: {
      noPanel: true
    }
  },
  whitelistExternal: true
};

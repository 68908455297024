<button type="button" mat-icon-button size="small" [matMenuTriggerFor]="menu"
        [color]="model ? 'accent' : null"
        [matTooltip]="(model ? 'MODULE.TIMELINE.STICKY.TOOLTIP.SELECTED' : 'MODULE.TIMELINE.STICKY.TOOLTIP.UNSELECTED') | translate"
        matTooltipPosition="above">
  <mat-icon svgIcon="pin"></mat-icon>
</button>
<mat-menu #menu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition">
  <span mat-menu-title>{{'MODULE.TIMELINE.STICKY.EXPIRY.LABEL' | translate}}</span>
  <button type="button" mat-menu-item *ngFor="let option of stickyExpiryOptions | stickyOptions:model"
          [attr.aria-label]="option.label + '.ARIA' | translate"
          (click)="setSelectedSticky(option.expiry)">{{option.label | translate}}</button>
</mat-menu>

(function (angular) {
  'use strict';

  LicenseIndicatorController.$inject = ["$rootScope", "$scope", "ngxLicenseService"];
  angular
      .module('commons.ui')
      .component('oyocLicenseIndicator', licenseIndicator())
      .controller('LicenseIndicatorController', LicenseIndicatorController);

  /**
   * @ngdoc directive
   * @name commons.ui.oyocLicenseIndicator:oyocLicenseIndicator
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Renders the warning indicator on admin navigation tab when license is invalid or about to expire.
   *
   * @requires $rootScope
   * @requires $scope
   * @requires ngxLicenseService
   */
  function licenseIndicator() {
    return {
      templateUrl: 'app/commons/ui/components/license-indicator/license-indicator.html',
      controller: 'LicenseIndicatorController'
    };
  }

  function LicenseIndicatorController($rootScope, $scope, ngxLicenseService) {
    var vm = this;

    vm.$onInit = _init;

    function _warn(license) {
      if (license.currentUserSize >= (license.maxUserSize * .9)) {
        return true;
      }
      if (license.currentStorageSize && license.currentStorageSize >= (license.maxStorageSize * .9)) {
        return true;
      }
      return license.validDaysLeft !== null && license.validDaysLeft < 30;
    }

    function _init() {
      var subscription = ngxLicenseService.getLicenseUpdates$().subscribe(function (license) {
        if (license.status === 'VALID') {
          vm.status = _warn(license) ? 'warning' : undefined;
        } else {
          vm.status = 'error';
        }
      });
      $scope.$on('$destroy', function () { subscription.unsubscribe(); });
      ngxLicenseService.getLicenseFromCache().subscribe();
    }
  }

})(angular);

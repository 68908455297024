<ng-container *ngIf="state$ | async; let state">
  <coyo-launchpad-header [class.floating]="scrollOffset > 0"
                         [canAddLink]="state.managableCategories.length"
                         [useColumns]="state.settings?.columns"
                         [useCondensed]="state.settings?.condensed"
                         (toggleColumns)="toggleColumns()"
                         (toggleCondensed)="toggleCondensed()"
                         (addLink)="addLink()" (close)="close('')"></coyo-launchpad-header>
  <div class="launchpad-body-wrapper" (scroll)="onScroll($event)" #launchpadBodyWrapper>
    <div class="launchpad-body"
         [class.columns]="state.settings?.columns"
         [class.condensed]="state.settings?.condensed">
      <coyo-launchpad-navigation [categories]="state.categories"
                                 [active]="active$ | async"
                                 (navigate)="navigate($event)"></coyo-launchpad-navigation>
      <coyo-launchpad-apps class="launchpad-apps" *ngIf="isO365$ | async"
                           [apps]="apps"></coyo-launchpad-apps>
      <div class="launchpad-categories">
        <ng-container *ngFor="let category of state.categories">
          <coyo-launchpad-category *ngIf="category.links.length || category._permissions?.manage"
                                   [attr.data-id]="category.id"
                                   [category]="category"
                                   (add)="addLink(category)"
                                   (edit)="editLink(category, $event)"
                                   (delete)="deleteLink(category, $event)"></coyo-launchpad-category>
        </ng-container>
        <coyo-spinner *ngIf="state.isLoading"></coyo-spinner>
      </div>
    </div>
  </div>
</ng-container>

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

/**
 * Service to manage the hashtags subscriptions.
 */
@Injectable({
  providedIn: 'root'
})
export class HashtagSettingsService {

  constructor(private http: HttpClient) {
  }

  /**
   * Retrieves all hashtags subscribed by the current user.
   *
   * @returns an Observable with the hashtags
   */
  getHashtags(): Observable<string[]> {
    return this.http.get<string[]>('/web/hashtags');
  }

  /**
   * Subscribes the current user to a new hashtag.
   *
   * @param hashtag the hashtag the user is subscribing to
   * @returns an Observable with the subscribed hashtag
   */
  addHashtag(hashtag: string): Observable<void> {
    return this.http.post<void>('/web/hashtags', {hashtag});
  }

  /**
   * Unsubscribes the current user from a hashtag.
   *
   * @param hashtag the hashtag the user is unsubscribing from
   * @returns an empty Observable
   */
  deleteHashtag(hashtag: string): Observable<void> {
    const encodedHashtag = encodeURIComponent(hashtag);
    return this.http.delete<void>(`/web/hashtags/${encodedHashtag}`);
  }
}

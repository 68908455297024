(function (angular) {
  'use strict';

  ModuleConfig.$inject = ["$stateProvider"];
  angular
      .module('coyo.admin.jobs', [
        'coyo.base',
        'coyo.domain'
      ])
      .config(ModuleConfig);

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider) {
    $stateProvider.state('admin.jobs', {
      abstract: true,
      url: '/jobs',
      template: '<ui-view></ui-view>',
      data: {
        globalPermissions: 'MANAGE_JOBS',
        pageTitle: 'ADMIN.MENU.JOBS'
      }
    }).state('admin.jobs.list', {
      url: '',
      views: {
        '@admin.jobs': {
          templateUrl: 'app/modules/admin/jobs/views/jobs-list/admin.jobs-list.html',
          controller: 'AdminJobsListController',
          controllerAs: '$ctrl'
        }
      }
    }).state('admin.jobs.details', {
      url: '/{name}',
      views: {
        '@admin.jobs': {
          templateUrl: 'app/modules/admin/jobs/views/jobs-details/admin.jobs-details.html',
          controller: 'AdminJobsDetailsController',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        job: ["JobModel", "$stateParams", function (JobModel, $stateParams) {
          return JobModel.get({name: $stateParams.name});
        }]
      }
    });
  }
})(angular);

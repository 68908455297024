import {ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {OverlayRef} from '@angular/cdk/overlay';
import {ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {O365ApiService} from '@app/integration/o365/o365-api/o365-api.service';
import {LocalStorageService} from '@core/storage/local-storage/local-storage.service';
import {LaunchpadApp} from '@domain/launchpad/launchpad-app';
import {LaunchpadCategory} from '@domain/launchpad/launchpad-category';
import {LaunchpadCategoryService} from '@domain/launchpad/launchpad-category.service';
import {LaunchpadLink} from '@domain/launchpad/launchpad-link';
import {LaunchpadLinkService} from '@domain/launchpad/launchpad-link.service';
import {OverlayComponent} from '@shared/overlay/overlay-component';
import {OverlayService} from '@shared/overlay/overlay.service';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, finalize, switchMap} from 'rxjs/operators';
import {LaunchpadCategoryComponent} from '../launchpad-category/launchpad-category.component';
import {CATEGORIES, CATEGORY, LaunchpadLinkManagerComponent, LINK} from '../launchpad-link-manager/launchpad-link-manager.component';
import {LaunchpadSettings} from './launchpad-settings';

/**
 * The internal state of the launchpad component.
 */
interface LaunchpadComponentState {
  isLoading: boolean;
  settings: LaunchpadSettings;
  categories: LaunchpadCategory[];
  managableCategories: LaunchpadCategory[];
}

/**
 * The launchpad component.
 */
@Component({
  selector: 'coyo-launchpad',
  templateUrl: './launchpad.component.html',
  styleUrls: ['./launchpad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadComponent extends OverlayComponent<{}> implements OnInit {
  private static readonly SETTINGS_KEY: string = 'launchpad-settings';
  private active: Subject<string> = new Subject<string>();
  private scrollHighlight: boolean = true;

  apps: LaunchpadApp[] = [
    {name: 'Outlook', icon: 'o365-outlook', url: 'https://outlook.office365.com'},
    {name: 'Word', icon: 'o365-word', url: 'https://www.office.com/launch/word'},
    {name: 'Excel', icon: 'o365-excel', url: 'https://www.office.com/launch/excel'},
    {name: 'PowerPoint', icon: 'o365-powerpoint', url: 'https://www.office.com/launch/powerpoint'},
    {name: 'Teams', icon: 'o365-teams', url: 'https://teams.microsoft.com'},
    {name: 'Planner', icon: 'o365-planner', url: 'https://tasks.office.com'},
    {name: 'SharePoint', icon: 'o365-sharepoint', url: ''},
    {name: 'OneNote', icon: 'o365-one-note', url: 'https://www.office.com/launch/onenote'},
    {name: 'OneDrive', icon: 'o365-one-drive', url: 'https://portal.office.com/onedrive'}
  ];

  isO365$: Observable<boolean>;
  scrollOffset: number;
  active$: Observable<string>;
  state$: BehaviorSubject<LaunchpadComponentState> = new BehaviorSubject({
    isLoading: false,
    settings: null,
    categories: [],
    managableCategories: []
  });

  @ViewChild('launchpadBodyWrapper', {
    read: ElementRef
  }) launchpadBodyWrapper: ElementRef<HTMLElement>;

  @ViewChildren(LaunchpadCategoryComponent, {
    read: ElementRef
  }) categoryComponents: QueryList<ElementRef<HTMLElement>>;

  constructor(overlayRef: OverlayRef,
              focusTrapFactory: ConfigurableFocusTrapFactory,
              private localStorageService: LocalStorageService,
              private overlayService: OverlayService,
              private launchpadCategoryService: LaunchpadCategoryService,
              private launchpadLinkService: LaunchpadLinkService,
              private o365apiService: O365ApiService) {
    super(overlayRef, focusTrapFactory);
    this.active$ = this.active.asObservable().pipe(distinctUntilChanged());
  }

  ngOnInit(): void {
    this.loadSettings();
    this.loadApps();
    this.loadCategories();
  }

  /**
   * Tracks the scrolling position to create a floating header effect
   * and to update the scrollSpy on the launchpad navigation.
   *
   * @param $event the scroll event.
   */
  onScroll($event: any): void {
    this.scrollOffset = $event.target.scrollTop;

    if (this.scrollHighlight) {
      const offsetTop = $event.target.offsetTop;
      const active = this.categoryComponents.toArray()
        .map(component => component.nativeElement)
        .find(element => element.offsetTop + element.offsetHeight - offsetTop > $event.target.scrollTop);
      this.active.next(active.dataset.id);
    }
  }

  /**
   * Scrolls to the given category and activates it.
   *
   * @param category the category to activate
   */
  navigate(category: LaunchpadCategory): void {
    this.active.next(category.id);
    this.scrollHighlight = false;
    setTimeout(() => this.scrollHighlight = true, 1000);

    if (category.id === this.state$.getValue().categories[0].id) {
      this.launchpadBodyWrapper.nativeElement.scroll({top: 0, behavior: 'smooth'});
    } else {
      const target = this.categoryComponents.toArray()
        .map(component => component.nativeElement)
        .find(element => element.dataset.id === category.id);
      const offset = target.offsetTop - this.launchpadBodyWrapper.nativeElement.offsetTop;
      this.launchpadBodyWrapper.nativeElement.scroll({top: offset - 12, behavior: 'smooth'});
    }
  }

  /**
   * Adds a new launchpad link.
   *
   * @param category the corresponding category
   */
  addLink(category?: LaunchpadCategory): void {
    const providers = [
      {provide: CATEGORY, useValue: category},
      {provide: CATEGORIES, useValue: this.state$.getValue().managableCategories}
    ];

    this.overlayService.open(LaunchpadLinkManagerComponent, {
      scrollStrategy: this.overlayService.scrollStrategies.block(),
      panelClass: ['launchpad', 'launchpad-manager'],
      hasBackdrop: false,
      height: '100%',
      width: '100%'
    }, providers).subscribe((newData: [LaunchpadCategory, LaunchpadLink]) => this.patchState({
      categories: this.updateCategories(null, newData)
    }));
  }

  /**
   * Edits the given launchpad link.
   *
   * @param category the corresponding category
   * @param link the link to be edited
   */
  editLink(category: LaunchpadCategory, link: LaunchpadLink): void {
    const providers = [
      {provide: LINK, useValue: link},
      {provide: CATEGORY, useValue: category},
      {provide: CATEGORIES, useValue: this.state$.getValue().managableCategories}
    ];

    this.overlayService.open(LaunchpadLinkManagerComponent, {
      scrollStrategy: this.overlayService.scrollStrategies.block(),
      panelClass: ['launchpad', 'launchpad-manager'],
      hasBackdrop: false,
      height: '100%',
      width: '100%'
    }, providers)
      .pipe(finalize(() => setTimeout(() => this.focus(`coyo-launchpad-link[data-id="${link.id}"] [dropdowntoggle]`))))
      .subscribe((newData: [LaunchpadCategory, LaunchpadLink]) => this.patchState({
        categories: this.updateCategories([category, link], newData)
      }));
  }

  /**
   * Deletes the given launchpad link.
   *
   * @param category the corresponding category
   * @param link the link to be deleted
   */
  deleteLink(category: LaunchpadCategory, link: LaunchpadLink): void {
    this.launchpadLinkService.delete(link.id, {
      context: {categoryId: category.id}
    }).subscribe(() => this.patchState({
      categories: this.updateCategories([category, link], null)
    }));
  }

  /**
   * Toggles the column view for the launchpad.
   */
  toggleColumns(): void {
    this.updateSettings(settings => ({columns: !settings.columns}));
  }

  /**
   * Toggles the condensed view of the launchpad.
   */
  toggleCondensed(): void {
    this.updateSettings(settings => ({condensed: !settings.condensed}));
  }

  private patchState(state: Partial<LaunchpadComponentState>): void {
    this.state$.next({...this.state$.getValue(), ...state});
  }

  private loadSettings(): void {
    this.patchState({
      settings: this.localStorageService.getValue<LaunchpadSettings>(LaunchpadComponent.SETTINGS_KEY, {
        columns: false,
        condensed: false
      })
    });
  }

  private loadApps(): void {
    this.isO365$ = this.o365apiService.isApiActive();
    this.isO365$
      .pipe(filter(isO365 => isO365))
      .pipe(switchMap(isO365 => this.o365apiService.getDefaultSite()))
      .subscribe(site => {
        const index = _.findIndex(this.apps, {name: 'SharePoint'});
        this.apps.splice(index, 1, {name: 'SharePoint', icon: 'o365-sharepoint', url: site.webUrl + '/_layouts/15/sharepoint.aspx'});
      });
  }

  private loadCategories(): void {
    this.patchState({isLoading: true});
    this.launchpadCategoryService.getAll({
      permissions: ['manage']
    }).subscribe(categories => {
      this.patchState({
        isLoading: false,
        categories,
        managableCategories: _.filter(categories, {_permissions: {manage: true}})
      });
      if (categories.length) {
        this.active.next(categories[0].id);
        setTimeout(() => this.focus('coyo-launchpad-navigation button'));
      }
    });
  }

  private updateSettings(mod: (settings: LaunchpadSettings) => Partial<LaunchpadSettings>): void {
    const oldSettings = this.state$.getValue().settings;
    const newSettings = {...oldSettings, ...mod(oldSettings)};
    this.localStorageService.setValue(LaunchpadComponent.SETTINGS_KEY, newSettings);
    this.patchState({settings: newSettings});
  }

  private updateCategories(oldData: [LaunchpadCategory, LaunchpadLink] | null,
                           newData: [LaunchpadCategory, LaunchpadLink] | null): LaunchpadCategory[] {
    const categories = this.state$.getValue().categories;
    let oldLinkIndex = -1;
    if (oldData) {
      const [oldCategory, oldLink] = oldData;
      const oldCategoryIndex = _.findIndex(categories, {id: oldCategory.id});
      oldLinkIndex = _.findIndex(categories[oldCategoryIndex].links, {id: oldLink.id});
      // update category reference to trigger change detection
      categories[oldCategoryIndex] = _.clone(categories[oldCategoryIndex]);
      categories[oldCategoryIndex].links.splice(oldLinkIndex, 1);
    }
    if (newData) {
      const [newCategory, newLink] = newData;
      const newCategoryIndex = _.findIndex(categories, {id: newCategory.id});
      // update category reference to trigger change detection
      categories[newCategoryIndex] = _.clone(categories[newCategoryIndex]);
      categories[newCategoryIndex].links.splice(oldLinkIndex < 0
        ? categories[newCategoryIndex].links.length
        : oldLinkIndex, 0, newLink);
    }
    return categories;
  }

  private focus(selector: string): void {
    // not the Angular way but the simplest one to avoid coupling...
    const element = this.overlayRef.overlayElement.querySelector<HTMLElement>(selector);
    if (element) {
      element.focus({preventScroll: true});
    }
  }
}

import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EventDateSyncService} from '@app/events/event-date-sync.service';
import {EventDates} from '@app/events/event-dates';
import {EventSettingsModalService} from '@app/events/event-settings/event-settings-modal.service';
import {rteOptions} from '@app/events/rte-options';
import {EntityId} from '@domain/entity-id/entity-id';
import {EventService} from '@domain/event/event.service';
import {SenderEvent} from '@domain/event/sender-event';
import {TranslateService} from '@ngx-translate/core';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {UserChooserSelection} from '@shared/sender-ui/user-chooser/user-chooser-selection';
import {NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import * as moment from 'moment-timezone';
import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs';

@Component({
  selector: 'coyo-event-settings',
  templateUrl: './event-settings.component.html',
  styleUrls: ['./event-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventSettingsComponent implements OnInit, OnDestroy {

  /**
   * The event id.
   * If only the event id is given the component will retrieve the full entire event.
   */
  @Input() eventId: string;

  /**
   * The event.
   */
  @Input() event?: SenderEvent;

  eventSettingsForm: FormGroup;
  eventDates: EventDates = {
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    offset: 0
  };
  adminCountMessage$: Observable<string>;
  formInitialisation$: Observable<boolean>;

  readonly slugPrefixEvents: string = '/events/';
  readonly eventDescriptionRteOptions: object = rteOptions;
  readonly timeOffset: number = 1;

  private event$: Observable<SenderEvent>;
  private formInitialisationSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private adminCountMessageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private initialAdminCount: number = 1;

  constructor(
    @Inject(NG1_STATE_SERVICE) private stateService: IStateService,
    private eventDateSyncService: EventDateSyncService,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private eventSettingsModalService: EventSettingsModalService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.formInitialisation$ = this.formInitialisationSubject.asObservable();
    this.adminCountMessage$ = this.adminCountMessageSubject.asObservable();
    this.event$ = this.event ? of(this.event) : this.eventId ? this.eventService.getEvent(this.eventId) : EMPTY;
    this.event$.subscribe({
      next: event => {
        this.initForm(event);
        this.eventId = event.id;
        this.event = event;
        this.initAdminCount();
        this.formInitialisationSubject.next(true);
      },
      error: () => {
        this.stateService.go('main.event');
      }
    });
  }

  ngOnDestroy(): void {
    this.formInitialisationSubject.complete();
    this.adminCountMessageSubject.complete();
  }

  /**
   * Submit the form.
   */
  submitForm(): void {
    if (this.eventSettingsForm.valid) {
      const eventData = {...this.event, ...this.eventSettingsForm.getRawValue()};
      if (!eventData.limitedParticipantsFlag) {
        delete eventData.limitedParticipants;
      }
      const format = 'YYYY-MM-DDTHH:mm:ss';
      if (eventData.fullDay) {
        const timezone = '+00:00';
        eventData.startDate = moment(eventData.startDate).format(format) + timezone;
        eventData.endDate = moment(eventData.endDate).format(format) + timezone;
      } else {
        moment(eventData.startDate).format(format);
        moment(eventData.endDate).format(format);
      }
      this.eventService.updateEvent(eventData).subscribe({
        next: () => {
          this.stateService.go('main.event.show.timeline', {idOrSlug: this.event.id}, {reload: true});
          this.notificationService.success('MODULE.EVENTS.EDIT.SUCCESS');
        }
      });
    }
  }

  /**
   * Cancel form and return to previous state.
   */
  cancelForm(): void {
    let state = 'main.event.show';
    let params = {idOrSlug: this.eventId};
    if (!!this.stateService['previous']) {
      const prevState = this.stateService['previous'];
      state = prevState.name;
      params = prevState.params;
    }
    this.stateService.go(state, params);
  }

  /**
   * Delete the current event and return to previous state.
   */
  deleteEvent(): void {
    this.eventSettingsModalService.openDeleteEventConfirmation().subscribe({
      next: deleteEvent => {
        if (deleteEvent) {
          this.eventService.deleteEvent(this.event.id).subscribe({
            next: () => {
              this.stateService.go('main.event');
              this.notificationService.success('EVENT.DELETE.SUCCESS');
            }
          });
        }
      }
    });
  }

  /**
   * Open a modal for a user chooser to select the admins.
   */
  openSelectAdminModal(): void {
    const entityId: EntityId = {id: this.event.id, typeName: this.event.typeName};
    this.eventSettingsModalService.openAdminSelection(entityId).subscribe({
      next: (selection: UserChooserSelection) => {
        if (selection && selection.memberIds) {
          const entityIds: EntityId[] = selection.memberIds.map(id => ({id: id, typeName: 'user'}));
          this.eventSettingsForm.get('admins').patchValue(entityIds);
          this.updateAdminCountMessage(entityIds.length);
        }
      }
    });
  }

  /**
   * Sync start and end date.
   */
  updateStartDate(): void {
    this.eventDateSyncService.updateWithStartDate(this.eventDates);
    this.updateEventDates();
  }

  /**
   * Sync start and end date.
   */
  updateEndDate(): void {
    this.eventDateSyncService.updateWithEndDate(this.eventDates);
    this.updateEventDates();
  }

  /**
   * Sync start and end time.
   */
  updateStartTime(): void {
    this.eventDateSyncService.updateWithStartTime(this.eventDates);
    this.updateEventDates();
  }

  /**
   * Sync start and end time.
   */
  updateEndTime(): void {
    this.eventDateSyncService.updateWithEndTime(this.eventDates);
    this.updateEventDates();
  }

  /**
   * Called after rte content changes.
   *
   * @param content The new content of the rte.
   */
  descriptionChanges(content: any): void {
    this.eventSettingsForm.patchValue({description: content});
  }

  private updateEventDates(): void {
    this.eventSettingsForm
      .patchValue({startDate: this.setTimeForDate(this.eventDates.startDate, this.eventDates.startTime)});
    this.eventSettingsForm
      .patchValue({endDate: this.setTimeForDate(this.eventDates.endDate, this.eventDates.endTime)});
  }

  private setTimeForDate(dateToUpdate: Date, time: Date): Date {
    const date = new Date(dateToUpdate.getTime());
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(0, 0);
    return date;
  }

  private initForm(eventData: SenderEvent): void {
    this.initDates(eventData.startDate, eventData.endDate);
    this.eventDates.offset = this.eventDateSyncService
      .differenceInMilliseconds(eventData.startDate, eventData.endDate);

    this.eventSettingsForm = this.formBuilder.group({
      id: [eventData.id, Validators.required],
      name: [eventData.displayName, [CoyoValidators.notBlank, Validators.maxLength(255)]],
      host: [eventData.host, Validators.required],
      place: [eventData.place],
      description: [eventData.description],
      fullDay: [eventData.fullDay],
      slug: [eventData.slug, CoyoValidators.notBlank],
      startDate: [moment(eventData.startDate).toDate(), Validators.required],
      endDate: [moment(eventData.endDate).toDate(), Validators.required],
      showParticipants: [eventData.showParticipants],
      requestDefiniteAnswer: [eventData.requestDefiniteAnswer],
      limitedParticipantsFlag: [this.getParticipantsLimit(eventData) > 0],
      limitedParticipants: this.formBuilder.group({
        participantsLimit: [this.getParticipantsLimit(eventData),
          this.getParticipantsLimit(eventData) > 0 ? [Validators.required, Validators.min(1)] : null]
      }),
      admins: [[]]
    });

    this.eventSettingsForm.get('limitedParticipantsFlag').valueChanges.subscribe(flag => {
      if (flag) {
        this.eventSettingsForm.get(['limitedParticipants', 'participantsLimit'])
          .setValidators([Validators.required, Validators.min(1)]);
      } else {
        this.eventSettingsForm.get(['limitedParticipants', 'participantsLimit']).clearValidators();
      }
      this.eventSettingsForm.get(['limitedParticipants', 'participantsLimit']).updateValueAndValidity();
    });

    this.eventSettingsForm.get('fullDay').valueChanges.subscribe(fullDay => {
      if (!fullDay) {
        const startDate = this.eventSettingsForm.get('startDate');
        const startTime = new Date();
        startTime.setHours(startTime.getHours() + 1, 0, 0, 0);
        startTime.setMinutes(0, 0, 0);
        startDate.patchValue(this.setTimeForDate(startDate.value, startTime));

        const endDate = this.eventSettingsForm.get('endDate');
        const endTime = new Date();
        endTime.setHours(startDate.value.getHours() + this.timeOffset, 0, 0, 0);
        endDate.patchValue(this.setTimeForDate(endDate.value, endTime));

        this.initDates(startDate.value, endDate.value);
      }
      this.eventSettingsForm.updateValueAndValidity();
    });
  }

  private initAdminCount(): void {
    this.eventService.getEventAdmins(this.eventId).subscribe(membershipPage => {
      this.initialAdminCount = membershipPage.totalElements;
      this.updateAdminCountMessage();
    });
  }

  private updateAdminCountMessage(newInvited: number = 0): void {
    const translatedValue = this.translateService
      .instant('EVENT.ADMINS.SELECTED_COUNT', {count: this.initialAdminCount + newInvited});
    this.adminCountMessageSubject.next(translatedValue);
  }

  private initDates(startDate: Date, endDate: Date): void {
    const start = moment(startDate).toDate();
    this.eventDates.startDate = start;
    this.eventDates.startTime = start;
    const end = moment(endDate).toDate();
    this.eventDates.endDate = end;
    this.eventDates.endTime = end;
  }

  private getParticipantsLimit(eventData: SenderEvent): number {
    if (!eventData.limitedParticipants || !eventData.limitedParticipants.participantsLimit) {
      return 0;
    }
    return eventData.limitedParticipants.participantsLimit;
  }

}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {LaunchpadCategory} from '@domain/launchpad/launchpad-category';

/**
 * The category navigation in the launchpad.
 */
@Component({
  selector: 'coyo-launchpad-navigation',
  templateUrl: './launchpad-navigation.component.html',
  styleUrls: ['./launchpad-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadNavigationComponent {

  /**
   * The launchpad categories.
   */
  @Input() categories: LaunchpadCategory[];

  /**
   * The ID of the active category.
   */
  @Input() active: string;

  /**
   * Event that is emitted when a navigation link is clicked.
   */
  @Output() navigate: EventEmitter<LaunchpadCategory> = new EventEmitter<LaunchpadCategory>();
}

(function (angular) {
  'use strict';

  selectSender.$inject = ["$translate", "selectFactoryModel", "SenderModel", "Pageable", "coyoConfig", "$q", "$timeout"];
  angular.module('commons.ui')
      .directive('coyoLegacySelectSender', selectSender);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoLegacySelectSender:coyoLegacySelectSender
   * @restrict 'E'
   * @scope
   *
   * @description
   * Renders a UI select field for sender selection.
   *
   * @requires $translate
   * @requires components.ui.selectFactoryModel
   * @requires coyo.domain.SenderModel
   * @requires commons.resource.Pageable
   * @requires commons.config.coyoConfig
   *
   * @param {object} ng-model (required) the currently selected sender
   * @param {string} placeholder (optional) translation key for the input field placeholder
   *
   * @deprecated please use if possible SelectSenderComponent.
   */
  function selectSender($translate, selectFactoryModel, SenderModel, Pageable, coyoConfig, $q, $timeout) {
    var requests = {};

    var selectDirective = selectFactoryModel({
      refresh: refresh,
      multiple: true,
      minSelectableItems: 6,
      mobile: false,
      emptyText: 'COMMONS.SELECT_SENDER.EMPTY',
      pageSize: 10,
      sublines: ['subline']
    });
    selectDirective.templateUrl = 'app/commons/ui/components/select-sender/select-sender.html';

    return selectDirective;

    function refresh(pageableData, search, parameters, hint, sublineMapper) {
      var pageable = new Pageable(pageableData.page, pageableData.size, 'displayName.sort');
      var filters = {type: _.get(parameters, 'allowedTypeNames', ['user', 'workspace', 'page'])};
      var fields = ['displayName'];
      var aggregations = {};
      var mode = _.get(parameters, 'mode', false);
      var findRecipient = _.get(parameters, 'findRecipient', false);
      var findOnlyManagedSenders = _.get(parameters, 'findOnlyManagedSenders', false);
      var findSharingRecipients = _.get(parameters, 'findSharingRecipients', false);
      var method;

      if (mode === 'timeline-item' || mode === 'sender') {
        var entityId = _.get(parameters, 'id');
        method = function (term, pageable, filters, searchFields, aggregations) {
          return SenderModel.searchActableSendersWithFilter(
              mode, entityId, term, pageable, filters, searchFields, aggregations);
        };
      } else if (findRecipient) {
        method = function () {
          if (_.get(parameters, 'staticOptions[0].id') !== findRecipient) {
            return SenderModel.getWithPermissions({
              id: findRecipient
            }, null, ['manage']).then(function (sender) {
              return {
                content: _.get(sender, '_permissions.manage', false) ? [sender] : []
              };
            });
          } else {
            return $q.when([]);
          }
        };
      } else if (findOnlyManagedSenders) {
        method = SenderModel.searchManagedSendersWithFilter;
      } else if (findSharingRecipients) {
        method = SenderModel.searchSharingRecipientsWithFilter;
      } else {
        method = SenderModel.searchSendersWithFilter;
      }
      var config = {
        args: [search, pageable, filters, fields, aggregations],
        method: method
      };

      for (var currentKey in requests) {
        requests[currentKey].deferred.reject('cancel pending request');
      }
      requests = {};

      var key = angular.toJson(config);
      var deferred = $q.defer();
      var promise = deferred.promise;
      var request = {config: config, deferred: deferred};
      requests[key] = request;

      $timeout(function () {
        var requestPromise = request.config.method.apply(undefined, request.config.args);
        requestPromise.then(function (response) {
          request.deferred.resolve(response);
          delete requests[key];
        });
      });

      return promise.then(function (response) {
        angular.forEach(response.content, function (item) {
          if (sublineMapper) {
            sublineMapper(item).then(function (subline) {
              item.subline = subline;
            });
          } else {
            item.subline = $translate.instant(coyoConfig.entityTypes[item.typeName].label);
          }
        });

        return angular.extend(response.content, {
          meta: {
            last: response.last,
            number: response.number
          }
        });
      });
    }
  }

})(angular);

(function (angular) {
  'use strict';

  /**
   * screen width break points to determine screen size constants.
   */
  configureLogProvider.$inject = ["$logProvider", "coyoConfig"];
  configureCompileProvider.$inject = ["$compileProvider", "coyoConfig"];
  configureLocationProvider.$inject = ["$locationProvider"];
  configureRouteProvider.$inject = ["$stateProvider", "mainStates"];
  configureAnimateProvider.$inject = ["$animateProvider"];
  configureUiSelect.$inject = ["uiSelectConfig"];
  configureLoadingBarProvider.$inject = ["cfpLoadingBarProvider"];
  configureHttpProvider.$inject = ["$httpProvider", "coyoConfig"];
  configureQProvider.$inject = ["$qProvider"];
  whitelistUrlProtocols.$inject = ["$compileProvider"];
  configHotkeys.$inject = ["hotkeysProvider"];
  setGlobalVariables.$inject = ["$rootScope", "$state"];
  registerKeyEvents.$inject = ["$rootScope", "$document"];
  setPageTitle.$inject = ["$injector", "$timeout", "$transitions"];
  applyTrackingCode.$inject = ["$rootScope", "$timeout", "$window", "SettingsModel"];
  prepareMobileAppClient.$inject = ["$rootScope", "mobileService"];
  initErrorLogService.$inject = ["errorLogService"];
  addResizeListener.$inject = ["$window", "$rootScope", "$timeout", "breakPoints"];
  requestBrowserNotificationsPermissions.$inject = ["browserNotificationsService"];
  setCurrentlyActiveTab.$inject = ["$rootScope", "$localStorage", "utilService"];
  setDebounceValues.$inject = ["$rootScope", "debounceValues"];
  replacePrintPagebreakingElements.$inject = ["$window", "$document"];
  registerTransitionHooks.$inject = ["$rootScope", "$transitions"];
  var breakPoints = {
    sm: 768,
    md: 992,
    lg: 1200
  };

  /**
   * Unified values for input/search debounce.
   */
  var debounceValues = {
    sm: 750, // should not use coyo-update-on-enter
    lg: 1500 // should use coyo-update-on-enter
  };

  /**
   * List of states to be considered as the 'main' state of the application (e.g. after login).
   * The list is traversed until a state is found for which the user matches the required permission.
   * The list is exposed as a constant to allow extending it in customizing.
   */
  var mainStates = [
    {
      state: 'main.landing-page',
      globalPermission: 'ACCESS_LANDING_PAGES'
    }, {
      state: 'main.page',
      globalPermission: 'ACCESS_PAGES'
    }, {
      state: 'main.workspace',
      globalPermission: 'ACCESS_WORKSPACES'
    }, {
      state: 'main.profile-self',
      globalPermission: 'ACCESS_OWN_USER_PROFILE'
    }, {
      state: 'main.colleagues',
      globalPermission: 'ACCESS_COLLEAGUE_LIST'
    }, {
      state: 'main.event',
      globalPermission: 'ACCESS_EVENTS'
    }
  ];

  angular
      .module('coyo.app', [
        'commons.error',
        'commons.auth',
        'commons.i18n',
        'commons.layout',
        'commons.sender',
        'commons.target',
        'commons.config',
        'commons.browsernotifications',
        'commons.mobile',
        'commons.optimistic',
        'commons.sockets',
        'commons.tour',
        'commons.terms',
        'commons.subscriptions',
        'commons.templates',
        'commons.transitionsConfig',
        'coyo.setup',
        'coyo.login',
        'coyo.registration',
        'coyo.maintenance',
        'coyo.account',
        'coyo.profile',
        'coyo.messaging',
        'coyo.admin',
        'coyo.search',
        'coyo.notifications',
        'coyo.colleagues',
        'coyo.pages',
        'coyo.landing-pages',
        'coyo.workspaces',
        'coyo.apps',
        'coyo.widgets',
        'coyo.launchpad',
        'coyo.events',
        'cfp.hotkeys'
      ])
      .constant('breakPoints', breakPoints)
      .constant('mainStates', mainStates)
      .constant('debounceValues', debounceValues)
      .config(configureLogProvider)
      .config(configureCompileProvider)
      .config(configureLocationProvider)
      .config(configureRouteProvider)
      .config(configureAnimateProvider)
      .config(configureUiSelect)
      .config(configureLoadingBarProvider)
      .config(configureHttpProvider)
      .config(configureQProvider)
      .config(whitelistUrlProtocols)
      .config(configHotkeys)
      .run(setGlobalVariables)
      .run(registerKeyEvents)
      .run(setPageTitle)
      .run(applyTrackingCode)
      .run(prepareMobileAppClient)
      .run(initErrorLogService)
      .run(addResizeListener)
      .run(requestBrowserNotificationsPermissions)
      .run(setCurrentlyActiveTab)
      .run(setDebounceValues)
      .run(replacePrintPagebreakingElements)
      .run(registerTransitionHooks);

  function configureLogProvider($logProvider, coyoConfig) {
    $logProvider.debugEnabled(coyoConfig.debug);
  }

  function configureCompileProvider($compileProvider, coyoConfig) {
    $compileProvider.debugInfoEnabled(coyoConfig.debug);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);

    $compileProvider.preAssignBindingsEnabled(true);
  }

  function configureLocationProvider($locationProvider) {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    });
  }

  function configureAnimateProvider($animateProvider) {
    $animateProvider.classNameFilter(/(animate-|ui-select-)/);
  }

  function configureRouteProvider($stateProvider, mainStates) {
    $stateProvider.state('front', {
      url: '/f',
      templateUrl: 'app/layout.front.html',
      data: {
        authenticate: false
      }
    }).state('main', {
      url: '',
      redirectTo: 'main.default',
      templateUrl: 'app/layout.main.html',
      controller: 'LayoutMainController',
      controllerAs: '$ctrl',
      resolve: {
        landingPages: /*@ngInject*/ ["$q", "authService", "LandingPageModel", function ($q, authService, LandingPageModel) {
          return authService.getUser().then(function (currentUser) {
            return currentUser.hasGlobalPermissions('ACCESS_LANDING_PAGES')
              ? LandingPageModel.queryWithPermissions({all: true}, {}, ['manage', 'manageSlots'])
              : $q.resolve([]);
          });
        }]
      },
      data: {
        authenticate: true
      }
    }).state('main.default', {
      url: '/',
      redirect: /*@ngInject*/ ["authService", function (authService) {
        return authService.getUser().then(function (currentUser) {
          var redirectState = _.find(mainStates, function (adminState) {
            return currentUser.hasGlobalPermissions(adminState.globalPermission);
          });
          return _.get(redirectState, 'state', 'main.empty');
        });
      }]
    }).state('main.empty', {
      template: '<p class="text-center text-muted" translate="ERRORS.NO_MAIN_STATE"></p>'
    });
  }

  function configureUiSelect(uiSelectConfig) {
    uiSelectConfig.appendToBody = true;
  }

  function configureLoadingBarProvider(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
    cfpLoadingBarProvider.parentSelector = '.loading-bar-container';
    cfpLoadingBarProvider.loadingBarTemplate = '<div id="loading-bar"><div class="bar"></div></div>';
  }

  function configureHttpProvider($httpProvider, coyoConfig) {
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';

    var headers = $httpProvider.defaults.headers;
    if (!headers.common) {
      headers.common = {};
    }
    headers.common['Cache-Control'] = 'no-cache, no-store';
    headers.common.Pragma = 'no-cache';
    headers.common['If-Modified-Since'] = '0';
    headers.common['X-Coyo-Frontend-Version'] = coyoConfig.versionString();
    headers.common.Accept = 'application/json';
  }

  function configureQProvider($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
  }

  function setGlobalVariables($rootScope, $state) {
    $rootScope.$state = $state;
  }

  function registerKeyEvents($rootScope, $document) {
    $document.on('keyup', function ($event) {
      if ($event.keyCode === 27) {
        $rootScope.$emit('keyup:esc', $event);
      }
    });

    $document.on('keydown', function ($event) {
      if ($event.keyCode === 27) {
        $rootScope.$emit('keydown:esc', $event);
      }
    });
  }

  function _removeTrackingScripts($window, className) {
    $window.document.querySelectorAll('.' + className).forEach(function (oldScript) {
      oldScript.remove();
    });
  }

  function _addTrackingScripts($window, trackingCode, className) {
    var divElement = $window.document.createElement('div');
    divElement.innerHTML = trackingCode;
    divElement.querySelectorAll('script').forEach(function (tmpScriptElement) {
      var scriptElement = $window.document.createElement('script');
      Array.from(tmpScriptElement.attributes).forEach(function (attr) {
        scriptElement.setAttribute(attr.name, attr.value);
      });
      scriptElement.text = tmpScriptElement.innerHTML;
      scriptElement.classList.add(className);
      $window.document.body.appendChild(scriptElement);
    });
  }

  function applyTrackingCode($rootScope, $timeout, $window, SettingsModel) {
    var trackingScriptClassName = 'tracking-code-script';
    var _applyTrackingCode = function () {
      $timeout(function () {
        _removeTrackingScripts($window, trackingScriptClassName);
        SettingsModel.retrieveByKey('trackingCode').then(function (trackingCode) {
          if (trackingCode) {
            _addTrackingScripts($window, trackingCode, trackingScriptClassName);
          }
        });
      });
    };

    $rootScope.$on('authService:login:success', _applyTrackingCode);

    _applyTrackingCode();
  }

  function setPageTitle($injector, $timeout, $transitions) {
    $transitions.onSuccess({}, function (transition) {
      var titleService = transition.injector().get('ngxPageTitleService');
      if (transition.targetState() && transition.targetState().state() && transition.targetState().state().data) {
        if (transition.targetState().state().data.pageTitle) {
          titleService.setTitle(transition.targetState().state().data.pageTitle, true);
          return;
        } else if (transition.targetState().state().data.pageTitle === false) {
          return;
        }
      }
      titleService.setTitle('');
    });
  }

  function prepareMobileAppClient($rootScope, mobileService) {
    $rootScope.app = mobileService.getInfo();
  }

  function initErrorLogService(errorLogService) {
    errorLogService.init();
  }

  /**
   * Add a resize listener that applies the current display size to the rootScope. Is initially triggered once. It also
   * contains information about the screen supports retina or not.
   */
  function addResizeListener($window, $rootScope, $timeout, breakPoints) {

    var isRetina = ($window.devicePixelRatio > 1 || ($window.matchMedia && $window.matchMedia('(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5),(min-resolution: 192dpi),(min-resolution: 2dppx)').matches));

    var _setScreenSize = function (width, $rootScope, breakPoints) {
      var screenSize = {
        isXs: width < breakPoints.sm,
        isSm: width >= breakPoints.sm && width < breakPoints.md,
        isMd: width >= breakPoints.md && width < breakPoints.lg,
        isLg: width >= breakPoints.lg,
        isRetina: isRetina
      };

      // equals & event
      if (!angular.equals($rootScope.screenSize, screenSize)) {
        var oldScreenSize = $rootScope.screenSize;
        $rootScope.screenSize = screenSize;
        $rootScope.$emit('screenSize:changed', screenSize, oldScreenSize);
      }
    };

    // add listener
    angular.element($window).on('resize', _.throttle(function () {
      $timeout(function () {
        _setScreenSize($window.innerWidth, $rootScope, breakPoints);
      });
    }, 50));

    angular.element($window).on('orientationchange', function () {
      $timeout(function () {
        _setScreenSize($window.innerWidth, $rootScope, breakPoints);
      });
    });

    // set once initially
    _setScreenSize($window.innerWidth, $rootScope, breakPoints);
  }

  /**
   * Checks whether the user needs to grant browser notifications permissions and requests it if that's the case.
   */
  function requestBrowserNotificationsPermissions(browserNotificationsService) {
    browserNotificationsService.permissionRequestNeeded().then(function (check) {
      if (check) {
        browserNotificationsService.requestPermission();
      }
    });
  }

  /**
   * Sets the currently active tab:
   * - Generates a UID for the currently active tab.
   * - Registers a (throttled) mouse move event
   * - Sets this tab as the currently active tab in the local storage to be able to check the currently active tab
   *   across tabs
   */
  function setCurrentlyActiveTab($rootScope, $localStorage, utilService) {
    if (!$rootScope.tabId) {
      $rootScope.tabId = utilService.uuid();
    }
    angular.element(document).find('body').on('mousemove', _.throttle(function () {
      $localStorage.activeTabId = $rootScope.tabId;
    }, 1000, {leading: true, trailing: false}));
  }

  function setDebounceValues($rootScope, debounceValues) {
    $rootScope.debounce = debounceValues;
  }

  function whitelistUrlProtocols($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|ms-word):/);
  }

  function configHotkeys(hotkeysProvider) {
    hotkeysProvider.includeCheatSheet = false;
  }

  /**
   * Adds onbeforeprint and onafterprint handlers that replace DOM elements which break the layout in printing.
   * Fieldsets cause firefox to only print the first page no matter how long the text is. Therefore before printing
   * they are replaced with divs and the original element is rendered into the dom on the afterprint handler.
   * https://bugzilla.mozilla.org/show_bug.cgi?id=471015
   * Not all browsers support these handlers - but we only need them for firefox at the moment.
   *
   * @requires $window
   * @requires $document
   */
  function replacePrintPagebreakingElements($window, $document) {
    var replacedElements = [];
    $window.onbeforeprint = function () {
      replacedElements = [];
      var elements = $document[0].querySelectorAll('fieldset') || [];
      Array.prototype.forEach.call(elements, function (el, index) {
        replacedElements.push(angular.element(el).replaceWith(
            angular.element('<div id="convertedfieldset' + index + '"/>').append(angular.element(el).contents())));
      });
    };

    $window.onafterprint = function () {
      replacedElements.forEach(function (originalElement, index) {
        var convertedElement = angular.element($document[0].querySelector('#convertedfieldset' + index));
        convertedElement.replaceWith(originalElement.append(convertedElement.contents()));
      });
    };
  }

  function registerTransitionHooks($rootScope, $transitions) {
    $transitions.onStart({}, function () {
      $rootScope.$broadcast('transitions:start');
    });
  }
})(angular);

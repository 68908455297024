import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {workspacesFileState} from './workspaces.state';

export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: [workspacesFileState]});

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    uiRouterModuleChild
  ]
})
export class WorkspacesModule {
}

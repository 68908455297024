import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {WelcomeWidget} from '@widgets/welcome/welcome-widget';
import {WelcomeWidgetSettingsComponent} from './welcome-widget-settings/welcome-widget-settings.component';
import {WelcomeWidgetComponent} from './welcome-widget/welcome-widget.component';

export const WELCOME_WIDGET: WidgetConfig<WelcomeWidget> = {
  key: 'welcome',
  name: 'WIDGET.WELCOME.NAME',
  description: 'WIDGET.WELCOME.DESCRIPTION',
  icon: 'zmdi-mood',
  component: WelcomeWidgetComponent,
  categories: WidgetCategory.PERSONAL,
  settings: {
    skipOnCreate: false,
    component: WelcomeWidgetSettingsComponent,
  },
  renderOptions: {
    printable: false,
    panels: {
      noPanel: true
    }
  },
  whitelistExternal: true
};

import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnChanges, OnInit, Optional, Self, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {SearchRequest} from '@domain/search/SearchRequest';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {SelectUiComponent} from '@shared/select-ui/select-ui.component';
import {SelectUiSettings} from '@shared/select-ui/select-ui.settings';
import {FindOptions, SelectSenderOptions} from '@shared/sender-ui/select-sender/select-sender-options';
import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Component to search and select a sender.
 */
@Component({
  selector: 'coyo-select-sender',
  templateUrl: './select-sender.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: MatFormFieldControl,
    useExisting: forwardRef(() => SelectSenderComponent)
  }]
})
export class SelectSenderComponent implements OnInit, OnChanges, ControlValueAccessor, MatFormFieldControl<Sender> {

  /**
   * Combined field for findOptions, senderTypes and senderFilter
   */
  @Input() options: SelectSenderOptions;

  /**
   * Placeholder string shown if no item is selected
   */
  @Input() placeholder: string;

  /**
   * The number of items per request
   */
  @Input() scrollPageSize: number = 10;

  /**
   * The distance from the bottom a user has to scroll to trigger the next page load
   * (For some reason the value of the scroll location is quite inaccurate. Will mostly load earlier than anticipated)
   */
  @Input() scrollPageTriggerOffset: number = 5;

  /**
   * The debounce time for the filter query when typing into the search field
   */
  @Input() typeaheadDebounceTime: number = 250;

  /**
   * Boolean indicating whether multiple values can be selected
   */
  @Input() multiselect: boolean = false;

  /**
   * Boolean indicating whether the dropdown closes each time a new item is added
   */
  @Input() closeOnSelect: boolean = true;

  /**
   * Boolean indicating whether a "clear all" button is displayed
   */
  @Input() clearable: boolean = true;

  /**
   * Classes to be applied to the ng-select.
   */
  @Input() selectClass?: string | string[] | Set<string> | { [klass: string]: any; };

  /**
   * Appearance to be applied to the ng-select.
   */
  @Input() selectAppearance?: string;

  /**
   * Bind the ID property of the selected model. By default binds to whole object.
   */
  @Input() bindId: boolean = false;

  /**
   * Settings object for passing settings to select ui base component
   */
  settings: SelectUiSettings<Sender>;

  /**
   * Base component for select components
   */
  @ViewChild(SelectUiComponent)
  select: SelectUiComponent<Sender>;

  readonly autofilled: boolean;
  readonly controlType: string;
  readonly disabled: boolean;
  readonly empty: boolean;
  readonly errorState: boolean;
  readonly focused: boolean;
  readonly id: string;
  readonly required: boolean;
  readonly userAriaDescribedBy: string;
  value: Sender | null;
  stateChanges: Subject<void> = new Subject<void>();

  constructor(private senderService: SenderService,
              @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get urlPath(): string {
    return {
      [FindOptions.ALL]: '/search',
      [FindOptions.MANAGED_SENDERS_ONLY]: '/search/managed',
      [FindOptions.SHARE_TARGETS_ONLY]: '/search/sharing-recipients'
    }[this.options.findOptions];
  }

  ngOnInit(): void {
    this.settings = this.createSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newSettings = this.createSettings();
    if (!_.isEqual(this.settings, newSettings)) {
      this.settings = newSettings;
    }
  }

  registerOnChange(fn: any): void {
    setTimeout(() => this.select.registerOnChange(fn), 0);
  }

  registerOnTouched(fn: any): void {
    setTimeout(() => this.select.registerOnTouched(fn), 0);
  }

  setDisabledState(isDisabled: boolean): void {
    setTimeout(() => this.select.setDisabledState(isDisabled), 0);
  }

  writeValue(value: any): void {
    setTimeout(() => {
      this.select.writeValue(value);
      this.stateChanges.next();
    }, 0);
  }

  private createSettings(): SelectUiSettings<Sender> {
    return {
      closeOnSelect: this.closeOnSelect,
      multiselect: this.multiselect,
      searchFn: this.searchFn.bind(this),
      compareFn: (a: Sender, b: Sender) => a.id === b.id,
      clearable: this.clearable,
      placeholder: this.placeholder,
      debounceTime: this.typeaheadDebounceTime,
      pageSize: this.scrollPageSize,
      scrollOffsetTrigger: this.scrollPageTriggerOffset,
      class: this.selectClass,
      appearance: this.selectAppearance,
      bind: this.bindId ? {
        value: 'id',
        initFn: (id: string) => this.senderService.get(id)
      } : null
    };
  }

  private searchFn(pageable: Pageable, term: string): Observable<Page<Sender>> {
    return this.senderService.getPage(pageable, {
      path: this.urlPath,
      params: this.buildSearchParams(term)
    }).pipe(map(this.filterPages.bind(this)));
  }

  private buildSearchParams(term: string): HttpParams {
    return new SearchRequest(term, [], {type: this.options.senderTypes}).toHttpParams();
  }

  private filterPages(page: Page<Sender>): Page<Sender> {
    if (!!this.options.showSender) {
      page.content = page.content.filter(this.options.showSender);
    }
    return page;
  }

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }
}

<div class="widget-hashtag">
  <h3 class="widget-title" translate="WIDGET.HASHTAG.TITLE"></h3>
  <ng-container *ngIf="hashtags$ | async; let hashtags; else loading">
    <ul *ngIf="hashtags.length">
      <li *ngFor="let hashtag of hashtags"
          [ngStyle]="{'opacity': hashtag.opacity, 'font-weight': hashtag.fontWeight}"
          (click)="openHashtag(hashtag)">{{hashtag.tag}}</li>
    </ul>
    <p *ngIf="!hashtags.length" class="empty text-muted" translate="WIDGET.HASHTAG.EMPTY"></p>
  </ng-container>
  <ng-template #loading>
    <div class="loading">
      <coyo-spinner size="sm"></coyo-spinner>
    </div>
  </ng-template>
</div>

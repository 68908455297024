(function (angular) {
  'use strict';

  ChampionshipAppRankingController.$inject = ["$scope", "$q", "Pageable", "app", "championshipRankingService", "colleagueGuessesModalService", "currentUser"];
  angular
      .module('coyo.apps.championship')
      .controller('ChampionshipAppRankingController', ChampionshipAppRankingController);

  function ChampionshipAppRankingController($scope, $q, Pageable, app, championshipRankingService, colleagueGuessesModalService, currentUser) {
    var vm = this;

    vm.$onInit = onInit;
    vm.open = open;

    function onInit() {
      vm.loading = true;

      $scope.$watch(function () {
        return _.get(vm, 'page.number');
      }, function () {
        vm.userRankingState = _getUserRankingState();
      });

      var pageable = new Pageable(0, 20);
      var rankingPromise = championshipRankingService.getRanking(app, pageable).then(function (data) {
        vm.page = data;
      });

      var rankingUserPromise = championshipRankingService.getRankingForUser(app, currentUser.id).then(function (data) {
        vm.userRanking = data;
        vm.userRankingState = _getUserRankingState();
      });

      $q.all(rankingPromise, rankingUserPromise).finally(function () {
        vm.loading = false;
      });
    }

    function _getUserRankingState() {
      var rankingUser = _.get(vm, 'userRanking', {});
      var rankingPage = _.get(vm, 'page.content', []);

      var userId = _.get(vm, 'userRanking.user.id');
      var allIds = _.map(rankingPage, 'user.id');
      if (rankingUser.rank === null || !allIds.length || _.includes(allIds, userId)) {
        return 0;
      } else if (rankingUser.rank <= rankingPage[0].rank) {
        return rankingUser.rank - rankingPage[0].rank - 1;
      } else if (rankingUser.rank >= rankingPage[rankingPage.length - 1].rank) {
        return rankingUser.rank - rankingPage[rankingPage.length - 1].rank + 1;
      }
      return null;
    }

    function open(user) {
      return colleagueGuessesModalService.open(app, user, vm.userRanking);
    }
  }

})(angular);

import {Selector} from '@ngxs/store';
import {License} from '@shared/license/license';
import {LicenseStateModel} from '@shared/license/license-state-model';
import {LicenseState} from '@shared/license/license.state';

/**
 * Static selectors for the LicenseState
 */
export class LicenseStateSelectors {

  @Selector([LicenseState])
  static valid(state: LicenseStateModel): boolean {
    return state.valid;
  }

  @Selector([LicenseState])
  static license(state: LicenseStateModel): License {
    return state.license;
  }
}

import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '@root/injection-tokens';
import * as _ from 'lodash';
import {interval} from 'rxjs';
import {EtagCacheEntry} from './etag-cache-entry';

/**
 * The type of the cache for ETag requests.
 */
export type EtagCache = { [key: string]: EtagCacheEntry<any> };

/**
 * The type of the cache for bulk ETag requests.
 */
export type EtagBulkCache = { [key: string]: EtagCache };

/**
 * Session storage based local cache to store REST responses with ETag headers.
 */
@Injectable({
  providedIn: 'root'
})
export class EtagCacheService {

  /**
   * The session storage key for the ETag cache.
   */
  static readonly CACHE_KEY: string = 'coyo-etagCache';

  /**
   * The session storage key for the ETag bulk cache.
   */
  static readonly BULK_CACHE_KEY: string = 'coyo-etagBulkCache';

  /**
   * The interval to check for outdated cache entries in milliseconds.
   */
  static readonly CACHE_CLEANUP_INTERVAL: number = 1000 * 60;

  /**
   * The maximum age of a cache entry in milliseconds.
   */
  static readonly CACHE_EXPIRY: number = 1000 * 60 * 60;

  private readonly cache: EtagCache;
  private readonly bulkCache: EtagBulkCache;

  constructor(@Inject(WINDOW) private window: Window) {
    this.cache = JSON.parse(this.window.sessionStorage.getItem(EtagCacheService.CACHE_KEY) || '{}');
    this.bulkCache = JSON.parse(this.window.sessionStorage.getItem(EtagCacheService.BULK_CACHE_KEY) || '{}');
    interval(EtagCacheService.CACHE_CLEANUP_INTERVAL).subscribe(() => this.purge());
  }

  /**
   * Creates a cache key from the given URL and bulk ID.
   *
   * @param url the request URL
   * @param bulkId the bulk ID
   * @returns the cache key
   */
  buildKey(url: string, bulkId?: string): string {
    return (bulkId ? url.replace(new RegExp(`[&?]${bulkId}(?:=[^&]*)?`, 'g'), '') : url)
        .replace(new RegExp('[:.]', 'g'), '');
  }

  /**
   * Checks if the given cache key exists.
   *
   * @param key the cache key
   * @returns `true` if cached data exists for the key
   */
  isCached(key: string): boolean {
    return !_.isUndefined(this.cache[key]);
  }

  /**
   * Get the cached data for the given key.
   *
   * @param key the cache key
   * @returns the cached data
   */
  getEntry(key: string): EtagCacheEntry<any> | null {
    return this.isCached(key) ? this.accessAndSave(key) : null;
  }

  /**
   * Store the response data of an ETag request.
   *
   * @param key the cache key
   * @param etag the value of ETag header sent by the backend
   * @param status the HTTP status code
   * @param body the response data
   */
  putEntry<T>(key: string, etag: string, status: number, body: T): void {
    this.accessAndSave(key, {etag, status, body: _.cloneDeep(body), access: undefined});
  }

  /**
   * Checks if the given bulk cache key exists.
   *
   * @param key the bulk cache key
   * @returns `true` if cached data exists for the key
   */
  isBulkCached(key: string): boolean {
    return !_.isUndefined(this.bulkCache[key]);
  }

  /**
   * Checks if the given bulk cache key exists with the given bulk ID.
   *
   * @param key the bulk cache key
   * @param id the bulk ID
   * @returns `true` if cached data exists for the key and ID
   */
  isBulkIdCached(key: string, id: string): boolean {
    return this.isBulkCached(key) && !_.isUndefined(this.bulkCache[key][id]);
  }

  /**
   * Get the cached data for the given key and bulk ID.
   *
   * @param key the bulk cache key
   * @param id the bulk ID
   * @returns the cached data
   */
  getBulkEntry(key: string, id: string): EtagCacheEntry<any> | null {
    return this.isBulkIdCached(key, id) ? this.accessAndSaveBulk(key, id) : null;
  }

  /**
   * Store the response data of a bulk ETag request.
   *
   * @param key the bulk cache key
   * @param id the bulk ID
   * @param etag the value of ETag header sent by the backend
   * @param status the HTTP status code
   * @param body the response data
   */
  putBulkEntry<T>(key: string, id: string, etag: string, status: number, body: T): void {
    this.accessAndSaveBulk(key, id, {etag, status, body: _.cloneDeep(body), access: undefined});
  }

  /**
   * Removes all outdated entries from the cache.
   */
  purge(): void {
    const max = new Date().getTime() - EtagCacheService.CACHE_EXPIRY;
    const isExpired = (entry: EtagCacheEntry<any>) => !!entry.access && entry.access < max;

    // purge cache
    this.deleteIf(this.cache, isExpired);
    this.window.sessionStorage.setItem(EtagCacheService.CACHE_KEY, JSON.stringify(this.cache));

    // purge bulk cache
    _.forEach(this.bulkCache, cache => this.deleteIf(cache, isExpired));
    this.deleteIf(this.bulkCache, _.isEmpty);
    this.window.sessionStorage.setItem(EtagCacheService.BULK_CACHE_KEY, JSON.stringify(this.bulkCache));
  }

  /**
   * Clear all data from the cache.
   */
  clearAll(): void {
    _(this.cache).entries().forEach(([key]) => _.unset(this.cache, key));
    _(this.bulkCache).entries().forEach(([key]) => _.unset(this.bulkCache, key));
    this.window.sessionStorage.setItem(EtagCacheService.CACHE_KEY, '{}');
    this.window.sessionStorage.setItem(EtagCacheService.BULK_CACHE_KEY, '{}');
  }

  /*
   * Set the access field on the given entry and return it.
   */
  private access(entry: EtagCacheEntry<any>): EtagCacheEntry<any> {
    return _.set(entry, 'access', new Date().getTime());
  }

  /*
   * Set the access field on the given entry, save the cache and return the entry.
   */
  private accessAndSave(key: string, entry?: EtagCacheEntry<any>): EtagCacheEntry<any> {
    const accessEntry = this.access(entry || this.cache[key]);
    _.set(this.cache, `${key}`, accessEntry);
    this.window.sessionStorage.setItem(EtagCacheService.CACHE_KEY, JSON.stringify(this.cache));
    return accessEntry;
  }

  /*
   * Set the access field on the given entry, save the cache and return the entry.
   */
  private accessAndSaveBulk(key: string, id: string, entry?: EtagCacheEntry<any>): EtagCacheEntry<any> {
    const accessEntry = this.access(entry || this.bulkCache[key][id]);
    _.set(this.bulkCache, `${key}[${id}]`, accessEntry);
    this.window.sessionStorage.setItem(EtagCacheService.BULK_CACHE_KEY, JSON.stringify(this.bulkCache));
    return accessEntry;
  }

  /*
   * Delete from cache if given property holds.
   */
  private deleteIf<T>(cache: { [key: string]: T }, property: (entry: T) => boolean): void {
    _(cache).entries()
      .filter(([_key, entry]) => property(entry))
      .forEach(([key, _entry]) => _.unset(cache, key));
  }
}

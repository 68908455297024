import {Directive, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {App} from '@apps/api/app';
import {AppConfig} from '@apps/api/app-config';
import {AppSettings} from '@apps/api/app-settings/app-settings';

/**
 * Directive for rendering the not migrated ng1 settings
 */
@Directive({
  selector: 'coyo-ng1-app-settings' // tslint:disable-line:directive-selector
})
// tslint:disable-next-line:directive-class-suffix
export class Ng1AppSettingsComponent extends UpgradeComponent implements OnInit, OnChanges {

  /**
   * The app
   */
  @Input() app: App<AppSettings>;

  /**
   * The configuration of the app
   */
  @Input() config: AppConfig<AppSettings>;

  /**
   * Event if the validity of the legacy form changes
   */
  @Output() validityChanged: EventEmitter<any>;

  constructor(ref: ElementRef, inj: Injector) {
    super('oyocAppSettingsContainer', ref, inj);
  }
}

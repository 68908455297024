/**
 * The type, how the file should be displayed
 */
export enum PreviewType {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  VIDEO = 'VIDEO',
  GSUITE_IMAGE = 'GSUITE_IMAGE',
  GSUITE_VIDEO = 'GSUITE_VIDEO',
  GSUITE_PDF = 'GSUITE_PDF',
  GSUITE_AS_PDF_EXPORTABLE_DOCUMENT = 'GSUITE_AS_PDF_EXPORTABLE_DOCUMENT',
  NO_PREVIEW = 'NO_PREVIEW'
}

import {Vec2} from '@shared/preview/file-preview/image-container/vec2';

/**
 * Data object for pinch event payload
 */
export class PinchEvent {
  constructor(public point: Vec2,
              public distance: number,
              public previousDistance: number,
              public delta: number,
              public factor: number) {

  }
}

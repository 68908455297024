import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {GoogleFileMetaData} from '@app/integration/gsuite/google-api/google-file-metadata';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {GSUITE} from '@domain/attachment/storage';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Component to indicate that a preview is not available.
 *
 * ChangeDetection.onPush has been removed in order to detect changes on the
 * Input 'file' even if the reference has not changed.
 */
@Component({
  selector: 'coyo-no-preview-available',
  templateUrl: './no-preview-available.component.html',
  styleUrls: ['./no-preview-available.component.scss']
})
export class NoPreviewAvailableComponent implements OnInit, OnChanges {

  private static WEB_CONTENT_LINK: string = 'webContentLink';

  /**
   * The file preview.
   */
  @Input() file: FilePreview;

  isDesktop$: Observable<boolean>;
  googleFileData: GoogleFileMetaData;
  isGSuiteFile: boolean;
  gSuiteActive: boolean;
  gSuiteAvailable: boolean;
  errorTitle: string;
  errorMessage: string;
  loading: boolean;

  constructor(private googleApiService: GoogleApiService, private windowSizeService: WindowSizeService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.isDesktop$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize >= ScreenSize.MD));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file && changes.file.currentValue !== changes.file.previousValue) {
      this.initFile();
    }
  }

  private initFile(): void {
    if (this.file.storage === GSUITE) {
      this.isGSuiteFile = true;

      if (!this.file.storageAvailable) {
        this.gSuiteAvailable = false;
        this.errorTitle = 'FILE_DETAILS.GSUITE_STORAGE_NOT_AVAILABLE';
        this.errorMessage = 'FILE_DETAILS.GSUITE_STORAGE_NOT_AVAILABLE.HINT';
        return;
      }

      this.errorTitle = 'FILE_DETAILS.GSUITE_FILE_EXPORT_FAILED';
      this.loading = true;
      this.googleApiService.isGoogleApiActive()
        .subscribe({
          next: isApiActive => {
            this.gSuiteActive = isApiActive;
            if (!isApiActive) {
              this.errorMessage = 'FILE_DETAILS.GSUITE_FILE_NOT_ACCESSIBLE';
              return;
            }
            this.getGoogleFileData()
              .then(data => {
                this.googleFileData = data;
                this.loading = false;
              })
              .catch(() => {
                this.handleGoogleApiError();
              });
          },
          error: () => {
            this.handleGoogleApiError();
          }
        });
    } else {
      this.gSuiteActive = false;
      this.isGSuiteFile = false;
      this.errorTitle = 'FILE_LIBRARY.PREVIEW.NOTAVAILABLE.TEXT';
    }
  }

  private handleGoogleApiError(): void {
    this.gSuiteActive = false;
    this.errorMessage = 'FILE_DETAILS.GSUITE_FILE_NOT_ACCESSIBLE';
  }

  private getGoogleFileData(): Promise<GoogleFileMetaData> {
    return this.googleApiService.getFileMetadata(this.file.fileId, NoPreviewAvailableComponent.WEB_CONTENT_LINK);
  }
}

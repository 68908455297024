import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '@domain/settings/settings.service';
import {Bookmark} from '@widgets/bookmarking/bookmark.model';

enum Step {
  Url = 'URL',
  Title = 'TITLE'
}

/**
 * Form to add a new bookmark.
 */
@Component({
  selector: 'coyo-bookmarking-add-form',
  templateUrl: './bookmarking-add-form.component.html',
  styleUrls: ['./bookmarking-add-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkingAddFormComponent implements OnInit {

  /**
   * Event when the user submitted the new bookmark.
   */
  @Output() bookmarkAdd: EventEmitter<Bookmark> = new EventEmitter<Bookmark>();

  @ViewChild('urlInput') urlInput: ElementRef<HTMLInputElement>;
  @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;

  linkPattern: string;
  url: FormControl = new FormControl('', [Validators.required]);
  bookmarkForm: FormGroup = new FormGroup({
    url: this.url,
    title: new FormControl('')
  });
  step: Step = Step.Url;

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.settingsService.retrieveByKey('linkPattern').subscribe(pattern => {
      this.linkPattern = pattern;
      this.url.setValidators([Validators.required, Validators.pattern(pattern)]);
    });
  }

  nextStep(): boolean {
    if (this.bookmarkForm.dirty) {
      this.bookmarkForm.markAsTouched();
    }
    if (this.bookmarkForm.invalid) {
      return false;
    }
    if (this.step === Step.Url) {
      this.step = Step.Title;
      setTimeout(() => {
        this.titleInput.nativeElement.focus();
      });
    } else {
      this.bookmarkAdd.emit(this.bookmarkForm.value);
      this.bookmarkForm.reset();
      this.step = Step.Url;
      setTimeout(() => {
        this.urlInput.nativeElement.focus();
      });
    }
    return true;
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Skeleton} from '@coyo/ui';
import {Target} from '@domain/sender/target';
import {TargetService} from '@domain/sender/target/target.service';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {WikiArticleWidget} from '@widgets/wiki-article/wiki-article-widget';
import {WikiArticleWidgetStateModel} from '@widgets/wiki-article/wiki-article-widget/wiki-article-widget.state';
import {Observable} from 'rxjs';
import {Init} from './wiki-article-widget.actions';

@Component({
  selector: 'coyo-wiki-article-widget',
  templateUrl: './wiki-article-widget.component.html',
  styleUrls: ['./wiki-article-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WikiArticleWidgetComponent extends WidgetComponent<WikiArticleWidget> implements OnInit, OnChanges {

  state$: Observable<WikiArticleWidgetStateModel>;
  skeletons: Skeleton[] = [
    { top: 4, left: 0, width: 120, height: 14 }, { top: 5, left: 'calc(100% - 100px)', width: 100, height: 12},
    { top: 28, left: 0, width: 240, height: 18 }
  ];

  private id: string;

  constructor(cd: ChangeDetectorRef, private store: Store, private targetService: TargetService) {
    super(cd);
  }

  ngOnInit(): void {
    this.id = this.widget.id || this.widget.tempId;
    this.state$ = this.store
      .select((state: any) => state['wikiArticleWidgetState'][this.id]);
    this.store.dispatch(new Init(this.id, this.widget.settings._articleId));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget && this.shouldReInit(changes.widget.currentValue, changes.widget.previousValue)) {
      this.store.dispatch(new Init(this.id, this.widget.settings._articleId));
    }
  }

  private shouldReInit(newValue: WikiArticleWidget, oldValue: WikiArticleWidget): boolean {
    if (!oldValue && newValue) {
      return true;
    }
    if (!newValue) {
      return false;
    }
    return oldValue.settings._articleId !== newValue.settings._articleId;
  }

  getArticleLink(target: Target): string {
    return this.targetService.getLinkTo(target);
  }
}

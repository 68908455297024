<input #timePickerInput type="text"
       class="form-control"
       [disabled]="disabled"
       [ngModel]="currentDateTimeString"
       (ngModelChange)="onInputChange($event)"
       (blur)="resetInputValue()"
       [popover]="timePickerPopover"
       [placement]="'bottom'"
       [outsideClick]="true">
<ng-template #timePickerPopover>
  <timepicker [ngModel]="currentDateTime"
              (ngModelChange)="onPickerChange($event)"
              [showMeridian]="isMeridian"
              [minuteStep]="5"></timepicker>
</ng-template>


import {LatestBlogArticlesWidgetSettings} from '@widgets/latest-blog-articles/latest-blog-articles-widget-settings';

/**
 * This action triggers a load or reload for the given widget, according to the given settings
 */
export class Load {
  static readonly type: string = '[Latest Blog Articles] load';
  constructor(public id: string, public settings: LatestBlogArticlesWidgetSettings) {}
}

/**
 * This action will remove the state of the widget with the given id from the global redux state
 */
export class Reset {
  static readonly type: string = '[Latest Blog Articles] destroy';
  constructor(public id: string) {}
}

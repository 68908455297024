<div class="btn-group" dropdown>
  <button aria-controls="dropdown-basic" class="btn option-btn dropdown-toggle btn-xs btn-zmdi" dropdownToggle
          id="button-basic"
          type="button">
    <mat-icon svgIcon="text-size"></mat-icon>
  </button>
  <ul *dropdownMenu aria-labelledby="button-basic" class="dropdown-menu" role="menu">
    <li *ngFor="let option of options" [ngClass]="{'active': widget.settings?._headline?.size === option.size}"
        role="menuitem">
      <a (click)="select(option)" [innerText]="option.size"
         class="dropdown-item">
      </a>
    </li>
  </ul>
</div>

<input type="text"
       class="form-control"
       readonly="readonly"
       tabindex="-1"
       #datepickerInput
       [disabled]="disabled"
       [placeholder]="placeholder"
       [matDatepicker]="datepicker"
       [value]="date"
       (dateChange)="datepickerValueChange($event)"
       (blur)="resetInputValue()"
       (click)="datepicker.open()">
<mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
<mat-datepicker #datepicker
                [touchUi]="isMobile$ | async"
                [startAt]="date">
</mat-datepicker>

import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {User} from '@domain/user/user';

export class InitFileDetails {
  static type: string = '[FileDetails] Init';

  constructor(
    readonly fileDetailsDialogId: string,
    readonly files: FilePreview[],
    readonly authors: { [fileId: string]: User },
    readonly options: { showAuthors: boolean, appIdOrSlug: string }) {
  }
}

export class RefreshFileInDetails {
  static type: string = '[FileDetails] Refresh file';

  constructor(
    readonly fileDetailsDialogId: string,
    readonly fileId: string) {
  }
}

export class AddFilePreview {
  static type: string = '[FileDetails] Add file preview';

  constructor(
    readonly fileDetailsDialogId: string,
    readonly filePreview: FilePreview,
    readonly fileAuthor?: User) {
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {IframeMessage} from '@shared/iframe/iframe-message';
import {IframeSandbox} from '@shared/iframe/iframe-sandbox.enum';
import * as _ from 'lodash';
import {NGXLogger} from 'ngx-logger';

/**
 * An iFrame with communication and security setup. The URL of the iFrame is
 * automatically trusted by this component.
 *
 * The sandbox attribute should be enabled for every iFrame to enable an extra
 * set of restrictions for the content in the iframe. All possible values are
 * defined in the IframeSandbox enum.
 *
 * The component also establishes a secure communication channel between the
 * parent site and the embedded content. For both ways of the communication,
 * it is ensured that only messages from the embedded URL are handled. The
 * communication then works as follows:
 *   - messages from the parent site to the child site are done by using the
 *     `send<T = any>(topic: string, payload: T | null = null): void` method
 *     in the component.
 *   - messages from the child site to the parent site are done via the
 *     `@Output() message: EventEmitter<IframeMessage<any>>` of the component.
 *     It will emit every time a message is received from the child site.
 *
 * It might be worthwhile making allow property configurable later on to allow
 * usage of camera and microphone e.g. allow = "camera 'src' 'self'; microphone
 * 'src' 'self'; fullscreen 'src' 'self';". For IE an additional allowusermedia
 * attribute would be required.
 */
@Component({
  selector: 'coyo-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeComponent implements OnChanges {

  /**
   * The URL to embed in the iFrame.
   *
   * This URL will also be used as the target origin for Window.postMessage() and
   * to validate received messages while listening on 'window:message'.
   */
  @Input() url: string;

  /**
   * The title attribute of the iframe. Needed for accessibility.
   */
  @Input() title: string;

  /**
   * Set of lifted restrictions for the content in the iFrame or null for no sandbox, so no restrictions.
   */
  @Input() sandbox: IframeSandbox[] | null = null;

  /**
   * Set to true if the <iframe> can activate fullscreen mode by calling the requestFullscreen() method.
   */
  @Input() allowFullscreen: boolean = false;

  /**
   * Setting if scrolling is allowed.
   */
  @Input() scrolling: boolean = false;

  /**
   * Specifies the events' target origins that this component should accept.
   *
   * Note that the iFrame will not except events from the specified URL
   * if origins are set and do not contain the URL.
   */
  @Input() origins: string[] = null;

  /**
   * Event emitter for messages received from the embedded iFrame content.
   */
  @Output() iFrameMessage: EventEmitter<IframeMessage> = new EventEmitter<IframeMessage>();

  @ViewChild('iframe', {static: true}) iframe: ElementRef<HTMLIFrameElement>;

  constructor(private log: NGXLogger) {
  }

  /**
   * Sends a message event to the embedded iFrame content.
   *
   * @param message an iFrame message or a JWT token to be sent
   * @param targetOrigin a specific target origin to send the message to (defaults to the iFrame's URL)
   */
  send<T extends IframeMessage>(message: T | string, targetOrigin?: string): void {
    // Always specify an exact target origin, not *, when you use postMessage
    // to send data to other windows. A malicious site can change the location
    // of the window without your knowledge, and therefore it can intercept the
    // data sent using postMessage.
    try {
      const origin = new URL(targetOrigin || this.url).origin;
      this.log.debug(`Sending message to target origin ${origin}`);
      this.iframe.nativeElement.contentWindow.postMessage(message, origin);
    } catch (e) {
      this.log.error(`Cannot send message to target origin ${targetOrigin || this.url}`, e);
    }
  }

  /**
   * Receives messages from the iFrame and delegates them to the component's output.
   *
   * @param event the message event
   */
  @HostListener('window:message', ['$event'])
  receive(event: MessageEvent): void {
    // If you do expect to receive messages from other sites, always verify the
    // sender's identity using the origin and possibly source properties.
    // Having verified identity, however, you still should always verify the
    // syntax of the received message.

    if (event.origin === 'null') {
      // Missing "allow-same-origin" in sandboxed iFrames causes event.origin to be "null" (not null).
      // Add IframeSandbox.SameOrigin to the components "sandbox" input.
      this.log.error('Cannot access event.origin in sandboxed iFrame without "allow-same-origin"');
    } else if (this.acceptMessage(event)) {
      if (this.isIframeMessage(event.data)) {
        this.iFrameMessage.emit({...event.data, origin: event.origin});
      } else {
        this.log.warn('Invalid message format', JSON.stringify(event.data));
      }
    } else {
      this.log.warn('Invalid event origin', event.origin);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // setting the iFrame's allowFullscreen and sandbox properties manually since
    // setting it via Angular bindings causes some browsers to ignore the setting.
    if (changes.allowFullscreen) {
      this.iframe.nativeElement.allowFullscreen = changes.allowFullscreen.currentValue;
    }

    if (changes.sandbox) {
      if (changes.sandbox.currentValue) {
        this.iframe.nativeElement.sandbox.value = changes.sandbox.currentValue.join(' ');
      } else {
        this.iframe.nativeElement.removeAttribute('sandbox');
      }
    }
  }

  private acceptMessage(event: MessageEvent): boolean {
    return this.origins?.length
      ? this.origins.some(origin => event.origin === new URL(origin).origin)
      : event.origin === new URL(this.url).origin;
  }

  private isIframeMessage(data: any): data is IframeMessage {
    return !!data
      && _.isString(data.iss)
      && _.isString(data.sub)
      && (!data.jti || _.isString(data.jti));
  }
}

import {ChangeDetectorRef, Component, Inject, Input, OnChanges, SimpleChanges, ViewRef} from '@angular/core';
import {Widget} from '@domain/widget/widget';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {SlotId} from '@widgets/api/widget-state.model';
import * as _ from 'lodash';
import {WidgetConfig} from './widget-config';

/**
 * A component that renders a specific widget.
 */
@Component({
  template: ''
})
export abstract class WidgetComponent<WidgetType extends Widget<WidgetSettings>> implements OnChanges {

  /**
   * The underlying widget config definition.
   */
  @Input() config: WidgetConfig<WidgetType>;

  /**
   * The actual widget data.
   */
  @Input() widget: WidgetType;

  /**
   * The state of the widget edit mode.
   */
  @Input() editMode: boolean;

  /**
   * The edit scope of the widget. This is required for firing state actions in the widget components.
   */
  @Input() editScope: string;

  /**
   * The slot name of the widget. This is required for firing state actions in the widget components.
   */
  @Input() slot: SlotId;

  constructor(@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef) {
  }

  /**
   * Call the change detector to detect changes.
   */
  detectChanges(): void {
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  // Despite of "doing nothing" this function is necessary to make change detection
  // work on the widgets that extend WidgetComponent. With this we should be able to
  // use OnPush change detection on widgets.
  ngOnChanges(changes: SimpleChanges): void {
    // default that does nothing
  }

  /**
   * Checks if the widget settings changed in any of the given keys.
   *
   * @param changes the simple changes object
   * @param keys the keys to be checked
   * @return true if the settings changed in any of the given keys.
   */
  protected changedSettingsIn(changes: SimpleChanges, ...keys: string[]): boolean {
    const prev = _.get(changes, 'widget.previousValue.settings');
    const curr = _.get(changes, 'widget.currentValue.settings');
    return _.some(keys, key => _.get(prev, key) !== _.get(curr, key));
  }

  /**
   * Checks if the widget settings changed in any keys matching the given regular expression.
   *
   * @param changes the simple changes object
   * @param keyMatch the regular expression to match the keys against
   * @return true if the settings changed in any of the matching keys.
   */
  protected changedSettingsLike(changes: SimpleChanges, keyMatch: RegExp): boolean {
    const prev = _.get(changes, 'widget.previousValue.settings');
    const curr = _.get(changes, 'widget.currentValue.settings');
    const keys = _.union(_.keys(prev), _.keys(curr)).filter(key => !!keyMatch.exec(key));
    return _.some(keys, key => _.get(prev, key) !== _.get(curr, key));
  }
}

import {NgModule} from '@angular/core';
import {UiModule} from '@coyo/ui';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {CollapseModule} from 'ngx-bootstrap';
import {AppsWidgetsModule} from './apps-widgets/apps-widgets.module';
import {GeneralSettingsComponent} from './settings/general-settings/general-settings.component';
import './settings/general-settings/general-settings.component.downgrade';
import {generalSettingsState} from './settings/general-settings/general-settings.state';

/**
 * Module for the administration area
 */
@NgModule({
  declarations: [GeneralSettingsComponent],
  entryComponents: [GeneralSettingsComponent],
  imports: [
    UiModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    CollapseModule,
    HelpModule,
    AppsWidgetsModule,
    UIRouterUpgradeModule.forChild({
      states: [generalSettingsState]
    })
  ]
})
export class AdminModule {
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {HeadlineWidget} from '@widgets/headline/headline-widget';
import {HeadlineWidgetInlineOptionsComponent} from './headline-widget-inline-options/headline-widget-inline-options.component';
import {HeadlineWidgetComponent} from './headline-widget/headline-widget.component';

export const HEADLINE_WIDGET: WidgetConfig<HeadlineWidget> = {
  key: 'headline',
  name: 'WIDGET.HEADLINE.NAME',
  description: 'WIDGET.HEADLINE.DESCRIPTION',
  icon: 'zmdi-format-color-text',
  categories: WidgetCategory.STATIC,
  renderOptions: {
    printable: true
  },
  inlineOptions: {
    component: HeadlineWidgetInlineOptionsComponent
  },
  component: HeadlineWidgetComponent,
  whitelistExternal: true,
  mobileSupportTypes: ['landing-page']
};

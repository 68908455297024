import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input} from '@angular/core';
import {O365ApiService} from '@app/integration/o365/o365-api/o365-api.service';
import {LaunchpadApp} from '@domain/launchpad/launchpad-app';
import * as _ from 'lodash';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, map, throttleTime} from 'rxjs/operators';

@Component({
  selector: 'coyo-launchpad-apps',
  templateUrl: './launchpad-apps.component.html',
  styleUrls: ['./launchpad-apps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadAppsComponent implements AfterViewInit {
  private static readonly APP_WIDTH: number = 110;
  private containerWidth: Subject<number> = new Subject<number>();
  readonly OUTLOOK: string = 'Outlook';

  /**
   * The user apps to display on the launchpad
   */
  @Input() apps: LaunchpadApp[];

  hasUnreadOutlookEmails$: Observable<boolean>;
  showExtended: BehaviorSubject<boolean> = new BehaviorSubject(false);
  visibleApps$: Observable<{
    columns: number;
    apps: LaunchpadApp[][]
  }>;

  constructor(private elementRef: ElementRef<HTMLElement>,
              private o365apiService: O365ApiService) {
    this.hasUnreadOutlookEmails$ = this.o365apiService.hasUnreadOutlookEmails();
    this.visibleApps$ = combineLatest([this.containerWidth.asObservable(), this.showExtended.asObservable()])
      .pipe(map(([width, showExtended]) => [Math.floor(width / LaunchpadAppsComponent.APP_WIDTH), showExtended]))
      .pipe(throttleTime(100))
      .pipe(distinctUntilChanged())
      .pipe(map(([number, showExtended]: [number, boolean]) => {
          const chunks = _.chunk(this.apps, number);
          return {
            columns: number,
            apps: showExtended ? chunks : [chunks[0]]
          };
        }
      ));
  }

  getWidthPercentage(maxColumns: number): string {
    const percentage = 100 / maxColumns;
    return `${percentage}%`;
  }

  trackByName(app: LaunchpadApp): string {
    return app.name;
  }

  trackByIndex(index: number): number {
    return index;
  }

  @HostListener('window:resize', ['$event.target'])
  ngAfterViewInit(): void {
    this.containerWidth.next(this.elementRef.nativeElement.offsetWidth);
  }
}

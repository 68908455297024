import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PluginService} from '@domain/plugin/plugin.service';
import {Widget} from '@domain/widget/widget';
import {Action, State, StateContext, StateOperator} from '@ngxs/store';
import {append, compose, iif, insertItem, patch, removeItem, updateItem} from '@ngxs/store/operators';
import {PatchSpec} from '@ngxs/store/operators/patch';
import {WidgetLayoutResponse} from '@widgets/api/widget-layout/widget-layout-response';
import {WidgetLayoutService} from '@widgets/api/widget-layout/widget-layout.service';
import {WidgetResponse} from '@widgets/api/widget-layout/widget-response';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {WidgetService} from '@widgets/api/widget-slot/widget.service';
import {
  EditScopeStateModel,
  InsertedLayoutsStateModel,
  InsertedWidgetsStateModel,
  SlotId,
  UpdatedLayoutsStateModel,
  UpdatedWidgetsStateModel,
  WidgetLayoutStateModel,
  WidgetStateModel
} from '@widgets/api/widget-state.model';
import {
  AddRowToLayout,
  AddWidgetToSlot,
  CancelEditMode,
  CleanupLayoutState,
  CleanupSlotState,
  CreateWidgetLayout,
  CutWidget,
  DeleteWidgetLayout,
  DuplicateWidgetLayout,
  InitializeWidgetLayout,
  InitializeWidgetSlot,
  MoveWidget,
  MoveWidgetDown,
  MoveWidgetUp,
  NotifyPlugins,
  PasteWidget,
  PersistWidgetChanges,
  PrepareNewLayoutRevision,
  RemoveRowFromLayout,
  RemoveWidgetFromSlot,
  RenameLayoutState,
  RenameSlotState,
  SetEditMode,
  ToggleMobileWidgetVisibility,
  ToggleWidgetTitleEdit,
  UpdateParent,
  UpdateWidgetSettings
} from '@widgets/api/widget.actions';
import {
  appendToWidgetSlot,
  getWidgetSlotStateAccessor,
  patchEditScope,
  patchLayout,
  updateItems
} from '@widgets/api/widget.state-operators';
import {PluginInstanceRegistryService} from '@widgets/plugin/plugin-instance-registry/plugin-instance-registry.service';
import {RICH_TEXT_WIDGET} from '@widgets/rich-text/rich-text-widget-config';
import * as _ from 'lodash';
import {concat, EMPTY, forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, defaultIfEmpty, finalize, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';

@State<WidgetStateModel>({
  name: 'widget',
  defaults: {
    editScopes: {},
    layouts: {},
    slots: {}
  }
})
@Injectable()
export class WidgetState {

  constructor(private widgetLayoutService: WidgetLayoutService,
              private widgetSlotService: WidgetService,
              private pluginService: PluginService,
              private pluginInstanceRegistryService: PluginInstanceRegistryService) {
  }

  @Action(DeleteWidgetLayout)
  deleteWidgetLayout(ctx: StateContext<WidgetStateModel>, action: DeleteWidgetLayout): void {
    ctx.setState(patch({
      editScopes: patch({
        [action.editScope]: iif(
          () => !!ctx.getState().editScopes[action.editScope].insertedLayouts.find(value => value.name === action.name),
          patch({
            insertedLayouts: removeItem<InsertedLayoutsStateModel>(value => value.name === action.name)
          }),
          patch<EditScopeStateModel>({
            deletedLayouts: append([{
              name: action.name
            }])
          }))
      }),
      layouts: patch({
        [action.name]: undefined
      })
    }));
  }

  @Action(CreateWidgetLayout)
  createWidgetLayout(ctx: StateContext<WidgetStateModel>, action: CreateWidgetLayout): void {
    const slotId = uuid();
    const newLayout: WidgetLayoutStateModel = {
      name: action.name,
      created: new Date(),
      modified: new Date(),
      parent: action.parent,
      settings: {
        rows: [{
          name: uuid(),
          slots: [{name: slotId, cols: 12}]
        }]
      }
    };
    const slotName = 'layout-' + action.name + '-slot-' + slotId;
    ctx.setState(patch<WidgetStateModel>({
      editScopes: patch({
        [action.editScope]: patch<EditScopeStateModel>({
          insertedLayouts: append<InsertedLayoutsStateModel>([newLayout])
        })
      }),
      layouts: patch({
        [action.name]: newLayout
      }),
      slots: patch({
        [slotName]: []
      })
    }));
    if (action.createInitialRte) {
      this.addWidgetToSlot(ctx, new AddWidgetToSlot({
        name: slotName,
        language: action.language
      }, RICH_TEXT_WIDGET, {}, action.parent, action.editScope));
    }
  }

  @Action(PrepareNewLayoutRevision)
  prepareNewLayoutRevision(ctx: StateContext<WidgetStateModel>, action: PrepareNewLayoutRevision): Observable<void> {
    const observables = action.langs.map(lang => {
      let oldLayoutName = action.baseLayout;
      let newLayoutName = action.newLayoutName;

      if (lang && lang !== 'NONE') {
        oldLayoutName += '-' + lang;
        newLayoutName += '-' + lang;
      }

      return this.widgetLayoutService.get(oldLayoutName, {
        handleErrors: false,
        params: this.buildGetLayoutRequestParams(action)
      }).pipe(map(layout => {
        const slotsToBeDuplicated = layout.settings.rows
          .map(row => row.slots)
          .reduce((previousValue, currentValue) => [...previousValue, ...currentValue], []);

        const widgetsToBeInserted: InsertedWidgetsStateModel[] = [];

        const slotPatchObj = {};

        slotsToBeDuplicated.forEach(slot => {
          const oldSlotName = `layout-${oldLayoutName}-slot-${slot.name}`;
          const newSlotName = `layout-${newLayoutName}-slot-${slot.name}`;

          const oldWidgets = layout.widgets[oldSlotName].map(widget => ({
            ..._.omit(widget, 'id'),
            tempId: uuid()
          } as any));
          oldWidgets.forEach(value => widgetsToBeInserted.push({
            slot: {
              name: newSlotName,
              language: lang
            },
            widget: value
          }));

          const slotStateAcessor = getWidgetSlotStateAccessor({name: newSlotName, language: lang});
          slotPatchObj[slotStateAcessor] = oldWidgets;
        });

        const newLayout = {..._.omit(layout, 'widgets'), name: newLayoutName};
        ctx.setState(patch<WidgetStateModel>({
          layouts: patch({
            [newLayoutName]: newLayout
          }),
          slots: patch(slotPatchObj),
          editScopes: patch({
            [action.editScope]: patch<EditScopeStateModel>({
              insertedLayouts: append<InsertedLayoutsStateModel>([newLayout]),
              insertedWidgets: append(widgetsToBeInserted)
            })
          })
        }));
      }));
    });
    return forkJoin(observables).pipe(mergeMap(() => EMPTY));
  }

  @Action(DuplicateWidgetLayout)
  duplicateWidgetLayout(ctx: StateContext<WidgetStateModel>, action: DuplicateWidgetLayout): void {
    const layout = {
      ...ctx.getState().layouts[action.baseLayout],
      name: action.newLayoutName
    };

    const slotsToBeDuplicated = layout.settings.rows
      .map(row => row.slots)
      .reduce((previousValue, currentValue) => [...previousValue, ...currentValue], []);

    const widgetsToBeInserted: InsertedWidgetsStateModel[] = [];

    const slotPatchObj = {};

    slotsToBeDuplicated.forEach(slot => {
      const oldSlotName = `layout-${action.baseLayout}-slot-${slot.name}`;
      const newSlotName = `layout-${action.newLayoutName}-slot-${slot.name}`;

      const oldWidgets = ctx.getState().slots[getWidgetSlotStateAccessor({
        name: oldSlotName,
        language: null // we are always duplicating from the base language which is null
      })].map(widget => ({
        ..._.omit(widget, 'id'),
        tempId: uuid()
      } as any));
      oldWidgets.forEach(value => widgetsToBeInserted.push({
        slot: {
          name: newSlotName,
          language: action.lang
        },
        widget: value
      }));

      const slotStateAccessor = getWidgetSlotStateAccessor({name: newSlotName, language: action.lang});
      slotPatchObj[slotStateAccessor] = oldWidgets;
    });

    ctx.setState(patch<WidgetStateModel>({
      layouts: patch({
        [action.newLayoutName]: layout
      }),
      slots: patch(slotPatchObj),
      editScopes: patch({
        [action.editScope]: patch<EditScopeStateModel>({
          insertedLayouts: append<InsertedLayoutsStateModel>([layout]),
          insertedWidgets: append(widgetsToBeInserted)
        })
      })
    }));
  }

  @Action(InitializeWidgetLayout)
  initializeWidgetLayout(ctx: StateContext<WidgetStateModel>, action: InitializeWidgetLayout): Observable<void> {
    if (!action.force && ctx.getState().layouts[action.name]) {
      return EMPTY; // already initialized (e.g. in a multi language context)
    }
    let layout$: Observable<WidgetLayoutResponse>;
    if (action.layout) {
      layout$ = of(action.layout);
    } else {
      layout$ = this.widgetLayoutService.get(action.name, {
        handleErrors: false,
        params: this.buildGetLayoutRequestParams(action)
      }).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            const widgetLayout: WidgetLayoutResponse = {
              name: action.name,
              created: new Date(),
              modified: new Date(),
              parent: action.parent,
              settings: {
                rows: []
              },
              widgets: {}
            };
            return of(widgetLayout);
          } else {
            return throwError(err);
          }
        }));
    }
    return layout$.pipe(
      tap(val => {
        ctx.setState(patch({
          layouts: patch({
            [action.name]: _.omit(val, 'widgets')
          })
        }));
      }), concatMap(val =>
        Object.keys(val.widgets).map(key => {
          ctx.dispatch(new InitializeWidgetSlot({
            name: key,
            language: action.language
          }, action.parent, val.widgets[key], true));
        }))
    );
  }

  @Action(InitializeWidgetSlot)
  initializeWidgetSlot(ctx: StateContext<WidgetStateModel>, action: InitializeWidgetSlot): Observable<void> {
    if (!action.force && ctx.getState().slots[getWidgetSlotStateAccessor(action.slot)]) {
      return EMPTY; // already initialized (e.g. in a multi language context)
    }

    let widgets$: Observable<WidgetResponse[]>;
    if (action.widgets) {
      widgets$ = of(action.widgets);
    } else {
      widgets$ = this.widgetSlotService.getAll({
        context: {
          name: action.slot.name
        },
        params: this.buildGetLayoutRequestParams(action)
      });
    }
    return widgets$.pipe(tap(val => {
      ctx.setState(patch({
        slots: patch({
          [getWidgetSlotStateAccessor(action.slot)]: val as any
        })
      }));
    }), switchMap(() => EMPTY));
  }

  @Action(CancelEditMode)
  cancelEditMode(ctx: StateContext<WidgetStateModel>, action: SetEditMode): void {
    this.setEditMode(ctx, action.editScope, false);
  }

  @Action(SetEditMode)
  setEditModeAction(ctx: StateContext<WidgetStateModel>, action: SetEditMode): void {
    this.setEditMode(ctx, action.editScope, action.value);
  }

  private setEditMode(ctx: StateContext<WidgetStateModel>, editScope: string, enabled: boolean): void {
    if (!ctx.getState().editScopes[editScope]) {
      ctx.setState(patch({
        editScopes: patch<EditScopeStateModel>({
          [editScope]: {
            editMode: false,
            insertedLayouts: [],
            updatedLayouts: [],
            deletedLayouts: [],
            insertedWidgets: [],
            updatedWidgets: [],
            deletedWidgets: [],
            slotOrderUpdated: [],
            clipboard: null
          }
        })
      }));
    }

    ctx.setState(patch({
      editScopes: patch({
        [editScope]:
          iif(enabled, patch({
            editMode: enabled
          }), patch<EditScopeStateModel>({
            editMode: enabled,
            insertedLayouts: [],
            updatedLayouts: [],
            deletedLayouts: [],
            insertedWidgets: [],
            updatedWidgets: [],
            deletedWidgets: [],
            slotOrderUpdated: [],
            clipboard: null
          }))
      })
    }));
  }

  @Action(UpdateParent)
  updateParent(ctx: StateContext<WidgetStateModel>, action: UpdateParent): void {
    const editScope = ctx.getState().editScopes[action.editScope];
    if (!editScope) {
      return;
    }
    let patchObj: PatchSpec<WidgetStateModel>;
    if (!action.slot) {
      patchObj = {
        editScopes: patch({
          [action.editScope]: patch<EditScopeStateModel>({
            insertedLayouts: editScope.insertedLayouts.map(elem => ({...elem, parent: action.parent})),
            insertedWidgets: editScope.insertedWidgets.map(elem => ({
              ...elem,
              widget: {...elem.widget, parent: action.parent}
            }))
          })
        })
      };
    } else if (action.slot) {
      const slotStateAccessor = getWidgetSlotStateAccessor(action.slot);
      const slotState = ctx.getState().slots[slotStateAccessor];
      patchObj = {
        slots: patch({
          [slotStateAccessor]: slotState.map(elem => ({
            ...elem, parent: action.parent
          }))
        })
      };
    }
    ctx.setState(patch(patchObj));
  }

  @Action(AddRowToLayout)
  addRowToLayout(ctx: StateContext<WidgetStateModel>, action: AddRowToLayout): void {
    ctx.setState(patchLayout(action.layout, {
      settings: patch({
        rows: insertItem({
          name: uuid(),
          slots: action.cols.map(cols => ({name: uuid(), cols: cols}))
        }, action.index)
      })
    }));

    this.updateLayoutWithSettings(ctx, action);
  }

  @Action(RemoveRowFromLayout)
  removeRowFromLayout(ctx: StateContext<WidgetStateModel>, action: RemoveRowFromLayout): void {
    ctx.setState(patchLayout(action.layout, {
      settings: patch({
        rows: removeItem(action.index)
      })
    }));

    this.updateLayoutWithSettings(ctx, action);
  }

  @Action(ToggleMobileWidgetVisibility)
  toggleMobileWidgetVisibility(ctx: StateContext<WidgetStateModel>, action: ToggleMobileWidgetVisibility): void {
    const oldWidgetState = ctx.getState().slots[getWidgetSlotStateAccessor(action.slot)]
      .find(value => value.id === action.id || value.tempId === action.id);

    ctx.dispatch(new UpdateWidgetSettings(action.slot, action.id, {
      ...oldWidgetState.settings,
      _hideMobile: !oldWidgetState.settings._hideMobile
    }, action.editScope));
  }

  @Action(AddWidgetToSlot)
  addWidgetToSlot(ctx: StateContext<WidgetStateModel>, action: AddWidgetToSlot): void {
    const widget: Widget<WidgetSettings> = {
      tempId: uuid(),
      key: action.config.key,
      parent: action.parent,
      settings: action.settings
    };
    ctx.setState(compose(patchEditScope(action.editScope, {
      insertedWidgets: append([{
        slot: action.slot,
        widget: widget
      }]),
      slotOrderUpdated: append([action.slot])
    }), appendToWidgetSlot(action.slot, widget)));
  }

  @Action(RemoveWidgetFromSlot)
  removeWidgetFromSlot(ctx: StateContext<WidgetStateModel>, action: RemoveWidgetFromSlot): void {
    ctx.setState(patch({
      editScopes: patch({
        [action.editScope]: iif(() =>
            !!ctx.getState()
              .editScopes[action.editScope]
              .insertedWidgets
              .find(event => event.widget.tempId === action.widget.tempId),
          patch<EditScopeStateModel>({
            insertedWidgets: removeItem<InsertedWidgetsStateModel>(item => item.widget.tempId === action.widget.tempId)
          }),
          patch<EditScopeStateModel>({
            deletedWidgets: append([{
              id: action.widget.id,
              slotName: action.slot.name
            }])
          }))
      }),
      slots: patch({
        [getWidgetSlotStateAccessor(action.slot)]: removeItem<Widget<WidgetSettings>>(widget => widget.id ?
          widget.id === action.widget.id
          : widget.tempId === action.widget.tempId)
      })
    }));
  }

  @Action(UpdateWidgetSettings)
  updateWidgetSettings(ctx: StateContext<WidgetStateModel>, action: UpdateWidgetSettings): void {
    ctx.setState(patch({
      editScopes: patch({
        [action.editScope]: iif(() =>
            !!ctx.getState().editScopes[action.editScope].insertedWidgets
              .find(event => event.widget.tempId === action.id),
          patch<EditScopeStateModel>({
            insertedWidgets: updateItem<InsertedWidgetsStateModel>(item => item.widget.tempId === action.id,
              patch<InsertedWidgetsStateModel>({
                widget: patch({
                  settings: action.settings
                }),
                slot: action.slot
              }))
          }),
          patch<EditScopeStateModel>({
            updatedWidgets: iif(value =>
                !!value.find((event: any) => event.id === action.id),
              updateItem(item => item.id === action.id, {
                id: action.id,
                settings: action.settings,
                slotName: action.slot.name
              }), append([{
                id: action.id,
                settings: action.settings,
                slotName: action.slot.name
              }])
            )
          }))
      }),
      slots: patch({
        [getWidgetSlotStateAccessor(action.slot)]: updateItem<Widget<WidgetSettings>>(widget =>
          widget.id === action.id || widget.tempId === action.id, patch({
          settings: action.settings
        }))
      })
    }));
  }

  @Action(ToggleWidgetTitleEdit)
  toggleWidgetTitleEdit(ctx: StateContext<WidgetStateModel>, action: ToggleWidgetTitleEdit): void {
    const currentInputId = ctx.getState().editScopes[action.editScope].titleInputDisplayedForWidgetId;
    ctx.setState(patchEditScope(action.editScope, {
      titleInputDisplayedForWidgetId: currentInputId === action.widgetId ? '' : action.widgetId
    }));
  }

  @Action(MoveWidgetDown)
  moveWidgetDown(ctx: StateContext<WidgetStateModel>, action: MoveWidgetDown): void {
    const index = ctx.getState().slots[getWidgetSlotStateAccessor(action.slot)]
      .findIndex(value => value.id === action.widgetId || value.tempId === action.widgetId);
    this.moveWidgetToIndex(ctx, action, index, index + 1);
  }

  @Action(MoveWidgetUp)
  moveWidgetUp(ctx: StateContext<WidgetStateModel>, action: MoveWidgetUp): void {
    const index = ctx.getState().slots[getWidgetSlotStateAccessor(action.slot)]
      .findIndex(value => value.id === action.widgetId || value.tempId === action.widgetId);

    this.moveWidgetToIndex(ctx, action, index, index - 1);
  }

  @Action(CutWidget)
  cutWidget(ctx: StateContext<WidgetStateModel>, action: CutWidget): void {
    ctx.setState(patchEditScope(action.editScope, {
      clipboard: {
        slot: action.slot,
        widgetId: action.widgetId
      }
    }));
  }

  @Action(PasteWidget)
  pasteWidget(ctx: StateContext<WidgetStateModel>, action: PasteWidget): void {
    const clipboard = ctx.getState().editScopes[action.editScope].clipboard;

    const removeIndex = ctx.getState().slots[getWidgetSlotStateAccessor(clipboard.slot)]
      .findIndex(value => value.id === clipboard.widgetId || value.tempId === clipboard.widgetId);
    const element = ctx.getState().slots[getWidgetSlotStateAccessor(clipboard.slot)][removeIndex];

    const slotStateOperator: StateOperator<any> = clipboard.slot.name === action.slot.name
      ? patch({
        [getWidgetSlotStateAccessor(clipboard.slot)]: compose(removeItem(removeIndex), append([element]))
      })
      : patch({
        [getWidgetSlotStateAccessor(clipboard.slot)]: removeItem(removeIndex),
        [getWidgetSlotStateAccessor(action.slot)]: append([element])
      });

    ctx.setState(compose(patchEditScope(action.editScope, {
      clipboard: null,
      slotOrderUpdated: append([action.slot])
    }), patch({
      slots: slotStateOperator
    })));

    ctx.dispatch(new UpdateWidgetSettings(action.slot, element.id || element.tempId, element.settings, action.editScope));
  }

  @Action(MoveWidget)
  moveWidget(ctx: StateContext<WidgetStateModel>, action: MoveWidget): void {
    const removeIndex = ctx.getState().slots[getWidgetSlotStateAccessor(action.prevSlot)]
      .findIndex(value => value.id === action.widgetId || value.tempId === action.widgetId);
    const element = ctx.getState().slots[getWidgetSlotStateAccessor(action.prevSlot)][removeIndex];

    if (action.prevSlot.name === action.currSlot.name) {
      this.moveWidgetInSameSlot(ctx, action, element);
    } else {
      this.moveWidgetInOtherSlot(ctx, action, element, removeIndex);
    }
  }

  @Action(RenameLayoutState)
  renameLayoutState(ctx: StateContext<WidgetStateModel>, action: RenameLayoutState): void {
    const currentVal = ctx.getState().layouts[action.oldName];
    ctx.setState(patch<WidgetStateModel>({
      editScopes: patch({
        [action.editScope]: patch<EditScopeStateModel>({
          insertedLayouts: updateItem<InsertedLayoutsStateModel>(value => value.name === action.oldName, patch({
            name: action.newName
          })),
          updatedLayouts: updateItem<InsertedLayoutsStateModel>(value => value.name === action.oldName, patch({
            name: action.newName
          }))
          // not for deleted because those can not change their name!
        })
      }),
      layouts: patch({
        [action.oldName]: undefined,
        [action.newName]: {...currentVal, name: action.newName}
      })
    }));
  }

  @Action(RenameSlotState)
  renameSlotState(ctx: StateContext<WidgetStateModel>, action: RenameSlotState): void {
    const currentVal = ctx.getState().slots[action.oldName];
    ctx.setState(patch<WidgetStateModel>({
      editScopes: patch({
        [action.editScope]: patch<EditScopeStateModel>({
          insertedWidgets: updateItems<InsertedWidgetsStateModel>(value => value.slot.name === action.oldName, patch({
            slot: patch({
              name: action.newName
            })
          })),
          updatedWidgets: updateItems<UpdatedWidgetsStateModel>(value => value.slotName === action.oldName, patch({
            slotName: action.newName
          })),
          slotOrderUpdated: updateItems<SlotId>(value => value.name === action.oldName, patch({
            name: action.newName
          }))
        })
      }),
      slots: patch({
        [action.oldName]: undefined,
        [action.newName]: currentVal
      })
    }));
  }

  @Action(CleanupLayoutState)
  cleanupLayoutState(ctx: StateContext<WidgetStateModel>, action: CleanupLayoutState): void {
    ctx.setState(patch<WidgetStateModel>({
      layouts: patch({
        [action.name]: undefined
      })
    }));
  }

  @Action(CleanupSlotState)
  cleanupSlotState(ctx: StateContext<WidgetStateModel>, action: CleanupSlotState): void {
    ctx.setState(patch<WidgetStateModel>({
      slots: patch({
        [getWidgetSlotStateAccessor(action.slot)]: undefined
      })
    }));
  }

  @Action(PersistWidgetChanges)
  persistWidgetChanges(ctx: StateContext<WidgetStateModel>, action: PersistWidgetChanges): Observable<unknown> {
    const insertLayoutRequests = this.buildInsertLayoutRequests(ctx, action);
    const updateLayoutRequests = this.buildUpdateLayoutRequests(ctx, action);
    const deleteLayoutRequests = this.buildDeleteLayoutRequests(ctx, action);
    const insertWidgetRequests = this.buildInsertWidgetRequests(ctx, action);
    const updateWidgetRequests = this.buildUpdateWidgetRequests(ctx, action);
    const deleteWidgetRequests = this.buildDeleteWidgetRequests(ctx, action);

    return this.buildSaveRequest(insertLayoutRequests, updateLayoutRequests, deleteLayoutRequests,
      insertWidgetRequests, updateWidgetRequests, deleteWidgetRequests, ctx, action);
  }

  @Action(NotifyPlugins)
  notifyPlugins(ctx: StateContext<WidgetStateModel>, action: NotifyPlugins): void {
    const pluginSrcIds = _(ctx.getState().slots)
      .values()
      .flatten()
      .filter(widget => PluginService.isWidgetKey(widget.key))
      .map('settings._srcId')
      .value();

    if (pluginSrcIds.length) {
      this.pluginService.getEditTokens(pluginSrcIds, action.action)
        .subscribe(tokenMap => _.forEach(tokenMap, (token, srcId) => {
          const widget = this.pluginInstanceRegistryService.get(srcId);
          // we rely on the fact that the origins have already been resolved
          const origins = widget?.origin$.getValue();
          if (origins) {
            _.forEach(origins, origin => widget?.iFrame.send(token, origin));
          } else {
            widget?.iFrame.send(token);
          }
        }));
    }
  }

  private moveWidgetInSameSlot(ctx: StateContext<WidgetStateModel>, action: MoveWidget, element: Widget<WidgetSettings>): void {
    ctx.setState(compose(patchEditScope(action.editScope, {
      slotOrderUpdated: append([{name: action.prevSlot.name, language: action.prevSlot.language}])
    }), (patch({
      slots: patch({
        [getWidgetSlotStateAccessor(action.prevSlot)]: compose(removeItem(action.prevIdx), insertItem(element, action.currIdx))
      })
    }))));
  }

  private moveWidgetInOtherSlot(ctx: StateContext<WidgetStateModel>, action: MoveWidget, element: Widget<WidgetSettings>, removeIndex: number): void {
    const slotStateOperator: StateOperator<any> = patch({
      [getWidgetSlotStateAccessor(action.prevSlot)]: removeItem(removeIndex),
      [getWidgetSlotStateAccessor(action.currSlot)]: insertItem(element, action.currIdx)
    });

    ctx.setState(patch({
      slots: slotStateOperator
    }));

    ctx.setState(patchEditScope(action.editScope, {
      slotOrderUpdated: append([{name: action.currSlot.name, language: action.currSlot.language}])
    }));
    ctx.dispatch(new UpdateWidgetSettings(action.currSlot, element.id || element.tempId, element.settings, action.editScope));
  }

  private buildGetLayoutRequestParams(action: InitializeWidgetSlot | InitializeWidgetLayout | PrepareNewLayoutRevision): { [key: string]: string } {
    const params: { [key: string]: string } = {
      includeWidgets: 'true'
    };

    if (_.get(action.parent, 'id', '') !== ''
      && _.get(action.parent, 'typeName', '') !== '') {
      params.parentId = action.parent.id;
      params.parentType = action.parent.typeName;
    }
    return params;
  }

  private updateLayoutWithSettings(ctx: StateContext<WidgetStateModel>,
                                   action: AddRowToLayout | RemoveRowFromLayout): void {
    ctx.setState(patch({
      editScopes: patch({
        [action.editScope]: iif(() =>
            !!ctx.getState()
              .editScopes[action.editScope]
              .updatedLayouts
              .find(event => event.name === action.layout),
          patch<EditScopeStateModel>({
            updatedLayouts: updateItem<UpdatedLayoutsStateModel>(item => item.name === action.layout, {
              name: action.layout,
              settings: {
                ...ctx.getState().layouts[action.layout].settings
              }
            })
          }),
          patch<EditScopeStateModel>({
            updatedLayouts: append<UpdatedLayoutsStateModel>([{
              name: action.layout,
              settings: {
                ...ctx.getState().layouts[action.layout].settings
              }
            }])
          }))
      })
    }));
  }

  private moveWidgetToIndex(ctx: StateContext<WidgetStateModel>, action: MoveWidgetDown, fromIndex: number, toIndex: number): void {
    const element = ctx.getState().slots[getWidgetSlotStateAccessor(action.slot)][fromIndex];
    ctx.setState(compose(patchEditScope(action.editScope, {
      slotOrderUpdated: append([{name: action.slot.name, language: action.slot.language}])
    }), (patch({
      slots: patch({
        [getWidgetSlotStateAccessor(action.slot)]: compose(removeItem(fromIndex), insertItem(element, toIndex))
      })
    }))));
  }

  private buildSaveRequest(insertLayoutRequests: Observable<WidgetLayoutResponse>[],
                           updateLayoutRequests: Observable<WidgetLayoutResponse>[],
                           deleteLayoutRequests: Observable<void>[],
                           insertWidgetRequests: Observable<WidgetResponse>[],
                           updateWidgetRequests: Observable<WidgetResponse>[],
                           deleteWidgetRequests: Observable<void>[],
                           ctx: StateContext<WidgetStateModel>,
                           action: PersistWidgetChanges): Observable<unknown> {
    const editScope = ctx.getState().editScopes[action.editScope];
    return concat(
      forkJoin(deleteLayoutRequests).pipe(defaultIfEmpty({})),
      forkJoin(insertLayoutRequests).pipe(defaultIfEmpty({})),
      forkJoin(updateLayoutRequests).pipe(defaultIfEmpty({})),
      forkJoin(insertWidgetRequests).pipe(defaultIfEmpty({})),
      forkJoin(updateWidgetRequests).pipe(defaultIfEmpty({})),
      forkJoin(deleteWidgetRequests).pipe(defaultIfEmpty({}), concatMap(() => {
        // these requests need to be built after the state has been updated with the new widget id's and so on
        const toBeOrderedSlots = _.uniqWith(editScope.slotOrderUpdated, _.isEqual);
        const orderObservables = toBeOrderedSlots.filter(slotId => {
          const slot = ctx.getState().slots[getWidgetSlotStateAccessor(slotId)];
          return slot && slot.length > 1;
        })
          .map(slotId => {
            const widgets = ctx.getState().slots[getWidgetSlotStateAccessor(slotId)];
            const widgetIds = widgets.map(widget => widget.id);
            return this.widgetSlotService.orderSlot(widgetIds, slotId.name, widgets[0].parent);
          });
        return forkJoin(orderObservables).pipe(defaultIfEmpty({}));
      }))
    ).pipe(finalize(() => ctx.dispatch(new SetEditMode(action.editScope, false))));
  }

  private buildDeleteWidgetRequests(ctx: StateContext<WidgetStateModel>, action: PersistWidgetChanges): Observable<void>[] {
    const editScope = ctx.getState().editScopes[action.editScope];

    return editScope.deletedWidgets.map(event => this.widgetSlotService.delete(event.id, {
      context: {
        name: event.slotName
      }
    }));
  }

  private buildUpdateWidgetRequests(ctx: StateContext<WidgetStateModel>, action: PersistWidgetChanges): Observable<WidgetResponse>[] {
    const editScope = ctx.getState().editScopes[action.editScope];

    return editScope.updatedWidgets.map(event => this.widgetSlotService.put(event.id, {
      settings: event.settings
    }, {
      context: {
        name: event.slotName
      }
    }));
  }

  private buildInsertWidgetRequests(ctx: StateContext<WidgetStateModel>, action: PersistWidgetChanges): Observable<WidgetResponse>[] {
    const editScope = ctx.getState().editScopes[action.editScope];

    return editScope.insertedWidgets.map(event => this.widgetSlotService.post(event.widget, {
      context: {
        name: event.slot.name
      }
    }).pipe(tap(response => {
      const slotStateAccessor = getWidgetSlotStateAccessor(event.slot);
      if (!ctx.getState().slots[slotStateAccessor]) {
        return;
      }
      ctx.setState(patch({
        slots: patch({
          [slotStateAccessor]: updateItem<Widget<WidgetSettings>>(value => value.tempId === event.widget.tempId, patch({
            id: response.id
          }))
        })
      }));
    })));
  }

  private buildDeleteLayoutRequests(ctx: StateContext<WidgetStateModel>, action: PersistWidgetChanges): Observable<void>[] {
    const editScope = ctx.getState().editScopes[action.editScope];

    return editScope.deletedLayouts.map(deletedEvent =>
      this.widgetLayoutService.delete(deletedEvent.name, {}));
  }

  private buildUpdateLayoutRequests(ctx: StateContext<WidgetStateModel>, action: PersistWidgetChanges): Observable<WidgetLayoutResponse>[] {
    const state = ctx.getState();
    const editScope = state.editScopes[action.editScope];

    return editScope.updatedLayouts.map(updateEvent =>
      this.widgetLayoutService.put(updateEvent.name, state.layouts[updateEvent.name], {}));
  }

  private buildInsertLayoutRequests(ctx: StateContext<WidgetStateModel>, action: PersistWidgetChanges): Observable<WidgetLayoutResponse>[] {
    const editScope = ctx.getState().editScopes[action.editScope];

    return editScope.insertedLayouts.map(insertEvent => {
      const body: any = {
        settings: _.merge({}, insertEvent.settings)
      };

      if (insertEvent.parent) {
        body.parentId = insertEvent.parent.id;
        body.parentType = insertEvent.parent.typeName;
      }

      return this.widgetLayoutService.post(body, null, insertEvent.name);
    });
  }
}

(function (angular) {
  'use strict';

  FileFieldController.$inject = ["$scope", "authService", "SenderModel"];
  angular
      .module('coyo.apps.commons.fields')
      .controller('FileFieldController', FileFieldController);

  function FileFieldController($scope, authService, SenderModel) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.noAppContext = $scope.config.appFormSettings.noAppContext;
      vm.fileLibraryOptions = {selectMode: $scope.config.settings.multiple ? 'multiple' : 'single'};
      if (vm.noAppContext) {
        authService.getUser().then(function (user) {
          vm.sender = user;
        });
      } else {
        SenderModel.getWithPermissions($scope.config.senderId, {}, ['manage', 'createFile']).then(function (sender) {
          vm.sender = sender;
        });
      }
    }
  }

})(angular);

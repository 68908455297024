import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';
import * as moment from 'moment';
import {Observable, timer} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

const momentFn = moment;

/**
 * Displays the date & time relative to a given date. Hovering over the text displays the exact date in a tooltip.
 * E.g. prints `A few seconds ago`
 *
 * @deprecated Use the `time-ago` pipe from the `time` module instead.
 */
@Component({
  selector: 'coyo-time-ago',
  templateUrl: './time-ago.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeAgoComponent implements OnInit {

  /**
   * The date which the relative time is displayed for
   */
  @Input() date: Date;

  /**
   * Position of the tooltip.
   */
  @Input() tooltipPosition: TooltipPosition = 'below';

  /**
   * Extends tooltip text.
   */
  @Input() textExtend: string;

  /**
   * Determines whether the 'ago' suffix should be displayed
   */
  @Input() displayAgo: boolean = true;

  $formattedDate: Observable<string>;

  ngOnInit(): void {
    this.$formattedDate = timer(0, 60000)
      .pipe(startWith())
      .pipe(map(() => momentFn(this.date).fromNow(!this.displayAgo)));
  }
}

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DeleteConfirmationDialogData} from '@shared/dialog/delete-confirmation/delete-confirmation-dialog-data';

/**
 * A component to dynamically render a {@link WidgetComponent}.
 */
@Component({
  selector: 'coyo-widget-deletion-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteConfirmationDialogComponent {

  confirmed: boolean;

  constructor(private dialog: MatDialogRef<DeleteConfirmationDialogComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: DeleteConfirmationDialogData) {
    this.confirmed = !data.needsConfirmation;
  }

  ok(): void {
    this.dialog.close(true);
  }

  cancel(): void {
    this.dialog.close(false);
  }

  setConfirmed(checked: boolean): void {
    this.confirmed = checked;
  }
}

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {FilePreviewAnimationLoader} from '@shared/preview/file-preview/animation/file-preview-animation-loader/file-preview-animation-loader';

/**
 * The component can be used to show the file preview generation animation.
 */
@Component({
  selector: 'coyo-file-preview-animation-loader',
  template: ''
})
export class FilePreviewAnimationLoaderComponent implements OnInit {

  constructor(private loader: FilePreviewAnimationLoader, private vcr: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.createFilePreviewAnimationComponent();
  }

  private createFilePreviewAnimationComponent(): void {
    this.loader.load()
      .then(factory => this.vcr.createComponent(factory).instance);
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {InterestingColleaguesWidget} from '@widgets/interesting-colleagues/interesting-colleagues-widget';
import {InterestingColleaguesWidgetComponent} from '@widgets/interesting-colleagues/interesting-colleagues-widget/interesting-colleagues-widget.component';

export const INTERESTING_COLLEAGUES_WIDGET: WidgetConfig<InterestingColleaguesWidget> = {
  key: 'interestingcolleagues',
  name: 'WIDGETS.INTERESTINGCOLLEAGUES.NAME',
  description: 'WIDGETS.INTERESTINGCOLLEAGUES.DESCRIPTION',
  defaultTitle: 'WIDGETS.INTERESTINGCOLLEAGUES.NAME',
  icon: 'zmdi-share',
  component: InterestingColleaguesWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  renderOptions: {
    printable: false,
  },
  whitelistExternal: false
};

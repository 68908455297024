import {InjectionToken} from '@angular/core';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';

/**
 * A custom injection token for RTE plugins.
 */
export const RTE_PLUGINS: InjectionToken<RtePlugin[]> =
  new InjectionToken<RtePlugin[]>('rte-plugins');

/**
 * Base class for all custom RTE plugins.
 */
export abstract class RtePlugin {
  private isInitialized: boolean = false;

  /**
   * Initializes the plugin if it is not initialized yet.
   *
   * @param settings the RTE settings
   * @param force if `true` it forces reinitialization
   */
  initialize(settings: RteSettings, force?: boolean): void {
    if (!this.isInitialized || force) {
      this.doInitialize(settings);
      this.isInitialized = true;
    }
  }

  /**
   * Initializes the plugin.
   *
   * @param settings the RTE settings
   */
  protected abstract doInitialize(settings: RteSettings): void;

  /**
   * Collects available dropdown options from the list of provided Froala command keys.
   *
   * @param froala the Froala instance
   * @param keys the list of command keys
   * @returns a lit of dropdown options
   */
  protected getDropdownOptions(froala: any, ...keys: string[]): { [key: string]: string; } {
    const options = {} as { [key: string]: string; };
    keys.forEach(key => {
      if (froala.COMMANDS[key]) {
        options[key] = froala.COMMANDS[key].title;
      }
    });
    return options;
  }
}

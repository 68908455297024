import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

/**
 * A pipe to format the offset (in minutes) of a time zone, e.g. "+01:00"
 * for an offset of 60 minutes, "+01:30" for an offset of 90 minutes or
 * "-02:00" for an offset of -120.
 */
@Pipe({
  name: 'timezoneOffset'
})
export class TimezoneOffsetPipe implements PipeTransform {

  transform(offset: number): string {
    const sgn = offset < 0 ? '-' : '+';
    const hrs = Math.abs(Math.floor(offset / 60));
    const mns = Math.abs(offset % 60);
    return `${sgn}${_.padStart('' + hrs, 2, '0')}:${_.padStart('' + mns, 2, '0')}`;
  }
}

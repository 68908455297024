import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IframeMessage} from '@shared/iframe/iframe-message';
import {WidgetEditService} from '@widgets/api/widget-edit/widget-edit.service';
import {CONFIG_KEY_PREFIX, PluginWidgetSettings} from '@widgets/plugin/plugin-widget-settings.model';
import {PluginWidgetComponent} from '@widgets/plugin/plugin-widget/plugin-widget.component';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {first, pluck, switchMap} from 'rxjs/operators';
import {PluginEventHandler} from '../plugin-event-handler';

/**
 * A plugin event handler for initialization events.
 *
 * This handler initializes a plug-in via a back end request. The response
 * contains a set of context properties (e.g. user ID or tenant ID), the
 * global and local configuration of the plug-in as well as the current
 * state of the edit mode. The response is a signed JWT token that is
 * forwarded to the plug-in front end.
 */
@Injectable()
export class PluginInitEventHandlerService extends PluginEventHandler<IframeMessage> {
  private static readonly URL: string = '/web/plugins';
  private static readonly SUB: string = 'init';

  constructor(private http: HttpClient,
              private widgetEditService: WidgetEditService) {
    super();
  }

  canHandle(sub: string): boolean {
    return sub === PluginInitEventHandlerService.SUB;
  }

  handle(pluginComponent: PluginWidgetComponent, event: IframeMessage): Observable<string> {
    const pluginId = this.getPluginId(pluginComponent);
    const pluginSettings = this.getPluginSettings(pluginComponent);
    const url = `${PluginInitEventHandlerService.URL}/${pluginId}/init`;

    return this.widgetEditService.editModeEnabled$()
      .pipe(first())
      .pipe(switchMap(mode => {
        const headers = {handleErrors: 'false'};
        const params = _.pickBy({
          srcId: pluginComponent.srcId,
          edit: mode.toString(),
          jti: event.jti
        }, param => !_.isNil(param));
        return this.http.put<{ config: string }>(url, this.getConfig(pluginSettings), {headers, params});
      })).pipe(pluck('token'));
  }

  private getConfig(pluginSettings: PluginWidgetSettings): { [key: string]: any } {
    return _.chain(pluginSettings)
      .pickBy((value, key) => key.startsWith(CONFIG_KEY_PREFIX))
      .mapKeys((value, key) => key.slice(CONFIG_KEY_PREFIX.length))
      .value();
  }
}

import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  Input,
  OnChanges,
  SimpleChanges, ViewChild
} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {GSUITE} from '@domain/attachment/storage';
import {VideoPreviewReference} from '@shared/preview/file-preview/file-video-preview/video-preview-reference';
/**
 * This Components handles the video preview
 */
@Component({
  selector: 'coyo-file-video-preview',
  templateUrl: './file-video-preview.component.html',
  styleUrls: ['./file-video-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileVideoPreviewComponent implements OnChanges {

  /**
   * Attached file
   */
  @Input() file: VideoPreviewReference;
  /**
   * File preview url
   */
  @Input() url: string;
  /**
   * File groupId
   */
  @Input() groupId: string;

  /**
   * Show video controls. Default is true.
   */
  @Input() showControls: boolean = true;

  /**
   * Get the reference to the displayed video
   */
  @ViewChild('videoPlayer', {static: false}) videoPlayer: ElementRef;

  videoUrl: string;

  constructor(private urlService: UrlService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.videoUrl = !!this.file ? this.createVideoUrl() : null;
  }

  isGSuiteVideo(): boolean {
    return !!this.file && this.file.storage === GSUITE;
  }

  private createVideoUrl(): string {
    return this.isGSuiteVideo()
      ? this.createGSuitePreviewUrl()
      : this.createInternalVideoUrl();
  }

  private createInternalVideoUrl(): string {
    const baseUrl = this.urlService.getBackendUrl()
      + this.urlService.insertPathVariablesIntoUrl(this.url, {groupId: this.groupId, id: this.file.id});
    return baseUrl + '/stream#t=0.001';
  }

  private createGSuitePreviewUrl(): string {
    return 'https://drive.google.com/file/d/' + this.file.fileId + '/preview';
  }

  /**
   * Played or paused the video depends on the currant status
   */
  togglePlay(): void {
     this.isPaused() ? this.play() : this.pause();
  }

  /**
   * Checks if the video is paused
   * @return boolean if video is paused or not
   */
  isPaused(): boolean {
    return this.getCurrentVideo().paused;
  }

  /**
   * Increase the volume of the video
   */
  volumeUp(): void {
    const volume = this.getCurrentVideo().volume + 0.1;
    this.getCurrentVideo().volume = volume > 1 ? 1 : volume;
  }

  /**
   * Decrease the volume of the video
   */
  volumeDown(): void {
    const volume = this.getCurrentVideo().volume - 0.1;
    this.getCurrentVideo().volume = volume < 0 ? 0 : volume;
  }

  /**
   * Jump to a specific point at the video
   * @param scale between 0 and 9 for the position where the video should be jump to
   */
  jumpTo(scale: number): void {
    this.getCurrentVideo().currentTime = this.getCurrentVideo().duration * (scale / 10);
  }

  private play(): void {
    this.getCurrentVideo().play();
  }
  private pause(): void {
    this.getCurrentVideo().pause();
  }
  private getCurrentVideo(): HTMLVideoElement {
    return this.videoPlayer.nativeElement;
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Locale} from '@shared/multilanguage/locale';

/**
 * A component to select locales / languages
 */
@Component({
  selector: 'coyo-locale-picker',
  templateUrl: './locale-picker.component.html',
  styleUrls: ['./locale-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalePickerComponent {
  /**
   * Available locales to pick from
   */
  @Input() locales: Locale[];

  /**
   * Initially selected locale
   */
  @Input() selectedLocale: Locale;

  /**
   * Emits an event when the user selects a different locale
   */
  @Output() readonly localeChanged: EventEmitter<Locale> = new EventEmitter<Locale>();

  /**
   * Selects a locale. If the locale is different from the currently selected locale,
   * localeChanged will be emitted.
   *
   * @param locale The locale selected
   */
  selectLocale(locale: Locale): void {
    if (!locale.equals(this.selectedLocale)) {
      this.selectedLocale = locale;
      this.localeChanged.emit(this.selectedLocale);
    }
  }
}

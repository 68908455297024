<mat-form-field [class.mat-form-field-invalid]="validateAll && !allValid()" appearance="outline" class="no-hint">
  <mat-label *ngIf="showLabel">{{'SELECT_LANGUAGE' | translate}}</mat-label>
  <ng-select (ngModelChange)="setLanguage($event)"
             [(ngModel)]="selectedLanguage"
             [clearable]="false"
             [items]="languages"
             [required]="true"
             [searchable]="false"
             appearance="outline"
             appendTo="body"
             bindValue="language"
             class="mat-ng-select">
    <ng-template let-item="item" ng-label-tmp>
      <span class="language-option">
        <coyo-country-flag *ngIf="showFlags" [locale]="item.language  | locale" class="item-flag"></coyo-country-flag>
        <span class="name">{{'LANGUAGE.LANGUAGES.' + item.language | uppercase | translate}}</span>
      </span>
    </ng-template>
    <ng-template let-item="item" ng-option-tmp>
      <span class="language-option">
        <coyo-country-flag *ngIf="showFlags" [locale]="item.language | locale" class="item-flag"></coyo-country-flag>
        <span class="name">{{'LANGUAGE.LANGUAGES.' + item.language | uppercase | translate}}</span>
        <mat-icon *ngIf="item.created === false" svgIcon="plus"></mat-icon>
        <mat-icon *ngIf="item.created === true && !item.valid" [svgIcon]="validationIconFailed" color="warn"></mat-icon>
        <mat-icon *ngIf="item.created === true && item.valid" [svgIcon]="validationIconSuccess"></mat-icon>
      </span>
    </ng-template>
  </ng-select>
</mat-form-field>

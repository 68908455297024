import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {LayoutType} from '@widgets/api/widget-layout-chooser-modal/layout-type';

@Component({
  selector: 'coyo-widget-layout-chooser-modal',
  templateUrl: './widget-layout-chooser-modal.component.html',
  styleUrls: ['./widget-layout-chooser-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetLayoutChooserModalComponent {

  layouts: LayoutType[] = [
    {slots: [3, 6, 3]},
    {slots: [3, 9]},
    {slots: [9, 3]},
    {slots: [8, 4]},
    {slots: [4, 8]},
    {slots: [4, 4, 4]},
    {slots: [3, 3, 3, 3]},
    {slots: [6, 6]},
    {slots: [12]}
  ];

  constructor(private dialogRef: MatDialogRef<WidgetLayoutChooserModalComponent>, private translateService: TranslateService) {
  }

  save(slot: LayoutType): void {
    this.dialogRef.close(slot);
  }

  getLayoutAriaLabel(index: number): string {
    return this.layouts[index].slots.toString() + ' ' + this.translateService.instant('WIDGETS.LAYOUT.ROW.LAYOUT.INFO');
  }
}

<!--IMAGE PREVIEW-->
<div class="pi-preview-image-container"
     [class.has-animated-gif]="isGifPlaybackAllowed()"
     [class.gif-is-running]="gifIsRunning">
  <img *ngIf="shouldShowImgPreview()"
       [src]="imageSrc" (load)="imageLoaded()" class="max-image-size" data-test="file-preview__preview-image"
       draggable="false">
  <div *ngIf="shouldShowBackgroundImg()"
       [style.background-image]="'url(' + imageSrc + ')'"
       class="pi-background-container"
       draggable="false">
  </div>
  <span class="gif-play-button" (click)="onClickGifPlayButton($event)"><i class="zmdi zmdi-play-circle-outline zmdi-hc-2x"></i></span>
  <span class="alt-details-link"><i class="zmdi zmdi-info"></i></span>
  <coyo-spinner class="pi-spinner-vert-container gif-spinner" *ngIf="loadingGif"></coyo-spinner>
</div>

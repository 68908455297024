import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Share} from '@domain/share/share';
import * as _ from 'lodash';
import {SharesModalData} from './shares-modal-data';
import {SharesModalResults} from './shares-modal-results';

/**
 * Renders the shares modal view.
 */
@Component({
  selector: 'coyo-shares-modal',
  templateUrl: './shares-modal.component.html',
  styleUrls: ['./shares-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharesModalComponent implements OnInit, OnDestroy {
  private modalChanges: SharesModalResults;

  constructor(private dialogRef: MatDialogRef<SharesModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: SharesModalData) {
  }

  ngOnInit(): void {
    this.modalChanges = {
      deletedShares: []
    };
  }

  ngOnDestroy(): void {
    this.dialogRef.close(this.modalChanges);
  }

  /**
   * Deletes the share and emits a delete event.
   *
   * @param share The share.
   */
  deleteShare(share: Share): void {
    const idx = _.findIndex(this.data.shares, {id: share.id});
    if (idx > -1) {
      this.data.shares.splice(idx, 1);
      this.modalChanges.deletedShares.push(share);
    }
  }
}

/**
 * Component for rendering the engage blog article view.
 */
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Terms} from '@domain/terms/terms';
import {TermsService} from '@domain/terms/terms.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'coyo-terms-view',
  templateUrl: './terms-view.component.html',
  styleUrls: ['./terms-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsViewComponent implements OnInit {

  terms$: Observable<Terms>;

  constructor(private termsService: TermsService) {
  }

  ngOnInit(): void {
    this.terms$ = this.termsService.getBestSuitable();
  }
}

<h3 class="widget-title">{{title || ("WIDGET.COMPLETE_PROFILE.TITLE" | translate)}}</h3>
<ul *ngIf="fields$ | async; let fields; else loading">
  <li *ngFor="let field of fields">
    <a mat-button size="small" class="block-left" [uiSref]="field.state" [uiParams]="field.params" [class.completed]="field.value">
      <mat-icon [svgIcon]="field.value ? 'check' : 'close'" size="small"></mat-icon>
      <span class="task-label">{{ field.label | translate }}</span>
    </a>
  </li>
</ul>
<ng-template #loading>
  <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
</ng-template>

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {User} from '@domain/user/user';

/**
 * Component responsible for showing an Engage download hint.
 */
@Component({
  selector: 'coyo-engage-download-hint',
  templateUrl: './engage-download-hint.component.html',
  styleUrls: ['./engage-download-hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngageDownloadHintComponent implements OnInit {

  currentUser: User;

  platforms: string[] = ['IOS', 'ANDROID'];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.getUser().subscribe(user => this.currentUser = user);
  }
}

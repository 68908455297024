import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {Widget} from '@domain/widget/widget';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {SlotId} from '@widgets/api/widget-state.model';
import {WidgetComponent} from '../widget-component';
import {WidgetConfig} from '../widget-config';

/**
 * A component to dynamically render a {@link WidgetComponent}.
 */
@Component({
  selector: 'coyo-widget-container',
  template: '',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetContainerComponent implements OnInit, OnChanges {

  private componentRef: ComponentRef<WidgetComponent<Widget<WidgetSettings>>>;

  /**
   * The underlying widget config definition.
   */
  @Input() config: WidgetConfig<Widget<WidgetSettings>>;

  /**
   * The actual widget data.
   */
  @Input() widget: any;

  /**
   * The state of the widget edit mode.
   */
  @Input() editMode: boolean;

  /**
   * The edit scope of the widget. This is required for firing state actions in the widget components.
   */
  @Input() editScope: string;

  /**
   * The slot name of the widget. This is required for firing state actions in the widget components.
   */
  @Input() slot: SlotId;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.config.component);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    this.updateComponentRefValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef) {
      this.updateComponentRefValues();
      this.componentRef.instance.ngOnChanges(changes);
      this.componentRef.instance.detectChanges();
    }
  }

  private updateComponentRefValues(): void {
    this.componentRef.instance.config = this.config;
    this.componentRef.instance.widget = this.widget;
    this.componentRef.instance.editMode = this.editMode;
    this.componentRef.instance.editScope = this.editScope;
    this.componentRef.instance.slot = this.slot;
  }
}

import {Drive} from '@app/integration/o365/o365-api/domain/drive';
import {BaseDriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/base-drive-file-picker-item';
import {DriveItemFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/drive-item-file-picker-item';
import {FetchDriveItems} from '@app/integration/o365/share-point-file-picker/share-point-file-picker.service';
import {OFFICE365, StorageType} from '@domain/attachment/storage';
import {Observable} from 'rxjs';

/**
 * This class creates a FilePickerItem based on a SharePoint Drive entity
 */
export class DriveFilePickerItem implements BaseDriveFilePickerItem {
  readonly id: string;
  readonly name: string;
  readonly isFolder: boolean;
  readonly isSearchResult: boolean;
  readonly sizeInBytes: number | null;
  readonly lastModified: number;
  readonly mimeType: string | null;
  readonly storageType: StorageType;

  constructor(private drive: Drive, private fetchDriveItems: FetchDriveItems) {
    this.id = drive.id;
    this.name = drive.name;
    this.isFolder = true;
    this.isSearchResult = false;
    this.sizeInBytes = null;
    this.lastModified = new Date(this.drive.lastModifiedDateTime).getTime();
    this.mimeType = null;
    this.storageType = OFFICE365;
  }

  static fromArray(drives: Drive[], fetchDriveItems: FetchDriveItems): DriveFilePickerItem[] {
    return drives.map(drive => new DriveFilePickerItem(drive, fetchDriveItems));
  }

  getChildren(): Observable<DriveItemFilePickerItem[]> {
    return this.fetchDriveItems(this.drive.id);
  }
}

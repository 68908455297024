<ng-container *ngxPermissionsOnly="['LIKE']">
  <ng-container *ngIf="(likeState$ | async) as likeState">
    <span class="divider" *ngIf="prependDivider && condensed && likeState.likingSenderCount">•</span>
    <!-- tracking information -->
    <div style="display: none"
         [attr.data-like-target-id]="target.id"
         [attr.data-like-target-type]="target.typeName"
         [attr.data-like-count]="likeState.likingSenderCount"></div>
    <button mat-button [class.mat-link-button]="condensed" type="button" size="small" (click)="openLikes()" data-test="like-info-button"
            *ngIf="likeState.likingSenderCount"
            [matTooltip]="(tooltip$ | async)"
            matTooltipClass="like-info-tooltip">
      <mat-icon *ngIf="!textual" svgIcon="thumbs-up-circled"></mat-icon>
      {{condensed ? likeState.likingSenderCount : 'MODULE.SOCIAL.LIKE.TEXT' | translate : getLikeMessageData(likeState)}}
    </button>
  </ng-container>
</ng-container>

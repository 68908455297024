<coyo-attachment-list class="comment-form-attachments" [selectedAttachments]="attachments$ | async"
                      (attachmentRemoved)="removeAttachment($event)"></coyo-attachment-list>
<form name="commentsForm"
      class="form-horizontal coyo-new"
      data-test="comment-form"
      [formGroup]="form"
      (ngSubmit)="submit(form.value)">
  <div class="form-header">
    <coyo-sender-avatar [sender]="comment?.author || author" avatarSize="sm" [noLink]="true"></coyo-sender-avatar>
    <div class="bubble">
      <div class="textarea-autosize-wrapper">
       <textarea class="form-control"
                 data-test="timeline-comment-message"
                 name="message"
                 #message
                 formControlName="message"
                 coyoMention
                 [placeholder]="'COMMENTS.FORM.PLACEHOLDER' | translate"
                 [attr.aria-label]="'COMMENTS.FORM.PLACEHOLDER' | translate"
                 autosize rows="1" [minRows]="1"
                 (focus)="onFocus()"
                 data-test="timeline-comment-message-edit-mode"></textarea>
      </div>
      <coyo-emoji-picker #emojiPicker [input]="message" class="hidden-xs"></coyo-emoji-picker>
      <coyo-attachment-btn id="attachments" formControlName="attachments"
                           [sender]="target"
                           [context]="author"
                           xPosition="before"></coyo-attachment-btn>
      <button mat-icon-button type="submit" size="small" class="mobile-submit-button" data-test="mobile-comment-submit-button"
              *ngIf="!comment && isXs$ | async"
              [disabled]="form.invalid">
        <mat-icon svgIcon="paperplane"></mat-icon>
      </button>
    </div>
  </div>
  <div class="form-actions" *ngIf="((formActionsActive) || (formState$ | async))">
    <div *ngIf="!(isXs$ | async) && form.valid">
      <span *ngIf="(formState$ | async) === CommentFormStatus.SUBMITTING" translate="COMMENTS.FORM.STATUS.SUBMITTING"></span>
      <span *ngIf="(formState$ | async) === CommentFormStatus.ERROR" translate="COMMENTS.ACTIONS.ERROR"></span>
      <span *ngIf="(formState$ | async) === CommentFormStatus.START">
        <span [innerHTML]="'COMMENTS.ACTIONS.PRESS_ENTER' | translate | markdown"></span>
        <button color="accent" mat-button mat-link-button data-test="comment-form-submit" (click)="submit(form.value)">{{'COMMENTS.ACTIONS.SEND' | translate}}</button>
      </span>
      <span *ngIf="(formState$ | async) !== CommentFormStatus.SUBMITTING">
        <span [innerHTML]="'COMMENTS.ACTIONS.PRESS_ESCAPE' | translate | markdown"></span>
        <button color="accent" mat-button mat-link-button data-test="comment-form-reset" (click)="cancelForm()">{{'COMMENTS.ACTIONS.CANCEL' | translate}}</button>
      </span>
    </div>
    <div *ngIf="(isXs$ | async) && comment" class="mobile-edit-actions">
      <button type="button" mat-flat-button data-test="mobile-edit-chancel-button" (click)="cancelForm()">
        <span>{{'CANCEL' | translate}}</span>
      </button>
      <button type="submit" mat-flat-button color="primary" data-test="mobile-edit-submit-button" [disabled]="!form.valid">
        <span>{{'COMMENTS.ACTIONS.UPDATE' | translate}}</span>
      </button>
    </div>
  </div>
  <p *ngIf="(isXs$ | async) && (formState$ | async) === CommentFormStatus.ERROR">{{'COMMENTS.ACTIONS.ERROR' | translate}}</p>
</form>

import { Pipe, PipeTransform } from '@angular/core';
import {youtubeNoCookie} from '@shared/rte/video-plugin/rte-youtube-regex';

@Pipe({
  name: 'youtubeNoCookie'
})
export class YoutubeNoCookiePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (typeof value !== 'string') {
      return '';
    }
    return value.replace(youtubeNoCookie['regex'], youtubeNoCookie['replacement']);
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {SelectionEntry} from '@app/filter/selection-filter/selection-entry';
import * as _ from 'lodash';

/**
 * Sum the total participation from a SelectionEntry list.
 */
@Pipe({
  name: 'sumTotalParticipation'
})
export class SumTotalParticipationPipe implements PipeTransform {

  constructor() {
  }

  /**
   * Sum the total participation
   *
   * @param entries The participation list
   * @returns Sum all counters (Field 'count'). Returns 0 if the parameter is null or empty
   */
  transform(entries: SelectionEntry[]): number {
    return _.sumBy(entries, 'count');
  }
}

import {Injectable} from '@angular/core';
import {PluginWidgetComponent} from '@widgets/plugin/plugin-widget/plugin-widget.component';

/**
 * A simple plug-in component instance registry that allows other services and
 * components to access plug-in widgets. Plug-in widget components will
 * register themselves onInit and deregister onDestroy. This registry is then
 * used by the widget layout service to retrieve plug-ins and send messages to
 * their respective iFrames.
 */
@Injectable({
  providedIn: 'root'
})
export class PluginInstanceRegistryService {
  private _instances: { [srcId: string]: PluginWidgetComponent } = {};

  /**
   * All registered component instances.
   */
  get instances(): { [srcId: string]: PluginWidgetComponent } {
    return this._instances;
  }

  /**
   * Retrieves a single registered component. Make sure to not store the
   * reference to the component in order to avoid memory leaks.
   *
   * @param srcId the corresponding srcID of the component
   * @returns the component or `null`
   */
  get(srcId: string): PluginWidgetComponent | null {
    return this._instances[srcId] || null;
  }

  /**
   * Adds a component to the registration.
   *
   * @param srcId the corresponding srcID
   * @param component the component
   */
  register(srcId: string, component: PluginWidgetComponent): void {
    this._instances[srcId] = component;
  }

  /**
   * Removes a component from the registration.
   *
   * @param srcId the corresponding srcID
   */
  deregister(srcId: string): void {
    delete this._instances[srcId]; // tslint:disable-line:no-dynamic-delete
  }
}

<h3 mat-dialog-title translate="APPS.SETTINGS.TITLE"></h3>
<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
<form [formGroup]="appForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <coyo-app-settings [isNew]="false"
                       [config]="data.appConfig"
                       [app]="data.app"
                       [parentForm]="appForm"
                       [sender]="data.sender"
                       (legacyFormValidityChanged)="onValidityChanged($event)"></coyo-app-settings>
    <hr>
    <div class="delete-title" translate="APPS.DELETE"></div>
    <div class="delete-help">
      {{'APPS.DELETE.HELP' | translate:{hideContentDeletionHint: hideContentDeletionHint} }}
    </div>
    <button mat-flat-button type="button"
            [attr.aria-label]="'APPS.DELETE' | translate" (click)="delete()">{{'APPS.DELETE' | translate}}</button>
  </div>
  <mat-dialog-actions>
    <button type="reset"
            formName="appForm"
            mat-button color="default"
            [attr.aria-label]="'DISCARD' | translate"
            (click)="close()">{{'DISCARD' | translate}}</button>
    <button type="submit"
            formName="appForm"
            mat-flat-button
            color="accent"
            [loading]="loading$ | async"
            [attr.aria-label]="'SAVE' | translate"
            [disabled]="appForm.invalid || legacyFormInvalid || (appForm.statusChanges | async) === 'PENDING'">{{'SAVE' | translate}}</button>
  </mat-dialog-actions>
</form>

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {TeaserWidget} from '@widgets/teaser/teaser-widget';
import {TeaserWidgetSettingsComponent} from './teaser-widget-settings/teaser-widget-settings.component';
import {TeaserWidgetComponent} from './teaser-widget/teaser-widget.component';

/**
 * The configuration for the teaser widget.
 */
export const TEASER_WIDGET: WidgetConfig<TeaserWidget> = {
  key: 'teaser',
  name: 'WIDGET.TEASER.NAME',
  description: 'WIDGET.TEASER.DESCRIPTION',
  icon: 'zmdi-view-carousel',
  component: TeaserWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    component: TeaserWidgetSettingsComponent,
    skipOnCreate: false
  },
  renderOptions: {
    printable: false
  },
  whitelistExternal: true,
  mobileSupportTypes: ['landing-page']
};

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {TeaserWidget} from '@widgets/teaser/teaser-widget';
import {SwiperLoaderService} from '@widgets/teaser/teaser-widget/swiper-loader.service';
import * as _ from 'lodash';
import Swiper, {SwiperOptions} from 'swiper';

/**
 * The teaser widget component.
 */
@Component({
  selector: 'coyo-teaser-widget',
  templateUrl: './teaser-widget.component.html',
  styleUrls: ['teaser-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeaserWidgetComponent extends WidgetComponent<TeaserWidget> implements AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('swiperContainer', {
    read: ElementRef,
    static: true
  }) swiperContainer: ElementRef;

  @HostBinding('class.one-line') twoLineDescription: boolean;

  swiper: Swiper;

  constructor(private swiperLoader: SwiperLoaderService, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  get sizeClass(): string {
    return this.widget.settings._format === '3:1' ? 'size-lg' : 'size-sm';
  }

  get noPaginationClass(): string {
    return this.widget.settings.slides.length > 1  ? 'no-pagination' : '';
  }

  ngAfterViewInit(): void {
    const options = this.getOptions();
    setTimeout(() => {
      this.swiper = this.swiperLoader.createInstance(this.swiperContainer.nativeElement, options);
      this.twoLineDescription = this.swiperContainer.nativeElement.offsetHeight < 95;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this.swiper && (changes.editMode || (changes.widget && !changes.widget.isFirstChange()))) {
      const self = this;
      let options = this.swiper.params;
      this.swiper.destroy(false, false);
      this.twoLineDescription = this.swiperContainer.nativeElement.offsetHeight < 95;

      setTimeout(() => {
        // do this in next tick so the DOM has the chance to update
        if (this.widget.settings.slides.length > 1) {
          options.autoplay = {
            delay: self.widget.settings._autoplayDelay * 1000,
            disableOnInteraction: false
          };
        } else {
          options = _.omit(options, 'autoplay');
        }

        this.swiper = this.swiperLoader.createInstance(this.swiperContainer.nativeElement, options);
      });
    }
  }

  ngOnDestroy(): void {
    this.swiper.destroy(true, true);
  }

  private getOptions(): SwiperOptions {
    const self = this;
    const onSlideChange = function(): void {
      setTimeout(() => {
        self.detectChanges();
      });
    };
    let options: SwiperOptions = {
      // Optional parameters
      direction: 'horizontal',
      // autoHeight: true,
      loop: true,
      autoplay: {
        delay: self.widget.settings._autoplayDelay * 1000,
        disableOnInteraction: false
      },

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      on: {
        init: onSlideChange,
        slideChange: onSlideChange
      }
    };
    if (_.get(this.widget.settings, 'slides.length', 0) <= 1) {
      options = _.omit(options, ['autoplay', 'pagination']);
      options.loop = false;
      options.allowTouchMove = false;
    }
    return options;
  }
}

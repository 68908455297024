import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {TimeModule} from '@shared/time/time.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {RSS_WIDGET} from '@widgets/rss/rss-widget-config';
import {RssWidgetSettingsComponent} from '@widgets/rss/rss-widget-settings/rss-widget-settings.component';
import {RssWidgetComponent} from '@widgets/rss/rss-widget/rss-widget.component';
import {TabsModule} from 'ngx-bootstrap';

/**
 * Module providing the RSS widget
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    HelpModule,
    TabsModule,
    TimeModule
  ],
  declarations: [
    RssWidgetComponent,
    RssWidgetSettingsComponent
  ],
  entryComponents: [
    RssWidgetComponent,
    RssWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: RSS_WIDGET, multi: true}
  ]
})

export class RssWidgetModule {
}

import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';

export const CONFIG_KEY_PREFIX = '_config_';
export const SRC_ID = '_srcId';
export const ENTRY_POINT_ID = '_entryPointId';

/**
 * The entity model for the settings of a plug-in
 */
export interface PluginWidgetSettings extends WidgetSettings {
  _srcId: string;
  _entryPointId: string;
  [additionalProperties: string]: any;
}

import {Injectable} from '@angular/core';
import {EventDistributionService} from '@app/analytics/event-distributor/event-distribution.service';
import {EventTypes} from '@app/analytics/event-types';
import {LocalStorageService} from '@core/storage/local-storage/local-storage.service';

/**
 * The main service for tracking the reach of entities like timeline-item, blog article...
 */
@Injectable({
  providedIn: 'root'
})
export class UserReachedService {

  static TRANSMITTED_ENTITY_ARRAY_LIMIT: number = 500;
  static TRANSMITTED_ENTITY_KEY: string = 'transmitted-entity-ids';

  private transmittedEntityIds: string[] = [];

  constructor(private eventDistributionService: EventDistributionService,
              private localStorageService: LocalStorageService) {
  }

  handleUserReachedEvent(time: Date, entityId: string, entityType: string): void {
    this.transmittedEntityIds = this.getTransmittedEntityIds();
    if (!this.transmittedEntityIds.includes(this.getEntityIdDateString(entityId, time))) {
      this.addToTransmittedEntityIds(entityId, time);
      this.eventDistributionService.addEvent(EventTypes.USER_REACHED, {
        time: time.toISOString(),
        payload: {
          entityId: entityId,
          entityType: entityType,
          client: 'web'
        }
      });
    }
  }

  private addToTransmittedEntityIds(entityId: string, publicationTime: Date): void {
    if (this.transmittedEntityIds.length >= UserReachedService.TRANSMITTED_ENTITY_ARRAY_LIMIT) {
      this.transmittedEntityIds = this.transmittedEntityIds.slice(1, UserReachedService.TRANSMITTED_ENTITY_ARRAY_LIMIT);
    }
    this.transmittedEntityIds.unshift(this.getEntityIdDateString(entityId, publicationTime));
    this.localStorageService.setValue(UserReachedService.TRANSMITTED_ENTITY_KEY, this.transmittedEntityIds);
  }

  private getTransmittedEntityIds(): string[] {
    return this.localStorageService.getValue<string[]>(UserReachedService.TRANSMITTED_ENTITY_KEY, []);
  }

  private getEntityIdDateString(entityId: string, publicationTime: Date): string {
    return `${entityId}|${publicationTime.toISOString().split('T')[0]}`;
  }

}

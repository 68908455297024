<div class="no-preview-available-text" [class.bottom-space]="isGSuiteFile">
  <span>{{errorTitle | translate}}</span>
</div>

<div *ngIf="errorMessage" class="text-muted file-not-accessible-text top-space">
  {{errorMessage | translate}}
</div>

<div *ngIf="gSuiteActive">
  <div *ngIf="!file.canEdit && !file.externalFileUrl && isGSuiteFile" class="file-not-accessible-text"
       translate="FILE_DETAILS.GSUITE_FILE_NOT_ACCESSIBLE">
  </div>
  <div>
    <ng-container *ngIf="isDesktop$ | async">
      <a class="coyo-btn coyo-btn-primary" [class.disabled]="!googleFileData?.webContentLink"
         [href]="googleFileData?.webContentLink" target="_self">
        <i class="zmdi zmdi-hc-lg zmdi-download mr-xxxs"></i>
        <span translate="DOWNLOAD"></span>
      </a>
      <a class="coyo-btn coyo-btn-secondary" *ngIf="file.externalFileUrl" [href]="file.externalFileUrl"
         target="_blank">
        <span class="open-in-g-suite-icon pr-xxxs"><i class="zmdi zmdi-google"></i></span>
        <span *ngIf="!file.canEdit" translate="FILE_DETAILS.OPEN_IN_G_SUITE"></span>
        <span *ngIf="file.canEdit" translate="FILE_DETAILS.EDIT_IN_G_SUITE"></span>
      </a>
    </ng-container>
  </div>
</div>

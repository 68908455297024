(function (angular) {
  'use strict';

  AdminJobsListController.$inject = ["$rootScope", "$scope", "JobModel"];
  angular.module('coyo.admin.jobs')
      .controller('AdminJobsListController', AdminJobsListController);

  function AdminJobsListController($rootScope, $scope, JobModel) {
    var vm = this;

    vm.isMobile = $rootScope.screenSize.isXs || $rootScope.screenSize.isSm;

    vm.$onInit = _init;
    vm.refresh = refresh;

    // ========================================

    function _init() {
      $scope.$on('$destroy', $rootScope.$on('screenSize:changed', function (event, screenSize) {
        vm.isMobile = screenSize.isXs || screenSize.isSm;
      }));

      _loadJobs();
    }

    function _loadJobs() {
      vm.loading = true;
      return JobModel.query().then(function (result) {
        vm.jobs = result;
      }).finally(function () {
        vm.loading = false;
      });
    }

    function refresh() {
      _loadJobs();
    }

  }

})(angular);

(function (angular) {
  'use strict';

  LoginTermsController.$inject = ["termsService", "$state", "TermsModel", "authService", "$translate"];
  angular
      .module('coyo.login')
      .controller('LoginTermsController', LoginTermsController);

  function LoginTermsController(termsService, $state, TermsModel, authService, $translate) {
    var vm = this;
    vm.$onInit = init;

    vm.accept = accept;
    vm.decline = decline;
    vm.fetchTermsByLanguage = fetchTermsByLanguage;

    vm.accepting = false;
    vm.declining = false;

    function accept() {
      vm.accepting = true;

      authService.getUser().then(function (user) {
        TermsModel.accept(user.id, vm.terms.language).then(function () {
          authService.getUser(true).then(function () {
            var targetState = termsService.getTargetState();
            $state.go(targetState.name, targetState.params);
          });
        });
      });
    }

    function decline() {
      vm.declining = true;

      authService.getUser().then(function (user) {
        TermsModel.decline(user.id, vm.terms.language).then(function () {
          authService.logout();
        });
      });
    }

    function fetchTermsByLanguage(language) {
      return TermsModel.getByLanguage(language).then(function (terms) {
        vm.terms = terms;
        _setButtonTranslations(vm.terms.language);
      });
    }

    function _fetchTerms() {
      return TermsModel.getBestSuitable().then(function (terms) {
        vm.terms = terms;
        _setButtonTranslations(vm.terms.language);
      });
    }

    function _setButtonTranslations(language) {
      $translate(['MODULE.LOGIN.TERMS.ACCEPT', 'MODULE.LOGIN.TERMS.DECLINE'], {}, null, null, language.toLowerCase())
          .then(function (translation) {
            vm.acceptButtonTranslation = translation['MODULE.LOGIN.TERMS.ACCEPT'];
            vm.declineButtonTranslation = translation['MODULE.LOGIN.TERMS.DECLINE'];
          });
    }

    function init() {
      termsService.userNeedsToAcceptTerms().then(function (showTerms) {
        if (showTerms) {
          _fetchTerms();
        } else {
          var targetState = termsService.getTargetState();
          $state.go(targetState.name, targetState.params);
        }
      });
    }
  }

})(angular);

import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {Sender} from '@domain/sender/sender';
import {User} from '@domain/user/user';
import {Store} from '@ngxs/store';
import {FileDetailsStateSelectors} from '@shared/files/file-details-modal/state/file-details.state.selectors';
import {Observable} from 'rxjs';

/**
 * Component to show information of a file preview in a definition list.
 */
@Component({
  selector: 'coyo-file-information',
  templateUrl: './file-information.component.html',
  styleUrls: ['./file-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileInformationComponent implements OnChanges {

  /**
   * The id of the file preview
   */
  @Input() fileId: string;

  /**
   * The id of the file details dialog
   */
  @Input() fileDetailsDialogId: string;

  /**
   * The sender the file belongs to
   */
  @Input() sender: Sender;

  file$: Observable<FilePreview>;

  author$: Observable<User>;

  constructor(private store: Store) {
  }

  ngOnChanges(): void {
    this.file$ = this.store.select(FileDetailsStateSelectors.getFile(this.fileDetailsDialogId, this.fileId));
    this.author$ = this.store.select(FileDetailsStateSelectors.getFileAuthor(this.fileDetailsDialogId, this.fileId));
  }

}

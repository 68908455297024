(function (angular) {
  'use strict';

  ImageReferenceController.$inject = ["$log", "$http", "$scope", "$timeout", "$httpParamSerializerJQLike", "FileModel", "DocumentModel", "backendUrlService", "socketService", "errorService"];
  angular
      .module('commons.ui')
      .component('coyoImageReference', imageReference())
      .controller('ImageReferenceController', ImageReferenceController);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoImageReference:coyoImageReference
   * @scope
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Renders an image from the file library as a picture element. Supports rendering different image sizes for
   * different screen sizes and also checks whether the display supports retina.
   *
   * @param {string} fileId
   * The id of the file to be rendered.
   *
   * @param {string} senderId
   * The id of the file's sender.
   *
   * @param {object=} sizeDefinitions
   * A map defining which screen size should result in which image size. The keys are defining the screen's size via
   * bootstrap style identifiers: `screen-xs`, `screen-sm`, `screen-md` and `screen-lg`. The image sizes are defined in
   * shirt sizes starting from XS to XXL. The image's width described by one of those sizes is defined in the backend.
   *
   * It is also possible to define a default size by using the `default` key. The directive checks whether the device
   * supports retina and will shift the image sizes up automatically if retina is supported.
   *
   * If the `sizeDefintitions` parameter is not set, the image will be rendered in it's original size. This is not
   * recommended since this might result in large images being loaded for mobile devices.
   *
   * Example:
   * ```
   * {
   *   'default': 'M',
   *   'screen-lg': 'XL'
   * }
   * ```
   *
   * In this example the image would be rendered in the size M (medium) if the screen size is smaller than 'screen-lg'.
   * Starting with a large screen, an image of the size XL (extra large) will be rendered. If the devices display
   * supports retina the image sizes 'L' and 'XXL' are rendered accordingly.
   *
   * @requires coyo.domain.FileModel
   * @requires coyo.domain.DocumentModel
   * @requires commons.resource.backendUrlService
   * @requires $log
   * @requires $scope
   * @requires $httpParamSerializerJQLike
   */
  function imageReference() {
    return {
      templateUrl: 'app/commons/ui/components/file-library/image-reference/image-reference.html',
      bindings: {
        fileId: '<',
        senderId: '<',
        sizeDefinitions: '<?',
        placeholderUrl: '<?'
      },
      controller: 'ImageReferenceController',
      controllerAs: '$ctrl'
    };
  }

  function ImageReferenceController($log, $http, $scope, $timeout, $httpParamSerializerJQLike, FileModel, DocumentModel,
                                    backendUrlService, socketService, errorService) {
    var vm = this;
    vm.previewAvailable = true;
    vm.isProcessing = true;

    vm.onLoad = onLoad;
    vm.onError = onError;

    var unsubscribePreviewStatusFn;
    var baseUrl, queryParams;
    var reloadTries = 1;

    var retinaLookup = {
      XS: 'S',
      S: 'M',
      M: 'L',
      L: 'XL',
      XL: 'XXL',
      XXL: 'ORIGINAL',
      ORIGINAL: 'ORIGINAL'
    };

    function onLoad() {
      vm.previewAvailable = true;
      vm.isProcessing = false;
    }

    function onError(event) {
      errorService.suppressNotification(event);

      if (reloadTries <= 0) {
        _previewStatusChange('FAILED');
        return;
      }
      reloadTries--;

      unsubscribePreviewStatusFn =
        socketService.subscribe('/topic/public/preview.status', function (data) {
          $scope.$apply(function () {
            _previewStatusChange(data.content.status);
          });
        }, null, vm.senderId + '.' + vm.fileId);

      // Don't cache this request client-side!
      $http.get(baseUrl + '/preview-status').then(function (statusData) {
        _previewStatusChange(statusData.data.status);
      }).catch(function (errorResponse) {
        errorService.suppressNotification(errorResponse);
        _setFailed();
      });
    }

    function _setImageUrl() {
      if (vm.fileId && vm.senderId) {
        FileModel.get({id: vm.fileId, senderId: vm.senderId}).then(function (file) {
          var image = {
            fileId: vm.fileId,
            senderId: vm.senderId,
            modified: file.modified
          };

          baseUrl = _getBaseUrl(image);
          queryParams = {modified: image.modified};

          vm.imageUrl = baseUrl + '?' + $httpParamSerializerJQLike(queryParams);
          vm.imageUrls = {};

          _setImageUrlsForSizes();
          vm.previewAvailable = true;

        }).catch(function (errorResponse) {
          errorService.suppressNotification(errorResponse);
          _setFailed();
        });
      } else {
        _setFailed();
      }
    }

    function _setImageUrlsForSizes() {
      if (vm.sizeDefinitions) {
        if (!vm.sizeDefinitions.default) {
          $log.error('Default missing: If providing size definitions you must specify an image size for default.');
          return;
        }
        _.forEach(vm.sizeDefinitions, function (imageSize, screenSize) {
          if (screenSize === 'default') {
            vm.defaultUrl = _getUrlForSize(baseUrl, queryParams, imageSize);
            if (retinaLookup[imageSize]) {
              vm.defaultUrl += ', ' + _getUrlForSize(baseUrl, queryParams, retinaLookup[imageSize]) + ' 2x';
            }
          } else {
            vm.imageUrls[screenSize] = _getUrlForSize(baseUrl, queryParams, imageSize);
            if (retinaLookup[imageSize]) {
              vm.imageUrls[screenSize] +=
                ', ' + _getUrlForSize(baseUrl, queryParams, retinaLookup[imageSize]) + ' 2x';
            }
          }
        });
      }
    }

    function _getUrlForSize(baseUrl, queryParams, size) {
      var params = angular.extend({}, queryParams, {'type': size});
      return baseUrl + '?' + $httpParamSerializerJQLike(params);
    }

    function _getBaseUrl(image) {
      return backendUrlService.getUrl() + DocumentModel.$url({
        senderId: image.senderId,
        id: image.fileId
      });
    }

    function _unsubscribeFileStatus() {
      if (unsubscribePreviewStatusFn) {
        unsubscribePreviewStatusFn();
        unsubscribePreviewStatusFn = undefined;
      }
    }

    function _triggerReload() {
      var timestamp = new Date().getTime();
      vm.imageUrl += '&t=' + timestamp;
      queryParams.t = timestamp;
      _setImageUrlsForSizes();
    }

    function _previewStatusChange(status) {
      switch (status) {
        case 'PROCESSING':
          vm.isProcessing = true;
          break;
        case 'SUCCESS':
          _unsubscribeFileStatus();
          vm.isProcessing = false;
          vm.previewAvailable = true;
          _triggerReload();
          break;
        case 'CANNOT_PROCESS':
        case 'FAILED':
          _unsubscribeFileStatus();
          _setFailed();
          break;
        default:
          break;
      }
    }

    function _setFailed() {
      vm.isProcessing = false;
      vm.previewAvailable = false;
    }

    function _refresh() {
      _unsubscribeFileStatus();
      vm.isProcessing = true;
      vm.imageUrl = undefined; // IE needs a rerender of the img element to apply a new src
      // so this is temporary undefined and used in ng-if condition for img element to be rendered

      _setImageUrl();
    }

    (function _init() {
      $scope.$on('$destroy', _unsubscribeFileStatus);

      $scope.$watch(function () {
        return vm.fileId;
      }, function () {
        _refresh();
      });

      $timeout(function () {});
    })();

  }

})(angular);

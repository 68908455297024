import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {LaunchpadLink} from '@domain/launchpad/launchpad-link';
import {LaunchpadLinkRequest} from './launchpad-link-request';

/**
 * Service to manage launchpad links.
 */
@Injectable({
  providedIn: 'root'
})
export class LaunchpadLinkService extends DomainService<LaunchpadLinkRequest, LaunchpadLink> {

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/launchpad/categories/{categoryId}/links';
  }
}

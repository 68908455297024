<div [formGroup]="parentForm">
  <div class="form-group">
    <label class="col-sm-2 control-label" translate="WIDGETS.SUGGEST_PAGES.SETTINGS.PAGE.LABEL"></label>
    <div class="col-sm-9">
      <coyo-select-sender [options]="selectOptions"
                          [closeOnSelect]="false"
                          formControlName="pages"
                          id="pages"
                          [multiselect]="true"
                          ></coyo-select-sender>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.SUGGEST_PAGES.SETTINGS.PAGE.HELP"></coyo-help>
    </div>
  </div>
</div>

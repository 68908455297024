import {ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {OverlayRef} from '@angular/cdk/overlay';
import {ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, InjectionToken, TemplateRef} from '@angular/core';
import {OverlayComponent} from '@shared/overlay/overlay-component';

/**
 * A custom injection token for the passed template into the overlay.
 */
export const ACTIONS_TEMPLATE_REF = new InjectionToken<TemplateRef<any>>('Actions template reference');

/**
 * This component renders the given template into a fullscreen overlay.
 */
@Component({
  selector: 'coyo-actions-menu-overlay',
  templateUrl: './actions-menu-overlay.component.html',
  styleUrls: ['./actions-menu-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsMenuOverlayComponent extends OverlayComponent<{}> {

  constructor(overlayRef: OverlayRef,
              focusTrapFactory: ConfigurableFocusTrapFactory,
              @Inject(ACTIONS_TEMPLATE_REF) public actionsTemplate: TemplateRef<any>,
              private elementRef: ElementRef) {
    super(overlayRef, focusTrapFactory);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (event.target !== this.elementRef.nativeElement) {
      this.close(null);
    }
  }

}

import {AfterViewInit, ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Like} from '@domain/like/like';
import {LikeService} from '@domain/like/like.service';
import {Likeable} from '@domain/like/likeable';
import {Direction} from '@domain/pagination/direction.enum';
import {Order} from '@domain/pagination/order';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Observable, of, Subject} from 'rxjs';
import {catchError, map, scan, switchMap} from 'rxjs/operators';

/**
 * Modal showing a list of senders who liked the given target.
 */
@Component({
  selector: 'coyo-likes-modal',
  templateUrl: './likes-modal.component.html',
  styleUrls: ['./likes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikesModalComponent implements AfterViewInit {
  private static readonly PAGE_SIZE: number = 20;
  private static readonly PAGE_SORT: Order = new Order('created', Direction.Desc);

  likes$: Observable<Like[]>;

  currentPage: Page<Like>;

  loading: boolean = true;

  private loadMoreSubject: Subject<void> = new Subject<void>();

  constructor(private likeService: LikeService,
              @Inject(MAT_DIALOG_DATA) public target: Likeable) {
    this.likes$ = this.loadMoreSubject
      .pipe(switchMap(() => this.loadNextPage()))
      .pipe(scan((acc, value) => acc.concat(value)));
  }

  ngAfterViewInit(): void {
    this.loadMoreSubject.next();
  }

  /**
   * Loads the next page of likes.
   */
  loadMore(): void {
    if (this.loading || this.currentPage.last) {
      return;
    }
    this.loading = true;
    this.loadMoreSubject.next();
  }

  private loadNextPage(): Observable<Like[]> {
    const number = this.currentPage ? this.currentPage.number + 1 : 0;
    const pageable = new Pageable(number, LikesModalComponent.PAGE_SIZE, null, LikesModalComponent.PAGE_SORT);
    const context = {
      targetId: this.target.id,
      targetType: this.target.typeName
    };

    return this.likeService.getPage(pageable, {context}).pipe(map(page => {
      this.currentPage = page;
      this.loading = false;
      return this.currentPage.content;
    })).pipe(catchError(() => {
      this.loading = false;
      return of([]);
    }));
  }
}

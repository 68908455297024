import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {VideoPreview} from '@domain/preview/video-preview';

/**
 * Component responsible for showing an embedded iframe video (e.g. from youtube).
 */
@Component({
  selector: 'coyo-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPreviewComponent {

  /**
   * The video to be shown by the component.
   */
  @Input() preview: VideoPreview;

  /**
   * Flag for showing a delete icon in the top right of the video.
   */
  @Input() editMode: boolean = false;

  /**
   * Event that is emitted when the component is in edit mode and the delete button is clicked.
   */
  @Output() previewDeleted: EventEmitter<VideoPreview> = new EventEmitter();

  constructor() {
  }

  /**
   * Calculates the ratio between height and width.
   *
   * @param video the video
   *
   * @return a percentage value of the ratio
   */
  getHeightPercentage(video: VideoPreview): string {
    return Math.ceil((video.height || 1080) / (video.width || 1920) * 100) + '%';
  }

  /**
   * Replaces plain youtube links to youtube-nocookie links.
   *
   * @param preview the video to calculate the URL for
   *
   * @return the calculated video URL
   */
  getVideoUrl(preview: VideoPreview): string {
    return preview.videoUrl.replace('youtube.com', 'youtube-nocookie.com');
  }
}

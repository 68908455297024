import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {App} from '@apps/api/app';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {AppService} from '@domain/apps/app.service';
import {GlobalAppService} from '@domain/apps/global-app.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {LatestBlogArticlesWidget} from '@widgets/latest-blog-articles/latest-blog-articles-widget';
import {
  CompatibilityAppModel,
  LatestBlogArticlesWidgetSettings
} from '@widgets/latest-blog-articles/latest-blog-articles-widget-settings';
import * as _ from 'lodash';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, startWith} from 'rxjs/operators';

/**
 * The latest-blog-articles widget settings component.
 */
@Component({
  selector: 'coyo-latest-blog-articles-widget-settings',
  templateUrl: './latest-blog-articles-widget-settings.component.html',
  styleUrls: ['./latest-blog-articles-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestBlogArticlesWidgetSettingsComponent extends WidgetSettingsComponent<LatestBlogArticlesWidget>
  implements OnInit {

  isSourceSelection$: Observable<boolean>;

  constructor(
    private readonly appService: AppService,
    private readonly globalAppService: GlobalAppService) {
    super();
  }

  ngOnInit(): void {
    const selectedApps = this.widget.settings._app ?? null;
    const sourceSelection = this.widget.settings._sourceSelection ?? 'ALL';
    const articleCount = this.widget.settings._articleCount ?? 5;
    const showTeaserImage = this.widget.settings._showTeaserImage ?? false;
    const onlySubscribed = this.widget.settings._onlySubscribed ?? false;
    this.parentForm.addControl('_app', new FormControl([]));
    this.parentForm.addControl('_showTeaserImage', new FormControl(showTeaserImage));
    this.parentForm.addControl('_sourceSelection', new FormControl(sourceSelection));
    this.parentForm.addControl('_articleCount', new FormControl(articleCount,
      [Validators.required, Validators.min(1), Validators.max(20), CoyoValidators.naturalNumber]));
    this.parentForm.addControl('_onlySubscribed', new FormControl(onlySubscribed));
    this.setupSourceSelectionObservable(sourceSelection);
    this.loadSelectedApps(selectedApps);
  }

  /**
   * Transforms the form data to settings data
   * @param settings The settings stored in the form
   * @returns An observable emitting the transformed save data
   */
  onBeforeSave(settings?: LatestBlogArticlesWidgetSettings): Observable<LatestBlogArticlesWidgetSettings> {
    const selectedApps = settings._app as App<any>[];
    let apps: CompatibilityAppModel[] = [];
    if (selectedApps && selectedApps.length > 0) {
      apps = selectedApps.map(app => ({id: app.id, senderId: app.senderId}));
    } else if (settings._sourceSelection === 'SELECTED') {
      settings._sourceSelection = 'ALL';
    }
    return of({
      _app: apps,
      _articleCount: settings._articleCount,
      _sourceSelection: settings._sourceSelection,
      _showTeaserImage: settings._showTeaserImage,
      _onlySubscribed: settings._onlySubscribed
    });
  }

  private setupSourceSelectionObservable(currentValue: string): void {
    this.isSourceSelection$ = this.parentForm
      .get('_sourceSelection')
      .valueChanges
      .pipe(map(value => value === 'SELECTED'), startWith(currentValue === 'SELECTED'));
  }

  private loadSelectedApps(selectedApps: CompatibilityAppModel[]): void {
    if (!!selectedApps && !!selectedApps.length) {
      const appResults: Observable<App<AppSettings>>[] = selectedApps.map((app: CompatibilityAppModel) => {
        if (app.senderId && app.senderId.length > 0) {
          return this.appService.get(app.id, {context: {senderId: app.senderId}});
        } else {
          return this.globalAppService.getById(app.id);
        }
      }).map(result => result.pipe(catchError(() => of(null))));
      forkJoin(appResults).subscribe(apps => {
        this.parentForm.patchValue({_app: apps.filter(app => app)});
      });
    }
  }
}

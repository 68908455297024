(function (angular) {
  'use strict';

  PageImprintController.$inject = ["$scope", "page", "UserModel", "$injector"];
  angular
      .module('coyo.pages')
      .controller('PageImprintController', PageImprintController);

  /**
   * Controller for the page imprint view
   */
  function PageImprintController($scope, page, UserModel, $injector) {
    var vm = this;

    vm.page = page;
    vm.admins = [];
    vm.editMode = false;
    vm.isNew = true;
    vm.edit = edit;
    vm.save = save;
    vm.cancel = cancel;
    vm.$onInit = _init;

    function cancel() {
      vm.editMode = false;
      $injector.get('ngxWidgetEditService').cancelEdit('page-imprint-' + vm.page.id);
    }

    function edit() {
      vm.editMode = true;
      vm.isNew = false;
      $injector.get('ngxWidgetEditService').enableEditMode('page-imprint-' + vm.page.id, false);
    }

    function save() {
      vm.editMode = false;
      $injector.get('ngxWidgetEditService').save('page-imprint-' + vm.page.id, false);
    }

    function _init() {
      UserModel.getUsers(vm.page.adminIds).then(function (result) {
        vm.admins = result;
      });
    }
  }
})(angular);

import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {App} from '@apps/api/app';
import {AuthService} from '@core/auth/auth.service';
import {AppService} from '@domain/apps/app.service';
import {Document} from '@domain/file/document';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {SettingsService} from '@domain/settings/settings.service';
import {CropSettings, Ng1FileLibraryModalOptions, Ng1FileLibraryModalService} from '@root/typings';
import {NG1_FILE_LIBRARY_MODAL_SERVICE} from '@upgrade/upgrade.module';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {ImageWidget} from '@widgets/image/image-widget';
import * as _ from 'lodash';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {defaultIfEmpty, map, switchMap} from 'rxjs/operators';

/**
 * The image widget state.
 */
interface ImageWidgetState {
  sender: Sender;
  app: App<any>;
  image: Document;
}

/**
 * The image widget settings component.
 */
@Component({
  selector: 'coyo-image-widget-settings',
  templateUrl: './image-widget-settings.component.html',
  styleUrls: ['./image-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageWidgetSettingsComponent extends WidgetSettingsComponent<ImageWidget> implements OnInit, OnDestroy {

  state$: Observable<ImageWidgetState>;
  private stateSubject$: BehaviorSubject<ImageWidgetState> = new BehaviorSubject<ImageWidgetState>({
    sender: null,
    app: null,
    image: null
  });

  constructor(private authService: AuthService,
              private senderService: SenderService,
              private appService: AppService,
              private settingsService: SettingsService,
              @Inject(NG1_FILE_LIBRARY_MODAL_SERVICE) private fileLibraryModalService: Ng1FileLibraryModalService) {
    super();
  }

  ngOnInit(): void {
    this.state$ = this.stateSubject$.asObservable();
    this.initSender();

    const imageFieldName = '_image';
    const image = _.get(this.widget.settings, imageFieldName, null);
    this.parentForm.addControl(imageFieldName, new FormControl(image, [Validators.required]));
    this.updateImageState(image);

    const urlFieldName = '_url';
    const url = _.get(this.widget.settings, urlFieldName, '');
    this.parentForm.addControl(urlFieldName, new FormControl(url));
    this.settingsService.retrieveByKey('linkPattern').subscribe(pattern => {
      this.parentForm.get('_url').setValidators([Validators.pattern(pattern)]);
      this.parentForm.updateValueAndValidity();
    });

    const linkTargetFieldName = '_linkTarget';
    const target = _.get(this.widget.settings, linkTargetFieldName, false);
    this.parentForm.addControl(linkTargetFieldName, new FormControl(target));
  }

  ngOnDestroy(): void {
    this.stateSubject$.complete();
  }

  /**
   * Opens the file library for image selection.
   */
  selectImage(): void {
    const currentState: ImageWidgetState = this.stateSubject$.getValue();
    this.openFileLibrary(currentState.sender, currentState.app)
      .subscribe((image: Document) => {
        if (image) {
          const newImageSettings = {
            id: image.id,
            senderId: image.senderId
          };
          _.set(this.widget, 'settings._image', newImageSettings);
          this.parentForm.patchValue({_image: newImageSettings});
          this.updateImageState(image);
        }
      });
  }

  private initSender(): void {
    const senderId = this.senderService.getCurrentIdOrSlug();
    if (senderId) {
      this.senderService.get(senderId, {permissions: ['createFile']})
        .pipe(
          switchMap(sender => this.appService.getCurrentApp(sender.id)
            .pipe(map(app => [sender, app]))
            .pipe(defaultIfEmpty([sender, null as App<any>]))
          )
        )
        .subscribe((result: [Sender, App<any>]) => this.updateState(result[0], result[1], null));
    } else {
      this.authService.getUser().subscribe(user => this.updateState(user, null, null));
    }
  }

  private openFileLibrary(sender: Sender, app?: App<any>): Observable<Document | Document[]> {
    const options: Ng1FileLibraryModalOptions = {
      uploadMultiple: false,
      selectMode: 'single',
      filterContentType: 'image',
      initialFolder: app ? {id: app.rootFolderId} : null,
      windowTopClass: 'image-widget-file-library-modal'
    };
    const cropSettings: CropSettings = {
      cropImage: true
    };
    return from(this.fileLibraryModalService.open(sender, options, cropSettings));
  }

  private updateState(sender: Sender, app: App<any>, image: Document): void {
    const currentState = this.stateSubject$.getValue();
    this.stateSubject$.next({
      sender,
      app,
      image: image ? image : currentState.image
    });
  }

  private updateImageState(image: Document): void {
    const currentState = this.stateSubject$.getValue();
    this.updateState(currentState.sender, currentState.app, image);
  }

}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {RichTextWidget} from '@widgets/rich-text/rich-text-widget';
import {RichTextWidgetComponent} from '@widgets/rich-text/rich-text-widget/rich-text-widget.component';

export const RICH_TEXT_WIDGET: WidgetConfig<RichTextWidget> = {
  key: 'rte',
  name: 'WIDGET.RTE.NAME',
  description: 'WIDGET.RTE.DESCRIPTION',
  icon: 'zmdi-text-format',
  component: RichTextWidgetComponent,
  categories: WidgetCategory.STATIC,
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

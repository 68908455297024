/**
 * Screen size depending on the window.innerWith
 *
 * < 768    -> XS
 * < 992    -> SM
 * < 1200   -> MD
 * >= 1200  -> LG
 */
export enum ScreenSize {
  XS, SM, MD, LG
}

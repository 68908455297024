(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .filter('fileTypeName', fileTypeNameFilter);

  /**
   * @ngdoc filter
   * @name commons.ui.fileTypeName:fileTypeName
   * @function
   *
   * @description
   * Converts a content (mime) type into a type name.
   */
  function fileTypeNameFilter() {
    return function (contentType) {
      if (contentType) {
        if (isMicrosoftOfficeFile(contentType)) {
          if (contentType.indexOf('word') >= 0) {
            return 'FILE_TYPE_NAME.WORD';
          }

          if (contentType.indexOf('sheet') >= 0) {
            return 'FILE_TYPE_NAME.EXCEL';
          }

          if (contentType.indexOf('presentation') >= 0) {
            return 'FILE_TYPE_NAME.POWERPOINT';
          }
        }
        if (isGoogleFile(contentType)) {
          if (contentType.indexOf('spreadsheet') >= 0) {
            return 'FILE_TYPE_NAME.GOOGLE_SPREADSHEET';
          }
          if (contentType.indexOf('document') >= 0) {
            return 'FILE_TYPE_NAME.GOOGLE_DOCUMENT';
          }
          if (contentType.indexOf('presentation') >= 0) {
            return 'FILE_TYPE_NAME.GOOGLE_PRESENTATION';
          }
        }

        // Images
        if (contentType.indexOf('image') >= 0) {
          return 'FILE_TYPE_NAME.IMAGE';
        }

        // Videos
        if (contentType.indexOf('video') >= 0) {
          return 'FILE_TYPE_NAME.VIDEO';
        }

        // PDF
        if (contentType.indexOf('pdf') >= 0) {
          return 'FILE_TYPE_NAME.PDF';
        }

        // Text
        if (contentType.indexOf('plain') >= 0) {
          return 'FILE_TYPE_NAME.PLAIN';
        }
      }

      // Default
      return 'FILE_TYPE_NAME.DEFAULT';
    };
  }

  function isGoogleFile(contentType) {
    return contentType.indexOf('google-apps') >= 0;
  }

  function isMicrosoftOfficeFile(contentType) {
    return contentType.indexOf('officedocument') >= 0;
  }

})(angular);

import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {INTERESTING_COLLEAGUES_WIDGET} from '@widgets/interesting-colleagues/interesting-colleagues-widget-config';
import {InterestingColleaguesWidgetComponent} from '@widgets/interesting-colleagues/interesting-colleagues-widget/interesting-colleagues-widget.component';
import {InterestingColleaguesWidgetState} from '@widgets/interesting-colleagues/interesting-colleagues-widget/interesting-colleagues-widget.state';
import {WIDGET_CONFIGS} from '../api/widget-config';

export const ngxsModule = NgxsModule.forFeature([InterestingColleaguesWidgetState]);

@NgModule({
  declarations: [InterestingColleaguesWidgetComponent],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    SenderUIModule,
    ngxsModule
  ],
  entryComponents: [InterestingColleaguesWidgetComponent],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: INTERESTING_COLLEAGUES_WIDGET, multi: true}
  ]
})
export class InterestingColleaguesModule {
}

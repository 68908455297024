<h3 mat-dialog-title translate="WIDGET.POLL.WIDGET.MODAL.TITLE"></h3>
<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>

<mat-dialog-content>
  <coyo-sender-list-item *ngFor="let voter of (voters$ | async)" [sender]="voter" avatarSize="xs" class="entry">
  </coyo-sender-list-item>

  <!-- empty text -->
  <div *ngIf="isEmpty$ | async" class="text-center text-muted">
    {{'WIDGET.POLL.WIDGET.NOVOTES' | translate}}
  </div>

  <!-- initial spinner / loading -->
  <div *ngIf="loading$ | async" class="text-center">
    <ui-spinner size="small"></ui-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="hasMore$ | async">
  <button (click)="loadMore()"
          [attr.aria-label]="'MORE' | translate"
          [disabled]="loading$ | async"
          color="accent"
          mat-flat-button type="button">{{'MORE' | translate}}</button>
</mat-dialog-actions>

import {NgModule} from '@angular/core';
import {TermsViewComponent} from '@app/engage/terms/terms-view/terms-view.component';
import {termsViewState} from '@app/engage/terms/terms.state';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {UpgradeModule} from '@upgrade/upgrade.module';

const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: [termsViewState]});

/**
 * Module for the engage app terms of use view.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    uiRouterModuleChild,
    UpgradeModule,
  ],
  declarations: [
    TermsViewComponent
  ]
})
export class TermsModule {
}

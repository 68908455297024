import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ResizedService} from '@shared/resize-event/resized.service';
import {ResizedDirective} from './resized.directive';

/**
 * Module for the resized events directive.
 */
@NgModule({
  imports: [CommonModule],
  exports: [ResizedDirective],
  declarations: [ResizedDirective],
  providers: [ResizedService]
})
export class ResizedEventModule {
}

<div *ngIf="!url">
  <ng-container *ngTemplateOutlet="imageReference"></ng-container>
</div>
<a *ngIf="url" [href]="url" [target]="linkTarget">
  <ng-container *ngTemplateOutlet="imageReference"></ng-container>
</a>

<ng-template #imageReference>
  <coyo-image-reference *ngIf="image"
                        [fileId]="image.id"
                        [senderId]="image.senderId"
                        [sizeDefinitions]="{'default': 'XL', 'screen-sm': 'XXL'}">
  </coyo-image-reference>
</ng-template>

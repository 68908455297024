(function (angular) {
  'use strict';

  WikiSettingsController.$inject = ["$scope"];
  angular
      .module('coyo.apps.wiki')
      .controller('WikiSettingsController', WikiSettingsController);

  function WikiSettingsController($scope) {
    var vm = this;

    vm.$onInit = onInit;
    vm.setAppFolderPermissions = setAppFolderPermissions;

    vm.app = $scope.model;
    vm.app.settings.editorType = _.chain(vm).get('app.settings.editorType').defaultTo('VIEWER').value();
    vm.app.settings.commentsAllowed = _.chain(vm).get('app.settings.commentsAllowed').defaultTo(false).value();

    function setAppFolderPermissions() {
      _.set(vm.app.settings, 'folderPermissions.modifyRole', vm.app.settings.editorType);
    }

    function onInit() {
      vm.setAppFolderPermissions();
    }
  }

})(angular);

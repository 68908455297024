import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {App} from '@apps/api/app';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Observable} from 'rxjs';

/**
 * Service to search for apps by their name and/or key
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalAppService {

  constructor(@Inject(HttpClient) protected httpClient: HttpClient) {
  }

  /**
   * Searches for an app by a specific name
   *
   * @param page The page to select
   * @param name The substring to search for in the app name
   * @returns An observable of a page of matching apps
   */
  getByName(page: Pageable, name: string): Observable<Page<App<any>>> {
    return this.get(page, '', name);
  }

  /**
   * Searches for an app by a specific key
   *
   * @param page The page to select
   * @param key The key to look for
   * @returns An observable of a page of matching apps
   */
  getByKey(page: Pageable, key: string): Observable<Page<App<any>>> {
    return this.get(page, key, '');
  }

  /**
   * Searches for an app by a specific name and key
   *
   * @param page The page to select
   * @param key The key to look for
   * @param name The substring to search for in the app name
   * @returns An observable of a page of matching apps
   */
  get(page: Pageable, key: string, name: string): Observable<Page<App<any>>> {
    const params = page.toHttpParams({key, name});
    return this.httpClient.get<Page<App<any>>>(this.getBaseUrl(), {params});
  }

  /**
   * Returns an app by its id
   * @param id The app id
   *
   * @returns An observable of the app
   */
  getById(id: string): Observable<App<any>> {
    return this.httpClient.get<App<any>>(`${this.getBaseUrl()}/${id}`);
  }

  protected getBaseUrl(): string {
    return '/web/apps';
  }
}

import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {EventStatusIconService} from '@app/events/event-status-icon/event-status-icon.service';
import {EventQueryData} from '@app/events/events/event-query-data';
import {EventQueryUiData} from '@app/events/events/event-query-ui-data';
import {FilterSelectionItems} from '@app/events/events/filter-selection-items';
import {SelectionEntry} from '@app/filter/selection-filter/selection-entry';
import {SessionStorageService} from '@core/storage/session-storage/session-storage.service';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {EventSearch} from '@domain/event/event-search';
import {EventService} from '@domain/event/event.service';
import {ParticipantStatus} from '@domain/event/participant-status';
import {SenderEvent} from '@domain/event/sender-event';
import {Direction} from '@domain/pagination/direction.enum';
import {Order} from '@domain/pagination/order';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {SenderService} from '@domain/sender/sender/sender.service';
import {Ng1SelectionFilterService} from '@root/typings';
import {DateSelection} from '@shared/date-range-picker/date-selection';
import {StateParams, StateService, UIRouter} from '@uirouter/core';
import {NG1_SELECTION_FILTER_SERVICE} from '@upgrade/upgrade.module';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Moment} from 'moment';
import {NgxPermissionsService} from 'ngx-permissions';
import {BehaviorSubject, from, Observable, Subscription as RxJsSubscription} from 'rxjs';
import {map, skip, switchMap} from 'rxjs/operators';

const STORAGE_KEY = 'eventQuery';
const DEFAULT_PAGE_SIZE = 20;
const REQUIRED_QUERY_PROPERTIES: string[] = ['term', 'from', 'to', 'status', 'participationStatus'];
const ARRAY_PROPERTIES: string[] = ['status', 'participationStatus'];
const SEARCH_FIELDS: string[] = ['displayName', 'description'];

/**
 * Events component that provides filter functionality, pagination and request events.
 */
@Component({
  selector: 'coyo-events',
  templateUrl: './events.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsComponent implements OnInit, OnDestroy {

  canCreateEvent$: Observable<boolean>;
  isMobile$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  page$: Observable<Page<SenderEvent>>;
  uiParams$: Observable<EventQueryUiData>;
  senderId: string;

  private statusKeys: string[] = ['SUBSCRIBED_HOSTS_OR_HOST_YOURSELF'];
  private participantStatusKeys: ParticipantStatus[] = [
    ParticipantStatus.PENDING,
    ParticipantStatus.ATTENDING,
    ParticipantStatus.MAYBE_ATTENDING,
    ParticipantStatus.NOT_ATTENDING
  ];
  private pageable: Pageable = new Pageable(0, DEFAULT_PAGE_SIZE, null, new Order('displayName.sort', Direction.Asc));

  private isLoadingSubject: BehaviorSubject<boolean>;
  private eventsPageSubject: BehaviorSubject<Page<SenderEvent>>;
  private query$: Observable<EventQueryData>;
  private uiParamsSubject: BehaviorSubject<EventQueryUiData>;
  private eventQueryParameterSubject: BehaviorSubject<EventQueryData>;
  private eventQuerySubscription: RxJsSubscription;

  private static toMoment(date: string): Moment {
    return date ? moment(date, EventSearch.DATE_FORMAT) : null;
  }

  private static definedPropertiesOnly<T>(obj: T): T {
    return _(obj).omitBy(_.isUndefined).omitBy(_.isNull).omitBy(EventsComponent.isEmptyArray).value() as T;
  }

  private static isEmptyArray(array: unknown[]): boolean {
    return Array.isArray(array) && _.compact(array).length === 0;
  }

  constructor(
    private readonly eventService: EventService,
    private readonly windowSizeService: WindowSizeService,
    private readonly stateService: StateService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly permissionService: NgxPermissionsService,
    private readonly senderService: SenderService,
    private readonly eventStatusIconService: EventStatusIconService,
    private readonly uiRouter: UIRouter,
    @Inject(NG1_SELECTION_FILTER_SERVICE) private readonly selectionFilterService: Ng1SelectionFilterService,
  ) { }

  ngOnInit(): void {
    this.senderId = this.senderService.getCurrentIdOrSlug();
    this.initializeObservables();
    this.loadPermission();
    this.determineQueryParameter();
    this.updateUiParams();
    this.persistQueryParameter();
    this.determineScreenSize();
  }

  ngOnDestroy(): void {
    this.isLoadingSubject.complete();
    this.eventsPageSubject.complete();
    this.eventQueryParameterSubject.complete();
    this.uiParamsSubject.complete();
    this.eventQuerySubscription.unsubscribe();
  }

  /**
   * Updates the query with the selected filter values.
   *
   * @param selection The SelectionEntry data
   */
  setStatusFilter(selection: SelectionEntry[]): void {
    this.updateEventQueryParameter({status: _.map(selection, 'key')});
  }

  /**
   * Updates the query with the selected filter values.
   *
   * @param selection The selection data
   */
  setParticipantStatusFilter(selection: string[]): void {
    this.updateEventQueryParameter({participationStatus: [selection[0]] as ParticipantStatus[]});
  }

  /**
   * Updates the query with the date selection from a datepicker.
   *
   * @param dateSelection The DateSelection data
   */
  dateSelectionChange(dateSelection: DateSelection): void {
    const currentQueryState = this.eventQueryParameterSubject.getValue();
    const newStart = dateSelection.start ? dateSelection.start.format(EventSearch.DATE_FORMAT) : null;
    const newEnd = dateSelection.end ? dateSelection.end.format(EventSearch.DATE_FORMAT) : null;
    if (currentQueryState.from === newStart && currentQueryState.to === newEnd) {
      return;
    }
    this.updateEventQueryParameter({from: newStart, to: newEnd});
  }

  /**
   * Resets the date selection from query.
   */
  resetDateRange(): void {
    this.updateEventQueryParameter({from: null, to: null});
  }

  /**
   * Resets all query data to default values.
   */
  resetFilter(): void {
    this.updateEventQueryParameter({
        term: null,
        from: null,
        to: null,
        status: null,
        participationStatus: null,
        pageNumber: null
      });
  }

  /**
   * Updates the query with the search term.
   *
   * @param event search term or in some cases a input event
   */
  search(event: string | Event): void {
    if (event instanceof Event) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.updateEventQueryParameter({term: event});
    }
  }

  /**
   * Updates the query with a new page number.
   *
   * @param page The page event
   */
  loadPageNumber(page: PageEvent): void {
    this.updateEventQueryParameter({pageNumber: page.pageIndex});
  }

  private initializeObservables(): void {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.eventsPageSubject = new BehaviorSubject<Page<SenderEvent>>(null);
    this.page$ = this.eventsPageSubject.asObservable();
    this.uiParamsSubject = new BehaviorSubject<EventQueryUiData>({});
    this.uiParams$ = this.uiParamsSubject.asObservable();
    this.eventQueryParameterSubject = new BehaviorSubject<EventQueryData>({});
    this.query$ = this.eventQueryParameterSubject.asObservable();
    this.eventQuerySubscription = this.query$
      .pipe(
        skip(1),
        switchMap(() => this.loadPage())
      )
      .subscribe({
        next: page => this.processPage(page)
      });
  }

  private persistQueryParameter(): void {
    const data: EventQueryData = EventsComponent.definedPropertiesOnly(this.eventQueryParameterSubject.getValue());
    this.sessionStorageService.setValue(STORAGE_KEY, data);
    const stateName = this.uiRouter.globals.current.name;
    this.stateService.transitionTo(stateName, data, {location: true});
  }

  private loadPermission(): void {
    this.canCreateEvent$ = from(this.permissionService.hasPermission('CREATE_EVENT'));
  }

  private determineScreenSize(): void {
    this.isMobile$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS || screenSize === ScreenSize.SM));
  }

  private updateEventQueryParameter(properties: EventQueryData): void {
    this.eventQueryParameterSubject.next(_.assign(this.eventQueryParameterSubject.getValue(), properties));
  }

  private determineQueryParameter(): void {
    // process session storage query parameters
    let params = this.sessionStorageService.getValue(STORAGE_KEY);
    // process url/state parameters
    const urlParams = this.uiRouter.globals.params;
    if (!_.isEmpty(EventsComponent.definedPropertiesOnly(_.pick(urlParams, REQUIRED_QUERY_PROPERTIES)))) {
      params = this.convertPropertiesToArray(urlParams, ARRAY_PROPERTIES);
    }
    // cleanup, filter out not needed properties that comes in via e.g. state params
    this.updateEventQueryParameter(_.pick(params, REQUIRED_QUERY_PROPERTIES) as EventQueryData);
  }

  private convertPropertiesToArray(stateParams: StateParams, properties: string[]): EventQueryData {
    const convertedProperties = _.mapValues(
      EventsComponent.definedPropertiesOnly(_.pick(stateParams, properties)), value => _.flattenDeep(_.concat(value))
    );
    return _.merge(stateParams, convertedProperties) as EventQueryData;
  }

  private generateStatusFilterEntries(selection: string[]): FilterSelectionItems {
    const entries: SelectionEntry[] = this.statusKeys.map((status: string) => ({
      key: status,
      icon: 'notification',
      active: _.includes(selection, status),
      count: 0
    }));
    return this.selectionFilterService.builder().items(entries).build();
  }

  private generateParticipantStatusFilterEntries(selection: ParticipantStatus[]): FilterSelectionItems {
    const entries: SelectionEntry[] = this.participantStatusKeys.map((status: ParticipantStatus) => ({
      key: status,
      icon: this.eventStatusIconService.getStatusIcon(status),
      active: _.includes(selection, status),
      count: 0
    }));
    return this.selectionFilterService.builder().items(entries).build();
  }

  private loadPage(): Observable<Page<SenderEvent>> {
    this.isLoadingSubject.next(true);
    const queryParams = this.eventQueryParameterSubject.getValue();
    this.pageable.page = queryParams.pageNumber || 0;
    const filters = EventsComponent.definedPropertiesOnly({
      status: queryParams.status, participationStatus: queryParams.participationStatus
    });
    const requestParams = new EventSearch(queryParams.term || '', queryParams.from, queryParams.to, filters, null, SEARCH_FIELDS);
    return this.eventService.getEvents(requestParams, this.pageable);
  }

  private processPage(page: Page<SenderEvent>): void {
    this.eventsPageSubject.next(page);
    this.persistQueryParameter();
    this.updateUiParams();
    this.isLoadingSubject.next(false);
  }

  private updateUiParams(): void {
    const currentQueryData: EventQueryData = this.eventQueryParameterSubject.getValue();
    const uiParams: EventQueryUiData = {
      term: currentQueryData.term,
      from: EventsComponent.toMoment(currentQueryData.from),
      to: EventsComponent.toMoment(currentQueryData.to),
      status: this.generateStatusFilterEntries(currentQueryData.status),
      participationStatus: this.generateParticipantStatusFilterEntries(currentQueryData.participationStatus),
      pageNumber: currentQueryData.pageNumber
    };
    this.uiParamsSubject.next(uiParams);
  }

}

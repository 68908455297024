import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectorRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {CaretService} from '@core/caret/caret.service';
import {MentionItem} from '@domain/mention/mention-item';
import {MentionService} from '@domain/mention/mention.service';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import * as _ from 'lodash';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {MentionComponent} from './mention.component';

/**
 * A directive that adds a mention dropdown to input or textarea elements.
 */
@Directive({
  selector: '[coyoMention]'
})
export class MentionDirective implements OnInit, OnDestroy {
  private static readonly PAGEABLE: Pageable = new Pageable(0, 8);
  static readonly THROTTLE: number = 50;

  /**
   * Append the popper-content element to a given selector, if multiple will apply to first.
   */
  @Input() appendTo: string;

  private menuFactory: ComponentFactory<MentionComponent>;
  private menu: ComponentRef<MentionComponent>;
  private lastMatch: string = null;
  private lastMatchStart: number = null;

  private searchSubject: Subject<string> = new Subject();
  private searchSubscription: Subscription;
  private menuSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elemRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    private viewRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private caretService: CaretService,
    private mentionService: MentionService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.menuFactory = this.componentFactoryResolver.resolveComponentFactory(MentionComponent);
    this.searchSubscription = this.searchSubject.asObservable()
      .pipe(debounceTime(MentionDirective.THROTTLE))
      .pipe(switchMap(term => this.mentionService.getItems(term, MentionDirective.PAGEABLE)))
      .subscribe(page => this.updatePage(page));
  }

  ngOnDestroy(): void {
    this.unsubscribeMenu();
    this.searchSubscription.unsubscribe();
  }

  /**
   * Event listener for user interactions via the keyboard.
   *
   * @param $event the keyboard event
   */
  @HostListener('keydown', ['$event']) onKeyDown($event: KeyboardEvent): void {
    const length = this.countItems();
    const key = $event.key || $event.keyCode; // tslint:disable-line:deprecation
    if (length > 0) {
      switch (key) {
        case 40:
        case 'Down':
        case 'ArrowDown':
          this.stopEvent($event);
          this.menu.instance.active = Math.min(this.menu.instance.active + 1, length - 1);
          return;
        case 38:
        case 'Up':
        case 'ArrowUp':
          this.stopEvent($event);
          this.menu.instance.active = Math.max(0, this.menu.instance.active - 1);
          return;
        case 9:
        case 'Tab':
        case 13:
        case 'Enter':
          this.stopEvent($event);
          this.menu.instance.select(this.menu.instance.page.content[this.menu.instance.active].user);
          return;
        case 27:
        case 'Esc':
        case 'Escape':
          this.stopEvent($event);
          this.destroyMenu();
          return;
      }
    }
  }

  /**
   * Event listener for user interactions via the keyboard.
   *
   * @param $event the keyboard event
   */
  @HostListener('keyup', ['$event']) onKeyUp($event: KeyboardEvent): void {
    const key = $event.key || $event.keyCode; // tslint:disable-line:deprecation
    switch (key) {
      case 40:
      case 'Down':
      case 'ArrowDown':
      case 38:
      case 'Up':
      case 'ArrowUp':
      case 9:
      case 'Tab':
      case 13:
      case 'Enter':
      case 27:
      case 'Esc':
      case 'Escape':
        return;
    }

    const match = this.findLastMention();
    if (match === null) {
      this.destroyMenu();
    } else if (match.index + match[1].length !== this.lastMatchStart && !$event.defaultPrevented) {
      this.createMenu(match);
    } else if (this.countItems() > 0 || this.lastMatch === '' || !match[2].startsWith(this.lastMatch)) {
      this.updateMatch(match);
    }
  }

  /*
   * Prevents the default action for the given event and stops its propagation.
   */
  private stopEvent($event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  /*
   * Creates a new mention dropdown and positions it at the last mention trigger.
   * It also subscribes to the selected emitter and inserts the selected slug.
   */
  private createMenu(match: RegExpExecArray): void {
    this.destroyMenu();

    const elem = this.elemRef.nativeElement;
    const offset = match ? match[2].length : 0;
    const caret = this.caretService.getCoordinates(elem, elem.selectionEnd - offset);
    this.menu = this.viewRef.createComponent(this.menuFactory);
    this.menu.instance.appendTo = this.appendTo;
    this.menu.instance.top = `${caret.top - elem.scrollTop}px`;
    this.menu.instance.left = `${caret.left - elem.scrollLeft}px`;
    this.menu.instance.height = `${caret.height}px`;
    this.menu.instance.clickedOutside.subscribe(() => this.destroyMenu());
    this.menu.instance.selected.subscribe((slug: string) => {
      this.insertTextAtCaret(slug);
      this.destroyMenu();
    });

    this.cd.detectChanges();
    this.updateMatch(match);
  }

  /*
   * Unsubscribes from the menu subscribed callback.
   */
  private unsubscribeMenu(): void {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
      this.menuSubscription = null;
    }
  }

  /*
   * Removes the current mention dropdown.
   */
  private destroyMenu(): void {
    this.unsubscribeMenu();
    this.viewRef.clear();
    this.menu = null;

    this.updateMatch(null);
  }

  /*
   * Remembers the last match for caching purposes.
   */
  private updateMatch(match: RegExpExecArray | null): void {
    if (!match) {
      this.lastMatch = null;
      this.lastMatchStart = null;
    } else if (this.lastMatch !== match[2]) {
      this.lastMatch = match[2];
      this.lastMatchStart = match.index + match[1].length;
      this.searchSubject.next(match[2]);
    }
  }

  /*
   * Updates the mention dropdown with the latest pagination data.
   */
  private updatePage(page: Page<MentionItem>): void {
    if (this.menu) {
      this.menu.instance.page = page;
      this.menu.instance.active = 0;
    }
  }

  /*
   * Counts the number of items on the current mention dropdown.
   */
  private countItems(): number {
    return _.get(this.menu, 'instance.page.content.length', 0);
  }

  /*
   * Finds the last mention trigger char before the current selection.
   */
  private findLastMention(): RegExpExecArray | null {
    const elem = this.elemRef.nativeElement;
    const text = elem.value.substring(0, elem.selectionStart);
    const last = /(^|[^-0-9a-zA-Z])@([^@]*)(?![\s\S]*@)/g.exec(text);
    return last && !/[\r\n]/.exec(last[2]) ? last : null;
  }

  /*
   * Inserts the given text at the current caret position.
   */
  private insertTextAtCaret(text: string): void {
    const elem = this.elemRef.nativeElement;
    const prefixEnd = this.getEndOfTextBeforeMention(elem);
    const suffixStart = this.getStartOfTextAfterMention(elem);
    const prefix = elem.value.substring(0, prefixEnd);
    const suffix = elem.value.substring(suffixStart, elem.value.length);
    const addSpace = !suffix || /^[-0-9a-zA-Z]/g.exec(suffix) !== null;
    const offset = text.length + (addSpace || suffix.startsWith(' ') ? 1 : 0);

    this.updateValue(elem, prefix + text + (addSpace ? ' ' : '') + suffix);
    elem.setSelectionRange(prefixEnd + offset, prefixEnd + offset);
    elem.focus();
  }

  /*
   * Returns the end of the text up to (and including) the latest mention trigger,
   * e.g. will return `7` for the given text `Hello @world how are you?`.
   */
  private getEndOfTextBeforeMention(elem: HTMLInputElement | HTMLTextAreaElement): number {
    const textBeforeSelection = elem.value.substring(0, elem.selectionStart);
    const partialBeforeSelection = /(?:^|[^-0-9a-zA-Z])@([^@]*)(?![\s\S]*[^-0-9a-zA-Z])/g.exec(textBeforeSelection)[1];
    return elem.selectionStart - partialBeforeSelection.length;
  }

  /*
   * Returns the start of the text form (and excluding) the latest mention slug,
   * e.g. will return `12` for the given text `Hello @world how are you?`.
   */
  private getStartOfTextAfterMention(elem: HTMLInputElement | HTMLTextAreaElement): number {
    const isSelection = elem.selectionStart !== elem.selectionEnd;
    const textAfterSelection = elem.value.substring(elem.selectionEnd, elem.value.length);
    const partialAfterSelection = isSelection ? '' : /[-0-9a-zA-Z]*/.exec(textAfterSelection)[0];
    return elem.selectionEnd + partialAfterSelection.length;
  }

  /*
   * Updates the value of the given input or textarea element and dispatches an `input`
   * event so angular bindings are updated (IE11 compatible).
   */
  private updateValue(elem: HTMLInputElement | HTMLTextAreaElement, value: string): void {
    elem.value = value;

    let event: Event;
    if (typeof (Event) === 'function') {
      event = new Event('input', {bubbles: false, cancelable: true});
    } else {
      event = this.document.createEvent('Event');
      event.initEvent('input', false, true);
    }

    elem.dispatchEvent(event);
  }
}

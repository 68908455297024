(function (angular) {
  'use strict';

  angular
      .module('coyo.account')
      .controller('AccountNotificationsController', AccountNotificationsController);

  /**
   * Controller for the account notification settings view
   */
  function AccountNotificationsController() {
  }

})(angular);

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AnchorDirective} from '@shared/anchor/anchor.directive';

/**
 * Module exporting a anchor directive.
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AnchorDirective
  ],
  exports: [
    AnchorDirective
  ]
})
export class AnchorModule {}

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Plugin} from '@domain/plugin/plugin';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {BehaviorSubject} from 'rxjs';
import {PluginAdminService} from '../plugin/plugin-admin.service';

@Component({
  templateUrl: './plugin-url-modal.component.html',
  styleUrls: ['./plugin-url-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PluginUrlModalComponent implements OnInit {

  // ATTENTION: Keep in sync with PluginConstants.java
  readonly SUPPORTED_MANIFEST_VERSIONS: string[] = ['2.0.0'];

  isLoading$: BehaviorSubject<boolean>;
  pluginUrl: FormControl;
  pluginForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<PluginUrlModalComponent, Plugin>,
              private pluginAdminService: PluginAdminService) {
  }

  ngOnInit(): void {
    this.isLoading$ = new BehaviorSubject<boolean>(false);
    this.pluginUrl = new FormControl('', [Validators.required, CoyoValidators.url]);
    this.pluginForm = new FormGroup({manifestUrl: this.pluginUrl});
  }

  submit(): void {
    this.isLoading$.next(true);
    this.pluginAdminService.add(this.pluginUrl.value).subscribe(
      plugin => this.dialogRef.close(plugin),
      () => this.isLoading$.next(false));
  }
}

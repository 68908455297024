import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {USER_PROFILE_WIDGET} from '@widgets/user-profile/user-profile-widget-config';
import {CleanPhoneNumberPipe} from './clean-phone-number/clean-phone-number.pipe';
import {UserProfileWidgetSettingsComponent} from './user-profile-widget-settings/user-profile-widget-settings.component';
import {UserProfileWidgetComponent} from './user-profile-widget/user-profile-widget.component';

@NgModule({
  declarations: [
    CleanPhoneNumberPipe,
    UserProfileWidgetComponent,
    UserProfileWidgetSettingsComponent
  ],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    SenderUIModule
  ],
  providers: [{
    provide: WIDGET_CONFIGS,
    useValue: USER_PROFILE_WIDGET,
    multi: true
  }]
})
export class UserProfileWidgetModule {
}

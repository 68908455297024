import {ChangeDetectionStrategy, Component, HostBinding, OnInit} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'coyo-notifications-wrapper',
  templateUrl: './notifications-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsWrapperComponent implements OnInit {
  canUseHashtags$: Promise<boolean>;

  @HostBinding('class.mat-typography') matTypography: boolean = true;

  constructor(private permissionService: NgxPermissionsService) { }

  ngOnInit(): void {
    this.canUseHashtags$ = this.permissionService.hasPermission('USE_HASHTAGS');
  }
}

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, Injector, Optional} from '@angular/core';
import * as _ from 'lodash';
import {NGXLogger} from 'ngx-logger';
import {
  MessageFormatConfig,
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler
} from 'ngx-translate-messageformat-compiler';

/**
 * This translation compiler uses document.createElement to sanitize all of its output. Unfortunately,
 * efforts to use Angular's `DomSanitizer` did not work out, since the sanitizer will convert umlauts
 * to HTML escaped entities.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationCompilerService extends TranslateMessageFormatCompiler {

  constructor(private injector: Injector, @Inject(DOCUMENT) private document: Document,
              @Inject(MESSAGE_FORMAT_CONFIG) @Optional() config?: MessageFormatConfig) {
    super(config);
    // hack for languages which do not have 'one' as pluralization parameter (for example chinese) but still uses
    // english fallback message keys
    if (this['messageFormat'] && typeof this['messageFormat'].disablePluralKeyChecks === 'function') {
      this['messageFormat'].disablePluralKeyChecks();
    }
  }

  /**
   * Compiles a single translation in a single language.
   *
   * @param value the translated value
   * @param lang the language
   * @return the compiled value
   */
  compile(value: string, lang: string): (params: any) => string {
    return params => {
      try {
        return this.resolve(super.compile(value, lang)(params));
      } catch (e) {
        // tslint:disable-next-line:no-console
        this.injector.get<{error(...params: any): void}>(NGXLogger, {error: console.error})
          .error(`Could not compile translations for language '${lang}'`, {...e, ...{value}});
        return value;
      }
    };
  }

  /**
   * Compiles a set of translations.
   *
   * @param translations the translations object
   * @param lang the language
   * @return the compiled values
   */
  compileTranslations(translations: any, lang: string): any {
    try {
      const compiledTranslations = super.compileTranslations(translations, lang);
      return _.mapValues(compiledTranslations, (fn, key) => (params: any) => {
        try {
          return this.resolve(fn(params));
        } catch (e) {
          // tslint:disable-next-line:no-console
          this.injector.get<{error(...params: any): void}>(NGXLogger, {error: console.error})
            .error(`Could not render message key ${key} ${e}`);
          return key;
        }
      });
    } catch (e) {
      const {success, error} = this.locateError(translations, lang);
      // tslint:disable-next-line:no-console
      this.injector.get<{error(...params: any): void}>(NGXLogger, {error: console.error})
        .error(`Could not compile translations for language '${lang}'`, error);
      return success;
    }
  }

  private resolve(result: any): string {
    if (result) {
      const elem = this.document.createElement('div');
      elem.innerHTML = result;
      return elem.textContent;
    }
    return result;
  }

  private locateError(translations: any, lang: string): { success: any, error: any } {
    let success = {};
    const error = {};
    _.forEach(translations, (value, key) => {
      try {
        const translation = {};
        translation[key] = value;
        success = {...success, ...super.compileTranslations(translation, lang)};
      } catch (e) {
        error[key] = {...e, ...{value}};
      }
    });
    return {success, error};
  }
}

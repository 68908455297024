<div #swiperContainer class="swiper-container" [ngClass]="sizeClass">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <!-- Slides -->
    <div class="swiper-slide" *ngFor="let slide of widget.settings.slides" tabindex="-1">
      <a tabindex="-1" [attr.href]="slide._url ? slide._url : null" class="slick-slide-inner" [attr.target]="slide._newTab ? '_blank' : '_self'">
        <img src="{{slide._image}}" [attr.href]="slide._url ? slide._url : null" alt="{{slide._alt}}" class="teaser-image">
        <div class="teaser-caption" [ngClass]="noPaginationClass" *ngIf="!!slide.headline">
          <h3>{{slide.headline}}</h3>
        </div>
      </a>
    </div>
  </div>
  <!-- If we need pagination -->
  <div class="swiper-pagination teaser-widget" *ngIf="widget.settings.slides.length > 1"></div>
</div>

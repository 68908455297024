import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {TimelineItem} from '@domain/timeline-item/timeline-item';

/**
 * Base class for timeline item content components
 */
@Component({
  template: ''
})
export class TimelineItemContentComponent {
  /**
   * The timeline item
   */
  @Input() item: TimelineItem;

  constructor(protected cd: ChangeDetectorRef) {
  }

  /**
   * Call the change detector to detect changes
   */
  detectChanges(): void {
    this.cd.detectChanges();
  }
}

<div [formGroup]="parentForm" class="media-row">
  <ng-content></ng-content>
  <coyo-file-image-preview [file]="file" [options]="{backgroundImage: true}"
                           [previewUrl]="previewUrl" size="M"
                           [groupId]="file.senderId"
                           *ngIf="isImage(file)"></coyo-file-image-preview>
  <coyo-video-preview-cover *ngIf="isVideo(file)"></coyo-video-preview-cover>
  <div class="media-form-fields">
    <input type="text" class="form-control" formControlName="description" [attr.placeholder]="file.name">
    <mat-checkbox formControlName="preview">
      <span translate="WIDGET.MEDIA.SETTINGS.MEDIA.PREVIEW" class="hidden-sm-and-down"></span>
      <span translate="WIDGET.MEDIA.SETTINGS.MEDIA.PREVIEW_SHORT" class="hidden-md-and-up"></span>
    </mat-checkbox>
  </div>
  <button mat-icon-button [attr.aria-label]="'WIDGET.MEDIA.SETTINGS.MEDIA.DELETE_ARIA' | translate"
          (click)="deleted.emit()">
    <mat-icon svgIcon="delete"></mat-icon>
  </button>
</div>

(function (angular) {
  'use strict';

  hashtagSubscriptionButtonController.$inject = ["authService", "$q", "ngxHashtagSettingsService", "ngxHashtagService"];
  angular
      .module('coyo.account')
      .directive('oyocHashtagSubscriptionButton', hashtagSubscriptionButton)
      .controller('HashtagSubscriptionButtonController', hashtagSubscriptionButtonController);

  function hashtagSubscriptionButton() {
    return {
      templateUrl: 'app/modules/account/components/hashtag-subscribe-button/hashtag-subscribe-button.html',
      controller: 'HashtagSubscriptionButtonController',
      controllerAs: '$ctrl',
      scope: true,
      bindToController: {
        term: '@'
      }
    };
  }

  function hashtagSubscriptionButtonController(authService, $q, ngxHashtagSettingsService, ngxHashtagService) {
    var vm = this;

    vm.isSearchTermAHashtag = isSearchTermAHashtag;
    vm.isSubscribedHashtag = isSubscribedHashtag;
    vm.onHashtagSubscribeButtonClick = onHashtagSubscribeButtonClick;
    vm.$onInit = onInit;

    vm.subscriptionLoading = true;

    function isSearchTermAHashtag() {
      return ngxHashtagService.isConcludedHashtag(vm.term);
    }

    function isSubscribedHashtag() {
      return vm.subscribedHashtags && _isHashtagInArray();
    }

    function onHashtagSubscribeButtonClick() {
      var termToUpperCase = vm.term.toUpperCase();
      if (!ngxHashtagService.isConcludedHashtag(vm.term)) {
        return $q.reject();
      }

      if (isSubscribedHashtag()) {
        vm.subscribedHashtags.splice(vm.subscribedHashtags.indexOf(termToUpperCase), 1);
        return ngxHashtagSettingsService.deleteHashtag(vm.term).toPromise()
            .catch(function () {
              vm.subscribedHashtags.push(termToUpperCase);
            });
      } else {
        vm.subscribedHashtags.push(termToUpperCase);
        return ngxHashtagSettingsService.addHashtag(vm.term).toPromise()
            .catch(function () {
              vm.subscribedHashtags.splice(vm.subscribedHashtags.indexOf(termToUpperCase), 1);
            });
      }
    }

    function onInit() {
      if (authService.canUseHashtags() && ngxHashtagService.isConcludedHashtag(vm.term)) {
        ngxHashtagSettingsService.getHashtags().toPromise()
            .then(function (subscribedHashtags) {
              vm.subscribedHashtags = subscribedHashtags;
              vm.subscriptionLoading = false;
            });
      }
    }

    function _isHashtagInArray() {
      return _.findIndex(vm.subscribedHashtags, function (subscribedHashtag) {
        return subscribedHashtag.toUpperCase() === vm.term.toUpperCase();
      }) > -1;
    }
  }

})(angular);

<ng-container *ngIf="(state$ | async) as state">
  <ng-select
    (scroll)="onScroll($event)"
    [ngClass]="settings.class"
    [clearable]="settings.clearable"
    [closeOnSelect]="settings.closeOnSelect"
    [compareWith]="settings.compareFn"
    [items]="state.items"
    [loading]="state.loading"
    [multiple]="settings.multiselect"
    [placeholder]="settings.placeholder | translate"
    [typeahead]="typeahead$"
    [appearance]="settings.appearance"
    [virtualScroll]="true"
    [bindValue]="settings.bind?.value"
    appendTo=".ng-select-overlay-container">
    <ng-template let-searchTerm="searchTerm" ng-notfound-tmp>
      <ng-container
        *ngTemplateOutlet="noResultTemplate ? noResultTemplate : noResultTemplateDefault; context: {searchTerm: searchTerm}">
      </ng-container>
    </ng-template>
    <ng-template let-item="item" let-searchTerm="searchTerm" ng-option-tmp>
      <ng-container *ngTemplateOutlet="itemTemplate; context: {item: item, searchTerm: searchTerm}">
      </ng-container>
    </ng-template>
    <ng-template let-clear="clear" let-item="item" ng-label-tmp>
      <ng-container *ngTemplateOutlet="selectedTemplate; context: {clear: clear, item: item}">
      </ng-container>
    </ng-template>
    <ng-template #noResultTemplateDefault let-searchTerm="searchTerm">
      <div class="ng-option disabled">{{'SELECT_UI.DATA_NOT_FOUND' | translate : {searchTerm: searchTerm} }}</div>
    </ng-template>
  </ng-select>
</ng-container>

<div class="share-item-message" *ngIf="!article.deleted">
  <a class="share-item-title" href="{{articleLink}}">
    {{article.title}}
  </a>
  <div class="share-item-meta">
    <span [translate]="'MODULE.TIMELINE.SHARE.POSTED_IN'"></span>
    <coyo-app-href [app]="article.app">
      <i class="zmdi zmdi-library"></i>&nbsp;{{article.app.name}}
    </coyo-app-href>
    <ng-container *ngIf="article.author">
      <span translate="BY"></span>
      <coyo-sender-link
          [sender]="article.author">
        <i class="zmdi zmdi-{{authorIcon}}"></i>&nbsp;
      </coyo-sender-link>
    </ng-container>
  </div>
  <div class="share-item-description">
    <a class="share-item-more" [translate]="'APP.WIKI.READ_MORE'" href="{{articleLink}}"></a>
  </div>
</div>
<div class="share-item-message" *ngIf="article.deleted">
  <div class="alert alert-warning">
    <span [translate]="'MODULE.TIMELINE.WIKI.WARNING'"></span>
  </div>
</div>

(function (angular) {
  'use strict';

  AdminJobsDetailsController.$inject = ["$rootScope", "$scope", "$injector", "Pageable", "job"];
  angular.module('coyo.admin.jobs')
      .controller('AdminJobsDetailsController', AdminJobsDetailsController);

  function AdminJobsDetailsController($rootScope, $scope, $injector, Pageable, job) {
    var vm = this;

    vm.job = job;
    vm.isMobile = $rootScope.screenSize.isXs || $rootScope.screenSize.isSm;

    vm.$onInit = _init;
    vm.startJob = startJob;
    vm.refresh = refresh;

    // ========================================

    function startJob() {
      if (vm.loading || vm.currentState === 'RUNNING') {
        return;
      }

      vm.job.start().then(function () {
        $injector.get('ngxNotificationService').success('ADMIN.JOBS.DETAILS.START_MANUALLY_DONE');
        vm.currentState = 'RUNNING';
      });
    }

    function refresh() {
      var page = _.get(vm.currentPage, 'number', 0);
      var pageSize = _.get(vm.currentPage, 'size', 10);
      _loadPage(page, pageSize);
    }

    // ========================================

    function _loadPage(page, pageSize) {
      if (vm.loading) {
        return;
      }

      vm.loading = true;
      job.getStatus(new Pageable(page, pageSize)).then(function (data) {
        vm.currentPage = data;
        vm.currentState = _.get(data, 'content[0].state');
      }).finally(function () {
        vm.loading = false;
      });
    }

    function _init() {
      $scope.$on('$destroy', $rootScope.$on('screenSize:changed', function (event, screenSize) {
        vm.isMobile = screenSize.isXs || screenSize.isSm;
      }));

      $scope.$watch(function () {
        return _.get(vm.currentPage, 'content');
      }, function (newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          newVal.forEach(function (status) {
            status.duration = Math.ceil((status.endTime - status.startTime) / 60000);
            status.durationUnit = 'minute';
            if (status.duration < 2) {
              status.duration = Math.ceil((status.endTime - status.startTime) / 1000);
              status.durationUnit = 'second';
            }
          });
        }
      });

      _loadPage(0, 10);
    }
  }

})(angular);

import {Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AppRegistryService} from '@apps/api/app-registry.service';
import {AppSettingsModalComponent} from '@apps/api/app-settings-modal/app-settings-modal.component';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {MatDialogSize} from '@coyo/ui';
import {Sender} from '@domain/sender/sender';
import {Observable} from 'rxjs';
import {filter, first} from 'rxjs/operators';
import {App} from '../app';

interface AppSettingsModalResult {
  deleted?: boolean;
  edited?: boolean;
  app: App<AppSettings>;
}

/**
 * Service for opening app settings modal
 */
@Injectable({
  providedIn: 'root'
})
export class AppSettingsModalService {

  constructor(private ngZone: NgZone, private dialog: MatDialog, private appRegistry: AppRegistryService) {
  }

  /**
   * Opens the settings modal
   *
   * @param sender The parent sender
   * @param app The app to be changed
   *
   * @returns The changed app
   */
  open(sender: Sender, app: App<AppSettings>): Observable<AppSettingsModalResult> {
    return this.ngZone.run(() => {
      const dialogRef = this.dialog.open(AppSettingsModalComponent, {
        width: MatDialogSize.Large,
        data: {
          sender,
          app,
          appConfig: this.appRegistry.get(app.key)
        }
      });

      return dialogRef.afterClosed()
        .pipe(first())
        .pipe(filter(value => value));
    });
  }
}

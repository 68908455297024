import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {Skeleton} from '@coyo/ui/lib/ui/skeleton/skeleton';
import {GuestSelection} from '@domain/guest/guest-selection';
import {SelectedGuestsDataSource} from '@shared/sender-ui/user-chooser/data-source/selected-guests-data-source';
import {UserChooserDataSource} from '@shared/sender-ui/user-chooser/data-source/user-chooser-data-source';
import {BehaviorSubject} from 'rxjs';

interface VirtualScrollSizes {
  itemSize: number;
  minBufferPx: number;
  maxBufferPx: number;
}

@Component({
  selector: 'coyo-guest-list',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuestListComponent implements OnInit {

  /**
   * Data source containing list of guests to display in the guest list.
   */
  @Input()
  guestDataSource: BehaviorSubject<UserChooserDataSource | SelectedGuestsDataSource>;

  /**
   * Determines whether the element should get a checkbox or not. Default is true.
   */
  @Input()
  renderCheckbox?: boolean = true;

  /**
   * Determines whether the additional guest information is to be displayed or not. Default is true.
   */
  @Input()
  showAdditionalInfos?: boolean = true;

  /**
   * The change of a guest list entry.
   */
  @Output()
  changes: EventEmitter<GuestSelection> = new EventEmitter();

  scrollSettings: number = 0;

  virtualScrollSizes: VirtualScrollSizes[] = [{
    itemSize: 80,
    minBufferPx: 320,
    maxBufferPx: 480
  }, {
    itemSize: 56,
    minBufferPx: 560,
    maxBufferPx: 1120
  }];

  readonly skeletons: Skeleton[] = [
    {top: 16, left: 0, width: 40, height: 40, radius: '50%'},
    {top: 18, left: 56, width: 'calc(60% - 70px)', height: 16},
    {top: 42, left: 56, width: 'calc(40% - 70px)', height: 12},
    {top: 26, left: 'calc(100% - 20px)', width: 20, height: 20}
  ];

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.scrollSettings = this.renderCheckbox ? 0 : 1;
  }

  emitChange(guest: GuestSelection): void {
    this.changes.emit(guest);
  }

  change(event: Event, guest: GuestSelection): void {
    event.stopPropagation();
    event.preventDefault();
    guest.selected = !guest.selected;
    this.emitChange(guest);
    this.cd.detectChanges();
  }

  processParams(params: string[]): object {
    const translationParams = {count: params.length};
    for (let i = 0; i < params.length; i++) {
      translationParams['param' + i] = params[i];
    }
    return translationParams;
  }
}

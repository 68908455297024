import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {FileService} from '@domain/file/file/file.service';
import {Store} from '@ngxs/store';
import {ConfirmationDialogService} from '@shared/dialog/confirmation-dialog/confirmation-dialog.service';
import {FileDetailsStateSelectors} from '@shared/files/file-details-modal/state/file-details.state.selectors';
import {Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

/**
 * Breaks the edit in office lock of a file
 */
@Component({
  selector: 'coyo-unlock-edit-in-office-button',
  templateUrl: './unlock-edit-in-office-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnlockEditInOfficeButtonComponent implements OnChanges {

  /**
   * The file id
   */
  @Input() fileId: string;

  /**
   * The file details dialog id
   */
  @Input() fileDetailsDialogId: string;

  canUnlock$: Observable<boolean>;

  constructor(
    private store: Store,
    private fileService: FileService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
  }

  ngOnChanges(): void {
    this.canUnlock$ = this.store
      .select(FileDetailsStateSelectors.getFile(this.fileDetailsDialogId, this.fileId))
      // tslint:disable-next-line:no-boolean-literal-compare
      .pipe(map(file => file.locked && file._permissions?.manage !== false));
  }

  unlock(): void {
    const file = this.store
      .selectSnapshot(FileDetailsStateSelectors.getFile(this.fileDetailsDialogId, this.fileId));
    this.confirmationDialogService.open('FILE_DETAILS.UNLOCK.TITLE', 'FILE_DETAILS.UNLOCK.TEXT')
      .pipe(
        filter(value => value),
        switchMap(() => this.fileService.unlock(file.id, file.senderId))
      )
      .subscribe();
  }

}

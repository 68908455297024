(function (angular) {
  'use strict';

  moderatorService.$inject = ["$state", "$injector", "authService", "etagCacheService"];
  angular
      .module('commons.ui')
      .factory('moderatorService', moderatorService);

  function moderatorService($state, $injector, authService, etagCacheService) {
    /**
     * Toggles the moderator mode for the given user. For all new permissions to be shown properly, a reload is needed.
     * As updates of the user from web sockets notification may interfere with the reload (see COYOFOUR-7171)
     * we need to unsubscribe from the socket subscriptions first, and subscribe to it again if no reload happened.
     */
    function toggleModeratorMode(user) {
      if (user) {
        authService.unsubscribeFromUserUpdate();
        user.setModeratorMode(!user.moderatorMode).then(function () {
          authService.getUser(true);
          _clearEtagCacheAndRevokeToken();
          $state.reload();
        }).finally(function () {
          authService.subscribeToUserUpdate();
        });
      }
    }

    function _clearEtagCacheAndRevokeToken() {
      etagCacheService.clearAll();
      $injector.get('ngxEtagCacheService').clearAll();
      $injector.get('ngxTokenService').getToken(true);
    }

    return {
      toggleModeratorMode: toggleModeratorMode,
    };
  }
})(angular);

import {Compiler, ComponentFactory, Injectable, Injector} from '@angular/core';
import {PdfViewerComponent} from '@shared/pdf/pdf-viewer/pdf-viewer.component';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerLoader {
  constructor(private compiler: Compiler, private injector: Injector) {}

  load(): Promise<ComponentFactory<PdfViewerComponent>> {
    return import('../pdf-lazy.module')
      .then(m => m.PdfLazyModule)
      .then(pdfLazyModule =>
        this.compiler.compileModuleAsync(pdfLazyModule).then(ngModuleFactory => {
          const moduleRef = ngModuleFactory.create(this.injector);
          const factory = moduleRef.componentFactoryResolver.resolveComponentFactory(PdfViewerComponent);
          return factory;
        }));
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SearchViewParams} from '@app/search-shell/search-wrapper/search.params';

/**
 * Integrates the external search (micro frontend served as Angular Element) into the COYO shell.
 */
@Component({
  selector: 'coyo-search-wrapper',
  templateUrl: './search-wrapper.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchWrapperComponent {

  /**
   * State parameters for the search micro frontend
   */
  @Input() params: SearchViewParams;

}

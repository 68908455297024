import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {Skeleton} from '@coyo/ui';
import {User} from '@domain/user/user';
import {WidgetComponent} from '@widgets/api/widget-component';
import {WidgetVisibilityService} from '@widgets/api/widget-visibility/widget-visibility.service';
import {CompleteProfileWidget} from '@widgets/complete-profile/complete-profile-widget';
import * as _ from 'lodash';
import {combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CompleteProfileWidgetService} from '../complete-profile-widget.service';
import {ProfileInfo} from '../profileInfo.model';

/**
 * A single field of the complete profile widget.
 */
interface ProfileInfoField {
  value: boolean;
  label: string;
  state: string;
  params?: { [key: string]: any; };
}

/**
 * Renders the widget to display user profile information
 * which should be completed by the user.
 */
@Component({
  selector: 'coyo-complete-profile-widget',
  templateUrl: './complete-profile-widget.component.html',
  styleUrls: ['./complete-profile-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteProfileWidgetComponent extends WidgetComponent<CompleteProfileWidget> implements OnInit {

  fields$: Observable<ProfileInfoField[]>;
  skeletons: Skeleton[] = [
    { top: 3, left: 0, width: 16, height: 16 }, { top: 3, left: 24, width: 'calc(90% - 24px)', height: 16 },
    { top: 33, left: 0, width: 16, height: 16 }, { top: 33, left: 24, width: 'calc(75% - 24px)', height: 16 },
    { top: 63, left: 0, width: 16, height: 16 }, { top: 63, left: 24, width: 'calc(70% - 24px)', height: 16 },
    { top: 93, left: 0, width: 16, height: 16 }, { top: 93, left: 24, width: 'calc(60% - 24px)', height: 16 },
    { top: 123, left: 0, width: 16, height: 16 }, { top: 123, left: 24, width: 'calc(50% - 24px)', height: 16 },
    { top: 153, left: 0, width: 16, height: 16 }, { top: 153, left: 24, width: 'calc(85% - 24px)', height: 16 }
  ];

  constructor(private authService: AuthService,
              private widgetVisibilityService: WidgetVisibilityService,
              private completeProfileWidgetService: CompleteProfileWidgetService,
              @Inject(ChangeDetectorRef) cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.fields$ = combineLatest([
      this.authService.getUser$(),
      this.completeProfileWidgetService.getCompleteProfileTasksValue()
    ]).pipe(tap(([user, info]: [User, ProfileInfo]) =>
      this.widgetVisibilityService.setHidden(this.widget.id, _.every(_.values(info)))
    )).pipe(map(([user, info]: [User, ProfileInfo]) => [{
      value: info.profileFields,
      label: 'WIDGET.COMPLETE_PROFILE.PROFILE_FIELDS',
      state: 'main.profile.info',
      params: {userId: user.slug}
    }, {
      value: info.avatar,
      label: 'WIDGET.COMPLETE_PROFILE.AVATAR',
      state: 'main.profile.activity',
      params: {userId: user.slug}
    }, {
      value: info.cover,
      label: 'WIDGET.COMPLETE_PROFILE.COVER',
      state: 'main.profile.activity',
      params: {userId: user.slug}
    }, {
      value: info.followingUser,
      label: 'WIDGET.COMPLETE_PROFILE.FOLLOWING_USER',
      state: 'main.colleagues'
    }, {
      value: info.pageMember,
      label: 'WIDGET.COMPLETE_PROFILE.PAGE_MEMBER',
      state: 'main.page'
    }, {
      value: info.createdPost,
      label: 'WIDGET.COMPLETE_PROFILE.CREATED_POST',
      state: 'main.profile.activity',
      params: {userId: user.slug}
    }]));
  }

  get title(): string {
    return _.get(this.widget, 'settings._title');
  }
}

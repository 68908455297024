import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {engageWidgetLayoutState} from '@app/engage/engage-widget-layout/engage-widget-layout.state';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';

const uiRouterModuleChild = UIRouterUpgradeModule
  .forChild({states: [engageWidgetLayoutState]});

/**
 * Router module for {@link EngageWidgetLayoutModule}
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    uiRouterModuleChild
  ]
})
export class EngageWidgetLayoutRouterModule {
}

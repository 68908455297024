import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

/**
 * Service to manage user integration settings.
 */
@Injectable({
  providedIn: 'root'
})
export class UserIntegrationSettingsService {

  protected getBaseUrl(): string {
    return '/web/users/integration-settings';
  }

  constructor(private http: HttpClient) {
  }

  /**
   * Get the current status of the users calendar sync settings.
   *
   * @return an observable holding the current state
   */
  isCalendarSyncEnabled(): Observable<boolean> {
    return this.http.get<boolean>(`${this.getBaseUrl()}/calendar-sync`);
  }

  /**
   * Update settings to enable sync of COYO events with external calendar.
   *
   * @param options options request options forwarded to http request
   * @return an empty observable
   */
  enableCalendarSync(options?: { headers: HttpHeaders }): Observable<void> {
    return this.http.put<void>(`${this.getBaseUrl()}/calendar-sync/enable`, null, options);
  }

  /**
   * Update settings to no longer sync COYO events with external calendar.
   *
   * @param options request options forwarded to http request
   * @return an empty observable
   */
  disableCalendarSync(options?: { headers: HttpHeaders }): Observable<void> {
    return this.http.put<void>(`${this.getBaseUrl()}/calendar-sync/disable`, null, options);
  }
}

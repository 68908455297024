import {Injectable} from '@angular/core';

/**
 * Service for content types calculation of the media widget
 */
@Injectable({
  providedIn: 'root'
})
export class ContentTypeService {

  /**
   * Regex for testing the content type.
   *
   * Will deliver five capture groups:
   * 1. type
   * 2. tree (ending in a dot)
   * 3. sub-type
   * 4. suffix
   * 5. parameter string
   */
  private static readonly CONTENT_TYPE_REGEX: RegExp =
    /([a-zA-Z0-9\-_]+)\/((?:[a-zA-Z0-9\-_]+\.)+)*([a-zA-Z0-9\-_]+)(?:\+([a-zA-Z0-9\-_]+))*(?:; ([a-zA-Z0-9\-_=]+))*/;

  /**
   * Checks if a content type symbolize an image
   *
   * @param contentType
   * The content type
   *
   * @return true if content type contains 'image'
   */
  isImage(contentType: string): boolean {
    return this.checkType(contentType, 'image');
  }

  /**
   * Checks if a content type symbolize an video
   *
   * @param contentType
   * The content type
   *
   * @return true if content type contains 'video'
   */
  isVideo(contentType: string): boolean {
    return this.checkType(contentType, 'video');
  }

  private checkType(contentType: string, expectedType: string): boolean {
    const result = ContentTypeService.CONTENT_TYPE_REGEX.exec(contentType);
    return result && result.length > 1 && result[1] === expectedType;
  }
}

(function (angular) {
  'use strict';

  backendUrlService.$inject = ["coyoConfig", "$localStorage", "$rootScope", "$log"];
  angular
      .module('commons.resource')
      .factory('backendUrlService', backendUrlService);

  /**
   * @ngdoc service
   * @name commons.resource.backendUrlService
   *
   * @description
   * Service for getting and setting the backend URL. This service takes the outside configuration parameter
   * commons.config.coyoConfig#backendUrlStrategy which can be any of:
   *
   * 'relative': No absolute backend URL will be prepended to backend URL calls. Calls will be made to /web/...
   * 'static': A configured backend URL will be prepended to backend URL calls.
   * 'configurable': The user can configure the backend URL in the frontend client upon login.
   *
   * @requires commons.config.coyoConfig
   * @requires $localStorage
   * @requires $rootScope
   * @requires $log
   */
  function backendUrlService(coyoConfig, $localStorage, $rootScope, $log) {

    var currentStrategy;

    setStrategy(coyoConfig.backendUrlStrategy || 'relative');

    return {
      getUrl: getUrl,
      isSet: isSet,
      setStrategy: setStrategy
    };

    /**
     * @ngdoc method
     * @name commons.resource.backendUrlService#getUrl
     * @methodOf commons.resource.backendUrlService
     *
     * @description
     * Gets the backend URL from the config or returns an empty string if the backend URL
     * strategy is 'relative'.
     *
     * @return {string} Backend URL.
     */
    function getUrl() {
      if (currentStrategy === 'static') {
        return coyoConfig.backendUrl;
      } else if (currentStrategy === 'relative') {
        return '';
      }

      return null;
    }

    /**
     * @ngdoc method
     * @name commons.resource.backendUrlService#isSet
     * @methodOf commons.resource.backendUrlService
     *
     * @description
     * Checks if a backend URL is set.
     */
    function isSet() {
      var url = getUrl();
      return angular.isDefined(url) && url !== null;
    }

    /**
     * @ngdoc method
     * @name commons.resource.backendUrlService#setStrategy
     * @methodOf commons.resource.backendUrlService
     *
     * @description
     * Updates the backend URL resolutions strategy.
     *
     * @param {string} strategy The new strategy. One of 'relative', 'static' or 'configurable'.
     */
    function setStrategy(strategy) {
      strategy = _.lowerCase(strategy);

      if (strategy === 'static' && (angular.isUndefined(coyoConfig.backendUrl) || coyoConfig.backendUrl === '')) {
        $log.error('[backendUrlService] No backend URL configured. Please make sure to configure a backend URL when ' +
            'using strategy \'static\'. Defaulting to strategy \'relative\'.');
        strategy = 'relative';
      }

      $log.info('[backendUrlService] Using backend URL strategy:', strategy);

      currentStrategy = strategy;
    }
  }

})(angular);

<div class="article" *ngIf="(state$ | async) as state">
  <coyo-blog-article-headline [article]="article" [sender]="state.sender"
                              *ngIf="!params.hideHeadline"></coyo-blog-article-headline>
  <div class="teaser" *ngIf="showTeaserImage()">
    <coyo-image-reference [fileId]="article.teaserImage.fileId" [senderId]="article.teaserImage.senderId"></coyo-image-reference>
  </div>
  <div class="text">
    <p *ngIf="showTeaserText()">{{article.teaserText}}</p>
    <coyo-widget-layout [name]="article.buildLayoutName(article.appId, article.usedLanguage)"
                        [parent]="article" renderStyle="plain" *ngIf="article" [language]="article.usedLanguage">
    </coyo-widget-layout>
  </div>
  <div class="social-options" *ngIf="showSocialFeatures()">
    <coyo-like-container [author]="state.author" [target]="article" *ngIf="article._permissions.like" [condensed]="true"></coyo-like-container>
    <coyo-comment-list [author]="state.author" [subscriptionToken]="article.subscriptionInfo.token" [target]="article" *ngIf="article._permissions.comment"></coyo-comment-list>
  </div>
</div>

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {LaunchpadCategory} from './launchpad-category';

/**
 * Service to manage launchpad categories.
 */
@Injectable({
  providedIn: 'root'
})
export class LaunchpadCategoryService extends DomainService<LaunchpadCategory, LaunchpadCategory> {

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/launchpad/categories';
  }
}

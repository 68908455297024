import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {ImagesCompleteDirective} from '@shared/print/images-complete/images-complete.directive';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WidgetsModule} from '@widgets/widgets.module';
import {MomentModule} from 'ngx-moment';
import {LoadingBarComponent} from './loading-bar/loading-bar.component';
import {PrintPreviewDialogComponent} from './print-preview-dialog/print-preview-dialog.component';
import './print-preview-dialog/print-preview-dialog.service.downgrade';

@NgModule({
  declarations: [
    PrintPreviewDialogComponent,
    ImagesCompleteDirective,
    LoadingBarComponent
  ],
  imports: [
    CoyoCommonsModule,
    MomentModule,
    SenderUIModule,
    UpgradeModule,
    WidgetsModule
  ]
})
export class PrintModule { }

<mat-form-field (click)="$event.stopPropagation()"
                *ngIf="{isMobile: isMobile$ | async} as windowSize"
                appearance="outline"
>
  <mat-label *ngIf="label">{{label}}</mat-label>
  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker">
    <input formControlName="start" matStartDate placeholder="{{'MODULE.EVENTS.FILTER.DATE.START' | translate}}">
    <input formControlName="end" matEndDate placeholder="{{'MODULE.EVENTS.FILTER.DATE.END' | translate}}">
  </mat-date-range-input>
  <mat-datepicker-toggle [for]="picker" matSuffix>
    <mat-icon matDatepickerToggleIcon svgIcon="event-app"></mat-icon>
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker [touchUi]="windowSize.isMobile"></mat-date-range-picker>
  <mat-hint (click)="resetDateSelection()" *ngIf="dateRangeForm.controls.start.value || dateRangeForm.controls.end.value" align="end">
    {{'MODULE.EVENTS.FILTER.DATE.CLEAR' | translate}}
  </mat-hint>
  <mat-error *ngIf="dateRangeForm.controls.start.errors?.matDatepickerParse || dateRangeForm.controls.end.errors?.matDatepickerParse">
    {{'VALIDATION.ERROR.DATE_FORMAT' | translate}}
  </mat-error>
  <mat-error *ngIf="dateRangeForm.controls.start.errors?.matStartDateInvalid || dateRangeForm.controls.end.errors?.matEndDateInvalid">
    {{'VALIDATION.ERROR.DATE_RANGE' | translate}}
  </mat-error>
</mat-form-field>

<coyo-language-picker *ngIf="showTranslations()"
                      [selectedLanguage]="defaultLanguage"
                      [languages]="availableLanguages$ | async"
                      (languageSwitched)="setLanguage($event)"></coyo-language-picker>
<ng-container [formGroup]="parentForm">
  <mat-form-field appearance="outline" class="no-hint" *ngIf="isDefaultLanguageSelected()">
    <mat-label>{{'APPS.SETTINGS.NAME.LABEL' | translate}}
      <coyo-country-flag [locale]="language | locale" *ngIf="showTranslations()"></coyo-country-flag>
    </mat-label>
    <input matInput data-test="input-app-name" type="text" formControlName="name" required>
  </mat-form-field>
  <ng-container *ngIf="!isDefaultLanguageSelected()" [formGroup]="translationGroup?.get(language)">
    <mat-form-field appearance="outline" class="no-hint">
      <mat-label>{{'APPS.SETTINGS.NAME.LABEL' | translate}}
        <coyo-country-flag [locale]="language | locale" *ngIf="showTranslations()"></coyo-country-flag>
      </mat-label>
      <input matInput data-test="input-app-name" type="text" formControlName="name" required>
    </mat-form-field>
  </ng-container>
  <mat-checkbox formControlName="active">{{'APPS.SETTINGS.ACTIVE.LABEL' | translate}}</mat-checkbox>
  <mat-form-field appearance="outline" *ngIf="!isNew" class="slug">
    <mat-label>{{'APPS.SETTINGS.SLUG.LABEL' | translate}}</mat-label>
    <span matPrefix>/{{app.key}}/</span>
    <input matInput data-test="input-app-slug" type="text" formControlName="slug" required>
    <mat-hint align="start" *ngIf="!parentForm.get('slug').errors">{{'APPS.SETTINGS.SLUG.HELP' | translate}}</mat-hint>
    <mat-error align="start"
               *ngIf="parentForm.get('slug').errors?.slugTaken">{{'ERRORS.VALIDATION.ALREADY_TAKEN' | translate}}</mat-error>
    <mat-error align="start"
               *ngIf="parentForm.get('slug').errors?.slugInvalid || parentForm.get('slug').errors?.pattern">{{'ERRORS.VALIDATION.PATTERN' | translate}}</mat-error>
    <mat-error align="start"
               *ngIf="parentForm.get('slug').errors?.required">{{'ERRORS.VALIDATION.REQUIRED' | translate}}</mat-error>
  </mat-form-field>
  <hr *ngIf="config.settings">
  <coyo-ng1-app-settings *ngIf="config.settings && !config.settings.component"
    [config]="config"
    [app]="app"
    (validityChanged)="legacyFormValidityChanged.emit($event)">
  </coyo-ng1-app-settings>
  <coyo-individual-app-settings-container *ngIf="config.settings?.component"
                                          [component]="config.settings.component"
                                          [app]="app"
                                          [parentForm]="settingsFormGroup">
  </coyo-individual-app-settings-container>
</ng-container>

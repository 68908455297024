import {Injectable} from '@angular/core';
import {LikeInfo} from '@domain/like/like-info';
import {LikeInfoUpdate} from '@domain/like/like-info-update';
import {LikeTargetState} from '@domain/like/like-target-state';
import {LikeToggledEvent} from '@domain/like/like-toggled-event';
import {LikeService} from '@domain/like/like.service';
import {
  LikeAddedOrRemoved,
  LikeInfosFetched,
  SubscribeLikeInfo,
  ToggleLike,
  ToggleLikeFailed,
  ToggleLikeSucceeded,
  UnsubscribeLikeInfo,
  UpdateSenderIdForTarget
} from '@domain/like/likes.actions';
import {Sender} from '@domain/sender/sender';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

/**
 * The model of the LikeState
 */
interface LikeStateModel {
  [targetId: string]: LikeTargetState;
}

@Injectable({providedIn: 'root'})
@State<LikeStateModel>({
  name: 'likes',
  defaults: {}
})
export class LikeState {
  static readonly DEFAULT_STATE: LikeTargetState = {
    isLoading: false,
    isLikedByCurrentSender: false,
    latestLikingSenders: [] as Sender[],
    likingSenderCount: 0,
    latestOtherLikingSenders: [] as Sender[],
    otherLikingSenderCount: 0,
    subscriberCount: 0,
    currentSenderId: undefined
  };
  static readonly MAX_STORED_SENDERS_COUNT: number = 10;

  private likeUpdateSubscription: Subscription;

  private likeToggledEventSubscriptions: Map<string, Subscription> = new Map<string, Subscription>();

  constructor(private likeService: LikeService) {
  }

  @Selector()
  static likesByTarget(state: LikeStateModel): (targetId: string) => LikeTargetState {
    return (targetId: string) => state[targetId] || LikeState.DEFAULT_STATE;
  }

  @Action(UpdateSenderIdForTarget)
  fetchLikeInfosForSender({dispatch}: StateContext<LikeStateModel>, {senderId, target}: UpdateSenderIdForTarget): void {

    if (!this.likeUpdateSubscription) {
      this.likeUpdateSubscription = this.likeService.getLikeInfoUpdates$()
        .subscribe(likeInfos => {
          dispatch(new LikeInfosFetched(likeInfos));
        });
    }
    this.likeService.fetchLikeInfo(senderId, target);
  }

  @Action(LikeInfosFetched)
  initializeLikeInfos({getState, patchState}: StateContext<LikeStateModel>, {likeInfoUpdates}: LikeInfosFetched): void {
    likeInfoUpdates.forEach(({targetId, likeInfo}) => {
      patchState({[targetId]: {...this.mergeLikeStateWithLikeInfo(targetId, getState(), likeInfo), isLoading: false}});
    });
  }

  @Action(ToggleLikeSucceeded)
  updateLikeInfo({getState, patchState}: StateContext<LikeStateModel>, {likeInfoUpdate: {targetId, likeInfo}}: ToggleLikeSucceeded): void {
    const updatedLikeState = this.mergeLikeStateWithLikeInfo(targetId, getState(), likeInfo);
    updatedLikeState.isLoading = false;
    patchState({[targetId]: updatedLikeState});
  }

  @Action(SubscribeLikeInfo)
  subscribeLikeInfo({getState, patchState, dispatch}: StateContext<LikeStateModel>, {senderId, target}: SubscribeLikeInfo): void {
    if (!this.likeToggledEventSubscriptions.has(target.id)) {
      this.likeToggledEventSubscriptions.set(target.id, this.likeService.getLikeToggledEvents$(target).subscribe((toggledEvent: LikeToggledEvent) => {
        dispatch(new LikeAddedOrRemoved(toggledEvent));
      }));
    }

    const targetLikeState = this.getCurrentOrDefaultLikeStateForTarget(target.id, getState());
    patchState({
      [target.id]: {
        ...targetLikeState,
        currentSenderId: senderId,
        subscriberCount: targetLikeState.subscriberCount + 1
      }
    });
  }

  @Action(UnsubscribeLikeInfo)
  unsubscribeLikeInfo({getState, patchState, setState}: StateContext<LikeStateModel>, {target}: UnsubscribeLikeInfo): void {
    const state = getState();
    const targetLikeState = this.getCurrentOrDefaultLikeStateForTarget(target.id, state);

    const newSubscriberCount = targetLikeState.subscriberCount - 1;
    if (newSubscriberCount <= 0) {
      if (this.likeToggledEventSubscriptions.has(target.id)) {
        this.likeToggledEventSubscriptions.get(target.id).unsubscribe();
        this.likeToggledEventSubscriptions.delete(target.id);
      }
      const {[target.id]: targetToDelete, ...stateWithoutTarget} = state;
      setState(stateWithoutTarget);
    } else {
      patchState({[target.id]: {...targetLikeState, subscriberCount: newSubscriberCount}});
    }

    if (Object.keys(getState()).length === 0) {
      this.likeUpdateSubscription.unsubscribe();
      this.likeUpdateSubscription = undefined;
    }
  }

  @Action(ToggleLikeFailed)
  updateLoadingState({getState, patchState}: StateContext<LikeStateModel>, {targetId}: ToggleLikeFailed): void {
    const currentState = this.getCurrentOrDefaultLikeStateForTarget(targetId, getState());
    patchState({[targetId]: {...currentState, isLoading: false}});
  }

  @Action(ToggleLike)
  toggleLike({dispatch, getState, patchState}: StateContext<LikeStateModel>, {senderId, target}: ToggleLike): Observable<void> {
    const likeState = this.getCurrentOrDefaultLikeStateForTarget(target.id, getState());
    patchState({[target.id]: {...likeState, isLoading: true}});
    const response$ = likeState.isLikedByCurrentSender
      ? this.likeService.unlike(senderId, target.id, target.typeName)
      : this.likeService.like(senderId, target.id, target.typeName);

    return response$.pipe(
      mergeMap((likeInfo: LikeInfoUpdate) => dispatch(new ToggleLikeSucceeded(likeInfo))),
      catchError(() => dispatch(new ToggleLikeFailed(target.id)))
    );
  }

  @Action(LikeAddedOrRemoved)
  handleLikeAddedOrRemoved({getState, patchState}: StateContext<LikeStateModel>, {likeToggledEvent}: LikeAddedOrRemoved): void {
    const targetId = likeToggledEvent.target.id;
    const currentState = this.getCurrentOrDefaultLikeStateForTarget(targetId, getState());

    if (!this.isStateValid(currentState, likeToggledEvent)) {
      this.likeService.fetchLikeInfo(currentState.currentSenderId, likeToggledEvent.target);
      return;
    }
    patchState({[targetId]: this.createUpdatedLikeState(likeToggledEvent, getState())});
  }

  private mergeLikeStateWithLikeInfo(targetId: string, state: LikeStateModel, likeInfo: LikeInfo): LikeTargetState {
    const updatedLikeState = {...this.getCurrentOrDefaultLikeStateForTarget(targetId, state), ...likeInfo};
    return this.addOthersAndOthersCount(updatedLikeState);
  }

  private isStateValid(currentState: LikeTargetState, likeToggledEvent: LikeToggledEvent): boolean {
    const actingSenderIsCurrentSender = likeToggledEvent.sender.id === currentState.currentSenderId;
    const expectedLikingSenderCount = likeToggledEvent.likeAdded
      ? currentState.likingSenderCount + 1
      : currentState.likingSenderCount - 1;
    const receivedLikingSenderCountIsExpected = likeToggledEvent.likingSenderCount === expectedLikingSenderCount;
    const receivedLikingSenderCountAlreadyInState = likeToggledEvent.likingSenderCount === currentState.likingSenderCount;
    const nextLikingSenderCountIsValid = actingSenderIsCurrentSender
      ? receivedLikingSenderCountIsExpected || receivedLikingSenderCountAlreadyInState
      : receivedLikingSenderCountIsExpected;

    if (likeToggledEvent.likeAdded) {
      const currentLikingSenderCountIsValid = actingSenderIsCurrentSender || !currentState.latestLikingSenders.includes(likeToggledEvent.sender);
      return currentLikingSenderCountIsValid && nextLikingSenderCountIsValid;
    }

    return nextLikingSenderCountIsValid;
  }

  private getCurrentOrDefaultLikeStateForTarget(targetId: string, state: LikeStateModel): LikeTargetState {
    return state[targetId] || LikeState.DEFAULT_STATE;
  }

  private addOthersAndOthersCount(likeState: LikeTargetState): LikeTargetState {
    const others = likeState.latestLikingSenders.filter(sender => sender.id !== likeState.currentSenderId);
    const othersCount = likeState.isLikedByCurrentSender ? likeState.likingSenderCount - 1 : likeState.likingSenderCount;
    return {...likeState, latestOtherLikingSenders: others, otherLikingSenderCount: othersCount};
  }

  private createUpdatedLikeState(likeToggledEvent: LikeToggledEvent, state: LikeStateModel): LikeTargetState {
    let updatedLikeState = {...this.getCurrentOrDefaultLikeStateForTarget(likeToggledEvent.target.id, state)};
    if (updatedLikeState.currentSenderId === likeToggledEvent.sender.id) {
      updatedLikeState.isLikedByCurrentSender = likeToggledEvent.likeAdded;
    }

    const likingSendersWithoutActingSender = updatedLikeState.latestLikingSenders.filter(sender => sender.id !== likeToggledEvent.sender.id);
    updatedLikeState.latestLikingSenders = likeToggledEvent.likeAdded
      ? [likeToggledEvent.sender, ...likingSendersWithoutActingSender].slice(0, LikeState.MAX_STORED_SENDERS_COUNT)
      : likingSendersWithoutActingSender;

    updatedLikeState.likingSenderCount = likeToggledEvent.likingSenderCount;
    updatedLikeState = this.addOthersAndOthersCount(updatedLikeState);
    return updatedLikeState;
  }
}

import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {AuthService} from '@core/auth/auth.service';
import {Document} from '@domain/file/document';
import {FileService} from '@domain/file/file/file.service';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {SingleFileWidget} from '@widgets/single-file/single-file-widget';
import {SingleFileWidgetSettings} from '@widgets/single-file/single-file-widget-settings';
import {Observable, of, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'coyo-single-file-widget-settings',
  templateUrl: './single-file-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleFileWidgetSettingsComponent extends WidgetSettingsComponent<SingleFileWidget> implements OnInit, OnDestroy {

  sender$: Observable<Sender>;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private senderService: SenderService,
              private authService: AuthService,
              private fileService: FileService) {
    super();
  }

  ngOnInit(): void {
    this.initSender();
    this.initFileFormControl();

    this.parentForm.addControl('_hidePreview',
      new FormControl(this.widget.settings._hidePreview || false));
    this.parentForm.addControl('_hideDate',
      new FormControl(this.widget.settings._hideDate || false));
    this.parentForm.addControl('_hideSender',
      new FormControl(this.widget.settings._hideSender || false));
    this.parentForm.addControl('_hideDownload',
      new FormControl(this.widget.settings._hideDownload || false));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onBeforeSave(settings?: SingleFileWidgetSettings & {_file: Document}): Observable<SingleFileWidgetSettings> {
    const {_file, ...settingsWithoutFile} = settings;
    return of({
      ...settingsWithoutFile,
      _fileId: _file.id,
      _senderId: _file.senderId
    });
  }

  private initSender(): void {
    const senderId = this.senderService.getCurrentIdOrSlug();
    if (senderId) {
      this.sender$ = this.senderService.get(senderId, {permissions: ['createFile']})
        .pipe(tap(sender => this.widget.settings._senderId = sender.id));
    } else {
      this.widget.settings._senderId = this.authService.getCurrentUserId();
      this.sender$ = this.senderService.get(this.widget.settings._senderId, {permissions: ['createFile']});
    }
  }

  private initFileFormControl(): void {
    this.parentForm.addControl('_file', new FormControl('', [Validators.required]));
    if (this.widget.settings._fileId && this.widget.settings._senderId) {
      this.fileService.getFile(this.widget.settings._fileId, this.widget.settings._senderId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(file => this.parentForm.get('_file').setValue(file));
    }
  }
}

import {Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogSize} from '@coyo/ui';
import {DeleteConfirmationDialogData} from '@shared/dialog/delete-confirmation/delete-confirmation-dialog-data';
import {DeleteConfirmationDialogComponent} from '@shared/dialog/delete-confirmation/delete-confirmation-dialog.component';
import {Observable} from 'rxjs';

/**
 * Service for showing delete confirmation dialogs
 */
@Injectable({
  providedIn: 'root'
})
export class DeleteConfirmationService {

  constructor(private dialog: MatDialog, private ngZone: NgZone) {
  }

  /**
   * Opens a delete confirmation dialog
   *
   * @param title The title of the dialog
   * @param body The body text of the dialog
   * @param confirmText The text on the confirmation button
   * @param cancelText The text on the cancel button
   * @param translationContext The translation context that is used to translate the message keys
   * @param needsConfirmation flag for showing an checkbox that needs to be checked before deletion is possible
   *
   * @returns true if confirmation button was clicked, false otherwise
   */
  open(
    title: string,
    body: string,
    confirmText: string,
    cancelText: string,
    translationContext?: {[key: string]: any},
    needsConfirmation: boolean = false
  ): Observable<boolean> {
    return this.dialog.open<DeleteConfirmationDialogComponent, DeleteConfirmationDialogData, boolean>
    (DeleteConfirmationDialogComponent, {
      width: MatDialogSize.Small,
      data: {
        title: title,
        body: body,
        cancelText: cancelText,
        confirmText: confirmText,
        translationContext: translationContext,
        needsConfirmation
      }
    }).afterClosed();
  }

  openLegacy(
    title: string,
    body: string,
    confirmText: string,
    cancelText: string,
    translationContext?: {[key: string]: any},
    needsConfirmation: boolean = false
  ): Promise<boolean> {
    return this.ngZone.run(() =>
      this.open(title, body, confirmText, cancelText, translationContext, needsConfirmation).toPromise());
  }
}

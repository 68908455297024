(function (angular) {
  'use strict';

  AppTranslationsModalController.$inject = ["$q", "$uibModalInstance", "sender", "SenderModel", "appRegistry", "senderNavigationUpdateService"];
  angular
      .module('coyo.apps.api')
      .controller('AppTranslationsModalController', AppTranslationsModalController);

  /**
   * Controller for editing navigation groups and apps.
   *
   * @requires $q
   * @requires $uibModalInstance
   * @requires sender
   * @requires SenderModel
   * @requires appRegistry
   * @requires senderNavigationUpdateService
   */
  function AppTranslationsModalController($q, $uibModalInstance, sender, SenderModel, appRegistry,
                                          senderNavigationUpdateService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.sender = sender;
    vm.getIcon = appRegistry.getIcon;
    vm.save = save;
    vm.isSenderTranslated = false;
    vm.appNavigation = [];
    vm.onLanguageChange = onLanguageChange;
    vm.onLanguageDeleted = onLanguageDeleted;
    vm.isTranslationRequired = isTranslationRequired;
    vm.updateValidity = updateValidity;
    vm.initTranslations = initTranslations;
    vm.isDefaultLanguageValid = isDefaultLanguageValid;
    vm.senderNavigationUpdateService = senderNavigationUpdateService;
    vm.getTranslatedAppKey = getTranslatedAppKey;

    vm.saveCallbacks = {
      onBeforeSave: function () {
        return $q.resolve();
      }
    };

    function _getAppById(appId) {
      return _.find(vm.apps, function (app) {
        return app.id === appId;
      });
    }

    function save() {
      _prepareTranslationsForSave();
      _saveAppNavigationTranslations(vm.appNavigation);
    }

    function _prepareTranslationsForSave() {
      vm.appNavigation = [];
      var copyOfLanguages = angular.copy(vm.languages);
      _.forEach(copyOfLanguages[vm.defaultLanguage].translations.appNavigation, function (group) {
        vm.appNavigation.push(_createDefaultGroup(group));
      });
      _.forEach(copyOfLanguages, function (language, key) {
        _.forEach(language.translations.appNavigation, function (group, index) {
          if (key !== vm.defaultLanguage) {
            vm.appNavigation[index].translations[key] = _createGroupTranslations(group);
          }
        });
      });
      // prepare apps per group for save
      _.forEach(vm.appNavigation, function (group) {
        group.apps = _.chain(group.apps).map(removeKey).value();
        _.forEach(group.translations, function (translation) {
          translation.apps = _.chain(translation.apps).map(removeKey).filter(containsName).value();
        });
      });

      function containsName(app) {
        return _.has(app, 'name') && app.name !== '';
      }

      function removeKey(app) {
        return _.pick(app, ['id', 'name']);
      }
    }

    function _saveAppNavigationTranslations(translations) {
      vm.saveCallbacks.onBeforeSave().then(function () {
        vm.senderModel.updateNavigation(translations, true).then(function (result) {
          vm.sender.appNavigation = vm.senderNavigationUpdateService.prepareNavigationUpdateResponse(result);
          vm.senderModel.getApps().then(function (result) {
            vm.sender.apps = result;
            initTranslations();
            $uibModalInstance.close(vm.sender);
          });
        });
      });
    }

    function isTranslationRequired(language) {
      return vm.sender.isTranslationRequired(vm.languages, vm.currentLanguage, language);
    }

    function onLanguageDeleted(language) {
      vm.languages[language] = {translations: {}};
      vm.currentLanguage = vm.defaultLanguage;
    }

    function onLanguageChange(copyFromDefault) {
      if (copyFromDefault) {
        vm.languages[vm.currentLanguage] = angular.copy(vm.languages[vm.defaultLanguage]);
      } else {
        vm.languages[vm.currentLanguage] = _createEmptyTranslation();
        _.forEach(vm.sender.appNavigation, function (navGroup, index) {
          vm.languages[vm.currentLanguage].translations.appNavigation[index] = _createGroupTranslations({name: ''});
          _.forEach(navGroup.apps, function (app) {
            var tmpApp = _getAppById(app);
            vm.languages[vm.currentLanguage].translations.appNavigation[index].apps.push({
              'id': tmpApp.id,
              'key': tmpApp.key,
              'name': ''
            });
          });
        });
      }
    }

    function updateValidity(form) {
      vm.languages[vm.defaultLanguage].valid = true;
      if (vm.currentLanguage === vm.defaultLanguage) {
        vm.languages[vm.defaultLanguage].valid = isDefaultLanguageValid();
      } else {
        vm.languages[vm.defaultLanguage].valid = isDefaultLanguageValid();
        vm.languages[vm.currentLanguage].valid = true;
      }
      form.$valid = isDefaultLanguageValid();
      form.$invalid = !isDefaultLanguageValid();
    }

    function isDefaultLanguageValid() {
      var valid = true;
      _.forEach(vm.languages[vm.defaultLanguage].translations.appNavigation, function (group) {
        _.forEach(group.apps, function (app) {
          if (app.name === '' || angular.isUndefined(app.name)) {
            valid = false;
          }
        });
      });
      return valid;
    }

    function getTranslatedAppKey(app) {
      return appRegistry.get(app.key).name;
    }

    function initTranslations() {
      vm.sender.initTranslations(vm);
      vm.currentLanguage = vm.defaultLanguage;

      var availableLanguages = _.concat(_.keys(vm.sender.translations), vm.defaultLanguage);
      vm.languages = _.zipObject(availableLanguages, _.map(availableLanguages, function () {
        return {active: true, translations: {}};
      }));

      vm.languages[(vm.sender.defaultLanguage) ? vm.sender.defaultLanguage : vm.currentLanguage] =
          _createEmptyTranslation();
      _initLanguagesWithExistingValues();
      _clearAllEmptyAppNavigationTranslation();

      function _initLanguagesWithExistingValues() {
        _.forEach(vm.languages, function (language, key) {
          language.translations = {'appNavigation': []};
          if (key === 'NONE' || key === vm.defaultLanguage) {
            _initialiseDefaultLanguage(language, key);
          } else {
            _initialiseAdditionalLanguages(language, key);
          }
        });

        function _initialiseDefaultLanguage(language) {
          _.forEach(vm.sender.appNavigation, function (group, index) {
            language.translations.appNavigation.push(_createGroupTranslations({name: group.name}));
            _.forEach(group.apps, function (appId) {
              var tmpApp = _getAppById(appId);
              var appName = (_.isUndefined(tmpApp)) ? '' : tmpApp.name;

              appName && language.translations.appNavigation[index].apps.push(_appTranslations(tmpApp, appName));
            });
          });
        }

        function _initialiseAdditionalLanguages(language, key) {
          var translatedGroups = angular.fromJson(vm.sender.translations[key].appNavigation);
          _.forEach(vm.sender.appNavigation, function (group, index) {
            if (!_.isUndefined(translatedGroups)) {
              var tmpGroup = _.isUndefined(translatedGroups[index]) ? '' : translatedGroups[index];
              language.translations.appNavigation.push(_createGroupTranslations({name: tmpGroup}));
            } else {
              language.translations.appNavigation.push(_createGroupTranslations({name: ''}));
            }
            _.forEach(group.apps, function (appId) {
              var tmpApp = _getAppById(appId);
              var appName = _.get(tmpApp, 'translations[' + key + '].name', '');
              tmpApp && language.translations.appNavigation[index].apps.push(_appTranslations(tmpApp, appName));
            });
          });
        }
      }

      function _clearAllEmptyAppNavigationTranslation() {
        _.forEach(vm.languages, function (language, key) {
          if (!_appNavigationTranslationExist(language) && key !== vm.defaultLanguage) {
            vm.languages[key] = {translations: {}, active: true};
          }
        });
      }
    }

    function _appNavigationTranslationExist(language) {
      var isNotEmpty = false;
      _.forEach(language.translations.appNavigation, function (group) {
        if (!isNotEmpty) {
          isNotEmpty = !_.isEmpty(group.name);
        }
      });
      if (!isNotEmpty) {
        _.forEach(language.translations.appNavigation, function (group) {
          _.forEach(group.apps, function (app) {
            if (!isNotEmpty) {
              isNotEmpty = !_.isEmpty(app.name);
            }
          });
        });
      }
      return isNotEmpty;
    }

    function _createDefaultGroup(group) {
      return {
        'name': group.name,
        'defaultLanguage': vm.defaultLanguage === 'NONE' || '' ? null : vm.defaultLanguage,
        'apps': group.apps,
        'translations': {}
      };
    }

    function _createGroupTranslations(group) {
      return {
        'name': group.name,
        'apps': _.isUndefined(group.apps) ? [] : group.apps
      };
    }

    function _createEmptyTranslation() {
      return {
        'active': true,
        'translations': {
          'appNavigation': []
        }
      };
    }

    function _appTranslations(app, name) {
      return {
        'id': app.id,
        'key': app.key,
        'name': name
      };
    }

    function onInit() {
      vm.senderModel = new SenderModel({id: vm.sender.id});
      vm.senderModel.get().then(function (result) {
        vm.sender = result;
        vm.senderModel.getApps().then(function (result) {
          vm.apps = result;
          vm.initTranslations();
        });
      });
    }
  }
})(angular);

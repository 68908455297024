import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogSize} from '@coyo/ui';
import {ExternalLinkPreviewConfirmationDialogData} from '@shared/preview/external-link-preview-confirmation-dialog/external-link-preview-confirmation-dialog-data';
import {ExternalLinkPreviewConfirmationDialogComponent} from '@shared/preview/external-link-preview-confirmation-dialog/external-link-preview-confirmation-dialog.component';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalLinkPreviewConfirmationDialogService {

  constructor(private dialog: MatDialog) {
  }

  open(title: string, url: string): Observable<boolean> {
    return this.dialog.open<ExternalLinkPreviewConfirmationDialogComponent, ExternalLinkPreviewConfirmationDialogData, boolean>
    (ExternalLinkPreviewConfirmationDialogComponent, {
      width: MatDialogSize.Small,
      data: {
        title,
        url
      }
    }).afterClosed();
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {TargetService} from '@domain/sender/target/target.service';
import {SubscriptionService} from '@domain/subscription/subscription.service';
import {User} from '@domain/user/user';
import {UserService} from '@domain/user/user.service';
import {SublineService} from '@shared/sender-ui/subline/subline.service';
import * as _ from 'lodash';
import {EMPTY, forkJoin, Observable, of, Subject} from 'rxjs';
import {bufferTime, filter, map, mergeMap, share, switchMap, take} from 'rxjs/operators';
import {MentionDetails} from './mention-details';
import {MentionItem} from './mention-item';

interface BulkedResponse {
  slugs: string[];
  details: MentionDetails[];
}

/**
 * A service to retrieve mentioning information.
 */
@Injectable({
  providedIn: 'root'
})
export class MentionService {

  /**
   * The number of milliseconds to throttle GET requests for details of this service.
   */
  static readonly THROTTLE: number = 50;

  /**
   * The maximum size of the mention details cache.
   */
  static readonly MAX_CACHE_SIZE: number = 50;

  /**
   * The maximum age of the cached mention details in ms.
   */
  static readonly MAX_CACHE_AGE: number = 5 * 60 * 1000;

  private cache: MentionDetails[] = [];
  private detailsSubject: Subject<string[]> = new Subject();
  private readonly getBulkMentionRequests: Observable<BulkedResponse> =
    this.detailsSubject.pipe(
      bufferTime(MentionService.THROTTLE),
      filter(slugs => slugs && slugs.length > 0),
      map(slugs => _.uniq(_.flatten(slugs))),
      mergeMap(this.request.bind(this)),
      map(this.publish.bind(this)),
      share<BulkedResponse>()
    );

  constructor(
    private http: HttpClient,
    private senderService: SenderService,
    private targetService: TargetService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private sublineService: SublineService) {
  }

  /**
   * Retrieves information about mentionable items.
   *
   * @param term a search term
   * @param pageable the current pagination data
   * @return a page of mentionable items
   */
  getItems(term: string, pageable: Pageable): Observable<Page<MentionItem>> {
    const users = !term
      ? this.subscriptionService.getSubscribedUsers(pageable)
      : this.userService.getPage(pageable, {
        params: {
          term,
          searchFields: 'displayName',
        }
      });

    return users.pipe(switchMap(page => !page.content.length
      ? of(this.withItems(page, []))
      : forkJoin(page.content.map(user => this.sublineService.getSublineForUser(user)
        .pipe(map(subline => ({user, subline})))
      )).pipe(map(items => this.withItems(page, items)))));
  }

  /**
   * Retrieves information about mentioned items.
   *
   * @param slugs an array of mentioned slugs
   * @return the resolved mentions
   */
  getDetails(...slugs: string[]): Observable<{ [slug: string]: MentionDetails }> {
    if (!slugs.length) {
      return EMPTY;
    }
    // emitted via timeout so that the return value is delivered before the observable is executed
    setTimeout(() => this.detailsSubject.next(slugs));
    return this.getBulkMentionRequests
      .pipe(filter(response => _.difference(slugs, response.slugs).length === 0))
      .pipe(map(response => _(response.details).keyBy('slug').pick(slugs).value()))
      .pipe(take(1));
  }

  private withItems<T>(page: Page<User>, items: T[]): Page<T> {
    const result = _.clone(page) as unknown as Page<T>;
    result.content = items;
    return result;
  }

  private request(slugs: string[]): Observable<BulkedResponse> {
    const cached = _.filter(this.cache, detail => _.includes(slugs, detail.slug));
    const request = _.without(slugs, ..._.map(cached, 'slug'));

    return !request.length
      ? of({
        slugs: slugs, details: cached
      }) // pure cache hits
      : this.http.get<{ [slug: string]: Sender }>(this.senderService.getUrl(), {
        params: {slug: request}
      }).pipe(switchMap(senderMap => _.isEmpty(senderMap) ? of([]) : forkJoin(
        _.map(senderMap, sender => this.targetService.canLinkTo(sender.target)
          .pipe(map(canLinkTo => ({
            slug: sender.slug,
            name: sender.displayName,
            link: canLinkTo ? this.targetService.getLinkTo(sender.target) : null,
            cached: null
          })))
        )
      ))).pipe(map(details => ({slugs: slugs, details: _.concat(details, cached)})));
  }

  private publish(response: BulkedResponse): BulkedResponse {
    const now = new Date().getTime();
    const newDetails = _(response.details)
      .filter(detail => !detail.cached)
      .map(detail => _.set(detail, 'cached', now))
      .value(); // details that are not cached
    const newSlugs = _.map(newDetails, 'slug');
    const allDetails = _(this.cache)
      .reject(detail => _.includes(newSlugs, detail.slug))
      .concat(newDetails)
      .value(); // all details (cached & not cached)
    this.cache = _(allDetails)
      .reject(detail => now - detail.cached > MentionService.MAX_CACHE_AGE)
      .slice(0, MentionService.MAX_CACHE_SIZE)
      .value(); // updated cache (age & size)
    return {slugs: response.slugs, details: allDetails};
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {SocketService} from '@core/socket/socket.service';
import {DomainService} from '@domain/domain/domain.service';
import {Like} from '@domain/like/like';
import {LikeInfoUpdate} from '@domain/like/like-info-update';
import {LikeToggledEvent} from '@domain/like/like-toggled-event';
import {Likeable} from '@domain/like/likeable';
import {Sender} from '@domain/sender/sender';
import * as _ from 'lodash';
import {forkJoin, Observable, Subject} from 'rxjs';
import {bufferTime, filter, flatMap, map} from 'rxjs/operators';

interface LikeInfoRequestData {
  senderId: string;
  target: Likeable;
}

interface LikeToggledEventDto {
  sender: Sender;
  target: Likeable;
  count: number;
  created: boolean;
}

/**
 * Response format for like fetch of single target
 */
interface LikeInfoResponse {
  count: number;
  latest: Sender[];
  likedBySender: boolean;
}

/**
 * Response format for like fetch of multiple targets
 */
interface LikeInfosResponse {
  [targetId: string]: LikeInfoResponse;
}

/**
 * Service to retrieve and manage likes.
 */
@Injectable({
  providedIn: 'root'
})
export class LikeService extends DomainService<Like, Like> {

  /**
   * The number of milliseconds to throttle GET requests of this service.
   */
  static readonly THROTTLE_MILLIS: number = 50;

  /**
   * The number of milliseconds to buffer incoming like info update websocket events before processing them.
   */
  static readonly UPDATE_BUFFER_MILLIS: number = 1000;

  private likeInfoFetchQueue$: Subject<LikeInfoRequestData>;

  constructor(http: HttpClient, urlService: UrlService, private socketService: SocketService) {
    super(http, urlService);
    this.likeInfoFetchQueue$ = new Subject<LikeInfoRequestData>();
  }

  /**
   * Performs a bulk request for the requested like targets and converts the responses.
   * The returned Observable has to be subscribed in order to actually fetch the like target.
   *
   * @returns An Observable emitting the fetched like info updates
   */
  getLikeInfoUpdates$(): Observable<LikeInfoUpdate[]> {
   return this.likeInfoFetchQueue$.pipe(
     bufferTime(LikeService.THROTTLE_MILLIS),
     filter(likeInfoRequests => likeInfoRequests.length > 0),
     map(likeInfoUpdates => _.uniqBy(likeInfoUpdates, 'target.id')),
     flatMap((likeInfoRequests: LikeInfoRequestData[]) => this.requestLikeInfos(likeInfoRequests))
   );
  }

  /**
   * Opens a websocket connection for the requested like target
   *
   * @param target - The target to open the web socket connection to
   * @returns An Observable emitting like toggled events from the web socket for the requested target
   */
  getLikeToggledEvents$(target: Likeable): Observable<LikeToggledEvent> {
    return this.setupWebsocketForTargetLikeUpdates(target).pipe(
      flatMap((likeToggledEventContent: LikeToggledEventDto[]) => this.toLikeToggledEvents(likeToggledEventContent))
    );
  }

  /**
   * Likes the given target as the given sender.
   *
   * @param senderId - The ID of the acting sender
   * @param targetId - The ID of the target
   * @param targetType - The type of the target
   * @returns An `Observable` holding the the latest like information
   */
  like(senderId: string, targetId: string, targetType: string): Observable<LikeInfoUpdate> {
    return this.http.post<LikeInfoResponse>(`/web/like-targets/${targetType}/${targetId}/likes/${senderId}`, null)
      .pipe(map((response: LikeInfoResponse) => this.toLikeInfoUpdate(senderId, targetId, response)));
  }

  /**
   * Unlikes the given target as the given sender.
   *
   * @param senderId - The ID of the acting sender
   * @param targetId - The ID of the target
   * @param targetType - The type of the target
   * @returns An `Observable` holding the the latest like information
   */
  unlike(senderId: string, targetId: string, targetType: string): Observable<LikeInfoUpdate> {
    return this.http.delete<LikeInfoResponse>(`/web/like-targets/${targetType}/${targetId}/likes/${senderId}`)
      .pipe(map((response: LikeInfoResponse) => this.toLikeInfoUpdate(senderId, targetId, response)));
  }

  /**
   * Add the given target to the like info fetch queue.
   *
   * @param senderId - The ID of the acting sender
   * @param target - The target to add to the like info fetch queue
   */
  fetchLikeInfo(senderId: string, target: Likeable): void {
    this.addToLikeInfoFetchQueue(senderId, target);
  }

  protected getBaseUrl(): string {
    return '/web/like-targets/{targetType}/{targetId}/likes';
  }

  private toLikeToggledEvents(updateEvents: LikeToggledEventDto[]): LikeToggledEvent[] {
    const eventsBySender = _.groupBy<LikeToggledEventDto>(updateEvents, 'sender.id');
    return Object.keys(eventsBySender).map(senderIdKey => {
      const lastIndex = eventsBySender[senderIdKey].length - 1;
      const lastEvent = eventsBySender[senderIdKey][lastIndex];
      return {target: lastEvent.target, likeAdded: lastEvent.created, likingSenderCount: lastEvent.count, sender: lastEvent.sender};
    });
  }

  private addToLikeInfoFetchQueue(senderId: string, likeTarget: Likeable): void {
    this.likeInfoFetchQueue$.next({senderId, target: likeTarget});
  }

  private requestLikeInfos(likeInfoRequests: LikeInfoRequestData[]): Observable<LikeInfoUpdate[]> {
    const likeInfoRequestByTargetType = _.groupBy<LikeInfoRequestData>(likeInfoRequests,
      likeInfoRequest => likeInfoRequest.target.typeName);
    const likeInfoUpdates$ = Object.entries(likeInfoRequestByTargetType).map(
      ([type, likeInfoRequestsOfTargetType]) =>
        this.requestLikeInfosForLikeTargetsOfCommonType(type, likeInfoRequestsOfTargetType)
    );
    return forkJoin(likeInfoUpdates$).pipe(map(_.flatten));
  }

  private requestLikeInfosForLikeTargetsOfCommonType(targetType: string,
                                                     likeInfoRequests: LikeInfoRequestData[]): Observable<LikeInfoUpdate[]> {
    const targetIds = likeInfoRequests.map(likeInfoRequest => likeInfoRequest.target.id);
    const senderId = likeInfoRequests[0].senderId;
    return this.http.get<LikeInfosResponse>(`/web/like-targets/${targetType}`, {
      headers: {
        etagBulkId: 'ids'
      },
      params: {
        senderId,
        ids: targetIds
      }
    }).pipe(
      map(response => this.toLikeInfoUpdates(response, senderId))
    );
  }

  private toLikeInfoUpdates(responses: LikeInfosResponse, senderId: string): LikeInfoUpdate[] {
    return Object.entries(responses).map(([targetId, response]) => (
      this.toLikeInfoUpdate(senderId, targetId, response)));
  }

  private toLikeInfoUpdate(senderId: string, targetId: string, response: LikeInfoResponse): LikeInfoUpdate {
    return {
      targetId,
      likeInfo: {
        isLikedByCurrentSender: response.likedBySender,
        latestLikingSenders: response.latest,
        likingSenderCount: response.count,
        currentSenderId: senderId,
      }
    };
  }

  private setupWebsocketForTargetLikeUpdates(target: Likeable): Observable<LikeToggledEventDto[]> {
    return this.socketService.listenTo$('/topic/like.' + target.typeName, null, target.id, target.subscriptionInfo.token)
      .pipe(
        map(event => event.content as LikeToggledEventDto),
        bufferTime(LikeService.UPDATE_BUFFER_MILLIS),
        filter((updateEvents: LikeToggledEventDto[]) => updateEvents.length > 0)
      );
  }
}

import {NgZone} from '@angular/core';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {FilePickerSelectionMode} from '@app/file-picker/file-picker-selection-mode';
import {FilePickerService} from '@app/file-picker/file-picker.service';
import {File} from '@domain/file/file';
import {FileService} from '@domain/file/file/file.service';
import {FilePreviewService} from '@domain/preview/file-preview/file-preview.service';
import {FileDetailsModalService} from '@shared/files/file-details-modal/file-details-modal.service';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';
import {HookResult, StateService, TargetState, Transition} from '@uirouter/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

transitionHook.$inject = ['$transition$'];

/**
 * Handler for deep file links. Will start a transition to the main page if a direct link is used and the
 * modal is closed.
 *
 * @param $transition$ The transition data
 * @returns a HookResult either of boolean or a new transition target
 */
export function transitionHook($transition$: Transition): HookResult {
  return new Promise(resolve => {
    const fileService: FileService = $transition$.injector().get(FileService);

    const fileId = $transition$.params().fileId;
    const senderId = $transition$.params().senderId;
    fileService.getFile(fileId, senderId, ['manage'])
      .pipe(switchMap(file => handleFile(file, $transition$)))
      .subscribe(modalResult => handleModalResult(modalResult, resolve, $transition$)
      );
  });
}

function handleModalResult(
  modalResult: unknown,
  resolve: (value: (boolean | TargetState)) => void,
  $transition$: Transition
): void {
  const stateService: StateService = $transition$.injector().get(StateService);
  const transitionSource = $transition$.from();
  const hasPreviousState = !!(transitionSource?.name?.length);
  const modalWasNotClosed = modalResult === 'state-transition-started' || modalResult === undefined;
  const fromLogin = transitionSource?.name?.startsWith('front.');
  if ((hasPreviousState || modalWasNotClosed) && !fromLogin) {
    resolve(false);
  } else {
    resolve(stateService.target('main.default'));
  }
}

function handleFile(file: File, $transition$: Transition): Observable<unknown> {
  return file.folder ? openFolderInLibrary(file, $transition$) : openFileDetails(file, $transition$);
}

function openFolderInLibrary(file: File, $transition$: Transition): Observable<unknown> {
  const filePickerService: FilePickerService = $transition$.injector().get(FilePickerService);
  const ngZone: NgZone = $transition$.injector().get(NgZone);
  return ngZone.run(() => filePickerService.openFilePicker({
      rootFolder: new FileLibraryFilePickerItem(file),
      selectionMode: FilePickerSelectionMode.DOWNLOAD
    })
  );
}

function openFileDetails(file: File, $transition$: Transition): Observable<unknown> {
  const fileDetailsModalService: FileDetailsModalService = $transition$.injector().get(FileDetailsModalService);
  const filePreviewService: FilePreviewService = $transition$.injector().get(FilePreviewService);
  const ngZone: NgZone = $transition$.injector().get(NgZone);
  const previews = filePreviewService.toFilePreviews([file]);
  return ngZone.run(() =>
    fileDetailsModalService.open(previews, 0, false)
  );
}

/**
 * State for showing the file details for deep file links
 */
export const filePreviewState: NgHybridStateDeclaration = {
  name: 'main.fileLibrary',
  url: '/files/:senderId/:fileId/:fileName',
  onEnter: transitionHook
};

import {Inject, Injectable, NgZone} from '@angular/core';
import {User} from '@domain/user/user';
import {Ng1AuthService} from '@root/typings';
import {attachToZone} from '@upgrade/attach-to-zone';
import {NG1_AUTH_SERVICE} from '@upgrade/upgrade.module';
import {from, merge, Observable} from 'rxjs';
import {filter, first, map, mergeMap, shareReplay, startWith} from 'rxjs/operators';

/**
 * Service for authentication and retrieving authentication information.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(@Inject(NgZone) private ngZone: NgZone,
              @Inject(NG1_AUTH_SERVICE) private authService: Ng1AuthService) {
  }

  private readonly _isAuthenticated$: Observable<boolean> = merge(
      this.authService.userLoggedIn$.pipe(map(() => true)),
      this.authService.userLoggedOut$.pipe(map(() => false))
    ).pipe(startWith(this.isAuthenticated()), shareReplay({bufferSize: 1, refCount: true}));

  /**
   * Returns the current user and caches the current user during application lifetime.
   *
   * @param forceRefresh force a refresh of the user cache
   * @returns an `Observable` containing the current user
   */
  getUser(forceRefresh: boolean = false): Observable<User> {
    const observable = from(this.authService.getUser(forceRefresh));
    return attachToZone(this.ngZone, observable);
  }

  /**
   * Returns the current user.
   *
   * The `Observable` provides a continues stream of the current user and will
   * not complete after the initial load. Subsequent changes to the user will
   * be delivered.
   *
   * @returns an `Observable` holding the current user.
   */
  getUser$(): Observable<User> {
    return merge(
      this.getUser(),
      this.authService.userUpdated$
        .pipe(map(([_$event, newUser, _oldUser]) => newUser))
    );
  }

  /**
   * Returns the user's authentication state.
   *
   * @returns the authentication state
   */
  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  /**
   * Returns an observable as a stream that triggers true or false whenever
   * a user logs in or out.
   *
   * @returns an `Observable` that triggers true when an authentication happened,
   * otherwise false.
   */
  isAuthenticated$(): Observable<boolean> {
    // cache this in a class property so we can make use of the replay operator for late subscriptions
    return this._isAuthenticated$;
  }

  /**
   * Returns an observable that emits a single user after an authentication success event has been emitted.
   * This should be used to setup global bulk observables in services instead of the normal getUser method.
   *
   * @returns an `Observable` containing the current or soon to be user
   */
  getUserAfterLogin(): Observable<any> {
    return this.isAuthenticated$()
      .pipe(
        filter(value => value),
        mergeMap(() => this.getUser()),
        first()
      );
  }

  /**
   * Returns if the user can use hashtags.
   *
   * @returns true if the user can use hashtags, false else
   */
  canUseHashtags(): boolean {
    return this.authService.canUseHashtags();
  }

  /**
   * Gets the current user id or null if no user is authenticated
   *
   * @returns the id of the current authenticated user or null if no user is authenticated
   */
  getCurrentUserId(): string | null {
    return this.authService.getCurrentUserId();
  }

  /**
   * Clears the user session and logs out the user
   */
  logout(): void {
    this.authService.logout();
  }

  /**
   * Checks if the user is logged in via sso
   *
   * @returns true if user is logged in via sso
   */
  isSsoLogin(): boolean {
    return this.authService.isSsoLogin();
  }
}

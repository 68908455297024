import {Injectable} from '@angular/core';
import {TargetService} from '@domain/sender/target/target.service';
import {Action, State, StateContext} from '@ngxs/store';
import {WidgetVisibilityService} from '@widgets/api/widget-visibility/widget-visibility.service';
import {LatestBlogArticle} from '@widgets/latest-blog-articles/latest-blog-article';
import {Load, Reset} from '@widgets/latest-blog-articles/latest-blog-articles-widget/latest-blog-articles-widget.actions';
import {LatestBlogArticlesService} from '@widgets/latest-blog-articles/latest-blog-articles.service';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * Stores the links for the sender and article mapped to each article id
 */
export interface LatestBlogArticleLinkCollection {
  senders: {[key: string]: string};
  articles: {[key: string]: string};
  apps: {[key: string]: string};
}

/**
 * The model representing a single latest blog article widget state
 */
export interface LatestBlogArticlesWidgetStateModel {
  loading: boolean;
  articles: LatestBlogArticle[];
  hasArticles: boolean;
  links: LatestBlogArticleLinkCollection;
}

/**
 * The global model for the latest blog article state
 * Maps widget id to widget state
 */
export interface LatestBlogWidgetArticlesStatesModel {
  [key: string]: LatestBlogArticlesWidgetStateModel;
}

/**
 * The actual widget state logic for the latest blog article widget
 */
@Injectable({providedIn: 'root'})
@State<LatestBlogWidgetArticlesStatesModel>({
  name: 'latestBlogArticles',
  defaults: {}
})
export class LatestBlogArticlesWidgetState {
  constructor(private latestBlogArticlesService: LatestBlogArticlesService,
              private targetService: TargetService,
              private widgetVisibilityService: WidgetVisibilityService) {
  }

  @Action(Load)
  init(ctx: StateContext<LatestBlogWidgetArticlesStatesModel>, action: Load): Observable<any> {
    this.setLoading(ctx, action.id);
    return this.latestBlogArticlesService.getLatestBlogArticles(action.settings)
      .pipe(
        tap((result: LatestBlogArticle[]) => {
          this.widgetVisibilityService.setHidden(action.id, !result || result.length === 0);
          ctx.patchState({
            [action.id]: {
              articles: result,
              hasArticles: !!result && result.length > 0,
              loading: false,
              links: this.getLinks(result)
            }
          });
        }));
  }

  @Action(Reset)
  destroy(ctx: StateContext<LatestBlogWidgetArticlesStatesModel>, action: Reset): void {
    ctx.setState({..._.omit(ctx.getState(), action.id)});
  }

  private setLoading(ctx: StateContext<LatestBlogWidgetArticlesStatesModel>, id: string): void {
    ctx.patchState({
      [id]: {
        articles: [],
        hasArticles: false,
        loading: true,
        links: {
          senders: {},
          articles: {},
          apps: {}
        }
      }
    });
  }

  private getLinks(articles: LatestBlogArticle[]): LatestBlogArticleLinkCollection {
    return {
      senders: articles.reduce((prev: any, curr: LatestBlogArticle) => {
        prev[curr.senderTarget.params.id] = this.targetService.getLinkTo(curr.senderTarget);
        return prev;
      }, {} as any),
      articles: articles.reduce((prev: any, curr: LatestBlogArticle) => {
        prev[curr.articleTarget.params.id] = this.targetService.getLinkTo(curr.articleTarget);
        return prev;
      }, {} as any),
      apps: articles.reduce((prev: any, curr: LatestBlogArticle) => {
        prev[curr.appTarget.params.id] = this.targetService.getLinkTo(curr.appTarget);
        return prev;
      }, {} as any)
    };
  }
}

(function (angular) {
  'use strict';

  TimelineItemModel.$inject = ["restResourceFactory", "coyoEndpoints", "UserModel", "TimelineShareModel", "$q", "$timeout", "timelineItemPermissions"];
  angular
      .module('coyo.domain')
      .factory('TimelineItemModel', TimelineItemModel)
      .constant('timelineItemPermissions',
          ['edit', 'delete', 'accessoriginalauthor', 'like', 'comment', 'share', 'sticky', 'actAsSender']);

  /**
   * @ngdoc service
   * @name coyo.domain.TimelineItemModel
   *
   * @description
   * Provides the timeline item model.
   *
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   * @requires coyo.domain.UserModel
   * @requires coyo.domain.TimelineShareModel
   * @requires $q
   * @requires $timeout
   * @requires timelineItemPermissions
   *
   */
  function TimelineItemModel(restResourceFactory, coyoEndpoints, UserModel, TimelineShareModel, $q, $timeout,
                             timelineItemPermissions) {
    var TimelineItemModel = restResourceFactory({
      url: coyoEndpoints.timeline.items,
      httpConfig: {
        etagCache: {
          bulkParameter: 'ids',
          addCacheFlagToPayload: true
        }
      }
    });

    var pendingShareCountRequests = {};

    // instance methods
    angular.extend(TimelineItemModel.prototype, {

      /**
       * @ngdoc method
       * @name coyo.domain.TimelineItemModel#markAsRead
       * @methodOf coyo.domain.TimelineItemModel
       *
       * @description
       * Mark the current timeline item as read for the current user.
       *
       * @returns {object} promise that resolved to the updated timeline item
       */
      markAsRead: function () {
        var item = this;
        var params = TimelineItemModel.applyPermissions(timelineItemPermissions);
        return item.$post(item.$url('read'), this, params);
      },

      /**
       * @ngdoc method
       * @name coyo.domain.TimelineItemModel#makeUnsticky
       * @methodOf coyo.domain.TimelineItemModel
       *
       * @description
       * Unset the sticky expiry date.
       *
       * @returns {object} promise that resolved to the updated timeline item
       */
      makeUnsticky: function () {
        var item = this;
        var params = TimelineItemModel.applyPermissions(timelineItemPermissions);
        return this.$post(item.$url('unsticky'), this, params);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.TimelineItemModel#getShares
       * @methodOf coyo.domain.TimelineItemModel
       *
       * @description
       * Gets the shares of a post.
       *
       * @returns {promise} A $http promise
       */
      getShares: function () {
        return this.getWithPermissions('/shares', '', ['delete', 'accessoriginalauthor', 'sticky'])
            .then(function (shares) {
              return shares.map(function (share) {
                return new TimelineShareModel(share);
              });
            });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.TimelineItemModel#getSharesCount
       * @methodOf coyo.domain.TimelineItemModel
       *
       * @description
       * Count the number of shares for the timeline item (bulks multiple requests per digest cycle).
       *
       * @returns {promise} A $http promise resolving to an int
       */
      getSharesCount: function () {
        var deferred = $q.defer();
        pendingShareCountRequests[this.id] = deferred;
        $timeout(function () {
          var requests = angular.copy(pendingShareCountRequests);
          var requestedIds = _.keys(requests);
          if (requestedIds.length) {
            pendingShareCountRequests = {};
            TimelineItemModel.$get(TimelineItemModel.$url('shares/count'), {ids: requestedIds})
                .then(function (response) {
                  requestedIds.forEach(function (id) {
                    requests[id].resolve(response[id]);
                  });
                });
          }
        });
        return deferred.promise;
      },

      /**
       * @ngdoc function
       * @name coyo.domain.TimelineItemModel#getOriginalAuthor
       * @methodOf coyo.domain.TimelineItemModel
       *
       * @description
       * Gets the original author of a post.
       *
       * @returns {promise} A $http promise
       */
      getOriginalAuthor: function () {
        var item = this;
        return UserModel.get(item.originalAuthorId);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.TimelineItemModel#getRelevantShare
       * @methodOf coyo.domain.TimelineItemModel
       * @description
       * Gets the relevanth share of the given item id
       *
       * @param {string} itemId the item id
       * @param {string} timelineType the timeline type
       * @param {string} senderId the sender id
       * @returns {promise} A $http promise
       */
      getRelevantShare: function (itemId, timelineType, senderId) {
        return this.getWithPermissions('/relevant-share', {senderId: senderId, timelineType: timelineType},
            []).then(function (share) {
          return new TimelineShareModel(share);
        });
      }

    });

    return TimelineItemModel;
  }

})(angular);

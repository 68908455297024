(function (angular) {
  'use strict';

  MainSidebarController.$inject = ["$rootScope", "$scope", "$localStorage", "$state", "$injector", "$q", "adminStates", "coyoConfig", "authService", "sidebarService", "targetService", "termsService", "userGuideService"];
  angular
      .module('commons.layout')
      .directive('oyocMainSidebar', mainSidebar)
      .controller('MainSidebarController', MainSidebarController);

  /**
   * @ngdoc directive
   * @name commons.layout.oyocMainSidebar:oyocMainSidebar
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Displays the sidebar navigation menu (only on mobile devices). The sidebar is registered to the sidebarService by
   * registering with its API.
   *
   * @requires $rootScope
   * @requires $scope
   * @requires $localStorage
   * @requires $state
   * @requires $injector
   * @requires $q
   * @requires coyo.admin.adminStates
   * @requires commons.config.coyoConfig
   * @requires commons.auth.authService
   * @requires commons.ui.sidebarService
   * @requires commons.target.targetService
   * @requires commons.terms.termsService
   * @requires commons.ui.userGuideService
   */
  function mainSidebar() {
    return {
      restrict: 'E',
      templateUrl: 'app/commons/layout/components/main-sidebar/main-sidebar.html',
      replace: true,
      scope: {},
      bindToController: {
        landingPages: '<'
      },
      controller: 'MainSidebarController',
      controllerAs: '$ctrl'
    };
  }

  function MainSidebarController($rootScope, $scope, $localStorage, $state, $injector, $q, adminStates, coyoConfig,
                                 authService, sidebarService, targetService, termsService, userGuideService) {
    var vm = this;
    vm.$onInit = onInit;
    vm.navState = {};
    vm.showSidebar = false;
    vm.fileLibraryActivated = false;
    vm.allAdminPermissions = _.map(adminStates, 'globalPermission').join(',');
    vm.mySubscriptions = {};
    vm.links = {
      page: {},
      workspace: {}
    };

    vm.open = open;
    vm.close = close;
    vm.help = help;
    vm.about = about;
    vm.logout = authService.logout;
    vm.showTerms = termsService.showModal;
    vm.loadMoreSubscriptions = loadMoreSubscriptions;
    vm.getIcon = getIcon;
    vm.navCollapse = navCollapse;
    vm.hasConfiguredUrl = hasConfiguredUrl;
    vm.shouldUseConfiguredUrlAndNewTab = shouldUseConfiguredUrlAndNewTab;
    vm.shouldUseConfiguredUrlAndSameTab = shouldUseConfiguredUrlAndSameTab;
    vm.shouldUseUiSrefAndSameTab = shouldUseUiSrefAndSameTab;

    // ----------------------------------------------------------------

    function open() {
      vm.showSidebar = true;
      $rootScope.showBackdrop = true;
    }

    function close() {
      vm.showSidebar = false;
      $rootScope.showBackdrop = false;
    }

    function help() {
      close();

      if ($state.current && $state.current.data && $state.current.data.guide) {
        userGuideService.open($state.current.data.guide);
      } else {
        userGuideService.notFound();
      }
    }

    function about() {
      close();
      $injector.get('ngxAboutCoyoModalService').openAboutCoyoModal();
    }

    function navCollapse(section) {
      vm.navState[section] = !vm.navState[section];
      $localStorage.navState[section] = !$localStorage.navState[section];
    }

    // -------------------------
    // My Subscriptions
    // -------------------------

    var pageSizeInit = 5;
    var pageSizeSucc = 20;

    initSubscriptions('page');
    initSubscriptions('workspace');
    function initSubscriptions(type) {
      vm.mySubscriptions[type] = {
        icon: type,
        items: [],
        favorite: [],
        autoSubscribe: [],
        loading: false,
        last: false
      };
    }

    function loadMoreSubscriptions(type) {
      var data = vm.mySubscriptions[type];
      if (data.loading || data.last) {
        return;
      }
      data.loading = true;

      var subscriptionService = $injector.get('ngxSubscriptionService');
      var paginationService = $injector.get('ngxPaginationService');
      var search = paginationService.search('', {type: [type]});
      var pageSize = data.items.length ? pageSizeSucc : pageSizeInit;
      var pageable = paginationService.pageable(0, pageSize, data.items.length);
      var deferred = $q.defer();
      subscriptionService.getSubscribedSenders(search, pageable)
          .toPromise()
          .then(deferred.resolve)
          .catch(deferred.reject);

      deferred.promise.then(function (richPage) {
        data.items.push.apply(data.items, richPage.page.content);
        data.autoSubscribe.push.apply(data.autoSubscribe, richPage.data.autoSubscribe);
        data.favorite.push.apply(data.favorite, richPage.data.favorite);
        data.last = data.items.length >= richPage.page.totalElements;
      }).finally(function () {
        data.loading = false;
        data.items.forEach(function (sender) {
          vm.links[type][sender.id] = targetService.getLink(sender.target);
        });
      });
    }

    function getIcon(type, sender) {
      var data = vm.mySubscriptions[type];
      return data.favorite.indexOf(sender.id) !== -1
        ? 'star'
        : data.autoSubscribe.indexOf(sender.id) !== -1
          ? 'pin'
          : data.icon;
    }

    function hasConfiguredUrl(landingPage) {
      return !!landingPage.configuredUrl;
    }

    function shouldUseConfiguredUrlAndNewTab(landingPage) {
      return hasConfiguredUrl(landingPage) && landingPage.newTab;
    }

    function shouldUseConfiguredUrlAndSameTab(landingPage) {
      return hasConfiguredUrl(landingPage) && !landingPage.newTab && !landingPage.uiSref;
    }

    function shouldUseUiSrefAndSameTab(landingPage) {
      return hasConfiguredUrl(landingPage) && !landingPage.newTab && landingPage.uiSref;
    }

    // ----------------------------------------------------------------

    function _checkTermsActive() {
      termsService.termsActive().then(function (active) {
        vm.termsActive = active;
      });
    }

    function onInit() {
      // register sidebar
      sidebarService.register({
        name: 'menu',
        open: open,
        close: close,
        isOpen: function () {
          return vm.showSidebar;
        }
      });

      // init navigation states
      if (angular.isUndefined($localStorage.navState)) {
        $localStorage.navState = {main: true};
      }
      vm.navState = angular.copy($localStorage.navState);

      // init user
      authService.getUser().then(function (user) {
        vm.user = user;
        _checkTermsActive();
        loadMoreSubscriptions('page');
        loadMoreSubscriptions('workspace');
      });

      authService.onGlobalPermissions('ACCESS_FILES', function (hasPermission) {
        vm.fileLibraryActivated = hasPermission;
      });

      var unsubscribePagesSubscribed = $rootScope.$on('page:subscribed', function () {
        initSubscriptions('page');
        loadMoreSubscriptions('page');
      });
      var unsubscribeWorkspacesJoined = $rootScope.$on('workspace:joined', function () {
        initSubscriptions('workspace');
        loadMoreSubscriptions('workspace');
      });
      var unsubscribePagesUnSubscribed = $rootScope.$on('page:unSubscribed', function () {
        initSubscriptions('page');
        loadMoreSubscriptions('page');
      });
      var unsubscribeWorkspacesLeft = $rootScope.$on('workspace:left', function () {
        initSubscriptions('workspace');
        loadMoreSubscriptions('workspace');
      });

      $scope.$on('$destroy', function () {
        unsubscribePagesSubscribed();
        unsubscribePagesUnSubscribed();
        unsubscribeWorkspacesJoined();
        unsubscribeWorkspacesLeft();
      });
    }
  }

})(angular);

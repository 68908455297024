import {Component} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldType} from '@ngx-formly/material/form-field';
import {FindOptions, SelectSenderOptions} from '@shared/sender-ui/select-sender/select-sender-options';

/**
 * A generic ngx-formly user selection field.
 */
@Component({
  selector: 'coyo-formly-user',
  template: `
    <coyo-select-sender
      selectClass="mat-ng-select"
      selectAppearance="outline"
      [formControl]="formControl"
      [options]="senderSelectOptions"
      [placeholder]="to.placeholder"
      [clearable]="to.clearable && !to.required"
      [multiselect]="to.multiple"
      [bindId]="to.bindId">
    </coyo-select-sender>`
})
export class FormlyUserComponent extends FieldType {

  readonly senderSelectOptions: SelectSenderOptions = {
    findOptions: FindOptions.ALL,
    senderTypes: ['user']
  };

  defaultOptions: FormlyFieldConfig = {
    templateOptions: {
      clearable: true,
      multiple: false,
      bindId: true
    }
  };
}

import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '@root/injection-tokens';
import * as _ from 'lodash';

/**
 * The service to print the job ad to the console.
 */
@Injectable({
  providedIn: 'root'
})
export class JobAdService {

  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Log this jod ad on the console to become a COYO developer. Just activates on the
   * COYO cloud to neither annoy private cloud customers or our own people developing on test systems or locally.
   */
  init(): void {
    if (this.isWhitelistedUrl()) {
      // tslint:disable:no-console
      console.log('\n\n\n');
      console.log('\t     @@@@@@@@@         @@@@@@@@@     @@@@          @@@@     @@@@@@@@@');
      console.log('\t  @@@@@@@@@@@@@@@    @@@@@@@@@@@@@@   @@@@@       @@@@   @@@@@@@@@@@@@@@');
      console.log('\t @@@@@       @@                @@@@@@  @@@@@    @@@@@               @@@@@');
      console.log('\t@@@@                @@@           @@@@   @@@@@  @@@@@    @@@           @@@@');
      console.log('\t@@@@               @@@             @@@@    @@@@@@@@    @@@@             @@@@');
      console.log('\t@@@@              @@@@             @@@@     @@@@@@     @@@@             @@@@');
      console.log('\t@@@@              @@@@             @@@@      @@@@      @@@@             @@@@');
      console.log('\t@@@@               @@@@           @@@@       @@@@       @@@@           @@@@');
      console.log('\t @@@@@       @@      @@@@@       @@@@@       @@@@        @@@@@       @@@@@');
      console.log('\t  @@@@@@@@@@@@@@@      @@@@@@@@@@@@@@        @@@@         @@@@@@@@@@@@@@@');
      console.log('\t     @@@@@@@@@           @@@@@@@@@           @@@@            @@@@@@@@@');
      console.log('\n\n\tHej, stranger!\n\n');
      console.log('\tGreat to see you are so curious about our code. Did you know we are hiring?\n');
      console.log('\tVisit https://coyoapp.com/en/jobs and apply now to become part of the COYO team!\n\n\n');
      // tslint:enable:no-console
    }
  }

  isWhitelistedUrl(): boolean {
    const url = this.window.location.hostname;
    const urlPattern = '(coyo.local|coyocloud.com|\\.branch.coyo4.com)';
    return !_.isEmpty(url) ? new RegExp(urlPattern + '/*', 'i').test(url) : false;
  }
}

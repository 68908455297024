import {Pipe, PipeTransform} from '@angular/core';
import {StripHtmlService} from '@shared/strip-html/strip-html.service';

/**
 * Strips HTML from a given text.
 */
@Pipe({name: 'stripHtml'})
export class StripHtmlPipe implements PipeTransform {

  constructor(private stripHtmlService: StripHtmlService) {
  }

  /**
   * Strips html from a given text.
   *
   * @param value the HTML-flavoured source text
   *
   * @return the text without HTML tags and chars
   */
  transform(value: string): string {
    return this.stripHtmlService.strip(value);
  }
}

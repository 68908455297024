(function (angular) {
  'use strict';

  AdminLandingPageDetailsController.$inject = ["$state", "$scope", "landingPage"];
  angular.module('coyo.admin.landingPages')
      .controller('AdminLandingPageDetailsController', AdminLandingPageDetailsController);

  function AdminLandingPageDetailsController($state, $scope, landingPage) {
    var vm = this;

    vm.oldSlug = landingPage.slug;
    vm.baseUrl = $state.href('main.landing-page', {}) + '/';
    vm.landingPage = landingPage;
    vm.showAdvanced = vm.landingPage.configuredUrl;
    vm.$onInit = onInit;

    vm.save = save;

    $scope.$watch('$ctrl.landingPage.configuredUrl', function (newVal) {
      if (newVal) {
        vm.landingPage.mobileActive = false;
      }
    });

    function save() {
      vm.landingPage.clientTypes = [];
      if (vm.landingPage.webActive) {
        vm.landingPage.clientTypes.push('WEB');
      }
      if (vm.landingPage.mobileActive) {
        vm.landingPage.clientTypes.push('MOBILE');
      }
      vm.landingPage.save().then(function () {
        $state.go('^.list');
      });
    }

    function onInit() {
      vm.landingPage.webActive = _isActiveOn('WEB');
      vm.landingPage.mobileActive = _isActiveOn('MOBILE');
    }

    function _isActiveOn(type) {
      return landingPage.clientTypes && landingPage.clientTypes.indexOf(type) !== -1;
    }
  }

})(angular);

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {MediaWidget} from '@widgets/media/media-widget';
import {MediaWidgetSettingsComponent} from './media-widget-settings/media-widget-settings.component';
import {MediaWidgetComponent} from './media-widget/media-widget.component';

/**
 * The configuration for the Media widget.
 */
export const MEDIA_WIDGET: WidgetConfig<MediaWidget> = {
  key: 'media',
  name: 'WIDGET.MEDIA.NAME',
  description: 'WIDGET.MEDIA.DESCRIPTION',
  icon: 'zmdi-collection-image-o',
  component: MediaWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    component: MediaWidgetSettingsComponent,
    skipOnCreate: false
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

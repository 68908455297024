(function (angular) {
  'use strict';

  errorLogService.$inject = ["$injector", "$log", "$window", "coyoEndpoints"];
  angular
      .module('commons.error')
      .factory('errorLogService', errorLogService)
      .provider('$exceptionHandler', {
        $get: ["errorLogService", function (errorLogService) {
          return errorLogService.log;
        }]
      });

  function errorLogService($injector, $log, $window, coyoEndpoints) {
    var $http, $timeout, authService;
    var throttle = -1;

    var errorCache = {};
    var errorPromise = null;

    return {
      init: init,
      log: log
    };

    function init() {
      $http = $injector.get('$http');
      $timeout = $injector.get('$timeout');
      authService = $injector.get('authService');

      $injector.get('SettingsModel').retrieveByKey('jsLogThrottle').then(function (jsLogThrottle) {
        throttle = _.toInteger(jsLogThrottle);
      });
    }

    function log(exception) {
      $log.error.apply($log, arguments);

      if (throttle < 0 || !authService.isAuthenticated()) {
        return;
      }

      _cache(exception);
      if (!errorPromise) {
        errorPromise = $timeout(function () {
          $http({
            url: coyoEndpoints.log,
            method: 'POST',
            data: _.values(errorCache),
            autoHandleErrors: false
          });
          errorCache = {};
          errorPromise = null;
        }, throttle, false);
      }
    }

    function _cache(exception) {
      var errorUrl = $window.location.href;
      var errorName = exception.name;
      var errorMessage = exception.message;
      var errorKey = [errorUrl, errorName, errorMessage].join('::');

      if (errorCache[errorKey]) {
        errorCache[errorKey].timestamps.push(new Date().getTime());
      } else {
        errorCache[errorKey] = {
          url: errorUrl,
          message: errorName + ': ' + errorMessage,
          stacktrace: [exception.stack],
          timestamps: [new Date().getTime()]
        };
      }
    }
  }

})(angular);

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DividerComponent} from './divider.component';
import './divider.component.downgrade';

/**
 * Module that contains a component used to display a divider.
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    DividerComponent
  ],
  exports: [
    DividerComponent
  ],
  entryComponents: [
    DividerComponent
  ]
})
export class DividerModule {
}

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {BirthdayWidget} from '@widgets/birthday/birthday-widget';
import * as _ from 'lodash';

@Component({
  selector: 'coyo-birthday-widget-settings',
  templateUrl: './birthday-widget-settings.component.html',
  styleUrls: ['./birthday-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BirthdayWidgetSettingsComponent extends WidgetSettingsComponent<BirthdayWidget> implements OnInit {

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.parentForm.addControl('_daysBeforeBirthday',
      this.fb.control(this.widget.settings._daysBeforeBirthday || 10,
        [Validators.required, Validators.min(1), Validators.max(366), CoyoValidators.naturalNumber]));
    this.parentForm.addControl('_displayAge',
      this.fb.control(!_.has(this.widget.settings, '_displayAge') || this.widget.settings._displayAge,
        []));
    this.parentForm.addControl('_birthdayNumber',
      this.fb.control(this.widget.settings._birthdayNumber || 5,
        [Validators.required, Validators.min(1), Validators.max(100), CoyoValidators.naturalNumber]));
    this.parentForm.addControl('_fetchBirthdays',
      this.fb.control(this.widget.settings._fetchBirthdays || 'ALL',
        [Validators.required, CoyoValidators.options('ALL', 'FOLLOWERS')]));
  }

}

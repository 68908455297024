<div class="interesting-colleagues-widget">
  <h3 class="widget-title">
    {{ getTitle() || ("WIDGETS.INTERESTINGCOLLEAGUES.NAME" | translate)}}
    <button (click)="refresh()" [attr.aria-label]="('WIDGETS.INTERESTINGCOLLEAGUES.REFRESH' | translate)"
            class="refresh-button" mat-icon-button>
      <mat-icon svgIcon="refresh"></mat-icon>
    </button>
  </h3>
</div>

<ng-container *ngIf="(state$ | async) as state">
  <coyo-user-list [enableShowMore]="false" [state]="state"></coyo-user-list>
</ng-container>

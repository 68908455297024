<header>
  <coyo-sender-avatar *ngIf="item.author" [sender]="item.author"
                      [showExternalFlag]="true"></coyo-sender-avatar>

  <div class="timeline-item-title">
    <div class="timeline-item-title-headline">
      <coyo-sender-link *ngIf="item.author" [sender]="item.author"
                        customClass="timeline-item-author-name"></coyo-sender-link><!--
       --><ng-container *ngIf="recipientNotAuthor"><!--
         --><mat-icon svgIcon="chevron-right" size="small" class="chevron"></mat-icon><!--
         --><ng-container *ngFor="let recipient of item.recipients; let last = last"><!--
           --><mat-icon svgIcon="globe" size="small" *ngIf="recipient.public && !hasShares"></mat-icon><!--
           --><mat-icon svgIcon="lock" size="small" *ngIf="!recipient.public && !hasShares"></mat-icon><!--
           --><mat-icon svgIcon="globe" size="small" *ngIf="hasShares && publicShare"></mat-icon><!--
           --><mat-icon svgIcon="globe" size="small" *ngIf="hasShares && !publicShare"></mat-icon><!--
           --><coyo-sender-link *ngIf="recipient" [sender]="recipient"></coyo-sender-link><span *ngIf="!last">,</span><!--
         --></ng-container>
    </ng-container>
    </div>

    <div class="timeline-item-title-subline">
      {{ item.created | timeAgo }}<span
      *ngIf="originalAuthor">, {{'MODULE.TIMELINE.ORIGINAL_AUTHOR.POSTED_BY' | translate}} {{originalAuthor.displayName}}</span>
    </div>
  </div>
</header>

import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {OFFICE365} from '@domain/attachment/storage';
import {CapabilitiesService} from '@domain/capability/capabilities/capabilities.service';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {Sender} from '@domain/sender/sender';
import {ExternalFileHandlerService} from '@shared/files/external-file-handler/external-file-handler.service';
import {FileDetailsModalService} from '@shared/files/file-details-modal/file-details-modal.service';
import {ExternalLinkPreviewConfirmationDialogService} from '@shared/preview/external-link-preview-confirmation-dialog/external-link-preview-confirmation-dialog.service';
import {RenderVariant} from '@shared/preview/file-preview-list/render-variant';
import {FilePreviewSpinnerOptions} from '@shared/preview/file-preview/file-preview-options';
import {Attachment} from 'app/core/domain/attachment/attachment';
import * as _ from 'lodash';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Component for the list of previews on timeline form, timeline posts, comments and messages
 */
@Component({
  selector: 'coyo-file-preview-list',
  templateUrl: './file-preview-list.component.html',
  styleUrls: ['./file-preview-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePreviewListComponent implements OnInit {

  /**
   * List of attachments attached on timeline post or chat message
   */
  @Input() attachments: Attachment[];
  /**
   * Id of the group
   */
  @Input() groupId: string;
  /**
   * Author of the timeline post or chat message
   */
  @Input() author: Sender;
  /**
   * Attachment preview Url
   */
  @Input() previewUrl: string;
  /**
   * Attachment view size
   */
  @Input() size: string;
  /**
   * If all attachments have the same view size
   */
  @Input() sameSize: boolean;
  /**
   * Attachment title for preview
   */
  @Input() previewTitle: string;
  /**
   * Attachment title
   */
  @Input() nonPreviewTitle: string;
  /**
   * Type of rendering that results in a different styling of the previewed items
   */
  @Input()
  @HostBinding('class')
  renderVariant: RenderVariant;
  /**
   * Configuration for the loading spinner
   */
  @Input() spinnerOptions: FilePreviewSpinnerOptions = {size: 'md', inverted: false};
  /**
   * Whether to show a hint to download the Engage app.
   */
  @Input() showEngageDownloadHint?: boolean = false;

  url: string = '/web/senders/{{groupId}}/documents/{{id}}';
  previewFiles$: Observable<FilePreview[]>;
  nonPreviewFiles$: Observable<FilePreview[]>;
  filePreviews$: Observable<FilePreview[]>;

  private cannotProcess: string[] = [];

  constructor(
    private fileDetailsModalService: FileDetailsModalService,
    private capabilitiesService: CapabilitiesService,
    private externalFileHandlerService: ExternalFileHandlerService,
    private externalLinkPreviewConfirmationDialogService: ExternalLinkPreviewConfirmationDialogService) {
  }

  ngOnInit(): void {
    this.size = this.size || 'xl';
    this.renderVariant = this.renderVariant || RenderVariant.BOXED;
    this.mapAttachments();
  }

  private mapAttachments(): void {
    this.filePreviews$ = forkJoin(this.attachments.map((attachment: Attachment) =>
      this.capabilitiesService.imgAvailable(attachment.contentType).pipe(map((available: boolean) => {
        const filePreview = this.toFilePreview(attachment);
        filePreview.previewAvailable = this.isLocalStoredAttachment(attachment) ? available : false;
        filePreview.showSize = attachment.storageAvailable && this.isLocalStoredAttachment(attachment);
        filePreview.deleted = this.isDeletedAttachment(attachment);
        return filePreview;
      })))
    ).pipe(map(previews => _.sortBy(previews, [(preview: FilePreview) => preview.name.toLowerCase(), 'created'])));

    this.previewFiles$ = this.filePreviews$
      .pipe(map(previews => previews.filter(preview => preview.previewAvailable && this.cannotProcess.indexOf(preview.id) === -1)));
    this.nonPreviewFiles$ = this.filePreviews$
      .pipe(map(previews => previews.filter(preview => !preview.previewAvailable || this.cannotProcess.indexOf(preview.id) !== -1)));
  }

  /**
   * Opens attached file details if available
   *
   * @param attachment Attached file
   * @param $event click event
   */
  showDetails(attachment: Attachment, $event: Event): void {
    if (!attachment.storageAvailable || this.isShowEngageDownload(attachment)) {
      return;
    }
    if (attachment.storage === OFFICE365) {
      this.openExternalFileInNewTab(attachment, $event);
      return;
    }

    this.openAttachmentInFileDetailsModal(attachment, $event);
  }

  private openAttachmentInFileDetailsModal(attachment: Attachment, $event: Event): void {
    $event.preventDefault();
    const files = this.getFilePreviewsSortedByName();
    const index = this.getAttachmentIndex(attachment);
    this.fileDetailsModalService.open(files, index, true).subscribe();
  }

  private openExternalFileInNewTab(attachment: Attachment, $event: Event): void {
    $event.preventDefault();
    this.externalFileHandlerService
      .getExternalFileDetails(attachment)
      .then(details => this.externalLinkPreviewConfirmationDialogService.open(
        attachment.name,
        details.externalUrl
      ));
  }

  private getFilePreviewsSortedByName(): FilePreview[] {
    const filePreviews = this.attachments.map(file => this.toFilePreview(file));
    return _.sortBy(filePreviews, preview => preview.name.toLowerCase());
  }

  private getAttachmentIndex(attachment: Attachment): number {
    const sortedByName = _.sortBy(this.attachments, preview => preview.name.toLowerCase());
    return _.findIndex(sortedByName, file => file.fileId === attachment.fileId || file.id === attachment.id);
  }

  private toFilePreview(attachment: Attachment): FilePreview {
    if (attachment.storage === 'LOCAL_FILE_LIBRARY') {
      return {
        ...attachment,
        id: attachment.fileId,
        attachment: false,
        groupId: attachment.modelId,
        senderId: attachment.modelId,
      };
    } else {
      return {
        ...attachment,
        attachment: true,
        groupId: this.groupId,
        previewUrl: this.previewUrl,
        author: this.author
      };
    }
  }

  /**
   * Maps the attachment in the nonPreviewFiles when preview file cannot be processed
   *
   * @param fileId File Id
   */
  onCannotProcess(fileId: string): void {
    this.cannotProcess.push(fileId);
    this.mapAttachments();
  }

  isShowEngageDownload(attachment: Attachment): boolean {
    return this.showEngageDownloadHint && this.isEngageVoiceMessage(attachment);
  }

  private isLocalStoredAttachment(attachment: Attachment): boolean {
    return !this.externalFileHandlerService.isExternalFile(attachment);
  }

  private isDeletedAttachment(attachment: Attachment): boolean {
    return !attachment.length && this.isLocalStoredAttachment(attachment);
  }

  private isEngageVoiceMessage(attachment: Attachment): boolean {
    return attachment.name && attachment.name.toLowerCase().startsWith('voicemessage')
      && attachment.contentType === 'audio/x-m4a';
  }
}

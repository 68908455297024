<div class="user-chooser-wrapper" #chooserRoot (window:resize)="onResize()">
  <div class="main-step" *ngIf="step === 1">
    <div class="main-step-wrapper">
      <div class="selection-area">
        <div class="panel-heading">
          <h5 class="panel-title" [translate]="titleTranslationKey"></h5>
        </div>
        <div class="panel-body">
          <div coyoFloatingHeader class="panel-floating-header">
            <div class="search-container">
              <mat-icon svgIcon="search"></mat-icon>
              <input type="text"
                     class="form-control input-sm"
                     placeholder="{{searchBarPlaceholder}}"
                     matInput
                     [formControl]="searchInput"
                     (keydown.enter)="$event.preventDefault()"/>
            </div>
          </div>
          <coyo-guest-list [guestDataSource]="userChooserDataSource$"
                           (changes)="updateInviteList($event)">
          </coyo-guest-list>
        </div>
        <div class="panel-footer form-action-buttons" coyoFloatingFooter *ngIf="isMobile">
           <ng-container *ngTemplateOutlet="actionButtons; context: {previous: 'USER.CHOOSER.STEP.PREVIOUS', next: 'USER.CHOOSER.STEP.NEXT'}"></ng-container>
        </div>
      </div>
      <div class="review-area" *ngIf="!isMobile">
        <ng-container *ngTemplateOutlet="reviewListOrHelp"></ng-container>
      </div>
    </div>
  </div>
  <div class="review-area-mobile" *ngIf="showGuestList && isMobile && step === 2">
    <ng-container *ngTemplateOutlet="reviewListOrHelp"></ng-container>
  </div>
</div>

<ng-template #actionButtons let-next="next" let-previous="previous">
  <button mat-button
          type="button"
          [attr.aria-label]="previous | translate"
          (click)="previousStep()">
    <span [translate]="previous"></span>
  </button>
  <button mat-flat-button
          color="accent"
          [type]="isNextButtonSubmitButton() ? 'submit' : 'button'"
          [attr.aria-label]="next | translate"
          (click)="isNextButtonSubmitButton() ? submit() : nextStep()">
    <span [translate]="next"></span>
  </button>
</ng-template>

<ng-template #reviewListOrHelp>
  <div coyoFloatingHeader class="panel-heading">
    <h3 class="panel-title" translate="USER.CHOOSER.GUEST_LIST"></h3>
  </div>
  <ng-container *ngIf="selectedGuestsList$ | async as selectedGuests">
    <ng-container *ngIf="selectedGuests.length > 0">
      <ng-container *ngTemplateOutlet="reviewArea"></ng-container>
    </ng-container>
    <ng-container *ngIf="selectedGuests.length === 0">
      <ng-container *ngTemplateOutlet="reviewAreaEmpty"></ng-container>
    </ng-container>
  </ng-container>
  <div coyoFloatingFooter class="panel-footer form-action-buttons">
    <ng-container *ngTemplateOutlet="actionButtons; context: {previous: 'USER.CHOOSER.STEP.PREVIOUS', next: submitButtonTranslationKey}"></ng-container>
  </div>
</ng-template>

<ng-template #reviewArea>
  <div class="panel-body">
    <coyo-guest-list [guestDataSource]="selectedGuestsDataSource$"
                     [renderCheckbox]="false"
                     [showAdditionalInfos]="false"
                     (changes)="updateInviteList($event)">
    </coyo-guest-list>
  </div>
</ng-template>

<ng-template #reviewAreaEmpty>
  <div class="panel-body user-chooser-help">
    <img *ngIf="!isMobile" [src]="reviewAreaImage"/>
    <div class="help-text">
      <span *ngIf="!isMobile" translate="USER.CHOOSER.GUEST_LIST.HELP.PART_ONE.DESKTOP"></span>
      <span *ngIf="isMobile" translate="USER.CHOOSER.GUEST_LIST.HELP.PART_ONE.MOBILE"></span>
      <div *ngIf="additionalEmptyListTranslationKey.length > 0"
           class="divider-wrapper">
        <coyo-divider>
          <span translate="USER.CHOOSER.GUEST_LIST.HELP.PART_TWO"></span>
        </coyo-divider>
      </div>
      <span *ngIf="additionalEmptyListTranslationKey.length > 0"
            [translate]="additionalEmptyListTranslationKey">
      </span>
    </div>
  </div>
</ng-template>

(function (angular) {
  'use strict';

  registerSamlJit.$inject = ["authenticationProviderTypeRegistryProvider"];
  angular
      .module('coyo.admin.authenticationProviders.saml-jit', [
        'coyo.base',
        'coyo.domain',
        'coyo.admin.authenticationProviders.api'
      ])
      .config(registerSamlJit);

  function registerSamlJit(authenticationProviderTypeRegistryProvider) {
    authenticationProviderTypeRegistryProvider.register({
      key: 'saml-jit',
      name: 'ADMIN.AUTHENTICATION.SAMLJIT.NAME',
      description: 'ADMIN.AUTHENTICATION.SAMLJIT.DESCRIPTION',
      directive: 'oyoc-saml-jit-settings',
      editSlug: true
    });
  }

})(angular);

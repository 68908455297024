import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '@core/auth/auth.service';
import {Document} from '@domain/file/document';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {MediaWidget} from '@widgets/media/media-widget';
import {MediaWidgetFile} from '@widgets/media/media-widget-file';
import {Observable} from 'rxjs';

/**
 * The media widget settings component.
 */
@Component({
  selector: 'coyo-media-widget-settings',
  templateUrl: './media-widget-settings.component.html',
  styleUrls: ['./media-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaWidgetSettingsComponent extends WidgetSettingsComponent<MediaWidget>
  implements OnInit {

  readonly previewUrl: string = '/web/senders/{{groupId}}/documents/{{id}}';

  sender$: Observable<Sender>;

  mediaArray: FormArray;

  constructor(private fb: FormBuilder,
              private senderService: SenderService,
              private authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    this.sender$ = this.senderService.get(
      this.senderService.getCurrentIdOrSlug() ?? this.authService.getCurrentUserId(),
      {permissions: ['createFile']}
    );

    const albumGroup = this.fb.group({});
    this.mediaArray = this.fb.array([], [Validators.required]);

    const album = this.widget.settings.album;
    const defaultTitle = album ? album.title : undefined;
    const defaultDescription = album ? album.description : undefined;
    const defaultLocation = album ? album.location : undefined;

    albumGroup.addControl('title', this.fb.control(defaultTitle,
      [Validators.maxLength(255)]));
    albumGroup.addControl('description', this.fb.control(defaultDescription));
    albumGroup.addControl('location', this.fb.control(defaultLocation,
      [Validators.maxLength(255)]));

    if (this.widget.settings._media) {
      this.addMediaToForm(this.widget.settings._media);
    }

    this.parentForm.addControl('album', albumGroup);
    this.parentForm.addControl('_media', this.mediaArray);
  }

  /**
   * Adds files to the media list if they are not already contained.
   *
   * @param $event
   * Array of documents to add
   */
  onFilesAdded($event: Document[]): void {
    this.addMediaToForm($event.filter(document => this.findMediaIndex(document) === -1));
  }

  private addMediaToForm(media: MediaWidgetFile[]): void {
    media.forEach((document, i) => {
      const group = this.fb.group({
        name: [document.name],
        id: [document.id],
        senderId: [document.senderId],
        contentType: [document.contentType],
        description: [document.description],
        // tslint:disable-next-line:no-boolean-literal-compare
        preview: [document.preview !== false],
        sortOrderId: [document.sortOrderId]
      });
      this.mediaArray.push(group);
    });
    this.setOrderId();
  }

  /**
   * Delete one media from the form.
   *
   * @param document
   * The document to be deleted
   */
  deleteMedia(document: MediaWidgetFile): void {
    const index = this.findMediaIndex(document);
    if (index > -1) {
      this.mediaArray.removeAt(index);
    }
    this.setOrderId();
  }

  private findMediaIndex(document: MediaWidgetFile): number {
    return this.mediaArray.controls.findIndex(control => control.value && control.value.id === document.id);
  }

  private setOrderId(): void {
    this.mediaArray.controls.forEach((control, i) => control.get('sortOrderId').setValue(i));
  }

  /**
   * Drop event putting the dragged media into the dropped slot of the media array.
   *
   * @param event
   * The drag and drop event
   */
  dropMedia(event: CdkDragDrop<MediaWidgetFile>): void {
    if (event.currentIndex === event.previousIndex) {
      return;
    }
    let adjustValue = 0.5;
    if (event.previousIndex > event.currentIndex) {
      adjustValue = -0.5;
    }
    const draggedControl = this.mediaArray.controls[event.previousIndex];
    const droppedControl = this.mediaArray.controls[event.currentIndex];
    draggedControl.get('sortOrderId').setValue(droppedControl.get('sortOrderId').value + adjustValue);
    this.mediaArray.controls.sort((a, b) => a.get('sortOrderId').value - b.get('sortOrderId').value);
    this.setOrderId();
  }
}

import {Inject, Injectable} from '@angular/core';
import {CoyoStorage} from '@core/storage/storage';
import {WINDOW} from '@root/injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements CoyoStorage {

  static readonly PREFIX: string = 'ngStorage-';

  constructor(@Inject(WINDOW) private windowService: Window) { }

  getValue<T>(key: string, fallback: T = null): T {
    const value = this.windowService.sessionStorage.getItem(SessionStorageService.PREFIX + key);
    return JSON.parse(value) || fallback;
  }

  setValue(key: string, value: any): void {
    this.windowService.sessionStorage.setItem(SessionStorageService.PREFIX + key, JSON.stringify(value));
  }

  deleteEntry(key: string): void {
    this.windowService.localStorage.removeItem(SessionStorageService.PREFIX + key);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDateSelectionModel, MatRangeDateSelectionModel} from '@angular/material/datepicker';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {DateSelection} from '@shared/date-range-picker/date-selection';
import {Moment} from 'moment';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

/**
 * Renders a date range picker.
 *
 * @Example:
 * <coyo-date-range-picker
 *    label="label"
 *    [startDate]="startDate"
 *    [endDate]="endDate"
 *    (dateSelectionChange)="onChanges($event)"
 *    (reset)="reset()"
 * ></coyo-date-range-picker>
 */
@Component({
  selector: 'coyo-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    {
      provide: MatDateSelectionModel,
      useClass: MatRangeDateSelectionModel
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The label for the date range picker.
   * A translation must happen externally.
   */
  @Input() label?: string = '';

  /**
   * The start date for datepicker initialisation.
   */
  @Input() startDate: Moment;

  /**
   * The end date for datepicker initialisation.
   */
  @Input() endDate: Moment;

  /**
   * The selected start and end dates.
   */
  @Output() dateSelectionChange: EventEmitter<DateSelection> = new EventEmitter<DateSelection>();

  /**
   * Reset event.
   */
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  dateRangeForm: FormGroup;
  isMobile$: Observable<boolean>;
  private start: Moment = null;
  private end: Moment = null;
  private debounceTime: number = 500;
  private isMobile: boolean = false;
  private isMobileSubscription: Subscription;

  constructor(private windowSizeService: WindowSizeService,
              private matDateSelectionModel: MatDateSelectionModel<Moment, Moment>) {
  }

  ngOnInit(): void {
    this.isMobile$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS || screenSize === ScreenSize.SM));
    this.isMobileSubscription = this.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.dateRangeForm = new FormGroup({
      start: new FormControl(this.startDate),
      end: new FormControl(this.endDate)
    });
    this.start = this.startDate || null;
    this.end = this.endDate || null;
    this.dateRangeForm.valueChanges
      .pipe(debounceTime(this.debounceTime))
      .subscribe(values => {
        if (this.dateRangeForm.invalid) {
          return;
        }
        if (this.isMobile && this.matDateSelectionModel.isComplete() || !this.isMobile) {
          if (this.start !== values.start || this.end !== values.end) {
            this.dateSelectionChange.emit(values);
          }
        }
        this.start = values.start;
        this.end = values.end;
      });
  }

  // Is needed if the inputs are cleared from outside
  ngOnChanges(changes: SimpleChanges): void {
    if (this.dateRangeForm) {
      const dateSelection: DateSelection = {};
      if (changes.startDate && !changes.startDate.currentValue
        && changes.startDate.currentValue !== changes.startDate.previousValue) {
        dateSelection.start = null;
      }
      if (changes.endDate && !changes.endDate.currentValue
        && changes.endDate.currentValue !== changes.endDate.previousValue) {
        dateSelection.end = null;
      }
      this.dateRangeForm.patchValue(dateSelection);
    }
  }

  ngOnDestroy(): void {
    this.reset.complete();
    this.dateSelectionChange.complete();
    this.isMobileSubscription.unsubscribe();
  }

  resetDateSelection(): void {
    this.start = null;
    this.end = null;
    this.dateRangeForm.reset();
    this.reset.emit();
  }

}

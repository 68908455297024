(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.search
   *
   * @description
   * # Search module #
   * The search module provides the search view and a service for search requests.
   *
   * @requires $stateProvider
   */
  ModuleConfig.$inject = ["$stateProvider", "searchConfig"];
  angular
      .module('coyo.search', [
        'coyo.base',
        'coyo.profile',
        'commons.resource',
        'commons.auth',
        'commons.ui'
      ])
      .config(ModuleConfig)
      .constant('searchConfig', {
        templates: {
          search: 'app/modules/search/views/search.html'
        },
        quickSearch: {
          page: 0,
          pageSize: 5
        }
      })
      .constant('externalSearchRequestLimit', 3);

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, searchConfig) {
    $stateProvider.state('main.search', {
      url: '/search?:term&:type[]&:modified[]&:sender[]&:author[]&:sort',
      templateUrl: searchConfig.templates.search,
      controller: 'SearchController',
      controllerAs: '$ctrl',
      resolve: {
        currentUser: ["authService", function (authService) {
          return authService.getUser();
        }]
      },
      data: {
        guide: 'search',
        pageTitle: 'MODULE.SEARCH.PAGE_TITLE'
      }
    });
  }

})(angular);

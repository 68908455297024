(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .filter('translateTimezone', translateTimezoneFilter);

  /**
   * @ngdoc filter
   * @name commons.ui.translateTimezone:translateTimezone
   * @function
   *
   * @description
   * Converts timezone names to timezone translation keys.
   *
   * @param {string} input An input timezone string to be converted
   */
  function translateTimezoneFilter() {

    return function (input) {
      if (input && input.toUpperCase && input.replace) {
        input = input.toUpperCase();
        return input.replace('/', '.');
      }
      return input;
    };
  }

})(angular);

<ng-container *ngIf="(state$ | async) as state">
  <h3 class="widget-title">
    {{widget.settings._title || ("WIDGETS.DOWNLOADS.NAME" | translate)}}</h3>
  <ng-container *ngIf="state.loading">
      <ui-skeleton [skeletons]="skeletons"
                   *ngFor="let file of widget.settings._files"></ui-skeleton>
  </ng-container>
  <ng-container *ngIf="!state.loading && !state.files.length">
    <span class="no-files" translate="WIDGETS.DOWNLOADS.NO_FILES"></span>
  </ng-container>
  <ng-container *ngFor="let file of state.files">
    <ng-container *ngIf="!editMode; else input">
      <a mat-flat-button [attr.href]="getLink(file)" target="_blank" class="block-left"
         [attr.aria-label]="'WIDGETS.DOWNLOADS.FILE.ARIA' | translate: ({name: getName(file)})">
        <mat-icon svgIcon="download"></mat-icon>
        <span>{{getName(file)}}</span>
      </a>
    </ng-container>
    <ng-template #input>
      <mat-form-field appearance="outline" class="edit-input">
        <mat-icon svgIcon="download" matPrefix></mat-icon>
        <input type="text" matInput
               #titleControl
               [ngModel]="file.title"
               (blur)="onTitleChange(titleControl.value, file)"
               [placeholder]="file.name"
               [attr.aria-label]="'WIDGETS.DOWNLOADS.FILE.INPUT.ARIA' | translate: ({name: getName(file)})">
      </mat-form-field>
    </ng-template>
  </ng-container>
</ng-container>

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * Message deleted information shown in chat channels.
 */
@Component({
  selector: 'coyo-message-deleted',
  templateUrl: './message-deleted.component.html',
  styleUrls: ['./message-deleted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDeletedComponent {

  /**
   * Parameter to define whether the personal (isAuthor) or impersonal (isNotAuthor) message should be shown. Default impersonal.
   */
  @Input() isAuthor?: boolean = false;

}

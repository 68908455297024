import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {RenderVariant} from '@shared/preview/file-preview-list/render-variant';

@Component({
  selector: 'coyo-file-non-preview',
  templateUrl: './file-non-preview.component.html',
  styleUrls: ['./file-non-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileNonPreviewComponent {

  /**
   * Attachment attached on timeline post, comment or chat message
   */
  @Input() attachment: FilePreview;

  /**
   * Attachment view size. Determines the size of the file icons here.
   */
  @Input() size: string;

  /**
   * Render variant of the attachment.
   */
  @Input() renderVariant: RenderVariant;

  /**
   * Whether to show a hint to download the Engage app.
   */
  @Input() showEngageDownloadHint?: boolean = false;

  readonly iconSizeByPreviewSize: {[previewSize: string]: string} = {
    lg: 'small',
    xl: 'medium'
  };

  isRenderedAsBoxed(renderVariant: RenderVariant): boolean {
    return renderVariant === RenderVariant.BOXED;
  }
}

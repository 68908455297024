import {ChangeDetectionStrategy, Component, HostListener, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FileVideoPreviewComponent} from '@shared/preview/file-preview/file-video-preview/file-video-preview.component';
import {ContentTypeService} from '@widgets/media/content-type/content-type.service';
import {MediaWidgetFile, MediaWidgetFileSettings} from '@widgets/media/media-widget-file';

@Component({
  selector: 'coyo-light-box',
  templateUrl: './light-box.component.html',
  styleUrls: ['./light-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LightBoxComponent {
  readonly previewUrl: string = '/web/senders/{{groupId}}/documents/{{id}}';

  media: MediaWidgetFile[];

  currentMedia: MediaWidgetFile;

  /**
   * Get the reference to the child FileVideoPreviewComponent
   */
  @ViewChild('videoPreview', {
    static: false
  }) videoPreview: FileVideoPreviewComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MediaWidgetFileSettings, private contentTypeService: ContentTypeService) {
    this.media = data.mediaFiles;
    this.currentMedia = this.media[data.currentIndex];
  }

  /**
   * Displayed the next media
   */
  loadNext(): void {
    const currentIndex = this.calculateFileIndex();
    const mediaCount = this.media.length;
    this.currentMedia = this.media[(currentIndex + 1) % mediaCount];
  }

  /**
   * Displayed the previous media
   */
  loadPrevious(): void {
    const currentIndex = this.calculateFileIndex();
    const newIndex = currentIndex > 0 ? currentIndex - 1 : this.media.length - 1;
    this.currentMedia = this.media[newIndex];
  }

  /**
   * Checks if media is a video
   * @param mediaWidgetFile file to check if it is a video
   * @return boolean true if media is video false if not
   */
  isVideo(mediaWidgetFile: MediaWidgetFile): boolean {
    return this.contentTypeService.isVideo(mediaWidgetFile.contentType);
  }

  /**
   * Checks if media is a image
   * @param mediaWidgetFile file to check if it is a image
   * @return boolean true if media is image false if not
   */
  isImage(mediaWidgetFile: MediaWidgetFile): boolean {
    return this.contentTypeService.isImage(mediaWidgetFile.contentType);
  }

  /**
   * Received key events to execute commands of the current video
   * @param $event keydown event
   */
  @HostListener('document:keydown', ['$event'])
  keyDown($event: KeyboardEvent): void {
    const key = $event.key;
    if ( key === 'ArrowLeft') {
      this.loadPrevious();
    } else if ( key === 'ArrowRight') {
      this.loadNext();
    }
    if (this.videoPreview) {
      if ( key === 'ArrowUp') {
        this.videoPreview.volumeUp();
      } else if (key === 'ArrowDown') {
        this.videoPreview.volumeDown();
      } else if ( key === ' ') {
        this.videoPreview.togglePlay();
      } else if ( Number.isInteger(+key)) {
        this.videoPreview.jumpTo(+key);
      }
    }
  }

  private calculateFileIndex(): number {
    return this.media.indexOf(this.currentMedia);
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Plugin} from './plugin';
import {PluginEntryPoint} from './plugin-entry-point';

/**
 * Service for plug-ins.
 */
@Injectable({
  providedIn: 'root'
})
export class PluginService {
  private static readonly WIDGET_KEY_PREFIX: string = 'plugin-';
  static readonly baseUrl: string = '/web/plugins';

  constructor(private http: HttpClient) {
  }

  /**
   * Extracts the plugin ID from the given widget key.
   *
   * @param widgetKey the dynamic widget key
   * @returns the plugin ID
   */
  static getPluginId(widgetKey: string): string {
    return widgetKey.slice(PluginService.WIDGET_KEY_PREFIX.length);
  }

  /**
   * Checks if the given widget key is a plugin key.
   *
   * @param key the widget key
   * @returns true, if it is a plugin key
   */
  static isWidgetKey(key: string): boolean {
    return key.startsWith(PluginService.WIDGET_KEY_PREFIX);
  }

  /**
   * Builds a dynamic widget key with the given plugin ID.
   *
   * @param pluginId the plugin ID
   * @returns the dynamic widget key
   */
  static getWidgetKey(pluginId: string): string {
    return PluginService.WIDGET_KEY_PREFIX + pluginId;
  }

  /**
   * Returns the list of plug-ins.
   *
   * @returns The list of plug-ins.
   */
  getAll(): Observable<Plugin[]> {
    return this.http.get<Plugin[]>(PluginService.baseUrl);
  }

  /**
   * Returns the plug-in with the given ID.
   *
   * @param id The id of the plug-in
   * @returns The plug-in.
   */
  get(id: string): Observable<Plugin> {
    return this.http.get<Plugin>(`${PluginService.baseUrl}/${id}`);
  }

  /**
   * Returns the PluginEntryPoint for the plug-in with the given id and its entry point with the given ID.
   *
   * @param pluginId The plug-in ID
   * @param entryPointId The entry point ID
   * @returns The PluginEntryPoint or an error is thrown if not found by plug-in or entry point ID
   */
  getEntryPoint(pluginId: string, entryPointId: string): Observable<PluginEntryPoint> {
    return this.get(pluginId)
      .pipe(
        map(plugin => plugin.manifest.entryPoints.find(entryPoint => entryPoint.id === entryPointId)),
        mergeMap(entryPoint => entryPoint ? of(entryPoint) : throwError(`Invalid entry point ID '${entryPointId}'`))
      );
  }

  /**
   * Retrieves a bunch of edit notification tokens from the backend. Each token will be signed individually
   * to then be forwarded to the respective plug-in.
   *
   * @param srcIds a list of plug-in source IDs
   * @param action the edit mode action
   * @returns an observable holding a map of notification tokens
   */
  getEditTokens(srcIds: string[], action: 'enter' | 'save' | 'cancel'): Observable<{ [srcId: string]: string; }> {
    const edit = {
      enter: 'true',
      save: 'false',
      cancel: 'false'
    }[action];
    return this.http.get<{ [srcId: string]: string; }>(`${PluginService.baseUrl}/edit`, {
      params: {srcIds, action, edit}
    });
  }
}

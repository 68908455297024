<ng-container *ngIf="{editAtIndex: editingCategory$ | async} as editState">
  <coyo-filter (cdkDropListDropped)="drop($event)" [title]="title" cdkDropList>
    <coyo-filter-entry
      (click)="resetCategorySelection()"
      [active]="selectedCategories | isActive:null"
      [badge]="totalCount"
      [text]="'FILTER_ALL' | translate"
      icon="double-check"
    ></coyo-filter-entry>
    <coyo-filter-entry
      (cdkDragEnded)="dragging = false"
      (cdkDragStarted)="dragging = true"
      (click)="toggleCategory(category)"
      (mouseenter)="hovering[idx] = !dragging"
      (mouseleave)="hovering[idx] = false"
      *ngFor="let category of categories; index as idx"
      [active]="selectedCategories | isActive:category"
      [badge]="category.count"
      [icon]="categoryIcon"
      [ngClass]="{
        'dragged-item': dragging,
        'entry-edited': editedCategory | isEdited:editState.editAtIndex:category,
        'entry-hovered': hovering[idx]
      }"
      [text]="category.name"
      cdkDrag
    >
      <div *cdkDragPlaceholder></div>
      <ng-container *ngIf="canManage">
        <input
          #categoryInput
          *ngIf="editedCategory | isEdited:editState.editAtIndex:category"
          [(ngModel)]="editedCategory.name"
          [attr.aria-label]="'FILTER.CATEGORY.EDIT.INPUT.ARIA_LABEL' | translate"
          [placeholder]="'FILTER.CATEGORY.EDIT.INPUT.PLACEHOLDER' | translate"
          type="text"
        >
        <span class="filter-entry-actions">
          <button
            (click)="saveCategory(category, $event)"
            *ngIf="editedCategory | isEdited:editState.editAtIndex:category"
            [attr.aria-label]="'FILTER.CATEGORY.SAVE.ARIA_LABEL' | translate"
            mat-icon-button
            size="small"
            type="button"
          >
            <mat-icon svgIcon="check"></mat-icon>
          </button>
          <ng-container *ngIf="!(editedCategory | isEdited:editState.editAtIndex:category)">
            <button
              (click)="stopPropagation($event)"
              [attr.aria-label]="'FILTER.CATEGORY.DRAG_HANDLE.ARIA_LABEL' | translate"
              cdkDragHandle
              mat-icon-button
              size="small"
              type="button"
            >
              <mat-icon svgIcon="align-vertically"></mat-icon>
            </button>
            <button
              (click)="editCategory(category, idx, $event)"
              [attr.aria-label]="'FILTER.CATEGORY.EDIT.ARIA_LABEL' | translate"
              mat-icon-button
              size="small"
              type="button"
            >
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
            <button
              (click)="deleteCategory(category, idx, $event)"
              [attr.aria-label]="'FILTER.CATEGORY.DELETE.ARIA_LABEL' | translate"
              mat-icon-button
              size="small"
              type="button"
            >
              <mat-icon svgIcon="delete"></mat-icon>
            </button>
          </ng-container>
        </span>
      </ng-container>
    </coyo-filter-entry>
    <coyo-filter-entry
      (click)="toggleCategory(noCategory)"
      [active]="selectedCategories | isActive:noCategory"
      [badge]="notAvailableCount"
      [icon]="categoryIcon"
      text="N/A"
    ></coyo-filter-entry>
  </coyo-filter>
  <button
    (click)="addCategory($event)"
    *ngIf="canManage && editState.editAtIndex === -1"
    class="add-category-action"
    color="accent"
    mat-button
    mat-link-button
    size="small"
    type="button"
  >
    <mat-icon svgIcon="plus-circled"></mat-icon>
    <span> {{'FILTER.CATEGORY.ADD' | translate}}</span>
  </button>
</ng-container>

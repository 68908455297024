import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {NgxPopperModule} from 'ngx-popper';
import {MentionComponent} from './mention.component';
import {MentionDirective} from './mention.directive';
import {MentionPipe} from './mention.pipe';

/**
 * Module that provides mentioning functionality.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    NgxPopperModule,
    SenderUIModule
  ],
  declarations: [
    MentionComponent,
    MentionDirective,
    MentionPipe
  ],
  exports: [
    MentionComponent,
    MentionDirective,
    MentionPipe
  ],
  entryComponents: [
    MentionComponent
  ]
})
export class MentionModule {}

(function (angular) {
  'use strict';

  ViewEditNavItemController.$inject = ["$rootScope", "$timeout", "sidebarService", "$injector"];
  angular
      .module('commons.ui')
      .directive('oyocViewEditNavItem', viewEditNavItem)
      .controller('ViewEditNavItemController', ViewEditNavItemController);

  /**
   * @ngdoc directive
   * @name commons.ui.oyocViewEditNavItem:oyocViewEditNavItem
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Renders a navigation item which triggers the edit mode for widget slots. If called from the sidebar navigation
   * the sidebar is closed after clicking the item.
   *
   * @requires $rootScope
   * @requires coyo.widgets.api.widgetLayoutService
   * @requires commons.ui.sidebarService
   */
  function viewEditNavItem() {
    return {
      restrict: 'E',
      templateUrl: 'app/commons/ui/components/view-edit-nav-item/view-edit-nav-item.html',
      replace: true,
      scope: {},
      bindToController: {},
      controller: 'ViewEditNavItemController',
      controllerAs: '$ctrl'
    };
  }

  function ViewEditNavItemController($rootScope, $timeout, sidebarService, $injector) {
    var vm = this;

    vm.edit = edit;
    vm.isVisible = false;
    vm.isActive = false;
    vm.$onInit = _init;
    vm.$onDestroy = onDestroy;

    function edit() {
      $injector.get('ngxWidgetEditService').enableEditMode('global');
      sidebarService.closeAll();
    }

    function _init() {
      vm.subscription = $injector.get('ngxWidgetEditService').canEnableGlobalEditMode$().subscribe(function (newVal) {
        $timeout(function () {
          vm.isVisible = newVal;
        });
      });

      vm.subscription2 = $injector.get('ngxWidgetEditService').editModeEnabled$().subscribe(function (newVal) {
        $timeout(function () {
          vm.isActive = newVal;
        });
      });
    }

    function onDestroy() {
      vm.subscription.unsubscribe();
      vm.subscription2.unsubscribe();
    }
  }

})(angular);

/**
 * Module providing the users-online widget
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DownloadModule} from '@shared/download/download.module';
import {FileModule} from '@shared/files/file.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {PreviewModule} from '@shared/preview/preview.module';
import {SelectFileModule} from '@shared/select-file/select-file.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {TimeModule} from '@shared/time/time.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {SINGLE_FILE_WIDGET} from '@widgets/single-file/single-file-widget-config';
import {SingleFileWidgetSettingsComponent} from '@widgets/single-file/single-file-widget-settings/single-file-widget-settings.component';
import {SingleFileWidgetComponent} from '@widgets/single-file/single-file-widget/single-file-widget.component';

@NgModule({
  imports: [
    CoyoCommonsModule,
    CommonModule,
    DownloadModule,
    FileModule,
    HelpModule,
    PreviewModule,
    CoyoFormsModule,
    TranslateModule,
    TimeModule,
    SenderUIModule,
    SelectFileModule
  ],
  declarations: [
    SingleFileWidgetComponent,
    SingleFileWidgetSettingsComponent
  ],
  entryComponents: [
    SingleFileWidgetComponent,
    SingleFileWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: SINGLE_FILE_WIDGET, multi: true}
  ],
})

export class SingleFileWidgetModule {
}

import {Inject, Injectable, Optional} from '@angular/core';
import {App} from '@apps/api/app';
import {AppConfig, AppConfigBase, APP_CONFIGS} from '@apps/api/app-config';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {Ng1AppRegistry} from '@root/typings';
import {NG1_APP_REGISTRY} from '@upgrade/upgrade.module';
import * as _ from 'lodash';

/**
 * Service that handles app registration
 */
@Injectable({
  providedIn: 'root'
})
export class AppRegistryService {
  static readonly APP_SENDER_TYPES: string[] = ['page', 'workspace'];
  private readonly apps: { [key: string]: AppConfig<any> };

  constructor(@Inject(NG1_APP_REGISTRY) private appRegistry: Ng1AppRegistry,
              @Optional() @Inject(APP_CONFIGS) appConfigs: AppConfig<AppSettings>[]) {
    if (appConfigs) {
      this.apps = appConfigs.reduce<{ [key: string]: AppConfig<AppSettings> }>((prev, curr: AppConfig<AppSettings>) => {
        prev[curr.key] = curr;
        return prev;
      }, {});
    } else {
      this.apps = {};
    }
  }

  private get allApps(): { [key: string]: AppConfig<AppSettings> } {
    const ng1Apps = this.appRegistry.getNg1();
    return _.merge(this.apps, ng1Apps);
  }

  private static extract(appConfig: AppConfig<AppSettings>): AppConfigBase {
    return appConfig ? _.pick(appConfig, [
      'key',
      'name',
      'description',
      'icon',
      'svgIcon',
      'settings',
      'allowedInstances',
      'allowedInstancesErrorMessage'
    ]) : null;
  }

  /**
   * Returns all apps that have been registered during config phase via
   *
   * @returns {array} Array of all registered apps.
   */
  getAll(): AppConfigBase[] {
    return _.map(this.allApps, AppRegistryService.extract);
  }

  /**
   * Returns an app by key.
   *
   * @param {string} key The app's key to be returned. Must not be empty or undefined.
   * @returns {object} Returns the app with the passed key
   */
  get(key: string): AppConfigBase | undefined {
    return AppRegistryService.extract(this.allApps[key]);
  }

  /**
   * Convenience method which returns the icon class (from the zmdi icon set) of an app.
   *
   * @param {object} app
   * The app to return the icon for.
   *
   * @returns {string} the zmdi class for the icon of the given app.
   */
  getIcon<Settings extends AppSettings>(app: App<Settings>): string {
    return AppRegistryService.extract(this.allApps[app.key]).icon;
  }

  /**
   * Returns the sender types that can have apps.
   *
   * @returns {string[]} The sender types.
   */
  getAppSenderTypes(): string[] {
    return AppRegistryService.APP_SENDER_TYPES;
  }

  /**
   * Returns the name of the default state of an app. This is either a state which has the property 'default' set
   * to 'true' or the root state, which is a state without 'url' and 'name' property.
   *
   * @param {string} appKey The key of the app to return the default state for.
   * @param {string} senderType The sender type which will be part of the state.
   * @returns {string} The absolute name of the default state.
   */
  getDefaultStateName(appKey: string, senderType: string): string {
    const appConfig = this.allApps[appKey];
    if (!appConfig) {
      throw new Error('App with key "' + appKey + '" could not be found');
    }
    const rootState = this.getRootStateName(appKey, senderType);
    const defaultState = appConfig.defaultStateName;
    return defaultState ? rootState + '.' + defaultState : rootState;
  }

  /**
   * Returns the root state name for a given app key and sender type. Note that this method will not check the
   * validity of the given app key or sender type.
   *
   * @param {string} appKey
   * The app key.
   *
   * @param {string} senderType
   * The sender type for the given app.
   *
   * @returns {string} The root state name.
   */
  getRootStateName(appKey: string, senderType: string): string {
    return 'main.' + senderType + '.show.apps.' + appKey;
  }
}

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

/**
 * A modal dialog to select a specific tour step.
 */
@Component({
  selector: 'coyo-tour-dialog',
  templateUrl: './tour-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public topics: string[],
              private dialogRef: MatDialogRef<TourDialogComponent, string>) {
  }

  select(topic: string): void {
    this.dialogRef.close(topic);
  }
}

import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {PresenceStatus} from '@domain/user/presence-status';
import {User} from '@domain/user/user';
import {UserService} from '@domain/user/user.service';
import {Ng1MessagingService} from '@root/typings';
import {NG1_MESSAGING_SERVICE} from '@upgrade/upgrade.module';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';

/**
 * user-avatar-image component
 */
@Component({
  selector: 'coyo-user-avatar-image',
  templateUrl: './user-avatar-image.component.html',
  styleUrls: ['./user-avatar-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarImageComponent implements OnInit {

  /**
   * The user to render the avatar for
   */
  @Input() user: User;

  /**
   * The size of the avatar
   */
  @Input() avatarSize: string;

  /**
   * Opens a modal with a larger size of the image
   */
  @Input() allowModal: boolean;

  /**
   * Can be set to 'true' if a follow button should be shown
   */
  @Input() showFollowButton: boolean;

  /**
   * Can be set to 'true' if a messaging button should be shown
   */
  @Input() showMessagingButton: boolean;

  /**
   * Can be set to explicitly state if the user should be displayed as external
   */
  @Input() showExternalFlag: boolean;

  /**
   * Can be set to 'true' if the user's online status should be displayed
   */
  @Input() showOnlineStatus: boolean;

  presenceStatus$: Observable<PresenceStatus>;
  loadError: boolean;

  constructor(private userService: UserService,
              private authService: AuthService,
              @Inject(NG1_MESSAGING_SERVICE) private messagingService: Ng1MessagingService) { }

  ngOnInit(): void {
    if (this.showExternalFlag === undefined) {
      this.showExternalFlag = this.avatarSize !== 'xs';
    }

    if (this.showOnlineStatus) {
      this.presenceStatus$ = this.authService.getUser().pipe(
        filter(currentUser => {
          const permissionName = currentUser.id === this.user.id ? 'ACCESS_OWN_USER_PROFILE' : 'ACCESS_OTHER_USER_PROFILE';
          return _.includes(currentUser.globalPermissions, permissionName);
        }),
        mergeMap(() => {
          this.showOnlineStatus = true;
          return this.userService.getPresenceStatus$(this.user);
      }));
    }
  }

  /**
   * Starts the new chat
   * @param event the click event necessary to create the new chat
   * @param partnerId The ID of the conversation partener
   * @returns false to prevent default behavior
   */
  startConversation(event: Event, partnerId: string): boolean {
    this.messagingService.start(partnerId);
    event.stopPropagation();
    return false;
  }

  /**
   * Prevents error when avatar-image can't be displayed
   */
  errorHandler(): void {
    this.loadError = true;
  }

}

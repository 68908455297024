import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {UserList} from '@shared/sender-ui/user-list/user-list';
import {WidgetComponent} from '@widgets/api/widget-component';
import {WidgetVisibilityService} from '@widgets/api/widget-visibility/widget-visibility.service';
import {NewColleaguesWidget} from '@widgets/new-colleagues/new-colleagues-widget';
import {Init, LoadMore, Reset} from '@widgets/new-colleagues/new-colleagues-widget/new-colleagues.actions';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

@Component({
  selector: 'coyo-new-colleagues-widget',
  templateUrl: './new-colleagues-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewColleaguesWidgetComponent extends WidgetComponent<NewColleaguesWidget> implements OnInit, OnDestroy {

  state$: Observable<UserList>;
  private id: string;
  constructor(cd: ChangeDetectorRef,
              private widgetVisibilityService: WidgetVisibilityService,
              private store: Store) {
    super(cd);
  }

  ngOnInit(): void {
    this.id = this.widget.id || this.widget.tempId;
    this.state$ = this.store.select(state => state.newColleagues[this.id])
      .pipe(filter(value => value))
      .pipe(tap(res => {
        this.widgetVisibilityService.setHidden(this.widget.id, !res.loading && !res.users.length);
        }));
    this.store.dispatch(new Init(this.id));
  }

  onLoadMore(): void {
    this.store.dispatch(new LoadMore( this.widget.id));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new Reset(this.widget.id));
  }

  getTitle(): string {
    return _.get(this.widget, 'settings._title');
  }
}

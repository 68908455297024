import {registerLocaleData} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {User} from '@domain/user/user';
import {TranslateService} from '@ngx-translate/core';
import {NG1_LOCAL_STORAGE} from '@upgrade/upgrade.module';
import * as moment from 'moment';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {localeMapping} from '../locale-mapping.constant';

/**
 * Service that is used to set the interface language of the Angular part of the application.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private subscription: Subscription;

  constructor(@Inject(NG1_LOCAL_STORAGE) private localStorage: any,
              private translateService: TranslateService,
              private authService: AuthService,
              private http: HttpClient) {
  }

  /**
   * Adds a subscription to the authenticated event and updates the interface language afterwards if the language has
   * changed
   *
   * @returns Observable of the first user language found
   */
  updateUserLanguageOnAuthChange(): Observable<string> {
    const subject = new Subject<string>();
    if (this.subscription && !this.subscription.closed) {
      return;
    }
    this.subscription = this.authService.isAuthenticated$()
      .pipe(switchMap(() => this.authService.getUser()
        .pipe(map((user: User) => user.language.toLowerCase()))
        .pipe(catchError(error => of(this.getDefaultLanguage())))))
      .subscribe((locale: string) => {
        if (this.getActiveLanguage() !== locale) {
          import(
            /* webpackInclude: /(cs|da|de|el|en|es|et|fi|fr|hr|hu|hy|is|it|ja|lv|nl|no|pl|pt|ro|ru|sk|sl|sr|sv|tr|zh)\.js$/ */
            `@angular/common/locales/${locale}.js`
            ).then(module => registerLocaleData(module.default));
          this.setNgXInterfaceLanguage(locale);
        }
        subject.next(locale);
        subject.complete();
    });
    return subject.asObservable();
  }

  /**
   * Sets the interface language of the ngx context of the application.
   *
   * CAUTION: This won't set the interface language in the angularJS part of the app
   *
   * @param lang the language key to set
   * @return A promise resolving when the interface language is set
   */
  setNgXInterfaceLanguage(lang: string): Promise<any> {
    moment.locale(localeMapping[lang] || lang);
    return this.translateService.use(lang).toPromise();
  }

  /**
   * Returns the currently active language.
   *
   * @return The active language
   */
  getActiveLanguage(): string {
    return this.translateService.currentLang;
  }

  /**
   * Gets the default language as a fallback if the user can not be requested for some reason.
   * Asks the local storage value for user language first and if it is not given it tries to get the browser language.
   * As last resort it takes 'en' as default language.
   *
   * @return The default language
   */
  getDefaultLanguage(): string {
    return this.localStorage['userLanguage']
      ? this.localStorage['userLanguage'].toLowerCase()
      : this.getBrowserLanguage();
  }

  /**
   * Gets the system language from the backend.
   * Default to browser language if system language cannot be fetched.
   *
   * @returns Observable containing system language key
   */
  getSystemLanguage(): Observable<string> {
    return this.http.get('/web/languages/public/default')
    .pipe(map((response: any) => {
      if (!response || !response.language) {
        this.getBrowserLanguage();
      }
      return response.language;
    }), catchError(() => this.getBrowserLanguage()));
  }

  getBrowserLanguage(): string {
    const browserLanguage = navigator.languages && navigator.languages.length
      ? navigator.languages[0] : (navigator.language || 'en');
    return browserLanguage.substring(0, 2).toLowerCase();
  }
}

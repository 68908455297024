<div class="form-inline duration-picker">
  <span *ngIf="isShown(unit.MONTHS)" class="duration-picker-unit duration-picker-months">
    <input name="months" type="number" class="form-control" [(ngModel)]="months" #fmonths="ngModel"
           [disabled]="isDisabled" [min]="getMin(unit.MONTHS)" [max]="getMax(unit.MONTHS)">
    <span>{{ i18nPrefix + 'MONTHS' | translate : { months : months } }}</span>
  </span>
  <span *ngIf="isShown(unit.DAYS)" class="duration-picker-unit duration-picker-days">
    <input name="days" type="number" class="form-control" [(ngModel)]="days"
           #fdays="ngModel" [disabled]="isDisabled" [min]="getMin(unit.DAYS)" [max]="getMax(unit.DAYS)">
    <span>{{ i18nPrefix + 'DAYS' | translate : { days : days } }}</span>
  </span>
  <span *ngIf="isShown(unit.HOURS)" class="duration-picker-unit duration-picker-hours">
    <input name="hours" type="number" class="form-control" [(ngModel)]="hours"
           #fhours="ngModel" [disabled]="isDisabled" [min]="getMin(unit.HOURS)" [max]="getMax(unit.HOURS)">
    {{ i18nPrefix + 'HOURS' | translate : { hours : hours } }}
  </span>
  <span *ngIf="isShown(unit.MINUTES)" class="duration-picker-unit duration-picker-minutes">
    <input name="minutes" type="number" class="form-control" [(ngModel)]="minutes"
           #fminutes="ngModel" [disabled]="isDisabled" [min]="getMin(unit.MINUTES)" [max]="getMax(unit.MINUTES)">
    {{ i18nPrefix + 'MINUTES' | translate : { minutes : minutes } }}
  </span>
  <span *ngIf="isShown(unit.SECONDS)" class="duration-picker-unit duration-picker-seconds">
    <input name="seconds" type="number" class="form-control" [(ngModel)]="seconds"
           #fseconds="ngModel" [disabled]="isDisabled" [min]="getMin(unit.SECONDS)" [max]="getMax(unit.SECONDS)">
    {{ i18nPrefix + 'SECONDS' | translate : { seconds : seconds } }}
  </span>
</div>


import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {TranslationService} from './translation-service/translation.service';
import './translation-service/translation.service.downgrade';

/**
 * Module for all i18n tasks.
 *
 * Provides the TranslationService for setting the interface language and is responsible for merging the
 * message files of AngularJs and Angular parts of the Application
 *
 * To define a message file in another module simply provide a 'message' injectable with multi flag set to true which
 * implements the Messages interface.
 *
 * example message file:
 * ```
 * export const messagesDe: Messages = {
 *    lang: 'de',
 *    messages: {
 *      'HELLO.WORLD': 'Hallo Welt'
 *    }
 * };
 * ```
 *
 * example module config:
 * ```
 * @NgModule({
 * ...
 * providers: [
 *   ...
 *   {provide: 'messages', multi: true, useValue: messagesDe},
 *   ...
 * ]
 * })
 * ```
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    UpgradeModule
  ],
  providers: [
    {provide: APP_INITIALIZER, multi: true, useFactory: localeAsyncProvider, deps: [AuthService, TranslationService]}
  ]
})
export class I18nModule {
  constructor() {
  }
}

/**
 * Asynchronous factory that gets the user language and sets the appropriate language.
 * At a later bootstrapping stage this is used to populate the LOCALE_ID.
 *
 * @param authService The auth service
 * @param translationService The translation service
 * @returns A promise that resolves to the user language.
 */
export function localeAsyncProvider(authService: AuthService, translationService: TranslationService): Function {
  return (): Promise<string> => translationService.updateUserLanguageOnAuthChange().toPromise();
}

import {getAngularJSGlobal} from '@angular/upgrade/static';
import {HashtagService} from './hashtag.service';

getAngularJSGlobal()
  .module('commons.ui')
  .filter('hashtags', ['$injector', hashtagsFilter]);

function hashtagsFilter($injector: any): (text: string) => string {
  const hashtagService: HashtagService = $injector.get('ngxHashtagService');
  return text => hashtagService.hashtags(text);
}

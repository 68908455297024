import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AccessToken} from '@app/integration/integration-api/access-token';
import {IntegrationApiService} from '@app/integration/integration-api/integration-api.service';
import {SettingsService} from '@domain/settings/settings.service';
import {from, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {GoogleFileMetaData} from './google-file-metadata';
import PickerBuilder = google.picker.PickerBuilder;

const GOOGLE_API_SCRIPT_LOCATION = 'https://apis.google.com/js/api.js';
const GOOGLE_DRIVE_FILES_ENDPOINT = 'https://www.googleapis.com/drive/v3/files/';

interface Permission {
  kind: string;
  id: string;
  type: string;
  role: string;
  allowFileDiscovery: boolean;
}

interface Permissions {
  permissions: Permission[];
}

/**
 * Service for putting the Google API script tag in the main html page if this feature is enabled.
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {

  static readonly INTEGRATION_TYPE: string = 'G_SUITE';

  private googleDiscoveryDocuments: Map<string, string> = new Map();

  constructor(private settingsService: SettingsService,
              private http: HttpClient,
              private integrationApiService: IntegrationApiService) {
  }

  private isGoogleIntegrationSettingActive(): Observable<boolean> {
    return from(this.settingsService.retrieveByKey('integrationType'))
        .pipe(map(integrationType => integrationType === GoogleApiService.INTEGRATION_TYPE));
  }

  /**
   * Place the script tag in the main page.
   */
  initGoogleApi(): void {
    this.isGoogleIntegrationSettingActive().subscribe(isActive => {
      if (isActive) {
        this.addApiScriptToDom();
      }
    });
  }

  /**
   * Adds the Google API script tag in the head section of the page.
   */
  private addApiScriptToDom(): void {
    if (!document.querySelectorAll(`[src="${GOOGLE_API_SCRIPT_LOCATION}"]`).length) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = GOOGLE_API_SCRIPT_LOCATION;
      document.querySelector('head').appendChild(script);
    }
  }

  /**
   * This method provides the state of the Google integration availability as
   * stream to subscribe.
   *
   * @returns an `Observable` holding the emitted Google integration activation status.
   */
  isGoogleApiActive(): Observable<boolean> {
    return this.integrationApiService.updateAndGetActiveState(GoogleApiService.INTEGRATION_TYPE);
  }

  /**
   * Returns the Bearer token of the current Google session without expiry information
   * in the form:
   *
   * "Bearer: <random token>"
   *
   * @returns Observable resolving the bearer token
   */
  getBearerTokenOnly(): Observable<string> {
    return this.integrationApiService.getToken().pipe(map((token: AccessToken) => `Bearer ${token.token}`));
  }

  /**
   * Performs a request against the Google API while using the given OAuth token.
   *
   * @param api name of the requested API
   * @param version version of the requested API
   * @param path Request URL that should be performed against Google
   * @returns a promise containing the resulting string or an error.
   */
  private doRequest(api: string, version: string, path: string): Promise<JSON> {
    return new Promise((resolve, reject) => {
      this.integrationApiService.getToken().subscribe((accessToken: AccessToken) => {
        gapi.load('client', () => {
          this.loadGoogleDiscoveryDocument(api, version)
            .subscribe(discoveryDocs => {
              gapi.client.setToken({access_token: accessToken.token});
              gapi.client.init({discoveryDocs: [discoveryDocs]})
                .then(() => gapi.client.request({
                  path: path
                })).then((response: any) => {
                resolve(response.result);
              }).catch(error => {
                reject(error);
              });
            }, error => {
              reject(error);
            });
        });
      }, error => {
        reject(error);
      });
    });
  }

  /**
   * Assembles the request url for getting the content of the file with the given file ID. Includes the current bearer
   * token as a query parameter so that no additional Authorization headers are necessary. Sets 'alt=media' so that the
   * resulting url can be processed by pdf.js as pdf source.
   *
   * @param fileId ID of the file
   * @returns Observable resolving the complete request url
   */
  getFileContentRequestUrl(fileId: string): Observable<string> {
    return this.appendAccessTokenParam(GOOGLE_DRIVE_FILES_ENDPOINT + fileId + '?alt=media');
  }

  /**
   * Appends the current bearer token as a query parameter to the given url so that no additional Authorization headers
   * are necessary.
   *
   * @param url the url where the token should be added
   * @returns Observable resolving the extended url
   */

  appendAccessTokenParam(url: string): Observable<string> {
    return this.integrationApiService.getToken().pipe(map((accessToken: AccessToken) => url + '&access_token=' + accessToken.token));
  }

  /**
   * Provides the metadata of a given file.
   *
   * @param fileId ID of the file
   * @param properties Optional properties of type string with default value '*', could be single property: 'webContentLink' or multiple: 'param1,param2,param3'
   * @returns interface for GoogleFileMetaData structure containing the requested structure
   */
  getFileMetadata(fileId: string, properties: string = '*'): Promise<GoogleFileMetaData> {
    return this.doRequest(
      'drive',
      'v3',
      `${GOOGLE_DRIVE_FILES_ENDPOINT}${fileId}?supportsTeamDrives=true&fields=${properties}`) as Promise<GoogleFileMetaData>;
  }

  /**
   * Determines whether a file is public visible.
   *
   * @param fileId Id of the file
   * @returns true if the file is visible, otherwise false
   */
  isFilePublicVisible(fileId: string): Promise<boolean> {
    return this.doRequest('drive', 'v3', GOOGLE_DRIVE_FILES_ENDPOINT + fileId + '/permissions?supportsTeamDrives=true')
      .then((result: any) => {
        const permission = result as Permissions;
        let publicVisible = false;

        permission.permissions.forEach((currentPermission: Permission) => {
          publicVisible = currentPermission.type === 'domain' || currentPermission.type === 'anyone' || publicVisible;
        });
        return publicVisible;
      });
  }

  /**
   * Factory that provides a picker builder.
   *
   * @returns the picker builder
   */
  createPickerBuilder(): PickerBuilder {
    return new google.picker.PickerBuilder();
  }

  /**
   * Provides the Google Discovery Documents (internally cached).
   *
   * Used for the {@link GoogleApiService#doRequest}.
   * {@see https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiclientinitargs}
   *
   * @param api requested API
   * @param version requested API version
   * @returns the requested Discovery Document
   */
  private loadGoogleDiscoveryDocument(api: string, version: string): Observable<string> {
    const googleDiscoveryDocumentKey = this.getGoogleDiscoveryDocumentKey(api, version);

    if (this.googleDiscoveryDocuments.has(googleDiscoveryDocumentKey)) {
      return of(this.googleDiscoveryDocuments.get(googleDiscoveryDocumentKey));
    } else {
      return this.http.get('https://www.googleapis.com/discovery/v1/apis/' + api + '/' + version + '/rest')
        .pipe(tap((discoveryDoc: string) => this.googleDiscoveryDocuments.set(googleDiscoveryDocumentKey, discoveryDoc)));
    }
  }

  /**
   * Creation of a access key for Google Discovery Document cache entries.
   *
   * @param api requested API
   * @param version requested API version
   * @returns the requested access key
   */
  private getGoogleDiscoveryDocumentKey(api: string, version: string): string {
    return api + ':' + version;
  }
}

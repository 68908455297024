import {InjectionToken} from '@angular/core';
import * as _ from 'lodash';

/**
 * A custom injection token for RTE options.
 */
export const RTE_OPTIONS: InjectionToken<RteOptions> = new InjectionToken<RteOptions>('rte-options');

/**
 * Base class for custom RTE options.
 */
export abstract class RteOptions {

  private static readonly TOOLBAR_BUTTONS: object = {
    moreText: {
      buttons: [
        'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize',
        'textColor', 'backgroundColor', 'clearFormatting'],
      buttonsVisible: 3
    },
    moreParagraph: {
      buttons: [
        'alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL',
        'paragraphFormat', 'paragraphStyle', 'outdent', 'indent', 'quote'],
      buttonsVisible: 3
    },
    moreRich: {
      buttons: [
        'insertLink', 'coyoInsertImage', 'coyoInsertAnchor', 'insertTable', 'coyoInsertFile', 'coyoInsertVideo', 'insertHR'],
      buttonsVisible: 2
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'html'],
      buttonsVisible: 0,
      align: 'right'
    }
  };

  /**
   * The default color set used by the RTE component.
   */
  static DEFAULT_COLORS: string[] = [
    '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
    '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
    '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
    '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'];

  /**
   * The default options used by the RTE component.
   */
  static DEFAULT_OPTIONS: { [key: string]: any; } = {
    attribution: false,
    toolbarSticky: false,
    heightMin: 200,
    placeholderText: '',
    tooltips: true,
    pluginsEnabled: [
      'align', 'codeView', 'colors', 'draggable', 'entities', 'file', 'fontSize',
      'fullscreen', 'image', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'quote',
      'paragraphFormat', 'paragraphStyle', 'table', 'url', 'video', 'wordPaste',
      'coyoAlignPlugin', 'coyoImagePlugin', 'coyoVideoPlugin', 'coyoFilePlugin', 'coyoAnchorPlugin'],
    shortcutsHint: true,
    shortcutsEnabled: ['bold', 'italic', 'underline', 'strikeThrough', 'undo', 'redo'],
    fontSize: ['12', '15', '18', '24', '36', '48', '72'],
    colors: RteOptions.DEFAULT_COLORS,
    colorsText: RteOptions.DEFAULT_COLORS,
    colorsHEXInput: false,
    paragraphFormat: {
      H2: 'RTE.FORMAT.H1',
      H3: 'RTE.FORMAT.H2',
      H4: 'RTE.FORMAT.H3',
      H5: 'RTE.FORMAT.H4',
      H6: 'RTE.FORMAT.H5',
      N: 'RTE.FORMAT.NORMAL',
      PRE: 'RTE.FORMAT.CODE'
    },
    paragraphStyles: {
      'rte-text-gray': 'RTE.STYLE.MUTED',
      'rte-text-border': 'RTE.STYLE.BORDERED',
      'rte-text-caps': 'RTE.STYLE.UPPERCASE',
      'rte-text-success': 'RTE.STYLE.SUCCESS',
      'rte-text-info': 'RTE.STYLE.INFO',
      'rte-text-warning': 'RTE.STYLE.WARNING',
      'rte-text-error': 'RTE.STYLE.ERROR'
    },
    paragraphMultipleStyles: false,
    linkConvertEmailAddress: true,
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    linkInsertButtons: [],
    tableStyles: {
      'rte-table-striped': 'RTE.TABLE.STRIPED',
      'rte-table-bordered': 'RTE.TABLE.BORDERED',
      'rte-table-condensed': 'RTE.TABLE.CONDENSED'
    },
    tableColors: RteOptions.DEFAULT_COLORS,
    tableColorsButtons: [],
    tableInsertButtons: [],
    tableEditButtons: [
      'tableCellHorizontalAlign', 'tableCellVerticalAlign', 'coyoTableAlign', '|', 'tableCellBackground', 'tableStyle', '-',
      'tableHeader', 'tableRows', 'tableColumns', 'tableCells', '|', 'tableRemove'],
    tableInsertHelper: false,
    tableResizerOffset: 15,
    lineBreakerOffset: 20,
    fileUpload: false,
    imageAltButtons: [],
    imageDefaultDisplay: 'block',
    imageDefaultWidth: 300,
    imageEditButtons: [
      'coyoImageAlign', 'imageStyle', '|', 'coyoInsertAnchorImage', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-',
      'imageSize', 'imageCaption', 'imageAlt', '|', 'imageRemove'],
    imagePaste: false,
    imageSizeButtons: [],
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'svg'],
    imageStyles: {
      'rte-img-border': 'RTE.IMAGE.BORDERED',
      'rte-img-round': 'RTE.IMAGE.ROUNDED',
      'rte-img-circle': 'RTE.IMAGE.CIRCLE',
      'rte-img-shadow': 'RTE.IMAGE.SHADOW'
    },
    pasteDeniedAttrs: ['id'],
    pasteDeniedTags: ['script'],
    toolbarButtonsXS: _.merge({}, RteOptions.TOOLBAR_BUTTONS, {
      moreText: { buttonsVisible: 2 },
      moreParagraph: { buttonsVisible: 0 },
      moreRich: { buttonsVisible: 0 },
      moreMisc: { buttonsVisible: 0 }
    }),
    toolbarButtonsSM: _.merge({}, RteOptions.TOOLBAR_BUTTONS, {
      moreText: { buttonsVisible: 2 },
      moreParagraph: { buttonsVisible: 2 },
      moreRich: { buttonsVisible: 1 },
      moreMisc: { buttonsVisible: 0 }
    }),
    toolbarButtonsMD: _.merge({}, RteOptions.TOOLBAR_BUTTONS, {
      moreText: { buttonsVisible: 2 },
      moreParagraph: { buttonsVisible: 2 },
      moreRich: { buttonsVisible: 2 },
      moreMisc: { buttonsVisible: 0 }
    }),
    toolbarButtons: RteOptions.TOOLBAR_BUTTONS,
    videoSizeButtons: [],
    videoEditButtons: ['coyoVideoAlign', 'videoSize', 'videoRemove'],
    htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen',
      'allowtransparency', 'alt', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor',
      'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan',
      'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default',
      'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form',
      'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv',
      'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop',
      'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name',
      'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate',
      'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped',
      'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset',
      'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate',
      'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap', 'coyo-download', 'coyo-anchor']
  };

  /**
   * Returns valid Froala RTE options.
   *
   * @see https://www.froala.com/wysiwyg-editor/docs/options
   */
  abstract get(): { [key: string]: any; };
}

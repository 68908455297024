(function (angular) {
  'use strict';

  LanguagesModel.$inject = ["restResourceFactory", "coyoEndpoints"];
  angular
      .module('coyo.domain')
      .factory('LanguagesModel', LanguagesModel);

  /**
   * @ngdoc service
   * @name coyo.domain.LanguagesModel
   *
   * @description
   * Provides the Coyo language model.
   *
   * @requires restResourceFactory
   * @requires coyoEndpoints
   */
  function LanguagesModel(restResourceFactory, coyoEndpoints) {
    var LanguagesModel = restResourceFactory({
      url: coyoEndpoints.languages
    });
    var allLanguagesRequest;

    // class members
    angular.extend(LanguagesModel, {

      /**
       * @ngdoc function
       * @name coyo.domain.LanguagesModel#retrieve
       * @methodOf coyo.domain.LanguagesModel
       *
       * @description
       * Retrieves the list of all active languages from the backend.
       *
       * @returns {promise} An $http promise
       */
      retrieve: function () {
        return LanguagesModel.query();
      },

      /**
       * @ngdoc function
       * @name coyo.domain.LanguagesModel#getAll
       * @methodOf coyo.domain.LanguagesModel
       *
       * @description
       * Retrieves the list of languages from the backend.
       *
       * @returns {promise} An $http promise
       */
      getAll: function () {
        if (!allLanguagesRequest) {
          allLanguagesRequest = LanguagesModel.query({includeInactive: true});
        }
        return allLanguagesRequest;
      },

      getDefaultLanguage: function () {
        return LanguagesModel.get('public/default');
      }
    });

    // instance members
    angular.extend(LanguagesModel.prototype, {
      /**
       * @ngdoc function
       * @name coyo.domain.LanguagesModel#toggleActive
       * @methodOf coyo.domain.LanguagesModel
       *
       * @description
       * Toggles the active state.
       *
       * @returns {promise} An $http promise
       */
      toggleActive: function () {
        return LanguagesModel.$put(this.$url() + '/' + this.language, {active: !this.active});
      }
    });

    return LanguagesModel;
  }

})(angular);

<h1 mat-dialog-title translate="ADMIN.APPS_WIDGETS.PLUGINS.ADD.TITLE"></h1>
<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
<form [formGroup]="pluginForm" (ngSubmit)="submit()">
  <div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label translate="ADMIN.APPS_WIDGETS.PLUGINS.ADD.URL.LABEL"></mat-label>
    <input matInput formControlName="manifestUrl" cdkFocusInitial placeholder="{{'ADMIN.APPS_WIDGETS.PLUGINS.ADD.URL.PLACEHOLDER' | translate}}">
    <mat-hint>{{'ADMIN.APPS_WIDGETS.PLUGINS.ADD.VERSIONS' | translate : {versions: SUPPORTED_MANIFEST_VERSIONS.join(', ')} }}</mat-hint>
    <mat-error *ngIf="pluginUrl.errors?.required" translate="ADMIN.APPS_WIDGETS.PLUGINS.ADD.URL.ERRORS.REQUIRED"></mat-error>
    <mat-error *ngIf="pluginUrl.errors?.pattern" translate="ADMIN.APPS_WIDGETS.PLUGINS.ADD.URL.ERRORS.PATTERN"></mat-error>
  </mat-form-field>
  </div>
  <div mat-dialog-actions class="form-actions">
    <button type="submit" mat-flat-button color="accent" [disabled]="pluginForm.invalid" [loading]="isLoading$ | async">{{'ADMIN.APPS_WIDGETS.PLUGINS.ADD.SUBMIT' | translate}}</button>
  </div>
</form>

<button type="button" mat-icon-button [matMenuTriggerFor]="menu" size="small"
        [color]="allSelectedAttachments?.length ? 'accent' : null"
        [matTooltip]="'ATTACH_FILES' | translate" matTooltipPosition="above">
  <mat-icon svgIcon="paperclip"></mat-icon>
</button>
<mat-menu #menu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition">
  <span mat-menu-title>{{'ATTACH_FILES' | translate}}</span>
  <button type="button" mat-menu-item (click)="fileInput.click()" data-test="open-upload-file-dialog"
          [attr.aria-label]="'MODULE.TIMELINE.ATTACHMENT.UPLOAD.ARIA' | translate">
    <mat-icon svgIcon="upload"></mat-icon>
    <span>{{'MODULE.TIMELINE.ATTACHMENT.UPLOAD' | translate}}</span>
  </button>
  <button type="button" mat-menu-item (click)="openCoyoFileLibrary()" data-test="open-file-library"
          [attr.aria-label]="'MODULE.TIMELINE.ATTACHMENT.FILE_LIBRARY.ARIA' | translate" *ngIf="fileLibraryActivated">
    <mat-icon svgIcon="folder"></mat-icon>
    <span>{{'MODULE.TIMELINE.ATTACHMENT.FILE_LIBRARY' | translate}}</span>
  </button>
  <button type="button" mat-menu-item (click)="openGSuitePicker()" data-test="open-google-suite-picker"
          [attr.aria-label]="'MODULE.TIMELINE.ATTACHMENT.G_SUITE_PICKER.ARIA' | translate" *ngIf="googleApiActivated | async">
    <mat-icon svgIcon="google-drive"></mat-icon>
    <span>{{'MODULE.TIMELINE.ATTACHMENT.G_SUITE_PICKER' | translate}}</span>
  </button>
  <button type="button" mat-menu-item (click)="openSharePointOnlinePicker()" data-test="open-sharepoint-picker"
          [attr.aria-label]="'MODULE.TIMELINE.ATTACHMENT.SHAREPOINT_ONLINE_PICKER.ARIA' | translate" *ngIf="o365ApiActivated | async">
    <mat-icon svgIcon="sharepoint"></mat-icon>
    <span>{{'MODULE.TIMELINE.ATTACHMENT.SHAREPOINT_ONLINE_PICKER' | translate}}</span>
  </button>
  <input style="display: none;" type="file" #fileInput
         ng2FileSelect [uploader]="uploader" multiple (onFileSelected)="uploadFiles()"/>
</mat-menu>

import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {Skeleton} from '@coyo/ui';
import {FileService} from '@domain/file/file/file.service';
import {FilePreviewStatus} from '@shared/preview/file-preview/file-preview-status';
import {ImageSize} from '@shared/preview/file-preview/image-size';
import {PreviewStatusService} from '@shared/preview/file-preview/preview-status/preview-status.service';
import {Observable, Subject} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';

/**
 * Component showing an internal COYO Image.
 * It receives the fileId and senderId of the file and does not check for correct content type.
 * Images stored externally (M365, Google etc.) and blob storage images are not supported.
 */
@Component({
  selector: 'coyo-internal-image',
  templateUrl: './internal-image.component.html',
  styleUrls: ['./internal-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalImageComponent implements OnChanges, OnDestroy {

  /**
   * The id of the file
   */
  @Input() fileId: string;

  /**
   * The id of the owning sender
   */
  @Input() senderId: string;

  /**
   * The preview size that should be loaded
   */
  @Input() size: ImageSize = 'L';

  link$: Observable<string>;

  previewStatus$: Observable<FilePreviewStatus>;

  private previewStatusRequest$: Subject<void> = new Subject<void>();

  skeletons: Skeleton[] = [{
    top: 0, left: 0, width: '100%', height: 200
  }];

  constructor(
    private fileService: FileService,
    private previewStatusService: PreviewStatusService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.link$ = this.fileService.getImagePreviewUrl(
      '/web/senders/{{groupId}}/documents/{{id}}',
      this.senderId,
      {id: this.fileId},
      this.size
    );
    this.previewStatus$ = this.previewStatusRequest$
      .pipe(
        switchMap(() => this.previewStatusService.getPreviewStatus$(
          {id: this.fileId},
          '/web/senders/{{groupId}}/documents/{{id}}',
          this.senderId
          ).pipe(
            startWith({
              isProcessing: true
            })
          )
        ),
        startWith({
          previewAvailable: true
        })
      );
  }

  ngOnDestroy(): void {
    this.previewStatusRequest$.complete();
  }

  onError(): void {
    this.previewStatusRequest$.next();
  }
}

(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.workspaces
   *
   * @description
   * # Workspaces module #
   */
  ModuleConfig.$inject = ["$stateProvider", "workspacesConfig"];
  registerTarget.$inject = ["targetServiceProvider"];
  angular
      .module('coyo.workspaces', [
        'coyo.base',
        'commons.auth',
        'commons.target',
        'commons.ui'
      ])
      .config(ModuleConfig)
      .config(registerTarget)
      .constant('workspacesConfig', {
        templates: {
          list: 'app/modules/workspaces/views/workspaces.list.html',
          create: 'app/modules/workspaces/views/workspaces.create.html',
          show: 'app/modules/workspaces/views/workspaces.show.html',
          settings: 'app/modules/workspaces/views/workspaces.settings.html',
          members: {
            tabs: 'app/modules/workspaces/views/workspaces.show.members.html',
            list: 'app/modules/workspaces/views/workspaces.show.members.list.html',
            invited: 'app/modules/workspaces/views/workspaces.show.members.invited.html',
            requested: 'app/modules/workspaces/views/workspaces.show.members.requested.html'
          }
        },
        list: {
          paging: {
            pageSize: 20
          }
        },
        members: {
          paging: {
            pageSize: 10
          }
        }
      });

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, workspacesConfig) {
    $stateProvider
        .state('main.workspace', {
          url: '/workspaces?:term&:categories[]&:status&:membership:reset',
          templateUrl: workspacesConfig.templates.list,
          controller: 'WorkspacesListController',
          controllerAs: '$ctrl',
          redirectTo: function (transition) {
            return transition.injector().getAsync('currentUser').then(function (currentUser) {
              if (currentUser.externalWorkspaceMember) {
                var Pageable = transition.injector().get('Pageable');
                var WorkspaceModel = transition.injector().get('WorkspaceModel');
                var pageable = new Pageable(0, 10, 'displayName.sort');
                var filters = {membershipStatus: 'APPROVED'};
                return WorkspaceModel.searchWithFilter(undefined, pageable, filters).then(function (result) {
                  if (result.totalElements === 1) {
                    return {state: 'main.workspace.show', params: {idOrSlug: result.content[0].slug}};
                  }
                  return null;
                });
              }
              return null;
            });
          },
          resolve: {
            currentUser: ["authService", function (authService) {
              return authService.getUser();
            }],
            categories: ["WorkspaceCategoryModel", function (WorkspaceCategoryModel) {
              return WorkspaceCategoryModel.get('all');
            }]
          },
          params: {
            'term': {dynamic: true, inherit: false},
            'categories[]': {dynamic: true, inherit: false},
            'status': {dynamic: true, inherit: false},
            'membership': {dynamic: true, inherit: false}
          },
          data: {
            guide: 'workspaces',
            globalPermissions: 'ACCESS_WORKSPACES',
            pageTitle: 'MODULE.WORKSPACES.PAGE_TITLE'
          }
        })
        .state('main.workspace.create', {
          url: '/create',
          views: {
            '@main': {
              templateUrl: workspacesConfig.templates.create,
              controller: 'WorkspacesCreateController',
              controllerAs: '$ctrl'
            }
          },
          resolve: {
            settings: ["SettingsModel", function (SettingsModel) {
              return SettingsModel.retrieve();
            }]
          }
        })
        .state('main.workspace.show', {
          url: '/:idOrSlug',
          views: {
            '@main': {
              templateUrl: workspacesConfig.templates.show,
              controller: 'WorkspacesShowController',
              controllerAs: '$ctrl'
            }
          },
          params: {
            appDeleted: {dynamic: true, inherit: false}
          },
          data: {
            senderParam: 'idOrSlug',
            pageTitle: false
          },
          resolve: {
            workspace: ["WorkspaceModel", "$stateParams", function (WorkspaceModel, $stateParams) {
              return WorkspaceModel.getWithPermissions({id: $stateParams.idOrSlug}, {},
                  ['manage', 'delete', 'manageApps', 'manageSlots', 'canSubscribe', 'createFile', 'inviteExternal',
                    'leave']);
            }],
            senderId: ["workspace", function (workspace) {
              return workspace.id;
            }],
            apps: ["SenderModel", "workspace", function (SenderModel, workspace) {
              return new SenderModel({id: workspace.id}).getApps();
            }]
          },
          onEnter: ["workspace", "$injector", function (workspace, $injector) {
            $injector.get('ngxPageTitleService').setTitle(workspace.displayName);
          }]
        })
        .state('main.workspace.show.settings', {
          url: '/settings',
          views: {
            '@main': {
              templateUrl: workspacesConfig.templates.settings,
              controller: 'WorkspaceSettingsController',
              controllerAs: '$ctrl'
            }
          }
        })
        .state('main.workspace.show.members', {
          url: '/members',
          templateUrl: workspacesConfig.templates.members.tabs,
          controller: 'WorkspaceMembersController',
          controllerAs: '$ctrl',
          redirect: 'main.workspace.show.members.list'
        })
        .state('main.workspace.show.members.list', {
          url: '/list',
          views: {
            '@main.workspace.show.members': {
              templateUrl: workspacesConfig.templates.members.list,
              controller: 'WorkspaceMemberListController',
              controllerAs: '$ctrl'
            }
          }
        })
        .state('main.workspace.show.members.invited', {
          url: '/invited',
          views: {
            '@main.workspace.show.members': {
              templateUrl: workspacesConfig.templates.members.invited,
              controller: 'WorkspaceMemberInvitedController',
              controllerAs: '$ctrl'
            }
          },
          params: {
            showDirectlyAddedHint: false,
            showDirectlyAddedExternalsHint: false
          }
        })
        .state('main.workspace.show.members.requested', {
          url: '/requested',
          views: {
            '@main.workspace.show.members': {
              templateUrl: workspacesConfig.templates.members.requested,
              controller: 'WorkspaceMemberRequestedController',
              controllerAs: '$ctrl'
            }
          }
        });
  }

  function registerTarget(targetServiceProvider) {
    /* register workspace overview */
    targetServiceProvider.register('workspace-list', /*@ngInject*/ ["params", "$state", function (params, $state) {
      var stateParams = _(params)
          .pick(['id', 'term', 'categories', 'status', 'membership'])
          .omitBy(_.isEmpty)
          .extend({reset: true})
          .value();
      return $state.href('main.workspace', stateParams, {inherit: false});
    }]);

    /* register workspace detail view */
    targetServiceProvider.register('workspace', /*@ngInject*/ ["params", "$state", function (params, $state) {
      var stateParams = {idOrSlug: params.slug || params.id};
      return params.go
        ? $state.go('main.workspace.show', stateParams, {reload: 'main.workspace.show'})
        : $state.href('main.workspace.show', stateParams, {reload: 'main.workspace.show'});
    }]);

    /* register workspace requested view */
    targetServiceProvider.register('workspace-requested', /*@ngInject*/ ["params", "$state", function (params, $state) {
      var stateParams = {idOrSlug: params.slug || params.id};
      return $state.href('main.workspace.show.members.requested', stateParams);
    }]);
  }

})(angular);

<div [formGroup]="parentForm">
  <div class="form-group">
    <label class="col-sm-2 control-label" translate="WIDGET.DIVIDER.SETTINGS.TEXT"></label>
    <div class="col-sm-9">
      <input class="form-control" type="text" name="text" id="text" formControlName="text">
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGET.DIVIDER.HELP.TEXT"></coyo-help>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {GoogleFileMetaData} from '@app/integration/gsuite/google-api/google-file-metadata';
import {O365ApiService} from '@app/integration/o365/o365-api/o365-api.service';
import {GSUITE, LOCAL_BLOB, OFFICE365, StorageType} from '@domain/attachment/storage';
import * as _ from 'lodash';
import {map} from 'rxjs/operators';
import {ExternalFileDetails} from './external-file-details';
import {File} from './file';

/**
 * Service that handles external files.
 *
 * Use this service to operate on files which are not stored in the COYO file library
 * but at external services like Google Drive or Office One Drive.
 */
@Injectable({
  providedIn: 'root'
})
export class ExternalFileHandlerService {

  /**
   * All Google media types follow the same pattern: application/vnd.google-apps.<TYPE>
   */
  private googleVendorMediaTypePattern: string = 'application/vnd.google-apps.';

  constructor(private googleApiService: GoogleApiService, private o365ApiService: O365ApiService) {
  }

  /**
   * Returns if the file is an external file (e.g a file that has an other storage type as 'LOCAL_BLOB_STORAGE').
   *
   * @param file the file that should be checked
   * @returns Returns true for external files or false otherwise
   */
  isExternalFile(file: File): boolean {
    // TODO undefined storage could also be an attachment with storage type LOCAL_FILE_LIBRARY
    // should be adjusted in the context of COYOFOUR-9749, COYOFOUR-9754, COYOFOUR-9755,
    const attachmentStorage: StorageType = _.get(file, 'storage', LOCAL_BLOB);
    const externalStorageTypes = [GSUITE, OFFICE365];
    return _.includes(externalStorageTypes, attachmentStorage);
  }

  /**
   * Retrieves file details for the given file. This information can then be used to link
   * to third party services like google drive, office one drive etc.
   *
   * @param file the file to get detailed file information
   * @returns a promise resolving the file details
   */
  getExternalFileDetails(file: File): Promise<ExternalFileDetails> {
    if (this.isGSuiteFile(file)) {
      return this.getGSuiteFileDetails(file);
    }

    if (this.isO365File(file)) {
      return this.getO365FileDetails(file);
    }

    return Promise.reject('Cannot get URLs from external storage.');
  }

  /**
   * Checks whether mediaType matches one of Google's vendor media types.
   *
   * @param mediaType the string value to test against
   * @returns true if mediaType matches Google's mediaType pattern
   */
  isGoogleMediaType(mediaType: string): boolean {
    return !mediaType ? false : mediaType.startsWith(this.googleVendorMediaTypePattern);
  }

  /**
   * Retrieves metadata from Google API and returns them as an instance of ExternalFileDetails.
   *
   * @param file the file to get metadata for
   * @returns a promise resolving the file details
   */
  private getGSuiteFileDetails(file: File): Promise<ExternalFileDetails> {
    return this.googleApiService.getFileMetadata(file.fileId)
      .then((googleFileData: GoogleFileMetaData) => ({
        externalUrl: googleFileData.webViewLink,
        previewUrl: googleFileData.thumbnailLink,
        fileSize: googleFileData.size,
        canEdit: googleFileData.capabilities.canEdit,
        exportLinks: googleFileData.exportLinks
      }));
  }

  private getO365FileDetails(file: File): Promise<ExternalFileDetails> {
    const idParts = file.fileId.split('$$');
    return this.o365ApiService
      .getDriveItem(idParts[0], idParts[1]).pipe(
        map(item => ({
          externalUrl: item.webUrl,
          previewUrl: item.webUrl,
          fileSize: item.size,
          canEdit: false
        }))
      ).toPromise();
  }

  /**
   * Check whether or not a file is stored in G Suite/Google Drive.
   *
   * @param file the file to check storage for
   * @returns whether or not the file is stored at Google
   */
  private isGSuiteFile(file: File): boolean {
    return file.storage === GSUITE;
  }

  private isO365File(file: File): boolean {
    return file.storage === OFFICE365;
  }
}

(function (angular) {
  'use strict';

  DateFieldController.$inject = ["$timeout", "$scope", "$rootScope"];
  angular
      .module('coyo.apps.commons.fields')
      .controller('DateFieldController', DateFieldController);

  function DateFieldController($timeout, $scope, $rootScope) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      if ($rootScope.dateFormat.short) {
        vm.dateFormat = $rootScope.dateFormat.short
            .replace(new RegExp('D', 'g'), 'd')
            .replace(new RegExp('Y', 'g'), 'y');
      } else {
        vm.dateFormat = 'yyyy-MM-dd';
      }
      var value = $scope.$parent.model.value;
      if (value) {
        $scope.$parent.model.value = new Date(value);
      }
    }
  }

})(angular);

import {Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {PageTitleService} from '@shared/notifications/page-title/page-title.service';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {auditTime, filter, switchMap, withLatestFrom} from 'rxjs/operators';
import {PageVisibilityService} from '../page-visibility/page-visibility.service';
import {UserNotificationChannel} from '../user-notification-settings/user-notification-channel.enum';
import {UserNotificationSettingsService} from '../user-notification-settings/user-notification-settings.service';

/**
 * Service to notify the user about changes in the browser tab. This includes
 * updates of the favicon as well as sounds for incoming messages.
 */
@Injectable({
  providedIn: 'root'
})
export class TabNotificationService {
  readonly AUDIT_TIME: number = 250;

  private categories: { [key: string]: number; };
  private sound: HTMLAudioElement;

  private count$: Subject<number>;
  private sound$: Subject<void>;

  constructor(private authService: AuthService,
              private pageTitleService: PageTitleService,
              private pageVisibilityService: PageVisibilityService,
              private userNotificationSettingsService: UserNotificationSettingsService) {
    this.categories = {};
    this.sound = new Audio('assets/sounds/notification.mp3');

    this.count$ = new Subject<number>();
    this.sound$ = new Subject<void>();

    this.count$.asObservable()
      .pipe(auditTime(this.AUDIT_TIME))
      .subscribe(value => this.pageTitleService.setCount(value));
    this.sound$.asObservable()
      .pipe(auditTime(this.AUDIT_TIME))
      .pipe(withLatestFrom(this.pageVisibilityService.isVisible$()))
      .pipe(filter(([, isVisible]) => !isVisible))
      .pipe(switchMap(() => this.userNotificationSettingsService.retrieveByChannel(UserNotificationChannel.Sound)))
      .pipe(filter(settings => settings && settings.active))
      .subscribe(() => this.playSound());
    this.authService.isAuthenticated$()
      .pipe(filter(isAuthenticated => !isAuthenticated))
      .subscribe(() => this.reset());
  }

  /**
   * Returns the current counter for the given category
   * or the sum of all counters if no category is provided.
   *
   * @param category the optional category
   * @returns the counter value
   */
  get(category?: string): number {
    return category
      ? this.categories[category] || 0
      : _.sum(_.values(this.categories));
  }

  /**
   * Sets the counter value for the given category and triggers an
   * update of the favicon.
   *
   * @param category the category
   * @param value the new value for the category
   * @param mute boolean indicator to not play a sound for this update
   */
  set(category: string, value: number, mute: boolean = false): void {
    const prevValue = this.get(category);
    this.categories[category] = value;

    if (value !== prevValue) {
      this.count$.next(this.get());
      if (value > prevValue && !mute) {
        this.sound$.next();
      }
    }
  }

  /**
   * Resets the counter for the given category or for all categories
   * if no category is provided.
   *
   * @param category the optional category
   */
  reset(category?: string): void {
    if (category) {
      this.set(category, 0);
    } else {
      this.categories = {};
      this.count$.next(0);
    }
  }

  private playSound(): void {
    // Ignore errors because the user didn't interact
    // with the document first: https://goo.gl/xX8pDD.
    // Also the user might have not granted required
    // permissions to autoplay sound in Safari.
    this.sound.play().catch(() => {});
  }
}

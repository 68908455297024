import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * Empty list notice component.
 *
 * @description
 * Shows a notice when a list is empty and provides the optional possibility to reset filter settings.
 */
@Component({
  selector: 'coyo-empty-list-notice',
  templateUrl: './empty-list-notice.component.html',
  styleUrls: ['./empty-list-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListNoticeComponent {

  /**
   * Message for the notice to be displayed.
   */
  @Input()
  message: string;

  /**
   * Shows a link button that emits on click if true.
   */
  @Input()
  showResetButton: boolean;

  /**
   * Emits on button click.
   */
  @Output()
  clickEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  click(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.clickEvent.emit();
  }

}

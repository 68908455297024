import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ImageLoaderService} from '@core/image/image-loader.service';
import {FileService} from '@domain/file/file/file.service';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {FilePreviewStatus} from '@shared/preview/file-preview/file-preview-status';
import {PinchEvent} from '@shared/preview/file-preview/image-container/pinch-event';
import {PinchHandler} from '@shared/preview/file-preview/image-container/pinch-handler';
import {SwipeEvent} from '@shared/preview/file-preview/image-container/swipe-event';
import {PreviewStatusService} from '@shared/preview/file-preview/preview-status/preview-status.service';
import {Observable, Subject} from 'rxjs';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {Vec2} from './vec2';

export enum ImageContainerStatus {
  INIT, LOADING, PROCESSING, SUCCESS, ERROR
}

/**
 * This Component handles the preview for pdf and video files.
 *
 * ChangeDetection.onPush has been removed in order to detect changes on the
 * Input "file" even if the reference has not changed.
 */
@Component({
  selector: 'coyo-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss']
})
export class ImageContainerComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * Attached file
   */
  @Input() file: FilePreview;
  /**
   * File preview url
   * e.g. /web/senders/{{groupId}}/documents/{{id}}
   */
  @Input() previewUrl: string;
  /**
   * File groupId
   */
  @Input() groupId: string;
  /**
   * Defines how strongly images resist being pulled beyond the screen limits
   */
  @Input() borderSnappyness: number = 0.35;
  /**
   * Defines how quickly swipe motion continuation dissipates
   */
  @Input() swipeDrag: number = 0.1;
  /**
   * Defined how much an image can be magnified by zooming in (value * original resolution)
   */
  @Input() maxMagnification: number = 3;
  /**
   * Fires when the image loading status changes
   */
  @Output() statusUpdated: EventEmitter<FilePreviewStatus> = new EventEmitter<FilePreviewStatus>();
  /**
   * Fires when the user clicks or taps the image (for full screen usage)
   */
  @Output() released: EventEmitter<Vec2> = new EventEmitter<Vec2>();
  /**
   * Location of the center of the container on the image
   */
  location: Vec2 = new Vec2(0, 0);
  /**
   * Zoom factor (image on screen size = zoom * original image size)
   */
  zoom: number = 1;
  /**
   * Remaining velocity from the last drag/swipe interaction
   */

  @ViewChild('canvasContainer', {static: true}) canvasContainer: ElementRef;
  @ViewChild('canvas', {static: true}) canvas: ElementRef;

  velocity: Vec2 = new Vec2(0, 0);
  private lastStatus: ImageContainerStatus = ImageContainerStatus.INIT;
  private mousePressed: boolean = false;
  private clickPos: Vec2 = new Vec2(0, 0);
  private imgSize: Vec2 = new Vec2(0, 0);
  private pinchHandler: PinchHandler = new PinchHandler();
  private isInteracting: boolean = false;
  private isDestroyed: boolean = false;
  private image: HTMLImageElement;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private fileService: FileService,
              private imageLoaderService: ImageLoaderService,
              private previewStatusService: PreviewStatusService) {
  }

  /**
   * Returns the container HTML element containing the image
   */
  get containerElement(): HTMLDivElement {
    return this.canvasContainer.nativeElement;
  }

  /**
   * Returns the canvas HTML element
   */
  get canvasElement(): HTMLCanvasElement {
    return this.canvas.nativeElement;
  }

  /**
   * Returns the original image dimensions
   */
  get imageSize(): Vec2 {
    return this.imgSize;
  }

  /**
   * Returns the minimum zoom factor which will allow the full image to be visible with the current resolution and aspect ratio
   */
  get minZoom(): number {
    return Math.min(1, Math.min(this.containerElement.clientHeight / this.imgSize.y, this.containerElement.clientWidth / this.imgSize.x));
  }

  /**
   * Returns the size of the container element in pixels
   */
  get canvasSize(): Vec2 {
    return new Vec2(this.containerElement.clientWidth, this.containerElement.clientHeight);
  }

  /**
   * Returns the minimum image space location for panning (top-left)
   */
  get minLocation(): Vec2 {
    return this.maxLocation.scale(-1);
  }

  /**
   * Returns the maximum image space location for panning (bottom-right)
   */
  get maxLocation(): Vec2 {
    return this.imgSize.subtract(this.canvasSize.scaleInverse(this.zoom)).abs.scale(0.5);
  }

  /**
   * Returns the current status of the image loading process
   */
  get status(): ImageContainerStatus {
    return this.lastStatus;
  }

  ngOnInit(): void {
    this.pinchHandler.onPinch(this.pinch.bind(this));
    this.pinchHandler.onSwipe(this.swipe.bind(this));
    this.updateImg(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateImg(true);
    requestAnimationFrame(this.frameLoop.bind(this));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.pinchHandler = null;
    this.isDestroyed = true;
  }

  /**
   * Loads the current image and stores the resolution.
   *
   * @param resetZoom If true, the zoom will be reset to the minimum zoom.
   */
  updateImg(resetZoom?: boolean): void {
    this.updateStatus(ImageContainerStatus.LOADING);
    this.getFullResImageUrl().pipe(
      mergeMap((src: string) => this.imageLoaderService.loadImage(src)),
      takeUntil(this.onDestroy$)).subscribe(image => {
        this.image = image;
        this.imgSize = new Vec2(this.image.width, this.image.height);
        if (resetZoom) {
          this.zoom = Math.min(this.maxMagnification, this.minZoom);
          this.applyTransform();
        }
        this.updateStatus(ImageContainerStatus.SUCCESS);
      },
      () => {
        this.onError();
      });
  }

  /**
   * Triggers on mousedown
   * @param e event parameters
   */
  @HostListener('mousedown', ['$event'])
  mouseDown(e: MouseEvent): void {
    if (e.button === 0) {
      this.isInteracting = true;
      e.preventDefault();
      e.stopPropagation();
      this.clickPos = new Vec2(e.x, e.y);
      this.mousePressed = true;
    }
  }

  /**
   * Triggers on mouseup (window-wide)
   * @param e event parameters
   */
  @HostListener('window:mouseup', ['$event'])
  mouseUp(e: MouseEvent): void {
    if (e.button === 0) {
      this.isInteracting = false;
      e.preventDefault();
      e.stopPropagation();
      const delta = new Vec2(e.x - this.clickPos.x, e.y - this.clickPos.y);
      if (delta.magnitudeSquared < 400) {
        this.released.emit(this.screen2ImageLocation(new Vec2(e.x, e.y)));
      }
      this.mousePressed = false;
    }
  }

  /**
   * Triggers on mousemove (window-wide)
   * @param e event parameters
   */
  @HostListener('window:mousemove', ['$event'])
  mouseMove(e: MouseEvent): void {
    if (this.mousePressed) {
      e.preventDefault();
      e.stopPropagation();
      this.setLocation(this.location.add(new Vec2(e.movementX, e.movementY).scaleInverse(this.zoom)));
    }
  }

  /**
   * Triggers on mousewheel events
   * @param e event parameters
   */
  @HostListener('wheel', ['$event'])
  scroll(e: WheelEvent): void {
    e.preventDefault();
    e.stopPropagation();
    const mouseLocation = new Vec2(
      e.clientX - this.canvasElement.clientLeft,
      e.clientY - this.canvasElement.clientTop);
    const oldLocation = this.screen2ImageLocation(mouseLocation);
    if (e.deltaY < 0) {
      this.zoom *= 1.1;
    } else if (e.deltaY > 0) {
      this.zoom *= 0.9;
    }
    this.zoom = Math.min(this.maxMagnification, Math.max(this.minZoom, this.zoom));
    const newLocation = this.screen2ImageLocation(mouseLocation);
    this.location = this.location.add(newLocation.subtract(oldLocation));
  }

  /**
   * Triggers on touchstart (touch-displays)
   * @param e event parameters
   */
  @HostListener('touchstart', ['$event'])
  touchstart(e: any): void {
    this.isInteracting = true;
    this.pinchHandler.touchstart(e);
  }

  /**
   * Triggers on touchend (window-wide, touch-displays)
   * @param e event parameters
   */
  @HostListener('window:touchend', ['$event'])
  touchend(e: any): void {
    if (e.touches.length < 1) {
      this.isInteracting = false;
    }
    this.pinchHandler.touchend(e);
  }

  /**
   * Triggers on touchmove (window-wide)
   * @param e event parameters
   */
  @HostListener('touchmove', ['$event'])
  touchmove(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.pinchHandler.touchmove(e);
  }

  screen2ImageSpace(p: Vec2): Vec2 {
    return p.subtract(this.canvasSize.scale(0.5)).scaleInverse(this.zoom);
  }

  image2ScreenSpace(p: Vec2): Vec2 {
    return p.scale(this.zoom).add(this.canvasSize.scale(0.5));
  }

  /**
   * Transforms the given screen space coordinate into the image coordinate system
   *
   * @param p A screen space coordinate (relative to the container object)
   *
   * @returns An image space coordinate
   */
  screen2ImageLocation(p: Vec2): Vec2 {
    return this.screen2ImageSpace(p).add(this.imgSize.scale(0.5)).subtract(this.location);
  }

  /**
   * Transforms the given image coordinate into the screen space coordinate system (relative to the container object)
   *
   * @param p An image space coordinate
   *
   * @returns A screen space coordinate
   */
  image2ScreenLocation(p: Vec2): Vec2 {
    return this.image2ScreenSpace(p.add(this.location).subtract(this.imgSize.scale(0.5)));
  }

  /**
   * Sets the current location. If used while there is no user interaction it will cause a swipe continuation effect.
   *
   * @param p A location in the image coordinate system
   */
  setLocation(p: Vec2): void {
    this.velocity = p.subtract(this.location);
    this.location = p;
  }

  private updateStatus(event: ImageContainerStatus): void {
    this.lastStatus = event;
    this.statusUpdated.emit({
      loading: this.status === ImageContainerStatus.LOADING,
      isProcessing: this.status === ImageContainerStatus.PROCESSING,
      previewAvailable: this.status === ImageContainerStatus.SUCCESS,
      conversionError: this.status === ImageContainerStatus.ERROR
    });
  }

  private pinch(e: PinchEvent): void {
    const oldLocation = this.screen2ImageLocation(e.point);
    this.zoom *= e.factor;
    this.zoom = Math.min(this.maxMagnification, Math.max(this.minZoom, this.zoom));
    const newLocation = this.screen2ImageLocation(e.point);
    this.location = this.location.add(newLocation.subtract(oldLocation));
  }

  private swipe(e: SwipeEvent): void {
    this.setLocation(this.location.add(e.delta.scaleInverse(this.zoom)));
  }

  private applyTransform(): void {
    if (this.image && this.image instanceof HTMLImageElement) {
      this.containerElement.scrollTo(0, 0);
      this.canvasElement.width = this.canvasElement.clientWidth;
      this.canvasElement.height = this.canvasElement.clientHeight;
      const context = this.canvasElement.getContext('2d');
      const scaledSize = this.imgSize.scale(this.zoom);
      const imageLocation = this.canvasSize.scale(0.5).add(this.location.subtract(this.imgSize.scale(0.5)).scale(this.zoom));
      context.drawImage(this.image, imageLocation.x, imageLocation.y, scaledSize.x, scaledSize.y);
    }
  }

  private frameLoop(): void {
    if (!this.isDestroyed) {
      if (!this.isInteracting) {
        const min = this.minLocation;
        const max = this.maxLocation;
        const allowedLocation = Vec2.max(min, Vec2.min(max, this.location));
        this.location = this.location.add(this.velocity);
        this.velocity = this.velocity.scale(1 - this.swipeDrag);
        this.location = this.location.scale(1 - this.borderSnappyness).add(allowedLocation.scale(this.borderSnappyness));
      }
      this.applyTransform();
      requestAnimationFrame(this.frameLoop.bind(this));
    }
  }

  private getFullResImageUrl(): Observable<string> {
    const url = this.file.previewUrl || this.previewUrl;
    return this.fileService.getImagePreviewUrl(url, this.groupId, this.file, 'ORIGINAL');
  }

  private onError(): void {
    this.previewStatusService.getPreviewStatus$(this.file, this.previewUrl, this.groupId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(status => {
        this.statusUpdated.emit(status);
        if (status.previewAvailable) {
          this.updateImg(true);
        }
      });
  }
}

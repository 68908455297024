import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {WidgetLayoutSaveBarComponent} from '@widgets/widget-layout-save-bar/widget-layout-save-bar.component';
import './widget-layout-save-bar.component.downgrade';

/**
 * Module providing the widget layout save bar
 */
@NgModule({
  declarations: [WidgetLayoutSaveBarComponent],
  entryComponents: [WidgetLayoutSaveBarComponent],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    HelpModule
  ]
})
export class WidgetLayoutSaveBarModule {
}

import {ConfigurableFocusTrapFactory, FocusTrap} from '@angular/cdk/a11y';
import {OverlayRef} from '@angular/cdk/overlay';
import {Observable, Subject} from 'rxjs';

/**
 * A basic overlay.
 */
export abstract class OverlayComponent<T> {
  private _trap: FocusTrap;
  private _focusElem: HTMLElement | null;
  private _result$: Subject<T> = new Subject<T>();

  protected constructor(protected overlayRef: OverlayRef,
                        private focusTrapFactory: ConfigurableFocusTrapFactory) {
    this.handleFocus();
    this.handleKeydown();
  }

  get result$(): Observable<T> {
    return this._result$.asObservable();
  }

  setLastFocusedElement(elem: HTMLElement): void {
    this._focusElem = elem;
  }

  /**
   * Closes the overlay and returns a value.
   *
   * @param result the returned result
   */
  close(result: T): void {
    this._result$.next(result);
    this.dispose();
  }

  /**
   * Closes the overlay without returning a value.
   */
  dispose(): void {
    this.overlayRef.dispose();
    this._result$.complete();
    this.restoreFocus();
  }

  private handleFocus(): Promise<boolean> {
    const element = this.overlayRef.overlayElement;
    this._trap = this.focusTrapFactory.create(element);
    return this._trap.focusInitialElementWhenReady();
  }

  private handleKeydown(): void {
    this.overlayRef.keydownEvents().subscribe($event => {
      switch ($event.code || $event.keyCode) { // tslint:disable-line:deprecation
        case 27:
        case 'Escape':
          const close: any = '';
          this.close(close);
      }
    });
  }

  private restoreFocus(): void {
    // We need the extra check, because IE can set the `activeElement` to null in some cases.
    if (!this._focusElem || this._focusElem.offsetParent === null) {
      // focusable element is not visible anymore...
    } else if (typeof this._focusElem.focus === 'function') {
      this._focusElem.focus();
    }
  }
}

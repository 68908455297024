import {Directive, Input, OnDestroy, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Dialog} from '@domain/modal/dialog';
import {Widget} from '@domain/widget/widget';
import {UIRouter} from '@uirouter/angular';
import {WidgetSettingsModalComponent} from '@widgets/api/widget-settings-modal/widget-settings-modal.component';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import * as _ from 'lodash';
import {Observable, of, Subject} from 'rxjs';
import {WidgetConfig} from './widget-config';
import {WidgetSettingsContainerComponent} from './widget-settings-container/widget-settings-container.component';

/**
 * A Modal object for a widgets
 */
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class WidgetModal extends Dialog<{ config: WidgetConfig<Widget<WidgetSettings>>; settings: any; }> implements OnDestroy {

  widgetForm: FormGroup;
  widget: Widget<WidgetSettings>;

  // ngx
  /**
   * The configuration of the widget
   */
  @Input() config: WidgetConfig<Widget<WidgetSettings>>;
  onSubmitSubject: Subject<WidgetSettings> = new Subject<WidgetSettings>();

  /**
   * The widget title
   */
  @Input() title?: string;

  @ViewChild(WidgetSettingsContainerComponent)
  settingsContainer: WidgetSettingsContainerComponent;

  constructor(
    public dialogRef: MatDialogRef<WidgetSettingsModalComponent>,
              uiRouter: UIRouter) {
    super(dialogRef, uiRouter);
    this.rebuildForm();
  }

  /**
   * Resets the form
   */
  rebuildForm(): void {
    this.widgetForm = new FormGroup({});
  }

  /**
   * Submits the form
   *
   * @param settings
   * The newly defined settings of the widget
   */
  submit(settings?: any): void {
    if (this.widgetForm.invalid) {
      return;
    }

    this.transformSettingsBeforeSave(settings).subscribe(transformedSettings => {
      // ngx save callback
      this.onSubmitSubject.next(transformedSettings);

      // legacy ng1 save callback
      const allSettings = _.extend(this.widget.settings, transformedSettings);

      this.modal.close({
        config: this.config,
        settings: allSettings
      });
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onSubmitSubject.complete();
  }

  private transformSettingsBeforeSave(settings: any): Observable<any> {
    let result: Observable<any>;
    if (this.settingsContainer && this.settingsContainer.widgetSettingsComponent) {
      result = this.settingsContainer.widgetSettingsComponent.onBeforeSave(settings);
    }
    if (!result) {
      result = of(settings);
    }
    return result;
  }
}

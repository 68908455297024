(function (angular) {
  'use strict';

  DetectedExternalRoleController.$inject = ["$scope"];
  angular
      .module('coyo.admin.userManagement')
      .directive('coyoExternalRoleWarning', detectedExternalRole)
      .controller('DetectedExternalRoleController', DetectedExternalRoleController);

  /**
   * @ngdoc directive
   * @name coyo.admin.userManagement.coyoExternalRoleWarning:coyoExternalRoleWarning
   * @scope
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Renders a bootstrap warning alert, if list of roles includes the EXTERNAL_WS role.
   *
   */
  function detectedExternalRole() {
    return {
      restrict: 'E',
      templateUrl: 'app/modules/admin/user-mgmt/components/user-and-group-detected-external-role/external-role-warning.html',
      scope: {
        roles: '<',
        superadmin: '<'
      },
      controller: 'DetectedExternalRoleController',
      controllerAs: '$ctrl'
    };
  }

  function DetectedExternalRoleController($scope) {
    var vm = this;
    vm.roles = $scope.roles;
    vm.superadmin = $scope.superadmin;
    vm.detectExternalRole = detectExternalRole;

    /**
     * This method search in vm.roles on the External Workspace Member role.
     * In success case: the variable isExternalWorkspaceMemberIncluded will set on true
     * In unsuccess case: the variable isExternalWorkspaceMemberIncluded will set on false
     */
    function detectExternalRole() {
      var roles = vm.roles;

      // Precondition: roles can not be null oder undefined!
      if (!roles) {
        vm.isExternalWorkspaceMemberIncluded = false;
        vm.isExternalSuperadmin = false;
        return;
      }

      var foundExternal = false;
      roles.forEach(function (currentElement) {
        if (currentElement.systemRoleName === 'EXTERNAL_WS') {
          foundExternal = true;
          return;
        }
      });

      vm.isExternalWorkspaceMemberIncluded = foundExternal;
      vm.isExternalSuperadmin = vm.superadmin && foundExternal;
    }

    /**
     * This method will executed by initialize from this controller
     */
    (function _init() {
      $scope.$watchGroup(['roles', 'superadmin'], function () {
        vm.roles = $scope.roles;
        vm.superadmin = $scope.superadmin;
        vm.detectExternalRole();
      }, true);
    })();

  }
})(angular);

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {SenderRootFilePickerItem} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item';
import {FilePickerData} from '@app/file-picker/file-picker-data';
import {FilePickerSelectionMode} from '@app/file-picker/file-picker-selection-mode';
import {InitSelection} from '@app/file-picker/file-picker-selection/file-picker-selection.actions';
import {FileService} from '@domain/file/file/file.service';
import {Sender} from '@domain/sender/sender';
import {Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'coyo-file-library-content',
  templateUrl: './file-library-content.component.html',
  styleUrls: ['./file-library-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryContentComponent implements OnInit {

  /**
   * Sender input resolved in the state config
   */
  @Input() sender: Sender;

  /**
   * The title shown in the header
   */
  @Input() title: string;

  /**
   * Flag to show authors
   */
  @Input() showAuthors?: boolean;

  /**
   * The id of the app if this file library content is shown in a documents app
   */
  @Input() appId?: string;

  /**
   * The root folder id, if not set the senders root folder is shown
   */
  @Input() rootFolderId?: string;

  rootFile$: Observable<FilePickerData>;

  constructor(private fileService: FileService, private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new InitSelection('multiple', [], null));
    this.rootFile$ = this.createRootFile()
      .pipe(map(file => ({
          showAuthors: this.showAuthors,
          appId: this.appId,
          rootFolder: file,
          selectionMode: FilePickerSelectionMode.DOWNLOAD,
          selectionType: 'multiple'
        })
      ));
  }

  private createRootFile(): Observable<FileLibraryFilePickerItem | SenderRootFilePickerItem> {
    if (!this.rootFolderId) {
      return of(new SenderRootFilePickerItem(this.sender));
    }
    return this.fileService.getFile(this.rootFolderId, this.sender.id, ['createFile', 'manage'])
      .pipe(map(file => new FileLibraryFilePickerItem({
        ...file,
        name: 'APP.FILE_LIBRARY.BREADCRUMB.START'
      })));
  }
}

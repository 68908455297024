import {HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';
import * as _ from 'lodash';

/**
 * Wrapper class for search requests to the backend
 */
export class SearchRequest {

  constructor(private term: string = '',
              private searchFields: string[] = [],
              private filter: { [key: string]: string | string[] } = {},
              private aggregations: { [key: string]: string | string[] } = {}) {

  }

  /**
   * Creates http params from the search request.
   * Following fields are added to the params object: term, filters, aggregations and searchFields.
   *
   * @return A HttpParams object of the searchRequest
   */
  toHttpParams(): HttpParams {
    return new HttpParams({
      fromObject: {
        term: this.term,
        filters: this.getQueryString(this.filter),
        aggregations: this.getQueryString(this.aggregations),
        searchFields: this.searchFields.join(',')
      },
      encoder: {
        encodeKey(key: string): string {
          return encodeURIComponent(key);
        },
        encodeValue(value: string): string {
          return encodeURIComponent(value);
        },
        decodeKey(key: string): string {
          return decodeURIComponent(key);
        },
        decodeValue(value: string): string {
          return decodeURIComponent(value);
        }
      } as HttpUrlEncodingCodec
    });
  }

  private getQueryString(object: { [key: string]: string | string[] }): string {
    return _.keys(object).map(key => {
      const value = object[key];
      if (value instanceof Array) {
        return value.map(elem => key + '=' + elem).join('&');
      } else {
        return key + '=' + value;
      }
    }).join('&');
  }
}

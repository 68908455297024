import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProfileInfo} from './profileInfo.model';

@Injectable({
  providedIn: 'root'
})
export class CompleteProfileWidgetService {

  constructor(private http: HttpClient) { }

  getCompleteProfileTasksValue(): Observable<ProfileInfo> {
    return this.http.get<ProfileInfo>('/web/widgets/complete-profile');
  }
}

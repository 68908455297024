import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {VideoWidgetSettingsComponent} from './video-widget-settings/video-widget-settings.component';
import {VIDEO_WIDGET} from './video-widget.config';
import {VideoWidgetComponent} from './video-widget/video-widget.component';

/**
 * Module providing the video widget
 */
 @NgModule({
   declarations: [VideoWidgetComponent, VideoWidgetSettingsComponent],
   entryComponents: [VideoWidgetComponent, VideoWidgetSettingsComponent],
   imports: [
     CoyoCommonsModule,
     CoyoFormsModule,
     HelpModule,
     SenderUIModule
   ],
   providers: [
     {provide: WIDGET_CONFIGS, useValue: VIDEO_WIDGET, multi: true}
   ]
 })
 export class VideoWidgetModule { }

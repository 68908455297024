(function (angular) {
  'use strict';

  angular
      .module('coyo.account')
      .controller('AccountMainController', AccountMainController);

  /**
   * Controller for the account view
   */
  function AccountMainController() {
  }

})(angular);

import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@core/auth/auth.service';
import {UserService} from '@domain/user/user.service';
import {WINDOW} from '@root/injection-tokens';
import {Observable} from 'rxjs';
import {Language} from './language';
import {SettingsLanguageService} from './settings-language.service';

/**
 * A form element to change the current user's language.
 */
@Component({
  selector: 'coyo-settings-language',
  templateUrl: './settings-language.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsLanguageComponent implements OnInit {

  languages$: Observable<Language[]>;
  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private settingsLanguageService: SettingsLanguageService,
              @Inject(WINDOW) private window: Window) {
  }

  ngOnInit(): void {
    this.languages$ = this.settingsLanguageService.getLanguages();

    this.form = this.formBuilder.group({language: ['', [Validators.required]]});
    this.authService.getUser().subscribe(user =>
      this.form.patchValue({language: user.language}));
  }

  /**
   * Submits the language value when changed.
   */
  submit(): void {
    this.setDisabled(true);
    const language = this.form.get('language').value;
    this.userService.setLanguage(language).subscribe(
      () => this.window.location.reload(),
      () => this.setDisabled(false));
  }

  private setDisabled(disabled: boolean): void {
    return disabled
      ? this.form.controls.language.disable()
      : this.form.controls.language.enable();
  }
}

(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name commons.auth
   *
   * @description
   * # Authentication module #
   * The authentication module contains authentication-related services and global angular interceptors.
   *
   * @requires $log
   * @requires eventSocket
   * @requires commons.auth.authService
   * @requires commons.browsernotifications.browserNotificationsService
   */
  registerEventListeners.$inject = ["$rootScope", "$log", "authService", "socketService", "browserNotificationsService"];
  notifyApp.$inject = ["authService", "mobileEventsService"];
  angular
      .module('commons.auth', [
        'coyo.base',
        'commons.config',
        'commons.error',
        'commons.resource',
        'commons.sockets',
        'commons.browsernotifications',
        'commons.mobile',
        'commons.rxjs',
        'coyo.domain'
      ])
      .constant('authConfig', {
        routes: {
          login: '/login',
          logout: '/logout',
          i18n: '/i18n'
        }
      })
      .run(registerEventListeners)
      .run(notifyApp);

  function registerEventListeners($rootScope, $log, authService, socketService, browserNotificationsService) {
    $log.debug('[commons.auth::run::registerEventListeners] Registering event listeners...');

    // subscribe to updates of the current user
    authService.subscribeToUserUpdate();

    // subscribe on login
    $rootScope.$on('authService:login:success', function () {
      _subscribeToPersonalTimeline($rootScope, $log, authService, socketService, browserNotificationsService);
      _subscribeToNotificationSettingChanges($rootScope, $log, authService, socketService, browserNotificationsService);
    });

    // initially subscribe if already logged in
    _subscribeToNotificationSettingChanges($rootScope, $log, authService, socketService, browserNotificationsService);
    _subscribeToPersonalTimeline($rootScope, $log, authService, socketService, browserNotificationsService);

    $rootScope.$on('socketService:reconnected', function () {
      authService.getUser(true);
    });
  }

  function notifyApp(authService, mobileEventsService) {
    if (authService.isAuthenticated()) {
      mobileEventsService.propagate('authService:login:success');
    }
  }

  function _subscribeToPersonalTimeline($rootScope, $log, authService, socketService, browserNotificationsService) {
    // subscribe to updates of the user's personal timeline
    authService.getUser().then(function (currentUser) {
      if (currentUser.hasGlobalPermissions('ACCESS_PERSONAL_TIMELINE')) {
        var unsubscribeItemCreated = socketService.subscribe('/user/topic/timeline.item.created', function (event) {
          $log.debug('[commons.auth::run] Received a personal timeline \'item created\' event:', event);
          browserNotificationsService.notifyPost(event);
        });
        var unsubscribeItemShare = socketService.subscribe('/user/topic/timeline.item.shared', function (event) {
          $log.debug('[commons.auth::run] Received a personal timeline \'item shared\' event:', event);
          browserNotificationsService.notifyPost(event);
        });
        $rootScope.$on('authService:logout:success', unsubscribeItemCreated);
        $rootScope.$on('authService:logout:success', unsubscribeItemShare);
        $rootScope.$on('authService:logout:failed', unsubscribeItemCreated);
        $rootScope.$on('authService:logout:failed', unsubscribeItemShare);
      }
    });
  }

  function _subscribeToNotificationSettingChanges($rootScope, $log, authService, socketService,
                                                  browserNotificationsService) {
    authService.getUser().then(function () {
      var unsubscribeNotificationFn = socketService.subscribe('/user/topic/notification.settings.changed',
          function (event) {
            $log.debug('[commons.auth::run] Received a \'notification settings updated\' event:', event);
            browserNotificationsService.getNotificationSettings(true);
          });
      $rootScope.$on('authService:logout:success', unsubscribeNotificationFn);
    });
  }

})(angular);

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {EventSettingsParticipantsDialogData} from '@app/events/event-settings/event-settings-participants-dialog-data';
import {UserChooserSelection} from '@shared/sender-ui/user-chooser/user-chooser-selection';

@Component({
  selector: 'coyo-event-settings-select-admins-modal',
  templateUrl: './event-settings-select-admins-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventSettingsSelectAdminsModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: EventSettingsParticipantsDialogData,
              private dialogRef: MatDialogRef<EventSettingsSelectAdminsModalComponent>) {
  }

  submit(selection: UserChooserSelection): void {
    this.close(selection);
  }

  close(selection?: UserChooserSelection): void {
    this.dialogRef.close(selection);
  }

}

<ng-container *ngxPermissionsOnly="['LIKE']">
  <!-- Unfortunately, we have to duplicate the button due to different directives. -->
  <ng-container *ngIf="(likeState$ | async) as likeState">
    <button mat-button mat-link-button type="button" size="small" (click)="toggle()" data-test="like-button" role="switch"
            *ngIf="textual"
            [color]="likeState.isLikedByCurrentSender ? 'accent' : null"
            [disabled]="likeState.isLoading"
            [attr.aria-checked]="likeState.isLikedByCurrentSender">{{'ENTITY_TYPE.LIKE' | translate}}</button>
    <button mat-button type="button" size="small" (click)="toggle()" data-test="like-button" role="switch"
            *ngIf="!textual"
            [color]="likeState.isLikedByCurrentSender ? 'accent' : null"
            [disabled]="likeState.isLoading"
            [attr.aria-checked]="likeState.isLikedByCurrentSender"><mat-icon svgIcon="thumbs-up"></mat-icon>{{'ENTITY_TYPE.LIKE' | translate}}</button>
  </ng-container>
</ng-container>

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {AuthService} from '@core/auth/auth.service';
import {Observable, zip} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {RteSettings} from './rte-settings';

/**
 * Service for retrieving the RTE settings.
 */
@Injectable({
  providedIn: 'root'
})
export class RteSettingsService {
  private settings$: Observable<RteSettings>;

  constructor(private http: HttpClient,
              private authService: AuthService,
              private googleApiService: GoogleApiService) {
  }

  /**
   * The base URL for the RTE settings request.
   *
   * @return the base URL
   */
  protected getBaseUrl(): string {
    return '/web/rte-settings';
  }

  /**
   * Gets the RTE settings. After the first backend request is done the settings are cached.
   *
   * @param force if `true` it forces refetching
   * @return Observable holding the RTE settings
   */
  getSettings(force?: boolean): Observable<RteSettings> {
    if (!this.settings$ || force) {
      this.settings$ = zip(
        this.authService.getUser(),
        this.googleApiService.isGoogleApiActive(),
        this.http.get<{ license: string }>(this.getBaseUrl())
      ).pipe(map(([user, isGoogleApiActive, settings]) => ({
        license: settings.license,
        canAccessFiles: user.globalPermissions.includes('ACCESS_FILES'),
        canAccessGoogle: isGoogleApiActive
      }))).pipe(shareReplay({bufferSize: 1, refCount: false}));
    }
    return this.settings$;
  }
}

import {Inject, Injectable, NgZone} from '@angular/core';
import {Ng1SocketService} from '@root/typings';
import {attachToZone} from '@upgrade/attach-to-zone';
import {NG1_SOCKET_SERVICE} from '@upgrade/upgrade.module';
import {Observable, Subscriber} from 'rxjs';

/**
 * Service that provides web socket capabilities.
 */
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private ngZone: NgZone, @Inject(NG1_SOCKET_SERVICE) private ng1SocketService: Ng1SocketService) {
  }

  /**
   * Listens to a given websocket topic.
   *
   * @param destination    the topic to listen to
   * @param eventFilter    an optional filter that filters events
   * @param selectorValue  selectorValue that is added to filter messages in the backend
   * @param jwtToken an optional jwtToken for permission check in Backend
   * @return an observable that emits when new websocket messages arrive
   */
  listenTo$(destination: string, eventFilter?: string | object | Function, selectorValue?: string, jwtToken?: string): Observable<any> {
    return attachToZone(this.ngZone, new Observable((subscriber: Subscriber<any>) => {
      const unsubscribeSubscription = this.ng1SocketService.subscribe(destination, (data: any) => {
        subscriber.next(data);
      }, eventFilter, selectorValue, jwtToken);
      return () => {
        unsubscribeSubscription();
      };
    }));
  }

  /**
   * Listens to websocket offline events.
   *
   * @return An observable that emits every time when the websocket went offline
   */
  listenToOffline$(): Observable<void> {
    return attachToZone(this.ngZone, this.ng1SocketService.webSocketOffline$);
  }

  /**
   * Listens to websocket disconnects.
   *
   * @return An observable that emits every time when the websocket was disconnected
   */
  listenToDisconnect$(): Observable<[Event, boolean]> {
    return this.ng1SocketService.webSocketDisconnected$;
  }

  /**
   * Listens to websocket reconnects.
   *
   * @return An observable that emits every time when the websocket finished reconnection
   */
  listenToReconnect$(): Observable<void> {
    return this.ng1SocketService.webSocketReconnected$;
  }
}

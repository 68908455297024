import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {BrowserService} from '@core/browser/browser.service';
import {UrlService} from '@core/http/url/url.service';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {WINDOW} from '@root/injection-tokens';
import {FilePreviewTypeService} from '@shared/preview/file-preview/file-preview-type/file-preview-type.service';
import {PreviewType} from '@shared/preview/file-preview/file-preview-type/preview.type';
import {ImageSize} from '@shared/preview/file-preview/image-size';
import {FilePreviewOptions, FilePreviewSpinnerOptions} from './file-preview-options';
import {FilePreviewStatus} from './file-preview-status';

/**
 * This Component handles the preview for pdf and video files.
 *
 * ChangeDetection.onPush has been removed in order to detect changes on the
 * Input "file" even if the reference has not changed.
 */
@Component({
  selector: 'coyo-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnDestroy, OnChanges {
  static readonly TIMEOUT_FOR_EXTERNAL_FILE_LOADING: number = 60000;

  /**
   * Attached file
   */
  @Input() file: FilePreview;
  /**
   * File preview url
   */
  @Input() url: string;
  /**
   * File groupId
   */
  @Input() groupId: string;
  /**
   * File preview size
   */
  @Input() size: string = 'lg';
  /**
   * File options
   */
  @Input() options: FilePreviewOptions;
  /**
   * Enable fullscreen pan/zoom view on click
   */
  @Input() interactiveImageView: boolean = false;
  /**
   * If true, the file preview animation (coyo-file-preview-generating-animation) will be displayed while loading the preview.
   * Otherwise the default spinner (coyo-spinner) will be shown.
   */
  @Input() usePreviewGeneratingAnimation: boolean = false;
  /**
   * Configuration for the loading spinner
   */
  @Input() spinnerOptions: FilePreviewSpinnerOptions;
  /**
   * Emits the file id, when preview of image-preview cannot be processed
   */
  @Output() cannotProcess: EventEmitter<string> = new EventEmitter<string>();

  previewType: PreviewType | null = null;
  loading: boolean = true;
  conversionFailed: boolean = false;
  previewIsGenerating: boolean = false;
  pdfSrc: string;
  fullResImageOverlayIsOpen: boolean = false;
  externalFileLoadingTimeout: number;

  /**
   * Defines the translation from preview sizes (bootstrap style) to available image sizes in backend.
   */
  readonly imageSizes: { [key: string]: { sd: ImageSize, retina: ImageSize } } = {
    xs: {sd: 'XS', retina: 'S'},
    sm: {sd: 'S', retina: 'M'},
    md: {sd: 'M', retina: 'L'},
    lg: {sd: 'L', retina: 'XL'},
    xl: {sd: 'XL', retina: 'XXL'},
    original: {sd: '', retina: ''}
  };

  constructor(private readonly windowSizeService: WindowSizeService,
              private readonly urlService: UrlService,
              private readonly googleApiService: GoogleApiService,
              private readonly filePreviewTypeService: FilePreviewTypeService,
              private readonly browserService: BrowserService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              @Inject(WINDOW) private readonly windowService: Window) {
  }

  ngOnDestroy(): void {
    if (this.externalFileLoadingTimeout) {
      this.windowService.clearTimeout(this.externalFileLoadingTimeout);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentFile = changes.file && changes.file.currentValue;
    if (!currentFile) {
      return;
    }
    this.reset();
    this.filePreviewTypeService.determinePreviewType(currentFile).subscribe(previewType => {
      this.previewType = previewType;
      switch (this.previewType) {
        case PreviewType.PDF:
          this.handlePdfFile();
          break;
        case PreviewType.GSUITE_AS_PDF_EXPORTABLE_DOCUMENT:
          this.handleGSuiteExportAsPdf(currentFile);
          break;
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  /**
   * Check if a loading spinner should be displayed
   *
   * @returns true or false
   */
  showSpinner(): boolean {
    return this.showLoading() && !this.usePreviewGeneratingAnimation;
  }

  /**
   * @returns true when the preview generation animation should be shown
   */
  showPreviewAnimation(): boolean {
    return this.showLoading() && this.usePreviewGeneratingAnimation;
  }

  /**
   * Check if the preview type is still being determined
   *
   * @returns true or false
   */
  isDeterminingPreviewType(): boolean {
    return this.previewType === null;
  }

  /**
   * Check if it is possible to show a preview for the current file
   *
   * @returns true or false
   */
  isPreviewAvailable(): boolean {
    return !this.isDeterminingPreviewType() && this.previewType !== PreviewType.NO_PREVIEW;
  }

  /**
   * Check if preview is being generated
   *
   * @returns true or false
   */
  isPreviewIsProcessing(): boolean {
    return this.isPreviewAvailable() && this.previewIsGenerating && !this.previewError();
  }

  /**
   * Check if preview conversion failed
   *
   * @returns true or false
   */
  previewError(): boolean {
    return !!this.file && this.isPreviewAvailable() && this.conversionFailed;
  }

  /**
   * Check if the preview generation information should be displayed
   *
   * @returns true or false
   */
  showPreviewGenerationInformation(): boolean {
    return !this.options.hidePreviewGenerationInformation;
  }

  /**
   * Updates component status variables
   *
   * @param status FilePreviewStatus interface
   */
  onStatusUpdated(status: FilePreviewStatus): void {
    this.loading = status.loading;
    this.previewIsGenerating = status.isProcessing;
    this.conversionFailed = status.conversionError;
    if (this.options.hidePreviewGenerationInformation && status.conversionError) {
      this.cannotProcess.emit(this.file.id);
    }
  }

  /**
   * Check if the image preview should be used
   *
   * @returns true or false
   */
  useImagePreview(): boolean {
    return !!this.file
      && (this.isImageFile() || this.showPdfCoverImageOnly())
      && !this.useFullResImageOverlay();
  }

  /**
   * Returns the image preview size that should be used
   *
   * @returns size The image size
   */
  getImagePreviewSize(): ImageSize {
    const size = this.imageSizes[this.size] || this.imageSizes.lg;
    return this.windowSizeService.isRetina() ? size.retina : size.sd;
  }

  /**
   * Check if the interactive full resolution image view should be used.
   *
   * @returns true or false
   */
  useFullResImageOverlay(): boolean {
    return !!this.file
      && this.interactiveImageView
      && this.isFullResImageOverlayAvailable()
      && this.isDesktop()
      && !this.browserService.isInternetExplorer();
  }

  /**
   * Check if the interactive full resolution image view is available for the current file
   *
   * @returns true or false
   */
  isFullResImageOverlayAvailable(): boolean {
    return this.isImageFile()
      && this.isInternalFile()
      && this.file.contentType !== 'image/gif'
      && !this.previewError();
  }

  /**
   * Opens a full resolution overlay, if it's mobile
   */
  openFullResImageOverlayOnMobile(): void {
    if (this.interactiveImageView && this.isFullResImageOverlayAvailable() && this.isMobile()) {
      this.fullResImageOverlayIsOpen = true;
    }
  }

  /**
   * Closes the full resolution overlay
   */
  closeFullResImageOverlay(): void {
    this.fullResImageOverlayIsOpen = false;
  }

  /**
   * Check if the video preview should be used
   *
   * @returns true or false
   */
  useVideoPreview(): boolean {
    return !!this.file
      && this.isVideoFile()
      && this.isPreviewAvailable();
  }

  /**
   * Check if the pdf preview should be used
   *
   * @returns true or false
   */
  usePdfPreview(): boolean {
    return !!this.file
      && this.isPdfPreviewPossible()
      && !this.showPdfCoverImageOnly();
  }

  /**
   * Gets triggered when the pdf viewer component has finished loading the pdf.
   */
  onPdfLoadingCompleted(): void {
    this.loading = false;
  }

  /**
   * Gets triggered when the pdf viewer component could not load the pdf.
   */
  onPdfLoadingFailed(): void {
    this.setPreviewUnavailable();
  }

  private reset(): void {
    this.closeFullResImageOverlay();
    this.previewType = null;
    this.conversionFailed = false;
    this.loading = false;
  }

  private showLoading(): boolean {
    return this.loading || this.isPreviewIsProcessing();
  }

  private setPreviewUnavailable(): void {
    this.loading = false;
    this.previewType = PreviewType.NO_PREVIEW;
  }

  private isImageFile(): boolean {
    return [PreviewType.IMAGE, PreviewType.GSUITE_IMAGE].includes(this.previewType);
  }

  private isVideoFile(): boolean {
    return [PreviewType.VIDEO, PreviewType.GSUITE_VIDEO].includes(this.previewType);
  }

  private showPdfCoverImageOnly(): boolean {
    if (!this.isPdfPreviewPossible()) {
      return false;
    }

    if (this.previewType === PreviewType.GSUITE_PDF) {
      return true;
    }

    return (this.isMobile() && !this.options.showPdfMobile)
      || (this.isDesktop() && !this.options.showPdfDesktop);
  }

  private isPdfPreviewPossible(): boolean {
    return this.isPreviewAvailable()
      && this.isPdfFile();
  }

  private isPdfFile(): boolean {
    return [PreviewType.PDF, PreviewType.GSUITE_PDF, PreviewType.GSUITE_AS_PDF_EXPORTABLE_DOCUMENT].includes(this.previewType);
  }

  private handlePdfFile(): void {
    if (!this.showPdfCoverImageOnly()) {
      this.loading = true; // loading state will be reset by onPdfLoadingCompleted() or onPdfLoadingFailed()
    }
    this.pdfSrc = undefined;
    this.pdfSrc = this.createPdfUrl(this.url, this.groupId, this.file.id, this.file.modified);
  }

  private createPdfUrl(url: string, groupId: string, fileId: string, modified: Date): string {
    const baseUrl = this.urlService.getBackendUrl()
      + this.urlService.insertPathVariablesIntoUrl(url, {groupId, id: fileId});
    const _modified = modified ? ('modified=' + modified) : '';
    const _amp = modified && (this.file.contentType !== 'application/pdf') ? '&' : '';
    const _format = (this.file.contentType !== 'application/pdf') ? ('format=application/pdf') : '';
    return baseUrl + (baseUrl.indexOf('?') < 0 ? '?' : '&') + _format + _amp + _modified;
  }

  private handleGSuiteExportAsPdf(file: FilePreview): void {
    if (!this.showPdfCoverImageOnly()) {
      this.loading = true; // loading state will be reset by onPdfLoadingCompleted() or onPdfLoadingFailed()
    }
    this.pdfSrc = undefined;
    this.startExternalFileTimeout();
    this.googleApiService
      .appendAccessTokenParam(file.exportLinks['application/pdf'])
      .subscribe({
        next: url => this.pdfSrc = url,
        error: () => this.setPreviewUnavailable()
      });
  }

  private startExternalFileTimeout(): void {
    this.externalFileLoadingTimeout = this.windowService.setTimeout(() => {
      if (this.loading && !this.isPreviewAvailable()) {
        this.setPreviewUnavailable();
      }
    }, FilePreviewComponent.TIMEOUT_FOR_EXTERNAL_FILE_LOADING);
  }

  private isMobile(): boolean {
    return this.windowSizeService.isXs() || this.windowSizeService.isSm();
  }

  private isDesktop(): boolean {
    return !this.isMobile();
  }

  private isInternalFile(): boolean {
    return this.filePreviewTypeService.isInternalFile(this.file);
  }
}

(function (angular) {
  'use strict';

  birthdayService.$inject = ["moment", "$translate"];
  angular
      .module('commons.ui')
      .factory('birthdayService', birthdayService);

  /**
   * @ngdoc service
   * @name commons.ui.birthdayService
   *
   * @description
   * Service to handle birthday data.
   *
   * @requires moment
   */
  function birthdayService(moment, $translate) {

    var YEARS_IN_PAST = 100,
        DATE_PATTERN = /^((\d{4})-)?(\d{2})-(\d{2})$/,
        REFERENCE_LEAP_YEAR = 2016,
        DEFAULT_VALUE = -1,
        DEFAULT_OPTION = {
          value: DEFAULT_VALUE,
          label: '--'
        };

    return {
      parseDate: parseDate,
      formatDate: formatDate,
      createDays: createDays,
      createMonths: createMonths,
      createYears: createYears,
      isDateEmpty: isDateEmpty,
      hasYear: hasYear,
      getReferenceLeapYear: getReferenceLeapYear,
      getDefaultValue: getDefaultValue,
      isFieldValue: isFieldValue,
      birthdayStringToDateString: birthdayStringToDateString
    };

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#parseDate
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Parses a given date string into an object with properties year, month and date
     *
     * @param {string} dateString The date string to parse.
     *
     * @returns {object} An object with the parsed date properties.
     */
    function parseDate(dateString) {
      var dateObject = {
            year: DEFAULT_VALUE,
            month: DEFAULT_VALUE,
            day: DEFAULT_VALUE
          },
          result = DATE_PATTERN.exec(dateString);
      if (result) {
        dateObject.year = initFieldValue(result[2]);
        var month = initFieldValue(result[3]);
        dateObject.month = initMonthValue(month);
        dateObject.day = parseInt(result[4]);
      }
      return dateObject;
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#formatDate
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Formats a date string from given date parameters.
     *
     * @param {int} year A year.
     * @param {int} month A month.
     * @param {int} day A day.
     *
     * @returns {string} The formatted date string.
     */
    function formatDate(year, month, day) {
      if (isDateEmpty(year, month, day)) {
        return '';
      }
      var formattedYear = isFieldValue(year) ? year + '-' : '',
          formattedMonth = isFieldValue(month) ? formatWithLeadingZero(month + 1) : '',
          formattedDay = isFieldValue(day) ? formatWithLeadingZero(day) : '';
      return formattedYear + formattedMonth + '-' + formattedDay;
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#createDays
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Provides an array of ascending day nummbers up to the given amount.
     *
     * @param {int} amount Amount of days to create.
     *
     * @returns {array} The array of ascending day numbers.
     */
    function createDays(amount) {
      var days = [DEFAULT_OPTION];
      for (var i = 0; i < amount; i++) {
        var value = i + 1;
        days.push({
          value: value,
          label: value
        });
      }
      return days;
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#createMonths
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Provides an array of ascending month objects containing an id and a label.
     *
     * @returns {array} The array of ascending month objects.
     */
    function createMonths() {
      var months = [DEFAULT_OPTION],
          labels = moment.monthsShort();
      for (var i = 0; i < 12; i++) {
        months.push({
          value: i,
          label: labels[i]
        });
      }
      return months;
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#createYears
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Provides an array of descending year numbers up to a defined amount in the past.
     *
     * @returns {array} The array of descending year numbers.
     */
    function createYears() {
      var years = [DEFAULT_OPTION];
      var currentYear = new Date().getFullYear();
      for (var i = currentYear; i >= currentYear - YEARS_IN_PAST; i--) {
        years.push({
          value: i,
          label: i
        });
      }
      return years;
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#isDateEmpty
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Provides information whether the given date values result in an empty date.
     *
     * @param {int} year A year.
     * @param {int} month A month.
     * @param {int} day A day.
     *
     * @returns {boolean} The information whether the combined date values result in an empty date.
     */
    function isDateEmpty(year, month, day) {
      return !isFieldValue(year) && !isFieldValue(month) && !isFieldValue(day);
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#hasYear
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Provides information about the given dateString containing a year portion.
     *
     * @param {int} dateString The date string to check.
     *
     * @returns {boolean} The information whether the date string contains a year.
     */
    function hasYear(dateString) {
      return dateString.length > 5;
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#getReferenceLeapYear
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Provides the defined reference leap year.
     *
     * @returns {int} The leap year.
     */
    function getReferenceLeapYear() {
      return REFERENCE_LEAP_YEAR;
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#getDefaultValue
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Provides the defined default value for a date portion.
     *
     * @returns {int} The default value.
     */
    function getDefaultValue() {
      return DEFAULT_VALUE;
    }

    /**
     * @ngdoc function
     * @name commons.ui.birthdayService#isFieldValue
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Checks whether the given field value has a viable value.
     *
     * @returns {boolean} Is a field value or not.
     */
    function isFieldValue(fieldValue) {
      return fieldValue !== DEFAULT_VALUE;
    }

    function initFieldValue(result) {
      var fieldValue = parseInt(result);
      return !isNaN(fieldValue) ? fieldValue : DEFAULT_VALUE;
    }

    function initMonthValue(month) {
      return isFieldValue(month) ? month - 1 : month;
    }

    function formatWithLeadingZero(number) {
      return (number < 10 ? '0' : '') + number;
    }

    /**
     * @ngdoc method
     * @name commons.ui.birthdayService#birthdayStringToDateString
     * @methodOf commons.ui.birthdayService
     *
     * @description
     * Creates a readable date string out of a given birthday property
     *
     * @param {string} birthdayString - A date string with or without a year (e.g. '08-08' or '1990-01-02')
     * @returns {string} A human readable date string translated to the user language.
     */
    function birthdayStringToDateString(birthdayString) {
      if (hasYear(birthdayString)) {
        return moment(birthdayString).format($translate.instant('DATE_FORMAT_MEDIUM'));
      } else {
        birthdayString = '1900-' + birthdayString;
        return moment(birthdayString).format($translate.instant('DATE_FORMAT_MEDIUM_WITHOUT_YEAR'));
      }
    }
  }

})(angular);

(function (angular) {
  'use strict';

  LicenseWarningBarController.$inject = ["$rootScope", "$scope", "$localStorage", "authService", "ngxLicenseService"];
  angular
      .module('commons.ui')
      .component('oyocLicenseWarningBar', licenseWarningBar())
      .controller('LicenseWarningBarController', LicenseWarningBarController);

  /**
   * @ngdoc directive
   * @name commons.ui.oyocLicenseWarningBar:oyocLicenseWarningBar
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Renders the warning bar when license is invalid.
   *
   * @requires $rootScope
   * @requires $scope
   * @requires $localStorage
   * @requires ngxLicenseService
   */
  function licenseWarningBar() {
    return {
      templateUrl: 'app/commons/ui/components/license-warning/license-warning.html',
      bindings: {
        area: '<'
      },
      controller: 'LicenseWarningBarController'
    };
  }

  function LicenseWarningBarController($rootScope, $scope, $localStorage, authService, ngxLicenseService) {
    var vm = this;

    vm.$onInit = _init;
    vm.invalid = false;
    vm.classes = {};

    function _updateLicense(license) {
      vm.invalid = license.status !== 'VALID';
      vm.target = 'admin.license.' + (license.environment === 'enterprise' ? 'license' : 'subscription');
    }

    function _checkLicense() {
      $scope.$watch(function () {
        return _.get($localStorage, 'messagingSidebar.compact', true);
      }, function (newVal) {
        vm.classes.compact = newVal;
      });

      ngxLicenseService.isLicenseValid().toPromise().then(function (valid) {
        vm.status = 'LICENSE.INVALID.WARNING';
        vm.invalid = !valid;
      });
    }

    function _init() {
      if (vm.area === 'admin') {
        authService.getUser().then(function (user) {
          if (user.hasGlobalPermissions('MANAGE_LICENSE')) {
            vm.classes.admin = true;
            vm.status = 'ADMIN.LICENSE.INVALID.WARNING';
            ngxLicenseService.getLicense().toPromise().then(_updateLicense);
            var subscription = ngxLicenseService.getLicenseUpdates$().subscribe(function (license) {
              _updateLicense(license);
            });
            $scope.$on('$destroy', function () { subscription.unsubscribe(); });
          } else {
            _checkLicense();
          }
        });
      } else {
        _checkLicense();
      }
    }
  }

})(angular);

import {Injectable} from '@angular/core';
import {BlogAppSettings} from '@apps/blog/blog-app-settings';
import {WikiAppSettings} from '@apps/wiki/wiki-app-settings';
import {AppService} from '@domain/apps/app.service';
import {BlogArticleService} from '@domain/blog-article/blog-article.service';
import {SocialPermissions} from '@domain/permissions/social-permissions';
import {ShareableType} from '@domain/share/shareable-type';
import {SharedArticle} from '@domain/share/sharedArticle';
import {TimelineItem} from '@domain/timeline-item/timeline-item';
import {TimelineItemTarget} from '@domain/timeline-item/timeline-item-target';
import {WikiArticleService} from '@domain/wiki-article/wiki-article.service';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map} from 'rxjs/operators';

export const ARTICLE_NOT_TARGET_NOT_FOUND = 'NO_ARTICLE_TARGET_FOUND';

/**
 * Service for all request methods to the timeline item domain.
 */
@Injectable({
  providedIn: 'root'
})
export class TimelineItemTargetService {

  constructor(private appService: AppService,
              private blogArticleService: BlogArticleService,
              private wikiArticleService: WikiArticleService) {
  }

  /**
   * Determines the target of a timeline item.
   * @param item The timeline item
   * @returns an observable that emits a timeline item target
   */
  determineTarget(item: TimelineItem): Observable<TimelineItemTarget> {
    switch (item.itemType) {
      case 'blog':
        return this.determineArticleTarget(item);
      case 'wiki':
        return this.determineArticleTarget(item);
      default: // sender
        return this.determineSenderTarget(item);
    }
  }

  private determineSenderTarget(item: TimelineItem): Observable<TimelineItemTarget> {
    const commentsShown = item._permissions.comment && !item.restricted;
    const likesShown = item._permissions.like && !item.restricted;
    const commentsAndLikesNotAllowed = !commentsShown && !likesShown;

    return of({
      id: item.id,
      itemId: item.id,
      author: item.author,
      created: item.created,
      modified: item.modified,
      shares: item.shares,
      parentPublic: item.parentPublic,
      itemType: item.itemType,
      typeName: this.getTypeName(item.itemType),
      _permissions: item._permissions,
      socialPermissions: {
        commentsShown: commentsShown,
        commentsAndLikesNotAllowed: commentsAndLikesNotAllowed,
        likesShown: likesShown
      },
      subscriptionInfo: item.subscriptionInfo
    });
  }

  private determineArticleTarget(item: TimelineItem): Observable<TimelineItemTarget> {
    const article: SharedArticle = (item.data as any).article;
    if (!article || !article.app) {
      return throwError(ARTICLE_NOT_TARGET_NOT_FOUND);
    }
    const requestContext = {
      context: {
        senderId: article.app.senderId,
        appId: article.app.id
      },
      permissions: ['*'],
      handleErrors: false
    };
    const app$ = this.appService.get(article.app.id, requestContext);
    const article$ = article.typeName === 'blog-article'
      ? this.blogArticleService.get(article.id, requestContext)
      : this.wikiArticleService.get(article.id, requestContext);

    return forkJoin([app$, article$]).pipe(map(results => {
      const settings = results[0].settings as (BlogAppSettings | WikiAppSettings);
      const commentsShown = settings.commentsAllowed;
      const likesShown = item.itemType === 'blog' || commentsShown;

      const permissions: SocialPermissions = {
        commentsShown: commentsShown,
        likesShown: likesShown,
        commentsAndLikesNotAllowed: !commentsShown && !likesShown
      };

      const target: TimelineItemTarget = {
        id: article.id,
        itemId: item.id,
        created: article.created,
        modified: article.modified,
        author: article.author,
        shares: item.shares,
        parentPublic: item.parentPublic,
        itemType: item.itemType,
        senderId: article.app.senderId,
        typeName: this.getTypeName(item.itemType),
        _permissions: results[1]._permissions,
        socialPermissions: permissions,
        subscriptionInfo: results[1].subscriptionInfo
      };
      return target;
    }));
  }

  private getTypeName(itemType: string): ShareableType {
    if (itemType === 'blog') {
      return 'blog-article';
    } else if (itemType === 'wiki') {
      return 'wiki-article';
    }
    return 'timeline-item';
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {SUBSCRIPTION_WIDGET} from '@widgets/subscription/subscription-widget-config';
import {SubscriptionWidgetComponent} from './subscription-widget/subscription-widget.component';

/**
 * Module providing the subscription widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    SubscriptionWidgetComponent
  ],
  entryComponents: [
    SubscriptionWidgetComponent
  ],
  providers: [{
    provide: WIDGET_CONFIGS,
    useValue: SUBSCRIPTION_WIDGET, multi: true
  }]
})
export class SubscriptionWidgetModule {
}

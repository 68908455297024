<button mat-icon-button type="button" size="small"
        [matTooltip]="'EMOJI.LABEL' | translate"
        matTooltipPosition="above"
        [popper]="emojiPickerTemplate"
        [popperHideOnClickOutside]="true"
        [popperDisableStyle]="true"
        [popperModifiers]="modifiers"
        popperTrigger="click"
        popperPlacement="bottom"
        popperApplyClass="emoji-picker-overlay"
        #popper="popper">
  <mat-icon svgIcon="smile"></mat-icon>
</button>
<popper-content #emojiPickerTemplate>
  <emoji-mart [emoji]=""
              [isNative]="true"
              [showSearch]="false"
              [showPreview]="false"
              [perLine]="6"
              [totalFrequentLines]="5"
              [exclude]="['objects', 'symbols', 'custom']"
              [title]=""
              [i18n]="i18n$ | async"
              [showSingleCategory]="true"
              (emojiSelect)="emojiSelect($event)">
  </emoji-mart>
</popper-content>

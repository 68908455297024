import {Inject, Injectable, NgZone} from '@angular/core';
import {Sender} from '@domain/sender/sender';
import {User} from '@domain/user/user';
import {Ng1SublineService} from '@root/typings';
import {attachToZone} from '@upgrade/attach-to-zone';
import {NG1_SUBLINE_SERVICE} from '@upgrade/upgrade.module';
import {from, Observable} from 'rxjs';

/**
 * Service to retrieve descriptive sublines for sender entities.
 */
@Injectable({
  providedIn: 'root'
})
export class SublineService {

  constructor(private ngZone: NgZone,
              @Inject(NG1_SUBLINE_SERVICE) private sublineService: Ng1SublineService) {}

  /**
   * Returns a descriptive subline for the given user. The subline is composed of
   * the configured user profile field values joined by '·'.
   *
   * @param user the user to retrieve the subline for
   * @returns an `Observable` holding the subline for the user
   */
  getSublineForUser(user: User): Observable<string> {
    const observable = from(this.sublineService.getSublineForUser(user));
    return attachToZone(this.ngZone, observable);
  }

  /**
   * Returns a descriptive subline for the given sender. In most cases, the subline
   * is the translated type name of the sender.
   *
   * @param sender the sender to retrieve the subline for
   * @returns an `Observable` holding the subline for the sender
   */
  getSublineForSender(sender: Sender): Observable<string> {
    const observable = from(this.sublineService.getSublineForSender(sender));
    return attachToZone(this.ngZone, observable);
  }
}

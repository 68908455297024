import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Report} from '@domain/reports/report';
import {TargetService} from '@domain/sender/target/target.service';

/**
 * Displays a single item in a list of reports.
 */
@Component({
  selector: 'coyo-report-item',
  templateUrl: './report-item.component.html',
  styleUrls: ['./report-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportItemComponent implements OnInit {

  /**
   * The report to display.
   */
  @Input() report: Report;

  /**
   * Emits when the report should be resolved.
   */
  @Output() resolve: EventEmitter<Report> = new EventEmitter<Report>();

  link: string;

  constructor(private targetService: TargetService) {
  }

  ngOnInit(): void {
    this.link = this.targetService.getLinkTo(this.report.target.target);
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TourModule} from '@app/tour/tour.module';
import {TranslateModule} from '@ngx-translate/core';
import {EventDateOverlayComponent} from '@shared/event-meta-data/event-date-overlay/event-date-overlay.component';
import {EventDatePipe} from '@shared/event-meta-data/event-date/event-date.pipe';
import {EventLocationComponent} from '@shared/event-meta-data/event-location/event-location.component';
import {EventParticipationComponent} from '@shared/event-meta-data/event-participation/event-participation.component';
import {EventTimePipe} from '@shared/event-meta-data/event-time/event-time.pipe';
import {HasEndedPipe} from '@shared/event-meta-data/has-ended/has-ended.pipe';
import {IsOngoingPipe} from '@shared/event-meta-data/is-ongoing/is-ongoing.pipe';
import {MaterialModule} from '@shared/material/material.module';
import {MomentModule} from 'ngx-moment';

/**
 * Shared module containing all components responsible for displaying event meta data like date, time and location.
 */
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MomentModule,
    TourModule,
    TranslateModule
  ],
  declarations: [
    EventDateOverlayComponent,
    EventDatePipe,
    EventLocationComponent,
    EventParticipationComponent,
    EventTimePipe,
    HasEndedPipe,
    IsOngoingPipe
  ],
  exports: [
    EventDateOverlayComponent,
    EventDatePipe,
    EventLocationComponent,
    EventParticipationComponent,
    EventTimePipe,
    HasEndedPipe,
    IsOngoingPipe
  ]
})
export class EventMetaDataModule { }

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {SubscriptionWidget} from '@widgets/subscription/subscription-widget';
import {SubscriptionWidgetComponent} from './subscription-widget/subscription-widget.component';

export const SUBSCRIPTION_WIDGET: WidgetConfig<SubscriptionWidget> = {
  key: 'subscriptions',
  name: 'WIDGET.SUBSCRIPTIONS.NAME',
  description: 'WIDGET.SUBSCRIPTIONS.DESCRIPTION',
  icon: 'zmdi-notifications',
  component: SubscriptionWidgetComponent,
  categories: WidgetCategory.PERSONAL,
  renderOptions: {
    printable: false
  },
  whitelistExternal: true
};

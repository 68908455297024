(function (angular) {
  'use strict';

  angular
      .module('commons.sockets')
      .constant('socketConfig', {
        /* Whether or not the service should attempt to connect immediately upon instantiation. */
        autoConnect: true,
        /* The STOMP heat-beating interval in milliseconds. */
        heartbeat: 30000
      });

})(angular);

import {A11yModule} from '@angular/cdk/a11y';
import {OverlayModule} from '@angular/cdk/overlay';
import {NgModule} from '@angular/core';
import {LaunchpadLinkIconComponent} from '@app/launchpad/launchpad-link-icon/launchpad-link-icon.component';
import {LaunchpadLinkManagerComponent} from '@app/launchpad/launchpad-link-manager/launchpad-link-manager.component';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {FileUploadModule} from 'ng2-file-upload';
import {LaunchpadAppsComponent} from './launchpad-apps/launchpad-apps.component';
import {LaunchpadCategoryColorPipe} from './launchpad-category-color/launchpad-category-color.pipe';
import {LaunchpadCategoryComponent} from './launchpad-category/launchpad-category.component';
import {LaunchpadHeaderComponent} from './launchpad-header/launchpad-header.component';
import {LaunchpadLinkIconSrcPipe} from './launchpad-link-icon-src/launchpad-link-icon-src.pipe';
import {LaunchpadLinkComponent} from './launchpad-link/launchpad-link.component';
import {LaunchpadNavigationComponent} from './launchpad-navigation/launchpad-navigation.component';
import {LaunchpadComponent} from './launchpad/launchpad.component';
import {LaunchpadService} from './launchpad/launchpad.service';
import './launchpad/launchpad.service.downgrade';

/**
 * The Launchpad module
 */
@NgModule({
  declarations: [
    LaunchpadAppsComponent,
    LaunchpadCategoryColorPipe,
    LaunchpadCategoryComponent,
    LaunchpadComponent,
    LaunchpadHeaderComponent,
    LaunchpadLinkComponent,
    LaunchpadLinkIconComponent,
    LaunchpadLinkIconSrcPipe,
    LaunchpadLinkManagerComponent,
    LaunchpadNavigationComponent
  ],
  imports: [
    A11yModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    FileUploadModule,
    OverlayModule
  ],
  entryComponents: [
    LaunchpadComponent,
    LaunchpadLinkManagerComponent
  ],
  providers: [
    LaunchpadService,
    LaunchpadLinkIconSrcPipe
  ]
})
export class LaunchpadModule {
}

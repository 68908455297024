<div *ngIf="{isMobile: isMobile$ | async} as windowSize" class="filter-box">
  <!-- mobile view -->
  <ng-container *ngIf="windowSize.isMobile">
    <ng-container *ngTemplateOutlet="mobileView"></ng-container>
  </ng-container>
  <!-- compact desktop view -->
  <ng-container *ngIf="!windowSize.isMobile && !vertical">
    <ng-container *ngTemplateOutlet="compactDesktopView"></ng-container>
  </ng-container>
  <!-- full size desktop view -->
  <ng-container *ngIf="!windowSize.isMobile && vertical">
    <ng-container *ngTemplateOutlet="desktopView"></ng-container>
  </ng-container>
</div>

<ng-template #mobileView>
  <div class="panel panel-default filter-box-mobile">
    <div *ngIf="!hideFilter" [ngClass]="{active: filterActive}" class="mobile-menu vertical">
      <button (click)="openFilterOverlay(filterContent)" mat-icon-button type="button">
        <mat-icon svgIcon="filter"></mat-icon>
      </button>
    </div>
    <div *ngIf="!hideSearch" class="mobile-search">
      <ng-container *ngTemplateOutlet="searchContent"></ng-container>
    </div>
    <div class="mobile-count vertical">
      <ng-container *ngTemplateOutlet="countContent"></ng-container>
    </div>
    <div *ngIf="!hideActions" class="mobile-actions vertical">
      <button (click)="openActionsOverlay(actionsContent)" *ngIf="!hideActionsOnMobile" mat-icon-button type="button">
        <mat-icon svgIcon="more-horizontal"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #compactDesktopView>
  <div class="panel panel-default filter-box-compact">
    <div *ngIf="!hideFilter" [ngClass]="{active: filterActive}" class="mobile-menu vertical">
      <button [matMenuTriggerFor]="menu" mat-icon-button type="button">
        <mat-icon svgIcon="filter"></mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="filter-box-compact-filter-menu">
        <ng-container *ngTemplateOutlet="filterContent"></ng-container>
      </mat-menu>
    </div>
    <div *ngIf="!hideSearch" class="mobile-search">
      <ng-container *ngTemplateOutlet="searchContent"></ng-container>
    </div>
    <div class="mobile-count vertical">
      <ng-container *ngTemplateOutlet="countContent"></ng-container>
    </div>
    <div *ngIf="!hideActions" class="mobile-actions vertical">
      <ng-container *ngTemplateOutlet="actionsContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #desktopView>
  <ng-container *ngIf="!hideActions">
    <ng-container *ngTemplateOutlet="actionsContent"></ng-container>
  </ng-container>
  <div class="panel panel-default filter-box-desktop">
    <div *ngIf="!hideSearch" class="desktop-search">
      <ng-container *ngTemplateOutlet="searchContent"></ng-container>
    </div>
    <div *ngIf="vertical && !hideFilter">
      <ng-container *ngTemplateOutlet="filterContent"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="countContent"></ng-container>
  </div>
</ng-template>

<!--
multiple ng-content's of the same type are not allowed in one template (even with ngIf...),
but with ng-template we can deal with that type of issue
-->
<ng-template #filterContent><ng-content select="fb-filter"></ng-content></ng-template>
<ng-template #actionsContent><ng-content select="fb-actions"></ng-content></ng-template>
<ng-template #searchContent><ng-content select="fb-search"></ng-content></ng-template>
<ng-template #countContent><ng-content select="fb-count"></ng-content></ng-template>

import {NgModule} from '@angular/core';
import {EngageWidgetLayoutRouterModule} from '@app/engage/engage-widget-layout/engage-widget-layout-router.module';
import {engageState, engageStateInit} from '@app/engage/engage.state';
import {NewsModule} from '@app/engage/news/news.module';
import {TermsModule} from '@app/engage/terms/terms.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import './mobile-request-handler-registry/mobile-request-handler-registry.service.downgrade';

const uiRouterModuleChild = UIRouterUpgradeModule
  .forChild({states: [engageState, engageStateInit]});

/**
 * A module providing all relevant services for the COYO mobile app.
 */
@NgModule({
  imports: [
    NewsModule,
    TermsModule,
    uiRouterModuleChild,
    EngageWidgetLayoutRouterModule
  ],
  exports: [
    NewsModule,
    TermsModule
  ]
})
export class EngageModule {
}

/**
 * Service for loading images with the correct orientation
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Image loader service that is reponsible to load an image. Mainly purpose is ensuring the testability of clients.
 */
export class ImageLoaderService {
  constructor() {

  }

  /**
   * Loads an image and guarantees that the image has been loaded when the observable completes
   * @param source The url of the image to be loaded
   * @returns an observable HTMLImageElement
   */
  loadImage(source: string): Observable<HTMLImageElement> {
    const image = new Image();
    return new Observable(subscriber => {
      image.onload = () => subscriber.next(image);
      image.onerror = err => subscriber.error(err);
      image.src = source;
    });
  }
}

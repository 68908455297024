(function (angular, Config) {
  'use strict';

  angular
      .module('commons.sockets')
      /**
       * Allow configuring delays for reloading data on reconnect in customizing projects
       */
      .constant('socketReconnectDelays', {
        GENERAL_RECONNECT_DELAY: _.random(Math.max(_.get(Config, 'minReconnectDelaySeconds', 10), 1),
            Math.max(_.get(Config, 'maxReconnectDelaySeconds', 30), 1)) * 1000,
        MESSAGES_RELOAD_DELAY: 0,
        UNREAD_MESSAGE_COUNT_RELOAD_DELAY: 0,
        TIMELINE_ITEMS_RELOAD_DELAY: 0,
        COMMENTS_RELOAD_DELAY: 0,
        PRESENCE_STATUS_RELOAD_DELAY: 0,
        NOTIFICATIONS_RELOAD_DELAY: 0
      });

})(angular, window.Config); //eslint-disable-line angular/window-service

<div *ngIf="!attachment.deleted && !showEngageDownloadHint; else warning"
   class="item-attachment"
   [matTooltip]="attachment.storageAvailable ? attachment.name : 'MODULE.TIMELINE.ATTACHMENT.STORAGE_NOT_AVAILABLE.' + attachment.storage | uppercase | translate">
  <div class="item-attachment-item">
    <div class="item-attachment-icon">
      <coyo-file-icon-by-mime-type [mimeType]="attachment.contentType" [storage]="attachment.storage"
                                   [size]="iconSizeByPreviewSize[size]"></coyo-file-icon-by-mime-type>
    </div>
    <div class="item-attachment-name">
      {{attachment.name}}
    </div>
    <div class="item-attachment-length" *ngIf="attachment.length && attachment.showSize">
      {{attachment.length | fileSize}}
    </div>
  </div>
  <div *ngIf="!attachment.storageAvailable" class="item-attachment-unavailable">
    <div class="item-attachment-unavailable-icon">
      <mat-icon color="n200" svgIcon="warning-triangle" [size]="iconSizeByPreviewSize[size]"></mat-icon>
    </div>
    <div *ngIf="isRenderedAsBoxed(renderVariant)" class="item-attachment-unavailable-text">
      {{'MODULE.TIMELINE.ATTACHMENT.STORAGE_NOT_AVAILABLE.' + attachment.storage | uppercase | translate}}
    </div>
  </div>
</div>
<ng-template #warning>
  <div *ngIf="attachment.deleted && !showEngageDownloadHint" class="alert alert-warning">
    {{'MODULE.TIMELINE.ATTACHMENT.NOT_AVAILABLE' | translate}}
  </div>
  <div *ngIf="!attachment.deleted && showEngageDownloadHint">
    <coyo-engage-download-hint></coyo-engage-download-hint>
  </div>
</ng-template>

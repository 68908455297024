(function (angular) {
  'use strict';

  PagesListController.$inject = ["$rootScope", "$scope", "$q", "$sessionStorage", "$state", "$stateParams", "$timeout", "$injector", "PageModel", "$controller", "categoryService", "PageCategoryModel", "Pageable", "currentUser", "categories", "authService", "modalService", "subscriptionsService", "pagesConfig", "selectionFilterService", "appService"];
  angular
      .module('coyo.pages')
      .controller('PagesListController', PagesListController);

  function PagesListController($rootScope, $scope, $q, $sessionStorage, $state, $stateParams, $timeout, $injector,
                               PageModel, $controller, categoryService, PageCategoryModel, Pageable, currentUser,
                               categories, authService, modalService, subscriptionsService, pagesConfig,
                               selectionFilterService, appService) {

    var vm = this,
        FILTER_KEY_ALL = selectionFilterService.KEY_ALL;

    vm.currentUser = currentUser;
    vm.categories = categories;
    vm.subscriptions = [];
    vm.subscriptionsLoaded = false;
    vm.languages = {};
    vm.loading = true;
    vm.appLinks = {};

    vm.$onInit = onInit;
    vm.search = search;
    vm.isAutoSubscribe = isAutoSubscribe;
    vm.setFilterStatus = setFilterStatus;
    vm.setFilterCategories = setFilterCategories;
    vm.resetFilters = resetFilters;
    vm.getAppLink = getAppLink;
    vm.nextPage = nextPage;
    vm.updateCategories = updateCategories;
    vm.saveCategory = saveCategory;
    vm.changeCategoryOrder = changeCategoryOrder;
    vm.deleteCategory = deleteCategory;

    function search(searchTerm) {
      if (!vm.editingCategory) {
        vm.query.term = searchTerm;
        vm.query.filters.status = _getQueryFilterStatus();
        _loadPages();
      }
    }

    function isAutoSubscribe(page) {
      return _.some(vm.subscriptions, {targetId: page.id, autoSubscribe: true}) || undefined;
    }

    function setFilterStatus(selection) {
      _setStatus(_.map(selection, 'key')[0]);
      _loadPages();
    }

    function setFilterCategories(selected) {
      _.set(vm.query, 'filters.categories', selected);
      _loadPages();
    }

    function resetFilters() {
      _resetFilterStatus();
      _resetFilterCategories();
      _initStatusFilter();
      search('');
    }

    function getAppLink(app, page) {
      return appService.getAppLinkForCreatedApp(page, app);
    }

    /* ==================== */

    function _loadPages() {
      if (vm.loading && vm.currentPage) {
        return;
      }
      vm.subscriptionsLoaded = false;

      // write params to URL
      $state.transitionTo('main.page', _.omitBy({
        term: _.get(vm.query, 'term', ''),
        'categories[]': _getQueryFilterCategories(),
        'status': _getQueryFilterStatus()
      }, _.isEmpty), {location: 'replace'});

      // perform search
      $sessionStorage.pageQuery = vm.query;
      vm.loading = true;

      var term = vm.query.term;
      var displaySort = 'displayNameSort.' + currentUser.language.toLowerCase();
      var sort = term ? ['_score,DESC', displaySort] : displaySort;
      var pageable = new Pageable(0, pagesConfig.list.paging.pageSize, sort);
      var filters = vm.query.filters;
      var searchFields = ['displayName', 'description'];
      var aggregations = {categories: 0, allCategories: 1};
      PageModel.searchWithFilter(term, pageable, filters, searchFields, aggregations).then(function (page) {
        vm.currentPage = page;
        _.forEach(vm.categories, function (category) {
          var data = _.find(page.aggregations.categories, {key: category.id});
          category.count = _.get(data, 'count', 0);
        });
        vm.totalCount = _.get(page.aggregations.allCategories[0], 'count', 0);
        var missingData = _.find(page.aggregations.categories, {key: 'N/A'});
        vm.missingCount = _.get(missingData, 'count', null);

        _loadSubscriptions(page);
      }).finally(function () {
        vm.loading = false;
      });
    }

    function nextPage(page) {
      return page.page(page._queryParams._page).then(function (p) {
        return _loadSubscriptions(p);
      });
    }

    function updateCategories(newCategories) {
      vm.categories = newCategories;
    }

    function saveCategory(categoryEvent) {
      return categoryService.saveCategory(categoryEvent, PageCategoryModel);
    }

    function deleteCategory(categoryEvent) {
      return categoryService.deleteCategory(categoryEvent, PageCategoryModel);
    }

    function changeCategoryOrder(categoryEvent) {
      return categoryService.changeCategoryOrder(categoryEvent, PageCategoryModel);
    }

    function _loadSubscriptions(page) {
      return subscriptionsService.getSubscriptions(vm.currentUser.id, _.map(page.content, 'id'))
          .then(function (subscriptions) {
            vm.subscriptions.push.apply(vm.subscriptions, subscriptions);
            vm.subscriptionsLoaded = true;
          });
    }

    function _getQueryFilterStatus() {
      var status = _.get(vm.query, 'filters.status');
      return !_.isUndefined(status) ? status : FILTER_KEY_ALL;
    }

    function _setStatus(status) {
      _.set(vm.query, 'filters.status', status);
    }

    function _resetFilterStatus() {
      vm.statusFilterModel.clearAll();
      _setStatus(FILTER_KEY_ALL);
    }

    function _getQueryFilterCategories() {
      return _.get(vm.query, 'filters.categories', []);
    }

    function _resetFilterCategories() {
      vm.query.filters.categories = vm.categoryFilter = [];
    }

    function _initStatusFilter() {
      vm.statusFilterModel =
          selectionFilterService.builder()
              .itemModel(selectionFilterService.itemBuilder().key('SUBSCRIBED').icon('notification').build())
              .itemModel(selectionFilterService.itemBuilder().key('ADMIN').icon('settings').build())
              .active(_getQueryFilterStatus()).build();
    }

    function _setQueryFilter(property) {
      angular.extend(vm.query, {filters: property});
    }

    function onInit() {

      // extract search from URL / storage
      vm.query = $sessionStorage.pageQuery || {};
      if ($stateParams.term) {
        _setQueryFilter({term: $stateParams.term});
      }
      if ($stateParams['categories[]']) {
        _setQueryFilter({categories: $stateParams['categories[]']});
      }
      if ($stateParams.status) {
        _setStatus($stateParams.status);
      } else {
        _setStatus(FILTER_KEY_ALL);
      }

      // register permission callback
      authService.onGlobalPermissions('MANAGE_PAGE_CATEGORIES', function (canManage) {
        vm.canManagePageCategories = canManage;
      });

      authService.onGlobalPermissions('CREATE_PAGE', function (canCreate) {
        vm.canCreatePage = canCreate;
      });

      vm.categoryFilter = _getQueryFilterCategories();

      _initStatusFilter();

      $timeout(function () {
        _loadPages();
      });
    }
  }

})(angular);

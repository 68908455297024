import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {MentionModule} from '@shared/mention/mention.module';
import {TextModule} from '@shared/text/text.module';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {WIDGET_CONFIGS} from '../api/widget-config';
import {CALLOUT_WIDGET} from './callout-widget-config';
import {CalloutWidgetSettingsComponent} from './callout-widget-settings/callout-widget-settings.component';
import {CalloutWidgetComponent} from './callout-widget/callout-widget.component';

/**
 * Module providing the callout widget.
 */
@NgModule({
  imports: [
    ButtonsModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    TextModule,
    MentionModule
  ],
  declarations: [
    CalloutWidgetComponent,
    CalloutWidgetSettingsComponent
  ],
  entryComponents: [
    CalloutWidgetComponent,
    CalloutWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: CALLOUT_WIDGET, multi: true}
  ]
})
export class CalloutWidgetModule {}

import {Directive, Input} from '@angular/core';
import {FilePickerItem} from '@app/file-picker/file-picker-item';

/**
 * Base class for the header of the file picker table.
 * The component selector should be like tr[filepicker-item] so the table rendering works as expected.
 */
@Directive()
export class FilePickerHeaderBaseDirective<T extends FilePickerItem> {

  /**
   * Parent Item to be rendered
   */
  @Input() item: T;

  /**
   * The children of the parent item
   */
  @Input() items: FilePickerItem[];

  /**
   * The file picker id
   */
  @Input() filePickerId: string;
}

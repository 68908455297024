import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {SettingsService} from '@domain/settings/settings.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import * as _ from 'lodash';
import {ButtonWidget} from '../button-widget';

/**
 * A style definition of the widget's button.
 */
interface ButtonStyle {
  clazz: string;
  name: string;
}

/**
 * The button widget's settings component.
 *
 * This component is used only in the context of the widget settings modal dialog.
 */
@Component({
  templateUrl: './button-widget-settings.component.html',
  styleUrls: ['./button-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonWidgetSettingsComponent extends WidgetSettingsComponent<ButtonWidget> implements OnInit {

  styles: ButtonStyle[] = [
    {clazz: 'btn-default', name: 'WIDGET.BUTTON.SETTINGS.STYLE.DEFAULT'},
    {clazz: 'btn-primary', name: 'WIDGET.BUTTON.SETTINGS.STYLE.PRIMARY'},
    {clazz: 'btn-success', name: 'WIDGET.BUTTON.SETTINGS.STYLE.SUCCESS'},
    {clazz: 'btn-info', name: 'WIDGET.BUTTON.SETTINGS.STYLE.INFO'},
    {clazz: 'btn-warning', name: 'WIDGET.BUTTON.SETTINGS.STYLE.WARNING'},
    {clazz: 'btn-danger', name: 'WIDGET.BUTTON.SETTINGS.STYLE.DANGER'}
  ];

  readonly MAX_LENGTH: number = 250;
  readonly CB_FALSE_VALUE: string = '_self';
  readonly CB_TRUE_VALUE: string = '_blank';

  constructor(private formBuilder: FormBuilder,
              private settingsService: SettingsService) {
    super();
  }

  ngOnInit(): void {
    const target = _.get(this.widget.settings, '_linkTarget', this.CB_TRUE_VALUE);
    const style = _.get(this.widget.settings, '_button.btnClass', this.styles[0].clazz);

    // set initial form controls
    this.parentForm.addControl('text', new FormControl(this.widget.settings.text, [CoyoValidators.notBlank, Validators.maxLength(this.MAX_LENGTH)]));
    this.parentForm.addControl('_url', new FormControl(this.widget.settings._url, [CoyoValidators.notBlank, CoyoValidators.url]));
    this.parentForm.addControl('_linkTarget', new FormControl(target));
    this.parentForm.addControl('_button', this.formBuilder.group({
      btnClass: this.formBuilder.control(style)
    }));

    // update form validators after URL pattern has been resolved
    this.settingsService.retrieveByKey('linkPattern').subscribe(pattern => {
      this.parentForm.get('_url').setValidators([CoyoValidators.notBlank, Validators.pattern(pattern)]);
      this.parentForm.updateValueAndValidity();
    });
  }

  /**
   * Set the custom link target value for the form.
   *
   * @param checkbox The material checkbox
   * @param checked The current status of the checkbox
   */
  checkboxChange(checkbox: MatCheckbox, checked: boolean): void {
    checkbox.value = checked ? this.CB_TRUE_VALUE : this.CB_FALSE_VALUE;
    this.parentForm.patchValue({_linkTarget: checkbox.value});
  }
}

(function (angular) {
  'use strict';

  etagHttpDecorator.$inject = ["$provide"];
  angular
      .module('commons.resource')
      .config(etagHttpDecorator);

  /**
   * @ngdoc object
   * @name commons.resource.etagHttpDecorator
   *
   * @description
   * A decorator for angular's $http service that adds ETag handling for REST calls.
   * If a GET request contains an ETag header, the response is stored in a local cache (session storage). Any subsequent
   * request for the same url (incl. the same parameters) will send the If-None-Match header and if the backend responds
   * with status 304 (not modified) the earlier result from the cache is used and returned to the caller.
   *
   * There is a special handling for bulk requests, where each ID is treated as a separate, etag-cachable request.
   * The headers used are analogous to non-bulk requests (X-Bulk-Etag and X-If-None-Match) and contain a map of ID to value.
   * The backend response contains the status values of the individual virtual requests as a map in header X-Bulk-Status.
   *
   * If the response is served from cache, a fromCache boolean flag is added  to the http response object.
   *
   * Configuration can be added to the $http config parameter:
   *
   * @param {string?} etagCache.bulkParameter
   * Name of the bulk ID request parameter
   *
   * @param {boolean?} etagCache.disabled
   * If true the cache is ignored. Default false.
   *
   * @param {boolean?} etagCache.addCacheFlagToPayload
   * If true then a property _fromCache with value true is added to the response.data object if the response is served
   * from cache. Useful if the raw response is not available to the called (e.g. when used with angular-rails-resource)
   * but the information about cache hit is required. Default false.
   *
   * @requires $sessionStorage
   * @requires etagCacheService
   */
  function etagHttpDecorator($provide) {
    $provide.decorator('$http', ["$delegate", "$sessionStorage", "etagCacheService", function ($delegate, $sessionStorage, etagCacheService) {

      /**
       * Replacement function for $http service
       *
       * @param {object} originalConfig
       * Configuration of http request
       *
       * @returns {object} promise
       */
      function $httpDecorator(originalConfig) {
        var config = _.cloneDeep(originalConfig);
        if (config.method.toLowerCase() === 'get') {
          var cacheKey = etagCacheService.buildCacheKey(config.url, config.params, config.etagCache);
          config.headers = config.headers || {};

          if (_isCacheEnabled(config.etagCache)) {

            if (etagCacheService.isCached(cacheKey)) {
              return _etagCacheRequest(cacheKey, config);
            }

            if (etagCacheService.isBulkCached(cacheKey)) {
              return _bulkEtagCacheRequest(config, cacheKey);
            }
          }
        }
        return $delegate(config).then(_addResponseWithEtagHeaderToCache);
      }

      function _isCacheEnabled(cacheConfig) {
        return !_.get(cacheConfig, 'disabled', false);
      }

      function _etagCacheRequest(cacheKey, args) {
        args.headers['If-None-Match'] = '"' + etagCacheService.get(cacheKey).etag + '"';
        return $delegate(args).then(function (response) {
          if (response.status === 304 && etagCacheService.isCached(cacheKey)) {
            var cacheEntry = etagCacheService.get(cacheKey);
            response.data = cacheEntry.data;
            response.status = cacheEntry.status;
            response.fromCache = true;
            if (_.get(response, 'config.etagCache.addCacheFlagToPayload') === true
                && angular.isObject(response.data)) {
              response.data._fromCache = true;
            }
          } else {
            _addResponseWithEtagHeaderToCache(response);
          }
          return response;
        });
      }

      function _bulkEtagCacheRequest(args, cacheKey) {
        var bulkParameter = _.get(args, 'etagCache.bulkParameter');
        if (bulkParameter && angular.isArray(args.params[bulkParameter])) {
          var etagMap = {};
          args.params[bulkParameter].forEach(function (id) {
            var entry = etagCacheService.getBulk(cacheKey, id);
            if (entry) {
              etagMap[id] = entry.etag;
            }
          });
          args.headers['X-Bulk-If-None-Match'] = angular.toJson(etagMap);
        }
        return $delegate(args).then(function (response) {
          if (response.headers('X-Bulk-Status')) {
            var bulkStatus = angular.fromJson(response.headers('X-Bulk-Status'));
            Object.keys(bulkStatus).forEach(function (id) {
              if (bulkStatus[id] === 304) {
                var entry = etagCacheService.getBulk(cacheKey, id);
                response.data[id] = entry.data;
                _.set(response, 'fromCache[' + id + ']', true);
                if (_.get(response, 'config.etagCache.addCacheFlagToPayload') === true
                    && angular.isObject(response.data[id])) {
                  response.data[id]._fromCache = true;
                }
              }
            });
          }
          _addResponseWithEtagHeaderToCache(response);
          return response;
        });
      }

      function _addResponseWithEtagHeaderToCache(response) {
        var etag = _getEtagHeader(response);
        if (etag && response.config.originalUrl) {
          var cacheKey = etagCacheService.buildCacheKey(response.config.originalUrl, response.config.params, response.config.etagCache);
          etagCacheService.store(cacheKey, response.data, etag, response.status);
        }
        var bulkEtag = response.headers('X-Bulk-Etag');
        if (bulkEtag) {
          var tags = angular.fromJson(bulkEtag);
          Object.keys(tags).forEach(function (id) {
            var cacheKey = etagCacheService.buildCacheKey(response.config.originalUrl, response.config.params, response.config.etagCache);
            etagCacheService.storeBulk(cacheKey, id, response.data[id], tags[id]);
          });
        }
        return response;
      }

      function _getEtagHeader(response) {
        var header = response.headers('Etag');
        if (!header) {
          return header;
        }
        return (header.startsWith('W/') ? header.substring(2) : header).replace(/"/g, '');
      }

      // delegate all member functions
      Object.keys($delegate).forEach(function (key) {
        $httpDecorator[key] = $delegate[key];
      });

      // override get to point to new $httpDecorator function
      $httpDecorator.get = function (url, config) {
        return $httpDecorator(angular.extend({}, config || {}, {
          method: 'get',
          url: url
        }));
      };

      return $httpDecorator;
    }]);
  }

})(angular);

import {Injectable} from '@angular/core';
import {BrowserService} from '@core/browser/browser.service';
import {GSUITE, LOCAL_BLOB, LOCAL_FILE_LIBRARY} from '@domain/attachment/storage';
import {CapabilitiesService} from '@domain/capability/capabilities/capabilities.service';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {ExternalFileHandlerService} from '@shared/files/external-file-handler/external-file-handler.service';
import {PreviewType} from '@shared/preview/file-preview/file-preview-type/preview.type';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * This class determines the type of how a file should be previewed
 */
@Injectable({
  providedIn: 'root'
})
export class FilePreviewTypeService {

  constructor(private browserService: BrowserService,
              private externalFileHandlerService: ExternalFileHandlerService,
              private capabilitiesService: CapabilitiesService) {
  }

  /**
   * Returns the type of how the file should be displayed
   * @param file the file that should be previewed
   *
   * @returns an Observable with the type.
   */
  determinePreviewType(file: FilePreview): Observable<PreviewType> {
    if (!file) {
      return of(PreviewType.NO_PREVIEW);
    }

    if (this.isInternalFile(file)) {
      if (this.isPdf(file)) {
        return of(PreviewType.PDF);
      }
      if (this.isVideo(file)) {
        return of(PreviewType.VIDEO);
      }
      return this.isImage(file).pipe(
        map(isImage => isImage ? PreviewType.IMAGE : PreviewType.NO_PREVIEW)
      );
    }

    if (this.isGSuiteFile(file)) {
      if (this.isGsuiteImage(file)) {
        return of(PreviewType.GSUITE_IMAGE);
      }
      if (this.isGSuitePDF(file)) {
        return of(PreviewType.GSUITE_PDF);
      }
      if (this.isGSuiteVideo(file)) {
        return of(PreviewType.GSUITE_VIDEO);
      }
      if (this.isGSuiteExportablePDF(file)) {
        return of(PreviewType.GSUITE_AS_PDF_EXPORTABLE_DOCUMENT);
      }
    }

    return of(PreviewType.NO_PREVIEW);
  }

  /**
   * Determines if the given file is an COYO internal file
   * @param file the file that should be checked
   * @returns true, when it's an COYO internal file. Otherwise false.
   */
  isInternalFile(file: FilePreview): boolean {
    return !!file && (!file.storage || file.storage === LOCAL_FILE_LIBRARY || file.storage === LOCAL_BLOB);
  }

  private isImage(file: FilePreview): Observable<boolean> {
    if (!file.length) {
      return of(false);
    }

    return this.capabilitiesService
      .previewImageFormat(file.contentType)
      .pipe(map(format => format !== ''));
  }

  private isPdf(file: FilePreview): boolean {
    return file.contentType === 'application/pdf';
  }

  private isVideo(file: FilePreview): boolean {
    switch (file.contentType) {
      case 'video/quicktime':
        return this.browserService.supportsQuicktimeVideo();
      case 'video/mp4':
        return true;
      default:
        return false;
    }
  }

  /**
   * Determines if the given file is stored in the G Suite
   * @param file the file that should be checked
   * @returns true, when it's a file that is stored in the G Suite. Otherwise false.
   */
  isGSuiteFile(file: FilePreview): boolean {
    return file && file.storage === GSUITE;
  }

  private isGsuiteImage(file: FilePreview): boolean {
    return file.length > 0 && file.contentType.startsWith('image');
  }

  private isGSuiteVideo(file: FilePreview): boolean {
    return this.isGSuiteFile(file) && this.isVideo(file);
  }

  private isGSuitePDF(file: FilePreview): boolean {
    return this.isGSuiteFile(file) && this.isPdf(file);
  }

  private isGSuiteExportablePDF(file: FilePreview): boolean {
    return this.externalFileHandlerService.isGoogleMediaType(file.contentType)
      && !!file.exportLinks && !!file.exportLinks['application/pdf'];
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {User} from '@domain/user/user';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewColleaguesService {

  private url: string = '/web/widgets/newcolleagues';

  constructor(@Inject(HttpClient) private http: HttpClient) { }

  getNewColleaguesList(pageable: Pageable): Observable<Page<User>> {
    return this.http.get<Page<User>>(this.url, {params: pageable.toHttpParams()});
  }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TargetService} from '@domain/sender/target/target.service';
import {SenderLinkTarget} from '@shared/sender-ui/sender-link/sender-link-target';
import {Observable} from 'rxjs';

/**
 * Click handler component, opens supplied sender when clicked.
 */
@Component({
  selector: 'coyo-sender-link',
  templateUrl: './sender-link.component.html',
  styleUrls: ['./sender-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderLinkComponent implements OnInit, OnChanges {

  /**
   * The sender that should be linked to.
   */
  @Input() sender: SenderLinkTarget;

  /**
   * Custom CSS class that is applied to the link text.
   */
  @Input() customClass: string;

  link: string;
  active$: Observable<boolean>;

  constructor(private targetService: TargetService) {
  }

  ngOnInit(): void {
    this.generateLink();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.generateLink();
  }

  private generateLink(): void {
    this.link = this.targetService.getLinkTo(this.sender.target);
    this.active$ = this.targetService.canLinkTo(this.sender.target);
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ContentTypeService} from '@widgets/media/content-type/content-type.service';
import {MediaWidgetFile} from '@widgets/media/media-widget-file';

/**
 * Component for showing information of one media file for the media widget settings dialog.
 *
 */
@Component({
  selector: 'coyo-media-widget-settings-row',
  templateUrl: './media-widget-settings-row.component.html',
  styleUrls: ['./media-widget-settings-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaWidgetSettingsRowComponent {

  readonly previewUrl: string = '/web/senders/{{groupId}}/documents/{{id}}';

  /**
   * The media file this row is responsible for
   */
  @Input() file: MediaWidgetFile;

  /**
   * The form group responsible for the description and preview input
   */
  @Input() parentForm: FormGroup;

  /**
   * Event that is emitted when the file should be deleted from the form
   */
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(private contentTypeService: ContentTypeService) {
  }

  /**
   * Checks if the given media is an Video.
   *
   * @param document
   * The media document
   *
   * @returns
   * true if the document is a video
   */
  isVideo(document: MediaWidgetFile): boolean {
    return this.contentTypeService.isVideo(document.contentType);
  }

  /**
   * Checks if the given media is an Image.
   *
   * @param document
   * The media documen
   *
   * @returns
   * true if the document is a image
   */
  isImage(document: MediaWidgetFile): boolean {
    return this.contentTypeService.isImage(document.contentType);
  }
}

import {Injectable, NgZone} from '@angular/core';
import {OverlayService} from '@shared/overlay/overlay.service';
import {LaunchpadComponent} from './launchpad.component';

/**
 * A service that opens the {@link LaunchpadComponent} overlay.
 */
@Injectable()
export class LaunchpadService {

  constructor(private overlayService: OverlayService, private ngZone: NgZone) {
  }

  isLaunchPadVisible: boolean = false;

  /**
   * Opens the {@links LaunchpadComponent}.
   */
  open(): void {
    if (!this.isLaunchPadVisible) {
      this.ngZone.run(() =>
        this.overlayService.open(LaunchpadComponent, {
          scrollStrategy: this.overlayService.scrollStrategies.block(),
          panelClass: 'launchpad',
          hasBackdrop: false,
          height: '100%',
          width: '100%'
        }).subscribe(() => {
          this.isLaunchPadVisible = false;
        })
      );
      this.isLaunchPadVisible = true;
    }
  }
}

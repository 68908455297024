(function (angular) {
  'use strict';

  anchorContainer.$inject = ["$window"];
  angular
      .module('commons.ui')
      .directive('coyoAnchorContainer', anchorContainer);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoAnchorContainer:coyoAnchorContainer
   * @element ANY
   * @restrict A
   *
   * @description
   * Directive to make anchor tags clickable every time within a wrapper component.
   *
   * @requires $window
   */
  function anchorContainer($window) {
    return {
      restrict: 'A',
      link: function ($scope, elem) {
        function onClick($event) {
          if ($event.target.tagName === 'A' && $event.target.outerHTML.includes('href="#')) {
            // reset to empty hash before setting it to the real value so scrolling works multiple times instead of just once
            $window.location.hash = '';
            $window.location.hash = $event.target.hash;
            // reset base URL so anchor links can be opened in a new tab
            $event.target.href = $window.location.href;
          }
        }

        elem.bind('click', onClick);
        $scope.$on('$destroy', function () {
          elem.unbind('click', onClick);
        });
      }
    };
  }

})(angular);

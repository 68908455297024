<ng-container [formGroup]="parentForm" *ngIf="entryPoints$ | async as entryPoints">
  <mat-form-field *ngIf="entryPoints.length > 1">
    <mat-label translate="WIDGET.PLUGIN.SETTINGS.ENTRY_POINT.LABEL"></mat-label>
    <ng-select formControlName="_entryPointId" [items]="entryPoints"
               bindLabel="name" bindValue="id" [clearable]="false"
               appendTo="body" class="mat-ng-select" appearance="outline">
      <ng-template ng-option-tmp let-item="item">
        {{item.name}}
        <div *ngIf="item.description" class="mat-hint">{{item.description}}</div>
      </ng-template>
    </ng-select>
    <mat-hint>{{'WIDGET.PLUGIN.SETTINGS.ENTRY_POINT.HELP' | translate}}</mat-hint>
    <mat-error *coyoFieldErrors="parentForm.controls._entryPointId.errors"></mat-error>
  </mat-form-field>

  <!-- dynamic plugin config form -->
  <formly-form [form]="parentForm" [model]="widget.settings" [fields]="config$ | async"></formly-form>
</ng-container>

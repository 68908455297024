(function (angular) {
  'use strict';

  AdminThemeDetailsController.$inject = ["$injector", "$state", "$q", "Upload", "tempUploadService", "coyoEndpoints", "backendUrl", "themes", "theme", "defaultThemeColors", "adminThemeConfig"];
  angular
      .module('coyo.admin.themes')
      .controller('AdminThemeDetailsController', AdminThemeDetailsController);

  function AdminThemeDetailsController($injector, $state, $q, Upload, tempUploadService, coyoEndpoints,
                                       backendUrl, themes, theme, defaultThemeColors, adminThemeConfig) {
    var vm = this;
    var tempUploadExpirySeconds = 1800;

    vm.themeCount = themes.length;
    vm.theme = theme;

    vm.colors = [
      {key: 'color-primary', displayName: 'ADMIN.THEMES.COLORS.COLOR_PRIMARY'},
      {key: 'color-secondary', displayName: 'ADMIN.THEMES.COLORS.COLOR_GRADIENT'},
      {key: 'color-navbar-border', displayName: 'ADMIN.THEMES.COLORS.COLOR_NAVBAR_BORDER'},
      {key: 'coyo-navbar-text', displayName: 'ADMIN.THEMES.COLORS.COLOR_NAVBAR_TEXT'},
      {key: 'coyo-navbar', displayName: 'ADMIN.THEMES.COLORS.COLOR_NAVBAR'},
      {key: 'coyo-navbar-active', displayName: 'ADMIN.THEMES.COLORS.COLOR_NAVBAR_ACTIVE'},
      {key: 'btn-primary-color', displayName: 'ADMIN.THEMES.COLORS.COLOR_BTN_PRIMARY_COLOR'},
      {key: 'btn-primary-bg', displayName: 'ADMIN.THEMES.COLORS.COLOR_BTN_PRIMARY_BG'},
      {key: 'color-background-main', displayName: 'ADMIN.THEMES.COLORS.COLOR_BACKGROUND_MAIN'},
      {key: 'text-color', displayName: 'ADMIN.THEMES.COLORS.COLOR_TEXT'},
      {key: 'link-color', displayName: 'ADMIN.THEMES.COLORS.COLOR_LINK'}
    ];

    vm.images = {}; // temp. storage for images
    vm.imageConfigs = adminThemeConfig.imageConfigs;

    // ====================

    vm.uploadImage = uploadImage;
    vm.removeImage = removeImage;
    vm.getImgUrl = getImgUrl;
    vm.save = save;

    // ====================

    function uploadImage(imageKey) {
      var imageConfig = _.find(vm.imageConfigs, {key: imageKey});
      var imageData = vm.images[imageKey];
      if (!imageData.fileToUpload) {
        return;
      }

      // regular size
      _resize(imageData.fileToUpload, imageConfig, 1).then(function (file) {
        tempUploadService.upload(file, tempUploadExpirySeconds).then(function (blob) {
          imageData.file = file;
          imageData.uid = blob.uid;
          vm.theme.images[imageConfig.key] = _createUrl(blob.uid, _getFileExtension(imageData.file.name));

          if (_isNonResizableImageType(file.type)) {
            file.fixedWidthPx = _getNonResizableImageWidth(file.type);
          }
          _.forEach(_.get(imageConfig, 'variables', {}), function (fn, key) {
            $q.when(fn(imageData.fileToUpload, file, Upload)).then(function (value) {
              vm.theme.images[key] = value;
            });
          });
        });
      });

      // retina size
      if (imageConfig.retinaKey) {
        _resize(imageData.fileToUpload, imageConfig, 2).then(function (file) {
          tempUploadService.upload(file, tempUploadExpirySeconds).then(function (blob) {
            imageData.retinaFile = file;
            imageData.retinaUid = blob.uid;
            vm.theme.images[imageConfig.retinaKey] = _createUrl(blob.uid, _getFileExtension(imageData.file.name));
          });
        });
      }
    }

    function removeImage(imageKey) {
      delete vm.images[imageKey];
      var imageConfig = _.find(vm.imageConfigs, {key: imageKey});
      _.concat(imageConfig.key, imageConfig.retinaKey, _.keys(imageConfig.variables)).forEach(function (key) {
        delete vm.theme.images[key];
      });
    }

    function getImgUrl(url) {
      return url ? url.replace(new RegExp('\'', 'g'), '') : undefined;
    }

    function save() {
      var isNew = vm.theme.isNew();
      vm.errorMessage = undefined;

      // cleanup variables
      var colorKeys = _.map(vm.colors, 'key');
      vm.theme.colors = _.pickBy(vm.theme.colors, function (val, key) {
        return _.includes(colorKeys, key) && !_.isEmpty(val);
      });

      // collect file IDs
      vm.theme.fileIds = _(vm.imageConfigs).map('key').flatMap(function (key) {
        return _(vm.images[key]).pick('uid', 'retinaUid').values().value();
      }).value();

      // save theme
      return vm.theme.save().then(function () {
        return $injector.get('ngxThemeService').applyTheme().then(function () {
          if (isNew || vm.themeCount > 1) {
            $state.go('^.list', {}, {reload: true});
          } else {
            $injector.get('ngxNotificationService').success('ADMIN.THEMES.SAVE.SUCCESS');
            vm.images = {};
          }
        });
      }, function (error) {
        vm.errorMessage = error.data.message;
      });
    }

    // ====================

    function _resize(file, config, resizeFactor) {
      if (_isNonResizableImageType(file.type) ||
         (!config.width && !config.height)) {
        return $q.resolve(file);
      }

      var targetWidth = config.width ? config.width * resizeFactor : null;
      var targetHeight = config.height ? config.height * resizeFactor : null;
      return Upload.resize(file, targetWidth, targetHeight, null, null, null, null, function (width, height) {
        var maxWidth = config.width && config.width * resizeFactor > width;
        var maxHeight = config.height && config.height * resizeFactor > height;
        return !maxWidth && !maxHeight;
      });
    }

    function _isNonResizableImageType(fileType) {
      return !!_.find(adminThemeConfig.nonResizableImageProperties, {type: fileType});
    }

    function _getNonResizableImageWidth(fileType) {
      return _.find(adminThemeConfig.nonResizableImageProperties, {type: fileType}).fixedWidthPx;
    }

    function _createUrl(uid, extension) {
      return '\'' + backendUrl + coyoEndpoints.theme.files
          .replace('{id}', uid)
          .replace('{extension}', extension) + '\'';
    }

    function _getFileExtension(fileName) {
      return fileName.split('.').pop();
    }

    (function _init() {
      // populate default colors
      _.forEach(vm.colors, function (color) {
        color.defaultValue = defaultThemeColors[color.key];
      });

      // init vm.imageConfigs
      _.forEach(vm.imageConfigs, function (imageConfig) {
        vm.images[imageConfig.key] = {};
      });
    })();
  }

})(angular);

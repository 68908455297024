(function (angular) {
  'use strict';

  LibraryFileDetailsController.$inject = ["$q", "$log", "$timeout", "$uibModalStack", "$injector", "Upload", "DocumentModel", "fileAuthorService", "coyoEndpoints", "backendUrlService", "modalService", "fileLibraryModalService"];
  angular
      .module('commons.ui')
      .component('coyoLibraryFileDetails', libraryFileDetails())
      .controller('LibraryFileDetailsController', LibraryFileDetailsController);

  /**
   * @ngdoc directive
   * @name  commons.ui.coyoLibraryFileDetails:coyoLibraryFileDetails
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Renders an action bar and detail information for library files.
   *
   * @param {object} file
   * The file to be displayed
   *
   * @param {object} fileAuthor
   * The file's author
   *
   * @param {boolean} showAuthors
   * Should authors be displayed
   *
   * @param {string} appIdOrSlug
   * The app from which this display was launched to determine showAuthor settings
   *
   * @param {boolean} linkToFileLibrary
   * Should a link to the file in its library be displayed
   *
   * @param {string} previewUrl
   * The url used for the preview image - two way bound so that the parent display is updated when another version is selected
   *
   * @param {function} updateFileFunc
   * Callback function for updating the file and its preview.
   *
   * @requires coyo.domain.DocumentModel
   */
  function libraryFileDetails() {
    return {
      templateUrl: 'app/commons/ui/components/file-details/library-file-details.html',
      bindings: {
        currentUser: '<',
        file: '<',
        fileAuthor: '<',
        showAuthors: '<',
        appIdOrSlug: '<',
        linkToFileLibrary: '<',
        previewUrl: '=',
        updateFileFunc: '&',
        fileDetailsDialogId: '<'
      },
      controller: 'LibraryFileDetailsController'
    };
  }

  /**
   * Controller managing display of a library with its details and versions.
   *
   * @requires $q
   * @requires $timeout
   * @requires $log
   * @requires $uibModalStack
   * @requires $injector
   * @requires Upload
   * @requires DocumentModel
   * @requires fileAuthorService
   * @requires coyoEndpoints
   * @requires backendUrlService
   * @requires fileAuthorService
   * @requires modalService
   * @requires fileLibraryModalService
   *
   * @constructor
   */
  function LibraryFileDetailsController($q, $log, $timeout, $uibModalStack, $injector, Upload, DocumentModel,
                                        fileAuthorService, coyoEndpoints, backendUrlService,
                                        modalService, fileLibraryModalService) {
    var vm = this;

    vm.$onInit = onInit;
    vm.openFileLibrary = openFileLibrary;
    vm.switchTab = switchTab;
    vm.selectVersion = selectVersion;
    vm.uploadVersion = uploadVersion;
    vm.restoreVersion = restoreVersion;
    vm.getDownloadUrlForVersion = getDownloadUrlForVersion;
    vm.canAccessFiles = false;

    vm.tabs = {
      active: 1,
      INFORMATION: 1,
      HISTORY: 2
    };

    /**
     * Closes the current modal and opens this file's file library in a modal.
     */
    function openFileLibrary() {
      var top = $uibModalStack.getTop();
      if (top) {
        $uibModalStack.dismiss(top.key);
      }
      fileLibraryModalService.open(vm.file.sender, {
        highlightedFileId: vm.file.id,
        initialFolder: vm.file.parent,
        selectionMode: 'download'
      });
    }

    /**
     * Displays the information of the selected tab. Resets preview to latest version of file,
     * and reloads version information if necessary.
     *
     * @param {number} tab to switch to
     */
    function switchTab(tab) {
      vm.tabs.active = tab;

      if (vm.versions.first) {
        selectVersion(vm.versions.content[0]);
      } else {
        vm.versions._queryParams._page = 0;
        var versionsAlreadyLoaded = vm.versions.content.length;
        _loadFileVersions().then(function (versionsPage) {
          versionsAlreadyLoaded ? selectVersion(versionsPage.content[0]) : _setCurrentVersion(versionsPage.content[0]);
        });
      }
    }

    /**
     * Sets the version as version to be previewed.
     *
     * @param {object} version to select
     */
    function selectVersion(version) {
      if (angular.isDefined(vm.currentVersionNumber) && vm.currentVersionNumber === version.versionNumber) {
        return;
      }
      _setCurrentVersion(version);
      var file = new DocumentModel(vm.file);
      vm.previewUrl = file.getVersionPreviewUrl(version);
    }

    /**
     * Uploads a new version of a file.
     *
     * @param {object} fileModel
     * The file model (with the old file data)
     *
     * @param {object} files
     * List of valid files to be uploaded as a new version. Only the first one will be used.
     *
     * @returns {promise} A promise
     */
    function uploadVersion(fileModel, files) {
      var fileData;

      if (!angular.isArray(files)) {
        fileData = files;
      } else {
        fileData = files[0];
      }

      if (!fileData) {
        return $q.resolve();
      }

      var url = coyoEndpoints.sender.versions.replace('{{senderId}}', vm.file.sender.id).replace('{{id}}', fileModel.id);

      // Attention: Don't set the name of the uploaded file, it cannot be changed!
      fileModel.contentType = fileData.type;

      return _uploadFile(fileModel, fileData, url).then(function () {
        vm.updateFileFunc();
      });
    }

    /**
     * Restores the given version of this view model's file.
     *
     * @param {object} version
     * The version to be restored.
     *
     */
    function restoreVersion(version) {
      new DocumentModel(vm.file).restoreVersion(version.id).then(function () {
        vm.updateFileFunc();
      });
    }

    /**
     * Returns the download URL for a specific version.
     *
     * @param {string} versionId
     * @return {string} The download URL for a specific version
     */
    function getDownloadUrlForVersion(versionId) {
      return backendUrlService.getUrl() + new DocumentModel(vm.file).getDownloadUrlForVersion(versionId);
    }

    function _setCurrentVersion(version) {
      vm.currentVersion = version;
      vm.currentVersionNumber = version.versionNumber;
    }

    function _loadFileVersions() {
      vm.versions.loading = true;
      var model = new DocumentModel(vm.file);
      return model.getVersions(vm.versions._queryParams).then(function (page) {
        vm.versions = page;
        _loadVersionAuthors();
        return vm.versions;
      });
    }

    function _loadVersionAuthors() {
      fileAuthorService.loadVersionAuthors(vm.file.sender.id, vm.file.id, vm.appIdOrSlug, vm.showAuthors,
          vm.file.sender._permissions.manage)
          .then(function (result) {
            vm.versionAuthors = result;
          });
    }

    function _uploadFile(fileModel, fileData, url) {
      // upload the file
      var data = {
        file: fileData
      };
      if (vm.parent) {
        data.parentId = vm.parent.id;
      }

      fileData.upload = Upload.upload({
        url: url,
        data: data,
        headers: {
          'X-Permissions': ['manage', 'publicLink']
        }
      });

      // fill the dummy with data
      return fileData.upload.then(function (response) {
        var deferred = $q.defer();

        $timeout(function () {
          angular.extend(fileModel, response.data);
          deferred.resolve(fileModel);
        });

        return deferred.promise;
      }, function (error) {
        $log.error('An error occurred while uploading the file ', fileData, error);
        $injector.get('ngxNotificationService').error('FILE_LIBRARY.ERROR.FILE_UPLOAD', null, {filename: fileData.name});
      });
    }

    function onInit() {
      vm.versions = {
        content: [],
        _queryParams: {
          _page: 0,
          _pageSize: 5,
          _sort: 'created,desc'
        }
      };
      vm.downloadUrl = backendUrlService.getUrl() + new DocumentModel(vm.file).getDownloadUrl();
      vm.canAccessFiles = vm.currentUser.globalPermissions.includes('ACCESS_FILES');
    }
  }

})(angular);

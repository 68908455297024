<coyo-sender-avatar [sender]="showOriginalAuthor ? (originalAuthor$ | async) : comment.author" avatarSize="sm" class="coyo-new" [showExternalFlag]="true"></coyo-sender-avatar>
<div class="comment coyo-new" data-test="comment-item">
  <div class="comment-title">
    <coyo-sender-link class="author" [sender]="showOriginalAuthor ? (originalAuthor$ | async) : comment.author" data-test="comment-author"></coyo-sender-link><!--
 --><span class="comment-time space-right">{{ comment.created | timeAgo : true }}</span><!--
 --><ng-container *ngIf="comment.edited"><!--
   --><span class="divider space-right">•</span><!--
   --><b translate="COMMENTS.EDITED" class="comment-edited space-right"></b><!--
 --></ng-container><!--
 --><ng-container *ngIf="author && !(isXs$ | async)"><!--
   --><ng-container *ngxPermissionsOnly="['LIKE']"><!--
     --><span class="divider">•</span><!--
     --><coyo-like-container class="space-right"
                             [author]="author"
                             [target]="comment"
                             [textual]="true"
                             [condensed]="true"
                             [skipInitRequest]="skipInitRequest">
        </coyo-like-container>
      </ng-container>
    </ng-container>
  </div>
  <coyo-comment-context-menu [comment]="comment" [editable]="editable"
                             (showAuthor)="showAuthor($event)" (changed)="changed.emit($event)"
                             (edit)="editComment()" (delete)="deleteComment()">
  </coyo-comment-context-menu>
  <div class="comment-body" data-test="comment-message"
       [innerHTML]="comment.message | markdown | hashtags | mention">
  </div>
  <coyo-jit-translation-output [showMentions]="true" [translation]="translation"></coyo-jit-translation-output>
  <div class="actions">
    <coyo-jit-translation-toggle field="message" [id]="comment.id" [originalText]="comment.message"
                                 [showIconOnMobile]="false"
                                 [typeName]="comment.typeName" (translated)="translation = $event">
    </coyo-jit-translation-toggle>
    <ng-container *ngIf="author && (isXs$ | async)"><!--
   --><coyo-like-container *ngxPermissionsOnly="['LIKE']"
                           [author]="author"
                           [target]="comment"
                           [textual]="true"
                           [condensed]="true"
                           [skipInitRequest]="skipInitRequest">
      </coyo-like-container>
    </ng-container>
  </div>
  <coyo-file-preview-list *ngIf="comment.attachments?.length > 0"
                          [attachments]="comment.attachments" [groupId]="comment.id"
                          [author]="comment.author" [previewUrl]="previewUrl" size="xl">
  </coyo-file-preview-list>
</div>


<div *ngIf="!editMode" [innerHTML]="widget.settings.md_text | markdown | hashtags"></div>
<div *ngIf="editMode" #editContainer>
  <textarea autosize rows="1" *ngIf="showInput || !widget.settings.md_text"
            class="form-control"
            placeholder="{{'WIDGET.TEXT.PLACEHOLDER' | translate}}"
            (ngModelChange)="contentUpdated($event)"
            [ngModel]="widget.settings.md_text" (blur)="onBlur()"></textarea>
  <div *ngIf="!showInput && widget.settings.md_text" [innerHTML]="widget.settings.md_text | markdown"
       (click)="onClick()"></div>
</div>

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {WikiArticle} from '@domain/wiki-article/wiki-article';

/**
 * Service that retrieves wiki articles.
 */
@Injectable({
  providedIn: 'root'
})
export class WikiArticleService extends DomainService<WikiArticle, WikiArticle> {

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/senders/{senderId}/apps/{appId}/wiki/articles';
  }
}

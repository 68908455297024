import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {TimelineItemUpdatedMessage} from '@app/timeline/timeline-item/timeline-item/timeline-item-updated-message';
import {AuthService} from '@core/auth/auth.service';
import {EtagInterceptor} from '@core/http/etag-interceptor/etag-interceptor';
import {SocketService} from '@core/socket/socket.service';
import {Skeleton} from '@coyo/ui';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Sender} from '@domain/sender/sender';
import {SubscriptionService} from '@domain/subscription/subscription.service';
import {TimelineItem} from '@domain/timeline-item/timeline-item';
import {TimelineItemService} from '@domain/timeline-item/timeline-item.service';
import {Ng1SocketReconnectDelays} from '@root/typings';
import {NG1_SOCKET_RECONNECT_DELAYS} from '@upgrade/upgrade.module';
import * as _ from 'lodash';
import {NgxPermissionsService} from 'ngx-permissions';
import {BehaviorSubject, from, Observable, of, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

interface TimelineStreamState {
  loadingNew: boolean;
  newItemsCount: number;
  loading: boolean;
  page: Page<TimelineItem>;
  items: TimelineItem[];
  mostRecentItemId: string;
}

/**
 * Renders the timeline stream. Consisting of the timeline form and a paginated list of items.
 *
 * The stream will not react on changes of the type or the context after initialization.
 */
@Component({
  selector: 'coyo-timeline-stream',
  templateUrl: './timeline-stream.component.html',
  styleUrls: ['./timeline-stream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineStreamComponent implements OnInit, OnDestroy {

  /**
   * The timeline type. The default value is 'personal'.
   */
  @Input() type: 'sender' | 'personal' = 'personal';

  /**
   * Permission flag if the user is allowed to post.
   */
  @Input() canPost: boolean = true;

  /**
   * The context sender. If it is a personal timeline this should be the current user otherwise the sender containing
   * the timeline.
   */
  @Input() context: Sender;

  visible$: Observable<boolean>;

  canPost$: Observable<boolean>;

  state$: Observable<TimelineStreamState>;

  timelineInitDate: Date = new Date();

  isPersonal: boolean;

  skeletons: Skeleton[] = [
    { top: 0, left: 0, width: '100%', height: 155, color: 'white' },
    { top: 155, left: 0, width: '100%', height: 1 },
    { top: 156, left: 0, width: '100%', height: 59, color: 'white'},
    { top: 215, left: 'calc(50% - 48.75%)', width: '97.5%', height: 40},
    { top: 20, left: 20, width: 50, height: 50, radius: '50%' },
    { top: 25, left: 95, width: '25%', height: 20 },
    { top: 50, left: 95, width: '15%', height: 15 },
    { top: 90, left: 20, width: '95%', height: 20 },
    { top: 115, left: 20, width: '90%', height: 20 },
    { top: 175, left: 20, width: '10%', height: 20 },
    { top: 175, left: '15%', width: '20%', height: 20 },
    { top: 175, left: 'calc(98% - 20%)', width: '20%', height: 20 }
  ];

  private stateSubject$: BehaviorSubject<TimelineStreamState> = new BehaviorSubject<TimelineStreamState>({
    loadingNew: false,
    newItemsCount: 0,
    loading: false,
    page: null,
    items: [],
    mostRecentItemId: null
  });

  private unloadedItems: string[] = [];

  private createSubscription: Subscription;

  private itemDeletedSubscriptions: { [key: string]: Subscription } = {};
  private itemUpdatedSubscriptions: { [key: string]: Subscription } = {};

  private sharedSubscription: Subscription;

  private reconnectSubscription: Subscription;

  private newItemsReferenceDate: number;

  private lastRefreshDate: number;

  private contextSenders: string[];

  constructor(private permissionService: NgxPermissionsService, private timelineItemService: TimelineItemService,
              private socketService: SocketService, private authService: AuthService,
              @Inject(NG1_SOCKET_RECONNECT_DELAYS) private socketReconnectDelays: Ng1SocketReconnectDelays,
              private subscriptionService: SubscriptionService, private changeDetectorRef: ChangeDetectorRef) {
    this.state$ = this.stateSubject$.asObservable();
  }

  ngOnInit(): void {
    this.isPersonal = this.type === 'personal';

    this.visible$ = from(this.permissionService.hasPermission('ACCESS_PERSONAL_TIMELINE'))
      .pipe(map(permission => permission || !this.isPersonal));

    this.canPost$ = from(this.permissionService.hasPermission('CREATE_TIMELINE_ITEM'))
      .pipe(map(permission => permission && this.canPost));

    this.updateCount();

    const route = '/topic/timeline.item.created';
    if (this.isPersonal) {
      this.createSubscription = this.socketService.listenTo$('/user' + route).subscribe(event =>
        this.addItem(event.content));
    } else {
      this.createSubscription = this.socketService.listenTo$(route, null, this.context.id, this.context.senderSubscriptionInfo.token).subscribe(event =>
        this.addItem(event.content));
    }

    const shareRoute = '/topic/timeline.item.shared';
    if (this.isPersonal) {
      this.sharedSubscription = this.socketService.listenTo$('/user' + shareRoute).subscribe(event =>
        this.onItemShared(event.content));
    } else {
      this.sharedSubscription = this.socketService.listenTo$(shareRoute, null, this.context.id, this.context.senderSubscriptionInfo.token).subscribe(event =>
        this.onItemShared(event.content));
    }

    this.loadContext();

    this.loadMore();

    this.reconnectSubscription = this.socketService.listenToReconnect$()
      .subscribe(() => this.onWebsocketReconnect());
  }

  ngOnDestroy(): void {
    if (this.createSubscription && !this.createSubscription.closed) {
      this.createSubscription.unsubscribe();
    }

    if (this.sharedSubscription && !this.sharedSubscription.closed) {
      this.sharedSubscription.unsubscribe();
    }

    if (this.reconnectSubscription && !this.reconnectSubscription.closed) {
      this.reconnectSubscription.unsubscribe();
    }

    this.unsubscribeAllItemSubscriptions();

    this.stateSubject$.complete();
  }

  /**
   * Loads new timeline items if they are not already loading. If there are more then one page of new items,
   * the already loaded items will be removed from state and only the new page is shown. Otherwise the new items will be
   * prepended to the current items.
   */
  loadNew(): void {
    if (this.stateSubject$.getValue().loadingNew) {
      return;
    }
    this.setLoadingNew(true);
    const senderId = this.context ? this.context.id : null;

    this.getLastUpdatedTimestamp()
      .pipe(switchMap(lastUpdate =>
        this.timelineItemService.getNewItems(
          senderId,
          this.type,
          TimelineItemService.TIMELINE_ITEM_PERMISSIONS,
          lastUpdate
        )))
      .subscribe(newItems => {
        let lastMostRecentItemId = null;
        let items = null;
        this.newItemsReferenceDate = newItems.data.lastRefreshDate;
        this.lastRefreshDate = newItems.data.newRefreshDate;
        const page = newItems.page;
        if (page.last) {
          items = this.setNew(this.mergeItems(page.content));
          lastMostRecentItemId = this.determineLastMostRecentItemId(items);
        } else {
          this.unsubscribeAllItemSubscriptions();
          items = this.setNew(page.content);
        }

        this.addState({
          mostRecentItemId: lastMostRecentItemId,
          newItemsCount: 0,
          items
        });
        _.forEach(page.content, item => this.subscribeItemDeleted(item.id, item.subscriptionInfo.token));
        _.forEach(page.content, item => this.subscribeItemUpdated(item.id, item.subscriptionInfo.token));
        this.unloadedItems = [];
        this.setLoadingNew(false);
        this.changeDetectorRef.detectChanges();
        this.scrollToMostRecentNewItem();
      }, () => this.setLoadingNew(false));
  }

  /**
   * Loads the next page of items if it is not already loading.
   */
  loadMore(): void {
    const state = this.stateSubject$.getValue();
    if (state.loading) {
      return;
    }
    this.setLoading(true);

    const offset = this.stateSubject$.getValue().items.length;

    this.requestPage(false, offset).subscribe(response => {
      const items = this.setNew(_.uniqBy(_.concat(this.stateSubject$.getValue().items, response.content), 'id'));
      _.forEach(response.content, item => this.subscribeItemDeleted(item.id, item.subscriptionInfo.token));
      _.forEach(response.content, item => this.subscribeItemUpdated(item.id, item.subscriptionInfo.token));
      const mostRecentItemId = this.determineLastMostRecentItemId(items);
      this.addState({page: response, loading: false, items, mostRecentItemId});
      this.changeDetectorRef.detectChanges();
      this.scrollToPageTopItem(offset);
    }, () => {
      this.setLoading(false);
    });
  }

  /**
   * Tracks item by id. Prevents unnecessary rerendering when an item is updated.
   * @param index the index
   * @param item the current item
   * @returns a unique identifier for a timeline item
   */
  trackItem(index: number, item: TimelineItem): string {
    return item.id;
  }

  /**
   * Update a timeline item
   * @param item the new version of the timeline item
   */
  updateItem(item: TimelineItem): void {
    const items = this.stateSubject$.getValue().items;
    const index = _.findIndex(items, ['id', item.id]);
    if (index !== -1) {
      items[index] = {...items[index], ...item};
      this.addState({items: items});
    }
  }

  /**
   * Returns an array of n to iterate in order to repeat skeletons when 'loading more'
   * @param n number of times to repeat skeletons
   * @returns an array to iterate
   */
  repeatSkeletons(n: number): any[] {
    return Array(n);
  }

  private getLastUpdatedTimestamp(): Observable<number> {
    return this.lastRefreshDate ?
      of(this.lastRefreshDate) :
      this.timelineItemService.getLastUpdate(this.type).pipe(map(response => response.at));
  }

  private onWebsocketReconnect(): void {
    setTimeout(() => this.updateItems(), this.socketReconnectDelays.TIMELINE_ITEMS_RELOAD_DELAY);
  }

  private updateItems(): void {
    this.updateCount(true);
    this.requestPage(true, 0).subscribe(page => {
      const newItems = this.setNew(
        _.filter(page.content,
          item => !_.some(this.stateSubject$.getValue().items,
            oldItem => oldItem.id === item.id)));

      if (newItems.length >= TimelineItemService.TIMELINE_PAGE_SIZE) {
        this.addState({
          items: newItems,
          page
        });
      } else {
        _.forEach(newItems, item => this.subscribeItemDeleted(item.id, item.subscriptionInfo.token));
        _.forEach(newItems, item => this.subscribeItemUpdated(item.id, item.subscriptionInfo.token));
        this.refreshState(page, newItems);
      }
    });
  }

  private refreshState(page: Page<TimelineItem>, newItems: TimelineItem[]): void {
    const updateItemIds = _(this.stateSubject$.getValue().items)
      .filter(item => !_.some(page.content, newItem => newItem.id === item.id))
      .map(item => item.id).value();
    if (updateItemIds.length) {
      this.timelineItemService
        .getItems(updateItemIds, this.context.id, this.type, TimelineItemService.TIMELINE_ITEM_PERMISSIONS)
        .subscribe(items => {
          const refreshedItems: { [key: string]: TimelineItem } = _.keyBy(page.content, 'id');
          const updatedItems = this.updateItemsFromResult({...refreshedItems, ...items},
            this.stateSubject$.getValue().items);
          this.addState({
            items: [...newItems, ...updatedItems],
            page
          });
        });
    } else {
      this.addState({
        items: page.content,
        page
      });
    }
  }

  private updateItemsFromResult(updatedItems: { [key: string]: TimelineItem }, oldItems: TimelineItem[]): TimelineItem[] {
    return this.orderItems(_(oldItems).filter(item => _.has(updatedItems, item.id))
      .map(oldItem => {
        if (oldItem.modified !== updatedItems[oldItem.id].modified) {
          return {...oldItem, ...updatedItems[oldItem.id]};
        } else {
          return oldItem;
        }
      }).value());
  }

  private requestPage(useCache: boolean, offset: number): Observable<Page<TimelineItem>> {
    const pageable = new Pageable(0, TimelineItemService.TIMELINE_PAGE_SIZE, offset);
    const params = {type: this.type, senderId: this.context.id, timelineInitDate: this.timelineInitDate.toISOString()};
    const headers = {};
    if (!useCache) {
      headers[EtagInterceptor.ETAG_ENABLED] = 'false';
    }

    return this.timelineItemService.getPage(pageable, {
      params,
      headers,
      permissions: TimelineItemService.TIMELINE_ITEM_PERMISSIONS
    });
  }

  private mergeItems(items: TimelineItem[]): TimelineItem[] {
    const oldItems = [...this.stateSubject$.getValue().items];
    const removed = _.remove(oldItems, item => _.some(items, {id: item.id}));

    _.forEach(removed, item => this.unsubscribeItemDeleted(item.id));
    _.forEach(removed, item => this.unsubscribeItemUpdated(item.id));

    const newItems = this.orderItems(_.concat(this.setNew(items), oldItems));

    const isLastPage = this.stateSubject$.getValue().page.last;

    while (!isLastPage && newItems.length && _.some(items, item => newItems[newItems.length - 1].id === item.id)) {
      newItems.pop();
    }

    return newItems;
  }

  private unsubscribeAllItemSubscriptions(): void {
    const deletedSubscriptions = _.values(this.itemDeletedSubscriptions);
    const updatedSubscriptions = _.values(this.itemUpdatedSubscriptions);
    _.concat(deletedSubscriptions, updatedSubscriptions).forEach(subscription => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  private determineLastMostRecentItemId(items: TimelineItem[]): string | null {
    if (_.some(items, item => item.isNew && item.author.id !== this.authService.getCurrentUserId())) {
      const foundItem = _.find(items, item => !item.isNew &&
        !(item.unread || (item.relevantShare ? item.relevantShare.unread : false)));
      return foundItem ? foundItem.id : null;
    } else {
      return null;
    }
  }

  private orderItems(items: TimelineItem[]): TimelineItem[] {
    return _.orderBy(items, [item => (item.unread || (item.relevantShare ? item.relevantShare.unread : false)) ? 1 : 0,
      item => this.getSortDate(item)], ['desc', 'desc']);
  }

  private getSortDate(item: TimelineItem): number {
    const relevantShare = item.relevantShare;
    if (!_.isEmpty(relevantShare)) {
      if (relevantShare.unread && relevantShare.stickyExpiry) {
        return relevantShare.stickyExpiry;
      } else {
        return relevantShare.created;
      }
    } else {
      return item.created;
    }
  }

  private isNew(item: TimelineItem): boolean {
    return !!(this.newItemsReferenceDate && this.newItemsReferenceDate < this.getSortDate(item));
  }

  private setNew(items: TimelineItem[]): TimelineItem[] {
    return _.map(items, item => {
      item.isNew = this.isNew(item);
      return item;
    });
  }

  private setLoading(loading: boolean): void {
    this.addState({
      loading
    });
  }

  private setLoadingNew(loadingNew: boolean): void {
    this.addState({
      loadingNew
    });
  }

  private addState(newStateObj: Partial<TimelineStreamState>): void {
    this.stateSubject$.next({
      ...this.stateSubject$.getValue(),
      ...newStateObj
    });
  }

  private addItem(event: { id: string, authorId: string, recipient: string, token?: string, personal?: boolean }): void {
    if (!this.isPersonal) {
      const senderId = this.context.id;
      this.timelineItemService.get(event.id, {
        params: {timelineType: this.type.toUpperCase(), senderId},
        permissions: TimelineItemService.TIMELINE_ITEM_PERMISSIONS,
        handleErrors: false
      }).subscribe(item => {
        const items = this.mergeItems([item]);
        item.isNew = event.recipient === this.context.id;
        this.subscribeItemDeleted(item.id, item.subscriptionInfo.token);
        this.subscribeItemUpdated(item.id, item.subscriptionInfo.token);
        this.addState({items});
      });
    } else if (this.authService.getCurrentUserId() === event.authorId && (_.isNil(event.personal) || event.personal)) {
      this.loadNew();
    } else if (this.unloadedItems.indexOf(event.id) === -1) {
      this.addUnloadedItem(event.id, event.token);
    }
  }

  private addUnloadedItem(id: string, token: string): void {
    this.unloadedItems.push(id);
    this.addState({newItemsCount: this.stateSubject$.getValue().newItemsCount + 1});
    this.subscribeItemDeleted(id, token);
    this.subscribeItemUpdated(id, token);
  }

  private subscribeItemUpdated(id: string, token: string): void {
    const route = '/topic/timeline.item';
    if (!this.itemUpdatedSubscriptions[id]) {
      this.itemUpdatedSubscriptions[id] = this.socketService.listenTo$(route, 'updated', id, token).subscribe(
        (item: TimelineItemUpdatedMessage) => this.updateItem(item.content)
      );
    }
  }

  private unsubscribeItemUpdated(id: string): void {
    const subscription = this.itemUpdatedSubscriptions[id];
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
    this.itemUpdatedSubscriptions = _.omit(this.itemUpdatedSubscriptions, id);
  }

  private subscribeItemDeleted(id: string, token: string): void {
    const route = '/topic/timeline.item';
    if (!this.itemDeletedSubscriptions[id]) {
      this.itemDeletedSubscriptions[id] = this.socketService.listenTo$(route, 'deleted', id, token).subscribe(
        () => this.removeItem(id)
      );
    }
  }

  private unsubscribeItemDeleted(id: string): void {
    const subscription = this.itemDeletedSubscriptions[id];
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
    this.itemDeletedSubscriptions = _.omit(this.itemDeletedSubscriptions, id);
  }

  private removeItem(id: string): void {
    let newItemsCount = this.stateSubject$.getValue().newItemsCount;
    let items = this.stateSubject$.getValue().items;
    let mostRecentItemId = this.stateSubject$.getValue().mostRecentItemId;
    if (this.unloadedItems.indexOf(id) >= 0) {
      this.unloadedItems = _.without(this.unloadedItems, id);
      newItemsCount--;
    }
    if (_.some(items, item => item.id === id)) {
      items = _.filter(this.stateSubject$.getValue().items, item => item.id !== id);
      mostRecentItemId = this.determineLastMostRecentItemId(items);
    }
    this.addState({newItemsCount, items, mostRecentItemId});
    this.unsubscribeItemDeleted(id);
    this.unsubscribeItemUpdated(id);
  }

  private updateCount(useCache: boolean = false): void {
    if (this.isPersonal) {
      this.getLastUpdatedTimestamp().pipe(switchMap(timestamp =>
        this.timelineItemService
          .getNewItemCount(this.type, timestamp, useCache))).subscribe(newItemsCount => this.addState({newItemsCount}));
    }
  }

  private onItemShared(event: { id: string, recipient: string, authorId: string }): void {
    const state = this.stateSubject$.getValue();
    const isRelevant = _.includes(this.contextSenders, event.recipient) ||
      (_.some(state.items, {id: event.id}) && !this.isPersonal);
    if (isRelevant) {
      this.removeItem(event.id);
      this.addItem(event);
    }
  }

  private loadContext(): void {
    if (this.isPersonal) {
      this.subscriptionService.getSubscriptionsByType('user', 'workspace', 'page').subscribe(subscriptions =>
        this.contextSenders = _.concat(_.map(subscriptions, 'targetId'), this.authService.getCurrentUserId()));
    } else {
      this.contextSenders = [this.context.id];
    }
  }

  private scrollToMostRecentNewItem(): void {
    const items = this.stateSubject$.getValue().items;

    if (items.length < 1 || items[0].isNew) {
      return;
    }

    const mostRecentNewItem = _.find(items, item => item.isNew);
    if (!!mostRecentNewItem) {
      const element = document.getElementById('item-' + mostRecentNewItem.id);
      element.scrollIntoView();
    }
  }

  /**
   * Scrolls to the top post of a new page when "load more" is clicked
   * @param offset the number that is the current offset for the loaded page in relation to the items
   */
  private scrollToPageTopItem(offset: number): void {
    const items = this.stateSubject$.getValue().items;

    if (items && offset > 0) {
      const itemId = items && items[offset] && items[offset].id;
      const element = itemId && document.getElementById('item-' + itemId);
      if (element) {
        element.scrollIntoView();
      }
    }
  }
}

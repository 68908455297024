(function (angular) {
  'use strict';

  LoginResetController.$inject = ["$injector", "$scope", "$state", "authService", "backendUrlService", "i18nService", "loginConfig", "passwordPattern", "emailPattern", "phonePattern", "token"];
  angular
      .module('coyo.login')
      .controller('LoginResetController', LoginResetController);

  function LoginResetController(
    $injector,
    $scope,
    $state,
    authService,
    backendUrlService,
    i18nService,
    loginConfig,
    passwordPattern,
    emailPattern,
    phonePattern,
    token
  ) {
    var vm = this;

    var errorCount = 0;

    vm.cssShakeClass = 'shake';
    vm.signal = loginConfig.signals.resetError;
    vm.passwordPattern = passwordPattern;
    vm.emailPattern = emailPattern;
    vm.phonePattern = phonePattern;
    vm.token = token;
    vm.sent = false;
    vm.error = null;
    vm.initialStep = true;
    vm.hasEmail = false;
    vm.emailRequestSent = false;

    vm.request = request;
    vm.reset = reset;
    vm.setUserJourney = setUserJourney;
    vm.passwordResetByAdminRequest = passwordResetByAdminRequest;


    function request(username) {
      vm.loading = true;
      authService.requestPassword(username).then(function () {
        _clearError();
        vm.sent = true;
      }).catch(function () {
        _setError('MODULE.LOGIN.RESET.REQUEST_ERROR');
      }).finally(function () {
        vm.loading = false;
      });
    }

    function reset(token, password) {
      vm.loading = true;
      authService.resetPassword(token, password).then(function (user) {
        $injector.get('ngxNotificationService').success('PASSWORD.CHANGE.SUCCESS');
        return authService.login(user.loginName, password).then(function () {
          _clearError();
          authService.getUser().then(function (user) {
            i18nService.setInterfaceLanguage(user.language);
          });
          $state.go('main');
        }).catch(function () {
          _setError('MODULE.LOGIN.AUTHENTICATION_FAILED');
        });
      }).catch(function (error) {
        _setError(error.status === 404 ? 'MODULE.LOGIN.RESET.RESET_ERROR' : 'PASSWORD.CHANGE.ERROR');
      });
    }

    function setUserJourney(hasEmail) {
      vm.initialStep = false;
      vm.hasEmail = hasEmail;
    }

    function passwordResetByAdminRequest(name, lastName, email, phone) {
      var passwordResetByAdminRequestFormData = {
        firstName: name,
        surName: lastName,
        email: email,
        phoneNumber: phone
      };
      authService.requestPasswordResetByAdmin(passwordResetByAdminRequestFormData).then(function () {
        // success message
        $injector.get('ngxNotificationService').success('MODULE.LOGIN.RESET.NO_EMAIL.REQUEST.SUCCESS');

        // success flag when request has been sent
        vm.emailRequestSent = true;
      }).catch(function () {
        _setError('MODULE.LOGIN.RESET.NO_EMAIL.REQUEST.ERROR');
      });
    }

    function _setError(errorMsg) {
      vm.loading = false;
      vm.error = errorMsg;
      if (++errorCount > loginConfig.misc.shakeAfterTimes) {
        $scope.$broadcast(vm.signal);
      }
    }

    function _clearError() {
      errorCount = 0;
      vm.error = null;
    }

    (function _init() {
      if (authService.isAuthenticated()) {
        $state.go('main');
      }
    })();
  }

})(angular);

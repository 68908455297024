(function (angular) {
  'use strict';

  EventsAppMainController.$inject = ["$timeout", "$injector", "EventModel", "Pageable", "sender", "eventsAppConfig", "selectionFilterService"];
  angular
      .module('coyo.apps.events')
      .controller('EventsAppMainController', EventsAppMainController);

  function EventsAppMainController($timeout, $injector, EventModel, Pageable, sender, eventsAppConfig,
                                   selectionFilterService) {
    var vm = this;
    var eventsStatusIconService = $injector.get('ngxStatusIconService');

    vm.sender = sender;

    vm.search = search;
    vm.filter = filter;
    vm.setParticipationStatusFilter = setParticipationStatusFilter;
    vm.filtersResettable = true;
    vm.resetFilters = resetFilters;
    vm.$onInit = _init;
    vm.dateSelectionChange = dateSelectionChange;
    vm.resetDateRangeFilter = resetDateRangeFilter;
    vm.onChangePagination = onChangePagination;

    /* ==================== */

    function search(searchTerm) {
      vm.query.term = searchTerm;
      _loadEvents();
    }

    function filter(dateRange) {
      _setDateRange(dateRange);
      _loadEvents();
    }

    function setParticipationStatusFilter(selected) {
      _setParticipationStatus(selected);
      _loadEvents();
    }

    function resetFilters() {
      _resetParticipationStatusFilter();
      _resetDateRangeFilter();
      _loadEvents();
    }

    function dateSelectionChange(dateSelection) {
      vm.dateRange[0] = dateSelection.start;
      vm.dateRange[1] = dateSelection.end;
      filter(vm.dateRange);
    }

    function resetDateRangeFilter() {
      _resetDateRangeFilter();
      _loadEvents();
    }

    /* ==================== */

    var PARAMS_IN_SEPARATE_ARGS = ['term', 'from', 'to']; // filter parameters that are passed as separate arguments

    function _loadEvents() {
      if (vm.loading) {
        return;
      }

      // perform search
      vm.loading = true;
      var term = vm.query.term;
      var from = vm.query.from ? (vm.query.from + 'T00:00:00') : undefined;
      var to = vm.query.to ? (vm.query.to + 'T23:59:59') : undefined;
      var sort = term ? ['_score,DESC', 'displayName.sort'] : 'displayName.sort';
      var newPageNumber = vm.page && vm.page._queryParams ? vm.page._queryParams._page : 0;
      var pageable = new Pageable(newPageNumber, eventsAppConfig.list.paging.pageSize, sort);
      var filters = angular.extend({host: [sender.id]}, _.omit(vm.query, PARAMS_IN_SEPARATE_ARGS));
      EventModel.searchWithFilter(term, from, to, pageable, filters).then(function (page) {
        vm.currentPage = page;
        vm.page = page;
        vm.filtersResettable = _isFiltersResettable();
      }).finally(function () {
        vm.loading = false;
      });
    }

    function _setParticipationStatus(participationStatus) {
      vm.query.participationStatus = participationStatus;
    }

    function _resetParticipationStatusFilter() {
      vm.participationStatusFilter.clearAll();
      _setParticipationStatus();
    }

    function _setDateRange(dateRange) {
      vm.query.from = dateRange && dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null;
      vm.query.to = dateRange && dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null;
    }

    function _resetDateRangeFilter() {
      vm.dateRange = [];
      _setDateRange(vm.dateRange);
    }

    function _isFiltersResettable() {
      return vm.participationStatusFilter.isSelected() || vm.dateRange.length > 0;
    }

    function onChangePagination(newPage) {
      $timeout(function () {
        _loadEvents();
      });
      return vm.page.page(newPage._queryParams._page);
    }

    function _init() {
      vm.query = {};
      vm.dateRange = [];

      var participationStatus = EventModel.getAllParticipationStatuses().map(function (status) {
        return {key: status, icon: eventsStatusIconService.getStatusIcon(status)};
      });
      vm.participationStatusFilter = selectionFilterService.builder().items(participationStatus).build();

      $timeout(function () {
        _loadEvents();
      });
    }
  }

})(angular);

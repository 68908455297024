(function (angular) {
  'use strict';

  ListController.$inject = ["$injector", "$scope", "$state", "app", "fields", "createFieldModalService", "addEntryModalService", "ListEntryModel", "Pageable", "socketService", "modalService", "listService", "listSortService", "fieldTypeRegistry", "backendUrlService"];
  angular
      .module('coyo.apps.list')
      .controller('ListController', ListController);

  function ListController($injector, $scope, $state, app, fields, createFieldModalService, addEntryModalService,
                          ListEntryModel, Pageable, socketService, modalService, listService, listSortService,
                          fieldTypeRegistry, backendUrlService) {

    var vm = this;
    var sortQuery = 'created,DESC';

    vm.app = app;
    vm.fields = fields;
    vm.loading = false;
    vm.search = '';

    vm.$onInit = init;
    vm.createField = createField;
    vm.addEntry = addEntry;
    vm.viewEntry = viewEntry;
    vm.editEntry = editEntry;
    vm.getValue = getValue;
    vm.getConfig = getConfig;
    vm.sort = sort;
    vm.save = save;
    vm.searchKeyPressed = searchKeyPressed;
    vm.deleteEntry = deleteEntry;
    vm.getDownloadUrl = getDownloadUrl;

    function listEntryUpdated($event) {
      var context = {
        senderId: app.senderId,
        appId: app.id,
        id: $event.content
      };

      var index = _.findIndex(vm.currentPage.content, function (entry) {
        return $event.content === entry.id;
      });

      if (index >= 0) {
        ListEntryModel.getWithPermissions(context, {}, ['edit', 'delete', 'comment']).then(function (result) {
          vm.currentPage.content[index] = result;
        });
      }
    }

    function searchKeyPressed() {
      _loadData();
    }

    function sort() {
      sortQuery = listSortService.createSortQuery();
      listSortService.storeSortConfig(vm.app.id);
      _loadData();
    }

    function createField() {
      createFieldModalService.open(app).then(function () {
        $state.go('^.configure');
      });
    }

    function addEntry() {
      addEntryModalService.open(app).then(function () {
        _loadData();
        _updateAppPermissions();
      });
    }

    function viewEntry(entry) {
      $state.go('.details', {
        id: entry.id
      });
    }

    function editEntry(entry) {
      $state.go('.details', {
        id: entry.id,
        mode: 'edit'
      });
    }

    function getValue(entry, fieldId) {
      return listService.getFieldValue(entry, fieldId);
    }

    function getConfig(key) {
      return fieldTypeRegistry.get(key).render;
    }

    function save(form) {
      vm.entry.senderId = vm.app.senderId;
      vm.entry.appId = vm.app.id;
      vm.entry.save().then(function () {
        $injector.get('ngxNotificationService').success('APP.LIST.ADD_ENTRY.SUCCESS');
        vm.entry = {};
        form.$setPristine();
        _updateAppPermissions();
      }).catch(function () {
        $injector.get('ngxNotificationService').error('APP.LIST.ADD_ENTRY.ERROR');
      });
    }

    function deleteEntry(entry) {
      vm.entry = entry;
      vm.entry.appId = vm.app.id;
      vm.entry.senderId = vm.app.senderId;
      modalService.confirmDelete({
        title: 'APP.LIST.MODAL.DELETE.TITLE',
        text: 'APP.LIST.MODAL.DELETE.TEXT',
        translationContext: {title: vm.entry.name}
      }).result.then(function () {
        vm.entry.delete().then(function () {
          _loadData();
        });
      });
    }

    function getDownloadUrl(fullList) {
      var context = {senderId: app.senderId, appId: app.id};
      var sort = fullList ? undefined : sortQuery;
      var query = fullList ? undefined : (vm.search || undefined);
      return backendUrlService.getUrl() + ListEntryModel.getDownloadUrl(sort, {query: query, hidden: fullList}, context);
    }

    function _loadData() {
      if (!vm.app._permissions.readEntries) {
        return;
      }

      vm.loading = true;
      var context = {senderId: app.senderId, appId: app.id};
      var pageable = new Pageable((vm.currentPage ? vm.currentPage.number : 0), 20, sortQuery);
      var queryParams = angular.extend({query: vm.search, _permissions: 'edit,delete'}, pageable.getParams());
      vm.lastQuery = queryParams;
      ListEntryModel.pagedQuery(pageable, queryParams, context).then(function (result) {
        if (vm.lastQuery === queryParams) {
          vm.currentPage = result;
          listService.initEntries(vm.currentPage.content, vm.fields);
        }
      }).finally(function () {
        if (vm.lastQuery === queryParams) {
          vm.loading = false;
        }
      });
    }

    function _updateAppPermissions() {
      app.getWithPermissions('', {}, ['createEntry']).then(function (result) {
        if (result._permissions) {
          angular.extend(app._permissions, result._permissions);
        }
      });
    }

    function init() {
      if (vm.fields && vm.fields.length) {
        listSortService.initSortConfig(vm.fields, vm.app.id);
        sort();
      }
      var unsubscribe = socketService.subscribe('/topic/app.list.entry.updated', listEntryUpdated, null, app.id, app.subscriptionInfo.token);
      $scope.$on('$destroy', function () {
        unsubscribe();
      });
    }
  }

})(angular);

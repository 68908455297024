import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {TextModule} from '@shared/text/text.module';
import {HelpComponent} from './help/help.component';
import './help/help.component.downgrade';

/**
 * Module for the help component
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    TextModule
  ],
  declarations: [
    HelpComponent
  ],
  exports: [
    HelpComponent
  ],
  entryComponents: [
    HelpComponent
  ]
})
export class HelpModule {}

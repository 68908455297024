import {Injectable} from '@angular/core';
import {EventDistributionService} from '@app/analytics/event-distributor/event-distribution.service';
import {EventTypes} from '@app/analytics/event-types';

/**
 * The main service for handling entity visited events.
 */
@Injectable({
  providedIn: 'root'
})
export class EntityVisitedService {

  constructor(private eventDistributionService: EventDistributionService) {
  }

  handleEntityVisitedEvent(time: Date, entityId: string, entityType: string): void {
    this.eventDistributionService.addEvent(EventTypes.ENTITY_VISITED, {
      time: time.toISOString(),
      payload: {
        entityId: entityId,
        entityType: entityType,
        client: 'web',
      }
    });
  }

}

(function (angular, Config) {
  'use strict';

  // When all zmdi icons are migrated in this context, they icon property can be removed.
  // The matIcon property is the new value for Angular Material Icons via COYO Icon Library
  angular
      .module('commons.config')
      .constant('coyoConfig', (function () {
        var entityTypes = {
          'default': {icon: 'circle-o', matIcon: 'plus-circle-outlined', color: '#999', label: '-', plural: '-'},
          'workspace': {icon: 'apps', matIcon: 'workspace', color: '#FFA800', label: 'ENTITY_TYPE.WORKSPACE', plural: 'ENTITY_TYPE.WORKSPACES'},
          'page': {icon: 'layers', matIcon: 'page', color: '#C16AA5', label: 'ENTITY_TYPE.PAGE', plural: 'ENTITY_TYPE.PAGES'},
          'user': {icon: 'account', matIcon: 'user', color: '#444', label: 'ENTITY_TYPE.USER', plural: 'ENTITY_TYPE.USERS'},
          'file': {icon: 'file', matIcon: 'generic-file', color: '#43b19e', label: 'ENTITY_TYPE.FILE', plural: 'ENTITY_TYPE.FILES'},
          'timeline-item': {icon: 'comment-text', matIcon: 'timeline-app', color: '#e02a6f', label: 'ENTITY_TYPE.TIMELINE_ITEM', plural: 'ENTITY_TYPE.TIMELINE_ITEMS'},
          'timeline-share': {label: 'ENTITY_TYPE.TIMELINE_SHARE'},
          'app': {icon: 'puzzle-piece', matIcon: 'puzzle-piece', label: 'ENTITY_TYPE.APP', color: '#317DC1'},
          'event': {icon: 'calendar', matIcon: 'event-app', label: 'ENTITY_TYPE.EVENT', plural: 'ENTITY_TYPE.EVENTS', color: '#990066'},
          'message': {icon: 'comments', matIcon: 'timeline-app', label: 'ENTITY_TYPE.MESSAGE', color: '#1ec3cd'},
          'message-channel': {icon: 'comments', matIcon: 'timeline-app', label: 'ENTITY_TYPE.MESSAGE_CHANNEL', color: '#1ec3cd'},
          'comment': {label: 'ENTITY_TYPE.COMMENT'},
          'email-activation': {label: 'ENTITY_TYPE.EMAIL_ACTIVATION'},
          'file-library': {label: 'ENTITY_TYPE.FILE_LIBRARY'},
          'landing-page': {icon: 'globe', matIcon: 'globe', color: '#317DC1', label: 'ENTITY_TYPE.LANDING_PAGE', plural: 'ENTITY_TYPE.LANDING_PAGES'},
          'language': {label: 'ENTITY_TYPE.LANGUAGE'},
          'like': {label: 'ENTITY_TYPE.LIKE'},
          'role': {label: 'ENTITY_TYPE.ROLE'},
          'subscription': {label: 'ENTITY_TYPE.SUBSCRIPTION'},
          'user-push-device': {label: 'ENTITY_TYPE.USER_PUSH_DEVICE'},
          'widget': {label: 'ENTITY_TYPE.WIDGET'}
        };

        return {
          backendUrl: Config.backendUrl,
          backendUrlStrategy: Config.backendUrlStrategy,
          applicationName: Config.applicationName,
          version: {
            major: 32,
            minor: 6,
            patch: 6,
            qualifier: 'RELEASE'
          },
          debug: Config.debug !== false,
          autoRefreshTokens: true,
          cookies: {
            user: 'coyoUser'
          },
          entityTypes: entityTypes,
          notificationTypeIcons: {
            'blog-article': {icon: 'collection-text', color: '#000'},
            'default': {icon: 'notifications', color: '#000'},
            'file': {icon: 'file', color: '#000'}, // backwards compatibility
            'file-created': {icon: 'file-plus', color: '#000'},
            'file-updated': {icon: 'cloud-upload', color: '#000'},
            'report': {icon: 'alert-circle', color: '#000'},
            'user-follow': {icon: 'account-add', color: '#000'},
            'wiki-article': {icon: 'library', color: '#000'},
            'forum-thread': {icon: 'help', color: '#000'},
            'workspace-approved': {icon: 'check', color: '#000'},
            'workspace-invited': {icon: 'account-add', color: '#000'},
            'workspace-joined': {icon: 'plus-circle-o', color: '#000'},
            'workspace-rejected': {icon: 'close-circle-o', color: '#000'},
            'workspace-removed': {icon: 'close-circle-o', color: '#000'},
            'workspace-requested': {icon: 'hourglass-alt', color: '#000'},
            'event-invited': {icon: 'account-add', color: '#000'},
            'event-attending': {icon: 'plus-circle-o', color: '#000'},
            'event-maybe-attending': {icon: 'help-outline', color: '#000'},
            'list-entry': {icon: 'view-list', color: '#000'},
            'task': {icon: 'assignment-check', color: '#000'},
            'form-entry': {icon: 'inbox', color: '#000'}
          },
          fileLibrary: {
            senderTypes: [
              angular.extend({
                permission: 'ACCESS_LANDING_PAGES',
                query: /*@ngInject*/ ["LandingPageModel", "Page", function (LandingPageModel, Page) {
                  return LandingPageModel.queryWithPermissions({all: true}, {}, ['createFile']).then(function (data) {
                    return new Page({
                      content: data,
                      first: true,
                      last: true,
                      number: 0,
                      numberOfElements: data.length,
                      size: data.length,
                      totalElements: data.length,
                      totalPages: 1
                    }, {}, {
                      resultMapper: function (item) {
                        return new LandingPageModel(item);
                      }
                    });
                  });
                }]
              }, entityTypes['landing-page']),
              angular.extend({
                permission: 'ACCESS_PAGES',
                query: /*@ngInject*/ ["PageModel", "Pageable", "page", "pageSize", function (PageModel, Pageable, page, pageSize) {
                  var pageable = new Pageable(page, pageSize, 'displayName.sort');
                  return PageModel.pagedQueryWithPermissions(pageable, {}, {}, ['createFile']);
                }]
              }, entityTypes.page),
              angular.extend({
                permission: 'ACCESS_WORKSPACES',
                query: /*@ngInject*/ ["WorkspaceModel", "Pageable", "page", "pageSize", function (WorkspaceModel, Pageable, page, pageSize) {
                  var pageable = new Pageable(page, pageSize, 'displayName.sort');
                  return WorkspaceModel.pagedQueryWithPermissions(pageable, {}, {}, ['createFile']);
                }]
              }, entityTypes.workspace)
            ]
          },
          rejectReason: {
            transitionStarted: 'state-transition-started'
          },
          i18n: {
            path: '/assets/messages/',
            suffix: '.json'
          },
          versionString: function () {
            var version = this.version;
            return version.major + '.' + version.minor + '.' + version.patch + '-' + version.qualifier;
          }
        };
      })());

})(angular, window.Config); //eslint-disable-line angular/window-service


(function (angular) {
  'use strict';

  senderNavigationUpdateService.$inject = ["SenderModel"];
  angular.module('commons.ui')
      .factory('senderNavigationUpdateService', senderNavigationUpdateService);

  /**
   * @ngdoc service
   * @name commons.ui.senderNavigationUpdateService
   *
   * @description
   * Can be used to update app navigation and/or prepare app navigation data response to be used in frontend
   *
   * @requires SenderModel
   */
  function senderNavigationUpdateService(SenderModel) {

    return {
      reorderNavigation: reorderNavigation,
      deleteNavigationGroup: deleteNavigationGroup,
      createNavigationGroup: createNavigationGroup,
      prepareNavigationUpdateResponse: prepareNavigationUpdateResponse
    };

    /**
     * @ngdoc method
     * @name commons.ui.senderNavigationUpdateService#deleteNavigationGroup
     * @methodOf commons.ui.senderNavigationUpdateService
     *
     * @description
     * Deletes an app navigation group of the affected sender.
     *
     * @param {string} senderId The id of the sender the app navigation belongs to.
     * @param {int} index       The index of the navigation group that shall be deleted.
     *
     * @returns {promise}       The promise of the senders updated app navigation.
     */
    function deleteNavigationGroup(senderId, index) {
      var senderModel = new SenderModel({id: senderId});
      return senderModel.deleteNavigationGroup(index);
    }

    /**
     * @ngdoc method
     * @name commons.ui.senderNavigationUpdateService#createNavigationGroup
     * @methodOf commons.ui.senderNavigationUpdateService
     *
     * @description
     * Creates a new empty app navigation group for the given sender.
     *
     * @param {string} senderId The id of the sender the app navigation belongs to.
     *
     * @returns {promise}       The promise of the senders updated app navigation.
     */
    function createNavigationGroup(senderId) {
      var senderModel = new SenderModel({id: senderId});
      return senderModel.createNavigationGroup();
    }

    /**
     * @ngdoc method
     * @name commons.ui.senderNavigationUpdateService#reorderNavigation
     * @methodOf commons.ui.senderNavigationUpdateService
     *
     * @description
     * Reorders the apps inside the app navigation for the given sender.
     *
     * @param {string} senderId       The id of the sender the app navigation belongs to.
     * @param {array} appNavigation   The reorderes app navigation from the given sender
     * @returns {promise}             The promise of the senders updated app navigation.
     */
    function reorderNavigation(senderId, appNavigation) {
      var senderModel = new SenderModel({id: senderId});
      return senderModel.reorderNavigation(appNavigation);
    }
  }

  /**
   * @ngdoc method
   * @name commons.ui.senderNavigationUpdateService#prepareNavigationUpdateResponse
   * @methodOf commons.ui.senderNavigationUpdateService
   *
   * @description
   * Transform the app navigation update result in the same structure that was given to the update method.
   *
   * @param {array} navigationGroups A list of all navigation groups in a structure provided by the backend.
   *
   * @returns {Array} A list of the given navigation groups in a structure used by the frontend.
   */
  function prepareNavigationUpdateResponse(navigationGroups) {
    var appNavigationGroups = [];
    _.forEach(navigationGroups, function (navigationGroup) {
      appNavigationGroups.push({
        name: navigationGroup.name,
        apps: _.map(navigationGroup.apps, 'id')
      });
    });
    return appNavigationGroups;
  }

})(angular);

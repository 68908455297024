import {Inject, Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LocalStorageService} from '@core/storage/local-storage/local-storage.service';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {User} from '@domain/user/user';
import {Ng1MobileEventsService} from '@root/typings';
import {FileDetailsModalComponent} from '@shared/files/file-details-modal/file-details-modal.component';
import {NG1_MOBILE_EVENTS_SERVICE} from '@upgrade/upgrade.module';
import {EMPTY, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileDetailsModalService {

  static readonly LOCAL_STORAGE_KEY: string = 'isEngageWebView';

  constructor(private dialog: MatDialog, private ngZone: NgZone,
              private localStorageService: LocalStorageService,
              @Inject(NG1_MOBILE_EVENTS_SERVICE) private readonly mobileEventsService: Ng1MobileEventsService) {
  }

  open(
    files: FilePreview[],
    currentIndex: number,
    linkToFileLibrary: boolean,
    showAuthors?: boolean,
    fileAuthors?: { [key: string]: User },
    appIdOrSlug?: string,
    filesTotal?: number,
    loadNext?: (currentIndex: number) => Promise<{file: FilePreview, author?: User}>
  ): Observable<unknown> {

    if (this.isEngageWebView()) {
      this.notifyApp(this.mobileEventsService, {
        senderId: files[currentIndex ?? 0].senderId,
        fileId: files[currentIndex ?? 0].id
      });
      return EMPTY;
    } else {
      return this.dialog.open(FileDetailsModalComponent, {
        height: '100%',
        width: '100%',
        data: {
          files,
          currentIndex,
          linkToFileLibrary,
          showAuthors,
          fileAuthors,
          appIdOrSlug,
          filesTotal,
          loadNext
        },
        panelClass: 'file-details-modal'
      }).afterClosed();
    }
  }

  openLegacy(files: FilePreview[],
             currentIndex: number,
             linkToFileLibrary: boolean,
             showAuthors: boolean,
             fileAuthors: { [key: string]: User },
             appIdOrSlug: string,
             filesTotal: number,
             loadNext: (currentIndex: number) => Promise<{file: FilePreview, author?: User}>
  ): Promise<unknown> {
    return this.ngZone.run(() =>
      this.open(files, currentIndex, linkToFileLibrary, showAuthors, fileAuthors, appIdOrSlug, filesTotal, loadNext).toPromise());
  }

  // Propergage informations about clicked file to native apps
  private notifyApp(mobileEventsService: Ng1MobileEventsService, file: {senderId: string, fileId: string}): void {
      mobileEventsService.propagate('transition', {name: 'main.fileLibrary', params: file});
  }

  // Checks if service is called within web view
  private isEngageWebView(): boolean {
    return this.localStorageService.getValue<boolean>(FileDetailsModalService.LOCAL_STORAGE_KEY, false);
  }
}

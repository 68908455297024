import {Injectable} from '@angular/core';
import {Target} from '@domain/sender/target';
import {Action, State, StateContext} from '@ngxs/store';
import {WidgetWikiArticleResponse} from '@widgets/wiki-article/widget-wiki-article-response';
import {WikiArticleWidgetService} from '@widgets/wiki-article/wiki-article-widget/wiki-article-widget.service';
import {Observable, of} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {Init, SetLoading} from './wiki-article-widget.actions';

export interface WikiArticleWidgetsStateModel {
  [key: string]: WikiArticleWidgetStateModel;
}

export interface WikiArticleWidgetStateModel {
  article: WidgetWikiArticleResponse;
  sender: {
    displayName: string,
    target: Target
  };
  loading: boolean;
}

@Injectable({providedIn: 'root'})
@State<WikiArticleWidgetsStateModel>({
  name: 'wikiArticleWidgetState',
  defaults: {}
})
export class WikiArticleWidgetState {

  constructor(private wikiArticleWidgetService: WikiArticleWidgetService) {

  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<WikiArticleWidgetsStateModel>, action: SetLoading): void {
    ctx.patchState({
      [action.id]: {
        ...ctx.getState()[action.id],
        loading: action.loading
      }
    });
  }

  @Action(Init)
  init(ctx: StateContext<WikiArticleWidgetsStateModel>, action: Init): Observable<void> {
    return ctx.dispatch(new SetLoading(action.id, true))
      .pipe(switchMap(() => this.wikiArticleWidgetService.getWikiArticle(action.articleId)))
      .pipe(tap(article => ctx.patchState({
        [action.id]: {
          ...ctx.getState()[action.id],
          sender: {
            displayName: article.senderName,
            target: article.senderTarget
          },
          article
        }
      }))).pipe(catchError(() =>
        of(null)), switchMap(() => ctx.dispatch(new SetLoading(action.id, false))));
  }
}

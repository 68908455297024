import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit} from '@angular/core';
import {OembedService} from '@domain/oembed/oembed.service';
import {WidgetComponent} from '@widgets/api/widget-component';
import * as _ from 'lodash';
import {VideoWidget} from '../video-widget';

@Component({
  selector: 'coyo-video-widget',
  templateUrl: './video-widget.component.html',
  styleUrls: ['./video-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoWidgetComponent extends WidgetComponent<VideoWidget> implements OnInit, OnChanges {
  videoUrl: string;
  ratio: number;
  error: boolean;
  videoHtml: string;

  constructor(cd: ChangeDetectorRef, private oembedService: OembedService) {
    super(cd);
   }

  ngOnInit(): void {
    this.renderVideo();
  }

  ngOnChanges(): void {
    if (this.videoUrl !== this.widget.settings._url) {
      this.renderVideo();
    }
  }

  private renderVideo(): void {
    if (this.widget.settings._backendData) {
      this.videoUrl = this.widget.settings._backendData.videoUrl;
      this.ratio = this.widget.settings._backendData.ratio;
    } else {
      this.videoUrl = this.widget.settings._url;
      const videoElement = this.oembedService.createByUrl(this.videoUrl);
      if (videoElement) {
        videoElement.setAttribute('width', '100%');
        videoElement.setAttribute('height', '100%');
        this.videoHtml = videoElement.outerHTML;
        this.ratio = 56.25; // fluid video size from https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
      } else {
        this.error = true;
      }
    }
  }
}

import {Injectable, Type} from '@angular/core';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {FilePickerItemService} from '@app/file-picker/file-picker-item.service';
import {FilePickerUploadItem} from '@app/file-picker/file-picker-upload-item/file-picker-upload-item';
import {FilePickerUploadItemComponent} from '@app/file-picker/file-picker-upload-item/file-picker-upload-item.component';
import {AuthService} from '@core/auth/auth.service';
import {FileService} from '@domain/file/file/file.service';
import {Page} from '@domain/pagination/page';
import {SenderService} from '@domain/sender/sender/sender.service';
import {Observable} from 'rxjs';

/**
 * Service responsible for handling temporary upload items
 */
@Injectable({
  providedIn: 'root',
})
export class FilePickerUploadItemService extends FilePickerItemService {
  constructor(
    protected readonly authService: AuthService,
    protected readonly fileService: FileService,
    protected readonly senderService: SenderService) {
    super();
  }

  getChildren(filePickerItem: FilePickerItem, pageIdx: number): Observable<Page<FilePickerItem>> {
    throw new Error('Method not implemented.');
  }

  getComponentType(): Type<FilePickerItemBaseDirective<FilePickerItem>> {
    return FilePickerUploadItemComponent;
  }

  getCapabilities(item: FilePickerItem): FilePickerItemServiceCapability {
    // tslint:disable-next-line:no-bitwise
    return FilePickerItemServiceCapability.NONE;
  }

  getResponsibilities(): Type<FilePickerItem>[] {
    return [FilePickerUploadItem];
  }

  isSelectable(item: FilePickerItem): boolean {
    return false;
  }
}

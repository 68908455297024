import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Sender} from '@domain/sender/sender';

/**
 * Shows a sender avatar with its name as a sender link.
 */
@Component({
  selector: 'coyo-sender-list-item',
  templateUrl: './sender-list-item.component.html',
  styleUrls: ['./sender-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderListItemComponent {

  /**
   * The sender to show.
   */
  @Input() sender: Sender;

  /**
   * Optional flag for removing the links from the avatar and name.
   */
  @Input() noLink?: boolean = false;

  /**
   * Subline to be shown under the sender name.
   */
  @Input() subline?: string;

  /**
   * The size of the sender avatar.
   */
  @Input() avatarSize?: 'xs' | 'sm' | 'md' = 'md';

  constructor() {}
}

import {NgModule} from '@angular/core';
import {TimelineModule} from '@app/timeline/timeline.module';
import {APP_CONFIGS} from '@apps/api/app-config';
import {AppStateGenerator} from '@apps/api/app-state-generator';
import {TimelineAppSettingsComponent} from '@apps/timeline/settings/timeline-app-settings.component';
import {TimelineAppComponent} from '@apps/timeline/timeline-app.component';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {TIMELINE_CONFIG} from './timeline-app.config';

// Disabled until app chooser is migrated
export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: AppStateGenerator.buildStates(TIMELINE_CONFIG)});

@NgModule({
  imports: [
    uiRouterModuleChild,
    CoyoCommonsModule,
    CoyoFormsModule,
    TimelineModule
  ],
  providers: [
    // Disabled until app chooser is migrated
    {provide: APP_CONFIGS, useValue: TIMELINE_CONFIG, multi: true}
  ],
  declarations: [TimelineAppComponent, TimelineAppSettingsComponent],
  entryComponents: []
})
export class TimelineAppModule {
}

(function (angular) {
  'use strict';

  scrollBehaviourService.$inject = ["$rootScope", "$window"];
  angular
      .module('commons.ui')
      .factory('scrollBehaviourService', scrollBehaviourService);

  /**
   * @ngdoc service
   * @name commons.ui.scrollBehaviourService
   *
   * @description
   * Provides methods for enabling or disabling the body scrolling when notifications or quick entity search are opened
   *
   * @requires $rootScope
   */
  function scrollBehaviourService($rootScope, $window) {

    var bodyScrollOffset = 0;

    return {
      enableBodyScrolling: enableBodyScrolling,
      disableBodyScrolling: disableBodyScrolling,
      disableBodyScrollingOnXsScreen: disableBodyScrollingOnXsScreen
    };

    /**
     * @ngdoc method
     * @name commons.ui.scrollBehaviourService#enableBodyScrolling
     * @methodOf commons.ui.scrollBehaviourService
     *
     * @description
     * Makes the body scrollable
     *
     */
    function enableBodyScrolling() {
      _getBodyElement().removeClass('no-scroll');
      $window.scrollTo(0, bodyScrollOffset);
    }

    /**
     * @ngdoc method
     * @name commons.ui.scrollBehaviourService#disableBodyScrolling
     * @methodOf commons.ui.scrollBehaviourService
     *
     * @description
     * Makes the body not scrollable
     *
     */
    function disableBodyScrolling() {
      var bodyElement = _getBodyElement();
      bodyScrollOffset = angular.element(document).find('html').scrollTop || angular.element(document).find('body').scrollTop || 0;
      bodyElement.addClass('no-scroll');
    }

    function disableBodyScrollingOnXsScreen() {
      if (isScreenSizeXs()) {
        disableBodyScrolling();
      }
    }

    function _getBodyElement() {
      return angular.element(document).find('html');
    }

    function isScreenSizeXs() {
      return $rootScope.screenSize.isXs;
    }
  }
})(angular);

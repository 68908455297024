import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {CommentRequest} from '@domain/comment/comment-request';
import {DomainService} from '@domain/domain/domain.service';
import {Page} from '@domain/pagination/page';
import {Sender} from '@domain/sender/sender';
import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {bufferTime, filter, map, mergeMap, share, take} from 'rxjs/operators';
import {Comment} from './comment';

/**
 * A comment page request.
 */
interface CommentPageRequest {
  targetType: string;
  requestSubject: Subject<string>;
  bulkCommentsSubjects: Observable<{ [key: string]: Page<Comment> }>;
}

/**
 * Service to manage comments.
 */
@Injectable({
  providedIn: 'root'
})
export class CommentService extends DomainService<CommentRequest, Comment> {

  /**
   * The number of milliseconds to throttle bulk requests of this service.
   */
  static readonly THROTTLE: number = 50;

  private bulkRequests: CommentPageRequest[] = [];

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  getBaseUrl(): string {
    return '/web/comments';
  }

  /**
   * Gets the initial page for comments and bulks simulaneous requests.
   * @param targetId The target id
   * @param targetType The target type
   * @returns An observable that emits the initial comment page of the requested target
   */
  getInitialPage(targetId: string, targetType: string): Observable<Page<Comment>> {
    const request = this.bulkRequests.find(r => r.targetType === targetType)
      || this.buildRequest(targetType);
    setTimeout(() => request.requestSubject.next(targetId));
    return request.bulkCommentsSubjects
      .pipe(filter(pages => _.has(pages, targetId)))
      .pipe(map(pages => pages[targetId]))
      .pipe(take(1));
  }

  /**
   * Get the original author of a comment.
   *
   * @param id The comment id
   *
   * @returns An observable resolving the sender representation of the original author and then completes.
   */
  getOriginalAuthor(id: string): Observable<Sender> {
    return this.http.get<Sender>(this.getBaseUrl() + '/' + id + '/original-author');
  }

  private buildRequest(targetType: string): CommentPageRequest {
    const subject = new Subject<string>();
    const request: CommentPageRequest = {
      targetType,
      requestSubject: subject,
      bulkCommentsSubjects: this.setup(targetType, subject)
    };
    this.bulkRequests.push(request);
    return request;
  }

  private setup(targetType: string, requestSubject: Subject<string>): Observable<{ [key: string]: Page<Comment> }> {
    return requestSubject
      .pipe(
        bufferTime(CommentService.THROTTLE),
        filter(ids => ids && ids.length > 0),
        mergeMap(ids => this.request(targetType, ids)),
        share<{ [key: string]: Page<Comment> }>()
      );
  }

  private request(targetType: string, targetIds: string[]): Observable<{ [key: string]: Page<Comment> }> {
    return this.http.get<{ [key: string]: Page<Comment> }>(`/web/comments/${targetType}`, {
      headers: {
        etagBulkId: 'ids'
      },
      params: {
        ids: targetIds,
        _permissions: '*'
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {FileLibraryFilePickerService} from '@app/file-library/file-library-file-picker-service/file-library-file-picker.service';
import {Document} from '@domain/file/document';
import {Sender} from '@domain/sender/sender';
import {CropSettings, SelectFileOptions} from '@shared/select-file/select-file-options';
import {Observable} from 'rxjs';

/**
 * Service opens a file library dialog for selecting file library documents
 */
@Injectable({
  providedIn: 'root'
})
export class SelectFileDialogService {

  constructor(private readonly fileLibraryFilePickerService: FileLibraryFilePickerService) {
  }

  /**
   * Open a file library select dialog.
   *
   * @param sender The sender of the file library to open
   * @param options The select options
   * @param cropSettings Settings if cropping of images should be used
   * @param selectedFiles Array of previously selected files that should be selected by default inside the dialog
   *
   * @returns An Array of selected documents
   */
  open(sender: Sender,
       options: SelectFileOptions = {},
       cropSettings: CropSettings = {},
       selectedFiles: Document[] = []): Observable<Document[]> {
    const filterContentTypes: string[] = [];
    if (options.filterContentType) {
      if (Array.isArray(options.filterContentType)) {
        filterContentTypes.push(...options.filterContentType);
      } else {
        filterContentTypes.push(options.filterContentType as string);
      }
    }
    return this.fileLibraryFilePickerService.open(sender, {selectedFiles, selectionType: options.selectMode, contentTypes: filterContentTypes});
  }
}

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';

/**
 * Service providing information about the current visibility state of the page
 * using the new Page Visibility API.
 */
@Injectable({
  providedIn: 'root'
})
export class PageVisibilityService {
  private visible$: BehaviorSubject<boolean>;

  constructor(@Inject(DOCUMENT) private document: Document) {
    const hasEventListener = typeof this.document.addEventListener !== 'undefined';
    const [hidden, visibilityChange] = this.getPropertyNames();
    if (hasEventListener && hidden && visibilityChange) {
      this.visible$ = new BehaviorSubject<boolean>(!this.document[hidden]);
      this.document.addEventListener(visibilityChange, () => this.visible$.next(!this.document[hidden]), false);
    }
  }

  /**
   * Returns an observable holding the current visibility state of the page.
   *
   * @returns the visibility state observable
   */
  isVisible$(): Observable<boolean> {
    return this.visible$
      ? this.visible$.asObservable()
      : throwError('EventListener or Page Visibility API not supported');
  }

  private getPropertyNames(): [string | null, string | null] {
    const doc = this.document as any;
    if (typeof doc.webkitHidden !== 'undefined') {
      return ['webkitHidden', 'webkitvisibilitychange'];
    } else if (typeof doc.msHidden !== 'undefined') {
      return ['msHidden', 'msvisibilitychange'];
    } else if (typeof doc.hidden !== 'undefined') {
      return ['hidden', 'visibilitychange'];
    } else {
      return [null, null];
    }
  }
}

import {APP_INITIALIZER, NgModule} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DeleteConfirmationDialogComponent} from '@shared/dialog/delete-confirmation/delete-confirmation-dialog.component';
import {TextModule} from '@shared/text/text.module';
import {TransitionService} from '@uirouter/angular';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import './delete-confirmation/delete-confirmation.service.downgrade';

export function stateInterceptor(matDialog: MatDialog, transitionService: TransitionService): Function {
  return () => transitionService.onBefore({}, () => {
    matDialog.closeAll();
  });
}

/**
 * Module that contains a component used to display expandable text
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    TextModule
  ],
  declarations: [
    DeleteConfirmationDialogComponent,
    ConfirmationDialogComponent
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: stateInterceptor,
    deps: [MatDialog, TransitionService],
    multi: true
  }]
})
export class DialogModule {
}

 import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
 import {DownloadModule} from '@shared/download/download.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {MaterialModule} from '@shared/material/material.module';
import {PreviewModule} from '@shared/preview/preview.module';
import {SelectFileModule} from '@shared/select-file/select-file.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {LightBoxComponent} from '@widgets/media/light-box/light-box.component';
import {MEDIA_WIDGET} from '@widgets/media/media-widget-config';
import {MediaWidgetSettingsComponent} from '@widgets/media/media-widget-settings/media-widget-settings.component';
import {MediaWidgetComponent} from '@widgets/media/media-widget/media-widget.component';
import {AutosizeModule} from 'ngx-autosize';
import {MediaWidgetSettingsRowComponent} from './media-widget-settings-row/media-widget-settings-row.component';
import {VideoPreviewCoverComponent} from './video-preview-cover/video-preview-cover.component';

/**
 * Module providing the Media widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    HelpModule,
    CoyoFormsModule,
    UpgradeModule,
    AutosizeModule,
    SelectFileModule,
    PreviewModule,
    MaterialModule,
    DownloadModule
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: MEDIA_WIDGET, multi: true}
  ],
  declarations: [
    MediaWidgetComponent,
    MediaWidgetSettingsComponent,
    VideoPreviewCoverComponent,
    MediaWidgetSettingsRowComponent,
    LightBoxComponent
  ],
  entryComponents: [
    MediaWidgetComponent,
    MediaWidgetSettingsComponent,
    LightBoxComponent
  ]
})
export class MediaWidgetModule {
}

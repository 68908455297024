import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SearchResultsList} from './search-results-list';

@Injectable({
  providedIn: 'root'
})
export class ExternalSearchResultsService {

  constructor(private http: HttpClient) { }

  getExternalSearchesResults(params: {term: string, limit: string}): Observable<SearchResultsList[]> {
    return this.http.get<SearchResultsList[]>('web/search/external', {params: params});
  }
}

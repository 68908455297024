<div *ngIf="state$ | async as state" [class.edit-mode]="editMode$ | async" cdkDropListGroup
     class="widget-layout widget-slot-{{renderStyle}} clearfix">
  <div *ngFor="let row of state.settings.rows; let $index = index; trackBy: trackSettingsRow"
       class="widget-layout-row">
    <div *ngIf="(editMode$ | async) && !simpleMode" class="widget-layout-row-options">
      <button (click)="addRow($index)" [attr.aria-label]="'WIDGET.LAYOUT.ADD_ROW' | translate"
              class="widget-layout-btn widget-layout-row-create">
        <i class="zmdi zmdi-plus"></i>
      </button>
      <button (click)="removeRow($index)"
              [attr.aria-label]="'WIDGET.LAYOUT.DELETE_ROW' | translate"
              class="widget-layout-btn widget-layout-row-delete">
        <i class="zmdi zmdi-minus"></i>
      </button>
    </div>

    <div class="row">
      <div *ngFor="let slot of row.slots; trackBy: trackSlot"
           class="widget-layout-row-slot-wrapper col-md-{{slot.cols}}">
        <coyo-widget-slot
          [canManage]="canManage"
          [editScope]="editScope"
          [embedded]="true"
          [language]="language"
          [name]="buildSlotName(state.name, slot.name)"
          [parent]="parent"
          [renderStyle]="renderStyle"
          [revisionBased]="revisionBased"
          [simpleMode]="simpleMode">
        </coyo-widget-slot>
      </div>
    </div>
  </div>
  <div *ngIf="(editMode$ | async) && !simpleMode" class="widget-layout-row-options last">
    <button (click)="addRow(state.settings.rows?.length)" [attr.aria-label]="'WIDGET.LAYOUT.ADD_ROW' | translate"
            class="widget-layout-btn widget-layout-row-create">
      <i class="zmdi zmdi-plus"></i>
    </button>
  </div>
</div>

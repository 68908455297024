<ng-container *ngIf="(item$ | async) as itemStateModel">
  <button (menuClosed)="setOpened(false)"
          (menuOpened)="setOpened(true)"
          [attr.aria-label]="'CONTEXT_MENU.SHOW' | translate"
          [matMenuTriggerFor]="menu"
          (click)="preventClickPropagation($event)"
          mat-icon-button
          type="button">
    <mat-icon svgIcon="more-horizontal"></mat-icon>
  </button>
  <mat-menu #menu xPosition="before">
    <button *ngIf="(canEditWithOffice$ | async) && item$ | async as item"
            (click)="editWithOffice(item.item)"
            [attr.aria-label]="'FILE_LIBRARY.EDIT_IN_OFFICE'  | translate"
            mat-menu-item>
      {{'EDIT' | translate}}
    </button>
    <button (click)="showFileDetails(itemStateModel.item)"
            *ngIf="!itemStateModel.item.isFolder"
            [attr.aria-label]="'FILE_LIBRARY.DETAILS'  | translate"
            mat-menu-item>
      {{'FILE_LIBRARY.DETAILS' | translate}}
    </button>
    <button *ngIf="canDownload$ | async"
            (click)="download(itemStateModel.item)"
            mat-menu-item>
      {{'DOWNLOAD' | translate}}
    </button>
    <button (click)="startRenaming()"
            *ngIf="canRename$ | async"
            [attr.aria-label]="'FILE_LIBRARY.MODAL.TITLE.RENAME'  | translate"
            mat-menu-item>
      {{'FILE_LIBRARY.MODAL.TITLE.RENAME' | translate}}
    </button>
    <button (click)="selectFile()"
            *ngIf="!itemStateModel.item.isFolder"
            mat-menu-item>
      {{'FILE_LIBRARY.UPLOAD_NEW_VERSION' | translate}}
    </button>
    <button *ngIf="(canPublicLink$ | async)"
            (click)="openPublicShareDialog(itemStateModel.item)"
            mat-menu-item>
      {{'PUBLIC_LINK.MENU' | translate}}
    </button>
    <button (cdkCopyToClipboardCopied)="showCopyLinkNotification()"
            *ngIf="(deepLink$ | async) as deepLink"
            [attr.aria-label]="'FILE_LIBRARY.COPY_LINK'  | translate"
            [cdkCopyToClipboard]="deepLink"
            mat-menu-item>
      {{'FILE_LIBRARY.COPY_LINK' | translate}}
    </button>
    <button (click)="delete(itemStateModel.item)" *ngIf="canDelete$ | async"
            [attr.aria-label]="'DELETE'  | translate"
            mat-menu-item>
      {{'DELETE' | translate}}
    </button>
  </mat-menu>
  <input #files
         *ngIf="(extension$ | async) as extension"
         (change)="uploadNewVersion(itemStateModel.item)"
         [accept]="extension"
         (click)="preventClickPropagation($event)"
         class="files"
         id="files"
         type="file">
</ng-container>

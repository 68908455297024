import {FilePickerData} from '@app/file-picker/file-picker-data';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemStateModel} from '@app/file-picker/file-picker-state/file-picker-state-model';

export class OpenFolder {
  static type: string = '[FilePicker] OpenFolder';

  constructor(readonly filePickerId: string, readonly item: FilePickerItem) {
  }
}

export class OpenParent {
  static type: string = '[FilePicker] OpenParent';

  constructor(readonly filePickerId: string) {
  }
}

export class OpenRoot {
  static type: string = '[FilePicker] OpenRoot';

  constructor(readonly filePickerId: string) {
  }
}

export class OpenInRoot {
  static type: string = '[FilePicker] OpenInRoot';

  constructor(readonly filePickerId: string, readonly item: FilePickerItem) {
  }
}

export class InitializeFilePicker {
  static type: string = '[FilePicker] Initialize';

  constructor(readonly filePickerId: string, readonly filePickerData: FilePickerData) {
  }
}

export class CloseFilePicker {
  static type: string = '[FilePicker] Close';

  constructor(readonly filePickerId: string) {
  }
}

export class DeleteFilePickerItem {
  static type: string = '[FilePicker] DeleteItem';

  constructor(readonly pickerId: string, readonly itemId: string | null) {
  }
}

export class ToggleRenamingFilePickerItem {
  static type: string = '[FilePicker] ToggleRenaming';

  constructor(readonly pickerId: string, readonly itemId: string | null) {
  }
}

export class FinishRenamingFilePickerItem {
  static type: string = '[FilePicker] FinishRenaming';

  constructor(readonly pickerId: string, readonly itemId: string, readonly newName: string) {
  }
}

export class CreateFolder {
  static type: string = '[FilePicker] CreateFolder';

  constructor(readonly pickerId: string, readonly initialName: string) {

  }
}

export class LoadMore {
  static type: string = '[FilePicker] LoadMore';

  constructor(readonly filePickerId: string, readonly filePickerItem: FilePickerItemStateModel) {
  }
}

export class MoveFileToFolder {
  static type: string = '[FilePicker] Move file';

  constructor(readonly filePickerId: string, readonly filePickerItemId: string, readonly folderId: string) {
  }
}

export class UploadFiles {
  static type: string = '[FilePicker] UploadFiles';

  constructor(readonly pickerId: string,
              readonly parentItem: FilePickerItem,
              readonly files: FileList | File[]) {
  }
}

export class UpdateFile {
  static type: string = '[FilePicker] UpdateFile';

  constructor(
    readonly pickerId: string,
    readonly filePickerItem: FilePickerItem,
    readonly file: File) {
  }
}

export class LoadFileAuthors {
  static type: string = '[FilePicker] LoadFileAuthors';

  constructor(readonly pickerId: string) {
  }
}

export class UpdatePublicLinkStatus {
  static type: string = '[FilePicker] UpdatePublicLinkStatus';

  constructor(readonly filePickerItemId: string, readonly hasPublicLink: boolean, readonly active: boolean) {
  }
}

export class RefreshItem {
  static type: string = '[FilePicker] RefreshItem';

  constructor(readonly filePickerItemId: string, readonly filePickerId: string) {
  }
}

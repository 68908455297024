<div class="bookmarking-widget">
  <h3 class="widget-title">
    {{widget.settings._title || ("WIDGETS.BOOKMARKING.NAME" | translate)}}</h3>

  <div *ngIf="!editMode && !bookmarks?.length">
    <cite class="text-muted" translate="WIDGETS.BOOKMARKING.NO_BOOKMARKS"></cite>
  </div>

  <ng-container *ngIf="!editMode">
    <div *ngFor="let bookmark of bookmarks" class="bookmark">
      <coyo-bookmarking-show [bookmark]="bookmark"></coyo-bookmarking-show>
    </div>
  </ng-container>

  <div (cdkDropListDropped)="drop($event)" *ngIf="editMode" cdkDropList cdkDropListLockAxis="y">
    <div *ngFor="let bookmark of bookmarks; let idx = index" cdkDrag class="bookmark">
      <coyo-bookmarking-edit-form (bookmarkChange)="changeBookmark($event, idx)"
                                  (bookmarkRemove)="removeBookmark(idx)"
                                  [bookmark]="bookmark">
      </coyo-bookmarking-edit-form>
    </div>
  </div>

  <div *ngIf="editMode" class="add-bookmark">
    <coyo-bookmarking-add-form (bookmarkAdd)="addBookmark($event)"></coyo-bookmarking-add-form>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FileLibraryFileDetailsService} from '@app/file-library/file-library-file-details/file-library-file-details.service';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {ToggleSelection} from '@app/file-picker/file-picker-selection/file-picker-selection.actions';
import {FilePickerSelectionsState} from '@app/file-picker/file-picker-selection/file-picker-selections-state';
import {
  FinishRenamingFilePickerItem,
  MoveFileToFolder,
  OpenFolder,
  ToggleRenamingFilePickerItem,
  RefreshItem
} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {Skeleton} from '@coyo/ui';
import {COYO_INTERNAL_FILE_DRAG_TYPE} from '@domain/file/file';
import {User} from '@domain/user/user';
import {WebdavService} from '@domain/webdav/webdav.service';
import {Store} from '@ngxs/store';
import {Observable, Subject, Subscription} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';

/**
 * Component rendering a table row for a file library file
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tr[coyo-file-library-file-picker-item]',
  templateUrl: './file-library-file-picker-item.component.html',
  styleUrls: ['./file-library-file-picker-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryFilePickerItemComponent extends FilePickerItemBaseDirective<FileLibraryFilePickerItem> implements OnChanges, OnDestroy {
  readonly MAX_LENGTH: number = 250;
  @HostBinding('class.disabled')
  selectionDisabled: boolean = false;

  @HostBinding('draggable')
  draggable: boolean = true;

  @ViewChild('drag') dragTemplate: ElementRef<HTMLElement>;

  fileNameWithoutExtension$: Observable<string>;
  fileExtension$: Observable<string>;
  author$: Observable<User>;
  showAuthor$: Observable<boolean>;
  isDownload$: Observable<boolean>;

  authorSkeletons: Skeleton[] = [{
    top: 4,
    left: 0,
    width: 100,
    height: 14
  }];

  private destroyed$: Subject<void> = new Subject<void>();

  private dragEnterCount: number = 0;

  private websocketSubscription: Subscription;
  private draggableSubscription: Subscription;

  constructor(store: Store,
              private readonly webdavService: WebdavService,
              private readonly renderer: Renderer2,
              private readonly fileLibraryFileDetailsService: FileLibraryFileDetailsService
  ) {
    super(store);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.isDownload$ = this.store.select(FilePickerStateSelectors.isDownloadMode(this.filePickerId));
    this.draggableSubscription?.unsubscribe();
    this.item$.pipe(takeUntil(this.destroyed$)).subscribe(item => {
      this.selectionDisabled = !(this.store.selectSnapshot(FilePickerSelectionsState.isItemSelectable(item)) || item.isFolder);
      this.draggable = item.permissions?.manage && !item.isAppRoot;
    });
    this.websocketSubscription?.unsubscribe();
    this.websocketSubscription = this.item$.pipe(takeUntil(this.destroyed$), switchMap(item =>
      this.webdavService
        .getLockInfo$(item.file.id, item.file.senderId, item.file.subscriptionInfo?.token))
    ).subscribe(lockInfo => {
      this.store.dispatch(new RefreshItem(this.itemId, this.filePickerId));
    });
    if (changes.itemId) {
      this.fileNameWithoutExtension$ = this.item$.pipe(map(item => this.getFileNameWithoutExtension(item.file.name)));
      this.fileExtension$ = this.item$.pipe(map(item => this.getFileExtension(item.file.name)));
      this.author$ = this.store.select(FilePickerStateSelectors.author(changes.itemId.currentValue));
    }
    if (changes.filePickerId) {
      this.showAuthor$ = this.store.select(FilePickerStateSelectors.showAuthor(changes.filePickerId.currentValue));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  renameFile(newName: string, event?: MouseEvent): void {
    this.cancelEvent(event);
    this.store.dispatch(new FinishRenamingFilePickerItem(this.filePickerId, this.itemId, newName));
  }

  cancelRenaming(event?: KeyboardEvent): void {
    this.cancelEvent(event);
    this.store.dispatch(new ToggleRenamingFilePickerItem(this.filePickerId, null));
  }

  cancelEvent(event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('dragstart', ['$event'])
  onStartDrag(event: DragEvent): void {
    event.dataTransfer.setData(COYO_INTERNAL_FILE_DRAG_TYPE, this.itemId);
    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setDragImage(this.dragTemplate.nativeElement, 0, 0);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    const targetId = event.dataTransfer.getData(COYO_INTERNAL_FILE_DRAG_TYPE);
    if (targetId !== this.itemId) {
      this.dragEnterCount = 0;
      this.renderer.removeClass(event.currentTarget, 'dropping');
      this.store.dispatch(new MoveFileToFolder(this.filePickerId, targetId, this.itemId));
    }
  }

  @HostListener('dragenter', ['$event'])
  allowFolderDrop(event: DragEvent): boolean {
    if (this.store.selectSnapshot(FilePickerStateSelectors.item(this.itemId))?.item.isFolder && event.dataTransfer.types[0] === COYO_INTERNAL_FILE_DRAG_TYPE) {
      this.dragEnterCount++;
      this.renderer.addClass(event.currentTarget, 'dropping');
      event.preventDefault();
      return false;
    }
    return true;
  }

  @HostListener('dragover', ['$event'])
  allowFolderDropOver(event: DragEvent): boolean {
    if (this.store.selectSnapshot(FilePickerStateSelectors.item(this.itemId))?.item.isFolder) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  @HostListener('dragleave', ['$event'])
  removeDroppingClass(event: DragEvent): void {
    this.dragEnterCount--;
    if (this.store.selectSnapshot(FilePickerStateSelectors.item(this.itemId))?.item.isFolder && this.dragEnterCount <= 0) {
      this.dragEnterCount = 0;
      this.renderer.removeClass(event.currentTarget, 'dropping');
    }
  }

  stopPropagation(event: Event): void {
    event.stopImmediatePropagation();
  }

  @HostListener('click')
  onClick(): void {
    const item = this.store.selectSnapshot(FilePickerStateSelectors.item(this.itemId)).item as FileLibraryFilePickerItem;
    const downloadMode = this.store.selectSnapshot(FilePickerStateSelectors.isDownloadMode(this.filePickerId));
    if (item.isFolder) {
      this.store.dispatch(new OpenFolder(this.filePickerId, item));
    } else if (downloadMode) {
      this.fileLibraryFileDetailsService.openFilePreview(this.filePickerId, item);
    } else if (!this.selectionDisabled) {
      this.store.dispatch(new ToggleSelection(item));
    }
  }

  private getFileNameWithoutExtension(fileName: string): string {
    const periodIndex = fileName.lastIndexOf('.');
    return periodIndex < 0 ? fileName : fileName.substring(0, periodIndex);
  }

  private getFileExtension(fileName: string): string {
    const periodIndex = fileName.lastIndexOf('.');
    return periodIndex < 0 ? '' : fileName.substring(periodIndex);
  }
}

import {Injectable} from '@angular/core';
import {RippleGlobalOptions} from '@angular/material/core';
import {BrowserService} from '@core/browser/browser.service';

@Injectable({
  providedIn: 'root'
})
export class AppGlobalRippleOptionsService implements RippleGlobalOptions {

  get disabled(): boolean {
    return this.browserService.isInternetExplorer();
  }

  constructor(private browserService: BrowserService) {
  }
}

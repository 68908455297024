(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .component('coyoMessageTimestamp', messageTimestamp());

  /**
   * @ngdoc directive
   * @name commons.ui.coyoMessageTimestamp:coyoMessageTimestamp
   *
   * @description
   * Shows the date depending on four states:
   * - on current day, then HH:MM, or HH:MMam/pm is displayed, depending on the locale
   * - the day before, then "Yesterday" is displayed
   * - within the last 7 days, then the weekday is displayed (for example "Wednesday")
   * - longer then a week ago: Weekday Date. Month is displayed. Format: Wed 19. Sep
   * Adds a tooltip with the original date and time to the directive.
   *
   * @param {object} date tha date to be displayed
   * @param {string} [tooltipPlacement=auto bottom] the positioning of the tooltip
   */
  function messageTimestamp() {
    return {
      templateUrl: 'app/commons/ui/components/message-timestamp/message-timestamp.html',
      bindings: {
        date: '<',
        tooltipPlacement: '@'
      },
      controller: 'MessageTimestampController',
      controllerAs: '$ctrl'
    };
  }

})(angular);

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PendingService} from '@core/http/pending/pending.service';
import {BlogArticle} from '@domain/blog-article/blog-article';
import {WikiArticle} from '@domain/wiki-article/wiki-article';
import {PreviewContentDialogData} from '@shared/print/print-preview-dialog/preview-content-dialog-data';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * A component to render the print preview of multiple wiki or blog articles in a dialog.
 */
@Component({
  selector: 'coyo-print-preview-dialog',
  templateUrl: './print-preview-dialog.component.html',
  styleUrls: ['./print-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintPreviewDialogComponent implements OnInit, OnDestroy {

  private static readonly FIXED_PREVIEW_WIDTH: number = 860;
  private static readonly DEFAULT_SCALE: number = 1;
  private static readonly RENDERING_BATCH_SIZE: number = 5;
  private static readonly HEIGHT_CALCULATION_TIMOUT: number = 5000;

  articles: (WikiArticle | BlogArticle)[];
  loading: boolean = true;
  currentArticlesRenderingSize: number;
  articlesRendered$: Subject<number>;
  @ViewChild('previewHolder', {static: true}) previewHolder: ElementRef;
  previewHeight: number;
  previewScale: number = 1;
  subscription: Subscription;

  private articlesRendered: number;
  private loadingCompleteNotifier$: Subject<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public previewContent: PreviewContentDialogData,
              private dialogRef: MatDialogRef<PrintPreviewDialogComponent>,
              public cd: ChangeDetectorRef,
              private pendingService: PendingService) {}

  ngOnInit(): void {
    this.articlesRendered = 0;
    this.articlesRendered$ = new Subject();
    this.loadingCompleteNotifier$ = new Subject();
    this.previewContent.articles.subscribe(articles => {
      this.articles = articles;
      this.managePacedLoading();
    });

    this.dialogRef.afterOpened().subscribe(() => {
      this.adaptScaling(this.previewHolder.nativeElement.offsetWidth);
    });
  }

  ngOnDestroy(): void {
    this.articlesRendered$.complete();
    this.loadingCompleteNotifier$.complete();
    this.subscription?.unsubscribe();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.adaptScaling(this.previewHolder.nativeElement.offsetWidth);
  }

  onImagesComplete(): void {
    this.articlesRendered++;
    this.articlesRendered$.next(this.articlesRendered);
  }

  print(): void {
    window.print();
  }

  private managePacedLoading(): void {
    this.currentArticlesRenderingSize = Math.min(PrintPreviewDialogComponent.RENDERING_BATCH_SIZE, this.articles.length);
    this.subscription = combineLatest([this.articlesRendered$.asObservable(), this.pendingService.hasPendingRequests$()])
      .pipe(takeUntil(this.loadingCompleteNotifier$))
      .subscribe((values: (number | boolean)[]) => {
        const articlesRendered = values[0];
        const hasPendingRequests = values[1];
        if (articlesRendered >= this.articles.length && !hasPendingRequests) {
          this.finishLoading();
          return;
        }
        if (articlesRendered >= this.currentArticlesRenderingSize && !hasPendingRequests) {
          this.currentArticlesRenderingSize =
            Math.min(this.currentArticlesRenderingSize + PrintPreviewDialogComponent.RENDERING_BATCH_SIZE, this.articles.length);
        }
      });
    this.cd.markForCheck();
  }

  private finishLoading(): void {
    this.loading = false;
    this.determinePreviewHeight();
    this.articlesRendered$.complete();
    this.loadingCompleteNotifier$.next();
    this.loadingCompleteNotifier$.complete();
    this.subscription.unsubscribe();
  }

  /**
   * In case the dialog cannot be rendered in full width, the preview content is scaled so that the layout still
   * looks as if displayed on a wide screen.
   * @param elementWidth actual width of the preview content holder
   */
  private adaptScaling(elementWidth: number): void {
    this.previewScale = Math.min(elementWidth / PrintPreviewDialogComponent.FIXED_PREVIEW_WIDTH, PrintPreviewDialogComponent.DEFAULT_SCALE);
    this.cd.markForCheck();
  }

  /**
   * If the preview content holder is scaled, it will still take up the original dimensions in rendering
   * leaving a long empty scroll area below the content. To eliminate this, the actual height is calculated
   * after an amount of time that all (images) are rendered, scaled and applied to the parent element.
   */
  private determinePreviewHeight(): void {
    setTimeout(() => {
      this.previewHeight = this.previewHolder.nativeElement.children[0].scrollHeight;
      this.cd.markForCheck();
    }, PrintPreviewDialogComponent.HEIGHT_CALCULATION_TIMOUT);
  }
}

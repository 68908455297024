import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {HtmlWidget} from '@widgets/html/html-widget';

@Component({
  templateUrl: './html-widget-settings.component.html',
  styleUrls: ['./html-widget-settings.component.scss'],
})
export class HtmlWidgetSettingsComponent extends WidgetSettingsComponent<HtmlWidget> implements OnInit {

  ngOnInit(): void {
    this.parentForm.addControl('html_content', new FormControl(this.widget.settings.html_content, Validators.required));
  }
}

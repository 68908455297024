(function (angular) {
  'use strict';

  messageHandler.$inject = ["$timeout", "$q", "MessageModel", "optimisticService", "optimisticTypeMessage", "optimisticStatus", "tempUploadService", "errorService", "tempUploadsTTLSeconds", "utilService"];
  angular
      .module('coyo.messaging')
      .factory('messageHandler', messageHandler);

  function messageHandler($timeout, $q, MessageModel, optimisticService, optimisticTypeMessage, optimisticStatus,
                          tempUploadService, errorService, tempUploadsTTLSeconds, utilService) {

    return {
      sendMessage: sendMessage,
      markAsRead: markAsRead,
      getAllMessagesFromStorage: getAllMessagesFromStorage,
      getNewMessagesFromStorage: getNewMessagesFromStorage,
      getPendingMessagesFromStorage: getPendingMessagesFromStorage,
      resendPendingMessages: resendPendingMessages,
      getLatestWorkingCopy: getLatestWorkingCopy,
      removeLocalMessage: removeLocalMessage,
      cleanupLocalMessages: cleanupLocalMessages,
      uploadFiles: uploadFiles
    };

    function sendMessage(message) {
      if (_.isEmpty(message.attachments) && _.isEmpty(_.get(message, 'data.message'))
          && _.isEmpty(message.fileLibraryAttachments)) {
        return $q.reject();
      }
      delete message.error;
      return new MessageModel(message).create().catch(function (errorResponse) {
        errorService.suppressNotification(errorResponse);
        _handleDeliveryFailure(message, errorResponse.data);
        return {message: message, error: errorResponse.data};
      });
    }

    function markAsRead(userId, channelId) {
      return MessageModel.markAsRead(userId, channelId);
    }

    function _handleDeliveryFailure(message, errorData) {
      var optimisticMessage = optimisticService.find(_.get(message, 'clientMessageId'));
      optimisticMessage.status = optimisticStatus.ERROR;
      message.error = errorData || 'CONNECTION_LOST';
      message.optimisticPending = false;
    }

    function _hasLocalOptimisticEntity(messageId) {
      return !_.isEmpty(optimisticService.find(messageId));
    }

    function getLatestWorkingCopy(channelId) {
      var latestWorkingCopy = _.head(_.filter(getNewMessagesFromStorage(), function (optimisticMessage) {
        var optimisticChannelId = _.get(optimisticMessage, 'entity.channelId');
        return !!optimisticChannelId && optimisticChannelId === channelId;
      }));
      if (latestWorkingCopy) {
        var uuid = utilService.uuid();
        latestWorkingCopy.entity.clientMessageId = uuid;
        latestWorkingCopy.entityId = uuid;
      }
      return latestWorkingCopy;
    }

    function getAllMessagesFromStorage() {
      return _getMessagesFromStorageByStatus();
    }

    function getNewMessagesFromStorage() {
      return _getMessagesFromStorageByStatus(optimisticStatus.NEW);
    }

    function getPendingMessagesFromStorage() {
      return _getMessagesFromStorageByStatus(optimisticStatus.PENDING);
    }

    function _getMessagesFromStorageByStatus(status) {
      return _.filter(optimisticService.findAll(optimisticTypeMessage), function (optimisticEntity) {
        return !status || optimisticEntity.status === status;
      });
    }

    function resendPendingMessages() {
      var pendingMessages = getPendingMessagesFromStorage();
      _resendPendingMessages(pendingMessages);
    }

    function _resendPendingMessages(nextPendingMessages) {
      if (_.isEmpty(nextPendingMessages)) {
        return;
      }
      var nextPendingMessage = nextPendingMessages.shift();
      $timeout(function () {
        sendMessage(nextPendingMessage.entity).then(function () {
          _resendPendingMessages(nextPendingMessages);
        });
      });
    }

    function removeLocalMessage(messageEntity) {
      var messageId = _.get(messageEntity, 'clientMessageId');
      if (_hasLocalOptimisticEntity(messageId)) {
        return optimisticService.remove(messageId);
      }
      return $q.resolve();
    }

    function cleanupLocalMessages() {
      var removableEntities = _.filter(getAllMessagesFromStorage(), function (message) {
        return _.isEmpty(_.get(message, 'entity.data.message')) && _.isEmpty(_.get(message, 'entity.attachments'));
      });
      if (_.isEmpty(removableEntities)) {
        return $q.resolve();
      }
      var removableEntityIds = _.map(removableEntities, 'entityId');
      return optimisticService.remove(removableEntityIds);
    }

    function uploadFiles(files, messageId, onFileUploadSuccess) {
      angular.forEach(files, function (file) {
        _uploadFile(file).then(function () {
          if (_.isFunction(onFileUploadSuccess)) {
            onFileUploadSuccess(file, messageId);
          }
        });
      });
    }

    function _uploadFile(file) {
      return tempUploadService.upload(file, tempUploadsTTLSeconds).catch(function (errorResponse) {
        errorService.suppressNotification(errorResponse);
        file.error = errorResponse.data || 'INTERNET_CONNECTION_LOST';
      }).then(function (blob) {
        angular.extend(file, blob);
      });
    }
  }
})(angular);

<div class="headline-widget" [ngClass]="widget.settings?._headline?.previewClass">
  <textarea class="headline-widget-headline"
            *ngIf="editMode"
            [ngModel]="widget.settings.text"
            (ngModelChange)="setText($event)"
            [placeholder]="'WIDGET.HEADLINE.PLACEHOLDER' | translate"
            autosize rows="1"></textarea>

  <p class="headline-widget-headline"
     *ngIf="!editMode">{{ widget.settings.text}}</p>
</div>

import {Injectable} from '@angular/core';
import {GSUITE, StorageType} from '@domain/attachment/storage';
import * as _ from 'lodash';

/**
 * This service provides the icon names of supported file icons to be used inside mat-icon tags
 */
@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor() {
  }

  gSuiteIconMapping: { [key: string]: string } = {
    'application/vnd.google-apps.document': 'google-document',
    'application/vnd.google-apps.drawing': 'google-drawing',
    'application/vnd.google-apps.folder': 'google-folder',
    'application/vnd.google-apps.form': 'google-form',
    'application/vnd.google-apps.fusiontable': 'google-fusiontable',
    'application/vnd.google-apps.map': 'google-map',
    'application/vnd.google-apps.presentation': 'google-presentation',
    'application/vnd.google-apps.script': 'google-script',
    'application/vnd.google-apps.site': 'google-site',
    'application/vnd.google-apps.spreadsheet': 'google-spreadsheet',
    'application/vnd.google-apps.video': 'google-video',
    'application/pdf': 'google-pdf',
    'image/.+': 'google-image',
    'video/.+': 'google-video'
  };

  msOfficeIconMapping: { [key: string]: string } = {
    'application/folder': 'folder_mc',
    'application/msword': 'word_mc',
    'application/onenote': 'mc-onenote',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word_mc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'word_mc',
    'application/vnd.ms-word.document.macroEnabled.12': 'word_mc',
    'application/vnd.ms-word.template.macroEnabled.12': 'word_mc',
    'application/vnd.ms-excel': 'excel_mc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel_mc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'excel_mc',
    'application/vnd.ms-excel.sheet.macroEnabled.12': 'excel_mc',
    'application/vnd.ms-excel.template.macroEnabled.12': 'excel_mc',
    'application/vnd.ms-excel.addin.macroEnabled.12': 'excel_mc',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': 'excel_mc',
    'application/vnd.ms-powerpoint': 'powerpoint_mc',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint_mc',
    'application/vnd.openxmlformats-officedocument.presentationml.template': 'powerpoint_mc',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'powerpoint_mc',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12': 'powerpoint_mc',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'powerpoint_mc',
    'application/vnd.ms-powerpoint.template.macroEnabled.12': 'powerpoint_mc',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'powerpoint_mc'
  };

  /**
   * Get icon name for the mime type depending on the storage type
   * of the file.
   *
   * @param mimeType mime type
   * @param storage the storage type of the file
   *
   * @returns a string with the icon name.
   */
  getFileIconByMimeType(mimeType: string, storage: StorageType): string {
    return this.determineIcon(mimeType, storage);
  }

  private determineExternalIcon(contentType: string, storage: StorageType): string {
    if (this.isGsuiteFile(storage)) {
      return this.determineGsuiteIconByMimeType(contentType);
    } else {
      return _.get(this.msOfficeIconMapping, contentType);
    }
  }

  private isGsuiteFile(storage: StorageType): boolean {
    return storage === GSUITE;
  }

  private determineGsuiteIconByMimeType(mimeType: string): string {
    const determineIcon = _.find(
      this.gSuiteIconMapping, (iconClasses, mimeTypeRegEx) => !!mimeType.match(new RegExp(mimeTypeRegEx)));
    return determineIcon ? determineIcon : 'google-file';
  }

  private determineIcon(contentType: string, storage: StorageType): string {
    const icon = this.determineExternalIcon(contentType, storage);
    if (icon) {
      return icon;
    }
    if (!contentType) {
      return 'generic-file';
    }
    if (contentType.indexOf('image') >= 0) {
      return 'mc-image';
    }
    if (contentType.indexOf('video') >= 0) {
      return 'video';
    }
    if (contentType.indexOf('pdf') >= 0) {
      return 'pdf';
    }
    if (contentType.indexOf('plain') >= 0) {
      return 'text';
    }
    if (contentType.indexOf('application') >= 0) {
      if (contentType.indexOf('zip') >= 0) {
        return 'mc-zip-archive';
      }
    }
    return 'generic-file';
  }
}

(function () {
  'use strict';

  colleagueGuessesModalService.$inject = ["modalService"];
  angular
      .module('coyo.apps.championship')
      .factory('colleagueGuessesModalService', colleagueGuessesModalService);

  /**
   * @ngdoc service
   * @name coyo.apps.championship.colleagueGuessesModalService
   *
   * @description
   * Displays a modal to show the guesses of colleagues.
   */
  function colleagueGuessesModalService(modalService) {

    return {
      open: open
    };

    /**
     * @ngdoc method
     * @name coyo.apps.commons.fields.colleagueGuessesModalService#open
     * @methodOf coyo.apps.championship.colleagueGuessesModalService
     *
     * @description
     * Opens the modal to show the guesses of a colleague.
     *
     * @returns {object}
     * Returns a promise with the game list.
     */
    function open(app, user) {
      return modalService.open({
        size: 'md',
        templateUrl: 'app/apps/championship/views/championship-colleague-guesses-modal.html',
        controller: 'ColleagueGuessesController',
        controllerAs: '$ctrl',
        resolve: {
          rankingForUser: /*@ngInject*/ ["championshipRankingService", function (championshipRankingService) {
            return championshipRankingService.getRankingForUser(app, user.id);
          }],
          rankingForAll: /*@ngInject*/ ["Pageable", "GameModel", function (Pageable, GameModel) {
            var pageable = new Pageable(0, 5);
            return GameModel.getGuessesForUser(app, user.id, pageable).then(function (response) {
              return response;
            });
          }]
        }
      }).result;
    }
  }

})();

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Sender} from '@domain/sender/sender';

/**
 * Shows the file library of COYO internal files of the given sender.
 */
@Component({
  selector: 'coyo-sender-files',
  templateUrl: './sender-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderFilesComponent {

  /**
   * The sender to show the files for
   */
  @Input() sender: Sender;
}

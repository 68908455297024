<coyo-pdf-viewer-toolbar *ngIf="showToolbar && !loading" [currentPage]="currentPage" [maxPages]="maxPages"
                         [loading]="loading" [activeSetting]="activeSettings" [settingPresets]="settingPresets"
                         (pageChange)="pageChange($event)" (settingSelected)="settingSelected($event)"
                         (updateRotation)="updateRotation($event)"></coyo-pdf-viewer-toolbar>
<pdf-viewer *ngIf="activeSettings && pdfSrc" [src]="pdfSrc"
            [render-text]="true"
            [stick-to-page]="activeSettings.stickToPage"
            [show-all]="true"
            [original-size]="activeSettings.originalSize"
            [autoresize]="activeSettings.autoresize"
            [fit-to-page]="activeSettings.fitToPage"
            [(page)]="currentPage"
            [zoom]="activeSettings.zoom"
            [rotation]="rotation"
            (after-load-complete)="afterLoadComplete($event)"
            (error)="onError()"
            style="display: block;"
></pdf-viewer>

<td colspan="5" id="column">
  <div class="item-content">
    <ui-spinner size="small"></ui-spinner>
    <span *ngIf="(item$ | async) as item"
          [attr.aria-label]="'FILEPICKER.FILE.ARIA' | translate : {file: item.name}">{{item.name}}
    </span>
  </div>
  <div *ngIf="progress$ | async as width" [style.width.%]="width" aria-valuemax="100" aria-valuemin="0"
       class="progress-bar" role="progressbar">
  </div>
</td>

import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';
import {GeneralSettingsComponent} from './general-settings.component';

export const generalSettingsState: NgHybridStateDeclaration = {
  name: 'admin.settings.general-settings',
  url: '/general',
  views: {
    '@admin.settings': {
      component: GeneralSettingsComponent
    }
  },
  resolve: {}
};

<div [formGroup]="parentForm">
  <label [translate]="'APP.TIMELINE.AUTHORS'"
         for="authorType" class="mat-label">
  </label>
  <mat-radio-group formControlName="authorType" id="authorType">
    <mat-radio-button [attr.aria-label]="'APP.TIMELINE.AUTHORTYPE.VIEWER.NAME' | translate"
                      [value]="viewerValue">{{"APP.TIMELINE.AUTHORTYPE.VIEWER.DESCRIPTION" | translate}}
    </mat-radio-button>
    <mat-radio-button [attr.aria-label]="'APP.TIMELINE.AUTHORTYPE.ADMIN.NAME' | translate"
                      [value]="adminValue">{{"APP.TIMELINE.AUTHORTYPE.ADMIN.DESCRIPTION" | translate}}
    </mat-radio-button>
  </mat-radio-group>
</div>

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {HtmlWidget} from '@widgets/html/html-widget';
import {HtmlWidgetSettingsComponent} from '@widgets/html/html-widget-settings/html-widget-settings.component';
import {HtmlWidgetComponent} from '@widgets/html/html-widget/html-widget.component';

export const HTML_WIDGET: WidgetConfig<HtmlWidget> = {
  key: 'html',
  name: 'WIDGET.HTML.NAME',
  description: 'WIDGET.HTML.DESCRIPTION',
  icon: 'zmdi-language-html5',
  component: HtmlWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    skipOnCreate: false,
    component: HtmlWidgetSettingsComponent
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {FileUpload} from '@domain/file/file/file-upload';
import {UuidService} from '@shared/uuid/uuid.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadFactoryService {

  constructor(private http: HttpClient, private uuidService: UuidService) { }

  createUpload(files: File[] | FileList, path: string, parentId: string, params?: {[key: string]: string[]}): FileUpload {
    return new FileUpload(this.http, this.uuidService, files, path, parentId, params);
  }
}

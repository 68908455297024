import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {DividerWidget} from '@widgets/divider/divider-widget';
import {DividerWidgetSettingsComponent} from './divider-widget-settings/divider-widget-settings.component';
import {DividerWidgetComponent} from './divider-widget/divider-widget.component';

export const DIVIDER_WIDGET: WidgetConfig<DividerWidget> = {
  key: 'divider',
  name: 'WIDGET.DIVIDER.NAME',
  description: 'WIDGET.DIVIDER.DESCRIPTION',
  icon: 'zmdi-power-input',
  component: DividerWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    skipOnCreate: true,
    component: DividerWidgetSettingsComponent,
  },
  renderOptions: {
    printable: true,
    panels: {
      noPanel: true
    }
  },
  whitelistExternal: true
};

import {Injectable, NgZone, Renderer2, RendererFactory2} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PageTitleService} from '@shared/notifications/page-title/page-title.service';
import {PreviewContentDialogData} from '@shared/print/print-preview-dialog/preview-content-dialog-data';
import {PrintPreviewDialogComponent} from '@shared/print/print-preview-dialog/print-preview-dialog.component';
import {Observable} from 'rxjs';

/**
 * Service to open a print preview of wiki or blog articles in a dialog.
 */
@Injectable({
  providedIn: 'root'
})
export class PrintPreviewDialogService {

  private static readonly DIALOG_WIDTH: number = 908;
  private renderer: Renderer2;

  constructor(private dialog: MatDialog, private ngZone: NgZone, private pageTitleService: PageTitleService, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Function that opens dialog called out from an AngularJS component.
   * Temporarily adapts the website title to the modal title so it's displayed in browser's print.
   * @param previewContent data passed to the dialog component for rendering the correct content
   *
   * @returns Observable which notifies when the modal is closed
   */
  open(previewContent: PreviewContentDialogData): Observable<void> {
    const originalSiteTitle: string = this.pageTitleService.getTitle();
    const previewDialog: MatDialogRef<PrintPreviewDialogComponent, void> = this.dialog.open<PrintPreviewDialogComponent, PreviewContentDialogData, void>
    (PrintPreviewDialogComponent, {
      width: `${PrintPreviewDialogService.DIALOG_WIDTH}px`,
      autoFocus: true,
      data: previewContent
    });
    previewDialog.afterOpened().subscribe(() => {
      this.pageTitleService.setTitle(previewContent.pageTitle);
      // adding a class to the body element whenever a modal is open is needed to hide the body content in print.scss
      this.renderer.addClass(document.body, 'mat-dialog-open');
    });
    previewDialog.afterClosed().subscribe(() => {
      this.pageTitleService.setTitle(originalSiteTitle);
      this.renderer.removeClass(document.body, 'mat-dialog-open');
    });
    return previewDialog.afterClosed();
  }

  /**
   * @Deprecated
   * Opens the print preview dialog from an AngularJS context.
   *
   * @param previewContent The content to be previewed
   *
   * @return empty promise that completes when dialog gets closed
   */
  openLegacy(previewContent: PreviewContentDialogData): Promise<void> {
    return this.ngZone.run(() => this.open(previewContent).toPromise());
  }
}

<div [formGroup]="parentForm">
  <div class="code-widget-warning">
    <div class="alert alert-warning">{{'WIDGET.CODE.WARNING' | translate }}</div>
  </div>

  <div class="form-horizontal code-widget-settings">
    <div class="form-group">
      <div class="col-sm-12 col-header">
        <label>{{'WIDGET.CODE.SETTINGS.HTML.TEXT' | translate }}</label>
        <coyo-help class="code-tip hidden-xs" tip="WIDGET.CODE.SETTINGS.HTML.HELP"></coyo-help>
      </div>
      <textarea rows="8" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                id="html_content" name="_html_content" class="form-control" formControlName="_html_content" [attr.placeholder]="getPlaceholderHtml()"></textarea>
    </div>
    <div class="form-group">
      <div class="col-sm-12 col-header">
        <label>{{'WIDGET.CODE.SETTINGS.JS.TEXT' | translate }}</label>
        <coyo-help class="code-tip hidden-xs" tip="WIDGET.CODE.SETTINGS.JS.HELP"></coyo-help>
      </div>
      <textarea rows="8" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                id="js_content" name="_js_content" class="form-control" formControlName="_js_content" [attr.placeholder]="getPlaceholderJs()"></textarea>
    </div>
    <div class="form-group">
      <div class="col-sm-12 col-header">
        <label>{{'WIDGET.CODE.SETTINGS.CSS.TEXT' | translate }}</label>
        <coyo-help class="code-tip hidden-xs" tip="WIDGET.CODE.SETTINGS.CSS.HELP"></coyo-help>
      </div>
      <textarea rows="8" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                id="css_content" name="_css_content" class="form-control" formControlName="_css_content" [attr.placeholder]="getPlaceholderCss()"></textarea>
    </div>
  </div>
</div>

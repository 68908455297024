import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DividerModule} from '@shared/divider/divider.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {DIVIDER_WIDGET} from './divider-widget-config';
import {DividerWidgetSettingsComponent} from './divider-widget-settings/divider-widget-settings.component';
import {DividerWidgetComponent} from './divider-widget/divider-widget.component';

/**
 * Module providing the divider widget
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    DividerModule,
    HelpModule
  ],
  declarations: [
    DividerWidgetComponent,
    DividerWidgetSettingsComponent
  ],
  entryComponents: [
    DividerWidgetComponent,
    DividerWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: DIVIDER_WIDGET, multi: true}
  ],
})
export class DividerWidgetModule {}

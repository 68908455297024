(function (angular) {
  'use strict';

  EventModel.$inject = ["$httpParamSerializer", "restResourceFactory", "coyoEndpoints", "Page", "Pageable", "moment", "$http", "backendUrlService"];
  angular
      .module('coyo.domain')
      .factory('EventModel', EventModel);

  /**
   * @ngdoc service
   * @name coyo.domain.EventModel
   *
   * @description
   * Provides the Coyo event model
   *
   * @requires $httpParamSerializer
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   * @requires commons.resource.Page
   * @requires commons.resource.Pageable
   * @requires moment
   * @requires $http
   */
  function EventModel($httpParamSerializer, restResourceFactory, coyoEndpoints, Page, Pageable, moment, $http,
                      backendUrlService) {
    var EventModel = restResourceFactory({
      url: coyoEndpoints.event.events
    });

    function _getSearchParameters(term, from, to, filters, searchFields, aggregations, pageable) {
      return angular.extend({
        term: term ? term : undefined,
        from: from ? from : angular.isUndefined(from) ? moment().format('YYYY-MM-DDTHH:mm:ss') : undefined,
        to: to ? to : undefined,
        filters: filters ? $httpParamSerializer(filters) : undefined,
        searchFields: searchFields ? searchFields.join(',') : undefined,
        aggregations: aggregations ? $httpParamSerializer(aggregations) : undefined
      }, pageable.getParams());
    }

    // class members
    angular.extend(EventModel, {

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#getAllParticipationStatuses
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Get all participation statuses as an array of strings.
       *
       * @returns {array} Array of all participation statuses
       */
      getAllParticipationStatuses: function () {
        return ['ATTENDING', 'PENDING', 'MAYBE_ATTENDING', 'NOT_ATTENDING'];
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#searchWithFilter
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Event elastic search with filter.
       *
       * @param {string|null} [term] search term
       * @param {string|null} [from] the start date to filter the search (formatted as 'YYYY-MM-DDTHH:mm:ss')
       * @param {string|null} [to] the end date to filter the search (formatted as 'YYYY-MM-DDTHH:mm:ss')
       * @param {object} pageable The paging information. If not set an offset of 0 and a page size of 20 will be used.
       * @param {object|null} [filters] search filters
       * @param {string|null} [searchFields] list of fields to search in
       * @param {object|null} [aggregations] search aggregations
       *
       * @returns {promise} An $http promise
       */
      searchWithFilter: function (term, from, to, pageable, filters, searchFields, aggregations) {
        var url = EventModel.$url();
        var params = _getSearchParameters(term, from, to, filters, searchFields, aggregations, pageable);
        return EventModel.getWithPermissions(null, params, ['manage', 'canParticipate']).then(function (response) {
          return new Page(response, params, {
            url: url,
            resultMapper: function (item) {
              return new EventModel(item);
            }
          });
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#getMembershipsWithFilter
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Returns the participants of an event
       *
       * @param {string|null} [term] search term
       * @param {object} pageable The paging information
       * @param {object|null} [filters] search filters
       * @param {string|null} [searchFields] list of fields to search in
       * @param {object|null} [aggregations] search aggregations
       * @param {string} slug The slug of the Event
       *
       * @return {promise} An $http promise
       */
      getMembershipsWithFilter: function (term, pageable, filters, searchFields, aggregations, slug) {
        var url = this.$url(slug + '/memberships');
        var params = _getSearchParameters(term, null, null, filters, searchFields, aggregations, pageable);
        return $http.get(url, {params: params, autoHandleErrors: false}).then(function (response) {
          return new Page(response.data, params, {
            url: url,
            resultMapper: function (item) {
              return new EventModel(item);
            }
          });
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#getIcalExportUrl
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Get the URL to downloaded the list of events in iCal format.
       *
       * @param {string|null} [term] search term
       * @param {string|null} [from] the start date to filter the search (formatted as 'YYYY-MM-DDTHH:mm:ss')
       * @param {string|null} [to] the end date to filter the search (formatted as 'YYYY-MM-DDTHH:mm:ss')
       * @param {object} pageable The paging information. If not set an offset of 0 and a page size of 20 will be used.
       * @param {object|null} [filters] search filters
       * @param {string|null} [searchFields] list of fields to search in
       * @param {object|null} [aggregations] search aggregations
       *
       * @returns {string} The download URL
       */
      getIcalExportUrl: function (term, from, to, pageable, filters, searchFields, aggregations) {
        var url = this.$url('ical');
        var params = _getSearchParameters(term, from, to, filters, searchFields, aggregations, pageable);
        return backendUrlService.getUrl() + url + '?' + $httpParamSerializer(params);
      }
    });

    // instance members
    angular.extend(EventModel.prototype, {

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#isMultiDay
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Checks if this event spans over multiple days.
       *
       * @returns {boolean} true if this event spans over multiple days, otherwise false.
       */
      isMultiDay: function () {
        return !moment(this.startDate).isSame(this.endDate, 'day');
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#updateEvent
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Updates the event.
       *
       * @returns {promise} An $http promise
       */
      updateEvent: function () {
        return this.update();
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#setStatus
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Sets the participation status for an event.
       *
       * @param {string} [status] The participation status to be set
       *
       * @returns {promise} An $http promise
       */
      setStatus: function (status) {
        return EventModel.$put(this.$url('status'), {status: status});
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#getAdminIds
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Returns admin ids of the event.
       *
       * @returns {promise} An $http promise
       */
      getAdminIds: function () {
        return this.getAdmins().then(function (admins) {
          return _.map(admins, 'userId');
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#getAdmins
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Returns the admins of the event
       *
       * @return {promise} promise that contains the admins
       */
      getAdmins: function () {
        var eventId = this.id;
        var pageable = new Pageable(0, 100, ['_score,DESC', 'displayName']);
        var filter = {role: 'ADMIN'};
        return EventModel.getMembershipsWithFilter('', pageable, filter, null, null, eventId).then(function (page) {
          return page.content;
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#inviteMembers
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Invites additional members to a event. Users can also invited via their group.
       *
       * @param {array} userAndGroupIds.userIds
       * An array of string with user ids to invite. Can be empty but not null.
       *
       * @param {array} userAndGroupIds.groupIds
       * An array of string with group ids. All users belonging to those groups are invited. Can be empty but not null.
       *
       * @returns {promise} An $http promise
       */
      inviteMembers: function (userAndGroupIds) {
        return EventModel.$put(this.$url('/memberships'), userAndGroupIds);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#removeMember
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Removes a member from an event.
       *
       * @param {string} userId
       * The identifier of the user to remove from the list of event memberships.
       *
       * @returns {promise} An $http promise
       */
      removeMember: function (userId) {
        return EventModel.$delete(this.$url('/memberships/' + userId));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#demote
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Demotes the given user from Admin to Member
       *
       * @param {string} userId
       * The identifier of the user to demote.
       *
       * @returns {promise} An $http promise
       */
      demote: function (userId) {
        return EventModel.$put(this.$url('/memberships/' + userId + '/demote'));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#deleteEvent
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Delete an event.
       *
       * @returns {promise} An $http promise
       */
      deleteEvent: function () {
        return this.$delete(this.$url());
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#canParticipate
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Returns true if the event can be participated by the current user.
       *
       * @returns {boolean} true if the event can be participated
       */
      canParticipate: function () {
        return this._permissions && this._permissions.canParticipate;
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#canManage
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Returns true if the event can be managed by the current user.
       *
       * @returns {boolean} true if the current user can manage the event
       */
      canManage: function () {
        return this._permissions && this._permissions.manage;
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#getNumberOfParticipants
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Returns the number of event participants with a given status
       *
       * @return {promise} An $http promise
       */
      getNumberOfParticipants: function (status) {
        var url = this.$url('/memberships/count');
        return EventModel.$get(url, {status: status});
      },

      /**
       * @ngdoc function
       * @name coyo.domain.EventModel#getIcalExportUrl
       * @methodOf coyo.domain.EventModel
       *
       * @description
       * Get the URL to downloaded the event in iCal format.
       *
       * @returns {string} The download URL
       */
      getIcalExportUrl: function () {
        var url = this.$url('/ical');
        return backendUrlService.getUrl() + url;
      }
    });

    return EventModel;
  }

})(angular);

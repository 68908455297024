import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {TourDialogComponent} from './tour-dialog/tour-dialog.component';
import {TourStepDirective} from './tour-step/tour-step.directive';
import './tour/tour.service.downgrade';

/**
 * A module that contains the COYO introduction tour.
 */
@NgModule({
  imports: [CoyoCommonsModule],
  exports: [TourStepDirective],
  declarations: [TourDialogComponent, TourStepDirective]
})
export class TourModule {
}

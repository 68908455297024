<ng-container mat-dialog-float [scrollingRef]="cdkVirtualScrollViewport.elementRef">
  <div mat-dialog-title>
    <h1>{{'MODULE.PAGES.SUBSCRIPTIONS' | translate : {count: this.subscriberInfoDataSource.subscriberCount} }}</h1>
    <ui-search [placeholder]="'MODULE.PAGES.SUBSCRIPTIONS.SEARCH.PLACEHOLDER' | translate"
               [clearLabel]="'CLEAR' | translate"
               (search)="search($event)"></ui-search>
  </div>
  <mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
  <div mat-dialog-content class="subscriber-list">
    <cdk-virtual-scroll-viewport [itemSize]="itemHeight"
                                 [minBufferPx]="2 * itemHeight"
                                 [maxBufferPx]="10 * itemHeight">
      <ng-container *cdkVirtualFor="let sender of subscriberInfoDataSource; trackBy: trackById">
        <coyo-sender-list-item *ngIf="sender" avatarSize="sm"
                               [sender]="sender"
                               [subline]="sender?.properties?.jobTitle">
        </coyo-sender-list-item>
        <ui-skeleton *ngIf="!sender" [skeletons]="skeletons" [height]="itemHeight"></ui-skeleton>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-container>

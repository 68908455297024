import {Inject, Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import * as _ from 'lodash';

/**
 * Service to handle texts containing hashtags.
 *
 * The <a href="https://support.twitter.com/articles/511696">twitter hashtag rules</a> were applied for hash-tagging
 * with the exception that pure numbers are also interpreted as hash tags.
 *
 * Beside the German umlauts and eszett, the special char "ñ" ("Ñ") <a href="https://de.wikipedia.org/wiki/%C3%91">Ñ</a>
 * can also be used in hash tags. #easteregg
 */
@Injectable({
  providedIn: 'root'
})
export class HashtagService {
  private regExpPattern: string = '#[a-zA-Z0-9äöüßÄÖÜñÑ]+';
  private regExp: RegExp = new RegExp('(^|\\s|>|&nbsp;)(' + this.regExpPattern + ')(?![^<]*>)', 'gm');

  constructor(private authService: AuthService,
              @Inject(NG1_STATE_SERVICE) private stateService: IStateService) {
  }

  /**
   * Returns the pattern for the hashtag regular expression.
   *
   * @returns the regular expression pattern
   */
  getPattern(): string {
    return this.regExpPattern;
  }

  /**
   * Enriches a text containing hashtags with links to these hashtags.
   *
   * @param text the source text containing hashtags
   * @returns the HTML-enriched result
   */
  hashtags(text: string): string {
    return this.isValid(text) ? text.replace(this.regExp, (match, p1, p2) =>
      p1 + '<a href="' + this.stateService.href('main.search', {term: p2}) + '">' + p2 + '</a>'
    ) : text;
  }

  /**
   * Returns true if given text is matching our concluded hash tag pattern.
   *
   * @param text the text to be validated
   * @returns the validation result as boolean
   */
  isConcludedHashtag(text: string): boolean {
    return new RegExp('^' + this.regExpPattern + '$').test(text);
  }

  private isValid(text: string): boolean {
    return _.isString(text) && !_.isEmpty(text) && this.authService.canUseHashtags();
  }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {WebdavService} from '@domain/webdav/webdav.service';
import {Store} from '@ngxs/store';
import {RefreshFileInDetails} from '@shared/files/file-details-modal/state/file-details.actions';
import {FileDetailsStateSelectors} from '@shared/files/file-details-modal/state/file-details.state.selectors';
import {Observable, Subject} from 'rxjs';
import {map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';

/**
 * Button that will open office tools for a file preview that is available in file details store and can be
 * edited with any supported office product.
 */
@Component({
  selector: 'coyo-edit-in-office-button',
  templateUrl: './edit-in-office-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditInOfficeButtonComponent implements OnChanges, OnDestroy {

  /**
   * The id of the file
   */
  @Input() fileId: string;

  /**
   * The id of the file details dialog
   */
  @Input() fileDetailsDialogId: string;

  file$: Observable<FilePreview>;

  canEditInOffice$: Observable<boolean>;

  private destroyed$: Subject<void> = new Subject();

  constructor(private store: Store, private webdavService: WebdavService) {
  }

  ngOnChanges(): void {
    this.file$ = this.store.select(FileDetailsStateSelectors.getFile(this.fileDetailsDialogId, this.fileId));
    this.file$.pipe(
      takeUntil(this.destroyed$),
      switchMap(file => this.webdavService.getLockInfo$(file.id, file.senderId, file.subscriptionInfo.token))
    ).subscribe(() => {
      this.store.dispatch(new RefreshFileInDetails(this.fileDetailsDialogId, this.fileId));
    });

    this.canEditInOffice$ = this.file$.pipe(
      // tslint:disable-next-line:no-boolean-literal-compare
      map(file => file._permissions?.editOffice !== false && this.webdavService.canEditInOffice(file.extension))
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  edit(file: FilePreview): void {
    this.webdavService
      .requestAccessToken(file.senderId, file.id)
      .pipe(
        mergeMap(token => this.webdavService.editFile(
            token,
            file.senderId,
            file.id,
            file.name,
            file.extension,
            file.subscriptionInfo.token
          )
        ),
        takeUntil(this.destroyed$)
      ).subscribe();
  }
}

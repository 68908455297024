<h1 mat-dialog-title translate="LIKE.LIST.TITLE"></h1>
<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
<div mat-dialog-content>
  <coyo-sender-list-item *ngFor="let like of (likes$ | async)"
                         [sender]="like.sender" avatarSize="xs" class="like">
  </coyo-sender-list-item>

  <!-- empty text -->
  <div class="text-center text-muted"
       *ngIf="!loading && !currentPage?.totalElements" translate="LIKE.LIST.EMPTY">
  </div>

  <!-- initial spinner / loading -->
  <div *ngIf="!currentPage" class="text-center">
    <coyo-spinner size="sm"></coyo-spinner>
  </div>
</div>
<div mat-dialog-actions *ngIf="currentPage && !currentPage.last">
  <button mat-flat-button color="primary" type="button"
          [disabled]="loading" (click)="loadMore()">{{'MORE' | translate}}</button>
</div>

import {Widget} from '@domain/widget/widget';
import {StateOperator} from '@ngxs/store';
import {append, patch} from '@ngxs/store/operators';
import {PatchSpec} from '@ngxs/store/operators/patch';
import {RepairType} from '@ngxs/store/operators/utils';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {EditScopeStateModel, SlotId, WidgetLayoutStateModel, WidgetStateModel} from '@widgets/api/widget-state.model';

export function getWidgetSlotStateAccessor(slot: SlotId): string {
  let name = slot.name;
  if (slot.language) {
    name += '-' + slot.language;
  }
  return name;
}

export function patchLayout(name: string, patchObj: PatchSpec<WidgetLayoutStateModel>): StateOperator<WidgetStateModel> {
  return patch<WidgetStateModel>({
    layouts: patch({
      [name]: patch(patchObj)
    })
  });
}

export function patchEditScope(name: string, patchObj: PatchSpec<EditScopeStateModel>): StateOperator<WidgetStateModel> {
  return patch<WidgetStateModel>({
    editScopes: patch({
      [name]: patch(patchObj)
    })
  });
}

export function appendToWidgetSlot(slot: SlotId, widget: Widget<WidgetSettings>): StateOperator<WidgetStateModel> {
  return patch<WidgetStateModel>({
    slots: patch({
        [getWidgetSlotStateAccessor(slot)]: append([widget])
    })
  });
}

type predicate<T> = (value?: T | RepairType<T> | Readonly<RepairType<T>>) => boolean;

export function updateItems<T>(selector: predicate<T>, operatorOrValue: T | StateOperator<T>): StateOperator<T[]> {
  return ((existing: T[]) => {
    const indexes = [];

    if (typeof selector !== 'number') {
      for (let i = 0; i < existing.length; i++) {
        if (selector(existing[i])) {
          indexes.push(i);
        }
      }
    } else {
      indexes.push(selector);
    }

    const clone = existing.slice();
    indexes.forEach(value => {
      const newValue = typeof operatorOrValue === 'function'
        ? (operatorOrValue as Function)(existing[value])
        : operatorOrValue;
      clone[value] = newValue;
    });

    return clone;
  });
}

(function (angular) {
  'use strict';

  JobModel.$inject = ["restResourceFactory", "Page", "coyoEndpoints"];
  angular
      .module('coyo.domain')
      .factory('JobModel', JobModel);

  /**
   * @ngdoc service
   * @name coyo.domain.JobModel
   *
   * @description
   * Provides the Coyo job model.
   *
   * @requires $http
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   */
  function JobModel(restResourceFactory, Page, coyoEndpoints) {
    var JobModel = restResourceFactory({
      url: coyoEndpoints.job.jobs
    });

    // instance members
    angular.extend(JobModel.prototype, {

      /**
       * @ngdoc function
       * @name coyo.domain.JobModel#getStatus
       * @methodOf coyo.domain.JobModel
       *
       * @description
       * Get the status list for this job.
       *
       * @param {object} pageable The paging information.
       *
       * @returns {promise} An $http promise
       */
      getStatus: function (pageable) {
        var url = JobModel.$url({name: this.name}) + '/status';
        var params = pageable.getParams();
        return JobModel.$get(url, params).then(function (response) {
          return new Page(response, params, {
            url: url
          });
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.JobModel#start
       * @methodOf coyo.domain.JobModel
       *
       * @description
       * Start the job manually
       *
       * @returns {promise} An $http promise
       */
      start: function () {
        return this.$put(this.$url('start'));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.JobModel#stop
       * @methodOf coyo.domain.JobModel
       *
       * @description
       * Stops the job manually
       *
       * @returns {promise} An $http promise
       */
      stop: function () {
        return this.$put(this.$url('stop'));
      }
    });

    return JobModel;
  }

})(angular);

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {UrlService} from '@core/http/url/url.service';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {User} from '@domain/user/user';
import {WidgetComponent} from '@widgets/api/widget-component';
import {WelcomeWidget} from '@widgets/welcome/welcome-widget';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

/**
 * This widget creates a welcome banner
 */
@Component({
  selector: 'coyo-welcome-widget',
  templateUrl: './welcome-widget.component.html',
  styleUrls: ['./welcome-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeWidgetComponent extends WidgetComponent<WelcomeWidget> implements OnInit, OnChanges {

  currentUser$: Observable<User>;
  showCover$: Observable<boolean>;
  coverStyle: string;

  constructor(private urlService: UrlService,
              private authService: AuthService,
              private windowSizeService: WindowSizeService,
              cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.currentUser$ = this.authService.getUser();
    this.showCover$ = this.getShowCover$();
  }

  ngOnChanges(): void {
    this.showCover$ = this.getShowCover$();
  }

  private getShowCover$(): Observable<boolean> {
    return this.currentUser$.pipe(
      tap(user => this.coverStyle = this.getCoverStyle(user)),
      map(user => this.widget.settings._showCover && !!_.get(user, 'imageUrls.cover', ''))
    );
  }

  private getCoverStyle(user: User): string {
    if (this.widget.settings._showCover) {
      const backendUrl = this.urlService.getBackendUrl();
      const coverUrl = _.get(user, 'imageUrls.cover', '');
      const size = this.windowSizeService.isRetina() ? 'XXL' : 'XL';
      return 'url(' + backendUrl + coverUrl + '&imageSize=' + size + ')';
    }
    return '';
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {MarkdownService} from './markdown.service';

/**
 * Transforms text containing markdown to HTML.
 */
@Pipe({name: 'markdown'})
export class MarkdownPipe implements PipeTransform {

  constructor(private markdownService: MarkdownService) {
  }

  /**
   * Adds HTML enrichment for markdown syntax.
   *
   * @param text the markdown-flavoured source text
   * @return the HTML enriched text
   */
  transform(text: string): string {
    return this.markdownService.markdown(text);
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {COMPLETE_PROFILE_WIDGET} from '@widgets/complete-profile/complete-profile-widget-config';
import {CompleteProfileWidgetComponent} from './complete-profile-widget/complete-profile-widget.component';

/**
 * Module providing the complete profile widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    CompleteProfileWidgetComponent
  ],
  entryComponents: [
    CompleteProfileWidgetComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: COMPLETE_PROFILE_WIDGET, multi: true}
  ]
})
export class CompleteProfileModule {
}

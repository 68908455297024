import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {timezones} from '@app/account/settings-timezone/timezones';
import {AuthService} from '@core/auth/auth.service';
import {UserService} from '@domain/user/user.service';
import {WINDOW} from '@root/injection-tokens';

/**
 * A form element to change the current user's time zone.
 */
@Component({
  selector: 'coyo-settings-timezone',
  templateUrl: './settings-timezone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsTimezoneComponent implements OnInit {
  readonly timezones: { key: string, offset: number }[] = timezones;

  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              @Inject(WINDOW) private window: Window) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({timezone: ['', [Validators.required]]});
    this.authService.getUser().subscribe(user =>
      this.form.patchValue({timezone: user.timezone}));
  }

  /**
   * Submits the time zone form.
   */
  submit(): void {
    this.setDisabled(true);
    const timezone = this.form.get('timezone').value;
    this.userService.setTimezone(timezone).subscribe(
      () => this.window.location.reload(),
      () => this.setDisabled(false));
  }

  private setDisabled(disabled: boolean): void {
    return disabled
      ? this.form.controls.timezone.disable()
      : this.form.controls.timezone.enable();
  }
}

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UpdateWidgetSettings} from '@widgets/api/widget.actions';
import {Bookmark} from '@widgets/bookmarking/bookmark.model';
import {BookmarkingWidget} from '@widgets/bookmarking/bookmarking-widget';
import {BookmarkingWidgetSettings} from '@widgets/bookmarking/bookmarking-widget-settings.model';
import * as _ from 'lodash';

/**
 * Widget to display bookmarks.
 */
@Component({
  selector: 'coyo-bookmarking',
  templateUrl: './bookmarking.component.html',
  styleUrls: ['./bookmarking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkingComponent extends WidgetComponent<BookmarkingWidget> implements OnInit {

  constructor(cd: ChangeDetectorRef, private store: Store) {
    super(cd);
  }

  ngOnInit(): void {
    if (!this.bookmarks) {
      this.store.dispatch(new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId, {
        _bookmarks: [] as Bookmark[],
      } as BookmarkingWidgetSettings, this.editScope));
    }
  }

  get bookmarks(): Bookmark[] {
    return this.widget.settings._bookmarks;
  }

  removeBookmark(idx: number): void {
    const newList = [...this.bookmarks];
    newList.splice(idx, 1);
    this.updateSettings(newList);
  }

  changeBookmark(bookmark: Bookmark, idx: number): void {
    const newList = [...this.bookmarks];
    newList.splice(idx, 1, bookmark);
    this.updateSettings(newList);
  }

  addBookmark(bookmark: Bookmark): void {
    const newList = [...this.bookmarks, bookmark];
    this.updateSettings(newList);
  }

  drop(event: CdkDragDrop<any>): void {
    const newList = [...this.bookmarks];
    moveItemInArray(newList, event.previousIndex, event.currentIndex);
    this.updateSettings(newList);
  }

  private updateSettings(bookmarks: Bookmark[]): void {
    this.store.dispatch(new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId, {
      ...this.widget.settings,
      _bookmarks: bookmarks
    } as BookmarkingWidgetSettings, this.editScope));
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef} from '@angular/core';
import {ACTIONS_TEMPLATE_REF, ActionsMenuOverlayComponent} from '@app/filter/filter-box/actions-menu-overlay/actions-menu-overlay.component';
import {
  FILTER_TEMPLATE_REF,
  FilterMenuOverlayComponent
} from '@app/filter/filter-box/filter-menu-overlay/filter-menu-overlay.component';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {OverlayService} from '@shared/overlay/overlay.service';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Filter box component that wraps the filters and renders given templates
 * via content projection to the correct layout.
 * @example
 * <coyo-filter-box>
 *   <fb-filter>
 *     [...]
 *   </fb-filter>
 *   <fb-search>
 *     [...]
 *   </fb-search>
 *   <fb-count>
 *     [...]
 *   </fb-count>
 *   <fb-actions>
 *     [...]
 *   </fb-actions>
 * </coyo-filter-box>
 */
@Component({
  selector: 'coyo-filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBoxComponent implements OnInit {

  /**
   * Indicates that a filter is active.
   */
  @Input()
  filterActive: boolean = false;

  /**
   * If true the filter section will not be displayed.
   */
  @Input()
  hideFilter: boolean = false;

  /**
   * If true the search section will not be displayed.
   */
  @Input()
  hideSearch: boolean = false;

  /**
   * If true the actions section will not be displayed.
   */
  @Input()
  hideActions: boolean = false;

  /**
   * Hide actions menu button on mobile view. Use this if you implemented a custom solution.
   * E.g.: Like in events.list.html
   */
  @Input()
  hideActionsOnMobile: boolean = false;

  /**
   * Vertical layout (desktop view).
   */
  @Input()
  vertical: boolean = false;

  isMobile$: Observable<boolean>;
  private isFilterMenuVisible: boolean = false;
  private isActionsMenuVisible: boolean = false;
  private filterOverlaySubscription: Subscription;
  private actionsOverlaySubscription: Subscription;

  constructor(private windowSizeService: WindowSizeService,
              private overlayService: OverlayService) {
  }

  ngOnInit(): void {
    this.isMobile$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS || screenSize === ScreenSize.SM));
  }

  openFilterOverlay(tempRef: TemplateRef<any>): void {
    if (!this.isFilterMenuVisible) {
      this.filterOverlaySubscription = this.overlayService.open(FilterMenuOverlayComponent, {
        scrollStrategy: this.overlayService.scrollStrategies.block(),
        panelClass: 'filter-box-filter-menu-overlay',
        hasBackdrop: false,
        height: '100%',
        width: '100%'
      }, [{provide: FILTER_TEMPLATE_REF, useValue: tempRef}]).subscribe(() => {
        this.isFilterMenuVisible = false;
        this.filterOverlaySubscription.unsubscribe();
      });
      this.isFilterMenuVisible = true;
    }
  }

  openActionsOverlay(tempRef: TemplateRef<any>): void {
    if (!this.isActionsMenuVisible) {
      this.actionsOverlaySubscription = this.overlayService.open(ActionsMenuOverlayComponent, {
        scrollStrategy: this.overlayService.scrollStrategies.block(),
        panelClass: 'filter-box-actions-menu-overlay',
        hasBackdrop: true,
        height: '100%',
        width: '100%'
      }, [{provide: ACTIONS_TEMPLATE_REF, useValue: tempRef}]).subscribe(() => {
        this.isActionsMenuVisible = false;
        this.actionsOverlaySubscription.unsubscribe();
      });
      this.isActionsMenuVisible = true;
    }
  }

}

(function (angular) {
  'use strict';

  BlogArticleEditController.$inject = ["$q", "$scope", "$state", "$timeout", "authService", "article", "app", "sender", "$injector", "BlogArticleModel", "moment"];
  angular
      .module('coyo.apps.blog')
      .controller('BlogArticleEditController', BlogArticleEditController);

  /**
   * Controller for creating and editing a blog article.
   *
   * @requires $q
   * @requires $scope
   * @requires $state
   * @requires $timeout
   * @requires authService
   * @requires article
   * @requires app
   * @requires sender
   * @requires WidgetLayoutModel
   * @constructor
   */
  function BlogArticleEditController($q, $scope, $state, $timeout, authService, article, app,
                                     sender, $injector, BlogArticleModel, moment) {
    var vm = this;
    var isEn = moment.locale().includes('en');

    vm.$onInit = init;
    vm.article = article;
    vm.originalArticle = angular.copy(vm.article);
    vm.app = app;
    vm.sender = sender;
    vm.editMode = true;
    vm.activeTab = 0;
    vm.publishAs = (vm.article.publishAsAuthor) ? 'AUTHOR' : 'SENDER';
    vm.teaserImage = article.teaserImage;
    vm.teaserImageWide = article.teaserImageWide;
    vm.simpleMode = true;
    vm.isSenderTranslated = false;
    vm.defaultLanguage;
    vm.currentLanguage;
    vm.languages = {};
    vm.languageInitialised = {};

    /**
     * Wanted behaviour:
     * - User is publisher and creates a new article: Instant publishing
     * - User is publisher/editor and edits a draft: No instant publishing
     * - User is not publisher: No publishing possible
     */
    if (vm.article.publishDate) {
      vm.publishStatus = 'PUBLISHED_AT';
      vm.article.publishDate = new Date(vm.article.publishDate);
      vm.article.publishTime = new Date(vm.article.publishDate);
    } else if (vm.app._permissions.publishArticle && vm.article.isNew()) {
      vm.publishStatus = 'PUBLISHED';
    } else {
      vm.publishStatus = 'DRAFT';
    }

    vm.resetPublish = resetPublish;
    vm.onLanguageChange = onLanguageChange;
    vm.onLanguageDeleted = onLanguageDeleted;
    vm.save = save;
    vm.cancel = cancel;
    vm.toggleAuthor = toggleAuthor;
    vm.isTranslationRequired = isTranslationRequired;
    vm.updateValidity = updateValidity;
    vm.setTime = setTime;
    vm.isMeridian = isMeridian;
    vm.getTimeFormat = getTimeFormat;
    vm.changeDate = changeDate;
    vm.getLayoutLanguageKey = getLayoutLanguageKey;

    function resetPublish() {
      vm.article.publishDate = null;
      vm.article.publishTime = null;
      vm.article.published = false;
    }

    function onLanguageDeleted(language) {
      vm.languageInitialised[language] = false;
      return $injector.get('ngxWidgetEditService')
          .markLayoutLanguageForDeletion(vm.app.id, vm.article.buildLayoutName(vm.app.id), language).toPromise();
    }

    function onLanguageChange(copyFromDefault) {
      vm.languageInitialised[vm.currentLanguage] = true;
      var defaultLayoutName = vm.article.buildLayoutName(vm.app.id);
      if (copyFromDefault) {
        $injector.get('ngxWidgetEditService')
            .duplicateLayoutForLanguage(vm.app.id, defaultLayoutName, vm.getLayoutLanguageKey(vm.currentLanguage));
      } else {
        var parent = {id: vm.app.id, typeName: 'app'};
        $injector.get('ngxWidgetEditService')
            .initializeEmptyLayoutForLanguage(vm.app.id, defaultLayoutName, vm.getLayoutLanguageKey(vm.currentLanguage), parent);
      }
    }

    function save() {
      vm.article.teaserImage = vm.languages[vm.defaultLanguage].translations.teaserImage ? _.pick(
          vm.languages[vm.defaultLanguage].translations.teaserImage, ['fileId', 'senderId']) : null;
      vm.article.teaserImageWide = vm.languages[vm.defaultLanguage].translations.teaserImageWide ? _.pick(
          vm.languages[vm.defaultLanguage].translations.teaserImageWide, ['fileId', 'senderId']) : null;

      if (vm.publishStatus === 'DRAFT') {
        vm.article.publishDate = null;
        vm.article.publishTime = null;
      } else if (vm.publishStatus === 'PUBLISHED' || !vm.article.publishDate) {
        vm.article.publishDate = new Date();
      }

      prepareTranslations();

      return article.save().then(function () {
        $timeout(function () {
          // allow article / layout name change to propagate
          $injector.get('ngxWidgetEditService').save(vm.app.id).toPromise().then(function () {
            $state.go('^.view', {id: vm.article.id});
          });
        });
      });

      function prepareTranslations() {
        vm.article.defaultLanguage = (vm.defaultLanguage === 'NONE') ? null : vm.defaultLanguage;
        // -- add translations for default language to article model
        if (vm.languages[vm.defaultLanguage]) {
          angular.forEach(vm.languages[vm.defaultLanguage].translations, function (value, key) {
            vm.article[key] = value;
          });
        }
        // -- add additional translations to article model
        vm.article.translations = {};
        angular.forEach(vm.languages, function (value, key) {
          if (value.active) {
            vm.article.translations[key] = value.translations ? value.translations : {};
          }
        });
        delete vm.article.translations[vm.defaultLanguage];

        // -- flatten teaserImage/Wide objects and add/remove them to/from article model
        angular.forEach(vm.languages, function (value, key) {
          if (key !== vm.defaultLanguage && vm.article.translations[key]) {
            var articleTranslation = vm.article.translations[key];

            handleImages('teaserImage', value, key, articleTranslation);
            handleImages('teaserImageWide', value, key, articleTranslation);
          }
        });

        function handleImages(type, value, key, articleTranslation) {
          var currentImage = value.translations[type],
              fileId = type + 'FileId',
              senderId = type + 'SenderId';
          if (currentImage) {
            if (currentImage.hasOwnProperty('fileId') && currentImage.hasOwnProperty('senderId')) {
              articleTranslation[fileId] = currentImage.fileId;
              articleTranslation[senderId] = currentImage.senderId;
            }
            delete vm.article.translations[key][type];
          } else if (articleTranslation[fileId] || articleTranslation[senderId]) {
            delete articleTranslation[fileId];
            delete articleTranslation[senderId];
          }
        }
      }
    }

    function cancel() {
      $injector.get('ngxWidgetEditService').cancelEdit(vm.app.id).toPromise().then(function () {
        if (article.isNew()) {
          $state.go('^');
        } else {
          $state.go('^.view', {id: vm.article.id});
        }
      });
    }

    function toggleAuthor() {
      vm.article.publishAsAuthor = (vm.publishAs === 'AUTHOR');
    }

    function isTranslationRequired(language) {
      return vm.sender.isTranslationRequired(vm.languages, vm.currentLanguage, language);
    }

    function updateValidity(key, valid) {
      vm.languages[key].valid = valid;
    }

    function setTime() {
      if (angular.isDefined(vm.article.publishTime) || vm.article.publishTime === null) {
        vm.article.publishDate.setHours(vm.article.publishTime.getHours(), vm.article.publishTime.getMinutes());
      }
    }

    function isMeridian() {
      return isEn;
    }

    function getTimeFormat() {
      return isEn ? 'hh:mm a' : 'HH:mm';
    }

    function changeDate() {
      if (angular.isUndefined(vm.article.publishTime) || vm.article.publishTime === null) {
        vm.article.publishTime = vm.article.publishDate;
      }
    }

    function getLayoutLanguageKey(lang) {
      if (!!lang && !!sender.defaultLanguage && lang !== 'NONE' && sender.defaultLanguage !== lang) {
        return lang;
      }
      return null;
    }

    /* ===== PRIVATE METHODS ===== */

    function init() {
      $injector.get('ngxWidgetEditService').enableEditMode(vm.app.id);
      authService.getUser().then(function (currentUser) {
        vm.currentUser = currentUser;
      });

      BlogArticleModel.canPublishAsSender(vm.app).then(function (result) {
        vm.canActAsSender = result;
      });

      initTranslations();

      if (vm.article.isNew()) {
        $injector.get('ngxWidgetEditService').initializeEmptyLayoutForLanguage(vm.app.id, vm.article.buildLayoutName(vm.app.id), vm.getLayoutLanguageKey(vm.currentLanguage), {
          id: vm.app.id,
          typeName: 'app'
        });
      }

      $scope.$on('$destroy', function () {
        $injector.get('ngxWidgetEditService').cancelEdit(vm.app.id);
      });
    }

    function initTranslations() {
      vm.isSenderTranslated = vm.sender.isSenderTranslated();
      vm.defaultLanguage = vm.sender.defaultLanguage !== null ? vm.sender.defaultLanguage : 'NONE';
      vm.currentLanguage = vm.defaultLanguage;

      if (!vm.article.defaultLanguage) {
        vm.article.defaultLanguage = vm.defaultLanguage;
      }

      // create translations with default values for all available languages
      var availableLanguages = _.concat(_.keys(vm.sender.translations), vm.defaultLanguage);
      vm.languages = _.zipObject(availableLanguages, _.map(availableLanguages, function () {
        return {active: true, translations: {}};
      }));
      // add default translations of blog article or init, if blog article not already translated
      vm.languages[(vm.article.defaultLanguage) ? vm.article.defaultLanguage : vm.currentLanguage] = {
        'active': true,
        'translations': {
          'title': article.title ? article.title : '',
          'teaserText': article.teaserText ? article.teaserText : '',
          'showTeaserWithText': article.showTeaserWithText ? article.showTeaserWithText : false,
          'teaserImage': article.teaserImage ? article.teaserImage : '',
          'teaserImageWide': article.teaserImageWide ? article.teaserImageWide : ''
        }
      };
      if (!vm.article.isNew()) {
        vm.languageInitialised[vm.article.defaultLanguage] = true;
      }
      // add all remaining translations of the blog article
      _.forEach(article.translations, function (value, key) {
        if (vm.languages[key]) {
          vm.languages[key].translations = value;

          // rebuild teaserImage objects with fileId and senderId
          if (value.teaserImageFileId && value.teaserImageSenderId) {
            vm.languages[key].translations.teaserImage =
                {'fileId': value.teaserImageFileId, 'senderId': value.teaserImageSenderId};
          }
          // rebuild teaserImageWide objects with fileId and senderId
          if (value.teaserImageWideFileId && value.teaserImageWideSenderId) {
            vm.languages[key].translations.teaserImageWide =
                {'fileId': value.teaserImageWideFileId, 'senderId': value.teaserImageWideSenderId};
          }
        }
      });
    }
  }

})(angular);

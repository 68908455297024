export class InitializeVoters {
  static readonly type: string = '[PollWidgetVotersModal] Init Voters';

  constructor(public widgetId: string, public optionId: string) {
  }
}

export class LoadMoreVoters {
  static readonly type: string = '[PollWidgetVotersModal] Load More Voters';

  constructor(public widgetId: string, public optionId: string) {
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {Terms} from '@domain/terms/terms';
import {Observable} from 'rxjs';

/**
 * Service to retrieve and manage users.
 */
@Injectable({
  providedIn: 'root'
})
export class TermsService extends DomainService<void, Terms> {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(UrlService) urlService: UrlService) {
    super(http, urlService);
  }

  getBestSuitable(): Observable<Terms> {
    return this.get('suitable', {});
  }

  protected getBaseUrl(): string {
    return '/web/terms';
  }
}

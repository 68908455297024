import {Inject, Injectable} from '@angular/core';
import {Document} from '@domain/file/document';
import {DocumentService} from '@domain/file/document/document.service';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {Ng1FileLibraryModalService} from '@root/typings';
import {RtePlugin} from '@shared/rte/rte-plugin';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';
import {NG1_FILE_LIBRARY_MODAL_SERVICE} from '@upgrade/upgrade.module';
import {from} from 'rxjs';

/**
 * A custom RTE plugin that connects the Froala editor to the COYO file library for video selection.
 */
@Injectable()
export class VideoFromFileLibraryPlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertVideoFromFileLibrary';

  constructor(@Inject(FROALA_EDITOR) private froala: any,
              @Inject(NG1_FILE_LIBRARY_MODAL_SERVICE) private fileLibraryModalService: Ng1FileLibraryModalService,
              private translateService: TranslateService,
              private documentService: DocumentService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    if (!settings.canAccessFiles) {
      return; // user is not allowed to access the file library
    }

    const plugin = this;
    this.froala.RegisterCommand(VideoFromFileLibraryPlugin.KEY, {
      title: plugin.translateService.instant('RTE.VIDEO.INSERT_VIDEO'),
      plugin: 'coyoVideoPlugin',
      icon: 'insertVideo',
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        plugin.openCoyoFileLibrary(this);
      }
    });
  }

  private openCoyoFileLibrary(editor: any): void {
    const plugin = this;
    const wrapper = editor.$oel.find('.fr-wrapper');
    const scrollPosition = wrapper.scrollTop();

    editor.selection.save();
    from(plugin.fileLibraryModalService.open(editor.getSender(), {
      selectMode: 'single',
      filterContentType: 'video/mp4',
      initialFolder: editor.getApp() ? {
        id: editor.getApp().rootFolderId
      } : null
    })).subscribe((document: Document) => {
      const url = plugin.documentService.getStreamUrl(document);
      editor.selection.restore();
      editor.video.insert(`<video src="${url}" controls></video>`);
      wrapper.scrollTop(scrollPosition);
    }, () => {
      editor.selection.restore();
      wrapper.scrollTop(scrollPosition);
    });
  }
}

<div class="lightbox-header">
  <div class="pagination-info">
    <span class="overlay-text empty">
        {{'WIDGET.MEDIA.LIGHTBOX.OF' | translate: ({count: currentMedia.sortOrderId + 1, length: media.length})}}
      </span>
    <coyo-download-button class="coyo-download-button" [fileId]="currentMedia.id" [senderId]="currentMedia.senderId"></coyo-download-button>
  </div>
  <span class="headline">{{ currentMedia.description || currentMedia.name }}</span>
  <div class="close-wrapper">
    <mat-dialog-close aria-label="Close Dialog"></mat-dialog-close>
  </div>
</div>


<div class="lightbox-content">
  <button (click)="loadPrevious()" class="prev-media" mat-icon-button [attr.aria-label]="'WIDGET.MEDIA.LIGHTBOX.PREVIOUS' | translate" size="large">
    <mat-icon svgIcon="chevron-left" size="large"></mat-icon>
  </button>
  <coyo-file-image-preview *ngIf="isImage(currentMedia)" [file]="currentMedia" [groupId]="currentMedia.senderId"
                           [options]="{backgroundImage: false}"
                           [previewUrl]="previewUrl"
                           size="XXL"
                           [attr.aria-label]=" currentMedia.name | translate" class="light-size">
  </coyo-file-image-preview>
  <coyo-file-video-preview *ngIf="isVideo(currentMedia)" [file]="currentMedia"
                           [groupId]="currentMedia.senderId"
                           [url]="previewUrl"
                           #videoPreview
                           class="coyo-file-video-preview"
                           [attr.aria-label]=" currentMedia.name | translate">
  </coyo-file-video-preview>
  <button mat-icon-button class="next-media" [attr.aria-label]="'WIDGET.MEDIA.LIGHTBOX.NEXT' | translate" (click)="loadNext()" size="large">
    <mat-icon svgIcon="chevron-right" size="large"></mat-icon>
  </button>
</div>

import {Injectable} from '@angular/core';
import {ResizeSensor} from 'css-element-queries';
import {ResizeSensorCallback} from 'css-element-queries/src/ResizeSensor';

/**
 * A service to provide a new ResizeSensor.
 */
@Injectable()
export class ResizedService {

  /**
   * Creates a new resize sensor on given elements. The provided callback is
   * called max 1 times per requestAnimationFrame and is called initially.
   * The resize sensor needs to be detach when done (on destroy for example)
   *
   * @param element the element to attach the sensor to
   * @param callback the callback to be executed
   * @return a new resize sensor for the element or `null` if the ResizeSensor is not available
   */
  getResizeSensor(element: Element | Element[], callback: ResizeSensorCallback): ResizeSensor | null {
    // only initialize resize watching if sensor is available
    return ResizeSensor ? new ResizeSensor(element, callback) : null;
  }
}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {UserList} from '@shared/sender-ui/user-list/user-list';
import {
  Load,
  Reset
} from '@widgets/interesting-colleagues/interesting-colleagues-widget/interesting-colleagues.actions';
import {InterestingColleaguesService} from '@widgets/interesting-colleagues/interesting-colleagues.service';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export interface InterestingColleaguesStateModel {
  [key: string]: UserList;
}

@Injectable({providedIn: 'root'})
@State<InterestingColleaguesStateModel>({
  name: 'interestingColleagues',
  defaults: {}
})
export class InterestingColleaguesWidgetState {

  constructor(private interestingColleaguesService: InterestingColleaguesService) {
  }

  @Action(Load)
  init(ctx: StateContext<InterestingColleaguesStateModel>, action: Load): Observable<any> {
    this.setLoading(ctx, action.id, true);
    return this.interestingColleaguesService.getInterestingColleaguesList()
      .pipe(
        tap(res => {
          ctx.patchState({
            [action.id]: {
              ...ctx.getState()[action.id],
              users: res.content,
              loading: false
            }
          });
        }));
  }

  @Action(Reset)
  destroy(ctx: StateContext<InterestingColleaguesStateModel>, action: Reset): void {
    ctx.setState({..._.omit(ctx.getState(), action.id)});
  }

  private setLoading(ctx: StateContext<InterestingColleaguesStateModel>, id: string, loading: boolean): void {
    ctx.patchState({
      [id]: {
        ...ctx.getState()[id],
        users: [],
        loading
      }
    });
  }
}

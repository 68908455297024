import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {NewColleaguesWidget} from '@widgets/new-colleagues/new-colleagues-widget';
import {NewColleaguesWidgetComponent} from '@widgets/new-colleagues/new-colleagues-widget/new-colleagues-widget.component';

export const NEW_COLLEAGUES_WIDGET: WidgetConfig<NewColleaguesWidget> = {
  key: 'newcolleagues',
  name: 'WIDGET.NEW.COLLEAGUES.NAME',
  description: 'WIDGET.NEW.COLLEAGUES.DESCRIPTION',
  defaultTitle: 'WIDGET.NEW.COLLEAGUES.TITLE',
  icon: 'zmdi-accounts-add',
  component: NewColleaguesWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  renderOptions: {
    printable: false,
  },
  whitelistExternal: false
};

<div class="launchpad-link-wrapper">
  <a [attr.href]="href" target="_blank" class="launchpad-link-item">
    <coyo-launchpad-link-icon [iconSrc]="link | launchpadLinkIconSrc" [color]="color"></coyo-launchpad-link-icon>
    <div class="link-content">
      <div class="name">{{link.name}}</div>
      <div aria-hidden="true" class="url">{{link.url}}</div>
    </div>
  </a>
  <button type="button" mat-icon-button [matMenuTriggerFor]="menu" size="small" *ngIf="canManage"
          [attr.aria-label]="'CONTEXT_MENU.SHOW' | translate" [attr.data-link-menu]="link.id">
    <mat-icon svgIcon="more-horizontal"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button type="button" mat-menu-item (click)="edit.emit()">
      <mat-icon svgIcon="pencil"></mat-icon> {{ 'EDIT' | translate}}
    </button>
    <button type="button" mat-menu-item (click)="delete.emit()">
      <mat-icon svgIcon="delete"></mat-icon> {{ 'DELETE' | translate}}
    </button>
  </mat-menu>
</div>

/**
 * Maps file extensions to the appropriate office application that handles the file type.
 * For example this is used to allow edit in office feature.
 */
export const OFFICE_EXT_APP_MAP = {
  dot: 'ms-word',
  dotm: 'ms-word',
  dotx: 'ms-word',
  doc: 'ms-word',
  docx: 'ms-word',
  docm: 'ms-word',
  xls: 'ms-excel',
  xlsx: 'ms-excel',
  xlsm: 'ms-excel',
  xlsb: 'ms-excel',
  pot: 'ms-powerpoint',
  potm: 'ms-powerpoint',
  potx: 'ms-powerpoint',
  ppt: 'ms-powerpoint',
  pptx: 'ms-powerpoint',
  ppsx: 'ms-powerpoint',
  pptm: 'ms-powerpoint',
  vsd: 'ms-visio',
  vsdx: 'ms-visio'
};

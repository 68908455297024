import {Injectable, Type} from '@angular/core';
import {FileLibraryRootFilePickerItem} from '@app/file-library/file-library-root-file-picker-item/file-library-root-file-picker-item';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {LandingPagesRootFilePickerItem} from '@app/file-library/landing-page-root-file-picker-item/landing-pages-root-file-picker-item';
import {MyFilesRootFilePickerItem} from '@app/file-library/my-files-file-picker-item/my-files-root-file-picker-item';
import {PagesRootFilePickerItem} from '@app/file-library/pages-root-file-picker-item/pages-root-file-picker-item';
import {WorkspacesRootFilePickerItem} from '@app/file-library/workspace-root-file-picker-item/workspaces-root-file-picker-item';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemService} from '@app/file-picker/file-picker-item.service';
import {AuthService} from '@core/auth/auth.service';
import {SinglePage} from '@domain/page/single-page';
import {Page} from '@domain/pagination/page';
import {NgxPermissionsService} from 'ngx-permissions';
import {forkJoin, from, Observable} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

/**
 * Service responsible for handling the file library root. It will check for the
 * respective permissions and return the different library folders accordingly
 */
@Injectable({
  providedIn: 'root'
})
export class FileLibraryRootFilePickerItemService extends FilePickerItemService {

  constructor(
    private readonly authService: AuthService,
    private readonly permissionService: NgxPermissionsService) {
    super();
  }

  getChildren(filePickerItem: FileLibraryRootFilePickerItem): Observable<Page<FilePickerItem>> {
    return this.authService.getUser$().pipe(take(1), switchMap(user => forkJoin([
      from(this.permissionService.hasPermission('ACCESS_FILES'))
        .pipe(map(hasPermission => hasPermission ? new MyFilesRootFilePickerItem(user) : null)),
      from(this.permissionService.hasPermission('ACCESS_LANDING_PAGES'))
        .pipe(map(hasPermission => hasPermission ? new LandingPagesRootFilePickerItem() : null)),
      from(this.permissionService.hasPermission('ACCESS_PAGES'))
        .pipe(map(hasPermission => hasPermission ? new PagesRootFilePickerItem() : null)),
      from(this.permissionService.hasPermission('ACCESS_WORKSPACES'))
        .pipe(map(hasPermission => hasPermission ? new WorkspacesRootFilePickerItem() : null))
    ])
      .pipe(map(items => new SinglePage(items.filter(item => !!item))))));
  }

  getCapabilities(filePickerItem: FilePickerItem): FilePickerItemServiceCapability {
    return FilePickerItemServiceCapability.NONE;
  }

  getResponsibilities(): Type<FilePickerItem>[] {
    return [FileLibraryRootFilePickerItem];
  }
}

<div [formGroup]="parentForm">
  <tabset [justified]="true">
    <tab [heading]="'WIDGET.RSS.SETTINGS.CONFIG' | translate">
      <div class="form-group" [class.has-error]="isInvalid('rssUrl')">
        <label for="rssUrl" class="col-sm-2 control-label" translate="WIDGET.RSS.SETTINGS.CONFIG.URL.LABEL"></label>
        <div class="col-sm-9">
          <div class="input-group">
            <div class="input-group-addon"><i class="zmdi zmdi-link"></i></div>
            <input name="rssUrl" id="rssUrl" type="url" formControlName="rssUrl" class="form-control"
                   placeholder="https://" aria-required="true">
          </div>
        </div>
      </div>
      <div class="form-group"
           [hidden]="!(parentForm.get('rssUrl').errors?.unauthorized || parentForm.get('userName').value)">
        <label for="userName" class="col-sm-2 control-label"
               translate="WIDGET.RSS.SETTINGS.CONFIG.USER.LABEL"></label>
        <div class="col-sm-9">
          <input name="userName" id="userName" type="text" formControlName="userName" class="form-control">
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="WIDGET.RSS.SETTINGS.CONFIG.USER.HELP"></coyo-help>
        </div>
      </div>
      <div class="form-group"
           [hidden]="!(parentForm.get('rssUrl').errors?.unauthorized || parentForm.get('userName').value)">
        <label for="password" class="col-sm-2 control-label"
               translate="WIDGET.RSS.SETTINGS.CONFIG.PASSWORD.LABEL"></label>
        <div class="col-sm-9">
          <input name="_encrypted_password" id="password" type="password" formControlName="_encrypted_password"
                 class="form-control">
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="WIDGET.RSS.SETTINGS.CONFIG.PASSWORD.HELP"></coyo-help>
        </div>
      </div>
      <div class="form-group"
           [ngClass]="parentForm.get('rssUrl').valid ? 'has-success' : (isLoading ? 'has-warning' : 'has-error')">
        <div class="col-sm-10 col-sm-offset-2">
          <div class="verify-button-container">
            <button type="button" mat-flat-button color="primary"
                    [disabled]="(!parentForm.pending || isLoading) && parentForm.valid"
                    (click)="verifyUrl()">
              <span translate="WIDGET.RSS.SETTINGS.CONFIG.VERIFY.LABEL"></span>
            </button>
            <div class="help-block" *ngIf="parentForm.dirty">
              <p *ngIf="isLoading" translate="WIDGET.RSS.SETTINGS.CONFIG.LOADING"></p>
              <p *ngIf="parentForm.get('rssUrl').valid" translate="WIDGET.RSS.ERRORS.VALIDATION.VALID_URL"></p>
              <p *ngIf="parentForm.get('rssUrl').errors?.invalidUrl"
                 translate="WIDGET.RSS.ERRORS.VALIDATION.INVALID_URL"></p>
              <p *ngIf="parentForm.get('rssUrl').errors?.unauthorized" translate="WIDGET.RSS.ERRORS.AUTHENTICATION"></p>
              <p *ngIf="parentForm.get('rssUrl').errors?.invalidCredentials"
                 translate="WIDGET.RSS.SETTINGS.CONFIG.CREDENTIALS.INVALID"></p>
              <p *ngIf="parentForm.get('rssUrl').errors?.connectionFailure"
                 translate="WIDGET.RSS.SETTINGS.CONFIG.CONNECTIONFAILURE"></p>
            </div>
          </div>
        </div>
      </div>
    </tab>
    <tab [heading]="'WIDGET.RSS.SETTINGS.CONFIG.FURTHER.CONFIG' | translate">
      <div class="form-group">
        <label for="maxCount" class="col-sm-3 control-label" translate="WIDGET.RSS.SETTINGS.CONFIG.MAX.COUNT"></label>
        <div class="col-sm-8">
          <input name="maxCount" id="maxCount" min="1" type="number" formControlName="maxCount" class="form-control">
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="WIDGET.RSS.SETTINGS.CONFIG.MAX.COUNT.HELP"></coyo-help>
        </div>
      </div>
      <div class="form-group">
        <label for="displayImage" class="col-sm-3 control-label" translate="WIDGET.RSS.SETTINGS.CONFIG.IMAGE"></label>
        <div class="col-sm-8">
          <coyo-checkbox name="displayImage" id="displayImage" formControlName="displayImage">
          </coyo-checkbox>
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="WIDGET.RSS.SETTINGS.CONFIG.IMAGE.HELP"></coyo-help>
        </div>
      </div>
    </tab>
  </tabset>
</div>

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef} from '@angular/core';
import {AppService} from '@domain/apps/app.service';
import {Document} from '@domain/file/document';
import {Sender} from '@domain/sender/sender';
import {SelectFileDialogService} from '@shared/select-file/select-file-dialog/select-file-dialog.service';
import {CropSettings, SelectFileOptions} from '@shared/select-file/select-file-options';
import {EMPTY, Subject} from 'rxjs';
import {catchError, defaultIfEmpty, filter, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'coyo-select-file-button',
  templateUrl: './select-file-button.component.html',
  styleUrls: ['./select-file-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFileButtonComponent implements OnDestroy {
  /**
   * The sender
   */
  @Input() sender: Sender;

  /**
   * The options
   */
  @Input() options: SelectFileOptions;

  /**
   * The crop settings
   */
  @Input() cropSettings: CropSettings;

  /**
   * Template for button text/icon
   */
  @Input() buttonTemplate?: TemplateRef<any>;

  /**
   * The files that are already selected
   */
  @Input() selectedFiles?: Document[];

  /**
   * Emits the selected files
   */
  @Output() filesSelected: EventEmitter<Document[]> = new EventEmitter<Document[]>();

  private destroyed$: Subject<void> = new Subject<void>();

  constructor(private appService: AppService,
              private selectFileDialogService: SelectFileDialogService) {
  }

  /**
   * Gets the current selected app and opens the file library in the current context. After closing the file library
   * the filesSelected event is emitted.
   */
  selectFiles(): void {
    this.appService.getCurrentApp(this.sender.id)
      .pipe(catchError(() => EMPTY))
      .pipe(defaultIfEmpty(undefined))
      .pipe(switchMap(currentApp => {
        let initialFolder = {};
        if (currentApp && currentApp.rootFolderId) {
          initialFolder = {id: currentApp.rootFolderId};
        }
        return this.selectFileDialogService.open(this.sender, {...this.options, initialFolder}, this.cropSettings, this.selectedFiles);
      }))
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter(value => !!value))
      .subscribe((selection: Document[]) => this.filesSelected.next(selection));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UpdateWidgetSettings} from '@widgets/api/widget.actions';
import {TextWidget} from '@widgets/text/text-widget';
import {TextWidgetSettings} from '@widgets/text/text-widget-settings.model';

/**
 * This widget displays an input for plain text.
 */
@Component({
  selector: 'coyo-text-widget',
  templateUrl: './text-widget.component.html',
  styleUrls: ['./text-widget.component.scss']
})
export class TextWidgetComponent extends WidgetComponent<TextWidget> {

  @ViewChild('editContainer', {
    static: true
  }) editContainer: ElementRef<HTMLDivElement>;

  showInput: boolean = true;

  constructor(private store: Store, cd: ChangeDetectorRef) {
    super(cd);
  }

  /**
   * Hides the input on blur.
   */
  onBlur(): void {
    this.showInput = false;
  }

  /**
   * Shows the input on click.
   */
  onClick(): void {
    this.showInput = true;
    setTimeout(() => (this.editContainer.nativeElement.firstElementChild as HTMLTextAreaElement).focus());
  }

  /**
   * Content updated
   * @param $event the new content
   */
  contentUpdated($event: string): void {
    this.store.dispatch(new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId, {
      ...this.widget.settings,
      md_text: $event
    } as TextWidgetSettings, this.editScope));
  }
}

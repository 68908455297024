import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FilePickerData} from '@app/file-picker/file-picker-data';
import {InitSelection, ResetSelection} from '@app/file-picker/file-picker-selection/file-picker-selection.actions';
import {FilePickerSelectionsState} from '@app/file-picker/file-picker-selection/file-picker-selections-state';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {FilePickerComponent} from '@app/file-picker/file-picker/file-picker.component';
import {Store} from '@ngxs/store';
import * as _ from 'lodash';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

/**
 * This component contains the filepicker inside of a modal
 */
@Component({
  selector: 'coyo-file-picker-modal',
  templateUrl: './file-picker-modal.component.html',
  styleUrls: ['./file-picker-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(FilePickerComponent) filePicker: FilePickerComponent;

  count$: Observable<{ count: number }>;
  isMultiSelect$: Observable<boolean>;
  destroyed$: Subject<void>;
  canSubmit$: Observable<boolean>;

  constructor(
    readonly dialogRef: MatDialogRef<FilePickerModalComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: FilePickerData,
    private readonly store: Store,
  ) {
    this.destroyed$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.dialogRef.backdropClick().pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.dialogRef.close([]);
    });
    this.store.dispatch(new InitSelection(this.data.selectionType, this.data.selectedFiles, this.data.contentTypes));
    this.count$ = this.store.select(FilePickerSelectionsState.selectionCount).pipe(map(count => ({
      count
    })));
    this.isMultiSelect$ = this.store.select(FilePickerSelectionsState.isMultiSelect);
  }

  /**
   * Submits the selected files
   */
  onSubmitSelectedFiles(): void {
    this.dialogRef.close(_.values(this.store.selectSnapshot(FilePickerSelectionsState.selectedItems)));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetSelection());
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.canSubmit$ = combineLatest([this.count$, this.store.select(FilePickerStateSelectors.isUploading(this.filePicker.id))])
      .pipe(map(([count, uploading]) => count.count && !uploading));
  }
}

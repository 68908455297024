import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {BOOKMARKING_WIDGET} from '@widgets/bookmarking/bookmarking-widget-config';
import {BookmarkingComponent} from '@widgets/bookmarking/bookmarking-widget/bookmarking.component';
import {BookmarkingAddFormComponent} from './bookmarking-add-form/bookmarking-add-form.component';
import {BookmarkingEditFormComponent} from './bookmarking-edit-form/bookmarking-edit-form.component';
import {BookmarkingShowComponent} from './bookmarking-show/bookmarking-show.component';

@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    DragDropModule,
    ReactiveFormsModule
  ],
  declarations: [
    BookmarkingAddFormComponent,
    BookmarkingComponent,
    BookmarkingEditFormComponent,
    BookmarkingShowComponent
  ],
  entryComponents: [
    BookmarkingComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: BOOKMARKING_WIDGET, multi: true}
  ]
})

export class BookmarkingWidgetModule {
}

<button type="button"  class="btn video-preview-delete" *ngIf="editMode" (click)="previewDeleted.emit(preview)">
  <i class="zmdi zmdi-close" aria-hidden="true"></i>
  <span class="sr-only" translate="PREVIEW.VIDEO.DELETE.ARIA"></span>
</button>
<div class="iframe-wrapper" [ngStyle]="{'padding-top': getHeightPercentage(preview)}">
  <div class="video-wrapper">
    <iframe frameborder="0" [src]="getVideoUrl(preview) | safeUrl"
            allowfullscreen mozallowfullscreen webkitallowfullscreen></iframe>
  </div>
</div>

<div [formGroup]="parentForm">
  <label [translate]="'APP.FILE_LIBRARY.SETTINGS.EDITORS.AUTHORS.LABEL'"
         class="mat-label" for="showAuthors">
  </label>
  <mat-checkbox formControlName="showAuthors"
                id="showAuthors">{{'APP.FILE_LIBRARY.SETTINGS.EDITORS.AUTHORS.DESCRIPTION' | translate}}
  </mat-checkbox>
  <label [translate]="'APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.LABEL'"
         class="mat-label" for="notification">
  </label>
  <mat-radio-group aria-labelledby="radio" formControlName="notification" id="notification">
    <mat-radio-button [attr.aria-label]="'APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.ALL' | translate"
                      [value]='notificationAll'
                      class="radio">{{"APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.ALL" | translate}}
    </mat-radio-button>
    <mat-radio-button [attr.aria-label]="'APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.ADMIN' | translate"
                      [value]="notificationAdmin"
                      class="radio">{{"APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.ADMIN" | translate}}
    </mat-radio-button>
    <mat-radio-button [attr.aria-label]="'APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.NONE' | translate"
                      [value]="notificationNone"
                      class="radio">{{"APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.NONE" | translate}}
    </mat-radio-button>
  </mat-radio-group>
  <label [translate]="'APP.FILE_LIBRARY.SETTINGS.EDITORS.LABEL'"
         class="mat-label" for="modifyRole">
  </label>
  <mat-radio-group formControlName="modifyRole" id="modifyRole">
    <mat-radio-button [attr.aria-label]="'APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.ALL' | translate"
                      [value]="editorsAll"
                      class="radio">{{"APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.ALL" | translate}}
      <mat-hint translate="APP.FILE_LIBRARY.SETTINGS.EDITORS.EVERYONE.DESCRIPTION"></mat-hint>
    </mat-radio-button>
    <mat-radio-button [attr.aria-label]="'APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.ADMIN' | translate"
                      [value]="editorsAdmin"
                      class="radio">{{"APP.FILE_LIBRARY.SETTINGS.NOTIFICATIONS.ADMIN" | translate}}
      <mat-hint translate="APP.FILE_LIBRARY.SETTINGS.EDITORS.ADMINS.DESCRIPTION"></mat-hint>
    </mat-radio-button>
  </mat-radio-group>
</div>

import {NgZone} from '@angular/core';
import {Observable, Observer} from 'rxjs';

/**
 * Reattaches the given observable to the ngZone, thus making it visible to Angular's
 * change detection mechanism. This is usually required when using AngularJS promises
 * and converting them to Angular observables.
 *
 * @param zone the current ngZone
 * @param observable the observable to attach
 * @return the reattached observable
 */
export function attachToZone<T>(zone: NgZone, observable: Observable<T>): Observable<T> {
  return new Observable((observer: Observer<T>) => {
    const onNext = (value: T) => zone.run(() => observer.next(value));
    const onError = (error: any) => zone.run(() => observer.error(error));
    const onComplete = () => zone.run(() => observer.complete());
    return observable.subscribe(onNext, onError, onComplete);
  });
}

import {Injectable} from '@angular/core';

/**
 * A service managing time and timezone related tasks.
 */
@Injectable({
  providedIn: 'root'
})
export class TimeService {
  private timezone: string = 'UTC';

  constructor() {
  }

  /**
   * Gets the user's configured timezone.
   *
   * @return the timezone
   */
  getTimezone(): string {
    return this.timezone;
  }

  /**
   * Sets the user's configured timezone.
   *
   * @param timezone the timezone
   */
  setTimezone(timezone: string): void {
    this.timezone = timezone;
  }

  /**
   * Gets the current time in milliseconds
   *
   * @return the milliseconds as returned by {@link Date#now}
   */
  getCurrentTimeMillis(): number {
    return Date.now();
  }
}

(function (angular) {
  'use strict';

  /*
   * Angular translate does not provide an adequate and safe way to escape / sanitize user input. All built-in
   * strategies for escaping will either allow XSS attacks or are not working correctly:
   *   - "sanitize": Escapes ampersands to &amp;
   *   - "escape":  Escapes ampersands to &amp;
   *   - "sanitizeParameters": XSS possible
   *   - "escapeParameters": XSS possible
   *   - "sce": XSS possible
   *   - "sceParameters": XSS possible
   *
   * @see https://angular-translate.github.io/docs/#/guide/19_security
   * @see https://github.com/angular-translate/angular-translate/issues/1101
   *
   * We thus provide a decorated version of the translate filter to fix double-escaped ampersands.
   */
  angular
      .module('coyo.app')
      .config(["$provide", function ($provide) {
        $provide.decorator('$translate', ["$delegate", function ($delegate) {
          return angular.extend(function () {
            return $delegate.apply(this, arguments).then(_convert);
          }, $delegate, {
            instant: function () {
              return _convert($delegate.instant.apply(this, arguments));
            }
          });
        }]);
      }]);

  function _resolve(result) {
    if (result) {
      // eslint-disable-next-line angular/document-service
      var elem = document.createElement('div');
      elem.innerHTML = result;
      return elem.textContent;
    }
    return result;
  }

  function _convert(result) {
    return _.isObject(result) ? _.mapValues(result, _resolve) : _resolve(result);
  }

})(angular);

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ContainsPipe} from '@shared/contains/contains.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ContainsPipe
  ],
  exports: [
    ContainsPipe
  ]
})
export class ContainsModule {
}

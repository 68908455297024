(function (angular) {
  'use strict';

  SenderNavigationMobileController.$inject = ["$injector", "appRegistry", "senderNavigationModalService", "SenderModel", "appService"];
  angular
      .module('commons.ui')
      .directive('coyoSenderNavigationMobile', senderNavigationMobile)
      .controller('SenderNavigationMobileController', SenderNavigationMobileController);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoSenderNavigationMobile:coyoSenderNavigationMobile
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Renders the mobile navigation for senders showing the senders' apps and options. If more than three apps are
   * available those apps are shown within a modal menu. The modal menu shows options for adding and editing
   * apps.
   *
   * @param {object} sender
   * The sender to display the navigation for.
   *
   * @param {object[]} apps
   * The apps of the given sender.
   *
   * @param {object} currentApp
   * The currently selected app. This is important to display the correct button for "Edit App".
   *
   * @requires $transclude
   * @requires appRegistry
   * @requires appService
   * @requires senderNavigationModalService
   * @requires SenderModel
   *
   */
  function senderNavigationMobile() {
    return {
      restrict: 'E',
      templateUrl: 'app/commons/ui/components/sender-navigation/sender-navigation-mobile.html',
      scope: {},
      bindToController: {
        apps: '=',
        sender: '=',
        currentApp: '<'
      },
      controller: 'SenderNavigationMobileController',
      controllerAs: '$ctrl'
    };
  }

  function SenderNavigationMobileController($injector, appRegistry, senderNavigationModalService, SenderModel,
                                            appService) {
    var vm = this;
    vm.getIcon = appRegistry.getIcon;
    vm.appLength = vm.apps.length;
    vm.apps = _getSortedApps();
    vm.senderModel = new SenderModel({id: vm.sender.id});

    vm.isCurrentlySelectedApp = isCurrentlySelectedApp;
    vm.openNavigationAndOptions = openNavigationAndOptions;

    function isCurrentlySelectedApp(app) {
      return appService.isCurrentApp(app);
    }

    function _getCurrentApp(appIdOrSlug) {
      return _.find(vm.apps, function (app) {
        return appIdOrSlug === app.slug || appIdOrSlug === app.id;
      });
    }

    function openNavigationAndOptions(currentApp) {
      senderNavigationModalService.open(vm.sender, vm.apps, currentApp).then(function () {
        vm.senderModel.get(null, {_permissions: ['*']}).then(function (data) {
          _.assign(vm.sender, data);
        }).then(function () {
          vm.senderModel.getApps().then(function (data) {
            vm.apps = data;
            vm.apps = _getSortedApps();
            vm.currentApp = _getCurrentApp(appService.getCurrentAppIdOrSlug());
          });
        });
      });
    }

    function _getSortedApps() {
      return _.chain(vm.sender.appNavigation)
          .flatMap(function (o) {
            return o.apps ? o.apps : [];
          })
          .map(function (appId) {
            return _.find(vm.apps, {id: appId});
          })
          .filter(angular.isDefined)
          .value();
    }
  }

})(angular);

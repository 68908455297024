import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {CompleteProfileWidget} from '@widgets/complete-profile/complete-profile-widget';
import {CompleteProfileWidgetComponent} from '@widgets/complete-profile/complete-profile-widget/complete-profile-widget.component';

/**
 * The configuration for the complete profile widget.
 */
export const COMPLETE_PROFILE_WIDGET: WidgetConfig<CompleteProfileWidget> = {
  key: 'completeprofile',
  name: 'WIDGET.COMPLETE_PROFILE.NAME',
  description: 'WIDGET.COMPLETE_PROFILE.DESCRIPTION',
  icon: 'zmdi-check-all',
  component: CompleteProfileWidgetComponent,
  categories: WidgetCategory.PERSONAL,
  defaultTitle: 'WIDGET.COMPLETE_PROFILE.TITLE',
  whitelistExternal: false
};

import {License} from '@shared/license/license';

export class LicenseValidityFetched {
  static readonly type: string = '[License API] LicenseValidityFetched';
  constructor(public validity: boolean) {}
}

export class LicenseValidityUpdated {
  static readonly type: string = '[License API] LicenseValidityUpdated';
  constructor(public validity: boolean) {}
}

export class LicenseFetched {
  static readonly type: string = '[License API] LicenseFetched';
  constructor(public license: License) {}
}

export class LicenseUpdated {
  static readonly type: string = '[License API] LicenseUpdated';
  constructor(public license: License) {}
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Locale} from '@shared/multilanguage/locale';

export enum FlagType {
  ROUNDED = 'rounded',
  SQUARED = 'squared',
}

/**
 * Renders a flag for the given language.
 */
@Component({
  selector: 'coyo-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryFlagComponent {

  /**
   * The locale for the flag to display
   */
  @Input() locale: Locale;

  constructor() {
  }

  get countryClass(): string {
    return `flag-icon-${this.locale.country.toLowerCase()}`;
  }
}

(function (angular) {
  'use strict';

  FieldModel.$inject = ["appResourceFactory", "$translate"];
  angular
      .module('coyo.apps.commons.fields')
      .factory('FieldModel', FieldModel);

  /**
   * @ngdoc service
   * @name coyo.apps.commons.fields.FieldModel
   *
   * @description
   * A model representing a field entity.
   *
   * @requires coyo.apps.api.appResourceFactory
   * @requires $translate
   */
  function FieldModel(appResourceFactory, $translate) {
    var Field = appResourceFactory({
      url: '/{{appKey}}/fields/{{id}}',
      name: 'listField'
    });

    // class members
    angular.extend(Field, {

      /**
       * @ngdoc method
       * @name coyo.apps.commons.fields.FieldModel#fromConfig
       * @methodOf coyo.apps.commons.fields.FieldModel
       *
       * @description
       * Creates and returns a new entity based on it's configuration
       *
       * @param {object} config
       * The configuration (field type) of a list field, which is used to create a new one.
       *
       * @returns {object} a newly created list field entity (which is not yet persisted).
       */
      fromConfig: function (config) {
        return new Field({
          key: config.key,
          name: $translate.instant(config.title),
          settings: {}
        });
      },

      /**
       * @ngdoc method
       * @name coyo.apps.commons.fields.FieldModel#order
       * @methodOf coyo.apps.commons.fields.FieldModel
       *
       * @description
       * Orders a list of fields based on their (new) priority.
       *
       * @param {string} appId
       * The id of the app the fields are belonging to.
       *
       * @param {string} senderId
       * The id of the sender which is the parent of the app.
       *
       * @param {array} ids
       * The list of field ids representing the new order
       *
       * @returns {object} a promise which resolves to the list of fields in the new order.
       */
      order: function (appId, appKey, senderId, ids) {
        var context = {appId: appId, appKey: appKey, senderId: senderId};
        return Field.$put(Field.$url(context, 'action/order'), ids);
      }
    });

    return Field;
  }

})(angular);

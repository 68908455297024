import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Category} from '@app/filter/category-filter/category';
import {CategoryOrderChangeEvent} from '@app/filter/category-filter/category-order-change-event';
import {DeleteConfirmationService} from '@shared/dialog/delete-confirmation/delete-confirmation.service';
import * as _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';

/**
 * Category filter component.
 *
 * @description
 * Renders a category filter and provides support to add, edit, reorder and remove categories.
 * This component only emits the changes.
 * The resulting tasks (e.g.: fetching the filter result, saving a new category) must be performed by the caller.
 *
 * @example
 * <coyo-category-filter
 *  title="..."
 *  [categories]="..."
 *  [canManage]="..."
 *  [totalCount]="..."
 *  [notAvailableCount]="..."
 *  [saveCallback]="..."
 *  [changeCallback]="..."
 *  [deleteCallback]="..."
 *  [selectedCategories]="..."
 *  (selectedCategoriesChange)="..."
 * >
 *   <coyo-filter>
 *     <coyo-filter-entry>...</coyo-filter-entry>
 *     <coyo-filter-entry>...</coyo-filter-entry>
 *   </coyo-filter>
 * </coyo-category-filter>
 *
 * @see FilterComponent, FilterEntryComponent
 */
@Component({
  selector: 'coyo-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryFilterComponent implements OnInit, OnDestroy {

  /**
   * The category title.
   */
  @Input()
  title: string;

  /**
   * The available categories.
   */
  @Input()
  categories: Category[] = [];

  /**
   * Reflects categories changes.
   */
  @Output()
  categoriesChange: EventEmitter<Category[]> = new EventEmitter<Category[]>();

  /**
   * Selected categories as a list of id's.
   */
  @Input()
  selectedCategories: string[] = [];

  /**
   * The changed selected categories list.
   */
  @Output()
  selectedCategoriesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  /**
   * Count to be shown on the 'All' option.
   */
  @Input()
  totalCount: string | number = 0;

  /**
   * Count to be shown on the 'N/A' option.
   */
  @Input()
  notAvailableCount: string | number = 0;

  /**
   * The permission to manage the category filter entries.
   */
  @Input()
  canManage: boolean;

  /**
   * The category icon.
   */
  @Input()
  categoryIcon?: string = 'page';

  /**
   *  Given callback function will be called on a save action.
   */
  @Input()
  saveCallback: (categoryEvent: Category) => Promise<Category>;

  /**
   * Given callback function will be called on a change order action.
   */
  @Input()
  changeCallback: (categoryEvent: CategoryOrderChangeEvent) => Promise<Category[]>;

  /**
   * Given callback function will be called on a delete action.
   */
  @Input()
  deleteCallback: (categoryEvent: Category) => Promise<Category>;

  @ViewChild('categoryInput', {static: false}) set inputInHtml(input: ElementRef) {
    if (input) {
      this.categoryInput = input;
    }
  }

  categoryInput: ElementRef;
  dragging: boolean = false;
  editedCategory: Category;
  editingCategory$: Observable<number>;
  hovering: boolean[] = [];
  noCategory: Category = {id: 'N/A'} as Category;

  private editingCategory: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  constructor(private deleteConfirmationService: DeleteConfirmationService) { }

  ngOnInit(): void {
    this.categories.forEach(() => {
      this.hovering.push(false);
    });
    this.editingCategory$ = this.editingCategory.asObservable();
  }

  ngOnDestroy(): void {
    this.editingCategory.complete();
  }

  @HostListener('document:keydown.escape') onEsc(): void {
    const idx = this.editingCategory.getValue();
    if (this.editingCategory && idx >= 0) {
      this.endEditing();
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onEnter($event: KeyboardEvent): void {
    const idx = this.editingCategory.getValue();
    if (this.editedCategory && idx >= 0) {
      this.saveCategory(
        this.categories[idx],
        $event
      );
    }
  }

  @HostListener('document:click', ['$event']) onOutsideClick($event: MouseEvent): void {
    const idx = this.editingCategory.getValue();
    if (this.categoryInput && this.categoryInput.nativeElement !== $event.target && idx >= 0) {
      this.endEditing();
    }
  }

  /**
   * Is called after a category is dropped in the filter list.
   *
   * @param event The drag & drop event
   */
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    const categoryEvent: CategoryOrderChangeEvent = {
      categoryOrder: this.categories.map((category: Category) => category.id),
      currentIndex: event.currentIndex,
      previousIndex: event.previousIndex
    };
    fromPromise(this.changeCallback(categoryEvent)).subscribe({
      next: () => this.emitCategoriesChange(),
      error: () => moveItemInArray(this.categories, event.currentIndex, event.previousIndex)
    });
  }

  /**
   * Adds a new category.
   *
   * @param event The input event
   */
  addCategory(event: Event): void {
    this.stopPropagation(event);
    const newCategory = {
      name: ''
    };
    this.categories.push(newCategory);
    this.startEditing(newCategory, this.categories.length);
  }

  /**
   * Enables edit mode for a category.
   *
   * @param category The edited category
   * @param index    The list index number
   * @param event    The input event
   */
  editCategory(category: Category, index: number, event: Event): void {
    this.stopPropagation(event);
    this.startEditing(category, index);
  }

  /**
   * Saves a category.
   *
   * @param category The category to be saved
   * @param event    The input event
   */
  saveCategory(category: Category, event: Event): void {
    this.stopPropagation(event);
    this.editedCategory.name = this.editedCategory.name.trim();
    fromPromise(this.saveCallback(this.editedCategory)).subscribe({
      next: savedCategory => {
        if (category && category.id) {
          const idx = this.categories.findIndex(c => c.id === category.id);
          this.categories[idx] = savedCategory;
        } else {
          this.categories.push(savedCategory);
        }
        this.endEditing();
        this.emitCategoriesChange();
      },
      error: () => {}
    });
  }

  /**
   * Deletes a category.
   *
   * @param category The category to be deleted
   * @param index    The list index number
   * @param event    The input event
   */
  deleteCategory(category: Category, index: number, event: Event): void {
    this.stopPropagation(event);
    this.startEditing(category, index);
    this.deleteConfirmationService.open(
      'FILTER.CATEGORY.DELETE.MODAL.TITLE',
      'FILTER.CATEGORY.DELETE.MODAL.TEXT',
      'YES',
      'CANCEL'
    ).subscribe(deleteCategory => {
      if (deleteCategory) {
        fromPromise(this.deleteCallback(category)).subscribe({
          next: () => {
            this.categories.splice(index, 1);
            this.endEditing();
            this.emitCategoriesChange();
          },
          error: () => {}
        });
      }
    });
  }

  /**
   * Adds or remove a category as filter parameter.
   *
   * @param category The category to add or remove
   */
  toggleCategory(category: Category): void {
    if (this.editingCategory.getValue() === -1) {
      if (category === this.noCategory) {
        this.selectedCategories = [this.noCategory.id];
      } else {
        if (this.selectedCategories.indexOf(this.noCategory.id) >= 0) {
          this.selectedCategories = [];
        }
        const idx = this.selectedCategories.indexOf(category.id);
        if (idx >= 0) {
          this.selectedCategories.splice(idx, 1);
        } else {
          this.selectedCategories.push(category.id);
        }
      }
      this.emitFilterModelChange();
    }
  }

  /**
   * Stop event propagation.
   *
   * @param event The input event
   */
  stopPropagation(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * Resets all category filter parameter.
   */
  resetCategorySelection(): void {
    this.selectedCategories = [];
    this.emitFilterModelChange();
  }

  private emitCategoriesChange(): void {
    this.categoriesChange.emit(this.categories);
  }

  private emitFilterModelChange(): void {
    this.selectedCategoriesChange.emit(this.selectedCategories);
  }

  private startEditing(category: Category, index: number): void {
    this.editedCategory = category;
    this.editingCategory.next(index);
    setTimeout(() => {
      if (this.categoryInput) {
        this.categoryInput.nativeElement.focus();
      }
    });
  }

  private endEditing(): void {
    this.editedCategory = null;
    _.remove(this.categories, (category: Category) => category && category.id === undefined);
    this.editingCategory.next(-1);
  }

}

<form [formGroup]="form" class="row">
  <div class="col-md-4">
    <label class="mat-form-field-label" (click)="timezoneSelect.focus()">{{'SETTINGS.PREFERENCES.TIMEZONE' | translate}}</label>
  </div>
  <div class="col-md-8">
    <mat-form-field class="mb-0">
      <ng-select class="mat-ng-select" formControlName="timezone" appearance="outline" appendTo="body"
                 [items]="timezones" bindLabel="key" bindValue="key"
                 [clearable]="false" [searchable]="false" (change)="submit()" #timezoneSelect>
        <ng-template ng-label-tmp let-item="item">
          {{('TIMEZONES.' + item.key) | replace : '/' : '.' | uppercase | translate}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
      <span class="pl-badges timezone-badges">
        {{('TIMEZONES.' + item.key) | replace : '/' : '.' | uppercase | translate}}
        <span class="badge badge-info">{{item.offset | timezoneOffset}}</span>
      </span>
        </ng-template>
      </ng-select>
      <mat-hint [innerHTML]="'SETTINGS.PREFERENCES.TIMEZONE.HINT' | translate | markdown"></mat-hint>
    </mat-form-field>
  </div>
</form>

import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {JitTranslationSettingsService} from '@app/admin/settings/jit-translation-settings/jit-translation-settings.service';
import {AppService} from '@domain/apps/app.service';
import {SettingsService} from '@domain/settings/settings.service';
import * as _ from 'lodash';
import {FileItem} from 'ng2-file-upload';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Provides a set of custom validators that can be used by form controls.
 */
export class CoyoValidators {

  /**
   * Validator that requires the control's value to not only contain whitespace.
   * The validator exists only as a function and not as a directive.
   *
   * @example
   * ```typescript
   * const control = new FormControl('   ', CoyoValidators.notBlank());
   *
   * console.log(control.errors); // {notBlank: true}
   * ```
   *
   * @param control the underlying form control
   * @returns An error map with the `notBlank` property
   * if the validation check fails, otherwise `null`.
   */
  static notBlank(control: AbstractControl): ValidationErrors | null {
    return _.isEmpty(control.value) || (_.isString(control.value) && _.isEmpty(control.value.trim())) ? {notBlank: true} : null;
  }

  /**
   * Validator that requires the control's value to be one of the provided options.
   * The validator exists only as a function and not as a directive.
   *
   * @example
   * ```typescript
   * const control = new FormControl(4, Validators.options(1, 3, 5));
   *
   * console.log(control.errors); // {options: {options: [1, 3, 5], actual: 4}}
   * ```
   *
   * @param options the list of valid options
   * @returns A validator function that returns an error map with the
   * `options` if the validation check fails, otherwise `null`.
   */
  static options<T>(...options: T[]): ValidatorFn {
    return control => !_.includes(options, control.value) ? {options: {options: options, actual: control.value}} : null;
  }

  /**
   * Validator that requires a field to be not blank when an other field has a true value.
   *
   * @example
   * ```typescript
   * const control = new FormGroup({
   *    checkbox: [true], requiredControl: ['']
   * }, {
   *    validators: [requiredIfTrue('checkbox', 'requiredControl', control => control.value)]
   * }));
   *
   * console.log(control.errors); // {requiredControl:{notBlank: true}}
   * ```
   *
   * @param requiredTrueControl The name of the checkbox like form control which must have the true value
   * @param requiredControl The name of the control which is required if the other control is truthy
   * @param trueProvider A function which should be considered true x
   *
   * @returns A Validator function returning an error map with the 'notBlank' property if the validator check failed
   */
  static requiredIfTrue(requiredTrueControl: string, requiredControl: string, trueProvider: (requiredControl: AbstractControl) => boolean): ValidatorFn {
    return control => {
      const result = trueProvider(control.get(requiredTrueControl)) && this.notBlank(control.get(requiredControl));
      const errorMap = {};
      errorMap[requiredControl] = result;
      return errorMap[requiredControl] ? errorMap : null;
    };
  }

  static url(control: AbstractControl): ValidationErrors | null {
    // look for a better RegEx
    const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)*[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/;
    return control.value ? Validators.pattern(reg)(control) : null;
  }

  /**
   * Validator that requires one of the provided control values to be non empty.
   *
   * @example
   * ```typescript
   * const form = this.formBuilder.group({
   *   attachments: [],
   *   message: ['']
   *  }, {validator: CoyoValidators.anyNotBlank('attachments', 'message')}
   * );
   *
   * console.log(form.errors); // {anyNotBlank: true}
   * ```
   *
   * @param controlNames a list of control paths to be checked for blankness
   * @returns A validator function that returns an error map with the
   * `anyNotBlank` property set if the validation check fails, otherwise `null`.
   */
  static anyNotBlank(...controlNames: string[]): ValidatorFn {
    return control => {
      const controls = _(controlNames).map(path => control.get(path)).filter(value => !!value).value();
      return _.some(controls, elem => !this.notBlank(elem)) ? null : {anyNotBlank: true};
    };
  }

  /**
   * Validator that requires none of the control's values is in uploading state.
   *
   * Note:
   * If the attachment is an instance of FileItem, it means that the item is being uploaded.
   * After uploading, a new object type will be applied.
   *
   * @example
   * ```typescript
   * const control = new FormControl([{name: 'test-file', isUploading: true}], CoyoValidators.notUploading);
   *
   * console.log(control.errors); // {uploading: true}
   * ```
   *
   * @param control the underlying form control
   *
   * @returns null if no value is in uploading state else an error map with uploading flag
   */
  static notUploading(control: AbstractControl): ValidationErrors | null {
    return _.some(control.value, attachment => attachment instanceof FileItem) ? {uploading: true} : null;
  }

  /**
   * Creates an asynchronous validator to check if the given api key is valid to access the configured translation
   * provider.
   * @param settingsService The service
   *
   * @return an async validator function
   */
  static createApiKeyValidator(settingsService: JitTranslationSettingsService): AsyncValidatorFn {
    return control => {
      if (control.pristine) {
        return of(null);
      } else if (!control.parent) {
        return of({invalidKey: true});
      } else if (!control.parent.get('activeProvider').value) {
        return of(null);
      }
      return settingsService
        .validateApiKey({
          activeProvider: control.parent.get('activeProvider').value,
          apiKey: control.value,
          region: control.parent.get('region').value
        })
        .pipe(map(valid => valid ? null : {invalidKey: true}));
    };
  }

  /**
   * Validator that requires the control values to be a natural number (incl. 0).
   *
   * @example
   * ```typescript
   * const control = new FormControl(0.32, CoyoValidators.naturalNumber);
   *
   * console.log(control.errors); // {naturalNumber: true}
   * ```
   *
   * @param control the underlying form control
   * @returns An error map with the `naturalNumber` property
   * if the validation check fails, otherwise `null`.
   */
  static naturalNumber(control: AbstractControl): ValidationErrors | null {
    return control.value && !/^\d+$/.test(control.value.toString())
      ? {naturalNumber: true}
      : null;
  }

  /**
   * Validator that requires all of the provided control values to be equal.
   *
   * @example
   * ```typescript
   * const form = this.formBuilder.group({
   *   password: ['password'],
   *   passwordConfirm: ['otherPassword']
   *  }, {validator: CoyoValidators.allEqual('password', 'passwordConfirm')}
   * );
   *
   * console.log(form.errors); // {allEqual: true}
   * ```
   *
   * @param controlNames a list of control paths to be checked for equality
   * @returns A validator function that returns an error map with the
   * `allEqual` property set if the validation check fails, otherwise `null`.
   */
  static allEqual(...controlNames: string[]): ValidatorFn {
    return control => {
      const controls = _(controlNames).map(path => control.get(path).value).uniq().value();
      return controls.length === 1 ? null : {allEqual: true};
    };
  }

  /**
   * Validator that requires all of the provided control values to be not equal.
   *
   * @example
   * ```typescript
   * const form = this.formBuilder.group({
   *   oldPassword: ['password'],
   *   newPassword: ['password']
   *  }, {validator: CoyoValidators.notEqual('oldPassword', 'newPassword')}
   * );
   *
   * console.log(form.errors); // {notEqual: true}
   * ```
   *
   * @param controlNames a list of control paths to be checked for equality
   * @returns A validator function that returns an error map with the
   * `notEqual` property set if the validation check fails, otherwise `null`.
   */
  static notEqual(...controlNames: string[]): ValidatorFn {
    return control => {
      const controls = _(controlNames).map(path => control.get(path).value).uniq().value();
      return controls.length === controlNames.length ? null : {notEqual: true};
    };
  }

  /**
   * Async validator that checks if the slug defined in a form control is already in use.
   *
   * @example
   * ```typescript
   * const control = new FormControl('slug', null,
   *                                 CoyoValidators.checkSlug('senderId', 'appId', appService));
   *
   * console.log(form.errors); // {slugTaken: true}
   * ```
   *
   * @param senderId The id of the sender parent of the app
   * @param appId The id of the app
   * @param appService The app service
   *
   * @returns An async validator function that returns an error map with the
   * `slugTaken` property set if the validation check fails, the `slugInvalid` property set if the slug is invalid
   * otherwise `null`.
   */
  static checkSlug(senderId: string, appId: string, appService: AppService): AsyncValidatorFn {
    return control => {
      if (!control.value || control.pristine) {
        return of(null);
      }
      return appService.isSlugTaken(senderId, appId, control.value).pipe(map(validation => {
        if (validation.taken) {
          return {slugTaken: true};
        } else if (!validation.valid) {
          return {slugInvalid: true};
        } else {
          return null;
        }
      }));
    };
  }

  /**
   * Async validator that wraps the default pattern validator and requires the
   * control's value to match a regex pattern retrieved via the settings service.
   *
   * @example
   * ```typescript
   * const control = new FormControl('password', null,
   *                                 CoyoValidators.pattern(settingsService, 'passwordPattern'));
   *
   * console.log(control.errors); // {passwordPattern: {requiredPattern: '^[a-zA-Z]*$', actualValue: '1'}}
   * ```
   *
   * @param settingsService the settingsService instance
   * @param key a valid settings key
   *
   * @returns An async validator function that returns an error map with the
   * given key as property if the validation check fails, otherwise `null`.
   */
  static pattern(settingsService: SettingsService, key: 'emailPattern' | 'linkPattern' | 'passwordPattern'): AsyncValidatorFn {
    return control => settingsService.retrieveByKey(key)
      .pipe(map(pattern => {
        const errors = Validators.pattern(pattern)(control);
        return errors ? CoyoValidators.copy(errors, 'pattern', key) : errors;
      }));
  }

  private static copy(data: { [key: string]: any; }, oldKey: string, newKey: string): { [key: string]: any; } {
    const result = {};
    result[newKey] = data[oldKey];
    return result;
  }
}

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {DividerWidget} from '@widgets/divider/divider-widget';

/**
 * The divider widget's settings component
 */
@Component({
  selector: 'coyo-divider-widget-settings',
  templateUrl: './divider-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DividerWidgetSettingsComponent extends WidgetSettingsComponent<DividerWidget> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.parentForm.addControl('text', new FormControl(this.widget.settings.text, Validators.maxLength(80)));
  }
}

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SettingsService} from '@domain/settings/settings.service';
import {UserService} from '@domain/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * A form element to change the current user's current password.
 */
@Component({
  selector: 'coyo-settings-password',
  templateUrl: './settings-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsPasswordComponent implements OnInit {
  showPassword: boolean = false;
  showPasswordNew: boolean = false;
  showPasswordConfirm: boolean = false;

  form: FormGroup;
  request$: Observable<void>;

  constructor(private formBuilder: FormBuilder,
              private settingsService: SettingsService,
              private userService: UserService,
              private translateService: TranslateService,
              private notificationService: NotificationService) {
  }

  get hasAnyValue(): boolean {
    return !!this.form.controls.password.value
      || !!this.form.controls.passwordNew.value
      || !!this.form.controls.passwordConfirm.value;
  }

  get hasAllValues(): boolean {
    return !!this.form.controls.password.value
      && !!this.form.controls.passwordNew.value
      && !!this.form.controls.passwordConfirm.value;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: [''],
      passwordNew: ['', [], [CoyoValidators.pattern(this.settingsService, 'passwordPattern')]],
      passwordConfirm: ['']
    }, {validators: CoyoValidators.allEqual('passwordNew', 'passwordConfirm')});

    // sync cross field validation to specific field
    this.form.statusChanges.subscribe(() => !this.form.controls.passwordConfirm.pristine
      ? this.form.controls.passwordConfirm.setErrors(this.form.errors, {emitEvent: false})
      : null);
  }

  /**
   * Focuses the given input.
   *
   * @param event the initial keyboard event
   * @param input the input to focus
   */
  next(event: KeyboardEvent, input: HTMLInputElement): void {
    event.preventDefault();
    input.focus();
  }

  /**
   * Submits the form.
   */
  submit(): void {
    if (this.form.invalid || !this.hasAllValues) {
      return;
    }

    const oldPassword = this.form.get('password').value;
    const newPassword = this.form.get('passwordNew').value;
    this.request$ = this.userService.setPassword(oldPassword, newPassword)
      .pipe(tap(() => this.notificationService.success(this.translateService.instant('SETTINGS.PASSWORD.SUCCESS'))))
      .pipe(tap(() => this.reset()));
  }

  /**
   * Resets the form.
   */
  reset(): void {
    this.showPassword = false;
    this.showPasswordNew = false;
    this.showPasswordConfirm = false;
    this.form.reset({
      password: '',
      passwordNew: '',
      passwordConfirm: ''
    });
  }
}

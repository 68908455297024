import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ReportListState} from '@app/reports/report-list/report-list-state';
import {Pageable} from '@domain/pagination/pageable';
import {Report} from '@domain/reports/report';
import {ReportsService} from '@domain/reports/reports.service';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

/**
 * Displays a list of reported content
 */
@Component({
  selector: 'coyo-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportListComponent implements OnInit {

  state$: BehaviorSubject<ReportListState> = new BehaviorSubject({
    isLoading: true,
    reports: [],
    count: 0,
    total: 0
  });

  constructor(private reportService: ReportsService) {
  }

  ngOnInit(): void {
    this.reportService.getPage(new Pageable(0, 20)).subscribe(page => this.state$.next({
      isLoading: false,
      reports: page.content,
      count: page.numberOfElements,
      total: page.totalElements
    }));
  }

  /**
   * Load more reports.
   */
  loadMore(): void {
    let state = this.state$.getValue();
    this.reportService.getPage(new Pageable(0, 20, state.count)).subscribe(page => {
      state = this.state$.getValue();
      this.state$.next({
        ...state, ...{
          isLoading: false,
          reports: [...state.reports, ...page.content],
          count: state.count + page.numberOfElements,
          total: page.totalElements,
        }
      });
    }, () => this.setLoading(false));
  }

  private setLoading(isLoading: boolean): void {
    const state = this.state$.getValue();
    this.state$.next({...state, ...{isLoading}});
  }

  /**
   * Resolve a report.
   * @param report The report
   */
  resolve(report: Report): void {
    this.setLoading(true);
    this.reportService.resolve(report).subscribe(() => {
      const reports = this.removeInArray(this.state$.getValue().reports, report.id);
      const oldState = this.state$.getValue();
      this.state$.next({...oldState, reports, count: --oldState.count, total: --oldState.total});
      this.setLoading(false);
    }, () => this.setLoading(false));
  }

  /*
   * Removes the given element from the array and returns a new array
   * or `null` if the array has not changed.
   */
  private removeInArray(reports: Report[], id: string): Report[] | null {
    const index = _.findIndex(reports, {id});
    if (index !== -1) {
      const result = [...reports];
      result.splice(index, 1);
      return result;
    }
    return null;
  }
}

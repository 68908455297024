import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserService} from '@domain/user/user.service';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UserOnlineWidget} from '@widgets/user-online/user-online-widget';
import {Observable} from 'rxjs';

/**
 * This widget displays trending users-online.
 */
@Component({
  selector: 'coyo-user-online-widget',
  templateUrl: './user-online-widget.component.html',
  styleUrls: ['./user-online-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserOnlineWidgetComponent extends WidgetComponent<UserOnlineWidget> implements OnInit {

  count$: Observable<number>;

  constructor(private userService: UserService, cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.count$ = this.userService.getUserOnlineCount();
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JitTranslationActiveSettings} from '@app/admin/settings/jit-translation-settings/jit-translation-active-settings';

import {JitTranslationSettingsService} from '@app/admin/settings/jit-translation-settings/jit-translation-settings.service';
import {AuthService} from '@core/auth/auth.service';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {User} from '@domain/user/user';
import {JitLanguageResponse} from '@shared/jit-translation/jit-translation/jit-language-response';
import {combineLatest, Observable, of, throwError, timer} from 'rxjs';
import {catchError, defaultIfEmpty, filter, first, map, mergeMap, shareReplay, switchMap} from 'rxjs/operators';
import {JitTranslationService} from '../jit-translation/jit-translation.service';

interface ToggleState {
  userLanguage?: string;
  showButton: boolean;
  id?: string;
  lang?: JitLanguageResponse;
  showIcon?: boolean;
}

/**
 * A button to show JIT translations.
 */
@Component({
  selector: 'coyo-jit-translation-toggle',
  templateUrl: './jit-translation-toggle.component.html',
  styleUrls: ['./jit-translation-toggle.component.scss']
})
export class JitTranslationToggleComponent implements OnInit {

  private static readonly DEFAULT_STATE: ToggleState = {
    showButton: false
  };

  /**
   * The ID of the target entity.
   */
  @Input() id: string;

  /**
   * The field of the target entity.
   */
  @Input() field: string;

  /**
   * The text to be translated
   */
  @Input() originalText: string;

  /**
   * The type of the entity to be translated
   */
  @Input() typeName: string;

  /**
   * Flag for showing the icon on mobile screens
   */
  @Input() showIconOnMobile: boolean = true;

  /**
   * The event output providing the translated text or `null` if the output should be hidden.
   */
  @Output() translated: EventEmitter<string | null> = new EventEmitter();

  showTranslation: boolean = false;

  isLoading: boolean = false;

  state$: Observable<ToggleState>;

  private translation: string;

  constructor(private authService: AuthService,
              private translationService: JitTranslationService,
              private jitSettingsService: JitTranslationSettingsService,
              private windowSizeService: WindowSizeService) {
  }

  ngOnInit(): void {
    this.state$ = this.jitSettingsService.getActiveSettings().pipe(
      filter(settings => !!settings.activeLanguages.length),
      switchMap(settings =>
        combineLatest([
            this.getLanguage(1),
            this.authService.getUser$(),
            of(settings),
            this.windowSizeService
              .observeScreenChange$()
              .pipe(map(screenSize => screenSize !== ScreenSize.XS || this.showIconOnMobile))
          ]
        )
      ),
      map(([lang, user, settings, showIcon]) =>
        ({
          userLanguage: user.language,
          showButton: user.language !== lang.language &&
            this.isActive(user, settings, lang.language, lang.disabledLanguages),
          id: this.id,
          lang,
          showIcon
        })
      ),
      defaultIfEmpty(JitTranslationToggleComponent.DEFAULT_STATE),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  /**
   * Translates the given source text and emits the translation via the event output
   * or `null` if the output should be hidden.
   */
  toggle(): void {
    if (this.isLoading) {
      return;
    }

    if (this.showTranslation) {
      this.translated.emit(null);
      this.showTranslation = false;
    } else if (this.translation) {
      this.translated.emit(this.translation);
      this.showTranslation = true;
    } else {
      this.isLoading = true;
      this.state$
        .pipe(first())
        .pipe(switchMap(state =>
          this.translationService.getTranslation(this.id, this.field, state.userLanguage, this.originalText)))
        .subscribe(translation => {
          this.translation = translation;
          this.translated.emit(translation);
          this.showTranslation = true;
          this.isLoading = false;
        });
    }
  }

  private isActive(user: User, settings: JitTranslationActiveSettings, targetLang: string,
                   disabledLanguages: string[]): boolean {
    const translationDisabled = disabledLanguages && disabledLanguages.indexOf(user.language.toUpperCase()) > -1;
    return settings.activeLanguages.length && settings.activeLanguages.indexOf(user.language.toUpperCase()) > -1 &&
      settings.activeLanguages.indexOf(targetLang) > -1 && !translationDisabled;
  }

  private getLanguage(retry: number): Observable<JitLanguageResponse> {
    return this.translationService.getLanguage(this.id, this.field, this.typeName).pipe(catchError(error => {
      if (error === 'NOT_PROCESSED' && retry < 4) {
        return timer(1000 * retry * 10).pipe(mergeMap(() => this.getLanguage(retry + 1)));
      } else {
        return throwError(error);
      }
    }));
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Comment} from '@domain/comment/comment';
import {CommentService} from '@domain/comment/comment.service';
import {Sender} from '@domain/sender/sender';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

/**
 * Displays a single comment in a list of comments.
 */
@Component({
  selector: 'coyo-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent implements OnInit {

  /**
   * The author of likes and other responses to the comment.
   */
  @Input() author: Sender;

  /**
   * The comment.
   */
  @Input() comment: Comment;

  /**
   * If the comment is editable based on non user permission criteria.
   * This could be true for example if the comment is the last one in the list.
   */
  @Input() editable: boolean;

  /**
   * Flag for skipping the initial requests for that comment.
   */
  @Input() skipInitRequest: boolean;

  /**
   * Emits when the comment should be deleted.
   */
  @Output() delete: EventEmitter<Comment> = new EventEmitter<Comment>();

  /**
   * Emits when the comment should be edited.
   */
  @Output() edit: EventEmitter<Comment> = new EventEmitter<Comment>();

  /**
   * Emits when the comment is changed.
   */
  @Output() changed: EventEmitter<Comment> = new EventEmitter<Comment>();

  previewUrl: string = '/web/comments/{{groupId}}/attachments/{{id}}';

  showOriginalAuthor: boolean = false;

  originalAuthor$: Observable<Sender> = null;

  isXs$: Observable<boolean>;

  translation: string;

  constructor(private commentService: CommentService,
              private windowSizeService: WindowSizeService) {
  }

  ngOnInit(): void {
    this.isXs$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS));
  }

  /**
   * Toggles the display of the original author.
   *
   * @param showAuthor whether to display the original author or not.
   */
  showAuthor(showAuthor: boolean): void {
    this.showOriginalAuthor = showAuthor;
    if (showAuthor && !this.originalAuthor$) {
      this.originalAuthor$ = this.commentService.getOriginalAuthor(this.comment.id)
        .pipe(startWith(this.comment.author));
    }
  }

  /**
   * Emit edit event.
   */
  editComment(): void {
    this.edit.emit(this.comment);
  }

  /**
   * Emit delete event.
   */
  deleteComment(): void {
    this.delete.emit(this.comment);
  }
}

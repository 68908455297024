import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {LatestBlogArticle} from '@widgets/latest-blog-articles/latest-blog-article';
import {LatestBlogArticlesWidgetSettings} from '@widgets/latest-blog-articles/latest-blog-articles-widget-settings';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Blog article widget service
 * Fetches a list of latest blog articles
 */
export class LatestBlogArticlesService {
  static readonly DEFAULT_COUNT: number = 5;
  private readonly url: string = '/web/widgets/blog/latest';

  constructor(@Inject(HttpClient) private http: HttpClient) {
  }

  getLatestBlogArticles(settings: LatestBlogArticlesWidgetSettings): Observable<LatestBlogArticle[]> {
    const appIds = settings._app?.map(app => app.id);
    return this.http.post<LatestBlogArticle[]>(this.url,
      {
        appIds,
        count: settings._articleCount ?? LatestBlogArticlesService.DEFAULT_COUNT,
        onlySubscribed: settings._onlySubscribed,
        sourceSelection: settings._sourceSelection
      });
  }
}

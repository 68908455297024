(function () {
  'use strict';

  utilService.$inject = ["$q"];
  angular
      .module('coyo.base')
      .factory('utilService', utilService);

  /**
   * @ngdoc service
   * @name coyo.base.utilService
   *
   * @description
   *     Provides basic utility functions.
   */
  function utilService($q) {
    return {
      uuid: uuid,
      requireInternetConnection: requireInternetConnection
    };

    /**
     * @ngdoc method
     * @name coyo.base.utilService#uuid
     * @methodOf coyo.base.utilService
     *
     * @description
     *     Generates a new UUID.
     * @returns {string}
     *     A UUID string, e.g. *4f87322d-f337-996f-8a9b-1ad08b82853c*.
     */
    function uuid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }

      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    /**
     * @ngdoc method
     * @name coyo.base.utilService#requireInternetConnection
     * @methodOf coyo.base.utilService
     *
     * @description
     *     Checks whether the client is connected to the internet
     * @returns {Object}
     *     A promise resolving the client's internet connection state
     */
    function requireInternetConnection() {
      if (navigator.onLine === false) {
        return $q.reject();
      }
      return $q.resolve();
    }
  }
})();

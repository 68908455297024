<div class="code-widget">
  <div class="panel">
    <div class="panel-body" [ngClass]="{'code-widget-warning alert alert-warning': !hasContent()}">
      <div #htmlDiv>
      </div>
      <div *ngIf="hasContent() && !hasHtmlCode()">
        {{'WIDGET.CODE.NO_HTML' | translate}}
      </div>
      <div *ngIf="!hasContent()">
        {{'WIDGET.CODE.INVALID' | translate}}
      </div>
    </div>
  </div>
</div>

import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {AnalyticsService} from '@app/analytics/analytics.service';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {SocketConnectionMonitorService} from '@app/socket/socket-connection-monitor/socket-connection-monitor.service';
import {AuthService} from '@core/auth/auth.service';
import {JobAdService} from '@core/console/job-ad.service';
import {ThemeService} from '@core/theme/theme.service';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Ng1MobileEventsService} from '@root/typings';
import {PageTitleService} from '@shared/notifications/page-title/page-title.service';
import {UIRouter, UrlService} from '@uirouter/core';
import {UIRouterRx} from '@uirouter/rx';
import {NG1_MOBILE_EVENTS_SERVICE} from '@upgrade/upgrade.module';
import {NGXLogger} from 'ngx-logger';
import {NgxPermissionsService} from 'ngx-permissions';
import {Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';

/**
 * Component for bootstrapping the angular part of the application
 */
@Component({
  selector: 'coyo-bootstrap',
  template: ''
})
export class BootstrapComponent implements OnInit, OnDestroy {
  private userSubscription: Subscription = null;

  constructor(urlService: UrlService,
              router: UIRouter,
              pageTitleService: PageTitleService,
              analyticsService: AnalyticsService,
              jobAdService: JobAdService,
              private windowSizeService: WindowSizeService,
              private themeService: ThemeService,
              private authService: AuthService,
              private permissionsService: NgxPermissionsService,
              private googleApiService: GoogleApiService,
              private log: NGXLogger,
              private socketConnectionMonitorService: SocketConnectionMonitorService,
              @Inject(NG1_MOBILE_EVENTS_SERVICE) private mobileEventsService: Ng1MobileEventsService) {
    this.themeService.applyTheme();
    router.plugin(UIRouterRx);
    urlService.listen();
    urlService.sync();
    pageTitleService.init();
    analyticsService.init();
    jobAdService.init();
  }

  ngOnInit(): void {
    this.mobileEventsService.propagate('onContextLoaded');
    this.userSubscription = this.authService.isAuthenticated$().pipe(filter(authenticated => authenticated))
      .pipe(switchMap(() => this.authService.getUser$())).subscribe({
        next: user => {
          const globalPermissions = user.globalPermissions || [];
          this.permissionsService.flushPermissions();
          this.permissionsService.loadPermissions(globalPermissions);
          this.googleApiService.initGoogleApi();
        },
        error: err => {
          this.log.error('error while retrieving user to get permissions', err);
        }
      });
    this.socketConnectionMonitorService.init();
  }

  ngOnDestroy(): void {
    this.permissionsService.flushPermissions();
    this.userSubscription.unsubscribe();
  }

  /**
   * Listens for window resize and updates the window size service
   */
  @HostListener('window:resize')
  onResize(): void {
    this.windowSizeService.updateWindow();
  }
}

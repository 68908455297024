import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {MentionModule} from '@shared/mention/mention.module';
import {TextModule} from '@shared/text/text.module';
import {CODE_WIDGET} from '@widgets/code/code-widget-config';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {WIDGET_CONFIGS} from '../api/widget-config';
import {CodeWidgetSettingsComponent} from './code-widget-settings/code-widget-settings.component';
import {CodeWidgetComponent} from './code-widget/code-widget.component';

/**
 * Module providing the code widget.
 */
@NgModule({
  imports: [
    ButtonsModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    HelpModule,
    TextModule,
    MentionModule
  ],
  declarations: [
    CodeWidgetComponent,
    CodeWidgetSettingsComponent
  ],
  entryComponents: [
    CodeWidgetComponent,
    CodeWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: CODE_WIDGET, multi: true}
  ]
})
export class CodeWidgetModule {}

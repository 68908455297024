<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
<div class="about-coyo-header">
  <div class="about-coyo-brand"></div>
</div>
<div mat-dialog-content>
  <div class="with-love">
    <mat-icon class="heart" (click)="clicks.next()" [class.activated]="experimentalFeaturesActive$ | async" svgIcon="heart"></mat-icon>
    Made with love in Hamburg, Germany
  </div>
  <p class="version-container">{{'ABOUT_COYO.VERSION' | translate}}: <strong>{{versionString}}</strong></p>
</div>
<div mat-dialog-actions>
  <a [attr.href]="privacyPolicyLinkMessageKey | translate"
     target="_blank"
     mat-button
     mat-link-button
     size="large"
     color="accent"
  >
    {{'ABOUT_COYO.PRIVACY_POLICY' | translate}}
  </a>
  <a href="https://www.coyoapp.com"
     target="_blank"
     mat-button
     mat-link-button
     size="large"
     color="accent"
  >
    www.coyoapp.com
  </a>
</div>

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';

/**
 * Service to strip HTML from texts.
 */
@Injectable({
  providedIn: 'root'
})
export class StripHtmlService {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  /**
   * Strips all HTML content from the given text.
   *
   * Because relying on the property textContent does fail us, when dealing with lists (the whitespaces get lost
   * between words) we have to try to strip the html manually because there seems to be no other way to deal with it.
   * Plus unfortunately a whole lot other stuff, e.g. replace non-breakable whitespaces and double whitespaces.
   *
   * @param value the HTML-flavoured source
   *
   * @returns the text without HTML tags and chars
   */
  strip(value: string): string {
    if (!value) {
      return '';
    }
    const element = this.document.createElement('div');
    let newText = value.replace(/<p>/g, ' ');
    newText = newText.replace(/<\/p>/g, '');
    element.innerHTML = newText.trim();
    const stripped = this.isSuicidalMode(element.innerHTML) ? this.doBadThings(element.innerHTML) : element.textContent;
    element.remove();
    return stripped;
  }

  private isSuicidalMode(html: string): boolean {
    return html.includes('<li>');
  }

  /**
   * #1 replace html tags
   * #2 replace non-breakable whitespaces
   * #3 replace multi-whitespaces with a single whitespace
   * #4 trim
   * #5 what could possibly go wrong? -> shoot me
   *
   * @param html the html to 'strip'
   * @returns the text without HTML tags and chars - hopefully
   */
  private doBadThings(html: string): string {
    return html.replace(/<.*?>/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .replace(/\s\s+/g, ' ')
      .trim();
  }
}

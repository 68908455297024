import {IconNameFilePickerItem} from '@app/file-library/icon-name-file-picker-item/icon-name-file-picker-item';
import {LOCAL_FILE_LIBRARY, StorageType} from '@domain/attachment/storage';

/**
 * File picker item showing a list of pages as children.
 */
export class PagesRootFilePickerItem implements IconNameFilePickerItem {
  readonly id: string = 'ENTITY_TYPE.PAGES';
  readonly name: string = 'ENTITY_TYPE.PAGES';
  readonly isFolder: boolean = true;
  readonly storageType: StorageType = LOCAL_FILE_LIBRARY;
  readonly hideHeader: boolean = true;
  readonly icon: string = 'page';
}

import {Pipe, PipeTransform} from '@angular/core';
import {Locale} from '@shared/multilanguage/locale';

@Pipe({
  name: 'locale'
})
export class LocalePipe implements PipeTransform {

  constructor() {
  }

  /**
   * Tries to create a Locale object from a language string
   * e.g. "DE" or "en_GB"
   *
   * @param localeString The locale or language string
   *
   * @returns a Locale object
   */
  transform(localeString: string): Locale {
    try {
      return Locale.parse(localeString);
    } catch (err) {
      try {
        return Locale.fromLanguage(localeString);
      } catch (err2) {
        return null;
      }
    }
  }

}

(function (angular) {
  'use strict';

  angular
      .module('coyo.admin.license')
      .component('usageInfo', usageInfo());

  /**
   * @ngdoc directive
   * @name commons.ui.usageInfo:usageInfo
   * @scope
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Renders a usage info element.
   *
   * @param {object} target The data target.
   * @param {number} target.current The current value.
   * @param {number} target.max The maximum value.
   * @param {string} type The usage type, to determine the translation key.
   */
  function usageInfo() {
    return {
      templateUrl: 'app/modules/admin/license/usage-info/usage-info.html',
      bindings: {
        target: '<',
        type: '<'
      }
    };
  }
})(angular);

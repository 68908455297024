import { Injectable } from '@angular/core';
import {DomainService} from '@domain/domain/domain.service';
import {LandingPage} from '@domain/landing-page/landing-page';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService extends DomainService<LandingPage, LandingPage> {

  protected getBaseUrl(): string {
    return '/web/landing-pages';
  }
}

import {FileUploadEvent} from './file-upload-event';

/**
 * Event that is emitted when a FileUpload is aborted
 */
export class FileUploadAbortedEvent extends FileUploadEvent {
  constructor(files: FileList | File[], readonly successfulUploads: number, readonly error: unknown) {
    super(files);
  }
}

(function (angular) {
  'use strict';

  appSettingsContainerController.$inject = ["$timeout", "$scope"];
  angular
      .module('coyo.apps.api')
      .component('oyocAppSettingsContainer', appSettingsContainer())
      .controller('AppSettingsContainerController', appSettingsContainerController);

  function appSettingsContainer() {
    return {
      templateUrl: 'app/apps/api/components/app-settings-container/app-settings-container.html',
      bindings: {
        legacyFormSet: '&',
        validityChanged: '&',
        app: '=',
        config: '=',
      },
      controller: 'AppSettingsContainerController'
    };
  }

  function appSettingsContainerController($timeout, $scope) {
    var vm = this;
    vm.$onInit = function () {
      var unsubscribeFormChanges = $scope.$watch($scope.ng1AppForm, function () {
        vm.legacyFormSet($scope.ng1AppForm);
      });

      var unsubscribeValidityChanges = $scope.$watch(function () {
        return $scope.ng1AppForm && $scope.ng1AppForm.$invalid;
      }, function () {
        vm.validityChanged($scope.ng1AppForm.$invalid);
      });

      $scope.$on('$destroy', unsubscribeFormChanges);
      $scope.$on('$destroy', unsubscribeValidityChanges);
    };
  }
})(angular);

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AuthService} from '@core/auth/auth.service';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {DownloadWidget} from '@widgets/download/download-widget';
import {DownloadWidgetSettings} from '@widgets/download/download-widget-settings';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';

/**
 * The download widget settings component.
 */
@Component({
  selector: 'coyo-download-widget-settings',
  templateUrl: './download-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadWidgetSettingsComponent extends WidgetSettingsComponent<DownloadWidget>
  implements OnInit {

  sender$: Observable<Sender>;

  constructor(private senderService: SenderService, private authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    this.sender$ = this.senderService.get(
      this.senderService.getCurrentIdOrSlug() ?? this.authService.getCurrentUserId(),
      {permissions: ['createFile']}
      );

    this.parentForm.addControl('_files', new FormControl(this.widget.settings._files || [],
      CoyoValidators.notBlank));
  }

  onBeforeSave(settings?: DownloadWidgetSettings): Observable<DownloadWidgetSettings> {
    return of({
      ...settings,
      _files: _.map(settings._files, file => ({
        id: file.id,
        senderId: file.senderId,
        title: file.title,
        name: file.name
      }))
    });
  }
}

import {NgModule} from '@angular/core';
import {APP_CONFIGS} from '@apps/api/app-config';
import {AppStateGenerator} from '@apps/api/app-state-generator';
import {ContentAppComponent} from '@apps/content/content-app.component';
import {CONTENT_APP_CONFIG} from '@apps/content/content-app.config';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {MultilanguageModule} from '@shared/multilanguage/multilanguage.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {WidgetsModule} from '@widgets/widgets.module';

export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: AppStateGenerator.buildStates(CONTENT_APP_CONFIG)});
/**
 * Module for components and configuration required for the content app
 */
@NgModule({
  imports: [
    uiRouterModuleChild,
    CoyoCommonsModule,
    CoyoFormsModule,
    MultilanguageModule,
    WidgetsModule
  ],
  providers: [
    {provide: APP_CONFIGS, useValue: CONTENT_APP_CONFIG, multi: true}
  ],
  declarations: [ContentAppComponent],
  entryComponents: []
})
export class ContentAppModule {
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

/**
 * Lock Button form control that is part of the new timeline item form.
 */
@Component({
  selector: 'coyo-lock-btn',
  templateUrl: './lock-btn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LockBtnComponent), multi: true}]
})
export class LockBtnComponent implements ControlValueAccessor {
  private onChange: (_: boolean) => void;

  model: boolean = false;

  constructor(private cd: ChangeDetectorRef) {}

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: (_: boolean) => void): void {
    this.onChange = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: (_: boolean) => void): void {
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(obj: boolean): void {
    this.model = obj;
    // have to mark for check because of https://github.com/angular/angular/issues/10816
    this.cd.markForCheck();
  }

  /**
   * Toggles the state of the button and sets the model value accordingly.
   *
   * @return `false`
   */
  toggle(): boolean {
    this.writeValue(!this.model);
    this.onChange(this.model);
    return false;
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * Filter component to show and wrap filter entries.
 *
 * @description
 * This component wraps the filter entries and give them a title.
 *
 * @example
 * <coyo-filter title="Title">
 *   <coyo-filter-entry>...</coyo-filter-entry>
 *   <coyo-filter-entry>...</coyo-filter-entry>
 * </coyo-filter>
 *
 * @see FilterEntryComponent
 */
@Component({
  selector: 'coyo-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent {

  /**
   * The title for the filter section.
   */
  @Input()
  title: string;

  /**
   * Indicates whether icons should be shown or not.
   */
  @Input()
  noIcons?: boolean = false;

  constructor() { }

}

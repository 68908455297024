import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {TextModule} from '@shared/text/text.module';
import {HashtagSettingsComponent} from './hashtags/hashtag-settings.component';
import './hashtags/hashtag-settings.service.downgrade';
import {NotificationsWrapperComponent} from './notifications-wrapper/notifications-wrapper.component';
import './notifications-wrapper/notifications-wrapper.component.downgrade';
import {NotificationsComponent} from './notifications/notifications.component';
import {SettingsDevicesState} from './settings-devices/sessions.state';
import {SettingsDevicesComponent} from './settings-devices/settings-devices.component';
import {SettingsEmailComponent} from './settings-email/settings-email.component';
import {SettingsIntegrationsComponent} from './settings-integrations/settings-integrations.component';
import {SettingsLanguageComponent} from './settings-language/settings-language.component';
import {SettingsNameComponent} from './settings-name/settings-name.component';
import {SettingsPasswordComponent} from './settings-password/settings-password.component';
import {SettingsTimezoneComponent} from './settings-timezone/settings-timezone.component';
import {SettingsComponent} from './settings/settings.component';
import './settings/settings.component.downgrade';
import {TimezoneOffsetPipe} from './timezone-offset/timezone-offset.pipe';

/**
 * Module which contains user setting related components.
 */
@NgModule({
  imports: [
    NgxsModule.forFeature([SettingsDevicesState]),
    CoyoCommonsModule,
    CoyoFormsModule,
    TextModule
  ],
  declarations: [
    SettingsComponent,
    SettingsDevicesComponent,
    SettingsEmailComponent,
    SettingsIntegrationsComponent,
    SettingsLanguageComponent,
    SettingsNameComponent,
    SettingsPasswordComponent,
    SettingsTimezoneComponent,
    TimezoneOffsetPipe,
    NotificationsComponent,
    HashtagSettingsComponent,
    NotificationsWrapperComponent
  ]
})
export class AccountModule {
}

(function (angular) {
  'use strict';

  etagCacheService.$inject = ["$sessionStorage", "$rootScope", "$interval"];
  angular
      .module('commons.resource')
      .factory('etagCacheService', etagCacheService);

  /**
   * @ngdoc service
   * @name commons.resource.etagCacheService
   *
   * @description
   * Session storage based local cache to store REST responses with ETag headers.
   *
   * @requires $sessionStorage
   * @requires $rootScope
   * @requires $interval
   */
  function etagCacheService($sessionStorage, $rootScope, $interval) {

    var CACHE_EXPIRY_MS = 1000 * 60 * 60;
    var CACHE_CLEANUP_INTERVAL_MS = 60000;
    var cache;
    var bulkCache;

    /**
     * @ngdoc function
     * @name commons.resource.etagCacheService#buildCacheKey
     * @methodOf commons.resource.etagCacheService
     *
     * @description
     * Create a cache key from the given url and request parameters.
     *
     * @param {string} url
     * The request url
     *
     * @param {object} params
     * Map of request parameters and their values.
     *
     * @param {object?} etagCacheConfig
     * The cache configuration
     *
     * @param {string?} etagCacheConfig.bulkParameter
     * Name of the request parameter containing bulk request IDs
     *
     * @returns {string} Cache key.
     */
    function buildCacheKey(url, params, etagCacheConfig) {
      var key = url;
      if (params && Object.keys(params).length) {
        var paramsString = Object.keys(params).filter(function (param) {
          return param !== _.get(etagCacheConfig, 'bulkParameter');
        }).map(function (param) {
          return param + '=' + params[param];
        }).join('&');
      }
      return key + (paramsString ? ('?' + paramsString) : '');
    }

    /**
     * @ngdoc function
     * @name commons.resource.etagCacheService#store
     * @methodOf commons.resource.etagCacheService
     *
     * @description
     * Store the response data of an etag request
     *
     * @param {string} key
     * The cache key
     *
     * @param {*} data
     * Response data
     *
     * @param {string} etag
     * Value of ETag header sent by backend
     *
     * @param {int} status
     * HTTP status
     */
    function store(key, data, etag, status) {
      cache[key] = _updateAccess({etag: etag, data: data, status: status});
    }

    /**
     * @ngdoc function
     * @name commons.resource.etagCacheService#isCached
     * @methodOf commons.resource.etagCacheService
     *
     * @description
     * Checks if the given cache key exists.
     *
     * @param {string} key
     * Cache key
     *
     * @returns {boolean}
     * True if cached data exists for the key
     */
    function isCached(key) {
      return angular.isDefined(_updateAccess(cache[key]));
    }

    /**
     * @ngdoc function
     * @name commons.resource.etagCacheService#get
     * @methodOf commons.resource.etagCacheService
     *
     * @description
     * Get the cached data for the given key.
     *
     * @param {string} key
     * Cache key
     *
     * @returns {object}
     * The cached data, containing properties data, etag and status
     */
    function get(key) {
      return _.pick(_updateAccess(cache[key]), ['etag', 'data', 'status']);
    }

    /**
     * @ngdoc function
     * @name commons.resource.etagCacheService#storeBulk
     * @methodOf commons.resource.etagCacheService
     *
     * @description
     * Store the response data of an bulk etag request
     *
     * @param {string} key
     * key The cache key
     *
     * @param {string} id
     * The bulk ID
     *
     * @param {*} data
     * Response data
     *
     * @param {string} etag
     * Value of ETag header sent by backend for the given ID
     */
    function storeBulk(key, id, data, etag) {
      _updateAccess(bulkCache[key]);
      var cacheValue = _updateAccess({etag: etag, data: data});
      _.set(bulkCache, key + '[' + id + ']', cacheValue);
    }

    /**
     * @ngdoc function
     * @name commons.resource.etagCacheService#isBulkCached
     * @methodOf commons.resource.etagCacheService
     *
     * @description
     * Checks if the given cache key exists in the bulk cache.
     * Only checks on URL level, not on the individual IDs stored below that.
     *
     * @param {string} key
     * Cache key
     *
     * @returns {boolean}
     * True if cached data exists for the key
     */
    function isBulkCached(key) {
      return angular.isDefined(_updateAccess(bulkCache[key]));
    }

    /**
     * @ngdoc function
     * @name commons.resource.etagCacheService#getBulk
     * @methodOf commons.resource.etagCacheService
     *
     * @description
     * Get the cached data for the given key and bulk ID.
     *
     * @param {string} key
     * Cache key
     *
     * @param {string} id
     * The bulk ID
     *
     * @returns {object}
     * The cached data, containing properties data and etag.
     */
    function getBulk(key, id) {
      if (angular.isDefined(bulkCache[key])) {
        _updateAccess(bulkCache[key]);
        return _.pick(_updateAccess(bulkCache[key][id]), ['etag', 'data']);
      }
      return undefined;
    }

    /**
     * @ngdoc function
     * @name commons.resource.etagCacheService#clearAll
     * @methodOf commons.resource.etagCacheService
     *
     * @description
     * Clear the whole etag cache.
     */
    function clearAll() {
      cache = $sessionStorage.etagCache = {};
      bulkCache = $sessionStorage.etagBulkCache = {};
    }

    /*
     * Track last access time of the given item. Used for cache expiry.
     */
    function _updateAccess(item) {
      if (item) {
        item.cacheAccess = new Date().getTime();
      }
      return item;
    }

    /*
     * Expire cache entries that have not been accessed for more than a given threshold.
     * This is not used to force any invalidation after a fixed time (that is done by the backend)
     * but rather removing unused data from cache to clear up space.
     */
    function _cleanupCache() {
      _deleteExpiredEntries(cache);

      Object.keys(bulkCache).forEach(function (key) {
        if (_isExpired(bulkCache[key])) {
          delete bulkCache[key];
        } else {
          _deleteExpiredEntries(bulkCache[key]);
        }
      });

      function _isExpired(cacheEntry) {
        if (!cacheEntry.cacheAccess) {
          return false;
        }
        return cacheEntry.cacheAccess < new Date().getTime() - CACHE_EXPIRY_MS;
      }

      function _deleteExpiredEntries(cacheEntries) {
        Object.keys(cacheEntries).forEach(function (key) {
          if (_isExpired(cacheEntries[key])) {
            delete cacheEntries[key];
          }
        });
      }
    }

    (function _init() {
      $rootScope.$on('authService:logout:before', function () {
        clearAll();
      });

      $interval(_cleanupCache, CACHE_CLEANUP_INTERVAL_MS);

      if (!$sessionStorage.etagCache) {
        $sessionStorage.etagCache = {};
      }
      if (!$sessionStorage.etagBulkCache) {
        $sessionStorage.etagBulkCache = {};
      }
      cache = $sessionStorage.etagCache;
      bulkCache = $sessionStorage.etagBulkCache;
    })();

    return {
      buildCacheKey: buildCacheKey,
      store: store,
      isCached: isCached,
      get: get,
      storeBulk: storeBulk,
      getBulk: getBulk,
      clearAll: clearAll,
      isBulkCached: isBulkCached
    };
  }

})(angular);

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {HEADLINE_WIDGET} from '@widgets/headline/headline-widget-config';
import {HeadlineWidgetComponent} from '@widgets/headline/headline-widget/headline-widget.component';
import {AutosizeModule} from 'ngx-autosize';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {HeadlineWidgetInlineOptionsComponent} from './headline-widget-inline-options/headline-widget-inline-options.component';

/**
 * Module for the headline widget
 */
@NgModule({
  imports: [
    AutosizeModule,
    BsDropdownModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    HelpModule
  ],
  declarations: [
    HeadlineWidgetComponent,
    HeadlineWidgetInlineOptionsComponent
  ],
  entryComponents: [
    HeadlineWidgetComponent,
    HeadlineWidgetInlineOptionsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: HEADLINE_WIDGET, multi: true}
  ]
})
export class HeadlineWidgetModule {}

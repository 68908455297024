import {Inject, Injectable} from '@angular/core';
import {CoyoStorage} from '@core/storage/storage';
import {WINDOW} from '@root/injection-tokens';
import {NG1_LOCAL_STORAGE} from '@upgrade/upgrade.module';

/**
 * Abstraction service for all local storage related stuff.
 *
 * To make sure to be compatible with the former angularJS local storage lib the prefix is still 'ngStorage-'
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements CoyoStorage {

  static readonly PREFIX: string = 'ngStorage-';

  constructor(@Inject(WINDOW) private windowService: Window,
              @Inject(NG1_LOCAL_STORAGE) private $localStorage: { $sync(): void }) {
  }

  setValue(key: string, value: any): void {
    this.windowService.localStorage.setItem(LocalStorageService.PREFIX + key, JSON.stringify(value));
    // We have to sync the angularJS local storage here to make it aware of the changes
    this.$localStorage.$sync();
  }

  getValue<T>(key: string, fallback: T = null): T {
    const value = this.windowService.localStorage.getItem(LocalStorageService.PREFIX + key);
    return JSON.parse(value) || fallback;
  }

  deleteEntry(key: string): void {
    this.windowService.localStorage.removeItem(LocalStorageService.PREFIX + key);
    // We have to sync the angularJS local storage here to make it aware of the changes
    this.$localStorage.$sync();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Provider,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {App} from '@apps/api/app';
import {GlobalAppService} from '@domain/apps/global-app.service';
import {Pageable} from '@domain/pagination/pageable';
import {SelectUiComponent} from '@shared/select-ui/select-ui.component';
import {SelectUiSettings} from '@shared/select-ui/select-ui.settings';
import * as _ from 'lodash';

const selectAppValueProvider: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectAppComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

/**
 * Component to search and select an app.
 *
 * Considering the timeouts in the proxied ControlValueAccessor methods:
 * Unfortunately the ViewChild query is not yet evaluated when the calls
 * are coming in, so the timeout is required to delay the calls by one cycle.
 */
@Component({
  selector: 'coyo-select-app',
  templateUrl: './select-app.component.html',
  styleUrls: ['./select-app.component.scss'],
  providers: [selectAppValueProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectAppComponent implements OnInit, OnChanges, ControlValueAccessor {
  /**
   * App-Type key to select what kind of apps will be found
   */
  @Input() key: string;
  /**
   * Placeholder string shown if no item is selected
   */
  @Input() placeholder: string;
  /**
   * The number of items per request
   */
  @Input() scrollPageSize: number = 10;
  /**
   * The distance from the bottom a user has to scroll to trigger the next page load
   * (For some reason the value of the scroll location is quite inaccurate. Will mostly load earlier than anticipated)
   */
  @Input() scrollPageTriggerOffset: number = 5;
  /**
   * The debounce time for the filter query when typing into the search field
   */
  @Input() typeaheadDebounceTime: number = 250;
  /**
   * Boolean indicating whether multiple values can be selected
   */
  @Input() multiselect: boolean = true;
  /**
   * Boolean indicating whether the dropdown closes each time a new item is added
   */
  @Input() closeOnSelect: boolean = true;
  /**
   * Boolean indicating whether a "clear all" button is displayed
   */
  @Input() clearable: boolean = true;
  /**
   * Settings object for passing settings to select ui base component
   */
  settings: SelectUiSettings<App<any>>;
  /**
   * Base component for select components
   */
  @ViewChild(SelectUiComponent)
  select: SelectUiComponent<App<any>>;

  constructor(private globalAppService: GlobalAppService) {
  }

  ngOnInit(): void {
    this.settings = this.createSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newSettings = this.createSettings();
    if (!_.isEqual(this.settings, newSettings)) {
      this.settings = newSettings;
    }
  }

  registerOnChange(fn: any): void {
    setTimeout(() => this.select.registerOnChange(fn), 0);
  }

  registerOnTouched(fn: any): void {
    setTimeout(() => this.select.registerOnTouched(fn), 0);
  }

  setDisabledState(isDisabled: boolean): void {
    setTimeout(() => this.select.setDisabledState(isDisabled), 0);
  }

  writeValue(value: any): void {
    setTimeout(() => this.select.writeValue(value), 0);
  }

  private createSettings(): SelectUiSettings<App<any>> {
    return {
      closeOnSelect: this.closeOnSelect,
      multiselect: !!this.multiselect,
      searchFn: (pageable: Pageable, term: string) => this.globalAppService.get(pageable, this.key, term),
      compareFn: (a: App<any>, b: App<any>) => a.id === b.id,
      clearable: this.clearable,
      placeholder: this.placeholder,
      debounceTime: this.typeaheadDebounceTime,
      pageSize: this.scrollPageSize,
      scrollOffsetTrigger: this.scrollPageTriggerOffset
    };
  }
}

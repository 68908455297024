(function () {
  'use strict';

  PageAutoSubscribeSettingsController.$inject = ["$scope"];
  angular
      .module('coyo.pages')
      .component('oyocPageAutoSubscribeSettings', pageAutoSubscribeSettings())
      .controller('PageAutoSubscribeSettingsController', PageAutoSubscribeSettingsController);

  function pageAutoSubscribeSettings() {
    return {
      controller: 'PageAutoSubscribeSettingsController',
      controllerAs: '$ctrl',
      templateUrl: 'app/modules/pages/components/auto-subscribe-settings/page-auto-subscribe-settings.html',
      bindings: {
        page: '='
      }
    };
  }

  function PageAutoSubscribeSettingsController($scope) {
    var vm = this;
    var originalPage;
    var selectedUsersBackup;

    vm.$onInit = onInit;
    vm.onUsersSelected = onUsersSelected;
    vm.onSelectionChange = onSelectionChange;

    function onUsersSelected() {
      _copySelectedSubscriptionsToPage();
    }

    function onSelectionChange() {
      if (vm.page.autoSubscribeType !== 'SELECTED') {
        _backupAutoSubscribedUsersAndGroups();
        vm.page.autoSubscribeUserIds = [];
        vm.page.autoSubscribeGroupIds = [];
      } else {
        _restoreAutoSubscribedUsersAndGroups();
        _copySelectedSubscriptionsToPage();
      }
      if (vm.page.isNew()) {
        vm.showWarning = vm.page.autoSubscribeType !== 'NONE';
      }
    }

    function _copySelectedSubscriptionsToPage() {
      vm.page.autoSubscribeUserIds = vm.autoSubscriptions.autoSubscribeUserIds;
      vm.page.autoSubscribeGroupIds = vm.autoSubscriptions.autoSubscribeGroupIds;
    }

    function _autoSubscribeFactorChanged(updatedPage) {
      var result = _isOrWasAutoSubscribe(updatedPage) &&
          (updatedPage.visibility !== originalPage.visibility ||
          !angular.equals(updatedPage.memberIds, originalPage.memberIds) ||
          !angular.equals(updatedPage.adminIds, originalPage.adminIds) ||
          !angular.equals(updatedPage.memberGroupIds, originalPage.memberGroupIds) ||
          !angular.equals(updatedPage.adminGroupIds, originalPage.adminGroupIds) ||
          updatedPage.autoSubscribeType !== originalPage.autoSubscribeType ||
          !angular.equals(updatedPage.autoSubscribeUserIds, originalPage.autoSubscribeGroupIds) ||
          !angular.equals(updatedPage.autoSubscribeGroupIds, originalPage.autoSubscribeGroupIds));
      return result;
    }

    function _backupAutoSubscribedUsersAndGroups() {
      if (!_.isEmpty(vm.page.autoSubscribeUserIds) || !_.isEmpty(vm.page.autoSubscribeGroupIds)) {
        selectedUsersBackup = _.pick(vm.page, ['autoSubscribeUserIds', 'autoSubscribeGroupIds']);
      }
    }

    function _restoreAutoSubscribedUsersAndGroups() {
      var source = selectedUsersBackup || vm.page;
      vm.autoSubscriptions = {
        autoSubscribeUserIds: _.get(source, 'autoSubscribeUserIds', []),
        autoSubscribeGroupIds: _.get(source, 'autoSubscribeGroupIds', [])
      };
    }

    function _isOrWasAutoSubscribe(updatedPage) {
      return updatedPage.autoSubscribeType !== 'NONE' || originalPage.autoSubscribeType !== 'NONE';
    }

    function onInit() {
      _restoreAutoSubscribedUsersAndGroups();
      if (!vm.page.isNew()) {
        originalPage = angular.copy(vm.page);
        $scope.$watch(function () {
          return vm.page;
        }, function (updatedPage, oldPage) {
          if (!angular.equals(updatedPage, oldPage)) {
            vm.showWarning = _autoSubscribeFactorChanged(updatedPage);
          }
        }, true);
      }
    }
  }
})();

import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SocketService} from '@core/socket/socket.service';
import {Comment} from '@domain/comment/comment';
import {CommentService} from '@domain/comment/comment.service';
import {Direction} from '@domain/pagination/direction.enum';
import {Order} from '@domain/pagination/order';
import {Pageable} from '@domain/pagination/pageable';
import {Sender} from '@domain/sender/sender';
import * as _ from 'lodash';
import {BehaviorSubject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

interface CommentListState {
  isLoading: boolean;
  comments: Comment[];
  commentsNew: Comment[];
  commentEditing: Comment | null;
  count: number;
  total: number;
}

/**
 * A component that displays a list of comments and offers a form to submit new comments.
 */
@Component({
  selector: 'coyo-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentListComponent implements OnInit, OnDestroy {
  private createdSubscription: Subscription;
  private updatedSubscription: Subscription;
  private deletedSubscription: Subscription;

  state$: BehaviorSubject<CommentListState> = new BehaviorSubject({
    isLoading: false,
    comments: [],
    commentsNew: [],
    commentEditing: null,
    count: 0,
    total: 0
  });

  /**
   * The target of the comment.
   */
  @Input() target: Sender;

  /**
   * The author of new comments.
   */
  @Input() author: Sender;

  /**
   * The websocket subscription token
   */
  @Input() subscriptionToken: string;

  /**
   * Flag if the initial request should be skipped
   */
  @Input() skipInitRequest: boolean = false;

  constructor(
    private commentService: CommentService,
    private socketService: SocketService) {
  }

  ngOnInit(): void {
    this.initSocket();
    if (!this.skipInitRequest) {
      this.initPage();
    }
  }

  private initSocket(): void {
    const topic = `/topic/comment`;
    this.createdSubscription = this.socketService.listenTo$(topic, 'created', this.target.id, this.subscriptionToken)
      .pipe(map(event => ({...event.content, ...{partiallyLoaded: true}, ...{subscriptionInfo: {token: event.content.token}}})))
      .subscribe(comment => this.addToState(comment));
    this.updatedSubscription = this.socketService.listenTo$(topic, 'updated', this.target.id, this.subscriptionToken)
      .pipe(map(event => ({...event.content, ...{partiallyLoaded: true}, ...{subscriptionInfo: {token: event.content.token}}})))
     .subscribe(comment => this.updateInState(comment));
    this.deletedSubscription = this.socketService.listenTo$(topic, 'deleted', this.target.id, this.subscriptionToken)
      .subscribe(event => this.removeFromState(event.content));
  }

  private initPage(): void {
    this.setLoading(true);
    this.commentService.getInitialPage(this.target.id, this.target.typeName)
      .subscribe(page => this.state$.next({
        isLoading: false,
        comments: page.content.reverse(),
        commentsNew: [],
        commentEditing: null,
        count: page.numberOfElements,
        total: page.totalElements,
      }), () => this.setLoading(false));
  }

  private setLoading(isLoading: boolean): void {
    const state = this.state$.getValue();
    this.state$.next({...state, ...{isLoading}});
  }

  private addToState(comment: Comment): void {
    const state = this.state$.getValue();
    const index = _.findIndex(state.comments, {id: comment.id});
    const indexNew = _.findIndex(state.commentsNew, {id: comment.id});
    if (index === -1 && indexNew === -1) {
      this.state$.next({
        ...state, ...{
          commentsNew: [...state.commentsNew, comment],
          count: state.count + 1,
          total: state.total + 1,
        }
      });
    }
  }

  /**
   * Updates the given comment and emits a new state
   * @param comment the updated comment
   */
  updateInState(comment: Comment): void {
    const state = this.state$.getValue();
    const comments = this.updateInArray(state.comments, comment);
    const commentsNew = this.updateInArray(state.commentsNew, comment);
    if (comments !== null) {
      this.state$.next({...state, ...{comments}});
    } else if (commentsNew !== null) {
      this.state$.next({...state, ...{commentsNew}});
    }
  }

  /*
   * Updates the array with the given element and returns a new array
   * or `null` if the array has not changed.
   */
  private updateInArray(comments: Comment[], comment: Comment): Comment[] | null {
    const index = _.findIndex(comments, {id: comment.id});
    if (index !== -1) {
      const result = [...comments];
      result.splice(index, 1, comment);
      return result;
    }
    return null;
  }

  private removeFromState(id: string): void {
    const state = this.state$.getValue();
    const comments = this.removeInArray(state.comments, id);
    const commentsNew = this.removeInArray(state.commentsNew, id);
    if (comments !== null) {
      this.state$.next({
        ...state, ...{
          comments,
          count: state.count - 1,
          total: state.total - 1,
        }
      });
    } else if (commentsNew !== null) {
      this.state$.next({
        ...state, ...{
          commentsNew,
          count: state.count - 1,
          total: state.total - 1,
        }
      });
    } else {
      this.state$.next({
        ...state, ...{
          total: state.total - 1,
        }
      });
    }
  }

  /*
   * Removes the given element from the array and returns a new array
   * or `null` if the array has not changed.
   */
  private removeInArray(comments: Comment[], id: string): Comment[] | null {
    const index = _.findIndex(comments, {id});
    if (index !== -1) {
      const result = [...comments];
      result.splice(index, 1);
      return result;
    }
    return null;
  }

  /**
   * Handles the event of a comment that has been created.
   *
   * @param comment the comment that has been created
   */
  commentCreated(comment: Comment): void {
    this.addToState(comment);
  }

  /**
   * Handles the event of a comment that has been updated.
   *
   * @param comment the comment to be updated
   */
  commentEdited(comment: Comment): void {
    this.updateInState(comment);
  }

  /**
   * Delete a comment.
   *
   * @param comment the comment to be deleted
   */
  deleteComment(comment: Comment): void {
    this.commentService.delete(comment.id).subscribe(() => this.removeFromState(comment.id));
  }

  /**
   * Begin editing a comment.
   *
   * @param comment the comment to be edited
   */
  editComment(comment: Comment): void {
    const state = this.state$.getValue();
    this.state$.next({
      ...state, ...{
        commentEditing: comment
      }
    });
  }

  /**
   * Cancel the editing of a comment.
   */
  cancelEditing(): void {
    const state = this.state$.getValue();
    this.state$.next({
      ...state, ...{
        commentEditing: null
      }
    });
  }

  /**
   * Load more comments (the next page).
   */
  loadMore(): void {
    let state = this.state$.getValue();
    if (state.isLoading) {
      return;
    }

    this.setLoading(true);
    this.commentService.getPage(new Pageable(0, 8, state.count, new Order('created', Direction.Desc)), {
      params: {
        targetId: this.target.id,
        targetType: this.target.typeName,
        _permissions: '*'
      }
    }).subscribe(page => {
      state = this.state$.getValue();
      this.state$.next({
        ...state, ...{
          isLoading: false,
          comments: [...page.content.reverse(), ...state.comments],
          count: state.count + page.numberOfElements,
          total: page.totalElements,
        }
      });
    }, () => this.setLoading(false));
  }

  /**
   * Tracks a comment identity in a ngFor loop.
   *
   * @param index index of the object in the collection that is iterated through
   * @param item the value that should be checked
   * @return the value that is used to verify identity
   */
  trackComment(index: number, item: Comment): string {
    return item.id;
  }

  ngOnDestroy(): void {
    this.createdSubscription.unsubscribe();
    this.updatedSubscription.unsubscribe();
    this.deletedSubscription.unsubscribe();
  }
}

<ng-container *ngIf="state$ | async as state; else loading">
  <h1 mat-dialog-title>{{'PUBLIC_LINK.TITLE' | translate}}</h1>
  <mat-dialog-close aria-label="Close Dialog"></mat-dialog-close>
  <div mat-dialog-content>
    <p *ngIf="!state.downloadUrl">{{'PUBLIC_LINK.INTRO' | translate}}</p>
    <ng-container *ngIf="state.downloadUrl">
      <p class="download-url" [class.disabled]="!state.active">{{state.downloadUrl}}</p>
      <p class="inactive-warning" *ngIf="!state.active">{{'PUBLIC_LINK.INFO' | translate}}</p>
    </ng-container>
    <button
      [cdkCopyToClipboard]="state.downloadUrl"
      (cdkCopyToClipboardCopied)="copied()"
      mat-flat-button
      color="default"
      size="small"
      cdkFocusInitial
      class="block" *ngIf="state.downloadUrl && state.active">
      {{'PUBLIC_LINK.COPY' | translate}}
    </button>
    <button mat-flat-button color="primary" (click)="create()" cdkFocusInitial class="block" *ngIf="!state.downloadUrl">{{'PUBLIC_LINK.CREATE' | translate}}</button>
  </div>
  <ng-container *ngIf="state.downloadUrl">
    <hr />
    <h5>{{'OPTIONS' | translate }}</h5>
    <div class="dialog-actions" mat-dialog-actions>
      <button mat-flat-button color="default" (click)="recreate(state.token)" *ngIf="state.token">{{'PUBLIC_LINK.RECREATE' | translate}}</button>
      <button mat-flat-button color="primary" (click)="activate(state.token)" *ngIf="!state.active">{{'PUBLIC_LINK.ACTIVATE' | translate}}</button>
      <button mat-flat-button color="warn" (click)="deactivate(state.token)" *ngIf="state.active">{{'PUBLIC_LINK.DEACTIVATE' | translate}}</button>
    </div>
  </ng-container>
</ng-container>
<ng-template #loading>
  <coyo-spinner></coyo-spinner>
</ng-template>

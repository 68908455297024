import {NgModule} from '@angular/core';
import {CreateFolderButtonComponent} from '@app/file-picker/create-folder-button/create-folder-button.component';
import {FILE_PICKER_ITEM_SERVICES} from '@app/file-picker/file-picker-item.service';
import {FilePickerSelectionComponent} from '@app/file-picker/file-picker-selection/file-picker-selection.component';
import {FilePickerSelectionsState} from '@app/file-picker/file-picker-selection/file-picker-selections-state';
import {FilePickerSelectAllComponent} from '@app/file-picker/file-picker-selection/filepicker-select-all/file-picker-select-all.component';
import {FilePickerState} from '@app/file-picker/file-picker-state/file-picker.state';
import {FilePickerUploadItemComponent} from '@app/file-picker/file-picker-upload-item/file-picker-upload-item.component';
import {FilePickerUploadItemService} from '@app/file-picker/file-picker-upload-item/file-picker-upload-item.service';
import {FilePickerComponent} from '@app/file-picker/file-picker/file-picker.component';
import {filePreviewState} from '@app/file-picker/file-preview-route/file-preview.state';
import {UploadButtonComponent} from '@app/file-picker/upload-button/upload-button.component';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {FileModule} from '@shared/files/file.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {InfiniteScrollingDirective} from '@shared/infinite-scrolling/infinite-scrolling.directive';
import {PreviewModule} from '@shared/preview/preview.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {DynamicIoModule} from 'ng-dynamic-component';
import {FilePickerBreadcrumbComponent} from './file-picker-breadcrumb/file-picker-breadcrumb.component';
import {FilePickerIconComponent} from './file-picker-icon/file-picker-icon.component';
import {FilePickerModalComponent} from './file-picker-modal/file-picker-modal.component';
import {FilePickerSearchComponent} from './file-picker-search/file-picker-search.component';

export const ngxsModule = NgxsModule.forFeature([FilePickerSelectionsState, FilePickerState]);
export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: [filePreviewState]});

/**
 * The filepicker module
 */
@NgModule({
  declarations: [
    FilePickerModalComponent,
    FilePickerComponent,
    FilePickerBreadcrumbComponent,
    FilePickerIconComponent,
    FilePickerSearchComponent,
    FilePickerSelectAllComponent,
    FilePickerSelectionComponent,
    FilePickerUploadItemComponent,
    CreateFolderButtonComponent,
    UploadButtonComponent,
    InfiniteScrollingDirective
  ],
  exports: [
    FilePickerIconComponent,
    FilePickerSelectAllComponent,
    FilePickerSelectionComponent,
    FilePickerBreadcrumbComponent,
    FilePickerComponent,
    CreateFolderButtonComponent,
    UploadButtonComponent,
    InfiniteScrollingDirective,
  ],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    FileModule,
    PreviewModule,
    DynamicIoModule,
    uiRouterModuleChild,
    ngxsModule
  ],
  providers: [
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: FilePickerUploadItemService, multi: true}
  ]
})
export class FilePickerModule {
}

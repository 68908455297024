import {NgModule} from '@angular/core';
import {AutofocusDirective} from '@shared/autofocus/autofocus.directive';

/**
 * Shared module containing all components responsible for the autofocus directive
 */
@NgModule({
  declarations: [
    AutofocusDirective
  ],
  exports: [
    AutofocusDirective
  ]
})
export class AutofocusModule {
}

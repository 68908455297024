(function (angular) {
  'use strict';

  EventsShowController.$inject = ["$q", "$scope", "$rootScope", "$injector", "moment", "event", "senderService", "currentUser", "Pageable", "EventModel", "ngxEventSettingsModalService", "ngxNotificationService", "selectionFilterService", "authService"];
  angular
      .module('coyo.events')
      .controller('EventsShowController', EventsShowController);

  function EventsShowController($q, $scope, $rootScope, $injector, moment, event, senderService, currentUser, Pageable,
                                EventModel, ngxEventSettingsModalService, ngxNotificationService,
                                selectionFilterService, authService) {
    var vm = this;
    var eventsStatusIconService = $injector.get('ngxStatusIconService');

    vm.currentUser = currentUser;
    vm.event = event;
    vm.currentPage = 0;
    vm.admins = [];
    vm.iCalExportUrl = null;

    vm.search = search;
    vm.changeAvatar = changeAvatar;
    vm.isOngoing = isOngoing;
    vm.hasStarted = hasStarted;
    vm.hasEnded = hasEnded;
    vm.currentUser = currentUser;
    vm.changeCover = senderService.changeCover({title: 'MODULE.EVENTS.MODALS.CHANGE_BG_IMAGE.TITLE'});
    vm.setParticipationStatusFilter = setParticipationStatusFilter;
    vm.onParticipationStatusChanged = onParticipationStatusChanged;
    vm.inviteMembers = inviteMembers;
    vm.removeMember = removeMember;
    vm.demote = demote;

    vm.params = angular.extend({
      searchTerm: undefined,
      status: []
    });
    vm.$onInit = _init;

    var canInviteGroupsPermission = false, canInvitePagesPermission = false, canInviteWorkspacesPermission = false;

    function changeAvatar(sender) {
      senderService.changeAvatar({title: 'MODULE.EVENTS.MODALS.CHANGE_AVATAR.TITLE'})(sender).then(function () {
        vm.event = angular.copy(vm.event);
      });
    }

    function setParticipationStatusFilter(selected) {
      vm.params.status = selected;
      return _loadMemberships();
    }

    function onParticipationStatusChanged(participantStatusChangedEvent) {
      var newIsAttending = participantStatusChangedEvent.newStatus === 'ATTENDING';
      var oldIsAttending = participantStatusChangedEvent.oldStatus === 'ATTENDING';
      if (newIsAttending !== oldIsAttending) {
        if (newIsAttending) {
          vm.event.attendingCount++;
        } else {
          vm.event.attendingCount--;
        }
      }

      _loadMemberships();
    }

    function search(term) {
      vm.params.searchTerm = term;
      return _loadMemberships();
    }

    function inviteMembers() {
      var entityId = {id: vm.event.id, typeName: vm.event.typeName};
      ngxEventSettingsModalService.openUserSelection(entityId, canInviteGroupsPermission, canInvitePagesPermission, canInviteWorkspacesPermission).then(function () {
        ngxNotificationService.success('MODULE.EVENTS.EDIT.SUCCESS');
        _loadMemberships();
      });
    }

    function removeMember(userId, removedMemberParticipationStatus) {
      if (vm.loading) {
        $q.reject();
      }
      vm.loading = true;
      return event.removeMember(userId).then(function () {
        if (removedMemberParticipationStatus === 'ATTENDING') {
          vm.event.attendingCount--;
        }
      }).finally(function () {
        vm.loading = false;
        _init();
      });
    }

    function demote(userId, $event) {
      $event.stopPropagation();
      event.demote(userId).then(function () {
        _loadMemberships();
        _loadAdmins();
      });
    }

    function _getStatusIcon(statusKey) {
      return eventsStatusIconService.getStatusIcon(statusKey);
    }

    function _loadMemberships() {
      if (vm.loading || !_shouldShowParticipants()) {
        return $q.reject();
      }
      vm.loading = true;

      var sort = vm.params.searchTerm ? ['_score,DESC', 'displayName'] : 'displayName';
      var pageable = new Pageable(0, 20, sort);
      var filter = _.omit(vm.params, 'searchTerm');
      var searchFields = ['displayName'];
      var aggregations = {status: ''};
      return EventModel.getMembershipsWithFilter(vm.params.searchTerm, pageable, filter, searchFields, aggregations,
          vm.event.slug).then(function (page) {
        vm.currentPage = page;
        // sort departments by:
        //   1. status count descending
        //   2. status name ascending
        var participationStatus;
        if (page.aggregations) {
          participationStatus = _.orderBy(page.aggregations.status,
              ['count', 'key'],
              ['desc', 'asc']);
        } else {
          participationStatus = [];
        }
        // set active state for every status
        participationStatus.forEach(function (status) {
          status.active = vm.params.status.indexOf(status.key) >= 0;
          status.icon = _getStatusIcon(status.key);
        });
        vm.participationStatusFilter = selectionFilterService.builder().items(participationStatus).build();
      }).finally(function () {
        vm.loading = false;
      });
    }

    function _loadAdmins() {
      event.getAdmins().then(function (admins) {
        vm.admins = admins;
      });
    }

    function _updateIcalExportUrl() {
      vm.iCalExportUrl = vm.event.getIcalExportUrl();
    }

    function _init() {
      _loadMemberships();
      _loadAdmins();
      _updateIcalExportUrl();
      vm.shouldShowParticipants = _shouldShowParticipants();
      vm.canManage = _canManage();
      authService.onGlobalPermissions('PERMIT_GROUP_INVITES', function (canInviteGroups) {
        canInviteGroupsPermission = canInviteGroups;
      }, true);
      authService.onGlobalPermissions('PERMIT_PAGE_INVITES', function (canInvitePages) {
        canInvitePagesPermission = canInvitePages;
      }, true);
      authService.onGlobalPermissions('PERMIT_WORKSPACE_INVITES', function (canInviteWorkspaces) {
        canInviteWorkspacesPermission = canInviteWorkspaces;
      }, true);
    }

    function isOngoing() {
      var now = moment();
      return now.isSameOrAfter(vm.event.startDate) && now.isBefore(vm.event.endDate);
    }

    function hasStarted() {
      return moment().isSameOrAfter(vm.event.startDate);
    }

    function hasEnded() {
      return moment().isSameOrAfter(vm.event.endDate);
    }

    function _shouldShowParticipants() {
      return vm.event.showParticipants || _canManage();
    }

    function _canManage() {
      return vm.event.canManage();
    }
  }

})(angular);

import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {NewColleaguesWidgetState} from '@widgets/new-colleagues/new-colleagues-widget/new-colleagues-widget.state';
import {WIDGET_CONFIGS} from '../api/widget-config';
import {NEW_COLLEAGUES_WIDGET} from './new-colleagues-widget-config';
import {NewColleaguesWidgetComponent} from './new-colleagues-widget/new-colleagues-widget.component';

export const ngxsModule = NgxsModule.forFeature([NewColleaguesWidgetState]);
@NgModule({
  declarations: [NewColleaguesWidgetComponent],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    SenderUIModule,
    ngxsModule
  ],
  entryComponents: [NewColleaguesWidgetComponent],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: NEW_COLLEAGUES_WIDGET, multi: true},
  ]
})
export class NewColleaguesModule { }

import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

/**
 * Service to hold {@link hasPendingRequests} BehaviorSubject that can be set by an HttpInterceptor
 * and holds if there are any outstanding requests application wide.
 */
@Injectable({
  providedIn: 'root'
})
export class PendingService {
  private hasPendingRequests: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Sets the next current value that is to be emitted by hasPendingRequests$.
   * @param value the next value
   */
  setNextHasPendingRequests(value: boolean): void {
    this.hasPendingRequests.next(value);
  }

  /**
   * Returns and observable that emits with the current, and then each changed value of hasPendingRequests.
   * @return Observable emitting on distinct values
   */
  hasPendingRequests$(): Observable<boolean> {
    return this.hasPendingRequests.pipe(distinctUntilChanged());
  }
}

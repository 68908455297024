import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerSelectionType} from '@app/file-picker/file-picker-selection/file-picker-selection-type';

export class ToggleSelection {
  static readonly type: string = '[FilepickerSelection] Toggle selection';

  constructor(public item: FilePickerItem) {
  }
}

export class SelectAll {
  static readonly type: string = '[FilepickerSelection] Select all';

  constructor(public items: FilePickerItem[]) {
  }
}

export class UnselectAll {
  static readonly type: string = '[FilepickerSelection] Unselect all';

  constructor(public items: FilePickerItem[]) {
  }
}

export class ResetSelection {
  static readonly type: string = '[FilepickerSelection] Reset selection';

  constructor() {
  }
}

export class InitSelection {
  static readonly type: string = '[FilepickerSelection] Init new selection';

  constructor(
    public selectionType: FilePickerSelectionType = 'multiple',
    public selectedFiles: FilePickerItem[] = [],
    public contentTypes: string[]
  ) {
  }
}

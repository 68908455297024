import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {EventSearch} from '@domain/event/event-search';
import {SenderEvent} from '@domain/event/sender-event';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Sender} from '@domain/sender/sender';
import {UserChooserSelection} from '@shared/sender-ui/user-chooser/user-chooser-selection';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService extends DomainService<SenderEvent, SenderEvent> {

  private static setsFormattedDateIfNotPresent(search: EventSearch): EventSearch {
    if (!search.from) {
      search.from = moment().format(EventSearch.DATE_TIME_FORMAT);
    }
    return search;
  }

  constructor(http: HttpClient,
              urlService: UrlService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/events';
  }

  /**
   * Create a new event.
   *
   * @param event The event data.
   * @return the created event.
   */
  createEvent(event: SenderEvent): Observable<SenderEvent> {
    return this.post(event);
  }

  /**
   * Gets the event by an id.
   *
   * @param eventId the event id.
   * @return the requested event.
   */
  getEvent(eventId: string): Observable<SenderEvent> {
    return this.get(eventId, {permissions: ['*']});
  }

  /**
   * Get events with a search request.
   *
   * @param search   the event search object
   * @param pageable the paging information
   * @return the requested events.
   */
  getEvents(search: EventSearch, pageable: Pageable): Observable<Page<SenderEvent>> {
    const params = EventService.setsFormattedDateIfNotPresent(search).toHttpParams();
    const permissions = ['canParticipate'];
    return this.getPage(pageable, {params, permissions});
  }

  /**
   * Update a event.
   *
   * @param event the event with the updated data.
   * @return the updated event.
   */
  updateEvent(event: SenderEvent): Observable<SenderEvent> {
    return this.put(event.id, event, {permissions: ['*']});
  }

  /**
   * Delete the event.
   *
   * @param eventId the event id.
   * @return an empty observable.
   */
  deleteEvent(eventId: string): Observable<void> {
    return this.delete(eventId);
  }

  /**
   * Retrieves upcoming events for the widget with the given ID.
   *
   * @param widgetId the ID of the corresponding widget
   * @param limit the maximum number of results
   * @param includeOngoing flag to indicate if ongoing events should be included
   * @param fetchType the type of events to fetch
   * @param host the corresponding event host
   * @return the requested events.
   */
  getUpcomingEvents(widgetId: string, limit: number, includeOngoing: boolean, fetchType: string, host: string): Observable<SenderEvent[]> {
    const headers = {handleErrors: 'true'};
    let params = new HttpParams()
      .append('limit', limit.toString())
      .append('includeOngoing', includeOngoing.toString())
      .append('fetchType', fetchType);
    if (host) {
      params = params.append('host', host);
    }

    return this.http.get<SenderEvent[]>(`/web/widgets/upcoming-events/${widgetId}`, {headers, params});
  }

  /**
   * Get the event admins.
   *
   * @param eventId The event id.
   * @return an observable with a page of event admins.
   */
  getEventAdmins(eventId: string): Observable<Page<Sender>> {
    const url = this.getUrl({eventId}, '/{eventId}/memberships?filters=role%3DADMIN');
    return this.http.get<Page<Sender>>(url);
  }

  /**
   * Update the event memberships.
   *
   * @param eventId   The event id.
   * @param selection The updated memberships.
   * @return an observable with no content.
   */
  updateEventMemberships(eventId: string, selection: UserChooserSelection): Observable<void> {
    const members = [
      ..._.map(selection.memberIds, id => ({id: id, typeName: UserChooserSelection.MEMBER_TYPE})),
      ..._.map(selection.memberGroupIds, id => ({id: id, typeName: UserChooserSelection.MEMBER_GROUP_TYPE})),
      ..._.map(selection.memberPageIds, id => ({id: id, typeName: UserChooserSelection.MEMBER_PAGE_TYPE})),
      ..._.map(selection.memberWorkspaceIds, id => ({id: id, typeName: UserChooserSelection.MEMBER_WORKSPACE_TYPE}))
    ];
    const url = this.getUrl({eventId: eventId}, '/{eventId}/memberships');
    return this.http.put<void>(url, {
      members: members
    });
  }
}

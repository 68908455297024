(function (angular) {
  'use strict';

  SenderModel.$inject = ["restResourceFactory", "$httpParamSerializer", "coyoEndpoints", "AppModel", "$state", "$stateParams", "Page"];
  angular
      .module('coyo.domain')
      .factory('SenderModel', SenderModel);

  /**
   * @ngdoc service
   * @name coyo.domain.SenderModel
   *
   * @description
   * Domain model representation of sender endpoint.
   *
   * @requires restResourceFactory
   * @requires restSerializer
   * @requires coyoEndpoints
   */
  function SenderModel(restResourceFactory, $httpParamSerializer, coyoEndpoints, AppModel, $state, $stateParams, Page) {
    var SenderModel = restResourceFactory({
      url: coyoEndpoints.sender.senders
    });

    /**
     * @ngdoc function
     * @name coyo.domain.SenderModel#searchWithFilter
     * @methodOf coyo.domain.SenderModel
     *
     * @description
     * General sender elastic search with filter. Url defines pre search selection of senders for specific use cases.
     *
     * @param {object} url use case specific url that allows backend to pre filter results based on use case
     * @param {string} term search term
     * @param {object} pageable The paging information. If not set an offset of 0 and a page size of 20 will be used.
     * @param {object} [filters] search filters
     * @param {string} [searchFields] list of fields to search in
     * @param {object} [aggregations] search aggregations
     * @params {object} optional additional request parameters
     *
     * @returns {promise} An $http promise
     */
    function _searchWithFilter(url, term, pageable, filters, searchFields, aggregations, params) {
      var _params = angular.extend({
        term: term ? term : undefined,
        filters: filters ? $httpParamSerializer(filters) : undefined,
        searchFields: searchFields ? searchFields.join(',') : undefined,
        aggregations: aggregations ? $httpParamSerializer(aggregations) : undefined
      }, pageable.getParams(), params ? params : {});
      return SenderModel.$get(url, _params).then(function (response) {
        return new Page(response, _params, {
          url: url,
          resultMapper: function (item) {
            return new SenderModel(item);
          }
        });
      });
    }

    // class members
    angular.extend(SenderModel, {

      /**
       * @ngdoc method
       * @name coyo.domain.SenderModel#getCurrentIdOrSlug
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Get the ID or slug of the current sender (based on the current state)
       *
       * @returns {string} ID or slug
       */
      getCurrentIdOrSlug: function () {
        var senderParam = _.get($state.current, 'data.senderParam');
        return $stateParams[senderParam];
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#searchManagedSendersWithFilter
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Sender elastic search with filter for all senders where the current user has managing rights.
       *
       * @param {string} term search term
       * @param {object=} pageable The paging information. If not set an offset of 0 and a page size of 20 will be used.
       * @param {object} filters search filters
       * @param {string[]?} searchFields list of fields to search in (default 'displayName')
       * @param {object} aggregations aggregations
       *
       * @returns {promise} An $http promise
       */
      searchManagedSendersWithFilter: function (term, pageable, filters, searchFields, aggregations) {
        var url = SenderModel.$url({}, 'search/managed');
        return _searchWithFilter(url, term, pageable, filters, searchFields, aggregations);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#searchSharingRecipientsWithFilter
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Sender elastic search with filter for all senders where the current user has sharing and posting permissions for.
       *
       * @param {string} term search term
       * @param {object=} pageable The paging information. If not set an offset of 0 and a page size of 20 will be used.
       * @param {object} filters search filters
       * @param {string[]?} searchFields list of fields to search in (default 'displayName')
       * @param {object} aggregations aggregations
       *
       * @returns {promise} An $http promise
       */
      searchSharingRecipientsWithFilter: function (term, pageable, filters, searchFields, aggregations) {
        var url = SenderModel.$url({}, 'search/sharing-recipients');
        return _searchWithFilter(url, term, pageable, filters, searchFields, aggregations);
      },

      /**
       * @ngdoc method
       * @name coyo.domain.SenderModel#searchActableSendersWithFilter
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Search allowed functional users for actions upon an entity, e.g. a timeline item, if the user is allowed to
       * act as sender.
       *
       * @param {string} entityType the entity type, e.g. 'timeline-item'
       * @param {string} entityId The entity id
       * @param {string} term search term
       * @param {object} pageable The paging information. If not set an offset of 0 and a page size of 20 will be used.
       * @param {object} [filters] search filters
       * @param {string} [searchFields] list of fields to search in
       * @param {object} [aggregations] search aggregations
       *
       * @returns {promise} A $http promise that resolves to a page of SenderModels
       */
      searchActableSendersWithFilter: function (entityType, entityId, term, pageable, filters, searchFields,
                                                aggregations) {
        var url = SenderModel.$url({}, 'search/actable-senders');
        var params = {type: entityType, id: entityId};
        return _searchWithFilter(url, term, pageable, filters, searchFields, aggregations, params);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#searchSendersWithFilter
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Sender elastic search with filter.
       *
       * @param {string} term search term
       * @param {object=} pageable The paging information. If not set an offset of 0 and a page size of 20 will be used.
       * @param {object} filters search filters
       * @param {string[]?} searchFields list of fields to search in (default 'displayName')
       * @param {object} aggregations aggregations
       *
       * @returns {promise} An $http promise
       */
      searchSendersWithFilter: function (term, pageable, filters, searchFields, aggregations) {
        var url = SenderModel.$url({}, 'search');
        return _searchWithFilter(url, term, pageable, filters, searchFields, aggregations);
      }
    });

    // instance members
    angular.extend(SenderModel.prototype, {

      /**
       * @ngdoc method
       * @name coyo.domain.SenderModel#getApps
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Returns all apps of this sender.
       *
       * @param {boolean} Boolean to tell the controller method to translate (false) or not translate the apps (true).
       *                  This parameter is optional and the default value is false.
       *
       * @returns {array} An array of all apps of this sender or an empty array if none could be found.
       */
      getApps: function (params) {
        params = _.isUndefined(params) ? {} : params;
        return AppModel.queryWithPermissions(params, {senderId: this.id},
            ['manage']);
      },

      /**
       * @ngdoc method
       * @name coyo.domain.SenderModel#getApp
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Returns the app with the given id of this sender.
       *
       * @param {string} appIdOrSlug The ID or slug of the app to return.
       * @param {object} queryParams An object of query parameter that should be passed to the server.
       * @returns {object} The app model with the given id.
       */
      getApp: function (appIdOrSlug, queryParams) {
        var params = SenderModel.applyPermissions(['*']);
        if (queryParams) {
          _.merge(params, queryParams);
        }
        return new AppModel({
          senderId: this.id,
          id: appIdOrSlug
        }).get(null, params);
      },

      /**
       * @ngdoc method
       * @name coyo.domain.SenderModel#addApp
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Adds an app to this sender.
       *
       * @param {string} key The key of the app to add.
       * @param {string} name The name of the app to add.
       * @returns {object} The newly created app model.
       */
      addApp: function (key, name) {
        return new AppModel({
          senderId: this.id,
          key: key,
          name: name
        }).create();
      },

      /**
       * @ngdoc method
       * @name coyo.domain.SenderModel#removeApp
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Removes an app from this sender.
       *
       * @param {string} appIdOrSlug The ID or slug of the app to remove.
       * @param {object} options are added as request params.
       * @returns {object} The deleted app model.
       */
      removeApp: function (appIdOrSlug, options) {
        var url = AppModel.$url({senderId: this.id, id: appIdOrSlug});
        return AppModel.$delete(url, options);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#updateNavigation
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Update the app navigation of this sender.
       *
       * @param {array} appNavigation The new app navigation.
       * @param {boolean} includeTranslationsUpdates A flag to process also the translations updates. Default false.
       * @returns {array} The app navigation of this sender.
       */
      updateNavigation: function (appNavigation, includeTranslationsUpdates) {
        var url = coyoEndpoints.sender.apps.updateAppNavigationTranslations.replace('{senderId}', this.id);
        return AppModel.$put(url, appNavigation, {}, {includeTranslationsUpdates: !!includeTranslationsUpdates});
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#reorderNavigation
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Reorders the apps of a app navigation group for this sender.
       *
       * @param {array} appNavigation The reordered app navigation
       * @returns {array} The app navigation of this sender.
       */
      reorderNavigation: function (appNavigation) {
        var url = coyoEndpoints.sender.apps.reorderAppNavigation.replace('{senderId}', this.id);
        return AppModel.$put(url, appNavigation.map(function (appNavigation) {
          return {apps: appNavigation.apps};
        }));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#deleteNavigation
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Deletes a app navigation group of this sender.
       *
       * @param {int} index The index of the group to delete
       * @returns {array} The app navigation of this sender.
       */
      deleteNavigationGroup: function (index) {
        var url = coyoEndpoints.sender.apps.deleteAppNavigationGroup.replace('{senderId}', this.id).replace('{index}', index);
        return AppModel.$delete(url);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#createNavigationGroup
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Creates a new app navigation group for this sender.
       *
       * @returns {array} The app navigation of this sender.
       */
      createNavigationGroup: function () {
        var url = coyoEndpoints.sender.apps.createAppNavigationGroup.replace('{senderId}', this.id);
        return AppModel.$post(url, {});
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#isSenderTranslated
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Provide the information whether multi language support is activated for the sender or not.
       *
       * @returns {boolean} True, if the multi language support is activated for the sender, otherwise false.
       */
      isSenderTranslated: function () {
        return !!(this.defaultLanguage) && Object.keys(this.translations).length !== 0;
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#getDefaultLanguage
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Provide the default language of the sender if multi language support is activated for the sender otherwise the
       * string 'NONE'.
       *
       * @returns {string} The default language, if the multi language support is activated for the sender, otherwise
       * 'NONE'.
       */
      getDefaultLanguage: function () {
        return this.defaultLanguage !== null ? this.defaultLanguage : 'NONE';
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#initTranslations
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Initialize the translations properties of the given view model based on the state of the sender.
       *
       * @param {object} viewModel The view model that should be initialized with the translation properties.
       */
      initTranslations: function (viewModel) {
        var vm = viewModel;
        vm.isSenderTranslated = this.isSenderTranslated();
        vm.defaultLanguage = this.getDefaultLanguage();
        // create translations with default values for all available languages
        var availableLanguages = _.concat(_.keys(this.translations), this.getDefaultLanguage());
        vm.languages = _.zipObject(availableLanguages, _.map(availableLanguages, function () {
          return {active: true, translations: {}};
        }));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.SenderModel#isTranslationRequired
       * @methodOf coyo.domain.SenderModel
       *
       * @description
       * Provide the information whether multi language elements should be shown or not, based on the state of the
       * sender and the given values.
       *
       * @param {object} availableTranslations The available translations.
       * @param {string} currentLanguage The current language the user have selected.
       * @param {string} language The affected language for which the requirement should be checked.
       * @returns {boolean} True, if the multi language elements should be shown or not.
       */
      isTranslationRequired: function (availableTranslations, currentLanguage, language) {
        if (this.getDefaultLanguage() === 'NONE') {
          return language === 'NONE';
        } else if (currentLanguage === language) {
          return true;
        } else {
          return (availableTranslations[language].translations && Object.keys(
              availableTranslations[language].translations).length !== 0);
        }
      }
    });

    return SenderModel;
  }

})(angular);

(function () {
  'use strict';

  FormEntriesController.$inject = ["app", "fields", "FormEntryModel", "formService", "Pageable", "fieldTypeRegistry", "modalService", "$state", "backendUrlService"];
  angular
      .module('coyo.apps.form')
      .controller('FormEntriesController', FormEntriesController);

  function FormEntriesController(app, fields, FormEntryModel, formService, Pageable, fieldTypeRegistry, modalService,
                                 $state, backendUrlService) {
    var vm = this;

    vm.$onInit = _init;
    vm.getConfig = getConfig;
    vm.getFieldValue = getFieldValue;
    vm.searchKeyPressed = searchKeyPressed;
    vm.sortOnField = sortOnField;
    vm.sortOnCreated = sortOnCreated;
    vm.sortOnAuthor = sortOnAuthor;
    vm.deleteEntry = deleteEntry;
    vm.showDetails = showDetails;
    vm.getDownloadUrl = getDownloadUrl;

    function searchKeyPressed() {
      _loadData();
    }

    function getConfig(field) {
      return fieldTypeRegistry.getRenderProperty(field.key);
    }

    function getFieldValue(entry, field) {
      var fieldEntry = formService.getFieldValue(entry, field.id);
      return fieldEntry ? fieldEntry.value : undefined;
    }

    function deleteEntry(entry) {
      entry.appId = vm.app.id;
      entry.senderId = vm.app.senderId;
      modalService.confirmDelete({
        title: 'APP.FORM.MODAL.DELETE.TITLE',
        text: 'APP.FORM.MODAL.DELETE.TEXT'
      }).result.then(function () {
        return entry.delete();
      }).then(function () {
        return _loadData();
      });
    }

    function sortOnCreated() {
      _sort('created', 'created');
    }

    function sortOnAuthor() {
      _sort('author', 'author.displayName.raw');
    }

    function sortOnField(field) {
      _sort(field.id, field.id);
    }

    function showDetails(entry) {
      $state.go('.details', {
        id: entry.id
      });
    }

    function getDownloadUrl(fullList) {
      var context = {senderId: app.senderId, appId: app.id};
      var sort = fullList ? undefined : _sortQuery();
      var query = fullList ? undefined : (vm.search || undefined);
      return backendUrlService.getUrl() + FormEntryModel.getDownloadUrl(sort, {query: query, hidden: fullList}, context);
    }

    function _sort(id, sortOn) {
      if (vm.loading) {
        return;
      }
      if (vm.sortConfig.id === id && vm.sortConfig.dir === 'DESC') {
        vm.sortConfig.id = undefined;
        vm.sortConfig.dir = undefined;
        vm.sortConfig.sortOn = undefined;
      } else if (vm.sortConfig.id === id && vm.sortConfig.dir === 'ASC') {
        vm.sortConfig.dir = 'DESC';
      } else {
        vm.sortConfig.id = id;
        vm.sortConfig.sortOn = sortOn;
        vm.sortConfig.dir = 'ASC';
      }
      _loadData();
    }

    function _sortQuery() {
      return vm.sortConfig.sortOn ? vm.sortConfig.sortOn + ',' + vm.sortConfig.dir : undefined;
    }

    function _loadData() {
      vm.loading = true;
      var context = {senderId: app.senderId, appId: app.id};
      var pageable = new Pageable((vm.currentPage ? vm.currentPage.number : 0), 20, _sortQuery());
      var query = {query: vm.search, _page: pageable.page, _pageSize: pageable.pageSize, _sort: pageable.sort};
      vm.currentQuery = query;
      return FormEntryModel.pagedQuery(pageable, query, context).then(function (result) {
        if (vm.currentQuery === query) {
          vm.currentPage = result;
          formService.initEntries(vm.currentPage.content, vm.fields);
        }
      }).finally(function () {
        if (vm.currentQuery === query) {
          vm.loading = false;
        }
      });
    }

    function _init() {
      vm.search = '';
      vm.sortConfig = {};
      vm.app = app;
      vm.fields = fields;

      _loadData();
    }
  }

})();

import {Injectable} from '@angular/core';
import {Plugin} from '@domain/plugin/plugin';
import {PluginService} from '@domain/plugin/plugin.service';
import {Widget} from '@domain/widget/widget';
import {UuidService} from '@shared/uuid/uuid.service';
import {DynamicWidgetConfigService, WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {PluginWidget} from '@widgets/plugin/plugin-widget';
import {ENTRY_POINT_ID, PluginWidgetSettings, SRC_ID} from '@widgets/plugin/plugin-widget-settings.model';
import {PluginWidgetSettingsComponent} from '@widgets/plugin/plugin-widget-settings/plugin-widget-settings.component';
import {PluginWidgetComponent} from '@widgets/plugin/plugin-widget/plugin-widget.component';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

/**
 * Service to retrieve and manage dynamic widgets derived from widget plugins.
 */
@Injectable()
export class PluginWidgetConfigService implements DynamicWidgetConfigService {
  private readonly allWidgetConfigs$: Observable<WidgetConfig<PluginWidget>[]>;

  constructor(pluginService: PluginService,
              private uuidService: UuidService) {
    this.allWidgetConfigs$ = pluginService.getAll()
      .pipe(map(plugins => this.widgetConfigsFromPlugins(plugins)))
      .pipe(shareReplay({bufferSize: 1, refCount: false}));
  }

  /**
   * Returns all dynamic widget configurations.
   *
   * @returns the widget configurations
   */
  get$(): Observable<WidgetConfig<Widget<WidgetSettings>>[]> {
    return this.allWidgetConfigs$;
  }

  private widgetConfigsFromPlugins(plugins: Plugin[]): WidgetConfig<PluginWidget, PluginWidgetSettings>[] {
    return plugins.map(plugin => ({
      key: PluginService.getWidgetKey(plugin.id),
      name: plugin.manifest.name,
      description: plugin.manifest.description,
      icon: 'zmdi-puzzle-piece',
      categories: WidgetCategory.PLUGIN,
      settings: this.hasSettings(plugin) ? {
        skipOnCreate: false,
        component: PluginWidgetSettingsComponent
      } : undefined,
      defaultSettings: this.defaultSettings(plugin),
      component: PluginWidgetComponent,
      whitelistExternal: false
    }));
  }

  private hasSettings(plugin: Plugin): boolean {
    return plugin.manifest.entryPoints.length > 1 || !!plugin.manifest.entryPoints[0].config?.length;
  }

  private defaultSettings(plugin: Plugin): () => Partial<PluginWidgetSettings> {
    return () => {
      const settings = {};
      settings[ENTRY_POINT_ID] = plugin.manifest.entryPoints[0].id;
      settings[SRC_ID] = this.uuidService.getUuid();
      return settings;
    };
  }
}

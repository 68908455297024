
export class LoadMore {
  static readonly type: string = '[New Colleagues] loadMore';

  constructor(public id: string) {}
}

export class Init {
  static readonly type: string = '[New Colleagues] init';
  constructor(public id: string) {}
}

export class SetLoading {
  static readonly type: string = '[New Colleagues] setLoading';
  constructor(public loading: boolean, public id: string) {}
}

export class Reset {
  static readonly type: string = '[New Colleagues] destroy';
  constructor(public id: string) {}
}

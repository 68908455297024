import {ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {RssEntry} from '@widgets/rss/rss-entry.model';
import {RssWidget} from '@widgets/rss/rss-widget';
import {RssWidgetService} from '@widgets/rss/rss-widget.service';
import {Observable, Subject} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';

/**
 * This widget displays entries of a RSS feed.
 */
@Component({
  templateUrl: './rss-widget.component.html',
  styleUrls: ['./rss-widget.component.scss']
})
export class RssWidgetComponent extends WidgetComponent<RssWidget> implements OnInit, OnChanges {

  entries$: Observable<RssEntry[]>;
  private change$: Subject<void> = new Subject<void>();

  constructor(private rssWidgetService: RssWidgetService,
              cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.entries$ = this.change$
      .pipe(startWith('')) // we need a "value" here to trigger the first execution
      .pipe(switchMap(() => this.loadRssEntries()));
  }

  /**
   * Retrieves the entries of a RSS feed.
   *
   * @return The RSS entries as an observable
   */
  private loadRssEntries(): Observable<RssEntry[]> {
    if (!this.widget.id || this.editMode) {
      return this.rssWidgetService.getEntriesForPreview(
        this.widget.settings.rssUrl,
        this.widget.settings.displayImage,
        this.widget.settings.maxCount,
        this.widget.settings.userName,
        this.widget.settings._encrypted_password
      );
    } else {
      return this.rssWidgetService.getEntries(this.widget.id);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.change$.next();
  }
}

<div class="form-wrapper" [class.open]="isOpen">
  <form class="form-horizontal" role="form"
        [attr.aria-label]="'MODULE.TIMELINE.FORM.ARIA' | translate"
        [formGroup]="timelineForm"
        (ngSubmit)="submit()"
        [coyoTourIf]="isPersonal"
        coyoTourStep="form"
        coyoTourTopic="timeline"
        [coyoTourOrder]="220"
        [coyoTourTitle]="'TOUR.TIMELINE.FORM.TITLE' | translate"
        [coyoTourText]="'TOUR.TIMELINE.FORM.TEXT' | translate"
        data-test="timeline-form">
    <div class="form-content">
      <div class="form-input">
        <coyo-sender-avatar [sender]="selectedSender" [noLink]="true"></coyo-sender-avatar>
        <label class="sr-only" aria-hidden="true" for="message"
               translate="MODULE.TIMELINE.FORM.INPUT.ARIA"></label>
        <div class="text-area-wrapper" (click)="message.focus()">
          <textarea class="form-control"
                    id="message"
                    formControlName="message"
                    #message
                    coyoMention
                    autosize rows="1"
                    (focus)="onFocus()"
                    [placeholder]="'MODULE.TIMELINE.FORM.PLACEHOLDER' | translate"
                    coyoExtractUrls
                    (urls)="urlSubject$.next($event)"
                    data-test="textarea-timeline-post"></textarea>
        </div>
        <button mat-icon-button type="button" size="small" class="form-close"
                (click)="reset()">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
        <coyo-help [modalTitle]="'MODULE.TIMELINE.FORM.HELP.MODAL.TITLE' | translate"
                   [modalText]="'MODULE.TIMELINE.FORM.HELP.MODAL.TEXT' | translate"
                   [modalAria]="'MODULE.TIMELINE.FORM.HELP.ARIA' | translate"></coyo-help>
        <div class="form-attachments">
          <coyo-preview-list [urls]="urlsAcc$ | async" formControlName="webPreviews"></coyo-preview-list>
          <coyo-attachment-list [selectedAttachments]="attachments$ | async"
                                (attachmentRemoved)="removeAttachment($event)"></coyo-attachment-list>
        </div>
      </div>
    </div>
    <div class="form-actions">
      <div class="form-action-buttons">
        <coyo-emoji-picker [input]="message" class="hidden-xs" data-test="timeline-emoji-picker"></coyo-emoji-picker>
        <coyo-attachment-btn id="allSelectedAttachments"
                             formControlName="allSelectedAttachments"
                             [sender]="selectedSender"
                             [context]="context"
                             [timelineType]="type"
                             [yPosition]="(isXs$ | async) ? 'above' : 'below'"
                             data-test="timeline-attachment-btn"></coyo-attachment-btn>
        <coyo-sticky-btn id="stickyExpiry"
                         formControlName="stickyExpiry"
                         [yPosition]="(isXs$ | async) ? 'above' : 'below'"
                         *ngxPermissionsOnly="'CREATE_STICKY_TIMELINE_ITEM'"
                         data-test="timeline-sticky-btn"></coyo-sticky-btn>
        <coyo-lock-btn id="restricted"
                       formControlName="restricted"
                       *ngxPermissionsOnly="'CREATE_RESTRICTED_TIMELINE_ITEM'"
                       data-test="timeline-lock-btn"></coyo-lock-btn>
        <coyo-functional-user-chooser id="author"
                                      formControlName="author"
                                      *ngIf="canActAsSender$ | async"
                                      [author]="selectedSender"
                                      [recipientId]="context.id"
                                      (change)="changeSelectedSender($event)"
                                      headline="MODULE.TIMELINE.FORM.POST_AS"></coyo-functional-user-chooser>
      </div>
      <button mat-flat-button type="submit" size="small" color="primary"
              [disabled]="!timelineForm.valid || (submitting$ | async)"
              [attr.aria-label]="'MODULE.TIMELINE.SUBMIT.ARIA' | translate"
              data-test="button-timeline-submit">{{ 'MODULE.TIMELINE.SUBMIT' | translate }}</button>
    </div>
  </form>
</div>

import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {MaterialModule} from '@shared/material/material.module';
import {SelectFileComponent} from '@shared/select-file/select-file.component';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {SelectFileButtonComponent} from './select-file-button/select-file-button.component';
import './select-file.component.downgrade';

/**
 * Module exporting a select file component.
 */
@NgModule({
  imports: [
    MaterialModule,
    TranslateModule,
    CoyoCommonsModule,
    UpgradeModule
  ],
  declarations: [
    SelectFileComponent,
    SelectFileButtonComponent
  ],
  exports: [
    SelectFileComponent,
    SelectFileButtonComponent
  ]
})
export class SelectFileModule {
}

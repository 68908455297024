import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DownloadModule} from '@shared/download/download.module';
import {FileModule} from '@shared/files/file.module';
import {PdfModule} from '@shared/pdf/pdf.module';
import {EngageDownloadHintComponent} from '@shared/preview/engage-download-hint/engage-download-hint.component';
import {ExternalLinkPreviewConfirmationDialogComponent} from '@shared/preview/external-link-preview-confirmation-dialog/external-link-preview-confirmation-dialog.component';
// tslint:disable-next-line:max-line-length
import {FilePreviewAnimationLoaderComponent} from '@shared/preview/file-preview/animation/file-preview-animation-loader/file-preview-animation-loader.component';
import {ImageContainerComponent} from '@shared/preview/file-preview/image-container/image-container.component';
import {FileIntegrationLinkComponent} from './file-integration-link/file-integration-link.component';
import './file-integration-link/file-integration-link.component.downgrade';
import {FileNonPreviewComponent} from './file-non-preview/file-non-preview.component';
import {FilePreviewListComponent} from './file-preview-list/file-preview-list.component';
import './file-preview-list/file-preview-list.component.downgrade';
import {FileImagePreviewComponent} from './file-preview/file-image-preview/file-image-preview.component';
import {FilePreviewComponent} from './file-preview/file-preview.component';
import './file-preview/file-preview.component.downgrade';
import {FileVideoPreviewComponent} from './file-preview/file-video-preview/file-video-preview.component';
import {NoPreviewAvailableComponent} from './file-preview/no-preview-available/no-preview-available.component';
import {LinkPreviewListComponent} from './link-preview-list/link-preview-list.component';
import {LinkPreviewComponent} from './link-preview/link-preview.component';
import {PreviewListComponent} from './preview-list/preview-list.component';
import {VideoPreviewListComponent} from './video-preview-list/video-preview-list.component';
import {VideoPreviewComponent} from './video-preview/video-preview.component';

/**
 * Module for all preview related components and services.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    DownloadModule,
    FileModule,
    PdfModule,
    TranslateModule
  ],
  declarations: [
    FileImagePreviewComponent,
    FileIntegrationLinkComponent,
    FileNonPreviewComponent,
    FilePreviewAnimationLoaderComponent,
    FilePreviewComponent,
    FilePreviewListComponent,
    FileVideoPreviewComponent,
    ImageContainerComponent,
    LinkPreviewComponent,
    LinkPreviewListComponent,
    NoPreviewAvailableComponent,
    PreviewListComponent,
    VideoPreviewComponent,
    VideoPreviewListComponent,
    ExternalLinkPreviewConfirmationDialogComponent,
    EngageDownloadHintComponent
  ],
  exports: [
    FilePreviewComponent,
    FileImagePreviewComponent,
    FilePreviewListComponent,
    FileIntegrationLinkComponent,
    LinkPreviewComponent,
    LinkPreviewListComponent,
    PreviewListComponent,
    VideoPreviewComponent,
    VideoPreviewListComponent,
    FileVideoPreviewComponent
  ],
  entryComponents: [
    FileIntegrationLinkComponent,
    FilePreviewComponent,
    FilePreviewListComponent,
    LinkPreviewListComponent,
    VideoPreviewListComponent
  ]
})
export class PreviewModule {
}

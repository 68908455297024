import {Directive, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

/**
 * Renders an image from the file library as a picture element. Supports rendering different image sizes for
 * different screen sizes and also checks whether the display supports retina.
 *
 * This component has been upgraded from angularjs.
 */
@Directive({
  selector: 'coyo-image-reference' // tslint:disable-line
})
export class ImageReferenceDirective extends UpgradeComponent {

  /**
   * The file id.
   */
  @Input() fileId: string;

  /**
   * The id of the file libraries sender.
   */
  @Input() senderId: string;

  /**
   * The file size definitions.
   */
  @Input() sizeDefinitions: {[key: string]: string};

  constructor(elementRef: ElementRef, injector: Injector) {
    super('coyoImageReference', elementRef, injector);
  }
}

/**
 * Module providing the button widget
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CoreModule} from '@core/core.module';
import {NgOptionHighlightModule} from '@coyo/ng-option-highlight';
import {TranslateModule} from '@ngx-translate/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {ButtonsModule} from 'ngx-bootstrap';
import {WIDGET_CONFIGS} from '../api/widget-config';
import {BLOG_ARTICLE_WIDGET} from './blog-article-widget-config';
import {BlogArticleWidgetSettingsComponent} from './blog-article-widget-settings/blog-article-widget-settings.component';
import {BlogArticleWidgetComponent} from './blog-article-widget/blog-article-widget.component';

@NgModule({
  imports: [
    ButtonsModule,
    CommonModule,
    CoreModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    TranslateModule,
    UpgradeModule,
    HelpModule,
    SenderUIModule,
    NgOptionHighlightModule
  ],
  declarations: [
    BlogArticleWidgetComponent,
    BlogArticleWidgetSettingsComponent
  ],
  entryComponents: [
    BlogArticleWidgetComponent,
    BlogArticleWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: BLOG_ARTICLE_WIDGET, multi: true}
  ]
})
export class BlogArticleWidgetModule {
}

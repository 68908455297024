import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Overlay} from '@angular/cdk/overlay';
import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '@core/auth/auth.service';
import {MatDialogSize} from '@coyo/ui';
import {EntityId} from '@domain/entity-id/entity-id';
import {Widget} from '@domain/widget/widget';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {DeleteConfirmationService} from '@shared/dialog/delete-confirmation/delete-confirmation.service';
import {ResizedEvent} from '@shared/resize-event/resized-event';
import {WidgetChooserComponent} from '@widgets/api/widget-chooser/widget-chooser.component';
import {WidgetConfig} from '@widgets/api/widget-config';
import {WidgetEditService} from '@widgets/api/widget-edit/widget-edit.service';
import {WidgetEnabledConfig, WidgetRegistryService} from '@widgets/api/widget-registry/widget-registry.service';
import {WidgetRenderStyle} from '@widgets/api/widget-render-style';
import {WidgetSettingsModalComponent} from '@widgets/api/widget-settings-modal/widget-settings-modal.component';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {ClipboardStateModel, EditScopeStateModel, SlotId, WidgetStateModel} from '@widgets/api/widget-state.model';
import {WidgetVisibilityService} from '@widgets/api/widget-visibility/widget-visibility.service';
import {
  AddWidgetToSlot,
  CancelEditMode,
  CleanupSlotState,
  CutWidget,
  InitializeWidgetSlot,
  MoveWidget,
  MoveWidgetDown,
  MoveWidgetUp,
  PasteWidget,
  RemoveWidgetFromSlot,
  RenameSlotState,
  ToggleMobileWidgetVisibility,
  ToggleWidgetTitleEdit,
  UpdateParent,
  UpdateWidgetSettings
} from '@widgets/api/widget.actions';
import {WidgetState} from '@widgets/api/widget.state';
import {getWidgetSlotStateAccessor} from '@widgets/api/widget.state-operators';
import * as _ from 'lodash';
import {NgxPermissionsService} from 'ngx-permissions';
import {combineLatest, from, Observable, of, Subject, zip} from 'rxjs';
import {distinctUntilChanged, filter, first, map, pluck, switchMap, takeUntil} from 'rxjs/operators';

interface WidgetWithConfig<Settings extends WidgetSettings> {
  widget: Widget<Settings>;
  _config: WidgetEnabledConfig;
  canManage: boolean;
  hasPanel: boolean;
  hasPanelBody: boolean;
}

@Component({
  selector: 'coyo-widget-slot',
  templateUrl: './widget-slot.component.html',
  styleUrls: ['./widget-slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetSlotComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The widget layout name
   */
  @Input() name: string;

  /**
   * The widget layout language
   */
  @Input() language: string;

  /**
   * The widget layout name
   */
  @Input() parent: EntityId;

  /**
   * The render style
   */
  @Input() renderStyle: WidgetRenderStyle = 'panel';

  /**
   * Is the slot used inside a layout or standalone.
   */
  @Input() embedded: boolean = false;

  /**
   * Should advanced controls be hidden
   */
  @Input() simpleMode: boolean = false;

  /**
   * The edit scope of the layout, needed for dispatching state changing actions. Usually 'global' or an app-id.
   */
  @Input() editScope: string = 'global';

  /**
   * The current user can manage this layout.
   * If undefined (= no local sender permission involved) the global permission will be checked.
   */
  @Input() canManage: boolean;

  /**
   * A revision based slot (e.g. in wiki articles) will do nothing when the name changes (because the layout handles everything).
   */
  @Input() revisionBased: boolean = false;

  /**
   * Flag if this slot is visible on mobile.
   */
  @Input() visibleOnMobile: boolean = true;

  state$: Observable<WidgetWithConfig<WidgetSettings>[]>;
  hiddenWidgets$: Observable<string[]>;
  editMode$: Observable<boolean>;
  editTitleId$: Observable<string>;
  clipboard$: Observable<ClipboardStateModel>;
  slotId: Observable<SlotId>;
  size$: Subject<ResizedEvent> = new Subject<ResizedEvent>();
  sizeClass$: Observable<string>;
  hover$: Subject<string> = new Subject<string>(); // TODO: IE only

  canManage$: Observable<boolean>;

  private onDestroy: Subject<void> = new Subject();

  constructor(private store: Store,
              private widgetRegistry: WidgetRegistryService,
              private widgetEditService: WidgetEditService,
              private widgetVisibilityService: WidgetVisibilityService,
              private permissionService: NgxPermissionsService,
              private dialog: MatDialog,
              private authService: AuthService,
              private actions$: Actions,
              private deleteConfirmationService: DeleteConfirmationService,
              private overlay: Overlay) {
  }

  ngOnInit(): void {
    this.hiddenWidgets$ = this.widgetVisibilityService.getHiddenIds$();
    this.sizeClass$ = this.size$.asObservable()
      .pipe(map(size => this.toSizeClass(size.newWidth)))
      .pipe(distinctUntilChanged());

    if (!this.embedded) {
      this.actions$.pipe(
        ofActionSuccessful(CancelEditMode),
        filter((action: CancelEditMode) => action.editScope === this.editScope),
        takeUntil(this.onDestroy)
      ).subscribe(() => {
        this.store.dispatch(new InitializeWidgetSlot(this.getSlotId(), this.parent, null, true));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.canManage$ || (changes.canManage && changes.canManage.currentValue !== changes.canManage.previousValue)) {
      this.canManage$ = from(this.permissionService.hasPermission('MANAGE_GLOBAL_WIDGETS'))
        .pipe(map(globalPermission => this.canManage
          || (this.canManage === undefined && globalPermission)));
    }
    if (!this.editMode$ || changes.editScope) {
      const editModePipe$: Observable<EditScopeStateModel> = this.store.select(WidgetState)
        .pipe(
          pluck('editScopes'),
          pluck(this.editScope)
        );
      this.editMode$ = editModePipe$.pipe(
        map(editScope => editScope ? editScope.editMode : false),
        distinctUntilChanged()
      );
      this.editTitleId$ = editModePipe$.pipe(
        map(editScope => editScope ? editScope.titleInputDisplayedForWidgetId : ''),
        distinctUntilChanged()
      );
      this.clipboard$ = editModePipe$.pipe(
        map(editScope => editScope ? editScope.clipboard : null),
        distinctUntilChanged()
      );
    }

    if (!this.state$ || (changes.name && changes.name.previousValue !== changes.name.currentValue)) {
      this.state$ = this.store
        .select<WidgetStateModel>(WidgetState)
        .pipe(
          map(state => {
            const slotStateAccessor = getWidgetSlotStateAccessor({
              name: this.name,
              language: this.language
            });
            return state.slots[slotStateAccessor];
          }),
          filter(widgets => !!widgets),
          switchMap(widgets => zip(
            this.widgetRegistry.getAllByKeys(widgets.map((widget: Widget<WidgetSettings>) => widget.key)),
            this.canManage$,
            this.authService.getUser()
            ).pipe(map(([configMapByKey, canManage, user]) =>
              widgets.map((widget: Widget<WidgetSettings>) => ({
                  widget,
                  _config: configMapByKey[widget.key],
                  canManage: canManage && (!configMapByKey[widget.key].moderatorsOnly || user.moderatorMode),
                  hasPanel: !this.getRenderOption(configMapByKey[widget.key], 'noPanel') && this.renderStyle === 'panels',
                  hasPanelBody: !this.getRenderOption(configMapByKey[widget.key], 'noPanel') && (this.renderStyle === 'panels' || this.renderStyle === 'panel')
                })
              )))
          )
        );
      this.slotId = of(this.getSlotId());
    }

    if (changes.name && (changes.name.isFirstChange() || changes.name.previousValue !== changes.name.currentValue)) {
      const previousName = changes.name.previousValue;
      const newName = changes.name.currentValue;
      this.unregister(previousName);
      this.register(newName);

      if (!this.revisionBased && !changes.name.isFirstChange()) {
        this.store.dispatch(new RenameSlotState(this.editScope, previousName, newName));
      } else if (!this.embedded) {
        this.store.dispatch(new InitializeWidgetSlot(this.getSlotId(), this.parent));
      }
    }

    if (changes.parent && !changes.parent.isFirstChange()) {
      this.store.dispatch(new UpdateParent(this.editScope, changes.parent.currentValue, {
        name: this.name,
        language: this.language
      }));
    }
  }

  isWidgetHidden(widget: Widget<WidgetSettings>): Observable<boolean> {
    return combineLatest([
        this.hiddenWidgets$.pipe(map(hiddenWidgets => hiddenWidgets.includes(widget.id || widget.tempId))),
        this.editMode$
      ]
    ).pipe(map(([isHidden, isEditMode]) => isHidden && !isEditMode));
  }

  ngOnDestroy(): void {
    this.unregister(this.name);
    this.onDestroy.next();
    this.onDestroy.complete();

    if (!this.embedded) {
      this.store.dispatch(new CleanupSlotState(this.getSlotId()));
    }
  }

  getSlotId(): SlotId {
    return {
      name: this.name,
      language: this.language
    };
  }

  addWidget(): boolean {
    const dialogRef = this.dialog.open(WidgetChooserComponent, {
      width: MatDialogSize.Medium,
      // This strategy needs to be used to make drag and drop work inside the dialog.
      // Issue link: https://github.com/angular/components/issues/15880
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        widget: {
          isNew: () => true, settings: {}
        },
        parentType: this.parent?.typeName
      },
    });
    dialogRef.afterClosed().pipe(filter(value => !!value)).subscribe(({config, settings}: {
      config: WidgetConfig<any>,
      settings: WidgetSettings
    }) => {
      this.store.dispatch(new AddWidgetToSlot(this.getSlotId(), config, settings, this.parent, this.editScope));
    });
    return false;
  }

  editTitle(widget: Widget<WidgetSettings>): boolean {
    this.store.dispatch(new ToggleWidgetTitleEdit(this.editScope, widget.id || widget.tempId));
    return false;
  }

  cutWidget(widget: Widget<WidgetSettings>): boolean {
    this.store.dispatch(new CutWidget(this.editScope, this.getSlotId(), widget.id || widget.tempId));
    return false;
  }

  pasteWidget(): boolean {
    this.store.dispatch(new PasteWidget(this.editScope, this.getSlotId()));
    return false;
  }

  moveWidget(event: CdkDragDrop<SlotId>): void {
    this.store.dispatch(new MoveWidget(event.item.data,
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
      this.editScope
    ));
  }

  toggleMobile(widget: Widget<WidgetSettings>): boolean {
    this.store.dispatch(new ToggleMobileWidgetVisibility(this.getSlotId(), widget.id || widget.tempId, this.editScope));
    return false;
  }

  editWidget(item: WidgetWithConfig<any>): boolean {
    const dialogRef = this.dialog.open(WidgetSettingsModalComponent, {
      // This strategy needs to be used to make drag and drop work inside the dialog.
      // Issue link: https://github.com/angular/components/issues/15880
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        config: item._config,
        widget: _.cloneDeep<Widget<any>>(item.widget)
      }
    });

    dialogRef.afterClosed()
      .pipe(filter(value => !!value))
      .subscribe(result => {
        const updateWidgetSettings = new UpdateWidgetSettings(this.getSlotId(), item.widget.id || item.widget.tempId, result.settings, this.editScope);
        return this.store.dispatch(updateWidgetSettings);
      });
    return false;
  }

  deleteWidget(widget: Widget<WidgetSettings>): boolean {
    this.deleteConfirmationService.open(
      'WIDGETS.MODAL.REMOVE.TITLE',
      'WIDGETS.MODAL.REMOVE.TEXT',
      'WIDGETS.MODAL.REMOVE.CONFIRM',
      'WIDGETS.MODAL.REMOVE.CANCEL'
    ).subscribe(doDelete => {
      if (doDelete) {
        this.store.dispatch(new RemoveWidgetFromSlot(this.getSlotId(), widget, this.editScope));
      }
    });

    return false;
  }

  moveUp(widget: Widget<WidgetSettings>): boolean {
    this.store.dispatch(new MoveWidgetUp(this.editScope, this.getSlotId(), widget.id || widget.tempId));
    return false;
  }

  moveDown(widget: Widget<WidgetSettings>): boolean {
    this.store.dispatch(new MoveWidgetDown(this.editScope, this.getSlotId(), widget.id || widget.tempId));
    return false;
  }

  getRenderOption(widgetConfig: WidgetEnabledConfig, option: string):  boolean {
    return !!widgetConfig.renderOptions?.[this.renderStyle]?.[option];
  }

  trackWidget(index: number, item: WidgetWithConfig<WidgetSettings>): string {
    if (!item) {
      return null;
    }
    return item.widget.id || item.widget.tempId;
  }

  hasTitle(widget: WidgetWithConfig<WidgetSettings>): boolean {
    return !!(widget._config && widget._config.defaultTitle);
  }

  renameWidget(widget: Widget<WidgetSettings>, newTitle: string): boolean {
    this.store.dispatch(new UpdateWidgetSettings(this.getSlotId(), widget.id || widget.tempId, {
      ...widget.settings,
      _title: newTitle
    }, this.editScope));
    return false;
  }

  register(name: string = this.name): void {
    this.canManage$.pipe(first()).subscribe(canManage => {
      if (canManage) {
        this.widgetEditService.register(name, this.editScope);
      }
    });
  }

  unregister(name: string = this.name): void {
    this.widgetEditService.unregister(name);
  }

  private toSizeClass(width: number): string {
    if (width <= 271) {
      return 'widget-slot-sm';
    } else if (width <= 576) {
      return 'widget-slot-md';
    }
    return 'widget-slot-lg';
  }
}

<div class="card">
  <div class="card-body">
    <h2 class="mat-headline">{{'MODULE.ACCOUNT.HASHTAG_SUBSCRIPTION_ADMINISTRATION.NAME' | translate}}</h2>
    <mat-form-field>
      <mat-label>{{'MODULE.ACCOUNT.HASHTAG_SUBSCRIPTION_ADMINISTRATION.LABEL' | translate}}</mat-label>
      <ng-select class="mat-ng-select" appearance="outline" [formControl]="hashtags"
                 [addTag]="submit"
                 [multiple]="true"
                 [selectOnTab]="true"
                 [isOpen]="false"
                 [clearable]="false"
                 (remove)="remove($event.value)"
                 [placeholder]="'MODULE.ACCOUNT.HASHTAG_SUBSCRIPTION_ADMINISTRATION.PLACEHOLDER' | translate"
                 [attr.aria-label]="'MODULE.ACCOUNT.HASHTAG_SUBSCRIPTION_ADMINISTRATION.LABEL' | translate"
                 appendTo="body">
        <ng-template let-clear="clear" let-item="item" ng-label-tmp>
          <button mat-flat-button size="small"
                  [attr.aria-label]="('MODULE.ACCOUNT.HASHTAG_SUBSCRIPTION_ADMINISTRATION.REMOVE' | translate) + ' ' + item"
                  (click)="clear(item)"
                  (keydown.enter)="clear(item)">
            <span class="ng-value-icon m-0"></span>
          </button>
          <span class="ng-value-label">{{item}}</span>
        </ng-template>
      </ng-select>
      <mat-hint>{{'MODULE.ACCOUNT.HASHTAG_SUBSCRIPTION_ADMINISTRATION.DESCRIPTION' | translate}}</mat-hint>
    </mat-form-field>
  </div>
</div>

import {Inject, Injectable} from '@angular/core';
import {EventDistributionService} from '@app/analytics/event-distributor/event-distribution.service';
import {EventTypes} from '@app/analytics/event-types';
import {UserActivityEvent} from '@app/analytics/user-activity/user-activity-event';
import {WINDOW} from '@root/injection-tokens';
import {PageVisibilityService} from '@shared/notifications/page-visibility/page-visibility.service';
import {filter} from 'rxjs/operators';

/**
 * Service that tracks the user activity on a tab
 */
@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  static readonly THRESHOLD_SECONDS: number = 3;

  constructor(private pageVisibilityService: PageVisibilityService,
              private eventDistributionService: EventDistributionService,
              @Inject(WINDOW) private window: Window) {
  }

  private tabFocused: Date | null = null;

  static secondsBetweenDates(firstDate: Date, secondDate: Date): number {
    const diff = firstDate.getTime() - secondDate.getTime();
    return Math.abs(diff / 1000);
  }

  /**
   * Initializes the service
   */
  init(): void {
    this.pageVisibilityService
      .isVisible$()
      .pipe(filter(() => !this.isEngageWebView()))
      .subscribe(visible => {
        if (visible) {
          this.handlePageVisible();
        } else {
          this.handlePageHidden();
        }
      });
  }

  private handlePageVisible(): void {
    this.tabFocused = new Date();
  }

  private handlePageHidden(): void {
    const tabUnfocused = new Date();
    if (!this.tabFocused) {
      return;
    }
    const userActivityInSeconds = UserActivityService.secondsBetweenDates(this.tabFocused, tabUnfocused);
    if (userActivityInSeconds >= UserActivityService.THRESHOLD_SECONDS) {
      const event: UserActivityEvent = {
        time: tabUnfocused.toISOString(),
        payload: {
          client: 'web',
          from: this.tabFocused.toISOString(),
          to: tabUnfocused.toISOString()
        }
      };
      this.eventDistributionService.addEvent(EventTypes.USER_ACTIVITY, event);
    }
    this.tabFocused = null;
  }

  private isEngageWebView(): boolean {
    return this.window.location.pathname.startsWith('/engage');
  }
}

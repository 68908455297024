(function (angular) {
  'use strict';

  MessagingChannelsController.$inject = ["$scope", "$rootScope", "$injector", "authService", "socketService", "Pageable", "MessageChannelModel", "browserNotificationsService", "$translate", "$timeout", "socketReconnectDelays", "$q"];
  angular
      .module('coyo.messaging')
      .controller('MessagingChannelsController', MessagingChannelsController);

  function MessagingChannelsController($scope, $rootScope, $injector, authService, socketService, Pageable, MessageChannelModel,
                                       browserNotificationsService, $translate, $timeout,
                                       socketReconnectDelays, $q) {
    var vm = this;
    vm.$onInit = onInit;
    vm.loadMore = loadMore;

    var pageSize = 25;

    function _initChannels(hasPermission) {
      if (hasPermission) {
        vm.channels = [];
        vm.currentPage = null;
        loadMore();

        var unsubscribeUpdatedFn = socketService.subscribe('/user/topic/messaging', _handleChannelUpdated, 'channelUpdated');
        var unsubscribeStatusUpdatedFn = socketService.subscribe('/user/topic/messaging', _handleChannelStatusUpdated, 'channelStatusUpdated');
        var unsubscribeLeaveFn = socketService.subscribe('/user/topic/messaging', _handleChannelLeave, 'channelLeave');
        var unsubscribeNewMessageFn = socketService.subscribe('/user/topic/messaging', _handleNewMessage, 'messageCreated');
        var unsubscribeUpdatedMessageFn = socketService.subscribe('/user/topic/messaging', _handleUpdatedMessage, 'messageUpdated');
        var unsubscribeReconnectFn = $rootScope.$on('socketService:reconnected', _reconnectHandler);
        var unsubscribeResetUnreadCountFn = $rootScope.$on('messaging:resetUnreadCount', _resetUnreadCount);

        $scope.$on('$destroy', unsubscribeUpdatedFn);
        $scope.$on('$destroy', unsubscribeStatusUpdatedFn);
        $scope.$on('$destroy', unsubscribeLeaveFn);
        $scope.$on('$destroy', unsubscribeNewMessageFn);
        $scope.$on('$destroy', unsubscribeUpdatedMessageFn);
        $scope.$on('$destroy', unsubscribeReconnectFn);
        $scope.$on('$destroy', unsubscribeResetUnreadCountFn);
      }
    }

    function _handleChannelUpdated(event) {
      $scope.$apply(function () {
        var messageChannel = _.get(event, 'content.messageChannel');
        var filtered = _.filter(vm.channels, {id: messageChannel.id});
        var lastNotification = _.get(messageChannel, 'lastCreatedMessage.data.notification');
        if (lastNotification) {
          _.set(messageChannel, 'lastCreatedMessage.data.message',
              $translate.instant(lastNotification.messageKey, lastNotification.messageArgs));
        }
        // Update list
        if (filtered.length === 1) {
          angular.extend(filtered[0], messageChannel);
        } else {
          _addChannelToState(new MessageChannelModel(messageChannel));
        }

        // Update current channel
        if (vm.msgSidebar.currentChannel && vm.msgSidebar.currentChannel.id === messageChannel.id) {
          angular.extend(vm.msgSidebar.currentChannel, messageChannel);
        }
      });
    }

    function _handleChannelStatusUpdated(event) {
      $scope.$apply(function () {
        var channelId = _.get(event, 'content.channelId');
        var filtered = _.filter(vm.channels, {id: channelId});
        if (filtered.length === 1) {
          filtered[0].unreadCount = event.content.unreadCount;
          var currentMember = _.find(filtered[0].members, ['user.id', vm.currentUser.id]);
          var muted = _.isNil(currentMember) ? true : currentMember.muted;
          $injector.get('ngxTabNotificationService').set('messaging-' + filtered[0].id, filtered[0].unreadCount, muted);
          $rootScope.$emit('messaging-channel:' + channelId + ':updated');
        }
      });
    }

    function _handleChannelLeave(event) {
      $scope.$apply(function () {
        vm.channels = _.reject(vm.channels, {id: event.content});

        if (vm.msgSidebar.currentChannel && vm.msgSidebar.currentChannel.id === event.content) {
          vm.msgSidebar.home();
        }
      });
    }

    function _handleUpdatedMessage(event) {
      $scope.$apply(function () {
        var channelId = _.get(event, 'content.channelId');
        var messageId = _.get(event, 'content.id');
        _getChannelById(channelId).then(function (channel) {
          if (_.get(channel, 'lastCreatedMessage.id') === messageId) {
            _.set(channel, 'lastCreatedMessage.data', event.content.data);
            $rootScope.$emit('messaging-channel:' + channelId + ':updated');
          }
        });
      });
    }

    function _handleNewMessage(event) {
      if (angular.isUndefined(event.content)) {
        return;
      }
      $scope.$apply(function () {
        var channelId = _.get(event, 'content.channelId');
        _getChannelById(channelId).then(function (channel) {
          if (!_.find(vm.channels, {id: channelId})) {
            _addChannelToState(channel);
          }

          var lastMessage = _.get(event, 'content.data.message');
          var lastNotification = _.get(event, 'content.data.notification');
          var lastAttachments = _.get(event, 'content.attachments', []);
          if (lastMessage) {
            _.set(channel, 'lastCreatedMessage.data.message', lastMessage);
          } else if (lastNotification) {
            _.set(channel, 'lastCreatedMessage.data.message',
                $translate.instant(lastNotification.messageKey, lastNotification.messageArgs));
          } else if (lastAttachments.length > 0) {
            var attachmentMessage = _.get(vm.currentUser, 'id') === _.get(event, 'content.author.id')
              ? $translate.instant('MODULE.MESSAGING.MESSAGES.SENT_FILE_OWN', {count: lastAttachments.length})
              : $translate.instant('MODULE.MESSAGING.MESSAGES.SENT_FILE', {count: lastAttachments.length});
            _.set(channel, 'lastCreatedMessage.data.message', attachmentMessage);
          }
          _.set(channel, 'lastCreatedMessage.created', _.get(event, 'content.created'));
          _.set(channel, 'lastCreatedMessage.id', _.get(event, 'content.id'));
          channel.updated = _.get(event, 'content.created');
          var currentChannelId = _.get(vm, 'msgSidebar.currentChannel.id');
          if (!_.isUndefined(channelId) && !_.isUndefined(currentChannelId) && channelId !== currentChannelId) {
            // This variable can be used by other views to check if there are new messages available
            vm.msgSidebar.hasNewMessages = true;
          }
          $rootScope.$emit('messaging-channel:' + channelId + ':updated');

          var member = channel.getMember(_.get(vm.currentUser, 'id'));
          if (!member.muted) {
            browserNotificationsService.notifyMessage(event, channel);
          }
        });
      });
    }

    function _getChannelById(channelId) {
      var channel = _.find(vm.channels, {id: channelId});
      return channel
        ? $q(function (resolve) { resolve(channel); })
        : new MessageChannelModel({id: channelId}).get();
    }

    function _addChannelToState(channel) {
      var currentMember = _.find(channel.members, ['user.id', vm.currentUser.id]);
      channel.unreadCount = _.isNil(currentMember) ? 0 : currentMember.unreadCount;
      var muted = _.isNil(currentMember) ? true : currentMember.muted;
      $injector.get('ngxTabNotificationService').set('messaging-' + channel.id, channel.unreadCount, muted);
      vm.channels.push(channel);
    }

    function loadMore() {
      if (!vm.loading && (!vm.currentPage || vm.channels.length < vm.currentPage.totalElements)) {
        vm.loading = true;

        var pageable = new Pageable(0, pageSize, 'updated,desc', vm.channels.length);

        MessageChannelModel.pagedQuery(pageable).then(function (page) {
          vm.currentPage = page;

          // Calculate once and store for better performance
          angular.forEach(page.content, function (channel) {
            var lastMessage = _.get(channel, 'lastCreatedMessage.data.message');
            var lastNotification = _.get(channel, 'lastCreatedMessage.data.notification');
            var lastAttachments = _.get(channel, 'lastCreatedMessage.attachments', []);
            if (!lastMessage && !lastNotification && lastAttachments.length > 0) {
              var attachmentMessage = _.get(vm.currentUser, 'id') === _.get(channel, 'lastCreatedMessage.author.id')
                ? $translate.instant('MODULE.MESSAGING.MESSAGES.SENT_FILE_OWN', {count: lastAttachments.length})
                : $translate.instant('MODULE.MESSAGING.MESSAGES.SENT_FILE', {count: lastAttachments.length});
              _.set(channel, 'lastCreatedMessage.data.message', attachmentMessage);
            } else if (lastNotification) {
              _.set(channel, 'lastCreatedMessage.data.message', $translate.instant(lastNotification.messageKey, lastNotification.messageArgs));
            }
            channel.unreadCount = channel.getUnreadCount(vm.currentUser);
            $injector.get('ngxTabNotificationService').set('messaging-' + channel.id, channel.unreadCount);
            $rootScope.$emit('messaging-channel:' + channel.id + ':updated');
          });

          vm.channels.push.apply(vm.channels, page.content);
        }).finally(function () {
          vm.loading = false;
        });
      }
    }

    function _reconnectHandler() {
      $timeout(function () {
        var pageable = new Pageable(0, pageSize, 'updated,desc');

        MessageChannelModel.pagedQuery(pageable).then(function (page) {
          var newCount = 0;
          _.forEach(page.content, function (channel) {
            if (angular.isUndefined(_.find(vm.channels, {id: channel.id, updated: channel.updated}))) {
              _.remove(vm.channels, {id: channel.id});
              channel.unreadCount = channel.getUnreadCount(vm.currentUser);
              vm.channels.unshift(channel);
              newCount++;
              $rootScope.$emit('messaging-channel:' + channel.id + ':updated');
            }
          });
          if (newCount >= pageSize) {
            $rootScope.$emit('messaging-channels:refresh');
          }
        });
      }, socketReconnectDelays.MESSAGES_RELOAD_DELAY);
    }

    function _resetUnreadCount(event, channel) {
      if (angular.isDefined(_.find(vm.channels, {id: channel.id, updated: channel.updated}))) {
        _.remove(vm.channels, {id: channel.id});
        channel.unreadCount = 0;
        vm.channels.unshift(channel);
        $rootScope.$emit('messaging-channel:' + channel.id + ':updated');
      }
    }

    function onInit() {
      authService.onGlobalPermissions('USE_MESSAGING', _initChannels);
    }
  }

})(angular);

(function () {
  'use strict';

  sublineService.$inject = ["profileFieldsService", "moment", "$translate", "birthdayService", "coyoConfig"];
  angular.module('commons.ui')
      .factory('sublineService', sublineService);

  /**
   * @ngdoc service
   * @name commons.ui.sublineService
   *
   * @description
   * Service providing the subline information for senders
   *
   * @requires profileFieldsService
   * @requires moment
   * @requires $translate
   * @requires birthdayService
   * @requires coyoConfig
   *
   */
  function sublineService(profileFieldsService, moment, $translate, birthdayService, coyoConfig) {
    var promise;

    return {
      getSublineForUser: getSublineForUser,
      getSublineForSender: getSublineForSender
    };

    function _getProfileFields() {
      if (!promise) {
        promise = profileFieldsService.getGroups().then(function (profileFieldGroups) {
          return _(profileFieldGroups)
              .flatMap('fields')
              .filter({userChooser: true})
              .orderBy(['userChooserOrder', 'order']).value();
        });
      }
      return promise;
    }

    /**
     * @ngdoc method
     * @name commons.ui.sublineService#getSublineForSender
     * @methodOf commons.ui.sublineService
     *
     * @description
     * Gets the subline of a sender. In case the sender is a user, it will provide a string containing the configured
     * user profile field values joined by '·'. Else it will be the translated type of the sender.
     *
     * @param {object} sender - The sender to calculate the subline for
     * @returns {promise} A promise that is resolved to the subline
     */
    function getSublineForSender(sender) {
      if (sender.typeName === 'user') {
        return getSublineForUser(sender);
      } else {
        return $translate(coyoConfig.entityTypes[sender.typeName].label);
      }
    }

    /**
     * @ngdoc method
     * @name commons.ui.sublineService#getSublineForUser
     * @methodOf commons.ui.sublineService
     *
     * @description
     * Gets the subline of a user. It will provide a string containing the configured
     * user profile field values joined by '·'.
     *
     * @param {object} user - The user to calculate the subline for
     * @returns {promise} A promise that is resolved to the subline
     */
    function getSublineForUser(user) {
      return _getProfileFields().then(function (profileFields) {
        return _(profileFields).map(function (field) {
          if (!user.properties || !user.properties[field.name]) {
            return '';
          }
          if (field.type === 'BIRTHDAY') {
            return birthdayService.birthdayStringToDateString(user.properties[field.name]);
          } else if (field.type === 'DATE') {
            return moment(user.properties[field.name]).format('L');
          } else {
            return user.properties[field.name];
          }
        }).filter(function (string) {
          return string;
        }).map(function (string) {
          try {
            return $translate.instant(string);
          } catch (e) {
            return string;
          }
        }).join(' · ');
      });
    }
  }

})();

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import * as _ from 'lodash';
import {Observable, throwError} from 'rxjs';
import {catchError, filter, first, map, shareReplay} from 'rxjs/operators';
import {UserNotificationChannel} from './user-notification-channel.enum';
import {UserNotificationSetting} from './user-notification-setting';

/**
 * Service to retrieve and manage a user's personal notification settings.
 */
@Injectable({
  providedIn: 'root'
})
export class UserNotificationSettingsService {
  private cache$: Observable<UserNotificationSetting<any>[]>;

  constructor(private http: HttpClient,
              private authService: AuthService) {
    this.authService.isAuthenticated$()
      .pipe(filter(isAuthenticated => !isAuthenticated))
      .subscribe(() => this.cache$ = null);
  }

  /**
   * Retrieves the user's personal notification settings and caches them.
   *
   * @returns the notification settings
   */
  retrieve(): Observable<UserNotificationSetting<any>[]> {
    if (!this.cache$) {
      const userId = this.authService.getCurrentUserId();
      const url = () => `/web/users/${userId}/notification-settings`;
      this.cache$ = userId
        ? this.http.get<UserNotificationSetting<any>[]>(url())
          .pipe(catchError(e => this.handleError(e)))
          .pipe(shareReplay({bufferSize: 1, refCount: false}))
        : null;
    }
    return this.cache$
      ? this.cache$.pipe(first())
      : throwError('Not authenticated');
  }

  /**
   * Retrieves the user's personal notification settings for a specific channel.
   *
   * @param channel the notification channel (e.g. Browser, Sound, Push, ...)
   * @returns the notification settings
   */
  retrieveByChannel(channel: UserNotificationChannel): Observable<UserNotificationSetting<any> | undefined> {
    return this.retrieve().pipe(map(settings => _.find(settings, {channel})));
  }

  private handleError(e: any): Observable<never> {
    this.cache$ = null;
    return throwError(e);
  }
}

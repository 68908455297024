import {Vec2} from '@shared/preview/file-preview/image-container/vec2';

/**
 * Data object for swipe event payload
 */
export class SwipeEvent {
  constructor(public point: Vec2,
              public previous: Vec2,
              public delta: Vec2,
              public distance: number) {

  }
}

(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name commons.tour
   *
   * @description
   * This modules contains components to display the user tour.
   */
  angular
      .module('commons.tour', []);

})(angular);

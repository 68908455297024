<coyo-collapsible-content class="timeline-item-message" data-test="timeline-item-message"
                          [content]="item.data.message | markdown | hashtags | mention">
</coyo-collapsible-content>
<coyo-jit-translation-output [showMentions]="true" [translation]="translation"></coyo-jit-translation-output>
<coyo-jit-translation-toggle [originalText]="item.data.message" field="message" [id]="item.id"
                             [typeName]="item.typeName" (translated)="translation = $event"></coyo-jit-translation-toggle>
<p *ngIf="item.data.edited" class="timeline-item-edited"
   translate [translateParams]="{modified: item.modified | amTimeAgo}">MODULE.TIMELINE.EDIT.REMARK</p>
<div class="timeline-item-attachments">
  <coyo-link-preview-list *ngIf="item.linkPreviews.length" [previews]="item.linkPreviews"></coyo-link-preview-list>
  <coyo-video-preview-list *ngIf="item.videoPreviews.length" [previews]="item.videoPreviews"></coyo-video-preview-list>
  <coyo-file-preview-list *ngIf="item.attachments.length"
                          [attachments]="item.attachments"
                          [groupId]="item.id"
                          [author]="item.author"
                          [previewUrl]="previewUrl"
                          size="xl"></coyo-file-preview-list>
</div>

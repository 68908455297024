(function (angular) {
  'use strict';

  angular
      .module('coyo.admin.jobs')
      .component('oyocJobExecutionOptions', jobOptions())
      .controller('JobExecutionOptionsController', JobExecutionOptionsController);

  /**
   * @ngdoc directive
   * @name coyo.admin.jobs.oyocJobExecutionOptions:oyocJobExecutionOptions
   * @restrict 'E'
   *
   * @description
   * Context menu options for job list.
   *
   * @param {object} actions the available user actions
   * @param {object} job the job
   */
  function jobOptions() {
    return {
      scope: {},
      controller: 'JobExecutionOptionsController',
      controllerAs: '$ctrl',
      templateUrl: 'app/modules/admin/jobs/components/job-execution-options/job-execution-options.html',
      bindings: {
        job: '<',
        refresh: '&',
        jobStatus: '<?'
      }
    };
  }

  function JobExecutionOptionsController() {
    var vm = this;

    vm.stopJobExecution = stopJobExecution;

    function stopJobExecution() {
      vm.job.stop().finally(vm.refresh);
    }
  }
})(angular);

(function (angular) {
  'use strict';

  I18nModel.$inject = ["$http", "coyoEndpoints", "$q"];
  angular
      .module('coyo.domain')
      .factory('I18nModel', I18nModel);

  /**
   * @ngdoc service
   * @name coyo.domain.I18nModel
   *
   * @description
   * Provides the Coyo i18n model.
   *
   * @requires $http
   * @requires commons.config.coyoEndpoints
   * @requires $q
   */
  function I18nModel($http, coyoEndpoints, $q) {
    var I18nModel = {};
    //class members
    function xhrGet(url) {
      var deferred = $q.defer();

      var xmlRequest = new XMLHttpRequest();
      xmlRequest.addEventListener('load', function () {
        deferred.resolve({data: angular.fromJson(this.responseText)});
      });
      xmlRequest.addEventListener('error', function () {
        deferred.reject();
      });
      xmlRequest.open('GET', url);
      xmlRequest.send();

      return deferred.promise;
    }

    function updateTranslation(lang, key, translation, bundle, method, token) {
      return $http({
        method: method,
        url: coyoEndpoints.i18n.overridesMgmt.replace('{language}', lang) + '/' + key,
        data: {
          bundle: bundle,
          value: translation
        },
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
    }

    angular.extend(I18nModel, {

      /**
       * @ngdoc function
       * @name coyo.domain.I18nModel#getAvailableLanguages
       * @methodOf coyo.domain.I18nModel
       *
       * @description
       * Get the available languages
       *
       * @returns {promise} An $http promise
       */
      getAvailableLanguages: function () {
        return $http({
          method: 'GET',
          url: coyoEndpoints.i18n.availableLanguages
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.I18nModel#getTranslations
       * @methodOf coyo.domain.I18nModel
       *
       * @description
       * Get the mixed in (key + override) translations for the given language key and fallback
       *
       * @param {string} lang The language key
       * @param {string} fallback The fallback language key
       *
       * @returns {promise} A promise
       */
      getTranslations: function (lang, fallback) {
        return xhrGet(coyoEndpoints.i18n.messages.replace('{language}', lang).replace('{fallback}', fallback));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.I18nModel#getBundles
       * @methodOf coyo.domain.I18nModel
       *
       * @description
       * Get all message key bundles for the given language
       *
       * @param {string} lang The language key
       *
       * @returns {promise} A promise
       */
      getBundles: function (lang) {
        return xhrGet(coyoEndpoints.i18n.bundles.replace('{language}', lang));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.I18nModel#getOverrides
       * @methodOf coyo.domain.I18nModel
       *
       * @description
       * Gets all message key overrides for the given language
       *
       * @param {string} lang The language key
       *
       * @returns {promise} A promise
       */
      getOverrides: function (lang) {
        return xhrGet(coyoEndpoints.i18n.overrides.replace('{language}', lang));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.I18nModel#modifyTranslation
       * @methodOf coyo.domain.I18nModel
       *
       * @description
       * Updates message key override
       *
       * @param {string} lang The language key
       * @param {string} key The message key
       * @param {string} translation The new value
       * @param {string} bundle The bundle
       * @param {string} token The bearer token
       *
       * @returns {promise} An $http promise
       */
      modifyTranslation: function (lang, key, translation, bundle, token) {
        return updateTranslation(lang, key, translation, bundle, 'PUT', token);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.I18nModel#createTranslation
       * @methodOf coyo.domain.I18nModel
       *
       * @description
       * Creates message key override
       *
       * @param {string} lang The language key
       * @param {string} key The message key
       * @param {string} translation The value
       * @param {string} bundle The bundle
       * @param {string} token The bearer token
       *
       * @returns {promise} An $http promise
       */
      createTranslation: function (lang, key, translation, bundle, token) {
        return updateTranslation(lang, key, translation, bundle, 'POST', token);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.I18nModel#deleteOverride
       * @methodOf coyo.domain.I18nModel
       *
       * @description
       * Deletes a translation override
       *
       * @param {string} lang The language key
       * @param {string} bundle The bundle
       * @param {string} key The key
       * @param {string} token The bearer token
       *
       * @returns {promise} An $http promise
       */
      deleteOverride: function (lang, bundle, key, token) {
        return $http({
          method: 'DELETE',
          url: coyoEndpoints.i18n.overridesMgmt.replace('{language}', lang) + '/' + bundle + '/' + key,
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.I18nModel#deleteOverrides
       * @methodOf coyo.domain.I18nModel
       *
       * @description
       * Deletes all translation overrides
       *
       * @param {string} lang The language key
       * @param {string} token The bearer token
       *
       * @returns {promise} An $http promise
       */
      deleteOverrides: function (lang, token) {
        return $http({
          method: 'DELETE',
          url: coyoEndpoints.i18n.overridesMgmt.replace('{language}', lang),
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
      },
    });


    return I18nModel;
  }

})(angular);

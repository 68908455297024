import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Page} from '@domain/pagination/page';
import {Sender} from '@domain/sender/sender';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PollWidgetService {

  constructor(private http: HttpClient) {
  }

  getSelectedAnswers(id: string): Observable<PollWidgetSelectedAnswerResponse[]> {
    return this.http.get<PollWidgetSelectedAnswerResponse[]>(`/web/widgets/poll/${id}/selected-answers`);
  }

  selectAnswer(id: string, option: string): Observable<PollWidgetSelectedAnswerResponse> {
    return this.http.post<PollWidgetSelectedAnswerResponse>(`/web/widgets/poll/${id}/selected-answers`, {
      optionId: option
    });
  }

  getVotes(id: string): Observable<PollWidgetOptionVoteResponse[]> {
    return this.http.get<PollWidgetOptionVoteResponse[]>(`/web/widgets/poll/${id}/votes`);
  }

  deleteAnswer(id: string, answerId: string): Observable<any> {
    return this.http.delete(`/web/widgets/poll/${id}/selected-answers/${answerId}`);
  }

  getVoters(id: string, optionId: string, page: number = 0): Observable<Page<Sender>> {
    return this.http.get<Page<Sender>>(`/web/widgets/poll/${id}/${optionId}/voters?_page=${page}`);
  }
}

export interface PollWidgetOptionVoteResponse {
  optionId: string;
  count: number;
}

export interface PollWidgetSelectedAnswerResponse {
  id: string;
  optionId: string;
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Sender} from '@domain/sender/sender';
import {Ng1FileAuthorService, Ng1fileDetailsModalService} from '@root/typings';
import {NG1_FILE_AUTHOR_SERVICE, NG1_FILE_DETAILS_MODAL_SERVICE} from '@upgrade/upgrade.module';
import {WidgetComponent} from '@widgets/api/widget-component';
import {DocumentInfo} from '@widgets/latest-files/document-info';
import {LatestFilesResponseBody} from '@widgets/latest-files/latest-files-response-body';
import {LatestFilesWidget} from '@widgets/latest-files/latest-files-widget';
import {LatestFilesService} from '@widgets/latest-files/latest-files.service';
import * as _ from 'lodash';
import {from, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';

/**
 * The latest-files widget component.
 */
@Component({
  selector: 'coyo-latest-files-widget',
  templateUrl: './latest-files-widget.component.html',
  styleUrls: ['./latest-files-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestFilesWidgetComponent extends WidgetComponent<LatestFilesWidget> implements OnInit, OnChanges, OnDestroy {
  files$: Observable<DocumentInfo[]>;
  pageDeleted$: Subject<boolean> = new Subject<boolean>();

  constructor(changeDetectorRef: ChangeDetectorRef,
              private latestFileService: LatestFilesService,
              @Inject(NG1_FILE_AUTHOR_SERVICE) private fileAuthorService: Ng1FileAuthorService,
              @Inject(NG1_FILE_DETAILS_MODAL_SERVICE) private fileDetailsModalService: Ng1fileDetailsModalService) {
    super(changeDetectorRef);
  }

  get title(): string | undefined {
    return _.get(this.widget, 'settings._title');
  }

  ngOnInit(): void {
    this.loadFiles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadFiles();
  }

  ngOnDestroy(): void {
    this.pageDeleted$.complete();
  }

  openDetails(event: MouseEvent, file: DocumentInfo): void {
    this.files$.subscribe(
      files => this.fileDetailsModalService.open(files, _.findIndex(files, {id: file.id})));
    event.preventDefault();
  }

  loadFiles(): void {
    this.files$ = this.latestFileService.getLatestFiles(this.widget.settings._app.id, this.widget.settings._fileCount).pipe(
      catchError(() => {
        this.pageDeleted$.next(true);
        this.pageDeleted$.complete();
        return throwError('');
      }),
      switchMap(latestFilesResponseBody => this.getFileAuthors(latestFilesResponseBody)),
      shareReplay({bufferSize: 1, refCount: false})
    );
  }

  hasFiles(): Observable<boolean> {
    return !!this.files$ ? this.files$.pipe(map(files => !!files && files.length > 0)) : of(false);
  }

  isNewFile(file: DocumentInfo): boolean {
    return file.modified !== null && file.modified === file.created;
  }

  private getFileAuthors(latestFilesResponseBody: LatestFilesResponseBody): Observable<DocumentInfo[]> {
    const id = this.widget.settings._app.id;
    const senderId = this.widget.settings._app.senderId;
    const documentIds = latestFilesResponseBody.documents.map(document => document.id);
    const showAuthors = latestFilesResponseBody.app.settings.showAuthors;
    return this.requestFileAuthors(id, senderId, documentIds, showAuthors)
      .pipe(map(authors => {
          latestFilesResponseBody.documents.forEach(document => document.author = authors[document.id]);
          return latestFilesResponseBody.documents;
        }
      ));
  }

  private requestFileAuthors(id: string, senderId: string, ids: string[], showAuthors: boolean): Observable<{ [id: string]: Sender }> {
    return from(this.fileAuthorService.loadFileAuthors(senderId, id, ids, showAuthors));
  }
}

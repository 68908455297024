import {ChangeDetectionStrategy, Component, HostListener, Input, OnChanges} from '@angular/core';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {ToggleSelection} from '@app/file-picker/file-picker-selection/file-picker-selection.actions';
import {FilePickerSelectionsState} from '@app/file-picker/file-picker-selection/file-picker-selections-state';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';

/**
 * Component rendering a material checkbox and toggles the selection state of the given file item.
 */
@Component({
  selector: 'coyo-file-picker-selection',
  templateUrl: './file-picker-selection.component.html',
  styleUrls: ['./file-picker-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerSelectionComponent implements OnChanges {

  /**
   * The selectable item
   */
  @Input() item: FilePickerItem;

  selected$: Observable<boolean>;

  selectable$: Observable<boolean>;

  constructor(private store: Store) {
  }

  ngOnChanges(): void {
    this.selected$ = this.store.select(FilePickerSelectionsState.isItemSelected(this.item.id));
    this.selectable$ = this.store.select(FilePickerSelectionsState.isItemSelectable(this.item));
  }

  toggleFileSelection(): void {
    this.store.dispatch(new ToggleSelection(this.item));
  }

  @HostListener('click')
  preventClickPropagation(event: Event): void {
    event.stopImmediatePropagation();
  }
}

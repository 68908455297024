import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {License} from '@shared/license/license';
import {LicenseStateModel} from '@shared/license/license-state-model';
import {LicenseFetched, LicenseUpdated, LicenseValidityFetched, LicenseValidityUpdated} from '@shared/license/license.actions';

/**
 * State for the license and it's validity
 */
@Injectable({providedIn: 'root'})
@State<LicenseStateModel>({
  name: 'license'
})
export class LicenseState {

  @Action(LicenseFetched)
  licenseFetched(ctx: StateContext<LicenseStateModel>, action: LicenseFetched): void {
    this.setLicense(ctx, action.license);
  }

  @Action(LicenseUpdated)
  licenseUpdated(ctx: StateContext<LicenseStateModel>, action: LicenseUpdated): void {
    this.setLicense(ctx, action.license);
  }

  @Action(LicenseValidityFetched)
  licenseValidityFetched(ctx: StateContext<LicenseStateModel>, action: LicenseValidityFetched): void {
    this.setLicenseValidity(ctx, action.validity);
  }

  @Action(LicenseValidityUpdated)
  licenseValidityUpdated(ctx: StateContext<LicenseStateModel>, action: LicenseValidityUpdated): void {
    this.setLicenseValidity(ctx, action.validity);
  }

  private setLicenseValidity(ctx: StateContext<LicenseStateModel>, validity: boolean): void {
    ctx.patchState({valid: validity});
  }

  private setLicense(ctx: StateContext<LicenseStateModel>, license: License): void {
    ctx.patchState({license: license});
  }
}

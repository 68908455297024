(function (angular) {
  'use strict';

  WorkspacesListController.$inject = ["$rootScope", "$scope", "$q", "$sessionStorage", "$state", "$stateParams", "$timeout", "$controller", "WorkspaceModel", "WorkspaceCategoryModel", "Pageable", "currentUser", "categories", "categoryService", "authService", "modalService", "workspacesConfig", "selectionFilterService"];
  angular
      .module('coyo.workspaces')
      .controller('WorkspacesListController', WorkspacesListController);

  function WorkspacesListController($rootScope, $scope, $q, $sessionStorage, $state, $stateParams, $timeout, $controller,
                                    WorkspaceModel, WorkspaceCategoryModel, Pageable, currentUser, categories,
                                    categoryService, authService, modalService, workspacesConfig, selectionFilterService) {

    var vm = this,
        FILTER_KEY_ALL = selectionFilterService.KEY_ALL,
        STATUS_FILTER_ACTIVE_KEY = 'ACTIVE',
        STATUS_FILTER_ARCHIVED_KEY = 'ARCHIVED',
        MEMBERSHIP_FILTER_ADMIN_KEY = 'ADMIN',
        MEMBERSHIP_FILTER_APPROVED_KEY = 'APPROVED',
        MEMBERSHIP_FILTER_INVITED_KEY = 'INVITED',
        MEMBERSHIP_FILTER_REQUESTED_KEY = 'REQUESTED';

    vm.currentUser = currentUser;
    vm.categories = categories;
    vm.loading = true;

    vm.$onInit = onInit;
    vm.showWorkspaceActions = showWorkspaceActions;
    vm.search = search;
    vm.setFilterStatus = setFilterStatus;
    vm.setFilterMembership = setFilterMembership;
    vm.setFilterCategories = setFilterCategories;
    vm.resetFilters = resetFilters;
    vm.getWorkspaceCategoryTooltip = getWorkspaceCategoryTooltip;
    vm.showFilters = showFilters;
    vm.updateCategories = updateCategories;
    vm.saveCategory = saveCategory;
    vm.changeCategoryOrder = changeCategoryOrder;
    vm.deleteCategory = deleteCategory;

    function showWorkspaceActions(currentWorkspace) {
      var isPublic = currentWorkspace.visibility === 'PUBLIC';
      var isProtected = currentWorkspace.visibility === 'PROTECTED';
      var isPrivate = currentWorkspace.visibility === 'PRIVATE';
      var hasMembership = currentWorkspace.membershipStatus !== 'NONE';
      return isPublic || isProtected || (isPrivate && hasMembership);
    }

    function showFilters() {
      return !vm.currentUser.externalWorkspaceMember;
    }

    function search(searchTerm) {
      if (!vm.editingCategory) {
        vm.query.term = searchTerm;
        vm.query.filters.status = _getQueryFilterStatus();
        vm.query.filters.membership = _getQueryFilterMembership();
        _loadWorkspaces();
      }
    }

    function setFilterStatus(status) {
      var selectedStatus = _.map(status, 'key')[0];
      _setStatus(selectedStatus || FILTER_KEY_ALL);
      _loadWorkspaces();
    }

    function setFilterMembership(membership) {
      var selectedMembership = _.map(membership, 'key')[0];
      _setMembership(selectedMembership || FILTER_KEY_ALL);
      _loadWorkspaces();
    }

    function setFilterCategories(selected) {
      _.set(vm.query, 'filters.categories', selected);
      _loadWorkspaces();
    }

    function resetFilters() {
      _resetFilterStatus();
      _resetFilterMembership();
      _resetFilterCategories();
      _initSelectionFilter();
      search('');
    }

    function getWorkspaceCategoryTooltip(workspace) {
      return workspace.categories.length > 2 ? _.join(_.map(_.slice(workspace.categories, 2), 'name'), '<br/>')
        : undefined;
    }

    function updateCategories(newCategories) {
      vm.categories = newCategories;
    }

    function saveCategory(categoryEvent) {
      return categoryService.saveCategory(categoryEvent, WorkspaceCategoryModel);
    }

    function deleteCategory(categoryEvent) {
      return categoryService.deleteCategory(categoryEvent, WorkspaceCategoryModel);
    }

    function changeCategoryOrder(categoryEvent) {
      return categoryService.changeCategoryOrder(categoryEvent, WorkspaceCategoryModel);
    }

    /* ==================== */

    function _loadWorkspaces() {
      if (vm.loading && vm.currentPage) {
        return;
      }

      // write params to URL
      var params = {
        term: _.get(vm.query, 'term', ''),
        'categories[]': _getQueryFilterCategories(),
        'status': _getQueryFilterStatus(),
        'membership': _getQueryFilterMembership()
      };
      $state.transitionTo('main.workspace', _.omitBy(params, _.isEmpty), {location: 'replace'});

      // perform search
      $sessionStorage.workspaceQuery = vm.query;
      vm.loading = true;

      var term = vm.query.term;
      var sort = term ? ['_score,DESC', 'displayName.sort'] : 'displayName.sort';
      var pageable = new Pageable(0, workspacesConfig.list.paging.pageSize, sort);
      var aggregations = {categories: 0, allCategories: 0, archived: 0};
      var filters = _getFiltersForQuery();
      var searchFields = ['displayName', 'description'];
      WorkspaceModel.searchWithFilter(term, pageable, filters, searchFields, aggregations, true).then(function (page) {
        vm.currentPage = page;
        vm.totalCount = _.get(page.aggregations.allCategories[0], 'count', 0);
        _.forEach(vm.categories, function (category) {
          var data = _.find(page.aggregations.categories, {key: category.id});
          category.count = _.get(data, 'count', 0);
        });
        var missingData = _.find(page.aggregations.categories, {key: 'N/A'});
        vm.missingCount = _.get(missingData, 'count', null);
        var archived = _.get(page, 'aggregations.archived');
        vm.active = _.find(archived, {key: '0'});
        vm.archived = _.find(archived, {key: '1'});
        vm.activeCategory = _getQueryFilterCategories();
        _setStatusCount();
        _orderWorkspaceCategories(filters.categories);
      }).finally(function () {
        vm.loading = false;
      });
    }

    function _orderWorkspaceCategories(categories) {
      _.forEach(vm.currentPage.content, function (elem) {
        elem.categories.sort(function (a, b) {
          var filterA = categories.indexOf(a.id) > -1;
          var filterB = categories.indexOf(b.id) > -1;
          if (filterA && filterB) {
            return a.name.localeCompare(b.name);
          } else if (filterA) {
            return -1;
          } else if (filterB) {
            return 1;
          } else {
            return a.name.localeCompare(b.name);
          }
        });
      });
    }

    function _getQueryFilterStatus() {
      var status = _.get(vm.query, 'filters.status');
      return !_.isUndefined(status) ? status : STATUS_FILTER_ACTIVE_KEY;
    }

    function _getQueryFilterMembership() {
      var membership = _.get(vm.query, 'filters.membership');
      return !_.isUndefined(membership) ? membership : FILTER_KEY_ALL;
    }

    function _getQueryFilterCategories() {
      return _.get(vm.query, 'filters.categories', []);
    }

    function _getFiltersForQuery() {
      var filters = _.clone(_.get(vm.query, 'filters'));

      // set status filter
      if (filters && filters.status) {
        switch (filters.status) {
          case STATUS_FILTER_ACTIVE_KEY:
            filters.archived = false;
            break;
          case STATUS_FILTER_ARCHIVED_KEY:
            filters.archived = true;
            break;
          default:
            filters.archived = undefined;
            break;
        }
        delete filters.status;
      }

      // set membership filter
      if (filters && filters.membership) {
        switch (filters.membership) {
          case MEMBERSHIP_FILTER_ADMIN_KEY:
          case MEMBERSHIP_FILTER_APPROVED_KEY:
          case MEMBERSHIP_FILTER_INVITED_KEY:
          case MEMBERSHIP_FILTER_REQUESTED_KEY:
            filters.membershipStatus = filters.membership;
            break;
          default:
            filters.membershipStatus = undefined;
            break;
        }
        delete filters.membership;
      }

      return filters;
    }

    function _setStatus(status) {
      $sessionStorage.workspaceStatusFilter = status;
      _.set(vm.query, 'filters.status', status);
    }

    function _setMembership(membership) {
      $sessionStorage.workspaceMembershipFilter = membership;
      _.set(vm.query, 'filters.membership', membership);
    }

    function _resetFilterStatus() {
      vm.statusFilterModel.selectItem(STATUS_FILTER_ACTIVE_KEY);
      _setStatus(STATUS_FILTER_ACTIVE_KEY);
    }

    function _resetFilterMembership() {
      vm.membershipFilterModel.clearAll();
      _setMembership(FILTER_KEY_ALL);
    }

    function _resetFilterCategories() {
      vm.query.filters.categories = vm.categoryFilter = [];
    }

    function _setStatusCount() {
      var activeCount = _.get(vm.active, 'count', 0);
      var archivedCount = _.get(vm.archived, 'count', 0);
      var totalCount = activeCount + archivedCount;
      vm.statusFilterModel.setCount(totalCount);
      vm.statusFilterModel.getItem(STATUS_FILTER_ACTIVE_KEY).count = activeCount;
      vm.statusFilterModel.getItem(STATUS_FILTER_ARCHIVED_KEY).count = archivedCount;
    }

    function _initSelectionFilter() {
      vm.statusFilterModel =
          selectionFilterService.builder()
              .itemModel(selectionFilterService.itemBuilder().key(STATUS_FILTER_ACTIVE_KEY).icon('check').build())
              .itemModel(
                  selectionFilterService.itemBuilder().key(STATUS_FILTER_ARCHIVED_KEY).icon('archive').build())
              .active(_getQueryFilterStatus()).build();

      vm.membershipFilterModel =
          selectionFilterService.builder()
              .itemModel(
                  selectionFilterService.itemBuilder().key(MEMBERSHIP_FILTER_APPROVED_KEY).icon('check').build())
              .itemModel(
                  selectionFilterService.itemBuilder().key(MEMBERSHIP_FILTER_INVITED_KEY).icon('envelope').build())
              .itemModel(
                  selectionFilterService.itemBuilder().key(MEMBERSHIP_FILTER_REQUESTED_KEY).icon('lock').build())
              .itemModel(
                  selectionFilterService.itemBuilder().key(MEMBERSHIP_FILTER_ADMIN_KEY).icon('settings').build())
              .active(_getQueryFilterMembership()).build();
    }

    function onInit() {
      // extract search from URL / storage
      if ($stateParams.reset) {
        delete $sessionStorage.workspaceQuery;
        delete $sessionStorage.workspaceStatusFilter;
        delete $sessionStorage.workspaceMembershipFilter;
      }

      vm.query = $sessionStorage.workspaceQuery || {};
      if ($stateParams.term || $stateParams['categories[]'] || $stateParams.status) {
        angular.extend(vm.query, {
          term: $stateParams.term,
          filters: {
            categories: $stateParams['categories[]'],
            status: $stateParams.status
          }
        });
      }

      var status = $sessionStorage.workspaceStatusFilter || STATUS_FILTER_ACTIVE_KEY;
      _setStatus(!_.isUndefined($stateParams.status) ? $stateParams.status : status);

      var membership = $sessionStorage.workspaceMembershipFilter || FILTER_KEY_ALL;
      _setMembership(!_.isUndefined($stateParams.membership) ? $stateParams.membership : membership);

      // register permission callback
      authService.onGlobalPermissions('CREATE_WORKSPACE', function (canCreateWorkspace) {
        vm.canCreateWorkspace = canCreateWorkspace;
      });
      authService.onGlobalPermissions('MANAGE_WORKSPACE_CATEGORIES', function (canManage) {
        vm.canManageWorkspaceCategories = canManage;
      });

      vm.categoryFilter = _getQueryFilterCategories();

      _initSelectionFilter();

      $timeout(function () {
        _loadWorkspaces();
      });
    }
  }

})(angular);

(function (angular) {
  'use strict';

  angular
      .module('coyo.admin.license')
      .component('validDays', validDays());

  /**
   * @ngdoc directive
   * @name commons.ui.validDays:validDays
   * @scope
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Renders a status element with info on how long the license will be valid (or since when it is expired).
   *
   * @param {string|boolean} status The license status.
   * @param {number} validDaysLeft The amount of days (negative if expired).
   * @param {array} validationFailures Array of validation failture codes.
   */
  function validDays() {
    return {
      templateUrl: 'app/modules/admin/license/valid-days/valid-days.html',
      bindings: {
        status: '<',
        validDaysLeft: '<',
        validationFailures: '<'
      }
    };
  }
})(angular);

import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {WikiArticleWidgetSettingsState} from '@widgets/wiki-article/wiki-article-widget-settings/wiki-article-widget-settings.state';
import {WIKI_ARTICLE} from '@widgets/wiki-article/wiki-article-widget.config';
import {WikiArticleWidgetState} from '@widgets/wiki-article/wiki-article-widget/wiki-article-widget.state';
import {WikiArticleWidgetSettingsComponent} from './wiki-article-widget-settings/wiki-article-widget-settings.component';
import {WikiArticleWidgetComponent} from './wiki-article-widget/wiki-article-widget.component';

export const ngxsModule = NgxsModule.forFeature([WikiArticleWidgetSettingsState, WikiArticleWidgetState]);

@NgModule({
  declarations: [WikiArticleWidgetComponent, WikiArticleWidgetSettingsComponent],
  entryComponents: [WikiArticleWidgetComponent, WikiArticleWidgetSettingsComponent],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    ngxsModule,
    SenderUIModule
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: WIKI_ARTICLE, multi: true}
  ]
})
export class WikiArticleWidgetModule {
}

<div class="single-file-widget" *ngIf="(state$ | async) as state">
  <div *ngIf="!state.file" class="no-file-found text-muted">
    <i class="zmdi zmdi-broken-image"></i>
    <span class="no-file-found-text">{{'WIDGET.SINGLEFILE.NO_FILE' | translate}}</span>
  </div>
  <ui-skeleton *ngIf="!state.file" [skeletons]="skeletons"></ui-skeleton>
  <div class="single-file-widget-details" *ngIf="state.file">
    <div class="single-file-widget-file-image">
      <coyo-file-preview *ngIf="!widget.settings._hidePreview && state.filePreview"
                         [file]="state.filePreview"
                         [url]="previewUrl"
                         [groupId]="state.file.senderId"
                         [options]="{hideNoPreviewAvailableMsg: true,
                                     hidePreviewGenerationInformation: true,
                                     allowGifPlayback: false}"
                         (click)="showFileDetails(state.filePreview)"
                         class="img-file-preview"></coyo-file-preview>
      <coyo-file-icon-by-mime-type
        *ngIf="widget.settings._hidePreview"
        [mimeType]="state.file.contentType"
        [storage]="state.file.storage"
        (click)="showFileDetails(state.filePreview)">
      </coyo-file-icon-by-mime-type>
    </div>
    <div class="single-file-widget-details-text" *ngIf="state.file.name || widget.settings.title">
      <h3>
        <a href="#" (click)="showFileDetails(state.filePreview)"
           *ngIf="!editMode"
           title="{{widget.settings.title || state.file.name }}">
          {{widget.settings.title || state.file.name }}
        </a>
        <input *ngIf="editMode"
               [formControl]="nameControl"
               type="text"
               placeholder="{{state.file.name}}"
               class="inplace-edit"/>
      </h3>
      <small class="single-file-widget-details-infos text-muted">
        {{state.file.contentType | fileTypeName| translate }}
        <span *ngIf="!widget.settings._hideDate">
            &sdot; {{state.file.modified | timeAgo}}
          </span>
        <br/>
        <span *ngIf="!widget.settings._hideSender">
            <coyo-sender-link [sender]="state.sender"></coyo-sender-link>
          </span>
        <span *ngIf="!widget.settings._hideDownload">
             &sdot; <a href="#" coyo-download="{{getDownloadUrl(state.file, state.sender)}}"
                       class="single-file-download">
          <i class="zmdi zmdi-download"></i>
          {{'DOWNLOAD' | translate}}
            </a>
          </span>
      </small>
    </div>
  </div>
</div>

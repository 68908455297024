import {Injectable} from '@angular/core';
import {UserActivityService} from '@app/analytics/user-activity/user-activity.service';
import {ServiceRecognitionService} from '@core/http/service-recognition/service-recognition.service';
import {LocalStorageService} from '@core/storage/local-storage/local-storage.service';
import {merge, Observable, of} from 'rxjs';
import {filter, take} from 'rxjs/operators';

/**
 * The main service for tracking of user interactions.
 * Run the `init()` method to enable the tracking.
 */
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private static readonly SERVICE_NAME: string = 'analytics';

  constructor(private serviceRecognitionService: ServiceRecognitionService,
              private userActivityService: UserActivityService,
              private localStorageService: LocalStorageService) {
  }

  /**
   * Initialize all services, that are part of analytics.
   * Hint: the service must also be enabled through {@link ServiceRecognitionService}.
   */
  init(): void {
    merge(
      this.isAnalyticsEnabled(),
      this.serviceRecognitionService.isServiceEnabled(AnalyticsService.SERVICE_NAME)
    ).pipe(
      filter(isActive => isActive),
      take(1)
    ).subscribe(() => {
      this.userActivityService.init();
      this.setAnalyticsEnabled();
    });
  }

  isAnalyticsEnabled(): Observable<boolean> {
    return of(this.localStorageService.getValue<boolean>('analytics', false));
  }

  private setAnalyticsEnabled(): void {
    this.localStorageService.setValue('analytics', true);
  }

}

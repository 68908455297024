<div [formGroup]="parentForm">
  <div class="form-group">
    <label for="daysBeforeBirthday" class="col-sm-3 control-label"
           translate="WIDGETS.BIRTHDAY.SETTINGS.BIRTHDAY_ADVANCED_DAYS.LABEL"></label>
    <div class="col-sm-9">
      <input name="daysBeforeBirthday" id="daysBeforeBirthday" class="form-control" type="number"
             formControlName="_daysBeforeBirthday">
    </div>
  </div>
  <div class="form-group">
    <label for="birthdayNumber" class="col-sm-3 control-label"
           translate="WIDGETS.BIRTHDAY.SETTINGS.BIRTHDAY_NUMBER.LABEL"></label>
    <div class="col-sm-9">
      <input name="birthdayNumber" id="birthdayNumber" class="form-control" type="number"
             formControlName="_birthdayNumber">
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-3 control-label" for="fetchBirthdays"
           translate="WIDGETS.BIRTHDAY.SETTINGS.BIRTHDAY.DISPLAY.LABEL"></label>
    <div class="col-sm-9">
      <div class="radio">
        <label>
          <input name="_fetchBirthdays" id="fetchBirthdays" type="radio" value="ALL"
                 formControlName="_fetchBirthdays">
          {{'WIDGETS.BIRTHDAY.SETTINGS.BIRTHDAY_ALL.USERS' | translate }}
        </label>
      </div>
      <div class="radio">
        <label>
          <input name="_fetchBirthdays" type="radio" value="FOLLOWERS" formControlName="_fetchBirthdays">
          {{'WIDGETS.BIRTHDAY.SETTINGS.BIRTHDAY_SUBSCRIBED.USERS' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="displayAge" class="col-sm-3 control-label"
           translate="WIDGETS.BIRTHDAY.SETTINGS.BIRTHDAY.AGE.LABEL"></label>
    <div class="col-sm-9">
      <coyo-checkbox id="displayAge" name="displayAge" formControlName="_displayAge"></coyo-checkbox>
    </div>
  </div>
</div>

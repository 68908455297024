import {CommonModule} from '@angular/common';
import {ErrorHandler, NgModule} from '@angular/core';
import {ErrorHandlerService} from '@core/error/error-handler.service';
import '@core/feature/feature-toggle-service/feature-toggle.service.downgrade';
import {UserStorageService} from '@core/storage/user-storage/user-storage.service';
import '@domain/integration-settings/user-integration-settings.service.downgrade.ts';
import '@domain/like/like.service.downgrade';
import '@domain/settings/settings.service.downgrade';
import '@domain/subscription/subscription.service.downgrade';
import {WINDOW} from '@root/injection-tokens';
import '@shared/notifications/notification/notification.service.downgrade';
import '@shared/notifications/page-title/page-title.service.downgrade';
import '@shared/notifications/tab-notification/tab-notification.service.downgrade';
import './feature/experimental-feature-toggle-service/experimental-feature-toggle.service.downgrade';
import './feature/feature-toggle-service/feature-toggle.service.downgrade';
import {HttpModule} from './http/http.module';
import {I18nModule} from './i18n/i18n.module';
import './script-loader/script-loader.service.downgrade';
import './theme/theme.service.downgrade';
import './token/token.service.downgrade';

/**
 * Module for core components and services that may only be imported once by the app module.
 */
@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    I18nModule
  ],
  providers: [
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {provide: WINDOW, useValue: window}
  ]
})
export class CoreModule {

  constructor(userStorageService: UserStorageService) {
    userStorageService.init();
  }
}

import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {FindOptions, SelectSenderOptions} from '@shared/sender-ui/select-sender/select-sender-options';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {FacegameWidget, FacegameWidgetSettings} from '@widgets/facegame/facegame-widget';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';

/**
 * The facegame widget's settings component.
 *
 * This component is used only in the context of the widget settings modal dialog.
 */
@Component({
  templateUrl: './facegame-widget-settings.component.html'
})
export class FacegameWidgetSettingsComponent extends WidgetSettingsComponent<FacegameWidget> implements OnInit {

  readonly selectSenderOptions: SelectSenderOptions = {
    senderTypes: ['page', 'workspace', 'event'],
    findOptions: FindOptions.MANAGED_SENDERS_ONLY
  };

  constructor(private senderService: SenderService) {
    super();
  }

  ngOnInit(): void {
    const external = _.get(this.widget.settings, '_includeExternal', false);
    const time = _.get(this.widget.settings, '_time', 30);
    this.parentForm.addControl('_time', new FormControl(time,
      [Validators.required, Validators.min(15), Validators.max(90)]));
    this.parentForm.addControl('_includeExternal', new FormControl(external));
    this.parentForm.addControl('_time', new FormControl(time));
    this.parentForm.addControl('_senderId', new FormControl(this.widget.settings._senderId));
    if (this.widget.settings._senderId) {
      this.senderService.get(this.widget.settings._senderId).subscribe(sender => {
        this.parentForm.patchValue({_senderId: sender});
      });
    }
  }

  onBeforeSave(settings?: {_senderId: Sender, _time: number, _includeExternal: boolean}): Observable<FacegameWidgetSettings> {
    return of({...settings, _senderId: settings._senderId ? settings._senderId.id : null});
  }
}

import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {OFFICE365, StorageType} from '@domain/attachment/storage';
import {Observable} from 'rxjs';

/**
 * File picker item showing the the name and the sender avatar.
 */
export class FileSearchFilePickerItem implements FilePickerItem {

  readonly isFolder: boolean = true;
  readonly storageType: StorageType = OFFICE365;

  constructor(readonly id: string, readonly name: string, public search: (() => Observable<FilePickerItem[]>)) {
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CollapsibleContentComponent} from './collapsible-content.component';

/**
 * Module that contains a component used to display expandable text
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    CollapsibleContentComponent
  ],
  exports: [
    CollapsibleContentComponent
  ],
  entryComponents: [
    CollapsibleContentComponent
  ]
})
export class CollapsibleContentModule {}

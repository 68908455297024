import {NgModule} from '@angular/core';
import {AnalyticsModule} from '@app/analytics/analytics.module';
import {TourModule} from '@app/tour/tour.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {CollapsibleContentModule} from '@shared/collapsible-content/collapsible-content.module';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DividerModule} from '@shared/divider/divider.module';
import {EmojiModule} from '@shared/emoji/emoji.module';
import {EventMetaDataModule} from '@shared/event-meta-data/event-meta-data.module';
import {FileModule} from '@shared/files/file.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {JitTranslationModule} from '@shared/jit-translation/jit-translation.module';
import {MentionModule} from '@shared/mention/mention.module';
import {PreviewModule} from '@shared/preview/preview.module';
import {RibbonModule} from '@shared/ribbon/ribbon.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {SocialModule} from '@shared/social/social.module';
import {TextModule} from '@shared/text/text.module';
import {TimeModule} from '@shared/time/time.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {AutosizeModule} from 'ngx-autosize';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {MomentModule} from 'ngx-moment';
import {FunctionalUserChooserComponent} from './timeline-form/functional-user-chooser/functional-user-chooser.component';
import {LockBtnComponent} from './timeline-form/lock-btn/lock-btn.component';
import {StickyBtnComponent} from './timeline-form/sticky-btn/sticky-btn.component';
import {StickyOptionsPipe} from './timeline-form/sticky-btn/sticky-options.pipe';
import {TimelineFormComponent} from './timeline-form/timeline-form/timeline-form.component';
import {TimelineItemContentOutletComponent} from './timeline-item/timeline-item-content-outlet/timeline-item-content-outlet.component';
import {BlogShareArticleComponent} from './timeline-item/timeline-item-content/blog-share-article/blog-share-article.component';
import {EventShareItemComponent} from './timeline-item/timeline-item-content/event-share-item/event-share-item.component';
import {PostItemComponent} from './timeline-item/timeline-item-content/post-item/post-item.component';
import {SenderShareItemComponent} from './timeline-item/timeline-item-content/sender-share-item/sender-share-item.component';
import {WikiShareArticleComponent} from './timeline-item/timeline-item-content/wiki-share-article/wiki-share-article.component';
import {TimelineItemContextMenuComponent} from './timeline-item/timeline-item-context-menu/timeline-item-context-menu.component';
import {TimelineItemEditModalComponent} from './timeline-item/timeline-item-edit-modal/timeline-item-edit-modal.component';
import {TimelineItemFooterComponent} from './timeline-item/timeline-item-footer/timeline-item-footer.component';
import {TimelineItemHeaderComponent} from './timeline-item/timeline-item-header/timeline-item-header.component';
import {TimelineItemReportModalComponent} from './timeline-item/timeline-item-report-modal/timeline-item-report-modal.component';
import {TimelineItemRoutingComponent} from './timeline-item/timeline-item-routing/timeline-item-routing.component';
import {TimelineItemComponent} from './timeline-item/timeline-item/timeline-item.component';
import {TimelineStreamComponent} from './timeline-stream/timeline-stream.component';
import './timeline-stream/timeline-stream.component.downgrade';
import {timelineItemState} from './timeline.state';

export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: [timelineItemState]});

/**
 * Feature module for all timeline related stuff
 */
@NgModule({
  imports: [
    AnalyticsModule,
    AutosizeModule,
    BsDropdownModule,
    CollapsibleContentModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    DividerModule,
    EmojiModule,
    EventMetaDataModule,
    FileModule,
    HelpModule,
    JitTranslationModule,
    TextModule,
    MentionModule,
    MomentModule,
    NgSelectModule,
    PreviewModule,
    RibbonModule,
    SenderUIModule,
    SocialModule,
    TimeModule,
    uiRouterModuleChild,
    UpgradeModule,
    TourModule
  ],
  declarations: [
    BlogShareArticleComponent,
    EventShareItemComponent,
    FunctionalUserChooserComponent,
    LockBtnComponent,
    PostItemComponent,
    SenderShareItemComponent,
    StickyBtnComponent,
    StickyOptionsPipe,
    TimelineFormComponent,
    TimelineItemComponent,
    TimelineItemContentOutletComponent,
    TimelineItemContextMenuComponent,
    TimelineItemEditModalComponent,
    TimelineItemFooterComponent,
    TimelineItemHeaderComponent,
    TimelineItemReportModalComponent,
    TimelineItemRoutingComponent,
    TimelineStreamComponent,
    WikiShareArticleComponent
  ],
  exports: [
    TimelineStreamComponent
  ],
  entryComponents: [
    BlogShareArticleComponent,
    EventShareItemComponent,
    PostItemComponent,
    SenderShareItemComponent,
    TimelineItemEditModalComponent,
    TimelineItemReportModalComponent,
    TimelineItemRoutingComponent,
    TimelineStreamComponent,
    WikiShareArticleComponent
  ]
})

export class TimelineModule {
}

import {Injectable} from '@angular/core';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Provides functionality around user translations.
 */
@Injectable({
  providedIn: 'root'
})
export class MultilanguageService {

  static readonly FEATURE_FLAG: string = 'content-multilanguage';

  constructor(private readonly featureToggleService: FeatureToggleService) {
  }

  /**
   * Checks if the user translation feature is activated.
   *
   * @returns An observable omitting true if active.
   */
  isEnabled(): Observable<boolean> {
    return this.featureToggleService.isFeatureAvailable(MultilanguageService.FEATURE_FLAG);
  }

  /**
   * Checks if the user translation feature has a valid license.
   *
   * @returns An observable omitting true if valid.
   */
  hasValidLicense(): Observable<boolean> {
    return this.featureToggleService.getFeatureState(MultilanguageService.FEATURE_FLAG).pipe(
      map(state => state.states['license']));
  }
}

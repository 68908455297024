(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.coyoInfiniteScroll
   * @restrict 'A'
   *
   * @description Applies endless scrolling. Executed once during initialization and then whenever
   *              user scrolls near the end of the element. Execution on initialization can be turned off
   *              by setting coyo-infinite-scroll-no-initial-load. This is "true" by default.
   *
   *              Scroll on div:            <div coyo-infinite-scroll="loadMore()">...</div>
   *              Scroll on other element:  <div coyo-infinite-scroll="loadMore()" coyo-infinite-scroll-element=".selector">...</div>
   *              Scroll on window:         <div coyo-infinite-scroll="loadMore()" coyo-infinite-scroll-element="$window">...</div>
   *
   *              This directive only takes care of the scrolling event. Loading more data and stopping when the
   *              last page was reached is up to you.
   */
  CoyoInfiniteScroll.$inject = ["$timeout", "$log", "$window"];
  angular.module('commons.ui')
      .directive('coyoInfiniteScroll', CoyoInfiniteScroll);

  function CoyoInfiniteScroll($timeout, $log, $window) {
    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        var initialLoad = angular.isUndefined(attrs.coyoInfiniteScrollNoInitialLoad);

        // load first page if not turned off (inside correct digest)
        if (initialLoad) {
          $timeout(function () {
            $log.debug('[coyoInfiniteScroll] Performing initial load.');
            $scope.$apply(attrs.coyoInfiniteScroll);
          });
        }

        // pixels before end, default=200
        var threshold = 200;
        if (attrs.coyoInfiniteScrollThreshold) {
          threshold = parseInt(attrs.coyoInfiniteScrollThreshold);
        }

        var bindTo, scrollCondition;
        bindToScrollElement(attrs.coyoInfiniteScrollElement);
        // watch for changed in element to scroll
        attrs.$observe('coyoInfiniteScrollElement', bindToScrollElement);

        // determine element to watch for scroll event
        function bindToScrollElement(coyoInfiniteScrollElement) {
          if (bindTo) {
            bindTo.unbind('scroll', scrollHandler);
          }

          if (coyoInfiniteScrollElement === '$window') {
            bindTo = angular.element(window);
            var body = angular.element(document).find('body')[0];
            scrollCondition = function () {
              return ($window.pageYOffset + $window.innerHeight + threshold) >= body.scrollHeight;
            };
          } else {
            if (coyoInfiniteScrollElement) {
              bindTo = angular.element($window.document.querySelector(coyoInfiniteScrollElement));
            } else {
              bindTo = element;
            }
            scrollCondition = function () {
              return (bindTo[0].scrollTop + bindTo[0].offsetHeight + threshold) >= bindTo[0].scrollHeight;
            };
          }
          bindTo.bind('scroll', scrollHandler);
        }

        // watch for scroll events => every 100ms
        var blocked = false;

        function scrollHandler() {
          if (!blocked) {
            blocked = true;

            $timeout(function () {
              if (scrollCondition()) {
                $scope.$apply(attrs.coyoInfiniteScroll);
              }
              blocked = false;
            }, 100);
          }
        }

        $scope.$on('$destroy', function () {
          bindTo.unbind('scroll', scrollHandler);
        });
      }
    };
  }
})();

<ng-container *ngxPermissionsOnly="['CREATE_SHARE']">
  <!-- Unfortunately, we have to duplicate the button due to different directives. -->
  <button mat-icon-button type="button" size="small" data-test="share-button"
          *ngIf="condensed"
          [disabled]="!target._permissions?.share"
          [attr.aria-label]="'SHARE_BUTTON.SHARE_MODAL.OPEN.ARIA' | translate"
          (click)="openShareModal()"><mat-icon svgIcon="share"></mat-icon></button>
  <button mat-button type="button" size="small" data-test="share-button"
          *ngIf="!condensed"
          [disabled]="!target._permissions?.share"
          [attr.aria-label]="'SHARE_BUTTON.SHARE_MODAL.OPEN.ARIA' | translate"
          (click)="openShareModal()"><mat-icon svgIcon="share"></mat-icon>{{'COMMONS.SHARES.SUBMIT' | translate}}</button>
</ng-container>

import {Injectable} from '@angular/core';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {LoadMore} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {FilePreviewService} from '@domain/preview/file-preview/file-preview.service';
import {User} from '@domain/user/user';
import {Store} from '@ngxs/store';
import {FileDetailsModalService} from '@shared/files/file-details-modal/file-details-modal.service';
import {filter, first, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileLibraryFileDetailsService {

  constructor(
    private readonly store: Store,
    private readonly filePreviewService: FilePreviewService,
    private readonly fileDetailsModalService: FileDetailsModalService
  ) {
  }

  openFilePreview(filePickerId: string, item: FileLibraryFilePickerItem): void {
    const showAuthors = this.store.selectSnapshot(FilePickerStateSelectors.showAuthor(filePickerId));
    const items = this.store.selectSnapshot(FilePickerStateSelectors.items(filePickerId));
    const appId = this.store.selectSnapshot(FilePickerStateSelectors.appId(filePickerId));
    const nonFolderItems = items.filter(elem => !elem.item.isFolder);
    const itemIndex = nonFolderItems
      .map(elem => elem.item)
      .indexOf(item);
    // This works because the first file preview can only be shown if the first non folder item is shown.
    // Folders are always sorted to the top of the table.
    const folderCount = items.length - nonFolderItems.length;
    const filePreviews = this.filePreviewService
      .toFilePreviews(
        nonFolderItems.map(fileStateModel => (fileStateModel.item as FileLibraryFilePickerItem).file)
      );
    const authors = nonFolderItems
      .reduce((mapObj, itemState) => ({
        ...mapObj,
        [itemState.item.id]: itemState.author
      }), {} as { [key: string]: User });
    const totalFiles = this.store.selectSnapshot(FilePickerStateSelectors.totalItemsInCurrentFolder(filePickerId)) - folderCount;

    this.fileDetailsModalService.open(
      filePreviews,
      itemIndex,
      false,
      showAuthors,
      authors,
      appId,
      totalFiles,
      (currentIndex: number) => this.loadNext(filePickerId, currentIndex, folderCount, showAuthors)
    );
  }

  private loadNext(filePickerId: string, currentIndex: number, folderCount: number, showAuthors: boolean): Promise<{ file: FilePreview, author: User }> {
    const noneFolderItems = this.store.selectSnapshot(FilePickerStateSelectors.items(filePickerId))
      .filter(elem => !elem.item.isFolder);
    if (currentIndex >= noneFolderItems.length) {
      const currentLocation = this.store.selectSnapshot(FilePickerStateSelectors.currentLocation(filePickerId));
      this.store.dispatch(new LoadMore(filePickerId, currentLocation));
    }
    return this.store.select(FilePickerStateSelectors.fileAtIndex(filePickerId, currentIndex + folderCount))
      .pipe(filter(currentFileModel =>
        !!currentFileModel &&
        currentFileModel.item instanceof FileLibraryFilePickerItem &&
        (!showAuthors || !!currentFileModel.author)))
      .pipe(first())
      .pipe(map(currentFileModel => ({
        file: this.filePreviewService.toFilePreview((currentFileModel.item as FileLibraryFilePickerItem).file),
        author: currentFileModel.author
      })))
      .toPromise();
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormlyModule as CoreFormlyModule} from '@ngx-formly/core';
import {FormlySelectModule} from '@ngx-formly/core/select';
import {FormlyMatFormFieldModule} from '@ngx-formly/material/form-field';
import {FormlyFieldInput} from '@ngx-formly/material/input';
import {FormlyFieldTextArea} from '@ngx-formly/material/textarea';
import {MaterialModule} from '@shared/material/material.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {FormlyCheckboxComponent} from './formly-checkbox/formly-checkbox.component';
import {FormlyRadioComponent} from './formly-radio/formly-radio.component';
import {FormlySelectComponent} from './formly-select/formly-select.component';
import {FormlyUserComponent} from './formly-user/formly-user.component';

/**
 * Module that contains form field definitions for generic form rendering via ngx-formly.
 */
@NgModule({
  declarations: [
    FormlyCheckboxComponent,
    FormlyRadioComponent,
    FormlySelectComponent,
    FormlyUserComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    NgSelectModule,
    SenderUIModule,
    FormlyMatFormFieldModule,
    FormlySelectModule,
    CoreFormlyModule.forChild({
      types: [{
        name: 'text',
        component: FormlyFieldInput,
        wrappers: ['form-field'],
        defaultOptions: {
          templateOptions: {
            hideRequiredMarker: true
          }
        }
      }, {
        name: 'textarea',
        component: FormlyFieldTextArea,
        wrappers: ['form-field'],
        defaultOptions: {
          templateOptions: {
            hideRequiredMarker: true,
            rows: 3
          }
        }
      }, {
        name: 'number',
        extends: 'text',
        defaultOptions: {
          templateOptions: {
            type: 'number'
          }
        }
      }, {
        name: 'checkbox',
        component: FormlyCheckboxComponent,
        defaultOptions: {
          templateOptions: {
            hideRequiredMarker: true,
            indeterminate: false
          }
        }
      }, {
        name: 'radio',
        component: FormlyRadioComponent,
        defaultOptions: {
          templateOptions: {
            hideRequiredMarker: true,
          }
        }
      }, {
        name: 'select',
        component: FormlySelectComponent,
        wrappers: ['form-field'],
        defaultOptions: {
          templateOptions: {
            hideRequiredMarker: true
          }
        }
      }, {
        name: 'user',
        component: FormlyUserComponent,
        wrappers: ['form-field'],
        defaultOptions: {
          templateOptions: {
            hideRequiredMarker: true,
            bindId: true
          }
        }
      }]
    })
  ]
})
export class FormlyModule {
}

import {Pipe, PipeTransform} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {LaunchpadLink} from '@domain/launchpad/launchpad-link';

/**
 * A simple pipe to generate the image URL for a launchpad link icon.
 */
@Pipe({
  name: 'launchpadLinkIconSrc'
})
export class LaunchpadLinkIconSrcPipe implements PipeTransform {

  constructor(private urlService: UrlService) {
  }

  /**
   * Generates the image URL for a launchpad link icon.
   *
   * @param link the launchpad link
   * @return the image URL
   */
  transform(link: LaunchpadLink): string {
    return link && link.icon
      ? this.urlService.getBackendUrl() + `/web/launchpad/links/${link.id}/icon?modified=${link.modified}`
      : null;
  }
}

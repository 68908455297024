(function (angular) {
  'use strict';

  fileDetailsModalService.$inject = ["$uibModal", "$injector"];
  angular
      .module('commons.ui')
      .factory('fileDetailsModalService', fileDetailsModalService);

  /**
   * @ngdoc service
   * @name  commons.ui.fileDetailsModalService
   *
   * @description
   * Service to show modal with file information and file preview.
   * Displays files from the file library as well as timeline item attachments.
   *
   * @requires $uibModal
   */
  function fileDetailsModalService($uibModal, $injector) {

    return {
      open: open,
      close: close
    };

    /**
     * @ngdoc method
     * @name commons.ui.fileDetailsModalService#open
     * @methodOf commons.ui.fileDetailsModalService
     *
     * @description
     * Open modal with file details and preview. If multiple files are provided one can browse through them.
     *
     * @param {Array|object} files
     * An array or single file to be shown in detail view. The file can either be an attachment file or a file library file.
     *
     * @param {number=} currentIndex
     * An optional index of which of the provided files should be displayed first. Defaults to 0.
     *
     * @param {boolean=} linkToFileLibrary
     * An optional if a link to the file in file library should be displayed. Defaults to false.
     *
     * @param {boolean=} showAuthors
     * An optional if the author of the file should be displayed.
     *
     * @param {object=} fileAuthors
     * An map of the files' authors.
     *
     * @param {string=} appIdOrSlug
     * If the modal was opened from an app - the apps' id or slug to check showAuthor settings and permissions.
     *
     * @param {number=} filesTotal
     * An optional total number of files if different from files.length. Files not loaded in files array will be loaded with
     * loadNext function
     *
     * @param {function=} loadNext
     * An optional function called to load the next file if files arrat is already at its end but there are more filesTotal.
     *
     * @returns {promise}
     * The modal instance
     */
    function open(files, currentIndex, linkToFileLibrary, showAuthors, fileAuthors, appIdOrSlug, filesTotal, loadNext) {
      var promise = $injector.get('ngxFileDetailsModalService')
          .openLegacy(files, currentIndex, linkToFileLibrary, showAuthors, fileAuthors, appIdOrSlug, filesTotal, loadNext);
      return {
        result: promise
      };
    }
  }

})(angular);

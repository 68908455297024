(function (angular) {
  'use strict';

  EntityVisitedController.$inject = ["$scope", "$injector"];
  angular
      .module('coyo.base')
      .directive('coyoAnalyticsEntityVisited', entityVisited)
      .controller('EntityVisitedController', EntityVisitedController);

  /**
   * @ngdoc directive
   * @name coyo.analytics:coyoAnalyticsEntityVisited
   * @element ANY
   * @restrict A
   * @scope
   *
   * @description
   * The entity visited directive indicates that the html element which it is attached to has been rendered.
   * @requires $injector
   * @requires $scope
   */
  function entityVisited() {
    return {
      restrict: 'A',
      scope: {
        entityId: '<',
        entityType: '<'
      },
      controller: 'EntityVisitedController'
    };
  }

  function EntityVisitedController($scope, $injector) {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      $injector.get('ngxAnalyticsService').isAnalyticsEnabled().toPromise().then(function (isAnalyticsEnabled) {
        if (isAnalyticsEnabled) {
          $injector.get('ngxEntityVisitedService').handleEntityVisitedEvent(new Date(), $scope.entityId, $scope.entityType);
        }
      });
    }
  }
}

)(angular);

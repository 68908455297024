<a *ngIf="article.teaserImage?.fileId && article.published" class="sender-avatar"
   ng-class="isMobile ? 'md' : 'xl'" [href]="articleLink">
  <coyo-image-reference [fileId]="article.teaserImage.fileId"
                        [senderId]="article.teaserImage.senderId"
                        [sizeDefinitions]="{'default': 'S', 'screen-lg': 'M'}"></coyo-image-reference>
</a>
<div class="share-item-message" *ngIf="article.published">
  <a class="share-item-title" [href]="articleLink">
    {{article.title}}
  </a>
  <div class="share-item-meta">
    <span translate="MODULE.TIMELINE.SHARE.POSTED_IN"></span>
    <coyo-app-href [app]="article.app">
      <i class="zmdi zmdi-collection-text"></i>&nbsp;{{article.app.name}}
    </coyo-app-href>
    <span translate="BY"></span>
    <coyo-sender-link *ngIf="author" [sender]="author">
      <i class="zmdi zmdi-{{authorIcon}}"></i>&nbsp;
    </coyo-sender-link>
  </div>
  <div class="share-item-description">
    <p class="share-item-teaser" [innerHtml]="article.teaserText | hashtags"></p>
    <a class="share-item-more" translate="APP.BLOG.READ_MORE" [href]="articleLink"></a>
  </div>
</div>
<div class="share-item-message" *ngIf="!article.published">
  <div class="alert alert-warning">
    <span translate="MODULE.TIMELINE.BLOG.WARNING"></span>
  </div>
</div>

<div class="toolbar-container">

  <div class="toolbar-left">

    <button type="button" (click)="previousPage()" [class.disabled]="currentPage <= 1" [disabled]="currentPage <= 1">
      <i class="zmdi zmdi-arrow-left"></i>
    </button>

    <button type="button" (click)="nextPage()" [class.disabled]="currentPage >= maxPages" [disabled]="currentPage >= maxPages">
      <i class="zmdi zmdi-arrow-right"></i>
    </button>

    <span>
      <input (keypress)="isNumber($event)" type="number" #box [value]="currentPage" min="1" [max]="maxPages" size="4" (keyup.enter)="onEnter(box.value)"> {{ 'PDFVIEWER.OF' | translate }} {{maxPages}}
    </span>

  </div>

  <div class="toolbar-center">

    <button type="button" (click)="zoomOut()">
      <i class="zmdi zmdi-minus"></i>
    </button>

    <button type="button" (click)="zoomIn()">
      <i class="zmdi zmdi-plus"></i>
    </button>

    <select [ngModel]="activeSetting" (ngModelChange)="updateActiveSetting($event)">
      <option *ngFor="let preset of presets" [ngValue]="preset" [selected]="preset === activeSetting" [translate]="preset.title"></option>
    </select>

    <button type="button" (click)="rotateLeft()">
      <i class="zmdi zmdi-rotate-left"></i>
    </button>

    <button type="button" (click)="rotateRight()">
      <i class="zmdi zmdi-rotate-right"></i>
    </button>

  </div>

  <div class="toolbar-right"></div>

</div>

<div class="loading-bar-container" [class.is-loading]="loading"></div>

<div *ngIf="url$ | async as url"
     class="plugin-wrapper"
     [class.plugin-ratio]="ratio$ | async"
     [style.padding-top.%]="ratio$ | async">
  <coyo-iframe class="plugin-content"
               [url]="url"
               [title]="config.name"
               [sandbox]="sandboxSettings"
               [scrolling]="scrolling$ | async"
               [style.height.px]="height$ | async"
               [origins]="origin$ | async"
               (iFrameMessage)="receive($event)">
  </coyo-iframe>
</div>

(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.admin.license
   *
   * @description
   * # Admin license management module #
   * The admin license management module provides views to manage license and subscriptions.
   */
  ModuleConfig.$inject = ["$stateProvider", "adminLicenseConfig"];
  angular
      .module('coyo.admin.license', [
        'coyo.base',
        'coyo.domain'
      ])
      .config(ModuleConfig)
      .constant('adminLicenseConfig', {
        templates: {
          home: 'app/modules/admin/license/admin.license.html',
          subscription: 'app/modules/admin/license/views/subscription/admin.subscription.html',
          license: 'app/modules/admin/license/views/license/admin.license.html'
        }
      });

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, adminLicenseConfig) {
    $stateProvider.state('admin.license', {
      url: '/license',
      templateUrl: adminLicenseConfig.templates.home,
      data: {
        globalPermissions: 'MANAGE_LICENSE',
        pageTitle: 'ADMIN.MENU.LICENSE'
      }
    }).state('admin.license.subscription', {
      url: '/subscription',
      views: {
        '@admin.license': {
          templateUrl: adminLicenseConfig.templates.subscription,
          controller: 'AdminSubscriptionController',
          controllerAs: '$ctrl'
        }
      }
    }).state('admin.license.license', {
      url: '/license',
      views: {
        '@admin.license': {
          templateUrl: adminLicenseConfig.templates.license,
          controller: 'AdminLicenseController',
          controllerAs: '$ctrl'
        }
      }
    });
  }

})(angular);

(function () {
  'use strict';

  ColleagueGuessesController.$inject = ["rankingForUser", "rankingForAll", "$rootScope", "moment"];
  angular
      .module('coyo.apps.championship')
      .controller('ColleagueGuessesController', ColleagueGuessesController);

  function ColleagueGuessesController(rankingForUser, rankingForAll, $rootScope, moment) {
    var vm = this;

    vm.$onInit = onInit;
    vm.onChange = onChange;

    function onInit() {
      vm.userRanking = rankingForUser;
      vm.page = rankingForAll;
      groupGames(vm.page);
    }

    function onChange(page) {
      return page.page(page._queryParams._page).then(groupGames);
    }

    function groupGames(page) {
      vm.groupedGames = _.groupBy(page.content, function (game) {
        return moment(game.date).startOf('day').format($rootScope.dateFormat.long);
      });
      vm.dates = _.keys(vm.groupedGames);
    }
  }

})();

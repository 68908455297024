import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {DownloadButtonComponent} from './download-button/download-button.component';
import {DownloadDirective} from './download.directive';

/**
 * Module responsible for downloads
 */
@NgModule({
  imports: [
    CommonModule,
    UpgradeModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    DownloadDirective,
    DownloadButtonComponent
  ],
  exports: [
    DownloadDirective,
    DownloadButtonComponent
  ]
})
export class DownloadModule {}

(function (angular) {
  'use strict';

  AdminWidgetListController.$inject = ["authService", "ngxFeatureToggleService"];
  angular.module('coyo.admin.apps-widgets')
      .controller('AdminWidgetListController', AdminWidgetListController);

  function AdminWidgetListController(authService, ngxFeatureToggleService) {
    var vm = this;
    vm.$onInit = onInit;
    vm.canManagePlugins = false;
    vm.isPluginAvailable = false;

    function onInit() {
      authService.onGlobalPermissions('MANAGE_PLUGINS', function (permission) {
        vm.canManagePlugins = permission;
      });
      ngxFeatureToggleService.getFeatureState('plugin').toPromise().then(function (plugin) {
        vm.isPluginAvailable = plugin.states.available;
      });
    }
  }
})(angular);

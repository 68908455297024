import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EventDateSyncService} from '@app/events/event-date-sync.service';
import {EventDates} from '@app/events/event-dates';
import {rteOptions} from '@app/events/rte-options';
import {EventService} from '@domain/event/event.service';
import {GuestSelection} from '@domain/guest/guest-selection';
import {Sender} from '@domain/sender/sender';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {FindOptions, SelectSenderOptions} from '@shared/sender-ui/select-sender/select-sender-options';
import {UserChooserSelection} from '@shared/sender-ui/user-chooser/user-chooser-selection';
import {NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import * as _ from 'lodash';
import * as moment from 'moment';
import {NgxPermissionsService} from 'ngx-permissions';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'coyo-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventCreateComponent implements OnInit, OnDestroy {

  /**
   * A given sender used as initial sender for the form.
   */
  @Input() initialSender: Sender;

  /**
   * If true the sender is not changable.
   */
  @Input() lockSender: boolean;

  /**
   * An attribute to mark the event as public or private.
   */
  @Input() public: boolean;

  /**
   * The form result of the general settings.
   */
  @Output() formResult: EventEmitter<object> = new EventEmitter<object>();

  eventCreateForm: FormGroup;
  eventDates: EventDates = {
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  };
  step: number = 1;
  canInviteGroups$: Observable<boolean>;
  canInviteWorkspaces$: Observable<boolean>;
  canInvitePages$: Observable<boolean>;
  selectedGuestsStorage: GuestSelection[] = [];

  readonly senderSelectOptions: SelectSenderOptions = {
    senderTypes: ['user', 'page', 'workspace'],
    findOptions: FindOptions.MANAGED_SENDERS_ONLY
  };

  readonly eventDescriptionRteOptions: object = rteOptions;

  constructor(@Inject(NG1_STATE_SERVICE) private stateService: IStateService,
              private permissionService: NgxPermissionsService,
              private eventDateSyncService: EventDateSyncService,
              private eventService: EventService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.canInviteGroups$ = from(this.permissionService.hasPermission('PERMIT_GROUP_INVITES'))
        .pipe(map(permission => permission));
    this.canInviteWorkspaces$ = from(this.permissionService.hasPermission('PERMIT_WORKSPACE_INVITES'))
        .pipe(map(permission => permission));
    this.canInvitePages$ = from(this.permissionService.hasPermission('PERMIT_PAGE_INVITES'))
      .pipe(map(permission => permission));
    this.initForm();
    this.initDates();
  }

  ngOnDestroy(): void {
    this.formResult.complete();
  }

  /**
   * Called after rte content changes.
   *
   * @param content The new content of the rte.
   */
  descriptionChanges(content: any): void {
    this.eventCreateForm.patchValue({description: content});
  }

  /**
   * Cancel form and return to origin state.
   */
  cancelForm(): void {
    let state = 'main.event';
    let params = {};
    if (!!this.stateService['previous']) {
      const prevState = this.stateService['previous'];
      state = prevState.name;
      params = prevState.params;
    }
    this.stateService.go(state, params);
  }

  /**
   * Submit form emits the form values.
   */
  submitForm(): void {
    const newEvent = this.eventCreateForm.getRawValue();
    if (newEvent.limitedParticipants) {
      newEvent.limitedParticipants = {
        participantsLimit: newEvent.participantsLimit
      };
    } else {
      newEvent.limitedParticipants = null;
    }
    if (newEvent.host.typeName === 'user') {
      newEvent.admins.push({id: newEvent.host.id, typeName: 'user'});
    }
    newEvent.hostId = {id: newEvent.host.id, typeName: newEvent.host.typeName};
    delete newEvent.host;
    delete newEvent.participantsLimit;
    newEvent.startDate = moment(newEvent.startDate).format('YYYY-MM-DDTHH:mm:ss');
    newEvent.endDate = moment(newEvent.endDate).format('YYYY-MM-DDTHH:mm:ss');
    this.eventService.createEvent(newEvent).subscribe(
      (response: any) => {
        this.stateService.go('main.event.show', {idOrSlug: response.event.id});
      },
      () => {
        this.stateService.go('main.event.create', {public: this.public});
      });
  }

  /**
   * Sync start and end date.
   */
  updateStartDate(): void {
    this.eventDateSyncService.updateWithStartDate(this.eventDates);
    this.updateEventDates();
  }

  /**
   * Sync start and end date.
   */
  updateEndDate(): void {
    this.eventDateSyncService.updateWithEndDate(this.eventDates);
    this.updateEventDates();
  }

  /**
   * Sync start and end time.
   */
  updateStartTime(): void {
    this.eventDateSyncService.updateWithStartTime(this.eventDates);
    this.updateEventDates();
  }

  /**
   * Sync start and end time.
   */
  updateEndTime(): void {
    this.eventDateSyncService.updateWithEndTime(this.eventDates);
    this.updateEventDates();
  }

  /**
   * Increase form step.
   */
  nextStep(): void {
    this.step++;
  }

  /**
   * Decrease form step.
   */
  previousStep(): void {
    this.step = Math.max(1, this.step - 1);
  }

  /**
   * Save the selected guests state and go to previous step.
   *
   * @param selectedGuests The current selected guests
   */
  saveUserChooserStateAndGoToPreviousStep(selectedGuests: GuestSelection[]): void {
    this.selectedGuestsStorage = selectedGuests;
    this.previousStep();
  }

  /**
   * Add selected invitees to form and submit it.
   *
   * @param selection The user chooser selection.
   */
  addInvitedUsers(selection: UserChooserSelection): void {
    const members = [
      ..._.map(selection.memberIds, id => ({id: id, typeName: UserChooserSelection.MEMBER_TYPE})),
      ..._.map(selection.memberGroupIds, id => ({id: id, typeName: UserChooserSelection.MEMBER_GROUP_TYPE})),
      ..._.map(selection.memberPageIds, id => ({id: id, typeName: UserChooserSelection.MEMBER_PAGE_TYPE})),
      ..._.map(selection.memberWorkspaceIds, id => ({id: id, typeName: UserChooserSelection.MEMBER_WORKSPACE_TYPE}))
    ];
    this.eventCreateForm.patchValue({members: members});
    this.submitForm();
  }

  private initDates(): void {
    const currentDate = new Date();

    this.eventDates.startDate = currentDate;
    const startTime = new Date(currentDate);
    startTime.setHours(currentDate.getHours() + 1, 0, 0, 0);
    this.eventDates.startTime = startTime;
    this.eventDates.startDate = startTime;

    this.eventDates.endDate = currentDate;
    const endTime = new Date(currentDate);
    endTime.setHours(startTime.getHours() + 1, 0, 0, 0);
    this.eventDates.endTime = endTime;
    this.eventDates.endDate = endTime;

    this.updateEventDates();
  }

  private initForm(): void {
    this.eventCreateForm = this.formBuilder.group({
      public: [this.public],
      name: new FormControl('', [CoyoValidators.notBlank, Validators.maxLength(255)]),
      host: new FormControl(this.initialSender, [Validators.required]),
      place: [''],
      description: [''],
      fullDay: [false],
      startDate: new FormControl(null, [Validators.required]),
      endDate: new FormControl(null, [Validators.required]),
      showParticipants: [false],
      requestDefiniteAnswer: [false],
      limitedParticipants: [false],
      admins: [[]],
      members: [[]],
      participantsLimit: [0]
    });
    this.eventCreateForm.get('limitedParticipants').valueChanges.subscribe(limitedParticipants => {
      if (limitedParticipants) {
        this.eventCreateForm.get('participantsLimit').setValidators([Validators.required, Validators.min(1)]);
      } else {
        this.eventCreateForm.get('participantsLimit').clearValidators();
      }
      this.eventCreateForm.get('participantsLimit').updateValueAndValidity();
    });

    if (this.lockSender) {
      this.eventCreateForm.controls.host.disable();
    }
  }

  private updateEventDates(): void {
    this.eventCreateForm
      .patchValue({startDate: this.setTimeForDate(this.eventDates.startDate, this.eventDates.startTime)});
    this.eventCreateForm
      .patchValue({endDate: this.setTimeForDate(this.eventDates.endDate, this.eventDates.endTime)});
  }

  private setTimeForDate(dateToUpdate: Date, time: Date): Date {
    const date = new Date(dateToUpdate.getTime());
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(0, 0);
    return date;
  }
}

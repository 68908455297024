import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Skeleton} from '@coyo/ui';
import {DocumentService} from '@domain/file/document/document.service';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UpdateWidgetSettings} from '@widgets/api/widget.actions';
import {DownloadWidget} from '@widgets/download/download-widget';
import {DownloadWidgetFile} from '@widgets/download/download-widget-file';
import {DownloadWidgetService} from '@widgets/download/download-widget/download-widget.service';
import * as _ from 'lodash';
import {merge, Observable, of, Subject} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {DownloadWidgetSettings} from '../download-widget-settings';

interface DownloadsWidgetState {
  files: DownloadWidgetFile[];
  loading: boolean;
}

/**
 * The download widget component.
 */
@Component({
  selector: 'coyo-download-widget',
  templateUrl: './download-widget.component.html',
  styleUrls: ['./download-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadWidgetComponent extends WidgetComponent<DownloadWidget> implements OnInit, OnChanges {

  private changes$: Subject<DownloadWidget> = new Subject<DownloadWidget>();

  state$: Observable<DownloadsWidgetState>;

  readonly skeletons: Skeleton[] = [{
    top: 10, left: 12, height: 20, width: 20
  }, {
    top: 10, left: 44, height: 20, width: 'calc(100% - 44px)'
  }];

  constructor(changeDetectorRef: ChangeDetectorRef, private downloadWidgetService: DownloadWidgetService,
              private documentService: DocumentService, private store: Store) {
    super(changeDetectorRef);
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.widget &&
      !_.isEqual(simpleChanges.widget.currentValue.settings._files, simpleChanges.widget.previousValue.settings._files)) {
      this.changes$.next(simpleChanges.widget.currentValue);
    } else if (simpleChanges.editMode) {
      this.changes$.next(this.widget);
    }
  }

  ngOnInit(): void {
    this.state$ = merge(this.getFiles(this.widget), this.changes$.asObservable()
      .pipe(switchMap(widget => this.getFiles(widget, true))))
      .pipe(map(files => _.sortBy(files, file => file.title || file.name)))
      .pipe(map(files => ({files, loading: false})))
      .pipe(startWith({files: [], loading: true}));
  }

  /**
   * Changes the files title or remove the json field if the title is empty
   *
   * @param event
   * The current title
   *
   * @param file
   * The file to be changed
   */
  onTitleChange(event: string, file: DownloadWidgetFile): void {
    if (event) {
      this.updateSettings({
        ...file,
        title: event
      });
    } else {
      this.updateSettings({
        ...file,
        title: undefined
      });
    }
  }

  private updateSettings(file: DownloadWidgetFile): void {
    const index = this.widget.settings._files.findIndex(value => value.id === file.id);
    const files = [].concat(this.widget.settings._files);
    files[index] = file;

    this.store.dispatch(new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId, {
      ...this.widget.settings,
      _files: files
    } as DownloadWidgetSettings, this.editScope));
  }

  /**
   * Gets the download url for a file
   *
   * @param file
   * The file
   *
   * @return
   * The download url
   */
  getLink(file: DownloadWidgetFile): string {
    return this.documentService.getDownloadUrl(file);
  }

  /**
   * Gets the file name.
   *
   * @param file
   * The file
   *
   * @return
   * The title of the file if it is set else the name.
   */
  getName(file: DownloadWidgetFile): string {
    return file.title || file.name;
  }

  private getFiles(widget: DownloadWidget, skipRequest?: boolean): Observable<DownloadWidgetFile[]> {
    if (!this.editMode) {
      return this.downloadWidgetService.getAccessibleFiles(widget.id);
    } else if (widget.id && !skipRequest) {
      return this.addNameAndTitleToSettings(widget);
    } else {
      return of(widget.settings._files);
    }
  }

  private addNameAndTitleToSettings(widget: DownloadWidget): Observable<DownloadWidgetFile[]> {
    return this.downloadWidgetService.getAccessibleFiles(widget.id).pipe(map(files => {
      const settings = {...widget.settings};
      const newFiles = settings._files.map(file => {
        const found = files.find(newFile => newFile.id === file.id);
        return found ? {...found, ...file} : null;
      }).filter(Boolean);

      this.store.dispatch(new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId,
        {...settings, _files: newFiles} as DownloadWidgetSettings, this.editScope));
      return newFiles;
    }));
  }
}

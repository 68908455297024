import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {EntityId} from '@domain/entity-id/entity-id';
import {GuestSelection} from '@domain/guest/guest-selection';
import {UserChooserSelectionConfig} from '@domain/guest/user-chooser-selection-config';
import {UserChooserService} from '@domain/guest/user-chooser.service';
import {TranslateService} from '@ngx-translate/core';
import {WINDOW} from '@root/injection-tokens';
import {SelectedGuestsDataSource} from '@shared/sender-ui/user-chooser/data-source/selected-guests-data-source';
import {UserChooserDataSource} from '@shared/sender-ui/user-chooser/data-source/user-chooser-data-source';
import {UserChooserSelection} from '@shared/sender-ui/user-chooser/user-chooser-selection';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';

@Component({
  selector: 'coyo-user-chooser-view',
  templateUrl: './user-chooser.component.html',
  styleUrls: ['./user-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserChooserComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * The initial guest selection. Can be used to initialize the guest list with guests.
   */
  @Input()
  initialSelection?: GuestSelection[] = [];

  /**
   * A entity id of an existing sender with probably existing selected users.
   * This data will be used to call a sender specific endpoint.
   */
  @Input()
  existingSender?: EntityId = null;

  /**
   * Flag to ignore already invited users for pre selection (typical use case: second invitation as admin).
   */
  @Input()
  ignoreAlreadyInvited?: boolean = false;

  /**
   * Hides the guest list if set to false. Default is true.
   */
  @Input()
  showGuestList?: boolean = true;

  /**
   * Config for the api endpoint to determine which contents should be returned.
   * Default: all false, except users.
   */
  @Input()
  config?: UserChooserSelectionConfig = {users: true, groups: false, pages: false, workspaces: false};

  /**
   * Set another title with a provided i18n key.
   * If no key is given the title will be set to a default.
   */
  @Input()
  titleTranslationKey?: string = 'USER.CHOOSER.TITLE';

  /**
   * Shows additional information on empty user chooser list screen. Provide a i18n key.
   * There are no additional information on default.
   */
  @Input()
  additionalEmptyListTranslationKey?: string = '';

  /**
   * The translation key for the final submit button. Provide a i18n key.
   */
  @Input()
  submitButtonTranslationKey?: string = 'USER_CHOOSER.BUTTON_TITLE.INVITE_USER';

  /**
   * Emits all ids of the selected guests.
   */
  @Output()
  result: EventEmitter<UserChooserSelection> = new EventEmitter<UserChooserSelection>();

  /**
   * Emits if the user wants to return to the origin of the form.
   */
  @Output()
  goBack: EventEmitter<GuestSelection[]> = new EventEmitter<GuestSelection[]>();

  @ViewChild('chooserRoot')
  private chooserRoot: ElementRef;

  isMobile: boolean = false;
  step: number = 1;
  reviewAreaImage: string = '/assets/images/user-chooser/guest-list-default.svg';
  searchBarPlaceholder: string = '';
  searchInput: FormControl = new FormControl();
  userChooserDataSource$: BehaviorSubject<UserChooserDataSource> = new BehaviorSubject<UserChooserDataSource>(null);
  selectedGuestsDataSource$: BehaviorSubject<SelectedGuestsDataSource> = new BehaviorSubject<SelectedGuestsDataSource>(null);
  selectedGuestsList$: BehaviorSubject<GuestSelection[]> = new BehaviorSubject([]);
  initialSearch: boolean = true;

  private selectedGuestsList: GuestSelection[] = [];
  private selectedGuestsDataSource: SelectedGuestsDataSource;
  private userChooserDataSource: UserChooserDataSource;
  private searchInputSubscription: Subscription;
  private observeScreenChangeSubscription: Subscription;
  private navbarHeight: number = 50;
  private subNavbarHeight: number = 0;
  private contentPadding: number = 32;

  constructor(private translateService: TranslateService,
              private userChooserService: UserChooserService,
              private windowSizeService: WindowSizeService,
              @Inject(WINDOW) private window: Window) {
  }

  ngOnInit(): void {
    this.observeScreenChangeSubscription = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS || screenSize === ScreenSize.SM))
      .subscribe(isMobile => this.isMobile = isMobile);
    this.searchInputSubscription = this.searchInput.valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        startWith('') // empty term requests all
      )
      .subscribe(searchTerm => this.search(searchTerm));
    this.selectedGuestsDataSource = new SelectedGuestsDataSource(this.selectedGuestsList$);
    this.selectedGuestsDataSource$.next(this.selectedGuestsDataSource);
    this.selectedGuestsList = this.initialSelection;
    this.selectedGuestsList$.next(this.selectedGuestsList);

    this.searchBarPlaceholder = this.createSearchBarPlaceholder();
  }

  ngAfterViewInit(): void {
    this.getCurrentHeights();
    this.calculateAndSetHeight();
  }

  ngOnDestroy(): void {
    this.searchInputSubscription.unsubscribe();
    this.observeScreenChangeSubscription.unsubscribe();

    this.selectedGuestsList$.complete();

    this.userChooserDataSource.disconnect();
    this.userChooserDataSource$.complete();

    this.selectedGuestsDataSource.disconnect();
    this.selectedGuestsDataSource$.complete();
  }

  onResize(): void {
    this.calculateAndSetHeight();
  }

  /**
   * Increase form step.
   */
  nextStep(): void {
    this.step++;
  }

  /**
   * Decrease form step.
   */
  previousStep(): void {
    this.step = Math.max(0, this.step - 1);
    if (this.step === 0) {
      this.returnToOrigin();
    }
  }

  /**
   * Disconnects from the old data source and create a new one with the current search term.
   *
   * @param searchTerm The search term
   */
  search(searchTerm: string): void {
    if (this.userChooserDataSource) {
      this.userChooserDataSource.disconnect();
    }
    this.userChooserDataSource = new UserChooserDataSource(
      this.userChooserService,
      searchTerm,
      this.config,
      this.ignoreAlreadyInvited,
      this.existingSender,
      this.selectedGuestsList$,
      this.initialSearch
    );
    this.userChooserDataSource$.next(this.userChooserDataSource);
    this.initialSearch = false;
  }

  /**
   * Add or remove a guest from the selection list.
   *
   * @param guest The guest with the selected state.
   */
  updateInviteList(guest: GuestSelection): void {
    if (guest.selected) {
      this.selectedGuestsList.push(guest);
    } else {
      const index = this.selectedGuestsList.findIndex(x => x.id === guest.id);
      if (index > -1) {
        this.selectedGuestsList.splice(index, 1);
      }
    }
    this.selectedGuestsList$.next([...this.selectedGuestsList].reverse());
  }

  /**
   * Submit the user chooser data by emitting the transformed guest list.
   */
  submit(): void {
    const result = new UserChooserSelection();
    this.selectedGuestsList.forEach(guest => {
      switch (guest.typeName) {
        case UserChooserSelection.MEMBER_TYPE:
          result.memberIds.push(guest.id);
          break;
        case UserChooserSelection.MEMBER_GROUP_TYPE:
          result.memberGroupIds.push(guest.id);
          break;
        case UserChooserSelection.MEMBER_PAGE_TYPE:
          result.memberPageIds.push(guest.id);
          break;
        case UserChooserSelection.MEMBER_WORKSPACE_TYPE:
          result.memberWorkspaceIds.push(guest.id);
          break;
      }
    });
    this.result.emit(result);
  }

  /**
   * Evaluates if the next button is a submit button or not.
   *
   * @return the evaluation as boolean.
   */
  isNextButtonSubmitButton(): boolean {
    return this.step === 1 && !this.isMobile || this.step === 2 && this.isMobile;
  }

  private getCurrentHeights(): void {
    const navbarElement = document.getElementsByClassName('navbar-main').item(0) as HTMLElement;
    if (navbarElement && navbarElement.offsetHeight) {
      this.navbarHeight = navbarElement.offsetHeight;
    }

    const subNavbarElement = document.getElementsByClassName('sub-navigation').item(0) as HTMLElement;
    if (subNavbarElement && subNavbarElement.offsetHeight) {
      this.subNavbarHeight = subNavbarElement.offsetHeight;
    }

    const content = document.getElementsByClassName('content').item(0);
    if (content) {
      const contentStyle = window.getComputedStyle(content);
      if (contentStyle) {
        this.contentPadding = parseInt(contentStyle.getPropertyValue('padding-top'), 10);
      }
    }
  }

  private calculateAndSetHeight(): void {
      let totalHeight = 0;
      if (this.isMobile) {
        totalHeight = this.window.innerHeight - (this.navbarHeight + (this.contentPadding - this.navbarHeight) * 2);
      } else if (!this.isMobile) {
        totalHeight = this.window.innerHeight - (this.navbarHeight + this.subNavbarHeight + this.contentPadding * 2);
      }
      this.chooserRoot.nativeElement.style.height = totalHeight + 'px';
  }

  private createSearchBarPlaceholder(): string {
    const translationKey = 'USER.CHOOSER.PLACEHOLDER.';
    const values = [];
    let text = this.translateService.instant(translationKey + 'SEARCH');

    for (const key in this.config) {
      if (this.config.hasOwnProperty(key) && !!this.config[key]) {
        values.push(key.toUpperCase());
      }
    }

    for (let i = 1; i <= values.length; i++) {
      if (i > 1 && i !== values.length && values.length > 1) {
        text += ', ';
      } else if (i === values.length && values.length > 1) {
        text += this.translateService.instant(translationKey + 'AND');
      }
      text += this.translateService.instant(translationKey + values[i - 1]);
    }
    return text;
  }

  private returnToOrigin(): void {
    this.goBack.emit(this.selectedGuestsList);
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {NotificationItemSkeletonComponent} from './notification-item-skeleton/notification-item-skeleton.component';
import './notification-item-skeleton/notification-item-skeleton.component.downgrade';

/**
 * Feature module for all notification related stuff
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    NotificationItemSkeletonComponent
  ],
  exports: [],
  entryComponents: [
    NotificationItemSkeletonComponent
  ]
})
export class NotificationsModule {
}

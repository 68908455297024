import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {WINDOW} from '@root/injection-tokens';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {Observable} from 'rxjs';
import {NOTIFICATION_INTERVALS} from './notification-intervals';
import {NotificationsSettings} from './notifications-settings';
import {NotificationsSettingsService} from './notifications-settings.service';

@Component({
  selector: 'coyo-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit {
  notificationSettings$: Observable<NotificationsSettings[]>;
  browserNotificationsActive: boolean;
  notificationIntervals: {value: string, name: string}[] = NOTIFICATION_INTERVALS;

  constructor(private notificationsSettingsService: NotificationsSettingsService,
              private notificationService: NotificationService,
              @Inject(WINDOW) private window: Window) { }

  ngOnInit(): void {
    this.getBrowserNotificationsFlag((this.window as any).Notification.permission);
    this.notificationSettings$ = this.notificationsSettingsService.getNotificationsSettings();
  }

  /**
   * Updates the channel state when the user changes the settings.
   * @param setting the notification settings
   */
  changeState(setting: NotificationsSettings): void {
    setting.active = !setting.active;
    this.notificationsSettingsService.updateNotificationSettings(setting).subscribe(() => {
      this.notificationService.success('MODULE.ACCOUNT.NOTIFICATION_SETTINGS.TOAST');
    });
  }

  /**
   * Updates the notification property state when the user changes the settings.
   * @param setting the notification settings
   * @param notificationKey the notification property key
   * @param notificationValue the corresponding notification property value
   */
  changeProperty(setting: NotificationsSettings, notificationKey: string, notificationValue: boolean): void {
    setting.properties.notifications[notificationKey] = !notificationValue;
    this.notificationsSettingsService.updateNotificationSettings(setting).subscribe(() => {
      this.notificationService.success('MODULE.ACCOUNT.NOTIFICATION_SETTINGS.TOAST');
    });
  }

  /**
   * Updates the email notification interval when the user changes it.
   * @param setting the notification settings
   */
  submit(setting: NotificationsSettings): void {
    this.notificationsSettingsService.updateNotificationSettings(setting).subscribe(() => {
      this.notificationService.success('MODULE.ACCOUNT.NOTIFICATION_SETTINGS.TOAST');
    });
  }

  /**
   * Returns the icon name according to the notification channel.
   * @param channel the notification channel
   * @returns the corresponding icon name
   */
  getChannelIcon(channel: string): string {
    switch (channel) {
      case 'BROWSER': return 'website';
      case 'PUSH': return 'push-notifications';
      case 'SOUND': return 'volume';
      case 'EMAIL': return 'envelope';
    }
  }

  private getBrowserNotificationsFlag(browserNotificationsPermission: string): void {
    switch (browserNotificationsPermission) {
      case 'granted':
        this.browserNotificationsActive = true;
        break;
      default:
      case 'denied':
        this.browserNotificationsActive = false;
        break;
    }
  }
}

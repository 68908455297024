import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {environment} from '@root/environments/environment';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error: Error | HttpErrorResponse): void {
    if (!environment.production && error instanceof HttpErrorResponse) {
     // do nothing
    } else {
      // tslint:disable-next-line:no-console
      this.injector.get<{error(...params: any): void}>(NGXLogger, {error: console.error}).error(error);
    }
  }
}

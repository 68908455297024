(function () {
  'use strict';

  wikiArticleTranslationService.$inject = ["$q", "$timeout", "$injector", "SettingsModel", "ngxMultiLanguageService"];
  angular.module('coyo.apps.wiki')
      .factory('wikiArticleTranslationService', wikiArticleTranslationService);

  /**
   * @ngdoc service
   * @name coyo.apps.wiki.wikiArticleTranslationService
   *
   * @description
   * This service provides methods to handle translations.
   *
   * @requires $q
   * @requires $timeout
   * @requires $injector
   * @requires coyo.domain.SettingsModel
   * @required ngxMultiLanguageService
   */
  function wikiArticleTranslationService($q, $timeout, $injector, SettingsModel, ngxMultiLanguageService) {
    return {
      initLanguages: initLanguages,
      prepareTranslations: prepareTranslations,
      onLanguageDeleted: onLanguageDeleted,
      onLanguageChange: onLanguageChange,
      isTranslationRequired: isTranslationRequired,
      updateValidity: updateValidity
    };

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#initLanguages
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Initializes and prepares everything required for multi-language support
     */
    function initLanguages(vm, currentUser, preferredLanguage) {
      vm.languageInitialised = {};
      vm.isSenderTranslated = vm.sender.isSenderTranslated();
      vm.defaultLanguage = vm.sender.getDefaultLanguage();
      vm.currentLanguage = _.isUndefined(vm.currentLanguage) ? vm.defaultLanguage : vm.currentLanguage;
      return ngxMultiLanguageService.isEnabled().toPromise().then(function (result) {
        vm.multiLanguageActive = result;

        if (!vm.article.defaultLanguage) {
          vm.article.defaultLanguage = vm.defaultLanguage;
        }

        if (vm.multiLanguageActive && vm.currentLanguage === 'NONE') {
          vm.currentLanguage = vm.defaultLanguage;
        }

        _initializeAllAvailableLanguages(vm);

        _setActiveAndNotEmptyTranslationKeys(vm);

        _setBestSuitableCurrentLanguage(vm, currentUser, preferredLanguage);
        vm.languageInitialised[vm.currentLanguage] = true;
        return $q.defer();
      });
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#initializeAllAvailableLanguages
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Extract all available languages and initialize with their translations or with an empty translation structure
     */
    function _initializeAllAvailableLanguages(vm) {
      var availableLanguages = _.concat(_.keys(vm.sender.translations), vm.defaultLanguage);
      vm.languages = _.zipObject(availableLanguages, _.map(availableLanguages, function () {
        return {active: true, translations: {}, empty: true};
      }));
      // add default translations of article or init, if article not already translated
      vm.languages[(vm.article.defaultLanguage) ? vm.article.defaultLanguage : vm.currentLanguage] = {
        'active': true,
        'translations': {
          'title': vm.article.title ? vm.article.title : ''
        },
        'empty': false
      };

      // add all remaining translations of the article
      _.forEach(vm.article.translations, function (value, key) {
        if (vm.languages[key] && !_.isEmpty(value)) {
          vm.languages[key].translations = value;
          vm.languages[key].empty = false;
        } else {
          vm.languages[key].empty = true;
        }
      });
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#getActiveAndNotEmptyTranslationKeys
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Extract the language keys of active and not empty translations
     */
    function _setActiveAndNotEmptyTranslationKeys(vm) {
      vm.translations = [];
      if (vm.multiLanguageActive === false) {
        vm.translations.push(vm.defaultLanguage);
      } else {
        _.forEach(vm.languages, function (value, key) {
          if (value.active && Object.keys(value.translations).length) {
            vm.languageInitialised[key] = true;
            vm.translations.push(key);
          }
        });
      }
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#getBestSuitableCurrentLanguage
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Determine the best suitable language for the current user. It is possible to pass a preferred language.
     */
    function _setBestSuitableCurrentLanguage(vm, currentUser, preferredLanguage) {
      if (vm.multiLanguageActive === false) {
        vm.currentLanguage = vm.article.defaultLanguage;
      } else {
        vm.preferredLanguage = preferredLanguage;
        if (angular.isDefined(preferredLanguage)
            && angular.isDefined(vm.languages[preferredLanguage])
            && !vm.languages[preferredLanguage].empty
            && !vm.editMode) {
          vm.currentLanguage = preferredLanguage;
        } else {
          currentUser.getBestSuitableLanguage(vm.translations, SettingsModel.retrieve).then(function (language) {
            if (language !== 'NONE') {
              vm.currentLanguage = language;
            }
          });
        }
      }
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#prepareTranslations
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Prepare the translations before saving the article.
     */
    function prepareTranslations(vm) {
      vm.article.defaultLanguage = (vm.defaultLanguage === 'NONE') ? null : vm.defaultLanguage;

      // -- add translations for default language to article model
      if (vm.languages[vm.defaultLanguage]) {
        angular.forEach(vm.languages[vm.defaultLanguage].translations, function (value, key) {
          vm.article[key] = value;
        });
      }
      // -- add additional translations to article model
      vm.article.translations = {};
      angular.forEach(vm.languages, function (value, key) {
        if (value.active) {
          vm.article.translations[key] = value.translations ? value.translations : {};
        }
      });
      delete vm.article.translations[vm.defaultLanguage];
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#onLanguageDeleted
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Callback for when a language has been removed. The layout for the given language is being collected and then
     * marked to be deleted (will be deleted, once 'save' is triggered).
     *
     * @param {object} language
     * The language key.
     *
     * @returns {object} A promise which is resolved once the layout is being collected and marked as 'to be deleted'.
     */
    function onLanguageDeleted($scope, vm, language) {
      vm.languageInitialised[language] = false;
      return $injector.get('ngxWidgetEditService')
          .markLayoutLanguageForDeletion(vm.app.id, vm.article.buildLayoutName(vm.app.id), language).toPromise();
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#onLanguageChange
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Callback for when a language has been added. If the param is true, the default layout & widgets are being
     * collected and copied to the new language. If the param is false, it starts with an empty layout.
     *
     * @param {boolean} copyFromDefault
     * Whether to copy the content from the default language or not.
     */
    function onLanguageChange(vm, copyFromDefault) {
      vm.languageInitialised[vm.currentLanguage] = true;
      var defaultLayoutName = vm.article.buildNextRevLayoutName(vm.app.id);
      if (copyFromDefault) {
        $injector.get('ngxWidgetEditService')
            .duplicateLayoutForLanguage(vm.app.id, defaultLayoutName, vm.currentLanguage);
      } else {
        var parent = {id: vm.app.id, typeName: 'app'};
        $injector.get('ngxWidgetEditService')
            .initializeEmptyLayoutForLanguage(vm.app.id, defaultLayoutName, vm.currentLanguage, parent);
      }
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#isTranslationRequired
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Check whether translation is required for the given language.
     *
     * @param {object} language
     * The language key.
     *
     * @returns {boolean} A bool indicating whether translation is required or not.
     */
    function isTranslationRequired(vm, language) {
      if (vm.defaultLanguage === 'NONE') {
        return language === 'NONE';
      } else if (vm.currentLanguage === language) {
        return true;
      } else {
        return (vm.languages[language].translations && Object.keys(vm.languages[language].translations).length !== 0);
      }
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleTranslationService#updateValidity
     * @methodOf coyo.apps.wiki.wikiArticleTranslationService
     *
     * @description
     * Update the validation state of the language for the given key.
     *
     * @param {string} key
     * The language key.
     *
     * @param {boolean} valid
     * The validation state.
     */
    function updateValidity(vm, key, valid) {
      vm.languages[key].valid = valid;
    }
  }
})();

import {NgModule} from '@angular/core';
import {FileLibraryFilePickerItemService} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item.service';
import {FileLibraryRootFilePickerItemService} from '@app/file-library/file-library-root-file-picker-item/file-library-root-file-picker-item.service';
import {LandingPagesRootFilePickerItemService} from '@app/file-library/landing-page-root-file-picker-item/landing-pages-root-file-picker-item.service';
import {PagesRootFilePickerItemService} from '@app/file-library/pages-root-file-picker-item/pages-root-file-picker-item.service';
import {SenderRootFilePickerItemService} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item.service';
import {WorkspacesRootFilePickerItemService} from '@app/file-library/workspace-root-file-picker-item/workspaces-root-file-picker-item.service';
import {FILE_PICKER_ITEM_SERVICES} from '@app/file-picker/file-picker-item.service';
import {FileSearchFilePickerItemService} from '@app/file-picker/file-picker-search/file-search-file-picker-item.service';
import {FilePickerModule} from '@app/file-picker/file-picker.module';
import {AutofocusModule} from '@shared/autofocus/autofocus.module';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {FileModule} from '@shared/files/file.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {MaterialModule} from '@shared/material/material.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {FileLibraryContentComponent} from './file-library-content/file-library-content.component';
import {FileLibraryFilePickerItemContextMenuComponent} from './file-library-file-picker-item-context-menu/file-library-file-picker-item-context-menu.component';
import {FileLibraryFilePickerItemPublicShareDialogComponent} from './file-library-file-picker-item-public-share-dialog/file-library-file-picker-item-public-share-dialog.component';
import {FileLibraryFilePickerItemComponent} from './file-library-file-picker-item/file-library-file-picker-item.component';
import './file-library-file-picker-service/file-library-file-picker.service.downgrade';
import {FileLibraryHeaderComponent} from './file-library-header/file-library-header.component';
import {IconNameFilePickerItemComponent} from './icon-name-file-picker-item/icon-name-file-picker-item.component';
import {SenderFilesComponent} from './sender-files/sender-files.component';
import {SenderRootFilePickerItemComponent} from './sender-root-file-picker-item/sender-root-file-picker-item.component';

/**
 * Module for file library file picker implementation.
 */
@NgModule({
  declarations: [
    SenderRootFilePickerItemComponent,
    IconNameFilePickerItemComponent,
    FileLibraryHeaderComponent,
    FileLibraryFilePickerItemComponent,
    FileLibraryFilePickerItemContextMenuComponent,
    FileLibraryFilePickerItemPublicShareDialogComponent,
    FileLibraryContentComponent,
    SenderFilesComponent,
  ],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    MaterialModule,
    SenderUIModule,
    FileModule,
    AutofocusModule,
    FilePickerModule
  ],
  exports: [
    FileLibraryContentComponent,
    SenderFilesComponent
  ],
  providers: [
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: FileLibraryFilePickerItemService, multi: true},
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: FileLibraryRootFilePickerItemService, multi: true},
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: LandingPagesRootFilePickerItemService, multi: true},
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: PagesRootFilePickerItemService, multi: true},
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: FileSearchFilePickerItemService, multi: true},
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: WorkspacesRootFilePickerItemService, multi: true},
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: SenderRootFilePickerItemService, multi: true}
  ],
})
export class FileLibraryModule {
}

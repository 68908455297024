<div class="form-group" [formGroup]="parentForm">
  <label class="col-sm-3 control-label" translate="WIDGETS.DOWNLOADS.SETTINGS.FILES.LABEL" for="select-file"></label>
  <div class="col-sm-9">
    <coyo-select-file *ngIf="sender$ | async; let sender"
                      id="select-file"
                      name="_files"
                      formControlName="_files"
                      [sender]="sender"
                      [attr.aria-invalid]="isInvalid('_files')">
    </coyo-select-file>
  </div>
</div>

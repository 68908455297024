<div class="video-widget">
  <div class="iframe-wrapper" *ngIf="!error" [ngStyle]="{'padding-top': ratio + '%'}">
    <iframe class="video-wrapper" *ngIf="widget.settings._backendData" [src]="videoUrl | safeUrl"
            allowfullscreen mozallowfullscreen webkitallowfullscreen></iframe>
    <div class="video-wrapper" *ngIf="!widget.settings._backendData && videoHtml" [innerHTML]="videoHtml | trustHtml"></div>
  </div>
  <div class="video-error" *ngIf="error">
    <i class="zmdi zmdi-hc-2x zmdi-alert-triangle"></i>
    <p class="m-0"><em>{{'WIDGETS.VIDEO.ERROR' | translate}}</em></p>
  </div>
  <div class="video-info" *ngIf="widget.settings.title || widget.settings.description">
    <h3>{{widget.settings.title}}</h3>
    <div class="text-muted">{{widget.settings.description}}</div>
  </div>
</div>

(function (angular) {
  'use strict';

  angular
      .module('commons.config')
      .constant('defaultThemeColors', {
        'color-primary': '#101d30',
        'color-secondary': '#101d30',
        'color-navbar-border': '#697687',
        'coyo-navbar-text': '#ffffff',
        'coyo-navbar': '#101d30',
        'coyo-navbar-active': '#202d40',
        'btn-primary-color': '#ffffff',
        'btn-primary-bg': '#0073e6',
        'color-background-main': '#e8e8e8',
        'text-color': '#101d30',
        'link-color': '#0073e6'
      });

})(angular);

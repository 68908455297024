import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {SocialModule} from '@shared/social/social.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WidgetsModule} from '@widgets/widgets.module';
import {MomentModule} from 'ngx-moment';
import {BlogArticleHeadlineComponent} from './blog-article-headline/blog-article-headline.component';
import {BlogArticleViewComponent} from './blog-article/blog-article-view.component';
import {newsState} from './news.state';

export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: [newsState]});

/**
 * Module for the engage app blog article view.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    MomentModule,
    SenderUIModule,
    SocialModule,
    uiRouterModuleChild,
    UpgradeModule,
    WidgetsModule
  ],
  declarations: [
    BlogArticleHeadlineComponent,
    BlogArticleViewComponent
  ]
})
export class NewsModule {
}

(function (angular) {
  'use strict';

  UserSuperiorController.$inject = ["errorService"];
  angular
      .module('coyo.profile')
      .component('oyocUserSuperior', userSuperior())
      .controller('UserSuperiorController', UserSuperiorController);

  /**
   * @ngdoc directive
   * @name coyo.profile.coyoUserSuperior:coyoUserSuperior
   * @restrict 'E'
   * @element OWN
   * @scope
   *
   * @description
   * Displays the superior of the given user.
   *
   * @param {object} user
   * the user to render the superior for
   *
   */
  function userSuperior() {
    return {
      templateUrl: 'app/modules/profile/components/user-superior/user-superior.html',
      bindings: {
        user: '<'
      },
      controller: 'UserSuperiorController'
    };
  }

  function UserSuperiorController(errorService) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.user.getManager(vm.user.id).then(function (superior) {
        vm.superior = superior.data;
      }).catch(function (errorResponse) {
        errorService.suppressNotification(errorResponse);
      });
    }
  }

})(angular);

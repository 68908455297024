import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PendingService} from '@core/http/pending/pending.service';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

/**
 * Http interceptor that keeps track of started and completed requests
 * and updates the {@link PendingService.hasPendingRequests} accordingly.
 */
@Injectable()
export class PendingRequestsInterceptor implements HttpInterceptor {

  private pendingRequestsCount: number = 0;

  constructor(private pendingService: PendingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.pendingRequestsCount++;
    this.pendingService.setNextHasPendingRequests(true);
    return next.handle(req).pipe(finalize(() => {
      this.pendingRequestsCount--;
      if (this.pendingRequestsCount === 0) {
        this.pendingService.setNextHasPendingRequests(false);
      }
    }));
  }
}

(function () {
  'use strict';

  wikiArticleService.$inject = ["$log", "$q", "$interval", "ngxPrintPreviewDialogService", "modalService", "WikiArticleModel", "wikiPrintPreviewConfig", "rxjs", "$injector"];
  angular.module('coyo.apps.wiki')
      .factory('wikiArticleService', wikiArticleService);

  /**
   * @ngdoc service
   * @name coyo.apps.wiki.wikiArticleService
   *
   * @description
   * This service provides a common way manage articles.
   *
   * @requires $log
   * @requires $q
   * @requires $interval
   * @requires coyo.base.modalService
   * @requires coyo.apps.wiki.WikiArticleModel
   * @requires coyo.apps.wiki.wikiPrintPreviewConfig
   */
  function wikiArticleService($log, $q, $interval, ngxPrintPreviewDialogService, modalService,
                              WikiArticleModel, wikiPrintPreviewConfig, rxjs, $injector) {
    var lockInterval = {};
    var lockIntervalMilliseconds = 5 * 60 * 1000;

    return {
      deleteArticle: deleteArticle,
      printPreview: printPreview,
      printPreviewWithSubArticles: printPreviewWithSubArticles,
      lock: lock,
      unlock: unlock,
      releaseLock: releaseLock,
      isLocked: isLocked,
      hasLock: hasLock,
      confirmWikiPrintPreview: confirmWikiPrintPreview
    };

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#deleteArticle
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * Opens a modal to delete an article. It checks whether the article has subarticles or is currently the home
     * article of the app. If this is the case warnings are displayed.
     *
     * @param {object} app
     * The app the article belongs to
     *
     * @param {object} article
     * The article to delete
     *
     * @returns {object} a promise which is resolved after the article is deleted.
     */
    function deleteArticle(app, article) {
      var translationContext = {
        title: article.title,
        nbOfArticles: article.wikiArticles,
        nbOfShares: article.shareCount,
        home: app.settings.home === article.id
      };

      return $injector.get('ngxDeleteConfirmationService').openLegacy(
          'APP.WIKI.MODAL.DELETE.TITLE',
          'APP.WIKI.MODAL.DELETE.TEXT',
          'APP.WIKI.MODAL.DELETE.CONFIRM_BUTTON',
          'CANCEL',
          translationContext,
          true
      ).then(function (deleted) {
        if (deleted) {
          return article.delete().then(function () {
            if (app.settings.home === article.id) {
              $log.debug('Deleted home article. Removing article as home from wiki app.');
              app.settings.home = '';
              return app.save().then(function () {
                return true;
              });
            }
            return true;
          });
        }
        return undefined;
      });
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#confirmWikiPrintPreview
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * The preview loading could take a while for large numbers of wiki articles. During the loading the UI
     * is blocked and thus we warn the user before starting. To prevent the confirmation dialog
     * from showing up for just a few wiki articles, this method compares the number of articles
     * to be previewed with a configured threshold.
     *
     * @param {number} count
     * The number of wiki articles to request user's confirmation for - usually the number of children + 1
     * for the article itself.
     *
     * @returns {object} promise that's only resolved if the user confirmed the preview or if the number
     * of articles is less than the configured threshold.
     */
    function confirmWikiPrintPreview(count) {
      if (count >= wikiPrintPreviewConfig.printPreviewWarningArticlesCount) {
        return modalService.confirm({
          title: 'APP.WIKI.EXPORT.LONG_RUNNING_CONFIRMATION.TITLE',
          text: 'APP.WIKI.EXPORT.LONG_RUNNING_CONFIRMATION.TEXT',
          translationContext: {
            count: count
          }
        }).result;
      } else {
        return $q.resolve();
      }
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#printPreview
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * Opens a modal to preview the article for printing.
     * The article's title in the desired language has to be given, together with the current language
     * for the widget layout.
     *
     * @param {object} printPreviewContent
     * the content properties for the preview modal
     *
     * @param {string} printPreviewContent.appId
     * the app's id the article belongs to
     *
     * @param {object} printPreviewContent.articles
     * Observable that will resolve to articles to preview.
     *
     * @param {boolean=} printPreviewContent.showLoadingBar
     * If true a loading bar for all content that is loaded is displayed before the content is shown.
     * Default false. A spinner is shown for content segments.
     *
     * @param {string} printPreviewContent.pageTitle
     * The title used for the page when the modal is open
     *
     * @param {string=} printPreviewContent.articleTitle
     * The article's title in the selected language. Optional. If not set the article's main title is used.
     * Only relevant if a single article is shown.
     *
     * @param {string=} printPreviewContent.selectedLanguage
     * The language in which to load the widget layout. Optional. If not set the article's usedLanguage is used.
     * Only relevant if a single article is shown.
     *
     * @returns {object} a promise which is resolved after the preview is closed.
     */
    function printPreview(printPreviewContent) {
      return ngxPrintPreviewDialogService.openLegacy(printPreviewContent);
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#printPreviewWithSubArticles
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * Opens a modal to preview a list of articles for printing.
     * The articles are deep children of the given article and the article itself.
     * The modal's title in the desired language has to be given.
     *
     * @param {string} app
     * The app the articles belongs to
     *
     * @param {object} article
     * The parent of articles to preview
     *
     * @param {string=} pageTitle
     * The article's or wiki title in the correct language
     *
     * @returns {object} a promise which is resolved after the preview is closed.
     */
    function printPreviewWithSubArticles(app, article, pageTitle) {
      return printPreview({
        appId: app.id,
        articles: rxjs.from(WikiArticleModel.getSubArticlesRecursiveWithWidgets(app, article.id)),
        pageTitle: pageTitle || article.title,
        selectedLanguage: _.get(article, 'usedLanguage'),
        showLoadingBar: true});
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#lock
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * Locks the given article for the current user and renews the lock every five minutes. Locking fails if the user
     * does not have the permission to lock the article or if the article is already locked. If successfully locked an
     * `/topic/item.&lt;article-id&gt;.lock.set` event is broadcast via websockets.
     *
     * @param {object} article
     * The article to lock.
     *
     * @param {object} currentUser
     * The current user who locks the article.
     *
     * @returns {object} A promise which is resolved on success and rejected if the lock could not be acquired.
     */
    function lock(article, currentUser) {
      var deferred = $q.defer();
      article.lock().then(function (lock) {
        if (hasLock(lock, currentUser)) {
          _startLockRefreshInterval(lock);
          deferred.resolve(lock);
        } else {
          deferred.reject();
        }
      });
      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#unlock
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * (Forcefully) unlocks the given article independently of who owns the lock right now and stops the renewing of
     * the lock. Unlocking fails if the user does not have the permission to unlock the article. If successfully
     * unlocked a `/topic/item.&lt;article-id&gt;.lock.removed` event is broadcast via websockets.
     *
     * @param {object} article The article to unlock.
     * @returns {object} A promise which is resolved if unlocking was successful and rejected if it failed.
     */
    function unlock(article) {
      return article.unlock().then(function () {
        _cancelLockRefreshInterval(article);
      });
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#releaseLock
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * Releases the lock from the given article if it is owned by the current user. If successfully released
     * a `/topic/item.&lt;article-id&gt;.lock.released` event is broadcast via websockets. The renewing of the lock is
     * stopped if the current user owns the lock - regardless if the actual unlocking was successful or not.
     *
     * @param {object} article
     * The article to unlock
     *
     * @param {object} currentUser
     * The current user who releases the article. This user needs to own the lock to the article. If not, nochting
     * happens.
     *
     * @param {boolean=false} changed
     * If the article was changed, this parameter should be set `true`. This way event recipients can see that they need
     * to refresh the article.
     *
     * @returns {object} A promise which is resolved if releasing the lock was successful and rejected in any other
     * case.
     */
    function releaseLock(article, currentUser, changed) {
      var deferred = $q.defer();
      if (isLocked(article) && hasLock(article, currentUser)) {
        return article.unlock(changed).finally(function () {
          _cancelLockRefreshInterval(article);
        });
      }
      deferred.reject();
      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#isLocked
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * Returns whether the given article is locked.
     *
     * @param {object} lockable
     * The lockable entity to check.
     *
     * @returns {boolean} `true` if the given article is locked, `false` otherwise.
     */
    function isLocked(lockable) {
      return lockable.locked;
    }

    /**
     * @ngdoc method
     * @name coyo.apps.wiki.wikiArticleService#hasLock
     * @methodOf coyo.apps.wiki.wikiArticleService
     *
     * @description
     * Returns whether the given lock is owned by the given user.
     *
     * @param {object} lockable
     * The lockable entity to check
     *
     * @param {object} user
     * The user to check
     *
     * @returns {boolean} `true` if the given article is locked by the given user, `false` otherwise.
     */
    function hasLock(lockable, user) {
      return (!!lockable.lockHolder && (lockable.lockHolder.id === user.id));
    }

    /******************* PRIVATE METHODS *******************/

    function _startLockRefreshInterval(lockable) {
      lockInterval[lockable.id] = $interval(function () {
        _refreshLock(lockable);
      }, lockIntervalMilliseconds);
    }

    function _cancelLockRefreshInterval(lockable) {
      $interval.cancel(lockInterval[lockable.id]);
      delete lockInterval[lockable.id];
    }

    function _refreshLock(article) {
      article.lock().then(function (lock) {
        $log.debug('[WikiArticleService] Refreshed lock', lock);
      }).catch(function () {
        _cancelLockRefreshInterval(article);
      });
    }

  }

})();

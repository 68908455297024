import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {VideoPreview} from '@domain/preview/video-preview';
import {WebPreviewService} from '@domain/preview/web-preview/web-preview.service';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {RtePlugin} from '../rte-plugin';
import {youtubeNoCookie} from './rte-youtube-regex';

/**
 * Service exporting the video plugin for the froala rte
 */
@Injectable()
export class VideoFromUrlPlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertVideoFromURL';

  private loadingVideoFromUrl: boolean;

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(FROALA_EDITOR) private froala: any,
              private log: NGXLogger,
              private webPreviewService: WebPreviewService,
              private translateService: TranslateService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    this.registerInsertVideoUrlPopupPlugin();
    this.registerInsertVideoByUrlSubmitCommand();
    this.registerInsertVideoByURLCommand();
  }

  private registerInsertVideoByURLCommand(): void {
    const plugin = this;
    plugin.froala.RegisterCommand(VideoFromUrlPlugin.KEY, {
      title: plugin.translateService.instant('RTE.VIDEO.FROM_URL'),
      plugin: 'coyoVideoPlugin',
      icon: 'insertVideo',
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(cmd: string): void {
        const editor = this;
        if (!editor.popups.isVisible('coyoVideoPlugin.popup')) {
          editor.selection.save();
          editor.coyoVideoPlugin.showPopup(cmd);
        }
      }
    });
  }

  private registerInsertVideoByUrlSubmitCommand(): void {
    const plugin = this;
    this.froala.RegisterCommand('coyoInsertVideoByUrlSubmit', {
      undo: false,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        const editor = this;

        if (plugin.loadingVideoFromUrl) {
          return;
        }

        plugin.loadingVideoFromUrl = true;
        const url = (plugin.document.getElementById(`froala-coyo-video-url-${editor.id}`) as HTMLInputElement).value;
        const insertButton = plugin.document.getElementsByClassName('fr-command fr-submit')[0] as HTMLButtonElement;

        // copy the classList
        const classListOfInsertButton: string[] = [];
        Object.assign(classListOfInsertButton, insertButton.classList);

        plugin.transformInsertButtonToSpinner(insertButton, classListOfInsertButton);

        // start async request, for video insert
        plugin.displayVideoInHtml(editor, this.popups, url, insertButton, classListOfInsertButton);
      }
    });
  }

  private displayVideoInHtml(
    editor: any,
    popups: any,
    url: string,
    insertButton: any,
    classListOfInsertButton: string[]
  ): Subscription {
    const plugin = this;

    function showError(): void {
      plugin.showUrlError(plugin.document.getElementById(`froala-coyo-video-url-${editor.id}`), url);
      plugin.loadingVideoFromUrl = false;
      plugin.recoverInsertButton(insertButton, classListOfInsertButton);
    }

    return plugin.webPreviewService.generateWebPreviews([url]).subscribe(videoInfos => {
      if (videoInfos.length === 1 && videoInfos[0].type === 'VIDEO') {
        (plugin.document.getElementById(`froala-coyo-video-url-${editor.id}`) as HTMLInputElement).value = '';
        editor.selection.restore();

        const videoInfo = videoInfos[0] as VideoPreview;
        const videoUrl = videoInfo.videoUrl
          ? videoInfo.videoUrl.replace(youtubeNoCookie['regex'], youtubeNoCookie['replacement'])
          : videoInfo.videoUrl;

        editor.video.insert(
          `<iframe webkitallowfullscreen mozallowfullscreen allowfullscreen frameborder="0"
               width="${videoInfo.width}" height="${videoInfo.height}" src="${videoUrl}"></iframe>`
        );

        plugin.loadingVideoFromUrl = false;

        // timeout to wait for the video to be attached to the rte content
        setTimeout(() => {
          popups.hide('coyoVideoPlugin.popup');
          plugin.recoverInsertButton(insertButton, classListOfInsertButton);
        }, 750);
      } else {
        showError();
      }
    }, () => {
      showError();
    });
  }

  private transformInsertButtonToSpinner(insertButton: HTMLButtonElement, classListOfInsertButton: string[]): void {
    classListOfInsertButton.forEach(currentValue => insertButton.classList.remove(currentValue));
    insertButton.classList.add('coyo-spinner');
    insertButton.classList.add('xs');
  }

  private recoverInsertButton(insertButton: HTMLButtonElement, classListOfInsertButton: string[]): void {
    insertButton.classList.remove('xs');
    insertButton.classList.remove('coyo-spinner');
    classListOfInsertButton.forEach(currentClass => insertButton.classList.add(currentClass));
  }

  private showUrlError(element: HTMLElement, url: string): void {
    this.log.info(`video is not embeddable (${url})`);
    element.classList.add('has-error');
  }

  private registerInsertVideoUrlPopupPlugin(): void {
    const plugin = this;

    Object.assign(this.froala.POPUP_TEMPLATES, {
      'coyoVideoPlugin.popup': '[_BUTTONS_][_CUSTOM_LAYER_]'
    });

    this.froala.PLUGINS.coyoVideoPlugin = function(editor: any): any {
      function initPopup(): any {
        return editor.popups.create('coyoVideoPlugin.popup', {
          buttons: '',
          custom_layer:
            `<div class="fr-layer fr-active" id="fr-video-by-url-layer-${editor.id}">
              <div class="fr-input-line">
                <input type="text" id="froala-coyo-video-url-${editor.id}"
                       placeholder="${plugin.translateService.instant('RTE.URL')}" class="fr-link-attr"
                       tabIndex="1">
                <label>${plugin.translateService.instant('RTE.URL')}</label>
              </div>
              <div class="fr-action-buttons">
                <button class="fr-command fr-submit" role="button" data-cmd="coyoInsertVideoByUrlSubmit" type="button"
                        href="#" tabIndex="2">${plugin.translateService.instant('RTE.INSERT')}</button>
              </div>
            </div>`
        });
      }

      function showPopup(cmd: string): any {
        const $popup = editor.popups.get('coyoVideoPlugin.popup') || initPopup();
        editor.popups.setContainer('coyoVideoPlugin.popup', editor.$tb);

        const $btn = editor.$tb.find(`.fr-command[data-cmd="${cmd}"]`);
        const left = $btn.offset().left;
        const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
        editor.popups.show('coyoVideoPlugin.popup', left, top, $btn.outerHeight());

        return $popup;
      }

      function hidePopup(): void {
        editor.popups.hide('coyoVideoPlugin.popup');
      }

      return {
        showPopup: showPopup,
        hidePopup: hidePopup
      };
    };
  }
}

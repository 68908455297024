import {SenderFilesComponent} from '@app/file-library/sender-files/sender-files.component';
import {WorkspaceService} from '@domain/workspace/workspace.service';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';
import {Transition} from '@uirouter/core';

/**
 * State for rendering the files of the given workspace. E.g. used in the manage file state.
 */
export const workspacesFileState: NgHybridStateDeclaration = {
  name: 'main.workspace.show.files',
  url: '/files',
  component: SenderFilesComponent,
  resolve: [
    {
      token: 'sender',
      policy: {when: 'EAGER'},
      deps: [WorkspaceService, Transition],
      resolveFn: (workspaceService: WorkspaceService, $transition$: Transition) => workspaceService.get(
        $transition$.params().idOrSlug,
        {
          permissions: ['manage', 'createFile']
        }).toPromise()
    },
  ]
};

import {NgModule} from '@angular/core';
import {pagesFileState} from '@app/pages/pages.state';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {SocialModule} from '@shared/social/social.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {PageVisibilityComponent} from './page-visibility/page-visibility.component';
import './page-visibility/page-visibility.component.downgrade';
import {SubscriberInfoComponent} from './subscriber-info/subscriber-info.component';
import './subscriber-info/subscriber-info.component.downgrade';
import {SubscriberModalComponent} from './subscriber-modal/subscriber-modal.component';

export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: [pagesFileState]});

/**
 * Pages feature module
 */
@NgModule({
  declarations: [
    PageVisibilityComponent,
    SubscriberInfoComponent,
    SubscriberModalComponent
  ],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    SenderUIModule,
    SocialModule,
    UpgradeModule,
    uiRouterModuleChild
  ],
  exports: [
    PageVisibilityComponent,
    SubscriberInfoComponent
  ],
  entryComponents: [
    PageVisibilityComponent,
    SubscriberInfoComponent,
    SubscriberModalComponent
  ]
})
export class PagesModule {
}

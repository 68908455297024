(function (angular) {
  'use strict';

  selectTeam.$inject = ["selectFactoryModel", "TeamAdminModel", "Pageable", "$translate"];
  angular
      .module('coyo.admin.gamification')
      .directive('coyoSelectTeam', selectTeam);

  /**
   * @ngdoc directive
   * @name coyo.widgets.blogarticle.coyoSelectBlogArticle:coyoSelectTeam
   * @element ANY
   * @restrict E
   * @scope
   *
   * @description
   * Renders a ui-select to select a specific team.
   */
  function selectTeam(selectFactoryModel, TeamAdminModel, Pageable, $translate) {
    var teams = null;

    return selectFactoryModel({
      refresh: _refresh,
      transform: _transform,
      multiple: false
    });

    function _refresh(pageable, search, params) {
      if (teams === null) {
        teams = TeamAdminModel.get({tournamentId: params.tournamentId}).then(function (response) {
          var names = _.concat(_.map(response, 'name'), _.map(response, 'shortName'));
          return $translate(names).then(function (namesTranslated) {
            return _.map(response, function (team) {
              return {
                id: team.id,
                displayName: namesTranslated[team.name],
                displayNameShort: namesTranslated[team.shortName]
              };
            }).sort(function (a, b) {
              return a.displayName.localeCompare(b.displayName);
            });
          });
        }).catch(function () {
          teams = null;
        });
      }

      return teams.then(function (response) {
        var result = _.filter(response, function (team) {
          return _.startsWith(team.displayName.toLowerCase(), (search || '').toLowerCase());
        });

        return angular.extend(result, {
          meta: {
            last: true,
            number: 0
          }
        });
      });
    }

    function _transform(team) {
      return team ? team.id : null;
    }
  }

})(angular);

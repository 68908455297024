import {Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {App} from '@apps/api/app';
import {AppChooserComponent} from '@apps/api/app-chooser/app-chooser.component';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {MatDialogSize} from '@coyo/ui';
import {SenderService} from '@domain/sender/sender/sender.service';
import {Observable} from 'rxjs';
import {filter, first, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppChooserService {

  constructor(private ngZone: NgZone, private dialog: MatDialog, private senderService: SenderService) {
  }

  /**
   * Opens the app chooser modal.
   *
   * @param senderId The id of the parent sender
   *
   * @return Observable of the created app
   */
  open(senderId: string): Observable<App<AppSettings>> {
    return this.senderService.get(senderId).pipe(switchMap(sender => {
      const dialogRef = this.dialog.open(AppChooserComponent, {
        width: MatDialogSize.Large,
        data: {
          sender
        }
      });

      return dialogRef.afterClosed()
        .pipe(first())
        .pipe(filter(value => value));
    }));
  }

  /**
   * @Deprecated
   * Opens the app chooser modal.
   *
   * @param senderId The id of the parent sender
   *
   * @return Promise of the created app
   */
  openLegacy(senderId: string): Promise<App<AppSettings>> {
    return this.ngZone.run(() => this.open(senderId).toPromise());
  }
}

import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {JitTranslationSettings} from '@app/admin/settings/jit-translation-settings/jit-translation-settings';
import {JitTranslationSettingsService} from '@app/admin/settings/jit-translation-settings/jit-translation-settings.service';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {SettingsService} from '@domain/settings/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {MultilanguageService} from '@shared/multilanguage/multilanguage-service/multilanguage.service';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import * as _ from 'lodash';
import {forkJoin, Observable, Subscription, throwError} from 'rxjs';
import {first, map, startWith, switchMap} from 'rxjs/operators';

/**
 * Component to handle the general admin settings
 */
@Component({
  selector: 'coyo-general-settings',
  templateUrl: './general-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GeneralSettingsComponent implements OnInit, OnDestroy {

  settingsForm: FormGroup;

  private readonly JITT_ACTIVATE_TRANSLATION: string = 'activateTranslation';

  private readonly JITT_ACTIVE_PROVIDER: string = 'activeProvider';

  private readonly JITT_API_KEY: string = 'apiKey';

  private readonly JITT_REGION: string = 'region';

  private readonly DELETED_USER_ANONONYMIZATION_ACTIVE: string = 'deletedUserAnonymizationActive';

  private readonly DELETED_USER_DISPLAYNAME: string = 'deletedUserDisplayName';

  readonly pageVisibility: string[] = ['PRIVATE', 'PUBLIC'];

  readonly workspaceVisibility: string[] = ['PRIVATE', 'PROTECTED', 'PUBLIC'];

  readonly integrations: string[] = ['NONE', 'G_SUITE', 'OFFICE_365'];

  readonly translationProviders: string[] = ['DEEPL', 'MICROSOFT'];

  userAnonymizationActive$: Observable<boolean>;

  isTranslationProviderActive$: Observable<boolean>;

  jitSettingsForm: FormGroup;
  private backendSettingsForm: FormGroup;

  private settingsSubscription: Subscription;
  jitServiceEnabled$: Observable<boolean>;
  multiLanguageAvailable$: Observable<boolean>;

  constructor(private formBuilder: FormBuilder, private settingsService: SettingsService,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private jitTranslationSettingsService: JitTranslationSettingsService,
              private multilanguageService: MultilanguageService,
              private featureToggleService: FeatureToggleService) {
  }

  ngOnInit(): void {
    this.jitServiceEnabled$ = this.jitTranslationSettingsService.hasValidLicense();
    this.multiLanguageAvailable$ = this.multilanguageService.hasValidLicense();

    this.initSettingsForm();

    // Load general settings
    this.settingsService.retrieveAndForceRefresh().subscribe(settings => {
      this.updateBackendSettingsForm(settings);
    });

    // Load jit-translation settings
    this.jitServiceEnabled$.pipe(switchMap(valid => {
      if (valid) {
        return this.jitTranslationSettingsService.getSettings();
      } else {
        return throwError('service not available or license expired');
      }
    }))
      .subscribe(jitSettings => {
        this.updateJITSettingsForm(jitSettings);
      }, err => {
        this.jitSettingsForm.disable();
      });

    this.settingsSubscription = this.jitSettingsForm.get(this.JITT_ACTIVATE_TRANSLATION).valueChanges
      .subscribe(() => {
        this.toggleJITSettings();
      });

    // init observables
    this.userAnonymizationActive$ = this.getUserAnonymizationValue$();
    this.isTranslationProviderActive$ = this.getActivatedTranslationProvider$();
  }

  ngOnDestroy(): void {
    this.settingsSubscription.unsubscribe();
  }

  /**
   * Submit the backend settings and the jit-translation settings.
   */
  submit(): void {
    if (this.settingsForm.status === 'PENDING') {
      this.settingsForm.statusChanges.pipe(first()).subscribe(status => {
        if (status === 'VALID') {
          this.submitForm();
        }
      });
    } else if (!this.settingsForm.invalid) {
      this.submitForm();
    }
  }

  private submitForm(): void {
    if (!this.backendSettingsForm.get(this.DELETED_USER_DISPLAYNAME).value) {
      this.backendSettingsForm.get(this.DELETED_USER_DISPLAYNAME).setValue(
        this.translateService.instant('ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELETED_NAME.DEFAULT')
      );
    }
    const backendSettingsObservable = this.settingsService.update(this.backendSettingsForm.getRawValue());
    const observables: Observable<any>[] = [backendSettingsObservable];

    if (this.jitSettingsForm.dirty) {
      observables.push(this.jitTranslationSettingsService.putSettings(this.jitSettingsForm.getRawValue()));
    }

    const observable = forkJoin(observables);
    observable.subscribe(([backendSettingsResult, jitSettingsResult]) => {
      if (jitSettingsResult) {
        this.updateJITSettingsForm(jitSettingsResult);
      }
      this.updateBackendSettingsForm(backendSettingsResult);
      this.notificationService.success('ADMIN.SETTINGS.SAVE.SUCCESS');
      this.featureToggleService.reload();
    });
  }

  private initSettingsForm(): void {

    this.jitSettingsForm = this.formBuilder.group({
      activateTranslation: [false],
      activeProvider: [null],
      apiKey: [null, [], CoyoValidators.createApiKeyValidator(this.jitTranslationSettingsService)],
      region: ['global', []]
    }, {
      validators: [
        CoyoValidators
          .requiredIfTrue(this.JITT_ACTIVATE_TRANSLATION, this.JITT_API_KEY, control => control.value === true),
        CoyoValidators
          .requiredIfTrue(this.JITT_ACTIVATE_TRANSLATION, this.JITT_ACTIVE_PROVIDER, control => control.value === true),
        CoyoValidators
          .requiredIfTrue(this.JITT_ACTIVE_PROVIDER, this.JITT_REGION, control => control.value === 'MICROSOFT'),
      ]
    });

    this.backendSettingsForm = this.formBuilder.group({
      networkName: [null],
      trackingCode: [null],
      subNavigationActive: [null],
      defaultVisibilityPages: [null],
      defaultVisibilityWorkspaces: [null],
      deletedUserAnonymizationActive: ['false'],
      deletedUserAnonymizationDelay: [null],
      deletedUserDisplayName: [
        this.translateService.instant('ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELETED_NAME.DEFAULT'),
        [Validators.maxLength(63)]
      ],
      userOfflineAfterCreationActive: [null],
      multiLanguageActive: [null],
      integrationType: [null]
    }, {
      validators: [
        CoyoValidators
          .requiredIfTrue(this.DELETED_USER_ANONONYMIZATION_ACTIVE,
            this.DELETED_USER_DISPLAYNAME, control => control.value === 'true')
      ]
    });

    this.settingsForm = this.formBuilder.group({
      backendSettings: this.backendSettingsForm,
      jitTranslationSettings: this.jitSettingsForm
    });
  }

  private updateBackendSettingsForm(settings: any): void {
    _.forEach(_.keys(this.backendSettingsForm.controls), controlName => {
      if (settings.has(controlName)) {
        this.backendSettingsForm
          .get(controlName)
          .setValue(settings.get(controlName));
      }
    });
  }

  private updateJITSettingsForm(jitSettingsValue: JitTranslationSettings): void {
    if (jitSettingsValue.activeProvider === 'NONE') {
      jitSettingsValue.activeProvider = null;
    }
    this.jitSettingsForm.patchValue({
      ...jitSettingsValue,
      activateTranslation: jitSettingsValue.activeProvider != null
    });
  }

  private getActivatedTranslationProvider$(): Observable<boolean> {
    return this.jitSettingsForm.get(this.JITT_ACTIVATE_TRANSLATION).valueChanges
      .pipe(startWith(this.jitSettingsForm.get(this.JITT_ACTIVATE_TRANSLATION).value))
      .pipe(map(value => value === true));
  }

  private getUserAnonymizationValue$(): Observable<boolean> {
    return this.backendSettingsForm.get(this.DELETED_USER_ANONONYMIZATION_ACTIVE).valueChanges
      .pipe(startWith(this.backendSettingsForm.get(this.DELETED_USER_ANONONYMIZATION_ACTIVE).value))
      .pipe(map(value => value === 'true'));
  }

  /**
   * Resets the value of the provider and the api-key when the admin disabled the translationActive checkbox.
   */
  private toggleJITSettings(): void {
    if (!this.jitSettingsForm.get(this.JITT_ACTIVATE_TRANSLATION).value) {
      this.jitSettingsForm.get(this.JITT_ACTIVE_PROVIDER).setValue(null);
      this.jitSettingsForm.get(this.JITT_API_KEY).reset(null);
      this.jitSettingsForm.get(this.JITT_REGION).reset('global');
    } else if (this.jitSettingsForm.get(this.JITT_ACTIVE_PROVIDER).value === null) {
      this.jitSettingsForm.get(this.JITT_API_KEY).disable();
      this.jitSettingsForm.get(this.JITT_REGION).reset('global');
    }
  }

  /**
   * Enabled the API input field when a provider was choosen.
   */
  enableApiInput(): void {
    this.jitSettingsForm.get(this.JITT_API_KEY).enable();
  }
}

import {Inject, NgModule} from '@angular/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DownloadModule} from '@shared/download/download.module';
import {AlignPluginService} from '@shared/rte/align-plugin/align-plugin.service';
import {AnchorPluginService} from '@shared/rte/anchor-plugin/anchor-plugin.service';
import {FileDropdownPlugin} from '@shared/rte/file-plugin/file-dropdown-plugin';
import {FileFromFileLibraryPlugin} from '@shared/rte/file-plugin/file-from-file-library-plugin';
import {FileFromGSuitePlugin} from '@shared/rte/file-plugin/file-from-g-suite-plugin';
import {FroalaTranslateService} from '@shared/rte/froala-translate/froala-translate.service';
import {ImageDropdownPlugin} from '@shared/rte/image-plugin/image-dropdown-plugin';
import {ImageFromFileLibraryPlugin} from '@shared/rte/image-plugin/image-from-file-library-plugin';
import {ImageFromGSuitePlugin} from '@shared/rte/image-plugin/image-from-g-suite-plugin';
import {RTE_PLUGINS} from '@shared/rte/rte-plugin';
import {VideoDropdownPlugin} from '@shared/rte/video-plugin/video-dropdown-plugin';
import {VideoFromFileLibraryPlugin} from '@shared/rte/video-plugin/video-from-file-library-plugin';
import {VideoFromGSuitePlugin} from '@shared/rte/video-plugin/video-from-g-suite-plugin';
import {VideoFromUrlPlugin} from '@shared/rte/video-plugin/video-from-url-plugin';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {RteComponent} from './rte/rte.component';
import './rte/rte.component.downgrade';

/**
 * Module providing the RTE component and the custom RTE plugins provided by COYO.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    DownloadModule,
    FroalaEditorModule,
    FroalaViewModule
  ],
  declarations: [
    RteComponent
  ],
  entryComponents: [
    RteComponent
  ],
  exports: [
    RteComponent
  ],
  providers: [
    {provide: FROALA_EDITOR, useValue: require('froala-editor')},
    {provide: RTE_PLUGINS, multi: true, useClass: AlignPluginService},
    {provide: RTE_PLUGINS, multi: true, useClass: AnchorPluginService},
    {provide: RTE_PLUGINS, multi: true, useClass: ImageFromFileLibraryPlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: ImageFromGSuitePlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: ImageDropdownPlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: VideoFromUrlPlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: VideoFromFileLibraryPlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: VideoFromGSuitePlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: VideoDropdownPlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: FileFromFileLibraryPlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: FileFromGSuitePlugin},
    {provide: RTE_PLUGINS, multi: true, useClass: FileDropdownPlugin}
  ]
})
export class RteModule {

  constructor(@Inject(FROALA_EDITOR) protected froala: any,
              froalaTranslateService: FroalaTranslateService) {
    this.patchLanguage(froalaTranslateService);
    this.patchUrlSanitizer();
    this.patchScreenSize();
  }

  private patchLanguage(froalaTranslateService: FroalaTranslateService): void {
    this.froala.MODULES.language = function(): FroalaTranslateService {
      return froalaTranslateService;
    };
  }

  private patchUrlSanitizer(): void {
    const originalHelpers = this.froala.MODULES.helpers;

    this.froala.MODULES.helpers = function(editor: any): any {
      const helpers = originalHelpers(editor);
      const sanitizeURL = helpers.sanitizeURL;

      /*
       * Allow additional schemes in sanitized URLs.
       */
      helpers.sanitizeURL = function(url: string): any {
        return sanitizeURL(url)
          .replace(/fax%3A/gi, 'fax:')
          .replace(/file%3A/gi, 'file:')
          .replace(/mailto%3A/gi, 'mailto:')
          .replace(/ms-word%3A/gi, 'ms-word:')
          .replace(/ms-excel%3A/gi, 'ms-excel:')
          .replace(/ms-powerpoint%3A/gi, 'ms-powerpoint:')
          .replace(/ms-visio%3A/gi, 'ms-visio:')
          .replace(/notes%3A/gi, 'notes:')
          .replace(/skype%3A/gi, 'skype:')
          .replace(/tel%3A/gi, 'tel:')
          .replace(/webcal%3A/gi, 'webcal:')
          .replace(/webcals%3A/gi, 'webcals:');
      };

      return helpers;
    };
  }

  private patchScreenSize(): void {
    const originalHelpers = this.froala.MODULES.helpers;
    const froala = this.froala;

    froala.MODULES.helpers = function(editor: any): any {
      const helpers = originalHelpers(editor);
      helpers.screenSize = function(): any {
        const innerWidth = editor.$el.width();
        if (innerWidth >= 800) {
          return froala.LG;
        } else if (innerWidth >= 640) {
          return froala.MD;
        } else if (innerWidth >= 480) {
          return froala.SM;
        } else {
          return froala.XS;
        }
      };
      return helpers;
    };
  }
}

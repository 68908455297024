(function (angular) {
  'use strict';

  SelectBirthdayController.$inject = ["moment", "birthdayService"];
  angular
      .module('commons.ui')
      .controller('SelectBirthdayController', SelectBirthdayController);

  function SelectBirthdayController(moment, birthdayService) {
    var vm = this,
        INITIAL_DAYS_PER_MONTH = 31,
        dateEmpty;

    vm.dayFieldValid = true;
    vm.monthFieldValid = true;

    vm.$onInit = init;
    vm.validateOnChangeDay = validateOnChangeDay;
    vm.validateOnChangeMonthOrYear = validateOnChangeMonthOrYear;

    function init() {
      vm.monthOptions = birthdayService.createMonths();
      vm.yearOptions = birthdayService.createYears();
      parseDate();
      validateAndCreateDayOptions();
    }

    function validateOnChangeDay() {
      validate();
      setFormattedDate();
    }

    function validateOnChangeMonthOrYear() {
      validateAndCreateDayOptions();
      validate();
      setFormattedDate();
    }

    function parseDate() {
      var dateObject = birthdayService.parseDate(vm.formattedDate);
      vm.year = dateObject.year;
      vm.month = dateObject.month;
      vm.day = dateObject.day;
    }

    function validateAndCreateDayOptions() {
      if (!isFieldValue(vm.month)) {
        vm.dayOptions = birthdayService.createDays(INITIAL_DAYS_PER_MONTH);
      } else {
        var referenceMoment = createReferenceMoment(1);
        if (referenceMoment.isValid()) {
          var numberOfDayOptions = referenceMoment.daysInMonth();
          vm.dayOptions = birthdayService.createDays(numberOfDayOptions);
          if (vm.day > numberOfDayOptions) {
            vm.day = birthdayService.getDefaultValue();
          }
        }
      }
    }

    function validate() {
      validateDateEmpty();
      validateFields();
      validateDate();
    }

    function validateFields() {
      vm.dayFieldValid = isFieldValue(vm.day) || dateEmpty;
      vm.monthFieldValid = isFieldValue(vm.month) || dateEmpty;
    }

    function validateDate() {
      var dateValid;
      if (dateEmpty) {
        dateValid = true;
      } else if (isFieldValue(vm.day) && isFieldValue(vm.month)) {
        var referenceMoment = createReferenceMoment(vm.day);
        dateValid = referenceMoment.isValid();
      } else {
        dateValid = false;
      }
      vm.ngModelCtrl.$setValidity('birthdayFormat', dateValid);
    }

    function setFormattedDate() {
      vm.formattedDate = birthdayService.formatDate(vm.year, vm.month, vm.day);
      vm.ngModelCtrl.$setViewValue(vm.formattedDate);
    }

    function createReferenceMoment(day) {
      var year;
      if (!isFieldValue(vm.year)) {
        year = birthdayService.getReferenceLeapYear();
      } else {
        year = vm.year;
      }

      return moment({year: year, month: vm.month, day: day});
    }

    function isFieldValue(fieldValue) {
      return birthdayService.isFieldValue(fieldValue);
    }

    function validateDateEmpty() {
      dateEmpty = birthdayService.isDateEmpty(vm.year, vm.month, vm.day);
    }
  }

})(angular);

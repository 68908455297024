(function (angular) {
  'use strict';

  /**
   * @ngdoc directive
   * @name coyo.landingPages.oyocSubNavigation:oyocSubNavigation
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Displays the landing page navigation.
   *
   * @requires $rootScope
   * @requires $scope
   * @requires $state
   * @requires coyo.domain.SettingsModel
   */
  SubNavigationController.$inject = ["$scope", "$state", "SettingsModel", "$transitions", "ngxUrlService"];
  angular
      .module('coyo.landing-pages')
      .component('oyocSubNavigation', subNavigation())
      .controller('SubNavigationController', SubNavigationController);

  function subNavigation() {
    return {
      templateUrl: 'app/modules/landing-pages/components/sub-navigation/sub-navigation.html',
      controller: 'SubNavigationController',
      bindings: {
        landingPages: '<'
      },
      controllerAs: '$ctrl'
    };
  }

  function SubNavigationController($scope, $state, SettingsModel, $transitions, ngxUrlService) {
    var vm = this;

    var views = {
      '/pages': 'main.page',
      '/workspaces': 'main.workspace',
      '/events': 'main.event'
    };

    vm.subNavigationActive = false;

    vm.$onInit = init;

    vm.getTarget = getTarget;

    function getTarget(landingPage) {
      return !landingPage.relativeUrl || landingPage.newTab ? (landingPage.newTab ? '_blank' : '_self') : undefined;
    }

    function _buildUiSReferenceString(viewName, paramsJsonString) {
      return viewName + '(' + paramsJsonString + ')';
    }

    function _resolveViewName(url) {
      return !_.isEmpty(url) ? views[ngxUrlService.getRelativePath(url)] : undefined;
    }

    function init() {
      _.forEach(vm.landingPages, function (landingPage) {
        landingPage.relativeUrl = landingPage.configuredUrl && ngxUrlService.isRelativePath(landingPage.configuredUrl);
        var stateName = _resolveViewName(landingPage.configuredUrl);
        if (landingPage.relativeUrl && !_.isUndefined(stateName)) {
          landingPage.uiSref =
              _buildUiSReferenceString(stateName, ngxUrlService.getUrlParameterAsJsonString(landingPage.configuredUrl));
        }
      });

      SettingsModel.retrieveByKey('subNavigationActive').then(function (newVal) {
        if (newVal === 'true') {
          vm.subNavigationActive = true;
        } else {
          vm.subNavigationActive = $state.includes('main.landing-page');
          var deregisterHook = $transitions.onSuccess({}, function (transition) {
            var $state = transition.injector().get('$state');
            vm.subNavigationActive = $state.includes('main.landing-page');
          });
          $scope.$on('$destroy', deregisterHook);
        }
      });
    }
  }

})(angular);

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '@domain/user/user';
import {Observable} from 'rxjs';
import {Session} from './session';

/**
 * Service to manage user sessions.
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsDevicesService {

  constructor(private http: HttpClient) {
  }

  /**
   * Gets sessions information of an user.
   *
   * @param user the user whom we want the sessions from
   * @returns an Observable with the list of user sessions
   */
  getPushDevices(user: User): Observable<Session[]> {
    return this.http.get<Session[]>(`/web/users/${user.id}/pushappinstallations`);
  }

  /**
   * Kills a user session on a device.
   *
   * @param userId the user ID
   * @param deviceId the device ID
   * @returns an empty Observable
   */
  deletePushDevices(userId: string, deviceId: string): Observable<void> {
    return this.http.delete<void>(`/web/users/${userId}/pushappinstallations/${deviceId}`);
  }
}

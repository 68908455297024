import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {DownloadWidget} from '@widgets/download/download-widget';
import {DownloadWidgetSettingsComponent} from './download-widget-settings/download-widget-settings.component';
import {DownloadWidgetComponent} from './download-widget/download-widget.component';

/**
 * The configuration for the download widget.
 */
export const DOWNLOAD_WIDGET: WidgetConfig<DownloadWidget> = {
  key: 'downloads',
  name: 'WIDGETS.DOWNLOADS.NAME',
  description: 'WIDGETS.DOWNLOADS.DESCRIPTION',
  defaultTitle: 'WIDGETS.DOWNLOADS.NAME',
  icon: 'zmdi-download',
  component: DownloadWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    component: DownloadWidgetSettingsComponent,
    skipOnCreate: false
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

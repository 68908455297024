<a [ngClass]="['sender-avatar', avatarSize, sender?.typeName || '']" [attr.href]="$senderLink | async" *ngIf="sender">
  <coyo-avatar-image *ngIf="sender?.imageUrls?.avatar && !hasError"
                     (error)="hasError = true"
                     [imageUrl]="sender?.imageUrls?.avatar"
                     [avatarSize]="avatarSize"
                     [textAlternative]="sender?.displayName"></coyo-avatar-image>
  <span class="sender-avatar-empty" *ngIf="!sender?.imageUrls?.avatar || hasError" [style.background-color]="sender?.color">
    {{sender.displayNameInitials}}
  </span>
  <coyo-external-icon *ngIf="showExternalFlag && sender?.externalWorkspaceMember"></coyo-external-icon>
</a>

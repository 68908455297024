import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AuthService} from '@core/auth/auth.service';
import {Widget} from '@domain/widget/widget';
import {TranslateService} from '@ngx-translate/core';
import {UIRouter} from '@uirouter/angular';
import {WidgetSettingsModalComponent} from '@widgets/api/widget-settings-modal/widget-settings-modal.component';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import * as lodash from 'lodash';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {WidgetCategory, WidgetConfig} from '../widget-config';
import {WidgetModal} from '../widget-modal';
import {WidgetRegistryService} from '../widget-registry/widget-registry.service';

const _ = lodash; // workaround for webpack rollup when packaging ngx sdk

export type WidgetGroup = [string, WidgetConfig<Widget<WidgetSettings>>[]];

/**
 * A modal dialog to select and configure widgets.
 *
 * Widgets must be both registered in the frontend and activated in the backend in order to be shown in the modal's
 * selection dialog.
 */
@Component({
  selector: 'coyo-widget-chooser',
  templateUrl: './widget-chooser.component.html',
  styleUrls: ['./widget-chooser.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetChooserComponent extends WidgetModal implements OnInit {
  private static TAB_ORDER: WidgetCategory[] = [WidgetCategory.DYNAMIC, WidgetCategory.STATIC, WidgetCategory.PERSONAL,
    WidgetCategory.PLUGIN];
  private static TAB_ALL: string = 'ALL';

  config: WidgetConfig<Widget<WidgetSettings>> = null;
  allConfigs$: Observable<WidgetConfig<Widget<WidgetSettings>>[]>;
  tabConfigs$: Observable<WidgetGroup[]>;
  searchInput: FormControl;

  private readonly widgetTemplate: Widget<WidgetSettings>;

  constructor(private authService: AuthService,
              private widgetRegistry: WidgetRegistryService,
              private translateService: TranslateService,
              dialogRef: MatDialogRef<WidgetSettingsModalComponent>,
              uiRouter: UIRouter,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super(dialogRef, uiRouter);
    this.widgetTemplate = data.widget; // data.widget is not a complete Widget, it contains just settings and isNew
    this.initWidget();
  }

  ngOnInit(): void {
    this.searchInput = new FormControl();
    this.allConfigs$ = this.authService.getUser()
      .pipe(switchMap(user => this.widgetRegistry.getEnabled(user.moderatorMode)))
      .pipe(switchMap(this.translate))
      .pipe(map(this.sort))
      .pipe(shareReplay({bufferSize: 1, refCount: false}));
    this.tabConfigs$ = this.searchInput
      .valueChanges
      .pipe(debounceTime(250))
      .pipe(distinctUntilChanged())
      .pipe(startWith(''))
      .pipe(switchMap(this.search));
  }

  /**
   * Selects a widget and sets the widget config. If the widget does not need further settings the widget is submitted
   *
   * @param config object of the widget type selected
   */
  select(config: WidgetConfig<Widget<WidgetSettings>>): void {
    this.config = config;
    this.initWidget();

    if (!config.settings || config.settings.skipOnCreate) {
      this.submit();
    }
  }

  /**
   * Reverts the config and the form
   */
  onBack(): void {
    this.config = null;
    this.rebuildForm();
  }

  /**
   * Checks if a widget offers mobileSupport
   * @param mobileSupportTypes types for which a widget offers mobileSupport
   * @return boolean if widget offers mobileSupport or not
   */
  isMobileSupported(mobileSupportTypes: string[]): boolean {
    return !!mobileSupportTypes && mobileSupportTypes.includes(this.data.parentType);
  }

  private search: (text: string) => Observable<WidgetGroup[]> = text => text
    ? this.allConfigs$.pipe(map(this.filter(text)))
    : this.allConfigs$.pipe(map(this.group));

  private translate: (configs: WidgetConfig<Widget<WidgetSettings>>[]) =>
    Observable<WidgetConfig<Widget<WidgetSettings>>[]> = configs => {
    const nameKeys = configs.map(config => config.name);
    const descKeys = configs.map(config => config.description || '');
    const keys = nameKeys.concat(descKeys);
    return this.translateService.get(keys).pipe(map(translations =>
      configs.map(config => {
        config.name = translations[config.name];
        config.description = translations[config.description];
        return config;
      })));
  };

  private sort: (configs: WidgetConfig<Widget<WidgetSettings>>[]) => WidgetConfig<Widget<WidgetSettings>>[] = configs =>
    _.sortBy(configs, config => config.name.toLowerCase());

  private group: (configs: WidgetConfig<Widget<WidgetSettings>>[]) => WidgetGroup[] = configs =>
    _(configs).groupBy('categories')
      .toPairs()
      .sortBy(pair => _.indexOf(WidgetChooserComponent.TAB_ORDER, pair[0]))
      .value();

  private filter: (text: string) => (configs: WidgetConfig<Widget<WidgetSettings>>[]) => WidgetGroup[] = text =>
    configs => [[WidgetChooserComponent.TAB_ALL, configs.filter(config =>
      config.name.toLowerCase().includes(text.toLowerCase()))]];

  private initWidget(): void {
    // adds the 'key' to the (still not complete) widget and updates
    // the default settings (if defined)
    this.widget = this.config ? {
      ...this.widgetTemplate,
      key: this.config.key,
      settings: {
        ...(this.config.defaultSettings ? this.config.defaultSettings() : {}),
        ...this.widgetTemplate?.settings
      }
    } : this.widgetTemplate;
  }
}

import {Injectable} from '@angular/core';
import {Caret} from './caret';

const getCaretCoordinates = require('textarea-caret');

/**
 * Service to retrieve caret position information.
 */
@Injectable({
  providedIn: 'root'
})
export class CaretService {

  constructor() {}

  /**
   * Returns the coordinates in pixel of the caret at the given position.
   *
   * @param element the input or textarea element
   * @param position the position of the caret
   * @return a `Caret` instance holding the caret coordinates
   */
  getCoordinates(element: HTMLElement, position: number): Caret {
    return getCaretCoordinates(element, position);
  }
}

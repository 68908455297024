/**
 * Enum indicating the availability of folder actions.
 */

// tslint:disable:no-bitwise
export enum FilePickerItemServiceCapability {
  NONE = 0,
  RENAME = 1 << 0,
  CREATE_FILE= 1 << 1,
  DELETE = 1 << 2,
  AUTHOR = 1 << 3,
  MOVE_FILE= 1 << 4,
  EDIT_WITH_OFFICE = 1 << 5,
  PUBLIC_LINK = 1 << 6
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {USER_ONLINE_WIDGET} from './user-online-widget-config';
import {UserOnlineWidgetComponent} from './user-online-widget/user-online-widget.component';

/**
 * Module providing the users-online widget
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    UserOnlineWidgetComponent
  ],
  entryComponents: [
    UserOnlineWidgetComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: USER_ONLINE_WIDGET, multi: true}
  ],
})

export class UserOnlineWidgetModule {}

<div *ngIf="rootFile$ | async as data">
  <div class="header">
    <h3 class="app-title">{{title}}</h3>
    <div *ngIf="filePicker" class="action-container">
      <coyo-upload-button *ngIf="filePicker.currentLocation$ | async as currentLocation"
                          [filePickerId]="filePicker.id"
                          [locationId]="currentLocation.item.id">
      </coyo-upload-button>
      <coyo-create-folder-button
        *ngIf="filePicker.currentLocation$ | async as currentLocation"
        [filePickerId]="filePicker.id"
        [locationId]="currentLocation.item.id">
      </coyo-create-folder-button>
    </div>
  </div>
  <div>
    <coyo-file-picker-breadcrumb [filePickerId]="filePicker.id"></coyo-file-picker-breadcrumb>
    <coyo-file-picker #filePicker [data]="data"></coyo-file-picker>
  </div>
</div>

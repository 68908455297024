import {App} from '@apps/api/app';
import {AppConfig} from '@apps/api/app-config';
import {DocumentAppSettingsComponent} from '@apps/document/document-app-settings/document-app-settings.component';
import {DocumentAppComponent} from '@apps/document/document-app.component';
import {DocumentAppSettings} from '@apps/document/document-app.settings';
import {SenderService} from '@domain/sender/sender/sender.service';

export const DOCUMENT_APP_CONFIG: AppConfig<DocumentAppSettings> = {
  name: 'APP.FILE_LIBRARY.NAME',
  description: 'APP.FILE_LIBRARY.DESCRIPTION',
  key: 'file-library',
  icon: 'zmdi-file',
  settings: {
    component: DocumentAppSettingsComponent,
    skipOnCreate: false
  },
  states: [
    {
      component: DocumentAppComponent,
      resolve: [{
        token: 'sender',
        deps: [SenderService, 'app'],
        resolveFn: (senderService: SenderService, app: App<DocumentAppSettings>) =>
          senderService.get(app.senderId, {
            params: {
              with: 'subscriptionInfo'
            }
          }).toPromise()
      }]
    }
  ], svgIcon: 'document-app'
};

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * Avatar overlay
 */
@Component({
  selector: 'coyo-avatar-overlay',
  templateUrl: './avatar-overlay.component.html',
  styleUrls: ['./avatar-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarOverlayComponent {
  /**
   * The icon displayed on the overlay
   */
  @Input() iconClass: string;
  /**
   * Event fired when the overlay is clicked
   */
  @Output() overlayClick: EventEmitter<void> = new EventEmitter(false);
}

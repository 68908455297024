(function () {
  'use strict';

  optimisticService.$inject = ["utilService", "$localStorage", "$q", "optimisticStatus", "$timeout"];
  angular
      .module('commons.optimistic')
      .factory('optimisticService', optimisticService);

  function OptimisticEntity(entityId, status, entity, entityType) {
    this.entityId = entityId;
    this.entityType = entityType;
    this.status = status;
    this.entity = entity;
  }

  function optimisticService(utilService, $localStorage, $q, optimisticStatus, $timeout) {
    return {
      saveEntity: saveEntity,
      remove: remove,
      find: find,
      findWith: findWith,
      findAll: findAll,
      clear: clear
    };

    function saveEntity(entity, entityType) {
      return _getOrCreateOptimisticEntity(entity, entityType).then(function (optimisticEntity) {
        optimisticEntity.entity = _.get(entity, 'entity', entity);
        return _pushToLocalStorage(optimisticEntity);
      });
    }

    function remove(entityIds) {
      var removedEntities = _.remove(_storage(), function (entity) {
        return entityIds && entityIds.indexOf(entity.entityId) !== -1 || entityIds === entity.entityId;
      });
      return _resolveNextTick(removedEntities);
    }

    function find(entityId) {
      return _.find(_storage(), {entityId: entityId});
    }

    function findWith(entityId, options) {
      var filter = _.merge({entityId: entityId}, options);
      return _.find(_storage(), filter);
    }

    function findAll(entityType) {
      return _.filter(_storage(), {entityType: entityType});
    }

    function _pushToLocalStorage(entity) {
      return remove(entity.entityId).then(function () {
        _storage().push(entity);
        return _resolveNextTick(entity);
      });
    }

    function _getOrCreateOptimisticEntity(entity, entityType) {
      var deferred = $q.defer();
      var optimisticEntity = _findExistingOptimisticEntity(entity);
      if (_.isEmpty(optimisticEntity)) {
        deferred.resolve(new OptimisticEntity(utilService.uuid(), optimisticStatus.NEW, entity, entityType));
      } else {
        _.assign(optimisticEntity, entity);
        var entityIds = _getNewMessagesFromChannelIdAndEntityType(_.get(entity, 'entity.channelId'), entityType);
        remove(entityIds).then(function () {
          deferred.resolve(optimisticEntity);
        });
      }
      return deferred.promise;
    }

    function _findExistingOptimisticEntity(entity) {
      return _.find(_storage(), function (localEntity) {
        return entity.entityId && localEntity.entityId === entity.entityId;
      });
    }

    function _getNewMessagesFromChannelIdAndEntityType(channelId, entityType) {
      var entities = _.filter(_storage(), function (localEntity) {
        return localEntity.entity.channelId === channelId
            && localEntity.status === optimisticStatus.NEW
            && localEntity.entityType === entityType;
      });
      return _.map(entities, 'entityId');
    }

    function _storage() {
      if (_.isEmpty($localStorage.optimistic)) {
        $localStorage.optimistic = [];
      }
      return $localStorage.optimistic;
    }

    function _resolveNextTick(resolve) {
      return $timeout(_.noop, 0).then(function () {
        return $q.resolve(resolve);
      });
    }

    function clear() {
      $localStorage.optimistic = null;
      $localStorage.removeItem('optimistic');
    }
  }

})();

import {Sender} from '@domain/sender/sender';

/**
 * Options that are used to filter the senders in the SelectSenderComponent
 */
export interface SelectSenderOptions {
  /**
   * A predefined filter rule
   */
  findOptions: FindOptions;

  /**
   * The sender types that are allowed to be shown. If the array is empty, all sender types are allowed.
   */
  senderTypes: SenderType[];

  /**
   * Optional - Filters the visible senders. If this function is not defined, all senders will be shown.
   * @param sender the sender that might be shown
   * @returns true when the sender is allowed to be shown, otherwise false
   */
  showSender?(sender: Sender): boolean;
}

/**
 * The type of senders
 */
export type SenderType = 'user' | 'page' | 'workspace' | 'event';

/**
 * Options to pefilter the result
 */
export enum FindOptions {
  ALL,
  MANAGED_SENDERS_ONLY,
  SHARE_TARGETS_ONLY
}

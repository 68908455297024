<ng-container *ngIf="(item$ | async) as item">
  <td [class.download]="(isDownload$ | async)" class="col-selection">
    <coyo-file-picker-selection [item]="item"></coyo-file-picker-selection>
  </td>
  <td #drag class="col-name">
    <div class="name-icon-wrapper">
      <coyo-file-picker-icon [item]="item"></coyo-file-picker-icon>
      <ng-container *ngIf="(filePickerState$ | async) as state">
        <ng-container *ngIf="item.isFolder; else fileName">
          <div class="name-wrapper">
            <a *ngIf="state.renaming !== item.id; else rename"
               [attr.aria-label]="'FILEPICKER.FOLDER.ARIA' | translate : {folder: item.name}"
               class="folder-name"
               draggable="false"
               href="#">
              {{item.name}}
            </a>
            <div class="subline">
              <span class="visible-xs"><ng-template [ngTemplateOutlet]="fileSize"></ng-template></span>
            </div>
          </div>
        </ng-container>
        <ng-template #fileName>
          <ng-container *ngIf="state.renaming !== item.id; else rename">
            <div class="name-wrapper">
              <div class="name pl-badges">
                <span [attr.aria-label]="'FILEPICKER.FILE.ARIA' | translate : {file: item.name}">{{item.name}}</span>
                <span *ngIf="item.hasActivePublicLink" class="badge badge-warning">
                  {{'FILE_LIBRARY.BATCH.ACTIVE_SHARE_LINK' | translate}}
                </span>
                <span *ngIf="item.lock?.locked" class="badge badge-warning">
                  {{'FILE_LIBRARY.FILE_LOCKED' | translate}}
                </span>
              </div>
              <div class="subline">
                <span class="visible-xs"><ng-template [ngTemplateOutlet]="fileSize"></ng-template></span>
                <span class="visible-xs"><ng-template [ngTemplateOutlet]="date"></ng-template></span>
                <ng-container *ngIf="showAuthor$ | async">
                  <span *ngIf="author$ | async; let author; else skeleton">
                    {{'FILEPICKER.AUTHOR.BY' | translate}}
                    <coyo-sender-link [sender]="author"></coyo-sender-link>
                  </span>
                  <ng-template #skeleton>
                    <div class="skeleton">
                      <ui-skeleton [skeletons]="authorSkeletons"></ui-skeleton>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #rename>
          <mat-form-field appearance="outline" class="inline-form-field">
            <input #renameInput
                   (blur)="renameFile(renameInput.value)"
                   (click)="stopPropagation($event)"
                   (keydown.enter)="renameFile(renameInput.value)"
                   (keydown.escape)="cancelRenaming($event)"
                   [maxLength]="MAX_LENGTH"
                   [selectAll]="true"
                   coyoAutofocus
                   matInput
                   value="{{fileNameWithoutExtension$ | async}}">
            <span *ngIf="fileExtension$ | async as fileExtension" matSuffix>{{fileExtension}}</span>
          </mat-form-field>
        </ng-template>
      </ng-container>
    </div>
  </td>
  <td class="hidden-xs col-size">
    <ng-template [ngTemplateOutlet]="fileSize"></ng-template>
  </td>
  <td class="hidden-xs col-modified">
    <ng-template [ngTemplateOutlet]="date"></ng-template>
  </td>
  <td class="col-context-menu">
    <coyo-file-library-file-picker-item-context-menu
      [filePickerId]="filePickerId"
      [itemId]="item.id">
    </coyo-file-library-file-picker-item-context-menu>
  </td>

  <ng-template #date>
    <span class="hidden-xs">{{item.lastModified | date:'short'}}</span>
    <span class="visible-xs-inline">{{item.lastModified | date:'shortDate'}}</span>
  </ng-template>
  <ng-template #fileSize>
    <span *ngIf="!item.isFolder">{{item.sizeInBytes | fileSize}}</span>
    <span *ngIf="item.isFolder">{{'FILE_LIBRARY.FILE_COUNT' | translate:({count: item.file.childCount})}}</span>
  </ng-template>
</ng-container>


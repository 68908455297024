<li [ngClass]="{'active': active}">
  <a (click)="onClick()">
    <ng-container *ngIf="{isLoading: loading$ | async} as loadingState">
      <i *ngIf="icon && loadingState.isLoading"
         class="zmdi zmdi-spinner zmdi-hc-spin"
         [style]="iconCssColor"
      ></i>
      <mat-icon *ngIf="icon && !loadingState.isLoading"
        [svgIcon]="icon"
        [style]="iconCssColor"></mat-icon>
      <span class="text">{{text}}</span>
      <ng-content></ng-content>
      <span *ngIf="badge" class="badge">{{badge}}</span>
    </ng-container>
  </a>
</li>

import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {BlogArticleWidget, SingleBlogArticle} from '@widgets/blog-article/blog-article-widget';
import {BlogArticleWidgetService} from '@widgets/blog-article/blog-article-widget.service';
import * as _ from 'lodash';
import {BehaviorSubject, merge, Observable, of, Subject} from 'rxjs';
import {debounceTime, map, scan, startWith, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'coyo-blog-article-widget-settings',
  templateUrl: './blog-article-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleWidgetSettingsComponent extends WidgetSettingsComponent<BlogArticleWidget>
  implements OnInit, AfterViewInit, OnDestroy {
  static readonly DEFAULT_PAGE_SIZE: number = 20;

  @ViewChild('searchInput', {static: false}) searchInput: HTMLElement;

  blogArticles$: Observable<SingleBlogArticle[]>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private search$: Subject<string> = new Subject<string>();
  private loadMore$: Subject<void> = new Subject<void>();
  private lastPageReached: boolean = false;
  private currentPage: number = 0;
  private articlesBuffer: SingleBlogArticle[] = [];
  private selectedArticle: SingleBlogArticle;
  private clearArticles$: Subject<void> = new Subject<void>();

  constructor(private blogArticleWidgetService: BlogArticleWidgetService) {
    super();
  }

  ngOnInit(): void {
    const selectedArticleId = this.widget.settings?._articleId;
    this.parentForm.addControl('_articleId', new FormControl(selectedArticleId, Validators.required));

    this.blogArticles$ = merge(
      this.clearArticles$.pipe(map(() => [])),
      this.getBlogArticles$(selectedArticleId)
    );
  }

  private getBlogArticles$(selectedArticleId?: string): Observable<SingleBlogArticle[]> {
    const selectedArticleTitle$ = selectedArticleId ?
      this.blogArticleWidgetService.getArticleById(selectedArticleId).pipe(map(article => {
        this.selectedArticle = article;
        return article.title;
      })) : of('');
    return selectedArticleTitle$
      .pipe(
        switchMap(selectedArticleTitle =>
          this.generateSearchObservable$(selectedArticleTitle)
        ),
        map(page => this.addPageToBufferedArticles(page)),
        tap(() => this.loading$.next(false))
      );
  }

  private addPageToBufferedArticles(page: Page<SingleBlogArticle>): SingleBlogArticle[] {
    this.lastPageReached = page.last;
    const selectedArticleArray = this.selectedArticle ? [this.selectedArticle] : [];
    this.articlesBuffer =  [...selectedArticleArray, ...this.articlesBuffer, ...page.content]
      .uniqBy(article => article.id);
    return this.articlesBuffer;
  }

  private generateSearchObservable$(startTerm: string): Observable<Page<SingleBlogArticle>> {
    return this.search$.pipe(
      startWith(startTerm),
      tap(() => this.loading$.next(true)),
      debounceTime(300),
      switchMap(term => {
        this.articlesBuffer = [];
        return this.generateNewLoadMoreSubject$().pipe(
          map(pageNumber => ({
            pageNumber,
            term
          }))
        );
      }),
      switchMap(searchInfo =>
        this.blogArticleWidgetService.getArticles(
          new Pageable(searchInfo.pageNumber, BlogArticleWidgetSettingsComponent.DEFAULT_PAGE_SIZE),
          searchInfo.term
        )
      )
    );
  }

  private generateNewLoadMoreSubject$(): Observable<number> {
    this.loadMore$.complete();
    this.loadMore$ = new Subject<void>();
    return this.loadMore$.pipe(
      // tslint:disable-next-line:deprecation
      startWith(null),
      scan(pageNumber => pageNumber + 1, -1)
    );
  }

  ngAfterViewInit(): void {
    this.searchInput.focus();
  }

  onScroll(event: { end: number }): void {
    if (!this.lastPageReached &&
      event.end > (this.currentPage + 1) *
      BlogArticleWidgetSettingsComponent.DEFAULT_PAGE_SIZE -
      BlogArticleWidgetSettingsComponent.DEFAULT_PAGE_SIZE / 2) {
      this.onScrollToEnd();
    }
  }

  onScrollToEnd(): void {
    if (!this.loading$.getValue() && !this.lastPageReached) {
      this.loading$.next(true);
      this.loadMore$.next();
    }
  }

  search(term: string): void {
    this.clearArticles$.next();
    this.search$.next(term);
  }

  setArticle(event: SingleBlogArticle): void {
    this.selectedArticle = event;
  }

  ngOnDestroy(): void {
    this.search$.complete();
    this.loadMore$.complete();
    this.clearArticles$.complete();
  }
}

<mat-accordion>
  <mat-expansion-panel [hideToggle]="true" [disabled]="true">
    <mat-expansion-panel-header class="accordion-title pl-badges" collapsedHeight="72px">
      <mat-panel-title>
        {{'ADMIN.APPS_WIDGETS.WIDGETS.TITLE' | translate}}:
        {{'ADMIN.APPS_WIDGETS.PLUGINS.TITLE' | translate}}
        <span class="badge badge-note" [matTooltip]="'ADMIN.APPS_WIDGETS.PLUGINS.BETA.TOOLTIP' | translate">{{'ADMIN.APPS_WIDGETS.PLUGINS.BETA' | translate}}</span>
      </mat-panel-title>
      <a class="developer-guide-button" mat-flat-button href="https://dev.coyoapp.com/" target="_blank"><mat-icon svgIcon="info"></mat-icon>{{'ADMIN.APPS_WIDGETS.PLUGINS.DEVELOPER_GUIDE' | translate}}</a>
      <button class="add-button" mat-flat-button color="primary" (click)="add()">{{'ADMIN.APPS_WIDGETS.PLUGINS.ADD.BUTTON' | translate}}</button>
      <button class="add-button-mobile" mat-icon-button color="primary" [attr.aria-label]="'ADMIN.APPS_WIDGETS.PLUGINS.ADD.BUTTON' | translate" (click)="add()">
        <mat-icon svgIcon="plus-circled"></mat-icon>
      </button>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <ng-container *ngIf="plugins$ | async; let plugins; else loading">
    <mat-expansion-panel *ngFor="let plugin of plugins; trackBy: trackById">
      <mat-expansion-panel-header collapsedHeight="72px" expandedHeight="96px">
        <mat-panel-title>
          <div class="plugin-name">{{plugin.plugin.manifest.name}}</div>
          <div class="plugin-description">{{plugin.plugin.manifest.description}}</div>
        </mat-panel-title>
        <mat-panel-description>v{{plugin.plugin.manifest.pluginVersion}}</mat-panel-description>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-md-6">
          <mat-checkbox [disabled]="!(plugin.formValid | async)"
                        [checked]="plugin.plugin.enabled"
                        (change)="setEnabled(plugin.plugin, $event)">
            {{'ADMIN.APPS_WIDGETS.PLUGINS.ENABLED' | translate}}
            <mat-hint *ngIf="!(plugin.formValid | async)"
                      [innerHTML]="'ADMIN.APPS_WIDGETS.PLUGINS.ENABLED_INVALID' | translate | markdown"></mat-hint>
          </mat-checkbox>
          <mat-checkbox [checked]="plugin.plugin.restricted"
                        (change)="setRestricted(plugin.plugin, $event)"
                        class="mb-0">
            {{'ADMIN.APPS_WIDGETS.PLUGINS.RESTRICTED' | translate}}</mat-checkbox>
        </div>
        <div class="col-md-6">
          <dl>
            <dt *ngIf="plugin.plugin.manifest.data?.length">{{'ADMIN.APPS_WIDGETS.PLUGINS.DATA_PERMISSIONS' | translate}}</dt>
            <dd *ngIf="plugin.plugin.manifest.data?.length">{{plugin.plugin.manifest.data | pluginData}}</dd>
            <dt>{{'ADMIN.APPS_WIDGETS.PLUGINS.ENTRY_POINTS' | translate}}</dt>
            <dd>{{plugin.plugin.manifest.entryPoints.length}}</dd>
          </dl>
        </div>
      </div>
      <ng-container *ngIf="plugin.plugin.manifest.config">
        <hr class="global-config">
        <formly-form [form]="plugin.form"
                     [fields]="plugin.plugin.manifest.config | pluginConfigFields"
                     [model]="plugin.config"></formly-form>
      </ng-container>
      <mat-action-row>
        <button mat-flat-button color="primary" *ngIf="plugin.plugin.manifest.config"
                [loading]="updating$ | async | contains : plugin.plugin.id"
                [disabled]="plugin.form.invalid"
                (click)="update(plugin.plugin, plugin.config)">
          {{'ADMIN.APPS_WIDGETS.PLUGINS.SAVE.BUTTON' | translate}}
        </button>
        <button mat-button color="warn"
                [loading]="removing$ | async | contains : plugin.plugin.id"
                (click)="remove(plugin.plugin)">
          {{'ADMIN.APPS_WIDGETS.PLUGINS.REMOVE.BUTTON' | translate}}
        </button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel [hideToggle]="true" [disabled]="true" *ngIf="!plugins.length">
      <mat-expansion-panel-header class="accordion-empty" collapsedHeight="72px">
        <mat-panel-title>{{'ADMIN.APPS_WIDGETS.PLUGINS.EMPTY' | translate}}</mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </ng-container>
  <ng-template #loading>
    <mat-expansion-panel [hideToggle]="true" [disabled]="true">
      <mat-expansion-panel-header class="accordion-loading" collapsedHeight="72px">
        <mat-panel-title>
          <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </ng-template>
</mat-accordion>

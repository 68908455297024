import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Skeleton} from '@coyo/ui';
import {Target} from '@domain/sender/target';
import {TargetService} from '@domain/sender/target/target.service';
import {WidgetComponent} from '@widgets/api/widget-component';
import {BlogArticleWidget, SingleBlogArticle} from '@widgets/blog-article/blog-article-widget';
import {BlogArticleWidgetService} from '@widgets/blog-article/blog-article-widget.service';
import {Observable, Subject} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'coyo-blog-article-widget',
  templateUrl: './blog-article-widget.component.html',
  styleUrls: ['./blog-article-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleWidgetComponent extends WidgetComponent<BlogArticleWidget> implements OnInit, OnChanges {

  article$: Observable<SingleBlogArticle>;
  skeletons: Skeleton[] = [
    { top: 0, left: 0, width: '20%', height: 15 },
    { top: 0, left: 'calc(100% - 75px)', width: 75, height: 15 },
    { top: 30, left: 0, width: '35%', height: 20 },
    { top: 70, left: 0, width: '100%', height: 15 },
    { top: 95, left: 0, width: '95%', height: 15 },
    { top: 125, left: 0, width: 75, height: 15 }
  ];
  private change$: Subject<string> = new Subject<string>();

  constructor(private blogArticleWidgetService: BlogArticleWidgetService,
              private targetService: TargetService,
              cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.article$ = this.change$
      .pipe(startWith(this.widget.settings._articleId))
      .pipe(switchMap(articleId => this.blogArticleWidgetService.getArticleById(articleId)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget && changes.widget.currentValue.settings) {
      this.change$.next(changes.widget.currentValue.settings._articleId);
    }
  }

  getLink(target: Target): string {
    return this.targetService.getLinkTo(target);
  }

}

<h1 mat-dialog-title translate="COMMONS.SHARES.LIST.TITLE"></h1>
<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
<div mat-dialog-content class="coyo-new">
  <p *ngIf="!data.shares?.length"
     class="text-muted text-center no-shares-notice"
     translate="MODULE.TIMELINE.SHARE_MODAL.NO_SHARES_NOTICE"></p>
  <ul>
    <li *ngFor="let share of data.shares; let first = first; let last = last">
      <coyo-timeline-share
        [class.first-timeline-share-item]="first"
        [class.last-timeline-share-item]="last"
        [share]="share"
        [itemType]="data.itemType"
        (shareDeleted)="deleteShare($event)">
      </coyo-timeline-share>
    </li>
  </ul>
</div>

(function (angular) {
  'use strict';

  SuppressScrollController.$inject = ["$scope", "$rootScope", "$window"];
  angular
      .module('commons.layout')
      .directive('oyocSuppressScroll', suppressScroll)
      .controller('SuppressScrollController', SuppressScrollController);

  /**
   * @ngdoc directive
   * @name coyo.app.oyocSuppressScroll:oyocSuppressScroll
   * @element ANY
   * @restrict A
   * @scope
   *
   * @description
   * Suppress the scrolling of the given container when a modal or sidebar is open.
   *
   * @requires $scope
   * @requires $rootScope
   * @requires $window
   */
  function suppressScroll() {
    return {
      restrict: 'A',
      controller: 'SuppressScrollController'
    };
  }

  function SuppressScrollController($scope, $rootScope, $window) {
    var vm = this;
    var scrollOffset = 0;
    var htmlElement = angular.element(document).find('html');
    var bodyElement = angular.element(document).find('body');

    vm.$onInit = onInit;

    function onInit() {
      var _unregisterTransitionChangedEvent = $rootScope.$on('transitions:start', function () {
        scrollOffset = 0;
      });

      var _unregisterWatch = $scope.$watch(function () {
        return $rootScope.showBackdrop;
      }, handleBackdropChange);

      $scope.$on('$destroy', function () {
        _unregisterWatch();
        _unregisterTransitionChangedEvent();
        _unpinBackground();
      });
    }

    function handleBackdropChange(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (newValue) {
        _pinBackground();
      } else {
        _unpinBackground();
      }
    }

    function _pinBackground() {
      scrollOffset = htmlElement[0].scrollTop || bodyElement[0].scrollTop || 0;
      htmlElement.addClass('fixed');
      bodyElement.css({'position': 'fixed', 'top': (-scrollOffset) + 'px'});
    }

    function _unpinBackground() {
      htmlElement.removeClass('fixed');
      bodyElement.css({'position': '', 'top': ''});
      $window.scrollTo(0, scrollOffset);
    }
  }

})(angular);

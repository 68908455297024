import {HtmlTagSearchResult} from './html-tag-search-result';
import {HtmlTagTreeNode} from './html-tag-tree-node';

class HtmlTagTypeDefinition {
  constructor(public type: string, public regExp: RegExp) {
  }
}

/**
 * Pseudo dom-tree root node
 */
export class HtmlTagTree {

  private static openTagRegexp: HtmlTagTypeDefinition = new HtmlTagTypeDefinition('opening', new RegExp(/<([a-zA-Z\-]+)(?:(?:\s[^>]*[^\/])|\s*)>/, 'g'));
  private static closeTagRegexp: HtmlTagTypeDefinition = new HtmlTagTypeDefinition('closing', new RegExp(/<\/([a-zA-Z\-]+)\s*>/, 'g'));
  private static selfClosingTagRegexp: HtmlTagTypeDefinition = new HtmlTagTypeDefinition('self-closing', new RegExp(/<([a-zA-Z\-]+)[^>]*\/\s*>/, 'g'));
  private static tagDefinitions: HtmlTagTypeDefinition[] = [HtmlTagTree.openTagRegexp, HtmlTagTree.closeTagRegexp, HtmlTagTree.selfClosingTagRegexp];

  private static voidTags: string[] = [
    'area',
    'base',
    'basefont',
    'bgsound',
    'br',
    'col',
    'command',
    'embed',
    'frame',
    'hr',
    'image',
    'img',
    'input',
    'isindex',
    'keygen',
    'link',
    'menuitem',
    'meta',
    'nextid',
    'param',
    'source',
    'track',
    'wbr'
  ];
  readonly tags: HtmlTagSearchResult[];
  readonly roots: HtmlTagTreeNode[];

  constructor(readonly text: string) {
    this.tags = HtmlTagTree.findHtmlTags(this.text);
    this.roots = HtmlTagTree.constructTagTree(this.tags, 0, this.tags.length);
  }

  /**
   * Searches for HTML-Tags in a string
   *
   * @param text The string to parse
   *
   * @returns a list of HTML-Tags found in the given string
   */
  static findHtmlTags(text: string): HtmlTagSearchResult[] {
    const results: RegExpExecArray[] = [];
    const done: boolean[] = [];
    const count = HtmlTagTree.tagDefinitions.length;

    for (let i = 0; i < count; i++) {
      results.push(null);
      done.push(false);
    }

    const result: HtmlTagSearchResult[] = [];
    let next: number;
    do {
      // execute multiple regexps
      HtmlTagTree.tagDefinitions.forEach((expr, i) => {
        if (!done[i] && results[i] == null) {
          results[i] = expr.regExp.exec(text);
          if (results[i] == null) {
            done[i] = true;
          }
        }
      });
      // find closest tag (lowest index)
      next = HtmlTagTree.findNextResultIndex(results);
      if (next != null) {
        result.push(HtmlTagTree.createResult(HtmlTagTree.tagDefinitions[next], results[next]));
        results[next] = null;
      }
    }
    while (next != null) ;
    return result;
  }

  private static constructTagTree(tags: HtmlTagSearchResult[], start: number, end: number): HtmlTagTreeNode[] {
    let i = start;
    const nodes: HtmlTagTreeNode[] = [];
    while (i < end) {
      const closingIndex = HtmlTagTree.findClosingNode(tags, i);
      const node = new HtmlTagTreeNode(tags[i], tags[closingIndex]);
      node.children = HtmlTagTree.constructTagTree(tags, i + 1, closingIndex);
      nodes.push(node);
      i = closingIndex + 1;
    }
    return nodes;
  }

  private static findClosingNode(tags: HtmlTagSearchResult[], index: number): number {
    if (tags[index].type === 'self-closing') {
      return index;
    }
    let level = 0;
    for (let i = index + 1; i < tags.length && level >= 0; i++) {
      if (tags[i].type === 'opening') {
        level++;
      } else if (tags[i].type === 'closing') {
        if (level === 0 && tags[i].tag === tags[index].tag) {
          return i;
        }
        level--;
      }
    }
    tags[index].type = 'self-closing';
    return index;
  }

  private static findNextResultIndex(results: RegExpExecArray[]): number {
    let lowestIndex: number = null;
    let lowestCharIndex = Number.POSITIVE_INFINITY;
    results.forEach((result: RegExpMatchArray, i: number) => {
      if (result != null && result.index < lowestCharIndex) {
        lowestIndex = i;
        lowestCharIndex = result.index;
      }
    });
    return lowestIndex;
  }

  private static createResult(definition: HtmlTagTypeDefinition, result: RegExpExecArray): HtmlTagSearchResult {
    let type = definition.type;
    if (type !== 'self-closing' && HtmlTagTree.voidTags.indexOf(result[1]) >= 0) {
      type = 'self-closing';
    }
    return new HtmlTagSearchResult(type, result.index, result.index + result[0].length, result[0].length, result[1], result[0]);
  }

  /**
   * @returns The length of the raw content string
   */
  get contentLength(): number {
    return this.text ? this.text.length : 0;
  }

  /**
   * @returns The length of the text outside of html-tags within the content
   */
  get textLength(): number {
    return this.text ? this.text.length - this.tags.reduce((sum, tag) => sum + tag.length, 0) : 0;
  }

  /**
   * Transforms a text-index to a content-index
   * Content-index is the global index in the input string
   * Text-index is the index within only the text-content of the input string (omitting all html-tags)
   *
   * @param contentIndex The content-index
   *
   * @returns the according text-index
   */

  getTextIndexAt(contentIndex: number): number {
    return Math.min(contentIndex - this.tags
      .filter(tag => tag.start < contentIndex)
      .reduce((sum, tag) => sum + tag.length, 0), this.textLength);
  }

  /**
   * Transforms a content-index to a text-index
   * Content-index is the global index in the input string
   * Text-index is the index within only the text-content of the input string (omitting all html-tags)
   *
   * @param textIndex The text-index
   *
   * @returns the according content-index
   */
  getContentIndexAt(textIndex: number): number {
    let tagLength = 0;
    for (const tag of this.tags) {
      if (tag.start <= textIndex + tagLength) {
        tagLength += tag.length;
      } else {
        break;
      }
    }
    return Math.min(textIndex + tagLength, this.contentLength);
  }

  /**
   * Searches for a string within the text content of the tree
   *
   * @param str The string to search for
   * @param startIndex The content index to start searching from
   *
   * @returns The content-index of the first occurrence of the given string or -1 if it is not found
   */
  contentIndexOf(str: string, startIndex: number = 0): number {
    if (!this.text) {
      return -1;
    }
    const index = this.text.indexOf(str, startIndex);
    if (index >= 0) {
      for (const node of this.roots) {
        if (node.isIndexInTag(index)) {
          return this.contentIndexOf(str, index + 1);
        }
      }
    }
    return index;
  }

  /**
   * Checks if the given char-index is between the start and end of this tag (including the tags themselves)
   *
   * @param index An index in the string
   *
   * @returns True if the index is within the scope of this node, false otherwise
   */
  isIndexInScope(index: number): boolean {
    return this.text ? index >= 0 && index < this.text.length : false;
  }

  /**
   * Checks if the given char-index is inside any start or end tag within the scope of this node
   *
   * @param index An index in the string
   *
   * @returns True if the index is inside any tag within the scope of this node, false otherwise
   */
  isIndexInTag(index: number): boolean {
    let isInTag = false;
    this.roots.forEach(node => {
      isInTag = isInTag || node.isIndexInTag(index);
    });
    return isInTag;
  }

  /**
   * Returns a list of tag-names that are open but not closed at the given index in the string
   *
   * @param index An index in the string
   *
   * @returns a list of tag-names, sorted by nesting depth reversed (deepest first)
   */
  getOpenNodesAt(index: number): string[] {
    const result: string[] = [];
    this.roots.forEach(node => {
      result.push(...node.getOpenNodesAt(index));
    });
    return result;
  }
}

import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {EventCreateMenuComponent} from '@app/events/event-create/event-create-menu/event-create-menu.component';
import {EventCreateComponent} from '@app/events/event-create/event-create.component';
import {EventDateLabelComponent} from '@app/events/event-date-label/event-date-label.component';
import {SumTotalParticipationPipe} from '@app/events/event-filter-participation-status/sum-total-participation/sum-total-participation.pipe';
import {EventSettingsInviteUsersModalComponent} from '@app/events/event-settings/event-settings-invite-users-modal/event-settings-invite-users-modal.component';
// tslint:disable-next-line:max-line-length
import {EventSettingsSelectAdminsModalComponent} from '@app/events/event-settings/event-settings-select-admins-modal/event-settings-select-admins-modal.component';
import {EventSettingsComponent} from '@app/events/event-settings/event-settings.component';
import {FilterModule} from '@app/filter/filter.module';
import {TourModule} from '@app/tour/tour.module';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DateRangePickerModule} from '@shared/date-range-picker/date-range-picker.module';
import {DividerModule} from '@shared/divider/divider.module';
import {EmptyListNoticeModule} from '@shared/empty-list-notice/empty-list-notice.module';
import {EventMetaDataModule} from '@shared/event-meta-data/event-meta-data.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {MaterialModule} from '@shared/material/material.module';
import {RibbonModule} from '@shared/ribbon/ribbon.module';
import {RteModule} from '@shared/rte/rte.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {UIRouterModule} from '@uirouter/angular';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WidgetsModule} from '@widgets/widgets.module';
import {MomentModule} from 'ngx-moment';
import '../shared/event-meta-data/event-location/event-location.component.downgrade';
import '../shared/event-meta-data/event-participation/event-participation.component.downgrade';
import '../shared/sender-ui/event-avatar/event-avatar.component.downgrade';
import './event-create/event-create-menu/event-create-menu.component.downgrade';
import './event-create/event-create.component.downgrade';
import './event-date-label/event-date-label.component.downgrade';
import {EventFilterParticipationStatusComponent} from './event-filter-participation-status/event-filter-participation-status.component';
import './event-filter-participation-status/event-filter-participation-status.component.downgrade';
import {EventListComponent} from './event-list/event-list.component';
import './event-list/event-list.component.downgrade';
import {ShowDividerPipe} from './event-list/show-divider/show-divider.pipe';
import {StartsTodayPipe} from './event-list/starts-today/starts-today.pipe';
// tslint:disable-next-line:max-line-length
import {EventSettingsDeleteConfirmationModalComponent} from './event-settings/event-settings-delete-confirmation-modal/event-settings-delete-confirmation-modal.component';
import './event-settings/event-settings-modal.service.downgrade';
import './event-settings/event-settings.component.downgrade';
import './event-status-icon/event-status-icon.service.downgrade';
import {EventsComponent} from './events/events.component';
import './events/events.component.downgrade';

/**
 * Event feature module
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    DateRangePickerModule,
    DividerModule,
    EmptyListNoticeModule,
    EventMetaDataModule,
    FilterModule,
    MaterialModule,
    MatPaginatorModule, // temp
    MomentModule,
    ReactiveFormsModule,
    RibbonModule,
    RteModule,
    SenderUIModule,
    TourModule,
    UIRouterModule,
    UpgradeModule,
    WidgetsModule
  ],
  declarations: [
    EventCreateComponent,
    EventCreateMenuComponent,
    EventDateLabelComponent,
    EventFilterParticipationStatusComponent,
    EventListComponent,
    EventsComponent,
    EventSettingsComponent,
    EventSettingsDeleteConfirmationModalComponent,
    EventSettingsDeleteConfirmationModalComponent,
    EventSettingsInviteUsersModalComponent,
    EventSettingsSelectAdminsModalComponent,
    EventSettingsSelectAdminsModalComponent,
    ShowDividerPipe,
    StartsTodayPipe,
    SumTotalParticipationPipe
  ],
  exports: [
    EventCreateComponent,
    EventCreateMenuComponent,
    EventDateLabelComponent,
    EventFilterParticipationStatusComponent,
    EventListComponent,
    EventsComponent,
    EventSettingsComponent,
    EventSettingsInviteUsersModalComponent
  ]
})
export class EventsModule {
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {HashtagWidget} from '@widgets/hashtag/hashtag-widget';
import {HashtagWidgetSettingsComponent} from './hashtag-widget-settings/hashtag-widget-settings.component';
import {HashtagWidgetComponent} from './hashtag-widget/hashtag-widget.component';

export const HASHTAG_WIDGET: WidgetConfig<HashtagWidget> = {
  key: 'hashtag',
  name: 'WIDGET.HASHTAG.NAME',
  description: 'WIDGET.HASHTAG.DESCRIPTION',
  icon: 'zmdi-label-heart',
  component: HashtagWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  settings: {
    skipOnCreate: false,
    component: HashtagWidgetSettingsComponent
  },
  renderOptions: {
    printable: false
  },
  whitelistExternal: false
};

import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UpdateWidgetSettings} from '@widgets/api/widget.actions';
import {RichTextWidget} from '@widgets/rich-text/rich-text-widget';
import {RichTextWidgetSettings} from '@widgets/rich-text/rich-text-widget-settings';

@Component({
  selector: 'coyo-rich-text-editor-widget',
  templateUrl: './rich-text-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextWidgetComponent extends WidgetComponent<RichTextWidget> {

  constructor(private store: Store, cd: ChangeDetectorRef) {
    super(cd);
  }

  contentUpdated($event: string): void {
    this.store.dispatch(new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId, {
      ...this.widget.settings,
      html_content: $event
    } as RichTextWidgetSettings, this.editScope));
  }
}

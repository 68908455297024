import {CdkDragSortEvent} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatDialogSize} from '@coyo/ui';
import {TranslateService} from '@ngx-translate/core';
import {DeleteConfirmationService} from '@shared/dialog/delete-confirmation/delete-confirmation.service';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {NewSlideDialogComponent} from '@widgets/teaser/new-slide-dialog/new-slide-dialog.component';
import {TeaserWidget} from '@widgets/teaser/teaser-widget';
import * as _ from 'lodash';

/**
 * The teaser widget settings component.
 */
@Component({
  selector: 'coyo-teaser-widget-settings',
  templateUrl: './teaser-widget-settings.component.html',
  styleUrls: ['./teaser-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeaserWidgetSettingsComponent extends WidgetSettingsComponent<TeaserWidget>
  implements OnInit {

  constructor(private dialog: MatDialog,
              private cd: ChangeDetectorRef,
              private dialogRef: MatDialogRef<TeaserWidgetSettingsComponent>,
              private translateService: TranslateService,
              private deleteConfirmationService: DeleteConfirmationService) {
    super();
  }

  ngOnInit(): void {
    _.defaults(this.widget.settings, {
      _format: '3:1',
      _autoplayDelay: 5,
      slides: [
        {
          _image: 'assets/images/widgets/teaser/dummy-content/dreaming_of_clouds.jpg',
          _url: '/pages/company-news/apps/timeline/timeline',
          _alt: '',
          headline: this.translateService.instant('WIDGET.TEASER.SETTINGS.SLIDES.LABEL.DEFAULT.ONE.HEADLINE'),
          _newTab: false
        },
        {
          _image: 'assets/images/widgets/teaser/dummy-content/favorit_act.jpg',
          _url: '/pages/company-news/apps/timeline/timeline',
          _alt: '',
          headline: this.translateService.instant('WIDGET.TEASER.SETTINGS.SLIDES.LABEL.DEFAULT.TWO.HEADLINE'),
          _newTab: false
        },
        {
          _image: 'assets/images/widgets/teaser/dummy-content/new_client.jpg',
          _url: '/pages/company-news/apps/timeline/timeline',
          _alt: '',
          headline: this.translateService.instant('WIDGET.TEASER.SETTINGS.SLIDES.LABEL.DEFAULT.THREE.HEADLINE'),
          _newTab: false
        }
      ]
    });

    this.parentForm.addControl('_format', new FormControl(this.widget.settings._format));
    this.parentForm.addControl('_autoplayDelay', new FormControl(this.widget.settings._autoplayDelay, [Validators.required, Validators.min(1)]));
    this.parentForm.addControl('slides', new FormArray([], [Validators.required]));

    this.widget.settings.slides.forEach(slide => {
      (this.parentForm.get('slides') as FormArray).push(new FormGroup({
        _image: new FormControl(slide._image),
        _url: new FormControl(slide._url),
        _alt: new FormControl(slide._alt),
        headline: new FormControl(slide.headline),
        _newTab: new FormControl(slide._newTab)
      }));
    });
  }

  addSlide(): void {
    this.hideDialog();
    const matDialogRef = this.dialog.open(NewSlideDialogComponent, {
      width: MatDialogSize.Medium,
      data: {}
    });
    matDialogRef.afterClosed().subscribe(newSlide => {
      if (newSlide) {
        (this.parentForm.get('slides') as FormArray).push(new FormGroup({
          _image: new FormControl(newSlide._image),
          _url: new FormControl(newSlide._url),
          _alt: new FormControl(newSlide._alt),
          headline: new FormControl(newSlide.headline),
          _newTab: new FormControl(newSlide._newTab)
        }));
      }
      this.showDialog();
      this.cd.markForCheck();
    });
  }

  editSlide(index: number): void {
    this.hideDialog();
    const matDialogRef = this.dialog.open(NewSlideDialogComponent, {
      width: MatDialogSize.Medium,
      hasBackdrop: false,
      data: (this.parentForm.get('slides') as FormArray).at(index).value
    });
    matDialogRef.afterClosed().subscribe(newSlide => {
      if (newSlide) {
        (this.parentForm.get('slides') as FormArray).at(index).patchValue(newSlide);
        this.cd.markForCheck();
      }
      this.showDialog();
    });
  }

  deleteSlide(index: number): void {
    this.deleteConfirmationService.open(
      'WIDGET.TEASER.SETTINGS.DELETE.SLIDE.TITLE',
      'WIDGET.TEASER.SETTINGS.DELETE.SLIDE.DESCRIPTION',
      'WIDGET.TEASER.SETTINGS.DELETE.SLIDE',
      'WIDGET.TEASER.SETTINGS.DELETE.SLIDE.KEEP')
      .subscribe(
        doDelete => {
          if (doDelete) {
            (this.parentForm.get('slides') as FormArray).removeAt(index);
            this.cd.markForCheck();
          }
        });
  }

  getSlides(): FormArray {
    return this.parentForm.get('slides') as FormArray;
  }

  dropped($event: CdkDragSortEvent): void {
    const control = this.getSlides().at($event.previousIndex);
    this.getSlides().removeAt($event.previousIndex);
    this.getSlides().insert($event.currentIndex, control);
  }

  private hideDialog(): void {
    this.dialogRef.addPanelClass('ng-hide');
  }

  private showDialog(): void {
    this.dialogRef.removePanelClass('ng-hide');
  }
}

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Options} from '@root/typings';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Base domain service providing HTTP request methods.
 */
@Injectable({
  providedIn: 'root'
})
export abstract class DomainService<Req, Res> {

  constructor(@Inject(HttpClient) protected http: HttpClient,
              @Inject(UrlService) protected urlService: UrlService) {
  }

  /**
   * Returns the base URL used for all requests of this service.
   *
   * @returns the base URL.
   */
  protected abstract getBaseUrl(): string;

  /**
   * Builds a URL and replaces all dynamic URL patterns (e.g. 'id' in '/{id}/foo') with values of the given context.
   *
   * @param context
   * The context of the url containing values for the path param.
   *
   * @param path
   * The additional path which will be appended to the base url of this service.
   *
   * @returns the URL based on the given context.
   */
  getUrl(context?: { [key: string]: string }, path?: string): string {
    const regex = /\{[^\}]+\}/g;
    const baseUrl = this.getBaseUrl();
    const parsedUrl = _.reduce((baseUrl || '').match(regex), (acc, key) =>
      acc.replace(key, _.get(context, key.substring(1, key.length - 1), '')), baseUrl);
    const parsedPath = _.reduce((path || '').match(regex), (acc, key) =>
      acc.replace(key, _.get(context, key.substring(1, key.length - 1), '')), path);
    return '/' + this.urlService.join(parsedUrl, parsedPath);
  }

  /**
   * Construct a GET request which interprets the body as a list of `Res` and returns it.
   *
   * @param options
   * An option object containing the context, path, header, param and permission information.
   *
   * @return an `Observable` of the body as an array of `Res`.
   */
  getAll(options?: Options): Observable<Res[]> {
    return this.http.get<Res[]>(this.buildUrl(options), {
      headers: this.mergeHeaders(
        _.get(options, 'headers'),
        _.get(options, 'handleErrors', true)),
      params: this.mergeParams(
        _.get(options, 'params'),
        _.get(options, 'permissions'))
    });
  }

  /**
   * Construct a GET request which interprets the body as a page of `Res` and returns it.
   *
   * @param pageable
   * Containing the pagination information.
   *
   * @param options
   * An option object containing the context, path, header, param and permission information.
   *
   * @return an `Observable` of the body as a page of `Res`.
   */
  getPage(pageable: Pageable, options?: Options): Observable<Page<Res>> {
    return this.http.get<Page<Res>>(this.buildUrl(options), {
      headers: this.mergeHeaders(
        _.get(options, 'headers'),
        _.get(options, 'handleErrors', true)),
      params: this.mergeParams(
        pageable.toHttpParams(_.get(options, 'params', new HttpParams())),
        _.get(options, 'permissions'))
    });
  }

  /**
   * Construct a GET request which interprets the body as an object of `Res` and returns it.
   *
   * @param id
   * the id of the domain object to request.
   *
   * @param options
   * An option object containing the context, path, header, param and permission information.
   *
   * @return an `Observable` of the body as an object of `Res`.
   */
  get(id: string, options?: Options): Observable<Res> {
    return this.http.get<Res>(this.buildUrl(options, id), {
      headers: this.mergeHeaders(
        _.get(options, 'headers'),
        _.get(options, 'handleErrors', true)),
      params: this.mergeParams(
        _.get(options, 'params'),
        _.get(options, 'permissions'))
    });
  }

  /**
   * Construct a POST request which interprets the body as an object of `Res` and returns it.
   *
   * @param body
   * The actual data to post of type `Req`.
   *
   * @param options
   * An option object containing the context, path, header, param and permission information.
   *
   * @param id
   * An optional id that the post request should go to
   *
   * @return an `Observable` of the body as an object of `Res`.
   */
  post(body: Req, options?: Options, id?: string): Observable<Res> {
    return this.http.post<Res>(this.buildUrl(options, id), body, {
      headers: this.mergeHeaders(
        _.get(options, 'headers'),
        _.get(options, 'handleErrors', true)),
      params: this.mergeParams(
        _.get(options, 'params'),
        _.get(options, 'permissions'))
    });
  }

  /**
   * Construct a PUT request which interprets the body as an object of `Res` and returns it.
   *
   * @param id
   * The id of the object.
   *
   * @param body
   * The actual data to put of type `Req`.
   *
   * @param options
   * An option object containing the context, path, header, param and permission information.
   *
   * @return an `Observable` of the body as an object of `Res`.
   */
  put(id: string, body: Req, options?: Options): Observable<Res> {
    return this.http.put<Res>(this.buildUrl(options, id), body, {
      headers: this.mergeHeaders(
        _.get(options, 'headers'),
        _.get(options, 'handleErrors', true)),
      params: this.mergeParams(
        _.get(options, 'params'),
        _.get(options, 'permissions'))
    });
  }

  /**
   * Construct a DELETE request.
   *
   * @param id
   * The id of the object to be deleted.
   *
   * @param options
   * An option object containing the context, path, header, param and permission information.
   *
   * @return an empty `Observable`.
   */
  delete(id: string, options?: Options): Observable<void> {
    return this.http.delete(this.buildUrl(options, id), {
      headers: this.mergeHeaders(
        _.get(options, 'headers'),
        _.get(options, 'handleErrors', true)),
      params: this.mergeParams(
        _.get(options, 'params'),
        _.get(options, 'permissions'))
    }).pipe(map(() => null));
  }

  protected buildUrl(options: Options, id?: string): string {
    const context = _.get(options, 'context');
    const path = _.get(options, 'path');
    const url = this.getUrl(context, path);
    return id ? url + '/' + id : url;
  }

  protected mergeHeaders(headers: HttpHeaders | { [header: string]: string | string[] },
                       handleErrors: boolean): HttpHeaders | { [header: string]: string | string[] } {
    if (handleErrors) {
      return headers;
    } else if (headers instanceof HttpHeaders) {
      return headers.set('handleErrors', `${handleErrors}`);
    } else {
      return {...headers, ...{handleErrors: `${handleErrors}`}};
    }
  }

  /**
   * Merges the given params with the given permissions to a new HttpParams object
   *
   * @param params The parameters
   * @param permissions The permissions to merge in
   *
   * @returns The merged params
   */
  protected mergeParams(params?: HttpParams | {
    [param: string]: string | string[];
  }, permissions?: string[]): HttpParams | {
    [param: string]: string | string[];
  } {
    if (_.isEmpty(permissions)) {
      return params;
    } else if (params instanceof HttpParams) {
      return params.append('_permissions', _.join(permissions, ','));
    } else {
      return {...params, ...{_permissions: _.join(permissions, ',')}};
    }
  }
}

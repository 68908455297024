import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {EventSettingsParticipantsDialogData} from '@app/events/event-settings/event-settings-participants-dialog-data';
import {EventService} from '@domain/event/event.service';
import {UserChooserSelection} from '@shared/sender-ui/user-chooser/user-chooser-selection';

@Component({
  selector: 'coyo-event-settings-participants-modal',
  templateUrl: './event-settings-invite-users-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventSettingsInviteUsersModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: EventSettingsParticipantsDialogData,
              private dialogRef: MatDialogRef<EventSettingsInviteUsersModalComponent>,
              private eventService: EventService) {
  }

  submit(selection: UserChooserSelection): void {
    this.eventService.updateEventMemberships(this.data.entityId.id, selection)
      .subscribe({
        next: () => this.close(selection),
        error: () => this.close()
      });
  }

  close(selection?: UserChooserSelection): void {
    this.dialogRef.close(selection);
  }
}

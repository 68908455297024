<cdk-virtual-scroll-viewport class="table-container floating-scroll-container"
                             *ngIf="guestDataSource | async as dataSource"
                             [itemSize]="virtualScrollSizes[scrollSettings].itemSize"
                             [minBufferPx]="virtualScrollSizes[scrollSettings].minBufferPx"
                             [maxBufferPx]="virtualScrollSizes[scrollSettings].maxBufferPx">
  <table>
    <tbody>
      <ng-container *cdkVirtualFor="let guest of dataSource">

        <tr *ngIf="guest"
            [ngClass]="{'clickable': renderCheckbox && !guest.disableForSelection, 'disabled': guest.disableForSelection}"
            (click)="renderCheckbox && !guest.disableForSelection && change($event, guest)">
          <td>
            <div class="avatar-image">
              <coyo-sender-avatar [sender]="guest" [avatarSize]="'sm'" [noLink]="true"></coyo-sender-avatar>
            </div>
          </td>
          <td class="invite-info-wrapper">
            <div class="invite-info">
              <span class="name" title="{{guest.displayName}}">{{guest.displayName}}</span>
              <ng-container *ngIf="showAdditionalInfos || guest.disableForSelection">
                <div class="additional-information" *ngFor="let additionalInfo of guest?.additionalInformation">
                  <span class="already-invited-info" *ngIf="guest.disableForSelection" translate="USER.CHOOSER.GUEST_LIST.ALREADY_INVITED"></span>
                  <span translate [translateParams]="processParams(additionalInfo?.translationArgs)">{{additionalInfo?.translationKey}}</span>
                </div>
              </ng-container>
            </div>
          </td>
          <td class="shrink">
            <mat-checkbox labelPosition="before"
                          *ngIf="renderCheckbox"
                          [ngModel]="guest.selected"
                          [disabled]="guest.disableForSelection"
                          (keyup.space)="!guest.disableForSelection && change($event, guest)"
                          (click)="!guest.disableForSelection && change($event, guest)">
            </mat-checkbox>
            <button type="button"
                    class="remove-invite"
                    mat-icon-button
                    *ngIf="!renderCheckbox"
                    [attr.aria-label]="'USER.CHOOSER.INVITE.REMOVE' | translate"
                    (click)="guest.selected = false; emitChange(guest);">
              <mat-icon svgIcon="close"></mat-icon>
            </button>
          </td>
        </tr>

        <tr *ngIf="!guest" class="table-row-skeleton">
          <td colspan="3">
            <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
          </td>
        </tr>

      </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="{
      isEmpty: dataSource.isEmpty$ | async,
      tooManyResults: dataSource.tooManyResults$ | async,
      startSearching: dataSource.hideInitialPage$ | async
    } as dataSourceStatus">
    <div *ngIf="dataSourceStatus.isEmpty || dataSourceStatus.tooManyResults || dataSourceStatus.startSearching" class="empty-search">
      <span *ngIf="dataSourceStatus.startSearching" translate="USER_CHOOSER.START_SEARCHING"></span>
      <span *ngIf="dataSourceStatus.isEmpty" translate="USER_CHOOSER.EMPTY.SEARCH"></span>
      <span *ngIf="!dataSourceStatus.startSearching && dataSourceStatus.tooManyResults" translate="USER_CHOOSER.TOO_MANY_RESULTS"></span>
    </div>
  </ng-container>
</cdk-virtual-scroll-viewport>


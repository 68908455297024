import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {EtagInterceptor} from '@core/http/etag-interceptor/etag-interceptor';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {Pageable} from '@domain/pagination/pageable';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {Share} from '@domain/share/share';
import {LastUpdateResponse} from '@domain/timeline-item/last-update-response';
import {NewTimelineItemsResponse} from '@domain/timeline-item/new-timeline-items-response';
import {TimelineItemTarget} from '@domain/timeline-item/timeline-item-target';
import {CoyoConfig} from '@root/typings';
import {NG1_COYO_CONFIG} from '@upgrade/upgrade.module';
import {Observable, Subject} from 'rxjs';
import {bufferTime, filter, first, map, mergeMap, share} from 'rxjs/operators';
import {TimelineItem} from './timeline-item';
import {TimelineItemRequest} from './timeline-item-request';

/**
 * Service for all request methods to the timeline item domain.
 */
@Injectable({
  providedIn: 'root'
})
export class TimelineItemService extends DomainService<TimelineItemRequest, TimelineItem> {

  /**
   * Time frame to collect other requests.
   */
  static readonly THROTTLE: number = 50;

  /**
   * List of possible timeline item permissions
   */
  static readonly TIMELINE_ITEM_PERMISSIONS: string[] =
    ['edit', 'delete', 'accessoriginalauthor', 'like', 'comment', 'share', 'sticky', 'actAsSender'];

  /**
   * Default page size for timeline
   */
  static readonly TIMELINE_PAGE_SIZE: number = 8;

  readonly shareCountBatchRequestSubject: Subject<string> = new Subject();

  readonly getBulkShareRequest: Observable<{ [id: string]: number }> = this.shareCountBatchRequestSubject
    .pipe(bufferTime(TimelineItemService.THROTTLE))
    .pipe(filter(ids => ids && ids.length > 0))
    .pipe(mergeMap(ids => this.request(ids)), share());

  constructor(protected http: HttpClient,
              protected urlService: UrlService,
              protected senderService: SenderService,
              @Inject(NG1_COYO_CONFIG) private coyoConfig: CoyoConfig) {
    super(http, urlService);
  }

  /**
   * Returns the share count for a timeline item.
   *
   * @param id the timeline id.
   * @return the share count.
   */
  getShareCount(id: string): Observable<number> {
    setTimeout(() => {
      // emit the if after returning the observable so we that the multicasted observable is subscribed and actually emitting
      this.shareCountBatchRequestSubject.next(id);
    });
    return this.getBulkShareRequest
      .pipe(filter(result => result[id] !== undefined), map(ids => ids[id]), first());
  }

  /**
   * Returns a timeline item for an id.
   *
   * @param id The timeline item id.
   * @param timelineType The timeline type.
   * @param senderId The sender id.
   * @param permissions The requested permissions.
   * @return the timeline item.
   */
  getItem(id: string, timelineType: 'personal' | 'sender', senderId: string, permissions: string[]): Observable<TimelineItem> {
    return super.get(id,
      {
        params: {timelineType: timelineType.toUpperCase(), senderId},
        permissions: permissions
      });
  }

  /**
   * Returns an icon
   * @param sender The sender
   * @returns the icon
   */
  getAuthorIcon(sender: Sender): string {
    return this.coyoConfig.entityTypes[sender.typeName].icon;
  }

  /**
   * Returns the relevant share of a timeline item.
   * @param timelineItemTarget The timeline item target
   * @param senderId The sender id
   * @param timelineType The timeline type
   * @returns an observable that emits the relevant share
   */
  getRelevantShare(timelineItemTarget: TimelineItemTarget, senderId: string, timelineType: string): Observable<Share> {
    return this.http.get<Share>(this.getUrl({
      id: timelineItemTarget.itemId
    }, '/{id}/relevant-share'), {
      headers: new HttpHeaders({
        handleErrors: 'false'
      }),
      params: {
        senderId: senderId,
        timelineType: timelineType.toUpperCase()
      }
    });
  }

  /**
   * Mark a sticky timeline item as read.
   * @param item The timelien item.
   * @returns an observable that emits once the request is complete
   */
  markAsRead(item: TimelineItem): Observable<TimelineItem> {
    return this.http.post<TimelineItem>(this.getUrl({
      id: item.id
    }, '/{id}/read'), null, {
      params: {
        _permissions: '*'
      }
    });
  }

  /**
   * Removes the stickyness of a timeline item.
   * This should not be confused with mark as read, as it the effect applies to all users.
   * @param item The timeline item
   * @returns an observable that emits once the http request is complete
   */
  unsticky(item: TimelineItem): Observable<TimelineItem> {
    return this.http.post<TimelineItem>(this.getUrl({
      id: item.id
    }, '/{id}/unsticky'), null, {
      params: {
        _permissions: '*'
      }
    });
  }

  /**
   * Gets the original author of a timeline item.
   * @param item The timeline item
   * @returns an observable that emits once the http request is complete
   */
  getOriginalAuthor(item: TimelineItem): Observable<Sender> {
    return this.senderService.get(item.originalAuthorId);
  }

  /**
   * Gets new timeline items for the timeline of the sender.
   *
   * @param senderId The id of the timelines sender
   * @param type The type of the timeline
   * @param permissions The permissions appended to the items
   * @param lastUpdateTimestamp The
   *
   * @returns The first page of new timeline items
   */
  getNewItems(senderId: string, type: 'sender' | 'personal', permissions: string[], lastUpdateTimestamp: number):
    Observable<NewTimelineItemsResponse> {
    const pageable = new Pageable(0, TimelineItemService.TIMELINE_PAGE_SIZE);
    const httpParams = pageable.toHttpParams(new HttpParams({
      fromObject:
        {senderId, type, since: new Date(lastUpdateTimestamp).toISOString()}
    }));
    const params = this.mergeParams(
      httpParams,
      permissions
    );
    return this.http.get<NewTimelineItemsResponse>(this.getUrl(null, '/new'), {params});
  }

  /**
   * Gets the number of new timeline items.
   *
   * @param type The type of the timeline
   * @param lastUpdateTimestamp Timestamp of the last update
   * @param useCache Determine if the etag cache should be used or not
   *
   * @returns An Observable of the number of new items
   */
  getNewItemCount(type: string, lastUpdateTimestamp: number, useCache: boolean = false): Observable<number> {
    const headers = {};
    if (!useCache) {
      headers[EtagInterceptor.ETAG_ENABLED] = 'false';
    }
    return this.http.get<number>(this.getUrl(null, '/new/count'), {
      params: {
        type,
        since: new Date(lastUpdateTimestamp).toISOString()
      }, headers
    });
  }

  /**
   * Gets the timeline items with the given ids.
   *
   * @param ids The ids of the items
   * @param senderId The sender id of the timeline
   * @param type The type of the timeline
   * @param permissions The permissions to request
   *
   * @returns An Observable of the timeline items
   */
  getItems(ids: string[], senderId: string, type: 'sender' | 'personal', permissions: string[] = []):
    Observable<{ [key: string]: TimelineItem }> {
    const params = this.mergeParams({
      timelineType: type.toUpperCase(),
      senderId,
      ids
    }, permissions);
    return this.http.get<{ [key: string]: TimelineItem }>(this.getBaseUrl(), {params});
  }

  getLastUpdate(type: 'sender' | 'personal'): Observable<LastUpdateResponse> {
    return this.http.get<LastUpdateResponse>(this.getUrl(null, '/last-update'), {params: {type}});
  }

  protected getBaseUrl(): string {
    return '/web/timeline-items';
  }

  private request(ids: string[]): Observable<{ [id: string]: number }> {
    return this.http.get<{ [id: string]: number }>(this.getUrl({}, '/shares/count'), {
      params: {
        ids
      }
    });
  }
}

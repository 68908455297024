import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {SelectionEntry} from '@app/filter/selection-filter/selection-entry';
import * as _ from 'lodash';

/**
 * Selection filter component.
 *
 * @description
 * Renders a filter that supports single or multi select.
 * This component only emits the changes.
 * The resulting tasks (e.g.: fetching the filter result) must be performed by the caller.
 *
 * @example
 * <coyo-selection-filter
 *    [title]="'TITLE' | translate"
 *    [textPrefix]="'PREFIX'"
 *    [filterModel]="filterModel"
 *    [totalCount]="totalCount"
 *    [singleSelect]="true"
 *    [allOption]="allOption"
 *    (filterModelChange)="onChange()"
 * ></coyo-selection-filter>
 *
 * @see FilterComponent, FilterEntryComponent
 * TODO: change the strategy back to OnPush if all calling controllers are migrated, in detail: e.g.: if the filterModel is an immutable object
 */
@Component({
  selector: 'coyo-selection-filter',
  templateUrl: './selection-filter.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class SelectionFilterComponent implements OnDestroy, OnChanges {

  constructor() { }

  static readonly DEFAULT_FILTER_PAGE_SIZE: number = 10;

  static readonly defaultAllOption: SelectionEntry = {
    key: 'ALL',
    count: 0,
    active: true,
    icon: 'double-check',
    iconColor: null,
    textKey: 'FILTER_ALL'
  };

  /**
   * The selection title.
   */
  @Input()
  title: string = '';

  /**
   * For translation key construction.
   */
  @Input()
  textPrefix: string = '';

  /**
   * Count to be shown on the 'All' option.
   */
  @Input()
  totalCount: number = 0;

  /**
   * A given selection.
   */
  @Input()
  filterModel: SelectionEntry[] = [];

  /**
   * The selection changes.
   */
  @Output()
  filterModelChange: EventEmitter<SelectionEntry[]> = new EventEmitter<SelectionEntry[]>();

  /**
   * Decides whether the all option is enabled or not.
   */
  @Input()
  allOptionEnabled: boolean = true;

  /**
   * The all option if you want to override the default.
   * If no all option is given the all state will handled internally.
   */
  @Input()
  allOption?: SelectionEntry = SelectionFilterComponent.defaultAllOption;

  /**
   * Decides whether there can be only one option selected.
   */
  @Input()
  singleSelect: boolean = false;

  /**
   * Limits the number of shown selectable entries.
   */
  filterPageSize: number = SelectionFilterComponent.DEFAULT_FILTER_PAGE_SIZE;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterModel && changes.filterModel.currentValue) {
      this.initFilter();
    }
    if (changes.totalCount  && changes.totalCount.currentValue) {
      this.allOption.count = changes.totalCount.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.filterModelChange.complete();
  }

  /**
   * Initialize filter state.
   */
  initFilter(): void {
    this.allOption = _.merge({},
      SelectionFilterComponent.defaultAllOption,
      this.allOption,
      {active: true}
    );
    this.filterModel.forEach(entry => {
      if (entry.active) {
        this.allOption.active = false;
      }
    });
  }

  /**
   * Resets the filter state to default 'All'.
   */
  resetSelection(): void {
    this.filterModel.forEach(selectionEntry => {
      selectionEntry.active = false;
    });
    this.filterModelChange.emit(this.activateAllOption());
  }

  /**
   * Toggles a selection filter entry state (active/inactive).
   * If single select is true all other filter entries are deactivated.
   * If no filter is active the 'All' state will be activated.
   *
   * @param key The key of the selection filter entry.
   */
  toggle(key: string): void {
    this.allOption.active = false;
    this.filterModel.forEach(selectionEntry => {
      if (key === selectionEntry.key) {
        selectionEntry.active = !selectionEntry.active;
      } else if (this.singleSelect) {
        selectionEntry.active = false;
      }
    });
    const selection = _.filter(this.filterModel, {active: true});
    this.filterModelChange.emit(selection.length > 0 ? selection : this.activateAllOption());
  }

  /**
   * Increases filterPageSize to show more filter entries.
   */
  showMoreItems(): void {
    this.filterPageSize += SelectionFilterComponent.DEFAULT_FILTER_PAGE_SIZE;
  }

  private activateAllOption(): null {
    this.allOption.active = true;
    return null;
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DownloadWidgetFile} from '@widgets/download/download-widget-file';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

interface AccessibleFilesResponse {
 accessibleFiles: {id: string, senderId: string, name: string, title: string}[];
}

/**
 * Service for the download widget
 */
@Injectable({
  providedIn: 'root'
})
export class DownloadWidgetService {

  constructor(private http: HttpClient) { }

  /**
   * Gets the accessible files of this widget for the current user.
   *
   * @param widgetId
   * The id of the widget
   *
   * @return
   * Set of accessible files
   */
  getAccessibleFiles(widgetId: string): Observable<DownloadWidgetFile[]> {
    return this.http.get<AccessibleFilesResponse>(`/web/widgets/download/${widgetId}/files`)
      .pipe(map(res => res.accessibleFiles));
  }
}

import {getAngularJSGlobal} from '@angular/upgrade/static';
import {MarkdownService} from './markdown.service';

getAngularJSGlobal()
  .module('commons.markdown')
  .filter('markdown', ['$injector', markdownFilter]);

function markdownFilter($injector: any): (text: string, autoLink: boolean) => string  {
  const markdownService: MarkdownService = $injector.get('ngxMarkdownService');
  return (text, autoLink) => markdownService.markdown(text, autoLink);
}

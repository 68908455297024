import {Inject, Injectable} from '@angular/core';
import {GDrivePickerService} from '@app/integration/gsuite/g-drive-picker/g-drive-picker.service';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {ModalService} from '@domain/modal/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {RtePlugin} from '@shared/rte/rte-plugin';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';

/**
 * A custom RTE plugin that extends the existing ImagePlugin with features of the GSuite.
 */
@Injectable()
export class ImageFromGSuitePlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertImageFromGSuite';

  constructor(@Inject(FROALA_EDITOR) private froala: any,
              private notificationService: NotificationService,
              private modalService: ModalService,
              private translateService: TranslateService,
              private googleApiService: GoogleApiService,
              private gDrivePickerService: GDrivePickerService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    if (!settings.canAccessGoogle) {
      return; // user is not allowed to access GSuite
    }

    const plugin = this;
    this.froala.RegisterCommand(ImageFromGSuitePlugin.KEY, {
      title: plugin.translateService.instant('RTE.INSERT_G_SUITE_IMAGE'),
      plugin: 'coyoImagePlugin',
      icon: 'insertImage',
      undo: false,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        plugin.openGoogleFilePicker(this);
      }
    });
  }

  private openGoogleFilePicker(editor: any): void {
    const plugin = this;
    const wrapper = editor.$oel.find('.fr-wrapper');
    const scrollPosition = wrapper.scrollTop();
    editor.selection.save();
    const prevImage = editor.image.get();

    plugin.gDrivePickerService.open({
      multipleSelect: false,
      view: 'DOCS_IMAGES',
      uploadView: false,
      recentFilesView: false
    }).then(selectedFiles => {
      editor.html.insert(`<div class="coyo-spinner-container" id="video-spinner-${editor.id}" contenteditable="false"><div class="coyo-spinner"/></div>`);
      selectedFiles.forEach(item => {
        plugin.googleApiService.getFileMetadata(item.id).then((metadata: any) => {
          plugin.googleApiService.isFilePublicVisible(item.id).then(publicVisible => {
            editor.$oel.find(`#video-spinner-${editor.id}`).remove();
            editor.selection.restore();
            if (publicVisible) {
              editor.image.insert(metadata.webContentLink, true, {}, prevImage);
            } else {
              this.modalService.openConfirm({
                title: 'RTE.INSERT_G_SUITE_IMAGE.PRIVATE.HINT.TITLE',
                text: 'RTE.INSERT_G_SUITE_IMAGE.PRIVATE.HINT.TEXT'
              }).subscribe(() => {
                editor.html.insert(`<a href="${item.url}" target="_blank"><img src="/assets/images/integration/gsuite-placeholder.svg"/></a>`);
              });
            }
            editor.undo.saveStep();
            wrapper.scrollTop(scrollPosition);
          }).catch(() => plugin.onError(editor, wrapper, scrollPosition));
        }).catch(() => plugin.onError(editor, wrapper, scrollPosition));
      });
    }).catch(() => plugin.onError(editor, wrapper, scrollPosition));
  }

  private onError(editor: any, wrapper: any, scrollPosition: number): void {
    editor.$oel.find(`#video-spinner-${editor.id}`).remove();
    editor.selection.restore();
    wrapper.scrollTop(scrollPosition);
    this.notificationService.error('RTE.INSERT_G_SUITE_IMAGE.ERROR');
  }
}

import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';

/**
 * Directive to emit back when all {@link HTMLImageElement}s inside the assigned DOM element
 * have finished loading (either successfully or errored).
 */
@Directive({
  selector: '[coyoImagesComplete]'
})
export class ImagesCompleteDirective implements AfterViewInit, OnDestroy {

  /**
   * Event that is emitted when all HTMLImageElements within the assigned element
   * are loaded completely or loading errored.
   */
  @Output() completed: EventEmitter<void> = new EventEmitter();

  private pendingImages: HTMLImageElement[] = [];
  private readonly loadEventListener: EventListener;

  constructor(private elemRef: ElementRef) {
    this.loadEventListener = event => this.onLoad(event);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elemRef.nativeElement;
      const images: NodeList = element.querySelectorAll('img');
      this.pendingImages = Array.prototype.filter.call(images, (img: HTMLImageElement) => !img.complete);
      if (this.pendingImages.length === 0) {
        this.completed.emit();
      } else {
        this.pendingImages.forEach(img => {
          img.addEventListener('load', this.loadEventListener);
          img.addEventListener('error', this.loadEventListener);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.pendingImages.forEach((item: HTMLImageElement) => {
      this.removeListener(item);
    });
  }

  private onLoad(event: Event): void {
    const img: HTMLImageElement = event.target as HTMLImageElement;
    this.removeListener(img);
    this.pendingImages.forEach((item, index) => {
      if (item === img) {
        this.pendingImages.splice(index, 1);
      }
    });
    if (this.pendingImages.length <= 0) {
      this.completed.emit();
    }
  }

  private removeListener(img: HTMLImageElement): void {
    img.removeEventListener('load', this.loadEventListener);
    img.removeEventListener('error', this.loadEventListener);
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {UserOnlineWidget} from '@widgets/user-online/user-online-widget';
import {UserOnlineWidgetComponent} from './user-online-widget/user-online-widget.component';

export const USER_ONLINE_WIDGET: WidgetConfig<UserOnlineWidget> = {
  key: 'useronline',
  name: 'WIDGET.USER_ONLINE.NAME',
  description: 'WIDGET.USER_ONLINE.DESCRIPTION',
  icon: 'zmdi-account-circle',
  component: UserOnlineWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  renderOptions: {
    printable: false
  },
  whitelistExternal: false
};

import {NgModule} from '@angular/core';
import {FILE_PICKER_ITEM_SERVICES} from '@app/file-picker/file-picker-item.service';
import {FilePickerModule} from '@app/file-picker/file-picker.module';
import {SharePointFilePickerItemService} from '@app/integration/o365/share-point-file-picker/share-point-file-picker-item.service';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import './o365-api/o365-api.service.downgrade';
import './share-point-file-picker/share-point-file-picker.service.downgrade';

/**
 * Module for providing the functionality around the O365 integration.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    FilePickerModule
  ],
  providers: [
    {provide: FILE_PICKER_ITEM_SERVICES, useExisting: SharePointFilePickerItemService, multi: true},
  ]
})
export class O365Module {
}

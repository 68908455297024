import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {LinkPreview} from '@domain/preview/link-preview';
import * as _ from 'lodash';
import {GoogleDocumentType} from './google-document-type';

/**
 * Component renders a link preview.
 */
@Component({
  selector: 'coyo-link-preview',
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkPreviewComponent {

  private readonly GOOGLE_DOCUMENT_TYPES: GoogleDocumentType[] = [
    {documentType: 'document', previewIconName: 'document'},
    {documentType: 'spreadsheets', previewIconName: 'spreadsheet'},
    {documentType: 'presentation', previewIconName: 'presentation'},
    {documentType: 'maps', previewIconName: 'map'},
    {documentType: 'forms', previewIconName: 'form'},
    {documentType: 'drawings', previewIconName: 'drawing'}];

  /**
   * The preview to render.
   */
  @Input() preview: LinkPreview;

  /**
   * Flag for enabling the edit mode and show a button to delete the link preview.
   */
  @Input() editMode: boolean = false;

  /**
   * Emitted when the delete button of the preview is clicked
   */
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  constructor(private urlService: UrlService) {
  }

  /**
   * Check if the preview has an image.
   *
   * @return true if there is a preview image.
   */
  hasPreviewImage(): boolean {
    return !!((this.preview.internal && this.preview.imageUrl)
      || (!this.preview.internal && this.preview.contentType && this.preview.imageBlobUid));
  }

  /**
   * Checks if the preview has an icon.
   *
   * @return true if it has an icon.
   */
  hasInternalIcon(): boolean {
    return this.preview.internal && this.preview.iconUrl && (!this.preview.contentType || !this.preview.imageBlobUid)
      && (!this.preview.tld || this.preview.tld.indexOf('.google.') === -1);
  }

  /**
   * Checks if the preview should be treated as an unpublished Google link with a special icon.
   *
   * @return true if the entry needs a Google icon.
   */
  hasGoogleIcon(): boolean {
    return !this.preview.internal && this.preview.iconUrl && !this.preview.contentType && !this.preview.imageBlobUid
      && this.preview.tld.indexOf('.google.') > -1
      && _.some(this.GOOGLE_DOCUMENT_TYPES, {documentType: this.preview.iconUrl});
  }

  /**
   * Checks if the preview is a COYO file library folder
   *
   * @return true if the preview is a COYO file library folder
   */
  isFileLibraryFolder(): boolean {
    return this.preview.contentType === 'application/x-coyo-file-library-folder';
  }

  /**
   * Gets the absolute image url of the preview.
   *
   * @return the image url prefixed with the backend url.
   */
  getAbsoluteImageUrl(): string {
    return this.urlService.join(this.urlService.getBackendUrl(), this.preview.imageUrl);
  }

  /**
   * Get the icon url for a google document type.
   *
   * @return the composed asset url
   */
  getGoogleIconUrl(): string {
    const type: GoogleDocumentType = _.find(this.GOOGLE_DOCUMENT_TYPES, {documentType: this.preview.iconUrl});
    return `/assets/icons/google-${type.previewIconName}.svg`;
  }
}

import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Type,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Widget} from '@domain/widget/widget';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {Subject} from 'rxjs';
import {WidgetSettingsComponent} from '../widget-settings-component';

/**
 * A component to dynamically render a {@link WidgetSettingsComponent}.
 */
@Component({
  selector: 'coyo-widget-settings-container',
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class WidgetSettingsContainerComponent implements OnInit, OnChanges {

  private componentRef: ComponentRef<WidgetSettingsComponent<Widget<WidgetSettings>>>;

  /**
   * The {@link WidgetSettingsComponent} to be rendered.
   */
  @Input() component: Type<WidgetSettingsComponent<Widget<WidgetSettings>>>;

  /**
   * The corresponding widget this settings component belongs to.
   */
  @Input() widget: any;

  /**
   * The parent settings form component.
   */
  @Input() parentForm: FormGroup;

  /**
   * The callback for the submit action.
   */
  @Input() onSubmit: Subject<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    this.updateComponentRefValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateComponentRefValues();
  }

  get widgetSettingsComponent(): WidgetSettingsComponent<Widget<WidgetSettings>> {
    return this.componentRef.instance;
  }

  private updateComponentRefValues(): void {
    if (this.componentRef) {
      this.componentRef.instance.widget = this.widget;
      this.componentRef.instance.parentForm = this.parentForm;
      this.componentRef.instance.onSubmit = this.onSubmit;
    }
  }
}

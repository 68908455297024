import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {LatestWikiArticle} from '@widgets/latest-wiki-articles/latest-wiki-article';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Wiki article widget service
 * Fetches a list of latest wiki articles
 */
export class LatestWikiArticlesService {
  static readonly DEFAULT_COUNT: number = 5;
  private readonly url: string = '/web/widgets/wiki/latest';

  constructor(@Inject(HttpClient) private http: HttpClient) {
  }

  getLatestWikiArticles(count: number = LatestWikiArticlesService.DEFAULT_COUNT, appId?: string): Observable<LatestWikiArticle[]> {
    let params = new HttpParams();
    if (!!appId) {
      params = params.append('appId', appId);
    }
    params = params.append('count', count.toString());
    return this.http.get<LatestWikiArticle[]>(this.url, {params});
  }
}

import {Injectable} from '@angular/core';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerSelectionType} from '@app/file-picker/file-picker-selection/file-picker-selection-type';
import {
  InitSelection,
  ResetSelection,
  SelectAll,
  ToggleSelection,
  UnselectAll
} from '@app/file-picker/file-picker-selection/file-picker-selection.actions';
import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';

interface SelectedItems {
  [key: string]: FilePickerItem;
}

interface FilePickerSelectionStateModel {
  mode: FilePickerSelectionType;
  selectedItems: SelectedItems;
  contentTypes: string[];
}

export interface FilePickerSelectionsStateModel {
  selection: FilePickerSelectionStateModel;
  stack: FilePickerSelectionStateModel[];
}

@Injectable({providedIn: 'root'})
@State<FilePickerSelectionsStateModel>({
  name: 'filePickerSelections',
  defaults: {
    selection: {
      mode: null,
      selectedItems: {},
      contentTypes: []
    },
    stack: []
  }
})
export class FilePickerSelectionsState {

  /**
   * Returns the currently selected items
   * @param state The state snapshot
   *
   * @return object containing the selected items with id as key
   */
  @Selector()
  static selectedItems(state: FilePickerSelectionsStateModel): SelectedItems {
    return state.selection.selectedItems;
  }

  /**
   * Returns the number of the currently selected items
   * @param state The state snapshot
   *
   * @return the number of the currently selected items
   */
  @Selector()
  static selectionCount(state: FilePickerSelectionsStateModel): number {
    return Object.keys(state.selection.selectedItems).length;
  }

  /**
   * Returns if multiselect is activated
   * @param state The state snapshot
   *
   * @return true if the selection mode is multi
   */
  @Selector()
  static isMultiSelect(state: FilePickerSelectionsStateModel): boolean {
    return state.selection.mode === 'multiple';
  }

  /**
   * Returns a selector emitting when an items selection status changes
   * @param itemId The id of the item
   *
   * @return true if the item is selected, false otherwise
   */
  static isItemSelected(itemId: string): (state: FilePickerSelectionsStateModel) => boolean {
    return createSelector([FilePickerSelectionsState], (state: FilePickerSelectionsStateModel) => !!state.selection.selectedItems[itemId]);
  }

  /**
   * Returns a selector emitting when an items selectable status changes
   * @param item The item
   *
   * @return true if the item is selectable, false otherwise
   */
  static isItemSelectable(item: FilePickerItem): (state: FilePickerSelectionsStateModel) => boolean {
    return createSelector(
      [FilePickerSelectionsState],
      (state: FilePickerSelectionsStateModel) => !item.isFolder && FilePickerSelectionsState.matchContentType(item, state)
    );
  }

  /**
   * Helper function checking if one of the allowed content types matches the item content type
   * @param item The item
   * @param state The state snapshot
   *
   * @return true if the content type matches at least one of the allowed or if there are no allowed content types
   */
  static matchContentType(item: FilePickerItem, state: FilePickerSelectionsStateModel): boolean {
    const contentTypes = state.selection.contentTypes;
    if (contentTypes && contentTypes.length) {
      return contentTypes.some(filter => this.checkMimeType(item.mimeType, filter));
    }
    return true;
  }

  private static checkMimeType(mimeType: string, filter: string): boolean {
    if (!filter || filter.length < 1) {
      return true;
    }
    if (!mimeType) {
      return false;
    }

    let result = false;
    if (filter.endsWith('/*')) {
      result ||= mimeType.startsWith(filter.substring(0, filter.length - 1));
    }
    if (filter.startsWith('.')) {
      result ||= mimeType.endsWith(filter);
    }
    result ||= mimeType === filter || mimeType.startsWith(filter) || new RegExp(filter).test(mimeType);
    return result;
  }

  @Action(ToggleSelection)
  toggleSelection(ctx: StateContext<FilePickerSelectionsStateModel>, action: ToggleSelection): void {
    if (ctx.getState().selection.selectedItems[action.item.id]) {
      const {[action.item.id]: value, ...newSelection} = ctx.getState().selection.selectedItems;
      this.patchSelection(ctx, newSelection);
    } else {
      const newSelection = ctx.getState().selection.mode === 'multiple' ?
        {...ctx.getState().selection.selectedItems, [action.item.id]: action.item} :
        {[action.item.id]: action.item};
      this.patchSelection(ctx, newSelection);
    }
  }

  @Action(SelectAll)
  selectAll(ctx: StateContext<FilePickerSelectionsStateModel>, action: SelectAll): void {
    if (ctx.getState().selection.mode === 'single') {
      return;
    }
    const newSelection = action.items
      .filter(item => FilePickerSelectionsState.matchContentType(item, ctx.getState()))
      .reduce((prev, current) => ({
        ...prev,
        [current.id]: current
      }), ctx.getState().selection.selectedItems);
    this.patchSelection(ctx, newSelection);
  }

  @Action(UnselectAll)
  unselectAll(ctx: StateContext<FilePickerSelectionsStateModel>, action: UnselectAll): void {
    const newSelection = action.items.reduce((prev, current) => {
      const {[current.id]: value, ...newState} = prev;
      return newState;
    }, ctx.getState().selection.selectedItems);
    this.patchSelection(ctx, newSelection);
  }

  @Action(ResetSelection)
  resetSelection(ctx: StateContext<FilePickerSelectionsStateModel>): void {
    const newStack = [...ctx.getState().stack];
    const selection = newStack.pop() ?? {
      mode: null,
      selectedItems: {},
      contentTypes: []
    };
    ctx.setState({selection, stack: newStack});
  }

  @Action(InitSelection)
  initSelection(ctx: StateContext<FilePickerSelectionsStateModel>, action: InitSelection): void {
    const currentSelection = ctx.getState().selection;
    if (currentSelection.mode) {
      ctx.patchState({
        stack: [...ctx.getState().stack, currentSelection]
      });
    }

    const selectedItems = action.selectedFiles.reduce((items: SelectedItems, item) => {
      items[item.id] = item;
      return items;
    }, {});

    ctx.patchState({
      selection: {
        mode: action.selectionType,
        selectedItems: selectedItems,
        contentTypes: action.contentTypes
      }
    });
  }

  private patchSelection(ctx: StateContext<FilePickerSelectionsStateModel>, newSelection: SelectedItems): void {
    ctx.patchState({
      selection: {
        mode: ctx.getState().selection.mode,
        selectedItems: newSelection,
        contentTypes: ctx.getState().selection.contentTypes
      }
    });
  }
}

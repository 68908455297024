import {Pipe, PipeTransform} from '@angular/core';
import {HashtagService} from './hashtag.service';

/**
 * Enriches a text containing hashtags with links to these hashtags. The rules for hash-tagging are addressed to the
 * <a href="https://support.twitter.com/articles/511696">twitter hashtag rules</a>.
 */
@Pipe({name: 'hashtags'})
export class HashtagsPipe implements PipeTransform {

  constructor(private hashtagService: HashtagService) {
  }

  /**
   * Adds html enrichment for all found hashtags to the given text.
   *
   * @param text the source text containing hashtags
   *
   * @return the HTML-enriched result
   */
  transform(text: string): string {
    return this.hashtagService.hashtags(text);
  }
}

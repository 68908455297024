(function (angular) {
  'use strict';

  AdminLicenseController.$inject = ["$q", "$scope", "$state", "$injector", "coyoConfig", "coyoUrls", "ngxFeatureToggleService", "ngxLicenseService"];
  angular
      .module('coyo.admin.license')
      .controller('AdminLicenseController', AdminLicenseController);

  function AdminLicenseController($q, $scope, $state, $injector, coyoConfig, coyoUrls, ngxFeatureToggleService, ngxLicenseService) {
    var vm = this,
        stored;
    vm.$onInit = _init;
    vm.save = save;
    vm.reset = reset;
    vm.resetable = resetable;

    function save(form) {
      var deferred = $q.defer();
      form.$setPristine();
      ngxLicenseService.updateLicense(vm.licenseKey).toPromise().then(function (license) {
        ngxFeatureToggleService.reload();
        _updateLicense(license);
        $injector.get('ngxNotificationService').success('ADMIN.LICENSE.SAVE.SUCCESS');
        deferred.resolve();
      }).catch(function () {
        _updateLicense({
          licenseKey: vm.licenseKey,
          currentUserSize: (vm.user && vm.user.current) || 0,
          maxUserSize: 0,
          orderId: vm.orderId,
          expectedServerId: vm.serverId
        }, true);
        deferred.reject();
      });
      return deferred.promise;
    }

    function reset(form) {
      form.$setPristine();
      if (stored) {
        _updateLicense(stored, true);
      }
    }

    function resetable() {
      return stored && stored.licenseKey !== vm.licenseKey;
    }

    function _updateLicense(license, doNotStore) {
      if (!doNotStore) {
        stored = license;
      }

      vm.customerCenterLink = coyoUrls.customerCenter + (license.orderId ? '/order/' + license.orderId : '');

      vm.status = license.status;
      vm.validationFailures = license.validationFailures;
      vm.addons = license.addons.map(function (obj) {
        return angular.fromJson(angular.toJson(obj));
      });
      vm.validDaysLeft = license.validDaysLeft;
      vm.until = license.until;
      vm.licenseKey = license.licenseKey;
      vm.orderId = license.orderId;
      vm.serverId = license.expectedServerId;

      vm.user = vm.user || {};
      vm.user.current = license.currentUserSize;
      vm.user.max = license.maxUserSize;
    }

    function _init() {
      vm.version = coyoConfig.versionString();
      ngxLicenseService.getLicense().toPromise().then(function (license) {
        if (license.environment === 'cloud') {
          $state.go('admin.license.subscription');
        }
        _updateLicense(license);
        $scope.$digest();
      });
    }
  }
})(angular);

(function () {
  'use strict';

  modalService.$inject = ["$uibModal", "$document"];
  angular
      .module('coyo.base')
      .factory('modalService', modalService);

  /**
   * @ngdoc service
   * @name coyo.base.modalService
   *
   * @description
   *     Renders styled modals.
   */
  function modalService($uibModal, $document) {

    return {
      open: open,
      note: note,
      confirm: confirm,
      confirmDelete: confirmDelete,
      select: select
    };

    /**
     * @ngdoc method
     * @name coyo.base.modalService#open
     * @methodOf coyo.base.modalService
     *
     * @description
     *     Display a modal dialog.
     * @param {object} config
     *     A configuration object.
     * @param {string} config.size
     *     The size of the modal.
     * @param {object=} config.resolve
     *     The modal's scope parameters. All parameters will automatically be
     *     bound to the default controller using the object's key name.
     * @param {object=} config.controller
     *     The modal's controller. A default controller will be provided with
     *     all parameter bindings.
     * @param {string=} config.templateUrl
     *     The modal's template URL.
     * @param {string=} config.windowTopClass
     *     An optional class to be added to the top div of the modal and the backdrop.
     */
    function open(config) {
      if (angular.isUndefined(config)) { config = {}; }
      if (angular.isUndefined(config.resolve)) { config.resolve = {}; }

      // auto-generate controller if missing
      if (angular.isUndefined(config.controller)) {
        var keys = Object.keys(config.resolve);
        var args = keys.join(',');
        var assign =
          'var vm = this;' +
          keys.map(function (arg) {
            return 'this[\'' + arg + '\'] = ' + arg + ';';
          }).join('');
        eval('config.controller = function (' + args + ') {' + assign + '};');
      }

      return $uibModal.open({
        animation: true,
        backdrop: config.backdrop || 'static',
        templateUrl: config.templateUrl || 'app/commons/ui/components/modal/modal.html',
        controller: config.controller,
        controllerAs: config.controllerAs || 'vm',
        windowTopClass: config.windowTopClass,
        size: config.size || '',
        resolve: config.resolve,
        bindToController: true,
        backdropClass: config.windowTopClass
      });
    }

    /**
     * @ngdoc method
     * @name coyo.base.modalService#note
     * @methodOf coyo.base.modalService
     *
     * @description
     *     Display a modal notification dialog.
     * @param {object} options
     *     Configuration options for the modal.
     * @param {string} options.size
     *     The size of the modal.
     * @param {string=} options.title
     *     Translation key for the title of the modal.
     * @param {string=} options.text
     *     Translation key for the text inside the modal.
     * @param {object=} options.translationContext
     *     An object containing values to be used for translation interpolation.
     * @param {object[]=} options.alerts
     *     An array of alert boxes to show.
     * @param {string=} options.alerts.level
     *     Bootstrap alert class suffix to be used (e.g. 'danger').
     * @param {string=} options.alerts.title
     *     Translation key of the prefix of the alert text, will be rendered in bold.
     * @param {string=} options.alerts.text
     *     Translation key of the text inside the alert.
     * @param {object=} options.close
     *     The close button configuration.
     */
    function note(options) {
      return open({
        size: options.size,
        controller: /*@ngInject*/ ["$uibModalInstance", function ($uibModalInstance) {
          var vm = angular.extend(this, {   // eslint-disable-line no-unused-vars
            title: options.title || '',
            text: options.text || '',
            translationContext: options.translationContext || {},
            alerts: options.alerts || [],
            buttons: [angular.extend({
              icon: 'check-circle',
              title: 'OK',
              style: 'btn-primary',
              onClick: onClick
            }, options.close)]
          });

          function onClick() {
            unbindKeyUp();
            $uibModalInstance.close();
          }

          function bindKeyUp() {
            $document.bind('keyup', onEnterKeyUp);
          }

          function unbindKeyUp() {
            $document.unbind('keyup', onEnterKeyUp);
          }

          var onEnterKeyUp = function (event) {
            if (event.which === 13) {
              event.preventDefault();
              unbindKeyUp();
              $uibModalInstance.close();
            }
          };
          bindKeyUp();
        }],
        windowTopClass: 'top-level-modal',
        backdropClass: 'top-level-modal'
      });
    }

    /**
     * @ngdoc method
     * @name coyo.base.modalService#confirm
     * @methodOf coyo.base.modalService
     *
     * @description
     *     Display a modal confirmation dialog.
     * @param {object} options
     *     Configuration options for the modal.
     * @param {string} options.size
     *     The size of the modal.
     * @param {string=} options.title
     *     Translation key for the title of the modal.
     * @param {string=} options.text
     *     Translation key for the text inside the modal.
     * @param {object=} options.translationContext
     *     An object containing values to be used for translation interpolation.
     * @param {object[]=} options.alerts
     *     An array of alert boxes to show.
     * @param {string=} options.alerts.level
     *     Bootstrap alert class suffix to be used (e.g. 'danger').
     * @param {string=} options.alerts.title
     *     Translation key of the prefix of the alert text, will be rendered in bold.
     * @param {string=} options.alerts.text
     *     Translation key of the text inside the alert.
     * @param {object=} options.close
     *     The confirm button configuration.
     * @param {object=} options.dismiss
     *     The dismiss button configuration.
     */
    function confirm(options) {
      // title, text, size, closeTitle, dismissTitle
      return open({
        size: options.size,
        windowTopClass: 'top-level-modal',
        backdropClass: 'top-level-modal',
        controller: /*@ngInject*/ ["$uibModalInstance", function ($uibModalInstance) {
          var vm = angular.extend(this, {   // eslint-disable-line no-unused-vars
            title: options.title || '',
            text: options.text || '',
            translationContext: options.translationContext || {},
            alerts: options.alerts || [],
            buttons: [angular.extend({
              icon: 'check-circle',
              title: 'OK',
              style: 'btn-primary',
              onClick: onClick
            }, options.close), angular.extend({
              icon: 'close-circle',
              title: 'CANCEL',
              style: 'btn-default',
              onClick: onDismiss
            }, options.dismiss)]
          });

          function onClick() {
            unbindKeyUp();
            $uibModalInstance.close();
          }

          function onDismiss() {
            unbindKeyUp();
            $uibModalInstance.dismiss();
          }

          function bindKeyUp() {
            $document.bind('keyup', onEnterKeyUp);
          }

          function unbindKeyUp() {
            $document.unbind('keyup', onEnterKeyUp);
          }

          var onEnterKeyUp = function (event) {
            if (event.which === 13) {
              event.preventDefault();
              unbindKeyUp();
              $uibModalInstance.close();
            }
          };
          bindKeyUp();
        }]
      });
    }

    /**
     * @ngdoc method
     * @name coyo.base.modalService#confirmDelete
     * @methodOf coyo.base.modalService
     *
     * @description
     *     Display a modal delete confirmation dialog.
     * @description
     *     Display a modal confirmation dialog.
     * @param {object} options
     *     Configuration options for the modal.
     * @param {string} options.size
     *     The size of the modal.
     * @param {string=} options.title
     *     Translation key for the title of the modal.
     * @param {string=} options.text
     *     Translation key for the text inside the modal.
     * @param {object=} options.translationContext
     *     An object containing values to be used for translation interpolation.
     * @param {object[]=} options.alerts
     *     An array of alert boxes to show.
     * @param {string=} options.alerts.level
     *     Bootstrap alert class suffix to be used (e.g. 'danger').
     * @param {string=} options.alerts.title
     *     Translation key of the prefix of the alert text, will be rendered in bold.
     * @param {string=} options.alerts.text
     *     Translation key of the text inside the alert.
     * @param {object=} options.close
     *     The confirm button configuration.
     * @param {object=} options.dismiss
     *     The dismiss button configuration.
     */
    function confirmDelete(options) {
      return confirm(angular.extend({
        close: {icon: 'delete', title: 'DELETE', style: 'btn-danger'},
        dismiss: {title: 'CANCEL'}
      }, options));
    }
    /**
     * @ngdoc method
     * @name coyo.base.modalService#select
     * @methodOf coyo.base.modalService
     *
     * @description
     *     Display a modal selection dialog.
     * @param {object} options
     *     Configuration options for the modal.
     * @param {string} options.size
     *     The size of the modal.
     * @param {string=} options.title
     *     Translation key for the title of the modal.
     * @param {string=} options.options
     *     The modal's selection options.
     * @param {object=} options.translationContext
     *     An object containing values to be used for translation interpolation.
     * @param {object[]} options.close
     *     The confirm button configuration.
     * @param {object=} options.dismiss
     *     The dismiss button configuration.
     * @param {function=} options.loadAdditionalPage
     *     A function to be called for loading more data in infinite scrolling
     *     mode (takes page number as argument, starting with 1 for second page
     *     - first page is always provided via resolve of options.values).
     */

    function select(options) {
      // title, text, options, size, closeTitle, dismissTitle
      return open({
        size: options.size,
        templateUrl: 'app/commons/ui/components/modal/modal-select.html',
        resolve: {
          values: options.options.values || function () {},
          selected: options.options.selected || function () {}
        },
        controller: /*@ngInject*/ ["$uibModalInstance", "values", "selected", function ($uibModalInstance, values, selected) {
          var vm = angular.extend(this, {
            title: options.title || '',
            text: options.text || '',
            translationContext: options.translationContext || {},
            buttons: [angular.extend({
              icon: 'check-circle',
              title: 'SELECT',
              style: 'btn-primary',
              onClick: select,
              hideMobile: true
            }, options.close), angular.extend({
              icon: 'close-circle',
              title: 'CANCEL',
              style: 'btn-default',
              onClick: onDismiss
            }, options.dismiss)]
          });

          var valueList;
          var selectedVaL;
          function processValueList(list) {
            valueList = [];
            selectedVaL = null;
            for (var key in list) {
              var val = list[key].value;
              valueList.push(val);
              if (selected === list[key].key) {
                selectedVaL = val;
              }
            }
            vm.options = {
              values: valueList,
              selected: selectedVaL
            };
          }
          processValueList(values);

          vm.addPage = function () {
            if (angular.isFunction(options.nextPage)) {
              vm.loading = true;
              options.nextPage().then(function (items) {
                values = values.concat(items);
                processValueList(values);
              }).finally(function () {
                vm.loading = false;
              });
            }
          };

          if (angular.isFunction(options.onOpened)) {
            options.onOpened();
          }

          function onDismiss() {
            unbindKeyUp();
            $uibModalInstance.dismiss();
          }

          function bindKeyUp() {
            $document.bind('keyup', onEnterKeyUp);
          }

          function unbindKeyUp() {
            $document.unbind('keyup', onEnterKeyUp);
          }

          var onEnterKeyUp = function (event) {
            if (event.which === 13) {
              event.preventDefault();
              if (select(options.selected)) {
                onDismiss();
              }
            }
          };
          bindKeyUp();

          vm.select = select;
          function select(option) {
            var found = false;
            var selectedItem = option || vm.options.selected;
            for (var key in values) {
              if (selectedItem === values[key].value) {
                unbindKeyUp();
                $uibModalInstance.close(values[key].key);
                found = true;
                break;
              }
            }
            if (!found) {
              unbindKeyUp();
              $uibModalInstance.close(selectedItem);
            }
          }
        }]
      });
    }
  }

})();

import {AppConfig} from '@apps/api/app-config';
import {ContentAppComponent} from '@apps/content/content-app.component';
import {ContentAppSettings} from '@apps/content/content-app.settings';

export const CONTENT_APP_CONFIG: AppConfig<ContentAppSettings> = {
  name: 'APP.CONTENT.NAME',
  description: 'APP.CONTENT.DESCRIPTION',
  key: 'content',
  icon: 'zmdi-view-compact',
  svgIcon: 'content-app',
  states: [
    {
      abstract: true,
      resolve: [{
        token: 'sender',
        resolveFn: (senderModel: any, app: any) => senderModel.getWithPermissions(app.senderId, {with: 'subscriptionInfo'}, []),
        deps: ['SenderModel', 'app']
      }]
    },
    {
      name: 'content',
      url: '',
      default: true,
      params: {created: false},
      component: ContentAppComponent,
      resolve: [{
        token: 'editMode',
        resolveFn: () => false
      }],
    },
    {
      name: 'edit',
      url: '/edit',
      params: {created: null},
      component: ContentAppComponent,
      resolve: [{
        token: 'editMode',
        resolveFn: () => true
      }]
    },
  ],
  settings: {
    skipOnCreate: false
  }
};

import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

/**
 * A simple pipe to transform a number into an array. Usually used to render a component n times using *ngFor,
 * e.g. `*ngFor="*ngFor="let i of 5 | toArray"`.
 */
@Pipe({
  name: 'toArray'
})
export class ToArrayPipe implements PipeTransform {

  transform(value: number): number[] {
    return _.range(value);
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '@domain/user/user';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileAuthorService {

  constructor(private http: HttpClient) {
  }

  getAuthors(senderId: string, appId: string, fileIds: string[]): Observable<{ [key: string]: User }> {
    return this.http.get<{ [key: string]: User }>(`/web/senders/${senderId}/file/authors/${appId}`, {params: {ids: fileIds}});
  }
}

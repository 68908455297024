import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Hashtag} from '../hashtag';

/**
 * Service for the hashtag widget.
 */
@Injectable({
  providedIn: 'root'
})
export class HashtagWidgetService {

  constructor(private http: HttpClient) {
  }

  /**
   * Retrieves the trending hashtags for a given period.
   *
   * @param period the period to consider the trending hashtags for
   * @returns the trending hashtags for the period sorted by count
   */
  getTrendingHashtags(period: string): Observable<Hashtag[]> {
    return this.http.get<{ [key: string]: number; }>('/web/widgets/hashtag/trending', {
      params: {
        period: period,
        limit: '20'
      }
    }).pipe(map((data: any) => {
      const counts = _.values(data);
      const minCount = _.min(counts) || 0;
      const maxCount = _.max(counts) || 1;
      return _.chain(data).map((count: number, tag: string) => ({
        tag: tag,
        count: count,
        opacity: this.weight(count, minCount, maxCount, counts.length, 0.25, 1),
        fontWeight: this.weight(count, minCount, maxCount, counts.length, 500, 800)
      })).orderBy(['count', 'tag'], ['desc', 'asc']).value();
    }));
  }

  private weight(count: number, minCount: number, maxCount: number, length: number, minTarget: number, maxTarget: number): number {
    const norm = (count - minCount) / (maxCount - minCount);
    return (norm || 1 / length) * (maxTarget - minTarget) + minTarget;
  }
}


<!-- root folder -->
<ng-container *ngIf="(root$ | async) as root">
  <a (click)="openFolder(root.item, $event)"
     [attr.aria-label]="'FILEPICKER.FOLDER.ARIA' | translate : {folder: root.item.name}"
     class="root-folder"
     (dragover)="onDragOverRoot($event, root)"
     (dragenter)="onDragEnter(root)"
     (dragleave)="onDragLeave($event)"
     (drop)="moveFile($event, root.item)"
     href>
    {{root.item.name | translate}}
  </a>
  <mat-icon class="chevron" size="small" svgIcon="chevron-right"></mat-icon>
</ng-container>
<!-- context menu -->
<ng-container *ngIf="(hasContextMenu$ | async)">
  <button [attr.aria-label]="'FILEPICKER.BREADCRUMB.CONTEXT_MENU.TOOLTIP' | translate"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerRestoreFocus]="false"
          (dragover)="openMenu()"
          (dragleave)="closeMenuDelayed()"
          mat-icon-button size="small"
          type="button" #trigger="matMenuTrigger">
    <mat-icon svgIcon="more-horizontal"></mat-icon>
  </button>
  <mat-menu #menu="matMenu" [hasBackdrop]="hasBackdrop$ | async">
    <button (click)="openFolder(model.item, $event)" *ngFor="let model of (contextMenuItems$ | async)"
            (dragover)="onDragOverMenuItem($event, model)"
            (dragenter)="onDragEnter(model, menuItem)"
            (dragleave)="onDragLeave($event)"
            (drop)="moveFile($event, model.item)"
            class="folder-button"
            mat-menu-item
            type="button"
            #menuItem="matMenuItem">
      <coyo-file-picker-icon [item]="model.item"></coyo-file-picker-icon>
      <span class="menu-item">
          {{model.item.name | translate}}
      </span>
    </button>
  </mat-menu>
  <mat-icon class="chevron" size="small" svgIcon="chevron-right"></mat-icon>
</ng-container>
<!-- current folder -->
<ng-container *ngIf="(currentLocation$ | async).item as currentLocation">
  <span [attr.aria-label]="'FILEPICKER.FOLDER.CURRENT.ARIA' | translate : {folder: currentLocation.name}"
        class="current-folder">
  {{currentLocation.name | translate}}
</span>

<ng-container *ngIf="state.users">
  <div class="user-list-container">
    <coyo-user-list-item *ngFor="let user of state.users" [user]="user"></coyo-user-list-item>
  </div>
</ng-container>
<ng-container *ngIf="state.loading">
  <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
</ng-container>
<ng-container *ngIf="enableShowMore && !state.loading && !state.last">
  <button (click)="onLoadMore()" *ngIf="!state.last" class="load-more" color="accent" mat-button mat-link-button>
    <mat-icon color="accent" svgIcon="chevron-down"></mat-icon>
    <span [attr.aria-label]="'MORE' | translate" translate="SHOW_MORE"></span>
  </button>
</ng-container>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AutolinkPipe} from './autolink/autolink.pipe';
import './hashtags/hashtag.service.downgrade';
import {HashtagsPipe} from './hashtags/hashtags.pipe';
import './hashtags/hashtags.pipe.downgrade';
import {MarkdownPipe} from './markdown/markdown.pipe';
import './markdown/markdown.pipe.downgrade';
import './markdown/markdown.service.downgrade';
import { ReplacePipe } from './replace/replace.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    AutolinkPipe,
    HashtagsPipe,
    MarkdownPipe,
    ReplacePipe
  ],
  declarations: [
    AutolinkPipe,
    HashtagsPipe,
    MarkdownPipe,
    ReplacePipe
  ]
})
export class TextModule {
}

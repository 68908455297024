import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogSize} from '@coyo/ui';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {PollWidget} from '@widgets/poll/poll-widget';
import {HydratePollWidget, RemoveVote, Vote} from '@widgets/poll/poll-widget.actions';
import {PollWidgetOptionsStateModel, PollWidgetStateModel} from '@widgets/poll/poll-widget.state';
import {VotersModalComponent} from '@widgets/poll/voters-modal/voters-modal.component';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'coyo-poll-widget',
  templateUrl: './poll-widget.component.html',
  styleUrls: ['./poll-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollWidgetComponent extends WidgetComponent<PollWidget> implements OnInit, OnChanges {

  /**
   * Edit layout mode active
   */
  @Input() settingsMode: boolean = false;

  widgetState$: Observable<PollWidgetStateModel>;
  options$: Observable<PollWidgetOptionsStateModel[]>;

  constructor(cd: ChangeDetectorRef,
              private store: Store,
              private dialog: MatDialog) {
    super(cd);
  }

  ngOnInit(): void {
    const widgetRef = this.widget.id || this.widget.dataReference;
    this.widgetState$ = this.store
      .select(state => state.pollWidget[widgetRef])
      .pipe(filter(state => !!state));
    this.options$ = this.widgetState$
      .pipe(filter(widgetState => !!widgetState && !!widgetState.options), map(widgetState => widgetState.options));
    this.dispatchHydratePollWidget(widgetRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.widget && !changes.widget.firstChange || changes.editMode) {
      this.dispatchHydratePollWidget(this.widget.id || this.widget.dataReference);
    }
  }

  toggleOption(option: PollWidgetOptionsStateModel): void {
    if (option.answerId) {
      this.store.dispatch(new RemoveVote(this.widget.id, option.id, option.answerId));
    } else {
      this.store.dispatch(new Vote(this.widget.id, option));
    }
  }

  showVoters(option: PollWidgetOptionsStateModel): void {
    if (!this.widget.settings._anonymous && (this.widget.settings._showResults || this.settingsMode)) {
      this.dialog.open(VotersModalComponent, {
        width: MatDialogSize.Medium,
        data: {
          widgetId: this.widget.id || this.widget.dataReference,
          optionId: option.id,
        }
      });
    }
  }

  canVote$({id}: PollWidgetOptionsStateModel): Observable<boolean> {
    return this.widgetState$.pipe(map(state => {
      const model = state.options.find(val => val.id === id);
      return !this.editMode && !this.settingsMode && !this.widget.settings._frozen && !state.frozen && !model.optimistic
          && (!!model.answerId || (_.sum(state.options.map(option => option.answerId ? 1 : 0)) < this.widget.settings._maxAnswers));
    }));
  }

  getPercentage$(option: PollWidgetOptionsStateModel): Observable<number> {
    return this.widgetState$.pipe(map(state => (option.votes / Math.max(1, state.totalVotes)) * 100));
  }

  dispatchHydratePollWidget(widgetRef: string): void {
    const showResults = this.settingsMode || this.widget.settings._showResults;
    this.store.dispatch(new HydratePollWidget(widgetRef, this.widget.settings._options, showResults, this.widget.settings._frozen));
  }
}

import {Injectable} from '@angular/core';
import {TargetService} from '@domain/sender/target/target.service';
import {Action, State, StateContext} from '@ngxs/store';
import {LatestWikiArticle} from '@widgets/latest-wiki-articles/latest-wiki-article';
import {
  Load,
  Reset
} from '@widgets/latest-wiki-articles/latest-wiki-articles-widget/latest-wiki-articles-widget.actions';
import {LatestWikiArticlesService} from '@widgets/latest-wiki-articles/latest-wiki-articles.service';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * Stores the links for the sender and article mapped to each article id
 */
export interface LatestWikiArticleLinkCollection {
  senders: { [key: string]: string };
  articles: { [key: string]: string };
}

/**
 * The model representing a single latest wiki article widget state
 */
export interface LatestWikiArticlesWidgetStateModel {
  loading: boolean;
  articles: LatestWikiArticle[];
  hasArticles: boolean;
  links: LatestWikiArticleLinkCollection;
}

/**
 * The global model for the latest wiki article state
 * Maps widget id to widget state
 */
export interface LatestWikiArticlesStatesModel {
  [key: string]: LatestWikiArticlesWidgetStateModel;
}

/**
 * The actual widget state logic for the latest wiki article widget
 */
@Injectable({providedIn: 'root'})
@State<LatestWikiArticlesStatesModel>({
  name: 'latestWikiArticles',
  defaults: {}
})
export class LatestWikiArticlesWidgetState {
  constructor(private latestWikiArticlesService: LatestWikiArticlesService,
              private targetService: TargetService) {
  }

  @Action(Load)
  init(ctx: StateContext<LatestWikiArticlesStatesModel>, action: Load): Observable<any> {
    this.setLoading(ctx, action.id);
    return this.latestWikiArticlesService.getLatestWikiArticles(action.settings._articleCount, action.settings._appId)
      .pipe(
        tap((result: LatestWikiArticle[]) => {
          ctx.patchState({
            [action.id]: {
              articles: result,
              hasArticles: !!result && result.length > 0,
              loading: false,
              links: this.getLinks(result)
            }
          });
        }));
  }

  @Action(Reset)
  destroy(ctx: StateContext<LatestWikiArticlesStatesModel>, action: Reset): void {
    ctx.setState({..._.omit(ctx.getState(), action.id)});
  }

  private setLoading(ctx: StateContext<LatestWikiArticlesStatesModel>, id: string): void {
    ctx.patchState({
      [id]: {
        articles: [],
        hasArticles: false,
        loading: true,
        links: {
          senders: {},
          articles: {}
        }
      }
    });
  }

  private getLinks(articles: LatestWikiArticle[]): LatestWikiArticleLinkCollection {
    return {
      senders: articles.reduce((prev: any, curr: LatestWikiArticle) => {
        prev[curr.senderTarget.params.id] = this.targetService.getLinkTo(curr.senderTarget);
        return prev;
      }, {} as any),
      articles: articles.reduce((prev: any, curr: LatestWikiArticle) => {
        prev[curr.articleTarget.params.id] = this.targetService.getLinkTo(curr.articleTarget);
        return prev;
      }, {} as any)
    };
  }
}

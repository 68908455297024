<h1 mat-dialog-title>{{(
  editMode ? 'WIDGET.TEASER.SETTINGS.CONFIG.SLIDE.EDIT' : 'WIDGET.TEASER.SETTINGS.CONFIG.SLIDE.NEW') | translate}}</h1>
<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
<div mat-dialog-content>
  <form [formGroup]="form">

    <mat-form-field>
      <mat-label translate="WIDGET.TEASER.SETTINGS.CONFIG.HEADLINE"></mat-label>
      <input matInput #headline formControlName="headline" maxlength="50" type="text">
      <mat-hint align="start">{{'WIDGET.TEASER.SETTINGS.CONFIG.HEADLINE.REQUIRED' | translate:{maxlength: 50} }}</mat-hint>
      <mat-hint align="end">{{headline.value?.length || 0}}/50</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label translate="WIDGET.TEASER.SETTINGS.CONFIG.URL"></mat-label>
      <input matInput formControlName="_url" type="text">
    </mat-form-field>

    <mat-form-field class="large-hint">
      <mat-label translate="WIDGET.TEASER.SETTINGS.CONFIG.ALT"></mat-label>
      <input matInput formControlName="_alt" type="text">
      <mat-hint align="start">{{'WIDGET.TEASER.SETTINGS.CONFIG.ALT.HINT' | translate}}</mat-hint>
    </mat-form-field>

    <mat-checkbox formControlName="_newTab">{{'WIDGET.TEASER.SETTINGS.CONFIG.TAB' | translate}}</mat-checkbox>

    <hr />

    <h5>{{'WIDGET.TEASER.SETTINGS.CONFIG.IMAGE' | translate}} *</h5>
    <coyo-select-file *ngIf="sender$ | async; let sender"
                      [options]="{selectMode: 'single', filterContentType: ['image']}"
                      [sender]="sender" formControlName="file">
    </coyo-select-file>
  </form>
</div>
<div mat-dialog-actions>
  <button color="default" mat-button mat-dialog-close type="button">{{'WIDGET.TEASER.SETTINGS.CONFIG.BUTTON.BACK' | translate}}</button>
  <button (click)="submit()" [disabled]="form.invalid" color="accent" mat-flat-button type="button">{{(editMode ? 'WIDGET.TEASER.SETTINGS.CONFIG.BUTTON.EDIT' : 'WIDGET.TEASER.SETTINGS.CONFIG.BUTTON.CREATE') | translate}}</button>
</div>

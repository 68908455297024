(function (angular) {
  'use strict';

  angular
      .module('coyo.notifications')
      .directive('oyocNotificationsDialog', notificationsDialog);

  /**
   * @ngdoc directive
   * @name coyo.notifications.oyocNotificationsDialog:oyocNotificationsDialog
   * @element ANY
   *
   * @description
   * Displays the notifications dialog.
   */
  function notificationsDialog() {
    return {
      templateUrl: 'app/modules/notifications/components/notifications-dialog.html',
      replace: true,
      scope: {},
      controller: 'NotificationsDialogController',
      controllerAs: '$ctrl'
    };
  }

})(angular);

<ng-container *ngxPermissionsOnly="'FOLLOW_USER'">
  <ng-container *ngIf="(state$ | async); let state">
    <button id="follow-button"
            *ngIf="state.show"
            class="user-follow btn btn-zmdi btn-{{size}}"
            [ngClass]="{
            'btn-default': !darkTheme && !state.following,
            'btn-dark': darkTheme && !state.following,
            'btn-success': state.following}"
            [matTooltip]="(state.following ? textFollowing : textFollow) | translate"
            [matTooltipDisabled]="!noLabel"
            (click)="toggle($event)">
      <i class="zmdi zmdi-hc-fw zmdi-account-add" *ngIf="!(loading$ | async); else spinner" aria-hidden="true"></i>
      <ng-template #spinner>
        <i class="zmdi zmdi-hc-fw zmdi-spinner zmdi-hc-spin" #spinner aria-hidden="true"></i>
      </ng-template><!--
  --><span class="btn-mobile-label" *ngIf="!noLabel && !state.following">{{textFollow | translate }}</span><!--
  --><span class="btn-mobile-label" *ngIf="!noLabel && state.following">{{textFollowing | translate }}</span>
    </button>
  </ng-container>
</ng-container>

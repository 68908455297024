(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.login
   *
   * @description
   * # Login module #
   * The login module renders the login page and handles everything login-related.
   *
   * @requires $stateProvider
   */
  ModuleConfig.$inject = ["$stateProvider", "loginConfig"];
  angular
      .module('coyo.login', [
        'coyo.base',
        'commons.auth',
        'commons.ui',
        'commons.i18n'
      ])
      .config(ModuleConfig)
      .constant('loginConfig', {
        templates: {
          configure: 'app/modules/login/views/configure/login.configure.html',
          reset: 'app/modules/login/views/reset/login.reset.html',
          terms: 'app/modules/login/views/terms/login.terms.html',
          main: 'app/modules/login/views/main/login.main.html',
          logout: {
            success: 'app/modules/login/views/logout/logout.success.html'
          }
        },
        signals: {
          loginError: 'LOGIN_ERROR',
          resetError: 'RESET_ERROR'
        },
        misc: {
          shakeAfterTimes: 1
        },
        autoLoginDelay: 2000,
        maintenance: {
          types: {
            global: 'GLOBAL_MAINTENANCE',
            tenant: 'TENANT_MAINTENANCE'
          }
        }
      });

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, loginConfig) {
    $stateProvider.state('front.reset', {
      url: '/reset?token',
      templateUrl: loginConfig.templates.reset,
      controller: 'LoginResetController',
      controllerAs: '$ctrl',
      data: {
        authenticate: false
      },
      resolve: {
        passwordPattern: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieveByKey('passwordPattern');
        }],
        emailPattern: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieveByKey('emailPattern');
        }],
        phonePattern: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieveByKey('phonePattern');
        }],
        token: ["$stateParams", function ($stateParams) {
          return $stateParams.token;
        }]
      }
    }).state('front.login', {
      url: '/login',
      templateUrl: loginConfig.templates.main,
      controller: 'LoginMainController',
      controllerAs: '$ctrl',
      data: {
        authenticate: false
      },
      params: {
        errorCode: null,
        username: null,
        password: null
      },
      resolve: {
        authenticationProviderConfigs: ["AuthenticationProviderModel", "backendUrlService", function (AuthenticationProviderModel, backendUrlService) {
          if (!backendUrlService.isSet()) {
            return [];
          }
          return AuthenticationProviderModel.query({}, 'public');
        }],
        maintenance: ["MaintenanceModel", "backendUrlService", "$state", "$q", function (MaintenanceModel, backendUrlService, $state, $q) {
          if (!backendUrlService.isSet()) {
            return $q.resolve();
          } else {
            return MaintenanceModel.getPublic().then(function (maintenance) {
              if (maintenance && maintenance.type === loginConfig.maintenance.types.global) {
                $state.go('front.maintenance', null, {location: false});
                return $q.reject();
              }
              return $q.resolve();
            });
          }
        }]
      }
    }).state('front.sso-login-success', {
      url: '/sso-login-success?userId',
      onEnter: ["$state", "$stateParams", "authService", "deeplinkService", function ($state, $stateParams, authService, deeplinkService) {
        authService.ssoLoginSuccess($stateParams.userId);
        $state.go(deeplinkService.getReturnToState() || 'main', deeplinkService.getReturnToStateParams());
        deeplinkService.clearReturnToState();
      }],
      data: {
        authenticate: false
      }
    }).state('front.sso-login-error', {
      url: '/sso-login-error?errorCode',
      onEnter: ["$state", "errorService", "$stateParams", function ($state, errorService, $stateParams) {
        if ($stateParams.errorCode === 'SSO_LOGIN_DENIED' || $stateParams.errorCode === 'SSO_USER_NOT_FOUND') {
          $state.go('front.login', {errorCode: $stateParams.errorCode});
        } else {
          errorService.getMessage(_.set({}, 'data.errorStatus', $stateParams.errorCode)).then(function (message) {
            errorService.showErrorPage(message, 401);
          });
        }
      }]
    }).state('front.terms', {
      url: '/terms',
      templateUrl: loginConfig.templates.terms,
      controller: 'LoginTermsController',
      controllerAs: '$ctrl',
      data: {
        authenticate: true
      },
      resolve: {
        settings: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieve();
        }]
      }
    }).state('front.logout-success', {
      url: '/logout-success',
      templateUrl: loginConfig.templates.logout.success,
      controller: angular.noop,
      controllerAs: '$ctrl',
      data: {
        authenticate: false
      }
    }).state('front.sso-logout-success', {
      url: '/sso-logout-success',
      onEnter: ["$state", "authService", function ($state, authService) {
        authService.ssoLogoutSuccess();
        $state.go('front.logout-success');
      }]
    });
  }

})(angular);

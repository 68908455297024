import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {RssWidget} from '@widgets/rss/rss-widget';
import {RssWidgetService} from '@widgets/rss/rss-widget.service';
import * as _ from 'lodash';
import {Observable, of, Subject} from 'rxjs';
import {finalize, map} from 'rxjs/operators';

/**
 * The hashtag widget's settings component.
 *
 * This component is used only in the context of the widget settings modal dialog.
 */
@Component({
  templateUrl: './rss-widget-settings.component.html',
  styleUrls: ['./rss-widget-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RssWidgetSettingsComponent extends WidgetSettingsComponent<RssWidget> implements OnInit {

  private asyncUrlValidator: Subject<ValidationErrors>;
  isLoading: boolean;

  constructor(private formBuilder: FormBuilder, private rssWidgetService: RssWidgetService) {
    super();
  }

  verifyUrl(): void {
    this.isLoading = true;
    this.rssWidgetService
      .verifyUrl(this.parentForm.get(['rssUrl']).value, this.parentForm.get(['userName']).value,
        this.parentForm.get(['_encrypted_password']).value)
      .pipe(map(urlVerificationResp =>
        urlVerificationResp.valid ? null :
          _.set({} as ValidationErrors, urlVerificationResp.validationMessage, true)))
      .pipe(finalize(() => {
        this.isLoading = false;
        this.asyncUrlValidator.complete();
      }))
      .subscribe(validationErrors => this.asyncUrlValidator.next(validationErrors),
        () => this.asyncUrlValidator.next({invalidUrl: true})
      );
  }

  ngOnInit(): void {
    if (!this.widget.settings.maxCount && !this.widget.settings.displayImage) {
      this.widget.settings.displayImage = true;
      this.widget.settings.maxCount = 5;
    }

    const urlControl = this.formBuilder.control(this.widget.settings.rssUrl,
      [Validators.required, CoyoValidators.url], this.createAsyncUrlValidator.bind(this));
    const userControl = this.formBuilder.control(this.widget.settings.userName);
    const passwordControl = this.formBuilder.control(this.widget.settings._encrypted_password);
    this.parentForm.addControl('rssUrl', urlControl);
    this.parentForm.addControl('userName', userControl);
    this.parentForm.addControl('_encrypted_password', passwordControl);
    this.parentForm.addControl('maxCount', this.formBuilder.control(this.widget.settings.maxCount));
    this.parentForm.addControl('displayImage', this.formBuilder.control(this.widget.settings.displayImage));

    // if credentials change then delete rssUrl errors and trigger async validation
    const onCredentialsChange = () => urlControl.setValue(urlControl.value);
    userControl.statusChanges.subscribe(onCredentialsChange);
    passwordControl.statusChanges.subscribe(onCredentialsChange);
  }

  private createAsyncUrlValidator(): Observable<ValidationErrors> {
    if (this.asyncUrlValidator) {
      this.asyncUrlValidator.next(null);
      this.asyncUrlValidator.complete();
    }
    if (this.parentForm.pristine) {
      this.asyncUrlValidator = null;
      return of(null as ValidationErrors);
    }
    this.asyncUrlValidator = new Subject<ValidationErrors>();
    return this.asyncUrlValidator;
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * Filter expand component.
 *
 * @description
 * Renders a single filter entry used to expand the filter list
 *
 * @example
 * <coyo-filter-expand ng-click="onClick('param1')"></coyo-filter-expand>
 */
@Component({
  selector: 'coyo-filter-expand',
  templateUrl: './filter-expand.component.html',
  styleUrls: ['./filter-expand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterExpandComponent {

  constructor() {
  }
}

import {AfterContentChecked, AfterViewInit, Directive, ElementRef} from '@angular/core';
import {FloatingModulesConstants} from '@shared/floating-modules/floating-modules.constants';

/**
 * This directive is used as an attribute directive (meaning you can set them on any element)
 * and renders a floating-header on any element when being set as attribute, that have a
 * scrollable content container and display it according to the scroll position of the
 * scrollable container - similar to the Angular Material Dialog floating header and footer effect.
 *
 * You need to set the following in the template:
 * - Set attribute 'coyoFloatingHeader' on any element to act as the header element above
 *  the scrollable container to put the floating-header behaviour and effect on it.
 * - Set the scrollable container which is connected to the floating-header by
 *  adding class 'floating-scroll-container' to the scrollable-container element.
 *
 * This is usable for multiple occurrences like for e.g. in the user-chooser.
 */
@Directive({
  selector: '[coyoFloatingHeader]'
})
export class FloatingHeaderDirective implements AfterViewInit, AfterContentChecked {

  private _element: HTMLElement;
  private _isFloating: boolean = false;
  private readonly _offsetTop: number = 0;
  private _scrollContainer: HTMLCollection;

  constructor(element: ElementRef) {
    this._element = element.nativeElement;
    this._offsetTop = this._element.offsetTop;
  }

  static _getContainerScrollPosition(scrollContainer: HTMLElement): number {
    return scrollContainer.scrollTop;
  }

  ngAfterViewInit(): void {
    this._updateScrollContainerEventListener();
  }

  ngAfterContentChecked(): void {
    this._updateScrollContainerEventListener();
  }

  private _updateScrollContainerEventListener(): void {
    // Get the scrollContainer which is a sibling and found from root of next element of floating header
    this._scrollContainer =
      this._element.nextElementSibling.getElementsByClassName(FloatingModulesConstants.SCROLL_CONTAINER_CLASS_NAME);

    /* tap into the elements scroll event after iterating HTMLCollection of HTMLElements */
    for (const element of Array.from(this._scrollContainer)) {
      const scrollContainer = element as HTMLElement;

      // Initially updating scroll position of scroll container, for e.g. when content grows
      this._updateScrollPosition(scrollContainer);

      // Add event listener to scrollContainer connected to the reference element as a sibling
      scrollContainer.addEventListener('scroll', () => {
        this._updateScrollPosition(scrollContainer);
      });
    }
  }

  private _updateScrollPosition(scrollContainer: HTMLElement): void {
    const containerTop = FloatingHeaderDirective._getContainerScrollPosition(scrollContainer);
    const offset = this._offsetTop;

    if (containerTop > offset && !this._isFloating) {
      this._makeFloat();
      this._isFloating = true;
    } else if (containerTop <= offset && this._isFloating) {
      this._resetFloat();
      this._isFloating = false;
    }
  }

  private _makeFloat(): void {
    this._element.style.cssText += 'position: -webkit-sticky; position: sticky;';
    this._element.style.cssText += 'box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2)';
    this._element.style.cssText += 'transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)';
    this._element.style.top = '0';
    this._element.style.zIndex = '10';
  }

  private _resetFloat(): void {
    this._element.style.position = '';
    this._element.style.top = '';
    this._element.style.boxShadow = '';
    this._element.style.transition = '';
    this._element.style.zIndex = '';
  }
}

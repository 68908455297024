<div class="rte-widget" coyoAnchorContainer>
  <div class="rte-html-container fr-view"
       *ngIf="!editMode"
       [innerHTML]="widget.settings.html_content | youtubeNoCookie | hashtags | trustHtml">
  </div>
  <coyo-rte
    *ngIf="editMode"
    [content]="widget.settings.html_content"
    (contentUpdated)="contentUpdated($event)"
    height="350">
  </coyo-rte>
</div>

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {VideoPreview} from '@domain/preview/video-preview';
import {WebPreviewService} from '@domain/preview/web-preview/web-preview.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {VideoWidget} from '../video-widget';
import {VideoWidgetSettings} from '../video-widget-settings.model';

@Component({
  selector: 'coyo-video-widget-settings',
  templateUrl: './video-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoWidgetSettingsComponent extends WidgetSettingsComponent<VideoWidget> implements OnInit {
  safeUrl: boolean = true;

  constructor(private webPreviewService: WebPreviewService) {
    super();
  }

  ngOnInit(): void {
    this.parentForm.addControl('_url', new FormControl(this.widget.settings._url, [Validators.required, CoyoValidators.url]));
    this.parentForm.addControl('title', new FormControl(this.widget.settings.title));
    this.parentForm.addControl('description', new FormControl(this.widget.settings.description));
    this.checkUrl();
  }

  checkUrl(): void {
    const formValue = this.parentForm.controls['_url'].value;
    this.safeUrl = !formValue || formValue.substring(0, 8) === 'https://';
  }

  /**
   * This function is called on the submit of the video widget settings changes
   * it returns an observable with the VideoWidgetSettings
   *
   * @param settings the settings of the video widget
   * @returns an Observable with video widget settings appending the _backendData information
   */
  onBeforeSave(settings?: any): Observable<VideoWidgetSettings> {
    return this.webPreviewService.generateWebPreview(settings._url).pipe(map((response: VideoPreview) => {
      const videoUrl = response.videoUrl;
      const ratio = (response.width && response.height) ? (response.height / response.width * 100) : 56.25;
      if (videoUrl && ratio) {
        return {
          ...settings,
          ...{
            _backendData: {
              videoUrl: videoUrl,
              ratio: ratio
            }
          }
        };
      } else {
        settings._backendData = null;
        return settings;
      }
    })).pipe(catchError(() => {
        settings._backendData = null;
        return of(settings);
      }
    ));
  }
}

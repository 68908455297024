(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .component('coyoProgressbar', progressbar())
      .controller('ProgressbarController', ProgressbarController);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoProgressbar:coyoProgressbar
   * @scope
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Renders a progressbar element.
   *
   * @param {object} target The data target.
   * @param {number} target.current The current value.
   * @param {number} target.max The maximum value.
   */
  function progressbar() {
    return {
      templateUrl: 'app/commons/ui/components/progressbar/progressbar.html',
      bindings: {
        target: '<',
        warningPercent: '<'
      },
      controller: 'ProgressbarController'
    };
  }

  function ProgressbarController() {
    var vm = this;
    vm.percentage = percentage;

    function percentage() {
      vm.class = '';
      if (!vm.target) {
        return 0;
      }
      if (vm.target.max === 0) {
        vm.class = 'full';
        return 100;
      }
      var percent = (vm.target.current * 100) / vm.target.max;
      if (!isFinite(percent) || percent < 0) {
        return 0;
      }
      if (percent > 100) {
        vm.class = 'full';
        return 100;
      }
      if (percent >= vm.warningPercent) {
        vm.class = 'warn';
      }
      return percent;
    }
  }
})(angular);

<div class="user-avatar" *ngIf="state$ | async; let state">
  <a *ngIf="state.showLink;else noLink" href uiSref="main.profile" [uiParams]="{userId: user.slug}">
    <coyo-user-avatar-image [user]="user"
                            [avatarSize]="avatarSize"
                            [allowModal]="allowModal"
                            [showFollowButton]="showFollowButton"
                            [showMessagingButton]="showMessagingButton"
                            [showExternalFlag]="showExternalFlag"
                            [showOnlineStatus]="showOnlineStatus"></coyo-user-avatar-image>
  </a>
  <ng-template #noLink>
    <coyo-user-avatar-image [user]="user"
                            [avatarSize]="avatarSize"
                            [allowModal]="allowModal"
                            [showFollowButton]="showFollowButton"
                            [showMessagingButton]="showMessagingButton"
                            [showExternalFlag]="showExternalFlag"
                            [showOnlineStatus]="showOnlineStatus"></coyo-user-avatar-image>
  </ng-template>
</div>

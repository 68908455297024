import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Share} from '@domain/share/share';
import {ShareService} from '@domain/share/share.service';
import {Shareable} from '@domain/share/shareable';
import {merge, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

/**
 * Container component combining share button and share count.
 */
@Component({
  selector: 'coyo-share-container',
  templateUrl: './share-container.component.html',
  styleUrls: ['./share-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareContainerComponent implements OnInit {

  /**
   * Model with data.
   */
  @Input() target: Shareable;

  /**
   * Event if a share was created.
   */
  @Output() shareCreated: EventEmitter<Share> = new EventEmitter<Share>();

  /**
   * Emits when a share is deleted by the shares modal
   */
  @Output() sharesDeleted: EventEmitter<Share[]> = new EventEmitter<Share[]>();

  count$: Observable<{ count: number }>;

  constructor(private shareService: ShareService) {
  }

  ngOnInit(): void {
    this.count$ = merge(this.shareCreated, this.sharesDeleted, of(undefined))
      .pipe(switchMap(() => this.shareService.getShareCount(this.target)))
      .pipe(map(count => ({count})));
  }
}

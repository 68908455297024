<ng-container [formGroup]="parentForm">
  <mat-tab-group mat-stretch-tabs color="accent" (selectedIndexChange)="selectTab($event)">
    <mat-tab [label]="'WIDGET.POLL.CONFIG' | translate">
      <mat-form-field>
        <mat-label translate="WIDGET.POLL.SETTINGS.CONFIG.QUESTION"></mat-label>
        <input matInput #question formControlName="question">
        <mat-hint align="end">{{question.value?.length || 0}}/{{QUESTION_MAX_LENGTH}}</mat-hint>
        <mat-error *coyoFieldErrors="parentForm.controls.question.errors"></mat-error>
      </mat-form-field>
      <mat-form-field class="no-hint">
        <mat-label translate="WIDGET.POLL.SETTINGS.CONFIG.DESCRIPTION"></mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
      </mat-form-field>
      <ng-container formArrayName="_options">
        <mat-form-field *ngFor="let option of getOptions().controls; let $index = index"
                        [formGroup]="option" class="no-hint">
          <mat-label *ngIf="!$index" translate="WIDGET.POLL.SETTINGS.CONFIG.OPTION"></mat-label>
          <input matInput formControlName="answer" [attr.maxlength]="ANSWER_MAX_LENGTH" #optionInput
                 (keydown.enter)="onInputEnter($event, option.get('answer').value)"
                 (keydown.backspace)="onInputBackspace($event, option.get('answer').value, $index)">
          <button matSuffix mat-icon-button *ngIf="$index" [attr.aria-label]="'DELETE' | translate" (click)="deleteOption($index)">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </mat-form-field>
      </ng-container>
      <button mat-button class="block" (click)="addOption(true)">
        <mat-icon svgIcon="plus"></mat-icon>{{'WIDGET.POLL.SETTINGS.CONFIG.ADDOPTION' | translate}}
      </button>
    </mat-tab>
    <mat-tab [label]="'WIDGET.POLL.EXTENDEDOPTIONS' | translate">
      <mat-form-field class="no-hint">
        <mat-label translate="WIDGET.POLL.SETTINGS.CONFIG.MAXANSWERS"></mat-label>
        <input matInput type="number" min="1" step="1" formControlName="_maxAnswers">
      </mat-form-field>
      <mat-checkbox formControlName="_anonymous">
        {{'WIDGET.POLL.SETTINGS.CONFIG.ANONYMOUS' | translate}}
        <mat-hint translate="WIDGET.POLL.SETTINGS.CONFIG.ANONYMOUS.HELP"></mat-hint>
      </mat-checkbox>
      <mat-checkbox formControlName="_showResults">
        {{'WIDGET.POLL.SETTINGS.CONFIG.SHOWRESULTS' | translate}}
        <mat-hint translate="WIDGET.POLL.SETTINGS.CONFIG.SHOWRESULTS.HELP"></mat-hint>
      </mat-checkbox>
      <mat-checkbox formControlName="_frozen">
        {{'WIDGET.POLL.SETTINGS.CONFIG.FROZEN' | translate}}
        <mat-hint translate="WIDGET.POLL.SETTINGS.CONFIG.FROZEN.HELP"></mat-hint>
      </mat-checkbox>
    </mat-tab>
    <mat-tab [label]="'WIDGET.POLL.RESULTS' | translate" *ngIf="widget.id || widget.dataReference">
      <coyo-poll-widget *ngIf="updatedWidget$ | async as updatedWidget"
                        [editMode]="true"
                        [settingsMode]="true"
                        [widget]="updatedWidget"></coyo-poll-widget>
    </mat-tab>
  </mat-tab-group>
</ng-container>

(function (angular) {
  'use strict';

  appTranslationsModalService.$inject = ["modalService"];
  angular.module('coyo.apps.api')
      .factory('appTranslationsModalService', appTranslationsModalService);

  /**
   * @ngdoc service
   * @name coyo.apps.api.appTranslationsModalService
   *
   * @description
   * Modal Service to open the app navigation translation modal.
   *
   * @requires modalService
   */
  function appTranslationsModalService(modalService) {

    return {
      open: open
    };

    /**
     * @ngdoc method
     * @name coyo.apps.api.appTranslationsModalService#open
     * @methodOf coyo.apps.api.appTranslationsModalService
     *
     * @description
     * Method to open the app navigation translation modal with its input and output params.
     *
     * @param {object} sender The sender which contains the navigation and apps
     *
     * @returns {object} The sender with the updated navigation and apps
     */
    function open(sender) {
      return modalService.open({
        size: 'lg',
        templateUrl: 'app/apps/api/components/app-translations/app-translations-modal.html',
        controller: 'AppTranslationsModalController',
        controllerAs: '$ctrl',
        resolve: {
          sender: function () {
            return angular.copy(sender);
          }
        }
      }).result;
    }
  }
})(angular);

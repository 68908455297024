(function (angular) {
  'use strict';

  FileAuthorModel.$inject = ["restResourceFactory", "coyoEndpoints"];
  angular
      .module('coyo.domain')
      .factory('FileAuthorModel', FileAuthorModel);

  /**
   * @ngdoc service
   * @name coyo.domain.FileAuthorModel
   *
   * @description
   * Provides the file author model.
   *
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   */
  function FileAuthorModel(restResourceFactory, coyoEndpoints) {
    var FileAuthorModel = restResourceFactory({
      url: coyoEndpoints.sender.senders
    });
    // class members
    angular.extend(FileAuthorModel, {

      /**
       * @ngdoc method
       * @name coyo.domain.FileAuthorModel#getFileAuthors
       * @methodOf coyo.domain.FileAuthorModel
       *
       * @description
       * Returns the authors of a given file list
       *
       * @param {string} senderId
       * The id of the sender the file belongs to.
       *
       * @param {string} appIdOrSlug
       * The app ID or slug
       *
       * @param {string} fileIds
       * List of file ID's
       *
       * @returns {object} A promise of the renaming operation
       */
      getFileAuthors: function (senderId, appIdOrSlug, fileIds) {
        var url = FileAuthorModel.$url(senderId + '/file/authors/' + appIdOrSlug);
        return FileAuthorModel.$get(url, {ids: fileIds});
      },

      /**
       * @ngdoc method
       * @name coyo.domain.FileAuthorModel#getVersionAuthors
       * @methodOf coyo.domain.FileAuthorModel
       *
       * @description
       * Returns the authors of a given file list
       *
       * @param {string} senderId
       * The id of the sender the file belongs to.
       *
       * @param {string} appIdOrSlug
       * The app ID or slug
       *
       * @param {string} fileId
       * List of file ID
       *
       * @returns {object} A promise of the renaming operation
       */
      getVersionAuthors: function (senderId, appIdOrSlug, fileId) {
        var url = FileAuthorModel.$url(senderId + '/documents/' + fileId + '/version/authors/' + appIdOrSlug);
        return FileAuthorModel.$get(url);
      }
    });

    return FileAuthorModel;
  }

})(angular);

import {LockInfo} from '@app/file-picker/lock-info';
import {StorageType} from '@domain/attachment/storage';
import {BaseModel} from '@domain/base-model/base-model';

/**
 * DataTransfer type name for coyo internal file ids
 */
export const COYO_INTERNAL_FILE_DRAG_TYPE = 'coyo/file-id';

/**
 * A file symbolizes a document or a folder.
 */
export interface File extends BaseModel, LockInfo {
  senderId: string;
  name: string;
  displayName: string;
  folder: boolean;
  appRoot: boolean;
  uploadFailed: boolean;
  contentType: string;
  extension: string;
  storage?: StorageType;
  length?: number;
  _permissions?: { [permission: string]: boolean };
  subscriptionInfo: {
    token: string;
  };
  childCount?: number;
  hasPublicLink: boolean;
  activePublicLink: boolean;
}

import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DeleteConfirmationDialogComponent} from '@shared/dialog/delete-confirmation/delete-confirmation-dialog.component';
import {DialogModule} from '@shared/dialog/dialog.module';
import {HelpModule} from '@shared/help/help.module';
import {MaterialModule} from '@shared/material/material.module';
import {SelectFileModule} from '@shared/select-file/select-file.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {TEASER_WIDGET} from '@widgets/teaser/teaser-widget-config';
import {TeaserWidgetSettingsComponent} from '@widgets/teaser/teaser-widget-settings/teaser-widget-settings.component';
import {TeaserWidgetComponent} from '@widgets/teaser/teaser-widget/teaser-widget.component';
import {AspectRatioChooserComponent} from './aspect-ratio-chooser/aspect-ratio-chooser.component';
import {NewSlideDialogComponent} from './new-slide-dialog/new-slide-dialog.component';

/**
 * Module providing the teaser widget.
 */
@NgModule({
    imports: [
        ReactiveFormsModule,
        CoyoCommonsModule,
        UpgradeModule,
        SelectFileModule,
        MaterialModule,
        DialogModule,
        HelpModule
    ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: TEASER_WIDGET, multi: true}
  ],
  declarations: [
    TeaserWidgetComponent,
    TeaserWidgetSettingsComponent,
    AspectRatioChooserComponent,
    NewSlideDialogComponent
  ],
  entryComponents: [
    TeaserWidgetComponent,
    TeaserWidgetSettingsComponent,
    NewSlideDialogComponent,
    DeleteConfirmationDialogComponent
  ]
})
export class TeaserWidgetModule {
}

import {STATE_NAME_ENGAGE} from '@app/engage/engage.state';
import {TermsViewComponent} from '@app/engage/terms/terms-view/terms-view.component';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';

// Do not change the state names
export const STATE_NAME_ENGAGE_TERMS_VIEW = STATE_NAME_ENGAGE + '.terms';

export const termsViewState: NgHybridStateDeclaration = {
  name: STATE_NAME_ENGAGE_TERMS_VIEW,
  url: '/terms',
  component: TermsViewComponent,
  data: {
    authenticate: true
  },
  params: {}
};

(function (angular) {
  'use strict';

  MaintenanceMessageController.$inject = ["$http", "$scope", "$timeout", "$sessionStorage", "adminStates", "authService", "socketService", "coyoEndpoints"];
  angular
      .module('commons.ui')
      .controller('MaintenanceMessageController', MaintenanceMessageController);

  function MaintenanceMessageController($http, $scope, $timeout, $sessionStorage,
                                        adminStates, authService, socketService, coyoEndpoints) {
    var vm = this;
    vm.$onInit = onInit;
    vm.close = close;
    var timeout;

    function updateDisplay() {
      if (vm.message.endDate) {
        if (timeout) {
          $timeout.cancel(timeout);
        }
        var endIn = vm.message.endDate - Date.now();
        if (endIn >= 0) {
          timeout = $timeout(function () {
            vm.display = false;
          }, endIn);
        }
      }
      var currentTime = new Date().getTime();

      authService.getUser().then(function (user) {
        var checkAdmin = vm.message.onlyAdmins !== 'true' ||
          (vm.message.onlyAdmins === 'true' && user.hasGlobalPermissions(vm.allAdminPermissions));
        var checkTime = !vm.message.endDate || currentTime <= vm.message.endDate;
        var checkMessage = vm.message.message && vm.message.message !== '';
        var checkHidden = angular.isUndefined($sessionStorage['maintenanceMessage.' + vm.message.id]);
        vm.display = checkMessage && checkAdmin && checkTime && checkHidden;
      });
    }

    function updateMessage() {
      return $http.get(coyoEndpoints.maintenance.message).then(function (response) {
        if (response.status === 204) {
          return {};
        }
        return response.data;
      });
    }

    function close() {
      $sessionStorage['maintenanceMessage.' + vm.message.id] = true;
      updateDisplay();
    }

    function onInit() {
      vm.message = {};
      vm.allAdminPermissions = _.map(adminStates, 'globalPermission').join(',');
      vm.display = false;

      updateMessage().then(function (message) {
        vm.message = message;
        updateDisplay();
      });

      authService.getUser().then(function (user) {
        var destination = '/topic/maintenance';
        var listenerSet = socketService.subscribe(destination, function (message) {
          vm.message = message.content;
          updateDisplay();
          $scope.$apply();
        }, 'SET', user.tenant, user.tenantSubscriptionInfo.token);
        var listenerUnset = socketService.subscribe(destination, function () {
          vm.message = {};
          updateDisplay();
          $scope.$apply();
        }, 'DELETED', user.tenant, user.tenantSubscriptionInfo.token);

        $scope.$on('$destroy', function () {
          listenerSet();
          listenerUnset();
          $timeout.cancel(timeout);
        });
      });
    }
  }

})(angular);

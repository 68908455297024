import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimelineFormAttachment} from '@shared/files/attachment-btn/types/timeline-form-attachment';
import {TimelineFormCoyoLibraryAttachment} from '@shared/files/attachment-btn/types/timeline-form-coyo-library-attachment';
import {FileItem} from 'ng2-file-upload';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Renders the file item with the progress number.
 */
@Component({
  selector: 'coyo-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentComponent implements OnInit {

  /**
   * The file item.
   */
  @Input() fileItem: FileItem | TimelineFormAttachment | TimelineFormCoyoLibraryAttachment;

  /**
   * Will be fired if a file item should be removed.
   */
  @Output() removedFileItem: EventEmitter<FileItem> = new EventEmitter();

  state$: Observable<{progress: number, completed: boolean}>;

  private uploadProgressSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private completeSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  ngOnInit(): void {
    this.state$ = combineLatest([this.completeSubject$, this.uploadProgressSubject$])
      .pipe(map(value => ({completed: value[0], progress: value[1]}) ));

    if (this.fileItem instanceof FileItem) {
      this.fileItem.onProgress = (percent: number) => this.uploadProgressSubject$.next(percent);
      this.fileItem.onSuccess = () => this.completeSubject$.next(true);
    } else {
      this.completeSubject$.next(true);
    }
  }

  /**
   * File item remove event.
   *
   * @param file the file to be removed
   */
  removeFile(file: FileItem): void {
    this.removedFileItem.emit(file);
  }

  /**
   * Returns the name of the file.
   *
   * @return the file name
   */
  getFileName(): string {
    return this.fileItem instanceof FileItem
      ? this.fileItem.file.name
      : this.fileItem.displayName || this.fileItem.name;
  }

  /**
   * Checks whether or not the file is protected. We consider files protected when e.g. the
   * visibility level is set to less than 'visible for company domain' on the external file provider.
   *
   * This only takes effect when creating a timeline item. Not when viewing it.
   *
   * @return true, if the file is protected enough to give the user a clue about it.
   */
  isFileProtected(): boolean {
    if (this.fileItem.hasOwnProperty('visibility')) {
      return this.fileItem['visibility'] === 'PRIVATE';
    }
    return false;
  }
}

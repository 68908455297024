<ng-container *ngIf="user$ | async; let currentUser">
  <coyo-legacy-file-details-modal [appIdOrSlug]="data.appIdOrSlug"
                                  [currentIndex]="data.currentIndex"
                                  [currentUser]="currentUser"
                                  [fileAuthors]="data.fileAuthors"
                                  [files]="data.files"
                                  [filesTotal]="data.filesTotal"
                                  [linkToFileLibrary]="data.linkToFileLibrary"
                                  [showAuthors]="data.showAuthors"
                                  [loadNext]="nextFunction"
                                  (close)="close($event)"
                                  [fileDetailsDialogId]="fileDetailsDialogId"
  ></coyo-legacy-file-details-modal>
</ng-container>


import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {BUTTON_WIDGET} from './button-widget-config';
import {ButtonWidgetSettingsComponent} from './button-widget-settings/button-widget-settings.component';
import {ButtonWidgetComponent} from './button-widget/button-widget.component';

/**
 * Module providing the button widget
 */
@NgModule({
  imports: [
    ButtonsModule,
    CoyoCommonsModule,
    CoyoFormsModule
  ],
  declarations: [
    ButtonWidgetComponent,
    ButtonWidgetSettingsComponent
  ],
  providers: [{
    provide: WIDGET_CONFIGS,
    useValue: BUTTON_WIDGET,
    multi: true
  }]
})
export class ButtonWidgetModule {
}

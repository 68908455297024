import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {EventMetaDataModule} from '@shared/event-meta-data/event-meta-data.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {MomentModule} from 'ngx-moment';
import {ToArrayPipe} from './as-array/as-array.pipe';
import {UPCOMING_EVENTS_WIDGET} from './upcoming-events-widget-config';
import {UpcomingEventsWidgetSettingsComponent} from './upcoming-events-widget-settings/upcoming-events-widget-settings.component';
import {UpcomingEventsWidgetComponent} from './upcoming-events-widget/upcoming-events-widget.component';

@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    EventMetaDataModule,
    MomentModule,
    SenderUIModule
  ],
  declarations: [
    UpcomingEventsWidgetComponent,
    UpcomingEventsWidgetSettingsComponent,
    ToArrayPipe
  ],
  entryComponents: [
    UpcomingEventsWidgetComponent,
    UpcomingEventsWidgetSettingsComponent
  ],
  exports: [],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: UPCOMING_EVENTS_WIDGET, multi: true}
  ]
})
export class UpcomingEventsWidgetModule {
}

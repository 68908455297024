import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {WIDGET_CONFIGS} from '../api/widget-config';
import {HASHTAG_WIDGET} from './hashtag-widget-config';
import {HashtagWidgetSettingsComponent} from './hashtag-widget-settings/hashtag-widget-settings.component';
import {HashtagWidgetComponent} from './hashtag-widget/hashtag-widget.component';
import {HashtagWidgetService} from './hashtag-widget/hashtag-widget.service';

/**
 * Module providing the hashtag widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule
  ],
  declarations: [
    HashtagWidgetComponent,
    HashtagWidgetSettingsComponent
  ],
  entryComponents: [
    HashtagWidgetComponent,
    HashtagWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: HASHTAG_WIDGET, multi: true},
    HashtagWidgetService
  ]
})
export class HashtagWidgetModule {}

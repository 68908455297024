<div [formGroup]="parentForm">
  <div [class.has-error]="isInvalid('app')" class="form-group">
    <label class="col-sm-2 control-label" translate="WIDGETS.LATESTFILES.SETTINGS.DOCUMENTAPP.LABEL"></label>
    <div class="col-sm-9">
      <coyo-select-app [multiselect]="false"
                       formControlName="app"
                       id="app" key="file-library" name="app" placeholder="COMMONS.SHARES.PLACEHOLDER">
      </coyo-select-app>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.LATESTFILES.SETTINGS.DOCUMENTAPP.HELP"></coyo-help>
    </div>
  </div>
  <div [class.has-error]="isInvalid('fileCount')" class="form-group">
    <label class="col-sm-2 control-label" for="fileCount"
           translate="WIDGETS.LATESTFILES.SETTINGS.FILE_COUNT.LABEL"></label>
    <div class="col-sm-9">
      <input
        class="form-control"
        formControlName="fileCount"
        id="fileCount"
        max="20"
        min="1"
        name="fileCount"
        required
        step="1"
        type="number">
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.LATESTFILES.SETTINGS.FILE_COUNT.HELP"></coyo-help>
    </div>
  </div>
</div>

(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.account
   *
   * @description
   * # Account module #
   * The account module contains
   * * custom account modals as a service,
   * * the account settings and
   * * the account notification settings.
   */
  ModuleConfig.$inject = ["$stateProvider", "accountConfig"];
  angular
      .module('coyo.account', [
        'coyo.base',
        'coyo.profile',
        'commons.auth',
        'commons.browsernotifications',
        'commons.ui',
        'ngTagsInput'
      ])
      .config(ModuleConfig)
      .constant('accountConfig', {
        templates: {
          main: 'app/modules/account/views/main/account.main.html',
          notifications: 'app/modules/account/views/notifications/account.notifications.html'
        }
      });

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, accountConfig) {
    $stateProvider.state('main.account', {
      url: '/account',
      templateUrl: accountConfig.templates.main,
      controller: 'AccountMainController',
      controllerAs: 'vm',
      resolve: {
        currentUser: ["authService", function (authService) {
          return authService.getUser();
        }],
        passwordPattern: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieveByKey('passwordPattern');
        }]
      },
      data: {
        globalPermissions: 'MANAGE_ACCOUNT_SETTINGS',
        globalPermissionsRequireAll: true,
        pageTitle: 'MODULE.ACCOUNT.HEADER',
        guide: 'account'
      }
    }).state('main.account-email-activation', {
      url: '/account/email-activation/:token',
      resolve: {
        currentUser: ["authService", function (authService) {
          return authService.getUser();
        }]
      },
      onEnter: ["currentUser", "userService", "$stateParams", "$injector", "$timeout", "$state", function (currentUser, userService, $stateParams, $injector, $timeout, $state) {
        if ($stateParams.token) {
          userService.activateEmail(currentUser, $stateParams.token).then(function () {
            $injector.get('ngxNotificationService').success('MODULE.ACCOUNT.MODALS.CHANGE_EMAIL_ADDRESS.MESSAGES.SUCCESS');
          }).finally(function () {
            $state.go('main.account');
          });
        }
      }],
      data: {
        globalPermissions: 'MANAGE_ACCOUNT_SETTINGS'
      }
    }).state('main.account-notifications', {
      url: '/account/notifications',
      templateUrl: accountConfig.templates.notifications,
      controller: 'AccountNotificationsController',
      controllerAs: '$ctrl',
      resolve: {
        currentUser: ["authService", function (authService) {
          return authService.getUser();
        }],
        notificationSettings: ["browserNotificationsService", function (browserNotificationsService) {
          return browserNotificationsService.getNotificationSettings(false);
        }]
      },
      data: {
        globalPermissions: 'MANAGE_NOTIFICATION_SETTINGS',
        pageTitle: 'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.HEADER',
        guide: 'notifications'
      }
    });
  }

})(angular);

import {Inject, Injectable} from '@angular/core';
import {FileLibraryFilePickerService} from '@app/file-library/file-library-file-picker-service/file-library-file-picker.service';
import {Document} from '@domain/file/document';
import {DocumentService} from '@domain/file/document/document.service';
import {FileService} from '@domain/file/file/file.service';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';
import {of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {RtePlugin} from '../rte-plugin';

/**
 * A custom RTE plugin that connects the Froala editor to the COYO file library for image selection.
 */
@Injectable()
export class ImageFromFileLibraryPlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertImageFromFileLibrary';

  constructor(@Inject(FROALA_EDITOR) private froala: any,
              private fileLibraryFilePickerService: FileLibraryFilePickerService,
              private translateService: TranslateService,
              private documentService: DocumentService,
              private fileService: FileService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    if (!settings.canAccessFiles) {
      return; // user is not allowed to access the file library
    }

    const plugin = this;
    plugin.froala.RegisterCommand(ImageFromFileLibraryPlugin.KEY, {
      title: plugin.translateService.instant('RTE.INSERT_IMAGE'),
      plugin: 'coyoImagePlugin',
      icon: 'insertImage',
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        plugin.openCoyoFileLibrary(this);
      }
    });
  }

  private openCoyoFileLibrary(editor: any): void {
    const plugin = this;
    const wrapper = editor.$oel.find('.fr-wrapper');
    const scrollPosition = wrapper.scrollTop();

    editor.selection.save();
    const prevImage = editor.image.get();
    const initialFolderRequest = editor.getApp() ?
      this.fileService.getFile(editor.getApp().rootFolderId, editor.getSender().id, ['*']) :
      of(null);
    initialFolderRequest
      .pipe(switchMap(initialFolder => plugin.fileLibraryFilePickerService.open(editor.getSender(), {
        selectionType: 'single',
        contentTypes: ['image/gif', 'image/jpeg', 'image/png', 'image/bmp', 'image/svg+xml'],
        initialFolder
      })))
      .pipe(filter(result => !!result?.length))
      .pipe(map(documents => documents[0]))
      .subscribe((document: Document) => {
        if (document) {
          const url = plugin.documentService.getDownloadUrl(document);
          editor.selection.restore();
          editor.image.insert(url, true, {}, prevImage);
        } else {
          editor.selection.restore();
        }
        wrapper.scrollTop(scrollPosition);
      }, () => {
        editor.selection.restore();
        wrapper.scrollTop(scrollPosition);
      });
  }
}

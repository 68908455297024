import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {App} from '@apps/api/app';
import {AppSettings} from '@apps/api/app-settings/app-settings';

/**
 * A component that renders the settings form of a specific app.
 */
@Component({
  template: ''
})
export abstract class IndividualAppSettingsComponent<Settings extends AppSettings> {

  /**
   * The corresponding app this settings component belongs to.
   */
  @Input() app?: App<Settings>;

  /**
   * The parent settings form component.
   */
  @Input() parentForm: FormGroup;

  /**
   * Called when the submit button is pressed. Can be used to alter the value that is going to be saved.
   * @param settings the current settings before save
   * @returns the new updated values
   */
  onBeforeSave(settings: Settings): Settings {
    return settings;
  }
}

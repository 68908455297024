(function (angular) {
  'use strict';

  TeamAdminModel.$inject = ["restResourceFactory"];
  angular
      .module('coyo.admin.gamification')
      .factory('TeamAdminModel', TeamAdminModel);

  /**
   * @ngdoc service
   * @name coyo.admin.gamification.TeamAdminModel
   *
   * @description
   * Domain model representing the team administration endpoint.
   *
   * @requires restResourceFactory
   */
  function TeamAdminModel(restResourceFactory) {
    var Team = restResourceFactory({
      url: '/web/tournaments/{{tournamentId}}/teams/{{id}}'
    });

    return Team;
  }

})(angular);

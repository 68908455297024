import {Injectable} from '@angular/core';
import {Page} from '@domain/page/page';
import {PageService} from '@domain/page/page.service';
import {SubscriptionService} from '@domain/subscription/subscription.service';
import {Action, State, StateContext} from '@ngxs/store';
import {SuggestPagesWidgetSettings} from '@widgets/suggest-pages/suggest-pages-widget-settings';
import {Load} from '@widgets/suggest-pages/suggest-pages-widget/suggest-pages-widget.actions';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {map, share, switchMap, tap} from 'rxjs/operators';

export interface SuggestPagesWidgetsStateModel {
  [key: string]: SuggestPagesWidgetStateModel;
}

export interface SuggestPagesWidgetStateModel {
  pages: Page[];
  hasPages: boolean;
  loading: boolean;
}

@Injectable({providedIn: 'root'})
@State<SuggestPagesWidgetsStateModel>({
  name: 'suggestPagesWidget',
  defaults: {}
})
export class SuggestPagesWidgetState {
  constructor(private subscriptionService: SubscriptionService, private pageService: PageService) {
  }

  @Action(Load)
  init(context: StateContext<SuggestPagesWidgetsStateModel>, action: Load): Observable<void> {
    context.setState({
      ..._.omit(context.getState(), action.id)
    });
    this.setLoading(context, action.id, true);
    return this.getPages(action.settings)
      .pipe(
        map(pages => pages.sort((a, b) => this.compareInsensitive(a.name, b.name))),
        map(pages => this.patchPagesIntoContext(context, action.id, pages)),
        tap(() => this.setLoading(context, action.id, false))
      );
  }

  private compareInsensitive(a: string, b: string): number {
    return 'x'.localeCompare('X', undefined, {sensitivity: 'base'}) === 0 ?
      a.localeCompare(b, undefined, {sensitivity: 'accent'}) :
      a.toLowerCase().localeCompare(b.toLowerCase());
  }

  private setLoading(context: StateContext<SuggestPagesWidgetsStateModel>, id: string, loading: boolean): void {
    context.patchState({
      [id]: {
        ...context.getState()[id],
        loading
      }
    });
  }

  private patchPagesIntoContext(context: StateContext<SuggestPagesWidgetsStateModel>, id: string, pages: Page[]): void {
    context.patchState({
      [id]: {
        ...context.getState()[id],
        hasPages: !!pages && pages.length > 0,
        pages
      }
    });
  }

  private getPages(settings: SuggestPagesWidgetSettings): Observable<Page[]> {
    if (settings._pageIds && settings._pageIds.length > 0) {
      return this.subscriptionService.getSubscriptionsByType('page')
        .pipe(
          map(subs => _.difference(settings._pageIds, subs.map(sub => sub.senderId))),
          switchMap(ids => this.pageService.getBulk(ids)),
          share()
        );
    }
    return of([]);
  }
}

import {AppConfig, AppState} from '@apps/api/app-config';
import {AppRegistryService} from '@apps/api/app-registry.service';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {AppService} from '@domain/apps/app.service';
import {Transition} from '@uirouter/core';
import * as _ from 'lodash';

/**
 * Static helper class for generating multiple states from state templates for apps. Generates each
 * state for each sender type
 */
// tslint:disable:no-console
export class AppStateGenerator {

  /**
   * Builds states for each sender type for an app
   * @param appConfig The app configuration
   *
   * @returns An array of states
   */
  static buildStates<Settings extends AppSettings>(appConfig: AppConfig<Settings>): AppState<Settings>[] {
    this.assert(appConfig.key, 'Config property "key" is required', appConfig);
    this.assert(appConfig.name, 'Config property "name" is required', appConfig);
    this.assert(appConfig.description, 'Config property "description" is required', appConfig);
    this.assert(appConfig.states, 'Config property "states" is required', appConfig);
    this.assert(!appConfig.icon || _.startsWith(appConfig.icon, 'zmdi-'), 'Config property "icon" must be a Material Design Iconic Font class.', appConfig);

    appConfig.allowedInstances = appConfig.allowedInstances ?? 0;
    appConfig.allowedInstancesErrorMessage = appConfig.allowedInstancesErrorMessage ?? 'APPS.SETTINGS.NUMBEROFAPPS.ERROR';
    appConfig.icon = appConfig.icon ?? 'zmdi-globe';
    appConfig.defaultStateName = this.buildDefaultState(appConfig) ?? '';
    const states: any[] = [];
    AppRegistryService.APP_SENDER_TYPES.forEach(senderType => {
      const appRoot = 'main.' + senderType + '.show.apps.' + appConfig.key;
      appConfig.states.forEach(state => states.push(this.registerState(appConfig, appRoot, senderType, state)));
    });
    appConfig.states = states;
    return states;
  }

  private static assert(value: any, message: string, config?: any): void {
    if (!value) {
      if (config) {
        console.error('[appRegistry] Invalid config', config);
      }
      console.warn('[appRegistry] ' + message);
      throw new Error('[appRegistry] ' + message);
    }
  }

  private static buildDefaultState<Settings extends AppSettings>(appConfig: AppConfig<Settings>): string {
    let defaultState = _.filter(appConfig.states, state => state.default);
    this.assert(defaultState.length < 2, 'Config must not contain more than one default state.', appConfig);

    if (!defaultState.length) {
      defaultState = _.filter(appConfig.states, state => !state.name && !state.url);
    }
    this.assert(defaultState.length === 1,
      'Config must contain exactly one default state. A default state is either explicitly flagged as "default" or has an "name" and "url".'
      , appConfig);
    this.assert(!defaultState[0].abstract, 'Config must not contain an abstract default state.', appConfig);
    return defaultState[0].name;
  }

  private static registerState<Settings extends AppSettings>(
    appConfig: AppConfig<Settings>,
    appRoot: string,
    senderType: string,
    stateParam: AppState<Settings>):
    AppState<Settings> {
    const state: AppState<any> = _.cloneDeep(stateParam);
    const isRootState = !state.url && !state.name;
    if (isRootState) {
      state.url = '/' + appConfig.key + '/:appIdOrSlug';
    }

    state.name = this.buildAbsoluteState(appRoot, state);

    if (isRootState) {
      state.resolve = state.resolve ?? [];
      state.resolve.push({
        token: 'app',
        deps: [AppService, Transition],
        resolveFn: (appService: AppService, transition: Transition) =>
          appService.getAppForSender(transition.params().idOrSlug, transition.params().appIdOrSlug, ['*']).toPromise()
      });
    }

    if (state.views && _.isObject(state.views)) {
      this.transformViews(appRoot, senderType, state);
    }
    return state;
  }

  private static buildAbsoluteState(appRoot: string, state: any): string {
    return (state.name) ? appRoot + '.' + state.name : appRoot;
  }

  private static transformViews(appRoot: string, senderType: string, state: any): void {
    const keys = Object.keys(state.views);
    keys.forEach(viewName => {
      let newViewName = viewName;
      if (viewName.indexOf('@') > -1) {
        if (viewName.indexOf('$appRoot') > -1) {
          newViewName = _.replace(newViewName, '$appRoot', appRoot);
        }
        if (viewName.indexOf('$sender') > -1) {
          newViewName = _.replace(newViewName, '$sender', senderType);
        }
        if (viewName !== newViewName) {
          state.views[newViewName] = state.views[viewName];
          // tslint:disable-next-line:no-dynamic-delete
          delete state.views[viewName];
        }
      }
    });
  }
}

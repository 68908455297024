<div [formGroup]="parentForm">
  <coyo-aspect-ratio-chooser formControlName="_format"></coyo-aspect-ratio-chooser>

  <section class="slide-rotation">
    <label translate="WIDGET.TEASER.SETTINGS.ROTATION.LABEL"></label>
    <p translate="WIDGET.TEASER.SETTINGS.ROTATION.DESCRIPTION"></p>
    <div class="input-group">
      <input type="number" class="form-control" id="autoplayDelay" formControlName="_autoplayDelay" placeholder="1000">
      <span class="input-group-addon">{{'WIDGET.TEASER.SETTINGS.ROTATION.UNIT' | translate}}</span>
    </div>
  </section>

  <section class="slide-actions">
    <div class="action-description">
      <label translate="WIDGET.TEASER.SETTINGS.SLIDES.LABEL"></label>
      <div translate="WIDGET.TEASER.SETTINGS.SLIDES.DESCRIPTION"></div>
    </div>
    <div class="action-container">
      <button mat-flat-button (click)="addSlide()">
        <mat-icon svgIcon="plus"></mat-icon> {{'WIDGET.TEASER.SETTINGS.BUTTON.NEW' | translate}}</button>
    </div>
  </section>

  <section class="slide-list" cdkDropList (cdkDropListDropped)="dropped($event)">
    <div class="slide-boundary">
      <div class="slide-item" *ngFor="let slide of getSlides().controls; let $index = index;" cdkDrag cdkDragBoundary=".slide-boundary">
        <div class="flex-container">
          <mat-icon class="slide-drag-handle" svgIcon="drag-handle"></mat-icon>
          <small class="hidden-xs slide-index" >{{$index + 1}}</small>
          <img class="slide-img" [attr.src]="slide.value._image"/>
          <div class="slide-caption">
            <h5>{{slide.value.headline}}</h5>
          </div>
          <div class="slide-context-menu">
            <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="more-horizontal"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="editSlide($index)">
                <mat-icon svgIcon="pencil"></mat-icon>
                <span translate="WIDGET.TEASER.SETTINGS.CONTEXT.EDIT"></span>
              </button>
              <button mat-menu-item (click)="deleteSlide($index)">
                <mat-icon svgIcon="delete"></mat-icon>
                <span translate="WIDGET.TEASER.SETTINGS.CONTEXT.DELETE"></span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="drag-placeholder" *cdkDragPlaceholder>
        </div>
      </div>
    </div>
  </section>
</div>

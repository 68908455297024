import {Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ResizedService} from '@shared/resize-event/resized.service';
import {ResizeSensor} from 'css-element-queries';
import {ResizedEvent} from './resized-event';

/**
 * Directive for the resized events.
 */
@Directive({
  selector: '[coyoResized]'
})
export class ResizedDirective implements OnInit, OnDestroy {
  private oldWidth: number;
  private oldHeight: number;
  private resizeSensor: ResizeSensor;

  /**
   * Emits the resized event.
   */
  @Output()
  readonly coyoResized: EventEmitter<ResizedEvent> = new EventEmitter<ResizedEvent>();

  constructor(private element: ElementRef,
              private resizedService: ResizedService) {
  }

  ngOnInit(): void {
    this.resizeSensor = this.resizedService.getResizeSensor(this.element.nativeElement, () => this.onResized());
  }

  ngOnDestroy(): void {
    if (this.resizeSensor) {
      this.resizeSensor.detach();
    }
  }

  /**
   * Emits the resized event when the values change
   */
  private onResized(): void {
    const newWidth = this.element.nativeElement.clientWidth;
    const newHeight = this.element.nativeElement.clientHeight;

    if (newWidth === this.oldWidth && newHeight === this.oldHeight) {
      return;
    }

    const event = new ResizedEvent(
      this.element,
      newWidth,
      newHeight,
      this.oldWidth,
      this.oldHeight
    );

    this.oldWidth = this.element.nativeElement.clientWidth;
    this.oldHeight = this.element.nativeElement.clientHeight;

    this.coyoResized.emit(event);
  }
}

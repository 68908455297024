import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe that removes formatting signs from a phone number.
 */
@Pipe({
  name: 'cleanPhoneNumber'
})
export class CleanPhoneNumberPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value ? String(value)
      .replace(/\(\s*0\s*\)/g, '')
      .replace(/[().\s-/A-Za-z]/g, '') : value;
  }
}

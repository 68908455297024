<div class="soundwave">
</div>
<div class="text">
  {{ 'MODULE.PREVIEW_LIST.NON_PREVIEW_ATTACHMENTS.VOICEMESSAGE.SALUTATION' | translate : {name: currentUser.firstname} }}
</div>
<div class="text">
  {{ 'MODULE.PREVIEW_LIST.NON_PREVIEW_ATTACHMENTS.VOICEMESSAGE.DESCRIPTION' | translate }}
</div>
<div class="link" *ngFor="let platform of platforms">
  <mat-icon size="small" svgIcon="media-external-link"></mat-icon>
  <a [href]="'MODULE.PREVIEW_LIST.NON_PREVIEW_ATTACHMENTS.VOICEMESSAGE.LINK.' + platform | translate" target="_blank">
    {{ 'MODULE.PREVIEW_LIST.NON_PREVIEW_ATTACHMENTS.VOICEMESSAGE.LINK.DESCRIPTION.' + platform | translate }}
  </a>
</div>

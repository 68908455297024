(function (angular) {
  'use strict';

  WorkspaceMembersController.$inject = ["$state", "$scope", "authService", "userChooserModalService", "userChooserExternalModalService", "ExternalUserModel", "workspace"];
  angular
      .module('coyo.workspaces')
      .controller('WorkspaceMembersController', WorkspaceMembersController);

  function WorkspaceMembersController($state, $scope, authService, userChooserModalService,
                                      userChooserExternalModalService, ExternalUserModel, workspace) {
    var vm = this;
    var selfState = 'main.workspace.show.members.invited';

    vm.workspace = workspace;
    vm.$onInit = _init;

    vm.inviteMembers = inviteMembers;
    vm.inviteExternals = inviteExternals;
    vm.inviteAdmins = inviteAdmins;

    function inviteMembers() {
      userChooserModalService.open({}, {usersOnly: !vm.canInviteGroups}).then(function (selected) {
        workspace.inviteMembers(selected).then(function (membershipStatus) {

          $state.go(selfState, {
            idOrSlug: workspace.slug,
            showDirectlyAddedHint: _hasDirectlyAddedUsers(membershipStatus)
          }, {
            reload: selfState
          });
        });
      });
    }

    function inviteExternals() {
      userChooserExternalModalService.open(vm.workspace.id).then(function (selected) {
        ExternalUserModel.inviteExternals(workspace.id, selected).then(function (result) {
          $state.go(selfState, {
            idOrSlug: workspace.slug,
            showDirectlyAddedExternalsHint: _hasDirectlyAddedUsers(result.data)
          }, {
            reload: selfState
          });
        });
      });
    }

    function inviteAdmins() {
      userChooserModalService.open({}, {usersOnly: !vm.canInviteGroups, internalOnly: true}).then(function (selected) {
        workspace.inviteAdmins(selected).then(function (membershipStatus) {
          $state.go(selfState, {
            idOrSlug: workspace.slug,
            showDirectlyAddedHint: _hasDirectlyAddedUsers(membershipStatus)
          }, {
            reload: selfState
          });
        });
      });
    }

    function _hasDirectlyAddedUsers(membershipStatus) {
      return angular.isDefined(_.invert(membershipStatus).APPROVED);
    }

    function _init() {
      authService.onGlobalPermissions('PERMIT_GROUP_INVITES', function (canInviteGroups) {
        vm.canInviteGroups = canInviteGroups;
      }, true);
    }
  }

})(angular);

(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .directive('coyoImageOnload', imageOnload);

  /**
   * @ngdoc directive
   * @name commons.ui.imageOnload:imageOnload
   * @restrict 'A'
   * @element ANY that triggers native load event.
   *
   * @description
   * Executes a given handler as soon as the element is loaded.
   *
   * @param {function} onloadHandler the function to execute on load
   *
   */
  function imageOnload() {
    return {
      restrict: 'A',
      scope: {
        onloadHandler: '&coyoImageOnload'
      },
      link: function (scope, element) {
        element.on('load', function () {
          scope.$apply(function () {
            scope.onloadHandler();
          });
        });
      }
    };
  }

})(angular);

import {Directive, HostListener, Inject, Input} from '@angular/core';
import {WINDOW} from '@root/injection-tokens';
import {Ng1StateLockService} from '@root/typings';
import {NG1_STATE_LOCK_SERVICE} from '@upgrade/upgrade.module';

/**
 * Legacy directive for blog-articles/wikis/rte file links created with the old rte
 *
 * For more information see:
 * https://coyoapp.atlassian.net/browse/COYOFOUR-9926
 */
// tslint:disable: directive-selector
// tslint:disable: no-input-rename
@Directive({
  selector: '[coyo-download]'
})
export class DownloadDirective {

  /**
   * The download file url
   */
  @Input('coyo-download') coyoDownload: string = '';

  constructor(@Inject(WINDOW) private window: any,
              @Inject(NG1_STATE_LOCK_SERVICE) private stateLockService: Ng1StateLockService) { }

  /**
   * Listener for click events which downloads the given file and add a `handledByAngular` flag to the event
   *
   * @param $event The click event
   */
  @HostListener('click', ['$event'])
  onClick($event: Event): void {
    $event['handledByAngular'] = true;
    this.stateLockService.ignoreLock(() => {
      this.window.location = this.coyoDownload.replace(/\'/g, '');
      return Promise.resolve();
    });
  }
}

import {NgModule} from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CoyoLabItemComponent} from '@app/admin/apps-widgets/coyo-lab/coyo-lab-item/coyo-lab-item.component';
import {CoyoLabComponent} from '@app/admin/apps-widgets/coyo-lab/coyo-lab.component';
import {FormlyModule} from '@ngx-formly/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {MaterialModule} from '@shared/material/material.module';
import {TextModule} from '@shared/text/text.module';
import './coyo-lab/coyo-lab.component.downgrade';
import {PluginConfigFieldsPipe} from './plugin-config-fields/plugin-config-fields.pipe';
import {PluginDataPipe} from './plugin-data/plugin-data.pipe';
import {PluginUrlModalComponent} from './plugin-url-modal/plugin-url-modal.component';
import {WidgetListComponent} from './widget-list/widget-list.component';
import './widget-list/widget-list.component.downgrade';
import {WidgetPluginListComponent} from './widget-plugin-list/widget-plugin-list.component';
import './widget-plugin-list/widget-plugin-list.component.downgrade';

@NgModule({
  declarations: [
    CoyoLabComponent,
    CoyoLabItemComponent,
    WidgetPluginListComponent,
    WidgetListComponent,
    PluginUrlModalComponent,
    PluginDataPipe,
    PluginConfigFieldsPipe
  ],
  entryComponents: [
    CoyoLabComponent,
    WidgetPluginListComponent,
    WidgetListComponent,
    PluginUrlModalComponent
  ],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    MaterialModule,
    FormlyModule,
    TextModule,
    MatSlideToggleModule
  ]
})
export class AppsWidgetsModule {
}

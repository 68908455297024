import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FilterEntryComponent} from '@app/filter/filter-entry/filter-entry.component';
import {FilterSearchComponent} from '@app/filter/filter-search/filter-search.component';
import {TranslateModule} from '@ngx-translate/core';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {MaterialModule} from '@shared/material/material.module';
import {CategoryFilterComponent} from './category-filter/category-filter.component';
import './category-filter/category-filter.component.downgrade';
import {IsActivePipe} from './category-filter/is-active/is-active.pipe';
import {IsEditedPipe} from './category-filter/is-edited/is-edited.pipe';
import {ActionsMenuOverlayComponent} from './filter-box/actions-menu-overlay/actions-menu-overlay.component';
import {FilterBoxComponent} from './filter-box/filter-box.component';
import './filter-box/filter-box.component.downgrade';
import {FilterMenuOverlayComponent} from './filter-box/filter-menu-overlay/filter-menu-overlay.component';
import './filter-entry/filter-entry.component.downgrade';
import {FilterExpandComponent} from './filter-expand/filter-expand.component';
import './filter-expand/filter-expand.component.downgrade';
import './filter-search/filter-search.component.downgrade';
import {FilterComponent} from './filter/filter.component';
import './filter/filter.component.downgrade';
import {SelectionFilterComponent} from './selection-filter/selection-filter.component';
import './selection-filter/selection-filter.component.downgrade';

@NgModule({
  imports: [
    CommonModule,
    CoyoFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule
  ],
  exports: [
    CategoryFilterComponent,
    FilterBoxComponent,
    FilterComponent,
    FilterEntryComponent,
    FilterExpandComponent,
    FilterSearchComponent,
    SelectionFilterComponent
  ],
  declarations: [
    ActionsMenuOverlayComponent,
    CategoryFilterComponent,
    FilterBoxComponent,
    FilterComponent,
    FilterEntryComponent,
    FilterExpandComponent,
    FilterSearchComponent,
    IsActivePipe,
    IsEditedPipe,
    SelectionFilterComponent,
    FilterMenuOverlayComponent
  ]
})
export class FilterModule {
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {LaunchpadLink} from '@domain/launchpad/launchpad-link';

/**
 * A single link in the launchpad.
 */
@Component({
  selector: 'coyo-launchpad-link',
  templateUrl: './launchpad-link.component.html',
  styleUrls: ['./launchpad-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadLinkComponent {

  /**
   * The launchpad link.
   */
  @Input() link: LaunchpadLink;

  /**
   * The category color.
   */
  @Input() color: string;

  /**
   * Flag indicating if the user can manage this link.
   */
  @Input() canManage: boolean;

  /**
   * Event that is emitted when the link should be edited.
   */
  @Output() edit: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Event that is emitted when the link should be deleted.
   */
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  get href(): string {
    return !this.link.url.match(/^[a-zA-Z]+:\/\//)
      ? 'https://' + this.link.url
      : this.link.url;
  }
}

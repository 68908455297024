import {Pipe, PipeTransform} from '@angular/core';
import {SenderEvent} from '@domain/event/sender-event';
import * as moment from 'moment';

@Pipe({
  name: 'startsToday',
  pure: true
})
export class StartsTodayPipe implements PipeTransform {

  /**
   * Determines whether the given event starts today.
   *
   * @param event The event with a start date
   * @returns true if the given event starts today, else false
   */
  transform(event: SenderEvent): boolean {
    const now = moment();
    return now.isSame(moment(event.startDate), 'day');
  }

}

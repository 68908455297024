<div class="lab">
  <div>
    <h2>{{"ADMIN.APPS_WIDGETS.LAB.TITLE" | translate}}</h2>
    {{"ADMIN.APPS_WIDGETS.LAB.DESCRIPTION" | translate}}
  </div>
  <ui-alert color="true" level="info">
    {{"ADMIN.APPS_WIDGETS.LAB.DISCLAIMER" | translate}}
  </ui-alert>
  <ul *ngIf="(features$ | async) as features; else empty" class="lab-container">
    <li *ngFor="let feature of features">
      <coyo-lab-item (click)="toggle(feature)" [feature]="feature"></coyo-lab-item>
    </li>
  </ul>
  <ng-template #empty>
    <div class="empty">
      {{"ADMIN.APPS_WIDGETS.LAB.EMPTY" | translate}}
    </div>
  </ng-template>
</div>

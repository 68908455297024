import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FilePickerItem} from '@app/file-picker/file-picker-item';

@Component({
  selector: 'coyo-file-picker-icon',
  templateUrl: './file-picker-icon.component.html',
  styleUrls: ['./file-picker-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerIconComponent implements OnInit {

  /**
   * The item which should be the icon displayed for
   */
  @Input() item: FilePickerItem;

  svgIcon: string;

  constructor() {
  }

  ngOnInit(): void {
    this.svgIcon = this.item.customSvgIcon || this.getSvgIcon();
  }

  private getSvgIcon(): string {
    return this.item.isFolder
      ? this.getFolderSvgIcon()
      : this.getFileSvgIcon();
  }

  private getFolderSvgIcon(): string {
    return 'folder_mc';
  }

  private getFileSvgIcon(): string {
    return this.getFileSvgIconByMimeType()
      || this.getFileSvgIconByExtension()
      || 'generic-file';
  }

  private getFileSvgIconByMimeType(): string | null {
    return this.getKeyOfMatchingRegexPattern(this.item.mimeType, {
      video: 'video/.*',
      text: 'text/.*'
    });
  }

  private getFileSvgIconByExtension(): string | null {
    return this.getKeyOfMatchingRegexPattern(this.item.name, {
      pdf: '.*\.pdf',
      sketch: '.*\.sketch',
      pages: '.*\.pages',
      numbers: '.*\.numbers',
      keynote: '.*\.key',
      word_mc: '.*\.doc[x]?',
      excel_mc: '.*\.xls[x]?',
      powerpoint_mc: '.*\.pp[ts][x]?'
    });
  }

  private getKeyOfMatchingRegexPattern(value: string, keyWithRegexPattern: { [key: string]: string }): string | null {
    if (!value) {
      return null;
    }

    for (const [key, pattern] of Object.entries(keyWithRegexPattern)) {
      if (!!value.match(new RegExp(pattern, 'i'))) {
        return key;
      }
    }

    return null;
  }
}

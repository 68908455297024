import {Injectable} from '@angular/core';
import {SocketService} from '@core/socket/socket.service';
import {GSUITE, StorageType} from '@domain/attachment/storage';
import {CapabilitiesService} from '@domain/capability/capabilities/capabilities.service';
import {FileService} from '@domain/file/file/file.service';
import {FilePreviewStatus} from '@shared/preview/file-preview/file-preview-status';
import {combineLatest, merge, Observable, of} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviewStatusService {

  constructor(private capabilitiesService: CapabilitiesService,
              private fileService: FileService,
              private socketService: SocketService) {
  }

  /**
   * Checks if preview can be generated and listens to preview changed events retrieved by the websocket.
   *
   * @param filePreview the file preview
   * @param url the url
   * @param groupId the group id
   * @return current preview status for given file preview
   */
  getPreviewStatus$(filePreview: { storage?: StorageType, contentType?: string, previewUrl?: string, id: string },
                    url: string,
                    groupId: string): Observable<FilePreviewStatus> {
    return this.canGeneratePreview(filePreview)
      .pipe(
        flatMap(generatePreview => {
          if (generatePreview) {
            return this.getPreviewStatusChanged$(groupId, filePreview, url);
          } else {
            return of('CANNOT_PROCESS');
          }
        }),
        map(status => this.emitFilePreviewStatus(status)));
  }

  private getPreviewStatusChanged$(groupId: string,
                                   filePreview: { storage?: StorageType, previewUrl?: string, id: string },
                                   previewUrl: string): Observable<FilePreviewStatus> {
    const url = filePreview.previewUrl || previewUrl;
    return merge(
      this.socketService.listenTo$('/topic/public/preview.status', null, groupId + '.' + filePreview.id)
        .pipe(map(data => data.content.status)),
      this.fileService.getPreviewStatus(url, groupId, filePreview.id)
    );
  }

  private canGeneratePreview(filePreview: { storage?: StorageType, contentType?: string }): Observable<boolean> {
    return combineLatest([
      of(this.isGSuiteFile(filePreview)),
      this.capabilitiesService.imgAvailable(filePreview.contentType)])
      .pipe(map(([isGSuite, imgAvailable]) => !isGSuite && (imgAvailable || !filePreview.contentType)));
  }

  private isGSuiteFile(file: { storage?: StorageType }): boolean {
    return file.storage === GSUITE;
  }

  private emitFilePreviewStatus(status: string | any): FilePreviewStatus {
    if (typeof status === 'string') {
      return this.createStatusByString(status);
    } else {
      return status;
    }
  }

  private createStatusByString(status: string): FilePreviewStatus {
    switch (status) {
      case 'CANNOT_PROCESS':
        return {
          loading: false,
          conversionError: false,
          isProcessing: false,
          previewAvailable: false
        };
      case 'PROCESSING':
        return {
          loading: true,
          conversionError: false,
          isProcessing: true,
          previewAvailable: false
        };
      case 'SUCCESS':
        return {
          loading: false,
          conversionError: false,
          isProcessing: false,
          previewAvailable: true
        };
      case 'FAILED':
        return {
          loading: false,
          conversionError: true,
          isProcessing: false,
          previewAvailable: false
        };
      default:
        return {
          loading: false,
          conversionError: false,
          isProcessing: false,
          previewAvailable: false
        };
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {StickyExpiryPeriod} from '@app/timeline/timeline-form/sticky-btn/sticky-expiry-period';
import * as _ from 'lodash';

/**
 * An internal pipe to filter the available sticky options;
 */
@Pipe({
  name: 'stickyOptions'
})
export class StickyOptionsPipe implements PipeTransform {

  /**
   * Transforms the input options and returns a filtered list of options.
   *
   * @param options all available options
   * @param model the currently selected option
   * @return the filtered list of options
   */
  transform(options: StickyExpiryPeriod[], model: number | null): StickyExpiryPeriod[] {
    return model ? options : _.filter(options, 'expiry');
  }
}

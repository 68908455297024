import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {Page} from '@domain/page/page';
import {Observable, of} from 'rxjs';

/**
 * Service for all request methods to the sender domain.
 */
@Injectable({
  providedIn: 'root'
})
export class PageService extends DomainService<Page, Page> {

  constructor(@Inject(HttpClient) protected http: HttpClient,
              @Inject(UrlService) protected urlService: UrlService) {
    super(http, urlService);
  }

  getBulk(pageIds: string[]): Observable<Page[]> {
    if (!pageIds || pageIds.length < 1) {
      return of([]);
    }
    return this.http.get<Page[]>(`${this.getBaseUrl()}?${pageIds.map(id => `pageIds=${id}`).join('&')}`);
  }

  protected getBaseUrl(): string {
    return '/web/pages';
  }
}

<div class="facegame-widget" *ngIf="(state$ | async) as state">
  <span class="headline" [translate]="'WIDGET.FACEGAME.HEADLINE'"></span>
  <div class="menu" *ngIf="!state.isLoading && !state.isRunning">
    <div class="teaser" *ngIf="!state.isLoading && !state.isRunning && !state.isNoMoreColleagues">
      <span class="description">
        {{'WIDGET.FACEGAME.GAME_DESCRIPTION.ONE' | translate}}
      </span>
      <span class="description">
        {{'WIDGET.FACEGAME.GAME_DESCRIPTION.TWO' | translate}}
      </span>
      <coyo-facegame-avatar-teaser [users]="state.avatarTeaserUserIds"></coyo-facegame-avatar-teaser>
      <button mat-flat-button (click)="startGame()">
        {{'WIDGET.FACEGAME.START_GAME' | translate}}
      </button>
    </div>
    <div class="teaser" *ngIf="state.isNoMoreColleagues">
      <span class="no-more-answers">
        {{'WIDGET.FACEGAME.ERROR.NO_MORE_ANSWERS' | translate}}
      </span>
      <button mat-flat-button (click)="startGame()">
        {{'WIDGET.FACEGAME.RESTART_GAME' | translate}}
      </button>
    </div>
  </div>
  <!-- render skeleton when loading -->
  <div *ngIf="state.isLoading">
    <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
  </div>
  <!-- the actual game -->
  <div class="game" *ngIf="!state.isLoading && state.isRunning" #game>
    <div class="image">
      <img [src]="state.game.imageUrl" [ngClass]="{'is-active': state.game.currentAnswer !== undefined, 'is-correct': state.game.lastAnswerCorrect === true, 'is-wrong': state.game.lastAnswerCorrect === false}"/>
    </div>
    <div class="answer-container">
      <div class="answer" *ngFor="let option of [0, 1, 2, 3]" >
        <button mat-flat-button (click)="answer(option)" [ngClass]="{'is-active': state.game.currentAnswer == option}">
          {{state.game.answers[option].displayName}}
        </button>
      </div>
    </div>
    <div class="score" [ngClass]="{'is-correct': state.game.lastAnswerCorrect === true, 'is-wrong': state.game.lastAnswerCorrect === false}">
      {{state.game.points}} {{'WIDGET.FACEGAME.POINTS' | translate}}
    </div>
    <div class="progress-container">
      <div class="progress">
        <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="state.game.percentage"
             [ngClass]="{'is-active': state.game.currentAnswer !== undefined, 'is-correct': state.game.lastAnswerCorrect === true, 'is-wrong': state.game.lastAnswerCorrect === false}">
        </div>
      </div>
    </div>
  </div>
  <!-- show highscores when game is not running -->
  <div class="ranking" *ngIf="!state.isLoading && !state.isRunning">
    <div class="headline">
      <i class="zmdi zmdi-trending-up"></i>&nbsp;{{'WIDGET.FACEGAME.LEADERBOARD' | translate}}
    </div>
    <div class="entry" *ngFor="let entry of state.ranking; let i = index">
      <div class="avatar">
        <coyo-user-avatar [user]="entry.user" [avatarSize]="'xs'" [showOnlineStatus]="false" [noLink]="true" [allowModal]="true"></coyo-user-avatar>
      </div>
      <div class="name">
        {{ entry.user.displayName }}
      </div>
      <div class="points">
        {{ entry.points }} Pts.
      </div>
    </div>
    <span *ngIf="state.ranking && state.ranking.length === 0">
      {{'WIDGET.FACEGAME.NO_SCORES' | translate}}
    </span>
  </div>
</div>

import {NgModule} from '@angular/core';
import {LikeState} from '@domain/like/like.state';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DividerModule} from '@shared/divider/divider.module';
import {EmojiModule} from '@shared/emoji/emoji.module';
import {FileModule} from '@shared/files/file.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {JitTranslationModule} from '@shared/jit-translation/jit-translation.module';
import {MentionModule} from '@shared/mention/mention.module';
import {PreviewModule} from '@shared/preview/preview.module';
import {RibbonModule} from '@shared/ribbon/ribbon.module';
import {CommentContextMenuComponent} from '@shared/social/comments/comment-context-menu/comment-context-menu.component';
import {CommentFormComponent} from '@shared/social/comments/comment-form/comment-form.component';
import {CommentListComponent} from '@shared/social/comments/comment-list/comment-list.component';
import {CommentComponent} from '@shared/social/comments/comment/comment.component';
import {LikeButtonComponent} from '@shared/social/likes/like-button/like-button.component';
import {LikeContainerComponent} from '@shared/social/likes/like-container/like-container.component';
import {LikeInfoComponent} from '@shared/social/likes/like-info/like-info.component';
import {LikesModalComponent} from '@shared/social/likes/likes-modal/likes-modal.component';
import {ShareButtonComponent} from '@shared/social/shares/share-button/share-button.component';
import {ShareContainerComponent} from '@shared/social/shares/share-container/share-container.component';
import {ShareInfoComponent} from '@shared/social/shares/share-info/share-info.component';
import {ShareModalComponent} from '@shared/social/shares/share-modal/share-modal.component';
import {ShareNavBarButtonComponent} from '@shared/social/shares/share-nav-bar-button/share-nav-bar-button.component';
import {SharesModalComponent} from '@shared/social/shares/shares-modal/shares-modal.component';
import {TimelineShareComponent} from '@shared/social/timeline-share/timeline-share.component';
import {TextModule} from '@shared/text/text.module';
import {TimeModule} from '@shared/time/time.module';
import {AutosizeModule} from 'ngx-autosize';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {MomentModule} from 'ngx-moment';
import {SenderUIModule} from '../sender-ui/sender-ui.module';
import './comments/comment-list/comment-list.component.downgrade';
import './likes/like-container/like-container.component.downgrade';
import './shares/share-container/share-container.component.downgrade';
import './shares/share-nav-bar-button/share-nav-bar-button.component.downgrade';
import {SubscribeButtonComponent} from './subscribe-button/subscribe-button.component';
import './subscribe-button/subscribe-button.component.downgrade';

export const ngxsModule = NgxsModule.forFeature([LikeState]);

/**
 * Module that contains components and services for social actions, e.g. like,
 * share, subscribe, etc.
 */
@NgModule({
  imports: [
    AutosizeModule,
    BsDropdownModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    DividerModule,
    EmojiModule,
    FileModule,
    HelpModule,
    TextModule,
    MentionModule,
    MomentModule,
    NgSelectModule,
    PreviewModule,
    RibbonModule,
    SenderUIModule,
    TimeModule,
    JitTranslationModule,
    ngxsModule
  ],
  declarations: [
    CommentComponent,
    CommentContextMenuComponent,
    CommentFormComponent,
    CommentListComponent,
    LikeButtonComponent,
    LikeContainerComponent,
    LikeInfoComponent,
    LikesModalComponent,
    ShareButtonComponent,
    ShareContainerComponent,
    ShareInfoComponent,
    ShareModalComponent,
    ShareNavBarButtonComponent,
    SharesModalComponent,
    SubscribeButtonComponent,
    TimelineShareComponent
  ],
  exports: [
    CommentListComponent,
    LikeButtonComponent,
    LikeContainerComponent,
    LikeInfoComponent,
    ShareButtonComponent,
    ShareContainerComponent,
    ShareInfoComponent,
    ShareNavBarButtonComponent,
    SubscribeButtonComponent,
    TimelineShareComponent
  ],
  entryComponents: [
    CommentContextMenuComponent,
    CommentListComponent,
    LikeContainerComponent,
    LikesModalComponent,
    ShareContainerComponent,
    ShareModalComponent,
    ShareNavBarButtonComponent,
    SharesModalComponent,
    SubscribeButtonComponent
  ]
})
export class SocialModule {}

(function (angular) {
  'use strict';

  socketService.$inject = ["$log", "$q", "$rootScope", "$timeout", "SockJS", "Stomp", "authService", "backendUrlService", "coyoEndpoints", "csrfService", "socketConfig", "socketReconnectDelays", "utilService", "$localStorage"];
  angular
      .module('commons.sockets')
      .factory('socketService', socketService);

  /**
   * @ngdoc service
   * @name commons.sockets.socketService
   *
   * @description Service to establishes and manages the WebSocket connection of the current user.
   *
   * @requires $log
   * @requires $q
   * @requires $rootScope
   * @requires $timeout
   * @requires SockJS
   * @requires Stomp
   * @requires authService
   * @requires backendUrlService
   * @requires coyoEndpoints
   * @requires csrfService
   * @requires socketConfig
   * @requires socketReconnectDelays
   * @requires utilService
   * @requires $localStorage
   */
  function socketService($log, $q, $rootScope, $timeout, SockJS, Stomp, authService, backendUrlService, coyoEndpoints,
                         csrfService, socketConfig, socketReconnectDelays, utilService, $localStorage) {
    var SILENT_RECONNECTS = 1 + 1;       // +1 because first retry happens immediately
    var ERROR_RESET_TIMEOUT = 30 * 1000; // long lasting connection time after that error count is reset
    var client;                          // the underlying stomp client
    var subscriptions = [];              // all registered stomp subscriptions
    var pendingRequests = [];
    var reconnectToken;
    var headers = null;                  // the response headers of the last successful connection attempt
    var connecting = false;              // the state of the current connection attempt
    var reconnectionTimeout = null;      // the current reconnection $timeout
    var reconnectionAttempts = 0;        // the current number of reconnection attempts
    var errorCount = 0;                  // the current number of websocket errors
    var errorReset;                      // timeout that resets the error count on a longer lasting connection

    // register auto-connect
    if (socketConfig.autoConnect) {
      connect();
    }

    // register auth / logout listeners
    $rootScope.$on('authService:login:success', connect);
    $rootScope.$on('authService:logout:success', disconnect);
    $rootScope.$on('authService:logout:failed', disconnect);

    return {
      isConnected: isConnected,
      connect: connect,
      reconnect: reconnect,
      disconnect: disconnect,
      subscribe: subscribe,
      receiveFrom: receiveFrom,
      webSocketOffline$: $rootScope.$eventToObservable('socketService:offline'),
      webSocketDisconnected$: $rootScope.$eventToObservable('socketService:disconnected'),
      webSocketReconnected$: $rootScope.$eventToObservable('socketService:reconnected')
    };

    // ------------------------------------------------------------------------

    /**
     * @ngdoc function
     * @name commons.sockets.socketService#isConnected
     * @methodOf commons.sockets.socketService
     *
     * @description
     * Checks the WebSocket connection.
     *
     * @return {boolean} True if the WebSocket is connected, false otherwise.
     */
    function isConnected() {
      return !!client && client.connected;
    }

    /**
     * @ngdoc function
     * @name commons.sockets.socketService#connect
     * @methodOf commons.sockets.socketService
     *
     * @description
     * Connects to the WebSocket.
     *
     * @return {object} A promise that resolves when the WebSocket is connected.
     */
    function connect() {
      var deferred = $q.defer();
      pendingRequests.push(deferred);
      _abortReconnect();
      _connect(false);
      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name commons.sockets.socketService#reconnect
     * @methodOf commons.sockets.socketService
     *
     * @description
     * Attempt to reconnect to the WebSocket after being disconnected.
     *
     * @return {object} A promise that resolves when the WebSocket is reconnected.
     */
    function reconnect() {
      var deferred = $q.defer();
      pendingRequests.push(deferred);
      _abortReconnect();
      _connect(true);
      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name commons.sockets.socketService#disconnect
     * @methodOf commons.sockets.socketService
     *
     * @description
     * Disconnects to the WebSocket.
     */
    function disconnect() {
      _abortReconnect();
      if (isConnected()) {
        client.disconnect();
      }
      client = null;
    }

    /**
     * @ngdoc function
     * @name commons.sockets.socketService#subscribe
     * @methodOf commons.sockets.socketService
     *
     * @description
     * Subscribes to the given destination and executes the given callback for every incoming message. Subscriptions are
     * persistent meaning that they are a) postponed until the WebSocket connection has been fully established and b)
     * reattached when the WebSocket connection is recovered after a loss of connection. It is therefore necessary to
     * unsubscribe when the subscription is not needed any longer. Subscribing to the same destination more than once
     * will only create a single shared subscription that delegates events to all subscribers.
     *
     * @param {string} destination A RabbitMQ topic exchange routing key (e.g. 'user.*' or 'timeline.sender.*' or '#').
     * @param {function} callback The callback to be executed with the event body.
     * @param {string|function|object=} eventFilter A eventFilter applied to every incoming message. Can be either a function that must TODO
     * @param {string} selectorValue TODO
     * @param {string} jwtToken  JWT from BackEnd for permissions-checks
     * return a boolean or an object whose properties are matched against the event object.
     *
     * @return {function} A function to terminate the subscription.
     */
    function subscribe(destination, callback, eventFilter, selectorValue, jwtToken) {
      if (angular.isString(eventFilter)) {
        eventFilter = {event: eventFilter};
      }
      var subscriberId = utilService.uuid();
      var subscription = _.find(subscriptions, {destination: destination, selector: selectorValue});
      var subscriber = {callback: callback, filter: eventFilter, id: subscriberId, destination: destination};
      if (subscription) {
        $log.debug('[socketService] Reusing existing subscription to ' + subscription.destination);
        subscription.subscribers.push(subscriber);
      } else {
        subscription = {
          destination: destination,
          callback: function (event) {
            $log.debug('[socketService] Incoming message', destination, event);
            this.subscribers.forEach(function (sub) {
              if (angular.isDefined(sub.filter)) {
                if (angular.isUndefined(_.find([event], sub.filter))) {
                  return;
                }
              }
              sub.callback(event);
            });
          },
          subscribers: [subscriber],
          selector: selectorValue,
          state: null,
          subscriptionToken: jwtToken
        };
        subscriptions.push(subscription);
        _subscribe(subscription);
      }

      return function () {
        _.remove(subscription.subscribers, function (sub) {
          return sub.id === subscriberId;
        });
        if (subscription.subscribers.length === 0) {
          if (subscription && subscription.state) {
            $log.debug('[socketService] Unsubscribing from ' + subscription.destination);
            var headers = {
              id: subscription.state.id
            };
            if (subscription.selector && subscription.subscriptionToken) {
              headers.simpDestination = subscription.destination;
              headers.selector = _buildSelectorHeader(subscription);
            }
            if (client) {
              client._transmit('UNSUBSCRIBE', headers);
            }
          }
          subscriptions.splice(_.indexOf(subscriptions, subscription), 1);
        }
      };
    }

    /**
     * @ngdoc function
     * @name commons.sockets.socketService#receiveFrom
     * @methodOf commons.sockets.socketService
     *
     * @description
     * STOMP supports receiving a single message from the socket server. Actually, this works through subscribing to
     * given destination once. The server automatically takes care of closing the subscription.
     *
     * This method is just an alias for subscribe(...).
     *
     * @param {string} destination A RabbitMQ topic exchange routing key (e.g. 'user.*' or 'timeline.sender.*' or '#')
     *
     * @returns {promise} Promise resolved with body of the message
     */
    function receiveFrom(destination) {
      return connect(false).then(function () {
        var c = client;
        var deferred = $q.defer();
        var subscription = client.subscribe(destination, function (message) {
          delete c.subscriptions[subscription.id];
          deferred.resolve(message.body ? angular.fromJson(message.body) : undefined);
        });
        return deferred.promise;
      }).catch(function (error) {
        $log.warn('[socketService] Could not receive from ' + destination + '.', error);
        return $q.reject(error);
      });
    }

    // ------------------------------------------------------------------------

    function _connect(isReconnect) {
      $log.debug('[socketService] Connecting...');

      if (connecting) {
        $log.debug('[socketService] Already connecting...');
        return;
      } else if (isConnected()) {
        $log.debug('[socketService] Already connected.', headers);
        _resolve(headers);
        return;
      } else if (!authService.isAuthenticated()) {
        $log.warn('[socketService] Connection failed for good: Not authenticated.');
        $rootScope.$emit('socketService:offline', null);
        _abortReconnect();
        _reject(null);
        return;
      }

      $rootScope.$emit('socketService:connecting');
      connecting = true;
      csrfService.getToken().then(function (token) {
        var url = backendUrlService.getUrl() + coyoEndpoints.socket.replace('{token}', token);
        var ws = new SockJS(url, null, {timeout: 30000});
        client = Stomp.over(ws);
        client.debug = false;
        client.heartbeat.outgoing = socketConfig.heartbeat;
        client.heartbeat.incoming = socketConfig.heartbeat;
        var connectHeaders = {
          login: '',
          passcode: '',
          'X-CSRF-TOKEN': token,
          'X-Coyo-Client-ID': $localStorage.clientId
        };

        var currentUserId = authService.getCurrentUserId();
        if (currentUserId !== null) {
          connectHeaders['X-Coyo-Current-User'] = currentUserId;
        } else {
          throw new Error('Missing user id in local storage for socket connect!');
        }
        client.connect(connectHeaders, function (response) {
          _onConnect(response, isReconnect);
        }, _onError);
      }).catch(function (error) {
        $log.warn('[socketService] Connection failed for good: No CSRF token.');
        $rootScope.$emit('socketService:offline', error);
        connecting = false;
        _abortReconnect();
        _reject(error);
      });
    }

    function _onConnect(response, isReconnect) {
      if (response.body === 'TOO_MANY_REQUESTS') {
        reconnectionAttempts += SILENT_RECONNECTS; // force reconnect notification immediately
        client.disconnect();
        client = null;
        $timeout(function () {
          _onError(response.body);
        });
        return;
      }
      headers = response.headers;
      authService.validateUserId(headers['user-name'], 'from WebSocket:Connected');
      $log.info('[socketService] Connected.', headers);
      if (isReconnect) {
        $timeout(function () {
          $log.debug('[socketService] Websocket reconnected successfully');
          $rootScope.$emit('socketService:reconnected');
        }, 2000); // small delay on reconnect to avoid initial connection issue when updating data
      } else {
        $rootScope.$emit('socketService:connected');
      }
      connecting = false;
      _abortReconnect();
      angular.forEach(subscriptions, function (subscription) {
        _subscribe(subscription, isReconnect);
      });
      reconnectToken = response.body;
      _resolve(headers);
    }

    function _onError(error) {
      $timeout.cancel(errorReset);
      if (isConnected()) {
        $log.error('[socketService] Websocket error no. ' + errorCount + ': ', error);
        disconnect();
        errorCount++;
        if (errorCount > 5) {
          return;
        }
      }

      errorReset = $timeout(function () {
        errorCount = 0;
        $log.info('[socketService] Connection lasted longer than threshold reseting error count ' + ERROR_RESET_TIMEOUT);
      }, ERROR_RESET_TIMEOUT);

      $rootScope.$emit('socketService:disconnected', reconnectionAttempts >= SILENT_RECONNECTS);
      connecting = false;

      var sleep = reconnectionAttempts === 0 ? 0 : socketReconnectDelays.GENERAL_RECONNECT_DELAY;
      $log.debug('[socketService] Connection failed, reconnecting in ' + sleep + 'ms.');
      reconnectionTimeout = $timeout(function () {
        reconnectionAttempts++;
        _connect(true);
      }, sleep, false);
    }

    function _resolve(headers) {
      _.forEach(pendingRequests, function (request) {
        request.resolve(headers);
      });
      pendingRequests = [];
    }

    function _reject(error) {
      _.forEach(pendingRequests, function (request) {
        request.reject(error);
      });
      pendingRequests = [];
    }

    function _abortReconnect() {
      reconnectionAttempts = 0;
      if (reconnectionTimeout !== null) {
        $timeout.cancel(reconnectionTimeout);
        reconnectionTimeout = null;
      }
    }

    function _subscribe(subscription, isReconnect) {
      if (isConnected()) {
        $log.debug('[socketService] Subscribing to ' + subscription.destination);
        var headers = {};
        if (subscription.selector) {
          headers.selector = _buildSelectorHeader(subscription);
        }
        if (subscription.subscriptionToken) {
          headers.subscriptionToken = isReconnect ? reconnectToken : subscription.subscriptionToken;
        }
        subscription.state = client.subscribe(subscription.destination, function (message) {
          return subscription.callback(message.body ? angular.fromJson(message.body) : undefined);
        }, headers);
      }
    }

    function _buildSelectorHeader(subscription) {
      return 'headers.toSubscribe==\'' + subscription.selector + '\'';
    }
  }

})(angular);

<button type="button" mat-icon-button [matMenuTriggerFor]="menu" size="small"
        *ngIf="canShowMenu" (menuOpened)="onOpen()"
        [attr.aria-label]="'CONTEXT_MENU.SHOW' | translate" data-test="comment-context-menu">
  <mat-icon svgIcon="more-horizontal"></mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <button type="button" mat-menu-item (click)="onShowOriginalAuthor()" *ngIf="canShowOriginalAuthor">
    <mat-icon svgIcon="user"></mat-icon> {{ 'MODULE.TIMELINE.ORIGINAL_AUTHOR.' + (!showOriginalAuthor ? '' : 'UN') + 'REVEAL' | translate }}
  </button>
  <button type="button" mat-menu-item (click)="edit.emit()" *ngIf="canShowEdit" data-test="edit-comment-button">
    <mat-icon svgIcon="pencil"></mat-icon> {{ 'EDIT' | translate}}
  </button>
  <button type="button" mat-menu-item (click)="delete.emit()" *ngIf="canShowDelete" data-test="delete-comment-button">
    <mat-icon svgIcon="delete"></mat-icon> {{ 'DELETE' | translate}}
  </button>
  <button type="button" mat-menu-item disabled *ngIf="loading$ | async">
    {{ 'LOADING' | translate}}
  </button>
  <button type="button" mat-menu-item disabled *ngIf="!(loading$ | async) && !canShowMenuOptions">
    {{ 'NO_ACTIONS' | translate}}
  </button>
</mat-menu>

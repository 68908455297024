import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerSearch} from '@app/file-picker/file-picker-search/file-picker-search';
import {FileSearchFilePickerItem} from '@app/file-picker/file-picker-search/file-search-file-picker-item';
import {TranslateService} from '@ngx-translate/core';
import {UuidService} from '@shared/uuid/uuid.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'coyo-file-picker-search',
  templateUrl: './file-picker-search.component.html',
  styleUrls: ['./file-picker-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerSearchComponent implements OnInit {

  /**
   * The search that should be executed.
   */
  @Input() search: FilePickerSearch;

  /**
   * Emits the search results as a FilePickerItem folder. Emits null, if the search term is empty
   */
  @Output() searchResult: EventEmitter<FilePickerItem | null> = new EventEmitter<FilePickerItem | null>();

  fileSearchInput: FormControl = new FormControl();

  constructor(
    private translate: TranslateService,
    private readonly uuidService: UuidService) {
  }

  ngOnInit(): void {
    this.fileSearchInput.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(query => this.executeSearch(query));
  }

  private executeSearch(query: string): void {
    if (!query) {
      this.searchResult.emit(null);
      return;
    }
    const searchFolder = new FileSearchFilePickerItem(
      this.uuidService.getUuid(),
      this.translate.instant('FILEPICKER.SEARCH_RESULTS.TITLE'),
      () => this.search.execute(query));
    this.searchResult.emit(searchFolder);
  }
}

(function () {
  'use strict';

  angular
      .module('commons.optimistic')
      .constant('optimisticStatus', {
        NEW: 'NEW',
        PENDING: 'PENDING',
        ERROR: 'ERROR'
      });

})();

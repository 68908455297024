import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValidationErrors} from '@angular/forms';

/**
 * Displays form error messages.
 */
@Component({
  selector: 'coyo-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ValidationErrorsComponent {

  /**
   * The validation errors.
   */
  @Input() errors: ValidationErrors | null;

  /**
   * Custom error messages.
   */
  @Input() messages?: { [key: string]: string; };

  constructor() {
  }
}

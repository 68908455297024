(function (angular) {
  'use strict';

  angular
      .module('coyo.apps.list')
      .controller('ListFrameController', ListFrameController);

  /**
   * The main list controller
   *
   * @constructor
   */
  function ListFrameController() {
    //var vm = this;
  }

})(angular);

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {EmojiModule} from '@shared/emoji/emoji.module';
import {FileModule} from '@shared/files/file.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {AutosizeModule} from 'ngx-autosize';
import {MessageDeletedComponent} from './message-deleted/message-deleted.component';
import './message-deleted/message-deleted.component.downgrade';
import {MessageFormComponent} from './message-form/message-form.component';
import './message-form/message-form.component.downgrade';

/**
 * Module containing all messaging components
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    EmojiModule,
    FileModule,
    AutosizeModule
  ],
  declarations: [MessageFormComponent, MessageDeletedComponent],
})
export class MessagingModule {
}

(function (angular) {
  'use strict';

  CsvSettingsController.$inject = ["$state", "$stateParams", "modalService", "UserDirectoryModel"];
  angular
      .module('coyo.admin.userDirectories.ldap')
      .component('oyocCsvSettings', csvSettings())
      .controller('CsvSettingsController', CsvSettingsController);

  /**
   * @ngdoc directive
   * @name coyo.admin.userDirectories.ldap.oyocCsvSettings:oyocCsvSettings
   * @restrict E
   * @scope
   *
   * @description
   * Renders a form for CSV settings.
   *
   * @param {object} ngModel The settings of the CSV import.
   * @param {object} syncJob The synchronization job that is associated with the directory.
   */
  function csvSettings() {
    return {
      require: 'ngModel',
      templateUrl: 'app/modules/admin/user-directories/ldap/csv-settings/csv-settings.html',
      controller: 'CsvSettingsController',
      bindings: {
        form: '<',
        ngModel: '=',
        validationErrors: '<'
      }
    };
  }

  function CsvSettingsController($state, $stateParams, modalService, UserDirectoryModel) {

    var vm = this;
    vm.$onInit = _onInit;

    vm.openHelp = openHelp;
    vm.openSyncJob = openSyncJob;

    function openHelp() {
      return modalService.open({
        templateUrl: 'app/modules/admin/user-directories/ldap/csv-settings/csv-settings.fileupload-help-modal.html',
        size: 'lg'
      }).result;
    }

    /**
     * Opens the sync job modal.
     */
    function openSyncJob() {
      function go() {
        $state.go('admin.jobs.details', {
          name: vm.ngModel.syncJob.name,
          returnState: 'admin.user-directories.edit',
          returnStateOpts: {
            id: $stateParams.id
          }
        });
      }

      if (vm.form.$dirty) {
        modalService.confirm({
          title: 'ADMIN.USER_DIRECTORIES.LDAP.SYNC.LABEL.JUMP_TO_JOB.CONFIRM.TITLE',
          text: 'ADMIN.USER_DIRECTORIES.LDAP.SYNC.LABEL.JUMP_TO_JOB.CONFIRM.TEXT'
        }).result.then(go);
      } else {
        go();
      }
    }

    /**
     * Initializes the controller.
     *
     * @private
     */
    function _onInit() {
      vm.availableOrphanedUsersPolicies = [];
      UserDirectoryModel.getOrphanPolicies().then(function (policies) {
        vm.availableOrphanedUsersPolicies = policies;
      });
      if (!vm.ngModel.settings) {
        vm.ngModel.settings = {
          userId: 'UNUSED_FIELD',
          uusername: 'UNUSED_FIELD',
          userFirstName: 'UNUSED_FIELD',
          userLastName: 'UNUSED_FIELD'
        };
      }
    }
  }

})(angular);

(function (angular) {
  'use strict';

  GameModel.$inject = ["appResourceFactory", "Page"];
  angular
      .module('coyo.apps.championship')
      .factory('GameModel', GameModel);

  /**
   * @ngdoc service
   * @name coyo.apps.championship.GameModel
   *
   * @description
   * Domain model representing the game endpoint.
   *
   * @requires appResourceFactory
   */
  function GameModel(appResourceFactory, Page) {
    var Game = appResourceFactory({
      appKey: 'championship',
      url: '/games/{{id}}',
      name: 'championship'
    });

    // instance members
    angular.extend(Game.prototype, {

      /**
       * @ngdoc function
       * @name coyo.apps.championship.GameModel#submitGuess
       * @methodOf coyo.apps.championship.GameModel
       *
       * @description
       * Submits a guess for a game
       *
       * @returns {promise} An $http promise
       */
      submitGuess: function (homeScore, awayScore) {
        return Game.$put(this.$url('action/guess'), {
          homeScore: homeScore,
          awayScore: awayScore
        });
      }
    });

    // class members
    angular.extend(Game, {

      /**
       * @ngdoc function
       * @name coyo.apps.championship.GameModel#getGuessesForUser
       * @methodOf coyo.apps.championship.GameModel
       *
       * @description
       * Returns a page of past guesses of a user
       *
       * @returns {promise} An $http promise
       */
      getGuessesForUser: function (app, userId, pageable) {
        var params = pageable.getParams();
        var url = this.$url({
          senderId: app.senderId,
          appId: app.id
        }, '/ranking/user/' + userId);
        return this.$get(url, params).then(function (response) {
          return new Page(response, params, {url: url});
        });
      }
    });

    return Game;
  }

})(angular);

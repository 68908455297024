import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {UIRouter} from '@uirouter/angular';
import {WidgetModal} from '../widget-modal';

/**
 * A modal dialog to configure widgets.
 *
 * This component should not be used directly to show a widget configuration dialog. The
 * {@link WidgetSettingsModalService#open} provides the canonical way to configure widgets.
 */
@Component({
  selector: 'coyo-widget-settings-modal',
  templateUrl: './widget-settings-modal.component.html',
  styleUrls: ['./widget-settings-modal.component.scss']
})
export class WidgetSettingsModalComponent extends WidgetModal implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WidgetSettingsModalComponent>,
    private translateService: TranslateService,
    uiRouter: UIRouter,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialogRef, uiRouter);
    this.widget = data.widget;
    this.config = data.config;
  }

  ngOnInit(): void {
    const titleKey = `WIDGETS.SETTINGS.${this.widget.key.toUpperCase()}.TITLE`;
    const translatedTitle = this.translateService.instant(titleKey);
    this.title = translatedTitle !== titleKey ? translatedTitle : this.config.name;
  }
}

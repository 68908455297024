import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {SubscriptionService} from '@domain/subscription/subscription.service';
import {User} from '@domain/user/user';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {first, map, mergeMap, shareReplay} from 'rxjs/operators';

/**
 * user-follow component
 */
@Component({
  selector: 'coyo-user-follow',
  templateUrl: './user-follow.component.html',
  styleUrls: ['./user-follow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFollowComponent implements OnInit {

  /**
   * the current user
   */
  @Input() user: User;

  /**
   * size input that effects the size of the follow button
   */
  @Input() size: string;

  /**
   * set to 'true' to not display label
   */
  @Input() noLabel: boolean;

  /**
   * set to 'true' to apply dark theme
   */
  @Input() darkTheme: boolean;

  /**
   * The text for "follow"
   */
  @Input() textFollow: string = 'USER.CLICK_TO_FOLLOW';

  /**
   * The text for when user is following target
   */
  @Input() textFollowing: string = 'USER.FOLLOWING';

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  following$: Observable<boolean>;
  show$: Observable<boolean>;
  state$: Observable<{
    show: boolean;
    following: boolean;
  }>;
  currentUser: User;

  constructor(private authService: AuthService,
              private subscriptionService: SubscriptionService) { }

  /**
   * Follows the user
   * @param event the click event necessary to toggle the Follow User
   * @returns false to prevent default behavior
   */
  toggle(event: Event): boolean {
    if (this.loading$.getValue()) {
      return;
    }
    this.loading$.next(true);

    this.following$.pipe(first(), mergeMap(following => {
      if (!following) {
        return this.subscriptionService.subscribe(this.user.id, this.user.id, 'user').pipe(map(() => null));
      } else {
        return this.subscriptionService.unsubscribe(this.user.id);
      }
    })).subscribe(subscription => {
      this.loading$.next(false);
    }, () => {
      this.loading$.next(false);
    });

    event.stopPropagation();
    return false;
  }

  ngOnInit(): void {
    this.show$ = this.authService.getUser().pipe(map(user => user.id !== this.user.id));
    this.following$ = this.authService.getUser().pipe(mergeMap(user => {
      this.currentUser = user;
      this.loading$.next(true);
      return this.subscriptionService.onSubscriptionChange$(this.user.id).pipe(map(subscription => {
        this.loading$.next(false);
        return subscription !== undefined;
      }));
    }), shareReplay({bufferSize: 1, refCount: true}));
    this.state$ = combineLatest([this.show$, this.following$])
      .pipe(map(([show, following]: [boolean, boolean]) => ({show, following})));
  }
}

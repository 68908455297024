import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SenderEvent} from '@domain/event/sender-event';
import {TargetService} from '@domain/sender/target/target.service';
import {SenderAvatarComponent} from '@shared/sender-ui/sender-avatar/sender-avatar.component';

/**
 * Component showing the event avatar
 */
@Component({
  selector: 'coyo-event-avatar',
  templateUrl: './event-avatar.component.html',
  styleUrls: ['./event-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventAvatarComponent extends SenderAvatarComponent {

  /**
   * The event
   */
  @Input() sender: SenderEvent;

  /**
   * Flag for showing the date overlay
   */
  @Input() showOverlay: boolean;

  constructor(targetService: TargetService) {
    super(targetService);
  }
}


export class Load {
  static readonly type: string = '[Interesting Colleagues] load';

  constructor(public id: string) {}
}

export class Reset {
  static readonly type: string = '[Interesting Colleagues] destroy';
  constructor(public id: string) {}
}

import { Injectable } from '@angular/core';

/**
 * Service to detect device properties
 */
@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {

  private iOSPlatforms: string[] = ['ipad simulator', 'iphone simulator', 'ipod simulator', 'ipad', 'iphone', 'ipod'];

  /**
   * Checks if the device is considered to run on iOS dependent on the window.navigator object
   * @returns boolean representing if client is iOS
   */
  isIOS(): boolean {
    try {
      return this.iOSPlatforms.includes(navigator.platform.toLowerCase()) || this.isIPadOnIOS13();
    } catch {
      return false;
    }
  }

  private isIPadOnIOS13(): boolean {
    // In iOS 13 'platform' for iPad isn't 'iPad' anymore, instead it's 'MacIntel'
    return navigator.userAgent.includes('Mac') && navigator.maxTouchPoints > 0;
  }
}

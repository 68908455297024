import {STATE_NAME_ENGAGE} from '@app/engage/engage.state';
import {BlogArticleViewComponent} from '@app/engage/news/blog-article/blog-article-view.component';
import {NewsViewParams} from '@app/engage/news/news-view-params';
import {BlogArticle} from '@domain/blog-article/blog-article';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';
import {ParamType} from '@uirouter/core';

// Do not change the state names
export const STATE_NAME_ENGAGE_NEWS = STATE_NAME_ENGAGE + '.news';

resolveBlogArticle.$inject = ['BlogArticleModel', '$transition$'];

/**
 * Function for resolving the blog article for the news state.
 *
 * @param blogArticleModel the angularJs blog article model
 * @param $transition$ the transition service
 * @return The blog article
 */
export function resolveBlogArticle(blogArticleModel: any, $transition$: any): BlogArticle {
  return blogArticleModel.getWithPermissions({
    id: $transition$.params().id,
    senderId: $transition$.params().senderId,
    appId: $transition$.params().appId
  }, {preferredLanguage: $transition$.params().preferredLanguage}, ['like', 'comment']);
}

resolveParams.$inject = ['$transition$'];

/**
 * Function for resolving the state params.
 *
 * @param $transition$ the transition serivce
 * @return the view params
 */
export function resolveParams($transition$: any): NewsViewParams {
  return $transition$.params();
}

export const newsState: NgHybridStateDeclaration = {
  name: STATE_NAME_ENGAGE_NEWS,
  url: '/news/:senderId/:appId/:id',
  component: BlogArticleViewComponent,
  data: {
    authenticate: true
  },
  params: {
    senderId: {type: 'path'},
    appId: {type: 'path'},
    id: {type: 'path'},
    hideTeaserText: null as ParamType,
    hideHeadline: null as ParamType,
    hideTeaserImg: null as ParamType,
    preferredLanguage: null as ParamType,
    hideSocialFeatures: null as ParamType
  },
  resolve: {
    article: resolveBlogArticle,
    params: resolveParams
  }
};

<h3 class="widget-title">
  {{widget.settings._title || ("WIDGETS.UPCOMING.EVENTS.NAME" | translate)}}
</h3>

<ng-container *ngIf="events$ | async; let events; else loading">
  <div *ngFor="let event of events" class="event-card">
    <div class="panel-full-width event-cover-wrapper">
      <a [style.backgroundImage]="getCover(event)" [uiParams]="{idOrSlug: event.slug}"
         tabindex="-1"
         uiSref="main.event.show.information"></a>
      <div class="event-tag">
        <span class="event-tag-day">{{event.startDate  | amDateFormat : 'D'}}</span>
        <span class="event-tag-month">{{event.startDate  | amDateFormat : 'MMM'}}</span>
      </div>
    </div>
    <h5 class="event-title"><a [uiParams]="{idOrSlug: event.slug}" uiSref="main.event.show.information">{{event.name}}</a></h5>
    <div class="event-detail">
      <mat-icon size="small" svgIcon="calendar"></mat-icon>
      <span class="event-detail-date">{{event | eventDate}}</span>
    </div>
    <div *ngIf="!event.fullDay" class="event-detail">
      <mat-icon size="small" svgIcon="clock-filled"></mat-icon>
      <span class="event-detail-time">{{event | eventTime}}</span>
    </div>
    <div class="event-detail" *ngIf="event.place">
      <mat-icon svgIcon="location-pin" size="small"></mat-icon>
      <span class="event-detail-place">
        <coyo-event-location [place]="event.place" [useMultiline]="true"></coyo-event-location>
      </span>
    </div>
  </div>
  <p *ngIf="!events.length" class="empty" translate="WIDGETS.UPCOMING.EVENTS.NO.UPCOMING.EVENTS"></p>
</ng-container>

<ng-template #loading>
  <div class="panel-full-width">
    <ui-skeleton *ngFor="let i of skeletonCount$ | async | toArray" [skeletons]="skeletons"></ui-skeleton>
  </div>
</ng-template>

import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {RtePlugin} from '@shared/rte/rte-plugin';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';
import {VideoFromGSuitePlugin} from '@shared/rte/video-plugin/video-from-g-suite-plugin';
import {VideoFromUrlPlugin} from '@shared/rte/video-plugin/video-from-url-plugin';
import {VideoFromFileLibraryPlugin} from './video-from-file-library-plugin';

/**
 * A custom RTE dropdown plugin that bundles the active commands into a drop down for video selection.
 */
@Injectable()
export class VideoDropdownPlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertVideo';

  constructor(@Inject(FROALA_EDITOR) private froala: any,
              private translateService: TranslateService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    this.registerCommand(this.getDropdownOptions(this.froala,
      VideoFromUrlPlugin.KEY,
      VideoFromFileLibraryPlugin.KEY,
      VideoFromGSuitePlugin.KEY
    ));
  }

  private registerCommand(options: { [key: string]: string; }): void {
    const plugin = this;
    const keys = Object.keys(options);
    if (keys.length === 1) {
      // Push command to the toolbar
      Object.defineProperty(this.froala.COMMANDS, VideoDropdownPlugin.KEY,
        Object.getOwnPropertyDescriptor(this.froala.COMMANDS, keys[0]));
    } else if (keys.length > 1) {
      // Register dropdown command
      this.froala.RegisterCommand(VideoDropdownPlugin.KEY, {
        title: plugin.translateService.instant('RTE.VIDEO.INSERT_VIDEO'),
        type: 'dropdown',
        options: options,
        plugin: 'coyoVideoPlugin',
        icon: 'insertVideo',
        undo: false,
        focus: false,
        showOnMobile: true,
        refreshAfterCallback: false,
        callback: function(cmd: string, opt: string): void {
          plugin.froala.COMMANDS[opt].callback.bind(this)(cmd, opt);
        }
      });
    }
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TargetService} from '@domain/sender/target/target.service';
import {SharedArticle} from '@domain/share/sharedArticle';
import {TimelineItemService} from '@domain/timeline-item/timeline-item.service';
import * as _ from 'lodash';
import {TimelineItemContentComponent} from '../timeline-item-content.component';

/**
 * Wiki articles share component
 */
@Component({
  selector: 'coyo-wiki-share-article',
  templateUrl: './wiki-share-article.component.html',
  styleUrls: ['../share-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WikiShareArticleComponent extends TimelineItemContentComponent implements OnInit {

  article: SharedArticle;
  articleLink: string;
  authorIcon: string;

  constructor(
    cd: ChangeDetectorRef,
    private targetService: TargetService,
    private timelineItemService: TimelineItemService) {
    super(cd);
  }

  ngOnInit(): void {
    this.article = (this.item.data as any).article;
    if (!_.isEmpty(this.article)) {
      if (this.article.author) {
        this.authorIcon = this.timelineItemService.getAuthorIcon(this.article.author);
      }
      this.populateArticleLinkForArticleShare(this.article);
    }
  }

  private populateArticleLinkForArticleShare(article: SharedArticle): void {
    if (this.isWikiArticle(article)) {
      this.articleLink = this.getWikiArticleLink(article);
    }
  }

  private isWikiArticle(article: SharedArticle): boolean {
    return article.typeName === 'wiki-article';
  }

  private getWikiArticleLink(article: SharedArticle): string {
    const articleTarget = (article.articleTarget) ? article.articleTarget : {
      name: article.typeName,
      params: {
        id: article.id,
        appId: article.app.id,
        appSlug: article.app.slug,
        appKey: article.app.key,
        senderId: article.sender.id,
        senderSlug: article.sender.slug,
        senderType: article.sender.target.name
      }
    };
    return this.targetService.getLinkTo(articleTarget);
  }
}

<ng-container *ngIf="(state$ | async) as state">
  <!-- tracking information -->
  <div style="display: none"
       [attr.data-comment-target-id]="target.id"
       [attr.data-comment-target-type]="target.typeName"
       [attr.data-comment-count]="state.total"></div>

  <!-- load more -->
  <div class="load-more" *ngIf="state.total > state.count">
    <button mat-button mat-link-button type="button" color="accent" size="small" class="load-more-btn"
            [disabled]="state.isLoading" (click)="loadMore()">{{'COMMENTS.LOAD_MORE' | translate}}</button>
    <span class="pagination-count">{{state.count}}&nbsp;/&nbsp;{{state.total}}</span>
  </div>

  <!-- old comments -->
  <ng-container *ngFor="let comment of state.comments; let last = last; trackBy: trackComment">
    <coyo-comment *ngIf="comment !== state.commentEditing"
                  [comment]="comment" [author]="author" [editable]="last && state.commentsNew.length === 0"
                  (edit)="editComment($event)" (delete)="deleteComment($event)"></coyo-comment>
  </ng-container>

  <!-- comments divider -->
  <div class="comments-new-divider" *ngIf="state.comments.length && state.commentsNew.length">
    <span class="line"></span>
    <span class="text" translate="COMMENTS.NEW"></span>
    <span class="line"></span>
  </div>

  <!-- new comments -->
  <ng-container *ngFor="let comment of state.commentsNew; let last = last; trackBy: trackComment" >
    <coyo-comment *ngIf="comment !== state.commentEditing"
                  [comment]="comment" [author]="author" [editable]="last"
                  (delete)="deleteComment($event)" (edit)="editComment($event)" (changed)="updateInState($event)"
                  [skipInitRequest]="true">
    </coyo-comment>
  </ng-container>

  <!-- comment form -->
  <coyo-comment-form *ngIf="target._permissions.comment === true"
                     [author]="author" [target]="target" [comment]="state.commentEditing"
                     (created)="commentCreated($event)" (edited)="commentEdited($event)" (cancel)="cancelEditing()">
  </coyo-comment-form>
</ng-container>

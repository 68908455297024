(function (angular) {
  'use strict';

  userChooserExternalModalService.$inject = ["modalService"];
  UserChooserExternalModalController.$inject = ["$uibModalInstance", "SettingsModel", "ExternalUserModel", "workspaceId", "$q"];
  angular.module('commons.ui')
      .factory('userChooserExternalModalService', userChooserExternalModalService)
      .controller('UserChooserExternalModalController', UserChooserExternalModalController);

  /**
   * @ngdoc service
   * @name commons.ui.userChooserExternalModalService
   *
   * @description
   * This service provides a method to open a modal in which users and/or groups can be selected. In order to find the
   * user/group to select, a user can filter and text search for users/groups in the system. Selected users/groups are
   * returned in a result object containing a 'users' array and a 'groups' array.
   *
   * @requires modalService
   * @requires $q
   * @requires $uibModalInstance
   * @requires UserModel
   * @requires GroupModel
   * @requires Pageable
   * @requires users
   * @requires groups
   * @requires settings
   */
  function userChooserExternalModalService(modalService) {
    return {
      open: open
    };

    /**
     * @ngdoc method
     * @name commons.ui.userChooserExternalModalService#open
     * @methodOf commons.ui.userChooserExternalModalService
     *
     * @description
     * Opens the chooser modal.
     *
     * @param {object} workspaceId The workspace id.
     * @returns {object} A result object containing the selected users and groups.
     */
    function open(workspaceId) {
      return modalService.open({
        size: 'lg',
        resolve: {
          workspaceId: function () {
            return workspaceId;
          }
        },
        templateUrl: 'app/commons/ui/components/user-chooser-external/user-chooser-external-modal.html',
        controller: 'UserChooserExternalModalController',
        controllerAs: '$ctrl'
      }).result.then(function (emailAddresses) {
        return emailAddresses;
      });
    }
  }

  function UserChooserExternalModalController($uibModalInstance, SettingsModel, ExternalUserModel, workspaceId, $q) {
    var vm = this;
    vm.emailAddresses = [];
    vm.workspaceId = workspaceId;
    vm.takenInternalEmails = [];
    vm.activeInvitationEmails = [];
    vm.leftoverText = null;

    vm.$onInit = _init();

    vm.save = save;
    vm.isFormValid = isFormValid;
    vm.validateEmail = validateEmail;
    vm.removeTag = removeTag;

    vm._validationPromise = null;

    // ----------

    /**
     * Closes this modal and returns the current selection.
     */
    function save() {
      (vm._validationPromise || $q.resolve())
          .then(_isValid)
          .then(function (isValid) {
            isValid && $uibModalInstance.close(vm.emailAddresses);
          });
    }

    /**
     * Check if the form is valid. Note that it is not checked if the email input field contains leftover text,
     * which determines an invalid email address, since this is visualized by the tags-input component itself and
     * has a better look-and-feel.
     *
     * @returns {boolean} true if the form is valid, false if an error should be displayed
     */
    function isFormValid() {
      return _hasAtLeastOneEmail() && !_hasAlreadyTakenEmails();
    }

    /**
     * Get the email address checked by the backend and adds it to the lists of taken addresses or active invitations.
     *
     * @param {object} tag The email address.
     */
    function validateEmail(tag) {
      vm._validationPromise = ExternalUserModel.checkEmail(workspaceId, tag.text).then(function (result) {
        vm._validationPromise = null;
        if (result.data.infoMessage === 'ACTIVE_INVITATION') {
          vm.activeInvitationEmails = _handleAddTag(vm.activeInvitationEmails, tag.text);
        } else if (result.data.infoMessage === 'TAKEN_BY_INTERNAL') {
          vm.takenInternalEmails = _handleAddTag(vm.takenInternalEmails, tag.text);
        }
      });
    }

    /**
     * Removes the email address of the list of invalid email addresses.
     *
     * @param {object} tag The email address.
     */
    function removeTag(tag) {
      vm.activeInvitationEmails = _handleRemoveTag(vm.activeInvitationEmails, tag.text);
      vm.takenInternalEmails = _handleRemoveTag(vm.takenInternalEmails, tag.text);
    }

    function _handleAddTag(emailList, tagText) {
      return _.concat(emailList, tagText);
    }

    function _handleRemoveTag(emailList, tagText) {
      return _.without(emailList, tagText);
    }

    function _isValid() {
      return _hasAtLeastOneEmail() && !_hasLeftoverText() && !_hasAlreadyTakenEmails();
    }

    function _hasAlreadyTakenEmails() {
      return vm.takenInternalEmails.length > 0;
    }

    function _hasLeftoverText() {
      return !!vm.leftoverText;
    }

    function _hasAtLeastOneEmail() {
      return vm.emailAddresses.length > 0;
    }

    function _init() {
      SettingsModel.retrieveByKey('emailPattern').then(function (emailPattern) {
        vm.emailPattern = emailPattern;
      });
    }
  }

})(angular);

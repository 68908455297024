import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

/**
 * Sanitizes the given resource URL.
 */
@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string): SafeResourceUrl {
    const safeValue = this.sanitizer.sanitize(SecurityContext.URL, value);
    return this.sanitizer.bypassSecurityTrustResourceUrl(safeValue);
  }
}

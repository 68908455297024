import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {map} from 'rxjs/operators';
import {HashtagSettingsService} from './hashtag-settings.service';

@Component({
  selector: 'coyo-hashtag-settings',
  templateUrl: './hashtag-settings.component.html',
  styleUrls: ['./hashtag-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HashtagSettingsComponent implements OnInit {
  hashtags: FormControl = new FormControl([]);

  // This needs to be used as a value to support correct this binding in the template
  readonly submit: (term: string) => Promise<string> = term => {
    const hashtag = term.startsWith('#') ? term : '#' + term;
    return !this.hashtags.value.includes(hashtag)
      ? this.hashtagSettingsService.addHashtag(hashtag).pipe(map(() => hashtag)).toPromise()
      : Promise.reject();
  };

  constructor(private hashtagSettingsService: HashtagSettingsService) {
  }

  ngOnInit(): void {
    this.hashtagSettingsService.getHashtags()
      .subscribe(hashtags => this.hashtags.setValue(hashtags));
  }

  remove(term: string): void {
    this.hashtagSettingsService.deleteHashtag(term).subscribe();
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {IframeComponent} from './iframe.component';

@NgModule({
  declarations: [
    IframeComponent
  ],
  imports: [
    CoyoCommonsModule
  ],
  exports: [
    IframeComponent
  ]
})
export class IframeModule {
}

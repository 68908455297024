<coyo-select [itemTemplate]="itemTemplate" [selectedTemplate]="selectedTemplate" [settings]="settings">
  <ng-template #itemTemplate let-item="item">
    <div class="headline">{{item['name']}}</div>
    <small class="sender-name">{{item['senderName']}}</small>
  </ng-template>
  <ng-template #selectedTemplate let-clear="clear" let-item="item">
    <span class="headline">{{item['name']}}</span>
    <small class="item-name gap-left">{{item['senderName']}}</small>
    <span (click)="clear(item)" *ngIf="settings.multiselect" aria-hidden="false" class="ng-value-clear"
          role="button">×</span>
  </ng-template>
</coyo-select>

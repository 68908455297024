import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ExternalLinkPreviewConfirmationDialogData} from '@shared/preview/external-link-preview-confirmation-dialog/external-link-preview-confirmation-dialog-data';

/**
 * A component that renders a confirmation dialog
 */
@Component({
  selector: 'coyo-external-link-preview-confirmation-dialog',
  templateUrl: './external-link-preview-confirmation-dialog.component.html',
  styleUrls: ['./external-link-preview-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalLinkPreviewConfirmationDialogComponent {

  constructor(private dialog: MatDialogRef<ExternalLinkPreviewConfirmationDialogComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: ExternalLinkPreviewConfirmationDialogData) {
  }

  close(): void {
    this.dialog.close();
  }
}

<div class="size-option">
  <div class="size-heading"><span><b translate="WIDGET.TEASER.SETIINGS.CONFIG.WIDE.LABEL"></b></span></div>
  <div class="size-heading"><span translate="WIDGET.TEASER.SETIINGS.CONFIG.WIDE.RESOLUTION"></span></div>
    <button (click)="selectWideOption()" mat-flat-button class="sample aspect-ratio-button" [attr.aria-label]="'WIDGET.TEASER.SETTINGS.CONFIG.ASPECT_RATIO1' | translate" tabindex="0" [ngClass]="{'active': value === '3:1'}">
      <div class="sample-block wide-width">
        <span class="ratio-label" translate="WIDGET.TEASER.SETTINGS.CONFIG.ASPECT_WIDE"></span>
      </div>
  </button>
</div>
<div class="size-option" >
  <div class="size-heading"><span><b translate="WIDGET.TEASER.SETIINGS.CONFIG.NARROW.LABEL"></b></span></div>
  <div class="size-heading"><span translate="WIDGET.TEASER.SETIINGS.CONFIG.NARROW.RESOLUTION"></span></div>
    <button (click)="selectNarrowOption()" mat-flat-button class="sample aspect-ratio-button" [attr.aria-label]="'WIDGET.TEASER.SETTINGS.CONFIG.ASPECT_RATIO2' | translate" tabindex="0" [ngClass]="{'active': value === '1:1'}">
      <div class="sample-block narrow-width">
        <span class="ratio-label" translate="WIDGET.TEASER.SETTINGS.CONFIG.ASPECT_NARROW"></span>
      </div>
  </button>
</div>

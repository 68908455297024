import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';

@Component({
  template: '',
})
export abstract class AppModalComponent {

  legacyFormInvalid: boolean;

  appForm: FormGroup;

  loading$: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  onValidityChanged($event: boolean): void {
    this.legacyFormInvalid = $event;
  }
}

import {Injectable} from '@angular/core';
import {FileAuthorService} from '@domain/file/file-author/file-author.service';
import {FileService} from '@domain/file/file/file.service';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {FilePreviewService} from '@domain/preview/file-preview/file-preview.service';
import {Action, State, StateContext} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {FileDetailsDialogStatesModel} from '@shared/files/file-details-modal/state/file-details-dialog-states-model';
import {
  AddFilePreview,
  InitFileDetails,
  RefreshFileInDetails
} from '@shared/files/file-details-modal/state/file-details.actions';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@State<FileDetailsDialogStatesModel>({
  name: 'fileDetails',
  defaults: {}
})
@Injectable()
export class FileDetailsState {

  constructor(private fileService: FileService,
              private fileAuthorService: FileAuthorService,
              private filePreviewService: FilePreviewService) {
  }

  @Action(InitFileDetails)
  initFileDetails(ctx: StateContext<FileDetailsDialogStatesModel>, action: InitFileDetails): void {
    ctx.patchState({
        [action.fileDetailsDialogId]: {
          files: action.files.reduce(
            (previousValue, currentValue) => ({...previousValue, [currentValue.id]: currentValue}),
            {} as { [id: string]: FilePreview }),
          authors: action.authors,
          options: action.options
        }
      }
    );
  }

  @Action(RefreshFileInDetails)
  refreshFileInDetails(ctx: StateContext<FileDetailsDialogStatesModel>, action: RefreshFileInDetails): Observable<void> {
    const currentFile = ctx.getState()[action.fileDetailsDialogId].files[action.fileId];
    const permissions = Object.keys(currentFile._permissions ?? {});
    return this.fileService
      .getFile(currentFile.id, currentFile.senderId, permissions)
      .pipe(
        map(newFile => this.filePreviewService.toFilePreview(newFile)),
        switchMap(newFile => {
          if (ctx.getState()[action.fileDetailsDialogId].options?.showAuthors) {
            return this.fileAuthorService.getAuthors(
              newFile.senderId,
              ctx.getState()[action.fileDetailsDialogId].options.appIdOrSlug,
              [newFile.id])
              .pipe(map(authors => ({
                file: newFile,
                author: authors[newFile.id]
              })));
          } else {
            return of({file: newFile, author: null});
          }
        }),
        map(value => {
          ctx.setState(
            patch({
              [action.fileDetailsDialogId]: patch({
                files: patch({
                  [value.file.id]: value.file
                }),
                authors: patch({
                  [value.file.id]: value.author
                })
              })
            })
          );
        })
      );
  }

  @Action(AddFilePreview)
  addFilePreview(ctx: StateContext<FileDetailsDialogStatesModel>, action: AddFilePreview): void {
    ctx.setState(
      patch({
        [action.fileDetailsDialogId]: patch({
          files: patch({
            [action.filePreview.id]: action.filePreview
          }),
          authors: patch({
            [action.filePreview.id]: action.fileAuthor
          })
        })
      })
    );
  }
}

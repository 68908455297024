import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SafeUrlPipe} from '@shared/trust/safe-url/safe-url.pipe';
import {TrustHtmlPipe} from './trust-html/trust-html.pipe';

/**
 * Module containing pipes to bypass the Angular security checks
 * and trust the given values to be safe.
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SafeUrlPipe,
    TrustHtmlPipe
  ],
  exports: [
    SafeUrlPipe,
    TrustHtmlPipe
  ]
})
export class TrustModule {
}

<h1 mat-dialog-title>{{data.title | translate:data.translationContext}}</h1>
<mat-dialog-close [attr.aria-label]="'CLOSE' | translate:data.translationContext"></mat-dialog-close>
<div mat-dialog-content>
  <div [innerHTML]="data.body | translate:data.translationContext | markdown"></div>
  <mat-checkbox *ngIf="data.needsConfirmation"
                (change)="setConfirmed($event.checked)">
    {{'DELETE_CONFIRM.CHECKBOX' | translate}}
  </mat-checkbox>
</div>
<div mat-dialog-actions>
  <button (click)="cancel()" mat-button mat-dialog-close
          type="button">{{data.cancelText | translate:data.translationContext}}</button>
  <button (click)="ok()" color="warn" mat-flat-button [disabled]="!confirmed"
          type="submit">{{data.confirmText | translate:data.translationContext }}</button>
</div>

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BlogArticle} from '@domain/blog-article/blog-article';
import {Sender} from '@domain/sender/sender';

/**
 * Component rendering the headline of the engage blog article view.
 */
@Component({
  selector: 'coyo-blog-article-headline',
  templateUrl: './blog-article-headline.component.html',
  styleUrls: ['./blog-article-headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleHeadlineComponent {

  /**
   * The blog article.
   */
  @Input() article: BlogArticle;

  /**
   * The sender of the article.
   */
  @Input() sender: Sender;

  constructor() {
  }
}

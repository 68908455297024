import {Inject, Injectable} from '@angular/core';
import {NewsViewParams} from '@app/engage/news/news-view-params';
import {EngageTermsViewHandlerService} from '@app/engage/terms/engage-terms-view-handler/engage-terms-view-handler.service';
import {Ng1DeviceRegistrationHandler, Ng1MobileStorageHandler} from '@root/typings';
import {NG1_DEVICE_REGISTRATION_HANDLER, NG1_MOBILE_STORAGE_HANDLER} from '@upgrade/upgrade.module';
import {EngageTransitionHandlerService} from '../engage-transition-handler/engage-transition-handler.service';
import {EngageNewsHandlerService} from '../news/engage-news-view-handler/engage-news-view-handler.service';

/**
 * Service for registering method calls for app callbacks.
 */
@Injectable({
  providedIn: 'root'
})
export class MobileRequestHandlerRegistryService {

  private readonly handlers: { [key: string]: (args: any) => void } = {
    getValue: this.mobileStorageHandler.getStorageValue,
    setValue: this.mobileStorageHandler.setStorageValue,
    registerPushDevice: this.deviceRegistrationHandler.registerPushDevice,
    deviceRegistration: this.deviceRegistrationHandler.registerPushDevice, // deprecated
    showNews: (argument: NewsViewParams) => this.engageNewsViewHandler.showNews(argument),
    showTerms: () => this.engageTermsViewHandler.showTermsView(),
    initialize: () => this.engageTransitionHandler.watchTransitions(),
    engageHello: () => this.engageTransitionHandler.watchTransitions() // deprecated
  };

  constructor(@Inject(NG1_MOBILE_STORAGE_HANDLER) private mobileStorageHandler: Ng1MobileStorageHandler,
              @Inject(NG1_DEVICE_REGISTRATION_HANDLER) private deviceRegistrationHandler: Ng1DeviceRegistrationHandler,
              private engageTransitionHandler: EngageTransitionHandlerService,
              private engageNewsViewHandler: EngageNewsHandlerService,
              private engageTermsViewHandler: EngageTermsViewHandlerService) {
  }

  /**
   * Gets the handler for the given method name
   *
   * @param name the method name
   * @return the handler function
   */
  getHandler(name: string): (argument: any) => void {
    return this.handlers[name];
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TeamsVerificationData} from '@app/msteams/teams-verification-data';
import {Observable} from 'rxjs';

/**
 * Service for sending JWT token of the coyo user to the teams application
 */
@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(private http: HttpClient) {
  }

  /**
   * Send the user token to the auth/success endpoint of the teams application
   * @param token the JWT Token
   *
   * @returns An Observable containing verification data for the teams authentication
   */
  sendToken(token: string): Observable<TeamsVerificationData> {
    const urlPath = '/web/teams/auth/success';
    return this.http.post<TeamsVerificationData>(urlPath, {token});
  }
}

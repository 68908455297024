import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PluginService} from '@domain/plugin/plugin.service';
import {IframeMessage} from '@shared/iframe/iframe-message';
import {IframeSandbox} from '@shared/iframe/iframe-sandbox.enum';
import {IframeComponent} from '@shared/iframe/iframe.component';
import {WidgetComponent} from '@widgets/api/widget-component';
import {PluginInstanceRegistryService} from '@widgets/plugin/plugin-instance-registry/plugin-instance-registry.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {PLUGIN_EVENT_HANDLERS, PluginEventHandler} from '../plugin-event-handler/plugin-event-handler';
import {PluginWidget} from '../plugin-widget';
import {IframeErrorMessage} from './iframe-error-message';

/**
 * The plug-in widget shows
 */
@Component({
  selector: 'coyo-plugin-widget',
  templateUrl: './plugin-widget.component.html',
  styleUrls: ['./plugin-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PluginWidgetComponent extends WidgetComponent<PluginWidget> implements OnInit, OnChanges, OnDestroy {
  private static readonly DEFAULT_HEIGHT: number = null;
  private static readonly DEFAULT_RATIO: number = PluginWidgetComponent.calcRatio('16:9');

  private _srcId: string;
  private _pluginId: string;
  scrolling$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  height$: BehaviorSubject<number | null> = new BehaviorSubject(PluginWidgetComponent.DEFAULT_HEIGHT);
  ratio$: BehaviorSubject<number | null> = new BehaviorSubject(PluginWidgetComponent.DEFAULT_RATIO);
  origin$: BehaviorSubject<string[] | null> = new BehaviorSubject(null);

  url$: Observable<string>;

  @ViewChild(IframeComponent) iFrame!: IframeComponent;

  /**
   * Settings for the iFrame's sandbox.
   */
  readonly sandboxSettings: IframeSandbox[] = [
    IframeSandbox.SameOrigin,
    IframeSandbox.Scripts,
    IframeSandbox.Forms,
    IframeSandbox.Popups,
    IframeSandbox.PopupsToEscapeSandbox
  ];

  /**
   * The corresponding srcID of the plugin.
   */
  get srcId(): string {
    return this._srcId;
  }

  /**
   * The corresponding ID of the plugin.
   */
  get pluginId(): string {
    return this._pluginId;
  }

  private static calcRatio(ratio: string): number {
    const [, w, h] = /^([1-9]\d*):([1-9]\d*)$/g.exec(ratio);
    return 100 * parseInt(h, 10) / parseInt(w, 10);
  }

  constructor(cd: ChangeDetectorRef,
              private pluginService: PluginService,
              private pluginInstanceRegistryService: PluginInstanceRegistryService,
              @Inject(PLUGIN_EVENT_HANDLERS) private pluginEventHandlers: PluginEventHandler<any>[]) {
    super(cd);
  }

  ngOnInit(): void {
    this.init();
    this.pluginInstanceRegistryService.register(this._srcId, this);
  }

  ngOnDestroy(): void {
    this.pluginInstanceRegistryService.deregister(this._srcId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.changedSettingsLike(changes, /^(?:_entryPointId|_config_.+)$/)) {
      this.init();
    }
  }

  receive(event: IframeMessage): void {
    if (event.iss !== this.srcId) {
      return; // don't handle the event as it is not meant for this plugin instance
    }

    this.pluginEventHandlers
      .filter(eventHandler => eventHandler.canHandle(event.sub))
      .forEach(eventHandler => eventHandler.handle(this, event)
        .pipe(catchError(error => of({...event, error})))
        .pipe(filter((message: string | void | IframeErrorMessage) => !!message))
        .subscribe((message: string | IframeErrorMessage) => this.iFrame.send(message, event.origin)));
  }

  updateHeight(height: string | number | undefined): void {
    switch (typeof height) {
      case 'number': {
        this.height$.next(height);
        this.ratio$.next(null);
        this.scrolling$.next(false);
        break;
      }
      case 'string': {
        this.height$.next(null);
        this.ratio$.next(PluginWidgetComponent.calcRatio(height));
        this.scrolling$.next(false);
        break;
      }
      default: {
        this.height$.next(PluginWidgetComponent.DEFAULT_HEIGHT);
        this.ratio$.next(PluginWidgetComponent.DEFAULT_RATIO);
        this.scrolling$.next(true);
        break;
      }
    }
  }

  private init(): void {
    this._srcId = this.widget.settings._srcId;
    this._pluginId = PluginService.getPluginId(this.config.key);
    this.url$ = this.pluginService.getEntryPoint(this.pluginId, this.widget.settings._entryPointId)
      .pipe(tap(entryPoint => this.updateHeight(entryPoint.height)))
      .pipe(tap(entryPoint => this.origin$.next(entryPoint.origin || null)))
      .pipe(map(entryPoint => entryPoint.url.indexOf('?') >= 0
        ? `${entryPoint.url}&src=${this.srcId}`
        : `${entryPoint.url}?src=${this.srcId}`));
  }
}

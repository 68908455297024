import {Directive, ElementRef, EventEmitter, Injector, Input, Output} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {User} from '@domain/user/user';

/**
 * Upgraded file details modal.
 */
@Directive({
  selector: 'coyo-legacy-file-details-modal' // tslint:disable-line
})
export class FileDetailsModalDirective extends UpgradeComponent {

  /**
   * An array or single file to be shown in detail view. The file can either be an attachment file or a file library file.
   */
  @Input() files: unknown;

  /**
   * An optional index of which of the provided files should be displayed first.
   */
  @Input() currentIndex?: number;

  /**
   * An optional if a link to the file in file library should be displayed.
   */
  @Input() linkToFileLibrary?: string;

  /**
   * An optional if the author of the file should be displayed.
   */
  @Input() showAuthors?: boolean;

  /**
   * An map of the files' authors.
   */
  @Input() fileAuthors?: { [key: string]: User };

  /**
   * If the modal was opened from an app - the apps' id or slug to check showAuthor settings and permissions.
   */
  @Input() appIdOrSlug?: string;

  /**
   * An optional total number of files if different from files.length. Files not loaded in files array will be loaded with
   * loadNext function
   */
  @Input() filesTotal?: number;

  /**
   * An optional function called to load the next file if files arrat is already at its end but there are more filesTotal.
   */
  @Input() loadNext?: () => void;

  /**
   * The current user.
   */
  @Input() currentUser: User;

  /**
   * The dialog id
   */
  @Input() fileDetailsDialogId: string;

  /**
   * Emitter that emits the updated files when the modal should be closed.
   */
  @Output() close: EventEmitter<File[]>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('coyoLegacyFileDetailsModal', elementRef, injector);
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Skeleton} from '@coyo/ui';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {WidgetComponent} from '@widgets/api/widget-component';
import {FacegameState} from '@widgets/facegame/facegame-state';
import {Facegame, FacegameWidget, RankingEntry} from '@widgets/facegame/facegame-widget';
import {FacegameService} from '@widgets/facegame/facegame.service';
import * as _ from 'lodash';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

/**
 * This widget displays the subscriptions of the current user.
 */
@Component({
  selector: 'coyo-subscription-widget',
  templateUrl: './facegame-widget.component.html',
  styleUrls: ['./facegame-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacegameWidgetComponent extends WidgetComponent<FacegameWidget> implements OnDestroy, OnInit {

  static readonly INITIAL_GAME_STATE: Facegame = {
    facegameId: undefined,
    started: false,
    startDate: undefined,
    endDate: undefined,
    remainingMilliSeconds: undefined,
    totalMilliSeconds: undefined,
    answers: [],
    currentAnswer: undefined,
    lastAnswerCorrect: undefined,
    percentage: 100,
    points: 0,
    multiplier: 1,
    imageUrl: undefined
  };

  state$: BehaviorSubject<FacegameState>;
  skeletons: Skeleton[] = [
    { top: 0, left: 'calc(50% - 55px)', width: 110, height: 110, radius: '50%' },
    { top: 180, left: 'calc(50% - 110px)', width: 220, height: 40 },
    { top: 225, left: 'calc(50% - 110px)', width: 220, height: 40 },
    { top: 270, left: 'calc(50% - 110px)', width: 220, height: 40 },
    { top: 315, left: 'calc(50% - 110px)', width: 220, height: 40 },
    { top: 380, left: 'calc(50% - 30px)', width: 60, height: 15 },
    { top: 405, left: 'calc(50% - 110px)', width: 220, height: 5 },
  ];

  private runningSubscription: Subscription;

  constructor(private facegameService: FacegameService,
              cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.initFacegameAndLoadRanking();
  }

  ngOnDestroy(): void {
    this.unsubscribeRunningSubscription();
  }

  startGame(): void {
    this.initFacegameAndLoadRanking();
    this.setNoMoreColleagues(false);
    this.setLoading(true);
    this.facegameService.startGame(this.widget.id)
      .pipe(finalize(() =>  this.setLoading(false)))
      .subscribe(gameResponse => {
        if (!_.isEmpty(gameResponse.answers)) {
          this.setGameStartData(gameResponse);
        } else {
          this.forceGameEnd();
        }
      }, () => this.setLoading(false));
  }

  setGameStartData(gameResponse: Facegame): void {
    this.setRunning(true);
    const game = this.state$.getValue().game;
    game.facegameId = gameResponse.facegameId;
    game.answers = gameResponse.answers;
    game.startDate = new Date().getTime();
    game.endDate = gameResponse.endDate;
    game.totalMilliSeconds = Math.round((game.endDate - game.startDate));
    game.currentAnswer = undefined;
    game.lastAnswerCorrect = undefined;
    game.imageUrl = gameResponse.imageUrl;
    this.setGame(game);
    this.runningSubscription = interval(100).subscribe(() => {
      if (this.state$.getValue().isRunning) {
        this.checkGame();
      }
    });
  }

  checkGame(): void {
    const state = this.state$.getValue();
    state.game.remainingMilliSeconds = state.game.endDate - new Date().getTime();
    state.game.percentage = Math.max(0, (state.game.remainingMilliSeconds / state.game.totalMilliSeconds) * 100);
    this.setGame(state.game);
    if (state.game.remainingMilliSeconds <= 0) {
      this.unsubscribeRunningSubscription();
      this.initFacegameAndLoadRanking();
    }
  }

  answer(index: number): void {
    let game = this.state$.getValue().game;
    if (_.isUndefined(game.currentAnswer)) {
      const time = new Date().getTime();
      game.currentAnswer = index;
      this.facegameService.answer(game.facegameId, game.answers[index].id).subscribe(response => {
        game = this.updateGameData(game, response, time);
      }, this.initFacegameAndLoadRanking);
    }
  }

  updateGameData(facegame: Facegame, response: Facegame, time: number): Facegame {
    let game = facegame;
    game.points = response.points;
    game.multiplier = response.multiplier;
    game.lastAnswerCorrect = response.lastAnswerCorrect;
    this.setGame(game);
    if (!_.isEmpty(response.answers)) {
      const now = new Date().getTime();
      const timeout = Math.max(1000 - (now - time), 0);
      setTimeout(() => {
        game = this.state$.getValue().game;
        game.answers = response.answers;
        game.currentAnswer = undefined;
        game.lastAnswerCorrect = undefined;
        game.imageUrl = response.imageUrl;
        this.setGame(facegame);
      }, Math.max(timeout + 250));
    } else {
      this.forceGameEnd();
    }
    return game;
  }

  getAvatarTeasers(): void {
    if (this.widget.id) {
      this.facegameService.getAvatarTeasers(this.widget.id, new Pageable(0, 3)).subscribe((setOfUserIds: Set<string>) => {
        this.setAvatarTeaserUserIds(setOfUserIds);
      });
    }
  }

  getRanking(): void {
    if (this.widget.id) {
      this.facegameService.getRanking(this.widget.id, new Pageable(0, 5)).subscribe((page: Page<RankingEntry>) => {
        this.setRanking(page.content);
      });
    }
  }

  forceGameEnd(): void {
    this.unsubscribeRunningSubscription();
    setTimeout(() => {
      this.initFacegameAndLoadRanking(true);
    }, Math.max(750));
  }

  initFacegameAndLoadRanking(isNoMoreColleagues: boolean = false): void {
    this.state$ = new BehaviorSubject({
      isRunning: false,
      isLoading: false,
      isNoMoreColleagues: isNoMoreColleagues,
      game: FacegameWidgetComponent.INITIAL_GAME_STATE,
      avatarTeaserUserIds: new Set(),
      ranking: []
    });
    this.getAvatarTeasers();
    this.getRanking();
    this.detectChanges();
  }

  private setRunning(isRunning: boolean): void {
    const state = this.state$.getValue();
    this.state$.next({...state, ...{isRunning}});
  }

  private setLoading(isLoading: boolean): void {
    const state = this.state$.getValue();
    this.state$.next({...state, ...{isLoading}});
  }

  private setNoMoreColleagues(isNoMoreColleagues: boolean): void {
    const state = this.state$.getValue();
    this.state$.next({...state, ...{isNoMoreColleagues}});
  }

  private setGame(game: Facegame): void {
    const state = this.state$.getValue();
    this.state$.next({...state, ...{game}});
  }

  private setAvatarTeaserUserIds(avatarTeaserUserIds: Set<string>): void {
    const state = this.state$.getValue();
    this.state$.next({...state, ...{avatarTeaserUserIds}});
  }

  private setRanking(ranking: RankingEntry[]): void {
    const state = this.state$.getValue();
    this.state$.next({...state, ...{ranking}});
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown($event: KeyboardEvent): void {
    if (!this.state$.getValue().isRunning) {
      return;
    }
    const key = $event.key || $event.keyCode; // tslint:disable-line:deprecation
    switch (key) {
      case '1':
      case 49:
        this.answer(0);
        break;
      case '2':
      case 50:
        this.answer(1);
        break;
      case '3':
      case 51:
        this.answer(2);
        break;
      case '4':
      case 52:
        this.answer(3);
    }
  }

  private unsubscribeRunningSubscription(): void {
    if (this.runningSubscription) {
      this.runningSubscription.unsubscribe();
    }
  }
}

(function (angular) {
  'use strict';

  SenderNavigationModalController.$inject = ["$state", "$injector", "sender", "apps", "currentApp", "SenderModel", "PageModel", "WorkspaceModel", "appRegistry", "authService", "appTranslationsModalService", "appService", "$scope"];
  angular
      .module('commons.ui')
      .controller('SenderNavigationModalController', SenderNavigationModalController);

  /**
   * Controller for mobile page navigation modal.
   *
   * @requires $state
   * @requires sender
   * @requires apps
   * @requires currentApp
   * @requires SenderModel
   * @requires PageModel
   * @requires WorkspaceModel
   * @requires appService
   * @requires appRegistry
   * @requires authService
   * @requires appTranslationsModalService
   * @requires senderNavigationUpdateService
   */
  function SenderNavigationModalController($state, $injector, sender, apps, currentApp, SenderModel, PageModel, WorkspaceModel,
                                           appRegistry, authService,
                                           appTranslationsModalService, appService, $scope) {
    var vm = this;
    vm.sender = sender;
    vm.apps = apps;

    vm.addApp = addApp;
    vm.getIcon = appRegistry.getIcon;
    vm.hasImprint = hasImprint();
    vm.editApp = editApp;
    vm.openTranslations = openTranslations;
    vm.addGroup = addGroup;
    vm.join = join;
    vm.leave = leave;

    vm.senderModel = new SenderModel({id: sender.id});
    authService.getUser().then(function (user) {
      vm.currentUser = user;
    });
    vm.navGroups = _mapAppsToGroups();
    vm.currentApp = currentApp ? currentApp : _.head(vm.apps);

    function addApp(sender, apps) {
      return appService.addApp(sender, apps);
    }

    function join() {
      vm.sender.join().then(function () {
        // we need to reload the state here since the workspace, the user and the members must be resolved again
        $state.reload('main.workspace.show');
      });
    }

    function leave() {
      vm.sender.leave().then(function () {
        $state.go('main.workspace');
      });
    }

    function addGroup() {
      vm.senderModel.createNavigationGroup().then(function (result) {
        vm.sender.appNavigation = result;
        vm.lastAppUpdate = new Date().getTime();
      }).finally(function () {
        vm.navGroups = undefined;
        vm.navGroups = _mapAppsToGroups();
      });
    }

    function _mapAppsToGroups() {
      return _.map(vm.sender.appNavigation, function (appGroup) {
        appGroup.apps = _.map(appGroup.apps, function (appGroupApp) {
          vm.apps = _.map(vm.apps, function (appData) {
            if (appData.id === appGroupApp) {
              appGroupApp = {
                'id': appData.id,
                'key': appData.key,
                'name': appData.name,
                'displayName': appData.displayName,
                'active': appData.active
              };
            }
            return appData;
          });
          return appGroupApp;
        });
        return appGroup;
      });
    }

    function reloadNavigationChanges(senderId, type) {
      if (type === 'page') {
        PageModel.get({id: senderId}).then(function (result) {
          refreshSenderAndLoadApps(result);
        });
      } else {
        WorkspaceModel.get({id: senderId}).then(function (result) {
          refreshSenderAndLoadApps(result);
        });
      }
    }

    function refreshSenderAndLoadApps(result) {
      vm.sender.appNavigation = result.appNavigation;
      vm.senderModel.getApps().then(function (result) {
        vm.apps = result;
        vm.navGroups = _mapAppsToGroups();
      });
    }

    function openTranslations(sender) {
      appTranslationsModalService.open(sender).then(function (result) {
        reloadNavigationChanges(result.id, result.typeName);
      });
    }

    function editApp() {
      if (vm.currentApp) {
        SenderModel.get(vm.currentApp.senderId).then(function (sender) {
          $injector.get('ngxAppSettingsModalService').open(sender, vm.currentApp).then(function (result) {
            $scope.$apply(function () {
              reloadNavigationChanges(result.senderId, result.senderType);
            });
          });
        });
      }
    }

    function hasImprint() {
      return vm.sender.typeName === 'page';
    }

  }

})(angular);

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {ReportItemComponent} from './report-item/report-item.component';
import {ReportListComponent} from './report-list/report-list.component';

export const uIRouterUpgradeModule = UIRouterUpgradeModule.forChild({
  states: [{
    name: 'main.report',
    component: ReportListComponent,
    url: '/reports'
  }],
});

/**
 * The reports module.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    SenderUIModule,
    uIRouterUpgradeModule
  ],
  declarations: [
    ReportItemComponent,
    ReportListComponent
  ],
})
export class ReportsModule {
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MenuPositionX, MenuPositionY} from '@angular/material/menu';
import {StickyExpiryPeriod} from '@app/timeline/timeline-form/sticky-btn/sticky-expiry-period';
import {StickyExpiryPeriods} from './sticky-expiry-periods';

const valueAccessor = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StickyBtnComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

/**
 * Component renders a sticky button which shows a dropdown to choose sticky duration.
 */
@Component({
  selector: 'coyo-sticky-btn',
  templateUrl: './sticky-btn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor]
})
export class StickyBtnComponent implements ControlValueAccessor {
  private onChange: (_: number) => void;

  /**
   * The horizontal placement of the sticky context menu.
   */
  @Input() xPosition: MenuPositionX = 'after';

  /**
   * The vertical placement of the sticky context menu.
   */
  @Input() yPosition: MenuPositionY = 'below';

  model: number | null = null;

  stickyExpiryOptions: StickyExpiryPeriod[] = StickyExpiryPeriods.all;

  constructor(private cd: ChangeDetectorRef) {
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: (_: number) => void): void {
    this.onChange = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: (_: number) => void): void {
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(obj: number): void {
    this.model = obj;
    this.cd.markForCheck();
  }

  /**
   * Sets the currently selected sticky period and emits an event that it is selected.
   *
   * @param expiry the expiry time of the selected option
   */
  setSelectedSticky(expiry: number): void {
    this.writeValue(expiry);
    this.onChange(expiry);
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';

/**
 * Provides COYO's general settings.
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private static readonly API_ENDPOINT: string = '/web/settings';
  private static readonly API_ENDPOINT_PUBLIC: string = '/web/settings/public';
  private static readonly CACHE_SIZE: number = 1;

  private cache$: Observable<Map<string, string>>;

  constructor(private http: HttpClient,
              private authService: AuthService) {
  }

  /**
   * Retrieves and caches the general settings from the backend. If the current user is not authenticated,
   * only public settings will be retrieved. Upon login, the cached settings are cleared.
   *
   * @return an observable holding the settings map
   */
  retrieve(): Observable<Map<string, any>> {
    if (!this.cache$) {
      this.cache$ = this.requestSettings().pipe(shareReplay({bufferSize: SettingsService.CACHE_SIZE, refCount: false}));
    }
    return this.cache$.pipe(first());
  }

  /**
   * Clears the cache and retrieves and caches the general settings from the backend.
   * If the current user is not authenticated, only public settings will be retrieved.
   * Upon login, the cached settings are cleared.
   *
   * @return an observable holding the settings map
   */
  retrieveAndForceRefresh(): Observable<Map<string, string>> {
    this.invalidateCache();
    return this.retrieve();
  }

  /**
   * Retrieves a single setting.
   *
   * @param key the setting's key
   * @returns an observable holding the setting's value
   */
  retrieveByKey(key: string): Observable<string> {
    return this.retrieve().pipe(map(value => value.get(key)));
  }

  /**
   * Clears the cache and retrieves a single setting by a key.
   *
   * @param key the setting's key
   * @returns an observable holding the setting's value
   */
  retrieveByKeyAndForceRefresh(key: string): Observable<string> {
    this.invalidateCache();
    return this.retrieveByKey(key);
  }

  /**
   * Updates the general settings in the database and invalidates cache.
   *
   * @param partialSettings with general settings to store
   * @return an observable holding the new state of the general settings
   */
  update(partialSettings: Map<string, string>): Observable<Map<string, string>> {
    return this.http.put<{ [key: string]: string; }>(SettingsService.API_ENDPOINT, partialSettings)
      .pipe(switchMap(() => this.retrieveAndForceRefresh()));
  }

  /**
   * Manually clears the settings cache.
   */
  invalidateCache(): void {
    this.cache$ = null;
  }

  private requestSettings(): Observable<Map<string, string>> {
    const endpoint = this.authService.isAuthenticated() ? SettingsService.API_ENDPOINT : SettingsService.API_ENDPOINT_PUBLIC;
    return this.http.get<{ [key: string]: string; }>(endpoint)
      .pipe(map(result => new Map(_.toPairs(result))));
  }
}

export const environment = {
  production: true,
  enableAnimations: false,
  autoStartTour: true,
  autolink: {
    urls: true,
    email: true,
    stripPrefix: true,
    truncate: {
      length: 48,
      location: 'smart'
    }
  }
};

/**
 * 2D-Vector helper class
 */
export class Vec2 {

  constructor(readonly x: number, readonly y: number) {
  }

  /**
   * Returns the squared magnitude of the vector
   */
  get magnitudeSquared(): number {
    return this.x * this.x + this.y * this.y;
  }

  /**
   * Returns the magnitude of this vector (sqrt(x*x+y*y))
   */
  get magnitude(): number {
    return Math.sqrt(this.magnitudeSquared);
  }

  /**
   * Returns a new vector with the absolute component values of this vector
   */
  get abs(): Vec2 {
    return new Vec2(Math.abs(this.x), Math.abs(this.y));
  }
  /**
   * Creates a vector containing the maximum for x and y of the given vectors
   *
   * @param a First Vector
   * @param b Second Vector
   *
   * @returns A Vec2 with the maximum x and y of input a and b
   */
  static max(a: Vec2, b: Vec2): Vec2 {
    return new Vec2(Math.max(a.x, b.x), Math.max(a.y, b.y));
  }

  /**
   * Creates a vector containing the minimum for x and y of the given vectors
   *
   * @param a First Vector
   * @param b Second Vector
   *
   * @returns A Vec2 with the minimum x and y of input a and b
   */
  static min(a: Vec2, b: Vec2): Vec2 {
    return new Vec2(Math.min(a.x, b.x), Math.min(a.y, b.y));
  }

  /**
   * Adds the components of the given Vector to the components of this Vector
   *
   * @param other The Vector to be added to this
   *
   * @returns A new Vec2 with the result of the addition
   */
  add(other: Vec2): Vec2 {
    return new Vec2(this.x + other.x, this.y + other.y);
  }

  /**
   * Subtracts the components of the given Vector from the components of this Vector
   *
   * @param other The Vector to be subtracted from this
   *
   * @returns A new Vec2 with the result of the subtraction
   */
  subtract(other: Vec2): Vec2 {
    return new Vec2(this.x - other.x, this.y - other.y);
  }

  /**
   * Multiplies the components of this vector with the components of the provided argument
   *
   * @param other The Vector to be multiplied with this
   *
   * @returns A new Vec2 with the result of the multiplication
   */
  multiply(other: Vec2): Vec2 {
    return new Vec2(this.x * other.x, this.y * other.y);
  }

  /**
   * Divides the components of this vector by the components of the provided argument
   *
   * @param other The Vector to divide this by
   *
   * @returns A new Vec2 with the result of the division
   */
  divide(other: Vec2): Vec2 {
    return new Vec2(this.x / other.x, this.y / other.y);
  }

  /**
   * Multiplies the components of this vector by a scalar value
   *
   * @param scalar A number to multiply the components of this vector by
   *
   * @returns A new Vec2 containing the result of the multiplication
   */
  scale(scalar: number): Vec2 {
    return new Vec2(this.x * scalar, this.y * scalar);
  }

  /**
   * Divides the components of this vector by the given scalar value
   *
   * @param scalar A number to divide the components of this vector by
   *
   * @returns A new Vec2 with the result of the division
   */
  scaleInverse(scalar: number): Vec2 {
    return new Vec2(this.x / scalar, this.y / scalar);
  }

  equals(obj: any): boolean {
    if (!obj) {
      return false;
    }
    return obj['x'] === this.x && obj['y'] === this.y;
  }

  toString(): string {
    return `[${this.x},${this.y}]`;
  }
}

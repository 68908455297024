import {Injectable, Type} from '@angular/core';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {FileLibraryHeaderComponent} from '@app/file-library/file-library-header/file-library-header.component';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {SenderRootFilePickerItem} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item';
import {SenderRootFilePickerItemComponent} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item.component';
import {CropSettings} from '@app/file-picker/crop-settings';
import {FilePickerHeaderBaseDirective} from '@app/file-picker/file-picker-header-base.directive';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {FilePickerItemService} from '@app/file-picker/file-picker-item.service';
import {FilePickerUpload} from '@app/file-picker/file-picker-upload';
import {FileService} from '@domain/file/file/file.service';
import {Direction} from '@domain/pagination/direction.enum';
import {Order} from '@domain/pagination/order';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {SenderService} from '@domain/sender/sender/sender.service';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

/**
 * Service responsible for handling sender root (my files) file picker items
 */
@Injectable({
  providedIn: 'root',
})
export class SenderRootFilePickerItemService extends FilePickerItemService {
  constructor(
    private readonly fileService: FileService,
    private readonly senderService: SenderService) {
    super();
  }

  getComponentType(): Type<FilePickerItemBaseDirective<FilePickerItem>> {
    return SenderRootFilePickerItemComponent;
  }

  getHeaderComponentType(): Type<FilePickerHeaderBaseDirective<FilePickerItem>> {
    return FileLibraryHeaderComponent;
  }

  getChildren(filePickerItem: SenderRootFilePickerItem, pageIdx: number): Observable<Page<FilePickerItem>> {
    return this.fileService
      .getChildren(filePickerItem.sender.id,
        new Pageable(pageIdx, 20, null, new Order('folder', Direction.Desc), new Order('name')),
        null
      )
      .pipe(map(page => ({...page, content: page.content.map(file => new FileLibraryFilePickerItem(file))})));
  }

  getCapabilities(filePickerItem: FilePickerItem): FilePickerItemServiceCapability {
    // tslint:disable-next-line:no-bitwise
    return FilePickerItemServiceCapability.RENAME
      | FilePickerItemServiceCapability.CREATE_FILE
      | FilePickerItemServiceCapability.MOVE_FILE;
  }

  getResponsibilities(): Type<FilePickerItem>[] {
    return [SenderRootFilePickerItem];
  }

  createFolder(parent: SenderRootFilePickerItem, name: string): Observable<FilePickerItem> {
    return this.senderService.createFolder(parent.sender.id, name).pipe(map(item => new FileLibraryFilePickerItem(item)));
  }

  moveFile(filePickerItem: FileLibraryFilePickerItem, destinationId: string): Observable<FilePickerItem> {
    return this.fileService.moveFile(filePickerItem.file)
      .pipe(switchMap(() => this.senderService.get(destinationId)))
      .pipe(map(sender => new SenderRootFilePickerItem(sender)));
  }

  upload(location: SenderRootFilePickerItem, files: FileList, cropSettings?: CropSettings): FilePickerUpload {
    const upload = this.fileService.upload(location.id, files, null, cropSettings);
    return {
      uploadEvent: upload,
      itemType: FileLibraryFilePickerItem
    };
  }
}

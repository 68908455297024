(function (angular) {
  'use strict';

  GameAdminModel.$inject = ["restResourceFactory"];
  angular
      .module('coyo.admin.gamification')
      .factory('GameAdminModel', GameAdminModel);

  /**
   * @ngdoc service
   * @name coyo.admin.gamification.GameAdminModel
   *
   * @description
   * Domain model representing the game administration endpoint.
   *
   * @requires restResourceFactory
   */
  function GameAdminModel(restResourceFactory) {
    var Game = restResourceFactory({
      url: '/web/tournaments/{{tournamentId}}/games/{{id}}'
    });

    return Game;
  }

})(angular);

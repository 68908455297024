import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {App} from '@apps/api/app';
import {AppConfigBase} from '@apps/api/app-config';
import {AppRegistryService} from '@apps/api/app-registry.service';
import {AppRequest} from '@apps/api/app-request';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {Sender} from '@domain/sender/sender';
import {StateService, UIRouter} from '@uirouter/core';
import * as _ from 'lodash';
import {EMPTY, forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

interface CheckSlugResult {
  valid: boolean;
  taken: boolean;
}

/**
 * Domain service for apps
 */
@Injectable({
  providedIn: 'root'
})
export class AppService extends DomainService<AppRequest<AppSettings>, App<AppSettings>> {

  constructor(http: HttpClient, urlService: UrlService,
              private appRegistryService: AppRegistryService,
              private stateService: StateService,
              private uiRouter: UIRouter) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/senders/{senderId}/apps';
  }

  /**
   * Creates an app for the given sender.
   *
   * @param app The app to create
   * @param senderId The sender parent of the app
   *
   * @return The created app entity
   */
  createApp(app: AppRequest<AppSettings>, senderId: string): Observable<App<AppSettings>> {
    return this.post(app, {
      context: {
        senderId
      },
      permissions: ['manage']
    });
  }

  /**
   * Deletes an app of the given sender.
   *
   * @param senderId The sender parent of the app
   * @param appId The id of the app
   * @param deleteAppFiles Flag if the created files for the app should be deleted
   *
   * @return Observable which completes after deletion is done
   */
  deleteApp(senderId: string, appId: string, deleteAppFiles: boolean = true): Observable<void> {
    return super.delete(appId, {
      context: {
        senderId
      },
      params: {
        deleteAppFiles: deleteAppFiles ? 'true' : 'false'
      }
    });
  }

  /**
   * Checks if the given slug is already taken for the given sender.
   *
   * @param senderId The sender parent of the app
   * @param appId The id of the app
   * @param slug The slug to be checked
   *
   * @return The result of the slug check. It could be taken, invalid (if it is not in the correct pattern) or valid.
   */
  isSlugTaken(senderId: string, appId: string, slug: string): Observable<CheckSlugResult> {
    return this.http.get<CheckSlugResult>(this.getUrl({senderId}, '/action/check-slug'), {
      params: {
        appId,
        slug
      }
    });
  }

  /**
   * Updates the given app.
   *
   * @param senderId The sender parent of the app
   * @param app The updated app
   *
   * @return The updated app entity
   */
  updateApp(senderId: string, app: App<AppSettings>): Observable<App<AppSettings>> {
    return this.put(app.id, app, {
      context: {
        senderId
      },
      permissions: ['manage']
    });
  }

  /**
   *
   * Returns the list of app configurations that are active for the given sender type.
   *
   * @param senderType
   * The type of sender to get the activated configurations for
   *
   * @returns List of activated app configurations
   */
  getActiveConfigsForSenderType(senderType: string): Observable<AppConfigBase[]> {
    return this.http.get<{ key: string }[]>(`/web/apps/configurations`, {
      params: {
        senderType: senderType
      }
    }).pipe(map(configList => configList.map(config => this.appRegistryService.get(config.key))));
  }

  /**
   * Retrieves all the possible app types that can be created for the given sender
   * @param sender The sender
   *
   * @return A list of app configs that can be created for the sender
   */
  getAppConfigsForSender(sender: Sender): Observable<AppConfigBase[]> {
    return forkJoin([
      this.getAll({context: {senderId: sender.id}}),
      this.getActiveConfigsForSenderType(sender.typeName)
    ]).pipe(map(([apps, enabledApps]) => enabledApps
      .filter(value => value.allowedInstances ?
        value.allowedInstances > apps.filter(app => app.key === value.key).length : true)
    ));
  }

  /**
   * Retrieves the current app.
   *
   * @param senderId the ID of the sender
   * @return an `Observable` containing the corresponding app or just completes
   * if there is no app context
   */
  getCurrentApp(senderId: string): Observable<App<AppSettings>> {
    const appId = this.getCurrentAppIdOrSlug();
    return appId && senderId
      ? this.getAppForSender(senderId, appId)
      : EMPTY;
  }

  /**
   * Returns the ID or slug of the current app or null if no app is active.
   *
   * @returns {string|null} The ID of the current app.
   */
  getCurrentAppIdOrSlug(): string {
    const senderType = this.getCurrentSenderType();
    if (senderType && this.stateService.includes('main.' + senderType + '.show.apps')) {
      return this.uiRouter.globals.params.appIdOrSlug;
    }
    return null;
  }

  getAppForSender(senderId: string, appId: string, permissions: string[] = []): Observable<App<AppSettings>> {
    return this.get(appId, {context: {senderId}, permissions});
  }

  private getCurrentSenderType(): string {
    const activeStateName = this.uiRouter.globals.$current.name;
    return _.find(AppRegistryService.APP_SENDER_TYPES, senderType => _.startsWith(activeStateName, 'main.' + senderType));
  }

}

import {Injectable, Type} from '@angular/core';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemService} from '@app/file-picker/file-picker-item.service';
import {FileSearchFilePickerItem} from '@app/file-picker/file-picker-search/file-search-file-picker-item';
import {SinglePage} from '@domain/page/single-page';
import {Page} from '@domain/pagination/page';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Service responsible for handling search result file picker items
 */
@Injectable({
  providedIn: 'root'
})
export class FileSearchFilePickerItemService extends FilePickerItemService {

  getChildren(filePickerItem: FileSearchFilePickerItem): Observable<Page<FilePickerItem>> {
    return filePickerItem.search().pipe(map(item => new SinglePage(item)));
  }

  getCapabilities(filePickerItem: FilePickerItem): FilePickerItemServiceCapability {
    return FilePickerItemServiceCapability.NONE;
  }

  getResponsibilities(): Type<FilePickerItem>[] {
    return [FileSearchFilePickerItem];
  }
}

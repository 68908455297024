(function (angular) {
  'use strict';

  MessageChannelHeaderController.$inject = ["MessageChannelMemberModel", "$q", "modalService"];
  angular
      .module('coyo.messaging')
      .controller('MessageChannelHeaderController', MessageChannelHeaderController);

  function MessageChannelHeaderController(MessageChannelMemberModel, $q, modalService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.leaveChannel = leaveChannel;
    vm.openInfoAndFiles = openInfoAndFiles;
    vm.editChannel = editChannel;
    vm.muteChannel = muteChannel;
    vm.back = back;

    function leaveChannel() {
      vm.member.delete();
    }

    function muteChannel() {
      vm.member.mute().then(function () {
        vm.member.channelId = vm.channel.id,
        vm.member.id = vm.currentUser.id,
        vm.member.muted = !vm.member.muted;
      });
    }

    function openInfoAndFiles() {
      _whenUserConfirmedToLeaveChannel().then(function () {
        if (vm.msgSidebar.view === 'channel') {
          vm.msgSidebar.switchView('channelInfo');
        }
      });
    }

    function editChannel() {
      _whenUserConfirmedToLeaveChannel().then(function () {
        if (vm.msgSidebar.view === 'channel' || vm.msgSidebar.view === 'channelInfo') {
          vm.msgSidebar.switchView('channelForm');
        }
      });
    }

    function back() {
      _whenUserConfirmedToLeaveChannel().then(function () {
        if (vm.msgSidebar.view === 'channel') {
          vm.msgSidebar.home();
        } else if (vm.msgSidebar.view === 'channelInfo') {
          vm.msgSidebar.switchView('channel');
        }
      });
    }

    function _whenUserConfirmedToLeaveChannel() {
      if (vm.channelLocked) {
        return modalService.confirm({
          title: 'MODULE.MESSAGING.LEAVE_CHANNEL.CONFIRMATION.TITLE',
          text: 'MODULE.MESSAGING.LEAVE_CHANNEL.CONFIRMATION.TEXT',
          close: {title: 'OK'},
          dismiss: {title: 'CANCEL'}
        }).result;
      }
      return $q.resolve();
    }

    function onInit() {
      vm.member = new MessageChannelMemberModel({
        channelId: vm.channel.id,
        id: vm.currentUser.id,
        muted: vm.channel.getMember(vm.currentUser.id).muted
      });
    }
  }

})(angular);

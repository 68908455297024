<div (froalaModelChange)="emitContentUpdatedEvent($event)"
     *ngIf="!loading"
     [(froalaModel)]="content"
     [class.focus]="focused"
     [froalaEditor]="options"
     [style.height]="height"
     class="coyo-rte">
</div>
<div (dragleave)="hideDropHint()"
     (dragover)="startDrop($event)"
     (drop)="handleDropEvent($event)"
     [ngClass]="overlayClass$ | async">
  <div class="drop-overlay-hint">
    <mat-icon svgIcon="upload"></mat-icon>
    {{'FILEPICKER.DROPZONE.MESSAGE' | translate}}</div>
</div>

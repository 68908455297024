import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {RteModule} from '@shared/rte/rte.module';
import {TextModule} from '@shared/text/text.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {RICH_TEXT_WIDGET} from '@widgets/rich-text/rich-text-widget-config';
import {AnchorContainerDirective} from './anchor-container/anchor-container.directive';
import {RichTextWidgetComponent} from './rich-text-widget/rich-text-widget.component';
import {TrustHtmlPipe} from './trust-html/trust-html.pipe';
import {YoutubeNoCookiePipe} from './youtube-no-cookie/youtube-no-cookie.pipe';

@NgModule({
  declarations: [RichTextWidgetComponent, YoutubeNoCookiePipe, TrustHtmlPipe, AnchorContainerDirective],
  imports: [
    CoyoCommonsModule,
    TextModule,
    RteModule
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: RICH_TEXT_WIDGET, multi: true}
  ],
  entryComponents: [RichTextWidgetComponent]
})
export class RichTextWidgetModule {
}

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {WelcomeWidget} from '@widgets/welcome/welcome-widget';

/**
 * The welcome widget's settings component.
 */
@Component({
  selector: 'coyo-welcome-widget-settings',
  templateUrl: './welcome-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeWidgetSettingsComponent extends WidgetSettingsComponent<WelcomeWidget> implements OnInit {
  readonly MAX_LENGTH: number = 40;

  ngOnInit(): void {
    this.parentForm.addControl('welcomeText', new FormControl(this.widget.settings.welcomeText, Validators.maxLength(this.MAX_LENGTH)));
    this.parentForm.addControl('_showCover', new FormControl(!!this.widget.settings._showCover));
  }
}

(function (angular) {
  'use strict';

  SearchController.$inject = ["$scope", "$rootScope", "$http", "$httpParamSerializer", "$q", "$state", "$stateParams", "$translate", "coyoConfig", "coyoEndpoints", "targetService", "Page", "currentUser", "errorService", "selectionFilterService", "userGuideService", "$timeout", "$filter"];
  angular
      .module('coyo.search')
      .controller('SearchController', SearchController);

  /**
   * Controller for the full search
   */
  function SearchController($scope, $rootScope, $http, $httpParamSerializer, $q, $state, $stateParams, $translate,
                            coyoConfig, coyoEndpoints, targetService, Page, currentUser, errorService,
                            selectionFilterService, userGuideService, $timeout, $filter) {

    var vm = this;

    vm.currentUser = currentUser;
    vm.subscriptionLoading = true;
    vm.loading = false;

    vm.defaultSearchSortingOptionKey = 'SCORE';
    vm.searchSortingOptions = [{key: 'DATE', icon: 'events'}, {key: 'SCORE', icon: 'search'}];
    vm.changeSorting = changeSorting;
    vm.showHelp = showHelp;
    vm.isEmpty = isEmpty;
    vm.getAggregationCountByType = getAggregationCountByType;
    vm.getType = getType;
    vm.selectFilter = selectFilter;
    vm.clearAllFilters = clearAllFilters;
    vm.setNewTerm = setNewTerm;
    vm.focusSearch = focusSearch;
    vm.search = search;
    vm.sortFilter = sortFilter;
    vm.clickHandler = clickHandler;
    vm.$onInit = init;
    vm.extendText = $filter('translate')('TIME_AGO.LAST.MODIFIEDAT');

    vm.links = {
      results: {},
      senders: {}
    };

    vm.hasAnySearchResult = false;
    vm.hasExternalSearchResults = false;
    vm.hasCoyoSearchResults = false;
    vm.hasSuggestions = false;
    vm.isFiltered = false;
    vm.showEmptySearchAndSuggestionsPanel = false;
    vm.showSearchResultsPanel = false;

    var unsubscribe = $rootScope.$on('screenSize:changed', function (event, screenSize) {
      vm.isMobile = screenSize.isXs || screenSize.isSm;
    });
    $scope.$on('$destroy', unsubscribe);

    vm.isMobile = $rootScope.screenSize.isXs || $rootScope.screenSize.isSm;

    function showHelp() {
      userGuideService.open('search_operators');
    }

    function getAggregationCountByType(type) {
      var typeAggregations = _.get(vm, 'currentPage.aggregations.type');
      return _.find(typeAggregations, ['key', type]).count;
    }

    function isEmpty(object) {
      return _.size(object) === 0;
    }

    function getType(typeName) {
      return coyoConfig.entityTypes[typeName] || coyoConfig.entityTypes.default;
    }

    function clickHandler(result) {
      if (_isMessage(result) && result.canLinkToResult) {
        _openMessage(result);
      } else if (_isFile(result) && result.canLinkToResult) {
        _openFile(result);
      }
    }

    function changeSorting(sortingOption) {
      $rootScope.search.activeSortingOption = sortingOption;
      search(0, true);
    }

    function selectFilter(selected, key) {
      var filter = $rootScope.search.filter;
      var selectedFilter = _.map(selected, 'key');
      if (selectedFilter.length > 0) {
        filter[key] = selectedFilter;
      } else {
        delete filter[key];
      }
      return search(0, true);
    }

    function clearAllFilters() {
      $rootScope.search.filter = {};
      return search(0, true);
    }

    function setNewTerm(newTerm) {
      $rootScope.search.term = newTerm;
      search(0, true);
    }

    function focusSearch() {
      $rootScope.search.visible = true;
      var searchField = angular.element(document).find('#global-search-input-field');
      if (searchField) {
        searchField.focus();
      }
    }

    function search(page, writeToUrl) {
      if (vm.loading || !$rootScope.search.term) {
        _updateViewVariables();
        return $q.reject();
      }

      _setIsGroupedSearch();
      _setIsFiltered();
      vm.loading = true;

      // write params to URL
      if (writeToUrl) {
        return $state.transitionTo('main.search', angular.extend({
          term: $rootScope.search.term,
          sort: $rootScope.search.activeSortingOption.key
        }, _.mapKeys($rootScope.search.filter, function (value, key) {
          return key + '[]';
        })), {notify: false}).finally(function () {
          vm.loading = false;
          vm.showSearchResults = true;
          _updateViewVariables();
        });
      } else {
        // execute search
        var searchUrl = vm.isGroupedSearch
          ? coyoEndpoints.search.groupedQuery
          : coyoEndpoints.search.query.replace('{page}', page).replace('{pageSize}', 20);
        var request = {
          method: 'GET',
          url: searchUrl,
          params: {
            term: $rootScope.search.term,
            filters: $httpParamSerializer($rootScope.search.filter),
            aggregations: $httpParamSerializer({
              type: _.keys(coyoConfig.entityTypes).length,
              modified: '',
              sender: 100,
              author: 100
            }),
            sort: $rootScope.search.activeSortingOption.key,
            newSearch: $rootScope.search.new
          }
        };

        return $http(request).then(function (result) {

          vm.currentPage = new Page(result.data, {
            _page: page,
            _pageSize: 20
          });
          vm.groupedSearchResults = _.get(result, 'data.groups');

          // permission checks
          var searchResults = vm.isGroupedSearch
            ? _.flatMap(_.values(result.data.groups))
            : result.data.content;

          _.forEach(searchResults, function (result) {
            targetService.onCanLinkTo(result.target, function (canLink) {
              result.canLinkToResult = canLink;
              if (!_isMessage(result) && !_isFile(result)) {
                vm.links.results[result.id] = _getResultLink(result);
              }
            });
            if (result.sender) {
              targetService.onCanLinkTo(result.sender.target, function (canLink) {
                result.canLinkToSender = canLink;
                vm.links.senders[result.sender.id] = _getSenderLink(result);
              });
            }
          });

          vm.typeAggregationTotal = _.sumBy(vm.currentPage.aggregations.type, 'count');

          vm.currentUserAuthorCount =
              _.get(_.find(vm.currentPage.aggregations.author, ['key', vm.currentUser.id]), 'count', 0);
          vm.currentUserSenderCount =
              _.get(_.find(vm.currentPage.aggregations.sender, ['key', vm.currentUser.id]), 'count', 0);

          _initFilters();

          vm.error = false;
          $rootScope.search.new = false;
        }).catch(function (errorResponse) {
          if (_.get(errorResponse, 'data.errorStatus') === 'INVALID_SEARCH_REQUEST') {
            errorService.suppressNotification(errorResponse);
            vm.error = true;
          }
        }).finally(function () {
          vm.loading = false;
          vm.showSearchResults = true;
          _updateViewVariables();
        });
      }
    }

    function sortFilter(item) {
      return item.key === vm.currentUser.id ? 0 : 1;
    }

    function init() {
      // extract search params from URL
      $rootScope.search = angular.extend($rootScope.search || {}, {
        visible: true,
        term: $stateParams.term,
        activeSortingOption: _getActiveSortingOptionFromStateParams(),
        filter: _.chain($stateParams).pickBy(function (value, key) {
          return _.endsWith(key, '[]') && !!value;
        }).mapKeys(function (value, key) {
          return key.slice(0, -2);
        }).value()
      });

      // execute first search request
      search(0, false);
    }

    // ================================

    function _getActiveSortingOptionFromStateParams() {
      var activeSearchSortingOption;
      if ($stateParams.sort) {
        activeSearchSortingOption = _.find(vm.searchSortingOptions, _isActiveSortingKey);
      }
      return activeSearchSortingOption
        ? activeSearchSortingOption
        : _.find(vm.searchSortingOptions, _isDefaultSortingKey);

      function _isActiveSortingKey(searchSortingOption) {
        return searchSortingOption.key === $stateParams.sort;
      }
      function _isDefaultSortingKey(searchSortingOption) {
        return searchSortingOption.key === vm.defaultSearchSortingOptionKey;
      }
    }

    function _openMessage(message) {
      if (message.canLinkToResult) {
        targetService.go(message.target);
      }
    }

    function _openFile(file) {
      targetService.go(file.target);
    }

    function _isMessage(result) {
      return result.typeName === 'message';
    }

    function _isFile(result) {
      return result.typeName === 'file';
    }

    function _initFilters() {
      _initTypeFilter();
      _initModifiedFilter();
      _initSenderFilter();
      _initAuthorFilter();
      _sortFiltersAlphabetically();
    }

    function _initTypeFilter() {
      vm.typeFilter = _initFilter('type', function (aggregation) {
        return {
          text: $translate.instant(getType(aggregation.key).label),
          icon: vm.getType(aggregation.key).matIcon,
          iconColor: vm.getType(aggregation.key).color
        };
      }, vm.typeAggregationTotal);
    }

    function _sortFiltersAlphabetically() {
      vm.typeFilter.items = _.orderBy(vm.typeFilter.items, 'text', 'asc');
      vm.authorFilter.items = _.orderBy(vm.authorFilter.items, 'text', 'asc');
      vm.senderFilter.items = _.orderBy(vm.senderFilter.items, 'text', 'asc');
    }

    function _initModifiedFilter() {
      vm.modifiedFilter = _initFilter('modified', function (aggregation) {
        return {
          order: aggregation.data.total,
          icon: 'clock-outlined'
        };
      }, vm.currentPage.totalElements);
      vm.modifiedFilter.allItem.textKey = 'MODULE.SEARCH.FILTER.MODIFIED.EVER';
    }

    function _initSenderFilter() {
      vm.senderFilter = _initFilter('sender', function (aggregation) {
        return {
          text: _getSenderNameOrOwnTimelineText(aggregation),
          icon: vm.getType(aggregation.data.typeName).matIcon,
          iconColor: vm.getType(aggregation.data.typeName).color
        };
      });
      vm.senderFilter.allItem.textKey = 'MODULE.SEARCH.FILTER.LOCATION.ANY';
    }

    function _initAuthorFilter() {
      vm.authorFilter = _initFilter('author', function (aggregation) {
        return {
          text: _getSenderNameOrSelfText(aggregation),
          icon: vm.getType(aggregation.data.typeName).matIcon,
          iconColor: vm.getType(aggregation.data.typeName).color
        };
      });

      vm.authorFilter.allItem.textKey = 'MODULE.SEARCH.FILTER.AUTHOR.ANY';
      vm.authorFilter.allIcon = 'zmdi-accounts-alt';
    }

    function _getSenderNameOrSelfText(aggregation) {
      return _getSenderNameOrAlternativeText(aggregation, 'MODULE.SEARCH.FILTER.AUTHOR.MYSELF');
    }

    function _getSenderNameOrOwnTimelineText(aggregation) {
      return _getSenderNameOrAlternativeText(aggregation, 'MODULE.SEARCH.FILTER.SENDER.MYSELF');
    }

    function _getSenderNameOrAlternativeText(aggregation, alternativeTextMessageKey) {
      if (aggregation.key === vm.currentUser.id) {
        return $translate.instant(alternativeTextMessageKey);
      } else {
        return aggregation.data.displayName;
      }
    }

    function _initFilter(aggregationName, optionsFunction, count) {
      var itemConfigs = _createFilterConfig(aggregationName, optionsFunction);
      return selectionFilterService.builder().count(count).items(itemConfigs).build();
    }

    function _createFilterConfig(aggregationName, optionsFunction) {
      if (!angular.isArray(vm.currentPage.aggregations[aggregationName])) {
        return [];
      }
      var filter = $rootScope.search.filter[aggregationName];
      return vm.currentPage.aggregations[aggregationName].map(function (aggregation) {
        return angular.extend({
          key: aggregation.key,
          active: filter && filter.indexOf(aggregation.key) >= 0,
          count: aggregation.count
        }, optionsFunction(aggregation));
      });
    }

    function _getResultLink(result) {
      if (result.canLinkToResult) {
        return targetService.getLink(result.target);
      } else {
        return '';
      }
    }

    function _getSenderLink(result) {
      if (result.sender && result.canLinkToSender) {
        return targetService.getLink(result.sender.target);
      } else {
        return '';
      }
    }

    function _hasCoyoSearchResults() {
      return _.get(vm, 'currentPage.totalElements') > 0;
    }

    function _hasSuggestions() {
      return _.get(vm, 'currentPage.suggestions.length') > 0;
    }

    function _setHasAnySearchResult() {
      vm.hasAnySearchResult = _hasCoyoSearchResults();
    }

    function _setHasCoyoSearchResults() {
      vm.hasCoyoSearchResults = _hasCoyoSearchResults();
    }

    function _setHasSuggestions() {
      vm.hasSuggestions = _hasSuggestions();
    }

    function _setIsFiltered() {
      vm.isFiltered = !_.isEmpty($rootScope.search.filter);
    }

    function _setIsGroupedSearch() {
      var searchFilter = _.get($rootScope, 'search.filter');
      vm.isGroupedSearch = _.isEmpty(searchFilter) || !_.has(searchFilter, 'type');
    }

    function _setShowEmptySearchAndSuggestionsPanel() {
      vm.showEmptySearchAndSuggestionsPanel = vm.showSearchResults && !vm.hasAnySearchResult || vm.hasSuggestions;
    }

    function _setShowSearchResultsPanel() {
      vm.showSearchResultsPanel = vm.showSearchResults && vm.hasAnySearchResult;
    }

    function _setShowSearchTermPanel() {
      vm.showSearchTermPanel = vm.hasAnySearchResult;
    }

    function _updateViewVariables() {
      _setHasCoyoSearchResults();
      _setHasAnySearchResult();
      _setHasSuggestions();
      _setIsFiltered();
      _setIsGroupedSearch();
      $timeout(function () {
        _setShowEmptySearchAndSuggestionsPanel();
        _setShowSearchResultsPanel();
        _setShowSearchTermPanel();
      });
    }
  }

})(angular);

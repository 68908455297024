import {Compiler, ComponentFactory, Injectable, Injector} from '@angular/core';
import {FilePreviewAnimationComponent} from '@shared/preview/file-preview/animation/file-preview-animation/file-preview-animation.component';

@Injectable({
  providedIn: 'root'
})
export class FilePreviewAnimationLoader {
  constructor(private compiler: Compiler, private injector: Injector) {}

  load(): Promise<ComponentFactory<FilePreviewAnimationComponent>> {
    return import('../file-preview-animation-lazy.module')
      .then(m => m.FilePreviewAnimationLazyModule)
      .then(lazyModule =>
        this.compiler.compileModuleAsync(lazyModule).then(ngModuleFactory => {
          const moduleRef = ngModuleFactory.create(this.injector);
          const factory = moduleRef.componentFactoryResolver.resolveComponentFactory(FilePreviewAnimationComponent);
          return factory;
        }));
  }
}

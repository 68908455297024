import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IndividualAppSettingsComponent} from '@apps/api/individual-app-settings/individual-app-settings.component';
import {
  DocumentAppSettings,
  EDITORS_ADMIN,
  EDITORS_ALL,
  NOTIFICATION_ADMIN,
  NOTIFICATION_ALL,
  NOTIFICATION_NONE
} from '@apps/document/document-app.settings';

@Component({
  selector: 'coyo-document-app-settings',
  templateUrl: './document-app-settings.component.html',
  styleUrls: ['./document-app-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentAppSettingsComponent extends IndividualAppSettingsComponent<DocumentAppSettings> implements OnInit {

  constructor() {
    super();
  }

  get notificationAll(): string {
    return NOTIFICATION_ALL;
  }

  get notificationAdmin(): string {
    return NOTIFICATION_ADMIN;
  }

  get notificationNone(): string {
    return NOTIFICATION_NONE;
  }

  get editorsAll(): string {
    return EDITORS_ALL;
  }

  get editorsAdmin(): string {
    return EDITORS_ADMIN;
  }

  onBeforeSave(settings?: any): any {
    return {
      ...settings, folderPermissions: {
        modifyRole: this.parentForm.controls['modifyRole'].value === this.editorsAdmin ? this.editorsAdmin : this.editorsAll
      }
    };
  }

  ngOnInit(): void {
    const showAuthors = this.app?.settings?.showAuthors ?? true;
    const notification = this.app?.settings?.notification ?? this.notificationNone;
    const modifyRole = this.app?.settings?.modifyRole === this.editorsAdmin ? this.editorsAdmin : this.editorsAll;

    this.parentForm.addControl('showAuthors', new FormControl(showAuthors));
    this.parentForm.addControl('notification', new FormControl(notification));
    this.parentForm.addControl('modifyRole', new FormControl(modifyRole));
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Skeleton} from '@coyo/ui';
import {Pageable} from '@domain/pagination/pageable';
import {Search} from '@domain/pagination/search';
import {Sender} from '@domain/sender/sender';
import {TargetService} from '@domain/sender/target/target.service';
import {SubscriptionService} from '@domain/subscription/subscription.service';
import {WidgetComponent} from '@widgets/api/widget-component';
import {SubscriptionWidget} from '@widgets/subscription/subscription-widget';
import * as _ from 'lodash';
import {NgxPermissionsService} from 'ngx-permissions';
import {BehaviorSubject, combineLatest, from, Observable} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';

interface SubscriptionState {
  isLoading: boolean;
  autoSubscribe: string[];
  favorite: string[];
  content: Sender[];
  last: boolean;
}

interface SubscriptionSection {
  permission: string;
  type: string;
  i18n: string;
  favorite: boolean;
  state$: BehaviorSubject<SubscriptionState>;
}

/**
 * This widget displays the subscriptions of the current user.
 */
@Component({
  selector: 'coyo-subscription-widget',
  templateUrl: './subscription-widget.component.html',
  styleUrls: ['./subscription-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionWidgetComponent extends WidgetComponent<SubscriptionWidget> implements OnInit {
  private static readonly pageSizeInit: number = 5;
  private static readonly pageSizeSucc: number = 20;

  private linkCache: { [id: string]: string; } = {};

  private readonly sections: SubscriptionSection[] = [{
    permission: 'ACCESS_PAGES',
    type: 'page',
    i18n: 'PAGE',
    favorite: false,
    state$: this.initState()
  }, {
    permission: 'ACCESS_WORKSPACES',
    type: 'workspace',
    i18n: 'WORKSPACE',
    favorite: true,
    state$: this.initState()
  }];

  sections$: Observable<SubscriptionSection[]>;

  readonly skeletons: Skeleton[] = [
    { top: 12, left: 0, width: 16, height: 16 },
    { top: 10, left: 24, width: 'calc(75% - 24px)', height: 20 },
    { top: 52, left: 0, width: 16, height: 16 },
    { top: 50, left: 24, width: 'calc(60% - 24px)', height: 20 },
    { top: 92, left: 0, width: 16, height: 16 },
    { top: 90, left: 24, width: 'calc(90% - 24px)', height: 20 }];

  constructor(private subscriptionService: SubscriptionService,
              private targetService: TargetService,
              private permissionsService: NgxPermissionsService,
              cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.sections$ = combineLatest(this.sections.map(section => from(this.permissionsService.hasPermission(section.permission))))
      .pipe(map(permissions => permissions
        .map((permission, i) => permission ? this.sections[i] : null)
        .filter(section => !!section)
      )).pipe(tap(sections => _.forEach(sections, section => this.load(section))));
  }

  /**
   * Loads the next page of senders for the given section.
   *
   * @param section the section to load
   */
  load(section: SubscriptionSection): void {
    const state = section.state$.getValue();
    if (state.isLoading || state.last) {
      return;
    }
    this.updateState(section.state$, {isLoading: true});

    const search = new Search('', {type: [section.type]});
    const pageSize = state.content.length
      ? SubscriptionWidgetComponent.pageSizeSucc
      : SubscriptionWidgetComponent.pageSizeInit;
    const pageable = new Pageable(0, pageSize, state.content.length);
    this.subscriptionService.getSubscribedSenders(search, pageable)
      .pipe(finalize(() => this.updateState(section.state$, {isLoading: false})))
      .subscribe(result => this.updateState(section.state$, {
        autoSubscribe: state.autoSubscribe.concat(result.data.autoSubscribe),
        favorite: state.favorite.concat(result.data.favorite),
        content: state.content.concat(result.page.content),
        last: state.content.length + result.page.numberOfElements >= result.page.totalElements
      }));
  }

  /**
   * Checks if the sender is auto subscribed.
   *
   * @param section the corresponding section
   * @param sender the sender to check
   * @return the subscription state
   */
  isAutoSubscribe(section: SubscriptionSection, sender: Sender): boolean {
    const state = section.state$.getValue();
    return _.includes(state.autoSubscribe, sender.id);
  }

  /**
   * Checks if the sender is a favorite.
   *
   * @param section the corresponding section
   * @param sender the sender to check
   * @return the favorite state
   */
  isFavorite(section: SubscriptionSection, sender: Sender): boolean {
    const state = section.state$.getValue();
    return _.includes(state.favorite, sender.id);
  }

  /**
   * Retrieves the icon for the given sender.
   *
   * @param section the corresponding section
   * @param sender the sender to check
   * @return the icon name
   */
  getIcon(section: SubscriptionSection, sender: Sender): string {
    return this.isFavorite(section, sender)
      ? 'star'
      : this.isAutoSubscribe(section, sender)
        ? 'pin'
        : section.type;
  }

  /**
   * Retrieves the link to the given sender.
   *
   * @param sender the sender
   * @return the link URL
   */
  getLink(sender: Sender): string {
    let link = this.linkCache[sender.id];
    if (!link) {
      link = this.targetService.getLinkTo(sender.target);
      this.linkCache[sender.id] = link;
    }
    return link;
  }

  /**
   * Toggles the favorite state of the given sender in the section.
   *
   * @param section the corresponding section
   * @param sender the sender to toggle the status for
   * @param $event the click event
   */
  toggleFavorite(section: SubscriptionSection, sender: Sender, $event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    const state = section.state$.getValue();
    const favorite = this.isFavorite(section, sender);
    this.subscriptionService.setFavorite(sender.id, !favorite)
      .subscribe(() => this.updateState(section.state$, {
        favorite: favorite
          ? _.reject(state.favorite, fav => fav === sender.id)
          : _.concat(state.favorite, sender.id)
      }));
  }

  private initState(): BehaviorSubject<SubscriptionState> {
    return new BehaviorSubject({
      isLoading: false,
      autoSubscribe: [],
      favorite: [],
      content: [],
      last: false
    });
  }

  private updateState(state$: BehaviorSubject<SubscriptionState>, data: Partial<SubscriptionState>): void {
    state$.next({...state$.getValue(), ...data});
  }
}

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LatestFilesResponseBody} from '@widgets/latest-files/latest-files-response-body';
import {Observable} from 'rxjs';

/**
 * Service for the Last-Files widget
 */
@Injectable({
  providedIn: 'root'
})
export class LatestFilesService {
  constructor(private httpClient: HttpClient) {

  }

  /**
   * Requests a list of "latest files" for the given App
   *
   * @param appId The if of the app containing the files
   * @param count The number of files to return
   *
   * @returns A list of
   */
  getLatestFiles(appId: string, count: number): Observable<LatestFilesResponseBody> {
    return this.httpClient.get<LatestFilesResponseBody>('/web/widgets/latest-files', {
      headers: new HttpHeaders({
        handleErrors: 'false'
      }),
      params: {
        appId: appId,
        count: count.toString()
      }
    });
  }
}

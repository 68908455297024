import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AnalyticsEvent} from '@app/analytics/event-distributor/analytics-event';
import {EventConverter} from '@app/analytics/event-distributor/event-converter';
import {EventTypes} from '@app/analytics/event-types';
import {LocalStorageService} from '@core/storage/local-storage/local-storage.service';
import * as _ from 'lodash';
import {EMPTY, merge, Observable, timer} from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';

/**
 * Service that collects and sends events to COYO Analytics.
 * The events are temporarily stored in the local storage.
 * In regular intervals, these events are sent to COYO Analytics. The local storage will be cleared afterwards.
 */
@Injectable({
  providedIn: 'root'
})
export class EventDistributionService {
  static readonly EVENT_STORAGE_LIMIT: number = 1000;
  static readonly SEND_INTERVAL_MS: number = 60 * 1000;

  readonly urlByEventType: { [type in EventTypes]: string } = {
    userActivity: '/web/analytics/consumptions/user-activity',
    userReached: '/web/analytics/consumptions/user-reached',
    entityVisited: '/web/analytics/consumptions/entity-visited'
  };

  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) {
    timer(0, EventDistributionService.SEND_INTERVAL_MS)
      .pipe(mergeMap(() => this.sendEvents()))
      .subscribe();
  }

  /**
   * Adds an event that will be transmitted as a batch in regular intervals
   * @param eventType of the event
   * @param event the event
   */
  addEvent(eventType: EventTypes, event: AnalyticsEvent): void {
    this.setEventsBatch(eventType, EventConverter.toLocalStorageString(eventType, event));
  }

  private getEventsBatch(): EventsBatch {
    return this.localStorageService.getValue<any>('events', {});
  }

  private setEventsBatch(eventType: string, event: string): void {
    const eventsBatch = this.getEventsBatch();
    if (this.getEventBatchSize(eventsBatch) < EventDistributionService.EVENT_STORAGE_LIMIT) {
      this.localStorageService.setValue('events', this.updateEventsBatch(eventType, event, eventsBatch));
    }
  }

  private updateEventsBatch(eventType: string, event: string, eventsBatch: EventsBatch): EventsBatch {
    if (!!eventsBatch[eventType]) {
      eventsBatch[eventType].push(event);
    } else {
      eventsBatch[eventType] = [event];
    }
    return eventsBatch;
  }

  private sendEvents(): Observable<void> {
    const eventsBatch = this.getEventsBatch();
    if (!eventsBatch || _.isEmpty(eventsBatch)) {
      return EMPTY;
    }
    const headers = {handleErrors: 'false'};

    const requests =
      Object.values(EventTypes)
        .filter(type => !_.isEmpty(eventsBatch[type]))
        .map(type => this.sendToAnalyticsAdapter(eventsBatch[type], type, this.urlByEventType[type], headers));

    return merge(...requests);
  }

  private sendToAnalyticsAdapter(eventStrings: string[],
                                 eventType: EventTypes,
                                 url: string,
                                 headers: { handleErrors: string }): Observable<void> {
    const events = eventStrings
      .filter(event => typeof event === 'string')
      .map(eventString => EventConverter.fromLocalStorageString(eventType, eventString));

    return this.httpClient
      .post<void>(url, events, {headers})
      .pipe(
        tap(() => this.removeEventsFromBatchByType(eventType)),
        catchError(() => EMPTY)
      );
  }

  private removeEventsFromBatchByType(eventType: string): void {
    const eventsBatch = this.getEventsBatch();
    eventsBatch[eventType] = [];
    this.localStorageService.setValue('events', eventsBatch);
  }

  private getEventBatchSize(eventBatch: EventsBatch): number {
    return eventBatch.userActivity ? eventBatch.userActivity.length : 0;
  }
}

type EventsBatch = { [type in EventTypes]: string[] };

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NewsViewParams} from '@app/engage/news/news-view-params';
import {AuthService} from '@core/auth/auth.service';
import {BlogArticle} from '@domain/blog-article/blog-article';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {User} from '@domain/user/user';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

interface BlogViewState {
  author: User;
  sender: Sender;
}

/**
 * Component for rendering the engage blog article view.
 */
@Component({
  selector: 'coyo-blog-article-view',
  templateUrl: './blog-article-view.component.html',
  styleUrls: ['./blog-article-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleViewComponent implements OnInit {

  /**
   * The blog article.
   */
  @Input() article: BlogArticle;

  /**
   * The parameters how to display the blog article.
   */
  @Input() params: NewsViewParams;

  state$: Observable<BlogViewState>;

  constructor(private senderService: SenderService, private authService: AuthService) { }

  ngOnInit(): void {
    this.state$ = combineLatest([this.senderService.get(this.article.senderId), this.authService.getUser()])
      .pipe(map(([sender, author]) => ({sender, author})));
  }

  /**
   * Checks if the social features should be shown depending on the permissions and the engage app flags.
   *
   * @return true if the component should show the like button and the comments
   */
  showSocialFeatures(): boolean {
    return !this.params.hideSocialFeatures && (this.article._permissions.like || this.article._permissions.comment);
  }

  showTeaserImage(): boolean {
    return this.article.teaserImage && !this.params.hideTeaserImg && this.article.showTeaserWithText;
  }

  showTeaserText(): boolean {
    return !this.params.hideTeaserText && this.article.showTeaserWithText;
  }
}

import {NgModule} from '@angular/core';
import {SelectAppComponent} from '@shared/app-ui/select-app.component';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SelectUiModule} from '@shared/select-ui/select-ui.module';

/**
 * Shared module containing all components responsible for sender ui
 */
@NgModule({
  imports: [
    CoyoFormsModule,
    CoyoCommonsModule,
    SelectUiModule
  ],
  declarations: [
    SelectAppComponent
  ],
  exports: [
    SelectAppComponent
  ],
  entryComponents: [
    SelectAppComponent
  ]
})
export class AppUiModule {
}

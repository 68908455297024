import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {WidgetLayoutResponse} from '@widgets/api/widget-layout/widget-layout-response';

@Injectable({
  providedIn: 'root'
})
export class WidgetLayoutService extends DomainService<any, WidgetLayoutResponse> {

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/widget-layouts';
  }
}

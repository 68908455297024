<ng-container *ngIf="state$ | async; let state">
  <div class="attachment-icon">
    <i class="zmdi zmdi-attachment-alt" aria-hidden="true"></i>
  </div>
  <div class="file-visibility-status" *ngIf="isFileProtected()" translate="MODULE.TIMELINE.ATTACHMENT.VISIBILITY.PROTECTED"></div>
  <div class="attachment-name">
    {{ getFileName() }}
  </div>
  <div class="attachment-options">
    <span class="attachment-upload" *ngIf="!state.completed">{{state.progress}}%</span>
    <button type="button" class="coyo-btn coyo-btn-sm coyo-btn-link coyo-btn-icon attachment-close" *ngIf="state.completed" (click)="removeFile(fileItem)">
      <i class="zmdi zmdi-close" aria-hidden="true"></i>
      <span class="sr-only" translate="MODULE.TIMELINE.ATTACHMENT.DELETE.ARIA"></span>
    </button>
  </div>
</ng-container>

import {NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SelectUiComponent} from '@shared/select-ui/select-ui.component';
import {SelectUiComponentState} from '@shared/select-ui/state/select-ui-component.state';

export const ngxsModule = NgxsModule.forFeature([SelectUiComponentState]);

/**
 * This module provides a Base Select UI Component to be used by more specific selection components.
 * It allows for custom templating and styling of items, custom search and filtering. All state handling
 * is done within this component.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    NgSelectModule,
    ngxsModule
  ],
  declarations: [
    SelectUiComponent
  ],
  exports: [
    SelectUiComponent
  ]
})
export class SelectUiModule {
}

(function (angular) {
  'use strict';

  formService.$inject = ["fieldValueService", "FormEntryModel"];
  angular
      .module('coyo.apps.form')
      .factory('formService', formService);

  /**
   * @ngdoc service
   * @name coyo.apps.form.formService
   *
   * @description
   * Service for common functions of the form that must be shared between different controllers
   */
  function formService(fieldValueService, FormEntryModel) {

    return angular.extend({
      createEntry: createEntry,
    }, fieldValueService);

    /**
     * @ngdoc method
     * @name coyo.apps.form.formService#createEntry
     * @methodOf coyo.apps.form.formService
     *
     * @description
     * Create entry model with missing field values
     *
     * @returns {object}
     * Form entry model
     */
    function createEntry() {
      return new FormEntryModel({
        values: []
      });
    }
  }
})(angular);


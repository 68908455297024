(function () {
  'use strict';

  listDetailModalService.$inject = ["modalService"];
  ListDetailModalController.$inject = ["$stateParams", "listEntryDetailsService", "SenderModel", "app", "fieldTypeRegistry", "fields", "entry", "mode", "listService", "errorService", "currentUser"];
  angular.module('coyo.apps.list')
      .factory('listDetailModalService', listDetailModalService)
      .controller('ListDetailModalController', ListDetailModalController);

  function listDetailModalService(modalService) {

    return {
      open: open
    };

    function open(app, entry, mode) {
      return modalService.open({
        size: 'lg',
        templateUrl: 'app/apps/list/components/list-detail-modal.html',
        controller: 'ListDetailModalController',
        controllerAs: '$ctrl',
        resolve: {
          app: function () {
            return app;
          },
          fields: /*@ngInject*/ ["FieldModel", function (FieldModel) {
            var context = {
              senderId: app.senderId,
              appId: app.id,
              appKey: app.key
            };
            return FieldModel.get(context);
          }],
          entry: function () {
            return entry;
          },
          mode: function () {
            return mode;
          },
          currentUser: /*@ngInject*/ ["authService", function (authService) {
            return authService.getUser();
          }]
        }
      }).result;
    }
  }

  function ListDetailModalController($stateParams, listEntryDetailsService, SenderModel, app, fieldTypeRegistry,
                                     fields, entry, mode, listService, errorService, currentUser) {
    var vm = this;
    vm.currentUser = currentUser;
    vm.app = app;
    vm.fields = fields;
    vm.entry = listService.initEntry(entry, vm.fields);
    vm.entrySubscribable = {id: entry.id, typeName: entry.typeName, senderId: app.senderId};

    vm.getValue = getValue;
    vm.switchTab = switchTab;
    vm.enableEditMode = enableEditMode;
    vm.$onInit = onInit;
    vm.updateHistory = updateHistory;

    function onInit() {
      if (mode === 'edit') {
        enableEditMode();
      } else {
        vm.activeTab = 'details';
      }
      updateHistory(vm.entry);

      vm.appFormSettings = {noAppContext: app.settings.permissionRead !== 'ALL'};

      listEntryDetailsService.registerEditCompleteHandler(onRegisterComplete);
    }

    function updateHistory(entry) {
      vm.history = {
        created: new Date(entry.created),
        modified: new Date(entry.modified),
        author: {},
        editor: {}
      };

      SenderModel.get(entry.author.id).then(function (author) {
        vm.history.author = author;
      }).catch(function (errorResponse) {
        errorService.suppressNotification(errorResponse);
        errorService.getMessage(errorResponse).then(function (message) {
          vm.history.author.senderErrorMessage = message;
        });
      });
      SenderModel.get(entry.editorId).then(function (editor) {
        vm.history.editor = editor;
      }).catch(function (errorResponse) {
        errorService.suppressNotification(errorResponse);
        errorService.getMessage(errorResponse).then(function (message) {
          vm.history.editor.senderErrorMessage = message;
        });
      });
    }

    function onRegisterComplete(entry) {
      vm.entry = entry;
      updateHistory(entry);
      vm.switchTab('details');
    }

    function switchTab(tab) {
      vm.activeTab = tab;
    }

    function enableEditMode() {
      listEntryDetailsService.setCurrentContext({
        senderId: app.senderId,
        appId: app.id,
        id: $stateParams.id
      });
      vm.activeTab = 'edit';
    }

    function getValue(entry, fieldId) {
      var find = listService.getFieldValue(entry, fieldId);
      return find && find.value;
    }
  }
})();

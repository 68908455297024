(function (angular) {
  'use strict';

  registerLdap.$inject = ["userDirectoryTypeRegistryProvider"];
  registerAd.$inject = ["userDirectoryTypeRegistryProvider"];
  registerCsv.$inject = ["userDirectoryTypeRegistryProvider"];
  registerMsGraph.$inject = ["userDirectoryTypeRegistryProvider"];
  registerGoogle.$inject = ["userDirectoryTypeRegistryProvider"];
  angular
      .module('coyo.admin.userDirectories.ldap', [
        'coyo.base',
        'coyo.domain',
        'coyo.admin.userDirectories.api',
        'commons.i18n.custom'
      ])
      .config(registerLdap)
      .config(registerAd)
      .config(registerCsv)
      .config(registerMsGraph)
      .config(registerGoogle);

  function registerLdap(userDirectoryTypeRegistryProvider) {
    userDirectoryTypeRegistryProvider.register({
      key: 'ldap',
      name: 'ADMIN.USER_DIRECTORIES.LDAP.NAME',
      description: 'ADMIN.USER_DIRECTORIES.LDAP.DESCRIPTION',
      directive: 'oyoc-ldap-settings'
    });
  }

  function registerAd(userDirectoryTypeRegistryProvider) {
    userDirectoryTypeRegistryProvider.register({
      key: 'ad',
      name: 'ADMIN.USER_DIRECTORIES.AD.NAME',
      description: 'ADMIN.USER_DIRECTORIES.AD.DESCRIPTION',
      directive: 'oyoc-ldap-settings'
    });
  }

  function registerCsv(userDirectoryTypeRegistryProvider) {
    userDirectoryTypeRegistryProvider.register({
      key: 'csv',
      name: 'ADMIN.USER_DIRECTORIES.CSV.NAME',
      description: 'ADMIN.USER_DIRECTORIES.CSV.DESCRIPTION',
      directive: 'oyoc-csv-settings'
    });
  }

  function registerMsGraph(userDirectoryTypeRegistryProvider) {
    userDirectoryTypeRegistryProvider.register({
      key: 'msgraph',
      name: 'ADMIN.USER_DIRECTORIES.MSGRAPH.NAME',
      description: 'ADMIN.USER_DIRECTORIES.MSGRAPH.DESCRIPTION',
      directive: 'oyoc-ms-graph-settings'
    });
  }

  function registerGoogle(userDirectoryTypeRegistryProvider) {
    userDirectoryTypeRegistryProvider.register({
      key: 'google',
      name: 'ADMIN.USER_DIRECTORIES.GOOGLE.NAME',
      description: 'ADMIN.USER_DIRECTORIES.GOOGLE.DESCRIPTION',
      directive: 'oyoc-google-settings'
    });
  }

})(angular);

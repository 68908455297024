import {Pipe, PipeTransform} from '@angular/core';
import {SenderEvent} from '@domain/event/sender-event';
import * as moment from 'moment';

@Pipe({
  name: 'showDivider',
  pure: true
})
export class ShowDividerPipe implements PipeTransform {

  /**
   * Determines whether the divider should be displayed or not.
   *
   * @param events The events.
   * @param index the index of the current list element.
   * @returns true if the divider should be displayed, else false
   */
  transform(events: SenderEvent[], index: number): boolean {
    const currentEvent = events[index];
    const previousEvent = events[index - 1];
    return !previousEvent || !moment(previousEvent.startDate).isSame(currentEvent.startDate, 'day');
  }

}

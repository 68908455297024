import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

/**
 * Filter search component.
 *
 * @description
 * Renders a search field for a filter.
 *
 * @example
 * <coyo-filter-search (search)="..." [placeholder]="..." [term]="...">...</coyo-filter-search>
 */
@Component({
  selector: 'coyo-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSearchComponent implements OnInit, OnDestroy {

  /**
   * A given placeholder that should be displayed.
   */
  @Input()
  placeholder: string;

  /**
   * The search term that should be displayed.
   */
  @Input()
  term?: string = '';

  /**
   * Event emitter for the search.
   */
  @Output() search: EventEmitter<string> = new EventEmitter();

  private searchTermChanged: Subject<string> = new Subject<string>();

  isFocused: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.searchTermChanged
      .pipe(debounceTime(750))
      .pipe(distinctUntilChanged())
      .subscribe((value: string) => {
        this.search.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.searchTermChanged.complete();
  }

  onChange(updatedSearchTerm: string): void {
    this.searchTermChanged.next(updatedSearchTerm);
  }

  onFocus(): void {
    this.isFocused = true;
  }

  onBlur(): void {
    this.isFocused = false;
  }
}

<form [formGroup]="bookmarkForm" class="flex" (ngSubmit)="nextStep()">
  <div class="add-icon" aria-hidden="true">
    <i class="zmdi zmdi-plus-circle-o"
       [ngClass]="{'has-error': bookmarkForm.touched && bookmarkForm.dirty && bookmarkForm.invalid}"></i>
  </div>

  <div class="bookmark flex">
    <div class="bookmark-url" *ngIf="step === 'URL'" [class.has-error]="url.touched && url.dirty && url.invalid">
      <input *ngIf="linkPattern" formControlName="url" #urlInput
             class="inplace-edit"
             placeholder="https://"
             [attr.aria-label]="'WIDGETS.BOOKMARKING.URL.ARIA' | translate"
             type="url"
             required aria-required="true"
             autocomplete="off"
             [attr.aria-invalid]="url.invalid">
      <div class="sr-only" *ngIf="url.invalid">
        <coyo-validation-errors [errors]="url.errors"></coyo-validation-errors>
      </div>
    </div>

    <div class="bookmark-title" *ngIf="step === 'TITLE'">
      <input formControlName="title" #titleInput
             class="inplace-edit"
             [attr.placeholder]="'WIDGETS.BOOKMARKING.TITLE.PLACEHOLDER' | translate"
             [attr.aria-label]="'WIDGETS.BOOKMARKING.TITLE.ARIA' | translate"
             type="text"
             maxlength="50"
             autocomplete="off">
    </div>
  </div>

  <div class="check-icon" (click)="nextStep()"
       [matTooltip]="'WIDGETS.BOOKMARKING.EDIT.NEXT_FROM.' + step | translate">
    <i class="zmdi" [ngClass]="{'zmdi-check': step === 'TITLE', 'zmdi-arrow-right': step === 'URL'}" aria-hidden="true"></i>
  </div>
</form>

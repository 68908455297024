<ng-container *ngIf="canCreateFolder$ | async">
  <button (click)="createFolder()"
          class="hidden-xs"
          mat-button
          size="small">
    <mat-icon size="small" svgIcon="folder"></mat-icon>
    {{'FILE_LIBRARY.ACTIONS.NEW_FOLDER' | translate}}
  </button>
  <button (click)="createFolder()"
          class="visible-xs"
          mat-icon-button
          size="small">
    <mat-icon svgIcon="folder"></mat-icon>
  </button>
</ng-container>

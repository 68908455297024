import {HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Ng1AuthService} from '@root/typings';
import {NG1_AUTH_SERVICE, NG1_LOCAL_STORAGE} from '@upgrade/upgrade.module';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';
import {BackendInterceptor} from '../backend-interceptor/backend-interceptor';
import {UrlService} from '../url/url.service';

/**
 * Http interceptor for adding the security headers to fix switching user problem
 */
@Injectable()
export class SecurityInterceptor extends BackendInterceptor {

  constructor(@Inject(NG1_LOCAL_STORAGE) private $localStorage: any,
              @Inject(NG1_AUTH_SERVICE) private authService: Ng1AuthService,
              urlService: UrlService) {
    super(urlService);
  }

  /**
   * Adds the 'X-Coyo-Client-ID' and 'X-Coyo-Current-User' to the http request and set the 'X-Coyo-Client-ID' to the
   * local storage if it does not exist after the response
   *
   * @param req
   * The request
   *
   * @param next
   * The next http handler in the chain
   *
   * @return An Observable of the http event
   */
  interceptBackendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.urlService.isAbsoluteBackendUrl(req.url) && !this.urlService.isRelativePath(req.url)) {
      return next.handle(req);
    }
    if (!this.$localStorage.clientId) {
      this.$localStorage.clientId = uuid();
    }
    let newRequest = req.clone({
      setHeaders: {
        'X-Coyo-Client-ID': this.$localStorage.clientId
      }
    });

    const currentUserId = this.authService.getCurrentUserId();
    if (currentUserId !== null) {
      newRequest = newRequest.clone({
        setHeaders: {
          'X-Coyo-Current-User': currentUserId
        }
      });
    }

    return next.handle(newRequest).pipe(tap((event: HttpEvent<any>) => this.handleResponse(event, req.method)));
  }

  private handleResponse(event: HttpEvent<any>, method: string): void {
    if (event instanceof HttpResponse) {
      const currentUserId = event.headers.get('X-Coyo-Current-User');
      if (currentUserId) {
        this.authService.validateUserId(currentUserId, () => JSON.stringify({
          URI: event.url,
          method: method,
          headers: event.headers
        }));
      }
    }
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * A component to render the output of a JIT translation.
 */
@Component({
  selector: 'coyo-jit-translation-output',
  templateUrl: './jit-translation-output.component.html',
  styleUrls: ['./jit-translation-output.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JitTranslationOutputComponent {

  /**
   * The translated text.
   */
  @Input() translation: string;

  /**
   * If mentions should be used.
   */
  @Input() showMentions: boolean;

  constructor() {
  }
}

import {EntityVisitedEvent} from '@app/analytics/entity-visited/entity-visited-events';
import {AnalyticsEvent} from '@app/analytics/event-distributor/analytics-event';
import {EventTypes} from '@app/analytics/event-types';
import {UserActivityEvent} from '@app/analytics/user-activity/user-activity-event';
import {UserReachedEvent} from '@app/analytics/user-reach/user-reached-event';

/**
 * Class for converting {@link AnalyticsEvent}
 */
export class EventConverter {

  /**
   * Converts the given event to a string compressed representation for the local storage based on the event type.
   * @param eventType the event type
   * @param event the analytics event
   * @return string
   */
  static toLocalStorageString(eventType: EventTypes, event: AnalyticsEvent): string {
    switch (eventType) {
      case EventTypes.USER_ACTIVITY:
        const userActivityEvent = event as UserActivityEvent;
        return `${new Date(userActivityEvent.payload.from).getTime()}|${new Date(userActivityEvent.payload.to).getTime()}`;
      case EventTypes.USER_REACHED:
        const userReachedEvent = event as UserReachedEvent;
        return `${new Date(userReachedEvent.time).getTime()}|${userReachedEvent.payload.entityId}|${userReachedEvent.payload.entityType}`;
      case EventTypes.ENTITY_VISITED:
        const visitedEvent = event as EntityVisitedEvent;
        return `${new Date(visitedEvent.time).getTime()}|${visitedEvent.payload.entityId}|${visitedEvent.payload.entityType}`;
      default:
        EventConverter.logError(eventType);
        break;
    }
  }

  /**
   * Converts the given string from the local storage back to an event based on the type.
   * @param eventType the event type
   * @param eventString the string to be converted
   * @return analytics event
   */
  static fromLocalStorageString(eventType: EventTypes, eventString: string): AnalyticsEvent {
    switch (eventType) {
      case EventTypes.USER_ACTIVITY:
        return EventConverter.toUserActivityEvent(eventString);
      case EventTypes.USER_REACHED:
        return EventConverter.toUserReachedEvent(eventString);
      case EventTypes.ENTITY_VISITED:
        return EventConverter.toEntityVisitedEvent(eventString);
      default:
        EventConverter.logError(eventType);
        break;
    }
  }

  private static logError(eventType: EventTypes): void {
    throw new Error(`Cannot convert given event type: ${eventType}.`);
  }

  private static toUserActivityEvent(eventString: string): UserActivityEvent {
    const [from, to] = eventString.split('|');
    const fromDate = new Date(parseInt(from, 10)).toISOString();
    const toDate = new Date(parseInt(to, 10)).toISOString();
    return {
      time: toDate,
      payload: {from: fromDate, to: toDate, client: 'web'}
    };
  }

  private static toUserReachedEvent(eventString: string): UserReachedEvent {
    const [time, entityId, entityType] = eventString.split('|');
    return {
      time: new Date(parseInt(time, 10)).toISOString(),
      payload: {entityId: entityId, entityType: entityType, client: 'web'}
    };
  }

  private static toEntityVisitedEvent(eventString: string): EntityVisitedEvent {
    const [time, entityId, entityType] = eventString.split('|');
    return {
      time: new Date(parseInt(time, 10)).toISOString(),
      payload: {entityId: entityId, entityType: entityType, client: 'web'}
    };
  }
}

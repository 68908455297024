import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {O365ApiService} from '@app/integration/o365/o365-api/o365-api.service';
import {Observable, of, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {SearchResultsList} from '../search-results-list';

/**
 * Displays a list of external search results in a separate panel/card.
 */
@Component({
  selector: 'coyo-search-results-external-panel',
  templateUrl: './search-results-external-panel.component.html',
  styleUrls: ['./search-results-external-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultsExternalPanelComponent implements OnInit {

  /**
   * Search results and the name of the search provider.
   */
  @Input() externalSearchResults: SearchResultsList;

  /**
   * The search term, the user has entered.
   */
  @Input() searchQuery: string;

  showResults: boolean = true;

  externalSearchIcon: string;

  externalSearchURL$: Observable<string>;

  constructor(private o365ApiService: O365ApiService) {
  }

  ngOnInit(): void {
    this.externalSearchIcon = this.getMatIconBySearchProvider(this.externalSearchResults.searchProvider);
    this.externalSearchURL$ = this.getExternalSearchURL(this.externalSearchResults.searchProvider);
  }

  toggleResultsList(): void {
    this.showResults = !this.showResults;
  }

  private getExternalSearchURL(searchProvider: string): Observable<string> {
    switch (searchProvider) {
      case 'GOOGLE':
        return of('https://drive.google.com/drive/search?q=' + this.searchQuery);
      case 'MICROSOFT':
        return this.o365ApiService.getSharePointUrl()
          .pipe(map(url => url + '/_layouts/15/sharepoint.aspx?q=' + this.searchQuery + '&v=%2Fsearch%2Ffiles'));
      default:
        return throwError('Unknown search provider');
    }
  }

  private getMatIconBySearchProvider(searchProvider: string): string {
    switch (searchProvider) {
      case 'GOOGLE':
        return 'mc-google-drive';
      case 'MICROSOFT':
        return 'sharepoint_mc';
      default:
        return undefined;
    }
  }
}

<div *ngIf="!editMode; else editHeader" class="content-header">
  <coyo-locale-picker
    (localeChanged)="localeSwitched($event)"
    *ngIf="currentLocale$ | async; let currentLocale"
    [locales]="locales"
    [selectedLocale]="currentLocale">
  </coyo-locale-picker>
  <div class="edit-button">
    <button
      (click)="edit()"
      *ngIf="app._permissions.manage"
      [attr.aria-label]="'APP.CONTENT.EDIT_CONTENT' | translate"
      mat-button mat-link-button>
      <mat-icon class="edit-icon" svgIcon="pencil"></mat-icon>
      {{'APP.CONTENT.EDIT_CONTENT' | translate}}
    </button>
  </div>
</div>
<ng-template #editHeader>
  <div class="content-header-edit">
    <button mat-button mat-link-button (click)="cancel()">
      {{'CANCEL' | translate}}
    </button>
    <div class="save-container">
      <coyo-language-picker
        (languageSwitched)="languageSwitched($event)"
        *ngIf="currentLanguage$ | async; let currentLanguage"
        [languages]="availableTranslations"
        [selectedLanguage]="currentLanguage"
        [showLabel]="false"
        [validateAll]="false"
        [showFlags]="true"
        class="language-picker">
      </coyo-language-picker>
      <button (click)="delete(layoutLanguage)"
              *ngIf="layoutLanguage$ | async as layoutLanguage"
              [attr.aria-label]="'APP.CONTENT.EDIT_MODE.DELETE'"
              mat-icon-button>
        <mat-icon svgIcon="trash-bin"></mat-icon>
      </button>
      <div class="edit-button">
        <button
          [disabled]="isSaving$ | async"
          (click)="save()"
          *ngIf="app._permissions.manage"
          [attr.aria-label]="'APP.CONTENT.EDIT_MODE.END' | translate"
          mat-button mat-link-button>
          <mat-icon class="edit-icon" svgIcon="check"></mat-icon>
          {{'APP.CONTENT.EDIT_MODE.END' | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>
<div class="content content-app" data-test="panel-content-app">
  <coyo-widget-layout *ngIf="layoutName"
                      [canManage]="app._permissions.manage"
                      [editScope]="app.id"
                      [language]="layoutLanguage$ | async"
                      [name]="layoutName"
                      [parent]="parent"
                      [renderStyle]="'plain'"
                      [revisionBased]="true">
  </coyo-widget-layout>
</div>

<div class="card" *ngIf="sessions$ | async; let sessions">
  <div class="card-body">
    <h2 class="mat-headline">{{'MODULE.ACCOUNT.PUSH_DEVICES.HEADER' | translate}}</h2>
    <div *ngIf="!(sessions | keyvalue)?.length">
      {{'MODULE.ACCOUNT.PUSH_DEVICES.MESSAGES.NO_DEVICES_FOUND' | translate}}
    </div>
    <ng-container *ngFor="let sessionDevice of sessions | keyvalue">
      <h4>{{sessionDevice.key}}</h4>
      <div class="item" *ngFor="let session of sessionDevice.value">
        <mat-icon [svgIcon]="session.appType === 'COYO' ? 'emblem-coyo' : 'emblem-engage'"></mat-icon>
        {{(session.appType === 'COYO' ? 'MODULE.ACCOUNT.PUSH_DEVICES.NAME.COYO' : 'MODULE.ACCOUNT.PUSH_DEVICES.NAME.MESSENGER') | translate}}
        <button mat-button mat-link-button (click)="logout(session.userId, session.id)">{{'BUTTONS.LOGOUT' | translate}}</button>
      </div>
    </ng-container>
  </div>
</div>

import {NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppUiModule} from '@shared/app-ui/app-ui.module';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {FileModule} from '@shared/files/file.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {TimeModule} from '@shared/time/time.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {LATEST_FILES_WIDGET} from '@widgets/latest-files/latest-files-widget-config';
import {LatestFilesWidgetSettingsComponent} from '@widgets/latest-files/latest-files-widget-settings/latest-files-widget-settings.component';
import {LatestFilesWidgetComponent} from '@widgets/latest-files/latest-files-widget/latest-files-widget.component';

/**
 * Module providing the latest-files widget.
 */
@NgModule({
  imports: [
    CoyoFormsModule,
    CoyoCommonsModule,
    UpgradeModule,
    TimeModule,
    FileModule,
    NgSelectModule,
    HelpModule,
    AppUiModule
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: LATEST_FILES_WIDGET, multi: true}
  ],
  declarations: [
    LatestFilesWidgetComponent,
    LatestFilesWidgetSettingsComponent
  ],
  entryComponents: [
    LatestFilesWidgetComponent,
    LatestFilesWidgetSettingsComponent
  ]
})
export class LatestFilesWidgetModule {
}

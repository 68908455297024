import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UpdateWidgetSettings} from '@widgets/api/widget.actions';
import {HeadlineWidget} from '@widgets/headline/headline-widget';
import {SizeOption} from '@widgets/headline/size-option';
import sizeOptions from '@widgets/headline/size-options';
import {Observable} from 'rxjs';

/**
 * The headline widget displays a headline.
 */
@Component({
  selector: 'coyo-headline-widget',
  templateUrl: './headline-widget.component.html',
  styleUrls: ['./headline-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadlineWidgetComponent extends WidgetComponent<HeadlineWidget> implements OnInit {

  /**
   * The state of the widget edit mode.
   */
  @Input() editMode: boolean;

  constructor(cd: ChangeDetectorRef, private readonly store: Store) {
    super(cd);
  }

  setText(value: string): void {
    this.updateSettings(value, this.widget.settings._headline);
  }

  ngOnInit(): void {
    if (!this.widget.settings._headline) {
      this.updateSettings(this.widget.settings.text, sizeOptions[1]);
    }
  }

  private updateSettings(text: string, size: SizeOption): Observable<void> {
    const settings = {...this.widget.settings, text, _headline: size};
    const updateWidgetSettingsAction = new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId, settings, this.editScope);
    return this.store.dispatch(updateWidgetSettingsAction);
  }
}

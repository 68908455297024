import {SettingPreset} from './setting-preset';

export const buildSetting = (
  title: string,
  zoom: number,
  stickToPage: boolean = true,
  originalSize: boolean = false,
  fitToPage: boolean = false,
  autoresize: boolean = true
): SettingPreset => ({
  title,
  stickToPage,
  fitToPage,
  autoresize,
  originalSize,
  zoom
});

export const settingPresets: SettingPreset[] = [
  buildSetting('PDFVIEWER.AUTOZOOM', 1),
  buildSetting('PDFVIEWER.ORIGINALSIZE', 1, false, true),
  buildSetting('50%', 0.5),
  buildSetting('75%', 0.75),
  buildSetting('100%', 1),
  buildSetting('125%', 1.25),
  buildSetting('150%', 1.5),
  buildSetting('200%', 2),
  buildSetting('300%', 3),
  buildSetting('400%', 4)
];

<ng-container [formGroup]="parentForm">

  <mat-form-field>
    <mat-label translate="WIDGET.BUTTON.SETTINGS.TEXT"></mat-label>
    <input matInput #text formControlName="text" autofocus>
    <mat-hint align="end">{{text.value?.length || 0}}/{{MAX_LENGTH}}</mat-hint>
    <mat-error *coyoFieldErrors="parentForm.controls.text.errors"></mat-error>
  </mat-form-field>

  <mat-form-field formGroupName="_button" class="no-hint">
    <mat-label translate="WIDGET.BUTTON.SETTINGS.STYLE"></mat-label>
    <ng-select class="mat-ng-select"
               formControlName="btnClass"
               appendTo="body"
               [clearable]="false"
               [searchable]="false">
      <ng-option *ngFor="let style of styles" [value]="style.clazz">{{style.name | translate}}</ng-option>
    </ng-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="WIDGET.BUTTON.SETTINGS.URL"></mat-label>
    <mat-icon matPrefix svgIcon="link"></mat-icon>
    <input matInput formControlName="_url" [placeholder]="'WIDGET.BUTTON.SETTINGS.URL.PLACEHOLDER' | translate">
    <mat-error *coyoFieldErrors="parentForm.controls._url.errors"></mat-error>
  </mat-form-field>

  <mat-checkbox [value]="parentForm.controls._linkTarget.value"
                [checked]="parentForm.controls._linkTarget.value === CB_TRUE_VALUE"
                (change)="checkboxChange($event.source, $event.checked)">
    {{'WIDGET.BUTTON.SETTINGS.TARGET' | translate}}
  </mat-checkbox>

  <div class="button-preview-title">{{'WIDGET.BUTTON.SETTINGS.PREVIEW' | translate}}</div>
  <div class="button-preview-area">
    <button class="btn {{parentForm.get('_button.btnClass')?.value}}">
      {{parentForm.controls.text.value ? parentForm.controls.text.value : 'WIDGET.BUTTON.SETTINGS.PREVIEW' | translate}}
    </button>
  </div>

</ng-container>

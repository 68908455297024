<div class="wiki-widget">
  <h3 class="widget-title">
    {{getTitle() || ("WIDGETS.WIKI.NAME" | translate)}}
  </h3>

  <ng-container *ngIf="(state$ | async) as state">
    <div *ngIf="!state.loading && !state.hasArticles" translate="WIDGETS.WIKI.NO_ARTICLES"></div>
    <div *ngFor="let article of state.articles" class="wiki-widget-article">
      <div class="wiki-widget-icon">
        <i class="zmdi zmdi-library"></i>
      </div>
      <div class="wiki-widget-info">
        <a [href]="state.links.articles[article.articleTarget.params.id]">{{article.title}}</a>
        <div class="small text-muted">
          <coyo-time-ago [date]="article.updated"></coyo-time-ago>&nbsp;in&nbsp;<a
          [href]="state.links.senders[article.senderTarget.params.id]">{{article.senderName}}</a>
        </div>
      </div>
    </div>
    <ng-container *ngIf="state.loading">
      <ui-skeleton *ngFor="let number of getNeededSkeletons()" [skeletons]="skeletons"></ui-skeleton>
    </ng-container>
  </ng-container>
</div>

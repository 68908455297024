<span class="icon-container">
  <mat-icon [svgIcon]="feature.icon" class="icon" size="large"></mat-icon>
</span>
<span class="info">
  <div class="title">{{feature.nameKey | translate}}</div>
  <div class="description">{{feature.descriptionKey | translate}}</div>
</span>
<span class="toggle">
  <mat-slide-toggle [checked]="feature.states['enabled']"></mat-slide-toggle>
</span>

(function (angular) {
  'use strict';

  ExternalUserModel.$inject = ["$http", "$httpParamSerializer", "coyoEndpoints"];
  angular
      .module('coyo.domain')
      .factory('ExternalUserModel', ExternalUserModel);

  /**
   * @ngdoc service
   * @name coyo.domain.ExternalUserModel
   *
   * @description
   * Provides the Coyo workspace model.
   *
   * @requires $rootScope
   * @requires $httpParamSerializer
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   * @requires commons.resource.Page
   */
  function ExternalUserModel($http, $httpParamSerializer, coyoEndpoints) {
    var ExternalUserModel = {

      url: coyoEndpoints.externalUser,

      /**
       * @ngdoc function
       * @name coyo.domain.ExternalUserModel#checkEmail
       * @methodOf coyo.domain.ExternalUserModel
       *
       * @description
       * Checks if email address is taken and if an invitation exists for that email address and workspace
       *
       * @param {string} workspaceId of the workspace
       * @param {string} email of external invite
       *
       * @returns {promise} An $http promise
       */
      checkEmail: function (workspaceId, email) {
        return $http({
          method: 'GET',
          url: this.url.workspace.checkEmail,
          params: {
            idOrSlug: workspaceId,
            email: email
          }
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.ExternalUserModel#inviteExternals
       * @methodOf coyo.domain.ExternalUserModel
       *
       * @description
       * Invites externals to a workspace by their email addresses.
       *
       * @param {string} workspaceId of the workspace
       * @param {array} userAndGroupIds.userIds
       * An array of strings with email addresses to invite. Can be empty but not null.
       *
       * @returns {promise} An $http promise
       */
      inviteExternals: function (workspaceId, emailAddresses) {
        return $http({
          method: 'PUT',
          url: this.url.workspace.invite,
          data: {
            idOrSlug: workspaceId,
            emailAddresses: emailAddresses
          }
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.ExternalUserModel#declineExternalMember
       * @methodOf coyo.domain.ExternalUserModel
       *
       * @description
       * Declines an invitation of an external member from a workspace.
       *
       * @param {string} workspaceId of the workspace
       * @param {string} email of external invite
       *
       * @return {promise} An $http promise
       */
      declineExternalMember: function (workspaceId, email) {
        return $http({
          method: 'DELETE',
          url: this.url.workspace.deleteInvitation,
          params: {
            idOrSlug: workspaceId,
            email: email
          }
        });
      }
    };

    return ExternalUserModel;
  }

})(angular);

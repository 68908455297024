/**
 * Service to manage comments.
 */
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {RssEntry} from '@widgets/rss/rss-entry.model';
import {RssWidgetVerifyUrlResponse} from '@widgets/rss/rss-widget-verify-url-response.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RssWidgetService extends DomainService<RssEntry, RssEntry> {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(UrlService) urlService: UrlService) {
    super(http, urlService);
  }

  /**
   * Verify the given RSS feed URL.
   *
   * @param rssUrl The URL to verify
   * @param userName An optional user name if the feed needs basic authentication
   * @param password An optional password if the feed needs basic authentication
   * @returns a RssWidgetVerifyUrlResponse with a flag if the URL is valid and an optional validation message
   * that describes why the verification failed
   */
  verifyUrl(rssUrl: string, userName?: string, password?: string): Observable<RssWidgetVerifyUrlResponse> {
    return this.http.post<RssWidgetVerifyUrlResponse>(this.getBaseUrl() + '/verify',
      {rssUrl: rssUrl, userName: userName, password: password});
  }

  /**
   * Returns the preview entries for the RSS feed URL.
   *
   * @param rssUrl The URL to verify
   * @param withImages Whether images should be included or not
   * @param size How many entries should be fetched
   * @param userName An optional user name if the feed needs basic authentication
   * @param password An optional password if the feed needs basic authentication
   * @returns an array of RssEntry items
   */
  getEntriesForPreview(rssUrl: string, withImages: boolean, size: number, userName?: string, password?: string):
    Observable<RssEntry[]> {
    return this.getAll({
      path: '/entries',
      params: {
        url: rssUrl, images: withImages.toString(), size: size.toString(), userName: userName, password: password
      }
    });
  }

  /**
   * Get the RSS feed entries for the given RSS widget.
   *
   * @param widgetId The Widget id
   * @returns an array of RssEntry items
   */
  getEntries(widgetId: string): Observable<RssEntry[]> {
    return this.getAll({
      context: {widgetId},
      path: '/{widgetId}/entries'
    });
  }

  getBaseUrl(): string {
    return '/web/widgets/rss';
  }
}

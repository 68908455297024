<div [class.loading]="loading"
     [class.processing]="previewIsGenerating"
     [class.unavailable]="!isPreviewAvailable()"
     [class.video]="useVideoPreview()"
     class="pi-container">

  <!--IMAGE PREVIEW-->
  <coyo-file-image-preview
    (cannotProcess)="cannotProcess.emit($event)"
    (click)="openFullResImageOverlayOnMobile()"
    (statusUpdated)="onStatusUpdated($event)"
    [class.loading]="loading"
    *ngIf="useImagePreview()"
    [class.hidden]="fullResImageOverlayIsOpen"
    [file]="file"
    [groupId]="groupId"
    [class.clickable]="isFullResImageOverlayAvailable()"
    [options]="options"
    [size]="getImagePreviewSize()"
    [previewUrl]="url">
  </coyo-file-image-preview>

  <!-- FULL SCREEN OVERLAY FOR MOBILE && DESKTOP -->
  <coyo-image-container
    (released)="closeFullResImageOverlay()"
    (statusUpdated)="onStatusUpdated($event)"
    *ngIf="fullResImageOverlayIsOpen || useFullResImageOverlay()"
    [file]="file"
    [previewUrl]="url"
    [groupId]="groupId">
  </coyo-image-container>

  <!--PDF PREVIEW-->
  <div [ngClass]="{'pi-preview-pdf-container' : !loading}"
       *ngIf="usePdfPreview()">
    <coyo-pdf-viewer-loader
      *ngIf="!isPreviewIsProcessing()"
      [showToolbar]="isDesktop()"
      [src]="pdfSrc"
      (loadingCompleted)="onPdfLoadingCompleted()"
      (loadingFailed)="onPdfLoadingFailed()">
    </coyo-pdf-viewer-loader>
  </div>

  <!--VIDEO STREAMING -->
  <coyo-file-video-preview
    *ngIf="useVideoPreview()"
    [file]="file"
    [url]="url"
    [groupId]="groupId">
  </coyo-file-video-preview>

  <!--NO PREVIEW & SPINNER-->
  <div *ngIf="showPreviewGenerationInformation()" class="pi-no-preview-container">
    <coyo-no-preview-available
      *ngIf="!isDeterminingPreviewType() && !isPreviewAvailable()"
      [file]="file">
    </coyo-no-preview-available>
    <div class="alert alert-warning"
         *ngIf="previewError()">
      <div translate="FILE_LIBRARY.PREVIEW.NOTAVAILABLE.TEXT_WS"></div>
    </div>
  </div>
  <coyo-spinner class="pi-spinner-container" [inverted]="spinnerOptions?.inverted" [size]="spinnerOptions?.size || 'md'" *ngIf="showSpinner()"></coyo-spinner>
  <coyo-file-preview-animation-loader *ngIf="showPreviewAnimation()">
  </coyo-file-preview-animation-loader>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import {SenderEvent} from '@domain/event/sender-event';
import * as moment from 'moment';

@Pipe({
  name: 'hasEnded',
  pure: true
})
export class HasEndedPipe implements PipeTransform {

  /**
   * Determines whether the event has already ended.
   *
   * @param event The event with an end date.
   * @returns true if the event has already ended, else false
   */
  transform(event: SenderEvent): boolean {
    return moment().isSameOrAfter(moment(event.endDate));
  }

}

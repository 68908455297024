<ng-container *ngIf="{ preview: previewFiles$ | async, nonPreview: nonPreviewFiles$ | async}; let previews">
  <div class="attachment-previews" >
    <div class="attachment-previews-title"
        *ngIf="previewTitle && previews.preview.length > 0"
        [translate]="previewTitle">
    </div>
    <a href="{{attachment.externalFileUrl}}"
      class="item-attachment"
      [attr.title]="attachment.name"
      *ngFor="let attachment of previews.preview"
      (click)="showDetails(attachment, $event)"
      [class.single-file]="previews.preview.length === 1">
      <coyo-file-preview [file]="attachment"
                          [url]="url"
                          [groupId]="attachment.groupId"
                          [size]="size"
                          (cannotProcess)="onCannotProcess($event)"
                          [options]="{hideNoPreviewAvailableMsg: false,
                                    hidePreviewGenerationInformation: true,
                                    backgroundImage: previews.preview.length !== 1,
                                    allowGifPlayback: previews.preview.length === 1}"
                          [spinnerOptions]="spinnerOptions"
                          [class.single-file]="previews.preview.length === 1"
                          [class.same-size]="sameSize"
                          [class.loading]="attachment.externalFileUrlLoading">
      </coyo-file-preview>
    </a>
  </div>
  <div class="attachment-list" *ngIf="previews?.nonPreview?.length > 0">
    <div class="attachment-list-title"
        *ngIf="nonPreviewTitle && previews.nonPreview.length > 0 && previews.preview.length > 0"
        translate [translateParams]="{count: previews.nonPreview.length}">{{nonPreviewTitle}}
    </div>
    <div *ngFor="let attachment of previews.nonPreview">
      <coyo-file-non-preview (click)="showDetails(attachment, $event)"
                             [attachment]="attachment"
                             [class.clickable]="attachment.storageAvailable"
                             [size]="size"
                             [renderVariant]="renderVariant"
                             [showEngageDownloadHint]="isShowEngageDownload(attachment)"></coyo-file-non-preview>
    </div>
  </div>
</ng-container>

(function (angular) {
  'use strict';

  updateLanguageSetup.$inject = ["$document", "updateLanguageService"];
  updateLanguageService.$inject = ["$rootScope", "$translate"];
  angular
      .module('commons.i18n')
      .run(updateLanguageSetup)
      .factory('updateLanguageService', updateLanguageService);

  function updateLanguageSetup($document, updateLanguageService) {
    var htmlNode = $document.find('html');
    updateLanguageService.addElement(htmlNode);
  }

  /**
   * @ngdoc service
   * @name commons.i18n.updateLanguage
   *
   * @description
   * This service holds an extensible list of html elements and keeps their 'lang' attribute in sync with
   * the user chosen language provided by $translate service.
   *
   * @requires $rootScope
   * @requires $translate
   */
  function updateLanguageService($rootScope, $translate) {
    var syncedElements = [];
    _registerEvents();

    function addElement(element) {
      if (_.findIndex(syncedElements, element) < 0) {
        syncedElements.push(element);
      }
    }

    function _onTranslationChangedHandler() {
      var language = $translate.proposedLanguage();
      _.forEach(syncedElements, function (element) {
        element.attr('lang', language);
      });
    }

    function _registerEvents() {
      $rootScope.$on('$translateChangeSuccess', _onTranslationChangedHandler);
      $translate.onReady(_onTranslationChangedHandler);
    }

    return {
      addElement: addElement,
      syncedElements: syncedElements
    };
  }

})(angular);

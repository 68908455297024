<ng-container *ngIf="(available$ | async)">
  <ng-container *ngIf="searches$ | async; let searches; else loading">
    <coyo-search-results-external-panel *ngFor="let search of searches"
                                          [externalSearchResults]="search"
                                          [searchQuery]="searchQuery">
      </coyo-search-results-external-panel>
  </ng-container>
  <ng-template #loading>
    <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
  </ng-template>
</ng-container>



import {Injectable} from '@angular/core';
import {EventDates} from '@app/events/event-dates';
import * as moment from 'moment';
import {Moment} from 'moment-timezone/moment-timezone';

/**
 * @ngdoc service
 * @name EventDateSyncService
 *
 * @description
 * This service update and sync date and time properties from a event date/time picker.
 * The start date and times can not be after the end date and time and vice versa.
 */
@Injectable({
  providedIn: 'root'
})
export class EventDateSyncService {

  private defaultOffset: number = 1000 * 60 * 60; // milliseconds of 1h

  /**
   * Sync all dates with the given start date.
   *
   * @param dates the dates object
   */
  updateWithStartDate(dates: EventDates): void {
    if (!this.validateEventDates(dates)) {
      return;
    }
    this.initOffset(dates);
    this.syncStartTimeWithStartDate(dates);
    this.syncStartDateWithStartTime(dates); // most datepicker set the time of the date object to 0.
    this.updateEndDateIfBeforeStartDate(dates);
    this.updateEndTimeIfSameOrBeforeStartTime(dates);
  }

  /**
   * Sync all dates with the given start time.
   *
   * @param dates the dates object
   */
  updateWithStartTime(dates: EventDates): void {
    if (!this.validateEventDates(dates)) {
      return;
    }
    this.initOffset(dates);
    this.syncStartDateWithStartTime(dates);
    this.updateEndTimeIfSameOrBeforeStartTime(dates);
  }

  /**
   * Sync all dates with the given end date.
   *
   * @param dates the dates object
   */
  updateWithEndDate(dates: EventDates): void {
    if (!this.validateEventDates(dates)) {
      return;
    }
    this.initOffset(dates);
    this.syncEndTimeWithEndDate(dates);
    this.syncEndDateWithEndTime(dates); // most datepicker set the time of the date object to 0.
    this.updateStartDateIfAfterEndDate(dates);
    this.updateStartTimeIfSameOrAfterEndTime(dates);
    this.updateCustomDateDifference(dates);
  }

  /**
   * Sync all dates with the given end time.
   *
   * @param dates the dates object
   */
  updateWithEndTime(dates: EventDates): void {
    if (!this.validateEventDates(dates)) {
      return;
    }
    this.initOffset(dates);
    this.syncEndDateWithEndTime(dates);
    this.updateStartTimeIfSameOrAfterEndTime(dates);
    this.updateCustomDateDifference(dates);
  }

  /**
   * Returns the difference between two dates.
   *
   * @param dateA a date as Date
   * @param dateB a date as Date
   * @return the result in milliseconds as absolute value
   */
  differenceInMilliseconds(dateA: Date, dateB: Date): number {
    if (this.validateDate(dateA) && this.validateDate(dateB)) {
      return Math.abs(moment(dateA).diff(moment(dateB)));
    }
    return this.defaultOffset;
  }

  private updateStartDateIfAfterEndDate(dates: EventDates): void {
    const startDate = this.copyAndConvert(dates.startDate);
    const startDay = startDate.clone().startOf('day');
    const endDate = this.copyAndConvert(dates.endDate);
    const endDay = endDate.clone().startOf('day');

    if (startDay.isAfter(endDay)) {
      const startTime = this.copyAndConvert(dates.startTime);
      const newStartDate = endDate.clone();
      newStartDate.hours(startTime.hours()).minutes(startTime.minutes());
      dates.startDate = newStartDate.toDate();
      dates.startTime = newStartDate.toDate();
    }
    this.syncStartTimeWithStartDate(dates);
  }

  private updateEndDateIfBeforeStartDate(dates: EventDates): void {
    const startDate = this.copyAndConvert(dates.startDate);
    const startDay = startDate.clone().startOf('day');
    const endDate = this.copyAndConvert(dates.endDate);
    const endDay = endDate.clone().startOf('day');

    if (endDay.isSameOrBefore(startDay)) {
      const endTime = this.copyAndConvert(dates.endTime);
      const newEndDate = startDate.clone().add(dates.offset, 'milliseconds');
      newEndDate.hours(endTime.hours()).minutes(endTime.minutes());
      dates.endDate = newEndDate.toDate();
      dates.endTime = newEndDate.toDate();
    }
    this.syncEndTimeWithEndDate(dates);
  }

  private updateStartTimeIfSameOrAfterEndTime(dates: EventDates): void {
    const startTime = this.copyAndConvert(dates.startTime);
    const endTime = this.copyAndConvert(dates.endTime);

    if (startTime.isSameOrAfter(endTime)) {
      const newStartTime = endTime.clone().subtract(dates.offset, 'milliseconds');
      dates.startDate = newStartTime.toDate();
      dates.startTime = newStartTime.toDate();
    }
  }

  private updateEndTimeIfSameOrBeforeStartTime(dates: EventDates): void {
    const startTime = this.copyAndConvert(dates.startTime);
    const startDay = startTime.clone().startOf('day');
    const endTime = this.copyAndConvert(dates.endTime);
    const endDay = endTime.clone().startOf('day');

    if (endDay.isSame(startDay) || endTime.isBefore(startTime)) {
      const newEndTime = startTime.add(dates.offset, 'milliseconds');
      dates.endDate = newEndTime.toDate();
      dates.endTime = newEndTime.toDate();
    }
  }

  private syncStartDateWithStartTime(dates: EventDates): void {
    dates.startDate = this.syncStart(dates).toDate();
  }

  private syncStartTimeWithStartDate(dates: EventDates): void {
    dates.startTime = this.syncStart(dates).toDate();
  }

  private syncEndDateWithEndTime(dates: EventDates): void {
    dates.endDate = this.syncEnd(dates).toDate();
  }

  private syncEndTimeWithEndDate(dates: EventDates): void {
    dates.endTime = this.syncEnd(dates).toDate();
  }

  private updateCustomDateDifference(dates: EventDates): void {
    dates.offset = this.differenceInMilliseconds(dates.startTime, dates.endTime);
  }

  private syncStart(dates: EventDates): Moment {
    const startDate = this.copyAndConvert(dates.startDate);
    const startTime = this.copyAndConvert(dates.startTime);

    if (!startDate.isSame(startTime)) {
      startDate.hours(startTime.hours()).minutes(startTime.minutes()).seconds(0);
      return startDate;
    }
    return startDate;
  }

  private syncEnd(dates: EventDates): Moment {
    const endDate = this.copyAndConvert(dates.endDate);
    const endTime = this.copyAndConvert(dates.endTime);

    if (!endDate.isSame(endTime)) {
      endDate.hours(endTime.hours()).minutes(endTime.minutes()).seconds(0);
      return endDate;
    }
    return endDate;
  }

  private copyAndConvert(date: Date): Moment {
    return moment(date).clone();
  }

  private validateEventDates(dates: EventDates): boolean {
    let valid = true;
    const keys = Object.keys(dates);
    for (const key of keys) {
      if (key !== 'offset') {
        const date = dates[key];
        valid = this.validateDate(date);
        if (!valid) {
          return;
        }
      }
    }
    return valid;
  }

  private validateDate(date: Date): boolean {
    return date && date.getTime instanceof Function && !isNaN(date.getTime()) && moment(date).isValid();
  }

  private initOffset(dates: EventDates): void {
    if (!dates.offset) {
      dates.offset = this.defaultOffset;
    }
  }
}

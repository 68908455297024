import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SelectFileModule} from '@shared/select-file/select-file.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {DOWNLOAD_WIDGET} from '@widgets/download/download-widget-config';
import {DownloadWidgetSettingsComponent} from '@widgets/download/download-widget-settings/download-widget-settings.component';
import {DownloadWidgetComponent} from '@widgets/download/download-widget/download-widget.component';

/**
 * Module providing the download widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    SelectFileModule
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: DOWNLOAD_WIDGET, multi: true}
  ],
  declarations: [
    DownloadWidgetComponent,
    DownloadWidgetSettingsComponent
  ],
  entryComponents: [
    DownloadWidgetComponent,
    DownloadWidgetSettingsComponent
  ]
})
export class DownloadWidgetModule {
}

<ng-container *ngIf="languages$ | async; let languages">
  <form [formGroup]="form" class="row" *ngIf="languages.length > 1">
    <div class="col-md-4">
      <label class="mat-form-field-label" (click)="languageSelect.focus()">{{'SETTINGS.PREFERENCES.LANGUAGE' | translate}}</label>
    </div>
    <div class="col-md-8">
      <mat-form-field>
        <ng-select class="mat-ng-select" formControlName="language" appearance="outline"
                   appendTo="body" [clearable]="false" [searchable]="false" (change)="submit()" #languageSelect>
          <ng-option *ngFor="let language of languages" [value]="language.language">{{'LANGUAGE.LANGUAGES.' + language.language | translate}}</ng-option>
        </ng-select>
        <mat-hint [innerHTML]="'SETTINGS.PREFERENCES.LANGUAGE.HINT' | translate | markdown"></mat-hint>
      </mat-form-field>
    </div>
  </form>
</ng-container>

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {MentionModule} from '@shared/mention/mention.module';
import {TextModule} from '@shared/text/text.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {HTML_WIDGET} from '@widgets/html/html-widget-config';
import {HtmlWidgetSettingsComponent} from './html-widget-settings/html-widget-settings.component';
import {HtmlWidgetComponent} from './html-widget/html-widget.component';

/**
 * Module providing the html widget
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    TextModule,
    HelpModule,
    MentionModule
  ],
  declarations: [
    HtmlWidgetComponent,
    HtmlWidgetSettingsComponent
  ],
  entryComponents: [
    HtmlWidgetComponent,
    HtmlWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: HTML_WIDGET, multi: true}
  ]
})

export class HtmlWidgetModule {
}

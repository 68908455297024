import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LaunchpadService} from '@app/launchpad/launchpad/launchpad.service';
import {SEARCH_FEATURE_TOGGLE} from '@app/search-shell/search-shell.constants';
import {TourService} from '@app/tour/tour/tour.service';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {TranslationService} from '@core/i18n/translation-service/translation.service';
import {ScriptLoaderService} from '@core/script-loader/script-loader.service';
import {Target} from '@domain/sender/target';
import {TargetService} from '@domain/sender/target/target.service';
import {WINDOW} from '@root/injection-tokens';
import {NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import {filter, single, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchShellService {

  constructor(private scriptLoader: ScriptLoaderService,
              @Inject(WINDOW) private windowService: Window, @Inject(DOCUMENT) private document: Document,
              @Inject(NG1_STATE_SERVICE) private stateService: IStateService,
              private targetService: TargetService, private dialog: MatDialog,
              private launchpadService: LaunchpadService, private tourService: TourService,
              private translationService: TranslationService,
              private featureToggleService: FeatureToggleService) {
  }

  /**
   * Issues the opening of the search dialog.
   * Integrates the microfrontend with the main app.
   */
  openSearch(): void {
    if (this.launchpadService.isLaunchPadVisible || this.tourService.isActive() || this.isSomeModalOpen()) {
      return;
    }

    this.featureToggleService.isFeatureAvailable(SEARCH_FEATURE_TOGGLE)
      .pipe(single())
      .pipe(filter(isActive => isActive))
      .pipe(switchMap(() => this.scriptLoader.provideExternalComponents('search-ui')))
      .subscribe(() => {
        this.sendMessageToSearchUi('openSearchDialog');
      });
  }

  /**
   * Registers the event callback for all events from the search-ui.
   */
  handleSearchEvents(): void {
    this.windowService.addEventListener('message', this.processEvent.bind(this), false);
  }

  private processEvent(event: MessageEvent): void {
    const {sub, target, payload} = event.data;
    if (event.origin !== this.windowService.origin || target !== 'main-ui') {
      return;
    }
    if (sub === 'openSearchResultsPage') {
      this.processSearchResultsRouting(payload);
    }
    if (sub === 'requestTargetLink') {
      this.processTargetLinkRequest(payload);
    }
    if (sub === 'requestCurrentUserLanguage') {
      this.processUserLanguage();
    }
  }

  private processSearchResultsRouting(payload: any): void {
    this.stateService.go('main.search-beta', {q: payload.searchTerm});
  }

  private processTargetLinkRequest(payload: any): void {
    const link = this.targetService.getLinkTo(payload.target as Target);
    this.sendMessageToSearchUi('targetResolved', {
      link,
      requestRandom: payload.requestRandom
    });
  }

  private processUserLanguage(): void {
    const userLang = this.translationService.getDefaultLanguage();
    this.translationService.getSystemLanguage().subscribe(sysLang => {
      this.sendMessageToSearchUi('currentUserLanguage', {
        userLang,
        sysLang
      });
    });

  }

  private sendMessageToSearchUi(sub: string, payload: any = {}): void {
    this.windowService.postMessage({
      target: 'search-ui',
      iss: 'main-ui',
      sub,
      payload
    }, this.windowService.origin);
  }

  private isSomeModalOpen(): boolean {
    const isAngularJsModalOpen = this.document.body.className.indexOf('modal-open') !== -1;
    const isMaterialDialogOpen = this.dialog.openDialogs.length !== 0;
    return isAngularJsModalOpen || isMaterialDialogOpen;
  }
}

<ng-container *ngIf="(state$ | async) as state">
  <div class="file-picker" coyo-global-permissions="ACCESS_FILES">
    <ul class="file-picker-list" *ngIf="state.selectedFiles?.length">
      <li *ngFor="let file of state.selectedFiles">
        <ng-container *ngIf="file.name">
          {{file.name}}
          <button class="remove-file" (click)="removeFile(file, state.selectedFiles)"><i
            class="zmdi zmdi-close-circle"></i>
          </button>
        </ng-container>
      </li>
    </ul>
    <coyo-select-file-button [buttonTemplate]="buttonTemplate"
                             [options]="options"
                             [sender]="sender"
                             [cropSettings]="cropSettings"
                             [selectedFiles]="state.selectedFiles"
                             (filesSelected)="selectFile($event, state.selectedFiles)"
    ></coyo-select-file-button>
  </div>
</ng-container>


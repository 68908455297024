import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@core/auth/auth.service';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {TimelineItemService} from '@domain/timeline-item/timeline-item.service';
import {User} from '@domain/user/user';
import {Ng1ScrollBehaviourService} from '@root/typings';
import {TimelineFormAttachment} from '@shared/files/attachment-btn/types/timeline-form-attachment';
import {TimelineFormCoyoLibraryAttachment} from '@shared/files/attachment-btn/types/timeline-form-coyo-library-attachment';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {NG1_SCROLL_BEHAVIOR_SERVICE} from '@upgrade/upgrade.module';
import * as _ from 'lodash';
import {FileItem} from 'ng2-file-upload';
import {NgxPermissionsService} from 'ngx-permissions';
import {BehaviorSubject, from, Observable, Subject, Subscription} from 'rxjs';
import {map, scan, startWith} from 'rxjs/operators';
import {StickyExpiryPeriods} from '../sticky-btn/sticky-expiry-periods';

/**
 * A timeline form component.
 */
@Component({
  selector: 'coyo-timeline-form',
  templateUrl: './timeline-form.component.html',
  styleUrls: ['./timeline-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineFormComponent implements OnInit, OnDestroy {

  @ViewChild('message', {
    static: true
  }) input: ElementRef<HTMLTextAreaElement>;

  /**
   * The type of the timeline stream.
   */
  @Input() type: 'personal' | 'sender';

  /**
   * The context sender of the timeline stream.
   */
  @Input() context?: Sender;

  isPersonal: boolean;
  isOpen: boolean = false;
  messageControl: FormControl;
  timelineForm: FormGroup;
  attachments$: Observable<(FileItem | TimelineFormAttachment | TimelineFormCoyoLibraryAttachment)[]>;
  canActAsSender$: Observable<boolean>;
  selectedSender: Sender;
  submitting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isXs$: Observable<boolean>;

  private currentUser: User;

  urlSubject$: Subject<string[]>;

  urlsAcc$: Observable<string[]>;
  private screenChangeSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef,
              private renderer: Renderer2,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private senderService: SenderService,
              private timelineItemService: TimelineItemService,
              private permissionService: NgxPermissionsService,
              private windowSizeService: WindowSizeService,
              @Inject(NG1_SCROLL_BEHAVIOR_SERVICE) private scrollBehaviourService: Ng1ScrollBehaviourService) {
  }

  ngOnInit(): void {
    this.isPersonal = this.type === 'personal';

    this.createForm();
    this.resetPreviewSubjects();

    this.authService.getUser().subscribe(user => {
      this.currentUser = user;
      const context = this.context ? this.context : user;

      this.loadPermissions(context.id);
      this.reset();
    });

    this.attachments$ = this.timelineForm.get('allSelectedAttachments').valueChanges.pipe(startWith([]));

    this.observeWindowSizeChanges();
  }

  loadPermissions(senderId: string): void {
    /*The 'createFile' permission is required for the 'File Library' component */
    this.senderService.get(senderId, {
      permissions: ['actAsSenderOnSameSender', 'createFile']
    }).subscribe(response => {
      this.context = response;
      this.canActAsSender$ = from(this.permissionService.hasPermission('ACT_AS_SENDER'))
        .pipe(map(permission => !!(permission ||
          _.get(response, '_permissions.actAsSenderOnSameSender', false))));
    });
  }

  ngOnDestroy(): void {
    if (this.screenChangeSubscription) {
      this.screenChangeSubscription.unsubscribe();
    }
  }

  /**
   * Submit form with control + enter keydown
   */
  @HostListener('keydown.control.Enter')
  onCtrlEnter(): void {
    if (this.timelineForm.invalid) {
      return;
    }
    this.submit();
    this.input.nativeElement.blur();
  }

  /**
   * Submits the form and sends the data to the backend.
   */
  submit(): void {
    if (this.submitting$.getValue()) {
      return;
    }
    this.submitting$.next(true);
    const attachments = this.timelineForm
      .get('allSelectedAttachments').value
      .filter((a: TimelineFormAttachment | TimelineFormCoyoLibraryAttachment) => a.storage !== 'LOCAL_FILE_LIBRARY');
    const fileLibraryAttachments = this.timelineForm
      .get('allSelectedAttachments').value
      .filter((a: TimelineFormAttachment | TimelineFormCoyoLibraryAttachment) => a.storage === 'LOCAL_FILE_LIBRARY');
    const formValue = this.timelineForm.getRawValue();
    this.timelineItemService.post({
      ..._.omit(formValue, ['author', 'message', 'allSelectedAttachments', 'stickyExpiry']),
      stickyExpiry: this.getDurationDate(formValue.stickyExpiry),
      type: 'post',
      authorId: formValue.author.id,
      data: {message: formValue.message},
      attachments,
      fileLibraryAttachments
    }).subscribe(
      () => this.reset(),
      () => {},
      () => this.submitting$.next(false));
  }

  /**
   * Removes the given attachment from the form.
   *
   * @param attachment the attachment to be removed.
   */
  removeAttachment(attachment: TimelineFormAttachment | TimelineFormCoyoLibraryAttachment): void {
    this.timelineForm
      .get('allSelectedAttachments')
      .setValue(
        _.filter(this.timelineForm.get('allSelectedAttachments').value, a => !_.isEqual(attachment, a))
      );
  }

  /**
   * Changes the selected sender value.
   *
   * @param sender The sender
   */
  changeSelectedSender(sender: Sender): void {
    if (this.type === 'personal') {
      this.timelineForm.get('recipientIds').setValue([sender.id]);
    }
    this.selectedSender = sender;
  }

  /**
   * Resets the form
   *
   * @return `false`
   */
  reset(): boolean {
    this.isOpen = false;
    this.timelineForm.reset({
      author: this.currentUser,
      recipientIds: this.type === 'personal' ? [this.currentUser.id] : [this.context.id],
      message: '',
      restricted: false,
      stickyExpiry: StickyExpiryPeriods.none.expiry,
      allSelectedAttachments: [],
      webPreviews: {}
    });

    this.changeSelectedSender(this.currentUser);
    this.resetPreviewSubjects();
    this.removeNoScroll();
    this.cd.detectChanges();

    // reset autosize styles and return false to prevent click actions
    setTimeout(() => this.renderer.removeStyle(this.input.nativeElement, 'height'));
    return false;
  }

  /**
   * Marks the timeline form as touched and adds the modal open class on xs screens to prevent scrolling of the
   * background
   */
  onFocus(): void {
    this.isOpen = true;
    if (this.windowSizeService.isXs()) {
      this.scrollBehaviourService.disableBodyScrollingOnXsScreen();
    }
  }

  private createForm(): void {
    const expiries = _.map(StickyExpiryPeriods.all, 'expiry');
    this.messageControl = new FormControl('', [Validators.required, CoyoValidators.notBlank]);
    this.timelineForm = this.formBuilder.group({
      author: [null, Validators.required],
      recipientIds: [null, CoyoValidators.notBlank],
      message: this.messageControl,
      restricted: [false],
      stickyExpiry: [StickyExpiryPeriods.none.expiry, CoyoValidators.options(...expiries)],
      allSelectedAttachments: [],
      webPreviews: [{}]
    });
  }

  private observeWindowSizeChanges(): void {
    this.isXs$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS));
    this.screenChangeSubscription = this.isXs$.subscribe(isXs => {
      if (this.timelineForm.touched && !isXs) {
        this.removeNoScroll();
      }
    });
  }

  private removeNoScroll(): void {
    this.scrollBehaviourService.enableBodyScrolling();
  }

  private resetPreviewSubjects(): void {
    if (this.urlSubject$) {
      this.urlSubject$.complete();
    }
    this.urlSubject$ = new Subject<string[]>();
    this.urlsAcc$ = this.urlSubject$.asObservable()
      .pipe(scan<string[]>((acc, url) => [...acc, ...url], []))
      .pipe(map(urls => _.uniq(urls)));
  }

  private getDurationDate(duration: number): number {
    return duration ? new Date().getTime() + duration : null;
  }
}

import {AppSettings} from '@apps/api/app-settings/app-settings';

/**
 * Settings for the document app
 */

export const NOTIFICATION_ALL = 'ALL';
export const NOTIFICATION_ADMIN = 'ADMIN';
export const NOTIFICATION_NONE = 'NONE';

export const EDITORS_ALL = 'VIEWER';
export const EDITORS_ADMIN = 'ADMIN';

export interface DocumentAppSettings extends AppSettings {
  notification: typeof NOTIFICATION_ALL | typeof NOTIFICATION_ADMIN | typeof NOTIFICATION_NONE;
  modifyRole: typeof EDITORS_ALL | typeof EDITORS_ADMIN;
  showAuthors: boolean;
}

export class UserChooserSelection {

  static readonly MEMBER_TYPE: string = 'user';
  static readonly MEMBER_GROUP_TYPE: string = 'group';
  static readonly MEMBER_PAGE_TYPE: string = 'page';
  static readonly MEMBER_WORKSPACE_TYPE: string = 'workspace';

  memberIds?: string[];
  memberGroupIds?: string[];
  memberPageIds?: string[];
  memberWorkspaceIds?: string[];

  constructor(
    memberIds: string[] = [],
    memberGroupIds: string[] = [],
    memberPageIds: string[] = [],
    memberWorkspaceIds: string[] = []
  ) {
    this.memberIds = memberIds;
    this.memberGroupIds = memberGroupIds;
    this.memberPageIds = memberPageIds;
    this.memberWorkspaceIds = memberWorkspaceIds;
  }
}

<a [attr.href]="preview.url" [attr.target]="!editMode && preview.internal ? '_self' : '_blank'" class="link-preview">
  <a href="#" class="coyo-btn coyo-btn-sm coyo-btn-link coyo-btn-icon link-preview-close" *ngIf="editMode" (click)="delete.emit()">
    <i class="zmdi zmdi-close" aria-hidden="true"></i>
    <span class="sr-only" translate="PREVIEW.VIDEO.DELETE.ARIA"></span>
  </a>
  <div class="link-preview-image">
    <img [className]="preview.contentType?.includes('ico') ? 'link-preview-image-ico' : 'link-preview-image'"
         [src]="getAbsoluteImageUrl()"
         *ngIf="hasPreviewImage() && !isFileLibraryFolder()"
         aria-hidden="true">
    <img class="link-preview-image"
         [src]="preview.iconUrl"
         *ngIf="hasInternalIcon()"
         aria-hidden="true">
    <img class="link-google-preview-image"
         [src]="getGoogleIconUrl()"
         *ngIf="hasGoogleIcon()"
         aria-hidden="true">
    <div class="link-preview-icon"
         *ngIf="isFileLibraryFolder()">
      <i class="zmdi zmdi-folder-outline"></i>
    </div>
    <div class="link-preview-icon"
         *ngIf="!hasInternalIcon() && !hasPreviewImage() && !hasGoogleIcon()">
      <i class="zmdi zmdi-globe"></i>
    </div>
  </div>
  <div class="link-preview-text">
    <div class="link-preview-title" *ngIf="preview.title" [translate]="preview.title"></div>
    <div class="link-preview-url" *ngIf="preview.tld">{{preview.tld}}</div>
    <div class="link-preview-description" *ngIf="preview.description" [translate]="preview.description"></div>
  </div>
</a>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Skeleton} from '@coyo/ui';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {SuggestPagesWidget} from '@widgets/suggest-pages/suggest-pages-widget';
import {Load} from '@widgets/suggest-pages/suggest-pages-widget/suggest-pages-widget.actions';
import {SuggestPagesWidgetStateModel} from '@widgets/suggest-pages/suggest-pages-widget/suggest-pages-widget.state';
import * as _ from 'lodash';
import {Observable} from 'rxjs';

/**
 * The suggest-pages widget component.
 */
@Component({
  selector: 'coyo-suggest-pages-widget',
  templateUrl: './suggest-pages-widget.component.html',
  styleUrls: ['./suggest-pages-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestPagesWidgetComponent extends WidgetComponent<SuggestPagesWidget> implements OnInit, OnChanges {
  state$: Observable<SuggestPagesWidgetStateModel>;
  private id: string;
  skeletons: Skeleton[] = [
    { top: 0, left: 0, width: '100%', height: 22.5}
  ];
  constructor(changeDetectorRef: ChangeDetectorRef, private store: Store) {
    super(changeDetectorRef);
  }

  get title(): string {
    return _.get(this.widget, 'settings._title');
  }

  ngOnInit(): void {
    this.id = this.widget.id || this.widget.tempId;
    this.state$ = this.store.select(state => state.suggestPagesWidget[this.id]);
    this.store.dispatch(new Load(this.widget.settings, this.id));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.xor(
      _.get(changes.widget, 'previousValue.settings._pageIds', []),
      _.get(changes.widget, 'currentValue.settings._pageIds', [])).length !== 0) {
      this.store.dispatch(new Load(this.widget.settings, this.id));
    }
  }

  getNeededSkeletons(): number[] {
    return _.take(this.widget.settings._pageIds, 3).map((x, i) => i);
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

/**
 * The timeline item edit form modal component.
 */
@Component({
  templateUrl: './socket-connection-lost-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocketConnectionLostModalComponent {

  constructor(private dialogRef: MatDialogRef<SocketConnectionLostModalComponent, boolean>) {
  }

  onReload(): void {
    this.dialogRef.close(true);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MentionItem} from '@domain/mention/mention-item';
import {Page} from '@domain/pagination/page';
import {User} from '@domain/user/user';
import {PopperContent, PopperController} from 'ngx-popper';
import Popper from 'popper.js';

/**
 * A selection dropdown for mentionable items.
 */
@Component({
  selector: 'coyo-mention',
  templateUrl: './mention.component.html',
  styleUrls: ['./mention.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MentionComponent {
  @HostBinding('style.top') top: string;
  @HostBinding('style.left') left: string;
  @HostBinding('style.height') height: string;

  /**
   * Append the popper-content element to a given selector, if multiple will apply to first.
   */
  @Input() appendTo: string;

  /**
   * Event emitter that fires on an outside click.
   */
  @Output() clickedOutside: EventEmitter<Event> = new EventEmitter();

  @ViewChild('mentions', {
    static: true
  }) popperContent: PopperContent;
  @ViewChild('popper', {
    static: true
  }) popperController: PopperController;

  readonly modifiers: Popper.Modifiers = Popper.Defaults.modifiers;

  _page: Page<MentionItem>;

  _active: number = 0;

  /**
   * The current page of items to be displayed.
   */
  get page(): Page<MentionItem> {
    return this._page;
  }

  /**
   * Updates the current page of items to be displayed.
   */
  @Input() set page(page: Page<MentionItem>) {
    const wasEmpty = !this._page || !this._page.content.length;
    const shouldShow = wasEmpty && page.content.length;
    const shouldHide = !wasEmpty && !page.content.length;

    this._page = page;
    this.cd.detectChanges();

    if (shouldShow) {
      this.popperController.show();
    } else if (shouldHide) {
      this.popperController.hide();
    } else {
      this.popperContent.update();
    }
  }

  /**
   * The index of the item that is currently active.
   */
  get active(): number {
    return this._active;
  }

  /**
   * Updates the index of the item that is currently active.
   */
  @Input() set active(active: number) {
    this._active = active;
    this.cd.detectChanges();
  }

  /**
   * Event emitter for selected mention slugs.
   */
  @Output() selected: EventEmitter<string> = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) {
  }

  /**
   * Select the given user.
   *
   * @param user the active user
   * @return `false`
   */
  select(user: User): boolean {
    this.selected.emit(user.slug);
    return false;
  }
}

<label [attr.for]="inputId" class="coyo-checkbox-label label-position-{{labelPosition}}" [class.checked]="isChecked">
  <input #input type="checkbox"
         class="coyo-checkbox-input sr-only"
         [id]="inputId"
         [required]="required"
         [checked]="isChecked"
         [disabled]="disabled"
         [attr.name]="name"
         [tabIndex]="-1"
         [attr.aria-checked]="isChecked"
         [attr.aria-label]="(ariaLabel | translate)"
         (click)="onInputClick()">
  <div class="coyo-checkbox-frame coyo-checkbox btn btn-default" [tabIndex]="tabIndex" (keydown.enter)="onInputClick()" data-test="checkbox-frame">
    <i class="coyo-checkbox-check zmdi zmdi-hc-fw zmdi-check" *ngIf="isChecked"></i>
    <i class="coyo-checkbox-check zmdi zmdi-hc-fw zmdi-minus" *ngIf="isPartiallyChecked"></i>
  </div>
  <span *ngIf="label" aria-hidden="true" class="coyo-checkbox-label-text  no-user-select">{{label | translate}}</span>
</label>
<div class="coyo-checkbox-hint" *ngIf="hint">
  <span class="text-muted">{{hint | translate}}</span>
</div>

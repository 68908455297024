(function (angular) {
  'use strict';

  coyoTranslationLoader.$inject = ["LanguagesModel", "I18nModel"];
  angular
      .module('commons.i18n.custom')
      .factory('coyoTranslationLoader', coyoTranslationLoader);

  /**
   * @ngdoc service
   * @name commons.i18n.custom.coyoTranslationLoader
   *
   * @description
   * This factory provides a custom loader for angular-translate.
   * The loader fetches the mixed in translations (bundle + override) from the i18n service and caches them.
   * The system default language is send as a fallback to the user language.
   *
   * @requires coyo.domain.LanguagesModel
   * @requires coyo.domain.I18nModel
   */
  function coyoTranslationLoader(LanguagesModel, I18nModel) {
    var cachedTranslations = {};

    function loadMessages(lang, fallback) {
      return I18nModel.getTranslations(lang, fallback).then(function (result) {
        return result.data;
      });
    }

    return function (options) {
      var userLanguage = options.key;
      if (cachedTranslations[userLanguage]) {
        return cachedTranslations[userLanguage];
      }
      cachedTranslations[userLanguage] = LanguagesModel.getDefaultLanguage().then(function (defaultLang) {
        var fallback = (defaultLang && defaultLang.language) ? defaultLang.language.toLowerCase() : 'en';
        return loadMessages(userLanguage, fallback);
      }).catch(function () {
        return loadMessages('en', 'en');
      });

      return cachedTranslations[userLanguage];
    };
  }

})(angular);

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FilePickerData} from '@app/file-picker/file-picker-data';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {
  FilePickerModalComponent,
} from '@app/file-picker/file-picker-modal/file-picker-modal.component';
import {MatDialogSize} from '@coyo/ui';
import {Observable} from 'rxjs';

/**
 * This service handles the filepicker.
 */
@Injectable({
  providedIn: 'root'
})
export class FilePickerService {

  constructor(private dialog: MatDialog) {
  }

  /**
   * Opens a file picker.
   *
   * @param data The information that are used to display the file picker
   * @return Observable of selected items
   */
  openFilePicker(data: FilePickerData): Observable<FilePickerItem[]> {
    return this.dialog.open<FilePickerModalComponent, FilePickerData>(FilePickerModalComponent, {
      data,
      width: MatDialogSize.Large,
    }).afterClosed();
  }
}

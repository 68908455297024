import {Page} from '@domain/pagination/page';
/**
 * Implementation of interface page.ts which creates just a single page
 *
 * @param content contains the children of the page
 */
export class SinglePage<T> implements Page<T> {

  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: [];
  totalElements: number;
  totalPages: number;

  constructor(content: T[]) {
    this.content = content;
    this.empty = this.content.length === 0;
    this.first = true;
    this.last = true;
    this.number = 0;
    this.numberOfElements = this.content.length;
    this.size = this.content.length;
    this.totalElements = this.content.length;
    this.totalPages = 1;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {PluginConfigField} from '@domain/plugin/plugin-config-field';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FormlyFieldConverterService} from '@shared/formly/formly-field-converter/formly-field-converter.service';

/**
 * A pipe to convert plugin config fields to formly field definitions.
 */
@Pipe({
  name: 'pluginConfigFields'
})
export class PluginConfigFieldsPipe implements PipeTransform {

  constructor(private formlyFieldConverterService: FormlyFieldConverterService) {
  }

  transform(configFields: PluginConfigField[]): FormlyFieldConfig[] {
    return configFields
      ? configFields.map(configField => this.formlyFieldConverterService.fromPluginConfigField(configField))
      : [];
  }
}

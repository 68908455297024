import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldType} from '@ngx-formly/material';

/**
 * A generic ngx-formly checkbox field.
 */
@Component({
  selector: 'coyo-formly-checkbox',
  template: `
    <mat-checkbox
      [formControl]="formControl"
      [id]="id"
      [required]="to.required"
      [formlyAttributes]="field"
      [tabindex]="to.tabindex"
      [indeterminate]="to.indeterminate && formControl.value == null"
      [color]="to.color"
      [labelPosition]="to.align || to.labelPosition">
      {{ to.label }}
      <span *ngIf="to.required && to.hideRequiredMarker !== true" class="mat-form-field-required-marker">*</span>
      <mat-hint *ngIf="to.description">{{ to.description }}</mat-hint>
    </mat-checkbox>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyCheckboxComponent extends FieldType {

  defaultOptions: FormlyFieldConfig = {
    templateOptions: {
      hideFieldUnderline: true,
      indeterminate: true,
      floatLabel: 'always',
      hideLabel: true,
      align: 'start', // start or end
      color: 'accent' // workaround for https://github.com/angular/components/issues/18465
    }
  };
}

import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {Document} from '@domain/file/document';
import {DocumentService} from '@domain/file/document/document.service';

/**
 * Component which offers a Icon-button to download files.
 */
@Component({
  selector: 'coyo-download-button',
  templateUrl: './download-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadButtonComponent implements OnChanges {

  /**
   * Id of the file to download
   */
  @Input() fileId: string;

  /**
   * Sender id of the sender the file belongs to
   */
  @Input() senderId: string;

  /**
   * The download url for the chosen file
   */
  downloadUrl: string;

  constructor(private documentService: DocumentService) { }

  ngOnChanges(): void {
    this.downloadUrl = this.getDownloadUrl();
  }

  private getDownloadUrl(): string {
      return this.documentService.getDownloadUrl({id: this.fileId, senderId: this.senderId} as Document);
  }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CreateFolder} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';

/**
 * Button to create a folder in the file library
 */
@Component({
  selector: 'coyo-create-folder-button',
  templateUrl: './create-folder-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFolderButtonComponent implements OnChanges {
  /**
   * The id of the current location item
   */
  @Input() locationId: string;

  /**
   * The id of the file picker for this button
   */
  @Input() filePickerId: string;
  canCreateFolder$: Observable<boolean>;

  constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService) {
  }

  createFolder(): void {
    this.store.dispatch(new CreateFolder(this.filePickerId, this.translateService.instant('FILE_LIBRARY.DEFAULT_FOLDER_NAME')));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.locationId?.currentValue) {
      this.canCreateFolder$ = this.store.select(FilePickerStateSelectors.canCreateFile(this.locationId));
    }
  }
}

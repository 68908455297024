<div class="flex single-blog-article-widget-settings" id="article-widget-settings" [formGroup]="parentForm">
  <div class="row">
    <label class="col-sm-3 control-label" for="articleId"
           translate="WIDGETS.BLOGARTICLE.SETTINGS.BLOG_ARTICLE"></label>
    <div class="col-sm-8">
      <ng-select
        #searchInput
        id="articlesSelect"
        formControlName="_articleId"
        [items]="blogArticles$ | async"
        [virtualScroll]="true"
        [loading]="loading$ | async"
        bindLabel="title"
        bindValue="id"
        placeholder="{{'WIDGETS.BLOGARTICLE.NOTHING.SELECTED' | translate}}"
        appendTo="body"
        (search)="search($event.term)"
        (clear)="search('')"
        (scroll)="onScroll($event)"
        (scrollToEnd)="onScrollToEnd()"
        (change)="setArticle($event)">
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <span [ngOptionHighlight]="search">{{item.title}}</span>
          <small>{{item.appName}}</small>
        </ng-template>
      </ng-select>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.BLOGARTICLE.SETTINGS.BLOG_ARTICLE.HELP"></coyo-help>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {AutolinkService} from '@shared/text/autolink/autolink.service';

/**
 * Service to handle markdown flavoured texts.
 */
@Injectable({
  providedIn: 'root'
})
export class MarkdownService {
  private marked: any = require('marked');

  constructor(private autolinkService: AutolinkService) {
  }

  /**
   * Transforms text containing markdown to HTML.
   *
   * @param text the markdown-flavoured source text
   * @param autolink enable automatic URL linking
   * @returns the HTML-enriched result
   */
  markdown(text: string, autolink: boolean = true): string {
    if (!text) {
      return text;
    }

    this.marked.use({
      breaks: true,
      mangle: false,
      smartypants: true,
      tokenizer: this.buildTokenizer([
        'space', 'fences', 'blockquote', 'list', 'paragraph', 'text',
        'escape', 'url', 'strong', 'em', 'codespan', 'br', 'del', 'inlineText']),
      renderer: this.buildRenderer()
    });

    const html = this.marked(text).replace(/\n$/g, '');
    return autolink ? this.autolinkService.link(html) : html;
  }

  /**
   * Strips all markdown directives from the given text.
   *
   * Note that this method uses a DOMParser to extract text from the markdown-flavoured
   * source text. This might have some unwanted consequences (such as missing bullets or
   * numbers in unordered or ordered lists).
   *
   * @param text the markdown-flavoured source text
   * @returns the plain result
   */
  strip(text: string): string {
    // use DOMParser to avoid user generated JS code execution
    // see https://stackoverflow.com/questions/822452/strip-html-from-text-javascript/47140708#47140708
    const body = text ? new DOMParser().parseFromString(this.markdown(text, false), 'text/html').body : null;
    return text ? (body.textContent || body.innerText || '').replace(/\n$/g, '') : text;
  }

  private buildTokenizer(whitelist: string[]): object {
    const options = [
      'space', 'code', 'fences', 'heading', 'nptable', 'hr', 'blockquote', 'list', 'html', 'def', 'table', 'lheading', 'paragraph', 'text',
      'escape', 'tag', 'link', 'reflink', 'strong', 'em', 'codespan', 'br', 'del', 'autolink', 'url', 'inlineText'];

    const result = {};
    options
      .filter(option => whitelist.indexOf(option) === -1)
      .forEach(option => result[option] = () => {}); // noop

    return result;
  }

  private buildRenderer(): object {
    return {
      // links are handled by Autolinker but need to be handled to not interfere
      // with other markdown syntax, e.g. google.de/_test_
      link: (href: string, title: string, text: string) => text
    };
  }
}

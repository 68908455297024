(function (angular) {
  'use strict';

  FormController.$inject = ["fields", "app", "createFieldModalService", "$state", "formService"];
  angular
      .module('coyo.apps.form')
      .controller('FormController', FormController);

  function FormController(fields, app, createFieldModalService, $state, formService) {
    var vm = this;

    vm.loading = false;

    vm.save = save;
    vm.createField = createField;
    vm.$onInit = _init;

    function save() {
      vm.loading = true;
      vm.entry.senderId = vm.app.senderId;
      vm.entry.appId = vm.app.id;
      vm.entry.appKey = vm.app.key;
      return vm.entry.save().then(function () {
        return _updateAppPermissions().then(function () {
          vm.formSubmitted = true;
        });
      }).catch(function () {
        vm.formSubmitted = false;
      }).finally(function () {
        vm.loading = false;
      });
    }

    function createField() {
      createFieldModalService.open(app).then(function () {
        $state.go('^.configure');
      });
    }

    function _init() {
      vm.fields = fields;
      vm.app = app;
      vm.formSubmitted = false;
      vm.entry = formService.createEntry();
      vm.appFormSettings = {noAppContext: true};
      _updateAppPermissions();
    }

    function _updateAppPermissions() {
      return app.getWithPermissions('', {}, ['createEntry']).then(function (result) {
        if (result._permissions) {
          angular.extend(app._permissions, result._permissions);
        }
      });
    }
  }
})(angular);

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {CountryFlagComponent} from '@shared/multilanguage/country-flag/country-flag.component';
import {LocalePickerComponent} from '@shared/multilanguage/locale-picker/locale-picker.component';
import {LocalePipe} from '@shared/multilanguage/locale.pipe';
import {LanguagePickerComponent} from './language-picker/language-picker.component';
import './multilanguage-service/multilanguage.service.downgrade';

/**
 * Module for all components related to multi language
 */
@NgModule({
  declarations: [
    LanguagePickerComponent,
    CountryFlagComponent,
    LocalePickerComponent,
    LocalePipe
  ],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule
  ],
  exports: [
    LanguagePickerComponent,
    CountryFlagComponent,
    LocalePickerComponent,
    LocalePipe
  ]
})
export class MultilanguageModule {
}

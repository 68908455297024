import {Injectable} from '@angular/core';
import {NewsViewParams} from '@app/engage/news/news-view-params';
import {STATE_NAME_ENGAGE_NEWS} from '@app/engage/news/news.state';
import {StateService} from '@uirouter/core';

/**
 * Service for changing the state to show a specific blog article.
 */
@Injectable({
  providedIn: 'root'
})
export class EngageNewsHandlerService {

  constructor(private stateService: StateService) {
  }

  /**
   * Move to the blog article state.
   *
   * @param params the params defining which blog article to show
   */
  showNews(params: NewsViewParams): void {
    this.stateService.go(STATE_NAME_ENGAGE_NEWS, params);
  }
}

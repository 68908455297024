import {IconNameFilePickerItem} from '@app/file-library/icon-name-file-picker-item/icon-name-file-picker-item';
import {LOCAL_FILE_LIBRARY, StorageType} from '@domain/attachment/storage';

/**
 * Root file picker item for a list of landing pages as children.
 */
export class LandingPagesRootFilePickerItem implements IconNameFilePickerItem {
  readonly id: string = 'ENTITY_TYPE.LANDING_PAGES';
  readonly name: string = 'ENTITY_TYPE.LANDING_PAGES';
  readonly isFolder: boolean = true;
  readonly storageType: StorageType = LOCAL_FILE_LIBRARY;
  readonly hideHeader: boolean = true;
  readonly icon: string = 'globe';
}

(function (angular) {
  'use strict';

  categoryService.$inject = ["$q", "ngxNotificationService"];
  angular
      .module('commons.ui')
      .factory('categoryService', categoryService);

  /**
   * @ngdoc service
   * @name commons.ui.categoryService
   *
   * @description
   * Provides some functions to save, reorder or delete a category.
   */
  function categoryService($q, ngxNotificationService) {

    return {
      saveCategory: saveCategory,
      changeCategoryOrder: changeCategoryOrder,
      deleteCategory: deleteCategory
    };

    /**
     * Saves a category.
     *
     * @param {object} categoryEvent The category to be saved
     * @param {class} CategoryModel  The category model
     * @return {Promise<object | void>} The saved category or an error.
     */
    function saveCategory(categoryEvent, CategoryModel) {
      var category = new CategoryModel(categoryEvent);
      if (!category.name) {
        ngxNotificationService.error('ERRORS.STATUSCODE.INVALID_ARGUMENTS');
        return $q.reject();
      }
      if (category.name.trim().toUpperCase() === 'N/A') {
        ngxNotificationService.error('ERRORS.STATUSCODE.NAME_TAKEN', null, category);
        return $q.reject();
      }
      return category.save()
          .catch(function () {
            ngxNotificationService.error('ERRORS.STATUSCODE.UNKNOWN');
            return $q.reject();
          });
    }

    /**
     * Saves a category order.
     *
     * @param {object} categoryEvent The category order change event
     * @param {class} CategoryModel  The category model
     * @return {Promise<string[] | void>} The saved order as list of category ids.
     */
    function changeCategoryOrder(categoryEvent, CategoryModel) {
      if (categoryEvent.previousIndex !== categoryEvent.currentIndex) {
        return CategoryModel.order(categoryEvent.categoryOrder);
      }
      return $q.reject();
    }

    /**
     * Deletes a category.
     *
     * @param {object} categoryEvent The category to be deleted
     * @param {class} CategoryModel  The category model
     * @return {Promise<object | void>} The deleted category.
     */
    function deleteCategory(categoryEvent, CategoryModel) {
      var category = new CategoryModel(categoryEvent);
      return category.delete()
          .catch(function () {
            ngxNotificationService.error('ERRORS.STATUSCODE.UNKNOWN');
            return $q.reject();
          });
    }
  }

})(angular);

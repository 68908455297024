<div class="row">
  <div class="col-md-8">
    <div class="card account-settings">
      <div class="card-body">
        <h2 class="mat-headline">{{'SETTINGS.ACCOUNT.HEADLINE' | translate}}</h2>
        <coyo-settings-name fieldName="firstName"></coyo-settings-name>
        <coyo-settings-name fieldName="lastName"></coyo-settings-name>
        <coyo-settings-email></coyo-settings-email>
      </div>
      <hr>
      <div class="card-body">
        <h2 class="mat-headline">{{'SETTINGS.PREFERENCES.HEADLINE' | translate}}</h2>
        <coyo-settings-language></coyo-settings-language>
        <coyo-settings-timezone></coyo-settings-timezone>
      </div>
      <ng-container *ngIf="showPasswordSettings$ | async">
        <hr>
        <coyo-settings-password></coyo-settings-password>
      </ng-container>
    </div>
  </div>
  <div class="col-md-4">
    <coyo-settings-integrations *ngIf="showIntegrationSettings$ | async"></coyo-settings-integrations>
    <coyo-settings-devices></coyo-settings-devices>
  </div>
</div>


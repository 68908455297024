(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name commons.rxjs
   *
   * @description
   * The `commons.rxjs` module contains essential components for reactive extension bindings
   * for Angular apps.
   *
   * @see https://github.com/Reactive-Extensions/rx.angular.js
   */
  angular
      .module('commons.rxjs', []);

})(angular);

import {Pipe, PipeTransform} from '@angular/core';
import {SenderEvent} from '@domain/event/sender-event';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

/**
 * Transforms an event to its correct event date.
 */
@Pipe({
  name: 'eventDate'
})
export class EventDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(event: SenderEvent): string {
    const format = this.translateService.instant('DATE_FORMAT_SHORT');

    const startDate = moment(event.startDate);
    const endDate = moment(event.endDate);

    const start = startDate.format(format);
    const end = endDate.format(format);

    return start === end ? start : `${start} – ${end}`;
  }
}

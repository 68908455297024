import {Pipe, PipeTransform} from '@angular/core';
import {TimeProviderService} from '@core/time-provider/time-provider.service';
import {BirthdayService} from '@widgets/birthday/birthday/birthday.service';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  constructor(private birthdayService: BirthdayService, private timeProviderService: TimeProviderService) {}

  /**
   * Calculates the age that the person will have on the next birthday from a given reference date.
   *
   * @param birthday The birthday
   * @param referenceDate The reference date, for example the current date
   *
   * @returns the age that the person will have on the next birthday from a given reference date
   */
  transform(birthday: string, referenceDate: Date): number | null {
    if (this.birthdayService.hasYear(birthday)) {
      const birthDate = this.timeProviderService.createDateWithoutTimezoneOffset(birthday);
      return birthDate.getMonth() < referenceDate.getMonth() ||
      (birthDate.getMonth() === referenceDate.getMonth() && birthDate.getDate() < referenceDate.getDate())
        ? referenceDate.getFullYear() - birthDate.getFullYear() + 1
        : referenceDate.getFullYear() - birthDate.getFullYear();
    }
    return null;
  }

}

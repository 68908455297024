import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {RibbonComponent} from './ribbon.component';
import './ribbon.component.downgrade';

/**
 * Module for the ribbon component.
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    RibbonComponent
  ],
  exports: [
    RibbonComponent
  ],
  entryComponents: [
    RibbonComponent
  ]
})
export class RibbonModule {}

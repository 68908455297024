(function (angular) {
  'use strict';

  MessagingSidebarController.$inject = ["$scope", "$rootScope", "$timeout", "$localStorage", "authService", "sidebarService", "userService", "targetService"];
  angular
      .module('coyo.messaging')
      .controller('MessagingSidebarController', MessagingSidebarController);

  /**
   * Main messaging sidebar controller.
   */
  function MessagingSidebarController($scope, $rootScope, $timeout, $localStorage, authService, sidebarService,
                                      userService, targetService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.toggleCompact = toggleCompact;
    vm.home = home;
    vm.openChannel = openChannel;
    vm.switchView = switchView;
    vm.openProfile = openProfile;

    function open() {
      vm.show = true;
      $rootScope.showBackdrop = !$rootScope.screenSize.isLg;
    }

    function close() {
      vm.show = false;
      $rootScope.showBackdrop = false;
    }

    function toggleCompact(on) {
      vm.compact = on;
      $rootScope.$broadcast('toggleMessagingSidebar');
      $rootScope.messagingCompact = on;
      $localStorage.messagingSidebar.compact = on;
    }

    function home() {
      vm.view = null;
      vm.currentChannel = null;
      vm.hasNewMessages = false;
    }

    function openChannel(channel) {
      vm.currentChannel = channel;
      switchView('channel');
      $rootScope.$emit('messaging:resetUnreadCount', channel);
    }

    function switchView(view) {
      toggleCompact(false);
      vm.view = view;
    }

    function openProfile(channel, currentUser) {
      var sender = channel.getChannelPartner(currentUser);
      if (sender) {
        targetService.onCanLinkTo(sender.target, function (canLink) {
          if (canLink) {
            targetService.go(sender.target);
          } else {
            switchView('channelInfo');
          }
        });
      }
    }

    function onInit() {
      vm.presenceStatus = {
        state: 'OFFLINE'
      };

      if (!$localStorage.messagingSidebar) {
        $localStorage.messagingSidebar = {
          compact: true
        };
      }

      toggleCompact($localStorage.messagingSidebar.compact);

      sidebarService.register({
        name: 'messaging',
        open: open,
        close: close,
        isOpen: function () {
          return vm.show;
        }
      });

      authService.getUser().then(function (user) {
        vm.currentUser = user;

        userService.getPresenceStatus(user, function (status) {
          vm.presenceStatus = status;
        }, $scope);
      });

      // react to outside toggle via event
      var unsubscribeToggleFn = $rootScope.$on('messaging:toggle', function (event, compact) {
        return toggleCompact(compact);
      });

      // react to outside toggle via event
      var unsubscribeStartFn = $rootScope.$on('messaging:start', function (event, channel) {
        vm.view = null;
        $timeout(function () {
          openChannel(channel);
          sidebarService.open('messaging');
        });
      });

      // react to resize via event
      var unsubscribeChangedFn = $rootScope.$on('screenSize:changed', function (event, screenSize) {
        if (vm.show) {
          $rootScope.showBackdrop = !screenSize.isLg;
        }
      });
      var unsubscribeScreenSizeChangeFn = $rootScope.$on('screenSize:changed', function (event, screenSize) {
        _updateSize(screenSize);
      });
      _updateSize($rootScope.screenSize);

      $scope.$on('$destroy', function () {
        unsubscribeToggleFn();
        unsubscribeStartFn();
        unsubscribeChangedFn();
        unsubscribeScreenSizeChangeFn();
      });
    }

    function _updateSize(screenSize) {
      vm.isMobile = screenSize.isXs || screenSize.isSm;
    }
  }

})(angular);

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {FileModule} from '@shared/files/file.module';
import {TimeModule} from '@shared/time/time.module';
import {SearchResultExternalComponent} from './search-result-external/search-result-external.component';
import {SearchResultsExternalPanelComponent} from './search-results-external-panel/search-results-external-panel.component';
import {SearchResultsExternalWrapperComponent} from './search-results-external-wrapper/search-results-external-wrapper.component';
import './search-results-external-wrapper/search-results-external-wrapper.component.downgrade';
import {SearchSortingMenuComponent} from './search-sorting-menu/search-sorting-menu.component';
import './search-sorting-menu/search-sorting-menu.component.downgrade';

/**
 * This module provides access to the COYO search as well as to external search providers.
 */
@NgModule({
  imports: [
    CommonModule,
    CoyoCommonsModule,
    FileModule,
    TimeModule,
    TranslateModule
  ],
  declarations: [
    SearchResultExternalComponent,
    SearchResultsExternalPanelComponent,
    SearchResultsExternalWrapperComponent,
    SearchSortingMenuComponent
  ],
  entryComponents: [
    SearchResultsExternalWrapperComponent
  ]
})
export class SearchModule {
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PdfViewerLoaderComponent} from '@shared/pdf/pdf-viewer-loader/pdf-viewer-loader.component';

/**
 * Module that lazy loads the pdf viewer module.
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PdfViewerLoaderComponent
  ],
  exports: [
    PdfViewerLoaderComponent
  ],
  entryComponents: [
    PdfViewerLoaderComponent
  ]
})
export class PdfModule {
}

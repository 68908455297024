(function (angular) {
  'use strict';

  AdminSubscriptionController.$inject = ["$state", "$scope", "coyoUrls", "ngxLicenseService"];
  angular
      .module('coyo.admin.license')
      .controller('AdminSubscriptionController', AdminSubscriptionController);

  function AdminSubscriptionController($state, $scope, coyoUrls, ngxLicenseService) {
    var vm = this;
    vm.$onInit = _init;

    function _init() {
      ngxLicenseService.getLicense().toPromise().then(function (license) {
        if (license.environment === 'enterprise') {
          $state.go('admin.license.license');
        }

        vm.customerCenterLink = coyoUrls.customerCenter + '/order/' + license.orderId;

        vm.status = license.status;
        vm.validationFailures = license.validationFailures;
        vm.addons = license.addons;
        vm.validDaysLeft = license.validDaysLeft;
        vm.until = license.until;
        vm.user = {
          current: license.currentUserSize,
          max: license.maxUserSize
        };
        vm.storage = {
          current: license.currentStorageSize,
          max: license.maxStorageSize
        };
        $scope.$digest();
      });
    }
  }
})(angular);

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Store} from '@ngxs/store';
import {WidgetInlineSettingsComponent} from '@widgets/api/widget-inline-settings-component';
import {UpdateWidgetSettings} from '@widgets/api/widget.actions';
import {HeadlineWidget} from '@widgets/headline/headline-widget';
import {SizeOption} from '../size-option';
import sizeOptions from '../size-options';

/**
 * Inline settings component for the headline widget
 */
@Component({
  selector: 'coyo-headline-widget-inline-options',
  templateUrl: './headline-widget-inline-options.component.html',
  styleUrls: ['./headline-widget-inline-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadlineWidgetInlineOptionsComponent extends WidgetInlineSettingsComponent<HeadlineWidget> {

  options: SizeOption[] = sizeOptions;

  constructor(private store: Store) {
    super();
  }

  /**
   * Select a size option
   * @param option size option
   */
  select(option: SizeOption): void {
    const settings = {...this.widget.settings, _headline: option};
    const updateWidgetSettingsAction = new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId, settings, this.editScope);
    this.store.dispatch(updateWidgetSettingsAction);
  }
}

(function (angular) {
  'use strict';

  angular
      .module('coyo.apps.blog')
      .controller('BlogFrameController', BlogFrameController);

  /**
   * The main blog controller
   *
   * @constructor
   */
  function BlogFrameController() {
    //var vm = this;
  }

})(angular);

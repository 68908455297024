import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DeviceDetectionService} from '@core/device-detection/device-detection.service';
import {ExperimentalFeatureToggleService} from '@core/feature/experimental-feature-toggle-service/experimental-feature-toggle.service';
import {Observable, Subject} from 'rxjs';
import {bufferCount, map, startWith} from 'rxjs/operators';

/**
 * Renders a modal with the "About COYO" content.
 */
@Component({
  templateUrl: './about-coyo-modal.component.html',
  styleUrls: ['./about-coyo-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutCoyoModalComponent implements OnInit {
  experimentalFeaturesActive$: Observable<boolean>;
  clicks: Subject<void> = new Subject<void>();
  privacyPolicyLinkMessageKey: string;

  constructor(private experimentalFeatureService: ExperimentalFeatureToggleService, private deviceDetectionService: DeviceDetectionService,
              @Inject(MAT_DIALOG_DATA) public versionString: string) {
  }

  ngOnInit(): void {
    this.privacyPolicyLinkMessageKey = this.deviceDetectionService.isIOS() ? 'ABOUT_COYO.PRIVACY_LINK_IOS' : 'ABOUT_COYO.PRIVACY_LINK';
    this.experimentalFeaturesActive$ = this.clicks
      .pipe(bufferCount(3))
      .pipe(map(() =>
        this.experimentalFeatureService.toggleExperimentalFeatures()
      )).pipe(startWith(this.experimentalFeatureService.experimentalFeaturesActive()));
  }
}

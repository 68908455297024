<div class="latest-files-widget">
  <h3 class="widget-title">
    {{title || ("WIDGETS.LATESTFILES.NAME" | translate)}}
  </h3>
  <em *ngIf="!(hasFiles() | async) && !(pageDeleted$ | async)" class="text-muted" translate="WIDGETS.LATESTFILES.NO_FILES"></em>
  <div *ngIf="pageDeleted$ | async" class="alert alert-warning">{{'WIDGETS.LATESTFILES.PAGE_DEACTIVATED' | translate}}</div>
  <div *ngFor="let file of files$ | async" class="file-item">
    <div class="file-info">
      <coyo-file-icon-by-mime-type
        [mimeType]="file.contentType"
        [storage]="file.storage" size="small">
      </coyo-file-icon-by-mime-type>
      <a (click)="openDetails($event, file)" class="file-info-title" href>{{file.displayName}}</a>
      <span *ngIf="!isNewFile(file)" class="label label-warning label-text"
            translate="WIDGETS.LATESTFILES.UPDATED"></span>
      <span *ngIf="!isNewFile(file)" class="label label-warning label-empty-text"></span>
    </div>
    <div *ngIf="file.author" class="author small">
      <a [uiParams]="{userId: file.author.slug}" href uiSref="main.profile">{{file.author.displayName}}</a>
      <coyo-time-ago [date]="file.modified" class="text-muted"></coyo-time-ago>
    </div>
  </div>
</div>

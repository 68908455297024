import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {Skeleton} from '@coyo/ui/lib/ui/skeleton/skeleton';
import {EventService} from '@domain/event/event.service';
import {SenderEvent} from '@domain/event/sender-event';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UpcomingEventsWidget} from '@widgets/upcoming-events/upcoming-events-widget';
import {UpcomingEventsWidgetSettings} from '@widgets/upcoming-events/upcoming-events-widget-settings.model';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

/**
 * This widget displays upcoming events.
 */
@Component({
  selector: 'coyo-upcoming-events-widget',
  templateUrl: 'upcoming-events-widget.component.html',
  styleUrls: ['./upcoming-events-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcomingEventsWidgetComponent extends WidgetComponent<UpcomingEventsWidget> implements OnInit, OnChanges {
  private settings$: Subject<UpcomingEventsWidgetSettings>;

  readonly skeletons: Skeleton[] = [
    {top: 0, left: 0, width: '100%', height: 72, radius: 0},
    {top: 8, left: 30, width: 56, height: 56, color: '#fff'},
    {top: 90, left: 30, width: '60%', height: 16},
    {top: 119, left: 30, width: 14, height: 14, radius: '50%'},
    {top: 119, left: 50, width: '40%', height: 14},
    {top: 144, left: 30, width: 14, height: 14, radius: '50%'},
    {top: 144, left: 50, width: '50%', height: 14},
    {top: 169, left: 30, width: 14, height: 14, radius: '50%'},
    {top: 169, left: 50, width: '30%', height: 14}
  ];

  skeletonCount$: Observable<number>;

  events$: Observable<SenderEvent[]>;

  constructor(cd: ChangeDetectorRef,
              private sanitizer: DomSanitizer,
              private eventService: EventService) {
    super(cd);
  }

  ngOnInit(): void {
    this.settings$ = new BehaviorSubject<UpcomingEventsWidgetSettings>(this.widget.settings);
    this.skeletonCount$ = this.settings$.asObservable()
      .pipe(map(settings => Math.min(settings._numberOfDisplayedEvents, 5)));
    this.events$ = this.settings$.asObservable()
      .pipe(switchMap(settings => this.fetchEvents(settings)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.changedSettingsIn(changes, '_fetchUpcomingEvents', '_displayOngoingEvents', '_numberOfDisplayedEvents', '_sender')) {
      this.settings$.next(_.get(changes, 'widget.currentValue.settings') as unknown as UpcomingEventsWidgetSettings);
    }
  }

  getCover(event: SenderEvent): SafeStyle | null {
    const cover = _.get(event, 'imageUrls.cover', null);
    return cover ? this.sanitizer.bypassSecurityTrustStyle(`url(${event.imageUrls.cover}&imageSize=XL)`) : null;
  }

  private fetchEvents(settings: UpcomingEventsWidgetSettings): Observable<SenderEvent[]> {
    const widgetId = this.widget.id;
    const limit = settings._numberOfDisplayedEvents;
    const includeOngoing = settings._displayOngoingEvents;
    const fetchType = settings._fetchUpcomingEvents;
    const hostId = settings._sender;

    return this.eventService.getUpcomingEvents(widgetId, limit, includeOngoing, fetchType, hostId);
  }
}

import {HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Ng1AuthService} from '@root/typings';
import {NG1_AUTH_SERVICE, NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {BackendInterceptor} from '../backend-interceptor/backend-interceptor';
import {UrlService} from '../url/url.service';

/**
 * Http interceptor checks if the user is not authenticated after a response and redirects to the login screen
 */
@Injectable()
export class AuthInterceptor extends BackendInterceptor {

  constructor(@Inject(NG1_STATE_SERVICE) private state: IStateService,
              @Inject(NG1_AUTH_SERVICE) private authService: Ng1AuthService,
              urlService: UrlService) {
    super(urlService);
  }

  /**
   * Reads the status code of the response and if it is a 401 and the user is authenticated, the session is cleared
   * and the user gets redirected to the login screen.
   *
   * @param req the request
   * @param next the next http handler in the chain
   * @return an Observable of the http event
   */
  interceptBackendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => this.checkAuthentication(event)));
  }

  private checkAuthentication(event: HttpEvent<any>): void {
    if (event instanceof HttpResponse && event.status === 401) {
      if (this.authService.isAuthenticated()) {
        this.authService.clearSession().then(() => {
          this.state.go('front.login');
        });
      }
    }
  }
}

import {LikeInfoUpdate} from '@domain/like/like-info-update';
import {LikeToggledEvent} from '@domain/like/like-toggled-event';
import {Likeable} from '@domain/like/likeable';

export class ToggleLikeSucceeded {
   static readonly type: string = '[Like API] ToggleLikeSucceeded';
   constructor(public likeInfoUpdate: LikeInfoUpdate) {
   }
}
export class ToggleLikeFailed {
  static readonly type: string = '[Like API] ToggleLikeFailed';
  constructor(public targetId: string) {}
}

export class UpdateSenderIdForTarget {
  static readonly type: string = '[Like Component] UpdateSenderIdForTarget';
  constructor(public senderId: string, public target: Likeable) {
  }
}

export class LikeInfosFetched {
  static readonly type: string = '[Like API] LikeInfosFetched';
  constructor(public likeInfoUpdates: LikeInfoUpdate[]) {
  }
}

export class SubscribeLikeInfo {
  static readonly type: string = '[Like Component] SubscribeLikeInfo';
  constructor(public senderId: string, public target: Likeable) {
  }
}

export class UnsubscribeLikeInfo {
  static readonly type: string = '[Like Component] UnsubscribeLikeInfo';
  constructor(public target: Likeable) {
  }
}

export class ToggleLike {
  static readonly type: string = '[Like API] ToggleLike';
  constructor(public senderId: string, public target: Likeable) {
  }
}

export class LikeAddedOrRemoved {
  static readonly type: string = '[Like API] LikeRemoved';
  constructor(public likeToggledEvent: LikeToggledEvent) {
  }
}

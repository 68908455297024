import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Session} from './session';
import {InitSessions, LogoutSession} from './sessions.action';
import {SessionsStateModel, SettingsDevicesState} from './sessions.state';

@Component({
  selector: 'coyo-settings-devices',
  templateUrl: './settings-devices.component.html',
  styleUrls: ['./settings-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsDevicesComponent implements OnInit {
  sessions$: Observable<{ [key: string]: Session[] }>;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new InitSessions());
    this.sessions$ = this.store.select<SessionsStateModel>(SettingsDevicesState)
      .pipe(map((state: SessionsStateModel) => _.groupBy(state.sessions, 'name')));
  }

  /**
   * Kills the user session on the intended device.
   *
   * @param userId the user ID
   * @param deviceId the device ID
   */
  logout(userId: string, deviceId: string): void {
    this.store.dispatch(new LogoutSession(userId, deviceId));
  }
}

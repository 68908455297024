<div class="terms" *ngIf="terms$ | async; let terms; else loading">
  <div class="terms-header">
    <h3 class="terms-title">{{terms.title}}</h3>
  </div>
  <div class="terms-body">
    <div class="rte-html-container fr-view" [innerHTML]="terms.text | trustHtml"></div>
  </div>
</div>
<ng-template #loading>
  <div class="skeleton"></div>
</ng-template>

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {IframeWidget} from '@widgets/iframe/iframe-widget';
import {IframeWidgetSettingsComponent} from '@widgets/iframe/iframe-widget-settings/iframe-widget-settings.component';
import {IframeWidgetComponent} from '@widgets/iframe/iframe-widget/iframe-widget.component';

export const IFRAME_WIDGET: WidgetConfig<IframeWidget> = {
  key: 'iframe',
  name: 'WIDGET.IFRAME.NAME',
  description: 'WIDGET.IFRAME.DESCRIPTION',
  icon: 'zmdi-picture-in-picture',
  categories: WidgetCategory.DYNAMIC,
  settings: {
    skipOnCreate: false,
    component: IframeWidgetSettingsComponent
  },
  renderOptions: {
    printable: true
  },
  component: IframeWidgetComponent,
  whitelistExternal: true
};

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {ContentTypeService} from '@widgets/media/content-type/content-type.service';
import {LightBoxService} from '@widgets/media/light-box/light-box.service';
import {MediaWidget} from '@widgets/media/media-widget';
import {MediaWidgetFile} from '@widgets/media/media-widget-file';

/**
 * The Media widget component.
 */
@Component({
  selector: 'coyo-media-widget',
  templateUrl: './media-widget.component.html',
  styleUrls: ['./media-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaWidgetComponent extends WidgetComponent<MediaWidget> implements OnInit, OnChanges {
  readonly previewUrl: string = '/web/senders/{{groupId}}/documents/{{id}}';

  previewMedia: MediaWidgetFile[];

  constructor(changeDetectorRef: ChangeDetectorRef,
              private contentTypeService: ContentTypeService,
              private lightBoxService: LightBoxService) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {
    this.previewMedia = this.getPreviewMedia();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.previewMedia = this.getPreviewMedia();
    }
  }

  private getPreviewMedia(): MediaWidgetFile[] {
    return this.widget.settings._media
      .filter(media => media.preview)
      .sort((a, b) => a.sortOrderId - b.sortOrderId);
  }

  /**
   * Checks if the given media is an Video.
   *
   * @param document
   * The media document
   *
   * @returns
   * true if the document is a video
   */
  isVideo(document: MediaWidgetFile): boolean {
    return this.contentTypeService.isVideo(document.contentType);
  }

  /**
   * Checks if the given media is an Image.
   *
   * @param document
   * The media document
   *
   * @returns
   * true if the document is a image
   */
  isImage(document: MediaWidgetFile): boolean {
    return this.contentTypeService.isImage(document.contentType);
  }

  /**
   * Calculates if the overlay for additional media should be shown.
   *
   * @param isLast
   * true if the item is the last item of the preview media
   *
   * @returns
   * true if the overlay should be displayed
   */
  showAdditionalMediaOverlay(isLast: boolean): boolean {
    return isLast && !!(this.widget.settings._media.length - this.previewMedia.length);
  }

  /**
   * Opens the lightbox modal for the current document.
   *
   * @param currentFile
   * The document to show first
   */
  openLightBox(currentFile: MediaWidgetFile): void {
    this.lightBoxService.openLightBox(currentFile.sortOrderId, this.widget.settings._media);
  }
}

import {NgModule} from '@angular/core';
import {PickerModule} from '@coyo/ngx-emoji-mart';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {NgxPopperModule} from 'ngx-popper';
import {EmojiPickerComponent} from './emoji-picker/emoji-picker.component';

/**
 * Module to provide the emoji picker.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    NgxPopperModule,
    PickerModule
  ],
  declarations: [
    EmojiPickerComponent
  ],
  exports: [
    EmojiPickerComponent
  ],
  entryComponents: [
    EmojiPickerComponent
  ]
})
export class EmojiModule {}

import {Injectable} from '@angular/core';
import {File} from '@domain/file/file';
import {FilePreview} from '@domain/preview/file-preview/file-preview';

/**
 * Transform files to filePreviews
 */
@Injectable({
  providedIn: 'root'
})
export class FilePreviewService {

  constructor() {
  }

  toFilePreviews(files: File[]): FilePreview[] {
    return files.map(this.toFilePreview);
  }

  toFilePreview(file: File): FilePreview {
    return {
      length: 1, // this is a fallback because previews need a length
      ...file,
      serialVersionUID: null,
      id: file.id,
      name: file.name,
      contentType: file.contentType,
      previewUrl: null,
      created: new Date(file.created),
      modified: new Date(file.modified),
      storage: 'LOCAL_BLOB_STORAGE',
      storageAvailable: true,
      downloadUrl: null,
      fileId: file.id,
      modelId: null,
      deleted: false,
      attachment: false,
      groupId: file.senderId,
      senderId: file.senderId
    };
  }
}

import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {CropSettings} from '@app/file-picker/crop-settings';
import {
  FilePickerItemStateModel,
  FilePickerStateModel,
  FilePickerStatesModel
} from '@app/file-picker/file-picker-state/file-picker-state-model';
import {FilePickerState} from '@app/file-picker/file-picker-state/file-picker.state';
import {FilePickerUploadItem} from '@app/file-picker/file-picker-upload-item/file-picker-upload-item';
import {User} from '@domain/user/user';
import {createSelector} from '@ngxs/store';

export class FilePickerStateSelectors {

  /**
   * Selector for the current folder of the file picker
   * @param filePickerId The id of the file picker
   * @returns A selector function for the current location of the given file picker
   */
  static currentLocation(filePickerId: string): (state: FilePickerStatesModel) => FilePickerItemStateModel | null {
    return createSelector([FilePickerState], state => FilePickerState.getCurrentLocation(state, filePickerId));
  }

  /**
   * Selector for the root folder of the file picker
   * @param filePickerId The id of the file picker
   * @returns A selector function for the root of the given file picker
   */
  static root(filePickerId: string): (state: FilePickerStatesModel) => FilePickerItemStateModel | null {
    return createSelector([FilePickerState], state => {
      const breadcrumbs = state.pickers[filePickerId]?.breadcrumbs;
      if (breadcrumbs && breadcrumbs.length > 0) {
        return state.items[breadcrumbs[0]];
      }
      return null;
    });
  }

  /**
   * Selector for the parent of a file picker
   * @param filePickerId The id of the file picker
   * @returns A selector function for the parent of the given file picker
   */
  static parent(filePickerId: string): (state: FilePickerStatesModel) => FilePickerItemStateModel | null {
    return createSelector([FilePickerState], state => {
      const breadcrumbs = state.pickers[filePickerId]?.breadcrumbs;
      if (breadcrumbs && breadcrumbs.length > 1) {
        return state.items[breadcrumbs[breadcrumbs.length - 2]];
      }
      return null;
    });
  }

  /**
   * Selector for the items of a file picker
   * @param filePickerId The id of the file picker
   * @returns A selector function for the items of the given file picker
   */
  static items(filePickerId: string): (state: FilePickerStatesModel) => FilePickerItemStateModel[] {
    return createSelector([FilePickerState], state => FilePickerStateSelectors.getItems(state, filePickerId)
    );
  }

  /**
   * Selector for a file picker
   * @param filePickerId The id of the file picker
   * @returns A selector function for the given file picker
   */
  static filePicker(filePickerId: string): (state: FilePickerStatesModel) => FilePickerStateModel {
    return createSelector([FilePickerState], state => state.pickers[filePickerId]);
  }

  /**
   * Selector for a single file picker item
   * @param itemId The id of the file picker item
   * @returns A selector function for the items of the given file picker
   */
  static item(itemId: string): (state: FilePickerStatesModel) => FilePickerItemStateModel {
    return createSelector([FilePickerState], state => state.items[itemId]);
  }

  /**
   * Selector for checking if an item allows file creation
   * @param itemId The id of the file picker item
   * @returns A selector function for the boolean result
   */
  static canCreateFile(itemId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => {
      const model = state.items[itemId];
      return this.checkFileCreation(model);
    });
  }

  /**
   * Selector for checking if an item allows file creation at the current location
   * @param filePickerId The id of the file picker
   * @returns A selector function for the boolean result
   */
  static canCreateFileAtCurrentLocation(filePickerId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => {
      const filePickerItemStateModel = FilePickerState.getCurrentLocation(state, filePickerId);
      const model = state.items[filePickerItemStateModel.item.id];
      return this.checkFileCreation(model);
    });
  }

  /**
   * Selector for checking if an item allows renaming
   * @param itemId The id of the file picker item
   * @returns A selector function for the boolean result
   */
  static canRename(itemId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => {
      const model = state.items[itemId];
      // tslint:disable-next-line:no-bitwise
      const hasCapability = !!(model.capabilities & FilePickerItemServiceCapability.RENAME);
      // tslint:disable-next-line:no-boolean-literal-compare
      const hasPermission = model.item?.permissions?.manage !== false;
      return hasCapability && hasPermission;
    });
  }

  /**
   * Selector for checking if an item allows deleting
   * @param itemId The id of the file picker item
   * @returns A selector function for the boolean result
   */
  static canDelete(itemId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => {
      const model = state.items[itemId];
      const isAppFolder = model.item instanceof FileLibraryFilePickerItem ? model.item.isAppRoot : false;
      // tslint:disable-next-line:no-bitwise
      const hasCapability = !!(model.capabilities & FilePickerItemServiceCapability.DELETE);
      // tslint:disable-next-line:no-boolean-literal-compare
      const hasPermission = model.item?.permissions?.manage !== false;
      return !isAppFolder && hasCapability && hasPermission;
    });
  }

  /**
   * Selector for checking if an item allows editing it with office
   * @param itemId The id of the file picker item
   * @returns A selector function for the boolean result
   */
  static canPublicLink(itemId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => {
      const model = state.items[itemId];
      // tslint:disable-next-line:no-bitwise
      const hasCapability = !!(model.capabilities & FilePickerItemServiceCapability.PUBLIC_LINK);
      // tslint:disable-next-line:no-boolean-literal-compare
      const hasPermission = model.item?.permissions?.publicLink !== false;
      // tslint:disable-next-line:no-boolean-literal-compare
      const isFile = !model.item.isFolder;
      return hasCapability && hasPermission && isFile;
    });
  }

  /**
   * Selector for checking if an item allows editing it with office
   * @param itemId The id of the file picker item
   * @returns A selector function for the boolean result
   */
  static canEditWithOffice(itemId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => {
      const model = state.items[itemId];
      // tslint:disable-next-line:no-bitwise
      const hasCapability = !!(model.capabilities & FilePickerItemServiceCapability.EDIT_WITH_OFFICE);
      // tslint:disable-next-line:no-boolean-literal-compare
      const hasPermission = model.item?.permissions?.editOffice !== false;
      return !model.item.lock?.locked && hasCapability && hasPermission;
    });
  }

  /**
   * Selector for checking if an item allows download
   * @param itemId The id of the file picker item
   * @returns A selector function for the boolean result
   */
  static canDownload(itemId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => {
      const item = state.items[itemId].item;
      return item.isFolder ? (item as FileLibraryFilePickerItem).childCount > 0 : true;
    });
  }

  /**
   * Selector for the breadcrumb items of a file picker
   * @param filePickerId The id of the file picker
   * @returns A selector function for the breadcrumb items of the given file picker
   */
  static breadcrumbs(filePickerId: string): (state: FilePickerStatesModel) => FilePickerItemStateModel[] {
    return createSelector([FilePickerState], state => state.pickers[filePickerId]?.breadcrumbs?.map(itemId => state.items[itemId]) ?? []);
  }

  /**
   * Selector for the loading state of a file picker
   * @param filePickerId The id of the file picker
   * @returns A selector function for the loading state of the given file picker
   */
  static loading(filePickerId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => state.pickers[filePickerId]?.loading ?? false);
  }

  /**
   * Selector if show authors is activated in the given picker
   *
   * @param filePickerId The picker id
   *
   * @returns true if authors should be shown
   */
  static showAuthor(filePickerId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => !!state.pickers[filePickerId].options?.showAuthors);
  }

  /**
   * Selector for the app id of the file picker (if available)
   *
   * @param filePickerId The picker id
   *
   * @returns the id of the app if the file picker is opened in an app or null otherwise
   */
  static appId(filePickerId: string): (state: FilePickerStatesModel) => string {
    return createSelector([FilePickerState], state => state.pickers[filePickerId].options?.appId);
  }

  /**
   * Selector which parse the mime types
   *
   * @param filePickerId The picker id
   *
   * @returns string the parsed mime types
   */
  static canParseMimeTypes(filePickerId: string): (state: FilePickerStatesModel) => string {
    return createSelector([FilePickerState], state => this.parseMimeTypes(state.pickers[filePickerId].options.contentTypes));
  }

  /**
   * Selector for the author of a item
   *
   * @param itemId The id of the item
   *
   * @returns the author of the item
   */
  static author(itemId: string): (state: FilePickerStatesModel) => User | null {
    return createSelector([FilePickerState], state => state.items[itemId].author);
  }

  /**
   * Selector if uploaded images should be cropped before upload.
   *
   * @param filePickerId The id of the file picker
   *
   * @returns True if the cropping dialog should be opened before upload of images.
   */
  static shouldCrop(filePickerId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => state.pickers[filePickerId].options?.cropSettings?.cropImage);
  }

  /**
   * Selector for current crop settings.
   *
   * @param filePickerId The id of the file picker
   *
   * @returns The crop settings
   */
  static cropSettings(filePickerId: string): (state: FilePickerStatesModel) => CropSettings {
    return createSelector([FilePickerState], state => state.pickers[filePickerId].options?.cropSettings);
  }

  /**
   * Selector for download selection mode
   * @param filePickerId The id of the file picker
   *
   * @returns True if the selection mode of the picker is download.
   */
  static isDownloadMode(filePickerId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState], state => state.pickers[filePickerId]?.options?.selectionMode === 'download');
  }

  /**
   * Gets the overall number of files and directories in the current folder.
   *
   * @param filePickerId The file picker id
   *
   * @returns The overall number of files and directories
   */
  static totalItemsInCurrentFolder(filePickerId: string): (state: FilePickerStatesModel) => number {
    return createSelector([FilePickerState], state => state.pickers[filePickerId]?.totalItems);
  }

  /**
   * Returns the file at a specific index.
   *
   * @param filePickerId The file picker id
   * @param index The index
   *
   * @returns The file at the given index
   */
  static fileAtIndex(filePickerId: string, index: number): (state: FilePickerStatesModel) => FilePickerItemStateModel {
    return createSelector([FilePickerState], state => state.items[state.pickers[filePickerId].itemIds[index]]);
  }

  /**
   * Returns if there is still uploading in progress.
   *
   * @param filePickerId The file picker id
   *
   * @returns True if there is still uploading in progress
   */
  static isUploading(filePickerId: string): (state: FilePickerStatesModel) => boolean {
    return createSelector([FilePickerState],
      state => FilePickerStateSelectors
        .getItems(state, filePickerId)
        .some(model => model.item instanceof FilePickerUploadItem));
  }

  private static getItems(state: FilePickerStatesModel, filePickerId: string): FilePickerItemStateModel[] {
    return state.pickers[filePickerId]?.itemIds?.map(itemId => state.items[itemId]).filter(item => !!item) ?? [];
  }

  private static checkFileCreation(model: FilePickerItemStateModel): boolean {
    // tslint:disable-next-line:no-bitwise
    const hasCapability = !!(model.capabilities & FilePickerItemServiceCapability.CREATE_FILE);
    const hasPermission = (model.item?.permissions?.createFile === undefined && model.item?.permissions?.manage === undefined) ||
      (model.item?.permissions?.createFile || model.item?.permissions?.manage);
    return model.item.isFolder && hasCapability && hasPermission;
  }

  private static parseMimeTypes(types: string[]): string {
    if (!types || types.length < 1) {
      return '*';
    }
    return types.map(type => type.indexOf('/') < 0 ? `${type}/*` : type).join(',');
  }
}

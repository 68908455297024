(function (angular) {
  'use strict';

  MessagingChannelInfoController.$inject = ["MessageAttachmentModel", "MessageChannelMemberModel", "Pageable", "backendUrlService", "externalFileHandlerService", "$scope"];
  angular
      .module('coyo.messaging')
      .controller('MessagingChannelInfoController', MessagingChannelInfoController);

  function MessagingChannelInfoController(MessageAttachmentModel, MessageChannelMemberModel, Pageable,
                                          backendUrlService, externalFileHandlerService, $scope) {
    var vm = this;
    vm.$onInit = onInit;

    vm.loadMoreAttachments = loadMoreAttachments;
    vm.deleteMember = deleteMember;
    vm.toggleMemberRole = toggleMemberRole;

    function loadMoreAttachments() {
      if (!vm.loading && (!vm.attachmentData.currentPage || !vm.attachmentData.currentPage.last)) {
        vm.loading = true;

        var nextPage = (vm.attachmentData.currentPage ? vm.attachmentData.currentPage.number + 1 : 0);

        MessageAttachmentModel.pagedQuery(new Pageable(nextPage, 10, 'created,desc'), {includeVoiceMessages: false}, {
          channelId: vm.channel.id
        }).then(function (page) {
          vm.attachmentData.currentPage = page;
          vm.attachmentData.attachments.push.apply(vm.attachmentData.attachments, page.content);
          _.forEach(vm.attachmentData.attachments, function (attachment) {
            attachment.isExternalFile = externalFileHandlerService.isExternalFile(attachment);
            if (attachment.isExternalFile && !attachment.metaData) {
              _handleExternalFiles(attachment);
            }
          });
        }).finally(function () {
          vm.loading = false;
        });
      }
    }

    function deleteMember(userId) {
      new MessageChannelMemberModel({
        channelId: vm.channel.id,
        id: userId
      }).delete();
    }

    function toggleMemberRole(member) {
      new MessageChannelMemberModel({
        channelId: vm.channel.id,
        id: member.user.id,
        role: member.role === 'MEMBER' ? 'ADMIN' : 'MEMBER'
      }).update();
    }

    function onInit() {
      vm.memberLimit = 10;
      vm.fileErrors = {};
      vm.attachmentData = {
        attachments: [],
        currentPage: null
      };
      vm.backendUrl = backendUrlService.getUrl();

      loadMoreAttachments();
    }

    function _handleExternalFiles(attachment) {
      attachment.externalFileMetaDataLoading = true;
      externalFileHandlerService.getExternalFileDetails(attachment)
          .then(function (fileDetails) {
            attachment.metaData = fileDetails;
          })
          .catch(function (e) {
            if (e.status === 404) {
              vm.fileErrors[attachment.id] = 'FILE_DETAILS.EXTERNAL_FILE_NOT_AVAILABLE';
            }
          })
          .finally(function () {
            delete attachment.externalFileMetaDataLoading;
            $scope.$digest();
          });
    }
  }

})(angular);

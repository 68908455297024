import {Inject, Injectable, NgZone} from '@angular/core';
import {Ng1ModalOptions, Ng1ModalService} from '@root/typings';
import {attachToZone} from '@upgrade/attach-to-zone';
import {NG1_MODAL_SERVICE} from '@upgrade/upgrade.module';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Service to open a modal.
 */
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private ngZone: NgZone,
              @Inject(NG1_MODAL_SERVICE) private modalService: Ng1ModalService) {
  }

  /**
   * Open a modal.
   *
   * @param options The modal options.
   * @return the modal result.
   */
  open(options: Ng1ModalOptions): Observable<void> {
    const modalInstance = this.modalService.open(options) as any;
    return attachToZone(this.ngZone, from(modalInstance.result)).pipe(map(() => {}));
  }

  /**
   * Open a confirm modal.
   *
   * @param options The modal options.
   * @return the modal result.
   */
  openConfirm(options: Ng1ModalOptions): Observable<any> {
    const modalInstance = this.modalService.confirm(options) as any;
    return attachToZone(this.ngZone, from(modalInstance.result)).pipe(map(() => {}));
  }

  /**
   * Open a confirm delete modal.
   *
   * @param options The modal options.
   * @return the modal result.
   */
  confirmDelete(options: Ng1ModalOptions): Observable<void> {
    const modalInstance = this.modalService.confirmDelete(options) as any;
    return attachToZone(this.ngZone, from(modalInstance.result)).pipe(map(() => {}));
  }
}

import {Injectable} from '@angular/core';

export const MILLISECONDS_TO_HOURS = 60000;

@Injectable({
  providedIn: 'root'
})
export class TimeProviderService {

  constructor() { }

  /**
   * Returns the current date
   *
   * @returns Date
   */
  getCurrentDate(): Date {
    return new Date();
  }

  /**
   * Returns a date with removed timezone offset
   *
   * @param date The date string for which the timezone offset should be removed (eg. '1987-03-17')
   * @returns Date with removed timezone offset
   */
  createDateWithoutTimezoneOffset(date: string): Date {
    const dateReg = /^\d{4}-\d{2}-\d{2}$/;
    if (dateReg.test(date)) {
      const offset = new Date(date).getTimezoneOffset() * MILLISECONDS_TO_HOURS;
      const dateWithoutOffset = new Date(Date.parse(date) + offset);
      return new Date(dateWithoutOffset.setHours(0, 0, 0));
    }
    return null;
  }
}

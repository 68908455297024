import {EngageWidgetLayoutComponent} from '@app/engage/engage-widget-layout/engage-widget-layout/engage-widget-layout.component';
import {STATE_NAME_ENGAGE} from '@app/engage/engage.state';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';
import {Transition} from '@uirouter/core';

// Do not change the state names
export const STATE_NAME_ENGAGE_WIDGET_LAYOUT = STATE_NAME_ENGAGE + '.widget-layout';

/**
 * State for engage webview showing a widget layout.
 *
 * Reachable under url '/engage/widget-layout/parentTypeName/parentId/layoutName?language&renderStyle'
 *
 * Query parameters language and renderStyle can be omitted
 */
export const engageWidgetLayoutState: NgHybridStateDeclaration = {
  name: STATE_NAME_ENGAGE_WIDGET_LAYOUT,
  url: '/widget-layout/:parentType/:parentId/:layoutName?language&renderStyle',
  component: EngageWidgetLayoutComponent,
  data: {
    authenticate: true
  },
  params: {
    parentType: {type: 'path'},
    parentId: {type: 'path'},
    layoutName: {type: 'path'},
    language: {type: 'query'},
    renderStyle: {type: 'query'}
  },
  loadChildren: () => import('./engage-widget-layout.module').then(mod => mod.EngageWidgetLayoutModule),
  resolve: [{
    token: 'parent',
    deps: [Transition],
    resolveFn: (transition: Transition) => {
      const params = transition.params();
      return {id: params.partentId, typeName: params.parentType};
    }
  }, {
    token: 'name',
    deps: [Transition],
    resolveFn: (transition: Transition) => transition.params().layoutName
  }, {
    token: 'language',
    deps: [Transition],
    resolveFn: (transition: Transition) => transition.params().language
  }, {
    token: 'renderStyle',
    deps: [Transition],
    resolveFn: (transition: Transition) => transition.params().renderStyle ?? 'plain'
  }]
};

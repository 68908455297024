(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .controller('MessageTimestampController', MessageTimestampController);

  function MessageTimestampController() {
    var vm = this;
    var day = 86400000; // 24 * 60 * 60 * 1000
    var week = day * 7;

    vm.isCurrentDay = isCurrentDay;
    vm.isYesterday = isYesterday;
    vm.isWithinAWeek = isWithinAWeek;
    vm.isAWeekAgo = isAWeekAgo;

    function isCurrentDay(date) {
      var inputDate = _resetTime(_copyDate(date));
      var currentDate = _resetTime(new Date());
      return inputDate === currentDate;
    }

    function isYesterday(date) {
      var inputDate = _resetTime(_copyDate(date));
      var yesterdayDate = _resetTime(new Date() - day);
      return inputDate === yesterdayDate;
    }

    function isWithinAWeek(date) {
      var inputDate = _copyDate(date);
      var yesterday = new Date() - day;
      var yesterdayDate = _resetTime(yesterday);
      var lastDayOfThisWeek = new Date(new Date() - week);
      return inputDate < yesterdayDate && inputDate >= lastDayOfThisWeek;
    }

    function isAWeekAgo(date) {
      var inputDate = _copyDate(date);
      var lastDayOfThisWeek = new Date(new Date() - week);
      return inputDate < lastDayOfThisWeek;
    }

    function _copyDate(date) {
      return new Date().setTime(date);
    }

    function _resetTime(date) {
      return new Date(date).setHours(0, 0, 0, 0);
    }

  }

})(angular);

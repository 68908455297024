import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

export type SpinnerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

/**
 * Shows a spinner.
 *
 * @deprecated Use ui-spinner from pattern library.
 */
@Component({
  selector: 'coyo-spinner',
  templateUrl: './spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {

  /**
   * The size of the spinner. Default is 'md'.
   *
   */
  @Input() size: SpinnerSize = 'md';

  /**
   * Flag if the spinners colors should be inverted to use it on dark backgrounds.
   */
  @Input() inverted: boolean = false;

  constructor() {}
}

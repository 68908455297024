<section class="reports-list coyo-new" *ngIf="(state$ | async) as state">
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-main">
      <h1 class="panel-title" translate="MODULE.REPORT.REPORTS"></h1>
    </div>
    <div class="panel-body">
      <coyo-report-item *ngFor="let report of state.reports" [report]="report" (resolve)="resolve($event)"></coyo-report-item>

      <div class="text-center" *ngIf="!state.isLoading && state.count < state.total">
        <button class="btn btn-sm btn-primary" (click)="loadMore()" translate="MORE"></button>
      </div>

      <!-- spinner / loading -->
      <coyo-spinner class="text-center" *ngIf="state.isLoading"></coyo-spinner>

      <!-- empty -->
      <p class="text-center text-muted" *ngIf="state.isLoading || !state.reports.length" translate="MODULE.REPORT.EMPTY"></p>
    </div>
  </div>
</section>

(function (angular) {
  'use strict';

  RegistrationModel.$inject = ["restResourceFactory", "coyoEndpoints", "$http"];
  angular
      .module('coyo.domain')
      .factory('RegistrationModel', RegistrationModel);

  /**
   * @ngdoc service
   * @name coyo.domain.RegistrationModel
   *
   * @description
   * Provides the registration model for external users.
   *
   * @requires $httpParamSerializer
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   * @requires $http
   */
  function RegistrationModel(restResourceFactory, coyoEndpoints, $http) {
    var RegistrationModel = restResourceFactory({
      url: coyoEndpoints.register.externalUser
    });

    // class members
    angular.extend(RegistrationModel, {

      /**
       * @ngdoc function
       * @name coyo.domain.RegistrationModel#get
       * @methodOf coyo.domain.RegistrationModel
       *
       * @description
       * Get the external user registration data, that has to be completed by the user for registration, by the token
       * that was sent to the user via email.
       *
       * @param {string} token The registration token from the invitation email.
       *
       * @returns {promise} An $http promise
       */
      get: function (token) {
        var params = {token: token};
        return $http.get(this.$url(), {
          params: params,
          autoHandleErrors: false
        }).then(function (response) {
          return response.data;
        });
      }
    });

    return RegistrationModel;
  }

})(angular);

import {ChangeDetectorRef, Inject, NgZone, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {WINDOW} from '@root/injection-tokens';
import * as moment from 'moment-timezone';
import {TimeService} from '../time/time.service';

/**
 * A custom timeago pipe to display the time difference between a given date and now.
 */
@Pipe({name: 'timeAgo', pure: false})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private lastDate: moment.Moment | null = null;
  private lastShort: boolean = false;
  private lastReference: moment.Moment | null = null;
  private text: string;
  private timer: number | null;
  private update: number | null;

  constructor(@Inject(WINDOW) private window: Window,
              private cdRef: ChangeDetectorRef,
              private ngZone: NgZone,
              private timeService: TimeService,
              private translateService: TranslateService) {
  }

  /**
   * Returns a textual representation of the time difference between a given date and now.
   *
   * @param date the date to show the difference for
   * @param short flag to render a short date
   * @param reference the reference date (defaults to `moment()`)
   * @return the difference as text
   */
  transform(date: moment.MomentInput, short: boolean = false, reference?: moment.MomentInput): string {
    if (this.hasChanged(date, short, reference)) {
      this.lastDate = date ? moment(date) : null;
      this.lastShort = short;
      this.lastReference = reference ? moment(reference) : null;
      this.process();
      this.removeTimer();
      this.createTimer();
    } else {
      this.createTimer();
    }
    return this.text;
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private hasChanged(date: moment.MomentInput, short: boolean, reference?: moment.MomentInput): boolean {
    return (this.lastDate ? !this.lastDate.isSame(date) : !!date)
      || (this.lastShort !== short)
      || (this.lastReference ? !this.lastReference.isSame(reference) : !!reference);
  }

  private process(): void {
    const ref = this.lastReference || moment();
    const diff = Math.ceil(ref.diff(this.lastDate, 'second', true));
    if (this.lastDate === null) {
      this.update = null;
      this.text = '';
    } else if (diff < 120) {
      this.update = 120 - diff;
      this.text = this.translate('TIME_AGO.NOW', {
        minutes: Math.floor(diff / 60)
      });
    } else if (diff < 3600) {
      this.update = (3600 - diff) % 60;
      this.update += !this.update ? 60 : 0;
      this.text = this.translate('TIME_AGO.MINUTES', {
        minutes: Math.floor(diff / 60)
      });
    } else if (ref.isSame(this.lastDate, 'day')) {
      this.update = Math.ceil(this.lastDate.clone().add(1, 'day').startOf('day').diff(this.lastDate, 'second', true));
      this.text = this.translate('TIME_AGO.TODAY', {
        date: this.format(this.translate('TIME_AGO.DATE', {sameYear: ref.isSame(this.lastDate, 'year')})),
        time: this.format(this.translate('TIME_AGO.TIME')),
      });
    } else if (ref.clone().subtract(1, 'day').isSame(this.lastDate, 'day')) {
      this.update = Math.ceil(this.lastDate.clone().add(2, 'day').startOf('day').diff(this.lastDate, 'second', true));
      this.text = this.translate('TIME_AGO.YESTERDAY', {
        date: this.format(this.translate('TIME_AGO.DATE', {sameYear: ref.isSame(this.lastDate, 'year')})),
        time: this.format(this.translate('TIME_AGO.TIME')),
      });
    } else {
      this.update = null;
      this.text = this.translate('TIME_AGO.EXACT', {
        date: this.format(this.translate('TIME_AGO.DATE', {sameYear: ref.isSame(this.lastDate, 'year')})),
        time: this.format(this.translate('TIME_AGO.TIME')),
      });
    }
  }

  private translate(key: string | string[], interpolateParams?: object): string | any {
    return this.translateService.instant(`${key}${this.lastShort ? '.SHORT' : ''}`, interpolateParams);
  }

  private format(format: string): string {
    const timezone = this.timeService.getTimezone();
    return this.lastDate.clone().tz(timezone).format(format);
  }

  private createTimer(): void {
    if (this.timer || !this.update) {
      return;
    }

    this.timer = this.ngZone.runOutsideAngular(() => this.window.setTimeout(() => {
      this.process();
      this.timer = null;
      this.ngZone.run(() => this.cdRef.markForCheck());
    }, this.update * 1000));
  }

  private removeTimer(): void {
    if (this.timer) {
      this.window.clearTimeout(this.timer);
      this.timer = null;
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {SenderEvent} from '@domain/event/sender-event';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

/**
 * Transforms an event to its correct event time.
 */
@Pipe({
  name: 'eventTime'
})
export class EventTimePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(event: SenderEvent): string {
    const format = this.translateService.instant('TIME_FORMAT_SHORT');

    const start = moment(event.startDate);
    const end = moment(event.endDate);
    const sameDay = start.isSame(end, 'day');

    const startStr = start.format(format);
    const endStr = end.format(format);
    return startStr === endStr && sameDay ? startStr : `${startStr} – ${endStr}`;
  }
}

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Page} from '@domain/page/page';
import {PageService} from '@domain/page/page.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {FindOptions, SelectSenderOptions} from '@shared/sender-ui/select-sender/select-sender-options';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {SuggestPagesWidget} from '@widgets/suggest-pages/suggest-pages-widget';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';

/**
 * The suggest-pages widget settings component.
 */
@Component({
  selector: 'coyo-suggest-pages-widget-settings',
  templateUrl: './suggest-pages-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestPagesWidgetSettingsComponent extends WidgetSettingsComponent<SuggestPagesWidget>
  implements OnInit {

  readonly selectOptions: SelectSenderOptions = {senderTypes: ['page'], findOptions: FindOptions.ALL};

  constructor(private pageService: PageService) {
    super();

  }

  ngOnInit(): void {
    this.parentForm.addControl('pages', new FormControl([], CoyoValidators.notBlank));
    const pageIds: string[] = _.get(this.widget.settings, '_pageIds', []);
    this.pageService.getBulk(pageIds).subscribe(pages => {
      this.parentForm.patchValue({pages});
    });

  }

  onBeforeSave(settings?: any): Observable<any> {
    return of({
      _pageIds: settings['pages'].map((page: Page) => page.id)
    });
  }
}

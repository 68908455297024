<ng-container *ngIf="file$ | async as file">
  <dl class="coyo-file-details dl-horizontal">
    <dt *ngIf="file.locked" translate="FILE_DETAILS.STATUS"></dt>
    <dd *ngIf="file.locked">
      <div class="status pl-badges">
        <div class="badge badge-warning">
          {{'FILE_LIBRARY.FILE_LOCKED' | translate}}
        </div>
        <div class="lock-holder-information">
          <coyo-sender-link *ngIf="file.lockHolder" [sender]="file.lockHolder"></coyo-sender-link>
          <span class="lock-time">{{file.lockDate | timeAgo}}</span>
        </div>
      </div>
    </dd>
    <dt translate="FILE_DETAILS.FILESIZE"></dt>
    <dd>{{file.length | fileSize}}</dd>
    <dt translate="FILE_DETAILS.FILETYPE"></dt>
    <dd>{{file.contentType}}</dd>
    <dt translate="FILE_DETAILS.FILE_ORIGIN"></dt>
    <dd><coyo-sender-link ng-if="$ctrl.sender" href [sender]="sender"></coyo-sender-link></dd>
    <ng-container *ngIf="author$ | async as author">
      <dt translate="FILE_DETAILS.AUTHOR"></dt>
      <dd>
        <coyo-sender-link [sender]="author"></coyo-sender-link>
      </dd>
    </ng-container>
    <dt translate="FILE_DETAILS.FILE_MODIFIED"></dt>
    <dd>{{file.modified | date:'medium'}}</dd>
    <ng-content></ng-content>
  </dl>
</ng-container>

import {NgModule} from '@angular/core';
import {Ng1AppSettingsComponent} from '@apps/api/ng1-app-settings/ng1-app-settings.component';
import {ContentAppModule} from '@apps/content/content-app.module';
import {DocumentAppModule} from '@apps/document/document-app.module';
import {TimelineAppModule} from '@apps/timeline/timeline-app.module';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {MultilanguageModule} from '@shared/multilanguage/multilanguage.module';
import {PrintModule} from '@shared/print/print.module';
import {AppChooserComponent} from './api/app-chooser/app-chooser.component';
import './api/app-chooser/app-chooser.service.downgrade';
import './api/app-registry.service.downgrade';
import {AppSettingsModalComponent} from './api/app-settings-modal/app-settings-modal.component';
import './api/app-settings-modal/app-settings-modal.service.downgrade';
import {AppSettingsComponent} from './api/app-settings/app-settings.component';
import {IndividualAppSettingsContainerComponent} from './api/individual-app-settings-container/individual-app-settings-container.component';

/**
 * Module for Apps
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    MultilanguageModule,
    TimelineAppModule,
    ContentAppModule,
    HelpModule,
    DocumentAppModule,
    PrintModule
  ],
  declarations: [AppChooserComponent, Ng1AppSettingsComponent, AppSettingsComponent, AppSettingsModalComponent, IndividualAppSettingsContainerComponent],
})

export class AppsModule {
}

(function (angular) {
  'use strict';

  fileLibraryModalService.$inject = ["modalService", "$injector"];
  angular
      .module('commons.ui')
      .factory('fileLibraryModalService', fileLibraryModalService);

  /**
   * @ngdoc service
   * @name commons.ui.fileLibraryModalService
   *
   * @description
   * Service for showing the file library within a modal.
   *
   * @requires modalService
   * @requires $uibModalInstance
   */
  function fileLibraryModalService(modalService, $injector) {
    return {
      open: open
    };

    /**
     * @ngdoc method
     * @name commons.ui.fileLibraryModalService#open
     * @methodOf commons.ui.fileLibraryModalService
     *
     * @description
     * Opens the file library in a modal and passes the various options to the file library.
     *
     * @param {object=} sender
     * The sender for the file library. If provided the file library is opened with the given sender being the active
     * view.
     *
     * @param {object=} options
     * File library options. See {@link commons.ui:fileLibrary fileLibrary}
     *
     * @param {object=} cropSettings
     * Image crop settings. See {@link commons.ui:fileLibrary fileLibrary}
     *
     * @returns {promise} An http promise
     */
    function open(sender, options, cropSettings) {
      options.selectionType = options.selectMode;
      options.cropSettings = cropSettings;
      return $injector.get('ngxFileLibraryFilePickerService').openLegacy(sender, options).then(function (selection) {
        return options.selectionType === 'single' ? _.get(selection, '[0]') : selection;
      });
    }
  }
})(angular);

<div class="filepicker-modal">
  <h3 mat-dialog-title>{{'FILE_LIBRARY.TITLE' | translate}}</h3>
  <mat-dialog-close [attr.aria-label]="'CLOSE' | translate" [mat-dialog-close]="[]"></mat-dialog-close>
  <div class="filepicker-header">
    <coyo-file-picker-breadcrumb [filePickerId]="filePicker.id"></coyo-file-picker-breadcrumb>
    <coyo-file-picker-search (searchResult)="filePicker.openInRoot($event)"
                             [search]="data.search"></coyo-file-picker-search>
    <ng-container *ngIf="filePicker.currentLocation$ | async as currentLocation">
      <coyo-create-folder-button
        [filePickerId]="filePicker.id"
        [locationId]="currentLocation.item.id">
      </coyo-create-folder-button>
      <coyo-upload-button
        [filePickerId]="filePicker.id"
        [locationId]="currentLocation.item.id">
      </coyo-upload-button>
    </ng-container>
  </div>
  <div mat-dialog-content>
    <div class="mat-full-width">
      <coyo-file-picker #filePicker [data]="data"></coyo-file-picker>
    </div>
  </div>
  <div *ngIf="data?.selectionMode !== 'download'" mat-dialog-actions>
    <button mat-button mat-dialog-close type="button">{{'CANCEL' | translate}}</button>
    <button (click)="onSubmitSelectedFiles()"
            [disabled]="!(canSubmit$ | async)"
            color="primary"
            mat-flat-button
            type="submit">
        <span *ngIf="isMultiSelect$ | async; else single">
          {{'FILEPICKER.BUTTON.SELECT' | translate : (count$ | async) }}
        </span>
      <ng-template #single>
        {{'SELECT' | translate }}
      </ng-template>
    </button>
  </div>
</div>

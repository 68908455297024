import {App} from '@apps/api/app';
import {AppConfig} from '@apps/api/app-config';
import {TimelineAppSettingsComponent} from '@apps/timeline/settings/timeline-app-settings.component';
import {TimelineAppComponent} from '@apps/timeline/timeline-app.component';
import {TimelineAppSettings} from '@apps/timeline/timeline-app.settings';
import {SenderService} from '@domain/sender/sender/sender.service';

export const TIMELINE_CONFIG: AppConfig<TimelineAppSettings> = {
  name: 'APP.TIMELINE.NAME',
  description: 'APP.TIMELINE.DESCRIPTION',
  key: 'timeline',
  icon: 'zmdi-comment-list',
  svgIcon: 'timeline-app',
  allowedInstances: 1,
  allowedInstancesErrorMessage: 'APP.TIMELINE.ALLOWEDINSTANCES.ERROR',
  states: [
    {
      component: TimelineAppComponent,
      resolve: [{
        token: 'sender',
        deps: [SenderService, 'app'],
        resolveFn: (senderService: SenderService, app: App<TimelineAppSettings>) =>
          senderService.get(app.senderId, {
            params: {
              with: 'subscriptionInfo'
            }
          }).toPromise()
      }]
    }
  ],
  settings: {
    component: TimelineAppSettingsComponent,
    skipOnCreate: false
  }
};

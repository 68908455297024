<div class="event-participation-menu"
     [ngClass]="{'ongoing': isOngoing}"
     coyoTourStep="participation"
     coyoTourTopic="events"
     [coyoTourIf]="!isMenuDisabled()"
     [coyoTourOrder]="640"
     [coyoTourTitle]="'TOUR.EVENTS.PARTICIPATION.TITLE' | translate"
     [coyoTourText]="'TOUR.EVENTS.PARTICIPATION.TEXT' | translate"
     coyoTourPlacement="left-start">
  <div *ngIf="!isFullyBooked()">
    <button mat-flat-button size="small" [matMenuTriggerFor]="eventParticipationMenu" [disabled]="isMenuDisabled()">
      <mat-icon *ngIf="selectedOption.status !== 'PENDING'" [svgIcon]="selectedOption.icon"></mat-icon>
      <span *ngIf="!(isXs$ | async) || selectedOption.status === 'PENDING'">{{buildStatusTranslationKey(selectedOption) | translate}}</span>
      <mat-icon *ngIf="!isMenuDisabled()" svgIcon="chevron-down" class="chevron"></mat-icon>
    </button>
    <mat-menu #eventParticipationMenu="matMenu">
      <button *ngFor="let option of options"
              class="event-visibility-button"
              mat-menu-item
              [ngClass]="{'active' : isCurrentStatus(option)}"
              [value]="option"
              [disabled]="!isParticipantStatusSelectable(option)"
              (click)="participantStatusWasChanged(option)">
        <mat-icon [ngClass]="{'active' : isCurrentStatus(option)}" [svgIcon]="option.icon"></mat-icon>&nbsp;
        <span>{{buildStatusTranslationKey(option) | translate}}</span>
      </button>
    </mat-menu>
  </div>
  <div *ngIf="isFullyBooked()">
    <button class="fully-booked" mat-flat-button size="small" [disabled]="true">
      <mat-icon size="small" svgIcon="stop"></mat-icon>
      <span *ngIf="!(isXs$ | async)"> {{this.event.limitedParticipants.participantsLimit}}/‍{{this.event.limitedParticipants.participantsLimit}}</span>
    </button>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter, forwardRef,
  HostListener,
  Input, OnChanges,
  OnInit,
  Output, Provider, SimpleChange, SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslationService} from '@core/i18n/translation-service/translation.service';

const moment = require('moment');

const timePickerValueProvider: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimePickerComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

/**
 * The time picker component.
 */
@Component({
  selector: 'coyo-time-picker',
  templateUrl: './time-picker.component.html',
  providers: [timePickerValueProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerComponent implements OnInit, OnChanges, ControlValueAccessor {

  /**
   * The initial date.
   */
  @Input() time?: Date;

  /**
   * The selected date.
   */
  @Output() timeChange: EventEmitter<Date> = new EventEmitter<Date>();

  /**
   * Element reference of the input field.
   */
  @ViewChild('timePickerInput', {
    static: true
  }) input: ElementRef;

  /**
   * Element reference of the popover.
   */
  @ViewChild('timePickerPopover', {
    static: true
  }) popover: ElementRef;

  currentDateTime: Date;
  currentDateTimeString: string;
  isMeridian: boolean = true;
  showPopover: boolean = false;
  disabled: boolean = false;
  private languageKey: string = 'en';
  private onChangeFn: (date: Date) => void;
  private validTimeFormat: RegExp = /^(\d\d:\d\d(\s[aApP][mM])?)$/;

  constructor(private translationService: TranslationService) { }

  ngOnInit(): void {
    this.languageKey = this.translationService.getActiveLanguage() || 'en';
    this.isMeridian = this.languageKey === 'en';
    this.currentDateTime = this.time ? this.time : new Date();
    this.currentDateTimeString = this.convertToTimeString(this.currentDateTime);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const time: SimpleChange = changes.time;
    if (!time.firstChange && time.previousValue !== time.currentValue) {
      this.currentDateTime = time.currentValue;
      this.currentDateTimeString = this.convertToTimeString(this.currentDateTime);
    }
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: (date: Date) => void): void {
    this.onChangeFn = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: (date: Date) => void): void {
  }

  /* tslint:disable-next-line:completed-docs */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(value: Date): void {
    this.time = value;
  }

  /**
   * Listener for escape keydown event to close the time picker.
   */
  @HostListener('document:keydown.esc')
  closeOnEsc(): void {
    this.showPopover = false;
  }

  /**
   * Listener for outside click event to close the time picker.
   * @param event The mouse event.
   */
  @HostListener('document:click', ['$event'])
  closeOnOutsideClick(event: MouseEvent): void {
    if (!this.input.nativeElement.contains(event.target)) {
      this.showPopover = false;
    }
  }

  /**
   * Will be called on new time input.
   * @param time The new time as a time string
   */
  onInputChange(time: string): void {
    if (this.isValidTimeString(time as string)) {
      const parsedDate = this.parseDateFromTimeString(time as string);
      if (parsedDate && moment(parsedDate).isValid()) {
        this.currentDateTime = parsedDate;
        if (typeof this.onChangeFn === 'function') {
          this.onChangeFn(parsedDate);
        }
        this.timeChange.emit(parsedDate);
      }
    }
  }

  /**
   * Will be called on new time selection via time picker.
   * @param date The new date object.
   */
  onPickerChange(date: Date): void {
    if (date && moment(date).isValid()) {
      this.currentDateTimeString = this.convertToTimeString(date);
      if (typeof this.onChangeFn === 'function') {
        this.onChangeFn(date);
      }
      this.timeChange.emit(date);
    }
  }

  /**
   * Resets the input value.
   */
  resetInputValue(): void {
    this.input.nativeElement.value = this.currentDateTimeString;
  }

  private isValidTimeString(time: string): boolean {
    return typeof time === 'string'
      && this.validTimeFormat.test(time.trim());
  }

  private parseDateFromTimeString(time: string): Date {
    const newTime = moment(time, this.isMeridian ? 'hh:mm a' : 'HH:mm');
    const initial = moment(this.currentDateTime);
    newTime.year(initial.year()).month(initial.month()).day(initial.day());
    return newTime.toDate();
  }

  private convertToTimeString(date: Date): string {
    return moment(date).format(this.isMeridian ? 'hh:mm a' : 'HH:mm');
  }

}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import {UrlService} from '@core/http/url/url.service';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Ng1ImageModalService} from '@root/typings';
import {NG1_IMAGE_MODAL_SERVICE} from '@upgrade/upgrade.module';

export type AvatarSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

/**
 * Component rendering an avatar image of the given url in the given size. The text alternative is shown as tooltip.
 */
@Component({
  selector: 'coyo-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarImageComponent implements OnChanges {

  /**
   * The relative url of the image.
   */
  @Input() imageUrl: string;

  /**
   * The text representation of the image for accessibility.
   */
  @Input() textAlternative: string;

  /**
   * The size of the avatar.
   *
   * @default 'md'
   */
  @Input() avatarSize?: AvatarSize = 'md';

  /**
   * Opens a modal with a larger size of the image
   *
   * @default false
   */
  @Input() allowModal: boolean;

  /**
   * The background color of the avatar if the image background is transparent.
   */
  @Input() avatarBackground?: string;

  /**
   * Error event when the image could not be loaded.
   */
  @Output() error: EventEmitter<void> = new EventEmitter<void>();

  imageSrc: string = '';

  imageSrcXL: string = '';

  constructor(
    @Inject(NG1_IMAGE_MODAL_SERVICE) private imageModalService: Ng1ImageModalService,
    @Inject(UrlService) private urlService: UrlService,
    @Inject(WindowSizeService) private windowSizeService: WindowSizeService) {
  }

  private getImageSrc(url: string, size?: string): string {
    if (url) {
      let baseUrl = this.urlService.join(this.urlService.getBackendUrl(), url);
      if (size) {
        baseUrl += (baseUrl.indexOf('?') < 0 ? '?' : '&') + 'imageSize=' + size;
      }
      return baseUrl;
    }
    return '';
  }

  private getImageSize(): string {
    const retina = this.windowSizeService.isRetina();
    if (this.avatarSize === 'lg' || this.avatarSize === 'xl') {
      return retina ? 'L' : 'M';
    } else if (this.avatarSize === 'xs' || this.avatarSize === 'sm') {
      return retina ? 'S' : 'XS';
    } else {
      return retina ? 'M' : 'S';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.imageUrl) {
      this.imageSrc = this.getImageSrc(this.imageUrl, this.getImageSize());
      this.imageSrcXL = this.getImageSrc(this.imageUrl, 'XL');
    } else {
      this.imageSrc = '';
    }
  }

  /**
   * Check if the modal could be shown
   *
   * @returns true if it could be shwon
   */
  canShowModal(): boolean {
    return this.allowModal && this.imageSrcXL !== '';
  }

  /**
   * Opens the modal with the image in size XL
   */
  showModal(): void {
    if (this.canShowModal()) {
      this.imageModalService.open(this.imageSrcXL, this.textAlternative);
    }
  }
}

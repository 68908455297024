import {Injectable} from '@angular/core';
import Swiper, {SelectableElement, SwiperOptions} from 'swiper';

@Injectable({
  providedIn: 'root'
})
export class SwiperLoaderService {

  constructor() {
  }

  /**
   * This method should originally lazy load the swiper library with import() syntax, but according to
   * https://github.com/nolimits4web/swiper/issues/2282 the swiper library is not es5 compatible and when importing it
   * is not going to be transpiled. Will not work in IE11 that way. Therefore we eagerly import it in angular.json for
   * the time being.
   * @param container A container DOM element
   * @param options the swiper options
   * @returns A swiper instance
   */
  createInstance(container: SelectableElement, options?: SwiperOptions): Swiper {
    // @ts-ignore
    return new window.Swiper(container,  options);
  }
}

import {Component, HostBinding, Input} from '@angular/core';
import {Widget} from '@domain/widget/widget';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {SlotId} from '@widgets/api/widget-state.model';

/**
 * A component that renders the inline settings form of a specific widget.
 */
@Component({
  template: ''
})
export abstract class WidgetInlineSettingsComponent<WidgetType extends Widget<WidgetSettings>> {

  /**
   * The corresponding widget this settings component belongs to.
   */
  @Input() widget: WidgetType;

  /**
   * The edit scope of the widget. This is required for firing state actions in the widget components.
   */
  @Input() editScope: string;

  /**
   * The slot name of the widget. This is required for firing state actions in the widget components.
   */
  @Input() slot: SlotId;

  @HostBinding('class') hostClasses: string = 'btn-group';
}

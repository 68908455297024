<ng-container *ngIf="activeSortingOption">
  <button *ngIf="!buttonOnly" mat-button size="small" [matMenuTriggerFor]="searchFilterMenu" [disabled]="disabled">
    <mat-icon size="small" svgIcon="sorting"></mat-icon>
    <span *ngIf="!buttonOnly">
      {{"MODULE.SEARCH.SORTING.SORTING_MENU" | translate}}: {{
        "MODULE.SEARCH.SORTING.SORTING_OPTION." + activeSortingOption.key | translate
      }}
    </span>
  </button>
  <button *ngIf="buttonOnly" mat-icon-button size="small" [matMenuTriggerFor]="searchFilterMenu" [disabled]="disabled"
    [attr.aria-label]="('MODULE.SEARCH.SORTING.SORTING_MENU' | translate) + ': ' + ('MODULE.SEARCH.SORTING.SORTING_OPTION.' + activeSortingOption.key | translate)">
    <mat-icon size="small" svgIcon="sorting"></mat-icon>
  </button>

  <mat-menu #searchFilterMenu xPosition="before">
    <span mat-menu-title>{{"MODULE.SEARCH.SORTING.SORTING_MENU" | translate}}</span>
    <button *ngFor="let option of sortingOptions" (click)="emitSortingChange(option)" mat-menu-item>
      <mat-icon [color]="activeSortingOption === option ? 'accent' : null" [svgIcon]="option.icon"></mat-icon>
      <span>{{"MODULE.SEARCH.SORTING.SORTING_OPTION." + option.key | translate}}</span>
    </button>
  </mat-menu>
</ng-container>

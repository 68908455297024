import {BirthdayWidgetSettings} from '@widgets/birthday/birthday-widget-settings.model';

export class Init {
  static readonly type: string = '[BirthdayWidget] Init';
  constructor(public settings: BirthdayWidgetSettings, public id: string, public edit: boolean = false) { }
}

export class LoadMore {
  static readonly type: string = '[BirthdayWidget] Load more';
  constructor(public settings: BirthdayWidgetSettings, public id: string, public edit: boolean = false) { }
}

export class SetLoading {
  static readonly type: string = '[BirthdayWidget] Set loading';
  constructor(public loading: boolean, public id: string) { }
}

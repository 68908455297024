<ng-select [items]="senders$ | async" class="functional-user-chooser coyo-new" [searchable]="false" [clearable]="false"
           [(ngModel)]="author" (open)="onOpen()" (scroll)="onScroll($event)" [virtualScroll]="true"
           (change)="selectSender($event)"
           [attr.aria-label]="headline | translate" role="combobox"
           data-test="functional-user-chooser">
  <ng-template ng-header-tmp>
    <span [translate]="headline"></span>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <coyo-sender-avatar [sender]="item" [noLink]="true" avatarSize="sm"></coyo-sender-avatar>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <coyo-sender-list-item [sender]="item" [noLink]="true" [subline]="item.subline" role="option"></coyo-sender-list-item>
  </ng-template>
</ng-select>

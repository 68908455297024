import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendInterceptor} from '@core/http/backend-interceptor/backend-interceptor';
import {ServiceRecognitionService} from '@core/http/service-recognition/service-recognition.service';
import {UrlService} from '@core/http/url/url.service';
import {TokenService} from '@core/token/token.service';
import {Observable, throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';

/**
 * Intercepts all requests that going to one of the microservices and adds the jwt token as authentication there.
 */
@Injectable()
export class ServiceInterceptor extends BackendInterceptor {

  static readonly FORCE_FLAG: string = 'forceCall';

  constructor(urlService: UrlService, private serviceRecognitionService: ServiceRecognitionService,
              private tokenService: TokenService) {
    super(urlService);
  }

  interceptBackendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const targetService = this.serviceRecognitionService.getTargetService(req.url);
    if (!targetService) {
      return next.handle(req);
    }
    return this.serviceRecognitionService.isServiceEnabled(targetService).pipe(switchMap(active => {
      if (active || req.headers.get(ServiceInterceptor.FORCE_FLAG)) {
        return this.tokenService.getToken().pipe(switchMap(token =>
          next.handle(req.clone({headers: req.headers.set('x-coyo-token', token)}))
        ));
      } else {
        return throwError(new HttpErrorResponse({
          status: 504,
          url: req.url,
          headers: req.headers,
          statusText: 'Service is disabled in this environment'
        }));
      }
    }));
  }
}

<form [formGroup]="form" class="row">
  <div class="col-md-4" [class.mat-error]="form.controls[fieldName].touched && form.controls[fieldName].invalid">
    <label class="mat-form-field-label" (click)="name.focus()">{{'SETTINGS.ACCOUNT.' + fieldName | uppercase | translate}}</label>
  </div>
  <div class="col-md-8">
    <mat-form-field>
      <input matInput [formControlName]="fieldName" [maxlength]="MAX_LENGTH" (blur)="submit()" (keydown.enter)="submit()" #name>
      <coyo-save-indicator matSuffix [request]="request$"></coyo-save-indicator>
      <mat-error *coyoFieldErrors="form.controls[fieldName].errors"></mat-error>
      <mat-hint *ngIf="form.controls[fieldName].disabled" [innerHTML]="'SETTINGS.ACCOUNT.' + (fieldName | uppercase) + '.HINT' | translate | markdown"></mat-hint>
    </mat-form-field>
  </div>
</form>

import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

/**
 * A pipe to translate plugin data IDs, e.g. "userId" or "userLanguage".
 */
@Pipe({
  name: 'pluginData'
})
export class PluginDataPipe implements PipeTransform {
  private static readonly PREFIX: string = 'ADMIN.APPS_WIDGETS.PLUGINS.DATA';

  constructor(private translateService: TranslateService) {
  }

  transform(data: string[]): string {
    return data.map(key => {
      const i18nKey = `${PluginDataPipe.PREFIX}.${_.snakeCase(key).toUpperCase()}`;
      const i18nVal = this.translateService.instant(i18nKey);
      return i18nKey !== i18nVal ? i18nVal : key;
    }).join(', ');
  }
}

(function () {
  'use strict';

  FormEntryDetailModalController.$inject = ["app", "fields", "entry", "fieldTypeRegistry", "formService"];
  angular
      .module('coyo.apps.form')
      .controller('FormEntryDetailModalController', FormEntryDetailModalController);

  function FormEntryDetailModalController(app, fields, entry, fieldTypeRegistry, formService) {
    var vm = this;
    vm.app = app;
    vm.fields = fields;
    vm.entry = entry;
    vm.getConfig = getConfig;
    vm.getFieldValue = getFieldValue;

    function getConfig(field) {
      return fieldTypeRegistry.getRenderProperty(field.key);
    }

    function getFieldValue(entry, field) {
      var fieldEntry = formService.getFieldValue(entry, field.id);
      return fieldEntry ? fieldEntry.value : undefined;
    }
  }
})();

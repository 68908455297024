(function (angular) {
  'use strict';

  AdminGeneralSettingsController.$inject = ["$injector", "SettingsModel", "settings", "$translate"];
  angular.module('coyo.admin.settings')
      .controller('AdminGeneralSettingsController', AdminGeneralSettingsController);

  function AdminGeneralSettingsController($injector, SettingsModel, settings, $translate) {
    var vm = this;
    vm.$onInit = onInit;
    vm.save = save;
    vm.generateAdminGeneralSettingsObject = generateAdminGeneralSettingsObject;
    vm.generateAdminGeneralSettingsObjectHelper = generateAdminGeneralSettingsObjectHelper;
    vm.transferObject = {};

    function save() {
      return vm.transferObject.update().then(function () {
        // reset settings cache
        SettingsModel.retrieve(true);
        $injector.get('ngxNotificationService').success('ADMIN.SETTINGS.SAVE.SUCCESS');
      });
    }

    function onInit() {
      // Load the current settings from db
      return settings.get().then(function (currentSettingsFromBackEnd) {
        settings = currentSettingsFromBackEnd;
        vm.generateAdminGeneralSettingsObject(currentSettingsFromBackEnd);

        $translate('ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELETED_NAME.DEFAULT').then(function (translation) {
          vm.transferObject.deletedUserDisplayName = vm.transferObject.deletedUserDisplayName || translation;
        }, function () {
          vm.transferObject.deletedUserDisplayName = 'ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELETED_NAME.DEFAULT';
        });
      });
    }

    /**
     * This method creates a deep copy of settings, but only with the keys that this component really has.
     * The new created copy will be stored in the variable transferObject.
     * Now we can communicate with the backend server via the transferObject variable, and only transfer the data that we need.
     *
     * @param {settings} - settings object, over this object we have a communication to the back-end-server
     */
    function generateAdminGeneralSettingsObject(settings) {
      vm.transferObject = vm.generateAdminGeneralSettingsObjectHelper(_.cloneDeep(settings));
    }

    /**
     * Helper function for generateAdminGeneralSettingsObject.
     * In first step we clear the deep copy variable.
     * In second step we fill the new copy with the necessary keys
     *
     * @param {settingsDeepCopy} - the deep copy object from settings
     * @returns {settingsDeepCopy}
     */
    function generateAdminGeneralSettingsObjectHelper(settingsDeepCopy) {
      _(settingsDeepCopy).keys().forEach(function (key) {
        delete settingsDeepCopy[key];
      });

      settingsDeepCopy.networkName = settings.networkName;
      settingsDeepCopy.trackingCode = settings.trackingCode;
      settingsDeepCopy.subNavigationActive = settings.subNavigationActive;
      settingsDeepCopy.deletedUserAnonymizationActive = settings.deletedUserAnonymizationActive;
      settingsDeepCopy.deletedUserAnonymizationDelay = settings.deletedUserAnonymizationDelay;
      settingsDeepCopy.deletedUserDisplayName = settings.deletedUserDisplayName;
      settingsDeepCopy.defaultVisibilityPages = settings.defaultVisibilityPages;
      settingsDeepCopy.defaultVisibilityWorkspaces = settings.defaultVisibilityWorkspaces;
      settingsDeepCopy.userOfflineAfterCreationActive = settings.userOfflineAfterCreationActive;
      settingsDeepCopy.multiLanguageActive = settings.multiLanguageActive;
      settingsDeepCopy.integrationType = settings.integrationType;
      return settingsDeepCopy;
    }

  }

})(angular);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Provider
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {SublineService} from '@shared/sender-ui/subline/subline.service';
import {Observable, Subject} from 'rxjs';
import {concatMap, filter, map, scan, startWith} from 'rxjs/operators';

interface SublinedSender extends Sender {
  subline: string;
}

const functionalUserChooserValueProvider: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FunctionalUserChooserComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

/**
 * Component responsible for rendering the functional user chooser
 */
@Component({
  selector: 'coyo-functional-user-chooser',
  templateUrl: './functional-user-chooser.component.html',
  providers: [functionalUserChooserValueProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunctionalUserChooserComponent implements OnInit, ControlValueAccessor {

  /**
   * The selected user at creation (mainly the current user).
   */
  @Input() author: Sender;

  /**
   * The id of the context sender belonging to the timeline stream.
   */
  @Input() recipientId: string;

  /**
   * The id of the context timeline item.
   */
  @Input() timelineItemId: string;

  /**
   * Message key for the headline of the dropdown.
   */
  @Input() headline: string;

  /**
   * The selected sender.
   */
  @Output() change: EventEmitter<Sender> = new EventEmitter<Sender>();

  senders$: Observable<SublinedSender[]>;

  loading: boolean;

  private onChangeFn: (sender: Sender) => void;

  private managedSenders$: Subject<Sender> = new Subject<Sender>();

  private initialized: boolean = false;

  private currentPage: Page<Sender>;

  constructor(private senderService: SenderService,
              private sublineService: SublineService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.senders$ = this.managedSenders$.asObservable()
      .pipe(startWith(this.author))
      .pipe(filter(sender => !!sender))
      .pipe(concatMap(sender => this.getSublinedSender(sender)))
      .pipe(scan<SublinedSender, SublinedSender[]>((acc, value) => [...acc, value], []));
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Load the first page only if the dropdown is opened by the user.
   */
  onOpen(): void {
    if (!this.initialized) {
      this.initialized = true;
      this.loadNextPage(true);
    }
  }

  /**
   * Method called to check if a new page should be loaded.
   * Loads a new page if there are only 10 items left to scroll.
   *
   * @param event The event containing the current index of the last element in view
   */
  onScroll(event: { end: number }): void {
    if (this.currentPage && !this.currentPage.last &&
      event.end > ((this.currentPage.number + 1) * this.currentPage.size) - 10) {
      this.loadNextPage();
    }
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: (sender: Sender) => void): void {
    this.onChangeFn = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: (sender: Sender) => void): void {
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(value: Sender): void {
    this.author = value;
  }

  /**
   * Emits the change event and calls the change function
   *
   * @param sender The selected sender
   */
  selectSender(sender: Sender): void {
    this.change.emit(sender);
    if (this.onChangeFn) {
      this.onChangeFn(sender);
    }
  }

  private getSublinedSender(sender: Sender): Observable<SublinedSender> {
    return this.sublineService.getSublineForSender(sender)
      .pipe(map(subline => ({...sender, subline})));
  }

  private loadNextPage(initializing: boolean = false): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.senderService.getActableSenders(
      new Pageable(this.currentPage ? this.currentPage.number + 1 : 0, 20),
      this.timelineItemId || this.recipientId,
      this.timelineItemId ? 'timeline-item' : 'sender'
    ).subscribe(page => {
      this.currentPage = page;
      this.loading = false;
      page.content.forEach(elem => this.managedSenders$.next(elem));
    }, () => {
      this.loading = false;
      this.initialized = !initializing;
    });
  }
}

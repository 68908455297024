import {TeamsService} from '@app/msteams/teams/teams.service';
import {UrlService} from '@core/http/url/url.service';
import {TokenService} from '@core/token/token.service';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';
import {Transition} from '@uirouter/core';
import {switchMap} from 'rxjs/operators';

redirect.$inject = ['$transition$'];

/**
 * Setting up the local storage because the ios webview sometimes loses it on new instances.
 *
 * @param $transition$ the transition information
 */
export function redirect($transition$: Transition): void {
  const tokenService: TokenService = $transition$.injector().get(TokenService);
  const teamsService: TeamsService = $transition$.injector().get(TeamsService);
  const urlService: UrlService = $transition$.injector().get(UrlService);
  tokenService.getToken(true).pipe(switchMap(token => teamsService.sendToken(token)))
    .subscribe(verificationData => {
      const backendUrl = urlService.getBackendUrl();
      const urlPath = `/web/teams/auth/verify/${verificationData.verificationToken}`;
      document.location.href = urlService.join(backendUrl, urlPath);
    });
}

export const teamsRedirectState: NgHybridStateDeclaration = {
  name: 'teamsRedirect',
  url: '/teams/redirect',
  data: {
    authenticate: true
  },
  onEnter: redirect
};

import {CommonModule} from '@angular/common';
import {InjectionToken, NgModule} from '@angular/core';
import {
  Ng1AdminThemeConfig,
  Ng1AppRegistry,
  Ng1AuthService,
  Ng1BackendUrlService,
  Ng1CoyoConfig,
  Ng1CoyoTranslationLoader,
  Ng1CsrfService,
  Ng1DeepLinkService,
  Ng1DefaultThemeColors,
  Ng1DeviceRegistrationHandler,
  Ng1ErrorService,
  Ng1FileAuthorService,
  Ng1fileDetailsModalService,
  Ng1FileLibraryModalService,
  Ng1ImageCropModalService,
  Ng1ImageModalService,
  Ng1MessagingService,
  Ng1MobileEventsService,
  Ng1MobileStorageHandler,
  Ng1ModalService,
  Ng1ScrollBehaviourService,
  Ng1SocketReconnectDelays,
  Ng1SocketService,
  Ng1StateLockService,
  Ng1SublineService,
  Ng1TargetService,
  Ng1WidgetRegistry,
  Ng1SelectionFilterService
} from '@root/typings';
import {FileDetailsModalDirective} from '@upgrade/file-details-modal/file-details-modal.directive';
import {IScope, ITimeoutService} from 'angular';
import {IStateService} from 'angular-ui-router';
import {ngStorage} from 'ngstorage';
import {ImageReferenceDirective} from './image-reference/image-reference.directive';
import './pagination/pagination.service.downgrade';
import IInjectorService = angular.auto.IInjectorService;

export const NG1_FILE_LIBRARY_MODAL_SERVICE = new InjectionToken<Ng1FileLibraryModalService>('fileLibraryModalService');
export const NG1_COYO_TRANSLATION_LOADER = new InjectionToken<Ng1CoyoTranslationLoader>('coyoTranslationLoader');
export const NG1_COYO_CONFIG = new InjectionToken<Ng1CoyoTranslationLoader>('coyoConfig');
export const NG1_ADMIN_THEME_CONFIG = new InjectionToken<Ng1AdminThemeConfig>('adminThemeConfig');
export const NG1_DEFAULT_THEME_COLORS = new InjectionToken<Ng1DefaultThemeColors>('defaultThemeColors');
export const NG1_BACKEND_URL_SERVICE = new InjectionToken<Ng1BackendUrlService>('backendUrlService');
export const NG1_LOCAL_STORAGE = new InjectionToken<ngStorage.StorageService>('$localStorage');
export const NG1_MOBILE_EVENTS_SERVICE = new InjectionToken<Ng1MobileEventsService>('mobileEventsService');
export const NG1_CSRF_SERVICE = new InjectionToken<Ng1CsrfService>('csrfService');
export const NG1_AUTH_SERVICE = new InjectionToken<Ng1AuthService>('authService');
export const NG1_ERROR_SERVICE = new InjectionToken<Ng1ErrorService>('errorService');
export const NG1_STATE_SERVICE = new InjectionToken<IStateService>('$state');
export const NG1_WIDGET_REGISTRY = new InjectionToken<Ng1WidgetRegistry>('widgetRegistry');
export const NG1_TARGET_SERVICE = new InjectionToken<Ng1TargetService>('targetService');
export const NG1_$TIMEOUT = new InjectionToken<ITimeoutService>('$timeout');
export const NG1_SUBLINE_SERVICE = new InjectionToken<Ng1SublineService>('sublineService');
export const NG1_SOCKET_SERVICE = new InjectionToken<Ng1SocketService>('socketService');
export const NG1_MODAL_SERVICE = new InjectionToken<Ng1ModalService>('modalService');
export const NG1_STATE_LOCK_SERVICE = new InjectionToken<Ng1StateLockService>('stateLockService');
export const NG1_SCROLL_BEHAVIOR_SERVICE = new InjectionToken<Ng1ScrollBehaviourService>('scrollBehaviourService');
export const NG1_FILE_DETAILS_MODAL_SERVICE = new InjectionToken<Ng1fileDetailsModalService>('fileDetailsModalService');
export const NG1_IMAGE_MODAL_SERVICE = new InjectionToken<Ng1ImageModalService>('imageModalService');
export const NG1_MOBILE_STORAGE_HANDLER = new InjectionToken<Ng1MobileStorageHandler>('mobileStorageHandler');
export const NG1_DEVICE_REGISTRATION_HANDLER = new InjectionToken<Ng1DeviceRegistrationHandler>('deviceRegistrationHandler');
export const NG1_APP_REGISTRY = new InjectionToken<Ng1AppRegistry>('appRegistry');
export const NG1_MESSAGING_SERVICE = new InjectionToken<Ng1MessagingService>('messagingService');
export const NG1_SOCKET_RECONNECT_DELAYS = new InjectionToken<Ng1SocketReconnectDelays>('socketReconnectDelays');
export const NG1_FILE_AUTHOR_SERVICE = new InjectionToken<Ng1FileAuthorService>('fileAuthorService');
export const NG1_ROOTSCOPE = new InjectionToken<any>('$rootScope');
export const NG1_DEEP_LINK_SERVICE = new InjectionToken<Ng1DeepLinkService>('deepLinkService');
export const NG1_IMAGE_CROP_MODAL_SERVICE = new InjectionToken<Ng1ImageCropModalService>('imageCropModalService');
export const NG1_SELECTION_FILTER_SERVICE = new InjectionToken<Ng1SelectionFilterService>('selectionFilterService');

/**
 * Module exporting all the upgraded Angular1 assets
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FileDetailsModalDirective,
    ImageReferenceDirective
  ],
  exports: [
    FileDetailsModalDirective,
    ImageReferenceDirective
  ],
  providers: [
    {provide: NG1_FILE_LIBRARY_MODAL_SERVICE, useFactory: fileLibraryModalServiceFactory, deps: ['$injector']},
    {provide: NG1_COYO_TRANSLATION_LOADER, useFactory: coyoTranslationLoaderFactory, deps: ['$injector']},
    {provide: NG1_COYO_CONFIG, useFactory: coyoConfigFactory, deps: ['$injector']},
    {provide: NG1_ADMIN_THEME_CONFIG, useFactory: coyoAdminThemeConfigFactory, deps: ['$injector']},
    {provide: NG1_DEFAULT_THEME_COLORS, useFactory: defaultThemeColorsFactory, deps: ['$injector']},
    {provide: NG1_BACKEND_URL_SERVICE, useFactory: backendUrlServiceFactory, deps: ['$injector']},
    {provide: NG1_LOCAL_STORAGE, useFactory: $localStorageFactory, deps: ['$injector']},
    {provide: NG1_MOBILE_EVENTS_SERVICE, useFactory: mobileEventsServiceFactory, deps: ['$injector']},
    {provide: NG1_CSRF_SERVICE, useFactory: csrfServiceFactory, deps: ['$injector']},
    {provide: NG1_AUTH_SERVICE, useFactory: authServiceFactory, deps: ['$injector']},
    {provide: NG1_ERROR_SERVICE, useFactory: errorServiceFactory, deps: ['$injector']},
    {provide: NG1_STATE_SERVICE, useFactory: $stateFactory, deps: ['$injector']},
    {provide: NG1_WIDGET_REGISTRY, useFactory: widgetRegistryFactory, deps: ['$injector']},
    {provide: NG1_TARGET_SERVICE, useFactory: targetServiceFactory, deps: ['$injector']},
    {provide: '$scope', useFactory: scopeFactory, deps: ['$injector']}, // required for upgrading angularJs components
    {provide: NG1_ROOTSCOPE, useFactory: rootScopeFactory, deps: ['$injector']},
    {provide: NG1_$TIMEOUT, useFactory: timeoutFactory, deps: ['$injector']},
    {provide: NG1_SUBLINE_SERVICE, useFactory: sublineFactory, deps: ['$injector']},
    {provide: NG1_SOCKET_SERVICE, useFactory: socketServiceFactory, deps: ['$injector']},
    {provide: NG1_MODAL_SERVICE, useFactory: modalServiceFactory, deps: ['$injector']},
    {provide: NG1_STATE_LOCK_SERVICE, useFactory: stateLockServiceFactory, deps: ['$injector']},
    {provide: NG1_SCROLL_BEHAVIOR_SERVICE, useFactory: scrollBehaviourServiceFactory, deps: ['$injector']},
    {provide: NG1_FILE_DETAILS_MODAL_SERVICE, useFactory: fileDetailsModalServiceFactory, deps: ['$injector']},
    {provide: NG1_IMAGE_MODAL_SERVICE, useFactory: imageModalServiceFactory, deps: ['$injector']},
    {provide: NG1_MOBILE_STORAGE_HANDLER, useFactory: mobileStorageHandlerFactory, deps: ['$injector']},
    {provide: NG1_DEVICE_REGISTRATION_HANDLER, useFactory: deviceRegistrationHandlerFactory, deps: ['$injector']},
    {provide: NG1_APP_REGISTRY, useFactory: appRegistryFactory, deps: ['$injector']},
    {provide: NG1_MESSAGING_SERVICE, useFactory: messagingServiceFactory, deps: ['$injector']},
    {provide: NG1_SOCKET_RECONNECT_DELAYS, useFactory: socketReconnectDelaysFactory, deps: ['$injector']},
    {provide: NG1_FILE_AUTHOR_SERVICE, useFactory: fileAuthorServiceFactory, deps: ['$injector']},
    {provide: NG1_DEEP_LINK_SERVICE, useFactory: deepLinkServiceFactory, deps: ['$injector']},
    {provide: NG1_IMAGE_CROP_MODAL_SERVICE, useFactory: imageCropModalServiceFactory, deps: ['$injector']},
    {provide: NG1_SELECTION_FILTER_SERVICE, useFactory: selectionFilterServiceFactory, deps: ['$injector']}
  ]
})
export class UpgradeModule {
}

/**
 * Factory for the file library modal service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the file library modal service object
 */
export function fileLibraryModalServiceFactory(injector: IInjectorService): Ng1FileLibraryModalService {
  return injector.get('fileLibraryModalService');
}

/**
 * Factory for the translation loader
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the translation loader object
 */
export function coyoTranslationLoaderFactory(injector: IInjectorService): Ng1CoyoTranslationLoader {
  return injector.get('coyoTranslationLoader');
}

/**
 * Factory for the COYO config
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the coyo config
 */
export function coyoConfigFactory(injector: IInjectorService): Ng1CoyoConfig {
  return injector.get('coyoConfig');
}

/**
 * Factory for the admin theme config
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the admin theme config
 */
export function coyoAdminThemeConfigFactory(injector: IInjectorService): Ng1AdminThemeConfig {
  return injector.get('adminThemeConfig');
}

/**
 * Factory for the admin theme config
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the admin theme config
 */
export function defaultThemeColorsFactory(injector: IInjectorService): Ng1DefaultThemeColors {
  return injector.get('defaultThemeColors');
}

/**
 * Factory for the backend url service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the backend url service object
 */
export function backendUrlServiceFactory(injector: IInjectorService): Ng1BackendUrlService {
  return injector.get('backendUrlService');
}

/**
 * Factory for the local storage service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the local storage service object
 */
export function $localStorageFactory(injector: IInjectorService): ngStorage.StorageService {
  return injector.get('$localStorage');
}

/**
 * Factory for the csrf service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the csrf service object
 */
export function csrfServiceFactory(injector: IInjectorService): Ng1CsrfService {
  return injector.get('csrfService');
}

/**
 * Factory for the auth service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the auth service object
 */
export function authServiceFactory(injector: IInjectorService): Ng1AuthService {
  return injector.get('authService');
}

/**
 * Factory for the mobile events service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the mobile events service
 */
export function mobileEventsServiceFactory(injector: IInjectorService): Ng1MobileEventsService {
  return injector.get('mobileEventsService');
}

/**
 * Factory for the error service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the error service object
 */
export function errorServiceFactory(injector: IInjectorService): Ng1ErrorService {
  return injector.get('errorService');
}

/**
 * Factory for the state service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the state service object
 */
export function $stateFactory(injector: IInjectorService): IStateService {
  return injector.get('$state');
}

/**
 * Factory for the widget registry
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the widget registry object
 */
export function widgetRegistryFactory(injector: IInjectorService): Ng1WidgetRegistry {
  return injector.get('widgetRegistry');
}

/**
 * Factory for the target service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the target service object
 */
export function targetServiceFactory(injector: IInjectorService): Ng1TargetService {
  return injector.get('targetService');
}

/**
 * Factory for the scope
 *
 * @param injector
 * The AngularJS injector
 *
 * @return a new scope object
 */
export function scopeFactory(injector: IInjectorService): IScope {
  return injector.get('$rootScope').$new();
}

/**
 * Factory for the angularJs rootScope
 *
 * @param injector
 * The AngularJS injector
 *
 * @return angularJs rootScope
 */
export function rootScopeFactory(injector: IInjectorService): IScope {
  return injector.get('$rootScope');
}

/**
 * Factory for the timeout service
 *
 * @param injector
 * The AngularJS injector
 *
 * @return the timeout service object
 */
export function timeoutFactory(injector: IInjectorService): ITimeoutService {
  return injector.get('$timeout');
}

/**
 * Factory for the subline service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the subline service object
 */
export function sublineFactory(injector: IInjectorService): Ng1SublineService {
  return injector.get('sublineService');
}

/**
 * Factory for the subline service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the subline service object
 */
export function socketServiceFactory(injector: IInjectorService): Ng1SocketService {
  return injector.get('socketService');
}

/**
 * Factory for the modal service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the modal service object
 */
export function modalServiceFactory(injector: IInjectorService): Ng1ModalService {
  return injector.get('modalService');
}

/**
 * Factory for the stateLock service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the stateLock service object
 */
export function stateLockServiceFactory(injector: IInjectorService): Ng1StateLockService {
  return injector.get('stateLockService');
}

/**
 * Factory for the scroll behaviour service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the scroll behaviour service object
 */
export function scrollBehaviourServiceFactory(injector: IInjectorService): Ng1ScrollBehaviourService {
  return injector.get('scrollBehaviourService');
}

/**
 * Factory for the fileDetailsModal service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the fileDetailsModal service object
 */
export function fileDetailsModalServiceFactory(injector: IInjectorService): Ng1fileDetailsModalService {
  return injector.get('fileDetailsModalService');
}

/**
 * Factory for the fileDetailsModal service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the fileDetailsModal service object
 */
export function imageModalServiceFactory(injector: IInjectorService): Ng1ImageModalService {
  return injector.get('imageModalService');
}

/** Factory for the mobile storage handler service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the mobile storage handler service object
 */
export function mobileStorageHandlerFactory(injector: IInjectorService): Ng1MobileStorageHandler {
  return injector.get('mobileStorageHandler');
}

/** Factory for the device registration handler service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the device registration handler service object
 */
export function deviceRegistrationHandlerFactory(injector: IInjectorService): Ng1DeviceRegistrationHandler {
  return injector.get('deviceRegistrationHandler');
}

/**
 * Factory for appRegistry object
 *
 * @param injector
 * The angularJs injector
 *
 * @return the appRegistry object
 */
export function appRegistryFactory(injector: IInjectorService): Ng1AppRegistry {
  return injector.get('appRegistry');
}

/**
 * Factory for the messaging service
 *
 * @param injector
 * The angularJs injector
 *
 * @return the messaging service object
 */
export function messagingServiceFactory(injector: IInjectorService): Ng1MessagingService {
  return injector.get('messagingService');
}

/**
 * Factory for the socket reconnect delay constants
 *
 * @param injector
 * The angularJs injector
 *
 * @return the socket reconnect delay factory
 */
export function socketReconnectDelaysFactory(injector: IInjectorService): Ng1SocketReconnectDelays {
  return injector.get('socketReconnectDelays');
}

/**
 * Factory for the file author service
 * @param injector
 * The angularJs injector
 *
 * @return the file author service factory
 */
export function fileAuthorServiceFactory(injector: IInjectorService): Ng1FileAuthorService {
  return injector.get('fileAuthorService');
}

/**
 * Factory for the deep link service
 * @param injector
 * The angularJs injector
 *
 * @return the deep link service factory
 */
export function deepLinkServiceFactory(injector: IInjectorService): Ng1DeepLinkService {
  return injector.get('deeplinkService');
}

/**
 * Factory for the image crop modal service
 * @param injector
 * The angularJs injector
 *
 * @return the image crop modal service factory
 */
export function imageCropModalServiceFactory(injector: IInjectorService): Ng1DeepLinkService {
  return injector.get('imageCropModal');
}

/**
 * Factory for the selection filter service
 * @param injector
 * The angularJs injector
 *
 * @return the selection filter service
 */
export function selectionFilterServiceFactory(injector: IInjectorService): Ng1SelectionFilterService {
  return injector.get('selectionFilterService');
}

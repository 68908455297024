import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OembedService {
  private static readonly regex: {[key: string]: RegExp} = {
    youtube: /^(?:https?:\/\/)?(?:www\.|m\.)?(?:youtu\.be\/|youtube(-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    instagram: /(?:www\.|\/\/)instagram\.com\/p\/(.[a-zA-Z0-9_-]*)/,
    vine: /\/\/vine\.co\/v\/([a-zA-Z0-9]+)/,
    vimeo: /\/\/(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/,
    dailymotion: /.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/,
    youku: /\/\/v\.youku\.com\/v_show\/id_(\w+)=*\.html/,
    mp4: /^.+.(mp4|m4v)$/,
    ogg: /^.+.(ogg|ogv)$/,
    webm: /^.+.(webm)$/,
    videoCdn: /e\.video-cdn\.net\/(video|embed)\?video-id=.+&player-id=.+$/
  };

  /**
   * Creates a HTMLElement by the url of the video
   *
   * @param url url of the video
   * @returns a HTMLElement with the video
   */
  createByUrl(url: string): HTMLElement {
    // video url patterns(youtube, instagram, vimeo, dailymotion, youku, mp4, ogg, webm)
    // SOURCE: https://github.com/summernote/summernote/blob/develop/dist/summernote.js
    const ytMatch = url.match(OembedService.regex.youtube);
    const igMatch = url.match(OembedService.regex.instagram);
    const vMatch = url.match(OembedService.regex.vine);
    const vimMatch = url.match(OembedService.regex.vimeo);
    const dmMatch = url.match(OembedService.regex.dailymotion);
    const youkuMatch = url.match(OembedService.regex.youku);
    const mp4Match = url.match(OembedService.regex.mp4);
    const oggMatch = url.match(OembedService.regex.ogg);
    const webmMatch = url.match(OembedService.regex.webm);
    const cdnMatch = url.match(OembedService.regex.videoCdn);
    let element;

    if (ytMatch && ytMatch[2].length === 11) {
      element = this.createElement('//www.youtube-nocookie.com/embed/' + ytMatch[2]);
    } else if (igMatch && igMatch[0].length) {
      element = this.createElement('https://instagram.com/p/' + igMatch[1] + '/embed/');
      element.setAttribute('scrolling', 'no');
      element.setAttribute('allowtransparency', 'true');
    } else if (vMatch && vMatch[0].length) {
      element = this.createElement(vMatch[0] + '/embed/simple');
      element.setAttribute('class', 'vine-embed');
    } else if (vimMatch && vimMatch[3].length) {
      element = this.createElement('//player.vimeo.com/video/' + vimMatch[3]);
    } else if (dmMatch && dmMatch[2].length) {
      element = document.createElement('iframe');
      element.setAttribute('frameborder', '0');
      element.setAttribute('src', '//www.dailymotion.com/embed/video/' + dmMatch[2]);
    } else if (youkuMatch && youkuMatch[1].length) {
      element = this.createElement('//player.youku.com/embed/' + youkuMatch[1]);
    } else if (cdnMatch && cdnMatch[0].length) {
      element = this.createElement('https://' + cdnMatch[0]);
    } else if (mp4Match || oggMatch || webmMatch) {
      element = document.createElement('video');
      element.setAttribute('controls', '');
      element.setAttribute('src', url);
    }
    return element;
  }

  private createElement(src: string): HTMLElement {
    const element = document.createElement('iframe');
    element.setAttribute('webkitallowfullscreen', '');
    element.setAttribute('mozallowfullscreen', '');
    element.setAttribute('allowfullscreen', '');
    element.setAttribute('frameborder', '0');
    element.setAttribute('src', src);
    return element;
  }
}

(function (angular) {
  'use strict';

  TimelineShareModel.$inject = ["restResourceFactory", "coyoEndpoints"];
  angular
      .module('coyo.domain')
      .factory('TimelineShareModel', TimelineShareModel);

  /**
   * @ngdoc service
   * @name coyo.domain.TimelineShareModel
   *
   * @description
   * Provides the timeline share model.
   *
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   */
  function TimelineShareModel(restResourceFactory, coyoEndpoints) {
    var TimelineShareModel = restResourceFactory({
      url: coyoEndpoints.shares
    });

    // instance members
    angular.extend(TimelineShareModel.prototype, {

      /**
       * @ngdoc method
       * @name coyo.domain.TimelineShareModel#getOriginalAuthor
       * @methodOf coyo.domain.TimelineShareModel
       *
       * @description
       * Returns the original author of the affected TimelineItemModel.
       *
       * @returns {object} promise that resolved to the original author of the affected TimelineShareModel
       */
      getOriginalAuthor: function () {
        var url = TimelineShareModel.$url(this.id + '/originalauthor');
        return TimelineShareModel.$get(url);
      },

      /**
       * @ngdoc function
       * @name coyo.domain.TimelineShareModel#markAsRead
       * @methodOf coyo.domain.TimelineShareModel
       *
       * @description
       * Marks this share as read.
       *
       * @returns {promise} A $http promise
       */
      markAsRead: function () {
        var url = TimelineShareModel.$url(this.id + '/read');
        return TimelineShareModel.$post(url);
      },

      /**
       * @ngdoc method
       * @name coyo.domain.TimelineShareModel#makeUnsticky
       * @methodOf coyo.domain.TimelineShareModel
       *
       * @description
       * Unset the sticky expiry date.
       *
       * @returns {object} promise that resolved to the updated timeline share
       */
      makeUnsticky: function () {
        var url = TimelineShareModel.$url(this.id + '/unsticky');
        return TimelineShareModel.$post(url);
      },

      /**
       * @ngdoc method
       * @name coyo.domain.TimelineShareModel#removeFromShares
       * @methodOf coyo.domain.TimelineShareModel
       *
       * @description
       * Removes the current share from the given array of shares.
       *
       * @param {array} shares the array that contains the affected share
       *
       * @returns {boolean} `true` if the given share was removed from the given shares, otherwise `false`.
       */
      removeFromShares: function (shares) {
        var shareIndex = _.findIndex(shares, ['id', this.id]);
        if (shareIndex > -1) {
          shares.splice(shareIndex, 1);
          return true;
        }
        return false;
      }
    });

    // class members
    angular.extend(TimelineShareModel, {
      /**
       * @ngdoc method
       * @name coyo.domain.TimelineShareModel#share
       * @methodOf coyo.domain.TimelineShareModel
       *
       * @description
       * Creates a TimelineShareModel for the given targetType and share.
       *
       * @param {string} targetType The target type of the share.
       * @param {object} share The share.
       *
       * @returns {object} promise that resolved to the affected TimelineShareModel
       */
      share: function (targetType, share) {
        var url = TimelineShareModel.$url(targetType);
        return TimelineShareModel.$post(url, share);
      },

      /**
       * @ngdoc method
       * @name coyo.domain.TimelineShareModel#getShares
       * @methodOf coyo.domain.TimelineShareModel
       *
       * @description
       * Returns the TimelineShareModels for the given target type.
       * Addresses the following urls:
       * - /shares/timeline-item/{id}
       * - /shares/blog-article/{id}
       *
       * @returns {object} promise that resolves to the affected TimelineShareModels
       */
      getShares: function (typeName, id) {
        var url = TimelineShareModel.$url(typeName + '/' + id);
        return TimelineShareModel.$get(url, {_permissions: '*'});
      }

    });

    return TimelineShareModel;
  }

})(angular);

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {LikeTargetState} from '@domain/like/like-target-state';
import {LikeState} from '@domain/like/like.state';
import {SubscribeLikeInfo, UnsubscribeLikeInfo, UpdateSenderIdForTarget} from '@domain/like/likes.actions';
import {Sender} from '@domain/sender/sender';
import {Share} from '@domain/share/share';
import {ShareService} from '@domain/share/share.service';
import {TimelineItem} from '@domain/timeline-item/timeline-item';
import {TimelineItemTarget} from '@domain/timeline-item/timeline-item-target';
import {Store} from '@ngxs/store';
import {combineLatest, merge, Observable, of} from 'rxjs';
import {map, startWith, switchMap, tap} from 'rxjs/operators';

interface TimelineItemFooterState {
  likeState: LikeTargetState;
  shareCount: number;
}

/**
 * Footer component for timeline items. Showing all the social interaction items.
 */
@Component({
  selector: 'coyo-timeline-item-footer',
  templateUrl: './timeline-item-footer.component.html',
  styleUrls: ['./timeline-item-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemFooterComponent implements OnInit, OnDestroy {

  /**
   * The timeline item of the footer.
   */
  @Input() item: TimelineItem;

  /**
   * The context of the timeline used to calculate the possible functional users.
   */
  @Input() context: Sender;

  /**
   * The default author, mainly the current user.
   */
  @Input() author: Sender;

  /**
   * The target of the timeline item (e.g. a blog article when it is an blog-article share).
   */
  @Input() target: TimelineItemTarget;

  /**
   * Flag if the initial request should be skipped
   */
  @Input() skipInitRequest: boolean = false;

  /**
   * Emits when the author changed by the functional user chooser.
   */
  @Output() authorChanged: EventEmitter<Sender> = new EventEmitter<Sender>();

  /**
   * Emits when a share is created.
   */
  @Output() shareCreated: EventEmitter<Share> = new EventEmitter<Share>();

  /**
   * Emits when a share is deleted.
   */
  @Output() sharesDeleted: EventEmitter<Share[]> = new EventEmitter<Share[]>();

  state$: Observable<TimelineItemFooterState>;

  isXs$: Observable<boolean>;

  constructor(private store: Store,
              private shareService: ShareService,
              private windowSizeService: WindowSizeService) {
  }

  ngOnInit(): void {
    this.store.dispatch(new SubscribeLikeInfo(this.author.id, this.target));

    this.state$ = combineLatest([this.authorChanged, of(false)])
      .pipe(startWith([this.author, this.skipInitRequest]))
      .pipe(switchMap(([author, skipInitRequest]: [Sender, boolean]) =>
        combineLatest([this.getLikesStore(this.target.id, author.id), this.getSharesObservable(skipInitRequest)])
      ))
      .pipe(map(([likeState, shareCount]) => ({likeState, shareCount})));

    this.isXs$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UnsubscribeLikeInfo(this.target));
  }

  private getLikesStore(targetId: string, authorId: string): Observable<LikeTargetState> {
    this.store.dispatch(new UpdateSenderIdForTarget(authorId, this.target));
    return this.store
      .select(LikeState.likesByTarget)
      .pipe(
        map(filterFn => filterFn(targetId))
      );
  }

  private getSharesObservable(skipInitRequest: boolean): Observable<number> {
    return merge(this.shareCreated, this.sharesDeleted)
      .pipe(skipInitRequest ? tap() : startWith(null as Share))
      .pipe(switchMap(() => this.shareService.getShareCount(this.target)))
      .pipe(skipInitRequest ? startWith(0) : tap());
  }
}

(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name commons.mobile
   *
   * @description
   * A module containing extensions for the native apps. At the moment we are supporting iOS and Android.
   */
  angular
      .module('commons.mobile', []);

})(angular);

import {DriveItem} from '@app/integration/o365/o365-api/domain/drive-item';
import {BaseDriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/base-drive-file-picker-item';
import {FetchDriveItems} from '@app/integration/o365/share-point-file-picker/share-point-file-picker.service';
import {OFFICE365, StorageType} from '@domain/attachment/storage';
import {Observable} from 'rxjs';

/**
 * This class creates a FilePickerItem based on a SharePoint DriveItem entity
 */
export class DriveItemFilePickerItem implements BaseDriveFilePickerItem {
  readonly id: string;
  readonly name: string;
  readonly isFolder: boolean;
  readonly isSearchResult: boolean;
  readonly sizeInBytes: number | null;
  readonly lastModified: number;
  readonly mimeType: string | null;
  readonly storageType: StorageType;

  constructor(private driveItem: DriveItem, private fetchDriveItems: FetchDriveItems) {
    this.id = driveItem.parentReference.driveId + '$$' + driveItem.id;
    this.name = driveItem.name;
    this.isFolder = driveItem.hasOwnProperty('folder');
    this.isSearchResult = false;
    this.sizeInBytes = this.isFolder ? null : this.driveItem.size;
    this.lastModified = new Date(this.driveItem.lastModifiedDateTime).getTime();
    this.mimeType = driveItem.hasOwnProperty('file') ? this.driveItem.file.mimeType : null;
    this.storageType = OFFICE365;
  }

  static fromArray(driveItems: DriveItem[], fetchDriveItems: FetchDriveItems): DriveItemFilePickerItem[] {
    return driveItems.map(driveItem => new DriveItemFilePickerItem(driveItem, fetchDriveItems));
  }

  getChildren(): Observable<DriveItemFilePickerItem[]> {
    return this.fetchDriveItems(this.driveItem.parentReference.driveId, this.driveItem.id);
  }
}

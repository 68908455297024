<a class="user-online-link" uiSref="main.colleagues.online" *ngIf="count$ | async; let count; else loading">
  <div class="user-online-count">{{count}}</div>
  <div class="user-online-text">
    <div translate [translateParams]="{count: count}">WIDGET.USER_ONLINE.USERS</div>
    <div translate>WIDGET.USER_ONLINE.ONLINE</div>
  </div>
</a>
<ng-template #loading>
  <coyo-spinner size="sm"></coyo-spinner>
</ng-template>

import {Inject, Injectable} from '@angular/core';
import {Widget} from '@domain/widget/widget';
import {WidgetVisibilityConfig} from '@domain/widget/widget-visibility-config';
import {WidgetVisibilityConfigService} from '@domain/widget/widget-visibility-config.service';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import * as _ from 'lodash';
import {combineLatest, Observable, zip} from 'rxjs';
import {first, map, shareReplay} from 'rxjs/operators';
import {DynamicWidgetConfigService, WidgetConfig, WIDGET_CONFIGS, WIDGET_DYNAMIC_CONFIG_SERVICES} from '../widget-config';

export interface WidgetEnabledConfig extends WidgetConfig<Widget<WidgetSettings>> {
  enabled: boolean;
  moderatorsOnly: boolean;
}

type WidgetEnabledConfigMap = { [key: string]: WidgetEnabledConfig };

/**
 * Service to manage and retrieve provided instances of {@link WidgetConfig}.
 */
@Injectable()
export class WidgetRegistryService {

  private readonly widgetConfigs$: Observable<WidgetConfig<Widget<WidgetSettings>>[]>;

  constructor(@Inject(WIDGET_CONFIGS) private staticWidgetConfigs: WidgetConfig<Widget<WidgetSettings>>[],
              @Inject(WIDGET_DYNAMIC_CONFIG_SERVICES) dynamicWidgetConfigServices: DynamicWidgetConfigService[],
              private widgetVisibilityConfigService: WidgetVisibilityConfigService) {
    this.widgetConfigs$ = combineLatest(dynamicWidgetConfigServices.map(dynService => dynService.get$()))
      .pipe(
        map(dynamicConfigs => dynamicConfigs.reduce((all, dynConfig) => all.concat(dynConfig), staticWidgetConfigs)),
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  /**
   * Returns the configurations of all static registered widgets (including disabled widgets).
   *
   * @returns an array of widget configurations
   */
  getAllStatic(): WidgetConfig<Widget<WidgetSettings>>[] {
    return this.staticWidgetConfigs;
  }

  /**
   * Returns the configuration of the (possibly disabled) widget with the given widget key.
   *
   * @param key the key of the widget
   * @returns the widget configuration or `undefined` if no widget with the given key exists
   */
  get(key: string): Observable<WidgetConfig<Widget<WidgetSettings>> | undefined> {
    return this.widgetConfigs$.pipe(
      map(configs => configs.find(config => config.key === key)),
      first()
    );
  }

  /**
   * Returns a map for all given keys to their respective WidgetConfig.
   *
   * @param keys the array of keys
   * @returns a map of each key to its WidgetConfig or undefined
   */
  getAllByKeys(keys: string[]): Observable<WidgetEnabledConfigMap> {
    return zip(this.widgetConfigs$, this.widgetVisibilityConfigService.getAllEnabled())
      .pipe(
        map(([widgetConfigs, visibilityConfigs]) => keys.reduce((result: WidgetEnabledConfigMap, key: string) => {
            const visiblityConfig = visibilityConfigs.find(config => config.key === key);
            const widgetConfig = widgetConfigs.find(config => config.key === key);

            return widgetConfig ? _.set<WidgetEnabledConfigMap>(result, key, {
              enabled: _.get(visiblityConfig, 'enabled', false),
              moderatorsOnly: _.get(visiblityConfig, 'moderatorsOnly', false),
              ...widgetConfig
            }) : result;
          }, {})
        ), first());
  }

  /**
   * Returns the configurations of all registered and enabled widgets.
   *
   * @param includeRestricted include restricted widgets in the result list
   * @returns an array of widget configurations
   */
  getEnabled(includeRestricted: boolean = false): Observable<WidgetConfig<Widget<WidgetSettings>>[]> {
    const filter = (c1: WidgetConfig<Widget<WidgetSettings>>, c2: WidgetVisibilityConfig) =>
      c1.key === c2.key && (includeRestricted || !c2.moderatorsOnly);

    return zip(this.widgetConfigs$, this.widgetVisibilityConfigService.getAllEnabled())
      .pipe(
        map(([configs, enabledConfigs]) =>
          enabledConfigs.map(ec => configs.find(c => filter(c, ec))).filter(c => c !== undefined)
        ),
        first()
      );
  }
}

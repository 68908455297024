import {Injectable} from '@angular/core';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {Observable, of} from 'rxjs';

/**
 * Service for recognizing urls that will be handled by a micro service.
 */
@Injectable({
  providedIn: 'root'
})
export class ServiceRecognitionService {

  /**
   * List of paths that should be excluded from the default service unavailable handling of the error interceptor.
   */
  static readonly services: { [key: string]: string } = {
    'jit-translation': '/web/translation/',
    'analytics': '/web/analytics/'
  };

  constructor(private featureToggleServiceService: FeatureToggleService) {
  }

  /**
   * Checks if the given url will be handled by a micro service.
   *
   * @param url the url to be checked
   *
   * @return the target service name of the given url
   */
  getTargetService(url: string): string {
    let targetService: string = null;
    Object.keys(ServiceRecognitionService.services).forEach(key => {
      const value = ServiceRecognitionService.services[key];
      if (!targetService && url.includes(value)) {
        targetService = key;
      }
    });
    return targetService;
  }

  /**
   * Checks if the given service is currently available.
   *
   * @param targetService The service to check
   * @returns true is the service is available based on a feature toggle
   */
  isServiceEnabled(targetService: string): Observable<boolean> {
    if (!!targetService) {
      return this.featureToggleServiceService.isFeatureAvailable(targetService);
    }
    return of(false);
  }
}

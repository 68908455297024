import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {WelcomeWidgetSettingsComponent} from './welcome-widget-settings/welcome-widget-settings.component';
import {WELCOME_WIDGET} from './welcome-widget.config';
import {WelcomeWidgetComponent} from './welcome-widget/welcome-widget.component';

/**
 * Module providing the welcome widget
 */
@NgModule({
  declarations: [WelcomeWidgetComponent, WelcomeWidgetSettingsComponent],
  entryComponents: [WelcomeWidgetComponent, WelcomeWidgetSettingsComponent],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    HelpModule,
    SenderUIModule
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: WELCOME_WIDGET, multi: true}
  ]
})
export class WelcomeWidgetModule { }

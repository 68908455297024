import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {JitLanguageResponse} from '@shared/jit-translation/jit-translation/jit-language-response';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import * as _ from 'lodash';
import {Observable, of, Subject, throwError} from 'rxjs';
import {bufferTime, catchError, filter, first, flatMap, map, mergeMap, share, shareReplay} from 'rxjs/operators';

interface TypedLanguageResponse {
  response: LanguageResponse;
  typeName: string;
}

interface LanguageResponse {
  [id: string]: LanguageMapping;
}

interface LanguageMapping {
  languageMappings: {
    [key: string]: { 'language': string, 'disabledLanguages': string[] };
  };
  processed: boolean;
}

interface Translation {
  translation: string;
}

/**
 * Service to handle JIT translations.
 */
@Injectable({
  providedIn: 'root'
})
export class JitTranslationService {

  /**
   * The number of milliseconds to throttle GET language requests of this service.
   */
  static readonly THROTTLE: number = 250;
  private readonly getLanguageSubject: Subject<{id: string, typeName: string}> = new Subject<{id: string, typeName: string}>();
  private readonly getLanguageBulkRequest: Observable<TypedLanguageResponse> = this.getLanguageSubject
    .pipe(bufferTime(JitTranslationService.THROTTLE))
    .pipe(flatMap(requestArray => of(...this.collectIds(requestArray))))
    .pipe(mergeMap(typeRequest =>
      this.http.get<LanguageResponse>(`/web/translation/${typeRequest.typeName}/language`, {
        params: {
          ids: typeRequest.ids.join(',')
        }
      }).pipe(map(response => ({typeName: typeRequest.typeName, response})))))
    .pipe(share());

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              private notificationService: NotificationService) {
  }

  private collectIds(requests: {id: string, typeName: string}[]): {ids: string[], typeName: string}[] {
    const result: {[key: string]: string[]} = {};
    requests.forEach(request => {
      if (result[request.typeName]) {
        result[request.typeName].push(request.id);
      } else {
        result[request.typeName] = [request.id];
      }
    });
    return _.map(_.keys(result), key => ({typeName: key, ids: result[key]}));
  }

  /**
   * Retrieves a translation from the backend.
   *
   * @param id the ID of the source entity
   * @param field the field name to be used for the translation
   * @param language the target language
   * @param text the text that should be translated
   * @return an observable holding the translation
   */
  getTranslation(id: string, field: string, language: string, text: string): Observable<string> {
    const url = `/web/translation/translate/${id}/${field}`;
    return this.http.post<Translation>(url, {
      targetLanguage: language,
      text: text
    }, {headers: {handleErrors: 'false'}}).pipe(map(result => result.translation), catchError(error => {
      const msg = `<b>${this.translateService.instant('TRANSLATIONS.JIT.ERROR.NOTAVAILABLE')}</b><br>`;
      const err = `Error code: ${error.status}`;
      this.notificationService.info(`${msg}<br>${err}`, null, false, {enableHtml: true});
      return throwError(error);
    }));
  }

  /**
   * Retrieves languages from the backend.
   *
   * @param id the ID of the source entity
   * @param field the field name to be used for the translation
   * @param typeName the type name of the source entity
   * @return an observable holding the detected language and a list of disabled target languages
   */
  getLanguage(id: string, field: string, typeName: string): Observable<JitLanguageResponse> {
    setTimeout(() => {
      this.getLanguageSubject.next({id, typeName});
    });
    return this.getLanguageBulkRequest
      .pipe(filter(result => result.typeName === typeName && result.response[id] !== undefined))
      .pipe(map(result => result.response))
      .pipe(mergeMap(result => {
        if (!result[id].processed) {
          return throwError('NOT_PROCESSED');
        } else {
          return of(result[id].languageMappings[field]);
        }
      }))
      .pipe(shareReplay({bufferSize: 1, refCount: false}))
      .pipe(first());
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {EmptyListNoticeComponent} from '@shared/empty-list-notice/empty-list-notice.component';
import {MaterialModule} from '@shared/material/material.module';
import './empty-list-notice.component.downgrade';

@NgModule({
  declarations: [
    EmptyListNoticeComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule
  ],
  exports: [
    EmptyListNoticeComponent
  ]
})
export class EmptyListNoticeModule { }

import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {TimelineItemContentComponent} from '../timeline-item-content.component';

/**
 * Content component for a normal timeline post.
 */
@Component({
  selector: 'coyo-post-item',
  templateUrl: './post-item.component.html',
  styleUrls: ['./post-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostItemComponent extends TimelineItemContentComponent {

  previewUrl: string = '/web/timeline-items/{{groupId}}/attachments/{{id}}';

  translation: string;

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}

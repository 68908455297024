import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AppConfig, AppConfigBase} from '@apps/api/app-config';
import {AppModalComponent} from '@apps/api/app-modal/app-modal.component';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {AppSettingsComponent} from '@apps/api/app-settings/app-settings.component';
import {AppService} from '@domain/apps/app.service';
import {Sender} from '@domain/sender/sender';
import {BehaviorSubject, Observable} from 'rxjs';

interface AppChooserState {
  config?: AppConfig<AppSettings>;
  legacyApp?: any;
}

/**
 * Component used for creating new apps. Shows a list and the settings of the selected app.
 */
@Component({
  selector: 'coyo-app-chooser',
  templateUrl: './app-chooser.component.html',
  styleUrls: ['./app-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppChooserComponent extends AppModalComponent implements OnInit {

  apps$: Observable<AppConfigBase[]>;

  state$: BehaviorSubject<AppChooserState> = new BehaviorSubject<AppChooserState>({});

  @ViewChild(AppSettingsComponent) appSettings: AppSettingsComponent;

  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private dialogRef: MatDialogRef<AppChooserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sender: Sender }
  ) {
    super();
  }

  ngOnInit(): void {
    this.apps$ = this.appService.getAppConfigsForSender(this.data.sender);
    this.appForm = this.fb.group({});
  }

  openSettings(app: AppConfig<AppSettings>): void {
    this.state$.next({config: app, legacyApp: {key: app.key, settings: {}}});
  }

  onBack(): void {
    this.state$.next({});
    this.appForm.reset();
    this.legacyFormInvalid = false;
  }

  onSubmit(): void {
    let settings = {...this.state$.getValue().legacyApp.settings, ...this.appForm.getRawValue().settings};
    if (this.appSettings?.individualAppSettingsContainer?.individualAppSettings) {
      settings = this.appSettings.individualAppSettingsContainer.individualAppSettings.onBeforeSave(settings);
    }
    this.loading$.next(true);
    this.appService.createApp({
      ...this.state$.getValue().legacyApp,
      ...this.appForm.getRawValue(),
      ...{settings},
      senderId: this.data.sender.id,
      key: this.state$.getValue().config.key
    }, this.data.sender.id)
      .subscribe(app => {
        this.dialogRef.close(app);
      }, () => {
        this.loading$.next(false);
      });
  }
}

import {SelectUiSettings} from '@shared/select-ui/select-ui.settings';

/**
 * This action triggers a load or reload for the given widget, according to the given settings
 */
export class Load {
  static readonly type: string = '[Select UI Component] load';

  constructor(public id: string, public settings: SelectUiSettings<any>, public typeahead: string) {
  }
}

/**
 * This action triggers a loads more items for the given widget, according to the given settings
 */
export class LoadMore {
  static readonly type: string = '[Select UI Component] load more';

  constructor(public id: string, public settings: SelectUiSettings<any>) {
  }
}

/**
 * This action will remove the state of the widget with the given id from the global redux state
 */
export class Destroy {
  static readonly type: string = '[Select UI Component] destroy';

  constructor(public id: string) {
  }
}

import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '@root/injection-tokens';

/**
 * This service provides information about the browser capabilities.
 */
@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(@Inject(WINDOW) private window: Window) { }

  /**
   * Determines if quicktime videos are supported and the browser is not IE
   *
   * @returns true or false
   */
  supportsQuicktimeVideo(): boolean {
    return this.window['Modernizr'].video.h264 && !this.isInternetExplorer();
  }

  /**
   * Determines if the browser is the internet explorer
   *
   * @returns true or false
   */
  isInternetExplorer(): boolean {
    const userAgent = this.window.navigator.userAgent;

    const msie = userAgent.indexOf('MSIE ');
    const trident = userAgent.indexOf('Trident/');

    return msie >= 0 || trident >= 0;
  }
}

(function (angular) {
  'use strict';

  AdminUserDetailsController.$inject = ["$state", "$translate", "$scope", "authService", "RoleModel", "user"];
  angular
      .module('coyo.admin.userManagement')
      .controller('AdminUserDetailsController', AdminUserDetailsController);

  function AdminUserDetailsController($state, $translate, $scope, authService, RoleModel, user) {
    var vm = this;
    vm.user = user;
    vm.groups = user.groups;
    vm.roles = user.roles;
    vm.remoteUserDirectory = user.remoteUserDirectory
      ? {id: user.remoteUserDirectory.id, displayName: user.remoteUserDirectory.name} : null;
    vm.isPasswordFieldVisible = false;

    vm.save = save;
    vm.handleActiveChanged = handleActiveChanged;
    vm.handleWelcomeMailChanged = handleWelcomeMailChanged;
    vm.handleGeneratePasswordChanged = handleGeneratePasswordChanged;
    vm.hasRemoteUserIdWarning = hasRemoteUserIdWarning;
    vm.hasRemoteDirectoryChangedWarning = hasRemoteDirectoryChangedWarning;
    vm.hasRemoteDirectoryRemovedWarning = hasRemoteDirectoryRemovedWarning;

    function save() {
      vm.user.groupIds = _.map(vm.groups, 'id');
      vm.user.roleIds = _.map(vm.roles, 'id');
      vm.user.remoteUserDirectoryId = _.get(vm, 'remoteUserDirectory.id');
      // if password is empty delete the property and don't transfer it. Otherwise it would be considered invalid.
      if (_.isEmpty(vm.user.password)) {
        delete vm.user.password;
      }
      // delete login name as its not a required field but globally unique
      if (_.isEmpty(vm.user.loginNameAlt)) {
        delete vm.user.loginNameAlt;
      }
      return vm.user.isNew()
        ? vm.user.create().then(onSuccess)
        : vm.user.update().then(onSuccess);

      function onSuccess() {
        $state.go('^');
      }
    }

    function handleActiveChanged() {
      vm.user.welcomeMail = false;
      vm.user.generatePassword = false;
    }

    function handleWelcomeMailChanged() {
      vm.user.generatePassword = false;
    }

    function handleGeneratePasswordChanged() {
      vm.user.password = '';
    }

    function hasRemoteUserIdWarning() {
      return !!(vm.user.remoteUserId !== vm.oldRemoteUserId
          && vm.oldRemoteUserId
          && vm.user.remoteUserId
          && vm.remoteUserDirectory);
    }

    function hasRemoteDirectoryChangedWarning() {
      return !!(_.get(vm, 'remoteUserDirectory.id') !== vm.oldRemoteUserDirectoryId
          && !vm.user.isNew()
          && vm.remoteUserDirectory);
    }

    function hasRemoteDirectoryRemovedWarning() {
      return _.get(vm, 'remoteUserDirectory.id') !== vm.oldRemoteUserDirectoryId
          && !vm.remoteUserDirectory;
    }

    function determineAccumulatedRoles(groups) {
      var groupIds = angular.isArray(groups) ? _.map(groups, 'id') : [groups];
      RoleModel.getRolesForGroups(groupIds).then(function (groupRoles) {
        vm.accumulatedRoles = _.uniqBy(_.concat(vm.roles, groupRoles), 'id');
      });
    }

    function _isPasswordFieldVisible() {
      return (!user.remoteUserDirectory && !vm.remoteUserDirectory)
          || user.remoteUserDirectory && user.remoteUserDirectory.type === 'csv';
    }

    (function _init() {
      if (!vm.user.isNew()) {
        $translate('ADMIN.USER_MGMT.USERS.DETAILS.PASSWORD_UPDATE_HINT').then(function (text) {
          vm.passwordPlaceholder = text;
        });
        if (vm.user.status === 'HIDDEN') {
          vm.user.active = true;
        }
      }

      authService.getUser().then(function (currentUser) {
        vm.currentUser = currentUser;
      });

      vm.oldRemoteUserId = vm.user.remoteUserId;
      vm.oldRemoteUserDirectoryId = _.get(vm.user, 'remoteUserDirectory.id');
      vm.baseUrl = $state.href('main.profile', {});
      vm.oldSlug = user.slug;
      vm.isPasswordFieldVisible = _isPasswordFieldVisible();

      $scope.$watch('$ctrl.remoteUserDirectory', function () {
        vm.isPasswordFieldVisible = _isPasswordFieldVisible();
      });

      $scope.$watchGroup(['$ctrl.roles', '$ctrl.groups'], function () {
        determineAccumulatedRoles(vm.groups);
      });
    })();
  }

})(angular);

import {TimelineItem} from '@domain/timeline-item/timeline-item';
import {TimelineItemService} from '@domain/timeline-item/timeline-item.service';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';
import {StateService, Transition} from '@uirouter/core';
import {TimelineItemRoutingComponent} from './timeline-item/timeline-item-routing/timeline-item-routing.component';

resolveItem.$inject = ['$transition$'];

export function resolveItem(transition: Transition): Promise<TimelineItem> {
  const stateService = transition.injector().get(StateService);
  const timelineItemService: TimelineItemService = transition.injector().get(TimelineItemService);
  return timelineItemService
    .getItem(transition.params().id, 'personal', '', ['*'])
    .toPromise().catch(err => {
      stateService.go(transition.$from());
      return Promise.reject(err);
    });
}

export const timelineItemState: NgHybridStateDeclaration = {
  name: 'main.timeline-item',
  url: '/timeline/item/:id',
  component: TimelineItemRoutingComponent,
  data: {
    authenticate: true,
    pageTitle: 'MODULE.TIMELINE.ITEM.PAGE_TITLE'
  },
  resolve: {
    item: resolveItem
  }
};

<ng-container *ngIf="!loading && events; else skeletonTemplate">
  <ng-container *ngFor="let event of events.content; let i = index">
    <coyo-divider *ngIf="events.content | showDivider:i">
      <strong *ngIf="event | startsToday" translate="MODULE.EVENTS.TODAY"></strong>
      <span *ngIf="!(event | startsToday)">
        <strong>{{event.startDate | amDateFormat: eventDayName }}</strong>,
        {{event.startDate | amDateFormat: eventFullDate}}
      </span>
    </coyo-divider>

    <div class="panel panel-default">
      <div class="panel-body">
        <coyo-ribbon type="event" *ngIf="event | isOngoing"></coyo-ribbon>
        <ng-container *ngIf="{isMobile: isMobile$ | async} as windowSize">
          <coyo-event-avatar *ngIf="windowSize.isMobile" [sender]="event" [showOverlay]="true"></coyo-event-avatar>
          <coyo-event-avatar *ngIf="!windowSize.isMobile" [sender]="event" [avatarSize]="'xl'" [showOverlay]="true"></coyo-event-avatar>
        </ng-container>
        <div class="page-card-heading">
          <h4 class="event-title pointer">
            <a [uiSref]="'main.event.show'"
               [uiParams]="{idOrSlug: event.slug}"
               [uiOptions]="{reload: 'main.event.show', inherit: false}">
              {{event.displayName}}
            </a>
          </h4>
          <coyo-event-participation *ngIf="event._permissions.canParticipate"
                                    [event]="event"
                                    [isOngoing]="event | isOngoing"
                                    [hasEnded]="event | hasEnded"
                                    (statusChanged)="onParticipationStatusChanged($event, event)">
          </coyo-event-participation>
        </div>
        <div class="page-card-content">
          <div class="event-description" [textContent]="event.description | stripHtml"></div>
          <div class="event-labels">
            <coyo-event-date-label [event]="event"></coyo-event-date-label>
            <span class="event-label event-location" *ngIf="event.place">
              <mat-icon svgIcon="location-pin" size="small"></mat-icon>&nbsp;
              <coyo-event-location [place]="event.place" [displayWide]="event.fullDay"></coyo-event-location>
            </span>
            <span class="event-label label-desktop" *ngIf="event.attendingCount || event.limitedParticipants">
              <mat-icon svgIcon="contacts" size="small"></mat-icon>&nbsp;{{event.attendingCount}}<span *ngIf="event.limitedParticipants">/{{event.limitedParticipants?.participantsLimit}}</span>
            </span>
            <span class="event-label">
              <mat-icon [svgIcon]="event.public ? 'globe' : 'lock'" size="small"></mat-icon>
              &nbsp;{{'EVENT.VISIBILITY.' + event.visibilityName | translate}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #skeletonTemplate>
  <ui-skeleton [skeletons]="skeletonData.dateHeader"></ui-skeleton>
  <ng-container *ngIf="{isMobile: isMobile$ | async} as windowSize">
    <ui-skeleton *ngFor="let i of [].constructor(SKELETON_REPEAT_COUNT)"
                 [skeletons]="windowSize.isMobile ? skeletonData.eventListEntryMobile : skeletonData.eventListEntry">
    </ui-skeleton>
  </ng-container>
</ng-template>

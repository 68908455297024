(function (angular) {
  'use strict';

  SearchBarController.$inject = ["$rootScope", "$element", "$state", "$transitions", "$scope", "$timeout", "$http", "coyoEndpoints", "hotkeys", "searchConfig", "$log", "$q", "scrollBehaviourService"];
  angular
      .module('coyo.search')
      .directive('oyocSearchBar', searchBar)
      .directive('oyocShowTypeaheadOnFocus', oyocShowTypeaheadOnFocus)
      .controller('SearchBarController', SearchBarController);

  /**
   * @ngdoc directive
   * @name coyo.search.oyocSearchBar:oyocSearchBar
   * @restrict 'E'
   * @element OWN
   * @scope
   *
   * @description
   * Displays and handles the search box in the main nav bar.
   *
   * While the search box is always visible in mobile view, it can be expanded and hidden in desktop view.
   *
   * @requires $rootScope
   * @requires $element
   * @requires $state
   */
  function searchBar() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'app/modules/search/components/search-bar.html',
      controller: 'SearchBarController',
      controllerAs: 'searchBar'
    };
  }

  function oyocShowTypeaheadOnFocus() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        element.bind('focus', function () {
          var currentSelection = ngModel.$viewValue;
          ngModel.$setViewValue(undefined);
          ngModel.$setViewValue(currentSelection);
        });
      }
    };
  }

  function SearchBarController($rootScope, $element, $state, $transitions, $scope, $timeout, $http, coyoEndpoints,
                               hotkeys, searchConfig, $log, $q, scrollBehaviourService) {
    var vm = this;
    vm.$onInit = onInit;
    vm.onKeyUp = onKeyUp;
    vm.typeaheadIsOpen = false;

    var canceller;

    hotkeys.add({
      combo: 's',
      callback: function () {
        show();
      }
    });

    function search(query) {
      if (_.isEmpty(_.trim(query))) {
        return $q.reject();
      }

      _cancelLastQuickSearchRequest();
      return _requestQuickSearch(query)
          .then(_addShowAllResultsDummyToResponse);
    }

    function _requestQuickSearch(query) {
      return $http({
        method: 'GET',
        url: coyoEndpoints.search.quick
            .replace('{page}', searchConfig.quickSearch.page)
            .replace('{pageSize}', searchConfig.quickSearch.pageSize),
        params: {
          term: query,
          newSearch: false
        },
        autoHandleErrors: false,
        timeout: canceller.promise
      });
    }

    function _addShowAllResultsDummyToResponse(response) {
      var dummy = {
        displayName: _getSearchInputValue(),
        typeName: 'show-all-results-dummy'
      };

      var result = response.data.content;
      result.unshift(dummy);
      return result;
    }

    function _cancelLastQuickSearchRequest() {
      canceller.resolve();
      canceller = $q.defer();
    }

    function _getSearchInputValue() {
      var input = _findFirstElement('#global-search-input-field');
      return input ? input.value : undefined;
    }

    function _findFirstElement(elementSelector) {
      return _.first($element.querySelectorAll(elementSelector));
    }

    function searchResultSelected(item) {
      if (item.typeName === 'show-all-results-dummy') {
        var query = _getSearchInputValue();
        item.displayName = query;
        _openGlobalSearch(query);
      } else {
        _openEntityPage(item);
        hide();
      }
    }

    function _openEntityPage(item) {
      var idOrSlug = item.target.params.slug || item.target.params.id;

      switch (item.typeName) {
        case 'page':
        case 'workspace':
        case 'event':
          $state.transitionTo('main.' + item.target.name + '.show', {idOrSlug: idOrSlug});
          break;

        case 'user':
          $state.transitionTo('main.profile', {userId: idOrSlug});
          break;

        default:
          $log.error('Unconfigured typeName ' + item.typeName);
          break;
      }
    }

    function _resetSelection() {
      vm.searchResultSelection = undefined;
    }

    function allowSelection($event) {
      return !_tabKeyPressed($event);
    }

    function _tabKeyPressed($event) {
      return $event.keyCode === 9;
    }

    function onKeyUp($event) {
      if (!vm.typeaheadIsOpen && _enterKeyPressed($event)) {
        var query = _getSearchInputValue();
        if (_.isEmpty(_.trim(query))) {
          return;
        }
        _cancelLastQuickSearchRequest();
        _openGlobalSearch(query);
      }
    }

    function _enterKeyPressed($event) {
      return $event.keyCode === 13;
    }

    function _openGlobalSearch(query) {
      $rootScope.search.new = true;
      $state.transitionTo('main.search', angular.extend({
        term: query
      }));
    }

    function setIsSearchResult() {
      if ($state.is('main.search')) {
        vm.isSearchResult = true;
      }
    }

    function show() {
      _setVisibility(true);
      _focusSearch('input');
    }

    function hide() {
      _setVisibility(false);
      _focusSearch('.search-icon-open');
    }

    function _setVisibility(isVisible) {
      $rootScope.search = angular.extend($state.is('main.search') ? $rootScope.search : {}, {
        visible: isVisible
      });
      if (!isVisible) {
        _resetSelection();
      }
    }

    function _focusSearch(elementSelector) {
      $timeout(function () {
        var targetFocus = $element.find(elementSelector);
        if (targetFocus[0]) {
          targetFocus[0].focus();
        }
      }, 10);
    }

    function onInit() {
      vm.show = show;
      vm.hide = hide;
      vm.search = search;
      vm.allowSelection = allowSelection;
      vm.searchResultSelected = searchResultSelected;
      vm.searchResultSelection = undefined;
      vm.isSearchResult = false;

      canceller = $q.defer();

      if (!$rootScope.search) {
        $rootScope.search = {};
      } else {
        vm.searchResultSelection = $rootScope.search.term;
      }

      $scope.$watch('searchBar.typeaheadIsOpen', function (isOpen) {
        if (isOpen) {
          scrollBehaviourService.disableBodyScrollingOnXsScreen();
        } else {
          scrollBehaviourService.enableBodyScrolling();
        }
      });

      var deregisterHook = $transitions.onSuccess({}, setIsSearchResult);
      $scope.$on('$destroy', deregisterHook);
      setIsSearchResult();

      var deregisterSearchResultsLeftListener = $scope.$on('search:search-results-left', hide);
      $scope.$on('$destroy', deregisterSearchResultsLeftListener);

      var deregisterSearchResultUpdatedListener = $scope.$watch('$root.search.term', function (newVal) {
        vm.searchResultSelection = newVal;
      });
      $scope.$on('$destroy', deregisterSearchResultUpdatedListener);
    }
  }

})(angular);

(function (angular) {
  'use strict';

  ImageModalController.$inject = ["$document", "$scope", "$uibModalInstance", "imageUrl", "title"];
  angular
      .module('commons.ui')
      .controller('ImageModalController', ImageModalController);

  /**
   * @ngdoc controller
   * @name commons.ui.ImageModalController
   *
   * @description
   * This controller manages the image modal.
   *
   * @requires $document
   * @requires $scope
   * @requires $uibModalInstance
   * @requires imageUrl
   * @requires title
   */
  function ImageModalController($document, $scope, $uibModalInstance, imageUrl, title) {
    var vm = this;
    vm.$onInit = onInit;

    function _bindKeyUp() {
      $document.on('keyup', _onKeyUp);
    }

    function _unbindKeyUp() {
      $document.off('keyup', _onKeyUp);
    }

    function _closeModal() {
      $uibModalInstance.close(vm.updated);
    }

    function _onKeyUp(event) {
      if (event.which === 27) {
        $scope.$apply(function () {
          event.preventDefault();
          _closeModal();
        });
      }
    }

    function onInit() {
      vm.imageUrl = imageUrl;
      vm.title = title;

      _bindKeyUp();

      $scope.$on('$destroy', _unbindKeyUp);
    }
  }

})(angular);

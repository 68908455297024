import * as _ from 'lodash';

const TOOLBAR_BUTTONS = {
  moreText: {
    buttons: [
      'bold', 'italic', 'underline', 'strikeThrough', 'clearFormatting'
    ],
    buttonsVisible: 3
  },
  moreParagraph: {
    buttons: [
      'alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatUL'
    ],
    buttonsVisible: 3
  },
  moreRich: {
    buttons: [
      'insertLink'
    ],
    buttonsVisible: 2
  },
  moreMisc: {
    buttons: [
      'undo', 'redo', 'fullscreen', 'html'
    ],
    buttonsVisible: 0,
    align: 'right'
  }
};

export const rteOptions: object = {
  htmlRemoveTags: ['img'],
  imagePaste: false,
  pluginsEnabled: [
    'url',
    'link',
    'lists',
    'align',
    'fullscreen',
    'codeView'
  ],
  toolbarButtons: TOOLBAR_BUTTONS,
  toolbarButtonsXS: _.merge({}, TOOLBAR_BUTTONS, {
    moreText: { buttonsVisible: 2 },
    moreParagraph: { buttonsVisible: 0 },
    moreRich: { buttonsVisible: 0 },
    moreMisc: { buttonsVisible: 0 }
  }),
  toolbarButtonsSM: _.merge({}, TOOLBAR_BUTTONS, {
    moreText: { buttonsVisible: 2 },
    moreParagraph: { buttonsVisible: 2 },
    moreRich: { buttonsVisible: 1 },
    moreMisc: { buttonsVisible: 0 }
  }),
  toolbarButtonsMD: _.merge({}, TOOLBAR_BUTTONS, {
    moreText: { buttonsVisible: 2 },
    moreParagraph: { buttonsVisible: 2 },
    moreRich: { buttonsVisible: 2 },
    moreMisc: { buttonsVisible: 0 }
  }),
};

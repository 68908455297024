(function (angular) {
  'use strict';

  i18nService.$inject = ["$log", "$translate", "amMoment", "$numeral", "momentMapping"];
  angular
      .module('commons.i18n')
      .factory('i18nService', i18nService);

  /**
   * @ngdoc service
   * @name commons.i18n.i18nService
   *
   * @description
   * This service provides a method for setting the current language.
   *
   * @requires $log
   * @requires $translate
   * @requires amMoment
   * @requires $numeral
   * @requires momentMapping
   */
  function i18nService($log, $translate, amMoment, $numeral, momentMapping) {

    /**
     * @ngdoc method
     * @name commons.i18n.i18nService#setInterfaceLanguage
     * @methodOf commons.i18n.i18nService
     *
     * @description
     * Sets a new current language if the given language is available.
     *
     * @param {string} language The new current language
     */
    function setInterfaceLanguage(language) {
      if (angular.isString(language)) {
        language = language.toLowerCase();

        try {
          $translate.use(language).then(function () {
            $numeral.locale(language);
            var momentKey = _.has(momentMapping, language) ? _.get(momentMapping, language) : language.toLowerCase();
            if (amMoment.changeLocale(momentKey) !== momentKey) { // language changed to prior one, script has not been loaded yet
              angular.element(document).find('body').append('<script type="application/javascript" src="/moment-locale/' + momentKey + '.js"></script>');
              amMoment.changeLocale(language);
            }
            $log.debug('[i18nService] Interface language changed to', language);
          }).catch(function (error) {
            $log.error('[i18nService] Interface language could not be set to', language, error);
          });
        } catch (error) {
          $log.error('[i18nService] Error setting interface language', language, error);
        }
      } else {
        $log.error('[i18nService] Invalid language: ', language);
      }
    }

    return {
      setInterfaceLanguage: setInterfaceLanguage
    };
  }

})(angular);

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TimelineFormAttachment} from '@shared/files/attachment-btn/types/timeline-form-attachment';
import {TimelineFormCoyoLibraryAttachment} from '@shared/files/attachment-btn/types/timeline-form-coyo-library-attachment';
import {FileItem} from 'ng2-file-upload';

/**
 * Renders the attachment list for a timeline form.
 */
@Component({
  selector: 'coyo-attachment-list',
  templateUrl: './attachment-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentListComponent {

  /**
   * All selected attachments.
   */
  @Input() selectedAttachments: (FileItem | TimelineFormAttachment | TimelineFormCoyoLibraryAttachment)[];

  /**
   * Will be called if an attachment was removed from the list.
   */
  @Output() attachmentRemoved: EventEmitter<TimelineFormAttachment | TimelineFormCoyoLibraryAttachment> = new EventEmitter();

  constructor() {
  }

  /**
   * Removes the uploaded attachment from the list.
   *
   * @param attachment the attachment to be removed
   */
  removeAttachment(attachment: TimelineFormAttachment | TimelineFormCoyoLibraryAttachment): void {
    this.attachmentRemoved.emit(attachment);
  }
}

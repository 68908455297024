(function () {
  'use strict';

  EntryFormController.$inject = ["$scope", "fieldTypeRegistry", "fieldValueService"];
  angular
      .module('coyo.apps.commons.fields')
      .component('oyocEntryForm', entryForm())
      .controller('EntryFormController', EntryFormController);

  /**
   * @ngdoc directive
   * @name coyo.apps.commons.fields.oyocEntryForm:oyocEntryForm
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   *
   * @param {object} entry
   * The model object for an entry that stores all values that belong to the list entry.
   *
   * @param {array} fields
   * Field array that contains all fields that belong to the app. A concrete field consists of properties like id, name,
   * required, hidden and field type specific settings.
   *
   * @param {object} formCtrl
   * The form for defining the entry values
   *
   * @param {object} appFormSettings
   * Settings that are given by the app to be used by the form fields
   *
   * @param {boolean} appFormSettings.noAppContext
   * If true file fields will open the file library of the current user and not of the current app
   *
   */
  function entryForm() {
    return {
      templateUrl: 'app/apps/commons/fields/entry-forms/entry-form.html',
      bindings: {
        entry: '=',
        fields: '<',
        formCtrl: '<',
        appFormSettings: '<?'
      },
      controller: 'EntryFormController',
      controllerAs: '$ctrl'
    };
  }

  function EntryFormController($scope, fieldTypeRegistry, fieldValueService) {
    var vm = this;

    vm.$onInit = init;
    vm.getFieldValue = getFieldValue;
    vm.getFieldConfig = getFieldConfig;
    vm.showInitialValidationErrors = showInitialValidationErrors;

    function init() {
      $scope.$watch(function () {
        return vm.entry;
      }, _initEntry, true);

      vm.formCtrl.getFieldName = function (field) {
        return 'field-' + field.id;
      };

      vm.formCtrl.getField = function (field) {
        return vm.formCtrl[vm.formCtrl.getFieldName(field)];
      };
    }

    function getFieldConfig(field) {
      var config = angular.merge(field, fieldTypeRegistry.get(field.key));
      config.appFormSettings = vm.appFormSettings;
      return config;
    }

    function getFieldValue(fieldId) {
      return fieldValueService.getFieldValue(vm.entry, fieldId);
    }

    function showInitialValidationErrors(field) {
      var fieldCtrl = vm.formCtrl.getField(field);
      return !vm.entry.isNew() || fieldCtrl && fieldCtrl.$dirty;
    }

    function _initEntry() {
      vm.entry = fieldValueService.initEntry(vm.entry, vm.fields);
    }

  }
})();

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {IframeWidget} from '@widgets/iframe/iframe-widget';

/**
 * The iframe widget embeds web pages.
 */
@Component({
  selector: 'coyo-iframe-widget',
  templateUrl: './iframe-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeWidgetComponent extends WidgetComponent<IframeWidget> {
}

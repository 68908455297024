import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {App} from '@apps/api/app';
import {AppRegistryService} from '@apps/api/app-registry.service';
import {StateService} from '@uirouter/core';
import {IStateParamsService} from 'angular-ui-router';

/**
 * This component creates a link to an app, based on its sender type. To create the link the default
 * state is read from the app configuration.
 */
@Component({
  selector: 'coyo-app-href',
  templateUrl: './app-href.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHrefComponent implements OnInit {

  /**
   * The app to create a link for. The app must at least contain a key and an ID. This must
   * not be null or undefined.
   */
  @Input() app: App<any>;
  link: string;

  constructor(
    private appRegistry: AppRegistryService,
    private stateService: StateService
  ) { }

  /**
   * Creates the link for the app
   */
  ngOnInit(): void {
    const stateName = this.appRegistry.getDefaultStateName(this.app.key, this.app.senderType);
    const senderId  = this.app.senderId;
    const appIdOrSlug = this.app.slug || this.app.id;
    const stateParams: IStateParamsService = {};
    if (senderId) {
      stateParams.idOrSlug = senderId;
    }
    stateParams.appIdOrSlug = appIdOrSlug;
    this.link = this.stateService.href(stateName, stateParams);
  }
}

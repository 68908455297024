(function (angular) {
  'use strict';

  listService.$inject = ["fieldValueService", "ListEntryModel"];
  angular
      .module('coyo.apps.list')
      .factory('listService', listService);

  /**
   * @ngdoc service
   * @name coyo.apps.list.listService
   *
   * @description
   * Service for common functions of the list that must be shared between different controllers
   */
  function listService(fieldValueService, ListEntryModel) {

    return angular.extend({
      createEntry: createEntry,
    }, fieldValueService);

    /**
     * @ngdoc method
     * @name coyo.apps.list.listService#createEntry
     * @methodOf coyo.apps.list.listService
     *
     * @description
     * Create entry model with missing field values
     *
     * @returns {object}
     * List entry model
     */
    function createEntry() {
      return new ListEntryModel({
        values: []
      });
    }
  }
})(angular);


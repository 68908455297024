(function (angular) {
  'use strict';

  SamlJitSettingsController.$inject = ["$scope", "CapabilitiesModel", "profileFieldsService", "SAML_BINDING_DEFAULT"];
  angular.module('coyo.admin.authenticationProviders.saml-jit')
      .component('oyocSamlJitSettings', samlJitSettings())
      .controller('SamlJitSettingsController', SamlJitSettingsController);

  /**
   * @ngdoc directive
   * @name coyo.admin.authenticationProviders.saml-jit.oyocSamlJitSettings:oyocSamlJitSettings
   * @scope
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Renders the form fields of a SAML Just-in-time authentication provider.
   */
  function samlJitSettings() {
    return {
      require: 'ngModel',
      controller: 'SamlJitSettingsController',
      controllerAs: '$ctrl',
      templateUrl: 'app/modules/admin/authentication-providers/saml-jit/saml-jit-settings/saml-jit-settings.html',
      bindings: {
        form: '<',
        ngModel: '='
      }
    };
  }

  function SamlJitSettingsController($scope, CapabilitiesModel, profileFieldsService, SAML_BINDING_DEFAULT) {
    var vm = this;
    var profileFieldGroups = [];

    vm.$onInit = onInit;

    vm.addProfileFieldConfiguration = addProfileFieldConfiguration;
    vm.removeProfileField = removeProfileField;
    vm.onProfileFieldSelect = onProfileFieldSelect;

    function onProfileFieldSelect(fieldName, selectedField) {
      removeProfileField(fieldName, false);
      vm.ngModel.properties.jitProfileFields[selectedField.name] = '';
      vm.profileFieldsOrdered[_.findIndex(vm.profileFieldsOrdered, {name: fieldName})] = selectedField;
      _updateAvailableProfileFields();
    }

    function addProfileFieldConfiguration(e) {
      e.preventDefault();
      if (vm.availableProfileFields.length >= 0) {
        vm.ngModel.properties.jitProfileFields[vm.availableProfileFields[0].name] = '';
        vm.profileFieldsOrdered[vm.profileFieldsOrdered.length] = vm.availableProfileFields[0];
        _updateAvailableProfileFields();
      }
    }

    function removeProfileField(fieldName, removeFromDisplay) {
      if (_.has(vm.ngModel.properties.jitProfileFields, fieldName)) {
        delete vm.ngModel.properties.jitProfileFields[fieldName];
        if (removeFromDisplay) {
          _.remove(vm.profileFieldsOrdered, {name: fieldName});
        }
        _updateAvailableProfileFields();
      }
    }

    function _updateAvailableProfileFields() {
      vm.availableProfileFields = _getAvailableProfileFields();
    }

    function _getAllProfileFields() {
      var result = [];
      for (var i = 0; i < profileFieldGroups.length; ++i) {
        if (profileFieldGroups[i].fields) {
          for (var j = 0; j < profileFieldGroups[i].fields.length; ++j) {
            result.push(profileFieldGroups[i].fields[j]);
          }
        }
      }

      return result;
    }

    function _getAvailableProfileFields() {
      var result = _getAllProfileFields();

      if (result.length > 0) {
        return _.sortBy(_.filter(result, function (val) {
          return !_.has(vm.ngModel.properties.jitProfileFields, val.name);
        }), function (f) {
          return f.name;
        });
      }

      return [];
    }

    function _prepareDisplay() {
      var allProfileFields = _getAllProfileFields();
      _.forEach(vm.ngModel.properties.jitProfileFields, function (value, key) {
        var profileField = _.find(allProfileFields, {name: key});
        if (profileField) {
          vm.profileFieldsOrdered.push(profileField);
        }
      });
    }

    function _removeDeletedProfileFields() {
      var profileFields = _getAllProfileFields();
      var profileFieldMappings = _.get(vm.ngModel, 'properties.jitProfileFields');
      var filteredMappings = _.pickBy(profileFieldMappings, function (value, key) {
        return _.findIndex(profileFields, {name: key}) > -1;
      });
      _.set(vm.ngModel, 'properties.jitProfileFields', filteredMappings);
    }

    function onInit() {
      vm.oldSlug = vm.ngModel.slug;
      vm.availableLogoutMethods = ['UNKNOWN'];
      vm.idpBindings = ['POST', 'REDIRECT'];
      vm.availableProfileFields = [];
      vm.selectedProfileFields = [];
      vm.profileFieldsOrdered = [];

      if (!_.get(vm.ngModel, 'properties.authenticationBinding')) {
        _.set(vm.ngModel, 'properties.authenticationBinding', SAML_BINDING_DEFAULT);
      }
      if (!_.get(vm.ngModel, 'properties.logoutBinding')) {
        _.set(vm.ngModel, 'properties.logoutBinding', SAML_BINDING_DEFAULT);
      }

      var deregisterFn = $scope.$on('authenticationProviderFieldErrors', function (event, errorDetails) {
        vm.errorMessage = errorDetails[0].message;
        vm.signingCertificateError = angular.isDefined(_.find(errorDetails, {key: 'signingCertificate'}));
        vm.signingPrivateKeyError = angular.isDefined(_.find(errorDetails, {key: 'signingPrivateKey'}));
        vm.idpTrustAnchorError = angular.isDefined(_.find(errorDetails, {key: 'idpTrustAnchor'}));
      });
      $scope.$on('$destroy', deregisterFn);

      CapabilitiesModel.authProviderCapabilities('saml').then(function (samlCapabilities) {
        vm.availableLogoutMethods =
            _.isArray(samlCapabilities.logoutMethods) ? samlCapabilities.logoutMethods : ['UNKNOWN'];
      });

      if (_.isUndefined(_.get(vm.ngModel, 'properties.userDirectory'))) {
        _.set(vm.ngModel, 'properties.userDirectory', 'SAMLJIT');
      }
      _.set(vm.ngModel, 'properties.jitUserProvEnabled', true);

      profileFieldsService.getGroups().then(function (groups) {
        profileFieldGroups = groups;
        _removeDeletedProfileFields();
        _updateAvailableProfileFields();
        _prepareDisplay();
        vm.loading = false;
      });
    }
  }
})(angular);

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {FacegameWidgetSettingsComponent} from '@widgets/facegame/facegame-widget-settings/facegame-widget-settings.component';
import {FacegameWidgetComponent} from '@widgets/facegame/facegame-widget/facegame-widget.component';
import {SubscriptionWidget} from '@widgets/subscription/subscription-widget';

export const FACEGAME_WIDGET: WidgetConfig<SubscriptionWidget> = {
  key: 'facegame',
  name: 'WIDGET.FACEGAME.NAME',
  description: 'WIDGET.TEXT.DESCRIPTION',
  icon: 'zmdi-face',
  component: FacegameWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  settings: {
    skipOnCreate: false,
    component: FacegameWidgetSettingsComponent
  },
  renderOptions: {
    printable: false
  }
};

import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpParams,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendInterceptor} from '@core/http/backend-interceptor/backend-interceptor';
import {EtagCacheService} from '@core/http/etag-cache/etag-cache.service';
import {UrlService} from '@core/http/url/url.service';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

/**
 * An HTTP interceptor that adds ETag handling for REST calls.
 *
 * If a GET request contains an ETag header, the response is stored in a local cache (session storage). Any subsequent
 * request for the same url (incl. the same parameters) will send the `If-None-Match` header and if the backend
 * responds with status `304` (not modified) the cached result is used and returned to the caller.
 *
 * There is a special handling for bulk requests, where each ID is treated as a separate, ETag-cachable request.
 * The headers used are analogous to non-bulk requests (`X-Bulk-Etag` and `X-If-None-Match`) and contain a map of ID to
 * value. The backend response contains the status values of the individual virtual requests as a map in header
 * `X-Bulk-Status`.
 *
 * Configuration can be added by setting HTTP headers:
 *   - `etagEnabled`: if `'false'` the cache is ignored (default `'true'`).
 *   - `etagBulkId`: the name of the bulk request parameter.
 */
@Injectable()
export class EtagInterceptor extends BackendInterceptor {

  /**
   * The config key to enable etag caching. Defaults to `true`;
   */
  static readonly ETAG_ENABLED: string = 'etagEnabled';

  /**
   * The config key for the etag bulk ID.
   */
  static readonly ETAG_BULK_ID: string = 'etagBulkId';

  constructor(private etagCacheService: EtagCacheService,
              urlService: UrlService) {
    super(urlService);
  }

  /**
   * Adds ETag caching to all requests.
   *
   * @param request the current request
   * @param next the next HTTP handler in the chain
   * @returns an `Observable` of the `HttpEvent`
   */
  interceptBackendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isEnabled = request.headers.get(EtagInterceptor.ETAG_ENABLED) !== 'false';
    const bulkId = request.headers.get(EtagInterceptor.ETAG_BULK_ID);
    const cacheKey = this.etagCacheService.buildKey(request.urlWithParams, bulkId);
    const newRequest = request.clone({
      headers: request.headers
        .set('Cache-Control', 'no-cache, no-store')
        .set('Pragma', 'no-cache')
        .set('If-Modified-Since', '0')
        .delete(EtagInterceptor.ETAG_ENABLED)
        .delete(EtagInterceptor.ETAG_BULK_ID)
    });

    if (newRequest.method === 'GET' && isEnabled) {
      if (this.etagCacheService.isCached(cacheKey)) {
        return this.etagCacheRequest(cacheKey, newRequest, next);
      } else if (this.etagCacheService.isBulkCached(cacheKey)) {
        return this.bulkEtagCacheRequest(cacheKey, bulkId, newRequest, next);
      }
    }

    return next.handle(newRequest).pipe(map(response => response.type === HttpEventType.Response
      ? this.addResponseWithEtagHeaderToCache(cacheKey, response)
      : response));
  }

  /*
   * Performs an ETag-cached request.
   */
  private etagCacheRequest(cacheKey: string, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newRequest = request.clone({
      headers: request.headers.set('If-None-Match', `"${this.etagCacheService.getEntry(cacheKey).etag}"`)
    });
    // tslint:disable-next-line:deprecation
    return next.handle(newRequest).pipe(catchError(of), map((response: HttpEvent<any>) => {
      if (response instanceof HttpErrorResponse) {
        if (response.status === 304 && this.etagCacheService.isCached(cacheKey)) {
          const entry = this.etagCacheService.getEntry(cacheKey);
          return new HttpResponse({
            body: entry.body,
            status: entry.status,
            url: response.url,
            headers: response.headers
          });

        }
      }
      return this.addResponseWithEtagHeaderToCache(cacheKey, response);
    }));
  }

  /*
   * Performs an ETag-cached bulk request.
   */
  private bulkEtagCacheRequest(cacheKey: string, bulkId: string, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const etagMap = this.getCachedEtags(request.params, cacheKey, bulkId);
    const newRequest = !_.isEmpty(etagMap) ? request.clone({
      headers: request.headers.set('X-Bulk-If-None-Match', JSON.stringify(etagMap))
    }) : request;

    return next.handle(newRequest)
      .pipe(map(response => {
        if (response.type === HttpEventType.Response) {
          if (response.headers.has('X-Bulk-Status')) {
            const cached = _(JSON.parse(response.headers.get('X-Bulk-Status')))
              .pickBy((status, _id) => status === 304)
              .mapValues((_status, id) => this.etagCacheService.getBulkEntry(cacheKey, id).body)
              .value();
            return this.addResponseWithEtagHeaderToCache(cacheKey, response.clone({
              body: {...response.body, ...cached}
            }));
          }
          return this.addResponseWithEtagHeaderToCache(cacheKey, response);
        }
        return response;
      }));
  }

  /*
   * Add given response to ETag caches.
   */
  private addResponseWithEtagHeaderToCache(cacheKey: string, response: HttpEvent<any>): HttpEvent<any> {
    if (response.type === HttpEventType.Response) {
      const etag = this._getEtagHeader(response.headers.get('Etag'));
      if (etag) {
        this.etagCacheService.putEntry(cacheKey, etag, response.status, response.body);
      }
      const bulkEtag = response.headers.get('X-Bulk-Etag');
      if (bulkEtag) {
        _.forEach(JSON.parse(bulkEtag), (tag, id) => {
          this.etagCacheService.putBulkEntry(cacheKey, id, tag, response.status, response.body[id]);
        });
      }
    }
    return response;
  }

  private _getEtagHeader(header: string): string {
    if (!header) {
      return header;
    }
    return (header.startsWith('W/') ? header.substring(2) : header).replace(/"/g, '');
  }

  /*
   * Collects all cached ETags from the given HTTP params.
   */
  private getCachedEtags(params: HttpParams, cacheKey: string, bulkId: string): { [key: string]: string } {
    return _(params.getAll(bulkId))
      .keyBy(_.identity)
      .mapValues(id => _.get(this.etagCacheService.getBulkEntry(cacheKey, id), 'etag'))
      .pickBy(etag => !!etag)
      .value();
  }
}

import {Direction} from './direction.enum';

/**
 * Sort option for queries.
 */
export class Order {

  /**
   * The name of the field to sort on.
   */
  field: string;

  /**
   * The sorting direction.
   */
  direction: Direction;

  constructor(field: string,
              direction: Direction = Direction.Asc) {
    this.field = field;
    this.direction = direction;
  }
}

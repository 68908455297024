<div [formGroup]="parentForm">
  <div [class.has-error]="isInvalid('_sourceSelection')" class="form-group">
    <label class="col-sm-3 control-label" for="sourceSelection"
           translate="WIDGETS.BLOG.SETTINGS.BLOG_SOURCES.LABEL"></label>
    <mat-radio-group class="col-sm-8" formControlName="_sourceSelection" id="sourceSelection" name="sourceSelection">
      <mat-radio-button [attr.aria-label]="'WIDGETS.BLOG.SETTINGS.BLOG_USE_ALL_BLOGS.LABEL' | translate"
                        value="ALL">{{'WIDGETS.BLOG.SETTINGS.BLOG_USE_ALL_BLOGS.LABEL' | translate}}
      </mat-radio-button>
      <mat-radio-button [attr.aria-label]="'WIDGETS.BLOG.SETTINGS.BLOG_USE_SELECTED_BLOGS.LABEL' | translate"
                        value="SELECTED">{{"WIDGETS.BLOG.SETTINGS.BLOG_USE_SELECTED_BLOGS.LABEL" | translate}}
      </mat-radio-button>
    </mat-radio-group>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.BLOG.SETTINGS.BLOG_SOURCES.HELP"></coyo-help>
    </div>
  </div>
  <div [class.has-error]="isInvalid('_app')" class="form-group">
    <ng-container *ngIf="isSourceSelection$ | async">
      <label class="col-sm-3 control-label" translate="WIDGETS.BLOG.SETTINGS.BLOG_APP.LABEL"></label>
      <div class="col-sm-8">
        <coyo-select-app [attr.aria-label]="'WIDGETS.BLOG.SETTINGS.BLOG_APP.LABEL' | translate"
                         clearable="true"
                         closeOnSelect="true"
                         data-test="share-with-sender-select"
                         formControlName="_app"
                         id="apps"
                         key="blog"
                         multiselect="true"
                         placeholder="COMMONS.SHARES.PLACEHOLDER">
        </coyo-select-app>
      </div>
      <div class="col-sm-1 hidden-xs">
        <coyo-help tip="WIDGETS.BLOG.SETTINGS.BLOG_APP.HELP"></coyo-help>
      </div>
    </ng-container>
  </div>
  <div class="form-group">
    <label class="col-sm-3 control-label" for="showOnlySubscribed"
           translate="WIDGETS.BLOG.SETTINGS.SHOW_ONLY_SUBSCRIBED.LABEL"></label>
    <div class="col-sm-9 checkbox-option">
      <mat-checkbox [attr.aria-label]="'WIDGETS.BLOG.SETTINGS.SHOW_ONLY_SUBSCRIBED.LABEL' | translate"
                    formControlName="_onlySubscribed" id="showOnlySubscribed" name="showOnlySubscribed"></mat-checkbox>
    </div>
  </div>
  <div [class.has-error]="isInvalid('_articleCount')" class="form-group">
    <label class="col-sm-3 control-label" for="articleCount"
           translate="WIDGETS.BLOG.SETTINGS.ARTICLE_COUNT.LABEL"></label>
    <div class="col-sm-8">
      <input [attr.aria-label]="'WIDGETS.BLOG.SETTINGS.ARTICLE_COUNT.LABEL' | translate"
             class="form-control"
             formControlName="_articleCount"
             id="articleCount"
             max="20"
             min="1"
             name="articleCount"
             step="1"
             type="number">
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.BLOG.SETTINGS.ARTICLE_COUNT.HELP"></coyo-help>
    </div>
  </div>
  <div [class.has-error]="isInvalid('showTeaserImage')" class="form-group">
    <label class="col-sm-3 control-label" for="showTeaserImage"
           translate="WIDGETS.BLOG.SETTINGS.BLOG_SHOW_TEASER_IMAGE.LABEL"></label>
    <div class="col-sm-8 checkbox-option">
      <mat-checkbox [attr.aria-label]="'WIDGETS.BLOG.SETTINGS.BLOG_SHOW_TEASER_IMAGE.LABEL' | translate"
                    formControlName="_showTeaserImage" id="showTeaserImage" name="showTeaserImage"></mat-checkbox>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.BLOG.SETTINGS.BLOG_SHOW_TEASER_IMAGE.HELP"></coyo-help>
    </div>
  </div>
</div>

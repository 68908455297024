import {Inject, Injectable} from '@angular/core';
import {STATE_NAME_ENGAGE, STATE_NAME_ENGAGE_INIT} from '@app/engage/engage.state';
import {STATE_NAME_ENGAGE_NEWS} from '@app/engage/news/news.state';
import {STATE_NAME_ENGAGE_TERMS_VIEW} from '@app/engage/terms/terms.state';
import {Ng1MobileEventService} from '@root/typings';
import {TransitionService} from '@uirouter/core';
import {NG1_MOBILE_EVENTS_SERVICE, NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';

/**
 * Handler for the engage app.
 */
@Injectable({
  providedIn: 'root'
})
export class EngageTransitionHandlerService {

  private unregister: Function;

  constructor(private transitionService: TransitionService,
              @Inject(NG1_STATE_SERVICE) private stateService: IStateService,
              @Inject(NG1_MOBILE_EVENTS_SERVICE) private mobileEventService: Ng1MobileEventService) {
  }

  /**
   * Adds a transition listener that cancels all incoming transition except the
   * transitions to the news state and the login state. It also propagates the
   * event 'transition' with the state name and state parameters.
   */
  watchTransitions(): void {
    if (this.unregister) {
      this.unregister();
      this.unregister = null;
    }
    this.unregister = this.transitionService.onStart({}, transition => {
      const targetState = transition.targetState();
      const targetStateName = targetState.name();
      const targetStateParams = targetState.params();
      const targetStateUrl = this.stateService.href(targetStateName, targetStateParams);
      this.mobileEventService.propagate('transition', {
        name: targetStateName,
        params: targetStateParams,
        url: targetStateUrl
      });
      const states = ['front.login', STATE_NAME_ENGAGE, STATE_NAME_ENGAGE_INIT,
        STATE_NAME_ENGAGE_NEWS,
        STATE_NAME_ENGAGE_TERMS_VIEW];
      return states.indexOf(targetStateName) !== -1;
    }, {priority: Number.MAX_SAFE_INTEGER});
  }
}

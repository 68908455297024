<h3 class="widget-title">
  {{(widget.settings._title) || ("WIDGET.BIRTHDAY.NAME" | translate)}}</h3>
<ng-container *ngIf="(state$ | async) as state">
  <ng-container *ngIf="state.dates?.length">
    <div *ngFor="let userGroup of state.dates" class="birthday-container">
      <div class="birthday-date today" *ngIf="userGroup.today">{{'WIDGET.BIRTHDAY.TODAY' | translate}}</div>
      <div class="birthday-date" *ngIf="!userGroup.today">{{userGroup.birthday | date:'dd MMMM'}}</div>
      <div *ngFor="let user of userGroup.users" class="birthday-item-container">
        <div class="birthday-item">
          <a class="user-item-link" uiSref="main.profile" [uiParams]="{userId: user.slug}"
             [attr.aria-label]="'WIDGETS.BIRTHDAY.USER.LINK.ARIA' | translate:user">
            <coyo-user-avatar [user]="user"
                              avatarSize="sm"
                              [showOnlineStatus]="false"
                              [showExternalFlag]="true"
                              [noLink]="true"></coyo-user-avatar>
            <div class="user-item-container">
              <div class="user-item-headline">{{user.displayName}}</div>
              <span class="age" *ngIf="widget.settings._displayAge && (user.birthday | age: getCurrentDate())">
                {{'WIDGET.BIRTHDAY.AGE' | translate: {years: user.birthday | age: getCurrentDate()} }}
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="load-more">
      <button mat-button mat-link-button color="accent" (click)="loadMore()" *ngIf="!state.page.last && !state.loading">
        <mat-icon svgIcon="chevron-down" color="accent"></mat-icon>
        <span translate="SHOW_MORE" [attr.aria-label]="'WIDGET.BIRTHDAY.LOAD_MORE.ARIA' | translate"></span>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!state.dates?.length && !state.loading">
    <span class="empty-message">{{'WIDGET.BIRTHDAY.NO_BIRTHDAYS' | translate}}</span>
  </ng-container>
  <ng-container *ngIf="state.loading">
    <ui-skeleton [skeletons]="skeletons" *ngFor="let number of getNeededSkeletons(state)"></ui-skeleton>
  </ng-container>
</ng-container>


import {ViewportScroller} from '@angular/common';
import {WindowSizeService} from '@core/window-size/window-size.service';
import * as _ from 'lodash';
import {HtmlTagTree} from './html-tag-tree/html-tag-tree';
import {HtmlTagTreeNode} from './html-tag-tree/html-tag-tree-node';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

/**
 * Component for displaying shortened versions of long content with a collapse/expand functionality
 *
 * @Example:
 * <coyo-collapsible-component [content]="someContent">
 * </coyo-collapsible-component>
 */
@Component({
  selector: 'coyo-collapsible-content',
  templateUrl: './collapsible-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsibleContentComponent implements OnInit, OnChanges {

  /**
   * The raw content to display
   */
  @Input() content: string;

  /**
   * The number of characters where shortening is considered for desktop resolutions
   */
  @Input() maxCharsDesktop: number = 550;

  /**
   * The minimum amount of characters required to be hidden in order the shorten the text for desktop resolutions.
   * The is useful to avoid situations where only a few words are cut off
   */
  @Input() minCutoffDesktop: number = 200;

  /**
   * The number of characters where shortening is considered for mobile resolutions
   */
  @Input() maxCharsMobile: number = 280;

  /**
   * The minimum amount of characters required to be hidden in order the shorten the text for mobile resolutions.
   * The is useful to avoid situations where only a few words are cut off
   */
  @Input() minCutoffMobile: number = 100;

  /**
   * Indicates if the text is currently collapsed
   */
  get isCollapsed(): boolean {
    return this.collapsed;
  }

  /**
   * Indicates if the site is currently displayed on a mobile device
   */
  get isMobile(): boolean {
    return this.windowSizeService.isXs() || this.windowSizeService.isSm();
  }

  /**
   * The number of characters where shortening is considered for the current resolution
   */
  get maxChars(): number {
    return this.isMobile ? this.maxCharsMobile : this.maxCharsDesktop;
  }

  /**
   * The minimum amount of characters required to be hidden in order the shorten the text for the current resolution.
   * The is useful to avoid situations where only a few words are cut off
   */
  get minCutoff(): number {
    return this.isMobile ? this.minCutoffMobile : this.minCutoffDesktop;
  }

  /**
   * Gets the currently applicable translation key for the expand/collapse button
   */
  get buttonLabel(): string {
    return this.isCollapsed ? 'MODULE.TIMELINE.ITEM.SHOW_MORE' : 'MODULE.TIMELINE.ITEM.SHOW_LESS';
  }

  /**
   * Indicates whether the text can be shortened with the current configuration
   */
  get isCollapsible(): boolean {
    return this.cutoffIndex >= 0;
  }

  get text(): string {
    return (this.isCollapsed && this.collapsedText) ? this.collapsedText : this.content;
  }

  /**
   * The string to be appended to a cut-off text
   */
  suffixString: string = '... ';

  protected tagTree: HtmlTagTree;

  protected cutoffIndex: number;
  /**
   * Indicates if the text is currently collapsed
   */
  protected collapsed: boolean = true;

  /**
   * Stores the current scroll location when an item is expanded
   */
  protected lastScrollPosition: [number, number] = null;

  /**
   * Stores the shortened version of the current text
   */
  protected collapsedText: string = null;

  private static selectLowerValidIndex(indexA: number, indexB: number): number {
    return indexA < 0 ? (indexB < 0 ? -1 : indexB) : (indexB < 0 ? indexA : Math.min(indexA, indexB));
  }

  private static checkScrollPositionValue(v: [number, number]): boolean {
    return v && v.length === 2 && v[0] !== null && v[0] !== undefined && !isNaN(v[0]) && v[1] !== null && v[1] !== undefined && !isNaN(v[1]);
  }

  constructor(private viewportScroller: ViewportScroller,
              private windowSizeService: WindowSizeService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(): void {
    this.refresh();
  }

  /**
   * Re-applies the current state
   */
  refresh(): void {
    this.collapsedText = null;
    this.tagTree = new HtmlTagTree(this.content);
    this.generateShortenedText();
  }

  /**
   * Toggles the expanded/collapsed state if possible
   */
  toggle(): void {
    if (this.isCollapsed) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  /**
   * Expands the item to show the full text
   */
  expand(): void {
    this.lastScrollPosition = this.getScrollLocation();
    this.collapsed = false;
  }

  /**
   * Collapses the text if possible
   */
  collapse(): void {
    if (this.isCollapsible) {
      // scroll back to pre-expand location
      if (CollapsibleContentComponent.checkScrollPositionValue(this.lastScrollPosition)) {
        this.viewportScroller.scrollToPosition(this.lastScrollPosition);
      }
      this.collapsed = true;
    }
  }

  private getScrollLocation(): [number, number] {
    let loc = this.viewportScroller.getScrollPosition();
    if (!CollapsibleContentComponent.checkScrollPositionValue(loc)) {
      loc = [window.pageXOffset, window.pageYOffset];
    }
    if (!CollapsibleContentComponent.checkScrollPositionValue(loc) && document.documentElement) {
      loc = [document.documentElement.scrollLeft, document.documentElement.scrollTop];
    }
    if (!CollapsibleContentComponent.checkScrollPositionValue(loc) && document.body) {
      loc = [document.body.scrollLeft, document.body.scrollTop];
    }
    return loc;
  }

  private generateShortenedText(): void {
    this.tagTree = new HtmlTagTree(this.content);
    this.findCutoffIndex();
    if (this.cutoffIndex >= 0) {
      const parentTagAroundCutoff = _.find(this.tagTree.roots, tag => tag.isIndexInScope(this.cutoffIndex));
      const closingString = this.getClosingString(parentTagAroundCutoff, this.cutoffIndex);
      // construct shortened text
      this.collapsedText = `${this.content.substr(0, this.cutoffIndex)}${closingString}`;
    }
  }
  private getClosingString(parentTag: HtmlTagTreeNode, cutoffIndex: number): string {
    // generate closing tags for open nodes
    let tagAppendix = '';
    let appendixArray: string[] = [];
    // is there a tag surrounding the cutoff index ?
    if (!!parentTag) {
      appendixArray = parentTag.getOpenNodesAt(cutoffIndex).map(tag => `</${tag}>`);
      if (appendixArray.length > 0 && appendixArray[appendixArray.length - 1] === '</p>') {
        appendixArray.splice(appendixArray.length - 1, 0, this.suffixString);
      } else {
        appendixArray.push(this.suffixString);
      }
    } else {
      appendixArray.push(this.suffixString);
    }
    appendixArray.forEach(str => tagAppendix += str);
    return tagAppendix;
  }

  private findCutoffIndex(): void {
    this.cutoffIndex = -1;
    // check the basic text length requirements
    if (!!this.content && this.tagTree.textLength > this.maxChars + this.minCutoff) {
      const contentMaxChars = this.tagTree.getContentIndexAt(this.maxChars);
      const spaceIndex = this.tagTree.contentIndexOf(' ', contentMaxChars);
      const returnIndex = this.tagTree.contentIndexOf('\n', contentMaxChars);
      const index = CollapsibleContentComponent.selectLowerValidIndex(spaceIndex, returnIndex);
      if (this.tagTree.textLength - this.tagTree.getTextIndexAt(index) > this.minCutoff) {
        this.cutoffIndex = index;
      }
    }
  }
}

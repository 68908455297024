import {Injectable} from '@angular/core';
import {Sender} from '@domain/sender/sender';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {PollWidgetService} from '@widgets/poll/poll-widget/poll-widget.service';
import {InitializeVoters, LoadMoreVoters} from '@widgets/poll/voters-modal/voters-modal.actions';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export interface VotersModalStateModel {
  loading: boolean;
  voters: Sender[];
  currentPage: number;
  last: boolean;
}

@Injectable({providedIn: 'root'})
@State<VotersModalStateModel>({
  name: 'pollWidgetVotersModal',
  defaults: {
    loading: true,
    voters: [],
    currentPage: 0,
    last: false
  }
})
export class VotersModalState {

  constructor(private pollWidgetService: PollWidgetService) {
  }

  @Selector()
  static getState(state: VotersModalStateModel): VotersModalStateModel {
    return state;
  }

  @Action(InitializeVoters)
  initVoters(ctx: StateContext<VotersModalStateModel>, action: InitializeVoters): Observable<any> {
    ctx.patchState({
      voters: [],
      loading: true,
      currentPage: 0,
      last: false
    });
    return this.pollWidgetService.getVoters(action.widgetId, action.optionId).pipe(tap(voters => {
      ctx.patchState({
        ...ctx.getState(),
        voters: voters.content,
        loading: false,
        last: voters.last,
        currentPage: voters.number
      });
    }));
  }

  @Action(LoadMoreVoters)
  loadMoreVoters(ctx: StateContext<VotersModalStateModel>, action: LoadMoreVoters): Observable<any> {
    ctx.patchState({
      loading: true
    });
    return this.pollWidgetService.getVoters(action.widgetId, action.optionId, ctx.getState().currentPage + 1)
      .pipe(tap(voters => {
        ctx.patchState({
          ...ctx.getState(),
          voters: [...ctx.getState().voters, ...voters.content],
          loading: false,
          last: voters.last,
          currentPage: voters.number
        });
      }));
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '@core/auth/auth.service';
import {Share} from '@domain/share/share';
import {User} from '@domain/user/user';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {ShareModalComponent} from '@shared/social/shares/share-modal/share-modal.component';
import {NgxPermissionsService} from 'ngx-permissions';
import {from} from 'rxjs';
import {ShareModalData} from '../share-modal/share-modal-data';

interface ShareableSender {
  id: string;
  typeName: string;
  public: boolean;
}

/**
 * Share button for e.g. pages, workspaces and events.
 */
@Component({
  selector: 'coyo-share-nav-bar-button',
  templateUrl: './share-nav-bar-button.component.html',
  styleUrls: ['./share-nav-bar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareNavBarButtonComponent implements OnInit {

  private currentUser: User;

  /**
   * The share target. Can be a page, workspace or event.
   */
  @Input() shareTarget: ShareableSender;

  /**
   * The translation key.
   */
  @Input() translationKey: string;

  constructor(private authService: AuthService,
              private permissionService: NgxPermissionsService,
              private dialog: MatDialog,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.authService.getUser().subscribe(user => this.currentUser = user);
  }

  /**
   * Opens a modal with the share form.
   */
  openShareModal(): void {
    this.dialog.open<ShareModalComponent, ShareModalData>(ShareModalComponent, {
      data: {
        currentUser: this.currentUser,
        parentIsPublic: this.shareTarget.public,
        targetId: this.shareTarget.id,
        typeName: this.shareTarget.typeName,
        canCreateStickyShare: from(this.permissionService.hasPermission('CREATE_STICKY_TIMELINE_ITEM'))
      }
    }).afterClosed().subscribe((createdShare: Share) => {
      if (createdShare) {
        this.notificationService.success('COMMONS.SHARES.SUCCESS');
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {ParticipantStatus} from '@domain/event/participant-status';

@Injectable({
  providedIn: 'root'
})
export class EventStatusIconService {

  constructor() { }

  getStatusIcon(statusKey: ParticipantStatus): string {
    let statusIcon: string;
    switch (statusKey) {
      case 'ATTENDING':
        statusIcon = 'check';
        break;
      case 'PENDING':
        statusIcon = 'bell';
        break;
      case 'MAYBE_ATTENDING':
        statusIcon = 'help';
        break;
      case 'NOT_ATTENDING':
        statusIcon = 'close';
        break;
      default:
        statusIcon = null;
    }
    return statusIcon;
  }
}

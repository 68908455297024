import {BaseDriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/base-drive-file-picker-item';
import {StorageType} from '@domain/attachment/storage';
import {Observable} from 'rxjs';

export class DriveFolderItem implements BaseDriveFilePickerItem {
  readonly id: string;
  readonly name: string;
  readonly isFolder: boolean = true;
  readonly sizeInBytes: number | null = null;
  readonly lastModified: number = null;
  readonly mimeType: string | null = null;
  readonly storageType: StorageType;

  constructor(id: string, name: string, private readonly getChildrenFunc: () => Observable<BaseDriveFilePickerItem[]>) {
    this.id = id;
    this.name = name;
    this.storageType = 'OFFICE_365';
  }

  getChildren(): Observable<BaseDriveFilePickerItem[]> {
    return this.getChildrenFunc();
  }
}

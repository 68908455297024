import {PinchEvent} from '@shared/preview/file-preview/image-container/pinch-event';
import {SwipeEvent} from '@shared/preview/file-preview/image-container/swipe-event';
import {Vec2} from '@shared/preview/file-preview/image-container/vec2';

/**
 * Custom handler for mobile pinch and swipe events
 */
export class PinchHandler {
  private touches: { [key: string]: Vec2 } = {};
  private pinchListeners: Function[] = [];
  private swipeListeners: Function[] = [];

  private static convertToTouchList(list: TouchList): { [key: string]: Vec2 } {
    const result = {};
    for (let i = 0; i < list.length; i++) {
      result[list.item(i).identifier] = new Vec2(list.item(i).clientX, list.item(i).clientY);
    }
    return result;
  }

  private static touchList2Array(list: TouchList): Touch[] {
    const result: Touch[] = [];
    for (let i = 0; i < list.length; i++) {
      result[i] = list.item(i);
    }
    return result;
  }

  /**
   * Call this when a touchstart event is fired
   *
   * @param e The original event
   */
  touchstart(e: any): void {
    PinchHandler.touchList2Array(e.touches).forEach(touch => this.touches[touch.identifier] = new Vec2(touch.clientX, touch.clientY));
  }

  /**
   * Call this when a touchend event is fired (in many cases it is useful to use window:touchend to catch ALL touchend events)
   *
   * @param e The original event
   */
  touchend(e: any): void {
    const newTouches: { [key: string]: Vec2 } = {};
    const ids: string[] = PinchHandler.touchList2Array(e.touches).map(touch => touch.identifier.toString());
    Object.keys(this.touches).forEach(key => {
      if (ids.indexOf(key) < 0) {
        newTouches[key] = this.touches[key];
      }
    });
    this.touches = newTouches;
  }

  /**
   * Call this when a touchmove event is fired
   *
   * @param e The original event
   */
  touchmove(e: any): void {
    const newTouches = PinchHandler.convertToTouchList(e.touches);
    const keys = Object.keys(newTouches);
    if (keys.length === 2 && Object.keys(this.touches).length === 2) {
      if (this.touches[keys[0]] && this.touches[keys[1]]) {
        const oldLocation1 = this.touches[keys[0]];
        const oldLocation2 = this.touches[keys[1]];

        const newLocation1 = newTouches[keys[0]];
        const newLocation2 = newTouches[keys[1]];

        const deltaOld = oldLocation2.subtract(oldLocation1).magnitude;
        const deltaNew = newLocation2.subtract(newLocation1).magnitude;

        const distanceFactor = deltaNew / deltaOld;

        const p: Vec2 = newLocation1.add(newLocation2).scale(0.5);

        this.emitPinch(new PinchEvent(p, deltaNew, deltaOld, deltaNew - deltaOld, distanceFactor));
      }
    } else if (keys.length === 1 && Object.keys(this.touches).length === 1) {
      if (this.touches[keys[0]]) {
        const oldTouch = this.touches[keys[0]];
        const newTouch = newTouches[keys[0]];

        this.emitSwipe(new SwipeEvent(newTouch, oldTouch, newTouch.subtract(oldTouch), newTouch.subtract(oldTouch).magnitude));
      }
    }
    this.touches = newTouches;
  }

  /**
   * Registers a callback for pinch events
   *
   * @param handler The callback function. Will be called with a PinchEvent argument
   */
  onPinch(handler: Function): void {
    this.pinchListeners.push(handler);
  }

  /**
   * Registers a callback for swipe events
   *
   * @param handler The callback function. Will be called with a SwipeEvent argument
   */
  onSwipe(handler: Function): void {
    this.swipeListeners.push(handler);
  }

  private emitSwipe(value: SwipeEvent): void {
    this.swipeListeners.forEach(listener => listener(value));
  }

  private emitPinch(value: PinchEvent): void {
    this.pinchListeners.forEach(listener => listener(value));
  }

}

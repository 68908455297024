import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * The header of the launchpad.
 */
@Component({
  selector: 'coyo-launchpad-header',
  templateUrl: './launchpad-header.component.html',
  styleUrls: ['./launchpad-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadHeaderComponent implements OnInit {
  isXs$: Observable<boolean>;

  /**
   * Flag indicating if the user can add new links.
   */
  @Input() canAddLink: boolean;

  /**
   * Flag indicating if the user has switch on the column layout.
   */
  @Input() useColumns: boolean;

  /**
   * Flag indicating if the user has switch on the condensed view.
   */
  @Input() useCondensed: boolean;

  /**
   * Emits an event when a new link should be created.
   */
  @Output() addLink: EventEmitter<void> = new EventEmitter();

  /**
   * Emits an event when a the user toggles the column view.
   */
  @Output() toggleColumns: EventEmitter<void> = new EventEmitter();

  /**
   * Emits an event when a the user toggles the condensed view.
   */
  @Output() toggleCondensed: EventEmitter<void> = new EventEmitter();

  /**
   * Emits an event when the launchpad was closed.
   */
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(private windowSizeService: WindowSizeService) {
  }

  ngOnInit(): void {
    this.isXs$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS));
  }
}

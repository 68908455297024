import {Component} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldType} from '@ngx-formly/material/form-field';

/**
 * A generic ngx-formly radio button field.
 */
@Component({
  selector: 'coyo-formly-radio',
  template: `
    <label [id]="id + '_label'" class="mat-label">
      {{ to.label }}
      <mat-hint *ngIf="to.description">{{ to.description }}</mat-hint>
    </label>
    <mat-radio-group
      [id]="id"
      [attr.aria-labelledby]="id + '_label'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="to.required">
      <mat-radio-button
        *ngFor="let option of to.options | formlySelectOptions: field | async; let i = index"
        [id]="id + '_' + i"
        [color]="to.color"
        [labelPosition]="to.labelPosition"
        [value]="option.value">
        {{ option.label }}
        <mat-hint *ngIf="to.options[i].description">{{ to.options[i].description }}</mat-hint>
      </mat-radio-button>
    </mat-radio-group>`
})
export class FormlyRadioComponent extends FieldType {

  defaultOptions: FormlyFieldConfig = {
    templateOptions: {
      hideFieldUnderline: true,
      floatLabel: 'always',
      options: []
    }
  };
}

<div (cdkDropListDropped)="moveWidget($event)" (coyoResized)="size$.next($event)"
     [cdkDropListData]="this.getSlotId()"
     [cdkDropListDisabled]="!((editMode$ | async) && !simpleMode)" [ngClass]="{'edit-mode': editMode$ | async}"
     cdkDropList
     class="widget-slot widget-slot widget-slot-{{name}} widget-slot-{{renderStyle}} {{sizeClass$ | async}}">
  <ng-container *ngIf="state$ | async as items">
    <div *ngIf="items.length" [ngClass]="{'panel': renderStyle === 'panel'}">
      <div *ngFor="let item of items; trackBy: trackWidget; let first = first; let last = last"
           cdkDrag class="widget widget-{{item.widget.key}}"
           [cdkDragData]="item.widget.id || item.widget.tempId"
           [cdkDragDisabled]="!embedded"
           [hidden]="isWidgetHidden(item.widget) | async"
           [ngClass]="{
             'printable': item._config?.renderOptions?.printable,
             'hidden-sm-and-down': item.widget.settings._hideMobile && !(editMode$ | async),
             'hover': (hover$ | async) === (item.widget.id || '') + (item.widget.tempId || '')}"
           (mouseenter)="hover$.next((item.widget.id || '') + (item.widget.tempId || ''))"
           (mouseleave)="hover$.next(null)">
        <div *cdkDragPreview class="entity-drag-preview">
          <mat-icon svgIcon="drag-indicator"></mat-icon>
          <span [translate]="item._config.name" class="widget-name"></span>
        </div>
        <div *ngIf="(editMode$ | async) && (item.widget.settings._hideMobile || !visibleOnMobile)" class="mobile-hidden-info">
          <mat-icon svgIcon="mobile-off"></mat-icon>
          <span translate="WIDGETS.MOBILE.HIDDEN"></span>
        </div>
        <div [ngClass]="{'panel-body' : item.hasPanelBody, 'panel': item.hasPanel, 'no-panel': !item.hasPanel}" class="widget-inner">
          <coyo-widget-container *ngIf="item._config" [config]="item._config" [editMode]="editMode$ | async"
                                 [editScope]="editScope"
                                 [slot]="slotId | async" [widget]="item.widget">
          </coyo-widget-container>
          <div *ngIf="(editMode$ | async) && !simpleMode" class="btn-toolbar widget-options">

            <div class="btn-group">
              <button *ngIf="canManage && embedded"
                      [attr.aria-label]="'WIDGET.SLOT.OPTIONS.DRAG_WIDGET' | translate"
                      cdkDragHandle
                      type="button"
                      class="btn btn-xs btn-zmdi option-btn drag hidden-sm hidden-xs">
                <mat-icon svgIcon="drag-indicator"></mat-icon>
              </button>
            </div>
            <div class="btn-group">
              <!-- Widget options -->
              <coyo-widget-inline-settings-container *ngIf="item._config?.inlineOptions"
                                                     [component]="item._config?.inlineOptions.component"
                                                     [editScope]="editScope"
                                                     [slot]="getSlotId()"
                                                     [widget]="item.widget">
              </coyo-widget-inline-settings-container>
              <button (click)="moveUp(item.widget)" *ngIf="!first"
                      [attr.aria-label]="'WIDGET.SLOT.OPTIONS.MOVE_UP' | translate"
                      [ngClass]="{'visible-md visible-lg': !embedded}"
                      class="btn btn-xs btn-zmdi widget-order-up option-btn visible-sm visible-xs">
                <mat-icon svgIcon="chevron-up"></mat-icon>
              </button>
              <button (click)="moveDown(item.widget)" *ngIf="!last"
                      [attr.aria-label]="'WIDGET.SLOT.OPTIONS.MOVE_DOWN' | translate"
                      [ngClass]="{'visible-md visible-lg': !embedded}"
                      class="btn btn-xs btn-zmdi widget-order-down option-btn visible-sm visible-xs">
                <mat-icon svgIcon="chevron-down"></mat-icon>
              </button>
              <button (click)="editTitle(item.widget)" *ngIf="hasTitle(item)"
                      [attr.aria-label]="'WIDGET.SLOT.OPTIONS.EDIT_TITLE' | translate"
                      [class.active]="(editTitleId$ | async) === (item.widget.id || item.widget.tempId)"
                      class="btn btn-xs btn-zmdi widget-rename option-btn">
                <mat-icon svgIcon="title"></mat-icon>
              </button>
              <form
                *ngIf="(editTitleId$ | async) === (item.widget.id || item.widget.tempId) && hasTitle(item)"
                class="widget-input-title">
                <input (change)="renameWidget(item.widget, $event.target['value'])"
                       [value]="item.widget.settings._title || ''"
                       placeholder="{{item._config?.defaultTitle | translate}}"
                       type="text"/>
              </form>
              <button (click)="editWidget(item)" *ngIf="item.canManage && item._config?.settings"
                      [attr.aria-label]="'WIDGET.SLOT.OPTIONS.EDIT_WIDGET' | translate"
                      class="btn btn-xs btn-zmdi widget-edit option-btn">
                <mat-icon svgIcon="settings"></mat-icon>
              </button>
              <button (click)="cutWidget(item.widget)"
                      *ngIf="item.canManage"
                      [attr.aria-label]="'WIDGET.SLOT.OPTIONS.CUT' | translate"
                      class="btn btn-xs btn-zmdi widget-cut option-btn">
                <mat-icon svgIcon="scissors"></mat-icon>
              </button>
              <button (click)="toggleMobile(item.widget)" *ngIf="item.canManage && visibleOnMobile"
                      [attr.aria-label]="'WIDGET.SLOT.OPTIONS.HIDE_MOBILE' | translate"
                      [ngClass]="{'toggled' : item.widget.settings._hideMobile}"
                      class="btn btn-xs btn-zmdi widget-toggle-mobile option-btn">
                <mat-icon svgIcon="mobile-off"></mat-icon>
              </button>
              <button (click)="deleteWidget(item.widget)"
                      *ngIf="item.canManage"
                      [attr.aria-label]="'WIDGET.SLOT.OPTIONS.DELETE_WIDGET' | translate"
                      class="btn btn-xs btn-zmdi option-btn">
                <mat-icon svgIcon="delete"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="item.widget.key==='iframe'">
          <div class="iframe-overlay"></div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div *ngIf="(editMode$ | async) && !simpleMode" class="widget-slot-options">
    <div [ngClass]="{'divider': embedded === false}">
      <button (click)="pasteWidget()" *ngIf="clipboard$ | async"
              [attr.aria-label]="'WIDGET.SLOT.OPTIONS.PASTE_WIDGET' | translate" class="widget-slot-option"
              data-test="widget-add" mat-icon-button size="small">
        <mat-icon svgIcon="paste-circled"></mat-icon>
      </button>
      <button (click)="addWidget()" *ngIf="!(clipboard$ | async)"
              [attr.aria-label]="'WIDGET.SLOT.OPTIONS.ADD_WIDGET' | translate" class="widget-slot-option"
              data-test="widget-paste" mat-icon-button size="small">
        <mat-icon svgIcon="plus-circled"></mat-icon>
      </button>
    </div>
  </div>
</div>

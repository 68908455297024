import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {NgControl} from '@angular/forms';
import {WebPreviewService} from '@domain/preview/web-preview/web-preview.service';

/**
 * Extract url of the host form controll on space, tab or enter key or a paste event
 * and emits an array of the current urls.
 */
@Directive({
  selector: '[coyoExtractUrls]'
})
export class ExtractUrlsDirective {

  /**
   * All urls in the control value
   */
  @Output() urls: EventEmitter<string[]> = new EventEmitter();

  constructor(private control: NgControl, private linkPreviewService: WebPreviewService) {
  }

  /**
   * Extracts the urls from the control value after keydown event of space, enter or tab key.
   *
   * @param $event
   * The keyboard event
   */
  @HostListener('keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    switch ($event.code || $event.keyCode) { // tslint:disable-line:deprecation
      case 9:
      case 'Tab':
      case 13:
      case 'Enter':
      case 32:
      case 'Space':
        this.urls.emit(this.linkPreviewService.extractUrls(this.control.value));
    }
  }

  /**
   * Extracts the urls from the control value after paste event on the host element.
   *
   * @param $event
   * The clipboard event
   */
  @HostListener('paste')
  onPaste(): void {
    // adding a timeout because the content pasted is not accessible yet
    setTimeout(() => this.urls.emit(this.linkPreviewService.extractUrls(this.control.value)));
  }
}

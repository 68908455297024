import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {HydratePollWidget, RemoveVote, Vote} from '@widgets/poll/poll-widget.actions';
import {PollWidgetService} from '@widgets/poll/poll-widget/poll-widget.service';
import * as _ from 'lodash';
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

export interface PollWidgetsStateModel {
  [widgetId: string]: PollWidgetStateModel;
}

export interface PollWidgetStateModel {
  totalVotes: number;
  options: PollWidgetOptionsStateModel[];
  frozen: boolean;
}

export interface PollWidgetOptionsStateModel {
  id: string;
  answer: string;
  answerId?: string;
  votes: number;
  optimistic?: boolean;
}

@Injectable({providedIn: 'root'})
@State<PollWidgetsStateModel>({
  name: 'pollWidget',
  defaults: {}
})
export class PollWidgetsState {

  constructor(private pollWidgetService: PollWidgetService) {

  }

  @Selector()
  static getState(state: PollWidgetsStateModel): PollWidgetsStateModel {
    return state;
  }

  @Action(HydratePollWidget)
  hydrateState(ctx: StateContext<PollWidgetStateModel>, action: HydratePollWidget): Observable<any> {
    const votes$ = action.widgetId && action.loadResults ? this.pollWidgetService.getVotes(action.widgetId) : of([]);
    const answers$ = action.widgetId ? this.pollWidgetService.getSelectedAnswers(action.widgetId) : of([]);
    return forkJoin([votes$, answers$])
      .pipe(tap(([votes, answers]) => {
        const groupedVotes = _.groupBy(votes, 'optionId');
        const groupedAnswers = _.groupBy(answers, 'optionId');
        const totalVotes = _.sum(votes.map((options: any) => options.count));

        const newOptionState = action.options.map(value => {
          const currVotes = _.get(groupedVotes, value.id + '[0].count', 0) as number;
          return {
            id: value.id,
            answer: value.answer,
            answerId: _.get(groupedAnswers, value.id + '[0].id', undefined),
            votes: currVotes
          };
        });

        const result = {};
        result[action.widgetId] = {
          totalVotes: totalVotes,
          options: newOptionState,
          frozen: action.frozen
        };
        ctx.patchState(result);
      }));
  }

  @Action(Vote)
  vote(ctx: StateContext<PollWidgetsStateModel>, action: Vote): Observable<any> {
    this.addVote(ctx, action.widgetId, action.option.id, true, '');
    return this.pollWidgetService.selectAnswer(action.widgetId, action.option.id)
      .pipe(tap({
        next: response => {
          this.substractVote(ctx, action.widgetId, action.option.id, false);
          this.addVote(ctx, action.widgetId, action.option.id, false, response.id);
        }
      }), catchError(() => {
        setTimeout(() => {
          this.substractVote(ctx, action.widgetId, action.option.id, false);
          this.freezePoll(ctx, action.widgetId);
        });
        return EMPTY;
      }));
  }

  private addVote(ctx: StateContext<PollWidgetsStateModel>, widgetId: string, optionId: string, optimistic: boolean, answerId?: string): void {
    const widgetState = ctx.getState()[widgetId];

    const index = widgetState.options.findIndex(value => value.id === optionId);
    const option = {
      ...widgetState.options[index],
      answerId: answerId,
      votes: widgetState.options[index].votes + 1,
      optimistic: optimistic
    };

    const newState = {
      [widgetId]: {
        ...widgetState,
        totalVotes: widgetState.totalVotes + 1,
        options: [...widgetState.options.slice(0, index), option, ...widgetState.options.slice(index + 1)]
      }
    };

    ctx.patchState(newState);
  }

  @Action(RemoveVote)
  removeVote(ctx: StateContext<PollWidgetsStateModel>, action: RemoveVote): Observable<any> {
    this.substractVote(ctx, action.widgetId, action.optionId, true);
    return this.pollWidgetService.deleteAnswer(action.widgetId, action.answerId)
      .pipe(tap({
        next: () => {
          this.addVote(ctx, action.widgetId, action.optionId, false, action.answerId);
          this.substractVote(ctx, action.widgetId, action.optionId, false);
        }
      }), catchError(() => {
        setTimeout(() => {
          this.addVote(ctx, action.widgetId, action.optionId, false, action.answerId);
          this.freezePoll(ctx, action.widgetId);
        });
        return EMPTY;
      }));
  }

  private substractVote(ctx: StateContext<PollWidgetsStateModel>, widgetId: string, optionId: string, optimistic: boolean): void {
    const widgetState = ctx.getState()[widgetId];

    const index = widgetState.options.findIndex(value => value.id === optionId);
    const option = {
      ..._.omit(widgetState.options[index], 'answerId'),
      votes: widgetState.options[index].votes - 1,
      optimistic: optimistic
    };
    const newState: PollWidgetsStateModel = {
      [widgetId]: {
        ...widgetState,
        totalVotes: widgetState.totalVotes - 1,
        options: [...widgetState.options.slice(0, index), option, ...widgetState.options.slice(index + 1)]
      }
    };

    ctx.patchState(newState);
  }

  private freezePoll(ctx: StateContext<PollWidgetsStateModel>, widgetId: string): void {
    const widgetState = ctx.getState()[widgetId];

    const newState: PollWidgetsStateModel = {
      [widgetId]: {
        ...widgetState,
        frozen: true
      }
    };

    ctx.patchState(newState);
  }
}

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorService} from '@core/error/error.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

/**
 * Intercepts errors and checks for the error status code.
 * Displays a graphical message if a status code has been found.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private errorService: ErrorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleErrors = req.headers.get('handleErrors') !== 'false';
    return next.handle(req.clone({headers: req.headers.delete('handleErrors')}))
      .pipe(catchError((err: any) => this.errorService.handleError(err, handleErrors)));
  }
}

import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscribable} from '@domain/subscription/subscribable';
import {Subscription} from '@domain/subscription/subscription';
import {SubscriptionService} from '@domain/subscription/subscription.service';
import {combineLatest, Observable, Subject, Subscription as RxJsSubscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

interface SubscribeButtonState {
  isLoading: boolean;
  isSubscribed: boolean;
}

/**
 * A button to subscribe to an entity.
 */
@Component({
  selector: 'coyo-subscribe-button',
  templateUrl: './subscribe-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscribeButtonComponent implements OnInit, OnDestroy {
  private userSubscription: RxJsSubscription;
  private isLoading: Subject<boolean>;
  private isSubscribed: Subject<boolean>;

  state$: Observable<SubscribeButtonState>;

  /**
   * The subscription target.
   */
  @Input() target: Subscribable;

  constructor(private subscriptionService: SubscriptionService) {}

  ngOnInit(): void {
    this.isLoading = new Subject();
    this.isSubscribed = new Subject();
    this.state$ = combineLatest([
      this.isLoading.asObservable().pipe(startWith(true)),
      this.isSubscribed.asObservable().pipe(startWith(false))
    ]).pipe(map(([isLoading, isSubscribed]) => ({isLoading, isSubscribed})));

    this.userSubscription = this.subscriptionService.onSubscriptionChange$(this.target.id).subscribe({
      next: subscription => this.onNext(subscription),
      error: () => this.onError()
    });
  }

  /**
   * Toggles the subscription state for the component's target.
   *
   * @param state the current button state
   */
  toggle(state: SubscribeButtonState): void {
    if (state.isLoading) {
      return;
    }

    this.isLoading.next(true);
    const senderId = this.target.senderId ? this.target.senderId : this.target.author.id;
    const response: Observable<any> = state.isSubscribed
      ? this.subscriptionService.unsubscribe(this.target.id)
      : this.subscriptionService.subscribe(senderId, this.target.id, this.target.typeName);
    response.subscribe({
      next: subscription => this.onNext(subscription),
      error: () => this.onError()
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  private onNext(subscription: Subscription | Subscription[]): void {
    this.isSubscribed.next(!!subscription);
    this.isLoading.next(false);
  }

  private onError(): void {
    this.isLoading.next(false);
  }
}

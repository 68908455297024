import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {RssWidget} from '@widgets/rss/rss-widget';
import {RssWidgetSettingsComponent} from '@widgets/rss/rss-widget-settings/rss-widget-settings.component';
import {RssWidgetComponent} from '@widgets/rss/rss-widget/rss-widget.component';

export const RSS_WIDGET: WidgetConfig<RssWidget> = {
  key: 'rss',
  name: 'WIDGET.RSS.NAME',
  description: 'WIDGET.RSS.DESCRIPTION',
  icon: 'zmdi-rss',
  defaultTitle: 'WIDGET.RSS.NAME',
  component: RssWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  settings: {
    skipOnCreate: false,
    component: RssWidgetSettingsComponent
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

(function (angular) {
  'use strict';

  angular
      .module('coyo.pages')
      .directive('coyoPageVisibility-old', pageVisibility);

  /**
   * @ngdoc directive
   * @name coyo.pages.coyoPageVisibility:coyoPageVisibility
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Displays the page visibility.
   */
  function pageVisibility() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'app/modules/pages/components/page-visibility/page-visibility.html',
      scope: {
        page: '<?',
        visibility: '@'
      }
    };
  }

})(angular);

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {OFFICE_EXT_APP_MAP} from '@app/file-library/file-library-file-picker-item/office-file-extensions';
import {UrlService} from '@core/http/url/url.service';
import {SocketService} from '@core/socket/socket.service';
import {DialogService} from '@coyo/ui';
import {WebdavLockedMessage} from '@domain/webdav/webdav-locked-message';
import {TranslateService} from '@ngx-translate/core';
import {WINDOW} from '@root/injection-tokens';
import {Observable, Subscription} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';

/**
 * Service responsible for editing internal coyo files with office.
 */
@Injectable({
  providedIn: 'root'
})
export class WebdavService {
  private static url: string = '/web/webdav/{{senderId}}/{{fileId}}';
  private static editUrl: string = '/webdav/{{accessCode}}/{{senderId}}/{{fileId}}/{{fileName}}';

  constructor(private http: HttpClient,
              private urlService: UrlService,
              private dialogService: DialogService,
              private socketService: SocketService,
              private translateService: TranslateService,
              @Inject(WINDOW) private window: Window) {
  }

  /**
   * Requests an access token for editing the file with office.
   *
   * @param senderId The sender id of the file
   * @param fileId The file id
   *
   * @returns The access token
   */
  requestAccessToken(senderId: string, fileId: string): Observable<string> {
    const url = this.urlService.insertPathVariablesIntoUrl(WebdavService.url, {
      senderId: senderId,
      fileId: fileId
    });
    return this.http.post<{
      accessCode: string
    }>(url, {}).pipe(map(body => body.accessCode));
  }

  /**
   * Start the editing process, opens a modal to notify the user and tries to start the matching application.
   *
   * @param webdavToken The access token returned from {@link requestAccessToken}
   * @param senderId The sender id of the file
   * @param fileId The file id
   * @param fileName The name of the file
   * @param extension The file extension
   * @param subscriptionToken The subscription token of the websocket
   *
   * @returns An observable that is completed when the file is locked (hint for the office application to be opened).
   * If there is no file locking within 30 seconds the observable returns an error.
   */
  editFile(webdavToken: string, senderId: string, fileId: string, fileName: string, extension: string, subscriptionToken: string): Observable<void> {
    const dialogRef = this.dialogService.open({
      title: this.translateService.instant('FILE_LIBRARY.EDIT_IN_OFFICE_OPENING.TITLE'),
      text: this.translateService.instant('FILE_LIBRARY.EDIT_IN_OFFICE_OPENING.TEXT')
    });
    let lockInfoSubscription: Subscription;
    return new Observable<void>(subscriber => {
      lockInfoSubscription = this.getLockInfo$(fileId, senderId, subscriptionToken)
        .subscribe(() => {
          subscriber.complete();
        });
      setTimeout(() => {
        if (!subscriber.closed) {
          subscriber.error();
        }
      }, 30000);

      const url = this.generateWebdavHandlerUrl(extension, webdavToken, senderId, fileId, fileName);
      this.window.open(url, '_self');
    }).pipe(tap({
      error: () => {
        this.dialogService.open({
          title: this.translateService.instant('FILE_LIBRARY.EDIT_IN_OFFICE_FAILED.TITLE'),
          text: this.translateService.instant('FILE_LIBRARY.EDIT_IN_OFFICE_FAILED.TEXT'),
        });
      }
    })).pipe(finalize(() => {
      dialogRef.close();
      lockInfoSubscription?.unsubscribe();
    }));
  }

  private generateWebdavHandlerUrl(extension: string, accessToken: string,
                                   senderId: string, fileId: string, fileName: string): string {
    return encodeURI(
      OFFICE_EXT_APP_MAP[extension] +
      ':ofe|u|' +
      this.urlService.getCurrentDomain() +
      this.urlService.insertPathVariablesIntoUrl(WebdavService.editUrl, {
        accessCode: accessToken,
        senderId: senderId,
        fileId: fileId,
        fileName: fileName
      })
    );
  }

  /**
   * Subscribes to the file lock topic.
   *
   * @param fileId The file id
   * @param senderId The sender id of the file
   * @param subscriptionToken The subscription token of the websocket
   *
   * @returns Information if, when and by which user the file was locked.
   */
  getLockInfo$(fileId: string, senderId: string, subscriptionToken: string): Observable<WebdavLockedMessage> {
    return this.socketService.listenTo$('/topic/item.lock', null, fileId, subscriptionToken)
      .pipe(
        map(message => ({
          fileId,
          senderId,
          locked: message.event === 'set'
        })));
  }

  /**
   * Checks if an extension is supported by the office suite.
   * @param extension The file extension
   *
   * @return true if it is supported
   */
  canEditInOffice(extension: string): boolean {
    return !!OFFICE_EXT_APP_MAP[extension];
  }
}

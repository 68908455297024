import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AccessToken} from '@app/integration/integration-api/access-token';
import {ProviderInfo} from '@app/integration/integration-api/provider-info';
import {AuthService} from '@core/auth/auth.service';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';

/**
 * Mapping between “integration_type” and “coyo_backend_feature”
 */
const FeatureIntegrationMapping = {
  OFFICE_365: 'integration_o365',
  G_SUITE: 'integration_gsuite'
};

@Injectable({
  providedIn: 'root'
})
export class IntegrationApiService {
  private TOKEN_ENDPOINT: string = '/web/sso/token';
  private PROVIDER_INFO_ENDPOINT: string = '/web/sso/providerinfo';

  constructor(private readonly http: HttpClient,
              private readonly featureToggleService: FeatureToggleService,
              private readonly authService: AuthService) {
  }

  updateAndGetActiveState(integrationType: string): Observable<boolean> {
    return this.isFeatureAvailable(integrationType)
      .pipe(switchMap(available => available ? this.isUserLoggedInWithOauthAccount() : of(false)),
            catchError(() => of(false)),
            shareReplay({bufferSize: 1, refCount: false}));
  }

  private isFeatureAvailable(integrationType: string): Observable<boolean> {
    const integrationKey = FeatureIntegrationMapping[integrationType] || integrationType;
    return this.featureToggleService.isFeatureAvailable(integrationKey);
  }

  private isUserLoggedInWithOauthAccount(): Observable<boolean> {
    if (!this.authService.isSsoLogin()) {
      return of(false);
    }
    return this.getToken()
    .pipe(
      map(() => true),
      catchError(() => of(false)));
  }

  /**
   * Fetches an OAuth token.
   *
   * @returns an observable of the access token
   */
  getToken(): Observable<AccessToken> {
    const httpOptions = {
      headers: new HttpHeaders({
        handleErrors: 'false'
      })
    };
    return this.http.get<AccessToken>(this.TOKEN_ENDPOINT, httpOptions);
  }

  /**
   * Fetches information about the authentication provider the user is logged in with.
   * @returns an observable with the provider information
   */
  getTokenProviderInfo(): Observable<ProviderInfo> {
    const httpOptions = {
      headers: new HttpHeaders({
        handleErrors: 'false'
      })
    };
    return this.http.get<ProviderInfo>(this.PROVIDER_INFO_ENDPOINT, httpOptions);
  }
}

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

/**
 * Loads the external script for the angular elements components.
 */
@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  loaded: { [key: string]: ReplaySubject<boolean> } = {};

  /**
   * Loads an external script and appends it to the body.
   *
   * @param ident The script to load via eureka.
   * @returns An observable that completes when the script has been loaded.
   */
  provideExternalComponents(ident: string): Observable<boolean> {
    if (!this.loaded[ident] || this.loaded[ident].hasError) {
      this.loaded[ident] = new ReplaySubject<boolean>(1);
      const scriptLoaderScript = this.document.createElement('script');
      scriptLoaderScript.src = `/web/${ident}/main.js`;
      scriptLoaderScript.onload = () => {
        this.loaded[ident].next(true);
        this.loaded[ident].complete();
      };
      scriptLoaderScript.onerror = () => {
        this.loaded[ident].error(new Error('Failed to load external components'));
        this.document.body.removeChild(scriptLoaderScript);
      };
      this.document.body.appendChild(scriptLoaderScript);
    }
    return this.loaded[ident].asObservable();
  }
}

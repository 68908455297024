import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LikeTargetState} from '@domain/like/like-target-state';
import {LikeState} from '@domain/like/like.state';
import {Likeable} from '@domain/like/likeable';
import {SubscribeLikeInfo, ToggleLike, UnsubscribeLikeInfo, UpdateSenderIdForTarget} from '@domain/like/likes.actions';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Component rendering the like button
 */
@Component({
  selector: 'coyo-like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikeButtonComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The id of the sender to whom the like state should be related
   */
  @Input() senderId: string;

  /**
   * The like target
   */
  @Input() target: Likeable;

  /**
   * Flag to render information as text (no icons)
   */
  @Input() textual: boolean;

  /**
   * The like state of the target
   */
  likeState$: Observable<LikeTargetState>;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new SubscribeLikeInfo(this.senderId, this.target));
    this.likeState$ = this.store
      .select(LikeState.likesByTarget)
      .pipe(
        map(filterFn => filterFn(this.target.id))
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.senderId) {
      this.store.dispatch(new UpdateSenderIdForTarget(changes.senderId.currentValue, this.target));
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UnsubscribeLikeInfo(this.target));
  }

  /**
   * Called when the like button is clicked.
   */
  toggle(): void {
    this.store.dispatch(new ToggleLike(this.senderId, this.target));
  }
}

import {NgModule} from '@angular/core';
import {FormlyModule as FormlyCoreModule} from '@ngx-formly/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {FormlyModule} from '@shared/formly/formly.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {IframeModule} from '@shared/iframe/iframe.module';
import {WIDGET_DYNAMIC_CONFIG_SERVICES} from '@widgets/api/widget-config';
import {PluginHeightEventHandlerService} from '@widgets/plugin/plugin-event-handler/plugin-height-event-handler/plugin-height-event-handler.service';
import {PLUGIN_EVENT_HANDLERS} from './plugin-event-handler/plugin-event-handler';
import {PluginInitEventHandlerService} from './plugin-event-handler/plugin-init-event-handler/plugin-init-event-handler.service';
import {PluginWidgetConfigService} from './plugin-widget-config.service';
import {PluginWidgetSettingsComponent} from './plugin-widget-settings/plugin-widget-settings.component';
import {PluginWidgetComponent} from './plugin-widget/plugin-widget.component';

/**
 * Module for the plugin widget
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    FormlyCoreModule,
    FormlyModule,
    HelpModule,
    IframeModule
  ],
  declarations: [
    PluginWidgetComponent,
    PluginWidgetSettingsComponent
  ],
  providers: [
    {provide: PLUGIN_EVENT_HANDLERS, useClass: PluginInitEventHandlerService, multi: true},
    {provide: PLUGIN_EVENT_HANDLERS, useClass: PluginHeightEventHandlerService, multi: true},
    {provide: WIDGET_DYNAMIC_CONFIG_SERVICES, useClass: PluginWidgetConfigService, multi: true}
  ]
})
export class PluginWidgetModule {
}

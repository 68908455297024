(function (angular) {
  'use strict';

  ChampionshipAppGamesController.$inject = ["Pageable", "GameModel", "app", "moment", "$state", "$rootScope", "$q", "$scope"];
  angular
      .module('coyo.apps.championship')
      .controller('ChampionshipAppGamesController', ChampionshipAppGamesController);

  function ChampionshipAppGamesController(Pageable, GameModel, app, moment, $state, $rootScope,
                                          $q, $scope) {
    var vm = this;

    vm.$onInit = onInit;
    vm.isLocked = isLocked;
    vm.submitGuess = submitGuess;
    vm.onChange = onChange;

    function onInit() {
      vm.loading = true;

      $scope.$watch(function () {
        return _.get(vm, 'page.number');
      }, function () {
        _(vm).get('page.content', []).forEach(function (game) {
          _.set(game, 'guess._homeScore', _.get(game, 'guess.homeScore', null));
          _.set(game, 'guess._awayScore', _.get(game, 'guess.awayScore', null));
        });
      });

      var pageable = new Pageable(0, 12);
      return GameModel.pagedQuery(pageable, {past: _.endsWith($state.current.name, '.past')}, {
        senderId: app.senderId,
        appId: app.id
      }).then(function (data) {
        vm.page = data;
        groupGames(vm.page);
      }).finally(function () {
        vm.loading = false;
      });
    }

    function onChange(page) {
      return page.page(page._queryParams._page).then(groupGames);
    }

    function groupGames(page) {
      vm.groupedGames = _.groupBy(page.content, function (game) {
        return moment(game.date).startOf('day').format($rootScope.dateFormat.long);
      });
      vm.dates = _.keys(vm.groupedGames);
    }

    function submitGuess(game) {
      var homeScore = _.get(game, 'guess._homeScore', null);
      var awayScore = _.get(game, 'guess._awayScore', null);
      if (isEmpty(homeScore) === isEmpty(awayScore)
          && (homeScore !== _.get(game, 'guess.homeScore', null)
              || awayScore !== _.get(game, 'guess.awayScore', null))) {
        delete game.saved;
        GameModel.fromApp(app, game).submitGuess(homeScore, awayScore).then(function (data) {
          angular.extend(game.guess, data);
          game.saved = true;
        }).catch(function () {
          game.saved = false;
        });
      }
    }

    function isLocked(game) {
      return angular.isNumber(game.homeScore)
          || angular.isNumber(game.awayScore)
          || moment().isAfter(game.date);
    }

    function isEmpty(value) {
      return angular.isUndefined(value) || value === null;
    }
  }

})(angular);

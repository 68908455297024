import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Facegame, RankingEntry} from '@widgets/facegame/facegame-widget';
import {Observable} from 'rxjs';

/**
 * Service for exposing the backend url and manipulating given urls
 */
@Injectable({
  providedIn: 'root'
})
export class FacegameService {

  constructor(private http: HttpClient) {
  }

  protected getBaseUrl(): string {
    return '/web/widgets/facegame/';
  }

  startGame(widgetId: string): Observable<Facegame> {
    return this.http.post<Facegame>(this.getBaseUrl() + widgetId + '/start', {});
  }

  answer(facegameId: string, userId: string): Observable<Facegame> {
    return this.http.post<Facegame>(this.getBaseUrl() + facegameId + '/answer', {guessedUserId: userId});
  }

  getAvatarTeasers(widgetId: string, pageable: Pageable): Observable<Set<string>> {
    return this.http.get<Set<string>>(this.getBaseUrl() + widgetId + '/avatar-teasers', {
      params: pageable.toHttpParams()
    });
  }

  getRanking(widgetId: string, pageable: Pageable): Observable<Page<RankingEntry>> {
    return this.http.get<Page<RankingEntry>>(this.getBaseUrl() + widgetId + '/ranking', {
      params: pageable.toHttpParams()
    });
  }
}

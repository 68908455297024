import {ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {LinkPreview} from '@domain/preview/link-preview';
import {VideoPreview} from '@domain/preview/video-preview';
import {WebPreview} from '@domain/preview/web-preview';
import {WebPreviewService} from '@domain/preview/web-preview/web-preview.service';
import * as _ from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';

interface WebPreviews {
  linkPreviews?: LinkPreview[];
  videoPreviews?: VideoPreview[];
}

const valueAccessor = {
  provide: NG_VALUE_ACCESSOR,
  multi: true,
  useExisting: forwardRef(() => PreviewListComponent)  // tslint:disable-line:no-use-before-declare
};

/**
 * Form Control taking urls and renders the link and video previews for this urls
 */
@Component({
  selector: 'coyo-preview-list',
  templateUrl: './preview-list.component.html',
  styleUrls: ['./preview-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor]
})
export class PreviewListComponent implements OnChanges, ControlValueAccessor {

  /**
   * The urls to be previewed
   */
  @Input() urls: string[];

  linkPreviews$: Subject<LinkPreview[]> = new Subject<LinkPreview[]>();

  videoPreviews$: Subject<VideoPreview[]> = new Subject<VideoPreview[]>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private fetchedUrls: string[] = [];

  private errorUrls: string[] = [];

  private linkPreviewsBuffer: LinkPreview[] = [];

  private videoPreviewsBuffer: VideoPreview[] = [];

  private ignoredPreviews: WebPreview[] = [];

  private onChange: (previews: WebPreviews) => void;

  constructor(private webPreviewService: WebPreviewService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.fetchWebPreviews();
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(obj: WebPreviews): void {
    this.reset();
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: (previews: WebPreviews) => void): void {
    this.onChange = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: (previews: WebPreviews) => void): void {
  }

  /**
   * Removes a link preview from the list and emits an change event
   *
   * @param preview
   * The link preview
   */
  onLinkPreviewDeleted(preview: LinkPreview): void {
    this.ignoredPreviews.push(preview);
    this.removeIgnoredPreviews();
    this.linkPreviews$.next(this.linkPreviewsBuffer);
    this.onChange({videoPreviews: this.videoPreviewsBuffer, linkPreviews: this.linkPreviewsBuffer});
  }

  /**
   * Removes a video preview from the list and emits an change event
   *
   * @param preview
   * The video preview
   */
  onVideoPreviewDeleted(preview: VideoPreview): void {
    this.ignoredPreviews.push(preview);
    this.removeIgnoredPreviews();
    this.videoPreviews$.next(this.videoPreviewsBuffer);
    this.onChange({videoPreviews: this.videoPreviewsBuffer, linkPreviews: this.linkPreviewsBuffer});
  }

  private reset(): void {
    this.fetchedUrls = [];
    this.urls = [];
    this.linkPreviewsBuffer = [];
    this.videoPreviewsBuffer = [];
    this.videoPreviews$.next([]);
    this.linkPreviews$.next([]);
  }

  private removeIgnoredPreviews(): void {
    this.linkPreviewsBuffer = _.filter(this.linkPreviewsBuffer,
      preview => this.ignoredPreviews.indexOf(preview) === -1);
    this.videoPreviewsBuffer = _.filter(this.videoPreviewsBuffer,
      preview => this.ignoredPreviews.indexOf(preview) === -1);
  }

  private fetchWebPreviews(): void {
    const newUrls = _.difference(this.urls, [...this.fetchedUrls, ...this.errorUrls]);
    if (newUrls.length) {
      this.loading$.next(true);
      this.webPreviewService.generateWebPreviews(newUrls).subscribe(previews => {
        this.loading$.next(false);
        this.emitLinkPreviews(previews, this.urls);
        this.emitVideoPreviews(previews, this.urls);
        this.onChange({videoPreviews: this.videoPreviewsBuffer, linkPreviews: this.linkPreviewsBuffer});
      }, () => {
        this.loading$.next(false);
        this.fetchedUrls = _.difference(this.fetchedUrls, newUrls);
        this.errorUrls = _.concat(_.difference(newUrls, this.fetchedUrls), this.errorUrls);
      });
    }
    this.fetchedUrls = this.fetchedUrls.concat(newUrls);
  }

  private emitLinkPreviews(previews: WebPreview[], urls: string[]): void {
    this.linkPreviewsBuffer = this.linkPreviewsBuffer
      .concat(_(previews).filter(preview => preview.type === 'LINK')
        .map(preview => preview as LinkPreview).value());

    this.setPositions(this.linkPreviewsBuffer, urls);
    this.linkPreviews$.next(this.linkPreviewsBuffer);
  }

  private emitVideoPreviews(previews: WebPreview[], urls: string[]): void {
    this.videoPreviewsBuffer = this.videoPreviewsBuffer
      .concat(_(previews).filter(preview => preview.type === 'VIDEO')
        .map(preview => preview as VideoPreview).value());

    this.setPositions(this.linkPreviewsBuffer, urls);
    this.videoPreviews$.next(this.videoPreviewsBuffer);
  }

  private setPositions(previews: WebPreview[], urls: string[]): void {
    previews.forEach(preview => preview.position = urls.indexOf(preview.url));
  }
}

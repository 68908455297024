(function (angular) {
  'use strict';

  registerOauth2.$inject = ["authenticationProviderTypeRegistryProvider"];
  angular
      .module('coyo.admin.authenticationProviders.oauth2', [
        'coyo.base',
        'coyo.domain',
        'coyo.admin.authenticationProviders.api'
      ])
      .constant('OAUTH2_PRESETS', {
        gSuite: {
          mappingId: 'email',
          clientId: '',
          clientSecret: '',
          authenticationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
          tokenEndpoint: 'https://www.googleapis.com/oauth2/v4/token',
          userInfoEndpoint: 'https://www.googleapis.com/oauth2/v3/userinfo',
          scope: 'email profile openid',
          authenticationScheme: 'header',
          clientAuthenticationScheme: 'header'
        },
        gSuiteIntegration: {
          mappingId: 'email',
          clientId: '',
          clientSecret: '',
          authenticationEndpoint: 'https://accounts.google.com/o/oauth2/auth?access_type=offline&prompt=consent',
          tokenEndpoint: 'https://www.googleapis.com/oauth2/v4/token',
          userInfoEndpoint: 'https://www.googleapis.com/oauth2/v3/userinfo',
          scope: 'email profile openid https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/calendar.events',
          authenticationScheme: 'header',
          clientAuthenticationScheme: 'header'
        },
        office365: {
          mappingId: 'mail',
          clientId: '',
          clientSecret: '',
          authenticationEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
          tokenEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
          userInfoEndpoint: 'https://graph.microsoft.com/v1.0/me',
          scope: 'openid https://graph.microsoft.com/User.Read',
          authenticationScheme: 'header',
          clientAuthenticationScheme: 'form'
        },
        office365Integration: {
          mappingId: 'mail',
          clientId: '',
          clientSecret: '',
          authenticationEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
          tokenEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
          userInfoEndpoint: 'https://graph.microsoft.com/v1.0/me',
          scope: 'openid offline_access https://graph.microsoft.com/User.Read https://graph.microsoft.com/Calendars.ReadWrite Sites.Read.All Mail.Read',
          authenticationScheme: 'header',
          clientAuthenticationScheme: 'form'
        },
        facebook: {
          mappingId: 'email',
          clientId: '',
          clientSecret: '',
          authenticationEndpoint: 'https://www.facebook.com/dialog/oauth',
          tokenEndpoint: 'https://graph.facebook.com/oauth/access_token',
          userInfoEndpoint: 'https://graph.facebook.com/me',
          scope: 'email',
          authenticationScheme: 'query',
          clientAuthenticationScheme: 'form'
        },
        empty: {
          mappingId: '',
          clientId: '',
          clientSecret: '',
          authenticationEndpoint: '',
          tokenEndpoint: '',
          userInfoEndpoint: '',
          scope: '',
          authenticationScheme: 'header',
          clientAuthenticationScheme: 'header'
        }
      })
      .config(registerOauth2);

  function registerOauth2(authenticationProviderTypeRegistryProvider) {
    authenticationProviderTypeRegistryProvider.register({
      key: 'oauth2',
      name: 'ADMIN.AUTHENTICATION.OAUTH2.NAME',
      description: 'ADMIN.AUTHENTICATION.OAUTH2.DESCRIPTION',
      directive: 'oyoc-oauth2-settings',
      editSlug: true
    });
  }

})(angular);

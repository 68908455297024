import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AccessToken} from '@app/integration/integration-api/access-token';
import {GeoLocation} from '@app/integration/integration-api/geo-location';
import {IntegrationApiService} from '@app/integration/integration-api/integration-api.service';
import {Site} from '@app/integration/o365/o365-api/domain/site';
import {SiteCollection} from '@app/integration/o365/o365-api/domain/site-collection';
import {ErrorService} from '@core/error/error.service';
import * as _ from 'lodash';
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import {catchError, first, flatMap, map, mergeMap, shareReplay, switchMap} from 'rxjs/operators';

export type apiVersion = 'v1.0' | 'beta';

export const V1: apiVersion = 'v1.0';
export const BETA: apiVersion = 'beta';

/**
 * This service makes calls to the Microsoft GraphAPI
 */
@Injectable({
  providedIn: 'root'
})
export class GraphApiService {
  private static readonly GRAPH_URL: string = 'https://graph.microsoft.com/';
  private static readonly GEO_LOCATION_ENDPOINT: string = '/sites?filter=siteCollection/root ne null&select=webUrl,siteCollection';
  private static readonly BATCH_REQUEST_LIMIT: number = 15;

  private geo_locations_cache$: Observable<SiteCollection[]>;

  constructor(private integrationApiService: IntegrationApiService, private httpClient: HttpClient, private errorService: ErrorService) {
  }

  /**
   * Creates a GET request to the Microsoft GraphAPI and adds the needed headers
   * @param endpoint the endpoint of the GraphAPI (with leading slash)
   * @param version the version of the GraphAPI to query against
   * @param completeResult the flag to get complete response from Api
   * @param headers a set of additional request headers
   * @returns an Observable with the data in the defined type
   */
  get<T>(endpoint: string, version: apiVersion = V1, completeResult: boolean = false, headers: {[header: string]: string} = {}): Observable<T> {
    return this.requestApiToken().pipe(
      flatMap(token => {
        const url = GraphApiService.GRAPH_URL + version + endpoint;
        const options = {headers: {Authorization: 'Bearer ' + token.token, ...headers}};
        return this.httpClient.get<{ value: T }>(url, options);
      }),
      map((response: any) => completeResult ? response : (response.value ? response.value : response))
    );
  }

  /**
   * Creates batch request including multiple GET request to the Microsoft GraphAPI and adds the needed headers.
   * Microsoft only allows batch requests with up to 20 items, so the requests are chunked, sent in several requests
   * and results joined again.
   * @param endpoints the endpoints of the GraphAPI (with leading slash)
   * @param version the version of the GraphAPI to query against
   * @returns an Observable with the data in the defined type.
   *          Each result has the same index as the corresponding endpoint.
   *          If one request failed, its response will be null.
   */
  getBatch<T>(endpoints: string[], version: apiVersion = V1): Observable<T[]> {
    if (endpoints.length === 0) {
      return of([]);
    }
    const batchChunks: string[][] = _.chunk(endpoints, GraphApiService.BATCH_REQUEST_LIMIT);

    return this.integrationApiService.getToken().pipe(
      flatMap(token => {
        const url = GraphApiService.GRAPH_URL + version + '/$batch';
        const options = {headers: {Authorization: 'Bearer ' + token.token}};
        return forkJoin(batchChunks.map(endpointsChunk =>
          this.httpClient.post<BatchResponse<T>>(url, {
            requests: endpointsChunk.map((endpoint, index) => ({
              id: index,
              method: 'GET',
              url: endpoint
            }))
          }, options).pipe(
            map(batchResponse => this.sortResponsesById(batchResponse.responses)),
            map(responses => responses.map(response => this.isSuccessful(response) ? response.body : null))
          ))).pipe(map(results => results.reduce((all, item) => all.concat(item), [])));
      })
    );
  }

  /**
   * Determines all root sites - either through configuration, or querying a multi geo setup (in a non-geo-aware setup this will only return one root site).
   * Executes a Microsoft GraphAPI search query against all (configured) root sites and combines the results.
   * @param query the query to search for
   * @returns an Observable with the data in the defined type
   */
  searchInMultiGeoLocations(query: string): Observable<Site[]> {
    return this.requestApiToken().pipe(
      flatMap(token =>
        this.getGeoLocations(token).pipe(
          mergeMap((locationResponse: SiteCollection[]) =>
            forkJoin(locationResponse.map(siteCollection => {
              const locationUrl = GraphApiService.GRAPH_URL + V1 + `/sites/${siteCollection.hostname}/sites?search=${query}`;
              const options = {headers: {Authorization: 'Bearer ' + token.token}};
              return this.httpClient.get<{ value: Site[] }>(locationUrl, options).pipe(
                catchError(() => EMPTY),
                map((response: { value: Site[] }) => response.value));
            })).pipe(map(results => results.reduce((all, item) => all.concat(item), []))))))
    );
  }

  private sortResponsesById<T>(responses: Response<T>[]): Response<T>[] {
    return responses.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
  }

  private isSuccessful<T>(response: Response<T>): boolean {
    return response.status >= 200 && response.status < 400;
  }

  private requestApiToken(): Observable<AccessToken> {
    return this.integrationApiService.getToken().pipe(
      catchError(err => this.errorService.handleError(new HttpErrorResponse({
        status: 403,
        url: err.url,
        headers: err.headers,
        statusText: 'Forbidden. Token to access resource not found.'
      }), true)));
  }

  private getGeoLocations(token: AccessToken): Observable<SiteCollection[]> {
    if (!this.geo_locations_cache$) {
      this.geo_locations_cache$ = this.requestGeoLocations(token).pipe(shareReplay(1));
    }
    return this.geo_locations_cache$.pipe(first());
  }

  private requestGeoLocations(accessToken?: AccessToken): Observable<SiteCollection[]> {
    return this.integrationApiService.getTokenProviderInfo().pipe(
      catchError(() => of({preferredGeoLocations: new Array<GeoLocation>()})),
      switchMap(providerinfo => providerinfo && providerinfo.preferredGeoLocations && providerinfo.preferredGeoLocations.length ?
        of(providerinfo.preferredGeoLocations.map(geoLocation => ({
          hostname: geoLocation.hostname, dataLocationCode: geoLocation.code} as SiteCollection)))
        : this.requestGeoLocationRootSites(accessToken))
    );
  }

  private requestGeoLocationRootSites(accessToken?: AccessToken): Observable<SiteCollection[]> {
    const tokenObservable: Observable<AccessToken> = accessToken ? of(accessToken) : this.requestApiToken();
    return tokenObservable.pipe(
      flatMap(token => {
        const url = GraphApiService.GRAPH_URL + BETA + GraphApiService.GEO_LOCATION_ENDPOINT;
        const options = {headers: {Authorization: 'Bearer ' + token.token}};
        return this.httpClient.get<{ value: Site[] }>(url, options).pipe(
          map(result => result.value.map(site => site.siteCollection)));
      }));
  }
}

interface BatchResponse<T> {
  responses: Response<T>[];
}

interface Response<T> {
  id: string;
  status: number;
  body: T;
}

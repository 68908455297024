import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IndividualAppSettingsComponent} from '@apps/api/individual-app-settings/individual-app-settings.component';
import {ADMIN_VALUE, TimelineAppSettings, VIEWER_VALUE} from '@apps/timeline/timeline-app.settings';

/**
 * The latest-blog-articles widget settings component.
 */
@Component({
  selector: 'coyo-timeline-app-settings',
  templateUrl: './timeline-app-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineAppSettingsComponent extends IndividualAppSettingsComponent<TimelineAppSettings>
  implements OnInit {

  constructor() {
    super();
  }

  get viewerValue(): string {
    return VIEWER_VALUE;
  }

  get adminValue(): string {
    return ADMIN_VALUE;
  }

  ngOnInit(): void {
    const authorType = this.app?.settings?.authorType ?? this.viewerValue;

    this.parentForm.addControl('authorType', new FormControl(authorType));
  }
}

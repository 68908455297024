import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {DialogService, Skeleton} from '@coyo/ui';
import {Plugin} from '@domain/plugin/plugin';
import {PluginService} from '@domain/plugin/plugin.service';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {catchError, filter, first, map, pairwise, startWith} from 'rxjs/operators';
import {PluginUrlModalComponent} from '../plugin-url-modal/plugin-url-modal.component';
import {PluginAdminService} from '../plugin/plugin-admin.service';

interface PluginWithConfig {
  plugin: Plugin;
  form: FormGroup;
  formValid: Subject<boolean>;
  config: { [key: string]: any };
}

/**
 * A list of widget plug-ins.
 */
@Component({
  selector: 'coyo-widget-plugin-list',
  templateUrl: './widget-plugin-list.component.html',
  styleUrls: ['./widget-plugin-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetPluginListComponent implements OnInit {
  plugins$: BehaviorSubject<PluginWithConfig[]>; // all plugins along with their configuration
  updating$: BehaviorSubject<string[]>; // list of plugins that are currently being updated
  removing$: BehaviorSubject<string[]>; // list of plugins that are currently being removed

  readonly skeletons: Skeleton[] = [
    {top: 0, left: 0, width: '40%', height: 16},
    {top: 25, left: 0, width: 64, height: 14},
    {top: 25, left: 72, width: 'calc(30% - 72px)', height: 14}
  ];

  constructor(private dialog: MatDialog,
              private toastr: ToastrService,
              private dialogService: DialogService,
              private translateService: TranslateService,
              private pluginService: PluginService,
              private pluginAdminService: PluginAdminService) {
  }

  ngOnInit(): void {
    this.plugins$ = new BehaviorSubject<PluginWithConfig[]>(null);
    this.pluginService.getAll()
      .pipe(startWith([] as Plugin[]))
      .pipe(pairwise())
      .pipe(map(([oldPlugins, newPlugins]) => newPlugins.map(
        newPlugin => this.enrich(newPlugin, _.find(oldPlugins, {id: newPlugin.id})))))
      .subscribe(plugins => this.plugins$.next(plugins));
    this.updating$ = new BehaviorSubject<string[]>([]);
    this.removing$ = new BehaviorSubject<string[]>([]);
  }

  add(): void {
    this.dialog.open<PluginUrlModalComponent, any, Plugin>(PluginUrlModalComponent, {
      autoFocus: true
    }).afterClosed()
      .pipe(filter(plugin => !!plugin))
      .subscribe(plugin => {
        this.plugins$.next([...this.plugins$.value, this.enrich(plugin)]);
        this.toastr.success(
          this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.ADD.SUCCESS.MESSAGE'),
          this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.ADD.SUCCESS.TITLE'));
      });
  }

  setEnabled(plugin: Plugin, change: MatCheckboxChange): void {
    this.pluginAdminService.setEnabled(plugin.id, change.checked)
      .pipe(map(p => p.enabled))
      .pipe(catchError(() => of(!change.source.checked)))
      .subscribe(v => change.source.checked = v);
  }

  setRestricted(plugin: Plugin, change: MatCheckboxChange): void {
    this.pluginAdminService.setRestricted(plugin.id, change.checked)
      .pipe(map(p => p.restricted))
      .pipe(catchError(() => of(!change.source.checked)))
      .subscribe(v => change.source.checked = v);
  }

  update(plugin: Plugin, config: { [key: string]: any }): void {
    this.setProgress(plugin, this.updating$, true);
    this.pluginAdminService.setConfig(plugin.id, config).subscribe(
      () => {
        this.updateFormValid(plugin);
        this.setProgress(plugin, this.updating$, false);
        this.toastr.success(
          this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.UPDATE.SUCCESS.MESSAGE'),
          this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.UPDATE.SUCCESS.TITLE'));
      }, () => this.setProgress(plugin, this.updating$, false));
  }

  remove(plugin: Plugin): void {
    this.dialogService.open<boolean>({
      title: this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.REMOVE.CONFIRM.TITLE'),
      text: this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.REMOVE.CONFIRM.TEXT'),
      renderClose: false,
      actions: [{
        label: this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.REMOVE.CONFIRM.SUBMIT'),
        color: 'warn',
        action: ref => ref.close(true)
      }, {
        label: this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.REMOVE.CONFIRM.CANCEL'),
        action: ref => ref.close(false)
      }]
    }).afterClosed().pipe(filter(result => result)).subscribe(() => {
      this.setProgress(plugin, this.removing$, true);
      this.pluginAdminService.remove(plugin.id).subscribe(() => {
        this.plugins$.next(_.filter(this.plugins$.value, plugins => plugins.plugin.id !== plugin.id));
        this.setProgress(plugin, this.removing$, false);
        this.toastr.success(
          this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.REMOVE.SUCCESS.MESSAGE'),
          this.translateService.instant('ADMIN.APPS_WIDGETS.PLUGINS.REMOVE.SUCCESS.TITLE'));
      }, () => this.setProgress(plugin, this.removing$, false));
    });
  }

  trackById(index: number, item: PluginWithConfig): string {
    return item.plugin.id;
  }

  private enrich(newPlugin: Plugin, oldPlugin?: Plugin): PluginWithConfig {
    const form = new FormGroup({});
    const formValid = new BehaviorSubject<boolean>(true);

    form.statusChanges
      .pipe(first())
      .subscribe(status => formValid.next(status === 'VALID'));

    return {
      form, formValid,
      plugin: newPlugin,
      config: {...oldPlugin?.config, ...newPlugin.config}
    };
  }

  private setProgress(plugin: Plugin, subject: BehaviorSubject<string[]>, value: boolean): void {
    subject.next(value
      ? this.updating$.value.concat(plugin.id)
      : this.updating$.value.filter(id => id !== plugin.id));
  }

  private updateFormValid(plugin: Plugin): void {
    const value = _.find(this.plugins$.value, plugins => plugins.plugin.id === plugin.id);
    if (value) {
      value.formValid.next(value.form.valid);
    }
  }
}

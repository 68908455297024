import {Injectable, NgZone} from '@angular/core';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {DEFAULT_FILE_LIBRARY_DATA, FileLibraryFilePickerData} from '@app/file-library/file-library-file-picker-service/file-library-file-picker-data';
import {FileLibraryRootFilePickerItem} from '@app/file-library/file-library-root-file-picker-item/file-library-root-file-picker-item';
import {SenderRootFilePickerItem} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item';
import {FilePickerService} from '@app/file-picker/file-picker.service';
import {AuthService} from '@core/auth/auth.service';
import {Document} from '@core/domain/file/document';
import {FileService} from '@domain/file/file/file.service';
import {Sender} from '@domain/sender/sender';
import {Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

/**
 * Service for showing the file library in the file picker
 */
@Injectable({
  providedIn: 'root'
})
export class FileLibraryFilePickerService {

  constructor(private filepickerService: FilePickerService,
              private authService: AuthService,
              private ngZone: NgZone,
              private fileService: FileService) {
  }

  /**
   * Opens a file library file picker
   * @param sender The owner of the file library to show
   * @param data The options for the file library
   *
   * @returns selected documents
   */
  open(sender: Sender, data?: FileLibraryFilePickerData): Observable<Document[]> {
    const options = {...DEFAULT_FILE_LIBRARY_DATA, ...data};
    return this.authService.getUser()
      .pipe(switchMap(user =>
        this.filepickerService.openFilePicker({
          rootFolder: new FileLibraryRootFilePickerItem(),
          firstOpenFolder: options?.initialFolder?.id ? new FileLibraryFilePickerItem(data.initialFolder) : new SenderRootFilePickerItem(sender ?? user),
          selectedFiles: options.selectedFiles.map(document => new FileLibraryFilePickerItem(document)),
          selectionType: options.selectionType,
          selectionMode: options.selectionMode,
          contentTypes: options.contentTypes,
          cropSettings: options.cropSettings,
          upload: options.upload
        })))
      .pipe(filter(items => !!items))
      .pipe(map(items => items.map(item => {
          if (item instanceof FileLibraryFilePickerItem) {
            return item.file;
          } else {
            return null;
          }
        }).filter(item => item)
      ));
  }

  /**
   * Function for opening the modal from AngularJS code.
   * Wraps the open call in the ngZone and returns a promise.
   *
   * @param sender The owner of the file library to show
   * @param data The options for the file library
   *
   * @returns selected documents
   */
  openLegacy(sender: Sender, data?: FileLibraryFilePickerData): Promise<Document[]> {
    const fileRequest = data.initialFolder ? this.fileService.getFile(data.initialFolder.id, sender.id, ['*']) : of(null);
    const contentTypes = data.contentTypes || [];
    return this.ngZone.run(() =>
      fileRequest
        .pipe(map(initialFolder =>
          ({
            ...data,
            initialFolder,
            // tslint:disable-next-line:deprecation
            contentTypes: data.filterContentType ? [...contentTypes, data.filterContentType] : contentTypes
          })))
        .pipe(switchMap(options => this.open(sender, options)))
        .pipe(map(document => ({...document})))
        .toPromise());
  }
}

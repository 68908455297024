import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

/**
 * A save indicator that can be placed in material form fields as
 * as a suffix element. Note that the input of this component is
 * a request that this component will automatically subscribe to.
 */
@Component({
  selector: 'coyo-save-indicator',
  templateUrl: './save-indicator.component.html',
  styleUrls: ['./save-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveIndicatorComponent implements OnChanges {
  status$: Observable<'SUCCESS' | 'ERROR'> | null = null;

  /**
   * The status indicator.
   */
  @Input() request: Observable<any>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.request.currentValue) {
      this.status$ = changes.request.currentValue
        .pipe(map(() => 'SUCCESS'))
        .pipe(catchError(() => of('ERROR')));
    } else {
      this.status$ = null;
    }
  }
}

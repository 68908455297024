(function (angular) {
  'use strict';

  SenderNavigationController.$inject = ["$scope", "$rootScope", "$stateParams", "appRegistry", "appService", "appTranslationsModalService", "senderNavigationUpdateService", "SenderModel", "$timeout", "$injector"];
  angular
      .module('commons.ui')
      .directive('coyoSenderNavigation', senderNavigation)
      .controller('SenderNavigationController', SenderNavigationController);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoSenderNavigation:coyoSenderNavigation
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Renders the navigation for senders showing the senders' apps. Apps can be re-arranged via drag and drop.
   *
   * @param {object[]} apps
   * The apps of the given sender.
   *
   * @param {object} appsStatus
   * The current status of the apps.
   *
   * @param {object} sender
   * The sender to display the navigation for.
   *
   * @requires $scope
   * @requires $rootScope
   * @requires $stateParams
   * @requires SenderModel
   * @requires appRegistry
   * @requires appService
   * @requires appTranslationsModalService
   * @requires senderNavigationUpdateService
   * @requires SenderModel
   */
  function senderNavigation() {
    return {
      restrict: 'E',
      templateUrl: 'app/commons/ui/components/sender-navigation/sender-navigation.html',
      scope: {},
      bindToController: {
        apps: '<',
        appsStatus: '=',
        sender: '='
      },
      controller: 'SenderNavigationController',
      controllerAs: '$ctrl'
    };
  }

  function SenderNavigationController($scope, $rootScope, $stateParams, appRegistry, appService,
                                      appTranslationsModalService, senderNavigationUpdateService, SenderModel, $timeout,
                                      $injector) {
    var vm = this;

    vm.lastAppUpdate = new Date().getTime();
    vm.treeOptions = {dropped: reorderNavigation};

    vm.isCurrentlySelectedApp = isCurrentlySelectedApp;
    vm.getApp = getApp;
    vm.getAppsByGroup = getAppsByGroup;
    vm.getIcon = appRegistry.getIcon;
    vm.reorderNavigation = reorderNavigation;
    vm.deleteGroup = deleteGroup;
    vm.openSettings = openSettings;
    vm.openTranslations = openTranslations;
    vm.containsActiveApps = containsActiveApps;
    vm.isDraggable = isDraggable;

    function isCurrentlySelectedApp(app) {
      return appService.isCurrentApp(app);
    }

    function getAppsByGroup(appGroup) {
      return _.chain(appGroup.apps).map(getApp).filter(angular.isDefined).value();
    }

    function getApp(id) {
      return _.find(vm.apps, {id: id});
    }

    function reorderNavigation() {
      if (vm.sender._permissions.manageApps) {
        _reorderNavigation();
      }
    }

    function deleteGroup(index) {
      var appNavigation = angular.copy(vm.sender.appNavigation);
      appNavigation.splice(index, 1);
      _deleteNavigationGroup(index);
    }

    function openSettings(app, $event) {
      $stateParams.appIdOrSlug = app.slug;
      $event.preventDefault();
      $event.stopImmediatePropagation();
      if (vm.appsStatus.busy) {
        return;
      }
      SenderModel.get(app.senderId).then(function (sender) {
        $injector.get('ngxAppSettingsModalService').open(sender, app).toPromise().then(function (result) {
          $scope.$apply(function () {
            if (result && result.deleted) {
              $rootScope.$emit('app:deleted', app.id);
            } else if (result && result.edited) {
              $rootScope.$emit('app:updated', result.app);
            }
          });
        });
      });
    }

    function openTranslations(sender) {
      vm.appNavigation = appTranslationsModalService.open(sender).then(function (result) {
        vm.sender.appNavigation = result.appNavigation;
        vm.apps = result.apps;
        $scope.$emit('apps:updated');
        $scope.$emit('appNavigation:updated');
      });
    }

    function containsActiveApps(appGroup) {
      return _.findIndex(getAppsByGroup(appGroup), {active: true}) >= 0;
    }

    function isDraggable() {
      return vm.sender._permissions.manageApps && !vm.appsStatus.busy && (vm.apps.length > 1 || Object.keys(
          vm.sender.appNavigation).length > 1);
    }

    function _deleteNavigationGroup(index) {
      if (vm.appsStatus.busy) {
        return;
      }
      vm.appsStatus.busy = true;
      senderNavigationUpdateService.deleteNavigationGroup(vm.sender.id, index).then(function (result) {
        vm.sender.appNavigation = result;
        vm.lastAppUpdate = new Date().getTime();
      }).finally(function () {
        vm.appsStatus.busy = false;
      });
    }

    function _reorderNavigation() {
      if (vm.appsStatus.busy) {
        return;
      }
      vm.appsStatus.busy = true;
      senderNavigationUpdateService.reorderNavigation(vm.sender.id, vm.sender.appNavigation).then(function (result) {
        vm.sender.appNavigation = result;
        vm.lastAppUpdate = new Date().getTime();
      }).finally(function () {
        vm.appsStatus.busy = false;
      });
    }

    (function _init() {
      // update nav on app creation
      var unsubscribeAppCreated = $rootScope.$on('app:created', function (event, app) {
        if (!angular.isArray(_.get(vm.sender, 'appNavigation[0].apps'))) {
          vm.sender.appNavigation = [{name: _.get(vm.sender, 'appNavigation[0].name', ''), apps: [app.id]}];
        } else {
          vm.sender.appNavigation[0].apps.push(app.id);
        }
        if (_.findIndex(vm.apps, {id: app.id}) === -1) {
          vm.apps.push(app);
        }
        vm.lastAppUpdate = new Date().getTime();
      });

      // update nav on app deletion
      var unsubscribeAppDeleted = $rootScope.$on('app:deleted', function (event, appId) {
        _.forEach(vm.sender.appNavigation, function (group) {
          _.pull(group.apps, appId);
        });
        vm.lastAppUpdate = new Date().getTime();
      });

      // update nav on app update
      var unsubscribeAppUpdated = $rootScope.$on('app:updated', function (event, newApp) {
        if (newApp) {
          var index = _.findIndex(vm.apps, function (app) {
            return app.id === newApp.id;
          });
          if (index >= 0) {
            vm.apps[index] = newApp;
          }
        }

        vm.lastAppUpdate = new Date().getTime();
      });

      $scope.$on('$destroy', unsubscribeAppCreated);
      $scope.$on('$destroy', unsubscribeAppDeleted);
      $scope.$on('$destroy', unsubscribeAppUpdated);
    })();
  }

})(angular);

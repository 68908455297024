(function (angular) {
  'use strict';

  NotificationsDialogController.$inject = ["$log", "$scope", "$rootScope", "$timeout", "$injector", "notificationsMainService", "coyoConfig", "targetService", "socketService", "socketReconnectDelays", "authService", "browserNotificationsService", "scrollBehaviourService"];
  angular
      .module('coyo.notifications')
      .controller('NotificationsDialogController', NotificationsDialogController);

  function NotificationsDialogController($log, $scope, $rootScope, $timeout, $injector, notificationsMainService, coyoConfig,
                                         targetService, socketService, socketReconnectDelays, authService,
                                         browserNotificationsService, scrollBehaviourService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.show = false;
    vm.categories = ['DISCUSSION', 'ACTIVITY'];
    vm.data = {};
    vm.status = {};
    vm.links = {};

    vm.toggle = toggle;
    vm.close = close;
    vm.switchCategory = switchCategory;
    vm.unsubscribe = unsubscribe;
    vm.subscribe = subscribe;
    vm.markClicked = markClicked;
    vm.getIcon = getIcon;
    vm.markAllClicked = markAllClicked;
    vm.loadMore = loadMore;
    vm.clickHandler = clickHandler;

    // ----------------------------------------------------------------

    function clickHandler(target) {
      scrollBehaviourService.enableBodyScrolling();
      if (_isFile(target)) {
        _openFile(target);
      }
    }

    function toggle() {
      vm.show = !vm.show;
      if (vm.show) {
        vm.data = {};
        vm.switchCategory(vm.category || vm.categories[0]);
        scrollBehaviourService.disableBodyScrollingOnXsScreen();
      } else {
        scrollBehaviourService.enableBodyScrolling();
      }
    }

    function close() {
      scrollBehaviourService.enableBodyScrolling();
      vm.show = false;
    }

    function switchCategory(category) {
      vm.category = category;

      // mark all seen
      if (vm.status.unseen[category] > 0) {
        vm.status.unseen[category] = 0;
        notificationsMainService.markAllSeen(category);
      }
    }

    function unsubscribe(notification) {
      $log.error('Missing implementation for unsubscribe', notification);
    }

    function subscribe(notification) {
      $log.error('Missing implementation for subscribe', notification);
    }

    function markClicked(notification) {
      vm.show = false;
      if (!notification.clicked) {
        notificationsMainService.markClicked(notification).then(function () {
          notification.clicked = true;
          vm.status.unclicked[notification.category]--;
        });
      }
    }

    function getIcon(notificationTypeName) {
      var config = coyoConfig.notificationTypeIcons[notificationTypeName];
      return config ? config.icon : coyoConfig.notificationTypeIcons.default.icon;
    }

    function markAllClicked(category) {
      notificationsMainService.markAllClicked(category).then(function () {
        angular.forEach(vm.data[category].items, function (item) {
          vm.status.unclicked[category] = 0;
          item.clicked = true;
        });
      });
    }

    function loadMore(category) {
      if (!vm.data[category]) {
        vm.data[category] = {};
      }

      var data = vm.data[category];
      if (!data.loading && !_.get(data.currentPage, 'last', false)) {
        data.loading = true;

        var pageNumber = data.currentPage ? data.currentPage.number + 1 : 0;
        var pageSize = 10;
        notificationsMainService.getNotifications(category, pageNumber, pageSize).then(function (response) {
          data.currentPage = response;
          data.items = _.concat(data.items || [], response.content);
          data.items.forEach(function (item) {
            if (!_isFile(item.target)) {
              vm.links[item.id] = _getNotificationLink(item);
            }
          });
        }).finally(function () {
          data.loading = false;
        });
      }
    }

    // ----------------------------------------------------------------

    function _getNotificationLink(notification) {
      return targetService.getLink(notification.target);
    }

    function _openFile(file) {
      targetService.go(file);
    }

    function _isFile(target) {
      return angular.isDefined(target.name) && target.name === 'file';
    }

    function _handleNewNotification(event) {
      $log.debug('Receiving event for new notification', event);

      if (event.content.status) {
        vm.status = event.content.status;
        $injector.get('ngxTabNotificationService').set('notifications-ACTIVITY', event.content.status.unseen.ACTIVITY);
        $injector.get('ngxTabNotificationService').set('notifications-DISCUSSION', event.content.status.unseen.DISCUSSION);
        $scope.$apply();
      } else {
        // fallback for when the backend thinks user is offline and does not include status counts in message
        _getStatus().then(function () {
          $injector.get('ngxTabNotificationService').set('notifications-ACTIVITY', vm.status.unseen.ACTIVITY);
          $injector.get('ngxTabNotificationService').set('notifications-DISCUSSION', vm.status.unseen.DISCUSSION);
        });
      }
      if (vm.show && event.content.notification) {
        vm.data[event.content.notification.category] = {};
        vm.loadMore(event.content.notification.category);
      }

      browserNotificationsService.notifyEvent(event);
    }

    function _handleStatusChanged(event) {
      $log.debug('Receiving event for notification status update', event);

      if (event.content.status) {
        vm.status = event.content.status;
        $scope.$apply();
      }
    }

    function _getStatus() {
      return authService.getUser().then(function (user) {
        return notificationsMainService.getStatus(user).then(function (status) {
          vm.status = status;
        });
      });
    }

    function _reconnectHandler() {
      $timeout(function () {
        _getStatus();
      }, socketReconnectDelays.NOTIFICATIONS_RELOAD_DELAY);
    }

    // ----------------------------------------------------------------

    function onInit() {
      authService.onGlobalPermissions('ACCESS_NOTIFICATIONS', function (permission) {
        vm.visible = permission;

        if (permission) {
          _getStatus();

          var unsubscribeRaisedFn = socketService.subscribe('/user/topic/notification', _handleNewNotification,
              'raised');
          var unsubscribeStatusFn = socketService.subscribe('/user/topic/notification', _handleStatusChanged,
              'statusChanged');
          var unsubscribeReconnectFn = $rootScope.$on('socketService:reconnected', _reconnectHandler);
          $scope.$on('$destroy', unsubscribeRaisedFn);
          $scope.$on('$destroy', unsubscribeStatusFn);
          $scope.$on('$destroy', unsubscribeReconnectFn);
        }
      });
    }
  }

})(angular);

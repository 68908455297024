<ng-container *ngIf="(items$ | async); let items">
  <div class="dropZoneOverlay" *ngIf="(isDragLocalFile$ | async)">
    <div class="dragBoxWrapper">
      <div class="dragMessageBox">
        <mat-icon svgIcon="upload" class="upload-icon"></mat-icon>
        <span>{{'FILEPICKER.DROPZONE.MESSAGE' | translate}}</span>
      </div>
    </div>
  </div>
  <table class="file-picker-items-table">
    <ng-container *ngIf="(currentLocation$ | async) as currentLocation">
      <thead *ngIf="!currentLocation.item.hideHeader">
      <ng-container *ngIf="getItemHeaderComponent(currentLocation.item) as headerComponent; else defaultHeader">
        <ng-container [ndcDynamicInputs]="{item: currentLocation, items: items, filePickerId: id}"
                      [ngComponentOutlet]="headerComponent"></ng-container>
      </ng-container>
      <ng-template #defaultHeader>
        <tr>
          <th class="col-selection">
            <coyo-file-picker-select-all [availableItems]="items"></coyo-file-picker-select-all>
          </th>
          <th [class.col-select]="!isMobile" class="col-icon-name">
            {{'FILEPICKER.COLUMN.FILENAME' | translate}}
          </th>
          <th class="col-size hidden-xs">{{'FILEPICKER.COLUMN.SIZE' | translate}}</th>
          <th class="col-modified hidden-xs">{{'FILEPICKER.COLUMN.MODIFIED' | translate}}</th>
          <th *ngIf="isMobile && items.length > 0" class="col-select">
          </th>
        </tr>
      </ng-template>
      </thead>
      <tbody *ngIf="items.length > 0" [class.no-header]="currentLocation.item.hideHeader">
      <ng-container *ngFor="let model of items; trackBy: trackByFunction">
        <ng-container *ngIf="getItemComponent(model.item) as itemComponent; else defaultItem">
          <ng-container [ndcDynamicInputs]="{itemId: model.item.id, filePickerId: id}"
                        [ngComponentOutlet]="itemComponent">
          </ng-container>
        </ng-container>
        <ng-template #defaultItem>
          <tr (click)="filePickerItemClicked(model.item, $event)">
            <td class="col-selection">
              <coyo-file-picker-selection [item]="model.item"></coyo-file-picker-selection>
            </td>
            <td class="col-name">
              <div class="name-icon-wrapper">
                <coyo-file-picker-icon [item]="model.item"></coyo-file-picker-icon>
                <a *ngIf="model.item.isFolder; else fileName"
                   [attr.aria-label]="'FILEPICKER.FOLDER.ARIA' | translate : {folder: model.item.name}"
                   href>{{model.item.name}}</a>
                <ng-template #fileName>
                  <span [attr.aria-label]="'FILEPICKER.FILE.ARIA' | translate : {file: model.item.name}">
                    {{model.item.name}}
                  </span>
                </ng-template>
              </div>
            </td>
            <td class="hidden-xs col-size">
              <span *ngIf="!model.item.isFolder">{{model.item.sizeInBytes | fileSize}}</span>
              <span *ngIf="model.item.isFolder">- -</span>
            </td>
            <td class="hidden-xs col-modified">
              {{model.item.lastModified | date:'short'}}
            </td>
          </tr>
        </ng-template>
      </ng-container>
      <div (visible)="loadMore(currentLocation)" class="sentinel" coyoInfiniteScrolling></div>
      </tbody>
    </ng-container>
  </table>

  <div class="loading-container" *ngIf="(loading$ | async) as loading; else breadcrumbs">
    <ui-spinner></ui-spinner>
  </div>
  <ng-template #breadcrumbs>
    <div *ngIf="items.length === 0" class="empty-folder">
      <div>
        <span>{{'FILEPICKER.EMPTY.EMPTY_FOLDER' | translate}}</span>
        <ng-container *ngIf="canCreateFile$ | async">
          <span> {{'FILEPICKER.EMPTY.MESSAGE.1' | translate}}</span>
          <a (click)="openFileBrowser()">{{'FILEPICKER.EMPTY.MESSAGE.2' | translate}}</a>
          <span>{{'FILEPICKER.EMPTY.MESSAGE.3' | translate}}</span>
        </ng-container>
      </div>
      <button (click)="openParent()" *ngIf="(breadcrumbs$ | async).length > 1" color="default" mat-button
              mat-link-button
              type="button">{{'FILEPICKER.BUTTON.BACK' | translate}}
      </button>
      <input #files
             (change)="uploadFiles(files.files)"
             [accept]="mimeTypes$ | async"
             [multiple]="(multiple$ | async)"
             class="hidden"
             type="file">
    </div>
  </ng-template>
</ng-container>

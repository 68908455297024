import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FileLibraryFilePickerItemPublicShareDialogData} from '@app/file-library/file-library-file-picker-item-public-share-dialog/file-library-file-picker-item-public-share-dialog-data';
import {
  PublicFileLinkService,
} from '@app/file-library/file-library-file-picker-item-public-share-dialog/public-file-link.service';
import {PublicFileResponse} from '@app/file-library/file-library-file-picker-item-public-share-dialog/public-file-response';
import {UpdatePublicLinkStatus} from '@app/file-picker/file-picker-state/file-picker.actions';
import {Store} from '@ngxs/store';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'coyo-file-library-file-picker-item-public-share-dialog',
  templateUrl: './file-library-file-picker-item-public-share-dialog.component.html',
  styleUrls: ['./file-library-file-picker-item-public-share-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryFilePickerItemPublicShareDialogComponent implements OnInit, OnDestroy {

  state$: BehaviorSubject<PublicFileResponse> = new BehaviorSubject<PublicFileResponse>(null);

  constructor(private readonly publicFileLinkService: PublicFileLinkService,
              private readonly notificationService: NotificationService,
              private readonly store: Store,
              @Inject(MAT_DIALOG_DATA) private readonly data: FileLibraryFilePickerItemPublicShareDialogData) { }

  ngOnInit(): void {
    if (this.data.hasPublicLink) {
      this.publicFileLinkService.getLink(this.data.id, this.data.senderId)
        .subscribe(value => this.state$.next(value));
    } else {
      this.state$.next({
        downloadUrl: null,
        active: false,
        fileId: this.data.id,
        senderId: this.data.senderId,
        token: null
      });
    }
  }

  create(): void {
    this.publicFileLinkService.create(this.data.id, this.data.senderId).subscribe(value => this.state$.next(value));
  }

  recreate(token: string): void {
    this.publicFileLinkService.recreate(token).subscribe(value => this.state$.next(value));
  }

  activate(token: string): void {
    this.publicFileLinkService.activate(token).subscribe(() => this.state$.next({
      ...this.state$.getValue(),
        active: true
    }));
  }

  deactivate(token: string): void {
    this.publicFileLinkService.deactivate(token).subscribe(() => this.state$.next({
      ...this.state$.getValue(),
      active: false
    }));
  }

  copied(): void {
    this.notificationService.success('FILE_LIBRARY.COPY_LINK.SUCCESS');
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UpdatePublicLinkStatus(this.data.id, !!this.state$.getValue().downloadUrl, this.state$.getValue().active));
  }
}

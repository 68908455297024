import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {WidgetVisibilityConfig} from '@domain/widget/widget-visibility-config';
import {Observable} from 'rxjs';
import {first, shareReplay} from 'rxjs/operators';

/**
 * Service for widget visibility configurations
 */
@Injectable({
  providedIn: 'root'
})
export class WidgetVisibilityConfigService {
  static readonly baseUrl: string = '/web/widget-configurations';

  static readonly CACHE_EXPIRY: number = 1000 * 60 * 5;
  static readonly CACHE_SIZE: number = 1;

  private cache$: Observable<WidgetVisibilityConfig[]>;

  constructor(private http: HttpClient) {
  }

  /**
   * Get all enabled configs, including configs that are restricted to moderators only.
   * The results are cached for 5 minutes.
   *
   * @returns The list of enabled visibility configs.
   */
  getAllEnabled(): Observable<WidgetVisibilityConfig[]> {
    if (!this.cache$) {
      this.cache$ = this.requestEnabled().pipe(shareReplay({bufferSize: WidgetVisibilityConfigService.CACHE_SIZE, refCount: false}));
      setTimeout(() => this.purgeCache(), WidgetVisibilityConfigService.CACHE_EXPIRY);
    }
    return this.cache$.pipe(first());
  }

  /**
   * Create or update a config by the given widget key.
   *
   * @param key The widget key.
   * @param widgetVisibilityConfig The config to create or update.
   * @returns The created or updated config.
   */
  createOrUpdate(key: string, widgetVisibilityConfig: WidgetVisibilityConfig): Observable<WidgetVisibilityConfig> {
    this.purgeCache();
    return this.http.put<WidgetVisibilityConfig>(`${WidgetVisibilityConfigService.baseUrl}/${key}`,
      widgetVisibilityConfig);
  }

  private requestEnabled(): Observable<WidgetVisibilityConfig[]> {
    return this.http.get<WidgetVisibilityConfig[]>(WidgetVisibilityConfigService.baseUrl);
  }

  private purgeCache(): void {
    this.cache$ = null;
  }
}

/**
 * Module for the MS Teams Login
 */
import {NgModule} from '@angular/core';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {teamsRedirectState} from './teams-redirect.state';

export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: [teamsRedirectState]});

@NgModule({
  imports: [uiRouterModuleChild],
  declarations: []
})
export class TeamsRedirectModule {
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {SettingPreset} from '../setting-preset';
import {buildSetting} from '../setting-presets';

/**
 * The top navigation toolbar of the PDF viewer.
 */
@Component({
  selector: 'coyo-pdf-viewer-toolbar',
  templateUrl: './pdf-viewer-toolbar.component.html',
  styleUrls: ['./pdf-viewer-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerToolbarComponent implements OnInit {
  /**
   * when true it sets a loading state to the toolbar component
   */
  @Input() loading: boolean;

  /**
   * the current page to be shown in the page section of the toolbar
   */
  @Input() currentPage: number;

  /**
   * the total number of pages of the pdf document
   */
  @Input() maxPages: number;

  /**
   * the options of setting presets for page view (e.g. zoom level) to select from
   */
  @Input() settingPresets: SettingPreset[];

  /**
   * The active settings to be set (e.g. the zoom level)
   */
  @Input() activeSetting: SettingPreset;

  /**
   * Event when the user clicked on next or previous page
   */
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Event when the user changes the settings (e.g. the zoom level)
   */
  @Output() settingSelected: EventEmitter<SettingPreset> = new EventEmitter<SettingPreset>();

  /**
   * Event when the user clicked the rotate button
   */
  @Output() updateRotation: EventEmitter<number> = new EventEmitter<number>();

  rotation: number = 0;

  presets: SettingPreset[];

  ngOnInit(): void {
    this.presets = _.cloneDeep(this.settingPresets);
    this.activeSetting = this.presets[0];
    this.updateActiveSetting();
  }

  /**
   * Updates the active zoom level setting
   *
   * @param $event
   * The setting change event
   */
  updateActiveSetting($event?: SettingPreset): void {
    if ($event) {
      this.activeSetting = $event;
    }
    this.settingSelected.emit(this.activeSetting);
  }

  /**
   * Changes the Page to the given value
   *
   * @param value
   * The new page
   */
  onEnter(value: number): void {
    this.changePage(value);
  }

  /**
   * This method return true, if someone enters a number in the input window, otherwise false
   *
   * @param event - an event
   *
   * @return true if the event was a number char event
   */
  isNumber(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  /**
   * Moves to the given page number.
   *
   * @param page the new page number
   */
  changePage(page: number): void {
    if (page > 0 && page <= this.maxPages) {
      this.pageChange.emit(page);
    }
  }

  /**
   * Moves to the previous page if one exists.
   */
  previousPage(): void {
    this.changePage(this.currentPage - 1);
  }

  /**
   * Moves to the next page if one exists.
   */
  nextPage(): void {
    this.changePage(this.currentPage + 1);
  }

  /**
   * Decreases the zoom level.
   */
  zoomOut(): void {
    const zoom = this.activeSetting.zoom - this.calculateZoomFactor();
    this.zoom(Math.max(0.1, zoom));
  }

  /**
   * Increases the zoom level.
   */
  zoomIn(): void {
    const zoom = this.activeSetting.zoom + this.calculateZoomFactor();
    this.zoom(Math.min(10, zoom));
  }

  /**
   * Sets the zoom level to the given number.
   *
   * @param zoom the new zoom level
   */
  zoom(zoom: number): void {
    this.presets = _.cloneDeep(this.settingPresets);
    const title = Math.round(zoom * 100) + '%';
    this.activeSetting = buildSetting(title, zoom, true, false, true);
    this.presets.unshift(this.activeSetting);
    this.updateActiveSetting();
  }

  /**
   * Rotates the PDF in a counter-clockwise direction.
   */
  rotateLeft(): void {
    this.rotation = this.rotation - 90;
    this.updateRotation.emit(this.rotation);
  }

  /**
   * Rotates the PDF in a clockwise direction.
   */
  rotateRight(): void {
    this.rotation = this.rotation + 90;
    this.updateRotation.emit(this.rotation);
  }

  private calculateZoomFactor(): number {
    const factor = ((this.activeSetting.zoom + 1) / 10);
    return Math.round(factor * 10) / 10;
  }
}

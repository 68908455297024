import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {PollWidget} from '@widgets/poll/poll-widget';
import {PollWidgetSettingsOption} from '@widgets/poll/poll-widget-settings';
import {PollWidgetStateModel} from '@widgets/poll/poll-widget.state';
import * as _ from 'lodash';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, startWith, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'coyo-poll-widget-settings',
  templateUrl: './poll-widget-settings.component.html',
  styleUrls: ['./poll-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollWidgetSettingsComponent extends WidgetSettingsComponent<PollWidget> implements OnInit, OnDestroy {
  readonly QUESTION_MAX_LENGTH: number = 250;
  readonly ANSWER_MAX_LENGTH: number = 250;

  @ViewChildren('optionInput', {
    read: ElementRef
  }) optionInputs: QueryList<ElementRef>;

  updatedWidget$: Observable<PollWidget>;

  private widgetState$: Observable<PollWidgetStateModel>;
  private onDestroy$: Subject<void> = new Subject();
  private selectedTab$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.widgetState$ = this.store
      .select(state => state.pollWidget[this.widget.id || this.widget.dataReference])
      .pipe(filter(state => !!state), distinctUntilChanged());

    _.defaults(this.widget.settings, {
      _nextOptionId: 0,
      _maxAnswers: 1,
      _frozen: false,
      _anonymous: false,
      _showResults: true
    });
    this.parentForm.addControl('question', new FormControl(this.widget.settings.question,
      [Validators.maxLength(this.QUESTION_MAX_LENGTH), CoyoValidators.notBlank]));
    this.parentForm.addControl('description', new FormControl(this.widget.settings.description));
    this.parentForm.addControl('_frozen', new FormControl(this.widget.settings._frozen));
    this.parentForm.addControl('_anonymous', new FormControl(this.widget.settings._anonymous));
    this.parentForm.addControl('_maxAnswers', new FormControl(this.widget.settings._maxAnswers, [Validators.min(1)]));
    this.parentForm.addControl('_showResults', new FormControl(this.widget.settings._showResults));
    this.parentForm.addControl('_options', new FormArray([], [Validators.minLength(1)]));
    if (_.get(this.widget, 'settings._options.length', 0) === 0) {
      this.addOption(false, undefined, 0);
    } else {
      this.widget.settings._options.forEach((value, index) => {
        this.addOption(false, value, index);
      });
    }

    this.widgetState$.pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      state.options.forEach((value, index) => {
        const formControl = (this.parentForm.get('_options') as FormArray).at(index);
        const opts = {
          emitEvent: false
        };
        value.votes > 0 && formControl && formControl.enabled ? formControl.disable(opts) : formControl.enable(opts);
      });
      this.updateMaxAnswersValidator();
    });

    /* tslint:disable-next-line:deprecation */
    this.updatedWidget$ = combineLatest(
      this.selectedTab$,
      this.parentForm.valueChanges
        .pipe(startWith(this.parentForm.getRawValue()))
    ).pipe(map(([selectedTab, formValue]) => selectedTab === 2 ? {
      ...this.widget,
      settings: this.parentForm.getRawValue()
    } : null));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getOptions(): FormArray {
    return this.parentForm.get('_options') as FormArray;
  }

  addOption(focus: boolean, value?: PollWidgetSettingsOption, index?: number): void {
    const id = value ? value.id : this.widget.settings._nextOptionId++;
    const answer = value ? value.answer : '';
    const formGroup = new FormGroup({
      id: new FormControl(id),
      answer: new FormControl(answer, index === 0 ? [CoyoValidators.notBlank] : [])
    }, []);
    (this.parentForm.get('_options') as FormArray).push(formGroup);
    this.updateMaxAnswersValidator();
    if (focus) {
      setTimeout(() => {
        // this list will only contain the new input in the next 'tick'
        const last = this.optionInputs.last;
        if (last) {
          last.nativeElement.focus();
        }
      });
    }
  }

  onInputEnter(event: KeyboardEvent, answer: string): boolean {
    if (answer) {
      this.addOption(true);
    }
    event.preventDefault();
    return false;
  }

  onInputBackspace(event: KeyboardEvent, answer: string, index?: number): boolean {
    if (!answer && index > 0) {
      this.deleteOption(index);
      event.preventDefault();
      return false;
    }
    return true;
  }

  deleteOption(index: number): void {
    (this.parentForm.get('_options') as FormArray).removeAt(index);
    const toFocus = this.optionInputs.toArray()[Math.max(index - 1, 0)];
    if (toFocus) {
      toFocus.nativeElement.focus();
    }
    this.updateMaxAnswersValidator();
  }

  selectTab(index: number): void {
    this.selectedTab$.next(index);
  }

  updateMaxAnswersValidator(): void {
    const max = (this.parentForm.get('_options') as FormArray).length;
    this.parentForm.get('_maxAnswers')
      .setValidators([Validators.required, CoyoValidators.naturalNumber, Validators.min(1), Validators.max(max)]);
    this.parentForm.get('_maxAnswers').updateValueAndValidity({
      emitEvent: false
    });
  }
}

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {GDrivePickerService} from '@app/integration/gsuite/g-drive-picker/g-drive-picker.service';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {ModalService} from '@domain/modal/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {RtePlugin} from '@shared/rte/rte-plugin';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';

/**
 * A custom RTE plugin that connects the Froala editor to GSuite for video selection.
 */
@Injectable()
export class VideoFromGSuitePlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertVideoFromGSuite';

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(FROALA_EDITOR) private froala: any,
              private notificationService: NotificationService,
              private modalService: ModalService,
              private translateService: TranslateService,
              private googleApiService: GoogleApiService,
              private gDrivePickerService: GDrivePickerService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    if (!settings.canAccessGoogle) {
      return; // user is not allowed to access GSuite
    }

    const plugin = this;
    this.froala.RegisterCommand(VideoFromGSuitePlugin.KEY, {
      title: plugin.translateService.instant('RTE.VIDEO.FROM_G_SUITE'),
      plugin: 'coyoVideoPlugin',
      icon: 'insertVideo',
      undo: false,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        plugin.openGoogleFilePicker(this);
      }
    });
  }

  private openGoogleFilePicker(editor: any): void {
    const plugin = this;

    plugin.gDrivePickerService.open({
      multipleSelect: false,
      view: 'DOCS_VIDEOS',
      uploadView: false,
      recentFilesView: false
    }).then(selectedFiles => {
      editor.html.insert(`<div class="coyo-spinner-container" id="video-spinner-${editor.id}" contenteditable="false"><div class="coyo-spinner"/></div>`);
      selectedFiles.forEach(item => {
        plugin.googleApiService.getFileMetadata(item.id).then((metadata: any) => {
          plugin.googleApiService.isFilePublicVisible(item.id).then(publicVisible => {
            this.removeSpinner(editor);
            if (publicVisible) {
              editor.video.insert(`<video src="${metadata.webContentLink}" controls></video>`);
            } else {
              this.modalService.openConfirm({
                title: 'RTE.INSERT_G_SUITE_VIDEO.PRIVATE.HINT.TITLE',
                text: 'RTE.INSERT_G_SUITE_VIDEO.PRIVATE.HINT.TEXT'
              }).subscribe(() => {
                editor.html.insert(`<a href="${item.url}" target="_blank"><img src="/assets/images/integration/gsuite-placeholder.svg"/></a>`);
              });
            }
            editor.undo.saveStep();
          }).catch(() => plugin.onError(editor));
        }).catch(() => plugin.onError(editor));
      });
    }).catch(() => plugin.onError(editor));
  }

  private onError(editor: any): void {
    this.notificationService.error('RTE.VIDEO.FROM_G_SUITE.ERROR');
    this.removeSpinner(editor);
  }

  private removeSpinner(editor: any): void {
    const spinner = this.document.getElementById(`video-spinner-${editor.id}`);
    spinner.parentNode.removeChild(spinner);
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CounterComponent} from './counter.component';
import './counter.component.downgrade';

/**
 * Module that contains a component used to display a counter.
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    CounterComponent
  ],
  exports: [
    CounterComponent
  ],
  entryComponents: [
    CounterComponent
  ]
})
export class CounterModule {
}

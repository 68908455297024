(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .factory('selectionFilterService', selectionFilterService);

  /**
   * @ngdoc service
   * @name commons.ui.selectionFilterService
   *
   * @description
   * Provides the selection filter service.
   *
   */
  function selectionFilterService() {
    var selectionFilterService = {
      KEY_ALL: 'ALL',

      /**
       * @ngdoc function
       * @name commons.ui.selectionFilterService#build
       * @methodOf commons.ui.selectionFilterService
       *
       * @description
       * Returns a new SelectionFilterBuilder object.
       *
       * @returns {object} SelectionFilterBuilder
       */
      builder: function () {
        return new SelectionFilterBuilder();
      },

      /**
       * @ngdoc function
       * @name commons.ui.selectionFilterService#build
       * @methodOf commons.ui.selectionFilterService
       *
       * @description
       * Returns a new SelectionFilterBuilder object.
       *
       * @returns {object} SelectionFilterBuilder
       */
      itemBuilder: function () {
        return new SelectionFilterItemBuilder();
      }
    };

    function SelectionFilterBuilder() {
      this.selectionFilterModel = new SelectionFilterModel();
      this.allItemConfig = {key: selectionFilterService.KEY_ALL};
      this.activeKey = undefined;
    }

    // instance members
    angular.extend(SelectionFilterBuilder.prototype, {

      key: function (key) {
        this.selectionFilterModel.key = key;
        return this;
      },

      item: function (config) {
        var selectionFilterItemModel = new SelectionFilterItemModel(config);
        this.selectionFilterModel.items.push(selectionFilterItemModel);
        return this;
      },

      items: function (configs) {
        var self = this;
        configs.forEach(function (config) {
          self.item(config);
        });
        return this;
      },

      itemModel: function (itemModel) {
        this.selectionFilterModel.items.push(itemModel);
        return this;
      },

      count: function (count) {
        this.allItemConfig.count = count;
        return this;
      },

      active: function (key) {
        this.activeKey = key;
        return this;
      },

      icon: function (icon) {
        this.selectionFilterModel.icon = icon;
        return this;
      },

      iconColor: function (iconColor) {
        this.selectionFilterModel.iconColor = iconColor;
      },

      trackingId: function () {
        this.selectionFilterModel.trackingId = Math.random();
        return this;
      },

      build: function () {
        this.selectionFilterModel.allItem = new SelectionFilterItemModel(this.allItemConfig);
        if (angular.isDefined(this.activeKey)) {
          this.selectionFilterModel.selectItem(this.activeKey);
        }
        this.selectionFilterModel.updateAllActive();
        return this.selectionFilterModel;
      }
    });

    function SelectionFilterModel() {
      this.key = undefined;
      this.allItem = undefined;
      this.allIcon = undefined;
      this.items = [];
      this.icon = undefined;
      this.iconColor = undefined;
      this.trackingId = undefined;
    }

    angular.extend(SelectionFilterModel.prototype, {
      getItem: function (key) {
        return [].concat([this.allItem], this.items).filter(function (item) {
          return item.key === key;
        })[0];
      },

      selectItem: function (key) {
        this.items.forEach(function (item) {
          item.active = item.key === key;
        });
        this.updateAllActive();
      },

      isSelected: function () {
        return this.allItem.active === false;
      },

      getSelected: function () {
        return this.items.filter(function (item) {
          return item.active;
        }).map(function (item) {
          return item.key;
        });
      },

      clearAll: function () {
        var toggledItemKeys = this.items.filter(function (item) {
          return item.active;
        }).map(function (item) {
          return item.key;
        });

        this.items.forEach(function (item) {
          item.active = false;
        });
        this.allItem.active = true;

        return toggledItemKeys;
      },

      setCount: function (count) {
        this.allItem.count = count;
      },

      updateAllActive: function () {
        this.allItem.active = this.items.filter(function (item) {
          return item.active;
        }).length === 0;
      }
    });

    function SelectionFilterItemBuilder() {
      this.selectionFilterItemModel = new SelectionFilterItemModel({});
    }

    angular.extend(SelectionFilterItemBuilder.prototype, {
      key: function (key) {
        this.selectionFilterItemModel.key = key;
        return this;
      },
      textKey: function (textKey) {
        this.selectionFilterItemModel.textKey = textKey;
        return this;
      },
      text: function (text) {
        this.selectionFilterItemModel.text = text;
        return this;
      },
      icon: function (icon) {
        this.selectionFilterItemModel.icon = icon;
        return this;
      },
      iconColor: function (iconColor) {
        this.selectionFilterItemModel.iconColor = iconColor;
        return this;
      },
      active: function (active) {
        this.selectionFilterItemModel.active = active;
        return this;
      },
      count: function (count) {
        this.selectionFilterItemModel.count = count;
        return this;
      },
      order: function (order) {
        this.selectionFilterItemModel.order = order;
        return this;
      },
      build: function () {
        return this.selectionFilterItemModel;
      }
    });

    function SelectionFilterItemModel(config) {
      this.key = config.key;
      this.textKey = config.textKey;
      this.text = config.text;
      this.icon = config.icon;
      this.iconColor = config.iconColor;
      this.active = config.active || false;
      this.count = config.count || 0;
      this.order = config.order;
    }

    angular.extend(SelectionFilterItemModel.prototype, {
      isSelected: function () {
        return this.active;
      }
    });

    return selectionFilterService;
  }

})(angular);

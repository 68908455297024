import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {ImageWidget} from '@widgets/image/image-widget';
import {ImageWidgetSettingsComponent} from './image-widget-settings/image-widget-settings.component';
import {ImageWidgetComponent} from './image-widget/image-widget.component';

/**
 * The configuration for the image widget.
 */
export const IMAGE_WIDGET: WidgetConfig<ImageWidget> = {
  key: 'image',
  name: 'WIDGET.IMAGE.NAME',
  description: 'WIDGET.IMAGE.DESCRIPTION',
  icon: 'zmdi-image-o',
  component: ImageWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    skipOnCreate: false,
    component: ImageWidgetSettingsComponent
  },
  renderOptions: {
    printable: true,
    panels: {
      noPanel: true
    },
    panel: {
      noPanel: true
    }
  },
  whitelistExternal: true,
  mobileSupportTypes: ['landing-page']
};

import {Inject, Injectable} from '@angular/core';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemService, FILE_PICKER_ITEM_SERVICES} from '@app/file-picker/file-picker-item.service';

@Injectable({
  providedIn: 'root',
})
export class FilePickerItemServiceSelectorService {

  constructor(@Inject(FILE_PICKER_ITEM_SERVICES) readonly filePickerItemServices: FilePickerItemService[]) {

  }

  get(filePickerItem: FilePickerItem): FilePickerItemService {
    const matches = this.filePickerItemServices.filter(service => service.getResponsibilities().some(type => filePickerItem instanceof type));
    if (matches.length <= 0) {
      throw new Error(`No service defined for file picker item "${JSON.stringify(filePickerItem)}"`);
    }
    return matches[0];
  }
}

<div [formGroup]="parentForm" class="callout-widget-settings">
  <div class="form-group" formGroupName="_callout">
    <label class="col-sm-2 control-label" translate="WIDGET.CALLOUT.SETTINGS.STYLE"></label>
    <div class="col-sm-10">
      <div class="row">
        <div class="btn-group col-xs-12" btnRadioGroup formControlName="alertClass">
          <label *ngFor="let style of styles" [btnRadio]="style.value" class="btn col-xs-6 col-sm-3" [ngClass]="style.clazz"
                 tabindex="0" role="callout">{{ style.name | translate }}</label>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" [class.has-error]="isInvalid('md_text')">
    <label class="col-sm-2 control-label" for="md_text" translate="WIDGET.CALLOUT.SETTINGS.TEXT"></label>
    <div class="col-sm-10">
      <textarea class="form-control resize-v" rows="4" name="md_text" id="md_text" formControlName="md_text" coyoMention></textarea>
    </div>
  </div>
</div>

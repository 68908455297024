(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .directive('coyoSelectIntegration', coyoSelectIntegration);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoSelectIntegration:coyoSelectIntegration
   * @restrict E
   * @scope
   *
   * @description
   * Renders a UI select field to select the type of an integration (e.g. Office 365, G Suite).
   *
   * @param {string} placeholder The placeholder for the input field
   * @param {boolean} languageKeyPrefix The message key prefix to use.
   *
   */
  function coyoSelectIntegration() {
    return {
      restrict: 'E',
      require: 'ngModel',
      templateUrl: 'app/commons/ui/components/select-integration/select-integration.html',
      scope: {
        placeholder: '@?',
        languageKeyPrefix: '<'
      },
      link: function (scope, elem, attrs, ctrl) {
        scope.data = {
          visibilities: ['NONE', 'G_SUITE', 'OFFICE_365'],
          selected: null
        };

        // model -> select
        ctrl.$render = function () {
          scope.data.selected = ctrl.$viewValue;
        };

        // select -> model
        scope.$watch('data.selected', function (newVal) {
          ctrl.$setViewValue(newVal);
        });
      }
    };
  }

})(angular);

(function () {
  'use strict';

  angular
      .module('coyo.apps.commons.fields')
      .component('oyocPlaceholderSettings', placeholderSettings())
      .controller('PlaceholderSettingsController', PlaceholderSettingsController);

  function placeholderSettings() {
    return {
      templateUrl: 'app/apps/commons/fields/settings/components/placeholder-settings.html',
      bindings: {
        model: '<'
      },
      controller: 'PlaceholderSettingsController',
      controllerAs: '$ctrl'
    };
  }

  function PlaceholderSettingsController() {
  }
})();


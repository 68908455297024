import {StateOperator} from '@ngxs/store';
import {Predicate} from '@ngxs/store/operators/internals';
import {RepairType} from '@ngxs/store/operators/utils';

/**
 * @param selector - Index to insert items at. If larger than the existing array, items will be appended. Or a predicate function to select a location.
 * @param items - Specific items to insert
 * @returns a state operator to insert items into an array
 */
export function insertAt<T>(selector: number | Predicate<T>, items: T[]): StateOperator<RepairType<T>[]> {
  return function insertAtOperator(existing: Readonly<RepairType<T>[]>): RepairType<T>[] {
    // If `items` is `undefined` or `null` or `[]` but `existing` is provided
    // just return `existing`
    const itemsNotProvidedButExistingIs = (!items || !items.length) && existing;
    if (itemsNotProvidedButExistingIs) {
      return existing as RepairType<T>[];
    }

    if (Array.isArray(existing)) {
      if (typeof selector === 'number') {
        return existing.slice(0, selector).concat(items).concat(existing.slice(selector));
      } else {
        for (let i = 0; i < existing.length; i++) {
          if (selector(existing[i])) {
            return existing.slice(0, i).concat(items).concat(existing.slice(i));
          }
        }
      }
      return existing.concat(items);
    }

    // For example if some property is added dynamically
    // and didn't exist before thus it's not `ArrayLike`
    return items as RepairType<T>[];
  };
}

import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {SUGGEST_PAGES_WIDGET} from '@widgets/suggest-pages/suggest-pages-widget-config';
import {SuggestPagesWidgetSettingsComponent} from '@widgets/suggest-pages/suggest-pages-widget-settings/suggest-pages-widget-settings.component';
import {SuggestPagesWidgetComponent} from '@widgets/suggest-pages/suggest-pages-widget/suggest-pages-widget.component';
import {SuggestPagesWidgetState} from '@widgets/suggest-pages/suggest-pages-widget/suggest-pages-widget.state';

export const ngxsModule = NgxsModule.forFeature([SuggestPagesWidgetState]);

/**
 * Module providing the suggest-pages widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    UpgradeModule,
    SenderUIModule,
    HelpModule,
    ngxsModule
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: SUGGEST_PAGES_WIDGET, multi: true}
  ],
  declarations: [
    SuggestPagesWidgetComponent,
    SuggestPagesWidgetSettingsComponent
  ],
  entryComponents: [
    SuggestPagesWidgetComponent,
    SuggestPagesWidgetSettingsComponent
  ]
})
export class SuggestPagesWidgetModule {
}

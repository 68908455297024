import {InjectionToken, Type} from '@angular/core';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {AppComponent} from '@apps/api/app.component';
import {IndividualAppSettingsComponent} from '@apps/api/individual-app-settings/individual-app-settings.component';
import {Ng2ViewDeclaration} from '@uirouter/angular/interface';
import {ResolvableLiteral} from '@uirouter/core';

/**
 * The App configuration injection token.
 */
export const APP_CONFIGS: InjectionToken<AppConfig<AppSettings>[]> =
  new InjectionToken<AppConfig<AppSettings>[]>('APP_CONFIGS');

/**
 * Holds the router state configuration for an App
 */
export interface AppState<Settings extends AppSettings> {
  /**
   * The main App component
   */

  component?: Type<AppComponent<Settings>>;
  /**
   * When using a component declaration, each input binding for the component is supplied
   * data from a resolve of the same name, by default. You may supply data from a different resolve name by mapping it
   * here. This might be useful if you want to reuse the same resolve value with various components with different input
   * binding names.
   *
   * @see https://ui-router.github.io/ng2/docs/latest/interfaces/state.ng2statedeclaration.html#bindings
   */
  bindings?: {
    [key: string]: string;
  };

  /**
   * Decides if this is the default state. Only necessary with multiple states
   */
  default?: boolean;

  /**
   * Holds the url for this state. Will be set by AppStateGenerator.buildDefaultState()
   */
  url?: string;

  /**
   * The name of this state
   */
  name?: string;

  /**
   * Abstract state indicator
   *
   * An abstract state can never be directly activated.
   * Use an abstract state to provide inherited properties (url, resolve, data, etc) to children states.
   */
  abstract?: boolean;

  /**
   * Resolve - a mechanism to asynchronously fetch data, participating in the Transition lifecycle
   *
   * The resolve property defines data (or other dependencies) to be fetched asynchronously when
   * the state is being entered. After the data is fetched, it may be used in views, transition hooks
   * or other resolves that belong to this state. The data may also be used in any views or resolves that belong
   * to nested states.
   *
   * @see https://ui-router.github.io/ng2/docs/latest/interfaces/state.ng2statedeclaration.html#resolve
   */
  resolve?: ResolvableLiteral[];

  /**
   * An optional object used to define multiple named views.
   *
   * @see https://ui-router.github.io/ng2/docs/latest/interfaces/state.ng2statedeclaration.html#views
   */
  views?: { [key: string]: Ng2ViewDeclaration };

  /**
   * Additional state parameters
   */
  params?: { [key: string]: any };
}

/**
 * The basic app config without state declarations
 */
export class AppConfigBase {

  /**
   * The unique app key
   */
  key: string;

  /**
   * The app name
   */
  name: string;

  /**
   * The app description
   */
  description: string;

  /**
   * The app-icon indentifier for the angular js part of the application
   * @Deprecated
   */
  icon: string;

  /**
   * The app-icon identifier for the angular part of the application
   */
  svgIcon: string;

  /**
   * Maximum number of parallel instances allowed
   */
  allowedInstances?: number;

  /**
   * Error message when allowed instance count is exceeded
   */
  allowedInstancesErrorMessage?: string;
}

/**
 * Holds the App Configuration as well as router states
 */
export interface AppConfig<Settings extends AppSettings> extends AppConfigBase {
  /**
   * A list of states for registering the app in the ui-router state registry
   */
  states: AppState<Settings>[];

  /**
   * The name of the default state. Only necessary with multiple states.
   */
  defaultStateName?: string;

  /**
   * Configuration for the settings component used to configure the App Settings in the frontend.
   */
  settings?: {

    /**
     * Flag indicating if the settings dialog should be shown on widget creation.
     */
    skipOnCreate: boolean;

    /**
     * The component to be rendered as settings.
     */
    component?: Type<IndividualAppSettingsComponent<Settings>>;
  };
}

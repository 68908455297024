import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {PublicFileResponse} from '@app/file-library/file-library-file-picker-item-public-share-dialog/public-file-response';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicFileLinkService extends DomainService<void, PublicFileResponse> {
    protected getBaseUrl(): string {
      return '/web/public-link';
    }

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  getLink(fileId: string, senderId: string): Observable<PublicFileResponse> {
      return this.http.get<PublicFileResponse>(this.getUrl({
        senderId: senderId,
        fileId: fileId,
      }, '/{senderId}/{fileId}'));
  }

  create(fileId: string, senderId: string): Observable<PublicFileResponse> {
    return this.http.post<PublicFileResponse>(this.getUrl({
      senderId: senderId,
      fileId: fileId
    }, '/{senderId}/{fileId}'), {

    });
  }

  recreate(token: string): Observable<PublicFileResponse> {
    return this.http.put<PublicFileResponse>(this.getUrl({
      token: token
    }, '/{token}/recreate'), {

    });
  }

  deactivate(token: string): Observable<void> {
    return this.http.put<void>(this.getUrl({
      token: token
    }, '/{token}/deactivate'), {

    });
  }

  activate(token: string): Observable<void> {
    return this.http.put<void>(this.getUrl({
      token: token
    }, '/{token}/activate'), {

    });
  }
}

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name commons.ui.coyoFormValidationCompareTo
   * @scope
   * @restrict 'A'
   * @element ANY
   *
   * @description Validator to compare the value of the current model with another value.
   *
   * @example <input type="text" ng-model="$ctrl.model.confirmPassword" compareTo="$ctrl.model.password">
   */
  angular
      .module('commons.ui')
      .directive('compareTo', CoyoFormValidationCompareTo);

  function CoyoFormValidationCompareTo() {
    return {
      restrict: 'A',
      require: 'ng-model',
      scope: {
        compareToValue: '=compareTo'
      },
      link: function ($scope, $element, $attributes, ngModel) {

        ngModel.$validators.compareTo = function (modelValue) {
          return modelValue === $scope.compareToValue;
        };

        $scope.$watch('compareToValue', function () {
          ngModel.$validate();
        });
      }
    };
  }
})();

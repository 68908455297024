import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '@domain/settings/settings.service';
import {Bookmark} from '@widgets/bookmarking/bookmark.model';

enum Step {
  Url = 'URL',
  Title = 'TITLE'
}

/**
 * Form to edit/remove an existing bookmark.
 */
@Component({
  selector: 'coyo-bookmarking-edit-form',
  templateUrl: './bookmarking-edit-form.component.html',
  styleUrls: ['./bookmarking-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkingEditFormComponent implements OnInit {

  /**
   * Set the bookmark that should be edited.
   * The component will switch from ADD to EDIT mode when the bookmark is set.
   *
   * @param bookmark The bookmark to edit
   */
  @Input() set bookmark(bookmark: Bookmark) {
    this.bookmarkForm.setValue(bookmark);
  }

  /**
   * Event when the user submitted the edited bookmark.
   */
  @Output() bookmarkChange: EventEmitter<Bookmark> = new EventEmitter<Bookmark>();
  /**
   * Event when the user removed the bookmark.
   */
  @Output() bookmarkRemove: EventEmitter<void> = new EventEmitter();

  linkPattern: string;
  url: FormControl = new FormControl('', [Validators.required]);
  bookmarkForm: FormGroup = new FormGroup({
    url: this.url,
    title: new FormControl('')
  });
  step: Step = Step.Title;

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.settingsService.retrieveByKey('linkPattern').subscribe(pattern => {
      this.linkPattern = pattern;
      this.url.setValidators([Validators.required, Validators.pattern(pattern)]);
    });
  }

  toggleStep(): void {
    this.step = (this.step === Step.Title) ? Step.Url : Step.Title;
  }

  removeBookmark(): void {
    this.bookmarkRemove.emit();
  }

  saveBookmark(): void {
    this.bookmarkChange.emit(this.bookmarkForm.value);
    this.bookmarkForm.markAsPristine();
  }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {StorageType} from '@domain/attachment/storage';
import {IconService} from '@domain/icon/icon.service';

/**
 * Component that handles the file icon by  mime type
 */
@Component({
  selector: 'coyo-file-icon-by-mime-type',
  templateUrl: './file-icon-by-mime-type.component.html',
  styleUrls: ['./file-icon-by-mime-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileIconByMimeTypeComponent implements OnChanges {

  /**
   * Size of the icon, defaults to medium
   */
  @Input() size: 'small' | 'medium' | 'large';

  /**
   * Mime type
   */
  @Input() mimeType: string;

  /**
   * Storage type of the file
   */
  @Input() storage: StorageType;

  icon: string;

  constructor(private iconService: IconService) {
  }

  /**
   * Changes file icon during navigation
   */
  ngOnChanges(): void {
    this.icon = this.iconService.getFileIconByMimeType(this.mimeType, this.storage);
  }
}

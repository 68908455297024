<div class="rss-widget">
  <h3 class="widget-title">{{widget.settings._title || ("WIDGET.RSS.NAME" | translate)}}</h3>
  <div *ngIf="entries$ | async; let entries; else loading">
    <div class="rss-entry" *ngFor="let entry of entries">
      <div class="image" *ngIf="widget.settings.displayImage && entry.imageUrl" aria-hidden="true">
        <a [attr.href]="entry.feedUri" target="_blank">
          <img class="rss-entry-image" [attr.src]="entry.imageUrl">
        </a>
      </div>
      <div class="rss-body">
        <div class="rss-content">
          <h2 class="rss-title">
            <a [attr.href]="entry.feedUri" target="_blank" [innerHTML]="entry.title | trustHtml"></a>
          </h2>
          <p class="rss-description" [innerHTML]="entry.description | trustHtml"></p>
          <div class="rss-time-readmore">
            <coyo-time-ago class="entryDate rss-time" [date]="entry.publishedDate"></coyo-time-ago>
            <p class="entryDate rss-time">-</p>
            <a class="rss-readmore" [attr.href]="entry.feedUri"
               target="_blank">{{ 'WIDGET.RSS.READ_MORE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <div class="loading">
      <coyo-spinner size="sm"></coyo-spinner>
    </div>
  </ng-template>
</div>

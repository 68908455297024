/**
 * Container for HTML tag search results
 */
export class HtmlTagSearchResult {

  constructor(public type: string,
              public start: number,
              public end: number,
              public length: number,
              public tag: string,
              public content: string) {
  }
}

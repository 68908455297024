(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.events
   *
   * @description
   * Events module
   *
   * @requires $stateProvider
   * @requires main.event.eventsConfig
   */
  ModuleConfig.$inject = ["$stateProvider", "eventsConfig"];
  registerTarget.$inject = ["targetServiceProvider"];
  angular
      .module('coyo.events', [
        'coyo.base',
        'commons.ui',
        'commons.resource',
        'commons.target'
      ])
      .config(ModuleConfig)
      .config(registerTarget)
      .constant('eventsConfig', {
        templates: {
          event: {
            show: 'app/modules/events/views/events.show.html',
            timeline: 'app/modules/events/views/events.show.timeline.html',
            information: 'app/modules/events/views/events.show.information.html',
            participants: 'app/modules/events/views/events.show.participants.html'
          }
        },
        list: {
          paging: {
            pageSize: 20
          }
        }
      });

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, eventsConfig) {
    $stateProvider
        .state('main.event', {
          url: '/events?:term&:from&:to&:status&:participationStatus',
          params: {
            term: {
              dynamic: true
            },
            from: {
              dynamic: true
            },
            to: {
              dynamic: true
            },
            status: {
              dynamic: true
            },
            participationStatus: {
              dynamic: true
            }
          },
          templateProvider: ["$timeout", function ($timeout) {
            return $timeout(function () {
              return '<coyo-events></coyo-events>';
            });
          }],
          data: {
            pageTitle: 'MODULE.EVENTS.PAGE_TITLE',
            guide: 'events'
          },
          resolve: {
            currentUser: ["authService", function (authService) {
              return authService.getUser();
            }]
          }
        })
        .state('main.event.create', {
          url: '/create?:host&:public&:lockHost',
          views: {
            '@main': {
              templateProvider: ["$timeout", function ($timeout) {
                return $timeout(function () {
                  return '<coyo-event-create '
                      + '[initial-sender]="{{eventCreateCtrl.host}}"'
                      + '[lock-sender]="{{eventCreateCtrl.lockHost}}"'
                      + '[public]="{{eventCreateCtrl.publicVisibility}}"'
                      + '></coyo-event-create>';
                });
              }],
              controller: ["host", "lockHost", "publicVisibility", function (host, lockHost, publicVisibility) {
                this.host = host;
                this.lockHost = lockHost;
                this.publicVisibility = publicVisibility;
              }],
              controllerAs: 'eventCreateCtrl',
            }
          },
          resolve: {
            host: ["$q", "$stateParams", "SenderModel", "currentUser", function ($q, $stateParams, SenderModel, currentUser) {
              if ($stateParams.host) {
                return SenderModel.getWithPermissions($stateParams.host, {}, ['manage']).then(function (sender) {
                  return sender._permissions.manage ? sender : null;
                }).catch(function () {
                  return null;
                });
              }
              return $q.resolve(currentUser);
            }],
            lockHost: ["$stateParams", function ($stateParams) {
              return $stateParams.lockHost === 'true';
            }],
            publicVisibility: ["$stateParams", function ($stateParams) {
              return $stateParams.public === 'true';
            }]
          }
        })
        .state('main.event.show', {
          url: '/:idOrSlug',
          redirect: 'main.event.show.timeline',
          views: {
            '@main': {
              templateUrl: eventsConfig.templates.event.show,
              controller: 'EventsShowController',
              controllerAs: '$ctrl'
            }
          },
          data: {
            senderParam: 'idOrSlug',
            pageTitle: false
          },
          resolve: {
            event: ["EventModel", "$stateParams", function (EventModel, $stateParams) {
              return EventModel.getWithPermissions({id: $stateParams.idOrSlug}, {with: 'subscriptionInfo'},
                  ['manage', 'canParticipate', 'manageSlots']);
            }],
            senderId: ["event", function (event) {
              return event.id;
            }]
          },
          onEnter: ["event", "$injector", function (event, $injector) {
            $injector.get('ngxPageTitleService').setTitle(event.displayName);
          }]
        })
        .state('main.event.show.settings', {
          url: '/settings',
          views: {
            '@main': {
              templateProvider: ["$timeout", function ($timeout) {
                return $timeout(function () {
                  return '<coyo-event-settings '
                      + '  [event-id]="\'{{eventSettingsCtrl.eventId}}\'"'
                      + '></coyo-event-settings>';
                });
              }],
              controller: ["eventId", function (eventId) {
                this.eventId = eventId;
              }],
              controllerAs: 'eventSettingsCtrl',
            }
          },
          resolve: {
            eventId: ["$stateParams", function ($stateParams) {
              return $stateParams.idOrSlug;
            }]
          }
        })
        .state('main.event.show.timeline', {
          url: '/timeline',
          views: {
            '@main.event.show': {
              templateUrl: eventsConfig.templates.event.timeline
            }
          }
        })
        .state('main.event.show.information', {
          url: '/information',
          views: {
            '@main.event.show': {
              templateUrl: eventsConfig.templates.event.information
            }
          }
        })
        .state('main.event.show.participants', {
          url: '/participants',
          views: {
            '@main.event.show': {
              templateUrl: eventsConfig.templates.event.participants
            }
          }
        });
  }

  function registerTarget(targetServiceProvider) {
    /* register event links for activity notifications */
    targetServiceProvider.register('event', /*@ngInject*/ ["params", "$state", function (params, $state) {
      var stateParams = {idOrSlug: params.slug || params.id};
      return params.go
        ? $state.go('main.event.show', stateParams, {reload: 'main.event.show', inherit: false})
        : $state.href('main.event.show', stateParams, {reload: 'main.event.show', inherit: false});
    }]);
    targetServiceProvider.register('event_overview', /*@ngInject*/ ["params", "$state", function (params, $state) {
      return $state.href('main.event', {}, {inherit: false});
    }]);
  }

})(angular);

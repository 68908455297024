import {NgModule} from '@angular/core';
import {NgOptionHighlightModule} from '@coyo/ng-option-highlight';
import {NgSelectModule} from '@ng-select/ng-select';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DividerModule} from '@shared/divider/divider.module';
import {EventMetaDataModule} from '@shared/event-meta-data/event-meta-data.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SelectUiModule} from '@shared/select-ui/select-ui.module';
import {AvatarOverlayComponent} from '@shared/sender-ui/avatar-overlay/avatar-overlay.component';
import {EventAvatarComponent} from '@shared/sender-ui/event-avatar/event-avatar.component';
import {UIRouterModule} from '@uirouter/angular';
import {TooltipModule} from 'ngx-bootstrap';
import {AppHrefComponent} from './app-href/app-href.component';
import {AvatarImageComponent} from './avatar-image/avatar-image.component';
import './avatar-image/avatar-image.component.downgrade';
import './avatar-overlay/avatar-overlay.component.downgrade';
import {ExternalIconComponent} from './external-icon/external-icon.component';
import './external-icon/external-icon.component.downgrade';
import {GuestListComponent} from './guest-list/guest-list.component';
import './guest-list/guest-list.component.downgrade';
import {SelectSenderComponent} from './select-sender/select-sender.component';
import {SenderAvatarComponent} from './sender-avatar/sender-avatar.component';
import './sender-avatar/sender-avatar.component.downgrade';
import {SenderLinkComponent} from './sender-link/sender-link.component';
import './sender-link/sender-link.component.downgrade';
import {SenderListItemComponent} from './sender-list-item/sender-list-item.component';
import {SenderOptionsViewComponent} from './sender-options-view/sender-options-view.component';
import {UserAvatarImageComponent} from './user-avatar/user-avatar-image/user-avatar-image.component';
import {UserAvatarComponent} from './user-avatar/user-avatar.component';
import './user-avatar/user-avatar.component.downgrade';
import {UserChooserComponent} from './user-chooser/user-chooser.component';
import './user-chooser/user-chooser.component.downgrade';
import {UserFollowComponent} from './user-follow/user-follow.component';
import './user-follow/user-follow.component.downgrade';
import {UserListItemComponent} from './user-list-item/user-list-item.component';
import {UserListComponent} from './user-list/user-list.component';

/**
 * Shared module containing all components responsible for sender ui
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    DividerModule,
    EventMetaDataModule,
    NgOptionHighlightModule,
    NgSelectModule,
    SelectUiModule,
    TooltipModule,
    UIRouterModule
  ],
  declarations: [
    AppHrefComponent,
    AvatarImageComponent,
    AvatarOverlayComponent,
    EventAvatarComponent,
    ExternalIconComponent,
    GuestListComponent,
    SelectSenderComponent,
    SenderAvatarComponent,
    SenderLinkComponent,
    SenderListItemComponent,
    SenderOptionsViewComponent,
    UserAvatarComponent,
    UserAvatarImageComponent,
    UserChooserComponent,
    UserFollowComponent,
    UserListComponent,
    UserListItemComponent
  ],
  exports: [
    AppHrefComponent,
    AvatarImageComponent,
    EventAvatarComponent,
    ExternalIconComponent,
    GuestListComponent,
    SelectSenderComponent,
    SenderAvatarComponent,
    SenderLinkComponent,
    SenderListItemComponent,
    SenderOptionsViewComponent,
    UserAvatarComponent,
    UserAvatarImageComponent,
    UserChooserComponent,
    UserFollowComponent,
    UserListComponent,
    UserListItemComponent
  ],
  entryComponents: [
    AvatarImageComponent,
    AvatarOverlayComponent,
    EventAvatarComponent,
    ExternalIconComponent,
    GuestListComponent,
    SelectSenderComponent,
    SenderAvatarComponent,
    SenderLinkComponent,
    UserAvatarComponent,
    UserChooserComponent,
    UserFollowComponent
  ],
  providers: []
})
export class SenderUIModule {
}

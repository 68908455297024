import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {LatestBlogArticlesWidget} from '@widgets/latest-blog-articles/latest-blog-articles-widget';
import {LatestBlogArticlesWidgetSettingsComponent} from './latest-blog-articles-widget-settings/latest-blog-articles-widget-settings.component';
import {LatestBlogArticlesWidgetComponent} from './latest-blog-articles-widget/latest-blog-articles-widget.component';

/**
 * The configuration for the latest-blog-articles widget.
 */
export const LATEST_BLOG_ARTICLES_WIDGET: WidgetConfig<LatestBlogArticlesWidget> = {
  key: 'blog',
  name: 'WIDGETS.BLOG.NAME',
  description: 'WIDGETS.BLOG.DESCRIPTION',
  icon: 'zmdi-collection-text',
  component: LatestBlogArticlesWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  defaultTitle: 'WIDGETS.BLOG.NAME',
  settings: {
    component: LatestBlogArticlesWidgetSettingsComponent,
    skipOnCreate: false
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true,
  mobileSupportTypes: ['landing-page']
};

import {NgModule} from '@angular/core';
import {UiModule} from '@coyo/ui';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DialogModule} from '@shared/dialog/dialog.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {MaterialModule} from '@shared/material/material.module';
import {ResizedEventModule} from '@shared/resize-event/resized-event.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WidgetVisibilityService} from '@widgets/api/widget-visibility/widget-visibility.service';
import {WidgetState} from '@widgets/api/widget.state';
import {BirthdayModule} from '@widgets/birthday/birthday.module';
import {BlogArticleWidgetModule} from '@widgets/blog-article/blog-article-widget.module';
import {BookmarkingWidgetModule} from '@widgets/bookmarking/bookmarking-widget.module';
import {CodeWidgetModule} from '@widgets/code/code-widget.module';
import {CompleteProfileModule} from '@widgets/complete-profile/complete-profile.module';
import {DownloadWidgetModule} from '@widgets/download/download-widget.module';
import {FacegameWidgetModule} from '@widgets/facegame/facegame-widget.module';
import {HeadlineWidgetModule} from '@widgets/headline/headline-widget.module';
import {HtmlWidgetModule} from '@widgets/html/html-widget.module';
import {IframeWidgetModule} from '@widgets/iframe/iframe-widget.module';
import {ImageWidgetModule} from '@widgets/image/image-widget.module';
import {InterestingColleaguesModule} from '@widgets/interesting-colleagues/interesting-colleagues.module';
import {LatestBlogArticlesWidgetModule} from '@widgets/latest-blog-articles/latest-blog-articles-widget.module';
import {LatestFilesWidgetModule} from '@widgets/latest-files/latest-files-widget.module';
import {LatestWikiArticlesWidgetModule} from '@widgets/latest-wiki-articles/latest-wiki-articles-widget.module';
import {MediaWidgetModule} from '@widgets/media/media-widget.module';
import {PersonalTimelineWidgetModule} from '@widgets/personal-timeline/personal-timeline-widget.module';
import {PluginWidgetModule} from '@widgets/plugin/plugin-widget.module';
import {PollWidgetModule} from '@widgets/poll/poll-widget.module';
import {RichTextWidgetModule} from '@widgets/rich-text/rich-text-widget.module';
import {RssWidgetModule} from '@widgets/rss/rss-widget.module';
import {SingleFileWidgetModule} from '@widgets/single-file/single-file-widget.module';
import {SubscriptionWidgetModule} from '@widgets/subscription/subscription-widget.module';
import {SuggestPagesWidgetModule} from '@widgets/suggest-pages/suggest-pages-widget.module';
import {TeaserWidgetModule} from '@widgets/teaser/teaser-widget.module';
import {UpcomingEventsWidgetModule} from '@widgets/upcoming-events/upcoming-events.widget.module';
import {UserOnlineWidgetModule} from '@widgets/user-online/user-online-widget.module';
import {UserProfileWidgetModule} from '@widgets/user-profile/user-profile-widget.module';
import {WidgetLayoutSaveBarModule} from '@widgets/widget-layout-save-bar/widget-layout-save-bar.module';
import {WikiArticleWidgetModule} from '@widgets/wiki-article/wiki-article-widget.module';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {WidgetChooserComponent} from './api/widget-chooser/widget-chooser.component';
import {WidgetContainerComponent} from './api/widget-container/widget-container.component';
import './api/widget-edit/widget-edit.service.downgrade';
import {WidgetInlineSettingsContainerComponent} from './api/widget-inline-settings-container/widget-inline-settings-container.component';
import './api/widget-inline-settings-container/widget-inline-settings-container.component.downgrade';
import {WidgetLayoutChooserModalComponent} from './api/widget-layout-chooser-modal/widget-layout-chooser-modal.component';
import {WidgetLayoutComponent} from './api/widget-layout/widget-layout.component';
import './api/widget-layout/widget-layout.component.downgrade';
import {WidgetRegistryService} from './api/widget-registry/widget-registry.service';
import {WidgetSettingsContainerComponent} from './api/widget-settings-container/widget-settings-container.component';
import './api/widget-settings-container/widget-settings-container.component.downgrade';
import {WidgetSettingsModalComponent} from './api/widget-settings-modal/widget-settings-modal.component';
import {WidgetSlotComponent} from './api/widget-slot/widget-slot.component';
import './api/widget-slot/widget-slot.component.downgrade';
import {ButtonWidgetModule} from './button/button-widget.module';
import {CalloutWidgetModule} from './callout/callout-widget.module';
import {DividerWidgetModule} from './divider/divider-widget.module';
import {HashtagWidgetModule} from './hashtag/hashtag-widget.module';
import {NewColleaguesModule} from './new-colleagues/new-colleagues.module';
import {TextWidgetModule} from './text/text-widget.module';
import {VideoWidgetModule} from './video/video-widget.module';
import {WelcomeWidgetModule} from './welcome/welcome-widget.module';

export const ngxsModule = NgxsModule.forFeature([WidgetState]);

/**
 * This module provides an API for managing, registering and displaying widgets.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    BirthdayModule,
    BlogArticleWidgetModule,
    BookmarkingWidgetModule,
    ButtonWidgetModule,
    CalloutWidgetModule,
    CompleteProfileModule,
    CodeWidgetModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    DividerWidgetModule,
    DownloadWidgetModule,
    FacegameWidgetModule,
    HashtagWidgetModule,
    HeadlineWidgetModule,
    HtmlWidgetModule,
    LatestFilesWidgetModule,
    IframeWidgetModule,
    ImageWidgetModule,
    NewColleaguesModule,
    PersonalTimelineWidgetModule,
    PluginWidgetModule,
    RssWidgetModule,
    SingleFileWidgetModule,
    SubscriptionWidgetModule,
    TabsModule,
    TextWidgetModule,
    UiModule,
    UpgradeModule,
    UserProfileWidgetModule,
    UpcomingEventsWidgetModule,
    UserOnlineWidgetModule,
    WelcomeWidgetModule,
    RichTextWidgetModule,
    VideoWidgetModule,
    WikiArticleWidgetModule,
    SuggestPagesWidgetModule,
    PollWidgetModule,
    LatestWikiArticlesWidgetModule,
    MaterialModule,
    MediaWidgetModule,
    InterestingColleaguesModule,
    LatestBlogArticlesWidgetModule,
    TeaserWidgetModule,
    WidgetLayoutSaveBarModule,
    DialogModule,
    ResizedEventModule,
    ngxsModule
  ],
  declarations: [
    WidgetChooserComponent,
    WidgetContainerComponent,
    WidgetInlineSettingsContainerComponent,
    WidgetSettingsContainerComponent,
    WidgetSettingsModalComponent,
    WidgetLayoutChooserModalComponent,
    WidgetSlotComponent,
    WidgetLayoutComponent
  ],
  exports: [
    WidgetLayoutComponent,
    WidgetSlotComponent
  ],
  entryComponents: [
    WidgetChooserComponent,
    WidgetContainerComponent,
    WidgetInlineSettingsContainerComponent,
    WidgetSettingsContainerComponent,
    WidgetSettingsModalComponent,
    WidgetLayoutChooserModalComponent,
    WidgetSlotComponent,
    WidgetLayoutComponent
  ],
  providers: [
    WidgetRegistryService,
    WidgetVisibilityService,
  ]
})
export class WidgetsModule {
}

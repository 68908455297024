import {Injectable} from '@angular/core';
import {CoyoStorage} from '@core/storage/storage';
import * as _ from 'lodash';
import {AuthService} from '../../auth/auth.service';
import {LocalStorageService} from '../local-storage/local-storage.service';

/**
 * Service that stores values in the local storage and clears them after the user is logged out
 */
@Injectable({
  providedIn: 'root'
})
export class UserStorageService implements CoyoStorage {

  static readonly USER_STORAGE_KEY: string = 'USER-STORAGE';

  private userStorageCache: any;

  constructor(private localStorageService: LocalStorageService, private authService: AuthService) {
  }

  /**
   * Initialize the storage and listens to authentication changes to clear the storage
   */
  init(): void {
    this.initUserStorage();
    this.authService.isAuthenticated$().subscribe(authenticated => {
      if (!authenticated) {
        this.clear();
      }
    });
  }

  deleteEntry(key: string): void {
    this.userStorageCache = _.omit(this.userStorageCache, key);
    this.updateUserStorage(this.userStorageCache);
  }

  getValue<T>(key: string): T {
    return this.userStorageCache[key];
  }

  setValue(key: string, value: any): void {
    this.userStorageCache[key] = value;
    this.updateUserStorage(this.userStorageCache);
  }

  private clear(): void {
    this.localStorageService.deleteEntry(UserStorageService.USER_STORAGE_KEY);
    this.initUserStorage();
  }

  private initUserStorage(): void {
    this.userStorageCache = this.getUserStorageValue();
    if (!this.userStorageCache) {
      this.userStorageCache = {};
      this.updateUserStorage(this.userStorageCache);
    }
  }

  private getUserStorageValue(): any {
    return this.localStorageService.getValue(UserStorageService.USER_STORAGE_KEY);
  }

  private updateUserStorage(value: any): void {
    this.localStorageService.setValue(UserStorageService.USER_STORAGE_KEY, value);
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {PollWidget} from '@widgets/poll/poll-widget';
import {PollWidgetSettingsComponent} from '@widgets/poll/poll-widget-settings/poll-widget-settings.component';
import {PollWidgetComponent} from '@widgets/poll/poll-widget/poll-widget.component';

/**
 * The configuration for the image widget.
 */
export const POLL_WIDGET: WidgetConfig<PollWidget> = {
  key: 'poll',
  name: 'WIDGET.POLL.NAME',
  description: 'WIDGET.POLL.DESCRIPTION',
  icon: 'zmdi-chart zmdi-hc-rotate-90',
  categories: WidgetCategory.DYNAMIC,
  component: PollWidgetComponent,
  settings: {
    component: PollWidgetSettingsComponent,
    skipOnCreate: false
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

import {Inject, Injectable} from '@angular/core';
import {environment} from '@root/environments/environment';
import {WINDOW} from '@root/injection-tokens';
import Autolinker, {AutolinkerConfig} from 'autolinker';

/**
 * Service to autolink URLs and emails via Autolinker.
 */
@Injectable({
  providedIn: 'root'
})
export class AutolinkService {
  private static KEY_STR: string = '5491a41e-eccb-4a1f-b610-16604c675aec::';
  private static KEY_EXP: RegExp = /5491a41e-eccb-4a1f-b610-16604c675aec::(\d+)/g;

  constructor(@Inject(WINDOW) private window: Window) {
  }

  link(text: string, config?: AutolinkerConfig): string {
    if (!text) {
      return text;
    }

    // FIXME: HACK: replace <pre> and <code> elements to avoid interference with Autolinker
    // see: https://github.com/gregjacobs/Autolinker.js/issues/320
    const ejected = this.ejectCode(text);

    // apply Autolinker
    let result = Autolinker.link(ejected.text, {
      ...environment.autolink,
      ...config,
      phone: false
    } as AutolinkerConfig);

    // open internal links in same tab and remove host name except of public links
    const internalRegExp = new RegExp('href="' + this.window.location.origin + '(?!/web/public-link)([^"]+)" target="_blank" rel="noopener noreferrer"', 'i');
    result = result.replace(internalRegExp, 'href="$1"');

    // FIXME: HACK: insert <pre> and <code> elements back after Autolinker has been run
    // see: https://github.com/gregjacobs/Autolinker.js/issues/320
    return this.injectCode(result, ejected.parts);
  }

  private ejectCode(text: string): { parts: string[], text: string } {
    const parts: string[] = [];
    const result = text
      .replace(/<pre\b[^>]*>([^]*?)<\/pre>/gi, match => AutolinkService.KEY_STR + parts.push(match))
      .replace(/<code\b[^>]*>([^]*?)<\/code>/gi, match => AutolinkService.KEY_STR + parts.push(match));
    return {parts, text: result};
  }

  private injectCode(text: string, parts: string[]): string {
    return text.replace(AutolinkService.KEY_EXP, (match, i) => parts[parseInt(i, 10) - 1]);
  }
}

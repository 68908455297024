import {DOCUMENT} from '@angular/common';
import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EmojiData, EmojiEvent} from '@coyo/ngx-emoji-mart/ngx-emoji';
import {TranslateService} from '@ngx-translate/core';
import {PopperController} from 'ngx-popper';
import Popper from 'popper.js';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * An emoji picker that can be attached to an input or textarea.
 */
@Component({
  selector: 'coyo-emoji-picker',
  templateUrl: './emoji-picker.component.html'
})
export class EmojiPickerComponent implements OnInit {
  /**
   * The corresponding input or textarea that this picker is attached to.
   */
  @Input() input: HTMLInputElement | HTMLTextAreaElement;

  /**
   * Event emitter that fires when an emoji has been selected.
   */
  @Output() selected: EventEmitter<EmojiData> = new EventEmitter();

  @ViewChild('popper', {
    static: true
  }) popperController: PopperController;

  readonly modifiers: Popper.Modifiers = Popper.Defaults.modifiers;

  i18n$: Observable<any>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.i18n$ = this.translateService.get([
      'EMOJI.SEARCH',
      'EMOJI.NOT_FOUND',
      'EMOJI.CATEGORIES.SEARCH',
      'EMOJI.CATEGORIES.RECENT',
      'EMOJI.CATEGORIES.PEOPLE',
      'EMOJI.CATEGORIES.NATURE',
      'EMOJI.CATEGORIES.FOODS',
      'EMOJI.CATEGORIES.ACTIVITY',
      'EMOJI.CATEGORIES.PLACES',
      'EMOJI.CATEGORIES.OBJECTS',
      'EMOJI.CATEGORIES.SYMBOLS',
      'EMOJI.CATEGORIES.FLAGS',
      'EMOJI.CATEGORIES.CUSTOM'
    ]).pipe(map(i18n => ({
      search: i18n['EMOJI.SEARCH'],
      notfound: i18n['EMOJI.NOT_FOUND'],
      categories: {
        search: i18n['EMOJI.CATEGORIES.SEARCH'],
        recent: i18n['EMOJI.CATEGORIES.RECENT'],
        people: i18n['EMOJI.CATEGORIES.PEOPLE'],
        nature: i18n['EMOJI.CATEGORIES.NATURE'],
        foods: i18n['EMOJI.CATEGORIES.FOODS'],
        activity: i18n['EMOJI.CATEGORIES.ACTIVITY'],
        places: i18n['EMOJI.CATEGORIES.PLACES'],
        objects: i18n['EMOJI.CATEGORIES.OBJECTS'],
        symbols: i18n['EMOJI.CATEGORIES.SYMBOLS'],
        flags: i18n['EMOJI.CATEGORIES.FLAGS'],
        custom: i18n['EMOJI.CATEGORIES.CUSTOM']
      }
    })));
  }

  /**
   * Selects the given emoji.
   *
   * @param $event the event containing the emoji
   */
  emojiSelect($event: EmojiEvent): void {
    $event.$event.preventDefault();
    this.insertTextAtCaret($event.emoji.native);
    this.selected.emit($event.emoji);
  }

  /**
   * Listener for escape keydown event to close the emoji picker.
   */
  @HostListener('document:keydown.esc')
  @HostListener('document:keydown.enter')
  closePicker(): void { this.popperController.hide(); }

  /*
   * Inserts the given text at the current caret position.
   */
  private insertTextAtCaret(text: string): void {
    const selectionStart =  this.input.selectionStart;
    const prefix = this.input.value.substring(0, selectionStart);
    const suffix = this.input.value.substring(this.input.selectionEnd, this.input.value.length);

    this.updateValue(this.input, prefix + text + suffix);

    this.input.setSelectionRange(selectionStart + text.length, selectionStart + text.length);
    this.input.focus();
  }

  /*
   * Updates the value of the given input or textarea element and dispatches an `input`
   * event so angular bindings are updated (IE11 compatible).
   */
  private updateValue(elem: HTMLInputElement | HTMLTextAreaElement, value: string): void {
    elem.value = value;

    let event: Event;
    if (typeof (Event) === 'function') {
      event = new Event('input', {bubbles: false, cancelable: true});
    } else {
      event = this.document.createEvent('Event');
      event.initEvent('input', false, true);
    }

    elem.dispatchEvent(event);
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {PersonalTimelineWidget} from '@widgets/personal-timeline/personal-timeline-widget';
import {PersonalTimelineWidgetComponent} from './personal-timeline-widget/personal-timeline-widget.component';

export const PERSONAL_TIMELINE_WIDGET: WidgetConfig<PersonalTimelineWidget> = {
  key: 'personal-timeline',
  name: 'WIDGET.PERSONAL_TIMELINE.NAME',
  description: 'WIDGET.PERSONAL_TIMELINE.DESCRIPTION',
  icon: 'zmdi-comment-list',
  categories: WidgetCategory.PERSONAL,
  component: PersonalTimelineWidgetComponent,
  renderOptions: {
    printable: false,
    panels: {
      noPanel: true
    }
  },
  whitelistExternal: true
};

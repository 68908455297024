import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';

/**
 * A infinite scrolling directive implements with an interaction observer which always emits
 * when the viewed object enters the viewport.
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
@Directive({
  selector: '[coyoInfiniteScrolling]'
})
export class InfiniteScrollingDirective implements AfterViewInit, OnDestroy {

  /**
   * Emit when the sentinel object is viewable on the viewport
   */
  @Output() visible: EventEmitter<any> = new EventEmitter();

  private intersectionObserver: IntersectionObserver;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.intersectionObserver = new IntersectionObserver(entries => {
      this.intersect(entries);
    });
    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  private intersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach( entry => {
      if (entry.isIntersecting) {
        this.visible.emit();
      }
    });
  }

  ngOnDestroy(): void {
    this.intersectionObserver.disconnect();
  }
}

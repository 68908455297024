(function () {
  'use strict';

  CopyFileLinkController.$inject = ["$injector", "$window"];
  angular
      .module('commons.ui')
      .component('coyoCopyFileLink', copyFileLink())
      .controller('CopyFileLinkController', CopyFileLinkController);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoCopyFileLink:coyoCopyFileLink
   * @element ANY
   * @restrict AE
   * @scope
   *
   * @description
   * Renders a span which copy a link to the given file to the clipboard
   *
   * @param {object} file
   * The file to copy the link for.
   *
   * @param {string} label
   * The message key for the label
   *
   * @param {string} button
   * Whether to display the component as button.
   *
   * @requires $injector
   * @requires $window
   */
  function copyFileLink() {
    return {
      templateUrl: 'app/commons/ui/components/file-library/copy-link/copy-link.html',
      bindings: {
        file: '<',
        label: '@?',
        button: '@?'
      },
      controller: 'CopyFileLinkController',
      controllerAs: '$ctrl'
    };
  }

  function CopyFileLinkController($injector, $window) {
    var vm = this;
    vm.$onInit = _init;
    vm.success = success;

    function success() {
      $injector.get('ngxNotificationService').success('FILE_LIBRARY.COPY_LINK.SUCCESS');
    }

    function _init() {
      var fileName = vm.file.displayName.replace(/\//g, '');
      vm.link = $window.location.protocol
          + '//' + $window.location.host
          + '/files/' + vm.file.senderId
          + '/'
          + vm.file.id
          + '/'
          + $window.encodeURIComponent(_.replace(fileName, new RegExp('\\.', 'g'), ' '));
    }
  }

})();

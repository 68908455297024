(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.registration
   *
   * @description
   * # Registration module #
   * The registration module renders the registration page for external users.
   */
  ModuleConfig.$inject = ["$stateProvider", "registrationConfig"];
  angular
      .module('coyo.registration', [
        'coyo.base',
        'commons.auth',
        'commons.ui',
        'commons.i18n'
      ])
      .config(ModuleConfig)
      .constant('registrationConfig', {
        templates: {
          registerExternal: 'app/modules/registration/views/register-external/registration.register-external.html'
        }
      });

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, registrationConfig) {
    $stateProvider.state('front.register-external', {
      url: '/register-external?token',
      templateUrl: registrationConfig.templates.registerExternal,
      controller: 'RegisterExternalController',
      controllerAs: '$ctrl',
      data: {
        authenticate: false
      },
      resolve: {
        passwordPattern: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieveByKey('passwordPattern');
        }],
        token: ["$stateParams", function ($stateParams) {
          return $stateParams.token;
        }]
      }
    });
  }

})(angular);

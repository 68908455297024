<form name="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate data-test="timeline-edit-modal">
  <h1 mat-dialog-title translate="MODULE.TIMELINE.EDIT.MODAL.TITLE"></h1>
  <mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
  <div mat-dialog-content>
    <p *ngIf="showMessageHintSubject | async" class="alert alert-warning" translate="MODULE.TIMELINE.EDIT.MODAL.PERMISSIONS.WARNING"></p>
    <textarea id="message" name="message" formControlName="message"
              class="form-control resize-v resize-max"
              autosize coyoMention cdkFocusInitial data-test="input-timeline-edit">
    </textarea>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button type="submit" color="primary" data-test="button-timeline-edit-save"
            [disabled]="!formGroup.valid || sendFormData">{{'SAVE' | translate}}</button>
  </div>
</form>

(function (angular) {
  'use strict';

  MessagingChannelController.$inject = ["$injector", "$scope", "$rootScope", "$filter", "socketService", "MessageModel", "Pageable", "$interval", "$q", "$timeout", "$element", "backendUrlService", "coyoEndpoints", "optimisticService", "messageHandler", "optimisticTypeMessage", "optimisticStatus", "utilService", "messageChannelUserService", "socketReconnectDelays", "rxjs"];
  angular
      .module('coyo.messaging')
      .controller('MessagingChannelController', MessagingChannelController);

  function MessagingChannelController($injector, $scope, $rootScope, $filter, socketService, MessageModel, Pageable, $interval, $q,
                                      $timeout, $element, backendUrlService, coyoEndpoints, optimisticService,
                                      messageHandler, optimisticTypeMessage, optimisticStatus, utilService,
                                      messageChannelUserService, socketReconnectDelays, rxjs) {
    var vm = this;
    vm.$onInit = onInit;

    vm.loadMore = loadMore;
    vm.onMessageClick = onMessageClick;
    vm.isCurrentDay = isCurrentDay;
    vm.handleFileDrop = handleFileDrop;
    vm.handleFileDrag = handleFileDrag;
    vm.isFileDragging = false;
    vm.saveMessage = _saveMessage;
    vm.deleteMessage = deleteMessageFromLocalStorage;

    var initialPageSize = 30;
    var loadMorePageSize = 10;
    var showPendingIconAfter = 5000;
    var timeoutPromises = [];

    function loadMore() {
      if (!vm.loading && (!vm.currentPage || vm.messages.length < vm.currentPage.totalElements)) {
        vm.loading = true;

        var pageSize = (_.isEmpty(vm.messages)) ? initialPageSize : loadMorePageSize;
        var pageable = new Pageable(0, pageSize, 'created,desc', vm.messages.length);

        return MessageModel.pagedQuery(pageable, {}, {
          channelId: vm.channel.id,
          userId: vm.currentUser.id
        }).then(function (page) {
          if (!vm.currentPage) {
            vm.focusMessageFormField = true;
          }
          vm.currentPage = page;
          vm.messages.unshift.apply(vm.messages, page.content.reverse());
          _groupMessages();
          _markCurrentChannelAsRead();
        }).finally(function () {
          vm.loading = false;
          return $q.resolve();
        });
      }
      return $q.reject();
    }

    function onMessageClick(messageEntity) {
      if (!_.isEmpty(messageEntity.error)) {
        utilService.requireInternetConnection().then(function () {
          _removeMessage(messageEntity);
          _resendFailedMessage(messageEntity);
        }, _handleNoInternetConnectionError);
      }
    }

    function handleFileDrop(files) {
      if (vm.isFileDragging) {
        vm.isFileDragging = false;
        vm.droppedFiles.next(files);
      }
    }

    function handleFileDrag(isDragging) {
      vm.isFileDragging = isDragging;
      //Still kind of a mystery why this is necessary (manual update of the dom), but thanks to Finn Schröder for the tip.
      $scope.$apply();
    }

    function isCurrentDay(date) {
      var inputDate = new Date();
      inputDate = _resetTime(inputDate.setTime(date));
      var currentDate = _resetTime(new Date());
      return inputDate === currentDate;
    }

    function deleteMessageFromLocalStorage(message) {
      messageHandler.removeLocalMessage(message).then(function () {
        _removeMessage(message);
      });
    }

    function _removeMessage(message) {
      var clientMessageId = message.clientMessageId;
      var messageIndex = _.findLastIndex(vm.messages, ['clientMessageId', clientMessageId]);
      if (messageIndex > -1) {
        _.pullAt(vm.messages, messageIndex);
      }

      var dateValue = _resetTime(message.created).valueOf();
      _.forEachRight(vm.messageGroups[dateValue], function (group, groupIndex) {
        var itemIndex = _.findIndex(group, function (item) {
          return _.get(item, 'clientMessageId') === clientMessageId;
        });
        if (itemIndex > -1) {
          _.pullAt(vm.messageGroups[dateValue][groupIndex], itemIndex);
          return false;
        }
        return true;
      });
    }

    function _resetTime(date) {
      return new Date(date).setHours(0, 0, 0, 0);
    }

    function _resetToMinute(date) {
      return new Date(date).setSeconds(0, 0);
    }

    function _groupMessages() {
      vm.messageGroups = {};
      var messages = $filter('orderBy')(vm.messages, 'created');
      var timeGroup = [];
      var tmpTimeGroup = [];
      var checkMessage = null;
      _.forEach(messages, function (message, index) {
        if (index === 0) {
          tmpTimeGroup.push(message);
          checkMessage = message;
          return;
        }
        var time1 = checkMessage !== null ? checkMessage.created : null;
        if (time1 !== null) {
          time1 = _resetToMinute(time1);
        }
        var time2 = message.created !== null ? message.created : null;
        if (time2 !== null) {
          time2 = _resetToMinute(time2);
        }
        if (!!time1 && !!time2 && _.isEqual(time1.valueOf(), time2.valueOf()) && _.isEqual(checkMessage.author,
            message.author)) {
          tmpTimeGroup.push(message);
        } else {
          timeGroup.push(tmpTimeGroup);
          tmpTimeGroup = [];
          tmpTimeGroup.push(message);
          checkMessage = message;
        }
      });
      timeGroup.push(tmpTimeGroup);

      var comparisonDate = null;
      _.forEach(timeGroup, function (group) {
        var groupDate = _resetTime(new Date(group[0].created));
        if (groupDate !== comparisonDate) {
          comparisonDate = groupDate;
        }
        var groupDateValue = groupDate.valueOf();
        if (angular.isUndefined(vm.messageGroups[groupDateValue])) {
          vm.messageGroups[groupDateValue] = [];
        }
        vm.messageGroups[groupDateValue].push(group);
      });
      vm.days = _.keys(vm.messageGroups);
    }

    function _resendFailedMessage(messageEntity) {
      var entityId = _.get(messageEntity, 'clientMessageId');
      var optimisticMessage = optimisticService.findWith(entityId, {'status': optimisticStatus.ERROR});
      if (_.isEmpty(optimisticMessage)) {
        return $q.reject();
      }
      optimisticMessage.status = optimisticStatus.PENDING;
      delete optimisticMessage.entity.error;
      messageEntity.created = Date.now().valueOf();
      _insertMessageToMessageGroups(messageEntity);
      return optimisticService.saveEntity(optimisticMessage, optimisticTypeMessage).then(function () {
        messageEntity.optimisticPending = true;
        messageEntity.showSpinner = true;
        return messageHandler.sendMessage(messageEntity)
            .then(_deleteMessageWhenDuplicateMessageError);
      });
    }

    function _deleteMessageWhenDuplicateMessageError(data) {
      if (data && data.error && data.error.errorStatus === 'DUPLICATE_CHAT_MESSAGE') {
        deleteMessageFromLocalStorage(data.message);
      }
      return data;
    }

    function _insertMessageToMessageGroups(message) {
      var itemExisting = -1;
      if (message.clientMessageId) {
        itemExisting = _.findIndex(vm.messages, {clientMessageId: message.clientMessageId});
      } else if (message.id) {
        itemExisting = _.findIndex(vm.messages, {id: message.id});
      }
      if (itemExisting > -1) {
        return;
      }
      var messageDate = _resetToMinute(message.created ? message.created : Date.now());
      var dateValue = _resetTime(messageDate).valueOf();
      var itemIndex = -1;
      var lastGroupIndex = -1;
      var lastMessage = _.last($filter('orderBy')(vm.messages, 'created'));
      if (_.isEqual(_.get(lastMessage, 'author.id'), _.get(message, 'author.id'))) {
        _.forEachRight(vm.messageGroups[dateValue], function (group, groupIndex) {
          lastGroupIndex = groupIndex;
          itemIndex = _.findIndex(group, function (item) {
            return _resetToMinute(_.get(item, 'created')).valueOf() === messageDate.valueOf();
          });
          return itemIndex <= -1;
        });
      }
      if (lastGroupIndex > -1 && itemIndex > -1) {
        vm.messageGroups[dateValue][lastGroupIndex].push(message);
      } else {
        if (angular.isUndefined(vm.messageGroups[dateValue])) {
          vm.messageGroups[dateValue] = [];
          if (_.indexOf(vm.days, dateValue) === -1) {
            vm.days.push(dateValue);
          }
        }
        vm.messageGroups[dateValue].push([message]);
      }
      vm.messages.push(message);
    }

    function _saveMessage(submittedMessage) {

      var newMessageModel = new MessageModel({
        userId: vm.currentUser.id,
        author: _.pick(vm.currentUser, ['id', 'tenant', 'entityId', 'slug', 'displayName']),
        channelId: vm.channel.id,
        attachments: _.get(submittedMessage, 'attachments', []),
        fileLibraryAttachments: _.get(submittedMessage, 'fileLibraryAttachments', []),
        data: {
          message: _.get(submittedMessage, 'data.message', '')
        }
      });

      return optimisticService.saveEntity(newMessageModel, optimisticTypeMessage).then(function (optimisticEntity) {
        var newMessage = optimisticEntity;
        _.set(newMessage, 'entity.clientMessageId', optimisticEntity.entityId);
        var newMessageString = _.get(newMessage, 'entity.data.message', '');
        var newAttachment = _.get(newMessage, 'entity.attachments', []);
        var newFileLibraryAttachment = _.get(newMessage, 'entity.fileLibraryAttachments', []);
        if (newMessageString.trim().length === 0 && newAttachment.length === 0 && newFileLibraryAttachment.length === 0) {
          return;
        }
        vm.focusMessageFormField = false;
        newMessage.status = optimisticStatus.PENDING;
        var messageObj = angular.copy(newMessage);
        _insertMessageToMessageGroups(_.get(messageObj, 'entity'));
        optimisticService.saveEntity(messageObj, optimisticTypeMessage).then(function (message) {
          message.entity.created = _.get(message, 'entity.created', Date.now());
          vm.focusMessageFormField = true;
          _setDelayedPendingStatus(message);
          messageHandler.sendMessage(message.entity).then(function (messageResponse) {
            _processIncomingMessage(messageResponse, false);
          });
        });
      });
    }

    function _setDelayedPendingStatus(message) {
      var timeoutPromise = $timeout(function () {
        message.entity.optimisticPending = true;
        if (optimisticService.find(message.entityId) && !message.entity.error) {
          message.entity.showSpinner = true;
        }
      }, showPendingIconAfter);
      timeoutPromises.push({
        timeout: timeoutPromise,
        clientMessageId: message.entity.clientMessageId
      });
    }

    function _loadPendingMessagesFromLocalStorage() {
      var messages = _.filter(messageHandler.getAllMessagesFromStorage(), function (optimisticEntity) {
        return !!optimisticEntity.entity && optimisticEntity.entity.channelId === vm.channel.id
            && optimisticEntity.status !== optimisticStatus.NEW;
      });
      _.each(messages, function (optimisticEntity) {
        var message = optimisticEntity.entity;
        message.optimisticPending = (optimisticEntity.status === optimisticStatus.PENDING);
        message.error = message.error ? message.error
          : optimisticEntity.status === optimisticStatus.ERROR ? optimisticEntity.status : null;
        _.set(message, 'clientMessageId', optimisticEntity.entityId);
        if (!_.isEmpty(_.find(vm.messages, ['clientMessageId', optimisticEntity.entityId]))) {
          optimisticService.remove(optimisticEntity.entityId);
        } else {
          _insertMessageToMessageGroups(message);
        }
      });
    }

    function _replaceOptimisticMessageCopyWithActual(message) {
      var optimisticEntityId = _.get(message, 'clientMessageId');
      var messageIndex = _.findLastIndex(vm.messages, ['clientMessageId', optimisticEntityId]);
      _.pullAt(vm.messages, messageIndex);
      vm.messages.push(new MessageModel(message));
      var dateValue = _resetTime(message.created).valueOf();
      _.forEachRight(vm.messageGroups[dateValue], function (group, groupIndex) {
        var itemIndex = _.findIndex(group, function (item) {
          return _.get(item, 'clientMessageId') === optimisticEntityId;
        });
        if (itemIndex > -1) {
          _.assign(vm.messageGroups[dateValue][groupIndex][itemIndex], message);
          return false;
        }
        return true;
      });
    }

    function _messageUpdatedEventHandler(event) {
      $timeout(function () {
        _replaceOptimisticMessageCopyWithActual(event.content);
      }, socketReconnectDelays.MESSAGES_RELOAD_DELAY);
    }

    function _incomingMessageEventHandler(event) {
      _incomingMessageHandler(event.content);
    }

    function _incomingMessageHandler(incomingMessage) {
      if (!incomingMessage || incomingMessage.channelId !== vm.channel.id) {
        return;
      }
      var insertMessage = true;

      // todo: refactor this by a smarter optimistic insert
      var dateValue = _resetTime(incomingMessage.created).valueOf();
      _.forEachRight(vm.messageGroups[dateValue], function (group) {
        var itemIndex = _.findIndex(group, function (item) {
          return _.get(item, 'clientMessageId') === _.get(incomingMessage, 'clientMessageId', 'no-id')
              && !_.get(incomingMessage, 'data.notification');
        });
        if (itemIndex > -1) {
          insertMessage = false;
          return false;
        }
        return true;
      });
      _processIncomingMessage(incomingMessage, insertMessage);
    }

    function _processIncomingMessage(message, insertMessage) {
      if (!_.isEmpty(message) && !_.find(vm.messages, ['id', message.id])) {
        messageHandler.removeLocalMessage(message).then(function () {
          _unsetPendingStatusForMessage(message);
          if (insertMessage) {
            _markCurrentChannelAsRead();
            _insertMessageToMessageGroups(message);
          } else {
            _replaceOptimisticMessageCopyWithActual(message);
          }
        });
      }
    }

    function _cancelAndRemoveTimeout(message) {
      var timeoutPromise = _.find(timeoutPromises, {clientMessageId: message.clientMessageId});
      if (timeoutPromise) {
        $timeout.cancel(timeoutPromise.timeout);
        _.remove(timeoutPromises, function (timeout) {
          return timeout.clientMessageId === message.clientMessageId;
        });
      }
    }

    function _unsetPendingStatusForMessage(message) {
      _cancelAndRemoveTimeout(message);
      message.optimisticPending = false;
      message.showSpinner = false;
    }

    function _setupReconnectHandler() {
      return $rootScope.$on('socketService:reconnected', _reconnectHandler);

      function _reconnectHandler() {
        $timeout(function () {
          var pageable = new Pageable(0, loadMorePageSize, 'created,desc');
          MessageModel.pagedQuery(pageable, {}, {
            channelId: vm.channel.id,
            userId: vm.currentUser.id
          }).then(function (page) {
            var newCount = 0;
            _.forEach(page.content.reverse(), function (message) {
              if (angular.isUndefined(_.find(vm.messages, {id: message.id}))) {
                _incomingMessageHandler(message);
                newCount++;
              }
            });
            if (newCount >= loadMorePageSize) {
              $scope.$broadcast('messaging-channel:refresh');
            } else {
              _markCurrentChannelAsRead();
            }
          });
        }, socketReconnectDelays.MESSAGES_RELOAD_DELAY);
      }
    }

    function _markCurrentChannelAsRead() {
      messageHandler.markAsRead(vm.currentUser.id, vm.channel.id);
    }

    function _handleNoInternetConnectionError() {
      $injector.get('ngxNotificationService').error('ERRORS.NO_INTERNET_CONNECTION');
    }

    function _fetchDisabledChannelMembers() {
      var deferred = $q.defer();
      if (vm.channel.type !== 'SINGLE') { // only required for single channels to disable message form
        return deferred.resolve();
      }
      messageChannelUserService.fetchDisabledUsers(vm.channel.id)
          .then(function (response) {
            vm.disabledUsers = response.data;
            deferred.resolve();
          }, function () {
            deferred.reject('Fetching of disabled channel members failed.');
          });
      return deferred.promise;
    }

    function _clearLocalStorage() {
      optimisticService.clear();
    }

    function _cleanup() {
      _.forEach(timeoutPromises, function (timeoutPromise) {
        $timeout.cancel(timeoutPromise.timeout);
      });
    }

    function onInit() {
      vm.droppedFiles = new rxjs.Subject();
      vm.uploads = {};
      vm.messages = [];
      vm.saving = false;
      vm.backendUrl = backendUrlService.getUrl();
      vm.previewUrl = coyoEndpoints.messaging.preview;
      vm.messageGroups = {};
      vm.days = [];
      vm.disabledUsers = [];
      vm.cursorPosition = 0;

      var _unsubsribeMessageCreatedFn = socketService
          .subscribe('/user/topic/messaging', _incomingMessageEventHandler, 'messageCreated');
      var _unsubsribeMessageUpdatedFn = socketService
          .subscribe('/user/topic/messaging', _messageUpdatedEventHandler, 'messageUpdated');
      var _unregisterReconnectHandlerFn = _setupReconnectHandler();

      messageHandler.cleanupLocalMessages()
          .then(_fetchDisabledChannelMembers)
          .then(loadMore)
          .then(_loadPendingMessagesFromLocalStorage)
          .then(messageHandler.resendPendingMessages);

      $rootScope.$on('authService:logout:success', _clearLocalStorage);
      $scope.$on('$destroy', function () {
        _unsubsribeMessageCreatedFn();
        _unsubsribeMessageUpdatedFn();
        _unregisterReconnectHandlerFn();
        _cleanup();
      });
    }
  }

})(angular);

import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Type,
  ViewContainerRef
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {IndividualAppSettingsComponent} from '@apps/api/individual-app-settings/individual-app-settings.component';
import {App} from '../app';

/**
 * Wrapper component for app settings. Will create the settings component that is defined in the app configuration.
 */
@Component({
  selector: 'coyo-individual-app-settings-container',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndividualAppSettingsContainerComponent implements OnInit, OnChanges {

  /**
   * The configuration for the app
   */
  @Input() component: Type<IndividualAppSettingsComponent<AppSettings>>;

  /**
   * The formgroup to add the form controls to
   */
  @Input() parentForm: FormGroup;

  /**
   * The app if it already exists. Used to set the form control values.
   */
  @Input() app?: App<AppSettings>;

  private componentRef: ComponentRef<IndividualAppSettingsComponent<AppSettings>>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private viewContainerRef: ViewContainerRef) {
  }

  get individualAppSettings(): IndividualAppSettingsComponent<AppSettings> {
    return this.componentRef.instance;
  }

  ngOnInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    this.updateComponentRefValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateComponentRefValues();
  }

  private updateComponentRefValues(): void {
    if (this.componentRef) {
      this.componentRef.instance.parentForm = this.parentForm;
      this.componentRef.instance.app = this.app;
    }
  }

}

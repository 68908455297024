import {Overlay, OverlayConfig, OverlayRef, ScrollStrategyOptions} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType} from '@angular/cdk/portal';
import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, Injector, StaticProvider} from '@angular/core';
import {OverlayComponent} from '@shared/overlay/overlay-component';
import {Observable} from 'rxjs';

/**
 * Service to create basic material overlays.
 */
@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  constructor(private injector: Injector,
              private overlay: Overlay,
              @Inject(DOCUMENT) private document: Document) {
  }

  get scrollStrategies(): ScrollStrategyOptions {
    return this.overlay.scrollStrategies;
  }

  /**
   * Opens a new overlay.
   *
   * @param component the content of the overlay
   * @param config the overlay configuration
   * @param data additional data passed to the overlay
   * @return an observable that completes when the modal is closed.
   */
  open<S, T extends OverlayComponent<S>>(component: ComponentType<T>, config?: OverlayConfig, data?: StaticProvider[]): Observable<S> {
    const overlayRef = this.overlay.create(config);
    const overlayInjector = this.createInjector(overlayRef, data || []);
    const overlayPortal = new ComponentPortal(component, null, overlayInjector);
    const componentRef = overlayRef.attach(overlayPortal);

    componentRef.instance.setLastFocusedElement(this.document.activeElement as HTMLElement);
    return componentRef.instance.result$;
  }

  private createInjector(overlayRef: OverlayRef, data: StaticProvider[]): Injector {
    data.push({provide: OverlayRef, useValue: overlayRef});
    return Injector.create({
      providers: data,
      parent: this.injector
    });
  }
}

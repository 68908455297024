import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@core/auth/auth.service';
import {UserService} from '@domain/user/user.service';
import {Ng1DeepLinkService} from '@root/typings';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {StateService} from '@uirouter/core';
import {NG1_DEEP_LINK_SERVICE} from '@upgrade/upgrade.module';
import {merge, Observable, of, Subject} from 'rxjs';
import {catchError, map, startWith} from 'rxjs/operators';

@Component({
  selector: 'coyo-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit {

  /**
   * The pattern for passwords
   */
  @Input()
  passwordPattern: string;

  changePwForm: FormGroup;

  asyncErrors: Observable<{ error?: string }>;

  private wrongPassword$: Subject<boolean>;

  private submitting: boolean;

  constructor(private fb: FormBuilder, private userService: UserService,
              private stateService: StateService,
              private authService: AuthService,
              @Inject(NG1_DEEP_LINK_SERVICE) private deepLinkService: Ng1DeepLinkService) {
    this.wrongPassword$ = new Subject<boolean>();
  }

  ngOnInit(): void {
    this.changePwForm = this.fb.group({
      oldPassword: [null, Validators.required],
      newPassword: [null, [Validators.required, Validators.pattern(this.passwordPattern)]],
      newPasswordConfirm: [null, Validators.required]
    }, {
      validators: [
        CoyoValidators.allEqual('newPassword', 'newPasswordConfirm'),
        CoyoValidators.notEqual('oldPassword', 'newPassword')
      ]
    });

    this.asyncErrors = merge(this.changePwForm.get('oldPassword').valueChanges.pipe(map(() => false)),
      this.wrongPassword$).pipe(startWith(false)).pipe(map(error => {
      if (error) {
        this.changePwForm.get('oldPassword').setErrors({wrongPassword: true});
        return {error: 'PASSWORD.CHANGE.ERRORS.OLD_PASSWORD_WRONG'};
      } else {
        return {};
      }
    }));
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }
    this.submitting = true;

    this.userService.setPassword(
      this.changePwForm.get('oldPassword').value,
      this.changePwForm.get('newPassword').value,
      false
    )
      .pipe(map(() => true))
      .pipe(catchError(() => of(false)))
      .subscribe(changed => {
        if (changed) {
          const toState = this.deepLinkService.getReturnToState() || 'main';
          const toStateParams = this.deepLinkService.getReturnToStateParams() || {};
          this.stateService.go(toState, toStateParams).then(() => {
            this.deepLinkService.clearReturnToState();
          });
        } else {
          this.wrongPassword$.next(true);
        }
        this.submitting = false;
      });
  }

  cancel(): void {
    this.authService.logout();
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StripHtmlPipe} from '@shared/strip-html/strip-html.pipe';
import './strip-html.module.downgrade';

/**
 * Module exporting the strip html pipe.
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    StripHtmlPipe
  ],
  exports: [
    StripHtmlPipe
  ]
})
export class StripHtmlModule {
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Skeleton} from '@coyo/ui';
import {Document} from '@domain/file/document';
import {DocumentService} from '@domain/file/document/document.service';
import {File} from '@domain/file/file.ts';
import {FileService} from '@domain/file/file/file.service';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {Store} from '@ngxs/store';
import {Ng1fileDetailsModalService} from '@root/typings';
import {NG1_FILE_DETAILS_MODAL_SERVICE} from '@upgrade/upgrade.module';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UpdateWidgetSettings} from '@widgets/api/widget.actions';
import {SingleFileWidget} from '@widgets/single-file/single-file-widget';
import {SingleFileWidgetSettings} from '@widgets/single-file/single-file-widget-settings';
import {forkJoin, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';

interface SingleFileWidgetComponentState {
  file: File;
  sender: Sender;
  filePreview?: FilePreview;
}

@Component({
  selector: 'coyo-single-file-widget',
  templateUrl: './single-file-widget.component.html',
  styleUrls: ['./single-file-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleFileWidgetComponent extends WidgetComponent<SingleFileWidget> implements OnInit, OnChanges, OnDestroy {

  constructor(cd: ChangeDetectorRef,
              private documentService: DocumentService,
              @Inject(NG1_FILE_DETAILS_MODAL_SERVICE) private fileDetailsModalService: Ng1fileDetailsModalService,
              private fileService: FileService,
              private senderService: SenderService,
              private store: Store) {
    super(cd);
  }

  state$: Observable<SingleFileWidgetComponentState>;
  previewUrl: string = '/web/senders/{{groupId}}/documents/{{id}}';
  skeletons: Skeleton[] = [
    { top: 0, left: 0, width: '100%', height: 225 },
    { top: 245, left: 'calc(50% - 17.5%)', width: '35%', height: 20 },
    { top: 280, left: 'calc(50% - 13.75%)', width: '27.5%', height: 15 },
    { top: 300, left: 'calc(50% - 20%)', width: '40%', height: 15 }
  ];

  private changeSubject$: ReplaySubject<{fileId: string, senderId: string}> = new ReplaySubject();
  private onDestroy$: Subject<void> = new Subject<void>();
  nameControl: FormControl;

  ngOnInit(): void {
    this.state$ = this.changeSubject$
      .pipe(startWith({
        fileId: this.widget.settings._fileId,
        senderId: this.widget.settings._senderId
      }))
      .pipe(distinctUntilChanged((x, y) => x.fileId === y.fileId && x.senderId === y.senderId))
      .pipe(switchMap(() => forkJoin([
        this.fileService.getFile(this.widget.settings._fileId, this.widget.settings._senderId)
          .pipe(catchError(() => of(undefined))),
        this.senderService.get(this.widget.settings._senderId)
      ]))).pipe(
        map(([file, sender]) => ({file, sender})),
        tap((state: SingleFileWidgetComponentState) => {
          if (state.file) {
            state.filePreview = this.toFilePreview(state.file);
          }
        }));

    this.nameControl = new FormControl(this.widget.settings.title);
    this.nameControl.valueChanges.pipe(debounceTime(500), takeUntil(this.onDestroy$)).subscribe(value => {
      this.store.dispatch(new UpdateWidgetSettings(this.slot, this.widget.id || this.widget.tempId, {
        ...this.widget.settings,
        title: value
      } as SingleFileWidgetSettings, this.editScope));
    });
  }

  ngOnChanges(): void {
    this.changeSubject$.next({
      fileId: this.widget.settings._fileId,
      senderId: this.widget.settings._senderId
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getDownloadUrl(file: File, sender: Sender): string {
    return this.documentService.getDownloadUrl({id: file.id, senderId: sender.id} as Document);
  }

  showFileDetails(filePreview: FilePreview): void {
    this.fileDetailsModalService.open([filePreview]);
  }

  private toFilePreview(file: File): FilePreview {
    return {
      serialVersionUID: null,
      id: file.id,
      name: file.name,
      contentType: file.contentType,
      previewUrl: this.previewUrl,
      length: 1,
      created: new Date(file.created),
      modified: new Date(file.modified),
      storage: 'LOCAL_BLOB_STORAGE',
      storageAvailable: true,
      downloadUrl: null,
      fileId: file.id,
      modelId: null,
      deleted: false,
      attachment: false,
      groupId: file.senderId,
      senderId: file.senderId
    };
  }
}

<form class="form-horizontal"
      [formGroup]="settingsForm"
      (ngSubmit)="submit()">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="panel-title" translate="ADMIN.SETTINGS.GENERAL"></h3>
    </div>
    <div class="panel-body">
      <!-- Network name -->
      <div class="form-group" formGroupName="backendSettings">
        <label for="networkName" class="col-sm-3 control-label"
               translate="ADMIN.SETTINGS.NETWORK_NAME.LABEL"></label>
        <div class="col-sm-8">
          <input id="networkName" name="networkName" type="text" class="form-control" formControlName="networkName"
                 placeholder="COYO">
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="ADMIN.SETTINGS.NETWORK_NAME.HELP"></coyo-help>
        </div>
      </div>
      <!-- Tracking code -->
      <div class="form-group" formGroupName="backendSettings">
        <label for="trackingCode" class="col-sm-3 control-label"
               translate="ADMIN.SETTINGS.TRACKING_CODE.LABEL"></label>
        <div class="col-sm-8">
          <textarea id="trackingCode" name="trackingCode" class="form-control" rows="5" formControlName="trackingCode"
                    placeholder="<script ...></script>"></textarea>
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="ADMIN.SETTINGS.TRACKING_CODE.HELP"></coyo-help>
        </div>
      </div>
      <!-- Landing Page Subnavigation -->
      <div class="form-group" formGroupName="backendSettings">
        <label for="subNavigationActive"
               class="col-sm-3 control-label"
               translate="ADMIN.SETTINGS.SUB_NAVIGATION.ACTIVE.LABEL"></label>
        <div class="col-sm-8">
          <coyo-checkbox id="subNavigationActive" name="subNavigationActive"
                         formControlName="subNavigationActive"
                         trueValue="true"
                         falseValue="false">
          </coyo-checkbox>
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="ADMIN.SETTINGS.SUB_NAVIGATION.ACTIVE.HELP"></coyo-help>
        </div>
      </div>
      <!-- Anonymize deleted users -->
      <hr>
      <div class="form-group" formGroupName="backendSettings">
        <label for="anonymizeDeletedUsers"
               class="col-sm-3 control-label"
               translate="ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.ACTIVE.LABEL"></label>
        <div class="col-sm-8">
          <div class="table-row">
            <coyo-checkbox id="anonymizeDeletedUsers" name="anonymizeDeletedUsers" trueValue="true"
                           falseValue="false"
                           formControlName="deletedUserAnonymizationActive" class="table-cell"></coyo-checkbox>
            <span class="pl-xs table-cell text-bold"
                  *ngIf="userAnonymizationActive$ | async">
              <strong>{{'ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.WARNING' | translate }}</strong>
              {{'ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.WARNING_TEXT' | translate}}
            </span>
          </div>
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.ACTIVE.HELP"></coyo-help>
        </div>
      </div>
      <div [collapse]="!(userAnonymizationActive$ | async)">
        <div class="form-group" formGroupName="backendSettings">
          <label for="deletedUserAnonymizationDelay" class="col-sm-3 control-label"
                 translate="ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELAY.LABEL"></label>
          <div class="col-sm-8">
            <coyo-duration-picker id="deletedUserAnonymizationDelay" name="deletedUserAnonymizationDelay"
                                  formControlName="deletedUserAnonymizationDelay"
                                  mode="NORMALIZE" showUnits="DAYS,HOURS" max="PT744H">
            </coyo-duration-picker>
            <span translate="ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELAY.LABEL2" class="xs-block"></span>
            <div class="form-text text-muted">{{'ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELAY.LABEL3' | translate}}</div>
          </div>
          <div class="col-sm-1 hidden-xs">
            <coyo-help tip="ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELAY.HELP"></coyo-help>
          </div>
        </div>
        <div class="form-group" formGroupName="backendSettings">
          <label for="deletedUserDisplayName" class="col-sm-3 control-label"
                 translate="ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELETED_NAME.LABEL"></label>
          <div class="col-sm-8">
            <input id="deletedUserDisplayName" name="deletedUserDisplayName" type="text" class="form-control"
                   formControlName="deletedUserDisplayName">
          </div>
          <div class="col-sm-1 hidden-xs">
            <coyo-help tip="ADMIN.SETTINGS.ANONYMIZE_DELETEDUSERS.DELETED_NAME.HELP"></coyo-help>
          </div>
        </div>
      </div>
      <!-- Default visibilities -->
      <hr>
      <div class="form-group" formGroupName="backendSettings">
        <label for="defaultVisibilityPages" class="col-sm-3 control-label"
               translate="ADMIN.SETTINGS.DEFAULT_VISIBLITY_PAGES.LABEL"></label>
        <div class="col-sm-8">
          <ng-select id="defaultVisibilityPages"
                     name="defaultVisibilityPages"
                     formControlName="defaultVisibilityPages"
                     [items]="pageVisibility"
                     [searchable]="false"
                     [clearable]="false"
                     appendTo="body">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{'PAGE.VISIBILITY.' + item + '.LABEL' | translate}}
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-label="label">
              {{'PAGE.VISIBILITY.' + item + '.LABEL' | translate}}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="ADMIN.SETTINGS.DEFAULT_VISIBLITY_PAGES.HELP"></coyo-help>
        </div>
      </div>
      <div class="form-group" formGroupName="backendSettings">
        <label for="defaultVisibilityWorkspaces" class="col-sm-3 control-label"
               translate="ADMIN.SETTINGS.DEFAULT_VISIBLITY_WORKSPACE.LABEL"></label>
        <div class="col-sm-8">
          <ng-select id="defaultVisibilityWorkspaces"
                     name="defaultVisibilityWorkspaces"
                     formControlName="defaultVisibilityWorkspaces"
                     [items]="workspaceVisibility"
                     [searchable]="false"
                     [clearable]="false"
                     appendTo="body">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{'WORKSPACE.VISIBILITY.' + item + '.LABEL' | translate}}
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-label="label">
              {{'WORKSPACE.VISIBILITY.' + item + '.LABEL' | translate}}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="ADMIN.SETTINGS.DEFAULT_VISIBLITY_WORKSPACE.HELP"></coyo-help>
        </div>
      </div>
      <!-- Make new users offline after the first login -->
      <hr/>
      <div class="form-group" formGroupName="backendSettings">
        <label for="userOfflineAfterCreationActive"
               class="col-sm-3 control-label"
               translate="ADMIN.SETTINGS.USER_OFFLINE_AFTER_ACCOUNT_CREATION.ACTIVE.LABEL"></label>
        <div class="col-sm-8">
          <div class="table-row">
            <coyo-checkbox id="userOfflineAfterCreationActive" name="userOfflineAfterCreationActive" trueValue="true"
                           falseValue="false"
                           formControlName="userOfflineAfterCreationActive" class="table-cell"></coyo-checkbox>
          </div>
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="ADMIN.SETTINGS.USER_OFFLINE_AFTER_ACCOUNT_CREATION.ACTIVE.HELP"></coyo-help>
        </div>
      </div>
      <!-- Multi-language activation -->
      <ng-container *ngIf="(multiLanguageAvailable$ | async)">
        <hr/>
        <div class="form-group" formGroupName="backendSettings">
          <label for="multiLanguageActive"
                 class="col-sm-3 control-label"
                 translate="ADMIN.SETTINGS.MULTI_LANGUAGE.ACTIVE.LABEL"></label>
          <div class="col-sm-8">
            <div class="table-row">
              <coyo-checkbox id="multiLanguageActive" name="multiLanguageActive" trueValue="true" falseValue="false"
                             formControlName="multiLanguageActive" class="table-cell"></coyo-checkbox>
            </div>
            <div class="table-row">
              <a uiSref="admin.multi-language" translate="ADMIN.SETTINGS.MULTI_LANGUAGE.ACTIVE.TEXT"></a>
            </div>
          </div>
          <div class="col-sm-1 hidden-xs">
            <coyo-help tip="ADMIN.SETTINGS.MULTI_LANGUAGE.ACTIVE.HELP"></coyo-help>
          </div>
        </div>
      </ng-container>
      <!-- JIT translation settings -->
      <ng-container *ngIf="(jitServiceEnabled$ | async)">
        <hr/>
        <div class="form-group" formGroupName="jitTranslationSettings">
          <label for="activateTranslation" class="col-sm-3 control-label"
                 translate="ADMIN.SETTINGS.TRANSLATION_ACTIVATE.LABEL"></label>
          <div class="col-sm-8">
            <div class="table-row">
              <coyo-checkbox id="activateTranslation" name="activateTranslation"
                             formControlName="activateTranslation" class="table-cell"></coyo-checkbox>
            </div>
          </div>
          <div class="col-sm-1 hidden-xs">
            <coyo-help tip="ADMIN.SETTINGS.TRANSLATION_ACTIVATE.HELP"></coyo-help>
          </div>
        </div>
        <div [collapse]="!(isTranslationProviderActive$ | async)">
          <div class="form-group" formGroupName="jitTranslationSettings">
            <label for="activeProvider" class="col-sm-3 control-label"
                   translate="ADMIN.SETTINGS.TRANSLATION_PROVIDER.LABEL"></label>
            <div class="col-sm-8">
              <ng-select id="activeProvider"
                         name="activeProvider"
                         formControlName="activeProvider"
                         (change)="enableApiInput()"
                         [items]="translationProviders"
                         [searchable]="false"
                         [clearable]="false"
                         appendTo="body">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  {{'TRANSLATION.PROVIDER.' + item + '.LABEL' | translate}}
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-label="label">
                  {{'TRANSLATION.PROVIDER.' + item + '.LABEL' | translate}}
                </ng-template>
              </ng-select>
            </div>
            <div class="col-sm-1 hidden-xs">
              <coyo-help tip="ADMIN.SETTINGS.TRANSLATION_PROVIDER.HELP"></coyo-help>
            </div>
          </div>
          <div class="form-group"
               formGroupName="jitTranslationSettings"
               *ngIf="settingsForm.get('jitTranslationSettings.activeProvider').value === 'MICROSOFT'">
            <label for="jitServiceRegion" class="col-sm-3 control-label"
                   translate="ADMIN.SETTINGS.TRANSLATION_REGION.LABEL"></label>
            <div class="col-sm-8">
              <input id="jitServiceRegion" name="jitServiceRegion" type="text" class="form-control"
                     formControlName="region">
            </div>
            <div class="col-sm-1 hidden-xs">
              <coyo-help tip="ADMIN.SETTINGS.TRANSLATION_REGION.HELP"></coyo-help>
            </div>
          </div>
          <div class="form-group"
               [class.has-error]="settingsForm.get('jitTranslationSettings.apiKey').errors?.invalidKey"
               formGroupName="jitTranslationSettings">
            <label for="apiKey" class="col-sm-3 control-label"
                   translate="ADMIN.SETTINGS.TRANSLATION_KEY.LABEL"></label>
            <div class="col-sm-8">
              <input id="apiKey" name="apiKey" type="text" class="form-control"
                     formControlName="apiKey">
              <span class="help-block" *ngIf="settingsForm.get('jitTranslationSettings.apiKey').errors?.invalidKey">
                {{'ADMIN.SETTINGS.TRANSLATION_INVALID_KEY' | translate}}
              </span>
            </div>
            <div class="col-sm-1 hidden-xs">
              <coyo-help tip="ADMIN.SETTINGS.TRANSLATION_KEY.HELP"></coyo-help>
            </div>
          </div>
        </div>
      </ng-container>
      <hr/>
      <div class="form-group" formGroupName="backendSettings">
        <label for="integrationType" class="col-sm-3 control-label"
               translate="ADMIN.SETTINGS.ENABLE_INTEGRATION.LABEL"></label>
        <div class="col-sm-8">
          <ng-select id="integrationType"
                     name="integrationType"
                     formControlName="integrationType"
                     [items]="integrations"
                     [searchable]="false"
                     [clearable]="false"
                     appendTo="body">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{'INTEGRATION.ACTIVATED.' + item + '.LABEL' | translate}}
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-label="label">
              {{'INTEGRATION.ACTIVATED.' + item + '.LABEL' | translate}}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-sm-1 hidden-xs">
          <coyo-help tip="ADMIN.SETTINGS.ENABLE_INTEGRATION.HELP"></coyo-help>
        </div>
      </div>
    </div>
    <div class="panel-footer panel-actions">
      <div class="row">
        <div class="col-sm-9 col-sm-push-3">
          <button type="submit" class="coyo-btn coyo-btn-primary" [disabled]="settingsForm.invalid">
            <i class="zmdi zmdi-hc-fw zmdi-check"></i>&nbsp;{{'SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AppChooserComponent} from '@apps/api/app-chooser/app-chooser.component';
import {AppModalComponent} from '@apps/api/app-modal/app-modal.component';
import {AppSettingsModalData} from '@apps/api/app-settings-modal/app-settings-modal-data';
import {AppSettingsComponent} from '@apps/api/app-settings/app-settings.component';
import {AppService} from '@domain/apps/app.service';
import {DeleteConfirmationService} from '@shared/dialog/delete-confirmation/delete-confirmation.service';
import * as _ from 'lodash';
import {filter, switchMap} from 'rxjs/operators';

/**
 * Shows the settings of an app inside a material dialog.
 */
@Component({
  selector: 'coyo-app-settings-modal',
  templateUrl: './app-settings-modal.component.html',
  styleUrls: ['./app-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSettingsModalComponent extends AppModalComponent implements OnInit {

  @ViewChild(AppSettingsComponent) appSettings: AppSettingsComponent;

  private appTypesWithoutContentDeletion: string[] = ['timeline', 'events'];

  /**
   * Flag that indicates if delete hint message key should contain information that app content will be deleted
   */
  hideContentDeletionHint: boolean;

  constructor(
    private dialogRef: MatDialogRef<AppChooserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AppSettingsModalData,
    private fb: FormBuilder,
    private deleteConfirmationService: DeleteConfirmationService,
    private appService: AppService
  ) {
    super();
  }

  ngOnInit(): void {
    this.appForm = this.fb.group({});
    this.hideContentDeletionHint = _.includes(this.appTypesWithoutContentDeletion, this.data.appConfig.key);
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.loading$.next(true);
    let settings = {...this.data.app.settings, ...this.appForm.getRawValue().settings};
    if (this.appSettings?.individualAppSettingsContainer?.individualAppSettings) {
      settings = this.appSettings.individualAppSettingsContainer.individualAppSettings.onBeforeSave(settings);
    }
    this.appService.updateApp(this.data.sender.id, {
      ...this.data.app,
      ...this.appForm.getRawValue(),
      ...{settings},
      senderId: this.data.sender.id,
      key: this.data.appConfig.key
    }).subscribe(app => this.dialogRef.close({
      edited: true,
      app
    }), () => {
      this.loading$.next(false);
    });
  }

  delete(): void {
    this.deleteConfirmationService.open(
      'APPS.DELETE.MODAL.TITLE',
      this.hideContentDeletionHint ? '' : 'APPS.DELETE.MODAL.BODY',
      'APPS.DELETE.MODAL.CONFIRM',
      'APPS.DELETE.MODAL.CANCEL',
      {name: this.data.app.name},
      true
    )
      .pipe(filter(shouldDelete => shouldDelete)
      )
      .pipe(switchMap(() => this.appService.deleteApp(this.data.sender.id, this.data.app.id))
      )
      .subscribe(() => this.dialogRef.close({
        deleted: true
      }));
  }
}

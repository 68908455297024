<div class="alert alert-warning" translate="WIDGETS.IFRAME.SETTINGS.MESSAGE"></div>
<div [formGroup]="parentForm">
  <div class="form-group" [class.has-error]="isInvalid('url')">
    <label for="url" class="col-sm-2 control-label" translate="WIDGETS.IFRAME.SETTINGS.CONFIG.URL.LABEL"></label>
    <div class="col-sm-9">
      <input id="url" name="url" class="form-control" type="text" formControlName="url"
             [attr.placeholder]="'WIDGETS.IFRAME.SETTINGS.CONFIG.URL.PLACEHOLDER' | translate">
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.IFRAME.SETTINGS.CONFIG.URL.HELP"></coyo-help>
    </div>
  </div>
  <div class="form-group" [class.has-error]="isInvalid('height')">
    <label for="height" class="col-sm-2 control-label" translate="WIDGETS.IFRAME.SETTINGS.CONFIG.HEIGHT.LABEL"></label>
    <div class="col-sm-9">
      <div class="input-group">
        <input id="height" name="height" class="form-control" type="number" formControlName="height">
        <span class="input-group-addon">px</span>
      </div>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.IFRAME.SETTINGS.CONFIG.HEIGHT.HELP"></coyo-help>
    </div>
  </div>
  <div class="form-group" [class.has-error]="isInvalid('scrolling')">
    <label for="scrolling" class="col-sm-2 control-label" translate="WIDGETS.IFRAME.SETTINGS.CONFIG.SCROLL.LABEL"></label>
    <div class="col-sm-9">
      <coyo-checkbox id="scrolling" name="scrolling" formControlName="scrolling"></coyo-checkbox>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.IFRAME.SETTINGS.CONFIG.SCROLL.HELP"></coyo-help>
    </div>
  </div>
</div>

import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {AppUiModule} from '@shared/app-ui/app-ui.module';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {FileModule} from '@shared/files/file.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {TimeModule} from '@shared/time/time.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {LATEST_BLOG_ARTICLES_WIDGET} from '@widgets/latest-blog-articles/latest-blog-articles-widget-config';
/*tslint:disable:max-line-length*/
import {LatestBlogArticlesWidgetSettingsComponent} from '@widgets/latest-blog-articles/latest-blog-articles-widget-settings/latest-blog-articles-widget-settings.component';
import {LatestBlogArticlesWidgetComponent} from '@widgets/latest-blog-articles/latest-blog-articles-widget/latest-blog-articles-widget.component';
import {LatestBlogArticlesWidgetState} from '@widgets/latest-blog-articles/latest-blog-articles-widget/latest-blog-articles-widget.state';
import {PublishDatePipe} from './publish-date/publish-date.pipe';

export const ngxsModule = NgxsModule.forFeature([LatestBlogArticlesWidgetState]);

/**
 * Module providing the latest-blog-articles widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    AppUiModule,
    UpgradeModule,
    HelpModule,
    TimeModule,
    ngxsModule,
    FileModule
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: LATEST_BLOG_ARTICLES_WIDGET, multi: true}
  ],
  declarations: [
    LatestBlogArticlesWidgetComponent,
    LatestBlogArticlesWidgetSettingsComponent,
    PublishDatePipe
  ],
  entryComponents: [
    LatestBlogArticlesWidgetComponent,
    LatestBlogArticlesWidgetSettingsComponent
  ]
})
export class LatestBlogArticlesWidgetModule {
}

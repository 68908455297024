import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {User} from '@domain/user/user';

@Component({
  selector: 'coyo-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListItemComponent {

  /**
   * The user to render the avatar for
   */
  @Input() user: User;

  constructor() { }
}

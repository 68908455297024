import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[coyoAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {

  /**
   * If set to true the entire text of the input will be selected
   */
  @Input() selectAll?: boolean;

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
      if (this.selectAll) {
        (this.elementRef.nativeElement as HTMLInputElement).select();
      }
    });
  }
}

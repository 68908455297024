import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

interface RibbonType {
  className: string;
  hasButton: boolean;
  messageKey: string;
  ariaMessageKey?: string;
}

const ribbonTypes = {
  new: {className: 'ribbon-new', hasButton: false, messageKey: 'MODULE.TIMELINE.NEW'},
  sticky: {className: 'ribbon-sticky', hasButton: true, messageKey: 'MODULE.TIMELINE.STICKY', ariaMessageKey: 'MODULE.TIMELINE.STICKY.READ.ARIA'},
  event: {className: 'ribbon-new', hasButton: false, messageKey: 'MODULE.EVENTS.ONGOING'},
  archived: {className: 'ribbon-archived', hasButton: false, messageKey: 'WORKSPACE.ARCHIVED'}
};

/**
 * Component renders a ribbon on the top right corner of the parent element.
 * If it is clickable (for example the sticky ribbon) it will emit a click event.
 */
@Component({
  selector: 'coyo-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RibbonComponent implements OnInit, OnChanges {

  /**
   * The type of the ribbon
   */
  @Input() type:  'new' | 'sticky' | 'event' | 'archived';

  /**
   * Event emitted when the ribbon button is clicked
   */
  @Output() click: EventEmitter<void> = new EventEmitter<void>();

  ribbonType: RibbonType;

  constructor() { }

  ngOnInit(): void {
    this.setRibbonType();
  }

  ngOnChanges(): void {
    this.setRibbonType();
  }

  private setRibbonType(): void {
    this.ribbonType = ribbonTypes[this.type];
  }
}

import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import {AnalyticsService} from '@app/analytics/analytics.service';
import {UserReachedService} from '@app/analytics/user-reach/user-reached.service';
import {EntityId} from '@domain/entity-id/entity-id';

/**
 * A reach tracking implements with an interaction observer which always emits
 * when the viewed entity like a timeline item has entered the viewport of the user.
 * It is used for COYO analytics reach tracking.
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
@Directive({
  selector: '[coyoAnalyticsReachTracking]'
})
export class ReachTrackingDirective implements AfterViewInit, OnDestroy {

  static readonly RENDERING_THRESHOLD: number = 2000;
  static readonly INTERSECTION_THRESHOLD: number = 0.75;

  /**
   * The entity id of the entity which has reached the user.
   */
  @Input() entityId: EntityId;

  private intersectionObserver: IntersectionObserver;

  constructor(private elementRef: ElementRef,
              private analyticsService: AnalyticsService,
              private userReachedService: UserReachedService) {
  }

  ngAfterViewInit(): void {
    this.analyticsService.isAnalyticsEnabled().subscribe(isAnalyticsEnabled => {
      if (isAnalyticsEnabled) {
        setTimeout(() => this.initializeObserver(), ReachTrackingDirective.RENDERING_THRESHOLD);
      }
    });
  }

  private intersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach(entry => {
      if (entry.isIntersecting && entry.intersectionRatio >= ReachTrackingDirective.INTERSECTION_THRESHOLD) {
        this.userReachedService.handleUserReachedEvent(new Date(), this.entityId.id, this.entityId.typeName);
        this.intersectionObserver.disconnect();
      }
    });
  }

  private initializeObserver(): void {
    this.intersectionObserver = new IntersectionObserver(
      entries => this.intersect(entries), {
        threshold: ReachTrackingDirective.INTERSECTION_THRESHOLD
      });
    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }
}

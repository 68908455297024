import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FilePickerSelectionsState} from '@app/file-picker/file-picker-selection/file-picker-selections-state';
import {FilePickerItemStateModel} from '@app/file-picker/file-picker-state/file-picker-state-model';
import {UploadFiles} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {FileUploadEvent} from '@domain/file/file/events/file-upload-event';
import {Store} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * Upload button for the file library
 */
@Component({
  selector: 'coyo-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadButtonComponent implements OnChanges, OnDestroy {
  /**
   * The id of the current location item
   */
  @Input() locationId: string;

  /**
   * The id of the file picker for this button
   */
  @Input() filePickerId: string;

  @ViewChild('files', {static: false}) files: ElementRef<HTMLInputElement>;
  canUpload$: Observable<boolean>;
  location$: Observable<FilePickerItemStateModel>;
  mimeTypes$: Observable<string>;
  multiple$: Observable<boolean>;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private readonly store: Store) {
  }

  selectFiles(): void {
    this.files.nativeElement.click();
  }

  beginUpload(location: FilePickerItemStateModel): Observable<FileUploadEvent> {
    const files = this.files.nativeElement.files;
    if (files && files.length > 0) {
      const upload: Observable<FileUploadEvent> = this.store.dispatch(new UploadFiles(this.filePickerId, location.item, files));
      upload.pipe(takeUntil(this.destroyed$)).subscribe({
        complete: () => {
          this.files.nativeElement.value = '';
        }
      });
      return upload;
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.locationId?.currentValue) {
      this.canUpload$ = this.store.select(FilePickerStateSelectors.canCreateFile(this.locationId));
      this.location$ = this.store.select(FilePickerStateSelectors.item(this.locationId));
    }
    if (changes?.filePickerId?.currentValue) {
      this.multiple$ = this.store.select(FilePickerSelectionsState.isMultiSelect);
      this.mimeTypes$ = this.store.select(FilePickerStateSelectors.canParseMimeTypes(this.filePickerId));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild
} from '@angular/core';
import {FilePickerData} from '@app/file-picker/file-picker-data';
import {FilePickerHeaderBaseDirective} from '@app/file-picker/file-picker-header-base.directive';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {FilePickerItemServiceSelectorService} from '@app/file-picker/file-picker-item-service-selector.service';
import {ToggleSelection} from '@app/file-picker/file-picker-selection/file-picker-selection.actions';
import {FilePickerSelectionsState} from '@app/file-picker/file-picker-selection/file-picker-selections-state';
import {FilePickerItemStateModel} from '@app/file-picker/file-picker-state/file-picker-state-model';
import {
  CloseFilePicker,
  InitializeFilePicker,
  LoadMore,
  OpenFolder,
  OpenInRoot,
  OpenParent,
  OpenRoot,
  UploadFiles
} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Store} from '@ngxs/store';
import {UuidService} from '@shared/uuid/uuid.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * This component contains the filepicker inside of a modal
 */
@Component({
  selector: 'coyo-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerComponent implements OnInit, OnDestroy {

  /**
   * The data for the filepicker.
   */
  @Input() data: FilePickerData;

  @ViewChild('files', {static: false}) files: ElementRef<HTMLInputElement>;

  currentLocation$: Observable<FilePickerItemStateModel>;
  items$: Observable<FilePickerItemStateModel[]>;
  breadcrumbs$: Observable<FilePickerItemStateModel[]>;
  loading$: Observable<boolean>;
  destroyed: Subject<void> = new Subject<void>();
  isDragLocalFile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mimeTypes$: Observable<string>;
  multiple$: Observable<boolean>;
  canCreateFile$: Observable<boolean>;

  isMobile: boolean;
  readonly id: string;
  dragEnterCount: number = 0;
  initialized: boolean = false;

  constructor(
    private readonly windowSizeService: WindowSizeService,
    private readonly filePickerItemServiceSelectorService: FilePickerItemServiceSelectorService,
    private readonly store: Store,
    private readonly uuidService: UuidService) {
    this.id = this.uuidService.getUuid();
  }

  ngOnInit(): void {
    const {upload, ...config} = this.data;
    this.store.dispatch(new InitializeFilePicker(this.id, config));
    this.items$ = this.store.select(FilePickerStateSelectors.items(this.id));
    this.currentLocation$ = this.store.select(FilePickerStateSelectors.currentLocation(this.id))
      .pipe(tap(currentLocation => {
        if (!this.initialized && this.data.upload) {
          this.initialized = true;
          this.store.dispatch(new UploadFiles(this.id, currentLocation.item, upload));
        }
      }));
    this.breadcrumbs$ = this.store.select(FilePickerStateSelectors.breadcrumbs(this.id));
    this.loading$ = this.store.select(FilePickerStateSelectors.loading(this.id));
    this.isMobile = this.windowSizeService.isXs() || this.windowSizeService.isSm();
    this.multiple$ = this.store.select(FilePickerSelectionsState.isMultiSelect);
    this.mimeTypes$ = this.store.select(FilePickerStateSelectors.canParseMimeTypes(this.id));
    this.canCreateFile$ = this.store.select(FilePickerStateSelectors.canCreateFileAtCurrentLocation(this.id));
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.store.dispatch(new CloseFilePicker(this.id));
  }

  /**
   * Handles the click event on filepicker items
   * Navigates inside the folder for folders, toggles the selection for files
   *
   * @param item the filepicker item that has been clicked
   * @param $event the click event dispatched by the browser
   */
  filePickerItemClicked(item: FilePickerItem, $event?: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    item.isFolder ? this.openFolder(item) : this.store.dispatch(new ToggleSelection(item));
  }

  /**
   * Load the contents of the given folder item
   * @param item a folder item
   */
  openFolder(item: FilePickerItem): void {
    this.store.dispatch(new OpenFolder(this.id, item));
  }

  /**
   * Opens the parent folder
   */
  openParent(): void {
    this.store.dispatch(new OpenParent(this.id));
  }

  /**
   * Opens a folder directly below the root folder
   * @param specialFolder The folder to be opened
   */
  openInRoot(specialFolder: FilePickerItem | null): void {
    if (!specialFolder) {
      this.store.dispatch((new OpenRoot(this.id)));
    } else {
      this.store.dispatch((new OpenInRoot(this.id, specialFolder)));
    }
  }

  loadMore(current: FilePickerItemStateModel): void {
    this.store.dispatch(new LoadMore(this.id, current));
  }

  getItemComponent(item: FilePickerItem): Type<FilePickerItemBaseDirective<FilePickerItem>> {
    return this.filePickerItemServiceSelectorService.get(item).getComponentType();
  }

  getItemHeaderComponent(item: FilePickerItem): Type<FilePickerHeaderBaseDirective<FilePickerItem>> {
    return this.filePickerItemServiceSelectorService.get(item).getHeaderComponentType();
  }

  trackByFunction(index: number, model: FilePickerItemStateModel): string {
    return model.item.id;
  }

  uploadFiles(files: FileList): void {
    const location = this.store.selectSnapshot(FilePickerStateSelectors.currentLocation(this.id));
    this.store.dispatch(new UploadFiles(this.id, location.item, files));
  }

  @HostListener('drop', ['$event'])
  onDropzoneDrop(event: DragEvent): boolean {
    if (this.store.selectSnapshot(FilePickerStateSelectors.canCreateFileAtCurrentLocation(this.id))) {
      if (this.isLocalFile(event.dataTransfer)) {
        this.uploadFiles(event.dataTransfer.files);
        return false;
      }
    }
    return true;
  }

  @HostListener('document:drop', ['$event'])
  onDocumentDrop(event: DragEvent): boolean {
    if (this.isLocalFile(event.dataTransfer)) {
      this.isDragLocalFile$.next(false);
      this.dragEnterCount = 0;
      return false;
    }
    return true;
  }

  @HostListener('document:dragenter', ['$event'])
  enterDropZone(event: DragEvent): boolean {
    if (this.store.selectSnapshot(FilePickerStateSelectors.canCreateFileAtCurrentLocation(this.id))) {
      if (this.isLocalFile(event.dataTransfer)) {
        this.dragEnterCount++;
        this.isDragLocalFile$.next(true);
        return false;
      }
    }
    return true;
  }

  @HostListener('document:dragover', ['$event'])
  dragOver(event: DragEvent): boolean {
    event.preventDefault();
    return false;
  }

  @HostListener('document:dragleave', ['$event'])
  leaveDropZone(event: DragEvent): boolean {
    if (this.store.selectSnapshot(FilePickerStateSelectors.canCreateFileAtCurrentLocation(this.id))) {
      if (this.isLocalFile(event.dataTransfer)) {
        this.dragEnterCount--;
        if (this.dragEnterCount === 0) {
          this.isDragLocalFile$.next(false);
          return false;
        }
      }
    }
    return true;
  }

  openFileBrowser(): void {
    this.files.nativeElement.click();
  }

  private isLocalFile(data: DataTransfer): boolean {
    const value = data.types[0] === 'Files' || data.items[0].kind === 'file';
    return value;
  }
}

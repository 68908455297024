import {Injectable} from '@angular/core';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerSearch} from '@app/file-picker/file-picker-search/file-picker-search';
import {FilePickerService} from '@app/file-picker/file-picker.service';
import {O365ApiService} from '@app/integration/o365/o365-api/o365-api.service';
import {BaseDriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/base-drive-file-picker-item';
import {DriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/drive-file-picker-item';
import {DriveFolderItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/drive-folder-item';
import {DriveItemFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/drive-item-file-picker-item';
import {SiteFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/site-file-picker-item';
import {TranslateService} from '@ngx-translate/core';
import {UuidService} from '@shared/uuid/uuid.service';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export type FetchSites = () => Observable<SiteFilePickerItem[]>;
export type FetchDrives = (siteId: string) => Observable<DriveFilePickerItem[]>;
export type FetchDriveItems = (driveId: string, driveItemId?: string) => Observable<DriveItemFilePickerItem[]>;
export type FetchRecentDriveItems = () => Observable<DriveItemFilePickerItem[]>;
export type SearchSites = (query: string) => Observable<SiteFilePickerItem[]>;
export type SearchDriveItems = (query: string) => Observable<DriveItemFilePickerItem[]>;

/**
 * Service that handles the SharePoint File Picker.
 */
@Injectable({
  providedIn: 'root'
})
export class SharePointFilePickerService {
  constructor(
    private filepickerService: FilePickerService,
    private o365ApiService: O365ApiService,
    private translateService: TranslateService,
    private readonly uuidService: UuidService) {
  }

  private search: FilePickerSearch = {
    inputPlaceholder: this.translateService
      .instant('INTEGRATION.O365.FILEPICKER.SEARCH_FOR_FILES'),
    execute: query => forkJoin([this.searchForSites(query), this.searchForFiles(query)])
      .pipe(map(searchResults => [...searchResults[0], ...searchResults[1]]))
  };

  /**
   * Opens the SharePoint filepicker
   * @return Observable of selected items
   */
  openFilePicker(): Observable<FilePickerItem[]> {
    return this.filepickerService.openFilePicker({
      rootFolder: this.getRootFolderFilePickerItem(),
      search: this.search,
      firstOpenFolder: this.getRecentFilesFilePickerItem()
    });
  }

  private fetchSites: FetchSites = () =>
    this.o365ApiService
      .getSites()
      .pipe(map(sites => SiteFilePickerItem.fromArray(sites, this.fetchDrives)));

  private fetchDrives: FetchDrives = siteId =>
    this.o365ApiService
      .getDrivesBySiteId(siteId)
      .pipe(map(drives => DriveFilePickerItem.fromArray(drives, this.fetchDriveItems)));

  private fetchRecentFiles: FetchRecentDriveItems = () =>
    this.o365ApiService
      .getRecentFiles()
      .pipe(map(files => DriveItemFilePickerItem.fromArray(files, this.fetchDriveItems)));

  private fetchDriveItems: FetchDriveItems = (driveId, driveItemId = 'root') =>
    this.o365ApiService
      .getDriveItems(driveId, driveItemId)
      .pipe(map(driveItems => DriveItemFilePickerItem.fromArray(driveItems, this.fetchDriveItems)));

  private searchForSites: SearchSites = (query: string) =>
    this.o365ApiService
      .searchForSites(query)
      .pipe(map(sites => SiteFilePickerItem.fromArray(sites, this.fetchDrives)));

  private searchForFiles: SearchDriveItems = (query: string) =>
    this.o365ApiService
      .searchForDriveItems(query)
      .pipe(map(driveItems => DriveItemFilePickerItem.fromArray(driveItems, this.fetchDriveItems)));

  private getRootFolderFilePickerItem(): FilePickerItem {
    return this.createFolder('SharePoint', this.fetchSites);
  }

  private getRecentFilesFilePickerItem(): FilePickerItem {
    const recentFolderName = this.translateService.instant('RECENT_FOLDER.NAME');
    return this.createFolder(recentFolderName, this.fetchRecentFiles);
  }

  private createFolder(folderName: string, getChildren: () => Observable<BaseDriveFilePickerItem[]>): FilePickerItem {
    return new DriveFolderItem(this.uuidService.getUuid(), folderName, getChildren);
  }
}

import {FileUploadEvent} from './file-upload-event';

/**
 * Event that is emitted when a FileUpload has finished uploading all files
 */
export class FileUploadFinishedEvent extends FileUploadEvent {
  constructor(files: FileList | File[], readonly successfulUploads: number) {
    super(files);
  }
}

import { NgModule } from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import '@shared/license/license.service.downgrade';
import {LicenseState} from '@shared/license/license.state';

export const ngxsModule = NgxsModule.forFeature([LicenseState]);

@NgModule({
  imports: [
    ngxsModule
  ]
})
export class LicenseModule { }

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {LatestWikiArticlesWidget} from '@widgets/latest-wiki-articles/latest-wiki-articles-widget';
import {LatestWikiArticlesWidgetSettingsComponent} from './latest-wiki-articles-widget-settings/latest-wiki-articles-widget-settings.component';
import {LatestWikiArticlesWidgetComponent} from './latest-wiki-articles-widget/latest-wiki-articles-widget.component';

/**
 * The configuration for the latest-wiki-articles widget.
 */
export const LATEST_WIKI_ARTICLES_WIDGET: WidgetConfig<LatestWikiArticlesWidget> = {
  key: 'wiki',
  name: 'WIDGETS.WIKI.NAME',
  description: 'WIDGETS.WIKI.DESCRIPTION',
  icon: 'zmdi-library',
  component: LatestWikiArticlesWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  defaultTitle: 'WIDGETS.WIKI.NAME',
  settings: {
    component: LatestWikiArticlesWidgetSettingsComponent,
    skipOnCreate: false
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

/**
 * Enumeration for sort directions.
 */
export enum Direction {

  /**
   * The ascending sorting direction.
   */
  Asc = 'ASC',

  /**
   * The descending sorting direction.
   */
  Desc = 'DESC'
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {TextWidget} from '@widgets/text/text-widget';
import {TextWidgetComponent} from './text-widget/text-widget.component';

export const TEXT_WIDGET: WidgetConfig<TextWidget> = {
  key: 'text',
  name: 'WIDGET.TEXT.NAME',
  description: 'WIDGET.TEXT.DESCRIPTION',
  icon: 'zmdi-format-align-left',
  component: TextWidgetComponent,
  categories: WidgetCategory.STATIC,
  renderOptions: {
    printable: true
  },
  whitelistExternal: true,
  mobileSupportTypes: ['landing-page']
};

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SharePointSearchRow} from '@app/integration/o365/o365-api/domain/share-point-search-row';
import {SharePointTokenInfo} from '@app/integration/o365/o365-api/domain/share-point-token-info';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharePointApiService {
  static readonly TOKEN_INFO_ENDPOINT: string = '/web/sharepoint/tokeninfo';

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Creates a GET request to the SharePoint API and adds the needed headers
   * @param sharePointUrl the url of the SharePoint target (e.g. https://your-key.sharepoint.com)
   * @param endpoint the endpoint of the Sharepoint API (with leading slash)
   * @returns an Observable with the data as rows
   */
  get(sharePointUrl: string, endpoint: string): Observable<SharePointSearchRow[]> {
    return this.getSharePointTokenInfo().pipe(flatMap(token =>
      this.getWithToken(sharePointUrl, token.token, endpoint)));
  }

  /**
   * Creates a GET request to the SharePoint API and adds the needed headers
   * @param sharePointUrl the url of the SharePoint target (e.g. https://your-key.sharepoint.com)
   * @param token the sharepoint access token
   * @param endpoint the endpoint of the Sharepoint API (with leading slash)
   * @returns an Observable with the data as rows
   */
  getWithToken(sharePointUrl: string, token: string, endpoint: string): Observable<SharePointSearchRow[]> {
    const url = `${sharePointUrl}/_api${endpoint}`;
    const options = {headers: {Authorization: 'Bearer ' + token}};
    return this.httpClient
      .get<any>(url, options).pipe(
        map(response => _.get(response, 'PrimaryQueryResult.RelevantResults.Table.Rows', []))
      );
  }

  /**
   * Gets the user's sharepoint access token along with information on how and
   * where this token can be used.
   * @returns an Observable with the comprised information
   */
  getSharePointTokenInfo(): Observable<SharePointTokenInfo> {
    return this.httpClient.get<SharePointTokenInfo>(SharePointApiService.TOKEN_INFO_ENDPOINT);
  }
}

(function (angular) {
  'use strict';

  AdminLaunchpadListController.$inject = ["$state", "$injector", "LaunchpadCategoryModel", "settings", "SettingsModel", "modalService"];
  angular.module('coyo.admin.launchpad')
      .controller('AdminLaunchpadListController', AdminLaunchpadListController);

  function AdminLaunchpadListController($state, $injector, LaunchpadCategoryModel, settings, SettingsModel, modalService) {
    var vm = this;

    vm.categories = [];
    vm.actions = _buildActions();
    vm.treeOptions = _buildOptions();
    vm.settings = settings;
    vm.save = save;
    vm.isActive = (settings.launchpadActive === 'true');

    // ----------

    function _buildActions() {
      return {
        deleteCategory: function (category) {
          modalService.confirmDelete({
            title: 'ADMIN.LAUNCHPAD.OPTIONS.DELETE.MODAL.TITLE',
            text: 'ADMIN.LAUNCHPAD.OPTIONS.DELETE.MODAL.TEXT'
          }).result.then(function () {
            category.delete().then(function () {
              _.remove(vm.categories, {id: category.id});
            });
          });
        }
      };
    }

    function _buildOptions() {
      var cellWidths;
      return {
        dropped: function (event) {
          // persist new sort order
          if (event.source.index !== event.dest.index) {
            LaunchpadCategoryModel.order(_.map(vm.categories, 'id'));
          }
        },
        beforeDrag: function (scope) {
          // save original cell widths
          cellWidths = [];

          Array.prototype.forEach.call(scope.$element.children(), function (el) {
            cellWidths.push(el.width);
          });
          return true;
        },
        dragStart: function (event) {
          // set dragging cell widths

          Array.prototype.forEach.call(event.elements.dragging.find('td'), function (el, i) {
            parseFloat(getComputedStyle(el, null).width.replace('px', cellWidths[i]));
          });
        },
        beforeDrop: function (event) {
          // remove dragging cell widths

          Array.prototype.forEach.call(event.elements.dragging.find('td'), function (el) {
            parseFloat(getComputedStyle(el, null).width.replace('px', ''));
          });
        }
      };
    }

    function save() {
      return settings.update().then(function (data) {
        SettingsModel.retrieve(true); // reset settings cache
        $injector.get('ngxNotificationService').success('ADMIN.SETTINGS.SAVE.SUCCESS');
        vm.isActive = (data.launchpadActive === 'true');
      });
    }

    // ----------

    (function _init() {
      vm.loading = true;
      return LaunchpadCategoryModel.query({admin: true}).then(function (result) {
        vm.categories = result;
      }).finally(function () {
        vm.loading = false;
      });
    })();
  }

})(angular);

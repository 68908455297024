import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {UiModule} from '@coyo/ui';
import {TranslateModule} from '@ngx-translate/core';
import {AnchorModule} from '@shared/anchor/anchor.module';
import {ContainsModule} from '@shared/contains/contains.module';
import {FloatingFooterModule} from '@shared/floating-modules/floating-footer/floating-footer.module';
import {FloatingHeaderModule} from '@shared/floating-modules/floating-header/floating-header.module';
import {MaterialModule} from '@shared/material/material.module';
import {SpinnerModule} from '@shared/spinner/spinner.module';
import {StripHtmlModule} from '@shared/strip-html/strip-html.module';
import {TrustModule} from '@shared/trust/trust.module';
import {UIRouterModule} from '@uirouter/angular';
import {NgxPermissionsModule} from 'ngx-permissions';

/**
 * Module exporting a bundle of commonly used modules.
 *
 * Please only export other modules here!
 */
@NgModule({
  imports: [
    AnchorModule,
    CommonModule,
    ContainsModule,
    FloatingHeaderModule,
    FloatingFooterModule,
    HttpClientModule,
    MaterialModule,
    NgxPermissionsModule,
    SpinnerModule,
    StripHtmlModule,
    TranslateModule,
    TrustModule,
    UiModule,
    UIRouterModule
  ],
  exports: [
    AnchorModule,
    CommonModule,
    ContainsModule,
    FloatingHeaderModule,
    FloatingFooterModule,
    HttpClientModule,
    MaterialModule,
    NgxPermissionsModule,
    SpinnerModule,
    StripHtmlModule,
    TranslateModule,
    TrustModule,
    UiModule,
    UIRouterModule
  ]
})
export class CoyoCommonsModule {
}

import {Component} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {DividerWidget} from '@widgets/divider/divider-widget';

/**
 * This widget creates a divider
 */
@Component({
  selector: 'coyo-divider-widget',
  templateUrl: './divider-widget.component.html',
  styleUrls: ['./divider-widget.component.scss']
})
export class DividerWidgetComponent extends WidgetComponent<DividerWidget> {
}

import {Site} from '@app/integration/o365/o365-api/domain/site';
import {BaseDriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/base-drive-file-picker-item';
import {DriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/drive-file-picker-item';
import {FetchDrives} from '@app/integration/o365/share-point-file-picker/share-point-file-picker.service';
import {OFFICE365, StorageType} from '@domain/attachment/storage';
import {Observable} from 'rxjs';

/**
 * This class creates a FilePickerItem based on a SharePoint Site entity
 */
export class SiteFilePickerItem implements BaseDriveFilePickerItem {
  readonly id: string;
  readonly name: string;
  readonly isFolder: boolean;
  readonly isSearchResult: boolean;
  readonly sizeInBytes: number | null;
  readonly lastModified: number;
  readonly mimeType: string | null;
  readonly storageType: StorageType;
  readonly customSvgIcon?: string;

  constructor(private site: Site, private fetchDrives: FetchDrives) {
    this.id = site.id;
    this.name = site.displayName;
    this.isFolder = true;
    this.isSearchResult = false;
    this.sizeInBytes = null;
    this.lastModified = new Date(this.site.lastModifiedDateTime).getTime();
    this.mimeType = null;
    this.storageType = OFFICE365;
    this.customSvgIcon = 'sharepoint_mc';
  }

  static fromArray(sites: Site[], fetchDrives: FetchDrives): SiteFilePickerItem[] {
    return sites.map(site => new SiteFilePickerItem(site, fetchDrives));
  }

  getChildren(): Observable<DriveFilePickerItem[]> {
    return this.fetchDrives(this.site.id);
  }

}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';

/**
 * Component to display a loading bar for the given number of items.
 */
@Component({
  selector: 'coyo-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBarComponent implements OnChanges {

  /**
   * The total number of items to be loaded.
   */
  @Input()
  total: number;

  /**
   * The currently loaded number of items.
   */
  @Input()
  current: number;

  loadingPercentage: number = 0.01;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnChanges(): void {
    if (this.total) {
      this.loadingPercentage = Math.round(this.current / this.total * 100) / 100;
      this.cd.markForCheck();
    }
  }
}

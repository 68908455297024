import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {ExternalFileDetails} from '@shared/files/external-file-handler/external-file-details';
import {ExternalFileHandlerService} from '@shared/files/external-file-handler/external-file-handler.service';
import {File} from '@shared/files/external-file-handler/file';
import {EMPTY, from, Observable} from 'rxjs';
import {filter, catchError, switchMap} from 'rxjs/operators';

@Component({
  selector: 'coyo-file-integration-link',
  templateUrl: './file-integration-link.component.html',
  styleUrls: ['./file-integration-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileIntegrationLinkComponent implements OnChanges {

  /**
   * The COYO file that contains the fileId and storage type to retrieve the external file details.
   */
  @Input() file: File;

  externalFileDetails$: Observable<ExternalFileDetails>;

  constructor(private googleApiService: GoogleApiService, private fileService: ExternalFileHandlerService) {
  }

  ngOnChanges(): void {
    this.externalFileDetails$ = this.googleApiService.isGoogleApiActive()
      .pipe(
        filter(isActive => isActive),
        switchMap(() => from(this.fileService.getExternalFileDetails(this.file))),
        catchError(() => EMPTY)
      );
  }
}

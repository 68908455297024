<div class="card" *ngIf="notificationSettings$ | async; let notificationSettings">
  <ng-container *ngFor="let setting of notificationSettings; first as isFirst; last as isLast">
    <div class="card-body" role="group"
         [attr.aria-label]="'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.CHANNEL.' + setting.channel + '.DESCRIPTION' | translate">
      <h2 class="mat-headline" *ngIf="isFirst">{{'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.HEADER' | translate}}</h2>
      <mat-checkbox [checked]="setting.active" (change)="changeState(setting)"
                    [aria-label]="'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.CHANNEL.' + setting.channel + '.NAME' | translate">
        <mat-icon [svgIcon]="getChannelIcon(setting.channel)"></mat-icon>
        {{'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.CHANNEL.' + setting.channel + '.NAME' | translate}}
        <mat-hint *ngIf="!(setting.active && setting.channel === 'BROWSER' && !browserNotificationsActive)">
          {{'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.CHANNEL.' + setting.channel + '.DESCRIPTION' | translate}}
        </mat-hint>
        <mat-error *ngIf="setting.active && setting.channel === 'BROWSER' && !browserNotificationsActive">
          {{'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.FORM.NOTIFICATIONS.BROWSER.WARNING' | translate}}
        </mat-error>
      </mat-checkbox>
      <div class="setting-properties">
        <mat-form-field class="no-hint" *ngIf="setting.interval">
          <mat-label>{{'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.FORM.INTERVAL.LABEL' | translate}}</mat-label>
          <ng-select class="mat-ng-select"
                     appearance="outline"
                     [(ngModel)]="setting.interval"
                     appendTo="body"
                     [clearable]="false"
                     [searchable]="false"
                     [disabled]="!setting.active"
                     (change)="submit(setting)">
            <ng-option *ngFor="let timeInterval of notificationIntervals" [value]="timeInterval.value">{{timeInterval.name | translate}}</ng-option>
          </ng-select>
        </mat-form-field>
        <mat-checkbox *ngFor="let notification of setting.properties.notifications | keyvalue"
                      [checked]="notification.value"
                      (change)="changeProperty(setting, notification.key, notification.value)"
                      [disabled]="!setting.active">
          {{'MODULE.ACCOUNT.NOTIFICATION_SETTINGS.PROPERTIES.' + notification.key | uppercase | translate}}
        </mat-checkbox>
      </div>
    </div>
    <hr *ngIf="!isLast">
  </ng-container>
</div>

(function (angular) {
  'use strict';

  RegisterExternalController.$inject = ["$state", "$translate", "authService", "backendUrlService", "RegistrationModel", "LanguagesModel", "fieldConfig", "passwordPattern", "token"];
  angular
      .module('coyo.registration')
      .controller('RegisterExternalController', RegisterExternalController)
      .constant('fieldConfig', {
        firstName: {maxLength: 255},
        lastName: {maxLength: 255},
        password: {maxLength: 255}
      });

  /**
   * Controller for registering an external user that was invited via email to join a workspace.
   */
  function RegisterExternalController($state, $translate, authService, backendUrlService, RegistrationModel,
                                      LanguagesModel, fieldConfig, passwordPattern, token) {
    var vm = this;

    vm.loading = true;
    vm.error = null;
    vm.user = {};
    vm.fieldConfig = fieldConfig;
    vm.fieldConfig.password.pattern = passwordPattern;
    vm.focusOn = 'password';
    vm.register = register;
    vm.$onInit = init;

    function register() {
      vm.loading = true;
      vm.user.language = LanguagesModel.userLanguage || $translate.use();
      new RegistrationModel(vm.user).save()
          .then(function () {
            $state.go('front.login', {username: vm.user.email, password: vm.user.password});
          }, function (error) {
            _showError(error);
          })
          .finally(function () {
            vm.loading = false;
          });
    }

    function init() {
      if (authService.isAuthenticated()) {
        $state.go('main');
      } else {
        _initRegistration();
      }
    }

    function _initRegistration() {
      RegistrationModel.get(token)
          .then(function (user) {
            vm.user = user;
            vm.focusOn = user.canModifyName ? 'firstname' : 'password';
          }, function (error) {
            if (error.data.errorStatus === 'ALREADY_REGISTERED') {
              var email = _.get(error, 'data.context.email');
              $state.go('front.login', {errorCode: error.data.errorStatus, username: email});
            } else {
              _showError(error);
            }
          })
          .finally(function () {
            vm.loading = false;
          });
    }

    function _showError(error) {
      var email = _.get(error, 'data.context.email');
      if (email) {
        vm.user.email = email;
      }
      vm.error = 'MODULE.REGISTER-EXTERNAL.ERROR.' + (error.data.errorStatus || 'ERROR');
    }
  }

})(angular);

import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {WidgetComponent} from '@widgets/api/widget-component';
import {HashtagWidget} from '@widgets/hashtag/hashtag-widget';
import {IStateService} from 'angular-ui-router';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {Hashtag} from '../hashtag';
import {HashtagWidgetService} from './hashtag-widget.service';

/**
 * This widget displays trending hashtags.
 */
@Component({
  templateUrl: './hashtag-widget.component.html',
  styleUrls: ['./hashtag-widget.component.scss']
})
export class HashtagWidgetComponent extends WidgetComponent<HashtagWidget> implements OnInit {

  hashtags$: Observable<Hashtag[]>;

  constructor(@Inject(NG1_STATE_SERVICE) private stateService: IStateService,
              private hashtagWidgetService: HashtagWidgetService,
              cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.hashtags$ = this.getTrendingHashtags();
  }

  /**
   * Opens the search for the given hashtag.
   *
   * @param hashtag the hashtag to search for
   */
  openHashtag(hashtag: Hashtag): void {
    this.stateService.go('main.search', {term: hashtag.tag});
  }

  /**
   * Retrieves the most trending hashtags.
   *
   * @return an `Observable` holding an array of the most trending hashtags
   */
  getTrendingHashtags(): Observable<Hashtag[]> {
    const periodSettings: string = _.get(this.widget.settings, '_period.id', '');
    return this.hashtagWidgetService.getTrendingHashtags(periodSettings);
  }
}

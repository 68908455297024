import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AppComponent} from '@apps/api/app.component';
import {TimelineAppSettings} from '@apps/timeline/timeline-app.settings';
import {Sender} from '@domain/sender/sender';

@Component({
  templateUrl: './timeline-app.component.html',
  styleUrls: ['./timeline-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineAppComponent extends AppComponent<TimelineAppSettings> {

  /**
   * Sender input resolved in the state config
   */
  @Input() sender: Sender;

}

import {Injectable, Type} from '@angular/core';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {IconNameFilePickerItemComponent} from '@app/file-library/icon-name-file-picker-item/icon-name-file-picker-item.component';
import {LandingPagesRootFilePickerItem} from '@app/file-library/landing-page-root-file-picker-item/landing-pages-root-file-picker-item';
import {SenderRootFilePickerItem} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {FilePickerItemService} from '@app/file-picker/file-picker-item.service';
import {LandingPageService} from '@domain/landing-page/landing-page.service';
import {SinglePage} from '@domain/page/single-page';
import {Page} from '@domain/pagination/page';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Service responsible for handling landing page file picker items
 */
@Injectable({
  providedIn: 'root'
})
export class LandingPagesRootFilePickerItemService extends FilePickerItemService {

  constructor(private readonly landingPageService: LandingPageService) {
    super();
  }

  getChildren(filePickerItem: LandingPagesRootFilePickerItem): Observable<Page<FilePickerItem>> {
    return this.landingPageService.getAll({
      permissions: ['createFile'],
    }).pipe(map(pages => (new SinglePage(pages ? pages.map(items => new SenderRootFilePickerItem(items)) : []))));
  }

  getCapabilities(filePickerItem: FilePickerItem): FilePickerItemServiceCapability {
    return FilePickerItemServiceCapability.NONE;
  }

  getComponentType(): Type<FilePickerItemBaseDirective<FilePickerItem>> | null {
    return IconNameFilePickerItemComponent;
  }

  getResponsibilities(): Type<FilePickerItem>[] {
    return [LandingPagesRootFilePickerItem];
  }
}

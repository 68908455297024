import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {SenderEvent} from '@domain/event/sender-event';

import {SenderShareItemComponent} from '../sender-share-item/sender-share-item.component';

/**
 * Timeline share item for events
 */
@Component({
  selector: 'coyo-event-share-item',
  templateUrl: './event-share-item.component.html',
  styleUrls: ['../share-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventShareItemComponent extends SenderShareItemComponent implements OnInit {

  /**
   * The event that should be displayed.
   */
  @Input() sender: SenderEvent;

  description: string;

  constructor(cd: ChangeDetectorRef, windowSizeService: WindowSizeService) {
    super(cd, windowSizeService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.description = this.sender.description;
  }

  isSameDay(): boolean {
    const start = new Date(this.sender.startDate);
    const end = new Date(this.sender.endDate);
    return start.getFullYear() === end.getFullYear()
      && start.getMonth() === end.getMonth()
      && start.getDay() === end.getDay();
  }
}

import {HttpParams} from '@angular/common/http';
import {Order} from './order';

/**
 * Pagination information (e.g. page number, page size, sorting, ...) used to perform paginated requests.
 */
export class Pageable {

  /**
   * The page number of this pageable.
   */
  page: number;

  /**
   * The page size of this pageable.
   */
  pageSize: number;

  /**
   * The offset.
   */
  offset: number;

  /**
   * The sort order of this pageable.
   */
  sort: Order[];

  constructor(page: number = 0,
              pageSize: number = 20,
              offset: number = null,
              ...sort: Order[]) {
    this.page = page;
    this.pageSize = pageSize;
    this.offset = offset;
    this.sort = sort;
  }

  /**
   * Converts this pageable to `HttpParams`.
   *
   * @param params the params to be merged with the pageable
   * @returns a new set of parameters with appended values for this pageable
   */
  toHttpParams(params: HttpParams | { [param: string]: string | string[]; } = new HttpParams()): HttpParams {
    let httpParams = (params instanceof HttpParams ? params : new HttpParams({fromObject: params}));
    httpParams = httpParams.append('_page', this.page.toString());
    httpParams = httpParams.append('_pageSize', this.pageSize.toString());

    if (this.offset != null) {
      httpParams = httpParams.append('_offset', this.offset.toString());
    }

    if (this.sort != null && this.sort.length > 0) {
      this.sort.forEach(order => {
        httpParams = httpParams.append('_orderBy', order.field + ',' + order.direction);
      });
    }

    return httpParams;
  }
}

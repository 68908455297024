(function (angular) {
  'use strict';

  AdminUserDirectoriesDetailsController.$inject = ["$state", "userDirectory", "errorService", "Upload", "disabledDirectoryType"];
  angular
      .module('coyo.admin.userDirectories')
      .controller('AdminUserDirectoriesDetailsController', AdminUserDirectoriesDetailsController);

  function AdminUserDirectoriesDetailsController($state, userDirectory, errorService, Upload, disabledDirectoryType) {
    var vm = this;
    vm.userDirectory = userDirectory;
    vm.validationErrors = {};
    vm.disabledDirectoryType = disabledDirectoryType;

    vm.save = save;

    function save() {
      vm.validationErrors = {};
      if (userDirectory.type === 'csv') {
        return saveCsvImport();
      }
      return vm.userDirectory.save().then(onSuccess, onFailure);
    }

    function saveCsvImport() {
      Upload.upload({
        url: '/web/users/import',
        data: {
          name: userDirectory.name,
          active: _.get(userDirectory, 'active', false),
          activation: _.get(userDirectory, 'settings.activation', false),
          orphanedUsersPolicy: userDirectory.settings.orphanedUsersPolicy,
          recoverDeletedUsers: _.get(userDirectory, 'settings.recoverDeletedUsers', false),
          fileUid: userDirectory.settings.fileUid,
          file: userDirectory.csvFile
        }
      }).then(onSuccess, onFailure);

    }

    function onSuccess() {
      $state.go('^.list');
    }

    function onFailure(error) {
      if (error.status === 400) {
        vm.validationErrors = errorService.getValidationErrors(error);
      }
    }
  }
})(angular);

import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '@core/auth/auth.service';
import {FilePreview} from '@domain/preview/file-preview/file-preview';
import {User} from '@domain/user/user';
import {Store} from '@ngxs/store';
import {FileDetailsModalData} from '@shared/files/file-details-modal/file-details-modal-data';
import {AddFilePreview, InitFileDetails} from '@shared/files/file-details-modal/state/file-details.actions';
import {UuidService} from '@shared/uuid/uuid.service';
import {Observable} from 'rxjs';

/**
 * Material dialog which is showing the details of a file.
 * By now it is only a wrapper for the AngularJS file details component.
 */
@Component({
  selector: 'coyo-file-details-modal',
  templateUrl: './file-details-modal.component.html',
  styleUrls: ['./file-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileDetailsModalComponent implements OnInit {

  user$: Observable<User>;

  fileDetailsDialogId: string;

  nextFunction: (currentIndex: number) => Promise<{ file: FilePreview, author?: User }>;

  constructor(
    private dialog: MatDialogRef<FileDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileDetailsModalData,
    private authService: AuthService,
    private store: Store,
    private uuidService: UuidService
  ) {
  }

  ngOnInit(): void {
    this.user$ = this.authService.getUser();
    this.fileDetailsDialogId = this.uuidService.getUuid();
    this.store.dispatch(
      new InitFileDetails(
        this.fileDetailsDialogId,
        this.data.files,
        this.data.fileAuthors,
        {showAuthors: this.data.showAuthors, appIdOrSlug: this.data.appIdOrSlug}
      )
    );

    if (this.data.loadNext) {
      this.nextFunction = (currentIndex =>
          this.data
            .loadNext(currentIndex)
            .then(next => {
              this.store.dispatch(new AddFilePreview(this.fileDetailsDialogId, next.file, next.author));
              return next;
            })
      );
    }
  }

  close(result: unknown): void {
    this.dialog.close(result);
  }
}

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'coyo-event-settings-delete-confirmation-modal',
  templateUrl: './event-settings-delete-confirmation-modal.component.html',
  styleUrls: ['./event-settings-delete-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventSettingsDeleteConfirmationModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'coyo-video-preview-cover',
  templateUrl: './video-preview-cover.component.html',
  styleUrls: ['./video-preview-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPreviewCoverComponent {
}

import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {FileLibraryFilePickerService} from '@app/file-library/file-library-file-picker-service/file-library-file-picker.service';
import {App} from '@apps/api/app';
import {AuthService} from '@core/auth/auth.service';
import {AppService} from '@domain/apps/app.service';
import {Document as DocumentModel} from '@domain/file/document';
import {DocumentService} from '@domain/file/document/document.service';
import {FileService} from '@domain/file/file/file.service';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {TranslateService} from '@ngx-translate/core';
import {RTE_PLUGINS, RtePlugin} from '@shared/rte/rte-plugin';
import * as _ from 'lodash';
import {BehaviorSubject, EMPTY, Observable, of, zip} from 'rxjs';
import {filter, finalize, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {RTE_OPTIONS, RteOptions} from '../rte-options';
import {RteSettings} from './rte-settings/rte-settings';
import {RteSettingsService} from './rte-settings/rte-settings.service';

/**
 * A rich text editor component.
 *
 * This component supports AngularJS usage with ng-model.
 */
@Component({
  selector: 'coyo-rte',
  templateUrl: './rte.component.html',
  styleUrls: ['./rte.component.scss']
})
export class RteComponent implements OnInit, ControlValueAccessor {

  loading: boolean = true;
  options: { [key: string]: any; } = {};
  serverSettings: RteSettings;
  sender: Sender;
  app: App<any>;
  onChangeCallback: any;
  onTouchedCallback: any;
  editor: any;
  focused: boolean;
  overlayClass$: BehaviorSubject<string>;
  canUpload: boolean;

  /**
   * A map of RTE options overriding the default options.
   */
  @Input() optionOverrides: { [key: string]: any; };
  /**
   * The default height of the RTE.
   */
  @Input() height: number;
  /**
   * The content of the RTE.
   */
  @Input() content: any;
  /**
   * Event emitted when the contend of the RTE is changed.
   */
  @Output() contentUpdated: EventEmitter<string> = new EventEmitter<string>();

  constructor(private ngZone: NgZone,
              private translate: TranslateService,
              private settingsService: RteSettingsService,
              private senderService: SenderService,
              private appService: AppService,
              private authService: AuthService,
              private cd: ChangeDetectorRef,
              private fileLibraryFilePickerService: FileLibraryFilePickerService,
              private documentService: DocumentService,
              private fileService: FileService,
              @Inject(DOCUMENT) private document: Document,
              @Optional() @Inject(RTE_PLUGINS) private plugins: RtePlugin[],
              @Optional() @Inject(RTE_OPTIONS) private optionDefaults: RteOptions) {
  }

  ngOnInit(): void {
    this.overlayClass$ = new BehaviorSubject<string>('hidden');
    this.initSender();
    this.settingsService.getSettings()
      .pipe(tap(settings => this.serverSettings = settings))
      .pipe(finalize(() => {
        this.options = this.buildOptions();
        this.loading = false;
      })).subscribe(settings => {
      this.initPlugins(settings);
      this.cd.markForCheck();
    });
  }

  /**
   * Handles ESC clicks and closes the fullscreen mode of the editor.
   */
  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(): void {
    if (this.editor && this.editor.fullscreen.isActive()) {
      this.editor.fullscreen.toggle();
    }
  }

  /**
   * Emits an content updated event and calls the onChangeCallback.
   *
   * @param $event the Froala updated event
   */
  emitContentUpdatedEvent($event: any): void {
    this.contentUpdated.emit($event);
    if (this.onChangeCallback) {
      this.onChangeCallback($event);
    }
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(obj: any): void {
    this.content = obj;
  }

  /**
   * Called when pasting content into the RTE. Fixes the Froala issue that word documents
   * which do not create a rich text format in the clipboard are not pasted into the editor.
   *
   * @param editor the editor instance
   * @param event the original clipboard event
   * @return false when word paste event is triggered manually, true otherwise
   */
  triggerWordPasteForNoRTFDocuments(editor: any, event: ClipboardEvent): boolean {
    if (event.clipboardData) {
      const html = event.clipboardData.getData('text/html') || '';
      const rtf = event.clipboardData.getData('text/rtf');
      const isWord = html.match(/(class=\"?Mso|class=\'?Mso|class="?Xl|class='?Xl|class=Xl|style=\"[^\"]*\bmso\-|style=\'[^\']*\bmso\-|w:WordDocument)/gi);
      if (html && !rtf && isWord) {
        editor.events.trigger('paste.wordPaste', [html], true);
        return false;
      }
      return true;
    }
  }

  showDropHint(): void {
    if (this.isUploadAllowed()) {
      this.ngZone.run(() => this.overlayClass$.next('drop-overlay'));
    }
  }

  hideDropHint(): void {
    this.ngZone.run(() => this.overlayClass$.next('hidden'));
  }

  startDrop(event: DragEvent): boolean {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  handleDropEvent(event: DragEvent): boolean {
    this.hideDropHint();
    if (event.dataTransfer?.files?.length) {
      this.uploadImages(event.dataTransfer.files);
    }
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  private initSender(): void {
    const senderId = this.senderService.getCurrentIdOrSlug();
    zip(
      senderId ? this.senderService.get(senderId) : this.authService.getUser(),
      senderId ? this.appService.getCurrentApp(senderId) : of(null)
    ).subscribe(([sender, app]) => {
      this.sender = sender;
      this.app = app;
    });
  }

  private initPlugins(settings: RteSettings, force: boolean = false): void {
    if (this.plugins) {
      this.plugins.forEach(plugin => plugin.initialize(settings, force));
    }
  }

  private buildOptions(): { [key: string]: any; } {
    const component = this;
    const removeImg = ['fr-dib', 'fr-dii', 'fr-fil', 'fr-fir'];
    const removeVid = ['fr-dvb', 'fr-dvi', 'fr-fvl', 'fr-fvr'];

    const extendedOptions: { [key: string]: any; } = _.extend({
        events: {
          'initialized': function(): void {
            this.events.on('dragenter', function(event: any): boolean {
              if (event.originalEvent.dataTransfer.types.indexOf('Files') > -1) {
                component.showDropHint();
                event.preventDefault();
                event.stopPropagation();
                return false;
              } else {
                return true;
              }
            }, true);
            this.events.on('drop', function($dropEvent: any): boolean {
              if ($dropEvent.originalEvent.dataTransfer.types.indexOf('Files') > -1) {
                $dropEvent.preventDefault();
                $dropEvent.stopPropagation();
                return false;
              } else {
                return true;
              }
            }, true);
            component.editor = this;
            component.editor.getSender = () => component.sender;
            component.editor.getApp = () => component.app;
            component.document.querySelectorAll('.fr-view img, .fr-view span.fr-img-caption')
              .forEach(elem => elem.classList.remove(...removeImg));
            component.document.querySelectorAll('.fr-view .fr-video').forEach(elem => {
              elem.classList.remove(...removeImg);
              elem.setAttribute('draggable', 'true');
              elem.setAttribute('contentEditable', 'true');
            });
            component.document.querySelectorAll('.fr-view span[coyo-download]').forEach(elem => {
              let link = elem.getAttribute('coyo-download');
              link = link ? link.replace(/\'/g, '') : link;
              elem.outerHTML = `<a href="${link}" target="_self">${elem.innerHTML}</a>`;
            });
          },
          'image.inserted': function($images: any): void {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < $images.length; i++) {
              if ($images[i].src.startsWith('blob:')) {
                $images[i].parentElement.removeChild($images[i]);
              }
            }
            $images.addClass('fr-cdbc');
            removeImg.forEach(imgClass => {
              $images.removeClass(imgClass);
            });
          },
          'video.inserted': function($video: any): void {
            $video.addClass('fr-cdbc');
            removeVid.forEach(videoClass => {
              $video.removeClass(videoClass);
            });
          },
          'table.inserted': function(table: HTMLTableElement): void {
            table.classList.add('fr-table');
            table.classList.add('fr-cdbl');
            table.classList.add('rte-table-bordered');
          },
          'table.resized': function(table: HTMLTableElement): void {
            if (table.classList.contains('fr-cdil') || table.classList.contains('fr-cdir')) {
              table.style.setProperty('margin-left', null);
              table.style.setProperty('margin-right', null);
            }
          },
          'focus': function(): void {
            component.ngZone.run(() => {
              component.focused = true;
              if (component.onTouchedCallback) {
                component.onTouchedCallback();
              }
            });
          },
          'blur': function(): void {
            component.ngZone.run(() => {
              component.focused = false;
              if (this.codeView && this.codeView.isActive()) {
                this.codeView.toggle();
              }
              if (component.onChangeCallback) {
                component.onChangeCallback(component.content);
              }
            });
          },
          'paste.before': function(event: ClipboardEvent): void {
            component.triggerWordPasteForNoRTFDocuments(this, event);
          }
        }
      },
      RteOptions.DEFAULT_OPTIONS,
      this.optionDefaults ? this.optionDefaults.get() : {},
      {key: _.get(this.serverSettings, 'license')},
      this.optionOverrides);

    return _.extend(extendedOptions, {
      paragraphFormat: _.mapValues(extendedOptions.paragraphFormat, key => this.translate.instant(key)),
      paragraphStyles: _.mapValues(extendedOptions.paragraphStyles, key => this.translate.instant(key)),
      tableStyles: _.mapValues(extendedOptions.tableStyles, key => this.translate.instant(key)),
      imageStyles: _.mapValues(extendedOptions.imageStyles, key => this.translate.instant(key))
    });
  }

  private uploadImages(files: FileList): Observable<void> {
    if (this.isUploadAllowed()) {
      const wrapper = this.editor.$oel.find('.fr-wrapper');
      const scrollPosition = wrapper.scrollTop();
      const request = this.openFilePicker(files);

      const nextHandler = (documents: DocumentModel[]) => {
        for (const document of documents) {
          if (document.contentType.startsWith('image')) {
            const url = this.documentService.getDownloadUrl(document);
            this.editor.image.insert(url, true, {});
            this.editor.selection.clear();
          } else if (document.contentType.startsWith('video')) {
            const url = this.documentService.getDownloadUrl(document);
            this.editor.video.insert(`<video src="${url}" controls></video>`);
            this.editor.selection.clear();
          } else {
            this.editor.link.insert(this.fileService.getRelativeDeepUrl(document), document.displayName);
            this.editor.selection.clear();
          }
        }
        wrapper.scrollTop(scrollPosition);
      };

      const errorHandler = () => {
        this.editor.selection.restore();
        wrapper.scrollTop(scrollPosition);
      };

      request.subscribe({
        next: nextHandler,
        error: errorHandler
      });
      return request.pipe(map(() => null));
    } else {
      return EMPTY;
    }
  }

  private openFilePicker(files?: FileList): Observable<DocumentModel[]> {
    const initialFolderRequest = this.app ?
      this.fileService.getFile(this.app.rootFolderId, this.sender.id, ['*']) :
      of(null);
    return initialFolderRequest.pipe(
      switchMap(initialFolder => {
        const canUpload = initialFolder ? (!!initialFolder._permissions?.manage) :
          this.sender ? (!!this.sender._permissions?.manage) : true;
        if (canUpload) {
          return this.fileLibraryFilePickerService.open(this.sender, {
            selectionType: 'multiple',
            upload: files,
            initialFolder,
            contentTypes: this.uploadMimeTypes()
          });
        } else {
          return EMPTY;
        }
      }),
      filter(result => !!result?.length),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  private isPluginEnabled(name: string): boolean {
    return this.options.pluginsEnabled.indexOf(name) >= 0;
  }

  private isUploadAllowed(): boolean {
    return this.isPluginEnabled('coyoFilePlugin') ||
      this.isPluginEnabled('coyoVideoPlugin') ||
      this.isPluginEnabled('coyoImagePlugin');
  }

  private uploadMimeTypes(): string[] {
    const types: string[] = [];
    if (!this.isPluginEnabled('coyoFilePlugin')) {
      if (this.isPluginEnabled('coyoVideoPlugin')) {
        types.push('video');
      }
      if (this.isPluginEnabled('coyoImagePlugin')) {
        types.push('image');
      }
    }
    return types;
  }
}

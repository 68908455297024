import {AfterContentChecked, AfterViewInit, Directive, ElementRef} from '@angular/core';
import {FloatingModulesConstants} from '@shared/floating-modules/floating-modules.constants';

/**
 * This directive is used as an attribute directive (meaning you can set them on any element)
 * and renders a floating-footer on any element when being set as attribute, that have a
 * scrollable content container and display it according to the scroll position of the
 * scrollable container - similar to the Angular Material Dialog floating header and footer effect.
 *
 * You need to set the following in the template:
 * - Set attribute 'coyoFloatingFooter' on any element to act as the footer element below
 *  the scrollable container to put the floating-footer behaviour and effect on it.
 * - Set the scrollable container which is connected to the floating-footer by
 *  adding class 'floating-scroll-container' to the scrollable-container element.
 *
 * This is usable for multiple occurrences like for e.g. in the user-chooser.
 */
@Directive({
  selector: '[coyoFloatingFooter]'
})
export class FloatingFooterDirective implements AfterViewInit, AfterContentChecked {

  private _element: HTMLElement;
  private _isFloating: boolean = false;
  private _scrollContainer: HTMLCollection;

  constructor(element: ElementRef) {
    this._element = element.nativeElement;
  }

  static _getContainerScrollPosition(scrollContainer: HTMLElement): number {
    return scrollContainer.scrollTop;
  }

  /*
   * This formula is the only working one I found to correctly calculate what that maximum
   * scroll height of a scroll container is (important, as it gets compared to the scrollTop value!).
   *
   * Actually this exactly returns the value for the container, that scrollTop can have at maximum.
   */
  static _getContainerMaxScrollHeight(scrollContainer: HTMLElement): number {
    return scrollContainer.scrollHeight - scrollContainer.offsetHeight;
  }

  ngAfterViewInit(): void {
    this.updateScrollContainerEventListener();
  }

  ngAfterContentChecked(): void {
    this.updateScrollContainerEventListener();
  }

  private updateScrollContainerEventListener(): void {
    // Get the scrollContainer which is a sibling and found from root of previous element of floating footer
    this._scrollContainer =
      this._element.previousElementSibling.getElementsByClassName(FloatingModulesConstants.SCROLL_CONTAINER_CLASS_NAME);

    /* tap into the elements scroll event after iterating HTMLCollection of HTMLElements */
    for (const element of Array.from(this._scrollContainer)) {
      const scrollContainer = element as HTMLElement;

      // Initially updating scroll position of scroll container, for e.g. when content grows
      this._updateScrollPosition(scrollContainer);

      // Add event listener to scrollContainer connected to the reference element as a sibling
      scrollContainer.addEventListener('scroll', () => {
        this._updateScrollPosition(scrollContainer);
      });
    }
  }

  private _updateScrollPosition(scrollContainer: HTMLElement): void {
    const containerTop = FloatingFooterDirective._getContainerScrollPosition(scrollContainer);
    const containerMaxScrollHeight = FloatingFooterDirective._getContainerMaxScrollHeight(scrollContainer);

    if (containerTop < containerMaxScrollHeight && !this._isFloating) {
      this._makeFloat();
      this._isFloating = true;
    } else if (containerTop >= containerMaxScrollHeight && this._isFloating) {
      this._resetFloat();
      this._isFloating = false;
    }
  }

  private _makeFloat(): void {
    this._element.style.cssText += 'position: -webkit-sticky; position: sticky;';
    this._element.style.cssText += `box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
                                                0 4px 5px 0 rgba(0, 0, 0, 0.14),
                                                0 1px 10px 0 rgba(0, 0, 0, 0.12)`;
    this._element.style.cssText += 'box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)';
    this._element.style.top = '0';
    this._element.style.zIndex = '10';
  }

  private _resetFloat(): void {
    this._element.style.position = '';
    this._element.style.top = '';
    this._element.style.boxShadow = '';
    this._element.style.transition = '';
    this._element.style.zIndex = '';
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '@apps/api/app.component';
import {DocumentAppSettings} from '@apps/document/document-app.settings';
import {Sender} from '@domain/sender/sender';

@Component({
  selector: 'coyo-document-app',
  templateUrl: './document-app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentAppComponent extends AppComponent<DocumentAppSettings> implements OnInit {

  /**
   * Sender input resolved in the state config
   */
  @Input() sender: Sender;

  /**
   * Default settings for the old file library
   */
  legacyOptions: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.legacyOptions = {
      uploadMultiple: true,
      senderAsRoot: !this.app.rootFolderId,
      initialFolder: {id: this.app.rootFolderId},
      initialFolderAsRoot: !!this.app.rootFolderId,
      showAuthors: this.app.settings.showAuthors,
      appId: this.app.id
    };
  }

}

/**
 * A duration unit
 */
export enum DurationUnit {
  MILLISECONDS, SECONDS, MINUTES, HOURS, DAYS, MONTHS, YEARS
}

/**
 * Internal class to pass reduction results from one unit to the next when performing operations on the duration.
 * This is a very basic approach and normally this should rather be an abstract class with no properties whatsoever.
 */
class Adjustment {
  static readonly NOOP: Adjustment = new Adjustment(0, 1);

  constructor(public value: number, public rollover: number) {
  }

  /**
   * Asserts that the value is 0
   *
   * @param errorMessage
   * the error message to be shown when value is not correct
   */
  assertValue(errorMessage: string): void {
    if (this.value !== 0) {
      throw new Error(errorMessage);
    }
  }
}

/**
 * Internal class to represent a unit's value and the (reduction) operations.
 */
class DurationUnitValue {
  constructor(public unit: DurationUnit, public value: number, public rollover: number | null = null,
              public rolloverFactor: number | null = 1, public disabled: boolean = false) {
  }

  /**
   * Create a DurationUnitValue from a number or other DurationUnitValue
   *
   * @param value
   * The source duration
   * @param unit
   * The unit of the given value if it is a number
   * @param rollover
   * The rollover value
   *
   * @return the new duration
   */
  static from(value: number | DurationUnitValue, unit: DurationUnit, rollover?: number): DurationUnitValue {
    if (value instanceof DurationUnitValue) {
      return value.clone();
    } else {
      return new DurationUnitValue(unit, value, rollover);
    }
  }

  /**
   * Clones a DurationUnitValue
   *
   * @return the cloned value
   */
  clone(): DurationUnitValue {
    return new DurationUnitValue(this.unit, this.value, this.rollover, this.rolloverFactor, this.disabled);
  }

  /**
   * Gets the effective rollover
   *
   * @return the rollover value
   */
  getEffectiveRollover(): number | null {
    const effRollover = this.rollover * this.rolloverFactor;
    return isNaN(effRollover) ? null : effRollover;
  }

  /**
   * Adds a value to the duration
   *
   * @param value
   * The amount to add
   *
   * @return the mutated object
   */
  plus(value: number): DurationUnitValue {
    this.value += value;
    return this;
  }

  /**
   * Normalizes the DurationUnitValue
   *
   * @return the carry
   */
  normalize(): number {
    const effectiveRollover = this.getEffectiveRollover();
    if (effectiveRollover && !this.disabled) {
      const carry = Math.floor(this.value / effectiveRollover);
      this.value = this.value % effectiveRollover;
      return carry;
    }
    return 0;
  }

  /**
   * Disables the DurationUnitValue
   *
   * @return an Adjustment of the current
   */
  disable(): Adjustment {
    const oldValue = this.value;
    this.disabled = true;
    this.value = 0;
    return new Adjustment(oldValue, this.rollover);
  }

  /**
   * Adjusts the value when a higher unit is disabled. Multiplies the given adjustment value with the rollover of the
   * unit. If this unit is not disabled the method mutates this DurationUnitValue.
   *
   * @param adjustment
   * The adjustment to apply to the duration value
   *
   * @return A noop adjustment if this unit is not diabled or another Adjustment if this unit is diabled
   */
  adjustFromDisabledHigherUnit(adjustment: Adjustment): Adjustment {
    this.rolloverFactor = Number.isInteger(adjustment.rollover) ? this.rolloverFactor * adjustment.rollover : null;
    const valueToAdd = this.rollover * adjustment.value;
    if (this.disabled && ((adjustment.value !== 0) || (adjustment.rollover !== 1))) {
      return new Adjustment(valueToAdd, this.rollover);
    } else {
      this.value += valueToAdd;
      return Adjustment.NOOP;
    }
  }

  /**
   * Adds an Adjustment from a lower unit.
   *
   * @param adjustment
   * The adjustment to add
   *
   * @return an Adjustment with a rollover value of 1 or the source adjustment if this value is diabled.
   */
  adjustFromNormalizedLowerUnit(adjustment: Adjustment): Adjustment {
    return this.disabled ? adjustment : new Adjustment(this.plus(adjustment.value).normalize(), 1);
  }

  /**
   * Calculates the unit value of the given adjustment
   *
   * @param adjustment
   * The adjustment to calculate the unit value from
   *
   * @return an Adjustment containing the value of the DurationUnit plus
   * the value of the given adjustment multiplied by the rollover
   */
  toUnitValue(adjustment: Adjustment): Adjustment {
    const effRollover = Number.isInteger(this.rollover) ? this.rollover : 0;
    return new Adjustment(adjustment.value * effRollover + this.value, 0);
  }

  /**
   * Constructs a string representation of the DurationUnitValue
   *
   * @return the string representation
   */
  toString(): string {
    return '[unit:' + DurationUnit[this.unit] + ',value:' + this.value + ',disabled:' + this.disabled + ',rollover:'
      + this.rollover + ',factor:' + this.rolloverFactor + ']';
  }
}

/**
 * Class to represent a duration in time. A duration is immutable and every operation will result in a new duration
 * instance.
 * This class allows units to be disabled.
 */
export class Duration {
  static readonly ZERO: Duration = Duration.from(0, 0, 0, 0, 0, 0, 0);

  private constructor(private units: Map<DurationUnit, DurationUnitValue>) {
  }

  /**
   * Creates a duration
   *
   * @param years
   * The number of years
   * @param months
   * The number of months
   * @param days
   * The number of days
   * @param hours
   * The number of hours
   * @param minutes
   * The number of minutes
   * @param seconds
   * The number of seconds
   * @param milliseconds
   * The number of milliseconds
   * @return The duration
   */
  static from(years: number, months: number, days: number, hours: number, minutes: number, seconds: number,
              milliseconds: number): Duration {
    const units = new Map();
    [
      DurationUnitValue.from(years, DurationUnit.YEARS), DurationUnitValue.from(months, DurationUnit.MONTHS, 12),
      DurationUnitValue.from(days, DurationUnit.DAYS, 31), DurationUnitValue.from(hours, DurationUnit.HOURS, 24),
      DurationUnitValue.from(minutes, DurationUnit.MINUTES, 60), DurationUnitValue.from(seconds, DurationUnit.SECONDS, 60),
      DurationUnitValue.from(milliseconds, DurationUnit.MILLISECONDS, 1000)
    ].forEach(unitValue => units.set(unitValue.unit, unitValue));
    return new Duration(units);
  }

  /**
   * Get the value for the given unit.
   *
   * @param unit The unit
   * @returns The unit's value
   */
  get(unit: DurationUnit): number {
    return this.units.get(unit).value;
  }

  /**
   * Gets the years of a duration
   *
   * @return the year count
   */
  get years(): number {
    return this.get(DurationUnit.YEARS);
  }

  /**
   * Gets the months of a duration
   *
   * @return the month count
   */
  get months(): number {
    return this.get(DurationUnit.MONTHS);
  }

  /**
   * Gets the days of a duration
   *
   * @return the day count
   */
  get days(): number {
    return this.get(DurationUnit.DAYS);
  }

  /**
   * Gets the hours of a duration
   *
   * @return the hour count
   */
  get hours(): number {
    return this.get(DurationUnit.HOURS);
  }

  /**
   * Gets the minutes of a duration
   *
   * @return the minutes count
   */
  get minutes(): number {
    return this.get(DurationUnit.MINUTES);
  }

  /**
   * Gets the seconds of a duration
   *
   * @return the seconds count
   */
  get seconds(): number {
    return this.get(DurationUnit.SECONDS);
  }

  /**
   * Gets the milliseconds of a duration
   *
   * @return the millisecond count
   */
  get milliseconds(): number {
    return this.get(DurationUnit.MILLISECONDS);
  }

  /**
   * Returns the value for the given unit where a rollover to the next enabled unit would happen when the duration gets
   * normalized. Example: 25 hours would be normalized to 1 day and 1 hour.
   * If a unit is disabled then the effective rollover is higher. Example: If the MINUTES are disabled then the
   * rollover for the SECONDS unit is not 60 but 3600.
   * The rollover is null if the unit is infinite like for YEARS, as there is no higher unit. If the YEARS are disabled
   * then the rollover for the next lower enabled unit, like MONTHS, will be null.
   * Although there is no fixed rollover for DAYS, as a month has variable length, the rollover value here will be
   * 31 days for the sake of simplicity.
   *
   * @param unit The unit
   * @returns The rollover value or null if the value can be infinite.
   */
  getRollover(unit: DurationUnit): number | null {
    return this.units.get(unit).getEffectiveRollover();
  }

  /**
   * Sets a unit to the given value.
   * NOTA BENE: doesn't check for disabled units yet
   *
   * @param unit The unit
   * @param value The value to set
   * @returns A new duration instance with the new value
   */
  set(unit: DurationUnit, value: number): Duration {
    const newUnits = this.cloneUnits();
    newUnits.get(unit).value = value;
    return new Duration(newUnits);
  }

  /**
   * Adds a value to the given unit.
   * NOTA BENE: doesn't check for disabled units yet
   *
   * @param unit The unit
   * @param value The value to add
   * @returns A new duration instance with the new value
   */
  plus(unit: DurationUnit, value: number): Duration {
    const newUnits = this.cloneUnits();
    newUnits.get(unit).plus(value);
    return new Duration(newUnits);
  }

  /**
   * Disable a unit. This will have effects on the way normalization works.
   *
   * @param units The units to disable
   * @returns A new duration instance with the disabled unit
   */
  disable(units: DurationUnit[]): Duration {
    const newUnits = this.cloneUnits();
    (units || []).forEach(unit => {
      const adjustment = this.visit(
        this.descending(newUnits, this.smallerThan(unit)), newUnits.get(unit).disable(),
        DurationUnitValue.prototype.adjustFromDisabledHigherUnit);
      adjustment.assertValue('Illegal state while disabling units.'
        + ' Tried to disable smallest enabled unit having a value greater than zero.');
    });
    return new Duration(newUnits);
  }

  /**
   * Normalize a unit. This means that a unit value should be in the bounds that no rollover to the next enabled unit
   * would occur. The rollover value is then added to this next unit.
   * Example: a duration PT22H190M would be normalized to P1DT1H10M
   *
   * @returns A new duration instance with the normalized units
   */
  normalize(): Duration {
    const newUnits = this.cloneUnits();
    const adjustment = this.visit(
      this.ascending(newUnits), Adjustment.NOOP, DurationUnitValue.prototype.adjustFromNormalizedLowerUnit);
    adjustment.assertValue('Illegal state while normalizing units');
    return new Duration(newUnits);
  }

  /**
   * Get the milliseconds of a given unit. A MONTH is assumed to be 31 DAYS.
   *
   * @returns The duration in milliseconds
   */
  toMilliseconds(): number {
    return this.visit(this.descending(this.units), Adjustment.NOOP, DurationUnitValue.prototype.toUnitValue).value;
  }

  /*
  public toDebug(): string {
    return this.descending(this.units).reduce((dbg, unit) => dbg + unit.toString(), '');
  }
  */

  private cloneUnits(): Map<DurationUnit, DurationUnitValue> {
    const units = new Map();
    this.units.forEach((value, key) => units.set(key, value.clone()));
    return units;
  }

  /*
   * Visit each of the given units in a map/reduce fashion to manipulate the units and/or calculate a result.
   */
  private visit(units: DurationUnitValue[], start: Adjustment, fnAdjust: (adjustment: Adjustment) => Adjustment): Adjustment {
    return units.reduce((adj, unitValue) => fnAdjust.bind(unitValue)(adj), start);
  }

  private ascending(units: Map<DurationUnit, DurationUnitValue>,
                    fnFilter?: (unitKey: string) => boolean): DurationUnitValue[] {
    const filter: (unitKey: string) => boolean = fnFilter ? fnFilter : () => true;
    return Object.keys(DurationUnit)
      .filter(key => !isNaN(Number(DurationUnit[key])) && filter(key))
      .map(key => units.get(DurationUnit[key]));
  }

  private descending(units: Map<DurationUnit, DurationUnitValue>,
                     fnFilter?: (unitKey: string) => boolean): DurationUnitValue[] {
    return this.ascending(units, fnFilter).reverse();
  }

  private smallerThan(unit: DurationUnit): (unitKey: string) => boolean {
    return unitKey => DurationUnit[unitKey] < unit;
  }
}

/**
 * Duration formatter.
 */
export class DurationFormatter {

  private static DURATION_PATTERN: RegExp = new RegExp(
    '^P(?!$)(?:(\\d+)Y)?(?:(\\d+)M)?(?:(\\d+)D)?(?:T(?=\\d)(?:(\\d+)H)?(?:(\\d+)M)?(?:(\\d+)(?:\\.(\\d{1,3}))?S)?)?$');

  /**
   * Parse an ISO 8601 compliant string representation of a duration.
   * Example: 'P2M12DT3H0.12S' is 2 months, 12 days, 3 hours and 12 milliseconds.
   * A duration always begins with the letter P. Date and time fields are separated by the letter T to make months
   * and minutes unambiguous. Seconds may receive up to three fractional digits for milliseconds.
   *
   * @param isoDuration The ISO 8601 representation
   * @returns The parsed duration
   */
  static parseIso8601(isoDuration: string): Duration {
    const match = DurationFormatter.DURATION_PATTERN.exec(isoDuration);
    if (match === null) {
      throw new Error('Illegal duration format "' + isoDuration + '"');
    }
    const toInt = (intStr: string) => intStr ? Number(intStr) : 0;
    const toMillis = (intStr: string) => intStr ? Number('0.' + intStr) * 1000 : 0;
    return Duration.from(toInt(match[1]), toInt(match[2]), toInt(match[3]),
      toInt(match[4]), toInt(match[5]), toInt(match[6]), toMillis(match[7]));
  }

  /**
   * Format a duration as an ISO 8601 compliant string representation.
   *
   * @param duration
   * The duration
   *
   * @returns The ISO 8601 representation
   */
  static formatIso8601(duration: Duration): string {
    const renderUnit = (unit: number, unitSymbol: string) => (unit > 0) ? ('' + unit + unitSymbol) : '';
    const datePart = renderUnit(duration.years, 'Y') + renderUnit(duration.months, 'M')
      + renderUnit(duration.days, 'D');

    const padAndTrim = (v: number) => ('00' + v).slice(-3).replace(/^(\d*[1-9]+)0+/, '$1');
    const milliPartWithSymbol = ((duration.milliseconds > 0) ? ('.' + padAndTrim(duration.milliseconds)) : '') + 'S';
    const timePart = renderUnit(duration.hours, 'H') + renderUnit(duration.minutes, 'M')
      + renderUnit(duration.seconds, milliPartWithSymbol);

    const hasDatePart = datePart !== '';
    const hasTimePart = timePart !== '';
    const optionalZero = hasDatePart || hasTimePart ? '' : 'T0S';
    return 'P' + datePart + (hasTimePart ? 'T' + timePart : '') + optionalZero;
  }
}

import {Inject, Injectable, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SocketConnectionLostModalComponent} from '@app/socket/socket-connection-lost-modal/socket-connection-lost-modal.component';
import {SocketService} from '@core/socket/socket.service';
import {MatDialogSize} from '@coyo/ui';
import {WINDOW} from '@root/injection-tokens';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {ActiveToast} from 'ngx-toastr';
import {Subscription} from 'rxjs';

enum State {
  Connected,
  Disconnected,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class SocketConnectionMonitorService implements OnDestroy {

  private offlineSubscription: Subscription;
  private disconnectSubscription: Subscription;
  private reconnectSubscription: Subscription;
  private state: State = State.Connected;
  private showNotification: boolean;
  private connectionLostToast: ActiveToast<any>;
  private connectionLostModalRef: MatDialogRef<SocketConnectionLostModalComponent, boolean>;

  constructor(@Inject(WINDOW) private window: Window,
              private socketService: SocketService,
              private notificationService: NotificationService,
              private dialog: MatDialog) {
  }

  private static safeUnsubscribe(subscription: Subscription): void {
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
  }

  init(): void {
    this.offlineSubscription = this.socketService.listenToOffline$().subscribe(() => {
      this.setState(State.Offline, this.showNotification);
      this.closeNotificationToast();
      this.connectionLostModalRef = this.dialog
        .open<SocketConnectionLostModalComponent, boolean>(SocketConnectionLostModalComponent, {
          autoFocus: true,
          width: MatDialogSize.Small
        });
      this.connectionLostModalRef.afterClosed().subscribe(reload => {
        if (reload) {
          this.reload();
        } else {
          this.evaluateNotificationToast();
        }
      });
    });
    this.disconnectSubscription = this.socketService.listenToDisconnect$().subscribe((eventWithArgs: [Event, boolean]) => {
      this.setState(State.Disconnected, eventWithArgs[1]);
      this.evaluateNotificationToast();
    });
    this.reconnectSubscription = this.socketService.listenToReconnect$().subscribe(() => {
      this.setState(State.Connected, false);
      this.evaluateNotificationToast();
    });
  }

  ngOnDestroy(): void {
    this.closeNotificationToast();
    if (this.connectionLostModalRef) {
      this.connectionLostModalRef.close(false);
    }
    SocketConnectionMonitorService.safeUnsubscribe(this.offlineSubscription);
    SocketConnectionMonitorService.safeUnsubscribe(this.disconnectSubscription);
    SocketConnectionMonitorService.safeUnsubscribe(this.reconnectSubscription);
  }

  private setState(newState: State, showNotification: boolean): void {
    this.state = newState;
    this.showNotification = showNotification;
  }

  private evaluateNotificationToast(): void {
    if (this.state !== State.Connected && this.showNotification) {
      if (!this.connectionLostToast) {
        this.connectionLostToast = this.notificationService.error(
          'SOCKETS.RECONNECT.WARNING.MSG', 'SOCKETS.RECONNECT.WARNING.CAPTION', true, {
            closeButton: false, disableTimeOut: true, tapToDismiss: false
          });
        this.connectionLostToast.toastRef.afterClosed().subscribe(() => {
          this.connectionLostToast = null;
        });
      }
    } else {
      this.closeNotificationToast();
    }
  }

  private closeNotificationToast(): void {
    if (this.connectionLostToast) {
      this.connectionLostToast.toastRef.close();
    }
  }

  private reload(): void {
    this.window.location.reload();
  }
}

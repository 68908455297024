import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {App} from '@apps/api/app';
import {AppService} from '@domain/apps/app.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {LatestWikiArticlesWidget} from '@widgets/latest-wiki-articles/latest-wiki-articles-widget';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';

/**
 * The latest-wiki-articles widget settings component.
 */
@Component({
  selector: 'coyo-latest-wiki-articles-widget-settings',
  templateUrl: './latest-wiki-articles-widget-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestWikiArticlesWidgetSettingsComponent extends WidgetSettingsComponent<LatestWikiArticlesWidget>
  implements OnInit {
  selectedApp: App<any>;

  constructor(private appService: AppService) {
    super();
  }

  ngOnInit(): void {
    const selectedAppId = _.get(this.widget.settings, '_appId', null);
    const selectedSenderId = _.get(this.widget.settings, '_senderId', null);
    const articleCount = _.get(this.widget.settings, '_articleCount', 5);
    this.parentForm.addControl('selectedApp', new FormControl([]));
    this.parentForm.addControl('articleCount', new FormControl(articleCount,
      [Validators.required, Validators.min(1), Validators.max(20), CoyoValidators.naturalNumber]));
    if (!!selectedSenderId && !!selectedAppId) {
      this.appService.get(selectedAppId, {context: {senderId: selectedSenderId}})
        .subscribe(app => {
          this.parentForm.patchValue({selectedApp: app});
        });
    }
  }

  /**
   * Transforms the form data to settings data
   * @param settings The settings stored in the form
   * @returns An observable emitting the transformed save data
   */
  onBeforeSave(settings?: any): Observable<any> {
    return of({
      _articleCount: settings.articleCount,
      _appId: _.get(settings, 'selectedApp.id'),
      _senderId: _.get(settings, 'selectedApp.senderId')
    });
  }
}

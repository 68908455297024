<span class="user-avatar-image {{avatarSize}}" title="{{user.displayName}}">
    <coyo-avatar-image *ngIf="!loadError" (error)="errorHandler()"
                       [imageUrl]="user.imageUrls?.avatar"
                       [avatarSize]="avatarSize"
                       [avatarBackground]="user.color"
                       [textAlternative]="user.displayName"
                       [allowModal]="allowModal"></coyo-avatar-image>

  <span class="user-avatar-empty" *ngIf="!user.imageUrls?.avatar || loadError"
        [ngStyle]="{'background-color': user.color}">
    <i *ngIf="!user.displayNameInitials" class="zmdi zmdi-account"></i>
    {{user.displayNameInitials}}
  </span>

  <ng-container *ngIf="presenceStatus$ | async; let status">
    <span class="online-status {{status.state}}"
          *ngIf="showOnlineStatus && status?.state !== 'OFFLINE'"
          title="{{'PRESENCE_STATUS.' + (status ? status.state : 'OFFLINE') |
          translate}}{{status.label?.length ? ': ' : ''}}{{status.label}}">
    </span>
  </ng-container>

  <coyo-user-follow *ngIf="showFollowButton"
                    size="xs"
                    [user]="user"
                    [noLabel]="true"
                    [darkTheme]="true">
  </coyo-user-follow>

  <ng-container *ngxPermissionsOnly="'USE_MESSAGING'">
    <button *ngIf="!user.externalWorkspaceMember && showMessagingButton"
            class="user-messaging btn btn-xs btn-dark btn-zmdi"
            [matTooltip]="'USER.CLICK_TO_SEND_MESSAGE' | translate"
            (click)="startConversation($event, user.id);">
      <i class="zmdi zmdi-comment-text"></i>
    </button>
  </ng-container>

  <coyo-external-icon *ngIf="showExternalFlag && user.externalWorkspaceMember" [avatarSize]="avatarSize"></coyo-external-icon>
</span>

import {ChangeDetectionStrategy, Component, HostBinding, OnInit} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * The user settings.
 */
@Component({
  selector: 'coyo-settings',
  templateUrl: './settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {
  showPasswordSettings$: Observable<boolean>;
  showIntegrationSettings$: Observable<boolean>;

  @HostBinding('class.mat-typography') matTypography: boolean = true;

  constructor(private authService: AuthService,
              private featureToggleService: FeatureToggleService) {
  }

  ngOnInit(): void {
    this.showPasswordSettings$ = this.authService.getUser()
      .pipe(map(user => !user.remoteUser || user.remoteUserDirectory?.type === 'csv'));
    this.showIntegrationSettings$ = this.featureToggleService.getAvailableFeatures('sso_integration')
      .pipe(map(features => !!features.length));
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {SingleBlogArticle} from '@widgets/blog-article/blog-article-widget';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogArticleWidgetService extends DomainService<SingleBlogArticle, SingleBlogArticle> {

  private url: string = '/web/widgets/blogarticle';

  constructor(@Inject(HttpClient) protected http: HttpClient,
              @Inject(UrlService) protected urlService: UrlService) {
    super(http, urlService);
  }

  getArticleById(articleId: string): Observable<SingleBlogArticle> {
    return this.http.get<SingleBlogArticle>(this.getBaseUrl() + '/article/' + articleId);
  }

  getArticles(pageable: Pageable, term: string): Observable<Page<SingleBlogArticle>> {
    return this.getPage(pageable, {params: {title: term}});
  }

  protected getBaseUrl(): string {
    return this.url;
  }
}

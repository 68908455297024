import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {TimelineItem} from '@domain/timeline-item/timeline-item';
import {BlogShareArticleComponent} from '../timeline-item-content/blog-share-article/blog-share-article.component';
import {EventShareItemComponent} from '../timeline-item-content/event-share-item/event-share-item.component';
import {PostItemComponent} from '../timeline-item-content/post-item/post-item.component';
import {SenderShareItemComponent} from '../timeline-item-content/sender-share-item/sender-share-item.component';
import {TimelineItemContentComponent} from '../timeline-item-content/timeline-item-content.component';
import {WikiShareArticleComponent} from '../timeline-item-content/wiki-share-article/wiki-share-article.component';

const typeToComponentMapping = {
  post: PostItemComponent,
  page: SenderShareItemComponent,
  workspace: SenderShareItemComponent,
  event: EventShareItemComponent,
  blog: BlogShareArticleComponent,
  wiki: WikiShareArticleComponent
};

/**
 * Content outlet that creates a new component based on the timeline item type to show the right content.
 */
@Component({
  selector: 'coyo-timeline-item-content-outlet',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemContentOutletComponent implements OnInit, OnChanges {

  /**
   * The timeline item
   */
  @Input() item: TimelineItem;

  componentRef: ComponentRef<TimelineItemContentComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    const resolvedComponent = typeToComponentMapping[this.item.itemType];
    const resolvedFactory = this.componentFactoryResolver.resolveComponentFactory(resolvedComponent);
    this.componentRef = this.viewContainerRef.createComponent(resolvedFactory) as ComponentRef<TimelineItemContentComponent>;
    this.componentRef.instance.item = this.item;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef) {
      this.componentRef.instance.item = this.item;
      this.componentRef.instance.detectChanges();
    }
  }
}

(function (angular) {
  'use strict';

  authService.$inject = ["$http", "$q", "$log", "$rootScope", "$state", "$translate", "$localStorage", "$sessionStorage", "$filter", "$window", "$document", "$timeout", "$location", "$httpParamSerializerJQLike", "$injector", "errorLogService", "backendUrlService", "errorService", "mobileEventsService", "coyoEndpoints", "UserModel", "csrfService", "deeplinkService"];
  angular
      .module('commons.auth')
      .factory('authService', authService);

  /**
   * @ngdoc service
   * @name commons.auth.authService
   *
   * @description
   * Provides methods for authentication and retrieving authentication information.
   *
   * The service can be used to
   * * log in and log out,
   * * check if the user is currently authenticated,
   * * get the current user.
   *
   * @requires $http
   * @requires $q
   * @requires $log
   * @requires $rootScope
   * @requires $state
   * @requires $translate
   * @requires $localStorage
   * @requires $sessionStorage
   * @requires $filter
   * @requires $window
   * @requires $document
   * @requires $timeout
   * @requires $location
   * @requires $httpParamSerializerJQLike
   * @requires $injector
   * @requires commons.resource.backendUrlService
   * @requires commons.error.errorService
   * @requires commons.mobile.mobileEventsService
   * @requires commons.config.coyoEndpoints
   * @requires coyo.domain.UserModel
   * @requires commons.resource.csrfService
   */
  function authService($http, $q, $log, $rootScope, $state, $translate, $localStorage, $sessionStorage, $filter,
                       $window, $document, $timeout, $location, $httpParamSerializerJQLike, $injector, errorLogService,
                       backendUrlService, errorService, mobileEventsService, coyoEndpoints, UserModel, csrfService,
                       deeplinkService) {
    var currentUser = null;
    var currentUserPromise = null;
    var LOGIN_NAME_KEY = 'lastLoginName';
    var userUpdateUnsubscribeFn = null;

    /*
     * This flag is stored synchronously to be able to avoid a stateful AngularJS hashtag filter.
     * We rely on the fact that the current user is resolved during the login phase and that this
     * flag is kept in sync with the one of the current user. A clean solution is to extract the
     * login states and thus be able to always fetch the current user in a synchronous manner.
     * We are not quite there yet.
     */
    var useHashtags = null;

    return {
      userUpdated$: $rootScope.$eventToObservable('currentUser:updated', null, true),
      userLoggedIn$: $rootScope.$eventToObservable('authService:login:success'),
      userLoggedOut$: $rootScope.$eventToObservable('authService:logout:success'),
      getLastLogin: getLastLogin,
      login: login,
      logout: logout,
      clearSession: clearSession,
      requestPassword: requestPassword,
      requestPasswordResetByAdmin: requestPasswordResetByAdmin,
      resetPassword: resetPassword,
      isAuthenticated: isAuthenticated,
      canUseHashtags: canUseHashtags,
      getCurrentUserId: getCurrentUserId,
      validateUserId: validateUserId,
      getUser: getUser,
      onGlobalPermissions: onGlobalPermissions,
      ssoLoginSuccess: ssoLoginSuccess,
      ssoLogoutSuccess: ssoLogoutSuccess,
      subscribeToUserUpdate: subscribeToUserUpdate,
      unsubscribeFromUserUpdate: unsubscribeFromUserUpdate,
      isSsoLogin: isSsoLogin
    };

    /**
     * @ngdoc method
     * @name commons.auth.authService#login
     * @methodOf commons.auth.authService
     *
     * @description
     * Tries to log in the user with the given username and password.
     *
     * @param {string} username The username
     * @param {string} password The password
     * @return {object} An $http promise
     */
    function login(username, password) {
      var deferred = $q.defer();
      var data = $httpParamSerializerJQLike({username: username, password: password});
      var headers = {'Content-Type': 'application/x-www-form-urlencoded'};

      $http.post(coyoEndpoints.login, data, {
        headers: headers
      }).then(function (result) {
        $localStorage.userId = result.data.id;
        $localStorage.isAuthenticated = true;
        $log.info('[authService] Login succeeded');
        csrfService.clearToken();
        localStorage.setItem(LOGIN_NAME_KEY, username);
        _propagateEventAsync('authService:login:success', {
          sso: false,
          global: false
        });
        deferred.resolve(result);
      }).catch(function (e) {
        delete $localStorage.userId;
        delete $localStorage.isSsoLogin;
        delete $localStorage.isGlobalLogout;
        $localStorage.isAuthenticated = false;
        $log.error('[authService] Login failed', e);
        _propagateEventAsync('authService:login:failed', e);
        deferred.reject(e);
        clearSession();
      });

      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#getLastLogin
     * @methodOf commons.auth.authService
     *
     * @description
     * Returns the username of the last successful login
     *
     * @return {string} username of the last successful login
     */
    function getLastLogin() {
      return localStorage.getItem(LOGIN_NAME_KEY);
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#ssoLoginSuccess
     * @methodOf commons.auth.authService
     *
     * @description
     * Will be called after a successful SSO login.
     *
     * @param {string} userId The users ID
     */
    function ssoLoginSuccess(userId) {
      var loginSuccessParams = angular.fromJson($location.search().params) || {};
      $localStorage.isAuthenticated = true;
      if (userId) {
        $localStorage.userId = userId;
      } else {
        delete $localStorage.userId;
      }
      $localStorage.isSsoLogin = true;
      $localStorage.isGlobalLogout = loginSuccessParams.logoutMethod === 'GLOBAL';
      csrfService.clearToken();
      _propagateEventAsync('authService:login:success', {
        sso: $localStorage.isSsoLogin,
        global: $localStorage.isGlobalLogout
      });
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#ssoLoginSuccess
     * @methodOf commons.auth.authService
     *
     * @description
     * Will be called after a successful SSO logout iff the remote logout page redirects back to us.
     */
    function ssoLogoutSuccess() {
      _propagateEventAsync('authService:logout:success');
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#clearSession
     * @methodOf commons.auth.authService
     *
     * @description
     * Clears all user session data.
     *
     * @return {object} promise that is resolved when session data is removed from local storage.
     */
    function clearSession() {
      $localStorage.isAuthenticated = false;
      delete $localStorage.userId;
      delete $localStorage.clientId;
      delete $localStorage.isSsoLogin;
      delete $localStorage.isGlobalLogout;
      _resetSessionStorage();
      currentUser = null;
      currentUserPromise = null;
      useHashtags = null;
      csrfService.clearToken();
      $rootScope.search = {
        visible: false,
        term: ''
      };

      return $timeout(_.noop);
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#logout
     * @methodOf commons.auth.authService
     *
     * @description
     * Logs out the user and clears all data (tokens and user data).
     * Note that this function might never return due to a possible
     * redirection.
     *
     * After logging out the user is redirected to the login page.
     */
    function logout() {
      var isSsoLogin = $localStorage.isSsoLogin;
      var isGlobalLogout = $localStorage.isGlobalLogout;

      clearSession();

      if (isSsoLogin) {
        _ssoLogout(isGlobalLogout);
      } else {
        _logout();
      }
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#requestPassword
     * @methodOf commons.auth.authService
     *
     * @description
     * Requests a password reset link via email for the user with the given username.
     *
     * @param {string} username The username
     * @return {object} An $http promise
     */
    function requestPassword(username) {
      return $http.post(coyoEndpoints.reset, {
        username: username
      }, {
        autoHandleErrors: false
      });
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#requestPasswordResetByAdmin
     * @methodOf commons.auth.authService
     *
     * @description
     * Requests a password reset via a superadmin for the user with the given user data.
     *
     * @param {object} userData             The user information
     * @param {string} userData.firstName   The users firstname
     * @param {string} userData.surName     The users surname
     * @param {string} userData.email       The users email address
     * @param {string} userData.phoneNumber The users phone number
     * @return {object} An $http promise
     */
    function requestPasswordResetByAdmin(userData) {
      return $http.post(coyoEndpoints.emailReset, userData, {
        autoHandleErrors: false
      });
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#resetPassword
     * @methodOf commons.auth.authService
     *
     * @description
     * Resets the user's password using the given password reset token.
     *
     * @param {string} token    the password reset token
     * @param {string} password the new password
     * @return {object} An $http promise containing the updated user
     */
    function resetPassword(token, password) {
      return $http.put(coyoEndpoints.reset, {
        token: token,
        password: password
      }, {
        autoHandleErrors: false
      }).then(function (response) {
        return response.data;
      });
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#isAuthenticated
     * @methodOf commons.auth.authService
     *
     * @description
     * Returns whether the user is authenticated.
     *
     * @returns {boolean} True if the user is authenticated, false else
     */
    function isAuthenticated() {
      return $localStorage.isAuthenticated === true;
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#canUseHashtags
     * @methodOf commons.auth.authService
     *
     * @description
     * Returns whether the user can use hashtags.
     *
     * @returns {boolean} True if the user can use hashtags, false else
     */
    function canUseHashtags() {
      return useHashtags;
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#getCurrentUserId
     * @methodOf commons.auth.authService
     *
     * @description
     * Return the ID of the current user if authenticated, null otherwise.
     *
     * @returns {string|null} current user id
     */
    function getCurrentUserId() {
      if (!isAuthenticated()) {
        currentUser = null;
        delete $localStorage.userId;
        return null;
      }

      if (!$localStorage.userId) {
        if (currentUser) {
          $localStorage.userId = currentUser.id;
        } else {
          errorLogService.log(new Error('Current User ID missing! - Client ID: ' + $localStorage.clientId));
          logout();
        }
      }
      return $localStorage.userId || null;
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#validateUserId
     * @methodOf commons.auth.authService
     *
     * @description
     * Check the given ID against the current user ID. In case of mismatch, logout user and log error to server.
     *
     * @param {string} userId ID of the user to be checked
     * @param {string|function} context Either a string or callback to get additional information
     */
    function validateUserId(userId, context) {
      if (!userId || !isAuthenticated()) {
        return;
      }

      var currentUserId = getCurrentUserId();
      if (!currentUserId) {
        $localStorage.userId = userId;
        errorLogService.log(
            new Error('Current User ID missing. Frontend will use ' + userId + ' from response now! - '
                + (angular.isFunction(context) ? context() : context)));
      } else if (userId !== currentUserId) {
        errorLogService.log(
            new Error('Current User ID mismatch. Frontend expected ' + currentUserId + ' but got ' + userId
                + ' in the response! - ' + (angular.isFunction(context) ? context() : context)));
        logout();
      }
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#getUser
     * @methodOf commons.auth.authService
     *
     * @description
     * Returns the current user and caches the current user during application lifetime.
     *
     * This method should be called to retrieve the current user in any service.
     *
     * @params {boolean} forceRefresh [optional, default: false] Boolean flag whether forcing a user refresh
     *
     * @returns {promise} The current user
     */
    function getUser(forceRefresh) {
      if (isAuthenticated()) {
        if (forceRefresh) {
          currentUserPromise = null;
        }

        if (!currentUserPromise) {
          $log.debug('[authService] Loading current user...');

          var currentUserId = getCurrentUserId();
          currentUserPromise = UserModel.getWithPermissions({id: 'me'}, {with: 'globalPermissions,subscriptionInfo'},
              ['manage', 'accessProfile', 'accessTimeline', 'createFile']).then(function (user) {
            $log.debug('[authService] Loaded current user:', user);

            if (currentUserId === null) {
              $localStorage.userId = user.id;
            } else if (user.id !== currentUserId) {
              errorLogService.log(new Error('Loaded User ID mismatch. '
                  + 'Frontend expected ' + currentUserId + ' but got ' + user.id + ' in the response!'));
              logout();
              return null;
            }

            useHashtags = user.hasGlobalPermissions('USE_HASHTAGS');
            if (user._fromCache !== true) {
              $rootScope.$emit('currentUser:updated', user, angular.copy(currentUser));
            }
            if (currentUser) {
              angular.extend(currentUser, user);
            } else {
              currentUser = user;
            }
            $localStorage.userLanguage = currentUser.language;
            return currentUser;
          }).catch(function (error) {
            currentUserPromise = null;
            return _onUserLoadingError(error);
          });
        }

        return currentUserPromise;
      } else {
        return $q.reject('Not signed in.');
      }
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#onGlobalPermissions
     * @methodOf commons.auth.authService
     *
     * @description
     * Checks provided global permissions against the current user and executed the provided callback with the result.
     *
     * @deprecated
     * Live updates of global permissions are no longer supported, user UserModel.hasGlobalPermission instead.
     *
     * @param {string|string[]} permissionNames array or comma-separated list of global permission names
     * @param {function(boolean, object)} callback will be executed with the result of the permission check and the current user
     * @param {boolean?} requireAll flag if set then all provided permissions must be set, otherwise a single one will be enough
     *
     * @returns {function()} de-registration function for the change event (call this on scope destroy)
     */
    function onGlobalPermissions(permissionNames, callback, requireAll) {
      this.getUser().then(function (user) {
        callback(user.hasGlobalPermissions(permissionNames, requireAll), user);
        _applyDateFormats(user);
      });
    }

    function _onUserLoadingError(error) {
      $log.error('[authService] Could not load current user', error);

      var buttons = ['RETRY'];

      if (error.status >= 400 && error.status < 500) {
        logout();
      } else if (error.status < 0) {
        $translate('ERRORS.BACKEND.FAILED_CONTACT').then(function (translation) {
          errorService.showErrorPage(translation, null, buttons);
        });
      } else {
        $translate('ERRORS.USERS.FAILED_LOADING_CURRENT_USER').then(function (translation) {
          errorService.showErrorPage(translation, null, buttons);
        });
      }

      return $q.reject('Error loading current user.');
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#subscribeToUserUpdate
     * @methodOf commons.auth.authService
     *
     * @description
     * Subscribes to socket's user updated topic and refreshes the current user upon this call.
     * Saves an unsubscribe function to be called by
     * {@link commons.auth.authService commons.auth.authService#unsubscribeFromUserUpdate}.
     */
    function subscribeToUserUpdate() {
      var socketService = $injector.get('socketService');
      userUpdateUnsubscribeFn = socketService.subscribe('/user/topic/updated', function (event) {
        $log.debug('[commons.auth::run] Received a user updated event:', event);
        getUser(true);
      }, 'userUpdated');
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#unsubscribeFromUserUpdate
     * @methodOf commons.auth.authService
     *
     * @description
     * Unsubscribes from socket's user updated topic if there already was a subscription established by
     * {@link commons.auth.authService commons.auth.authService#subscribeToUserUpdate}.
     */
    function unsubscribeFromUserUpdate() {
      if (userUpdateUnsubscribeFn) {
        userUpdateUnsubscribeFn();
      }
    }

    /**
     * @ngdoc method
     * @name commons.auth.authService#isSsoLogin
     * @methodOf commons.auth.authService
     *
     * @description
     * returns true if the user is logged in via sso.
     */
    function isSsoLogin() {
      return $localStorage.isSsoLogin;
    }

    function _logout() {
      _propagateEvent('authService:logout:before');
      $http({
        url: coyoEndpoints.logout,
        method: 'POST',
        autoHandleErrors: false
      }).then(function (response) {
        localStorage.removeItem(LOGIN_NAME_KEY);
        $log.info('[authService] Logout succeeded');
        _propagateEventAsync('authService:logout:success');
        if (response.data) {
          var data = angular.fromJson(response.data);
          if (data.redirectTo) {
            $window.location.href = data.redirectTo;
          }
        }
      }).catch(function (error) {
        $log.error('[authService] Logout failed', error);
        _propagateEventAsync('authService:logout:failed', error);
      }).finally(function () {
        $state.go('front.logout-success');
      });
    }

    function _ssoLogout(global) {
      csrfService.getToken().then(function (token) {
        var globalLogoutParam = global ? 'global=true&' : '';
        var url = backendUrlService.getUrl() + coyoEndpoints.ssoLogout + '?' + globalLogoutParam + '_csrf=' + token;
        _propagateEvent('authService:logout:before', {logoutUrl: url});
        _post(url);
      }).catch(function (err) {
        $log.warn('[authService] SSO logout failed: No CSRF token or POST request failed. Falling back to local logout',
            err);
        _logout();
      });
    }

    function _post(url) {
      var form = $document[0].createElement('form');
      form.method = 'POST';
      form.action = url;
      $document.find('body').eq(0).append(form);
      form.submit();
    }

    function _applyDateFormats(user) {
      $rootScope.timezone = user.timezone;
      $injector.get('ngxTimeService').setTimezone(user.timezone);

      $rootScope.dateFormat = {
        long: $filter('translate')('DATE_FORMAT_LONG'),
        medium: $filter('translate')('DATE_FORMAT_MEDIUM'),
        short: $filter('translate')('DATE_FORMAT_SHORT')
      };

      $rootScope.timeFormat = {
        medium: $filter('translate')('TIME_FORMAT_MEDIUM'),
        short: $filter('translate')('TIME_FORMAT_SHORT')
      };
    }

    function _propagateEventAsync(eventName, payload) {
      $timeout(function () {
        $rootScope.$emit(eventName);
        mobileEventsService.propagate(eventName, payload);
      });
    }

    function _propagateEvent(eventName, payload) {
      $rootScope.$emit(eventName);
      mobileEventsService.propagate(eventName, payload);
    }

    function _resetSessionStorage() {
      var returnState = deeplinkService.getReturnToState();
      var params = deeplinkService.getReturnToStateParams();
      $sessionStorage.$reset();
      deeplinkService.setReturnToState(returnState, params);
    }
  }
})(angular);

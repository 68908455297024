import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AuthService} from '@core/auth/auth.service';
import {UrlService} from '@core/http/url/url.service';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'coyo-new-slide-dialog',
  templateUrl: './new-slide-dialog.component.html',
  styleUrls: ['new-slide-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSlideDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  sender$: Observable<Sender>;

  editMode: boolean = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<NewSlideDialogComponent>,
              private urlService: UrlService,
              private senderService: SenderService,
              @Inject(MAT_DIALOG_DATA) private data: NewSlideDialogData,
              private authService: AuthService) {

  }

  ngOnInit(): void {
    this.sender$ = this.senderService.get(
      this.senderService.getCurrentIdOrSlug() ?? this.authService.getCurrentUserId(),
      {permissions: ['createFile']}
      );
    this.editMode = !_.isEqual({}, this.data);
    const initialData: NewSlideDialogData = _.defaults({}, this.data, {
      _image: ''
    });

    this.form = this.fb.group({
      headline: this.fb.control(initialData.headline, [Validators.maxLength(50)]),
      _url: this.fb.control(initialData._url),
      _alt: this.fb.control(initialData._alt),
      file: this.fb.control('', []),
      _image: this.fb.control(initialData._image, [Validators.required]),
      _newTab: this.fb.control(initialData._newTab)
    });

    this.form.get('file')
      .valueChanges
      .subscribe(file => {
        if (file && file.id) {
          this.form.get('_image')
            .patchValue(this.createPreviewUrl(file.senderId, file.id, 'ORIGINAL', file.modified));
        } else {
          this.form.get('_image').patchValue(null);
        }
      });
  }

  private createPreviewUrl(senderId: string, documentId: string, size: string, modified: number): string {
    const baseUrl = this.urlService.getBackendUrl() + `/web/senders/${senderId}/documents/${documentId}`;
    const _modified = modified ? ('&modified=' + modified) : '';
    return baseUrl + (baseUrl.indexOf('?') < 0 ? '?' : '&') + 'imageSize=' + size + _modified;
  }

  submit(): void {
    const result = _.omit({...this.form.value}, 'file');
    this.dialogRef.close(result);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

interface NewSlideDialogData {
  headline: string;
  _url: string;
  _alt: string;
  _image: string;
  _newTab: boolean;
}

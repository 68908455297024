import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DownloadStatus} from '@domain/file/download/download-status';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {File} from '../file';

/**
 * Service for downloading COYO internal files and folders.
 */
@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient, private urlService: UrlService) {
  }

  isDownloadAvailable(file: File): Observable<DownloadStatus> {
    if (file.folder) {
      return this.getFolderDownloadStatus(file);
    } else {
      return of(DownloadStatus.CAN_DOWNLOAD);
    }
  }

  /**
   * Creates a download link for the given file
   * @param file A COYO internal file
   *
   * @return The download link for the given file
   */
  createDownloadLink(file: File): string {
    if (file.folder) {
      return this.createFolderDownloadLink(file);
    } else {
      return this.createDocumentDownloadLink(file);
    }
  }

  private createDocumentDownloadLink(file: File): string {
    return `${this.urlService.getCurrentDomain()}/web/senders/${file.senderId}/documents/${file.id}`;
  }

  private createFolderDownloadLink(file: File): string {
    return `${this.urlService.getCurrentDomain()}/web/senders/${file.senderId}/folders/${file.id}/zip`;
  }

  private getFolderDownloadStatus(file: File): Observable<DownloadStatus> {
    return this.http.get<{ status: DownloadStatus }>(`/web/senders/${file.senderId}/folders/${file.id}/zip/status`)
      .pipe(map(response => response.status));
  }
}

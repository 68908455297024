import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {License} from '@shared/license/license';
import {
  LicenseFetched,
  LicenseUpdated,
  LicenseValidityFetched,
  LicenseValidityUpdated
} from '@shared/license/license.actions';
import {LicenseStateSelectors} from '@shared/license/license.state.selectors';
import {Observable} from 'rxjs';
import {skipWhile, tap} from 'rxjs/operators';

/**
 * Service for getting and uploading the license and also checking it's validity.
 * Acts like a facade and manages a store for caching the license information.
 * This service should be used in components instead of using the store directly.
 */
@Injectable({providedIn: 'root'})
export class LicenseService {
  private LICENSE_URL: string = '/web/license';
  private LICENSE_VALID_STATUS: string = 'VALID';

  constructor(private http: HttpClient, private store: Store) {
  }

  /**
   * Request the validity of the current license
   *
   * @returns Flag representing if license is valid
   */
  isLicenseValid(): Observable<boolean> {
    if (this.store.selectSnapshot(LicenseStateSelectors.valid) != null) {
      return this.store.selectOnce(LicenseStateSelectors.valid);
    }
    return this.http.get<boolean>(`${this.LICENSE_URL}/valid`).pipe(
      tap((validity: boolean) => {
        this.store.dispatch(new LicenseValidityFetched(validity));
      }));
  }

  /**
   * Fetches the current tenant license. Caches the license in the store
   *
   * @returns an Observable holding the fetched license
   */
  getLicense(): Observable<License> {
    return this.http.get<License>(this.LICENSE_URL).pipe(tap((license: License) => {
      this.store.dispatch(new LicenseFetched(license));
      this.store.dispatch(new LicenseValidityUpdated(license.status === this.LICENSE_VALID_STATUS));
    }));
  }

  /**
   * Tries to return cached license from store. Fetches it when not existing in store already.
   *
   * @returns an Observable holding the license
   */
  getLicenseFromCache(): Observable<License> {
    if (this.store.selectSnapshot(LicenseStateSelectors.license)) {
      return this.store.selectOnce(LicenseStateSelectors.license);
    }
    return this.getLicense();
  }

  /**
   * Updates the current license
   *
   * @param license: the license payload to be updated
   * @returns Observable of license
   */
  updateLicense(license: License): Observable<License> {
    return this.http.put<License>(this.LICENSE_URL, {
      license: license
    }).pipe(
      tap((response: License) => {
        this.store.dispatch(new LicenseUpdated(response));
        this.store.dispatch(new LicenseValidityUpdated(response.status === this.LICENSE_VALID_STATUS));
      }));
  }

  /**
   * Gets an Observable emitting changes of the license
   * Filters out default state to prevent checks at caller side
   *
   * @returns Observable emitting licenses
   */
  getLicenseUpdates$(): Observable<License> {
    return this.store.select(LicenseStateSelectors.license)
      .pipe(skipWhile(license => license == null));
  }
}

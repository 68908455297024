import {HttpClient, HttpEvent, HttpEventType, HttpHeaders} from '@angular/common/http';
import {File as FileModel} from '@domain/file/file';
import {FileUploadAbortedEvent} from '@domain/file/file/events/file-upload-aborted-event';
import {FileUploadEvent} from '@domain/file/file/events/file-upload-event';
import {FileUploadFileAbortedEvent} from '@domain/file/file/events/file-upload-file-aborted-event';
import {FileUploadFileFinishedEvent} from '@domain/file/file/events/file-upload-file-finished-event';
import {FileUploadFinishedEvent} from '@domain/file/file/events/file-upload-finished-event';
import {FileUploadNextFileEvent} from '@domain/file/file/events/file-upload-next-file-event';
import {FileUploadProgressEvent} from '@domain/file/file/events/file-upload-progress-event';
import {UnsubscribeEmittingSubject} from '@domain/file/file/unsubscribe-emitting-subject';
import {UuidService} from '@shared/uuid/uuid.service';
import {Observable, Subscription} from 'rxjs';

// delay for each upload in milliseconds
export const UPLOAD_DELAY = 100;

/**
 * A custom subject implementation that handles file uploads
 * For the internals of angular http progress tracking
 * @see https://angular.io/guide/http#tracking-and-showing-request-progress
 *
 * Attention: For some unknown reason the http post call with event tracking seems
 * to execute outside of the angular zone, so this object might require to be re-attached to
 * the angular zone in order to mitigate some unexpected behavior.
 */
export class FileUpload extends UnsubscribeEmittingSubject<FileUploadEvent> {

  /**
   * The size of all files combined in bytes
   */
  readonly totalSize: number;
  private readonly uploads: { [tempId: string]: { file: File, index: number, subscription: Subscription, done: boolean } };
  private successfulUploads: number;
  private cancelled: boolean;
  private started: boolean = false;
  private totalUploaded: number;

  constructor(
    private readonly http: HttpClient,
    uuidService: UuidService,
    private readonly files: FileList | File[],
    private readonly path: string,
    readonly parentId?: string,
    private readonly params?: {[key: string]: string[]}
  ) {
    super();
    this.totalUploaded = 0;
    this.totalSize = 0;
    this.successfulUploads = 0;
    this.cancelled = false;
    this.started = false;
    this.uploads = {};
    for (let i = 0; i < this.files.length; i++) {
      this.uploads[uuidService.getUuid()] = {index: i, file: this.files[i], subscription: null, done: false};
      this.totalSize += this.files[i].size;
    }
  }

  /**
   * Overrides the Subject subscribe methods
   * Will start the upload process and call the original method
   * @param params The subscription handlers / Observer functions
   *
   * @returns The subscription
   */
  subscribe(...params: any[]): Subscription {
    const subscription = super.subscribe(...params);
    if (!this.started) {
      this.start();
    }
    return subscription;
  }

  private start(): Observable<FileUploadEvent> {
    if (this.started) {
      throw new Error('File upload can only be started once');
    }
    this.started = true;
    // when this subject is unsubscribed, cancel all running uploads
    this.onUnsubscribe().subscribe(() => {
        this.cancelled = true;
        // go through uploads
        Object.keys(this.uploads).forEach((tempId, i) => {
        const upload = this.uploads[tempId];
        if (!upload.done) {
          this.next(new FileUploadFileAbortedEvent(this.files, tempId, upload.index, this.successfulUploads, 'Aborted'));
        }
        if (!upload.subscription?.closed) {
          // unsubscribe upload subscription
          upload.subscription?.unsubscribe();
        }
      });
      // emit abort event
      this.next(new FileUploadAbortedEvent(this.files, this.successfulUploads, 'Aborted'));
      this.complete();
    });
    // start the actual upload process
    this.subscribeUploads();
    return this;
  }

  private subscribeUploads(): void {
    let i = 0;
    for (const id of Object.keys(this.uploads)) {
      // start download with a slight delay in order to avoid firing dozens of requests at the same moment
      setTimeout(() => {
          if (!this.cancelled) {
            // emit event for a new file upload
            this.next(new FileUploadNextFileEvent(this.files, id, this.uploads[id].index));
            // start request and bind observers
            this.uploads[id].subscription = this.createRequest(id)
              .subscribe({
                next: event => this.handleNextEvent(id, event),
                error: error => this.handleErrorEvent(id, error),
                complete: () => this.handleCompleteEvent(id)
              });
          }
        }
        , i++ * UPLOAD_DELAY);
    }
  }

  private handleNextEvent(id: string, event: HttpEvent<FileModel>): void {
    const upload = this.uploads[id];
    if (event.type === HttpEventType.UploadProgress) {
      // upload progress event informs about file upload progress
      const itemSize = upload.file.size;
      const itemDone = event.loaded;
      const totalDone = this.totalUploaded + itemDone;
      const itemProgress = Math.floor(100 * itemDone / itemSize);
      const totalProgress = Math.floor(100 * totalDone / this.totalSize);
      this.next(new FileUploadProgressEvent(this.files, id, upload.index, itemProgress, totalProgress));
    } else if (event.type === HttpEventType.Response) {
      // repose event informs about a finished upload and carries the response body
      this.totalUploaded += upload.file.size;
      this.successfulUploads++;
      this.next(new FileUploadFileFinishedEvent(this.files, id, upload.index, event.body, this.successfulUploads));
    }
  }

  private handleErrorEvent(id: string, event: Error): void {
    // this is called when a download fails unexpectedly
    this.uploads[id].done = true;
    this.next(new FileUploadFileAbortedEvent(this.files, id, this.uploads[id].index, this.successfulUploads, event));
    this.checkDone();
  }

  private handleCompleteEvent(id: string): void {
    // this is called when a download is fully done
    this.uploads[id].done = true;
    this.checkDone();
  }

  private createRequest(id: string): Observable<HttpEvent<FileModel>> {
    return this.http.post<FileModel>(this.path, this.createFormData(id), {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        handleErrors: 'false'
      }),
      params: this.params
    });
  }

  private createFormData(id: string): FormData {
    const formData = new FormData();
    formData.append('file', this.uploads[id].file);
    if (this.parentId) {
      formData.append('parentId', this.parentId);
    }
    return formData;
  }

  private checkDone(): void {
    if (Object.values(this.uploads).every(upload => upload.done)) {
      // if all downloads have completed, complete this subject
      this.next(new FileUploadFinishedEvent(this.files, this.successfulUploads));
      this.complete();
    }
  }
}

(function (angular) {
  'use strict';

  FormSettingsController.$inject = ["$scope", "SettingsModel"];
  angular
      .module('coyo.apps.form')
      .controller('FormSettingsController', FormSettingsController);

  function FormSettingsController($scope, SettingsModel) {
    var vm = this;

    vm.$onInit = _init();

    function _init() {
      vm.tab = 0;
      vm.app = $scope.model;
      if (!vm.app.settings.notification) {
        vm.app.settings.notification = 'ADMIN';
      }

      SettingsModel.retrieveByKey('emailPattern').then(function (emailPattern) {
        vm.emailPattern = emailPattern;
      });
    }
  }

})(angular);

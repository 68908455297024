<ng-container [formGroup]="parentForm">
  <mat-form-field appearance="outline" class="no-hint">
    <mat-label translate="WIDGET.USERPROFILE.USER"></mat-label>
    <coyo-select-sender formControlName="_selectedUser"
                        selectClass="mat-ng-select"
                        selectAppearance="outline"
                        [options]="senderSelectOptions"
                        [bindId]="true"></coyo-select-sender>
  </mat-form-field>
  <mat-checkbox formControlName="_showInfo">
    {{'WIDGET.USERPROFILE.SHOW.INFO.LABEL' | translate}}
  </mat-checkbox>
</ng-container>

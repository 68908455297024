(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.admin.themes
   *
   * @description
   * # Admin theme management module #
   * The admin theme management module provides views to manage custom css styles.
   */
  ModuleConfig.$inject = ["$stateProvider", "adminThemeConfig"];
  angular
      .module('coyo.admin.themes', [
        'coyo.base',
        'coyo.domain'
      ])
      .config(ModuleConfig)
      .constant('adminThemeConfig', {
        templates: {
          themeList: 'app/modules/admin/themes/views/theme-list/admin.theme-list.html',
          themeDetails: 'app/modules/admin/themes/views/theme-details/admin.theme-details.html',
        },
        nonResizableImageProperties: [{type: 'image/svg+xml', fixedWidthPx: 120}],
        imageConfigs: [{
          key: 'image-coyo-front',
          retinaKey: 'image-coyo-front-hd',
          displayName: 'ADMIN.THEMES.LOGOS.IMAGE_COYO_FRONT.DISPLAY_NAME',
          help: 'ADMIN.THEMES.LOGOS.IMAGE_COYO_FRONT.HELP',
          defaultUrl: '/assets/images/logos/coyo/logo-coyo-inversed-front-hd.png',
          width: 476,
          // workaround for bug in chrome, where image/* causes the file select dialog to open very slowly
          // http://stackoverflow.com/questions/39187857/inputfile-accept-image-open-dialog-so-slow-with-chrome
          acceptTypes: 'image/png, image/jpeg, image/gif',
          // variables are set to the result of the given fn (must be a promise), after a new image has been selected
          variables: {
            'height-image-front': function (originalFile, resizedFile, Upload) {
              return Upload.imageDimensions(resizedFile).then(function (dimensions) {
                return dimensions.height + 'px';
              });
            }
          }
        }, {
          key: 'image-coyo-nav',
          retinaKey: 'image-coyo-nav-hd',
          displayName: 'ADMIN.THEMES.LOGOS.IMAGE_COYO_NAV.DISPLAY_NAME',
          help: 'ADMIN.THEMES.LOGOS.IMAGE_COYO_NAV.HELP',
          defaultUrl: '/assets/images/logos/coyo/logo-coyo-inversed-nav-hd.png',
          height: 50,
          // workaround for bug in chrome, where image/* causes the file select dialog to open very slowly
          // http://stackoverflow.com/questions/39187857/inputfile-accept-image-open-dialog-so-slow-with-chrome
          acceptTypes: 'image/png, image/jpeg, image/gif, image/svg+xml',
          // variables are set to the result of the given fn (must be a promise), after a new image has been selected
          variables: {
            'width-navbar-brand': function (originalFile, resizedFile, Upload) {
              return Upload.imageDimensions(resizedFile).then(function (dimensions) {
                if (resizedFile.fixedWidthPx) {
                  return resizedFile.fixedWidthPx + 'px';
                }
                return dimensions.width + 'px';
              });
            }
          }
        }, {
          key: 'image-coyo-favicon',
          displayName: 'ADMIN.THEMES.LOGOS.IMAGE_COYO_FAVICON.DISPLAY_NAME',
          help: 'ADMIN.THEMES.LOGOS.IMAGE_COYO_FAVICON.HELP',
          defaultUrl: '/assets/images/logos/coyo/favicon.ico',
          acceptTypes: 'image/x-icon, image/vnd.microsoft.icon'
        }, {
          key: 'image-coyo-appleicon',
          displayName: 'ADMIN.THEMES.LOGOS.IMAGE_COYO_APPLE_ICON.DISPLAY_NAME',
          help: 'ADMIN.THEMES.LOGOS.IMAGE_COYO_APPLE_ICON.HELP',
          defaultUrl: '/assets/images/logos/coyo/apple-touch-icon.png',
          width: 180,
          height: 180,
          acceptTypes: 'image/x-icon, image/png'
        }]
      });

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, adminThemeConfig) {
    $stateProvider.state('admin.themes', {
      abstract: true,
      url: '/themes',
      template: '<ui-view></ui-view>',
      data: {
        globalPermissions: 'MANAGE_THEMES',
        pageTitle: 'ADMIN.MENU.THEMES'
      },
      resolve: {
        themes: ["ThemeModel", function (ThemeModel) {
          return ThemeModel.query();
        }]
      }
    }).state('admin.themes.list', {
      url: '',
      views: {
        '@admin.themes': {
          templateUrl: adminThemeConfig.templates.themeList,
          controller: 'AdminThemeListController',
          controllerAs: '$ctrl'
        }
      },
      redirectTo: function (transition) {
        return transition.injector().getAsync('themes').then(function (themes) {
          if (themes.length === 1) {
            return {state: 'admin.themes.edit', params: {id: themes[0].id}};
          }
          return null;
        });
      }
    }).state('admin.themes.create', {
      url: '/create',
      views: {
        '@admin.themes': {
          templateUrl: adminThemeConfig.templates.themeDetails,
          controller: 'AdminThemeDetailsController',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        backendUrl: ["backendUrlService", function (backendUrlService) {
          return backendUrlService.getUrl();
        }],
        currentUser: ["authService", function (authService) {
          return authService.getUser();
        }],
        theme: ["ThemeModel", "currentUser", function (ThemeModel, currentUser) {
          return new ThemeModel({
            active: true,
            userIds: [currentUser.id],
            userGroupIds: []
          });
        }]
      }
    }).state('admin.themes.edit', {
      url: '/edit/{id}',
      views: {
        '@admin.themes': {
          templateUrl: adminThemeConfig.templates.themeDetails,
          controller: 'AdminThemeDetailsController',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        backendUrl: ["backendUrlService", function (backendUrlService) {
          return backendUrlService.getUrl();
        }],
        theme: ["ThemeModel", "$stateParams", function (ThemeModel, $stateParams) {
          return ThemeModel.get($stateParams.id);
        }]
      }
    });
  }

})(angular);

import {Inject, Injectable} from '@angular/core';
import {PickerCallback} from '@app/integration/gsuite/g-drive-picker/picker-callback';
import {PickerSettings} from '@app/integration/gsuite/g-drive-picker/picker-settings';
import {AccessToken} from '@app/integration/integration-api/access-token';
import {IntegrationApiService} from '@app/integration/integration-api/integration-api.service';
import {AuthService} from '@core/auth/auth.service';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {WINDOW} from '@root/injection-tokens';
import {GoogleApiService} from '../google-api/google-api.service';
import DocsView = google.picker.DocsView;
import PickerBuilder = google.picker.PickerBuilder;

/**
 * Service for putting a Google filepicker on the screen.
 */
@Injectable({
  providedIn: 'root'
})
export class GDrivePickerService {

  constructor(@Inject(WindowSizeService) private windowSizeService: WindowSizeService,
              @Inject(WINDOW) private windowService: Window,
              @Inject(GoogleApiService) public googleApiService: GoogleApiService,
              @Inject(IntegrationApiService) public integrationApiService: IntegrationApiService,
              @Inject(AuthService) private authService: AuthService) {
  }

  /**
   * Opens the Google filepicker in the current view.
   *
   * @param pickerSettings configuration settings for the picker
   * @returns an array of files selected by the user.
   */
  open(pickerSettings?: PickerSettings): Promise<PickerCallback[]> {
    const service = this;

    const pickerConfiguration = pickerSettings || {
      multipleSelect: true,
      view: 'DOCS',
      uploadView: true,
      recentFilesView: true
    } as PickerSettings;

    return new Promise((resolve, reject) => {
      if (typeof gapi === 'undefined') {
        reject('google api is not loaded.');
      }
      gapi.load('picker', () => {
        service.authService.getUser().subscribe(user => {
          service.integrationApiService.getToken().subscribe((token: AccessToken) => {
            let picker = service.googleApiService.createPickerBuilder()
              .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
              .setLocale(user.language)
              .setOAuthToken(token.token)
              .setCallback((data: JSON) => {
                if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
                  resolve(data[google.picker.Response.DOCUMENTS].map((element: JSON) => ({
                    id: element[google.picker.Document.ID],
                    mimeType: element[google.picker.Document.MIME_TYPE],
                    url: element[google.picker.Document.URL],
                    displayName: element[google.picker.Document.NAME]
                  })));
                }
              });

            this.addViews(picker, pickerConfiguration);

            picker = this.handleMultiSelect(pickerConfiguration, picker);
            picker = this.preparePickerForMobileView(service, picker);

            this.showPicker(picker);
          }, () => reject('error in getting an oauth token.'));
        });
      });
    });
  }

  private addViews(pickerBuilder: PickerBuilder, pickerConfiguration: PickerSettings): void {
    if (pickerConfiguration.recentFilesView) {
      pickerBuilder.addView(this.createRecentDriveFilesDocsView());
    }
    pickerBuilder
      .addView(this.createPersonalDriveDocsView(pickerConfiguration))
      .addView(this.createTeamDriveDocsView(pickerConfiguration))
      .addView(this.createSharedWithMeDriveDocsView(pickerConfiguration));
    if (pickerConfiguration.uploadView) {
      pickerBuilder.addView(new google.picker.DocsUploadView());
    }
  }

  private createRecentDriveFilesDocsView(): DocsView {
    const recentView: any = new google.picker.DocsView();
    recentView.Ra = {sortKey: 15};
    return recentView;
  }

  private createTeamDriveDocsView(pickerConfiguration: PickerSettings): DocsView {
    return this.createDefaultDocsView(pickerConfiguration)
      .setEnableTeamDrives(true);
  }

  private createPersonalDriveDocsView(pickerConfiguration: PickerSettings): DocsView {
    return this.createDefaultDocsView(pickerConfiguration)
      .setOwnedByMe(true)
      .setEnableTeamDrives(false);
  }

  private createSharedWithMeDriveDocsView(pickerConfiguration: PickerSettings): DocsView {
    return this.createDefaultDocsView(pickerConfiguration)
      .setOwnedByMe(false)
      .setEnableTeamDrives(false);
  }

  private createDefaultDocsView(pickerConfiguration: PickerSettings): DocsView {
    return new google.picker.DocsView({
      DOCS: google.picker.ViewId.DOCS,
      DOCS_IMAGES: google.picker.ViewId.DOCS_IMAGES,
      DOCS_VIDEOS: google.picker.ViewId.DOCS_VIDEOS
    }[pickerConfiguration.view])
      .setIncludeFolders(true)
      .setSelectFolderEnabled(false);
  }

  private handleMultiSelect(pickerConfiguration: PickerSettings, picker: PickerBuilder): PickerBuilder {
    if (pickerConfiguration.multipleSelect) {
      return picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    }
    return picker;
  }

  private showPicker(picker: PickerBuilder): void {
    picker.build().setVisible(true);
  }

  private preparePickerForMobileView(service: GDrivePickerService, picker: PickerBuilder): PickerBuilder {
    if (service.windowSizeService.isXs() || service.windowSizeService.isSm()) {
      return picker.setSize(service.windowService.innerWidth, service.windowService.innerHeight);
    }
    return picker;
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SearchResult} from '../search-result';

/**
 * Displays an external search result.
 */
@Component({
  selector: 'coyo-search-result-external',
  templateUrl: './search-result-external.component.html',
  styleUrls: ['./search-result-external.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultExternalComponent {

  /**
   * The search result to be displayed.
   */
  @Input() searchResult: SearchResult;

  /**
   * Name of the source where the given search result stem from.
   * Used to determine the correct file type icon.
   */
  @Input() searchProvider: string;

  isFolder(): boolean {
    return this.searchResult.mimeType !== 'application/folder';
  }
}

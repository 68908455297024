<h4 mat-dialog-title translate="WIDGETS.LAYOUT.ROW.CHOOSER.TITLE"></h4>
<mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
<div class="widget-layout-row-types">
  <button (click)="save(layout)" *ngFor="let layout of layouts; let i = index"
          [attr.aria-label]="getLayoutAriaLabel(i)"
          mat-stroked-button
          tabindex="0">
    <div class="widget-layout-row-type">
      <div *ngFor="let slots of layout.slots"
           [ngStyle]="{'flex-basis': (slots * (100/12)) + '%'}" class="slot-placeholder">
      </div>
    </div>
  </button>
</div>

<h1 class="launchpad-header-title" translate="LAUNCHPAD.HEADLINE"></h1>
<button type="button" mat-icon-button *ngIf="canAddLink"
        (click)="addLink.emit()" [attr.aria-label]="'LAUNCHPAD.CATEGORY.ADD_LINK' | translate">
  <mat-icon svgIcon="outline-bookmark-plus"></mat-icon>
</button>
<button type="button" mat-icon-button [matMenuTriggerFor]="menu"
        [attr.aria-label]="'LAUNCHPAD.SETTINGS.LAYOUT' | translate">
  <mat-icon [svgIcon]="!useColumns || (isXs$ | async) ? 'list-view' : 'grid-view'"></mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <button type="button" mat-menu-item (click)="toggleColumns.emit(); $event.stopPropagation()" [hidden]="isXs$ | async">
    <mat-icon [svgIcon]="useColumns ? 'list-view' : 'grid-view'"></mat-icon> {{(useColumns ? 'LAUNCHPAD.SETTINGS.LAYOUT.USE_NO_COLUMNS' : 'LAUNCHPAD.SETTINGS.LAYOUT.USE_COLUMNS') | translate}}
  </button>
  <button type="button" mat-menu-item (click)="toggleCondensed.emit(); $event.stopPropagation()">
    <mat-icon [svgIcon]="useCondensed ? 'checkbox-checked' : 'checkbox-unchecked'"
              [color]="useCondensed ? 'accent' : null"></mat-icon> {{(useCondensed ? 'LAUNCHPAD.SETTINGS.LAYOUT.USE_NO_CONDENSED' : 'LAUNCHPAD.SETTINGS.LAYOUT.USE_CONDENSED') | translate}}
  </button>
</mat-menu>
<button type="button" mat-icon-button class="launchpad-close"
        (click)="close.emit()" [attr.aria-label]="'CLOSE' | translate">
  <mat-icon svgIcon="close"></mat-icon>
</button>

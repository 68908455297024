import {Injectable} from '@angular/core';
import {PluginWidgetComponent} from '@widgets/plugin/plugin-widget/plugin-widget.component';
import {Observable, of} from 'rxjs';
import {PluginEventHandler} from '../plugin-event-handler';
import {IframeHeightMessage} from './iframe-height-message';

/**
 * A plugin event handler for "setHeight" events.
 *
 * Will update the height of the iFrame according to the information
 * provided in the corresponding events. Events can either contain a
 * a number (height in pixels) or a string (ratio in "{width}:{height}".
 */
@Injectable()
export class PluginHeightEventHandlerService extends PluginEventHandler<IframeHeightMessage> {
  private static readonly REQUEST_TOPIC: string = 'setHeight';

  canHandle(topic: string): boolean {
    return topic === PluginHeightEventHandlerService.REQUEST_TOPIC;
  }

  handle(pluginComponent: PluginWidgetComponent, event: IframeHeightMessage): Observable<void> {
    pluginComponent.updateHeight(event.height);
    return of(void 0);
  }
}

(function (angular) {
  'use strict';

  MainNavigationController.$inject = ["$scope", "$rootScope", "$injector", "$state", "sidebarService", "authService", "adminStates", "backendUrlService", "userGuideService", "termsService", "SettingsModel", "hotkeys"];
  angular
      .module('commons.layout')
      .directive('oyocMainNavigation', mainNavigation)
      .controller('MainNavigationController', MainNavigationController);

  /**
   * @ngdoc directive
   * @name commons.layout.oyocMainNavigation:oyocMainNavigation
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Displays the main navigation menu including the search bar.
   *
   * @requires $scope
   * @requires $rootScope
   * @requires $injector
   * @requires $state
   * @requires commons.ui.sidebarService
   * @requires commons.auth.authService
   * @requires coyo.admin.adminStates
   * @requires commons.resource.backendUrlService
   * @requires commons.ui.userGuideService
   * @requires commons.terms.termsService
   * @requires domain.SettingsModel
   */
  function mainNavigation() {
    return {
      restrict: 'E',
      scope: {},
      bindToController: {},
      templateUrl: 'app/commons/layout/components/main-navigation/main-navigation.html',
      replace: true,
      controller: 'MainNavigationController',
      controllerAs: '$ctrl'
    };
  }

  function MainNavigationController($scope, $rootScope, $injector, $state, sidebarService, authService, adminStates,
                                    backendUrlService, userGuideService, termsService, SettingsModel, hotkeys) {
    var vm = this;

    vm.searchVisible = false;
    vm.accessLandingPages = false;
    vm.fileLibraryActivated = false;
    vm.allAdminPermissions = _.map(adminStates, 'globalPermission').join(',');
    vm.backendUrl = backendUrlService.getUrl();

    vm.$onInit = onInit;
    vm.openMenu = openMenu;
    vm.openLaunchpad = openLaunchpad;
    vm.openTour = openTour;
    vm.openHelp = openHelp;
    vm.openAbout = openAbout;
    vm.logout = authService.logout;
    vm.showTerms = termsService.showModal;

    // ----------------------------------------------------------------

    function openMenu() {
      sidebarService.open('menu');
    }

    function openLaunchpad() {
      $injector.get('coyoLaunchpad').open();
    }

    function openTour() {
      $injector.get('ngxTourService').restartTour();
    }

    function openHelp() {
      if ($state.current && $state.current.data && $state.current.data.guide) {
        userGuideService.open($state.current.data.guide);
      } else {
        userGuideService.notFound();
      }
    }

    function openAbout() {
      $injector.get('ngxAboutCoyoModalService').openAboutCoyoModal();
    }

    // ----------------------------------------------------------------

    function _checkTermsActive() {
      termsService.termsActive().then(function (active) {
        vm.termsActive = active;
      });
    }

    function onInit() {
      $injector.get('ngxTourService').getTopics$().subscribe(function (topics) {
        vm.hasTourSteps = topics.length > 0;
      });

      authService.onGlobalPermissions('ACCESS_LANDING_PAGES', function (permission, user) {
        vm.user = user;
        vm.accessLandingPages = permission;
        _checkTermsActive();
      });

      authService.onGlobalPermissions('ACCESS_FILES', function (hasPermission) {
        vm.fileLibraryActivated = hasPermission;
      });

      SettingsModel.retrieve().then(function (settings) {
        vm.launchpadActive = settings.launchpadActive === 'true';
      });

      hotkeys.add({
        combo: 'f',
        callback: function () {
          $injector.get('ngxSearchShellService').openSearch();
        }
      });

      hotkeys.add({
        combo: 'l',
        callback: function () {
          if (vm.launchpadActive && !$injector.get('coyoLaunchpad').isLaunchPadVisible) {
            openLaunchpad();
          }
        }
      });
    }
  }

})(angular);

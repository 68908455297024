import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {File} from '@domain/file/file';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {SearchRequest} from '@domain/search/SearchRequest';
import {Sender} from '@domain/sender/sender';
import {NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import * as _ from 'lodash';
import {Observable} from 'rxjs';

/**
 * Service for all request methods to the sender domain.
 */
@Injectable({
  providedIn: 'root'
})
export class SenderService extends DomainService<Sender, Sender> {

  static readonly NO_DEFAULT_LANGUAGE: string = 'NONE';

  constructor(@Inject(NG1_STATE_SERVICE) private $state: IStateService,
              @Inject(HttpClient) protected http: HttpClient,
              @Inject(UrlService) protected urlService: UrlService) {
    super(http, urlService);
  }

  /**
   * Retrieves the current sender ID or slug defined in the state.
   *
   * @return the ID or slug
   */
  getCurrentIdOrSlug(): string | null {
    const senderParam = _.get(this.$state.current, 'data.senderParam');
    return this.$state.params[senderParam];
  }

  /**
   * Retrieves a page of all senders the current user can use as functional
   * user in the current context.
   *
   * @param pageable the pagination information
   * @param id the ID of the context object (e.g. a timeline item ID or the ID of the sender of the timeline)
   * @param type the type of the context ('timeline-item' or 'sender')
   * @return the observable of the page
   */
  getActableSenders(pageable: Pageable, id: string, type: 'timeline-item' | 'sender'): Observable<Page<Sender>> {
    const searchRequest = new SearchRequest('', ['displayName'], {type: ['page', 'workspace', 'event']});
    return this.getPage(pageable, {
      path: 'search/actable-senders',
      params: searchRequest.toHttpParams().append('id', id).append('type', type)
    });
  }

  /**
   * Checks if the sender is translated.
   *
   * @param sender The sender to be checked
   *
   * @return true if the sender is translated
   */
  isTranslated(sender: Sender): boolean {
    return !!sender.defaultLanguage && sender.translations && Object.keys(sender.translations).length !== 0;
  }

  /**
   * Retrieves the default language of the sender.
   *
   * @param sender The sender to get the default language for
   *
   * @return the default language or 'NONE' if there is none
   */
  getDefaultLanguage(sender: Sender): string {
    return sender.defaultLanguage || SenderService.NO_DEFAULT_LANGUAGE;
  }

  /**
   * Gets all the available languages for the sender, including the default language.
   *
   * @param sender The sender
   *
   * @return List of available languages or [] if the sender is not translated
   */
  getAvailableLanguages(sender: Sender): string[] {
    if (this.isTranslated(sender)) {
      return [...Object.keys(sender.translations), this.getDefaultLanguage(sender)];
    } else {
      return [];
    }
  }

  /**
   * Creates a folder
   * @param senderId The id of the sender
   * @param name The name of the folder
   * @param parentId The parent id if the parent differs from the sender
   *
   * @returns an observable of the created file
   */
  createFolder(senderId: string, name: string, parentId?: string): Observable<File> {
    const body = {
      name,
      folder: true
    };
    if (parentId) {
      body['parentId'] = parentId;
    }
    return this.http.post<File>(`${this.getBaseUrl()}/${senderId}/folders`, body);
  }

  protected getBaseUrl(): string {
    return '/web/senders';
  }
}

import {PollWidgetSettingsOption} from '@widgets/poll/poll-widget-settings';
import {PollWidgetOptionsStateModel} from '@widgets/poll/poll-widget.state';

export class HydratePollWidget {
  static readonly type: string = '[PollWidget] Hydrate State';

  constructor(public widgetId: string, public options: PollWidgetSettingsOption[], public loadResults: boolean, public frozen: boolean) {
  }
}

export class Vote {
  static readonly type: string = '[PollWidget] Vote';

  constructor(public widgetId: string, public option: PollWidgetOptionsStateModel) {
  }
}

export class RemoveVote {
  static readonly type: string = '[PollWidget] Remove Vote';

  constructor(public widgetId: string, public optionId: string, public answerId: string) {
  }
}

<div class="report-body">
  <h4 class="report-header">
    <a [href]="link">{{report.target.displayName}}</a>
  </h4>
  <blockquote>
    <p [innerHtml]="report.message"></p>
    <footer>
      <ng-container  *ngIf="!report.anonymous">
        {{'MODULE.REPORT.REPORTED_BY' | translate}}
        <coyo-sender-link [sender]="report.author"></coyo-sender-link>
      </ng-container>
      <ng-container  *ngIf="report.anonymous">
        {{'MODULE.REPORT.REPORTED_BY_ANONYMOUS' | translate}}
      </ng-container>
      <span>{{'MODULE.REPORT.REPORTED_AT' | translate}} {{report.created | date:'short'}}</span>
    </footer>
  </blockquote>
</div>
<button class="coyo-btn coyo-btn-primary coyo-btn-sm coyo-btn-primary" (click)="resolve.emit(report)">
  <i class="zmdi zmdi-check"></i>&nbsp;{{'MODULE.REPORT.RESOLVE' | translate}}
</button>

(function (angular) {
  'use strict';

  datepickerService.$inject = ["moment", "dateFilter"];
  angular
      .module('commons.ui')
      .factory('datepickerService', datepickerService);

  /**
   * @ngdoc service
   * @name commons.ui.datepickerService
   *
   * @description
   *     Translates Datepicker.
   */
  function datepickerService(moment, dateFilter) {
    var months;
    var weekdays;
    var title;

    return {
      update: update,
      translateMonths: translateMonths,
      translateTitle: translateTitle,
      getTitle: getTitle,
      getWeekdays: getWeekdays
    };

    /**
     * @ngdoc method
     * @name commons.ui.datepickerService#update
     * @methodOf commons.ui.datepickerService
     *
     * @description
     *     Updates Translations if language has changed.
     */
    function update() {
      _createTranslatedMonths();
      _createTranslatedWeekdays();
      _setCurrentDate();
    }

    /**
     * @ngdoc method
     * @name commons.ui.datepickerService#translateMonths
     * @methodOf commons.ui.datepickerService
     *
     * @description
     *     Translate all Months.
     *
     * @param {object} dt
     *     An object including all Months capsuled. "rows" object of datepicker
     *
     * @returns {array} dt
     *     Returns an array including translated months capsuled
     */
    function translateMonths(dt) {
      _.forEach (dt, function () {
        var i = 0;
        for (var j = 0; j < dt.length; j++) {
          for (var k = 0; k < dt[j].length; k++) {
            dt[j][k].label = months[i].label;
            i++;
          }
        }
      });
      return dt;
    }

    /**
     * @ngdoc method
     * @name commons.ui.datepickerService#translateTitle
     * @methodOf commons.ui.datepickerService
     *
     * @description
     *     Translate current datepicker title to selected language.
     *
     * @param {string} titleToTranslate
     *     Date, which will be translated to the currently selected language.
     */
    function translateTitle(titleToTranslate) {
      var date = Date.parse('1 ' + titleToTranslate);
      title = moment(new Date(date)).format('MMMM YYYY');
    }

    /**
     * @ngdoc method
     * @name commons.ui.datepickerService#getTitle
     * @methodOf commons.ui.datepickerService
     *
     * @description
     *     Get current title.
     *
     * @returns {string} title
     *     Returns a the current translated title.
     */
    function getTitle() {
      return title;
    }

    /**
     * @ngdoc method
     * @name commons.ui.datepickerService#getWeekdays
     * @methodOf commons.ui.datepickerService
     *
     * @description
     *     Get current weekdays.
     *
     * @returns {object} weekdays
     *     Returns a the current translated weekdays.
     */
    function getWeekdays() {
      return weekdays;
    }

    function _createTranslatedMonths() {
      var labels = moment.months();
      months = [];
      for (var i = 0; i < 12; i++) {
        months.push({
          value: i,
          label: labels[i]
        });
      }
    }

    function _createTranslatedWeekdays() {
      weekdays = [];
      var abbr = moment.weekdaysShort();
      var full = moment.weekdays();
      for (var i = 0; i < 7; i++) {
        weekdays.push({
          abbr: abbr[i],
          full: full[i]
        });
      }
      var temp = weekdays.shift();
      weekdays.push(temp);
    }

    function _setCurrentDate() {
      title = dateFilter(moment()._d);
    }

  }

})(angular);

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {WebPreview} from '@domain/preview/web-preview';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Web preview services for generating video and link previews
 */
@Injectable({
  providedIn: 'root'
})
export class WebPreviewService {

  constructor(private http: HttpClient) {
  }

  /**
   * Extract urls from a text
   *
   * @param text e.g. a message of a post
   *
   * @return urls the urls found in a text
   */
  extractUrls(text: string): string[] {
    const regEx = /(https?:\/\/[^\s<]+[^<.,:;"')\]\s])/gi;
    return (text || '').match(regEx) || [];
  }

  /**
   * Generates web previews for the given urls.
   *
   * @param urls The array of urls
   *
   * @return an Observable of an array of WebPreviews. If a preview can't be generated for one of the given url it won't
   * contain a web preview for it.
   */
  generateWebPreviews(urls: string[]): Observable<WebPreview[]> {
    return this.http.post<WebPreview[]>('/web/web-previews', {urls});
  }

  /**
   * Generates a web preview for the given url.
   *
   * @param url
   * The url string
   *
   * @return an Observable of a WebPreview. If a preview can't be generated for the given url it won't
   * contain a web preview for it.
   */
  generateWebPreview(url: string): Observable<WebPreview | null> {
    return this.generateWebPreviews([url]).pipe(map(webPreview => webPreview[0] || null));
  }
}

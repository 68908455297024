import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FloatingFooterDirective} from '@shared/floating-modules/floating-footer/floating-footer.directive';

/**
 * Module exporting floating-footer directive.
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FloatingFooterDirective
  ],
  exports: [
    FloatingFooterDirective
  ]
})
export class FloatingFooterModule {}

import {Injectable} from '@angular/core';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Action, State, StateContext} from '@ngxs/store';
import {WidgetWikiArticleResponse} from '@widgets/wiki-article/widget-wiki-article-response';
import {
  Init,
  LoadMore,
  Search,
  SetLoading
} from '@widgets/wiki-article/wiki-article-widget-settings/wiki-article-widget-settings.actions';
import {WikiArticleWidgetService} from '@widgets/wiki-article/wiki-article-widget/wiki-article-widget.service';
import {Observable, of} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';

export interface WikiArticleWidgetSettingsStateModel {
  articles: WidgetWikiArticleResponse[];
  page: Page<WidgetWikiArticleResponse>;
  searchTerm: string;
  loading: boolean;
  initialized: boolean;
}

@Injectable({providedIn: 'root'})
@State<WikiArticleWidgetSettingsStateModel>({
  name: 'wikiArticleWidgetSettingsState',
  defaults: {
    articles: null,
    page: null,
    searchTerm: '',
    loading: false,
    initialized: false
  }
})
export class WikiArticleWidgetSettingsState {

  constructor(private wikiArticleWidgetService: WikiArticleWidgetService) {

  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<WikiArticleWidgetSettingsStateModel>, action: SetLoading): void {
    ctx.patchState({
      ...ctx.getState(),
      loading: action.loading
    });
  }

  @Action(Init)
  init(ctx: StateContext<WikiArticleWidgetSettingsStateModel>, action: Init): Observable<void> {
    ctx.patchState({
      articles: null,
      page: null,
      searchTerm: '',
      loading: false,
      initialized: false
    });
    if (!action.id) {
      ctx.patchState({
        initialized: true
      });
      return of();
    }
    return ctx.dispatch(new SetLoading(true))
      .pipe(switchMap(() => this.wikiArticleWidgetService.getWikiArticle(action.id)), catchError(err => of(null)))
      .pipe(tap(result => ctx.patchState({
        ...ctx.getState(),
        articles: result ? [result] : null,
        initialized: true
      }))).pipe(switchMap(() => ctx.dispatch(new SetLoading(false))));
  }

  @Action(LoadMore)
  loadMore(ctx: StateContext<WikiArticleWidgetSettingsStateModel>, action: LoadMore): Observable<void> {
    if (ctx.getState().loading || !ctx.getState().initialized) {
      return of();
    }
    return this.loadArticles(ctx, new Pageable(ctx.getState().page ? ctx.getState().page.number + 1 : 0),
      ctx.getState().searchTerm);
  }

  @Action(Search, {cancelUncompleted: true})
  search(ctx: StateContext<WikiArticleWidgetSettingsStateModel>, action: Search): Observable<void> {
    if (!ctx.getState().initialized) {
      return of();
    }
    return this.loadArticles(ctx, new Pageable(), action.searchTerm);
  }

  private loadArticles(ctx: StateContext<WikiArticleWidgetSettingsStateModel>, pageable: Pageable, searchTerm: string):
    Observable<void> {
    ctx.patchState({searchTerm: searchTerm});
    return ctx.dispatch(new SetLoading(true))
      .pipe(switchMap(() => this.wikiArticleWidgetService.getWikiArticlePage(pageable, searchTerm)))
      .pipe(tap(page => {
        if (ctx.getState().searchTerm === searchTerm) {
          ctx.patchState({
            ...ctx.getState(),
            articles: [...(pageable.page !== 0 ? ctx.getState().articles : []), ...page.content],
            page: page
          });
        }
      })).pipe(switchMap(() => ctx.dispatch(new SetLoading(false))));
  }
}

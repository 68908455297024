import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {CodeWidget} from '@widgets/code/code-widget';
import {CodeWidgetSettingsComponent} from './code-widget-settings/code-widget-settings.component';
import {CodeWidgetComponent} from './code-widget/code-widget.component';

/**
 * The configuration for the code widget.
 */
export const CODE_WIDGET: WidgetConfig<CodeWidget> = {
  key: 'code',
  name: 'WIDGET.CODE.NAME',
  description: 'WIDGET.CODE.DESCRIPTION',
  icon: 'zmdi-code',
  categories: WidgetCategory.STATIC,
  component: CodeWidgetComponent,
  settings: {
    skipOnCreate: false,
    component: CodeWidgetSettingsComponent
  },
  renderOptions: {
    printable: true,
    panels: {
      noPanel: true
    }
  },
  whitelistExternal: true
};

(function (angular) {
  'use strict';

  AdminGameListController.$inject = ["$stateParams", "GameAdminModel", "Pageable", "tournament"];
  angular
      .module('coyo.admin.gamification')
      .controller('AdminGameListController', AdminGameListController);

  function AdminGameListController($stateParams, GameAdminModel, Pageable, tournament) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.loading = true;
      vm.tournament = tournament;

      var pageable = new Pageable(_.toNumber($stateParams.page), 16);
      return GameAdminModel.pagedQuery(pageable, null, {'tournamentId': vm.tournament.id}).then(function (data) {
        vm.page = data;
      }).finally(function () {
        vm.loading = false;
      });
    }
  }

})(angular);

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {UserProfileWidget} from '@widgets/user-profile/user-profile-widget';
import {UserProfileWidgetSettingsComponent} from '@widgets/user-profile/user-profile-widget-settings/user-profile-widget-settings.component';
import {UserProfileWidgetComponent} from '@widgets/user-profile/user-profile-widget/user-profile-widget.component';

export const USER_PROFILE_WIDGET: WidgetConfig<UserProfileWidget> = {
  key: 'userprofile',
  name: 'WIDGET.USERPROFILE.NAME',
  description: 'WIDGET.USERPROFILE.DESCRIPTION',
  icon: 'zmdi-account-box-o',
  component: UserProfileWidgetComponent,
  settings: {
    component: UserProfileWidgetSettingsComponent,
    skipOnCreate: false
  },
  categories: WidgetCategory.DYNAMIC,
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

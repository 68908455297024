import {Inject, Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {SettingsService} from '@domain/settings/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {WINDOW} from '@root/injection-tokens';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Service responsible for handling the page title.
 *
 * The title consists of three (optional) components: the prefix (i.e. the
 * network name), the actual page title and the current notification count.
 * The resulting title is then composed as follows: "({count}) {prefix} | {title}"
 * (e.g. "(3) COYO | My page title").
 */
@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private prefix$: BehaviorSubject<string>;
  private title$: BehaviorSubject<string>;
  private count$: BehaviorSubject<number>;

  constructor(private titleService: Title,
              private translateService: TranslateService,
              private settingsService: SettingsService,
              @Inject(WINDOW) private window: any) {
    this.prefix$ = new BehaviorSubject<string>('');
    this.title$ = new BehaviorSubject<string>('');
    this.count$ = new BehaviorSubject<number>(0);

    this.onChange();
  }

  /**
   * Initializes the page title prefix
   */
  init(): void {
    const defaultNetworkName = this.window.Config.applicationName;
    this.settingsService.retrieveByKey('networkName').subscribe(
      networkName => this.setPrefix(networkName || defaultNetworkName),
      error => this.setPrefix(defaultNetworkName));
  }

  /**
   * Returns the current page prefix.
   *
   * @returns the page prefix
   */
  getPrefix(): string {
    return this.prefix$.getValue() || '';
  }

  /**
   * Updates the page prefix.
   *
   * @param prefix the new page prefix
   * @param i18n flag to indicate if the prefix should be translated
   */
  setPrefix(prefix: string, i18n: boolean = false): void {
    this.prefix$.next(prefix && i18n ? this.translateService.instant(prefix) : prefix);
  }

  /**
   * Returns the current page title.
   *
   * @returns the page title
   */
  getTitle(): string {
    return this.title$.getValue() || '';
  }

  /**
   * Updates the page title.
   *
   * @param title the new page title
   * @param i18n flag to indicate if the title should be translated
   */
  setTitle(title: string, i18n: boolean = false): void {
    if (title && i18n) {
      this.translateService.get(title).subscribe(value => {
        this.title$.next(value);
      });
    } else {
      this.title$.next(title);
    }
  }

  /**
   * Updates the page notification count.
   *
   * @param count the page notification count
   */
  setCount(count: number): void {
    this.count$.next(count);
  }

  private onChange(): void {
    combineLatest([
      this.prefix$,
      this.title$,
      this.count$
    ]).pipe(map(([prefix, title, count]) =>
      (count > 0 ? '(' + count + ') ' : '') +
      (prefix || '') +
      (prefix && title ? ' | ' : '') +
      (title || '')
    )).subscribe(title => this.titleService.setTitle(title.trim()));
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {ShareableSender} from '@domain/sender/shareable-sender';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TimelineItemContentComponent} from '../timeline-item-content.component';

/**
 * Displays the content of a shared page post.
 */
@Component({
  selector: 'coyo-sender-share-item',
  templateUrl: './sender-share-item.component.html',
  styleUrls: ['../share-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderShareItemComponent extends TimelineItemContentComponent implements OnInit {

  sender: ShareableSender;

  avatarSize$: Observable<string>;

  constructor(cd: ChangeDetectorRef,
              private windowSizeService: WindowSizeService) {
    super(cd);
  }

  ngOnInit(): void {
    this.sender = (this.item.data as any)[this.item.itemType];
    this.avatarSize$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(source => source === ScreenSize.XS ? 'md' : 'xl'));
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Sender} from '@domain/sender/sender';
import {SublineService} from '@shared/sender-ui/subline/subline.service';
import {Observable} from 'rxjs';

/**
 * Renders a sender representation for select input fields.
 */
@Component({
  selector: 'coyo-sender-options-view',
  templateUrl: './sender-options-view.component.html',
  styleUrls: ['./sender-options-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderOptionsViewComponent implements OnInit {

  /**
   * Sender to be displayed.
   */
  @Input() sender: Sender;

  /**
   * Search term to highlight the search value within the view.
   */
  @Input() searchTerm?: string;

  subline$: Observable<string>;

  constructor(private sublineService: SublineService) { }

  ngOnInit(): void {
    this.subline$ = this.sublineService.getSublineForSender(this.sender);
  }
}

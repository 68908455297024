import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {CodeWidget} from '@widgets/code/code-widget';
import * as _ from 'lodash';

/**
 * This widget displays renders code.
 */
@Component({
  selector: 'coyo-code-widget',
  templateUrl: './code-widget.component.html',
  styleUrls: ['./code-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeWidgetComponent extends WidgetComponent<CodeWidget> implements OnInit, OnChanges, OnDestroy {

  private htmlElement: HTMLElement;
  private scriptElement: HTMLScriptElement;
  private styleElement: HTMLStyleElement;

  @ViewChild('htmlDiv', {
    static: true
  }) htmlDiv: ElementRef;

  constructor(@Inject(DOCUMENT) private document: any,
              cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.updateHtml();
    this.updateScript();
    this.updateStyle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateHtml();
    this.updateScript();
    this.updateStyle();
  }

  ngOnDestroy(): void {
    if (this.hasHtmlTag()) {
      this.removeHtmlTag();
    }
    if (this.hasScriptTag()) {
      this.removeScriptTag();
    }
    if (this.hasStyleTag()) {
      this.removeCssTag();
    }
  }

  updateHtml(): void {
    if (this.widget.id && this.hasHtmlCode()) {
      if (this.hasHtmlTag()) {
        this.removeHtmlTag();
      }
      if (!this.hasHtmlTag()) {
        this.createHtmlTag();
      }
    }
  }

  updateScript(): void {
    if (this.widget.id && this.hasJsCode()) {
      if (!this.hasScriptTag()) {
        this.createScriptTag();
      }
      document.getElementById(this.getJsId()).textContent = this.widget.settings._js_content;
    }
  }

  updateStyle(): void {
    if (this.widget.id && this.hasCssCode()) {
      if (!this.hasStyleTag()) {
        this.createStyleTag();
      }
      document.getElementById(this.getCssId()).textContent = this.widget.settings._css_content;
    }
  }

  createHtmlTag(): void {
    this.htmlElement = this.document.createRange().createContextualFragment(this.widget.settings._html_content);
    this.htmlDiv.nativeElement.appendChild(this.htmlElement);
  }

  createScriptTag(): void {
    this.scriptElement = this.document.createElement('script');
    this.scriptElement.setAttribute('id', this.getJsId());
    this.scriptElement.setAttribute('type', 'application/javascript');
    this.document.head.appendChild(this.scriptElement);
  }

  createStyleTag(): void {
    this.styleElement = this.document.createElement('style');
    this.styleElement.setAttribute('id', this.getCssId());
    this.styleElement.setAttribute('type', 'text/css');
    this.document.head.appendChild(this.styleElement);
  }

  removeHtmlTag(): void {
    const children = Array.from(this.htmlDiv.nativeElement.children);
    children.forEach((child: any) => {
      this.htmlDiv.nativeElement.removeChild(child);
    });
  }

  removeScriptTag(): void {
    this.document.head.removeChild(this.scriptElement);
  }

  removeCssTag(): void {
    this.document.head.removeChild(this.styleElement);
  }

  hasHtmlTag(): boolean {
    return this.htmlDiv.nativeElement.children.length > 0;
  }

  hasScriptTag(): boolean {
    return !_.isUndefined(this.scriptElement) && !_.isNull(document.getElementById(this.getJsId()));
  }

  hasStyleTag(): boolean {
    return !_.isUndefined(this.styleElement) && !_.isNull(document.getElementById(this.getCssId()));
  }

  hasContent(): boolean {
    return this.hasHtmlCode() || this.hasJsCode() || this.hasCssCode();
  }

  hasHtmlCode(): boolean {
    return !_.isEmpty(this.widget.settings._html_content);
  }

  hasJsCode(): boolean {
    return !_.isEmpty(this.widget.settings._js_content);
  }

  hasCssCode(): boolean {
    return !_.isEmpty(this.widget.settings._css_content);
  }

  getJsId(): string {
    return this.widget.id + '-js';
  }

  getCssId(): string {
    return this.widget.id + '-css';
  }
}

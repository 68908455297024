import {Inject, Injectable} from '@angular/core';
import {EntityId} from '@domain/entity-id/entity-id';
import {Store} from '@ngxs/store';
import {Ng1StateLockService} from '@root/typings';
import {NG1_STATE_LOCK_SERVICE} from '@upgrade/upgrade.module';
import {WidgetStateModel} from '@widgets/api/widget-state.model';
import {
  CancelEditMode,
  CreateWidgetLayout,
  DeleteWidgetLayout,
  DuplicateWidgetLayout,
  NotifyPlugins,
  PersistWidgetChanges,
  PrepareNewLayoutRevision,
  SetEditMode
} from '@widgets/api/widget.actions';
import {WidgetState} from '@widgets/api/widget.state';
import * as _ from 'lodash';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, finalize, first, map, share, tap} from 'rxjs/operators';

/**
 * Manages the edit, save and cancel action for the widget layout as well as all widgets slots contained inside it.
 */
@Injectable({
  providedIn: 'root'
})
export class WidgetEditService {
  private registeredSlots$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(private store: Store,
              @Inject(NG1_STATE_LOCK_SERVICE) private stateLockService: Ng1StateLockService) {
  }

  /**
   * Observe if the edit mode is enabled.
   *
   * @param editScope The affected edit scope. The affected edit scope.
   * @returns Observable when the action is complete
   */
  editModeEnabled$(editScope: string = 'global'): Observable<boolean> {
    return this.store.select(WidgetState).pipe(map(state =>
      _.get(state.editScopes[editScope], 'editMode', false)), distinctUntilChanged());
  }

  /**
   * Save the current state of the specified edit scope.
   *
   * @param editScope The affected edit scope. The affected edit scope.
   * @param keepUnlocked If the state should be kept unlocked after the action
   * @returns Observable when the action is complete
   */
  save(editScope: string = 'global', keepUnlocked: boolean = false): Observable<void> {
    const action$ = this.store.dispatch(new PersistWidgetChanges(editScope))
      .pipe(share())
      .pipe(tap(() => this.store.dispatch(new NotifyPlugins(editScope, 'save'))));
    if (!keepUnlocked) {
      action$.pipe(finalize(() => this.stateLockService.unlock())).subscribe();
    }
    return action$;
  }

  /**
   * Cancel edit mode for all active edit scopes.
   *
   * @returns Observable when the action is complete
   */
  cancelAllEdit(): Observable<void> {
    const snapshot: WidgetStateModel = this.store.selectSnapshot(WidgetState);
    return combineLatest(Object.keys(snapshot.editScopes)
      .filter(value => snapshot.editScopes[value].editMode)
      .map(value => this.store.dispatch(new CancelEditMode(value)))
    ).pipe(map(() => void (0)));
  }

  /**
   * Cancel edit mode and restore previous state.
   *
   * @param editScope The affected edit scope.
   * @param keepUnlocked If the state should be kept unlocked after the action
   * @returns Observable when the action is complete
   */
  cancelEdit(editScope: string = 'global', keepUnlocked: boolean = false): Observable<void> {
    const action$ = this.store.dispatch(new CancelEditMode(editScope))
      .pipe(share())
      .pipe(tap(() => this.store.dispatch(new NotifyPlugins(editScope, 'cancel'))));
    if (!keepUnlocked) {
      action$.pipe(first()).subscribe(() => this.stateLockService.unlock());
    }
    return action$;
  }

  /**
   * Enable edit mode.
   *
   * @param editScope The affected edit scope.
   * @param keepUnlocked If the state should be kept unlocked after the action
   * @returns Observable when the action is complete
   */
  enableEditMode(editScope: string = 'global', keepUnlocked: boolean = false): Observable<void> {
    const action$ = this.store.dispatch(new SetEditMode(editScope, true))
      .pipe(share())
      .pipe(tap(() => this.store.dispatch(new NotifyPlugins(editScope, 'enter'))));
    if (!keepUnlocked) {
      action$.pipe(first()).subscribe(() => this.stateLockService.lock());
    }
    return action$;
  }

  /**
   * Mark a layout language for deletion.
   *
   * @param editScope The affected edit scope.
   * @param baseLayout the layout name
   * @param lang the language
   * @returns Observable when the action is complete
   */
  markLayoutLanguageForDeletion(editScope: string, baseLayout: string, lang: string): Observable<void> {
    return this.store.dispatch(new DeleteWidgetLayout(`${baseLayout}-${lang}`, editScope));
  }

  /**
   * Duplicate a layout for a language.
   *
   * @param editScope The affected edit scope
   * @param baseLayout the layout name
   * @param lang the language
   * @returns An observable that completes once the layout is duplicated
   */
  duplicateLayoutForLanguage(editScope: string, baseLayout: string, lang: string): Observable<void> {
    return this.store.dispatch(new DuplicateWidgetLayout(baseLayout, `${baseLayout}-${lang}`, lang, editScope));
  }

  /**
   * Duplicate a layout for all languages of the base revision to prepare creation of a new revision. Used in wiki articles.
   *
   * @param editScope The affected edit scope
   * @param oldLayoutName the layout name
   * @param newLayoutName the layout name
   * @param langs the languages to duplicate
   * @param parent The parent of the layout
   * @returns An observable that completes once the new rev is prepared
   */
  prepareNewRevision(editScope: string, oldLayoutName: string, newLayoutName: string, langs: string[], parent: EntityId): Observable<void> {
    return this.store.dispatch(new PrepareNewLayoutRevision(oldLayoutName, newLayoutName, langs, parent, editScope));
  }

  /**
   * Initialize empty layout for a language.
   *
   * @param editScope The affected edit scope
   * @param baseLayout the layout name
   * @param lang the language
   * @param parent the parent of the new layout
   * @returns An observable that does not emit, completes when action was dispatched
   */
  initializeEmptyLayoutForLanguage(editScope: string, baseLayout: string, lang: string, parent: EntityId): Observable<void> {
    let name = baseLayout;
    if (lang && lang !== 'NONE') {
      name += `-${lang}`;
    }
    return this.store.dispatch(new CreateWidgetLayout(name, lang, parent, editScope));
  }

  /**
   * Register a new globally editable slot.
   *
   * @param slotName the slot name
   * @param editScope the edit scope
   */
  register(slotName: string, editScope: string): void {
    if (editScope === 'global') {
      const registeredSlots = this.registeredSlots$.getValue();
      registeredSlots.push(slotName);
      this.registeredSlots$.next(registeredSlots);
    }
  }

  /**
   * Unregister a new globally editable slot.
   *
   * @param slotName the slot name
   */
  unregister(slotName: string): void {
    const registeredSlots = this.registeredSlots$.getValue();
    const indexOf = registeredSlots.indexOf(slotName);
    if (indexOf >= 0) {
      registeredSlots.splice(indexOf, 1);
      this.registeredSlots$.next(registeredSlots);
    }
  }

  /**
   * Checks if the global edit mode can be enabled.
   *
   * @returns Observable when the action is complete
   */
  canEnableGlobalEditMode$(): Observable<boolean> {
    return this.registeredSlots$.pipe(map(registeredSlots => registeredSlots.length > 0), distinctUntilChanged());
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {SenderEvent} from '@domain/event/sender-event';
import * as moment from 'moment';

@Pipe({
  name: 'isOngoing',
  pure: true
})
export class IsOngoingPipe implements PipeTransform {

  /**
   * Determines whether the event is ongoing.
   *
   * @param event The event with a start and an end date
   * @returns true if the event is ongoing, else false
   */
  transform(event: SenderEvent): boolean {
    const now = moment();
    return now.isSameOrAfter(moment(event.startDate)) && now.isSameOrBefore(moment(event.endDate));
  }

}

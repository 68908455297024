import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {User} from '@domain/user/user';
import {UserService} from '@domain/user/user.service';
import {WidgetComponent} from '@widgets/api/widget-component';
import {UserProfileWidget} from '@widgets/user-profile/user-profile-widget';
import {Observable} from 'rxjs';

/**
 * User profile widget
 */
@Component({
  selector: 'coyo-user-profile-widget',
  templateUrl: './user-profile-widget.component.html',
  styleUrls: ['./user-profile-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileWidgetComponent extends WidgetComponent<UserProfileWidget> implements OnInit, OnChanges {
  user$: Observable<User>;

  constructor(cd: ChangeDetectorRef, private userService: UserService) {
    super(cd);
  }

  ngOnInit(): void {
    this.updateUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.changedSettingsIn(changes, '_selectedUser')) {
      this.updateUser();
    }
  }

  private updateUser(): void {
    this.user$ = this.userService.get(this.widget.settings._selectedUser, {handleErrors: false});
  }
}

import {ChangeDetectionStrategy, Component, OnChanges, OnInit} from '@angular/core';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {FilePickerUploadItem} from '@app/file-picker/file-picker-upload-item/file-picker-upload-item';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Component rendering a table row for a file library file
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tr[coyo-file-library-upload-item]',
  templateUrl: './file-picker-upload-item.component.html',
  styleUrls: ['./file-picker-upload-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerUploadItemComponent extends FilePickerItemBaseDirective<FilePickerUploadItem> implements OnChanges, OnInit {
  progress$: Observable<number>;

  constructor(store: Store) {
    super(store);
  }

  ngOnInit(): void {
    this.progress$ = this.model$.pipe(map(model => model?.progress));
  }
}

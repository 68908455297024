import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * Coyo counter component.
 *
 * @description
 * Renders a list count (e.g. <em>'5 Users'</em>) including pluralization.
 *
 * Mobile view will reduce to a badge containing a number.
 *
 * @example
 * <coyo-counter [counterValue]="..." [translationKey]="...">...</coyo-counter>
 */
@Component({
  selector: 'coyo-counter',
  templateUrl: './counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterComponent {

  /**
   * The value of the counter.
   */
  @Input() counterValue: number;

  /**
   * The translationKey handling the count value (none, singular or plural) via translate.
   */
  @Input() translationKey: string;

  constructor() { }
}

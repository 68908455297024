<ng-container *ngIf="(state$ | async); let state">
  <div [formGroup]="parentForm">
    <div class="form-group">
      <label class="col-sm-3 control-label" translate="WIDGETS.WIKIARTICLE.SETTINGS.WIKIARTICLE.LABEL"></label>
      <div class="col-sm-9">
        <ng-select [items]="state.articles"
                   [virtualScroll]="true"
                   (scroll)="onScroll($event, state)"
                   (search)="onSearch($event)"
                   (open)="onOpen()"
                   bindValue="id"
                   bindLabel="title"
                   formControlName="_articleId"
                   [loading]="state.loading"
                   appendTo=".ng-select-overlay-container"
                   [notFoundText]="'WIDGETS.WIKIARTICLE.NO_ARTICLES' | translate"
                   [loadingText]="'LOADING' | translate">
          <ng-template ng-label-tmp let-item="item">
            <span class="value-title">{{item.title}}</span><span class="sender-name">{{item.senderName}}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <div>{{item.title}}</div>
            <div class="sender-name">{{item.senderName}}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
</ng-container>

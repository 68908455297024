import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe to transform file size.
 */
@Pipe({
  name: 'fileSize'
})
export class FileSize implements PipeTransform {

  /**
   * Converts a byte number into a human readable file size
   *
   * @param bytes Number of bytes
   * @return transformed human readable size
   */
  transform(bytes: number): string {
    if (bytes === undefined || isNaN(parseFloat(bytes.toString())) || !isFinite(bytes) || parseFloat(bytes.toString()) < 0) {
      return '-';
    }

    const precision = 1;
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const number = bytes ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0;
    const size = (bytes ? (bytes / Math.pow(1024, Math.floor(number))) : 0).toFixed(precision);
    return size + ' ' + units[number];
  }
}

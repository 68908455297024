(function (angular, rxjs) {
  'use strict';

  /**
   * @ngdoc service
   * @name commons.rxjs.rxjs
   *
   * @requires $window
   *
   * @description
   * Factory service that exposes the global `rxjs` object to the AngularJS world.
   */
  angular
      .module('commons.rxjs')
      .factory('rxjs', ["$window", function ($window) {
        $window.rxjs || ($window.rxjs = rxjs);
        return $window.rxjs;
      }]);

})(angular, rxjs);

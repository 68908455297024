import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {BirthdayUser} from '@widgets/birthday/birthday-user';
import {Observable} from 'rxjs';

export const REFERENCE_LEAP_YEAR = 2016;

@Injectable({
  providedIn: 'root'
})
export class BirthdayService {

  constructor(private http: HttpClient) { }

  /**
   * Retrieves a page of users which have their birthday in the next days.
   *
   * @param daysFuture The maximum number of days until the birthdate
   * @param widgetId The id of the widget.
   * @param onlySubscribed If set to true only subscribed users will be fetched
   * @param pageable Pagination information
   *
   * @returns The page of users
   */
  getBirthdays(daysFuture: number, widgetId: string, onlySubscribed: boolean, pageable: Pageable): Observable<Page<BirthdayUser>> {
    const url = '/web/widgets/birthday/birthdays';
    let params = pageable.toHttpParams();
    params = params.set('daysFuture', daysFuture.toString(10));
    if (widgetId) {
      params = params.set('id', widgetId);
    }
    if (onlySubscribed) {
      params = params.set('filters', 'subscribedTo');
    }
    return this.http.get<Page<BirthdayUser>>(url, {params});
  }

  /**
   * Checks if information about the birth year is added to the given string
   * @param birthday The birthday string in following format: 'yyyy-mm-dd' or 'mm-dd'
   *
   * @returns true if a year is given
   */
  hasYear(birthday: string): boolean {
    return birthday.length > 5;
  }
}

(function (angular) {
  'use strict';

  WidgetLayoutModel.$inject = ["$q", "restResourceFactory", "coyoEndpoints", "WidgetModel"];
  angular
      .module('coyo.domain')
      .factory('WidgetLayoutModel', WidgetLayoutModel);

  /**
   * @ngdoc service
   * @name coyo.domain.WidgetLayoutModel
   *
   * @description
   * Domain model representation of widget layout endpoint.
   *
   * @requires $q
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   * @requires coyo.domain.WidgetModel
   */
  function WidgetLayoutModel($q, restResourceFactory, coyoEndpoints, WidgetModel) {
    var WidgetLayoutModel = restResourceFactory({
      url: coyoEndpoints.widgetLayouts,
      extensions: ['snapshots']
    });

    var cache = {};

    angular.extend(WidgetLayoutModel.prototype, {
      /**
       * @ngdoc function
       * @name coyo.domain.WidgetLayoutModel#getWithWidgets
       * @methodOf coyo.domain.WidgetLayoutModel
       *
       * @description
       * Load the widget layout with the corresponding widgets included. The widget will be stored in the WidgetModel
       * cache where they can be retrieved by the widget slot directive.
       *
       * @returns {promise} A promise resolving the widget layout.
       */
      getWithWidgets: function () {
        return this.getWithPermissions(undefined, {includeWidgets: true}, ['manage', 'manageWidgets']).then(function (layout) {
          layout.cacheWidgets();
          return layout;
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.WidgetLayoutModel#getWithWidgetsFromCache
       * @methodOf coyo.domain.WidgetLayoutModel
       *
       * @description
       * Returns the widget layout from the internal (one time) cache if present.
       * Otherwise loads the corresponding layout with widgets included and cached. The widget will be stored in the WidgetModel
       * cache where they can be retrieved by the widget slot directive.
       *
       * @returns {promise} A promise resolving the widget layout.
       */
      getWithWidgetsFromCache: function () {
        if (cache[this.name]) {
          var result = $q.resolve(cache[this.name]);
          delete cache[this.name];
          return result;
        }
        return this.getWithWidgets();
      },

      /**
       * @ngdoc function
       * @name coyo.domain.WidgetLayoutModel#cacheWidgets
       * @methodOf coyo.domain.WidgetLayoutModel
       *
       * @description
       * If the widgets for this layout are present the widgets will be stored in the WidgetModel
       * cache where they can be retrieved by the widget slot directive.
       */
      cacheWidgets: function () {
        if (!_.isEmpty(this.widgets)) {
          var canManageWidgets = _.get(this, '_permissions.manageWidgets', false);
          _.forEach(this.widgets, function (widgets, slot) {
            WidgetModel.cache(slot, _.map(widgets, function (widget) {
              widget._permissions = {manage: canManageWidgets};
              return new WidgetModel(widget);
            }));
          });
        }
      },

      /**
       * @ngdoc function
       * @name coyo.domain.WidgetLayoutModel#cacheWithWidgets
       * @methodOf coyo.domain.WidgetLayoutModel
       *
       * @description
       * Store the widget layout in a (one-time) cache to support bulk loading for multiple layouts (e.g. when
       * generating a wiki article preview for multiple (child) articles).
       * The widgets included in the layout are also cached in the WidgetModel cache.
       */
      cacheWithWidgets: function () {
        this.cacheWidgets();
        cache[this.name] = this;
      }

    });

    return WidgetLayoutModel;
  }

})(angular);

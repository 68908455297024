(function (angular) {
  'use strict';

  championshipRankingService.$inject = ["$http", "Page"];
  angular.module('coyo.apps.championship')
      .factory('championshipRankingService', championshipRankingService);

  /**
   * @ngdoc service
   * @name coyo.apps.championship.championshipRankingService
   *
   * @description
   * Service for retrieving the ranking
   *
   * @requires Page
   */
  function championshipRankingService($http, Page) {
    return {
      getRankingForUser: getRankingForUser,
      getRanking: getRanking
    };

    /**
     * @ngdoc method
     * @name coyo.apps.championship.championshipRankingService#getRankingForUser
     * @methodOf coyo.apps.championship.championshipRankingService
     *
     * @description
     * Sends an request to the backend to returns an Object containing the ranking for a specific user
     *
     * @returns {promise} the http promise
     */
    function getRankingForUser(app, userId) {
      var url = '/web/senders/{{senderId}}/apps/{{appId}}/championship/games/ranking/{{userId}}'
          .replace('{{senderId}}', app.senderId)
          .replace('{{appId}}', app.id)
          .replace('{{userId}}', userId);
      return $http.get(url).then(function (response) {
        return response.data;
      });
    }

    /**
     * @ngdoc method
     * @name coyo.apps.championship.championshipRankingService#getRanking
     * @methodOf coyo.apps.championship.championshipRankingService
     *
     * @description
     * Sends a request to the backend to return a paged list of the ranking for the current app.
     *
     * @returns {promise} the http promise
     */
    function getRanking(app, pageable) {
      var params = pageable.getParams();
      var url = '/web/senders/{{senderId}}/apps/{{appId}}/championship/games/ranking'
          .replace('{{senderId}}', app.senderId)
          .replace('{{appId}}', app.id);
      return $http.get(url, params).then(function (response) {
        return new Page(response.data, params, {url: url});
      });
    }
  }

})(angular);

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Skeleton} from '@coyo/ui';
import {Widget} from '@domain/widget/widget';
import {WidgetVisibilityConfig} from '@domain/widget/widget-visibility-config';
import {WidgetVisibilityConfigService} from '@domain/widget/widget-visibility-config.service';
import {WidgetConfig} from '@widgets/api/widget-config';
import {WidgetRegistryService} from '@widgets/api/widget-registry/widget-registry.service';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

class WidgetListEntry {
  key: string;
  name: string;
  description: string;
  config: WidgetVisibilityConfig;

  constructor(widgetConfig: WidgetConfig<Widget<WidgetSettings>>, visibilityConfig?: WidgetVisibilityConfig) {
    this.key = widgetConfig.key;
    this.name = widgetConfig.name;
    this.description = widgetConfig.description;
    this.config = visibilityConfig || {
      key: widgetConfig.key,
      enabled: false,
      moderatorsOnly: false,
      whitelistExternal: false
    };
  }
}

@Component({
  selector: 'coyo-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetListComponent implements OnInit {

  widgetList$: Observable<WidgetListEntry[]> = new Observable<WidgetListEntry[]>();
  widgetConfigurations$: BehaviorSubject<WidgetVisibilityConfig[]> = new BehaviorSubject<WidgetVisibilityConfig[]>([]);
  updating$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  readonly skeletons: Skeleton[] = [
    {top: 0, left: 0, width: 'calc(100% - 300px)', height: 48},
    {top: 14, left: 'calc(100% - 220px)', width: 20, height: 20},
    {top: 14, left: 'calc(100% - 60px)', width: 20, height: 20}
  ];

  readonly skeletonCount: any[] = Array(3);

  constructor(private widgetRegistryService: WidgetRegistryService,
              private widgetVisibilityConfigService: WidgetVisibilityConfigService) {
  }

  ngOnInit(): void {
    this.readConfigurations();
    this.widgetList$ = this.widgetConfigurations$
      .pipe(map(configurations => this.widgetRegistryService.getAllStatic().map(widgetConfig =>
        (new WidgetListEntry(widgetConfig, configurations.find(c => c.key === widgetConfig.key))))));
  }

  trackByKey(index: number, item: WidgetListEntry): string {
    return item.key;
  }

  setEnabled(conf: WidgetVisibilityConfig, change: MatCheckboxChange): void {
    conf.enabled = change.checked;
    this.checked(conf, change, this.updateConfiguration.bind(this));
  }

  setModeratorsOnly(conf: WidgetVisibilityConfig, change: MatCheckboxChange): void {
    conf.moderatorsOnly = change.checked;
    this.checked(conf, change, this.updateConfiguration.bind(this));
  }

  private readConfigurations(): void {
    this.widgetVisibilityConfigService.getAllEnabled()
      .subscribe(widgetConfiguration => this.widgetConfigurations$.next(widgetConfiguration));
  }

  private updateConfigurationsLocal(updatedConf: WidgetVisibilityConfig): void {
    this.widgetConfigurations$.next(this.replaceOrAdd(this.widgetConfigurations$.value, updatedConf));
  }

  private updateConfiguration(newConf: WidgetVisibilityConfig): Observable<WidgetVisibilityConfig> {
    return this.widgetVisibilityConfigService.createOrUpdate(newConf.key, newConf);
  }

  private checked(newConf: WidgetVisibilityConfig, change: MatCheckboxChange,
                  fn: (conf: WidgetVisibilityConfig) => Observable<WidgetVisibilityConfig>): void {
    this.updating$.next(this.updating$.value.concat(newConf.key));
    fn(newConf).subscribe(updatedConf => {
      this.updateConfigurationsLocal(updatedConf);
      this.updating$.next(this.updating$.value.filter(id => id !== newConf.key));
    }, () => {
      change.source.checked = !change.source.checked;
      this.updating$.next(this.updating$.value.filter(id => id !== newConf.key));
    });
  }

  private replaceOrAdd(
    configurations: WidgetVisibilityConfig[], updatedConf: WidgetVisibilityConfig): WidgetVisibilityConfig[] {
    const idx = configurations.findIndex((p => p.key === updatedConf.key));
    if (idx !== -1) {
      configurations.splice(idx, 1, updatedConf);
    } else {
      configurations.push(updatedConf);
    }
    return configurations;
  }
}

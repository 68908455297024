import {Component} from '@angular/core';

/**
 * Renders a horizontal divider with a (optional) content.
 *
 * @Example:
 * <coyo-divider>Content</coyo-divider>
 */
@Component({
  selector: 'coyo-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent {

  constructor() {}
}

(function (angular) {
  'use strict';

  FormEntryModel.$inject = ["appResourceFactory", "$httpParamSerializer"];
  angular
      .module('coyo.apps.form')
      .factory('FormEntryModel', FormEntryModel);

  /**
   * @ngdoc service
   * @name coyo.apps.form.FormEntryModel
   *
   * @description
   * A model representing a field entity of a form.
   *
   * @requires coyo.apps.api.appResourceFactory
   * @requires $httpParamSerializer
   */
  function FormEntryModel(appResourceFactory, $httpParamSerializer) {
    var FormEntry = appResourceFactory({
      appKey: 'form',
      url: '/entries/{{id}}',
      name: 'formField'
    });

    // class members
    angular.extend(FormEntry, {

      /**
       * @ngdoc function
       * @name coyo.apps.form.FormEntryModel#getDownloadUrl
       * @methodOf coyo.apps.form.FormEntryModel
       *
       * @description
       * Returns a CSV download URL for form entries.
       *
       * @returns {string} A CSV download URL.
       */
      getDownloadUrl: function (sort, queryParams, context) {
        var url = FormEntry.$url(context);
        var params = angular.extend({
          download: 'csv',
          _orderBy: sort || undefined
        }, queryParams);
        return url + '?' + $httpParamSerializer(params);
      }
    });

    return FormEntry;
  }

})(angular);

(function (angular) {
  'use strict';

  ReachTrackingController.$inject = ["$scope", "$injector", "$timeout", "$element"];
  angular
      .module('coyo.base')
      .directive('coyoAnalyticsReachTracking', reachTracking)
      .controller('ReachTrackingController', ReachTrackingController);

  /**
   * @ngdoc directive
   * @name commons.analytics:coyoAnalyticsReachTracking
   * @element ANY
   * @restrict E
   * @scope
   *
   * @description
   * The reach tracking directive implements with an interaction observer which always emits
   * when the viewed entity like a timeline item has entered the viewport of the user.
   * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
   *
   * @requires $injector
   * @requires $scope
   */
  function reachTracking() {
    return {
      restrict: 'A',
      scope: {
        entityId: '<',
        entityType: '<'
      },
      controller: 'ReachTrackingController'
    };
  }

  function ReachTrackingController($scope, $injector, $timeout, $element) {
    var renderThreshold = 2000;
    var intersectionThreshold = 0.75;
    var observer;
    var vm = this;
    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;
    vm.intersect = intersect;

    function onInit() {
      $injector.get('ngxAnalyticsService').isAnalyticsEnabled().toPromise().then(function (isAnalyticsEnabled) {
        if (isAnalyticsEnabled) {
          $timeout(initializeObserver(), renderThreshold);
        }
      });
    }

    function intersect(entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting && entry.intersectionRatio >= intersectionThreshold) {
          $injector.get('ngxUserReachedService').handleUserReachedEvent(new Date(), $scope.entityId, $scope.entityType);
          observer.disconnect();
        }
      });
    }

    function initializeObserver() {
      observer = new IntersectionObserver(function (entries) {
        vm.intersect(entries);
      }, {threshold: intersectionThreshold});
      observer.observe($element[0]);
    }

    function onDestroy() {
      if (observer) {
        observer.disconnect();
      }
    }
  }

})(angular);

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Sender} from '@domain/sender/sender';
import {Store} from '@ngxs/store';
import {VotersModalInput} from '@widgets/poll/voters-modal/voters-modal-input';
import {InitializeVoters, LoadMoreVoters} from '@widgets/poll/voters-modal/voters-modal.actions';
import {VotersModalState, VotersModalStateModel} from '@widgets/poll/voters-modal/voters-modal.state';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'coyo-voters-modal',
  templateUrl: './voters-modal.component.html',
  styleUrls: ['./voters-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VotersModalComponent {

  state$: Observable<VotersModalStateModel>;

  voters$: Observable<Sender[]>;
  loading$: Observable<boolean>;
  isEmpty$: Observable<boolean>;
  hasMore$: Observable<boolean>;

  constructor(private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: VotersModalInput) {
    this.state$ = this.store.select(VotersModalState.getState);
    this.voters$ = this.state$.pipe(map(state => state.voters));
    this.loading$ = this.state$.pipe(map(state => state.loading));
    this.isEmpty$ = this.state$.pipe(map(state => !state.loading && state.voters.length === 0));
    this.hasMore$ = this.state$.pipe(map(state => !state.loading && !state.last));
    this.store.dispatch(new InitializeVoters(this.data.widgetId, this.data.optionId));
  }

  loadMore(): void {
    this.store.dispatch(new LoadMoreVoters(this.data.widgetId, this.data.optionId));
  }
}

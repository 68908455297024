import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Comment} from '@domain/comment/comment';
import {CommentRequest} from '@domain/comment/comment-request';
import {CommentService} from '@domain/comment/comment.service';
import {Sender} from '@domain/sender/sender';
import {EmojiPickerComponent} from '@shared/emoji/emoji-picker/emoji-picker.component';
import {TimelineFormAttachment} from '@shared/files/attachment-btn/types/timeline-form-attachment';
import {TimelineFormCoyoLibraryAttachment} from '@shared/files/attachment-btn/types/timeline-form-coyo-library-attachment';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {CommentAttachment} from '@shared/social/comments/comment-form/comment-attachment';
import * as _ from 'lodash';
import {FileItem} from 'ng2-file-upload';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {CommentFormStatus} from './comment-form-status';

/**
 * The value of the comment form
 */
interface CommentFormValue {
  message: string;
  attachments: CommentAttachment[];
}

/**
 * The comment form.
 */
@Component({
  selector: 'coyo-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentFormComponent implements OnInit, OnChanges, AfterViewInit {

  CommentFormStatus = CommentFormStatus; // tslint:disable-line

  /**
   * The author of new comments.
   */
  @Input() author: Sender;

  /**
   * The commentable target of the comment.
   */
  @Input() target: any;

  /**
   * The comment to be edited.
   */
  @Input() comment?: Comment;

  /**
   * Emits a comment when a new comment has been created.
   */
  @Output() created: EventEmitter<Comment> = new EventEmitter<Comment>();

  /**
   * Emits a comment when an existing comment has been updated.
   */
  @Output() edited: EventEmitter<Comment> = new EventEmitter<Comment>();

  /**
   * Emits when the editing of a comment has been canceled.
   */
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('message', {
    static: true
  }) messageRef: ElementRef<HTMLTextAreaElement>;

  @ViewChild('emojiPicker', {
    static: true
  }) emojiPicker: EmojiPickerComponent;

  attachments$: Observable<(FileItem | TimelineFormAttachment | TimelineFormCoyoLibraryAttachment)[]>;
  isXs$: Observable<boolean>;
  form: FormGroup;

  formActionsActive: boolean = false;
  formState$: BehaviorSubject<CommentFormStatus> = new BehaviorSubject<CommentFormStatus>(CommentFormStatus.START);

  constructor(fb: FormBuilder,
              private commentService: CommentService,
              private windowSizeService: WindowSizeService) {
    this.form = fb.group({
      message: ['', CoyoValidators.notBlank],
      attachments: [[], CoyoValidators.notUploading]
    });
  }

  ngOnInit(): void {
    this.attachments$ = this.form.get('attachments').valueChanges.pipe(startWith([]));
    this.isXs$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const commentChange = changes['comment'];
    if (commentChange && !commentChange.isFirstChange() && commentChange.currentValue) {
      this.form.setValue({
        message: this.comment.message || '',
        attachments: this.comment.attachments || []
      });
      this.attachments$ = this.form.get('attachments').valueChanges.pipe(startWith(this.comment.attachments));
      setTimeout(() => {
        const input = this.messageRef.nativeElement;
        input.focus();  // set focus and put cursor to end of input
        const val = input.value;
        input.value = '';
        input.value = val;
      }, 50);
    }
  }

  ngAfterViewInit(): void {
    // register the keydown handler here so it will always be the last handler (e.g. mentioning handler is fired first)
    this.messageRef.nativeElement.addEventListener('keydown', ($event: KeyboardEvent) => this.onKeydown($event));
  }

  /**
   * Removes the given attachment from the form.
   *
   * @param attachment the attachment to be removed.
   */
  removeAttachment(attachment: TimelineFormAttachment | TimelineFormCoyoLibraryAttachment): void {
    this.form
      .get('attachments')
      .setValue(
        _.filter(this.form.get('attachments').value, a => !_.isEqual(attachment, a))
      );
  }

  /**
   * Submits the form.
   *
   * @param value the form value
   */
  submit(value: CommentFormValue = this.form.getRawValue()): void {
    if (this.formState$.getValue() === CommentFormStatus.SUBMITTING || !this.form.valid) {
      return;
    }
    this.formState$.next(CommentFormStatus.SUBMITTING);

    const attachments: TimelineFormAttachment[] = (value.attachments || [])
      .filter((attachment: CommentAttachment) => attachment.storage !== 'LOCAL_FILE_LIBRARY')
      .map((attachment: CommentAttachment) => {
        attachment.uid = (attachment.uid || attachment.fileId);
        return attachment;
      }) as TimelineFormAttachment[];
    const fileLibraryAttachments: TimelineFormCoyoLibraryAttachment[] = (value.attachments || [])
      .filter((attachment: CommentAttachment) => attachment.storage === 'LOCAL_FILE_LIBRARY')
      .map((attachment: CommentAttachment) => {
        attachment.senderId = (attachment.senderId || attachment.modelId);
        return attachment;
      }) as TimelineFormCoyoLibraryAttachment[];

    const request: CommentRequest = {
      authorId: this.author.id,
      targetId: this.target.id,
      targetType: this.target.typeName,
      message: value.message,
      attachments: attachments,
      fileLibraryAttachments: fileLibraryAttachments
    };

    const options = {
      params: {
        _permissions: '*'
      }
    };

    const saveRequest = this.comment ?
      this.commentService.put(this.comment.id, request, options)
      : this.commentService.post(request, options);

    saveRequest.subscribe(res => {
      if (this.comment) {
        this.edited.emit(res);
      } else {
        this.created.emit(res);
      }
      this.reset();
    }, () => {
      this.formState$.next(CommentFormStatus.ERROR);
    });
  }

  /**
   * Resets the form to its default values.
   */
  reset(): void {
    this.comment = null;
    this.form.setValue({
      message: '',
      attachments: []
    });
    this.formState$.next(CommentFormStatus.START);
  }

  /**
   * Cancels the creation or editing process and resets the form.
   */
  cancelForm(): void {
    this.reset();
    this.cancel.emit();
  }

  /**
   * Handles the keyboard events in the message textarea.
   *
   * @param $event the event
   * @return false to prevent default event handling
   */
  onKeydown($event: KeyboardEvent): boolean | void {
    if (this.windowSizeService.isXs()) {
      // on mobile we don't want to submit on enter
      return;
    } else if (!$event.shiftKey && $event.key === 'Enter') {
      $event.preventDefault();
      this.submit();
      return false;
    } else if ($event.key === 'Escape') {
      this.emojiPicker.closePicker();
      if (this.form.get('message').value) {
        $event.stopPropagation();
        $event.preventDefault();
        this.cancelForm();
        return false;
      }
      this.cancelForm();
      return false;
    }
  }

  /**
   * Handles the event when the message textarea is focused.
   */
  onFocus(): void {
    this.formActionsActive = true;
  }

}

(function (angular) {
  'use strict';

  BlogSettingsController.$inject = ["$scope"];
  angular
      .module('coyo.apps.blog')
      .controller('BlogSettingsController', BlogSettingsController);

  function BlogSettingsController($scope) {
    var vm = this;
    vm.$onInit = onInit;
    vm.setAppFolderPermissions = setAppFolderPermissions;
    vm.clearPublisherUserList = clearPublisherUserList;

    vm.app = $scope.model;
    vm.app.settings.authorType = _.chain(vm).get('app.settings.authorType').defaultTo('ADMIN').value();
    vm.app.settings.publisherType = _.chain(vm).get('app.settings.publisherType').defaultTo('ADMIN').value();
    vm.app.settings.commentsAllowed = _.chain(vm).get('app.settings.commentsAllowed').defaultTo(true).value();

    function setAppFolderPermissions() {
      if (vm.app.settings.authorType === 'BLOGAPP_LIST_OF_USERS') {
        setListOfUsers();
      } else {
        clearListOfUsers();
        _.set(vm.app.settings, 'folderPermissions.modifyRole', vm.app.settings.authorType);
      }
    }

    function setListOfUsers() {
      _.set(vm.app.settings, 'folderPermissions.modifyRole', 'NONE');
      _.set(vm.app.settings, 'folderPermissions.users', vm.app.settings.authorIds);
    }

    function clearListOfUsers() {
      _.set(vm.app.settings, 'folderPermissions.users', []);
      vm.app.settings.authorIds && delete vm.app.settings.authorIds;
    }

    function clearPublisherUserList() {
      if (vm.app.settings.publisherType !== 'BLOGAPP_LIST_OF_USERS') {
        vm.app.settings.publisherIds && delete vm.app.settings.publisherIds;
      }
    }

    function onInit() {
      vm.setAppFolderPermissions();
    }
  }

})(angular);

(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .component('coyoSelectBirthday', selectBirthday());

  /**
   * @ngdoc directive
   * @name commons.ui.coyoSelectBirthday:coyoSelectBirthday
   * @scope
   * @restrict 'E'
   * @element ANY
   *
   * @description
   * Renders form fields for birthday.
   *
   * @param {string} formattedDate The given date string.
   *
   * @requires moment
   * @requires commons.ui.birthdayService
   */
  function selectBirthday() {
    return {
      templateUrl: 'app/commons/ui/components/birthday/select-birthday.html',
      require: {
        ngModelCtrl: 'ngModel'
      },
      bindings: {
        formattedDate: '=ngModel',
        disabled: '<'
      },
      controller: 'SelectBirthdayController',
      controllerAs: '$ctrl'
    };
  }

})(angular);

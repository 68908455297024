<article *ngIf="state$ | async as state" [class.has-ribbon]="!!state.ribbonType || state.relevantShare?.unread"
         [class.has-share]="!!state.relevantShare">
  <coyo-ribbon (click)="markAsRead()" [type]="state.ribbonType" *ngIf="state.ribbonType"></coyo-ribbon>
  <div id="item-{{state.item.id}}" class="scroll-anchor"></div>
  <coyo-timeline-share *ngIf="state.relevantShare"
                       [share]="state.relevantShare"
                       [itemType]="state.item.itemType"
                       [showRibbon]="true"
                       data-test="coyo-timeline-share">
  </coyo-timeline-share>

  <section class="timeline-item-main-part"
           [entityId]="{id: state.item.id, typeName: 'TIMELINE_ITEM'}"
           coyoAnalyticsReachTracking>
    <coyo-timeline-item-header *ngIf="state.item.itemType === 'post'"
                               [item]="state.item"
                               [originalAuthor]="state.originalAuthor"
                               [hasShares]="state.hasShares"
                               [publicShare]="state.publicShare"
                               [recipientNotAuthor]="isRecipientNotTheAuthor(state)">
    </coyo-timeline-item-header>
    <coyo-timeline-item-context-menu (showAuthor)="setShowOriginalAuthor($event)"
                                     (delete)="remove()"
                                     (unsticky)="unsticky()"
                                     [item]="state.item" data-test="button-timeline-entry-edit-dropdown">
    </coyo-timeline-item-context-menu>
    <coyo-timeline-item-content-outlet [item]="state.item"></coyo-timeline-item-content-outlet>
  </section>

  <coyo-timeline-item-footer *ngIf="state.author && state.target"
                             [author]="state.author"
                             [item]="state.item"
                             [context]="state.context"
                             [target]="state.target"
                             [skipInitRequest]="state.skipInitRequest"
                             (authorChanged)="setAuthor($event)"
                             (shareCreated)="emitRelevantShare()"
                             (sharesDeleted)="deleteShare($event)">
  </coyo-timeline-item-footer>
</article>
<ng-container *ngIf="state$ | async as state">
  <coyo-comment-list [target]="state.target"
                     [author]="state.author"
                     [subscriptionToken]="state.target.subscriptionInfo.token"
                     [skipInitRequest]="state.skipInitRequest"
                     *ngIf="state.target && state.author && state.target.socialPermissions.commentsShown"></coyo-comment-list>
</ng-container>

import {FileUploadEvent} from './file-upload-event';

/**
 * Event that is emitted when a single file upload of a FileUpload is aborted or errors
 */
export class FileUploadFileAbortedEvent extends FileUploadEvent {
  constructor(files: FileList | File[], readonly fileId: string, readonly fileIndex: number, readonly successfulUploads: number, readonly error: unknown) {
    super(files);
  }
}

import {EntityId} from '@domain/entity-id/entity-id';
import {Widget} from '@domain/widget/widget';
import {WidgetConfig} from '@widgets/api/widget-config';
import {WidgetLayoutResponse} from '@widgets/api/widget-layout/widget-layout-response';
import {WidgetResponse} from '@widgets/api/widget-layout/widget-response';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {SlotId} from '@widgets/api/widget-state.model';

export class CreateWidgetLayout {
  static type: string = '[Widgets] CreateWidgetLayout';

  constructor(readonly name: string, readonly language: string, readonly parent: EntityId, readonly editScope: string,
              readonly createInitialRte: boolean = true) {
  }
}

export class DeleteWidgetLayout {
  static type: string = '[Widgets] DeleteWidgetLayout';

  constructor(readonly name: string, readonly editScope: string) {
  }
}

export class DuplicateWidgetLayout {
  static type: string = '[Widgets] DuplicateWidgetLayout';

  constructor(readonly baseLayout: string, readonly newLayoutName: string, readonly lang: string, readonly editScope: string) {
  }
}

export class PrepareNewLayoutRevision {
  static type: string = '[Widgets] PrepareNewLayoutRevision';

  constructor(readonly baseLayout: string, readonly newLayoutName: string, readonly langs: string[], readonly parent: EntityId, readonly editScope: string) {
  }
}

export class InitializeWidgetLayout {
  static type: string = '[Widgets] InitializeWidgetLayout';

  constructor(readonly name: string,
              readonly language: string,
              readonly parent: EntityId,
              readonly editScope: string,
              readonly force: boolean = false,
              readonly layout?: WidgetLayoutResponse) {
  }
}

export class InitializeWidgetSlot {
  static type: string = '[Widgets] InitializeWidgetSlot';

  constructor(readonly slot: SlotId,
              readonly parent: EntityId,
              readonly widgets?: WidgetResponse[],
              readonly force: boolean = false) {
  }
}

export class AddWidgetToSlot {
  static type: string = '[Widgets] AddWidgetToSlot';

  constructor(readonly slot: SlotId, readonly config: WidgetConfig<any>,
              readonly settings: WidgetSettings, readonly parent: EntityId, readonly editScope: string) {
  }
}

export class RemoveWidgetFromSlot {
  static type: string = '[Widgets] RemoveWidgetFromSlot';

  constructor(readonly slot: SlotId, readonly widget: Widget<WidgetSettings>, readonly editScope: string) {
  }
}

export class UpdateWidgetSettings {
  static type: string = '[Widgets] UpdateWidgetSettings';

  constructor(readonly slot: SlotId, readonly id: string, readonly settings: WidgetSettings, readonly editScope: string) {
  }
}

export class ToggleMobileWidgetVisibility {
  static type: string = '[Widgets] ToggleMobileWidgetVisibility';

  constructor(readonly slot: SlotId, readonly id: string, readonly editScope: string) {
  }
}

export class AddRowToLayout {
  static type: string = '[Widgets] AddRowToLayout';

  constructor(readonly layout: string, readonly index: number, readonly cols: number[], readonly editScope: string) {
  }
}

export class RemoveRowFromLayout {
  static type: string = '[Widgets] RemoveRowFromLayout';

  constructor(readonly layout: string, readonly index: number, readonly editScope: string) {
  }
}

export class SetEditMode {
  static type: string = '[Widgets] SetEditMode';

  constructor(readonly editScope: string, readonly value: boolean) {
  }
}

export class CancelEditMode {
  static type: string = '[Widgets] CancelEditMode';

  constructor(readonly editScope: string) {
  }
}

export class UpdateParent {
  static type: string = '[Widgets] UpdateParent';

  constructor(readonly editScope: string, readonly parent: EntityId, readonly slot: SlotId) {
  }
}

export class PersistWidgetChanges {
  static type: string = '[Widgets] PersistWidgetChanges';

  constructor(readonly editScope: string = 'global') {
  }
}

export class ToggleWidgetTitleEdit {
  static type: string = '[Widgets] ToggleWidgetTitleEdit';

  constructor(readonly editScope: string = 'global', readonly widgetId: string) {
  }
}

export class MoveWidgetUp {
  static type: string = '[Widgets] MoveWidgetUp';

  constructor(readonly editScope: string = 'global', readonly slot: SlotId, readonly widgetId: string) {
  }
}

export class MoveWidgetDown {
  static type: string = '[Widgets] MoveWidgetDown';

  constructor(readonly editScope: string = 'global', readonly slot: SlotId, readonly widgetId: string) {
  }
}

export class CutWidget {
  static type: string = '[Widgets] CutWidget';

  constructor(readonly editScope: string = 'global', readonly slot: SlotId, readonly widgetId: string) {
  }
}

export class PasteWidget {
  static type: string = '[Widgets] PasteWidget';

  constructor(readonly editScope: string = 'global', readonly slot: SlotId) {
  }
}

export class MoveWidget {
  static type: string = 'MoveWidget';

  constructor(readonly widgetId: string,
              readonly prevSlot: SlotId,
              readonly currSlot: SlotId,
              readonly prevIdx: number,
              readonly currIdx: number,
              readonly editScope: string = 'global') {
  }
}

export class RenameLayoutState {
  static type: string = '[Widgets] RenameLayoutState';

  constructor(readonly editScope: string, readonly oldName: string, readonly newName: string) {
  }
}

export class RenameSlotState {
  static type: string = '[Widgets] RenameSlotState';

  constructor(readonly editScope: string, readonly oldName: string, readonly newName: string) {
  }
}

export class CleanupLayoutState {
  static type: string = '[Widgets] CleanupLayoutState';

  constructor(readonly name: string) {
  }
}

export class CleanupSlotState {
  static type: string = '[Widgets] CleanupSlotState';

  constructor(readonly slot: SlotId) {
  }
}

export class NotifyPlugins {
  static type: string = '[Widgets] NotifyPlugins';

  constructor(readonly editScope: string, readonly action: 'enter' | 'save' | 'cancel') {
  }
}

import {CommonModule} from '@angular/common';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SEARCH_FEATURE_TOGGLE} from '@app/search-shell/search-shell.constants';
import {SearchViewParams} from '@app/search-shell/search-wrapper/search.params';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {MaterialModule} from '@shared/material/material.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {SearchShellService} from './search-shell.service';
import './search-shell.service.downgrade';
import {SearchWrapperComponent} from './search-wrapper/search-wrapper.component';

resolveParams.$inject = ['$transition$'];

/**
 * resolves the params for the current route
 * @param $transition$ The angular js transition
 * @returns The route params
 */
export function resolveParams($transition$: any): SearchViewParams {
  return $transition$.params();
}

export const uIRouterUpgradeModule = UIRouterUpgradeModule.forChild({
  states: [{
    name: 'main.search-beta',
    component: SearchWrapperComponent,
    url: '/search-beta?q',
    data: {
      featureToggle: SEARCH_FEATURE_TOGGLE,
      microfrontend: 'search-ui'
    },
    params: {
      q: null
    },
    resolve: {
      params: resolveParams
    }
  }],
});

/**
 * Module acting as a shell for the new global search that is provided by a micro frontend
 * (currently in beta mode)
 */
@NgModule({
  declarations: [SearchWrapperComponent],
  imports: [
    CommonModule,
    CoyoCommonsModule,
    MaterialModule,
    uIRouterUpgradeModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (searchShell: SearchShellService) => () => searchShell.handleSearchEvents(),
      deps: [SearchShellService],
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchShellModule {
}

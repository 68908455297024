import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TimelineItemEditModalData} from '@app/timeline/timeline-item/timeline-item-edit-modal/timeline-item-edit-modal-data';
import {TimelineItemRequest} from '@domain/timeline-item/timeline-item-request';
import {TimelineItemService} from '@domain/timeline-item/timeline-item.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

/**
 * The timeline item edit form modal component.
 */
@Component({
  templateUrl: './timeline-item-edit-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemEditModalComponent implements OnInit {
  showMessageHintSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sendFormData: boolean = false;
  formGroup: FormGroup;

  constructor(private dialogRef: MatDialogRef<TimelineItemEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TimelineItemEditModalData,
              private formBuilder: FormBuilder,
              private timelineItemService: TimelineItemService) {
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      message: ['', CoyoValidators.notBlank]
    });
    this.timelineItemService.getItem(this.data.itemId, 'personal', '', ['edit']).subscribe(timelineItem => {
      this.formGroup.controls['message'].setValue(_.get(timelineItem, 'data.message', ''));
      this.changeFormEditState(_.get(timelineItem, '_permissions.edit', false));
    });
  }

  /**
   * Sends form data to backend on form submit.
   */
  onSubmit(): void {
    this.sendFormData = true;
    const body = {
      data: {
        message: this.formGroup.get('message').value,
        edited: true
      }
    } as TimelineItemRequest;
    this.timelineItemService.put(this.data.itemId, body, {handleErrors: false}).subscribe(() => {
      this.dialogRef.close(true);
      this.sendFormData = false;
    }, () => {
      this.changeFormEditState(false);
      this.sendFormData = false;
    });
  }

  private changeFormEditState(canEditItem: boolean): void {
    this.showMessageHintSubject.next(!canEditItem);
    if (!canEditItem) {
      this.formGroup.setErrors({itemUsed: true});
      this.formGroup.controls['message'].disable();
    }
  }
}

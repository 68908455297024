(function (angular) {
  'use strict';

  AppLanguageSwitchController.$inject = ["utilService", "$timeout"];
  angular
      .module('coyo.apps.api')
      .component('coyoAppLanguageSwitch', appLanguageSwitch())
      .controller('AppLanguageSwitchController', AppLanguageSwitchController);

  /**
   * @ngdoc directive
   * @name coyo.apps.api.coyoAppLanguageSwitch:coyoAppLanguageSwitch
   * @restrict 'EA'
   * @element OWN
   *
   * @description
   * Renders a flag with a ui-select to switch between all available languages of the1 entity.
   *
   * @param {string}  articleId the id of the blog article
   * @param {string}  currentLanguage The currently used/displayed language of the article
   * @param {object}  languages A object with all available languages.
   * @param {object}  fetchTranslation A function to load the translated content when language is changed.
   */
  function appLanguageSwitch() {
    return {
      templateUrl: 'app/apps/api/components/app-language-switch/app-language-switch.html',
      bindings: {
        currentLanguage: '=',
        availableLanguages: '=',
        fetchTranslation: '='
      },
      controller: 'AppLanguageSwitchController',
      controllerAs: '$ctrl'
    };
  }

  function AppLanguageSwitchController(utilService, $timeout) {
    var vm = this;

    vm.open = false;
    vm.id = 'context-menu-' + utilService.uuid();
    vm.toggleMenu = toggleMenu;
    vm.translateTo = translateTo;

    function toggleMenu(value) {
      vm.open = angular.isDefined(value) ? value : !vm.open;
    }

    function translateTo(language) {
      $timeout(function () {
        vm.fetchTranslation(language);
      }).then(function () {
        vm.toggleMenu(false);
      });
    }

  }
})(angular);

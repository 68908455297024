<section class="events-list-wrapper content-with-sidebar"
         *ngIf="{
           canCreateEvent: canCreateEvent$ | async,
           params: uiParams$ | async,
           isMobile: isMobile$ | async,
           isLoading: isLoading$ | async,
           pageOfEvents: page$ | async
         } as state"
>
  <div class="content-sidebar">
    <coyo-widget-slot
      [visibleOnMobile]="false"
      class="widget-slot-sidebar"
      name="events-list-top"
    ></coyo-widget-slot>

    <div *ngIf="state.canCreateEvent && !state.isMobile" data-test="create-event-menu">
      <coyo-event-create-menu
        [coyoTourOrder]="610"
        [coyoTourText]="'TOUR.EVENTS.CREATE.TEXT' | translate"
        [coyoTourTitle]="'TOUR.EVENTS.CREATE.TITLE' | translate"
        coyoTourPlacement="right-start"
        coyoTourStep="create"
        coyoTourTopic="events"
      ></coyo-event-create-menu>
    </div>

    <coyo-filter-box
      [coyoTourOrder]="620"
      [coyoTourText]="'TOUR.EVENTS.FILTER.TEXT' | translate"
      [coyoTourTitle]="'TOUR.EVENTS.FILTER.TITLE' | translate"
      [filterActive]="!!state.params.from || !!state.params.to"
      [hideActions]="!state.canCreateEvent"
      [vertical]="true"
      class="panel-navigation"
      coyoTourPlacement="right-start"
      coyoTourStep="filter"
      coyoTourTopic="events"
    >
      <fb-filter>
        <coyo-selection-filter
          [title]="'MODULE.EVENTS.FILTER.STATUS' | translate"
          [textPrefix]="'MODULE.EVENTS.FILTER.STATUS'"
          [filterModel]="state.params.status.items"
          (filterModelChange)="setStatusFilter($event)"
        ></coyo-selection-filter>
        <coyo-event-filter-participation-status
          [filterModel]="state.params.participationStatus.items"
          [singleSelect]="true"
          (filterModelChange)="setParticipantStatusFilter($event)"
        ></coyo-event-filter-participation-status>
        <coyo-filter [title]="'MODULE.EVENTS.FILTER.DATE' | translate">
          <coyo-date-range-picker
            (dateSelectionChange)="dateSelectionChange($event)"
            (reset)="resetDateRange()"
            [endDate]="state.params.to"
            [startDate]="state.params.from"
          ></coyo-date-range-picker>
        </coyo-filter>
      </fb-filter>
      <fb-search>
        <coyo-filter-search
          (search)="search($event)"
          [placeholder]="'SEARCH' | translate"
          [term]="state.params.term"></coyo-filter-search>
      </fb-search>
      <fb-actions *ngIf="state.canCreateEvent && state.isMobile">
        <coyo-event-create-menu [hostId]="this.senderId" [isAppView]="true"></coyo-event-create-menu>
      </fb-actions>
    </coyo-filter-box>

    <coyo-widget-slot
      [visibleOnMobile]="false"
      class="widget-slot-sidebar"
      name="events-list-bottom"
    ></coyo-widget-slot>

  </div>

  <div class="content-main">
    <coyo-widget-slot class="widget-slot-content" name="events-list-content"></coyo-widget-slot>

    <coyo-event-list [events]="state.pageOfEvents" [loading]="state.isLoading"></coyo-event-list>

    <coyo-empty-list-notice
      (clickEvent)="resetFilter()"
      *ngIf="!state.isLoading && state.pageOfEvents.empty"
      [message]="'MODULE.EVENTS.EMPTY' | translate"
      [showResetButton]="!state.pageOfEvents.totalElements"
    ></coyo-empty-list-notice>

    <div *ngIf="state.pageOfEvents && state.pageOfEvents.totalElements > state.pageOfEvents.size">
      <mat-paginator
        (page)="loadPageNumber($event)"
        [hidePageSize]="true"
        [length]="state.pageOfEvents.totalElements"
        [pageIndex]="state.pageOfEvents.number"
        [pageSize]="state.pageOfEvents.size"
      ></mat-paginator>
    </div>
  </div>

</section>

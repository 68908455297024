import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IconNameFilePickerItem} from '@app/file-library/icon-name-file-picker-item/icon-name-file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {Store} from '@ngxs/store';

/**
 * File picker component showing a table-row with only an icon and a name
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tr[coyo-icon-name-file-picker-item]',
  templateUrl: './icon-name-file-picker-item.component.html',
  styleUrls: ['./icon-name-file-picker-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconNameFilePickerItemComponent
  extends FilePickerItemBaseDirective<IconNameFilePickerItem> {

  constructor(store: Store) {
    super(store);
  }
}

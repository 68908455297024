import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {WikiArticleWidget} from '@widgets/wiki-article/wiki-article-widget';
import {WikiArticleWidgetSettingsComponent} from '@widgets/wiki-article/wiki-article-widget-settings/wiki-article-widget-settings.component';
import {WikiArticleWidgetComponent} from '@widgets/wiki-article/wiki-article-widget/wiki-article-widget.component';

export const WIKI_ARTICLE: WidgetConfig<WikiArticleWidget> = {
  name: 'WIDGETS.WIKIARTICLE.NAME',
  key: 'wikiarticle',
  icon: 'zmdi-library',
  categories: WidgetCategory.STATIC,
  component: WikiArticleWidgetComponent,
  description: 'WIDGETS.WIKIARTICLE.DESCRIPTION',
  settings: {
    skipOnCreate: false,
    component: WikiArticleWidgetSettingsComponent,
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

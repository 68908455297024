(function (angular) {
  'use strict';

  angular
      .module('coyo.messaging')
      .directive('oyocMessagingNavbarItem', messagingNavbarItem);

  /**
   * @ngdoc directive
   * @name coyo.messaging.oyocMessagingNavbarItem:oyocMessagingNavbarItem
   * @scope
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Renders the messaging navbar item with information about unread messages.
   */
  function messagingNavbarItem() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'app/modules/messaging/components/messaging-navbar-item/messaging-navbar-item.html',
      scope: {},
      controller: 'MessagingNavbarItemController',
      controllerAs: '$ctrl'
    };
  }

})(angular);

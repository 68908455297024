<ng-container *ngIf="state$ | async; let state">
  <h3 mat-dialog-title>
    <span translate="APPS.SETTINGS.TITLE" *ngIf="state.config"></span>
    <span translate="APPS.CHOOSER.TITLE" *ngIf="!state.config"></span>
    <coyo-help tip="APPS.HELP" placement="right"
               [modalTitle]="'MODULE.APPS.HELP.MODAL.TITLE' | translate"
               [modalText]="'MODULE.APPS.HELP.MODAL.TEXT' | translate"
               [modalAria]="'MODULE.APPS.HELP.MODAL.ARIA' | translate"
    ></coyo-help>
  </h3>
  <mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
  <form [formGroup]="appForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div class="app-list" *ngIf="!state.config">
        <ng-container *ngIf="apps$ | async; let apps; else loading">
          <ng-container *ngFor="let app of apps; last as isLast">
            <button mat-flat-button type="button" class="block-left" (click)="openSettings(app)">
              <div class="button-content">
                <div class="icon-wrapper">
                  <mat-icon [svgIcon]="app.svgIcon" size="large"></mat-icon>
                </div>
                <div class="description-wrapper">
                  <div class="name" [translate]="app.name"></div>
                  <div class="description hidden-xs" [translate]="app.description"></div>
                </div>
              </div>
            </button>
            <hr class="hidden-md-and-up hidden-sm app-divider" *ngIf="!isLast">
          </ng-container>
          <ng-container *ngIf="!apps.length">
            <span translate="APPS.CHOOSER.EMPTY"></span>
          </ng-container>
        </ng-container>
        <ng-template #loading>
          <ui-spinner></ui-spinner>
        </ng-template>
      </div>
      <div class="settings" *ngIf="state.config">
        <coyo-app-settings [config]="state.config"
                           [app]="state.legacyApp"
                           [sender]="data.sender"
                           (legacyFormValidityChanged)="onValidityChanged($event)"
                           [isNew]="true"
                           [parentForm]="appForm">
        </coyo-app-settings>
      </div>
    </div>
    <mat-dialog-actions *ngIf="state.config">
      <button type="reset"
              formName="appForm"
              mat-button color="default"
              [attr.aria-label]="'BACK' | translate"
              (click)="onBack()">{{'BACK' | translate}}</button>
      <button type="submit"
              formName="appForm"
              mat-flat-button
              color="accent"
              [loading]="loading$ | async"
              [attr.aria-label]="'SAVE' | translate"
              [disabled]="appForm.invalid || legacyFormInvalid">{{'SAVE' | translate}}</button>
    </mat-dialog-actions>
  </form>
</ng-container>


import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {
  FilePickerItemStateModel,
  FilePickerStateModel
} from '@app/file-picker/file-picker-state/file-picker-state-model';
import {OpenFolder} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Base class for filepicker item components.
 * The component selector should be like tr[filepicker-header] so the table rendering works as expected.
 */
@Directive()
export abstract class FilePickerItemBaseDirective<T extends FilePickerItem> implements OnChanges {

  model$: Observable<FilePickerItemStateModel>;
  item$: Observable<T>;
  filePickerState$: Observable<FilePickerStateModel>;
  /**
   * The id of the parent file picker
   */
  @Input() filePickerId: string;

  /**
   * Parent item of the table
   */
  @Input() itemId: string;

  protected constructor(protected readonly store: Store) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemId) {
      this.model$ = this.store.select(FilePickerStateSelectors.item(this.itemId));
      this.item$ = this.model$.pipe(map(model => model?.item as T));
    }
    if (changes.filePickerId) {
      this.filePickerState$ = this.store.select(state => state.filePicker.pickers[this.filePickerId]);
    }
  }

  openFolder(item: T): void {
    if (item.isFolder) {
      this.store.dispatch(new OpenFolder(this.filePickerId, item));
    }
  }
}

(function (angular) {
  'use strict';

  AdminTranslationsController.$inject = ["$q", "$translate", "$timeout", "$scope", "$filter", "modalService", "settings", "languages", "adminService", "I18nModel", "ngxTokenService"];
  angular
      .module('coyo.admin.multiLanguage')
      .controller('AdminTranslationsController', AdminTranslationsController);

  function AdminTranslationsController($q, $translate, $timeout, $scope, $filter, modalService, settings, languages,
                                       adminService, I18nModel, ngxTokenService) {
    var vm = this;
    vm.filter = {
      'status': {
        'language': filterLanguage,
        'translated': filterTranslatedKeys,
        'onlyTranslatedKeys': false
      },
      'languages': [],
      'search': searchFilter,
      'searchTerm': '',
      'activeFilterTranslated': false
    };

    var allTranslationKeys;
    var filteredTranslationKeys;

    vm.editKey = editKey;
    vm.saveKey = saveKey;
    vm.getTranslated = getTranslated;
    vm.currentPage = currentPage;
    vm.deleteAllKeys = deleteAllKeys;
    vm.onTranslateKeypress = onTranslateKeypress;
    vm.toggleLanguageMenu = toggleLanguageMenu;
    vm.isInAvailableLanguageKeys = isInAvailableLanguageKeys;
    vm.pageSize = 20;
    vm.page = 1;
    vm.reloadTable = false;
    vm.filterOpen = false;
    vm.currentEditValues = {};

    // need to know about screen size in ctrl to turn off infinite scrolling in desktop mode
    vm.mobile = adminService.initMobileCheck($scope, function (isMobile) {
      // move back to first page when switching between mobile and desktop view
      if (isMobile !== vm.mobile && vm.page > 1) {
        vm.page = 1;
      }
      vm.mobile = isMobile;
    });

    if (vm.mobile) {
      vm.page = 0;
    }

    // returns the index of the current page
    function currentPage() {
      return (vm.pageSize * (vm.page - 1));
    }

    function filterLanguage(languageKey) {
      vm.filter.activeLanguage = languageKey;
      _loadAllTranslations().then(function () {
        vm.filter.status.onlyTranslatedKeys = false;
        vm.filter.searchTerm = '';
        vm.filter.activeFilterTranslated = false;
        vm.page = 1;
        toggleLanguageMenu(false);
      });
    }

    function filterTranslatedKeys() {
      vm.filter.activeFilterTranslated = !vm.filter.activeFilterTranslated;
      var tableArray = [];
      vm.filter.searchTerm = '';

      if (vm.filter.activeFilterTranslated) {
        _.forEach(allTranslationKeys, function (value) {
          if (getTranslated(value.key)) {
            tableArray.push({key: value.key, value: value.value, bundle: value.bundle});
          }
        });
        vm.translationKeys = tableArray;
        filteredTranslationKeys = vm.translationKeys;
      } else {
        vm.translationKeys = allTranslationKeys;
        filteredTranslationKeys = vm.translationKeys;
      }
    }

    function toggleLanguageMenu(value) {
      $timeout(function () { // delay opening to avoid firing click-outside event
        vm.filterOpen = angular.isDefined(value) ? value : !vm.filterOpen;
        var fn = vm.filterOpen ? 'addClass' : 'removeClass';
        angular.element(document).querySelectorAll('#translation-language-menu')[fn]('active');
      });
    }

    function onTranslateKeypress($event) {
      if ($event.keyCode === 13) {
        var target = $event.target;
        target.blur();
      }
    }

    function searchFilter(search) {
      $scope.$apply(function () {
        vm.filter.searchTerm = search;
        vm.translationKeys = filteredTranslationKeys;
        if (search) {
          var query = _.lowerCase(search);
          vm.translationKeys = _.filter(vm.translationKeys, function (translation) {
            return _.includes(_.lowerCase(translation.key), query) ||
            _.includes(_.lowerCase(translation.value), query) ||
            _.includes(_.lowerCase((getTranslated(translation.key) || {}).translation), query);
          });
        }
      });
    }

    function editKey($event, key, index) {
      if ($event.keyCode === 32) { return; }
      if (angular.element($event.target).find(':focus').length === 0) {
        var trElement = vm.mobile ? $event.target.parentElement.parentElement.parentElement.querySelectorAll('#li-keys-' + index)
          : trElement = $event.target.parentElement.parentElement.parentElement.querySelectorAll('#tr-keys-' + index);
        trElement[0].classList.remove('override');
        var tdElement = $event.target.parentElement.parentElement.querySelectorAll('.key-edit');
        if (tdElement.length === 1) {
          tdElement[0].classList.add('edit');
        } else {
          tdElement[index].classList.add('edit');
        }
        var target = $event.target.parentElement.querySelectorAll('.input');
        if (target[0]) {
          target[0].select();
          target[0].focus();
        }

        if (!vm.currentEditValues[key]) {
          var translation = getTranslated(key);
          vm.currentEditValues[key] = _.get(translation, 'translation', '');
        }
      }
    }

    function saveKey($event, key, bundle) {
      var messageKey = getTranslated(key) ? getTranslated(key) : null;
      // remove trailing whitespace with trim
      var value = vm.mobile ? $event.target.value.trim() : vm.currentEditValues[key].trim();
      // update value in table (without trailing whitespace)
      $event.target.value = value;
      var promise;

      if (value) {
        angular.element($event.target.parentNode.parentNode).addClass('override');
        if (messageKey) {
          promise = performWithToken(_.partial(I18nModel.modifyTranslation, vm.filter.activeLanguage, key, value, bundle));
        } else {
          promise = performWithToken(_.partial(I18nModel.createTranslation, vm.filter.activeLanguage, key, value, bundle));
        }
      } else if (messageKey) {
        promise = performWithToken(_.partial(I18nModel.deleteOverride, vm.filter.activeLanguage, bundle, key));
      } else {
        promise = $q.resolve();
      }

      promise.then(function () {
        vm.currentEditValues[key] = null;
        _loadOverrides(vm.filter.activeLanguage).then(function () {
          angular.element($event.currentTarget.parentNode).removeClass('edit');
          searchFilter(vm.filter.searchTerm);
        });
      });
    }

    function deleteAllKeys() {
      modalService.confirm({
        title: 'ADMIN.TRANSLATIONS.MODAL.RESET.HEADLINE',
        text: 'ADMIN.TRANSLATIONS.MODAL.RESET.TEXT',
        close: {icon: 'delete', title: 'RESET_CURRENT_LANGUAGE', style: 'btn-danger'}
      }).result.then(function () {
        vm.reloadTable = true;
        performWithToken(_.partial(I18nModel.deleteOverrides, vm.filter.activeLanguage)).then(function () {
          _loadOverrides(vm.filter.activeLanguage).then(function () {
            if (vm.filter.activeFilterTranslated) {
              filterTranslatedKeys();
            }
            searchFilter(vm.filter.searchTerm);
            vm.filter.status.onlyTranslatedKeys = false;
            vm.reloadTable = false;
          });
        });
      });
    }

    function performWithToken(method) {
      return ngxTokenService.getToken().toPromise().then(function (token) {
        return method(token);
      });
    }

    function isInAvailableLanguageKeys(language) {
      var availableLanguageKeys = ['de', 'en', 'nl'];
      return availableLanguageKeys.indexOf(language) > -1;
    }

    function getTranslated(key) {
      return _.find(vm.translatedKeys, function (messageKey) {
        return messageKey.key === key;
      });
    }

    function _loadOverrides(language) {
      return I18nModel.getOverrides(language).then(function (keys) {
        vm.translatedKeys = keys.data;
      });
    }

    function _loadAllTranslations() {
      return getBundles(vm.filter.activeLanguage).then(function (bundles) {
        var tmpArray = [];

        _.forIn(bundles.data, function (keys, bundle) {
          _.forIn(keys, function (value, key) {
            tmpArray.push({key: key, value: value, bundle: bundle});
          });
        });

        vm.translationKeys = tmpArray;
        allTranslationKeys = vm.translationKeys;
        filteredTranslationKeys = vm.translationKeys;

        _loadOverrides(vm.filter.activeLanguage);
      });
    }

    function getBundles(lang) {
      var languageKey = isInAvailableLanguageKeys(lang) ? lang : 'en';
      return I18nModel.getBundles(languageKey);
    }

    function mapLanguage(language) {
      var translationPrefix = 'LANGUAGE.LANGUAGES.';

      return {
        language: language.language.toLowerCase(),
        name: $translate.instant(translationPrefix + language.language)
      };
    }

    function sortLanguages(languages) {
      return $filter('orderBy')(languages, 'name');
    }

    (function _init() {
      // Available language keys
      var activeLanguages = languages.filter(function (language) {
        return language.active;
      }).map(mapLanguage);
      activeLanguages = sortLanguages(activeLanguages);
      var inactiveLanguages = languages.filter(function (language) {
        return !language.active;
      }).map(mapLanguage);
      inactiveLanguages = sortLanguages(inactiveLanguages);
      vm.filter.languages = activeLanguages.concat(inactiveLanguages);
      // current language
      vm.filter.activeLanguage = settings.defaultLanguage.toLowerCase();

      // translation table of current language
      _loadAllTranslations().then();
    })();
  }
})(angular);

import {Inject, Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {Ng1TargetService} from '@root/typings';
import {NG1_TARGET_SERVICE} from '@upgrade/upgrade.module';
import {NgxPermissionsService} from 'ngx-permissions';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Target} from '../target';

/**
 * This service allows navigation to given targets.
 */
@Injectable({
  providedIn: 'root'
})
export class TargetService {

  constructor(
    @Inject(NG1_TARGET_SERVICE) private ng1TargetService: Ng1TargetService,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService) {
  }

  /**
   * Returns the link to a given target.
   *
   * @param target the target
   * @returns the link
   */
  getLinkTo(target: Target): string {
    return this.ng1TargetService.getLink(target);
  }

  /**
   * Checks whether the current user is allowed to open the link based on his
   * global permissions.
   *
   * @param target the target
   * @returns an `Observable` holding the iformation if the current user can
   * access the given target
   */
  canLinkTo(target: Target): Observable<boolean> {
    return this.authService.getUser()
      .pipe(map(user => {
        switch (target.name) {
          case 'user':
            return user.id === target.params.id ? 'ACCESS_OWN_USER_PROFILE' : 'ACCESS_OTHER_USER_PROFILE';
          case 'page':
            return 'ACCESS_PAGES';
          case 'workspace':
            return 'ACCESS_WORKSPACES';
          case 'event':
            return 'ACCESS_EVENTS';
          default:
            return null;
        }
      })).pipe(switchMap(permission => this.permissionsService.hasPermission(permission)));
  }

  /**
   * Checks whether the current user can access the given target and returns an
   * `Observable` holding the target link.
   *
   * @param target the target
   * @return an Observable resolving to the target link or `undefined` if the user
   * does not have the permissions to access the target
   */
  getLinkForCurrentUser(target: Target): Observable<string | undefined> {
    return this.canLinkTo(target).pipe(map(canLinkTo => canLinkTo ? this.getLinkTo(target) : undefined));
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReachTrackingDirective} from '@app/analytics/user-reach/reach-tracking.directive';
import './analytics.service.downgrade';
import './entity-visited/entity-visited.service.downgrade';
import './user-reach/user-reached.service.downgrade';

/**
 * This module tracks user activity for COYO Analytics.
 * Run the `init()` method of {@link AnalyticsService} to enable the tracking.
 */
@NgModule({
  declarations: [
    ReachTrackingDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ReachTrackingDirective
  ]
})
export class AnalyticsModule {
}

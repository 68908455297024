import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {SocketConnectionLostModalComponent} from './socket-connection-lost-modal/socket-connection-lost-modal.component';
import {SocketConnectionMonitorService} from './socket-connection-monitor/socket-connection-monitor.service';

/**
 * Module that contains miscellaneous services.
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    SocketConnectionLostModalComponent
  ],
  exports: [
    SocketConnectionLostModalComponent
  ],
  providers: [
    SocketConnectionMonitorService
  ]
})
export class SocketModule {
}

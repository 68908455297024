import {Inject, Injectable} from '@angular/core';
import {GDrivePickerService} from '@app/integration/gsuite/g-drive-picker/g-drive-picker.service';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {RtePlugin} from '@shared/rte/rte-plugin';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';

/**
 * A custom RTE plugin that extends the existing file plugin with features of the GSuite.
 */
@Injectable()
export class FileFromGSuitePlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertFileFromGSuite';

  constructor(@Inject(FROALA_EDITOR) private froala: any,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private gDrivePickerService: GDrivePickerService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    if (!settings.canAccessGoogle) {
      return; // user is not allowed to access GSuite
    }

    const plugin = this;
    plugin.froala.RegisterCommand(FileFromGSuitePlugin.KEY, {
      title: plugin.translateService.instant('RTE.INSERT_G_SUITE_FILE'),
      plugin: 'coyoFilePlugin',
      icon: 'insertFile',
      undo: false,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        plugin.openGSuiteFilePicker(this);
      }
    });
  }

  private openGSuiteFilePicker(editor: any): void {
    const plugin = this;
    const wrapper = editor.$oel.find('.fr-wrapper');
    const scrollPosition = wrapper.scrollTop();
    const prevText = editor.selection.text();

    editor.selection.save();
    plugin.gDrivePickerService.open({
      multipleSelect: false,
      view: 'DOCS',
      uploadView: true,
      recentFilesView: true
    }).then(selectedFiles => selectedFiles.forEach(file => {
      const name = prevText || file.displayName || file.url;
      editor.selection.restore();
      editor.link.insert(file.url, name, {target: '_blank'});
      wrapper.scrollTop(scrollPosition);
      editor.undo.saveStep();
    })).catch(() => {
      editor.selection.restore();
      wrapper.scrollTop(scrollPosition);
      this.notificationService.error('RTE.INSERT_G_SUITE_FILE.ERROR');
    });
  }
}

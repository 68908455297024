import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DomSanitizer} from '@angular/platform-browser';
import {MaterialModule as MaterialUiModule} from '@coyo/ui';
import {AppGlobalRippleOptionsService} from '@shared/material/app-global-ripple-options/app-global-ripple-options.service';
import './mat-icon.component.downgrade';
import './spinner.component.downgrade';

export function registerIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer): Function {
  return () => iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sprite.defs.svg'));
}

/**
 * Module exporting used angular modules and directives.
 */
@NgModule({
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MaterialUiModule,
    ScrollingModule,
    DragDropModule,
    ClipboardModule
  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MaterialUiModule,
    ScrollingModule,
    DragDropModule,
    ClipboardModule
  ],
  providers: [{
    provide: APP_INITIALIZER, useFactory: registerIcons,
    deps: [MatIconRegistry, DomSanitizer],
    multi: true
  }, {
    provide: MAT_RIPPLE_GLOBAL_OPTIONS,
    useExisting: AppGlobalRippleOptionsService
  }]
})
export class MaterialModule {
}

import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '@root/injection-tokens';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ScreenSize} from './screen-size';

/**
 * Breakpoint for window size small
 */
export const breakpointSm = 768;

/**
 * Breakpoint for window size medium
 */
export const breakpointMd = 992;

/**
 * Breakpoint for window size large
 */
export const breakpointLg = 1200;

/**
 * Service for requesting and updating the window width.
 */
@Injectable({
  providedIn: 'root'
})
export class WindowSizeService {

  private retina: boolean;

  private width: number;

  private height: number;

  private sizeChanged$: BehaviorSubject<ScreenSize> = new BehaviorSubject<ScreenSize>(ScreenSize.XS);

  constructor(@Inject(WINDOW) private window: Window) {
    this.updateWindow();
    this.setRetina();
  }

  /**
   * Updates the state of the service to align with the current window size
   */
  updateWindow(): void {
    this.width = this.window.innerWidth;
    this.height = this.window.innerHeight;
    const screenSize = this.getScreenSize();
    if (screenSize !== this.sizeChanged$.getValue()) {
      this.sizeChanged$.next(screenSize);
    }
  }

  /**
   * Check if the current device has a retina display
   *
   * @return true if it has a retina display
   */
  isRetina(): boolean {
    return this.retina;
  }

  /**
   * Returns the current window width.
   *
   * @return the width of the window
   */
  getWidth(): number {
    return this.width;
  }

  /**
   * Returns the current window height.
   *
   * @return the height of the window
   */
  getHeight(): number {
    return this.height;
  }

  /**
   * Checks if current window size is lower then the {@link breakpointSm}
   *
   * @return true if the window size is xs
   */
  isXs(): boolean {
    return this.sizeChanged$.getValue() === ScreenSize.XS;
  }

  /**
   * Checks if the current window size is between {@link breakpointSm} and {@link breakpointMd}
   *
   * @return true if the window size is sm
   */
  isSm(): boolean {
    return this.sizeChanged$.getValue() === ScreenSize.SM;
  }

  /**
   * Checks if the current window size is between {@link breakpointMd} and {@link breakpointLg}
   *
   * @return true if the window size is md
   */
  isMd(): boolean {
    return this.sizeChanged$.getValue() === ScreenSize.MD;
  }

  /**
   * Checks if the current window size higher or equal to {@link breakpointLg}
   *
   * @return true if the window size is lg
   */
  isLg(): boolean {
    return this.sizeChanged$.getValue() === ScreenSize.LG;
  }

  /**
   * Gets an observable that will emit when screen size is changed
   *
   * @return an observable emitting the new screen size if the screen size changed
   */
  observeScreenChange$(): Observable<ScreenSize> {
    return this.sizeChanged$.asObservable();
  }

  /**
   * Checks on resize if the current display size is a mobile display size
   *
   * @return Observable emitting true if the screen size is mobile
   */
  isMobile$(): Observable<boolean> {
    return this.observeScreenChange$().pipe(map(size => size === ScreenSize.XS));
  }

  /**
   * Returns the current screen size
   *
   * @return the screen size
   */
  getScreenSize(): ScreenSize {
    if (this.width < breakpointSm) {
      return ScreenSize.XS;
    } else if (this.width < breakpointMd) {
      return ScreenSize.SM;
    } else if (this.width < breakpointLg) {
      return ScreenSize.MD;
    } else {
      return ScreenSize.LG;
    }
  }

  private setRetina(): void {
    this.retina = (this.window.devicePixelRatio > 1 ||
      (this.window.matchMedia && this.window.matchMedia(
        '(-webkit-min-device-pixel-ratio: 1.5),' +
        '(-moz-min-device-pixel-ratio: 1.5),' +
        '(min-device-pixel-ratio: 1.5),(min-resolution: 192dpi),(min-resolution: 2dppx)'
      ).matches));
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SpinnerComponent} from './spinner.component';
import './spinner.component.downgrade';

/**
 * Module exporting a spinner component.
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    // tslint:disable-next-line:deprecation
    SpinnerComponent
  ],
  exports: [
    // tslint:disable-next-line:deprecation
    SpinnerComponent
  ],
  entryComponents: [
    // tslint:disable-next-line:deprecation
    SpinnerComponent
  ]
})
export class SpinnerModule {}

import {Inject, Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogSize} from '@coyo/ui';
import {Ng1CoyoConfig} from '@root/typings';
import {AboutCoyoModalComponent} from '@shared/about-coyo/about-coyo-modal.component';
import {NG1_COYO_CONFIG} from '@upgrade/upgrade.module';

/**
 * Renders a modal with the "About COYO" content.
 */
@Injectable({
  providedIn: 'root'
})
export class AboutCoyoModalService {

  constructor(
    private dialog: MatDialog,
    private ngZone: NgZone,
    @Inject(NG1_COYO_CONFIG) private coyoConfig: Ng1CoyoConfig
  ) {}

  /**
   * Opens a modal with the "About COYO" information content
   */
  openAboutCoyoModal(): void {
    this.ngZone.run(() => {
      this.dialog.open<AboutCoyoModalComponent, string>(AboutCoyoModalComponent, {
        width: MatDialogSize.Medium,
        data: this.coyoConfig.versionString(),
        panelClass: 'about-coyo-modal'
      });
    });
  }
}

import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {TimelineItemReportModalData} from './timeline-item-report-modal-data';

interface ReportData {
  targetId: string;
  targetType: string;
  message: string;
  anonymous: boolean;
}

/**
 * The report modal with the report form.
 */
@Component({
  templateUrl: './timeline-item-report-modal.component.html',
  styleUrls: ['./timeline-item-report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemReportModalComponent implements OnInit {

  reportForm: FormGroup;

  sendFormData: boolean = false;

  constructor(private dialogRef: MatDialogRef<TimelineItemReportModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TimelineItemReportModalData,
              private formBuilder: FormBuilder,
              private http: HttpClient) {
  }

  ngOnInit(): void {
    this.reportForm = this.formBuilder.group({
      targetId: [this.data.targetId, Validators.required],
      targetType: [this.data.targetType, Validators.required],
      message: ['', CoyoValidators.notBlank],
      anonymous: [false]
    });
  }

  /**
   * Returns the form data on form submit.
   */
  onSubmit(): void {
    this.sendFormData = true;
    this.http.post<ReportData>('/web/reports', this.reportForm.getRawValue()).subscribe(() => {
      this.dialogRef.close(true);
      this.sendFormData = false;
    }, () => {
      this.sendFormData = false;
    });
  }
}

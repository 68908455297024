import {ChangePasswordComponent} from '@app/login/change-password/change-password.component';
import {SettingsService} from '@domain/settings/settings.service';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';

export function settingsResolve(settingsService: SettingsService): Promise<string> {
  return settingsService.retrieveByKey('passwordPattern').toPromise();
}

export const changePasswordState: NgHybridStateDeclaration = {
  name: 'front.changePassword',
  url: '/change-password',
  component: ChangePasswordComponent,
  resolve: [{
    token: 'passwordPattern',
    deps: [SettingsService],
    resolveFn: settingsResolve
  }],
  data: {
    authenticate: true
  }
};

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {BlogArticle} from '@domain/blog-article/blog-article';
import {DomainService} from '@domain/domain/domain.service';

/**
 * Service to retrieve blog articles.
 */
@Injectable({
  providedIn: 'root'
})
export class BlogArticleService extends DomainService<BlogArticle, BlogArticle> {

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/senders/{senderId}/apps/{appId}/blog/articles';
  }
}

<section class="event-settings" *ngIf="{ready: formInitialisation$ | async} as formInitialisation;">
  <div class="panel panel-default">
    <form class="form-horizontal" role="form"
        [attr.aria-label]="'MODULE.TIMELINE.FORM.ARIA' | translate"
        [formGroup]="eventSettingsForm"
        (ngSubmit)="submitForm()"
        *ngIf="formInitialisation.ready; else loading">
      <div class="panel-heading">
        <h3 class="panel-title" translate="MODULE.EVENTS.SETTINGS.TITLE"></h3>
      </div>
      <div class="panel-body">
        <div class="group">
          <div class="form-group">
            <div class="col-md-12">
              <label for="name" translate="EVENT.TITLE.LABEL"></label>
              <input id="name" class="form-control" type="text" name="name" formControlName="name">
            </div>
          </div>

          <div class="form-group">
            <div class="col-md-12">
              <label for="slug" translate="EVENT.SLUG.LABEL"></label>
              <div class="input-group">
                <div class="input-group-addon">{{slugPrefixEvents}}</div>
                <input id="slug" class="form-control" type="text" name="slug" formControlName="slug">
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-md-12">
              <label for="place" translate="EVENT.PLACE.LABEL"></label>
              <input id="place" class="form-control" type="text" name="place" formControlName="place">
            </div>
          </div>
        </div>

        <div class="group">
          <div class="form-group group">
            <div class="col-md-12">
              <mat-checkbox id="fullDay"
                            name="fullDay"
                            formControlName="fullDay">{{'EVENT.FULL_DAY.LABEL' | translate}}
              </mat-checkbox>
            </div>
            <div class="input-group-date-picker col-md-6 col-xs-12">
              <label for="eventStartDate" translate="EVENT.DATE_START.LABEL"></label>
              <div class="input-group" [ngClass]="{'full-day': eventSettingsForm.controls.fullDay.value === true}">
                <coyo-date-picker id="eventStartDate" class="date"
                                  [(date)]="eventDates.startDate" (dateChange)="updateStartDate()">
                </coyo-date-picker>
                <coyo-time-picker id="eventStartTime" class="time"
                                  [(time)]="eventDates.startTime" (timeChange)="updateStartTime()">
                </coyo-time-picker>
              </div>
            </div>
            <div class="input-group-date-picker col-md-6 col-xs-12">
              <label for="eventEndDate" translate="EVENT.DATE_END.LABEL"></label>
              <div class="input-group" [ngClass]="{'full-day': eventSettingsForm.controls.fullDay.value}">
                <coyo-date-picker id="eventEndDate" class="date"
                                  [(date)]="eventDates.endDate" (dateChange)="updateEndDate()">
                </coyo-date-picker>
                <coyo-time-picker id="eventEndTime" class="time"
                                  [(time)]="eventDates.endTime" (timeChange)="updateEndTime()">
                </coyo-time-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-md-12">
            <label for="description" translate="EVENT.DESCRIPTION.LABEL"></label>
            <coyo-rte id="description"
                      [content]="event.description"
                      [optionOverrides]="eventDescriptionRteOptions"
                      (contentUpdated)="descriptionChanges($event)">
            </coyo-rte>
          </div>
        </div>
      </div>
      <div class="form-group advanced-settings">
        <div class="col-md-12">
          <label translate="MODULE.EVENTS.CREATE.ADVANCED_SETTINGS"></label>
        </div>
        <div class="col-md-12">
          <mat-checkbox id="showParticipants"
                        name="showParticipants"
                        formControlName="showParticipants">{{'EVENT.SHOW_PARTICIPANTS.LABEL' | translate}}
            <mat-hint translate="EVENT.SHOW_PARTICIPANTS.HELP"></mat-hint>
          </mat-checkbox>

        </div>
        <div class="col-md-12">
          <mat-checkbox id="requestDefiniteAnswer"
                        name="requestDefiniteAnswer"
                        formControlName="requestDefiniteAnswer">{{'EVENT.DEFINITE_ANSWERS.LABEL' | translate}}
            <mat-hint translate="EVENT.DEFINITE_ANSWERS.HELP"></mat-hint>
          </mat-checkbox>
        </div>
        <div class="col-md-12">
          <mat-checkbox id="limitedParticipantsFlag"
                        name="limitedParticipantsFlag"
                        formControlName="limitedParticipantsFlag">{{'EVENT.LIMITED_PARTICIPANTS.LABEL' | translate}}
            <mat-hint translate="EVENT.LIMITED_PARTICIPANTS.HELP"></mat-hint>
          </mat-checkbox>
          <span formGroupName="limitedParticipants">
          <input id="participantsLimit" class="form-control participant-count" type="number" name="participantsLimit"
                 formControlName="participantsLimit" [min]="0"
                 *ngIf="eventSettingsForm.controls.limitedParticipantsFlag.value">
        </span>
        </div>
        <!-- invite admins -->
        <div class="col-sm-12">
          <div class="advanced-settings-divider"><div></div></div>
        </div>
        <div class="col-sm-12">
          <label translate="EVENT.ADMINS.LABEL"></label>
        </div>
        <div class="col-sm-12">
          <button mat-flat-button
                  type="button"
                  [attr.aria-label]="'EVENT.ADMINS.HELP' | translate"
                  (click)="openSelectAdminModal()">
            <mat-icon svgIcon="contacts"></mat-icon><span translate="EVENT.ADMINS.INVITE_BUTTON"></span>
          </button>
          <div *ngIf="adminCountMessage$ | async as adminCountMessage" class="text-muted">
            {{adminCountMessage}}
          </div>
        </div>
        <!-- delete event -->
        <div class="col-sm-12">
          <div class="advanced-settings-divider"><div></div></div>
        </div>
        <div class="col-sm-12 label-with-subline">
          <label translate="EVENT.DELETE.LABEL"></label>
          <div class="text-muted" translate="EVENT.DELETE.BUTTON.HINT_TEXT"></div>
        </div>
        <div class="col-sm-12">
          <button mat-flat-button
                  type="button"
                  [attr.aria-label]="'EVENT.DELETE.BUTTON.LABEL' | translate"
                  (click)="deleteEvent()">
            <span translate="EVENT.DELETE.BUTTON.LABEL"></span>
          </button>
        </div>
      </div>
      <div class="form-actions">
        <div class="form-action-buttons right">
          <button mat-button
                  type="button"
                  [attr.aria-label]="'BACK' | translate"
                  (click)="cancelForm()">
            <span translate="BACK"></span>
          </button>
          <button mat-flat-button
                  color="primary"
                  type="button"
                  [attr.aria-label]="'DONE' | translate"
                  [disabled]="!eventSettingsForm.valid"
                  (click)="submitForm()">
            <span translate="DONE"></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

<ng-template #loading>
  <div class="loading-container">
    <div class="coyo-spinner lg" [title]="'MODULE.EVENTS.LOADING' | translate">...</div>
  </div>
</ng-template>

import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Type,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {Widget} from '@domain/widget/widget';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {SlotId} from '@widgets/api/widget-state.model';
import {WidgetInlineSettingsComponent} from '../widget-inline-settings-component';

/**
 * A component to dynamically render a {@link WidgetInlineSettingsComponent}.
 */
@Component({
  selector: 'coyo-widget-inline-settings-container',
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class WidgetInlineSettingsContainerComponent implements OnInit, OnChanges {

  private componentRef: ComponentRef<WidgetInlineSettingsComponent<Widget<WidgetSettings>>>;

  /**
   * The {@link WidgetInlineSettingsComponent} to be rendered.
   */
  @Input() component: Type<WidgetInlineSettingsComponent<Widget<WidgetSettings>>>;

  /**
   * The corresponding widget this settings component belongs to.
   */
  @Input() widget: any;

  /**
   * The edit scope of the widget. This is required for firing state actions in the widget components.
   */
  @Input() editScope: string;

  /**
   * The slot name of the widget. This is required for firing state actions in the widget components.
   */
  @Input() slot: SlotId;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    this.updateComponentRefValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateComponentRefValues();
  }

  private updateComponentRefValues(): void {
    if (this.componentRef) {
      this.componentRef.instance.widget = this.widget;
      this.componentRef.instance.editScope = this.editScope;
      this.componentRef.instance.slot = this.slot;
    }
  }
}

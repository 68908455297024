<div class="launchpad-body-wrapper">
  <div class="launchpad-body">
    <h2>{{'LAUNCHPAD.MANAGE.HEADLINE' | translate}}</h2>
    <form [formGroup]="linkForm">
      <div class="link-icon">
        <coyo-launchpad-link-icon [iconSrc]="iconSrc$ | async" [color]="category | launchpadCategoryColor"
                                  (click)="fileInput.click()"></coyo-launchpad-link-icon>
        <input style="display: none;" type="file" accept="image/*" #fileInput ng2FileSelect formControlName="file"
               [uploader]="uploader" (onFileSelected)="uploadIcon()">
        <div class="link-icon-buttons">
          <button mat-button mat-link-button type="button" size="small" color="accent"
                  (click)="fileInput.click()">{{'LAUNCHPAD.MANAGE.ICON.UPLOAD' | translate}}</button>
          <button mat-button mat-link-button type="button" size="small" color="accent" *ngIf="iconSrc$ | async"
                  (click)="removeIcon()">{{'LAUNCHPAD.MANAGE.ICON.REMOVE' | translate}}</button>
        </div>
      </div>
      <div class="link-meta">
        <div class="form-group" [class.has-error]="linkForm.controls['url'].touched && linkForm.controls['url'].errors">
          <label for="launchpad-url">{{'LAUNCHPAD.MANAGE.URL' | translate}}</label>
          <div class="url-input">
            <input class="form-control" type="url" name="url" id="launchpad-url" formControlName="url" [attr.aria-label]="'LAUNCHPAD.MANAGE.URL.ARIA' | translate"
                   (blur)="fetchUrlMetaData(false)" (keyup.enter)="fetchUrlMetaData(false)" placeholder="https://" cdkFocusInitial>
            <button mat-icon-button type="button" size="small"
                    (click)="fetchUrlMetaData(true)" [attr.aria-label]="'LAUNCHPAD.MANAGE.AUTOSUGGEST' | translate">
              <mat-icon svgIcon="refresh"></mat-icon>
            </button>
          </div>
          <coyo-validation-errors [errors]="linkForm.controls['url'].errors"></coyo-validation-errors>
        </div>
        <div class="form-group" [class.has-error]="linkForm.controls['name'].touched && linkForm.controls['name'].errors">
          <label for="launchpad-name">{{'LAUNCHPAD.MANAGE.NAME' | translate}}</label>
          <input class="form-control" type="text" name="name" id="launchpad-name" formControlName="name" [attr.aria-label]="'LAUNCHPAD.MANAGE.NAME.ARIA' | translate">
          <coyo-validation-errors [errors]="linkForm.controls['name'].errors"></coyo-validation-errors>
        </div>
        <div class="form-group" *ngIf="!link" [class.has-error]="linkForm.controls['category'].touched && linkForm.controls['category'].errors">
          <label for="launchpad-category">{{'LAUNCHPAD.MANAGE.CATEGORY' | translate}}</label>
          <ng-select class="custom" labelForId="category" formControlName="category" bindLabel="name"
                     id="launchpad-category" [attr.aria-label]="'LAUNCHPAD.MANAGE.CATEGORY.ARIA' | translate"
                     placeholder="{{'LAUNCHPAD.MANAGE.CATEGORY.PLACEHOLDER' | translate}}"
                     [items]="categories" [searchable]="false" [clearable]="false">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">{{item.id != 'personal' ? item.name : ('LAUNCHPAD.CATEGORY.PERSONAL' | translate)}}</ng-template>
          </ng-select>
          <coyo-validation-errors [errors]="linkForm.controls['category'].errors"></coyo-validation-errors>
        </div>
      </div>
    </form>
    <div class="link-actions">
      <button mat-button type="button" size="small" (click)="dispose()">
        {{'LAUNCHPAD.MANAGE.DISCARD' | translate}}</button>
      <button mat-flat-button size="small" color="primary" (click)="save()" [disabled]="!linkForm.valid">
        {{'LAUNCHPAD.MANAGE.SAVE' | translate}}</button>
    </div>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {MentionModule} from '@shared/mention/mention.module';
import {TextModule} from '@shared/text/text.module';
import {JitTranslationOutputComponent} from './jit-translation-output/jit-translation-output.component';
import './jit-translation-output/jit-translation-output.component.downgrade';
import {JitTranslationToggleComponent} from './jit-translation-toggle/jit-translation-toggle.component';
import './jit-translation-toggle/jit-translation-toggle.component.downgrade';

/**
 * Module for JIT translations.
 */
@NgModule({
  imports: [CoyoCommonsModule, MentionModule, TextModule],
  declarations: [JitTranslationToggleComponent, JitTranslationOutputComponent],
  entryComponents: [JitTranslationToggleComponent, JitTranslationOutputComponent],
  exports: [JitTranslationToggleComponent, JitTranslationOutputComponent]
})
export class JitTranslationModule {
}

<div class="card" *ngIf="integration$ | async; let integration">
  <div class="card-body">
    <h2 class="mat-headline">{{'MODULE.ACCOUNT.SETTINGS.INTEGRATIONS.HEADLINE' | translate}}</h2>
    <form [formGroup]="form">
      <mat-checkbox formControlName="syncEvents" (change)="update($event.checked)">
        <mat-icon [svgIcon]="integration.icon"></mat-icon> {{'MODULE.ACCOUNT.SETTINGS.INTEGRATIONS.EVENTS.' + integration.key + '.LABEL' | translate}}
        <mat-hint>{{'MODULE.ACCOUNT.SETTINGS.INTEGRATIONS.EVENTS.' + integration.key + '.HINT' | translate}}</mat-hint>
      </mat-checkbox>
    </form>
  </div>
</div>

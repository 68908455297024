import {Inject, Injectable} from '@angular/core';
import {TranslateLoader} from '@ngx-translate/core';
import {Ng1CoyoTranslationLoader} from '@root/typings';
import {NG1_COYO_TRANSLATION_LOADER} from '@upgrade/upgrade.module';
import {from, Observable} from 'rxjs';

/**
 * Service for merging the AngularJS and Angular translations and prepare them for ng-translate use.
 *
 * Attention: Message keys that are used in both frameworks will be overriden by the messages of the AngularJs part.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService implements TranslateLoader {

  /**
   * Constructs the service
   *
   * Merges all messages defined in the application
   *
   * @param coyoTranslationLoader The AngularJs translation message loader
   */
  constructor(@Inject(NG1_COYO_TRANSLATION_LOADER) private coyoTranslationLoader: Ng1CoyoTranslationLoader) {

  }

  /**
   * Returns the translations for the given language.
   *
   * @param lang the language key
   * @return the messages
   */
  getTranslation(lang: string): Observable<any> {
    return from(this.coyoTranslationLoader({key: lang, from: 'ngx'}));
  }
}

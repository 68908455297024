(function (angular) {
  'use strict';

  AdminTermsGeneralController.$inject = ["$q", "$injector", "TermsModel", "SettingsModel", "modalService", "languages", "settings", "translations", "ngxMultiLanguageService"];
  angular.module('coyo.admin.terms')
      .controller('AdminTermsGeneralController', AdminTermsGeneralController);

  function AdminTermsGeneralController($q, $injector, TermsModel, SettingsModel, modalService, languages,
                                       settings, translations, ngxMultiLanguageService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.settings = settings;
    vm.translations = translations;

    vm.isMultiLanguageActive = false;
    vm.defaultLanguage;
    vm.currentLanguage;
    vm.languages = {};

    vm.isActive = isActive;
    vm.canActivate = canActivate;
    vm.save = save;
    vm.reset = reset;
    vm.isTranslationRequired = isTranslationRequired;
    vm.updateValidity = updateValidity;
    vm.updateContentValidity = updateContentValidity;
    vm.onTranslationsAdded = onTranslationsAdded;

    vm.active = isActive();
    vm.hiddenUsers = isHiddenUsers();

    function isActive() {
      return vm.settings.termsRequired === 'true';
    }

    function isHiddenUsers() {
      return vm.settings.hiddenUsers === 'true';
    }

    function canActivate() {
      return isActive() || !isAnyTranslationInvalid() && isDefaultTranslationValid();

      function isDefaultTranslationValid() {
        var defaultTranslation = vm.languages[vm.defaultLanguage].translations;
        return !_.isEmpty(defaultTranslation.title) && !_.isEmpty(defaultTranslation.text);
      }

      function isAnyTranslationInvalid() {
        return !!_.find(_.values(vm.languages), ['valid', false]);
      }
    }

    function save() {
      return _saveTerms().then(function () {
        return (vm.active !== isActive() || vm.hiddenUsers !== isHiddenUsers()) ? _saveActivation() : 'TERMS_SAVED';
      }).then(function () {
        $injector.get('ngxNotificationService').success('ADMIN.TERMS.GENERAL.SAVE.SUCCESS');
      });
    }

    function _saveTerms() {
      var promises = [];
      _.forEach(vm.languages, function (value, language) {
        // delete removed terms
        if (_.isEmpty(value.translations) && alreadyExists(language)) {
          promises.push(getExistingTerms(language).delete().then(function (deleted) {
            vm.translations.splice(vm.translations.indexOf(deleted), 1);
            return deleted;
          }));
        }
        // save created or updated terms
        if (!_.isEmpty(value.translations)) {
          var terms;
          if (!alreadyExists(language)) {
            // add created terms
            terms = new TermsModel(value.translations);
            terms.language = language;
            promises.push(terms.save().then(function (saved) {
              vm.translations.push(saved);
              return saved;
            }));
          } else {
            // update updated terms
            terms = getExistingTerms(language);
            if (!_.isEqual({'title': terms.title, 'text': terms.text, 'url': terms.url}, value.translations)) {
              _.merge(terms, value.translations);
              promises.push(terms.save().then(function (saved) {
                vm.translations.splice(_.findIndex(vm.translations, ['language', language]), 1, saved);
                return saved;
              }));
            }
          }
        }
      });

      function alreadyExists(language) {
        return !!getExistingTerms(language);
      }

      function getExistingTerms(language) {
        return _.find(vm.translations, ['language', language]);
      }

      return $q.all(promises);
    }

    function _saveActivation() {
      var promise = vm.active ? TermsModel.activate(vm.hiddenUsers) : TermsModel.deactivate();
      return promise.then(function () {
        return SettingsModel.retrieve(true).then(function (settings) {
          vm.settings = settings;
          vm.active = isActive();
          vm.hiddenUsers = isHiddenUsers();
          return settings;
        });
      });
    }

    function reset() {
      return modalService.confirm({
        title: 'ADMIN.TERMS.MODAL.RESET.HEADLINE',
        text: 'ADMIN.TERMS.MODAL.RESET.TEXT',
        close: {icon: 'delete', title: 'RESET_TERMS_OF_USE', style: 'btn-danger'},
        dismiss: {title: 'CANCEL'}
      }).result.then(function () {
        return TermsModel.reset().then(function () {
          $injector.get('ngxNotificationService').success('ADMIN.TERMS.GENERAL.RESET.SUCCESS');
        });
      });
    }

    function isTranslationRequired(language) {
      return (vm.languages[language].translations && !!Object.keys(vm.languages[language].translations).length) ||
          (vm.currentLanguage === language);
    }

    function updateValidity(language, valid) {
      vm.languages[language].valid = valid;
    }

    function updateContentValidity(title, contents) {
      var isValid = !!title && contents !== '<p><br></p>' && contents !== '<p></p>' && contents !== '';
      if (!_.isEmpty(vm.languages[vm.currentLanguage].translations)) { // do not update when translations were removed
        updateValidity(vm.currentLanguage, isValid);
      }
    }

    function onTranslationsAdded(translationsApplied) {
      if (translationsApplied) {
        // don't overtake the url from the default language
        delete vm.languages[vm.currentLanguage].translations.url;
      }
    }

    function onInit() {
      initTranslations();
    }

    function initTranslations() {
      ngxMultiLanguageService.isEnabled().toPromise().then(function (result) {
        vm.isMultiLanguageActive = result;
      });
      vm.defaultLanguage = getDefaultLanguage();
      vm.currentLanguage = vm.defaultLanguage;

      var availableLanguages = getAvailableLanguages();

      function getAvailableLanguages() {
        var systemLanguages = _.chain(languages).filter(isActiveLanguage).map(getLanguageCode).value();
        var usedLanguages = _.chain(translations).map(getLanguageCode).value();
        return _.uniq(_.concat(systemLanguages, usedLanguages));

        function isActiveLanguage(language) {
          return language.active;
        }
      }

      function getDefaultLanguage() {
        var currentDefaultLanguage = _.chain(languages).filter(isDefaultLanguage).map(getLanguageCode).head().value();
        var usedDefaultLanguage = _.chain(translations).filter(areDefaultLanguageTerms).map(getLanguageCode).head()
            .value();

        return (usedDefaultLanguage) ? usedDefaultLanguage : currentDefaultLanguage;

        function isDefaultLanguage(language) {
          return language.defaultLanguage;
        }

        function areDefaultLanguageTerms(terms) {
          return terms.defaultLanguage;
        }
      }

      function getLanguageCode(language) {
        return language.language;
      }

      // prepare translations in general
      _.forEach(availableLanguages, function (language) {
        vm.languages[language] = {
          'active': true,
          'translations': {}
        };
      });
      // complete all available translations
      _.forEach(translations, function (translation) {
        vm.languages[translation.language] = {
          'active': true,
          'translations': {
            'title': translation.title,
            'text': translation.text,
            'url': translation.url
          }
        };
      });
      // prepare default language if terms not already exist
      if (_.isEmpty(vm.languages[vm.defaultLanguage].translations)) {
        vm.languages[vm.defaultLanguage].translations = {
          title: '',
          text: '',
          url: null
        };
      }
    }
  }

})(angular);

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {VideoPreview} from '@domain/preview/video-preview';

/**
 * Component responsible for showing embedded iframe videos (e.g. from youtube).
 */
@Component({
  selector: 'coyo-video-preview-list',
  templateUrl: './video-preview-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPreviewListComponent {

  /**
   * The videos to be shown by the component. Orders the videos by their position.
   *
   * @param previews the videos
   */
  @Input() set previews(previews: VideoPreview[]) {
    if (previews) {
      previews.sort((a, b) => a.position - b.position);
    }
    this.sortedPreviews = previews;
  }

  /**
   * Flag for showing a delete icon in the top right of each video.
   */
  @Input() editMode: boolean = false;

  /**
   * Event that is emitted when the component is in edit mode and a delete button is clicked.
   */
  @Output() previewDeleted: EventEmitter<VideoPreview> = new EventEmitter();

  sortedPreviews: VideoPreview[];

  constructor() {
  }
}

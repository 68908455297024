import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserIntegrationSettingsService} from '@domain/integration-settings/user-integration-settings.service';
import {SettingsService} from '@domain/settings/settings.service';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

interface Integration {
  key: string;
  icon: string;
  sync: boolean;
}

@Component({
  selector: 'coyo-settings-integrations',
  templateUrl: './settings-integrations.component.html',
  styleUrls: ['./settings-integrations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsIntegrationsComponent implements OnInit {
  private static readonly INTEGRATIONS: { [type: string]: Integration; } = {
    G_SUITE: {key: 'G_SUITE', icon: 'google-calendar', sync: false},
    OFFICE_365: {key: 'OFFICE_365', icon: 'o365-outlook', sync: false}
  };

  form: FormGroup;
  integration$: Observable<Integration>;

  constructor(private formBuilder: FormBuilder,
              private settingsService: SettingsService,
              private userIntegrationSettingsService: UserIntegrationSettingsService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({syncEvents: false});
    this.integration$ = combineLatest([
      this.settingsService.retrieveByKey('integrationType'),
      this.userIntegrationSettingsService.isCalendarSyncEnabled()
    ]).pipe(map(([integration, sync]) => ({...SettingsIntegrationsComponent.INTEGRATIONS[integration], sync})))
      .pipe(tap(integration => this.form.setValue({syncEvents: integration.sync})));
  }

  update(value: boolean): void {
    const req$ = value
      ? this.userIntegrationSettingsService.enableCalendarSync()
      : this.userIntegrationSettingsService.disableCalendarSync();
    req$.subscribe(() => this.onUpdateSuccess(value), () => this.onUpdateError(!value));
  }

  private onUpdateSuccess(syncEvents: boolean): void {
    this.notificationService.success('MODULE.ACCOUNT.SETTINGS.INTEGRATIONS.EVENTS.SUCCESS', null, {
      activated: syncEvents ? 'yes' : 'no'
    });
  }

  private onUpdateError(syncEvents: boolean): void {
    this.form.setValue({syncEvents});
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';

/**
 * Event date label with image component
 */
@Component({
  selector: 'coyo-event-location',
  templateUrl: './event-location.component.html',
  styleUrls: ['./event-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventLocationComponent implements OnInit {

  /**
   * The place of the event, which also can be an url.
   */
  @Input() place: string;

  /**
   * Optional flag for displaying the place multiline (e.g. used in the upcoming events widgets).
   */
  @Input() useMultiline?: boolean = false;

  /**
   * Optional flag for displaying the place twice as wide (e.g. used when no event start and end is to be displayed).
   */
  @Input() displayWide?: boolean = false;

  isUrl: boolean = false;

  constructor(private urlService: UrlService) {
    this.urlService = urlService;
  }

  ngOnInit(): void {
    this.isUrl = this.urlService.isValidUrl(this.place);
  }

  getShortenedUrl(): string {
    return this.urlService.getUrlWithoutProtocol(this.place);
  }
}

import {Injectable} from '@angular/core';
import {TimeProviderService} from '@core/time-provider/time-provider.service';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Action, State, StateContext} from '@ngxs/store';
import {BirthdayUser} from '@widgets/birthday/birthday-user';
import {BirthdayWidgetSettings} from '@widgets/birthday/birthday-widget-settings.model';
import {BirthdayService, REFERENCE_LEAP_YEAR} from '@widgets/birthday/birthday/birthday.service';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {Init, LoadMore, SetLoading} from './birthday-widget.actions';

export interface BirthdayWidgetsStateModel {
  [key: string]: BirthdayWidgetStateModel;
}

export interface BirthdayWidgetStateModel {
  dates: { birthday: Date; users: BirthdayUser[], today: boolean }[];
  loading: boolean;
  page: Page<BirthdayUser>;
}

@Injectable({providedIn: 'root'})
@State<BirthdayWidgetsStateModel>({
  name: 'birthdayWidget',
  defaults: {}
})
export class BirthdayWidgetState {

  constructor(private birthdayService: BirthdayService, private timeProviderService: TimeProviderService) {
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<BirthdayWidgetsStateModel>, action: SetLoading): void {
    ctx.patchState({
      [action.id]: {
        ...ctx.getState()[action.id],
        loading: action.loading
      }
    });
  }

  @Action(Init)
  init(ctx: StateContext<BirthdayWidgetsStateModel>, action: Init): Observable<void> {
    ctx.setState({
      ..._.omit(ctx.getState(), action.id)
    });
    const pageable = new Pageable(0, action.settings._birthdayNumber);
    return this.loadPage(pageable, action.id, action.settings, action.edit, ctx);
  }

  @Action(LoadMore)
  loadMore(ctx: StateContext<BirthdayWidgetsStateModel>, action: LoadMore): Observable<void> {
    if (!ctx.getState()[action.id] || !ctx.getState()[action.id].page) {
      return ctx.dispatch(new Init(action.settings, action.id, action.edit));
    }
    const pageable = new Pageable( ctx.getState()[action.id].page.number + 1,
      action.settings._birthdayNumber);
    return this.loadPage(pageable, action.id, action.settings, action.edit, ctx);
  }

  private loadPage(pageable: Pageable, id: string, settings: BirthdayWidgetSettings, withoutCache: boolean,
                   ctx: StateContext<BirthdayWidgetsStateModel>): Observable<void> {
    return ctx.dispatch(new SetLoading(true, id))
      .pipe(switchMap(() => this.birthdayService.getBirthdays(settings._daysBeforeBirthday,
        withoutCache ? null : id, settings._fetchBirthdays === 'FOLLOWERS', pageable)
        ),
        map(result => {
          let number = 0;
          const users = [..._.flatten(_.map(ctx.getState()[id].dates, 'users')), ...result.content];
          const obj = {
            dates: this.buildDictionary(_.map(users, user => ({...user, index: number++}))),
            page: result
          };
          return obj;
        }),
        tap(result => ctx.patchState({
          [id]: {
            ...ctx.getState()[id],
            ...result
          }
        })),
        switchMap(() => ctx.dispatch(new SetLoading(false, id))));
  }

  private buildDictionary(users: BirthdayUser[]): { birthday: Date; users: BirthdayUser[], today: boolean, minIndex: number }[] {
    const birthdayObject = _.omit(_.groupBy(users, user => this.createBirthDateWithoutYear(user.birthday)),
      'undefined');
    return _.sortBy(_.map(_.toPairs(birthdayObject), pair => ({
      birthday: new Date(pair[0]),
      users: pair[1],
      today: this.isToday(new Date(pair[0])),
      minIndex: _.minBy(pair[1], 'index').index
    })), 'minIndex');
  }

  private isToday(birthday: Date): boolean {
    const today = this.timeProviderService.getCurrentDate();
    return today.getDate() === birthday.getDate() && today.getMonth() === birthday.getMonth();
  }

  private createBirthDateWithoutYear(birthday: string): Date {
    const formattedBirthday = this.birthdayService.hasYear(birthday)
      ? REFERENCE_LEAP_YEAR + '-' + birthday.substring(birthday.length - 5, birthday.length)
      : REFERENCE_LEAP_YEAR + '-' + birthday;
    return this.timeProviderService.createDateWithoutTimezoneOffset(formattedBirthday);
  }
}

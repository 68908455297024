(function () {
  'use strict';

  subscriptionsService.$inject = ["$q", "$injector", "$rootScope", "socketService"];
  angular
      .module('commons.subscriptions')
      .factory('subscriptionsService', subscriptionsService);

  /**
   * @ngdoc service
   * @name commons.subscriptions.subscriptionsService
   * @deprecated use Angular2+ service
   *
   * @description
   * Service for handling subscriptions. It provides methods to subscribe, unsubscribe and get information about
   * subscriptions.
   */
  function subscriptionsService($q, $injector, $rootScope, socketService) {
    var ngxSubscriptionService = $injector.get('ngxSubscriptionService');

    return {
      subscribe: subscribe,
      unsubscribe: unsubscribe,
      getSubscriptions: getSubscriptions,
      getSubscriptionsByType: getSubscriptionsByType,
      onSubscriptionChange: onSubscriptionChange
    };

    /**
     * @ngdoc function
     * @name commons.subscriptions.subscriptionsService#subscribe
     * @methodOf commons.subscriptions.subscriptionsService
     *
     * @description
     * Subscribes a user to a target
     *
     * @param {string} userId The ID of the user to subscribe to the target.
     * @param {string} targetId The target ID
     * @param {string} targetType The target type
     * @param {string} senderId The sender ID
     *
     * @returns {promise} An $http promise
     */
    function subscribe(userId, targetId, targetType, senderId) {
      var deferred = $q.defer();
      ngxSubscriptionService.subscribe(senderId, targetId, targetType)
          .toPromise()
          .then(deferred.resolve)
          .catch(deferred.reject);
      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name commons.subscriptions.subscriptionsService#unsubscribe
     * @methodOf commons.subscriptions.subscriptionsService
     *
     * @description
     * Unsubscribes a user from a target. This means the subscription object is deleted.
     *
     * @param {string} userId The ID of the user to unsubscribe from the target.
     * @param {string} targetId The target ID
     *
     * @returns {promise} An $http promise
     */
    function unsubscribe(userId, targetId) {
      var deferred = $q.defer();
      ngxSubscriptionService.unsubscribe(targetId)
          .toPromise()
          .then(deferred.resolve)
          .catch(deferred.reject);
      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name commons.subscriptions.subscriptionsService#getSubscriptions
     * @methodOf commons.subscriptions.subscriptionsService
     *
     * @description
     * Returns the subscriptions of a user for given targets.
     *
     * @param {string} userId The ID of the user to check the subscription status for.
     * @param {string[]=} targetIds The IDs of the targets.
     *
     * @returns {promise} An $http promise.
     */
    function getSubscriptions(userId, targetIds) {
      var deferred = $q.defer();
      ngxSubscriptionService.getSubscriptionsById
          .apply(ngxSubscriptionService, targetIds)
          .toPromise().then(function (data) {
            deferred.resolve(_.isArray(data) ? data : (data ? [data] : []));
          }).catch(deferred.reject);
      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name commons.subscriptions.subscriptionsService#getSubscriptionsByType
     * @methodOf commons.subscriptions.subscriptionsService
     *
     * @description
     * Returns the subscriptions of a user for given target type.
     *
     * @param {string} userId The ID of the user to check the subscription status for.
     * @param {string} targetType The type name of the targets.
     *
     * @returns {promise} An $http promise.
     */
    function getSubscriptionsByType(userId, targetTypes) {
      var deferred = $q.defer();
      ngxSubscriptionService.getSubscriptionsByType
          .apply(ngxSubscriptionService, targetTypes)
          .toPromise().then(deferred.resolve)
          .catch(deferred.reject);
      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name commons.subscriptions.subscriptionsService#onSubscriptionChange
     * @methodOf commons.subscriptions.subscriptionsService
     *
     * @description
     * Determines whether a user is subscribed to the given target. Once it is resolved the passed callback is called.
     * In addition the caller is subscribed to an event that is invoked every time the subscriptions are changed.
     *
     * @param {string} userId The ID of the user to check the subscription status for.
     * @param {string} targetId The ID of the target.
     * @param {function} callback A callback method that is invoked every time the subscriptions of the user are
     * updated and contains the subscription object. It is called at least once initially.
     *
     * @returns {function} A function to unregister the event handler. This should be called when the scope is
     * destroyed.
     */
    function onSubscriptionChange(userId, targetId, callback) {
      getSubscriptions(userId, [targetId]).then(function (subscriptions) {
        callback(_.find(subscriptions, {targetId: targetId}));
      });
      return socketService.subscribe('/user/topic/updated', function () {
        getSubscriptions(userId, [targetId]).then(function (subscriptions) {
          callback(_.find(subscriptions, {targetId: targetId}));
        });
      }, 'subscriptionsUpdated');
    }
  }

})();

import {StickyExpiryPeriod} from './sticky-expiry-period';

/**
 * Predefined sticky expiry periods.
 */
export class StickyExpiryPeriods {

  static oneDay: StickyExpiryPeriod = {
    name: 'oneDay',
    expiry: 1000 * 60 * 60 * 24,
    label: 'MODULE.TIMELINE.STICKY.EXPIRY.ONE_DAY',
  };

  static oneWeek: StickyExpiryPeriod = {
    name: 'oneWeek',
    expiry: 1000 * 60 * 60 * 24 * 7,
    label: 'MODULE.TIMELINE.STICKY.EXPIRY.ONE_WEEK',
  };

  static oneMonth: StickyExpiryPeriod = {
    name: 'oneMonth',
    expiry: 1000 * 60 * 60 * 24 * 30,
    label: 'MODULE.TIMELINE.STICKY.EXPIRY.ONE_MONTH',
  };

  static none: StickyExpiryPeriod = {
    name: 'none',
    expiry: null,
    label: 'MODULE.TIMELINE.STICKY.EXPIRY.NONE',
  };

  static all: StickyExpiryPeriod[] = [
    StickyExpiryPeriods.oneDay,
    StickyExpiryPeriods.oneWeek,
    StickyExpiryPeriods.oneMonth,
    StickyExpiryPeriods.none
  ];
}

import {InjectionToken} from '@angular/core';
import {PluginService} from '@domain/plugin/plugin.service';
import {IframeMessage} from '@shared/iframe/iframe-message';
import {PluginWidgetSettings} from '@widgets/plugin/plugin-widget-settings.model';
import {PluginWidgetComponent} from '@widgets/plugin/plugin-widget/plugin-widget.component';
import {Observable} from 'rxjs';

/**
 * An injection token to provide plugin event handlers.
 */
export const PLUGIN_EVENT_HANDLERS: InjectionToken<PluginEventHandler<any>[]> =
  new InjectionToken<PluginEventHandler<any>[]>('PLUGIN_EVENT_HANDLERS');

/**
 * A handler for events sent by a plugin.
 */
export abstract class PluginEventHandler<T extends IframeMessage> {
  static readonly ERROR_TOPIC: string = 'error';

  /**
   * Convenience method to access the plugin ID.
   *
   * @param pluginComponent an instance of the plugin component
   * @return the plugin ID
   */
  protected getPluginId(pluginComponent: PluginWidgetComponent): string {
    return PluginService.getPluginId(pluginComponent.config.key);
  }

  /**
   * Convenience method to access the plugin settings.
   *
   * @param pluginComponent an instance of the plugin component
   * @return the plugin settings
   */
  protected getPluginSettings(pluginComponent: PluginWidgetComponent): PluginWidgetSettings {
    return pluginComponent.widget.settings;
  }

  /**
   * Checks if this event handler can handle the given subject.
   *
   * @param sub the subject of the event
   * @return true if the handler can handle the subject, else false
   */
  abstract canHandle(sub: string): boolean;

  /**
   * Handles an event sent by a plugin.
   *
   * @param pluginComponent the instance of the plugin component
   * @param event the event
   */
  abstract handle(pluginComponent: PluginWidgetComponent, event: T): Observable<string | void>;
}

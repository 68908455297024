import {NgModule} from '@angular/core';
import {TimelineModule} from '@app/timeline/timeline.module';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {PERSONAL_TIMELINE_WIDGET} from './personal-timeline-widget-config';
import {PersonalTimelineWidgetComponent} from './personal-timeline-widget/personal-timeline-widget.component';

/**
 * Personal timeline widget module
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    TimelineModule
  ],
  declarations: [
    PersonalTimelineWidgetComponent
  ],
  entryComponents: [
    PersonalTimelineWidgetComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: PERSONAL_TIMELINE_WIDGET, multi: true}
  ]
})
export class PersonalTimelineWidgetModule {}

(function (angular) {
  'use strict';

  RoleModel.$inject = ["$q", "restResourceFactory", "coyoEndpoints"];
  angular
      .module('coyo.domain')
      .factory('RoleModel', RoleModel);

  /**
   * @ngdoc service
   * @name coyo.domain.RoleModel
   *
   * @description
   * Provides the Coyo role model.
   *
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   */
  function RoleModel($q, restResourceFactory, coyoEndpoints) {
    var Role = restResourceFactory({
      url: coyoEndpoints.user.roles
    });

    // class members
    angular.extend(Role, {

      /**
       * @ngdoc function
       * @name coyo.domain.RoleModel#permissionConfiguration
       * @methodOf coyo.domain.RoleModel
       *
       * @description
       * Configures the permissions.
       *
       * @returns {promise} An $http promise
       */
      permissionConfiguration: function () {
        return this.$http({
          method: 'GET',
          url: this.$url('permission-groups')
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.RoleModel#getRolesForGroups
       * @methodOf coyo.domain.RoleModel
       *
       * @description
       * Requests all roles that are assigned to the given groups.
       *
       * @param {array} groupIds array of group ids
       * @returns {promise} An $http promise
       */
      getRolesForGroups: function (groupIds) {
        var url = Role.$url('groups');
        if (groupIds.length) {
          return Role.$get(url, {groupIds: groupIds}).then(function (data) {
            return _.map(data, function (role) {
              return new Role(role);
            });
          });
        } else {
          return $q.resolve([]);
        }
      }
    });

    return Role;
  }

})(angular);

import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Comment} from '@domain/comment/comment';
import {CommentService} from '@domain/comment/comment.service';
import {Ng1AuthService} from '@root/typings';
import {NG1_AUTH_SERVICE} from '@upgrade/upgrade.module';
import * as _ from 'lodash';
import {BehaviorSubject, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';

/**
 * Component display the context menu of comments
 */
@Component({
  selector: 'coyo-comment-context-menu',
  templateUrl: './comment-context-menu.component.html',
  styleUrls: ['./comment-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentContextMenuComponent implements OnInit {
  private currentUserId: string;
  private showOriginalAuthor: boolean;

  /**
   * The comment to show the context menu for
   */
  @Input() comment: Comment;

  /**
   * If the comment is editable based on on user permission criteria.
   * This could be true for example if the comment is the last one in the list.
   */
  @Input() editable: boolean;

  /**
   * Emits if show author is clicked
   */
  @Output() showAuthor: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Emits if edit is clicked
   */
  @Output() edit: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits if delete is clicked
   */
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits when the comment is changed
   */
  @Output() changed: EventEmitter<Comment> = new EventEmitter<Comment>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(NG1_AUTH_SERVICE) private authService: Ng1AuthService,
              private commentService: CommentService) {
  }

  ngOnInit(): void {
    this.currentUserId = this.authService.getCurrentUserId();
  }

  get canShowMenu(): boolean {
    return this.comment.partiallyLoaded || this.canShowMenuOptions;
  }

  get canShowMenuOptions(): boolean {
    return this.canShowOriginalAuthor || this.canShowEdit || this.canShowDelete;
  }

  get canShowOriginalAuthor(): boolean {
    return this.comment._permissions.accessoriginalauthor && this.comment.author.id !== this.currentUserId;
  }

  get canShowEdit(): boolean {
    return this.editable && this.comment._permissions.edit;
  }

  get canShowDelete(): boolean {
    return this.comment._permissions.delete;
  }

  /**
   * Emits the show original author event and toggles the flag
   */
  onShowOriginalAuthor(): void {
    this.showOriginalAuthor = !this.showOriginalAuthor;
    this.showAuthor.emit(this.showOriginalAuthor);
  }

  /**
   * Loads the comment's permissions when it was sent via web sockets.
   */
  onOpen(): void {
    if (this.comment.partiallyLoaded && (!this.comment._permissions || !_.keys(this.comment._permissions).length)) {
      this.loading$.next(true);
      const options = {params: {_permissions: '*'}, handleErrors: false};
      this.commentService.get(this.comment.id, options)
        .pipe(map(comment => {
          comment.partiallyLoaded = true;
          this.loading$.next(false);
          this.changed.emit(comment);
          return comment;
        }))
        .pipe(catchError(() => {
          this.loading$.next(false);
          return of(this.comment);
        })).pipe(take(1)).subscribe();
    }
  }
}

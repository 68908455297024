import {ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Sender} from '@domain/sender/sender';
import {TargetService} from '@domain/sender/target/target.service';
import {EMPTY, Observable} from 'rxjs';
import {AvatarSize} from '../avatar-image/avatar-image.component';

/**
 * Presenter component to render the sender avatar
 * Renders the right avatar for the given sender type.
 */
@Component({
  selector: 'coyo-sender-avatar',
  templateUrl: './sender-avatar.component.html',
  styleUrls: ['./sender-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderAvatarComponent implements OnInit, OnChanges {

  /**
   * The sender to render the avatar for.
   * Changes by mutating this sender object will not be detected by this component.
   */
  @Input() sender: Sender;

  /**
   * If set to true a click on the avatar will not link to the sender view.
   */
  @Input() noLink: boolean;

  /**
   * The size of the avatar.
   */
  @Input() avatarSize: AvatarSize = 'md';

  /**
   * Shows the external flag if the sender is external.
   */
  @Input() showExternalFlag: boolean;

  $senderLink: Observable<string>;

  hasError: boolean;

  constructor(@Inject(TargetService) private targetService: TargetService) {}

  ngOnInit(): void {
    this.setLink();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setLink();
  }

  private setLink(): void {
    this.$senderLink = this.noLink ? EMPTY : this.targetService.getLinkForCurrentUser(this.sender.target);
  }
}

import {File as FileModel} from '@domain/file/file';
import {FileUploadEvent} from './file-upload-event';

/**
 * Event that is emitted when a FileUpload has finished uploading a file
 */
export class FileUploadFileFinishedEvent extends FileUploadEvent {
  constructor(files: FileList | File[], readonly fileId: string, readonly fileIndex: number, readonly file: FileModel, readonly successfulUploads: number) {
    super(files);
  }
}

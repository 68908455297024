import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';

// Do not change the state names
export const STATE_NAME_ENGAGE = 'engage';
export const STATE_NAME_ENGAGE_INIT = STATE_NAME_ENGAGE + '.init';

export const engageState: NgHybridStateDeclaration = {
  name: STATE_NAME_ENGAGE,
  url: '/engage?clientId&userId&backendUrl', // Do not change the URL
  data: {
    authenticate: true
  },
  params: {
    clientId: {type: 'query'},
    userId: {type: 'query'},
    backendUrl: {type: 'query'}
  }
};

export const engageStateInit: NgHybridStateDeclaration = {
  name: STATE_NAME_ENGAGE_INIT,
  url: '/init' // Do not change the URL
};

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Bookmark} from '@widgets/bookmarking/bookmark.model';
import * as _ from 'lodash';

@Component({
  selector: 'coyo-bookmarking-show',
  templateUrl: './bookmarking-show.component.html',
  styleUrls: ['./bookmarking-show.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkingShowComponent {

  /**
   * The bookmark to show.
   */
  @Input() bookmark: Bookmark;

  get target(): string {
    return this.isRelativeUrl() ? '_self' : '_blank';
  }

  private isRelativeUrl(): boolean {
    return _(this.bookmark.url).startsWith('/');
  }
}

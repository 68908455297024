import {Injectable} from '@angular/core';
import {v4 as uuid} from 'uuid';

/**
 * A service providing UUID generation
 */
@Injectable({
  providedIn: 'root'
})
export class UuidService {

  /**
   * Get's a UUID
   *
   * @return A UUID
   */
  getUuid(): string {
    return uuid();
  }
}

<ng-container mat-dialog-float>
  <h3 mat-dialog-title>{{'WIDGETS.WIDGET_CHOOSER.TITLE' | translate}}</h3>
  <mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
  <form [formGroup]="widgetForm" class="form-horizontal" name="widgetForm" mat-dialog-content>
    <div *ngIf="!config">
      <input type="search" data-test="widget-search" class="form-control input-sm widget-filter"
             [formControl]="searchInput"
             [attr.placeholder]="'WIDGETS.WIDGET_CHOOSER.SEARCH' | translate"
             [attr.aria-label]="'WIDGETS.WIDGET_CHOOSER.SEARCH' | translate">
      <tabset *ngIf="(tabConfigs$ | async) as tabConfigs; else loading">
        <tab *ngFor="let pair of tabConfigs"
             [heading]="'WIDGETS.WIDGET_CHOOSER.CATEGORY.' + pair[0] | uppercase | translate">
          <ul class="widget-list" *ngIf="pair[1].length">
            <li class="widget-item" *ngFor="let config of pair[1]; last as isLast">
              <button type="button"
                      (click)="select(config)"
                      [attr.data-test]="'widget-' + config.key"
                      [attr.aria-label]="config.description ? (config.name + ': ' + config.description) : config.name">
                <i class="zmdi" [ngClass]="config.icon" aria-hidden="true" [matTooltip]="config.description"></i>
                <span><mat-icon class="mobile-icon" *ngIf="isMobileSupported(config.mobileSupportTypes)" size="small" svgIcon="mobile_on" [matTooltip]="'WIDGETS.TOOLTIPS.SUPPORT.MOBILE' | translate"
                                [aria-label]="'WIDGETS.TOOLTIPS.SUPPORT.MOBILE'  | translate"></mat-icon>{{config.name}}</span>
              </button>
              <hr class="hidden-md-and-up app-divider" *ngIf="!isLast">
            </li>
          </ul>
          <div *ngIf="!pair[1].length" class="widget-list-empty">
            {{'WIDGETS.WIDGET_CHOOSER.EMPTY' | translate}}
          </div>
        </tab>
      </tabset>
      <ng-template #loading>
        <tabset>
          <tab [heading]="'WIDGETS.WIDGET_CHOOSER.CATEGORY.ALL' | translate">
            <div class="loading-container">
              <div class="coyo-spinner lg" [title]="'WIDGETS.WIDGET_CHOOSER.LOADING' | translate">...</div>
            </div>
          </tab>
        </tabset>
      </ng-template>
    </div>
    <div *ngIf="config?.settings">
      <coyo-widget-settings-container [widget]="widget" [component]="config.settings.component" [parentForm]="widgetForm" [onSubmit]="onSubmitSubject"></coyo-widget-settings-container>
    </div>
  </form>
  <mat-dialog-actions *ngIf="config">
    <button formName="widgetForm" mat-button color="default" [attr.aria-label]="'BACK' | translate" (click)="onBack()">{{'BACK' | translate}}</button>
    <button formName="widgetForm" mat-flat-button color="accent" [attr.aria-label]="'SAVE' | translate" (click)="submit(widgetForm.value)" [disabled]="widgetForm.invalid || ng1WidgetForm?.$invalid">{{'SAVE' | translate}}</button>
  </mat-dialog-actions>
</ng-container>

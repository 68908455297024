<div class="panel panel-default">
  <div class="panel-heading">
    <h1 class="panel-title">{{'ADMIN.APPS_WIDGETS.WIDGETS.TITLE' | translate}}</h1>
  </div>
  <div class="panel-body">
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th>{{'ADMIN.APPS_WIDGETS.WIDGETS.HEADERS.WIDGET' | translate}}</th>
        <th class="text-center" translate="ADMIN.APPS_WIDGETS.WIDGETS.HEADERS.ENABLED"></th>
        <th class="text-center" translate="ADMIN.APPS_WIDGETS.WIDGETS.HEADERS.RESTRICTED"></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="widgetList$ | async; let widgets; else loading">
        <tr *ngFor="let widget of widgets; trackBy: trackByKey">
          <td>
            {{widget.name | translate }}<br>
            <small class="text-muted hidden-xs">{{widget.description | translate }}</small>
          </td>
          <td class="text-center">
            <mat-checkbox [checked]="widget.config.enabled" (change)="setEnabled(widget.config, $event)"
                          [disabled]="updating$ | async | contains : widget.config.key">
            </mat-checkbox>
          </td>
          <td class="text-center">
            <mat-checkbox [checked]="widget.config.moderatorsOnly" (change)="setModeratorsOnly(widget.config, $event)"
                          [disabled]="updating$ | async | contains : widget.config.key">
            </mat-checkbox>
          </td>
        </tr>
      </ng-container>
      <ng-template #loading>
        <tr *ngFor="let i of skeletonCount">
          <td colspan="3">
            <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
          </td>
        </tr>
      </ng-template>
      </tbody>
    </table>
  </div>
</div>

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogSize} from '@coyo/ui';
import {ConfirmationDialogData} from '@shared/dialog/confirmation-dialog/confirmation-dialog-data';
import {ConfirmationDialogComponent} from '@shared/dialog/confirmation-dialog/confirmation-dialog.component';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private dialog: MatDialog) {
  }

  open(title: string, body: string, translationContext?: any): Observable<boolean> {
    return this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>
    (ConfirmationDialogComponent, {
      width: MatDialogSize.Small,
      data: {
        title,
        body,
        translationContext
      }
    }).afterClosed();
  }
}

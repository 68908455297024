import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {BlogArticle} from '@domain/blog-article/blog-article';
import {Sender} from '@domain/sender/sender';
import {TargetService} from '@domain/sender/target/target.service';
import {TimelineItemService} from '@domain/timeline-item/timeline-item.service';
import {TimelineItemContentComponent} from '../timeline-item-content.component';

/**
 * Timeline share item for blog articles.
 */
@Component({
  selector: 'coyo-blog-share-article',
  templateUrl: './blog-share-article.component.html',
  styleUrls: ['../share-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogShareArticleComponent extends TimelineItemContentComponent implements OnInit {

  /**
   * The shared blog article
   */
  article: BlogArticle;

  articleLink: string;
  authorIcon: string;
  isMobile: boolean;
  author: Sender;

  constructor(cd: ChangeDetectorRef,
              private timelineItemService: TimelineItemService,
              private targetService: TargetService,
              private windowSizeService: WindowSizeService) {
    super(cd);
  }

  ngOnInit(): void {
    this.article = this.item.data.article;
    this.isMobile = this.windowSizeService.isXs();
    this.articleLink = this.article.articleTarget ? this.targetService.getLinkTo(this.article.articleTarget) : null;
    this.author = this.article.author || this.item.author;
    this.authorIcon = this.timelineItemService.getAuthorIcon(this.author);
  }
}

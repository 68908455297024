import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import {EventVisibility} from '@app/events/event-create/event-create-menu/event-visibility';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'coyo-event-create-menu',
  templateUrl: './event-create-menu.component.html',
  styleUrls: ['./event-create-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventCreateMenuComponent implements OnInit {

  /**
   * If true the buttons for the event app are displayed and a flag 'calledFromAppView' will be set.
   */
  @Input()
  isAppView?: boolean = false;

  /**
   * A given sender/host id for sender initialization on event create form.
   */
  @Input()
  hostId?: string;

  isMobile$: Observable<boolean>;

  readonly eventVisibilities: EventVisibility[] = [{
    name: 'PUBLIC', icon: 'globe'
  }, {
    name: 'PRIVATE', icon: 'lock'
  }];

  constructor(@Inject(NG1_STATE_SERVICE) private stateService: IStateService,
              private windowSizeService: WindowSizeService) { }

  ngOnInit(): void {
    this.isMobile$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS || screenSize === ScreenSize.SM));
  }

  /**
   * Called after a button was clicked, representing the event visibility.
   * @param visibility the event visibility object
   */
  redirectToEventCreate(visibility: EventVisibility): void {
    const params = {
      public: visibility.name === 'PUBLIC'
    } as any;
    if (this.hostId) {
      params.host = this.hostId;
    }
    if (this.isAppView) {
      params.lockHost = true;
    }
    this.stateService.go('main.event.create', params);
  }
}

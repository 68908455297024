import {CropSettings} from '@app/file-picker/crop-settings';
import {FilePickerSelectionMode} from '@app/file-picker/file-picker-selection-mode';
import {FilePickerSelectionType} from '@app/file-picker/file-picker-selection/file-picker-selection-type';
import {Document} from '@domain/file/document';
import {File as FileModel} from '@domain/file/file';

export const DEFAULT_FILE_LIBRARY_DATA: FileLibraryFilePickerData = {
  selectedFiles: [],
  selectionType: 'multiple',
  contentTypes: []
};

/**
 * Data object to open the file library in the file picker
 */
export interface FileLibraryFilePickerData {
  /**
   * Previously selected files that should be preselected.
   */
  selectedFiles?: Document[];

  /**
   * Optional - The selection mode of the file picker. If 'download' is chosen a button for downloading the selected files is shown,
   * otherwise the selected files are returned and the dialog is closed. Default is 'select'.
   */
  selectionMode?: FilePickerSelectionMode;

  /**
   * Selection type. All modes will return an array of Documents.
   */
  selectionType?: FilePickerSelectionType;

  /**
   * Regex array that will be tested upon the file types, if one of them matches
   * the file will be selectable.
   */
  contentTypes?: string[];

  /**
   * Legacy filter content type
   * @deprecated Don't use this in new components
   */
  filterContentType?: string;

  /**
   * The initially opened folder
   */
  initialFolder?: FileModel;

  /**
   * Flag if authors should be shown
   */
  showAuthors?: boolean;

  /**
   * Settings for image cropping
   */
  cropSettings?: CropSettings;

  /**
   * Files to upload on opening the file library
   */
  upload?: FileList | File[];
}

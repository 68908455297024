(function (angular) {
  'use strict';

  ChampionshipAppController.$inject = ["app"];
  angular
      .module('coyo.apps.championship')
      .controller('ChampionshipAppController', ChampionshipAppController);

  function ChampionshipAppController(app) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.app = app;
    }
  }

})(angular);

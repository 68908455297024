import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MenuPositionX, MenuPositionY} from '@angular/material/menu';
import {GDrivePickerFile} from '@app/integration/gsuite/g-drive-picker/g-drive-picker-file';
import {GDrivePickerService} from '@app/integration/gsuite/g-drive-picker/g-drive-picker.service';
import {GoogleApiService} from '@app/integration/gsuite/google-api/google-api.service';
import {O365ApiService} from '@app/integration/o365/o365-api/o365-api.service';
import {SharePointFilePickerService} from '@app/integration/o365/share-point-file-picker/share-point-file-picker.service';
import {AuthService} from '@core/auth/auth.service';
import {UrlService} from '@core/http/url/url.service';
import {GSUITE, LOCAL_BLOB, OFFICE365} from '@domain/attachment/storage';
import {Document} from '@domain/file/document';
import {Sender} from '@domain/sender/sender';
import {Ng1CsrfService} from '@root/typings';
import {FileUploadResponse} from '@shared/files/attachment-btn/types/file-upload-response';
import {TimelineFormAttachment} from '@shared/files/attachment-btn/types/timeline-form-attachment';
import {TimelineFormCoyoLibraryAttachment} from '@shared/files/attachment-btn/types/timeline-form-coyo-library-attachment';
import {SelectFileDialogService} from '@shared/select-file/select-file-dialog/select-file-dialog.service';
import {NG1_CSRF_SERVICE} from '@upgrade/upgrade.module';
import * as _ from 'lodash';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {BehaviorSubject, forkJoin, from, Observable, of, Subject, Subscription} from 'rxjs';
import {filter, flatMap, map, switchMap} from 'rxjs/operators';

const valueAccessor = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AttachmentBtnComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

/**
 * Timeline attachment button component.
 */
@Component({
  selector: 'coyo-attachment-btn',
  templateUrl: './attachment-btn.component.html',
  styleUrls: ['./attachment-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor]
})
export class AttachmentBtnComponent implements OnInit, ControlValueAccessor, OnDestroy {

  /**
   * The sender of the timeline post.
   */
  @Input() sender: Sender;

  /**
   * The timeline type.
   */
  @Input() timelineType: 'personal' | 'sender';

  /**
   * The context.
   */
  @Input() context: Sender;

  /**
   * Observable emitting dropped files
   */
  @Input() droppedFiles: Observable<File[]>;

  /**
   * The horizontal placement of the attachment context menu.
   */
  @Input() xPosition: MenuPositionX = 'after';

  /**
   * The vertical placement of the attachment context menu.
   */
  @Input() yPosition: MenuPositionY = 'below';

  /**
   * Output emitting when an upload starts(true) and all uploads are finished(false)
   */
  @Output() uploading: EventEmitter<boolean> = new EventEmitter<boolean>();

  uploader: FileUploader;
  googleApiActivated: Subject<boolean> = new BehaviorSubject(false);
  o365ApiActivated: Subject<boolean> = new BehaviorSubject(false);
  fileLibraryActivated: boolean;
  allSelectedAttachments: (FileItem | TimelineFormAttachment | TimelineFormCoyoLibraryAttachment)[] = [];

  private onChangeFn: (param: FileItem[] | TimelineFormAttachment[] | TimelineFormCoyoLibraryAttachment[]) => void;

  private droppedFilesSubscription: Subscription;

  constructor(private urlService: UrlService,
              private o365ApiService: O365ApiService,
              private sharePointFilePicker: SharePointFilePickerService,
              private gDrivePickerService: GDrivePickerService,
              private googleApiService: GoogleApiService,
              private ngZone: NgZone,
              private authService: AuthService,
              @Inject(NG1_CSRF_SERVICE) private csrfService: Ng1CsrfService,
              private selectFileDialogService: SelectFileDialogService) {
  }

  ngOnInit(): void {
    this.googleApiService.isGoogleApiActive().subscribe(active => this.googleApiActivated.next(active));
    this.o365ApiService.isApiActive()
      .pipe(switchMap(isActive => isActive ? this.o365ApiService.getTokenClaims() : of(null)))
      .subscribe(tokenClaims => {
        this.o365ApiActivated.next(!!tokenClaims && tokenClaims.scp.indexOf('Sites.Read.All') !== -1);
      });

    this.authService.getUser().subscribe(user => {
      this.fileLibraryActivated = user.globalPermissions.includes('ACCESS_FILES');
    });

    // init file uploader
    this.csrfService.getToken().then(csrfToken => {
      this.uploader = new FileUploader({
        url: this.urlService.getBackendUrl() + '/web/uploads/temp',
        method: 'POST',
        headers: [{name: 'X-CSRF-TOKEN', value: csrfToken}],
        additionalParameter: {
          for: 500
        }
      });

      this.uploader.onSuccessItem = (file: FileItem, response: string) => {
        this.addFileUpload(file, response);
      };

      this.uploader.onErrorItem = (file: FileItem, response: string, status: number) => {
        this.allSelectedAttachments = _.reject(this.allSelectedAttachments, elem => elem === file);
      };

      this.uploader.onBeforeUploadItem = () => {
        this.uploading.emit(true);
      };

      this.uploader.onCompleteAll = () => {
        this.uploading.emit(false);
      };
    });

    if (this.droppedFiles) {
      this.droppedFilesSubscription = this.droppedFiles.subscribe(file => {
        this.ngZone.run(() => {
          this.uploader.addToQueue(file);
          this.uploadFiles();
        });
      });
    }
  }

  /**
   * Uploads selected files from local hdd.
   */
  uploadFiles(): void {
    this.uploader.queue.forEach((fileItem: FileItem) => {
      if (!fileItem.isUploading && !fileItem.isUploaded) {
        fileItem.upload();
        this.allSelectedAttachments = [...this.allSelectedAttachments, fileItem];
      }
    });
    this.updateSelectedFilesOutput();
  }

  /**
   * Opens the coyo file library modal.
   */
  openCoyoFileLibrary(): void {
    this.ngZone.run(() => {
      this.selectFileDialogService
        .open(this.timelineType === 'personal' ? this.sender : this.context, {selectMode: 'multiple'}, {})
        .subscribe((selectedCoyoFileLibraryFiles: Document[]) => {
          const attachments: TimelineFormCoyoLibraryAttachment[] = _.map(selectedCoyoFileLibraryFiles, file =>
            ({
              fileId: file.id,
              senderId: file.senderId,
              name: file.displayName,
              displayName: file.displayName,
              storage: 'LOCAL_FILE_LIBRARY'
            }));
          this.allSelectedAttachments = [...this.allSelectedAttachments, ...attachments];
          this.updateSelectedFilesOutput();
        });
    });
  }

  /**
   * Opens the google suite file picker.
   */
  openGSuitePicker(): void {
    this.gDrivePickerService
      .open()
      .then((selectedGoogleDriveFiles: GDrivePickerFile[]) => {

        const attachments: TimelineFormAttachment[] = _.map(selectedGoogleDriveFiles, file =>
          ({
            uid: file.id,
            name: file.displayName,
            displayName: file.displayName,
            contentType: file.mimeType,
            storage: GSUITE,
            visibility: null
          })
        );

        forkJoin(attachments.map(attachment => this.fetchVisibilityForGDriveFile(attachment)
          .pipe(map(visible => ({id: attachment.uid, visible})))
        )).subscribe(fileVisibilities => {
          fileVisibilities.forEach(fileVisibility => {
            const attachment = _.head(_.filter(attachments, {uid: fileVisibility.id}));
            attachment.visibility = fileVisibility.visible ? 'PUBLIC' : 'PRIVATE';
          });
          this.allSelectedAttachments = [...this.allSelectedAttachments, ...attachments];
          this.updateSelectedFilesOutput();
        });
      });
  }

  /**
   * Opens the SharePoint file picker.
   */
  openSharePointOnlinePicker(): void {
    this.sharePointFilePicker.openFilePicker().pipe(
      filter(items => items && items.length > 0),
      flatMap(item => item),
      map(item => ({
          uid: item.id,
          name: item.name,
          displayName: item.name,
          contentType: item.mimeType,
          storage: OFFICE365,
          visibility: null
        })
      )).subscribe({
      next: (attachment: TimelineFormAttachment) => this.allSelectedAttachments = [...this.allSelectedAttachments, attachment],
      complete: () => this.updateSelectedFilesOutput()
    });
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: (param: FileItem[] | TimelineFormAttachment[] | TimelineFormCoyoLibraryAttachment[]) => void):
    void {
    this.onChangeFn = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: (param: FileItem[] | TimelineFormAttachment[] | TimelineFormCoyoLibraryAttachment[]) => void):
    void {
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(value: FileItem[] | TimelineFormAttachment[] | TimelineFormCoyoLibraryAttachment[]): void {
    this.allSelectedAttachments = value || [];
  }

  ngOnDestroy(): void {
    if (this.droppedFilesSubscription && !this.droppedFilesSubscription.closed) {
      this.droppedFilesSubscription.unsubscribe();
    }
    this.googleApiActivated.complete();
    this.o365ApiActivated.complete();
  }

  private updateSelectedFilesOutput(): void {
    this.onChangeFn(_.uniqWith(this.allSelectedAttachments, _.isEqual));
  }

  private addFileUpload(file: FileItem, response: string): void {
    const idx = _.findIndex(this.allSelectedAttachments, file);
    if (idx > -1) {
      const res: FileUploadResponse = JSON.parse(response);
      this.allSelectedAttachments[idx] = {
        uid: res.uid,
        contentType: res.contentType,
        name: file.file.name,
        displayName: file.file.name,
        storage: LOCAL_BLOB,
        visibility: 'PUBLIC'
      };
      this.updateSelectedFilesOutput();
    }
  }

  private fetchVisibilityForGDriveFile(file: TimelineFormAttachment): Observable<boolean> {
    return from(this.googleApiService.isFilePublicVisible(file.uid).catch(() => false));
  }
}

import {Component} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldType} from '@ngx-formly/material/form-field';

/**
 * A generic ngx-formly selection field.
 */
@Component({
  selector: 'coyo-formly-select',
  template: `
    <ng-select
      class="mat-ng-select"
      bindLabel="label"
      bindValue="value"
      [items]="to.options | formlySelectOptions:field | async"
      [id]="id"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      [tabIndex]="to.tabindex"
      [required]="to.required"
      [clearable]="to.clearable && !to.required"
      [multiple]="to.multiple"
      [searchable]="to.searchable"
      [readonly]="to.readonly"
      [appearance]="to.appearance"
      [appendTo]="to.appendTo"
      [errorStateMatcher]="errorStateMatcher">
      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{ item.label }}
        <div *ngIf="to.options[index].description" class="mat-hint">{{ to.options[index].description }}</div>
      </ng-template>
    </ng-select>`
})
export class FormlySelectComponent extends FieldType {

  defaultOptions: FormlyFieldConfig = {
    templateOptions: {
      appearance: 'outline',
      appendTo: 'body',
      options: [],
      clearable: true,
      multiple: false,
      searchable: false,
      readonly: false
    }
  };
}

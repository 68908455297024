import {Pipe, PipeTransform} from '@angular/core';
import {Category} from '@app/filter/category-filter/category';

@Pipe({
  name: 'isActive',
  pure: false
})
export class IsActivePipe implements PipeTransform {

  /**
   * Determines whether the current category is active or not.
   *
   * @param filterModel the current selected filter entries
   * @param category the requested category
   *
   * @return true if the filterModel contains the category else false
   */
  transform(filterModel: string[], category: Category): boolean {
    return category ? filterModel.indexOf(category.id) !== -1 : filterModel.length === 0;
  }

}

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '@core/auth/auth.service';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {MatDialogSize} from '@coyo/ui';
import {UserService} from '@domain/user/user.service';
import {environment} from '@env/environment';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, of, Subject, timer} from 'rxjs';
import {debounce, distinctUntilChanged, filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import Shepherd from 'shepherd.js';
import {ShepherdService} from '../shepherd/shepherd.service';
import {TourDialogComponent} from '../tour-dialog/tour-dialog.component';
import {TourStep} from '../tour-step';

/**
 * A service to manage the COYO introduction tour.
 */
@Injectable({
  providedIn: 'root'
})
export class TourService {
  private static readonly DEBOUNCE: number = 500;
  private static readonly SEPARATOR: string = '--';

  private tour: Shepherd.Tour;
  private tourStart$: Subject<{ topic: string | null, force: boolean }>; // triggers the start of the tour
  private tourSteps$: BehaviorSubject<TourStep[]>; // registered tour steps
  private tourVisitedNew$: Subject<string[]>; // emits topics the user has seen in the current tour
  private tourVisitedOld$: Observable<string[]>; // emits topics the user has seen in old tours

  constructor(private ngZone: NgZone,
              private shepherdService: ShepherdService,
              private authService: AuthService,
              private userService: UserService,
              private dialog: MatDialog,
              private windowSizeService: WindowSizeService,
              private translateService: TranslateService,
              @Inject(DOCUMENT) private document: Document) {
    this.tour = null;
    this.tourStart$ = new Subject<{ topic: string | null, force: boolean }>();
    this.tourSteps$ = new BehaviorSubject<TourStep[]>([]);
    this.tourVisitedNew$ = new Subject<string[]>();
    this.tourVisitedOld$ = this.authService.getUser$()
      .pipe(map(user => user.tourData?.visited || []));

    // mark tour steps as visited
    this.tourVisitedNew$
      .pipe(filter(steps => !!steps.length))
      .pipe(withLatestFrom(this.tourVisitedOld$))
      .pipe(map(([topicsNew, topicsOld]) => Array.from(new Set(topicsOld.concat(topicsNew)))))
      .pipe(switchMap(topics => this.userService.setVisitedTourTopics(...topics)))
      .subscribe();

    // start the tour if requested
    this.tourStart$
      .pipe(debounce(config => timer(config.force ? 0 : TourService.DEBOUNCE)))
      .pipe(withLatestFrom(this.tourSteps$, this.tourVisitedOld$))
      .pipe(map(([config, steps, visited]) => steps.filter(step =>
        (!config.topic || step.topic === config.topic) && // topic is requested
        (config.force || environment.autoStartTour && visited.indexOf(step.topic) === -1) && // topic is unvisited
        !this.isTourElementHidden(step) // step is visible
      )))
      .pipe(filter(steps => steps.length && !this.isActive() && this.windowSizeService.getScreenSize() >= ScreenSize.MD))
      .subscribe(steps => {
        this.tour = this.buildTour(steps);
        this.tour.start();
      });

    // close tour on mobile
    this.windowSizeService.observeScreenChange$()
      .pipe(filter(size => size < ScreenSize.MD))
      .subscribe(() => this.stopTour());
  }

  /**
   * Checks if a tour is currently active
   *
   * @returns true if a tour is active, otherwise false
   */
  isActive(): boolean {
    return this.tour !== null;
  }

  /**
   * Starts the tour.
   *
   * @param topic show only steps for this topic
   * @param force show the tour even if the user has already seen it
   */
  startTour(topic?: string, force: boolean = false): void {
    this.tourStart$.next({topic, force});
  }

  /**
   * Stops the current tour.
   */
  stopTour(): void {
    if (this.isActive()) {
      this.tour.cancel();
    }
  }

  /**
   * Opens a selection dialog to restart a tour or immediately
   * start the tour if only one topic is registered.
   */
  restartTour(): void {
    this.ngZone.run(() => {
      this.getTopics$()
        .pipe(take(1))
        .pipe(filter(topics => !!topics.length))
        .pipe(switchMap(topics => topics.length === 1
          ? of(topics[0])
          : this.dialog.open<TourDialogComponent, string[], string>(TourDialogComponent, {
            autoFocus: true,
            width: MatDialogSize.Small,
            data: topics
          }).afterClosed()))
        .pipe(filter(topic => !!topic))
        .subscribe(topic => this.startTour(topic, true));
    });
  }

  /**
   * Returns an observable of distinct topics for the tour.
   *
   * @returns an observable of topics
   */
  getTopics$(): Observable<string[]> {
    return this.tourSteps$.asObservable()
      .pipe(map(steps => Array.from(new Set(steps
        .filter(step => !this.isTourElementHidden(step))
        .map(step => step.topic)))))
      .pipe(distinctUntilChanged((s1, s2) => s1.length === s2.length && s1.every(s => s2.includes(s))));
  }

  /**
   * Registers a new tour step.
   *
   * @param step the step to add
   * @returns true if the step was added, otherwise false
   */
  addStep(step: TourStep): boolean {
    const steps = this.tourSteps$.value;
    const idx = steps.findIndex(s => s.name === step.name && s.topic === step.topic);
    if (idx === -1) {
      this.tourSteps$.next(steps.concat(step).sort((step1, step2) => step1.order - step2.order));
      return true;
    }
    return false;
  }

  /**
   * Unregisters a tour step.
   *
   * @param topic the topic of the tour step
   * @param name the name of the tour step
   * @returns true if the step was removed, otherwise false
   */
  removeStep(topic: string, name: string): boolean {
    const steps = this.tourSteps$.value;
    const newSteps = steps.filter(s => s.name !== name || s.topic !== topic);
    if (steps.length !== newSteps.length) {
      this.tourSteps$.next(newSteps);
      return true;
    }
    return false;
  }

  private buildTour(steps: TourStep[]): Shepherd.Tour {
    const result = this.shepherdService.tour({
      useModalOverlay: true,
      steps: steps
        .map((step, index) => ({
          id: this.toId(step.topic, step.name),
          buttons: this.buildButtons(index, steps.length),
          ...step.options
        })),
      defaultStepOptions: {
        canClickTarget: false,
        arrow: false,
        modalOverlayOpeningRadius: 4,
        modalOverlayOpeningPadding: 8,
        popperOptions: {
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, 16]
            }
          }]
        }
      }
    });

    result.on('complete', ({tour}: { index: number, tour: Shepherd.Tour }) => {
      this.tourVisitedNew$.next(this.extractTopics(tour));
      this.tour = null;
    });
    result.on('cancel', ({index, tour}: { index: number, tour: Shepherd.Tour }) => {
      this.tourVisitedNew$.next(this.extractTopics(tour, 0, index + 1));
      this.tour = null;
    });

    return result;
  }

  private buildButtons(index: number, length: number): Shepherd.Step.StepOptionsButton[] {
    const isFirst = index === 0;
    const isLast = index === length - 1;
    return [{
      text: this.translateService.instant('TOUR.SKIP'),
      label: this.translateService.instant('TOUR.SKIP.ARIA'),
      disabled: isLast,
      secondary: true,
      classes: 'shepherd-button-skip',
      action: function(): void {
        this.cancel();
      }
    }, {
      text: this.translateService.instant('TOUR.BACK'),
      label: this.translateService.instant('TOUR.BACK.ARIA'),
      disabled: isFirst,
      secondary: true,
      action: function(): void {
        this.back();
      }
    }, {
      text: this.translateService.instant(isLast ? 'TOUR.COMPLETE' : 'TOUR.NEXT'),
      label: this.translateService.instant(isLast ? 'TOUR.COMPLETE.ARIA' : 'TOUR.NEXT.ARIA'),
      action: function(): void {
        isLast ? this.complete() : this.next();
      }
    }] as any;
  }

  private extractTopics(tour: Shepherd.Tour, start?: number, end?: number): string[] {
    const steps = (tour as any).steps as Shepherd.Step[];
    const ids = steps.map(step => (step as any).id as string).slice(start, end);
    const topics = ids.map(id => this.fromId(id).topic);
    return Array.from(new Set(topics));
  }

  private toId(topic: string, name: string): string {
    return topic + TourService.SEPARATOR + name;
  }

  private fromId(id: string): { topic: string; name: string; } {
    const [topic, name] = id.split(TourService.SEPARATOR);
    return {topic, name};
  }

  private isTourElementHidden(step: TourStep): boolean {
    if (!step.options?.attachTo) {
      return false;
    }

    const element = this.getElement(step.options?.attachTo);
    return element &&
      element.offsetWidth === 0 &&
      element.offsetHeight === 0;
  }

  private getElement(attachTo?: Shepherd.Step.StepOptionsAttachTo): HTMLElement | null {
    return typeof attachTo?.element === 'string'
      ? this.document.querySelector<HTMLElement>(attachTo.element)
      : attachTo?.element || null;
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {DividerModule} from '@shared/divider/divider.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {MaterialModule} from '@shared/material/material.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {IMAGE_WIDGET} from '@widgets/image/image-widget-config';
import {ImageWidgetSettingsComponent} from '@widgets/image/image-widget-settings/image-widget-settings.component';
import {ImageWidgetComponent} from '@widgets/image/image-widget/image-widget.component';

/**
 * Module providing the image widget.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    DividerModule,
    MaterialModule,
    UpgradeModule
  ],
  declarations: [
    ImageWidgetComponent,
    ImageWidgetSettingsComponent
  ],
  entryComponents: [
    ImageWidgetComponent,
    ImageWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: IMAGE_WIDGET, multi: true},
  ]
})
export class ImageWidgetModule {
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/**
 * Component for showing an date overlay above the parent component
 */
@Component({
  selector: 'coyo-event-date-overlay',
  templateUrl: './event-date-overlay.component.html',
  styleUrls: ['./event-date-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDateOverlayComponent implements OnInit {

  /**
   * The date to display
   */
  @Input() date: Date;

  dateFormat: string;

  dayFormat: string;

  monthFormat: string;

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.dateFormat = this.translateService.instant('DATE_FORMAT_MEDIUM_WITHOUT_YEAR');
    this.dayFormat = this.extractFormat('D');
    this.monthFormat = this.extractFormat('M');
  }

  private extractFormat(filter: string): string {
    const regex = new RegExp(filter + '+', 'g');
    return this.dateFormat.match(regex)[0];
  }
}

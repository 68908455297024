import {NgModule} from '@angular/core';
import {FileLibraryModule} from '@app/file-library/file-library.module';
import {FilePickerModule} from '@app/file-picker/file-picker.module';
import {APP_CONFIGS} from '@apps/api/app-config';
import {AppStateGenerator} from '@apps/api/app-state-generator';
import {DocumentAppComponent} from '@apps/document/document-app.component';
import {DOCUMENT_APP_CONFIG} from '@apps/document/document-app.config';
import {TranslateModule} from '@ngx-translate/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {DocumentAppSettingsComponent} from './document-app-settings/document-app-settings.component';

// Disabled until app chooser is migrated
export const uiRouterModuleChild = UIRouterUpgradeModule.forChild({states: AppStateGenerator.buildStates(DOCUMENT_APP_CONFIG)});

@NgModule({
  declarations: [DocumentAppSettingsComponent, DocumentAppComponent],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    uiRouterModuleChild,
    TranslateModule,
    FilePickerModule,
    FileLibraryModule,
    UpgradeModule
  ],
  providers: [
    {provide: APP_CONFIGS, useValue: DOCUMENT_APP_CONFIG, multi: true}
  ]
})
export class DocumentAppModule { }

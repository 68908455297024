<ng-container *ngIf="(canUpload$ | async) && (location$ | async) as location">
  <button (click)="selectFiles()"
          class="hidden-xs"
          mat-button
          size="small">
    <mat-icon size="small" svgIcon="upload"></mat-icon>
    {{'FILE_LIBRARY.ACTIONS.UPLOAD_FILES' | translate}}
  </button>
  <button (click)="selectFiles()"
          class="visible-xs"
          mat-icon-button
          size="small">
    <mat-icon svgIcon="upload"></mat-icon>
  </button>
  <input #files
         (change)="beginUpload(location)"
         [accept]="mimeTypes$ | async"
         [multiple]="(multiple$ | async)"
         class="files"
         id="files"
         type="file">
</ng-container>

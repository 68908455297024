import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatDialogSize} from '@coyo/ui';
import {LikeTargetState} from '@domain/like/like-target-state';
import {LikeState} from '@domain/like/like.state';
import {Likeable} from '@domain/like/likeable';
import {SubscribeLikeInfo, UnsubscribeLikeInfo, UpdateSenderIdForTarget} from '@domain/like/likes.actions';
import {Sender} from '@domain/sender/sender';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LikesModalComponent} from '../likes-modal/likes-modal.component';

/**
 * Data for the message translation that sums up the like info for a target.
 */
interface LikeMessageData {
  othersCount: number;
  latest: string;
  isLiked: boolean;
  others: Sender[];
}

/**
 * Component showing information on who liked a target.
 */
@Component({
  selector: 'coyo-like-info',
  templateUrl: './like-info.component.html',
  styleUrls: ['./like-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikeInfoComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * The number of maximum liking senders to display in a tooltip
   */
  static readonly TOOLTIP_MAX_SENDER_COUNT: number = 5;

  private dialogRef: MatDialogRef<LikesModalComponent, void>;

  /**
   * The id of the sender to whom the like state should be related
   */
  @Input() senderId: string;

  /**
   * The like target.
   */
  @Input() target: Likeable;

  /**
   * Flag to render information as text (no icons)
   */
  @Input() textual: boolean;

  /**
   * Flag to show condensed information only (only the count will be shown).
   */
  @Input() condensed: boolean;

  /**
   * Flag if if a divider should be shown, only works if condensed is also true
   */
  @Input() prependDivider: boolean;

  /**
   * The like state of the target
   */
  likeState$: Observable<LikeTargetState>;

  tooltip$: Observable<string>;
  likeMessageData$: Observable<LikeMessageData>;

  constructor(private dialog: MatDialog, private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new SubscribeLikeInfo(this.senderId, this.target));
    this.likeState$ = this.store
      .select(LikeState.likesByTarget)
      .pipe(
        map(filterFn => filterFn(this.target.id)),
      );
    this.tooltip$ = this.likeState$.pipe(
      map(likeState => this.createTooltipText(likeState, !this.condensed))
    );
    this.likeMessageData$ = this.likeState$.pipe(
      map(likeState => this.getLikeMessageData(likeState))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.senderId) {
      this.store.dispatch(new UpdateSenderIdForTarget(changes.senderId.currentValue, this.target));
    }
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.store.dispatch(new UnsubscribeLikeInfo(this.target));
  }

  /**
   * Open a modal showing all the likes for the target.
   */
  openLikes(): void {
    this.dialogRef = this.dialog.open<LikesModalComponent, Likeable>(LikesModalComponent, {
      width: MatDialogSize.Small,
      data: this.target
    });
  }

  /**
   * Get a string with the latest senders who liked the target.
   * Maximum returned senders are set in {@link LikeInfoComponent.TOOLTIP_MAX_SENDER_COUNT}.
   *
   * @param likeState - The state to get latest liking senders from
   * @param excludeCurrentAndFirstOtherSender - True: will not include current sender, and will skip first sender
   * @returns A string with the names of the senders separated by new lines
   */
  private createTooltipText(likeState: LikeTargetState, excludeCurrentAndFirstOtherSender: boolean): string {
    const firstSenderIndex = excludeCurrentAndFirstOtherSender ? 1 : 0;
    const lastSenderIndex = LikeInfoComponent.TOOLTIP_MAX_SENDER_COUNT + firstSenderIndex;
    let sendersShown = excludeCurrentAndFirstOtherSender ? likeState.latestOtherLikingSenders : likeState.latestLikingSenders;
    sendersShown = sendersShown.slice(firstSenderIndex, lastSenderIndex);
    const senderNamesShown = sendersShown.map(sender => sender.displayName);
    const moreSendersToShow = likeState.otherLikingSenderCount > lastSenderIndex;
    return senderNamesShown.join('\n') + (moreSendersToShow ? '\n…' : '');
  }

  private getLikeMessageData(likeState: LikeTargetState): LikeMessageData {
    return {
      othersCount: likeState.otherLikingSenderCount,
      latest: likeState.latestOtherLikingSenders.length ? likeState.latestOtherLikingSenders[0].displayName : undefined,
      isLiked: likeState.isLikedByCurrentSender,
      others: likeState.latestOtherLikingSenders,
    };
  }
}

(function (angular) {
  'use strict';

  imageModalService.$inject = ["$uibModal"];
  angular
      .module('commons.ui')
      .factory('imageModalService', imageModalService);

  /**
   * @ngdoc service
   * @name  commons.ui.imageModalService
   *
   * @description
   * Service to show modal that contains an image
   *
   * @requires $uibModal
   */
  function imageModalService($uibModal) {

    return {
      open: open
    };

    /**
     * @ngdoc method
     * @name commons.ui.imageModalService#open
     * @methodOf commons.ui.imageModalService
     *
     * @description
     * Open modal that shows an image
     *
     * @param {string} imageUrl
     * The image url
     *
     * @param {string} title
     * The title of the image. Will be shown in header and used als alternative text for the image
     */
    function open(imageUrl, title) {
      return $uibModal.open({
        animation: true,
        backdrop: 'static',
        templateUrl: 'app/commons/ui/components/image-modal/image-modal.html',
        controller: 'ImageModalController',
        controllerAs: '$ctrl',
        resolve: {
          imageUrl: function () {
            return imageUrl;
          },
          title: function () {
            return title;
          }
        },
        bindToController: true,
        windowClass: 'modal-image',
        keyboard: false
      });
    }
  }

})(angular);

import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SubscriberInfoDataSource} from '@app/pages/subscriber-data-source/subscriber-info-data-source';
import {SubscriberModalComponent} from '@app/pages/subscriber-modal/subscriber-modal.component';
import {MatDialogSize} from '@coyo/ui';
import {SubscriptionService} from '@domain/subscription/subscription.service';

@Component({
  selector: 'coyo-subscriber-info',
  templateUrl: './subscriber-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriberInfoComponent implements OnDestroy {

  /**
   * The subscribed page ID.
   */
  @Input() targetId: string;

  /**
   * The page admin permission.
   */
  @Input() isAdmin: boolean;

  /**
   * The number of subscribers.
   */
  @Input() subscriberCount: number;

  private dialogRef: MatDialogRef<SubscriberModalComponent, void>;

  constructor(private dialog: MatDialog,
              private subscriptionService: SubscriptionService) {
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  /**
   * Open a modal showing all subscribers.
   */
  openSubscribers(): void {
    this.dialogRef = this.dialog.open<SubscriberModalComponent>(SubscriberModalComponent, {
      width: MatDialogSize.Small,
      minHeight: '280px',
      height: '50vh',
      data: new SubscriberInfoDataSource(this.subscriptionService, this.targetId, this.subscriberCount)
    });
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TargetService} from '@domain/sender/target/target.service';
import {User} from '@domain/user/user';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * User avatar component
 */
@Component({
  selector: 'coyo-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnInit {

  /**
   * The user to render the avatar for
   */
  @Input() user: User;

  /**
   * The size of the avatar
   */
  @Input() avatarSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  /**
   * Can be set to 'true' if the avatar image should not link to the user's profile
   */
  @Input() noLink: boolean;

  /**
   * Can be set to explicitly state if the user should be displayed as external
   */
  @Input() showExternalFlag: boolean;

  /**
   * Can be set to 'true' if the user's online status should be displayed
   */
  @Input() showOnlineStatus: boolean;

  /**
   * Can be set to 'true' if a follow button should be shown
   */
  @Input() showFollowButton: boolean;

  /**
   * Can be set to 'true' if a messaging button should be shown
   */
  @Input() showMessagingButton: boolean;

  /**
   * Opens a modal with a larger size of the image
   */
  @Input() allowModal: boolean;

  state$: Observable<{showLink: boolean}>;

  constructor(private targetService: TargetService) { }

  ngOnInit(): void {
    this.state$ = this.noLink && this.user ? of({showLink: false}) :
      this.targetService.canLinkTo(this.user.target).pipe(map(showLink => ({showLink})));
  }

}

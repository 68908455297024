import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {Theme} from '@domain/theme/theme';
import {Ng1AdminThemeConfig, Ng1DefaultThemeColors, Ng1MobileEventsService} from '@root/typings';
import {
  NG1_ADMIN_THEME_CONFIG,
  NG1_DEFAULT_THEME_COLORS,
  NG1_MOBILE_EVENTS_SERVICE
} from '@upgrade/upgrade.module';
import * as lodash from 'lodash';
import {Observable, of} from 'rxjs';

const _ = lodash;

/**
 * Service to manage the custom css theme
 */
@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private http: HttpClient, private urlService: UrlService,
              @Inject(DOCUMENT) private document: Document,
              @Inject(NG1_MOBILE_EVENTS_SERVICE) private mobileEventsService: Ng1MobileEventsService,
              @Inject(NG1_ADMIN_THEME_CONFIG) private adminThemeConfig: Ng1AdminThemeConfig,
              @Inject(NG1_DEFAULT_THEME_COLORS) private defaultThemeColors: Ng1DefaultThemeColors) {
  }

  /**
   * Applies the appropriate theme for the user.
   *
   * @returns Promise that completes when the theme is applied
   */
  applyTheme(): Promise<void> {
    const theme$: Observable<Theme> = this.urlService.isBackendUrlSet()
      ? this.http.get<Theme>('/web/themes/public')
      : of({css: '', colors: {}, images: {}} as Theme);

    return theme$.toPromise().then(theme => {
      this.removeTheme();
      this.applyColorsAndImages(theme);
      this.applyCSS(theme.css);
      this.applyMetaTags(theme.colors);
      this.applyFavicon(theme.images);
      return theme;
    }).then(theme => this.mobileEventsService.propagate('theme:applied', {...theme.colors, ...theme.images}))
      .catch(() => {
        this.applyMetaTags();
        this.onCustomThemeChanged();
      }).then(() => this.onCustomThemeChanged());
  }

  private removeTheme(): void {
    this.removeElementById('customTheme');
    this.removeElementById('customVars');
  }

  private applyCSS(css: string): void {
    this.document.head.appendChild(this.createStyleTag(css, {id: 'customTheme'}));
  }

  private applyMetaTags(colors?: { [key: string]: string }): void {
    const primaryColor = _.get(colors, 'color-primary', this.defaultThemeColors['color-primary']);
    const metaTags = ['theme-color', 'msapplication-navbutton-color', 'apple-mobile-web-app-status-bar-style'];
    metaTags.forEach(metaTag => {
      this.removeQuerySelector(`meta[name=${metaTag}]`);
      this.document.head.appendChild(this.createMetaTag(metaTag, primaryColor));
    });
  }

  private applyFavicon(images: { [key: string]: string }): void {
    const icon = images['image-coyo-favicon'];
    if (icon) {
      this.removeQuerySelector('link[rel="shortcut icon"]');
      const newElement = this.document.createElement('link');
      newElement.setAttribute('rel', 'shortcut icon');
      newElement.setAttribute('type', 'image/x-icon');
      newElement.setAttribute('href', icon.split('\'').join(''));
      this.document.head.appendChild(newElement);
    }
  }

  private applyColorsAndImages(theme: Theme = {} as Theme): Theme {
    const imageVariables = theme.images ? this.createImageCssVars(theme.images) : {};
    const themeVariables = {...theme.colors, ...imageVariables};

    const css = _.map(themeVariables, (value, key) => `--${key}: ${value};`).join(' ');
    this.document.head.insertBefore(
      this.createStyleTag(`:root {${css}}`, {id: 'customVars'}),
      this.document.getElementById('customTheme'));
    return theme;
  }

  private createStyleTag(css: string, attrs?: { [key: string]: any }): HTMLElement {
    const element = this.document.createElement('style');
    const attributes = {type: 'text/css', ...attrs};
    _.forEach(attributes, (value, key) => element.setAttribute(key, '' + value));
    const text = this.document.createTextNode(css);
    element.appendChild(text);
    return element;
  }

  private createMetaTag(name: string, content: string): HTMLElement {
    const newChild = this.document.createElement('meta');
    newChild.setAttribute('name', name);
    newChild.setAttribute('content', content);
    return newChild;
  }

  private createImageCssVars(themeImages: { [key: string]: string }): { [key: string]: string } {
    const images = {};
    this.adminThemeConfig.imageConfigs.forEach(config => {
      if (_.has(themeImages, config.key)) {
        images[config.key] = `url(${themeImages[config.key]})`;
      }
      if (config.retinaKey && _.has(themeImages, config.retinaKey)) {
        images[config.retinaKey] = `url(${themeImages[config.retinaKey]})`;
      }
      _(config.variables).keys()
        .filter(variable => themeImages[variable] !== undefined)
        .forEach(variable => images[variable] = themeImages[variable]);
    });
    return images;
  }

  private onCustomThemeChanged(): void {
    this.document.body.classList.remove('custom-theme-pending');
  }

  private removeElementById(elementId: string): void {
    const node = this.document.getElementById(elementId);
    if (node) {
      node.remove();
    }
  }

  private removeQuerySelector(selectors: string): void {
    const node = this.document.querySelector(selectors);
    if (node) {
      node.remove();
    }
  }
}

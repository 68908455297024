<h3 class="widget-title" *ngIf="widget.settings.album.title">
  {{widget.settings.album.title}}
</h3>
<span class="location" *ngIf="widget.settings.album.location">
  <mat-icon svgIcon="location-pin"></mat-icon><span>{{widget.settings.album.location}}</span>
</span>
<p class="description" *ngIf="widget.settings.album.description">{{widget.settings.album.description}}</p>
<div class="media-list"
     [class.full]="!(widget.settings.album.title || widget.settings.album.description || widget.settings.album.location)">
  <a href="#" class="box"
     *ngFor="let file of previewMedia; first as isFirst; last as isLast"
     [class.full]="isFirst && (previewMedia.length % 2)"
     (click)="openLightBox(file)"
     [attr.aria-label]="'WIDGET.MEDIA.OPEN.ARIA' | translate: {name: file.description || file.name}">
    <coyo-file-image-preview class="media-widget-media" [file]="file" [options]="{backgroundImage: true}"
                             [previewUrl]="previewUrl" size="XL"
                             [groupId]="file.senderId"
                             *ngIf="isImage(file)">
    </coyo-file-image-preview>
    <div class="video-preview" *ngIf="isVideo(file)">
      <div class="overlayed-box" *ngIf="!showAdditionalMediaOverlay(isLast)">
        <mat-icon svgIcon="media-video" class="overlay-text video-icon" size="large"></mat-icon>
        <div class="overlay"></div>
      </div>
      <coyo-file-video-preview [file]="file"
                               [groupId]="file.senderId"
                               [showControls]="false"
                               [url]="previewUrl">
      </coyo-file-video-preview>
    </div>
    <div *ngIf="showAdditionalMediaOverlay(isLast)" class="overlayed-box">
      <span class="overlay-text">+{{widget.settings._media.length - previewMedia.length}}</span>
      <div class="overlay"></div>
    </div>
  </a>
  <a class="box full" *ngIf="!previewMedia.length && widget.settings._media.length"
     (click)="openLightBox(widget.settings._media[0])">
    <div class="overlayed-box">
      <span class="overlay-text empty">
        {{'WIDGET.MEDIA.NO_PREVIEWS.VIEW' | translate: ({COUNT: widget.settings._media.length})}}
      </span>
      <div class="overlay"></div>
    </div>
  </a>
</div>

import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {ImageFromFileLibraryPlugin} from '@shared/rte/image-plugin/image-from-file-library-plugin';
import {ImageFromGSuitePlugin} from '@shared/rte/image-plugin/image-from-g-suite-plugin';
import {RtePlugin} from '@shared/rte/rte-plugin';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';

/**
 * A custom RTE dropdown plugin that bundles the active commands into a drop down for image selection.
 */
@Injectable()
export class ImageDropdownPlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertImage';

  constructor(@Inject(FROALA_EDITOR) private froala: any,
              private translateService: TranslateService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    this.registerCommand(this.getDropdownOptions(this.froala,
      ImageFromFileLibraryPlugin.KEY,
      ImageFromGSuitePlugin.KEY
    ));
  }

  private registerCommand(options: { [key: string]: string; }): void {
    const plugin = this;
    const keys = Object.keys(options);
    if (keys.length === 1) {
      // Push command to the toolbar
      Object.defineProperty(this.froala.COMMANDS, ImageDropdownPlugin.KEY,
        Object.getOwnPropertyDescriptor(this.froala.COMMANDS, keys[0]));
    } else if (keys.length > 1) {
      // Register dropdown command
      this.froala.RegisterCommand(ImageDropdownPlugin.KEY, {
        title: plugin.translateService.instant('RTE.INSERT_IMAGE'),
        type: 'dropdown',
        options: options,
        plugin: 'coyoImagePlugin',
        icon: 'insertImage',
        undo: false,
        focus: false,
        showOnMobile: true,
        refreshAfterCallback: false,
        callback: function(cmd: string, opt: string): void {
          plugin.froala.COMMANDS[opt].callback.bind(this)(cmd, opt);
        }
      });
    }
  }
}

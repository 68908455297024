import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Actions, ofActionDispatched, Select, Store} from '@ngxs/store';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {WikiArticleWidget} from '@widgets/wiki-article/wiki-article-widget';
import {
  Init,
  LoadMore,
  Search,
  TriggerSearch
} from '@widgets/wiki-article/wiki-article-widget-settings/wiki-article-widget-settings.actions';
import {
  WikiArticleWidgetSettingsState,
  WikiArticleWidgetSettingsStateModel
} from '@widgets/wiki-article/wiki-article-widget-settings/wiki-article-widget-settings.state';
import {Observable, Subject} from 'rxjs';
import {debounceTime, map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'coyo-wiki-article-widget-settings',
  templateUrl: './wiki-article-widget-settings.component.html',
  styleUrls: ['./wiki-article-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WikiArticleWidgetSettingsComponent extends WidgetSettingsComponent<WikiArticleWidget> implements OnInit,
  OnDestroy {

  @Select(WikiArticleWidgetSettingsState) state$: Observable<WikiArticleWidgetSettingsStateModel>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store, actions$: Actions) {
    super();
    actions$.pipe(ofActionDispatched(TriggerSearch),
      map((action: Search) => action.searchTerm),
      debounceTime(500),
      takeUntil(this.destroy$)).subscribe(term => {
        this.store.dispatch(new Search(term));
    });
  }

  ngOnInit(): void {
    this.parentForm.addControl('_articleId',
      new FormControl(this.widget.settings._articleId, Validators.required));

    this.store.dispatch(new Init(this.widget.settings._articleId)).subscribe(() => {
      const wikiArticleWidgetSettingsStateModel = this.store.selectSnapshot(WikiArticleWidgetSettingsState) as WikiArticleWidgetSettingsStateModel;
      if (wikiArticleWidgetSettingsStateModel?.articles === null) {
        // if no article could be loaded for any reason it will not be selected in the form
        this.parentForm.get('_articleId').reset();
      }
    });
  }

  onSearch(event: {term: string}): void {
    this.store.dispatch(new TriggerSearch(event.term));
  }

  onScroll(event: {start: number, end: number}, state: WikiArticleWidgetSettingsStateModel): void {
    if (state.loading) {
      return;
    } else if (state.initialized && state.page && (event.end >= state.articles.length - 8 && !state.page.last)) {
      this.store.dispatch(new LoadMore());
    }
  }

  onOpen(): void {
    this.store.dispatch(new Search(''));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

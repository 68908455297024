import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JitTranslationActiveSettings} from '@app/admin/settings/jit-translation-settings/jit-translation-active-settings';
import {JitTranslationSettings} from '@app/admin/settings/jit-translation-settings/jit-translation-settings';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';

/**
 * Service to handle jit-translation-settings
 */
@Injectable({
  providedIn: 'root'
})
export class JitTranslationSettingsService {

  static readonly FEATURE_FLAG: string = 'jit-translation';

  private isActive$: Observable<JitTranslationActiveSettings>;

  constructor(private http: HttpClient, private featureToggleService: FeatureToggleService) {
  }

  /**
   * Get the jit-translation settings.
   * @return an observable which holds the jit-translation settings
   */
  getSettings(): Observable<JitTranslationSettings> {
    return this.http.get<JitTranslationSettings>('/web/translation/settings', {
      headers: new HttpHeaders({
        handleErrors: 'false',
        forceCall: 'true'
      }),
    });
  }

  /**
   * Stores the given settings in the jit-translation service.
   * @param settings which are to be stored
   * @return an observable which holds the new saved jit-translation settings
   */
  putSettings(settings: JitTranslationSettings): Observable<JitTranslationSettings> {
    this.isActive$ = null;
    return this.http.put<JitTranslationSettings>('/web/translation/settings', settings, {
      headers: new HttpHeaders({
        forceCall: 'true'
      })
    });
  }

  /**
   * Checks if there is an active translation provider configured
   *
   * @return The active translation provider settings
   */
  getActiveSettings(): Observable<JitTranslationActiveSettings> {
    if (!this.isActive$) {
      this.isActive$ = this.isActive().pipe(
        switchMap(active => {
          if (active) {
            return this.http.get<JitTranslationActiveSettings>('/web/translation/settings/activeLanguages', {
              headers: {
                handleErrors: 'false'
              }
            });
          }
          return of({
            activeLanguages: []
          });
        }),
        catchError((err: HttpErrorResponse) => {
          this.isActive$ = null;
          return throwError(err);
        })
      ).pipe(shareReplay({bufferSize: 1, refCount: false}));
    }
    return this.isActive$;
  }

  /**
   * Validates if the given api key can be used to access the translation provider
   * @param settings The currently configured settings
   *
   * @return true if the api key is valid
   */
  validateApiKey(settings: JitTranslationSettings): Observable<boolean> {
    return this.http.post<{ apiKeyValid: boolean }>('/web/translation/settings/validate/api-key', settings, {
      headers: new HttpHeaders({
        forceCall: 'true'
      })
    })
      .pipe(map(result => result.apiKeyValid));
  }

  /**
   * Checks if the current tenant has a valid jit-translation license.
   * @return true if valid
   */
  hasValidLicense(): Observable<boolean> {
    return this.featureToggleService.getFeatureState(JitTranslationSettingsService.FEATURE_FLAG).pipe(
      map(state => state.states['license']));
  }

  private isActive(): Observable<boolean> {
    return this.featureToggleService.isFeatureAvailable(JitTranslationSettingsService.FEATURE_FLAG);
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Service to handle the hiding of widgets in a backwards compatible manner.
 */
@Injectable()
export class WidgetVisibilityService {

  private hiddenIds$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  /**
   * Returns an observable holding a list of hidden widget IDs.
   *
   * @returns the observable holding the IDs
   */
  getHiddenIds$(): Observable<string[]> {
    return this.hiddenIds$.asObservable();
  }

  /**
   * Hides the widget with the given ID.
   *
   * @param id the widget ID
   * @param isHidden the hidden status
   */
  setHidden(id: string, isHidden: boolean): void {
    const hiddenIds = this.hiddenIds$.getValue();
    const hiddenIdx = hiddenIds.indexOf(id);
    if (isHidden && (hiddenIdx === -1)) {
      this.hiddenIds$.next([...hiddenIds, id]);
    } else if (!isHidden && (hiddenIdx !== -1)) {
      this.hiddenIds$.next([...hiddenIds.slice(0, hiddenIdx), ...hiddenIds.slice(hiddenIdx + 1)]);
    }
  }
}

import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {AttachmentBtnComponent} from '@shared/files/attachment-btn/attachment-btn.component';
import {AttachmentListComponent} from '@shared/files/attachment-list/attachment-list.component';
import {AttachmentComponent} from '@shared/files/attachment/attachment.component';
import '@shared/files/external-file-handler/external-file-handler.service.downgrade';
import {EditInOfficeButtonComponent} from '@shared/files/file-details-modal/edit-in-office-button/edit-in-office-button.component';
import {FileDetailsState} from '@shared/files/file-details-modal/state/file-details.state';
import {FileIconByMimeTypeComponent} from '@shared/files/file-icon/file-icon-by-mime-type.component';
import {FileSize} from '@shared/files/file-size/file-size.pipe';
import {FileTypeNamePipe} from '@shared/files/file-type-name/file-type-name.pipe';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {TimeModule} from '@shared/time/time.module';
import {UpgradeModule} from '@upgrade/upgrade.module';
import {FileUploadModule} from 'ng2-file-upload';
import './file-details-modal/edit-in-office-button/edit-in-office-button.component.downgrade';
import {FileDetailsModalComponent} from './file-details-modal/file-details-modal.component';
import './file-details-modal/file-details-modal.service.downgrade';
import {FileInformationComponent} from './file-details-modal/file-information/file-information.component';
import './file-details-modal/file-information/file-information.component.downgrade';
import {UnlockEditInOfficeButtonComponent} from './file-details-modal/unlock-edit-in-office-button/unlock-edit-in-office-button.component';
import './file-details-modal/unlock-edit-in-office-button/unlock-edit-in-office-button.component.downgrade';
import './file-icon/file-icon-by-mime-type.component.downgrade';
import {InternalImageComponent} from './internal-image/internal-image.component';

export const ngxsModule = NgxsModule.forFeature([FileDetailsState]);

/**
 * Module that holds services concerning different types of file operations.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    FileUploadModule,
    UpgradeModule,
    ngxsModule,
    SenderUIModule,
    TimeModule
  ],
  declarations: [
    AttachmentBtnComponent,
    AttachmentComponent,
    AttachmentListComponent,
    FileIconByMimeTypeComponent,
    FileTypeNamePipe,
    FileSize,
    FileDetailsModalComponent,
    InternalImageComponent,
    EditInOfficeButtonComponent,
    UnlockEditInOfficeButtonComponent,
    FileInformationComponent
  ],
  exports: [
    AttachmentBtnComponent,
    AttachmentListComponent,
    FileIconByMimeTypeComponent,
    FileTypeNamePipe,
    FileSize,
    InternalImageComponent
  ],
  entryComponents: [
    AttachmentBtnComponent,
    FileDetailsModalComponent
  ]
})
export class FileModule {
}

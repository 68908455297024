import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogSize} from '@coyo/ui';
import {EntityId} from '@domain/entity-id/entity-id';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {WidgetEditService} from '@widgets/api/widget-edit/widget-edit.service';
import {LayoutType} from '@widgets/api/widget-layout-chooser-modal/layout-type';
import {WidgetLayoutChooserModalComponent} from '@widgets/api/widget-layout-chooser-modal/widget-layout-chooser-modal.component';
import {WidgetLayoutResponse} from '@widgets/api/widget-layout/widget-layout-response';
import {WidgetRenderStyle} from '@widgets/api/widget-render-style';
import {WidgetLayoutSettingsRowsSlotStateModel, WidgetLayoutSettingsRowsStateModel, WidgetLayoutStateModel} from '@widgets/api/widget-state.model';
import {
  AddRowToLayout,
  CancelEditMode,
  CleanupLayoutState,
  InitializeWidgetLayout,
  RemoveRowFromLayout,
  RenameLayoutState,
  UpdateParent
} from '@widgets/api/widget.actions';
import {WidgetState} from '@widgets/api/widget.state';
import {NgxPermissionsService} from 'ngx-permissions';
import {from, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, first, map, pluck, takeUntil} from 'rxjs/operators';

import * as _ from 'lodash';

@Component({
  selector: 'coyo-widget-layout',
  templateUrl: './widget-layout.component.html',
  styleUrls: ['./widget-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetLayoutComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The widget layout name
   */
  @Input() name: string;

  /**
   * The widget layout language
   */
  @Input() language: string;

  /**
   * The widget layout parent
   */
  @Input() parent: EntityId;

  /**
   * Optional widget layout data that has already been loaded
   */
  @Input() layout?: WidgetLayoutResponse;

  /**
   * The render style
   */
  @Input() renderStyle: WidgetRenderStyle = 'panel';

  /**
   * Should advanced controls be hidden
   */
  @Input() simpleMode: boolean = false;

  /**
   * The edit scope of the layout, needed for dispatching state changing actions. Usually 'global' or an app-id.
   */
  @Input() editScope: string = 'global';

  /**
   * The current user can manage this layout
   * If undefined (= no local sender permission involved) the global permission will be checked.
   */
  @Input() canManage: boolean;

  /**
   * A revision based layout (e.g. in wiki articles) will behave differently when the layout name changes.
   * Instead of renaming it will duplicate with the new layout name.
   */
  @Input() revisionBased: boolean = false;

  editMode$: Observable<boolean>;
  canManage$: Observable<boolean>;
  state$: Observable<WidgetLayoutStateModel>;
  private onDestroy: Subject<void> = new Subject();

  // save all languages this layout component had so cleanup can be done properly later
  // init with default language
  private languages: string[] = [null];

  constructor(private store: Store, private dialog: MatDialog, private widgetEditService: WidgetEditService,
              private permissionService: NgxPermissionsService, private actions$: Actions) {
  }

  ngOnInit(): void {
    const stateName = this.getStateName(this.name, this.language);

    this.actions$.pipe(
      ofActionSuccessful(CancelEditMode),
      filter((action: CancelEditMode) => action.editScope === this.editScope),
      takeUntil(this.onDestroy)
    ).subscribe(() => {
      this.store.dispatch(new InitializeWidgetLayout(stateName, this.language, this.parent, this.editScope, true, this.layout));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const stateName = this.getStateName(this.name, this.language);
    if (!this.editMode$ || changes.editScope) {
      this.editMode$ = this.store.select(WidgetState)
        .pipe(
          pluck('editScopes'),
          pluck(this.editScope),
          map(editScope => editScope ? editScope.editMode : false),
          distinctUntilChanged()
        );
    }

    if (!this.canManage$ || (changes.canManage && changes.canManage.currentValue !== changes.canManage.previousValue)) {
      this.canManage$ = from(this.permissionService.hasPermission('MANAGE_GLOBAL_WIDGETS'))
        .pipe(map(globalPermission => this.canManage
          || (this.canManage === undefined && globalPermission)));
    }

    let widgetNameChanged = _.get(changes, 'name', {
      isFirstChange: () => false
    }).isFirstChange();

    let oldName = this.name;
    let oldLanguage = this.language;

    if (changes.name && changes.name.previousValue !== changes.name.currentValue) {
      widgetNameChanged = true;
      oldName = changes.name.previousValue;
    }

    if (changes.language && changes.language.previousValue !== changes.language.currentValue) {
      widgetNameChanged = true;
      oldLanguage = changes.language.previousValue;
      this.languages.push(changes.language.currentValue);
    }

    if (widgetNameChanged) {
      const previousName = this.getStateName(oldName, oldLanguage);
      this.unregister(previousName);
      this.register(stateName);
      if (!this.revisionBased && !changes.name.isFirstChange()) {
        this.store.dispatch(new RenameLayoutState(this.editScope, previousName, stateName));
      } else {
        this.store.dispatch(new InitializeWidgetLayout(stateName, this.language, this.parent, this.editScope));
      }
    }

    if (changes.parent && !changes.parent.isFirstChange()) {
      this.store.dispatch(new UpdateParent(this.editScope, changes.parent.currentValue, null));
    }

    this.state$ = this.store.select(WidgetState)
      .pipe(
        pluck('layouts'),
        pluck(stateName),
        filter(value => value),
        distinctUntilChanged()
      );
  }

  buildSlotName(layoutName: string, slotName: string): string {
    return `layout-${layoutName}-slot-${slotName}`;
  }

  addRow(index: number = 0): boolean {
    this.dialog.open<WidgetLayoutChooserModalComponent>(WidgetLayoutChooserModalComponent, {
      width: MatDialogSize.Medium
    }).afterClosed()
      .pipe(filter(val => !!val))
      .subscribe((value: LayoutType) => {
        this.store.dispatch(new AddRowToLayout(this.getStateName(this.name, this.language), index, value.slots, this.editScope));
      });
    return false;
  }

  removeRow(index: number): boolean {
    this.store.dispatch(new RemoveRowFromLayout(this.getStateName(this.name, this.language), index, this.editScope));
    return false;
  }

  trackSettingsRow(index: number, item: WidgetLayoutSettingsRowsStateModel): any {
    return item && item.name;
  }

  trackSlot(index: number, item: WidgetLayoutSettingsRowsSlotStateModel): any {
    return item && item.name;
  }

  register(name: string = this.name): void {
    this.canManage$.pipe(first()).subscribe(canManage => {
      if (canManage) {
        this.widgetEditService.register(name, this.editScope);
      }
    });
  }

  unregister(name: string = this.name): void {
    this.widgetEditService.unregister(name);
  }

  ngOnDestroy(): void {
    this.unregister(this.getStateName(this.name, this.language));
    this.store.dispatch(new CleanupLayoutState(this.getStateName(this.name, this.language)));
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private getStateName(name: string, language?: string): string {
    let fullName = name;
    if (language) {
      fullName += '-' + language;
    }
    return fullName;
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {LatestFilesWidget} from '@widgets/latest-files/latest-files-widget';
import {LatestFilesWidgetSettingsComponent} from './latest-files-widget-settings/latest-files-widget-settings.component';
import {LatestFilesWidgetComponent} from './latest-files-widget/latest-files-widget.component';

/**
 * The configuration for the latest-files widget.
 */
export const LATEST_FILES_WIDGET: WidgetConfig<LatestFilesWidget> = {
  key: 'latestfiles',
  name: 'WIDGETS.LATESTFILES.NAME',
  description: 'WIDGETS.LATESTFILES.DESCRIPTION',
  icon: 'zmdi-coyo zmdi-coyo-image',
  component: LatestFilesWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  defaultTitle: 'WIDGETS.LATESTFILES.NAME',
  settings: {
    skipOnCreate: false,
    component: LatestFilesWidgetSettingsComponent
  },
  renderOptions: {
    printable: true
  },
  whitelistExternal: true
};

import {HtmlTagSearchResult} from './html-tag-search-result';

/**
 * Tree nodes for "Pseudo"-dom tree construction
 */
export class HtmlTagTreeNode {
  children: HtmlTagTreeNode[];

  constructor(public openingTag: HtmlTagSearchResult, public closingTag: HtmlTagSearchResult) {
    this.children = [];
  }

  /**
   * Iterates through all leafs of the tree ordered by the index of the opening tag
   * @param fn Callback function called for every node. Return falsy to break the loop
   * @param startIndex The iterator index. Should be 0
   *
   * @returns The current iterator index
   */
  forEach(fn: (value: HtmlTagTreeNode, index: number) => boolean, startIndex: number = 0): number {
    let i = startIndex;
    if (!fn(this, i++)) {
      return i;
    }
    for (const child of this.children) {
      i = child.forEach(fn, i);
    }
    return i;
  }

  /**
   * Checks if the given char-index is between the start and end of this tag (including the tags themselves)
   *
   * @param index An index in the string
   *
   * @returns True if the index is within the scope of this node, false otherwise
   */
  isIndexInScope(index: number): boolean {
    if (!this.openingTag && !this.closingTag) {
      return false;
    }
    const start = !this.openingTag ? this.closingTag.start : this.openingTag.start;
    const end = !this.closingTag ? this.openingTag.end : this.closingTag.end;
    return index >= start && index < end;
  }

  /**
   * Checks if the given char-index is inside any start or end tag within the scope of this node
   *
   * @param index An index in the string
   *
   * @returns True if the index is inside any tag within the scope of this node, false otherwise
   */
  isIndexInTag(index: number): boolean {
    return (index >= this.openingTag.start && index < this.openingTag.end)
      || (index >= this.closingTag.start && index < this.closingTag.end)
      || !this.children.every(child => !child.isIndexInTag(index));
  }

  /**
   * Returns a list of tag-names that are open but not closed at the given index in the string
   *
   * @param index An index in the string
   *
   * @returns a list of tag-names, sorted by nesting depth reversed (deepest first)
   */
  getOpenNodesAt(index: number): string[] {
    const result: string[] = [];
    this.children.forEach(child => result.push(...child.getOpenNodesAt(index)));
    if (this.openingTag.type !== 'self-closing') {
      if (index >= this.openingTag.end && index < this.closingTag.end) {
        result.push(this.openingTag.tag);
      }
    }
    return result;
  }

}

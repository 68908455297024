import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';

/**
 * A user's search request.
 */
export class Search {

  /**
   * A search query.
   */
  term: string;

  /**
   * A list of filters to apply. Key is name of filter (e.g. `type`)
   * and value is set of filter values (e.g. `["wiki-article"]`).
   */
  filters: { [key: string]: string[]; };

  /**
   * A list of aggregations with the maximum number of filters per
   * aggregation (e.g. `authors: 5` will return an aggregation with 5 authors).
   * Key is the name of the aggregation and value is the max. number of results.
   */
  aggregations: { [key: string]: number; };

  /**
   * A list of search fields.
   */
  searchFields: string[];

  /**
   * Flag is this is a new search request and should be used for search statistics.
   */
  newSearch: boolean;

  constructor(term: string = '',
              filters: { [key: string]: string[]; } = {},
              aggregations: { [key: string]: number; } = {},
              searchFields: string[] = [],
              newSearch: boolean = true) {
    this.term = term;
    this.filters = filters;
    this.aggregations = aggregations;
    this.searchFields = searchFields;
    this.newSearch = newSearch;
  }

  /**
   * Converts this search request to `HttpParams`.
   *
   * @param params the params to be merged with the search request
   * @returns a new set of parameters with appended values for this search request
   */
  toHttpParams(params: HttpParams | { [param: string]: string | string[]; } = new HttpParams()): HttpParams {
    let httpParams = (params instanceof HttpParams ? params : new HttpParams({fromObject: params}));

    if (!_.isEmpty(this.term)) {
      httpParams = httpParams.append('term', this.term);
    }

    _.forEach(this.filters, (values, key) => {
      const filter = _.map(values, value => `${key}=${value}`).join('&');
      httpParams = httpParams.append('filters', filter);
    });

    _.forEach(this.aggregations, (value, key) => {
      httpParams = httpParams.append('filters', `${key}=${value}`);
    });

    _.forEach(this.searchFields, value => {
      httpParams = httpParams.append('searchFields', value);
    });

    if (!this.newSearch) {
      httpParams = httpParams.append('newSearch', 'false');
    }

    return httpParams;
  }
}

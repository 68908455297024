import {Injectable} from '@angular/core';
import {Pageable} from '@domain/pagination/pageable';
import {Action, State, StateContext} from '@ngxs/store';
import {UserList} from '@shared/sender-ui/user-list/user-list';
import {Init, LoadMore, Reset, SetLoading} from '@widgets/new-colleagues/new-colleagues-widget/new-colleagues.actions';
import {NewColleaguesService} from '@widgets/new-colleagues/new-colleagues.service';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

export interface NewColleaguesStateModel {
  [key: string]: UserList;
}

@Injectable({providedIn: 'root'})
@State<NewColleaguesStateModel>({
  name: 'newColleagues',
  defaults: {}
})
export class NewColleaguesWidgetState {

  readonly PAGE_SIZE: number = 3;

  constructor(private newColleaguesService: NewColleaguesService) {
  }

  @Action(Init)
  init(ctx: StateContext<NewColleaguesStateModel>, action: Init): Observable<void> {
   return ctx.dispatch(new SetLoading(true, action.id))
      .pipe(
        switchMap(() => this.newColleaguesService.getNewColleaguesList(new Pageable(0, this.PAGE_SIZE))),
        tap(res => {
          ctx.patchState({
          [action.id]: {
            ...ctx.getState()[action.id],
            users: res.content,
            pages: 1,
            last: res.last
          }
        });
        }),
        switchMap(() => ctx.dispatch(new SetLoading(false, action.id))));
  }

  @Action(LoadMore)
  loadMore(ctx: StateContext<NewColleaguesStateModel>, action: LoadMore): Observable<void> {
    if (ctx.getState()[action.id].loading) {
      return of();
    }
    return ctx.dispatch(new SetLoading(true, action.id))
      .pipe(
        switchMap(() =>
          this.newColleaguesService.getNewColleaguesList(new Pageable(ctx.getState()[action.id].pages, this.PAGE_SIZE))),
        tap(res => {
          const state = ctx.getState()[action.id];
          ctx.patchState({
            [action.id]: {
              ...ctx.getState()[action.id],
              users: [...state.users, ...res.content],
              pages: state.pages + 1,
              last: res.last
            }
          });
        }),
        switchMap(() => ctx.dispatch(new SetLoading(false, action.id))));
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<NewColleaguesStateModel>, action: SetLoading): void {
    ctx.patchState({
      [action.id]: {
        ...ctx.getState()[action.id],
        loading: action.loading
      }
    });
  }

  @Action(Reset)
  destroy(ctx: StateContext<NewColleaguesStateModel>, action: Reset): void {
    ctx.setState({..._.omit(ctx.getState(), action.id)});
  }
}

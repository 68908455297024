import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfirmationDialogData} from '@shared/dialog/confirmation-dialog/confirmation-dialog-data';

/**
 * A component that renders a confirmation dialog
 */
@Component({
  selector: 'coyo-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {

  constructor(private dialog: MatDialogRef<ConfirmationDialogComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {
  }

  yes(): void {
    this.dialog.close(true);
  }

  no(): void {
    this.dialog.close(false);
  }
}

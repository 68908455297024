import {IconNameFilePickerItem} from '@app/file-library/icon-name-file-picker-item/icon-name-file-picker-item';
import {SenderRootFilePickerItem} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item';
import {Sender} from '@domain/sender/sender';

/**
 * File picker item showing the file library files of the current user.
 */
export class MyFilesRootFilePickerItem extends SenderRootFilePickerItem implements IconNameFilePickerItem {
  readonly id: string;
  readonly name: string;
  readonly icon: string;

  constructor(readonly sender: Sender) {
    super(sender);
    this.name = 'FILE_LIBRARY.MY_FILES';
    this.id = sender.id;
    this.icon = 'generic-file';
  }
}

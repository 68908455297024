/**
 * Allowed values for the iFrame sandbox attribute.
 */
export enum IframeSandbox {
  Forms = 'allow-forms', // Allows form submission
  Modals = 'allow-modals', // Allows to open modal windows
  OrientationLock = 'allow-orientation-lock', // Allows to lock the screen orientation
  PointerLock = 'allow-pointer-lock', // Allows to use the Pointer Lock API
  Popups = 'allow-popups', // Allows popups
  PopupsToEscapeSandbox = 'allow-popups-to-escape-sandbox', // Allows popups to open new windows without inheriting the sandboxing
  Presentation = 'allow-presentation', // Allows to start a presentation session
  SameOrigin = 'allow-same-origin', // Allows the iframe content to be treated as being from the same origin
  Scripts = 'allow-scripts', // Allows to run scripts
  TopNavigation = 'allow-top-navigation', // Allows the iframe content to navigate its top-level browsing context
  TopNavigationByUserActivation = 'allow-top-navigation-by-user-activation' // Allows top navigation, but only if initiated by user
}

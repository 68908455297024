(function (angular) {
  'use strict';

  ColleaguesMainController.$inject = ["$rootScope", "$scope", "$q", "$sessionStorage", "UserModel", "Pageable", "currentUser", "colleaguesConfig", "profileFieldGroups", "$stateParams", "$translate", "selectionFilterService"];
  angular
      .module('coyo.colleagues')
      .controller('ColleaguesMainController', ColleaguesMainController);

  function ColleaguesMainController($rootScope, $scope, $q, $sessionStorage, UserModel, Pageable, currentUser,
                                    colleaguesConfig, profileFieldGroups, $stateParams, $translate,
                                    selectionFilterService) {

    var vm = this,
        SUBSCRIBED_TO_KEY = 'subscribedTo',
        SUBSCRIBED_NOT_KEY = 'subscribedNot',
        SUBSCRIBED_BY_KEY = 'subscribedBy',
        AVAILABILITY_ONLINE_KEY = 'ONLINE';

    vm.currentUser = currentUser;

    vm.$onInit = _init;
    vm.search = search;
    vm.moreAggregations = moreAggregations;
    vm.setSubscriptionFilter = setSubscriptionFilter;
    vm.setAggregationFilter = setAggregationFilter;
    vm.setAvailabilityFilter = setAvailabilityFilter;
    vm.resetFilters = resetFilters;

    function moreAggregations(aggregation) {
      aggregation.max += 5;
    }

    function search(term) {
      vm.params.searchTerm = term;
      return _loadColleagues();
    }

    function setSubscriptionFilter() {
      _setSubscriptionFilter();
      _loadColleagues();
    }

    function setAggregationFilter(selection, aggregationName) {
      vm.params[aggregationName] = _.map(selection, 'key');
      _loadColleagues();
    }

    function setAvailabilityFilter(selection) {
      _setAvailabilityFilter(_.map(selection, 'key'));
      _loadColleagues();
    }

    function resetFilters() {
      _resetSubscriptionFilter();
      _resetAggregationFilters();
      _resetAvailabilityFilter();
      _initParams();
      _initFilters();
      search('');
    }

    function _loadColleagues() {
      if (vm.loading) {
        return $q.reject();
      }
      vm.loading = true;

      var sort = vm.params.searchTerm ? ['_score,DESC', 'lastname.sort,firstname.sort']
        : 'lastname.sort,firstname.sort';
      var pageable = new Pageable(0, colleaguesConfig.paging.pageSize, sort);
      var filter = _.omit(vm.params, 'searchTerm');
      var searchFields = ['displayName', 'email', 'properties.jobTitle'];
      var aggregations = {subscribedTo: '', subscribedBy: '', subscribedNot: '', availability: ''};
      _.forEach(vm.aggregations, function (aggregation) {
        _.set(aggregations, aggregation.name, 100);
      });
      _.forEach(vm.searchableProfileGroups, function (profileGroup) {
        var propertyString = 'properties.' + profileGroup.name;
        searchFields.indexOf(propertyString) === -1 && searchFields.push(propertyString);
      });
      return UserModel.searchWithFilter(vm.params.searchTerm, pageable, filter, searchFields, aggregations)
          .then(function (page) {
            $sessionStorage.colleagueList = vm.params;

            vm.currentPage = page;

            _setFilterCounts(page.aggregations);

            _.forOwn(page.aggregations, _setAggregation);

            _initAggregationFilters();

          }).finally(function () {
            vm.loading = false;
          });
    }

    function _setAggregation(value, key) {
      if (!vm.aggregations[key]) {
        return;
      }
      var activeFilters = _.get(vm.params, key);
      // set used aggregation as active and add used aggregations that do not hit any document
      activeFilters.forEach(function (filterValue) {
        var elem = _.find(value, {key: filterValue});
        if (elem) {
          elem.active = true;
        } else {
          value.push({key: filterValue, count: 0, active: true});
        }
      });

      _.set(vm.aggregations, key + '.count', vm.currentPage.totalElements);
      _.set(vm.aggregations, key + '.values', value);

      _moveCurrentUserDepartmentToTopOfFilters();
    }

    function _moveCurrentUserDepartmentToTopOfFilters() {
      var currentUserDepartment = currentUser.properties.department;
      if (currentUserDepartment) {
        var allDepartments = _.get(vm.aggregations, 'department.values');
        var userDepartmentIndex = _.findIndex(allDepartments, {key: currentUserDepartment});
        if (userDepartmentIndex >= 0) {
          var userDepartment = allDepartments[userDepartmentIndex];
          allDepartments.splice(userDepartmentIndex, 1);
          allDepartments.unshift(userDepartment);
        }
      }
    }

    function _clearAggregationFilter(aggregation) {
      _.set(vm.params, aggregation.name, []);
    }

    function _setSubscriptionFilter() {
      vm.subscriptionFilterModel.items.forEach(function (item) {
        if (item.isSelected()) {
          vm.params[item.key] = [true];
        } else {
          vm.params[item.key] = [];
        }
      });
    }

    function _resetSubscriptionFilter() {
      vm.subscriptionFilterModel.clearAll();
      _setSubscriptionFilter();
    }

    function _setAvailabilityFilter(selected) {
      vm.params.availability = selected;
    }

    function _resetAvailabilityFilter() {
      vm.availabilityFilterModel.clearAll();
      _setAvailabilityFilter([]);
    }

    function _resetAggregationFilters() {
      vm.aggregationFilters.forEach(function (filter) {
        _clearAggregationFilter(vm.aggregations[filter.key]);
      });
    }

    function _initFilters() {
      var subscriptionIcon = 'contacts';
      vm.subscriptionFilterModel = selectionFilterService.builder()
          .itemModel(selectionFilterService.itemBuilder()
              .key(SUBSCRIBED_TO_KEY)
              .active(_isFilterItemActive(vm.params.subscribedTo))
              .icon(subscriptionIcon)
              .build())
          .itemModel(selectionFilterService.itemBuilder()
              .key(SUBSCRIBED_NOT_KEY)
              .active(_isFilterItemActive(vm.params.subscribedNot))
              .icon(subscriptionIcon)
              .build())
          .itemModel(selectionFilterService.itemBuilder()
              .key(SUBSCRIBED_BY_KEY)
              .active(_isFilterItemActive(vm.params.subscribedBy))
              .icon(subscriptionIcon)
              .build())
          .build();

      vm.availabilityFilterModel = selectionFilterService.builder()
          .itemModel(selectionFilterService.itemBuilder()
              .key(AVAILABILITY_ONLINE_KEY)
              .icon('online')
              .active(_isFilterItemActive(vm.params.availability))
              .build())
          .build();
    }

    function _isFilterItemActive(param) {
      return param.length > 0;
    }

    function _initAggregationFilters() {
      vm.aggregationFilters = [];
      for (var key in vm.aggregations) {
        if (vm.aggregations.hasOwnProperty(key)) {
          var aggregation = vm.aggregations[key];
          var allItemsCount = 0;
          var itemConfigs = aggregation.values.map(function (value) {
            allItemsCount = allItemsCount + value.count;
            var item = {
              key: value.key,
              text: value.key,
              active: value.active,
              count: value.count,
              icon: 'document-app'
            };
            // dirty but fixes COYOFOUR-11962
            if (aggregation.type === 'OPTIONS' && value.key !== 'N/A') {
              item.text = $translate.instant('USER.VALUES.' + aggregation.name.toUpperCase() + '.' + value.key.toUpperCase());
            }
            return item;
          });
          var aggregationFilter = selectionFilterService.builder().key(key).count(aggregation.count)
              .items(itemConfigs).trackingId().build();
          aggregationFilter.setCount(allItemsCount);
          vm.aggregationFilters.push(aggregationFilter);
        }
      }
    }

    function _setFilterCounts(aggregations) {
      vm.subscriptionFilterModel.items.forEach(function (item) {
        item.count = aggregations[item.key][0].count;
      });

      vm.availabilityFilterModel.items.forEach(function (item) {
        item.count = aggregations.availability[0].count;
      });
    }

    function _updateSubscriptionFilterCounts(event, subscription) {
      var subscribedToFilterItem = vm.subscriptionFilterModel.getItem(SUBSCRIBED_TO_KEY),
          subscribedNotFilterItem = vm.subscriptionFilterModel.getItem(SUBSCRIBED_NOT_KEY);
      if (subscription.follow) {
        subscribedToFilterItem.count++;
        subscribedNotFilterItem.count--;
      } else {
        subscribedToFilterItem.count--;
        subscribedNotFilterItem.count++;
      }
    }

    function _initParams() {
      var defaultParams = {
        searchTerm: undefined,
        subscribedBy: [],
        subscribedTo: [],
        subscribedNot: [],
        availability: []
      };

      _.forEach(vm.aggregations, function (aggregation) {
        _.set(defaultParams, aggregation.name, []);
      });
      vm.params = angular.extend(defaultParams, $stateParams.params
        ? angular.copy($stateParams.params) : $sessionStorage.colleagueList);
    }

    function _init() {
      var aggregations = _.map(_.filter(_.flatMap(profileFieldGroups, 'fields'), 'searchAggregation'),
          function (aggregation) {
            aggregation.max = 5;
            aggregation.filterAllActive = true;
            aggregation.translation = $translate.instant('USER.' + aggregation.name.toUpperCase());
            aggregation.values = [];
            return aggregation;
          });
      aggregations = _.orderBy(aggregations, ['searchAggregationOrder', 'translation'], ['asc', 'asc']);

      vm.profileGroups = profileFieldGroups;
      vm.searchableProfileGroups = _(vm.profileGroups)
          .orderBy(['sortOrder', 'fields.order'], ['asc', 'asc'])
          .flatMap('fields')
          .filter({overview: true})
          .value();
      vm.aggregations = _.zipObject(_.map(aggregations, 'name'), aggregations);
      var unsubscribe = $rootScope.$on('currentUser.follow:update', _updateSubscriptionFilterCounts);
      $scope.$on('$destroy', unsubscribe);
      _initParams();
      _initFilters();
      _loadColleagues();
    }
  }

})(angular);

(function (angular) {
  'use strict';

  PageModel.$inject = ["$rootScope", "$httpParamSerializer", "restResourceFactory", "coyoEndpoints", "Page", "UserModel"];
  angular
      .module('coyo.domain')
      .factory('PageModel', PageModel);

  /**
   * @ngdoc service
   * @name coyo.domain.PageModel
   *
   * @description
   * Provides the Coyo page model.
   *
   * @requires $rootScope
   * @requires $httpParamSerializer
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   * @requires commons.resource.Page
   * @requires coyo.domain.UserModel
   */
  function PageModel($rootScope, $httpParamSerializer, restResourceFactory, coyoEndpoints, Page, UserModel) {
    var PageModel = restResourceFactory({
      url: coyoEndpoints.page.pages
    });

    // class members
    angular.extend(PageModel, {

      /**
       * @ngdoc function
       * @name coyo.domain.PageModel#searchWithFilter
       * @methodOf coyo.domain.PageModel
       *
       * @description
       * Page elastic search with filter.
       *
       * @param {string} term search term
       * @param {object} pageable The paging information. If not set an offset of 0 and a page size of 20 will be used.
       * @param {object} [filters] search filters
       * @param {string} [searchFields] list of fields to search in
       * @param {object} [aggregations] search aggregations
       *
       * @returns {promise} An $http promise
       */
      searchWithFilter: function (term, pageable, filters, searchFields, aggregations) {
        var url = PageModel.$url();
        var params = angular.extend({
          term: term ? term : undefined,
          filters: filters ? $httpParamSerializer(filters) : undefined,
          searchFields: searchFields ? searchFields.join(',') : undefined,
          aggregations: aggregations ? $httpParamSerializer(aggregations) : undefined
        }, pageable.getParams());
        return PageModel.$get(url, params).then(function (response) {
          return new Page(response, params, {
            url: url,
            resultMapper: function (item) {
              return new PageModel(item);
            }
          });
        });
      }
    });

    // instance members
    angular.extend(PageModel.prototype, {

      /**
       * @ngdoc function
       * @name coyo.domain.PageModel#getMembers
       * @methodOf coyo.domain.PageModel
       *
       * @description
       * Returns the IDs of the users and groups that are a member of the page.
       *
       * @returns {promise} An $http promise
       */
      getMembers: function () {
        return UserModel.$get(this.$url('members'));
      },

      /**
       * @ngdoc function
       * @name coyo.domain.PageModel#isTranslationRequired
       * @methodOf coyo.domain.PageModel
       *
       * @description
       * Provide the information whether multi language elements should be shown or not, based on the state of the
       * page and the given values.
       *
       * @param {object} availableTranslations The available translations.
       * @param {string} currentLanguage The current language the user have selected.
       * @param {string} language The affected language for which the requirement should be checked.
       * @returns {boolean} True, if the multi language elements should be shown or not.
       */
      isTranslationRequired: function (availableTranslations, currentLanguage, language) {
        if (this.defaultLanguage === 'NONE') {
          return language === 'NONE';
        } else if (currentLanguage === language) {
          return true;
        } else {
          return (availableTranslations[language].translations && Object.keys(
              availableTranslations[language].translations).length !== 0);
        }
      },

      /**
       * @ngdoc function
       * @name coyo.domain.PageModel#create
       * @methodOf coyo.domain.PageModel
       *
       * @description
       * Create a page.
       *
       * @returns {promise} An $http promise
       */
      create: function () {
        var page = this;
        return this.$post(this.$url(), page).then(function (result) {
          $rootScope.$emit('page:subscribed', page);
          return result;
        });
      },

      /**
       * @ngdoc function
       * @name coyo.domain.PageModel#delete
       * @methodOf coyo.domain.PageModel
       *
       * @description
       * Delete a page.
       *
       * @returns {promise} An $http promise
       */
      delete: function () {
        var page = this;
        return this.$delete(this.$url()).then(function (result) {
          $rootScope.$emit('page:unSubscribed', page.id);
          return result;
        });
      }
    });

    return PageModel;
  }

})(angular);

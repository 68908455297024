import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe to transform a content type to a type name.
 */
@Pipe({
  name: 'fileTypeName'
})
export class FileTypeNamePipe implements PipeTransform {

  private PATTERN: RegExp = /^[a-z-]+\/[a-z-.+]+($)?/;

  /**
   * Converts a content (mime) type into a type name.
   *
   * @param contentType content type as string
   * @return type name
   */
  transform(contentType: string): string {
    return this.emitTypeName(contentType);
  }

  private emitTypeName(contentType: string): string {
    if (contentType && this.matchesPattern(contentType)) {
      if (this.isMicrosoftOfficeFile(contentType)) {
        if (this.matches(contentType, /\/word/)) {
          return 'FILE_TYPE_NAME.WORD';
        }

        if (this.matches(contentType, /\/sheet/)) {
          return 'FILE_TYPE_NAME.EXCEL';
        }

        if (this.matches(contentType, /\/presentation/)) {
          return 'FILE_TYPE_NAME.POWERPOINT';
        }
      }
      if (this.isGoogleFile(contentType)) {
        if (this.matches(contentType, /\/spreadsheet/)) {
          return 'FILE_TYPE_NAME.GOOGLE_SPREADSHEET';
        }
        if (this.matches(contentType, /\/document/)) {
          return 'FILE_TYPE_NAME.GOOGLE_DOCUMENT';
        }
        if (this.matches(contentType, /\/presentation/)) {
          return 'FILE_TYPE_NAME.GOOGLE_PRESENTATION';
        }
      }

      // Images
      if (this.matches(contentType, /^image\//)) {
        return 'FILE_TYPE_NAME.IMAGE';
      }

      // Videos
      if (this.matches(contentType, /^video\//)) {
        return 'FILE_TYPE_NAME.VIDEO';
      }

      // PDF
      if (this.matches(contentType, /\/pdf/)) {
        return 'FILE_TYPE_NAME.PDF';
      }

      // Text
      if (this.matches(contentType, /\/plain/)) {
        return 'FILE_TYPE_NAME.PLAIN';
      }
    }
    return 'FILE_TYPE_NAME.DEFAULT';
  }

  private isMicrosoftOfficeFile(contentType: string): boolean {
    return this.matches(contentType, /^officedocument\//);
  }

  private isGoogleFile(contentType: string): boolean {
    return this.matches(contentType, /^google-apps\//);
  }

  private matches(contentType: string, regex: RegExp): boolean {
    return regex.test(contentType);
  }

  private matchesPattern(contentType: string): boolean {
    return this.PATTERN.test(contentType);
  }
}

import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Provider,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {TranslationService} from '@core/i18n/translation-service/translation.service';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const datePickerValueProvider: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

/**
 * The date picker component.
 */
@Component({
  selector: 'coyo-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [datePickerValueProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent implements OnInit, AfterViewChecked, ControlValueAccessor {

  /**
   * The initial date.
   */
  @Input() date?: Date = new Date();

  /**
   * The selected date.
   */
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  /**
   * The placeholder for the input form.
   */
  @Input() placeholder: string = '';

  @ViewChild('datepickerInput', {
    static: true
  }) datepickerInput: ElementRef;

  disabled: boolean = false;
  isMobile$: Observable<boolean>;
  private onChangeFn: (date: Date) => void;
  private lastValidViewValue: string;

  constructor(private translationService: TranslationService,
              private dateAdapter: DateAdapter<Date>,
              private windowSizeService: WindowSizeService) { }

  ngOnInit(): void {
    const locale: string = this.translationService.getActiveLanguage() || 'en';
    this.dateAdapter.setLocale(locale);
    this.isMobile$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS || screenSize === ScreenSize.SM));
  }

  ngAfterViewChecked(): void {
    const inputElementValue = this.datepickerInput.nativeElement.value;
    if (inputElementValue && inputElementValue !== 'Invalid date') {
      this.lastValidViewValue = inputElementValue;
    }
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fn: (date: Date) => void): void {
    this.onChangeFn = fn;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: (date: Date) => void): void {
  }

  /* tslint:disable-next-line:completed-docs */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(value: Date): void {
    this.date = value;
  }

  /**
   * Emits when the datepicker value changes and is valid.
   * @param datepickerInputEvent The datepicker event with the selected date
   */
  datepickerValueChange(datepickerInputEvent: MatDatepickerInputEvent<Date>): void {
    if (!datepickerInputEvent || !this.isValidDate(datepickerInputEvent.value)) {
      return;
    }
    if (typeof this.onChangeFn === 'function') {
      this.onChangeFn(datepickerInputEvent.value);
    }
    this.dateChange.emit(datepickerInputEvent.value);
    this.lastValidViewValue = this.datepickerInput.nativeElement.value;
  }

  /**
   * Resets the input value and update model value manually.
   */
  resetInputValue(): void {
    if (this.lastValidViewValue) {
      const inputElement = this.datepickerInput.nativeElement;
      if (this.lastValidViewValue !== inputElement.value) {
        inputElement.value = this.lastValidViewValue;
        inputElement.dispatchEvent(new Event('change'));
      }
    }
  }

  private isValidDate(date: Date): boolean {
    return date && !isNaN(date.getTime());
  }

}

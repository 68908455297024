import {compose} from '@ngxs/store/operators';

type RemoveValues<T> = {
  readonly [P in keyof T]?: T[P] extends (...args: any[]) => infer R ? R : T[P];
};

type RemoveOperator<T> = <U extends RemoveValues<T>>(existing: Readonly<U>) => U;

export function remove<T>(...properties: string[]): RemoveOperator<T> {
  return compose(...properties.map(property =>
    function removeStateOperator<U extends RemoveValues<T>>(existing: Readonly<U>): U {
      // @ts-ignore
      const {[property]: value, ...remainingState} = existing;
      return remainingState as U;
    }
  ));
}

import {Injectable} from '@angular/core';
import {STATE_NAME_ENGAGE_TERMS_VIEW} from '@app/engage/terms/terms.state';
import {StateService} from '@uirouter/core';

/**
 * Service for changing the state to show a the terms of use.
 */
@Injectable({
  providedIn: 'root'
})
export class EngageTermsViewHandlerService {

  constructor(private stateService: StateService) {
  }

  /**
   * Move to the Engage terms of use view state.
   */
  showTermsView(): void {
    this.stateService.go(STATE_NAME_ENGAGE_TERMS_VIEW);
  }
}

import {Injectable} from '@angular/core';
import {App} from '@apps/api/app';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Action, State, StateContext} from '@ngxs/store';
import {SelectUiSettings} from '@shared/select-ui/select-ui.settings';
import {Destroy, Load, LoadMore} from '@shared/select-ui/state/select-ui-component.actions';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * The model representing a single latest blog article widget state
 */
export interface SelectUiComponentStateModel {
  loading: boolean;
  itemCount: number;
  currentPage: number;
  lastPage: boolean;
  items: any[];
  typeahead: string;
  searchTerm: string;
}

/**
 * The global model for the latest blog article state
 * Maps widget id to widget state
 */
export interface SelectUiComponentStatesModel {
  [key: string]: SelectUiComponentStateModel;
}

/**
 * The actual widget state logic for the latest blog article widget
 */
@Injectable({providedIn: 'root'})
@State<SelectUiComponentStatesModel>({
  name: 'selectUiComponent',
  defaults: {}
})
export class SelectUiComponentState {

  private get emptyState(): SelectUiComponentStateModel {
    return {
      items: [],
      loading: true,
      itemCount: 0,
      currentPage: -1,
      lastPage: false,
      typeahead: '',
      searchTerm: ''
    };
  }

  @Action(Load, { cancelUncompleted: true })
  load(ctx: StateContext<SelectUiComponentStatesModel>, action: Load): Observable<any> {
    this.resetState(ctx, action.id);
    const newState = this.emptyState;
    newState.searchTerm = !!action.typeahead ? action.typeahead : '';
    return this.createSearchRequest(newState, action.settings, newState.searchTerm).pipe(
      map(page => this.mergeResult(ctx, action.id, newState, page)));
  }

  @Action(LoadMore)
  loadMore(ctx: StateContext<SelectUiComponentStatesModel>, action: LoadMore): Observable<any> {
    const currentState = this.getCurrentState(ctx, action.id);
    if (!currentState.lastPage && !currentState.loading) {
      this.patchState(ctx, action.id, {loading: true});
      return this.createSearchRequest(currentState, action.settings, currentState.searchTerm).pipe(
        map(page => this.mergeResult(ctx, action.id, currentState, page)));
    }
  }

  @Action(Destroy)
  destroy(ctx: StateContext<SelectUiComponentStatesModel>, action: Destroy): void {
    ctx.setState({..._.omit(ctx.getState(), action.id)});
  }

  private mergeResult(
    ctx: StateContext<SelectUiComponentStatesModel>,
    id: string,
    currentState: SelectUiComponentStateModel,
    page: Page<App<any>>): App<any>[] {

    const newState = this.emptyState;
    newState.items = _.union([...currentState.items, ...page.content], (app: App<any>) => app.id);
    newState.currentPage = page.number;
    newState.loading = false;
    newState.itemCount = !!newState.items ? newState.items.length : 0;
    newState.lastPage = page.last;
    this.patchState(ctx, id, newState);
    return page.content;
  }

  private getCurrentState(ctx: StateContext<SelectUiComponentStatesModel>, id: string): SelectUiComponentStateModel {
    let currentState = ctx.getState()[id];
    if (!currentState) {
      currentState = this.emptyState;
      ctx.patchState({[id]: currentState});
    }
    return currentState;
  }

  private patchState(ctx: StateContext<SelectUiComponentStatesModel>, id: string, values: any): SelectUiComponentStateModel {
    const currentState = this.getCurrentState(ctx, id);
    const newState = {...currentState, ...values};
    ctx.patchState({[id]: newState});
    return newState;
  }

  private setState(ctx: StateContext<SelectUiComponentStatesModel>, id: string, state: SelectUiComponentStateModel): SelectUiComponentStateModel {
    ctx.patchState({[id]: state});
    return state;
  }

  private resetState(ctx: StateContext<SelectUiComponentStatesModel>, id: string): void {
    this.setState(ctx, id, this.emptyState);
  }

  private createSearchRequest(state: SelectUiComponentStateModel, settings: SelectUiSettings<any>, searchTerm: string): Observable<Page<any>> {
    return settings.searchFn(new Pageable(state.currentPage + 1, settings.pageSize), searchTerm);
  }
}

import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ChangeDetectionStrategy, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SubscriberInfoDataSource} from '@app/pages/subscriber-data-source/subscriber-info-data-source';
import {Skeleton} from '@coyo/ui/lib/ui/skeleton/skeleton';
import {User} from '@domain/user/user';

/**
 * Modal showing a list of users who are subscribed to the given target.
 */
@Component({
  selector: 'coyo-subscriber-modal',
  templateUrl: './subscriber-modal.component.html',
  styleUrls: ['./subscriber-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriberModalComponent {

  readonly itemHeight: number = 60 + 1;

  readonly skeletons: Skeleton[] = [
    {top: 10, left: 24, width: 40, height: 40, radius: '50%'},
    {top: 12, left: 72, width: 'calc(70% - 70px)', height: 16},
    {top: 35, left: 72, width: 'calc(40% - 70px)', height: 14}
  ];

  @ViewChild(CdkVirtualScrollViewport, {
    static: true
  }) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  constructor(@Inject(MAT_DIALOG_DATA) public subscriberInfoDataSource: SubscriberInfoDataSource) {
  }

  search(term: string): void {
    this.subscriberInfoDataSource.setTerm(term);
    this.cdkVirtualScrollViewport.scrollToIndex(0);
  }

  trackById(index: number, user: User | undefined): string {
    return user ? user.id : null;
  }
}

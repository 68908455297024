/**
 * Parses the url search params.
 *
 * @returns The url search parameters of the current url
 */
function getUrlParams(): URLSearchParams {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}

/**
 * Check if the current url includes the paramters that indicate an Engage WebView Init call.
 *
 * @returns a boolean
 */
export function isEngageInitCall(): boolean {
  const urlParams = getUrlParams();

  const userId = urlParams.get('userId');
  const clientId = urlParams.get('clientId');
  const backendUrl = urlParams.get('backendUrl');
  return !!userId && !!clientId && !!backendUrl;
}

/**
 * Setting up the local storage because the ios webview sometimes loses it on new instances.
 */
export function initLocalStorageForEngageWebView(): void {
  const urlParams = getUrlParams();
  const userId = urlParams.get('userId');
  const clientId = urlParams.get('clientId');
  const backendUrl = urlParams.get('backendUrl');

  window.localStorage.setItem('ngStorage-isEngageWebView', JSON.stringify(true));
  window.localStorage.setItem('ngStorage-userId', JSON.stringify(userId));
  window.localStorage.setItem('ngStorage-clientId', JSON.stringify(clientId));
  window.localStorage.setItem('ngStorage-isAuthenticated', JSON.stringify(true));
  window.localStorage.setItem('ngStorage-backendUrl', JSON.stringify(decodeURIComponent(backendUrl)));
}

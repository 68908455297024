<div *ngIf="sender.imageUrls?.avatar && sender.active">
  <coyo-sender-avatar [sender]="sender" [avatarSize]="avatarSize$ | async"></coyo-sender-avatar>
</div>
<div class="share-item-message" *ngIf="sender.active">
  <a class="share-item-title" [uiSref]="'main.' + sender.typeName + '.show'" [uiParams]="{idOrSlug: sender.slug}">
    {{sender.title}}
  </a>
  <coyo-collapsible-content [content]="sender.description" class="share-item-description" *ngIf="sender.description">
  </coyo-collapsible-content>
  <div class="share-item-labels">
    <span class="label label-muted" *ngIf="sender.public"><i class="zmdi zmdi-globe"></i></span>
    <span class="label label-muted" *ngIf="sender.subscriptions"><i class="zmdi zmdi-accounts"></i>&nbsp;{{sender.subscriptions}}</span>
  </div>
</div>
<div class="share-item-message" *ngIf="!sender.active">
  <div class="alert alert-warning" [translate]="'MODULE.TIMELINE.' + sender.typeName.toUpperCase() + '.WARNING'"></div>
</div>

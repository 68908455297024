import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Page} from '@domain/pagination/page';
import {User} from '@domain/user/user';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterestingColleaguesService {

  private url: string = '/web/widgets/interestingcolleagues';

  constructor(@Inject(HttpClient) private http: HttpClient) {
  }

  getInterestingColleaguesList(): Observable<Page<User>> {
    return this.http.get<Page<User>>(this.url);
  }
}

import {AfterViewInit, Directive, HostListener, Inject} from '@angular/core';
import {WINDOW} from '@root/injection-tokens';

@Directive({
  selector: '[coyoAnchorContainer]'
})
export class AnchorContainerDirective implements AfterViewInit {

  constructor(@Inject(WINDOW) private window: Window) {
  }

  ngAfterViewInit(): void {
    const currentLocationHash = this.window.location.hash;
    if (currentLocationHash !== '') {
      this.window.location.hash = '';
      this.window.location.hash = currentLocationHash;
    }
  }

  @HostListener('click', ['$event']) onClick($event: MouseEvent): void {
    const target = $event.target as HTMLAnchorElement;
    if (target.getAttribute('coyo-anchor') !== null) {
      // reset to empty hash before setting it to the real value so scrolling works multiple times instead of just once
      this.window.location.hash = '';
      this.window.location.hash = target.hash;
      // reset base URL so anchor links can be opened in a new tab
      target.href = this.window.location.href;
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {LaunchpadCategory} from '@domain/launchpad/launchpad-category';

/**
 * A simple pipe to generate the color for the link icons in a launchpad category.
 */
@Pipe({
  name: 'launchpadCategoryColor'
})
export class LaunchpadCategoryColorPipe implements PipeTransform {

  /**
   * The available colors for a launchpad category.
   */
  readonly COLORS: string[] = ['#0073E6', '#697687', '#9A33FF', '#FFAB00', '#9BBF29', '#CC4B2B'];

  // tslint:disable:no-bitwise
  private static hash(input: string): number {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const chr = input.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return Math.abs(hash);
  }

  /**
   * Generates the color for the link icons in a launchpad category.
   *
   * @param category the launchpad category
   * @return the HEX icon color
   */
  transform(category: LaunchpadCategory): string {
    return category
      ? this.COLORS[LaunchpadCategoryColorPipe.hash(category.id) % this.COLORS.length]
      : this.COLORS[0];
  }
}

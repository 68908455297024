import {Injectable} from '@angular/core';
import {IntegrationApiService} from '@app/integration/integration-api/integration-api.service';
import {Drive} from '@app/integration/o365/o365-api/domain/drive';
import {DriveItem} from '@app/integration/o365/o365-api/domain/drive-item';
import {SharePointSearchRow} from '@app/integration/o365/o365-api/domain/share-point-search-row';
import {Site} from '@app/integration/o365/o365-api/domain/site';
import {BETA, GraphApiService, V1} from '@app/integration/o365/o365-api/graph-api.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import * as _ from 'lodash';
import * as mime from 'mime';
import {Observable, of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';
import {Insight} from './domain/insight';
import {SharePointApiService} from './share-point-api.service';

/**
 * Represents the claims that can be read from the office JWT token
 */
export type O365TokenClaims = {
  scp: string
};

/**
 * Service for interactions with the O365 integration.
 */
@Injectable({
  providedIn: 'root'
})
export class O365ApiService {

  static readonly INTEGRATION_TYPE: string = 'OFFICE_365';
  static readonly SHAREPOINT_ROW_LIMIT: number = 100;

  constructor(private integrationApiService: IntegrationApiService,
              private graphApi: GraphApiService,
              private sharepointApi: SharePointApiService,
              private jwtHelper: JwtHelperService) {
  }

  /**
   * This method provides the state of the O365 integration availability as
   * stream to subscribe.
   *
   * @returns an `Observable` holding the emitted O365 integration activation status.
   */
  isApiActive(): Observable<boolean> {
    return this.integrationApiService.updateAndGetActiveState(O365ApiService.INTEGRATION_TYPE);
  }

  /**
   * Returns the JWT token claims from the office token
   *
   * @returns an `Observable` holding the emitted token claims
   */
  getTokenClaims(): Observable<O365TokenClaims> {
    return this.integrationApiService.getToken().pipe(map(accessToken => this.jwtHelper.decodeToken(accessToken.token)));
  }

  /**
   * This method returns all visible sites of the SharePoint
   *
   * @returns an `Observable` with an array of site information ordered asc by displayName
   */
  getSites(): Observable<Site[]> {
    return this.graphApi.searchInMultiGeoLocations('*')
      .pipe(map(sites => _.orderBy(sites as Site[], ['displayName'], ['asc'])));
  }

  /**
   * This method returns recent used files
   *
   * @returns an `Observable` with an array of drive items
   */
  getRecentFiles(): Observable<DriveItem[]> {
    return this.graphApi.get('/me/insights/used?$filter=ResourceVisualization/containerType eq \'Site\'', BETA)
      .pipe(map(items => _.map(items as Insight[], item => {
        const driveId = /drives\/(.*)\/items/g.exec(item.resourceReference.id)[1];
        const id = /items\/(.*)/g.exec(item.resourceReference.id)[1];
        return {
          id,
          name: `${item.resourceVisualization.title}.${mime.getExtension(item.resourceVisualization.mediaType)}`,
          file: {
            mimeType: item.resourceVisualization.mediaType
          },
          parentReference: {
            driveId
          },
          size: -1,
          lastModifiedDateTime: item.lastUsed.lastModifiedDateTime
        } as DriveItem;
      })));
  }

  /**
   * This method returns the user organization default SharePoint site
   *
   * @returns an `Observable` with an object with the site information
   */
  getDefaultSite(): Observable<Site> {
    return this.graphApi.get('/sites/root');
  }

  /**
   * This method returns all drives of a site
   *
   * @param siteId the ID of the site
   * @returns an `Observable` with an array of drives sorted asc by name
   */
  getDrivesBySiteId(siteId: string): Observable<Drive[]> {
    return this.graphApi.get(`/sites/${siteId}/drives`);
  }

  /**
   * This method returns all driveItems of a drive
   *
   * @param driveId the ID of the drive
   * @param driveItemId the ID of the drive item. The default is 'root'.
   * @returns an `Observable` with an array of drivesItems sorted first folder asc by name then files asc by name
   */
  getDriveItems(driveId: string, driveItemId: string = 'root'): Observable<DriveItem[]> {
    return this.graphApi.get(`/drives/${driveId}/items/${driveItemId}/children`);
  }

  /**
   * This method returns a drive item by id
   *
   * @param driveId The ID of the drive the file is located in
   * @param driveItemId the ID of the item to return
   * @returns an observable with the fetched item
   */
  getDriveItem(driveId: string, driveItemId: string): Observable<DriveItem> {
    return this.graphApi.get(`/drives/${driveId}/items/${driveItemId}`);
  }

  /**
   * This method searches for sites
   *
   * @param query the query to be made
   * @return An observable with an array of sites
   */
  searchForSites(query: string): Observable<Site[]> {
    return this.graphApi.searchInMultiGeoLocations(query)
      .pipe(map(sites => _.orderBy(sites as Site[], ['displayName'], ['asc'])));
  }

  /**
   * This method searches for files in SharePoint
   *
   * @param query the query to be made
   * @returns an observable with an array SharePoint files
   */
  searchForDriveItems(query: string): Observable<DriveItem[]> {
    return this.sharepointApi.getSharePointTokenInfo().pipe(flatMap(sharepointTokenInfo =>
      this.sharepointApi
        .getWithToken(sharepointTokenInfo.sharepointUrl, sharepointTokenInfo.token, sharepointTokenInfo.multiGeo ?
          this.getMultiGeoSearchEndpoint(query, sharepointTokenInfo.clientId) : this.getSimpleSearchEndpoint(query))
        .pipe(flatMap(rows => this.getDriveItemsBySharePointRows(rows)))));
  }

  /**
   * This method search for unread emails in outlook
   * @returns an observable of boolean
   */
  hasUnreadOutlookEmails(): Observable<boolean> {
    const headers = {handleErrors: 'false'};
    return this.graphApi.get<{ '@odata.count': number }>('/me/mailFolders/Inbox/messages?$filter=isRead eq false&$count=true', V1, true, headers)
      .pipe(map(response => response['@odata.count'] > 0))
      .pipe(catchError(() => of(false)));
  }

  /**
   * Returns the sharepoint root url. (Default geo location)
   * @returns an observable of string
   */
  getSharePointUrl(): Observable<string> {
    return this.graphApi
      .get<{ webUrl: string }>('/sites/root?$select=webUrl')
      .pipe(map(response => response.webUrl));
  }

  private getDriveItemsBySharePointRows(rows: SharePointSearchRow[]): Observable<DriveItem[]> {
    const endpoints = rows
      .map(row => this.adaptPathForImages(row))
      .map(path => this.generateShareIdBySharePointFilePath(path))
      .map(shareId => `/shares/${shareId}/driveitem`);

    return this.graphApi
      .getBatch<DriveItem>(endpoints)
      .pipe(map(items => items.filter(item => item !== null)));
  }

  private generateShareIdBySharePointFilePath(path: string): string {
    return 'u!' + btoa(unescape(encodeURIComponent(path))).replace(/=*$/, '').replace('/', '_').replace('+', '-');
  }

  private getCellValue(row: SharePointSearchRow, key: string): string | undefined {
    const cell = row.Cells.find(c => c.Key === key);
    return !!cell ? cell.Value : undefined;
  }

  /*
  The SharePoint API returns a path like .../Forms/DispForm.aspx?ID=7 for images.
  So in that case the path has to be built from ParentLink and the result's Title.
  */
  private adaptPathForImages(row: SharePointSearchRow): string | undefined {
    if (this.getCellValue(row, 'Path').indexOf('/Forms/DispForm.aspx?ID=') >= 0) {
      return this.getCellValue(row, 'ParentLink') + '/' + this.getCellValue(row, 'Title') + '.' + this.getCellValue(row, 'FileType');
    }
    return this.getCellValue(row, 'Path');
  }

  private getMultiGeoSearchEndpoint(query: string, clientId: string): string {
    return `/search/query?sortlist='rank:descending,modifiedby:ascending'&queryText='${query}'
          &selectproperties='Title,Path,FileType,ParentLink'&rowLimit=${O365ApiService.SHAREPOINT_ROW_LIMIT}
          &Properties='EnableMultiGeoSearch:true'&ClientType='${clientId}'`;
  }

  private getSimpleSearchEndpoint(query: string): string {
    return `/search/query?sortlist='rank:descending,modifiedby:ascending'&queryText='${query}'
    &selectproperties='Title,Path,FileType,ParentLink'&rowLimit=${O365ApiService.SHAREPOINT_ROW_LIMIT}`;
  }
}

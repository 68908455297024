import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {SocketService} from '@core/socket/socket.service';
import {TimelineItem} from '@domain/timeline-item/timeline-item';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import {Subscription} from 'rxjs';

/**
 * Displays a single timeline item.
 */
@Component({
  selector: 'coyo-timeline-item-routing',
  templateUrl: './timeline-item-routing.component.html',
  styleUrls: ['./timeline-item-routing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemRoutingComponent implements OnInit, OnDestroy {

  /**
   * The timeline item
   */
  @Input() item: TimelineItem;

  private subscription: Subscription;

  constructor(private socketService: SocketService,
              private notificationService: NotificationService,
              @Inject(NG1_STATE_SERVICE) private stateService: IStateService) {
  }

  ngOnInit(): void {
    this.subscription = this.socketService.listenTo$('/topic/timeline.item', 'deleted', this.item.id, this.item.subscriptionInfo.token).subscribe(() => {
      this.notificationService.warning('MODULE.TIMELINE.ITEM.DELETED');
      this.stateService.go('main');
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {WidgetWikiArticleResponse} from '@widgets/wiki-article/widget-wiki-article-response';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WikiArticleWidgetService {

  constructor(private http: HttpClient) {
  }

  getWikiArticlePage(pageable: Pageable, searchTerm: string): Observable<Page<WidgetWikiArticleResponse>> {
    return this.http.get<Page<WidgetWikiArticleResponse>>('/web/widgets/wikiarticle',
      {params: pageable.toHttpParams({title: searchTerm})});
  }

  getWikiArticle(id: string): Observable<WidgetWikiArticleResponse> {
    return this.http.get<WidgetWikiArticleResponse>(`/web/widgets/wikiarticle/article/${id}`, {
      headers: new HttpHeaders({
        handleErrors: 'false'
      })
    });
  }
}

import {APP_INITIALIZER, Injector, ModuleWithProviders, NgModule} from '@angular/core';
import {changePasswordState} from '@app/login/login.state';
import {AuthService} from '@core/auth/auth.service';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {StateService, TransitionService} from '@uirouter/core';
import {NG1_DEEP_LINK_SERVICE} from '@upgrade/upgrade.module';
import {ChangePasswordComponent} from './change-password/change-password.component';

const uiRouterModuleChild: ModuleWithProviders<UIRouterUpgradeModule> =
  UIRouterUpgradeModule.forChild({states: [changePasswordState]});

export function stateInterceptor(transitionService: TransitionService, authService: AuthService,
                                 stateService: StateService,
                                 injector: Injector): Function {
  return () => transitionService.onStart({
      to: state => state.name !== 'front.changePassword'
    },
    transition => {
      if (transition.to().data.authenticate && authService.isAuthenticated() && !authService.isSsoLogin()) {
        return authService.getUser(transition.from().name === 'front.changePassword').toPromise()
          .then(user => {
            const deepLinkService = injector.get(NG1_DEEP_LINK_SERVICE);
            const stateParams = deepLinkService.getReturnToStateParams();
            if (user.isTemporaryPassword && !stateParams?.userId && !stateParams?.secret) {
              deepLinkService.setReturnToState(transition.to().name, transition.params());
              return stateService.go('front.changePassword').then();
            } else {
              return Promise.resolve();
            }
          }, error => Promise.reject(error));
      } else {
        return Promise.resolve();
      }
    });
}

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    uiRouterModuleChild
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: stateInterceptor,
    deps: [TransitionService, AuthService, StateService, Injector],
    multi: true
  }]
})
export class LoginModule {
}

(function () {
  'use strict';

  addEntryModalService.$inject = ["modalService"];
  AddEntryModalController.$inject = ["$injector", "$uibModalInstance", "app", "fields", "listService"];
  angular.module('coyo.apps.list')
      .factory('addEntryModalService', addEntryModalService)
      .controller('AddEntryModalController', AddEntryModalController);

  /**
   * @ngdoc service
   * @name coyo.apps.list.addEntryModalService
   *
   * @description
   * Displays a modal to add a new entry to the list
   */
  function addEntryModalService(modalService) {

    return {
      open: open
    };

    /**
     * @ngdoc method
     * @name coyo.apps.list.addEntryModalService#open
     * @methodOf coyo.apps.list.addEntryModalService
     *
     * @description
     * Opens the modal to create a new field for the list app by field type.
     *
     * @returns {object}
     * Returns a promise with the newly saved field.
     */
    function open(app) {
      return modalService.open({
        size: 'lg',
        templateUrl: 'app/apps/list/components/add-entry-modal.html',
        controller: 'AddEntryModalController',
        controllerAs: '$ctrl',
        resolve: {
          app: function () {
            return app;
          },
          fields: /*@ngInject*/ ["FieldModel", function (FieldModel) {
            var context = {
              senderId: app.senderId,
              appId: app.id,
              appKey: app.key
            };
            return FieldModel.get(context);
          }]
        }
      }).result;
    }
  }

  function AddEntryModalController($injector, $uibModalInstance, app, fields, listService) {
    var vm = this;
    vm.fields = fields;

    vm.appFormSettings = {noAppContext: app.settings.permissionRead !== 'ALL'};

    vm.save = save;
    vm.$onInit = _init;

    function save() {
      vm.entry.senderId = app.senderId;
      vm.entry.appId = app.id;
      return vm.entry.save().then(function (result) {

        // UiBootstrap's datepicker has problems with the string dates returned by our backend.
        // We hence convert them back to JS dates, if they are set.
        _(result.values).filter(function (val) {
          var isSet = !!val.value;
          var isDate = _.find(vm.fields, {id: val.fieldId}).key === 'date';
          return isSet && isDate;
        }).forEach(function (val) {
          val.value = new Date(val.value);
        });

        $injector.get('ngxNotificationService').success('APP.LIST.ADD_ENTRY.SUCCESS');
        return $uibModalInstance.close(result).result;
      }, function () {
        $injector.get('ngxNotificationService').error('APP.LIST.ADD_ENTRY.ERROR');
      });
    }

    function _init() {
      vm.entry = listService.createEntry();
    }
  }

})();

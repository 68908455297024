import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {PdfViewerLoader} from '@shared/pdf/pdf-viewer-loader/pdf-viewer-loader';
import {PdfViewerComponent} from '@shared/pdf/pdf-viewer/pdf-viewer.component';

/**
 * A PDF viewer.
 */
@Component({
  selector: 'coyo-pdf-viewer-loader',
  template: ''
})
export class PdfViewerLoaderComponent implements OnInit, OnChanges {

  /**
   * The source of the pdf
   */
  @Input() src: string;

  /**
   * If the top navigation toolbar of the PDF viewer should be rendered
   */
  @Input() showToolbar: boolean;

  /**
   * Emitted after the pdf is fully loaded
   */
  @Output() loadingCompleted: EventEmitter<void> = new EventEmitter();

  /**
   * Emitted if error occurred during loading the pdf
   */
  @Output() loadingFailed: EventEmitter<void> = new EventEmitter();

  loading: boolean = true;

  private pdfViewerComponent: PdfViewerComponent;

  constructor(private pdfViewerLoader: PdfViewerLoader, private vcr: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.createPdfViewerComponent().then(pdfViewerComponent => {
      this.pdfViewerComponent = pdfViewerComponent;
      this.pdfViewerComponent.src = this.src;
      this.pdfViewerComponent.showToolbar = this.showToolbar;
      this.pdfViewerComponent.loadingCompleted.subscribe(
        () => this.loadingCompleted.emit(),
        (err: Error) => this.loadingCompleted.error(err),
        () => this.loadingCompleted.complete()
      );
      this.pdfViewerComponent.loadingFailed.subscribe(
        () => this.loadingFailed.emit(),
        (err: Error) => this.loadingFailed.error(err),
        () => this.loadingFailed.complete()
      );
    });
  }

  private createPdfViewerComponent(): Promise<PdfViewerComponent> {
    return this.pdfViewerLoader.load()
      .then(factory => this.vcr.createComponent(factory).instance) as Promise<PdfViewerComponent>;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.pdfViewerComponent) {
      return;
    }

    this.updatePdfViewerValues(changes);
    this.pdfViewerComponent.ngOnChanges(changes);
  }

  private updatePdfViewerValues(changes: SimpleChanges): void {
    Object
      .keys(changes)
      .forEach(key => this.pdfViewerComponent[key] = changes[key].currentValue);
  }
}

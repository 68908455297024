<div class="media-widget-settings" [formGroup]="parentForm">
  <section class="album" formGroupName="album">
    <mat-form-field appearance="outline" class="title">
      <mat-label translate="WIDGET.MEDIA.SETTINGS.INFORMATION.ALBUM_TITLE.LABEL"></mat-label>
      <input matInput
             id="album-title"
             title="album-title"
             type="text"
             formControlName="title">
    </mat-form-field>
    <mat-form-field appearance="outline" class="location">
      <mat-label translate="WIDGET.MEDIA.SETTINGS.INFORMATION.ALBUM_LOCATION.LABEL"></mat-label>
      <input matInput id="album-location"
             title="album-location"
             type="text"
             formControlName="location">
      <mat-icon matSuffix svgIcon="location-pin"></mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline" class="description">
      <mat-label translate="WIDGET.MEDIA.SETTINGS.INFORMATION.ALBUM_DESCRIPTION.LABEL"></mat-label>
      <textarea matInput
                id="album-description"
                title="album-description"
                name="description"
                formControlName="description"></textarea>
    </mat-form-field>
  </section>
  <hr>
  <section class="media-list-section">
      <h5 translate="WIDGET.MEDIA.SETTINGS.MEDIA.TITLE"></h5>
      <coyo-select-file-button [options]="{filterContentType: ['image', 'video'], selectMode: 'multiple'}"
                               [sender]="sender$ | async"
                               [buttonTemplate]="buttonTemplate"
                               [selectedFiles]="mediaArray.getRawValue()"
                               (filesSelected)="onFilesAdded($event)">
        <ng-template #buttonTemplate>
          <mat-icon svgIcon="upload"></mat-icon>
          <span translate="WIDGET.MEDIA.SETTINGS.MEDIA.UPLOAD_BOX" class="hidden-sm-and-down"></span>
          <span translate="WIDGET.MEDIA.SETTINGS.MEDIA.UPLOAD_BOX_SHORT" class="hidden-md-and-up"></span>
        </ng-template>
      </coyo-select-file-button>
    <div class="selected-media" formArrayName="_media" *ngIf="mediaArray.controls.length">
      <div class="media-list" cdkDropList (cdkDropListDropped)="dropMedia($event)">
        <coyo-media-widget-settings-row *ngFor="let document of mediaArray.controls; let i = index"
                                        [formGroupName]="i"
                                        cdkDragLockAxis="y"
                                        cdkDrag
                                        cdkDragBoundary=".media-list"
                                        [file]="document.value"
                                        (deleted)="deleteMedia(document.value)"
                                        [parentForm]="document" class="media-row">
          <!--this is a hack to make drag handle work on sub components.-->
          <!--See https://stackoverflow.com/questions/54773383/cdkdraghandle-doesnt-work-inside-child-component-->
          <button mat-icon-button cdkDragHandle
                  [attr.aria-label]="'WIDGET.MEDIA.SETTINGS.MEDIA.DRAG_ARIA' | translate">
            <mat-icon svgIcon="drag-handle"></mat-icon>
          </button>
        </coyo-media-widget-settings-row>
      </div>
    </div>
  </section>

</div>

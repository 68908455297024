<div class="mention-reference" #popper="popper"
     [popper]="mentions"
     [popperDisableStyle]="true"
     [popperDisableAnimation]="true"
     [popperHideOnClickOutside]="true"
     [popperModifiers]="modifiers"
     [popperAppendTo]="appendTo"
     popperPlacement="bottom-start"
     popperApplyClass="mention-wrapper"
     popperTrigger="none">@</div>
<popper-content #mentions>
  <div class="mention-selection" *ngIf="page?.content.length">
    <ul role="menu" class="dropdown-menu">
      <li role="menuitem" *ngFor="let item of page.content; let i = index">
        <a href="#" (click)="select(item.user)" [class.hover]="i === _active" (mouseenter)="active = i">
          <coyo-sender-list-item [sender]="item.user" [noLink]="true" [subline]="item.subline" avatarSize="xs" role="option"></coyo-sender-list-item>
        </a>
      </li>
    </ul>
  </div>
</popper-content>

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SenderEvent} from '@domain/event/sender-event';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

/**
 * Event date label with image component
 */
@Component({
  selector: 'coyo-event-date-label',
  templateUrl: './event-date-label.component.html',
  styleUrls: ['./event-date-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDateLabelComponent implements OnInit {

  /**
   * The event to be shown.
   */
  @Input() event: SenderEvent;
  startDate: any;
  endDate: any;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
    const short = this.translateService.instant('TIME_FORMAT_SHORT');
    const long = this.translateService.instant('DATE_FORMAT_SHORT');

    const fullDay = this.event.fullDay;
    const startDatePattern = fullDay ? '' : short;

    const startDate = moment(this.event.startDate);
    const endDate = moment(this.event.endDate);

    let endDatePattern = fullDay ? '' : short;
    if (!startDate.isSame(endDate, 'day')) {
      endDatePattern = long + (fullDay ? '' : ', ') + endDatePattern;
    }
    this.startDate = startDatePattern && startDate.format(startDatePattern);
    this.endDate = endDatePattern && endDate.format(endDatePattern);
  }

}

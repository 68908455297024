export class Init {
  static readonly type: string = '[WikiArticleWidgetSettings] Init';
  constructor(public id: string) { }
}

export class SetLoading {
  static readonly type: string = '[WikiArticleWidgetSettings] Set loading';
  constructor(public loading: boolean) { }
}

export class Search {
  static readonly type: string = '[WikiArticleWidgetSettings] Search';
  constructor(public searchTerm: string) { }
}

export class TriggerSearch {
  static readonly type: string = '[WikiArticleWidgetSettings] Trigger';
  constructor(public searchTerm: string) { }
}

export class LoadMore {
  static readonly type: string = '[WikiArticleWidgetSettings] Load more';
  constructor() { }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {GSUITE, StorageType} from '@domain/attachment/storage';
import {CapabilitiesService} from '@domain/capability/capabilities/capabilities.service';
import {FileService} from '@domain/file/file/file.service';
import {ImagePreviewReference} from '@shared/preview/file-preview/file-image-preview/image-preview-reference';
import {ImageSize} from '@shared/preview/file-preview/image-size';
import {PreviewStatusService} from '@shared/preview/file-preview/preview-status/preview-status.service';
import {Subscription} from 'rxjs';
import {FilePreviewOptions} from '../file-preview-options';
import {FilePreviewStatus} from '../file-preview-status';

/**
 * This component handles image and GIF previews.
 */
@Component({
  selector: 'coyo-file-image-preview',
  templateUrl: './file-image-preview.component.html',
  styleUrls: ['./file-image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileImagePreviewComponent implements OnChanges, OnDestroy {

  /**
   * Attached file
   */
  @Input() file: ImagePreviewReference;
  /**
   * File preview Url
   */
  @Input() previewUrl: string;
  /**
   * File groupId
   */
  @Input() groupId: string;
  /**
   * File preview size
   */
  @Input() size: ImageSize;
  /**
   * File options
   */
  @Input() options: FilePreviewOptions = {backgroundImage: false};
  /**
   * Event emitted when preview cannot be processed
   */
  @Output() cannotProcess: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event emitted to update files status
   */
  @Output() statusUpdated: EventEmitter<FilePreviewStatus> = new EventEmitter<FilePreviewStatus>();

  loading: boolean = true;
  isProcessing: boolean = false;
  previewAvailable: boolean = false;
  conversionError: boolean = false;
  imageSrc: string = undefined;
  imageLoadingHelper: HTMLImageElement;
  loadingGif: boolean = false;
  gifIsRunning: boolean = false;
  imageSrcBackup: string;
  // As taken from ImageSize enum from backend:
  previewImageWidthDesktop: number = 800;
  previewImageWidthMobile: number = 400;

  private previewStatusChangeSubscription: Subscription;

  constructor(private capabilitiesService: CapabilitiesService,
              private ngZone: NgZone,
              private changeDetector: ChangeDetectorRef,
              private windowSizeService: WindowSizeService,
              private fileService: FileService,
              private previewStatusService: PreviewStatusService) {
  }

  ngOnDestroy(): void {
    this.unsubscribeFileStatus();
    this.unlistenImageLoadingHelper();
  }

  /**
   * Resets preview status when file changes
   *
   * @param changes to check file changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file && changes.file.currentValue !== undefined) {
      this.reset();
    }
  }

  /**
   * Checks if the file content type is gif and if the playback is allowed
   *
   * @returns true or false
   */
  isGifPlaybackAllowed(): boolean {
    if (this.file !== undefined && !this.loading) {
      return this.file.contentType === 'image/gif' && this.options.allowGifPlayback;
    }
    return false;
  }

  /**
   * Starts and stops gif animation
   *
   * @param $event OnClick event
   */
  onClickGifPlayButton($event: Event): void {
    if (!this.gifIsRunning) {
      this.gifIsRunning = true;
      this.imageSrcBackup = this.imageSrc;
      this.loadingGif = true;
      this.imageSrc = this.file.downloadUrl;
    } else {
      this.gifIsRunning = false;
      this.imageSrc = this.imageSrcBackup;
    }
    $event.preventDefault();
    $event.stopPropagation(); // prevent opening of file detail view
  }

  /**
   * Update status when image preview is loaded
   */
  imageLoaded(): void {
    this.loading = false;
    this.loadingGif = false;
    this.changeDetector.detectChanges();
  }

  /**
   * Check if image is ready to be previewed
   *
   * @returns true or false
   */
  shouldShowImgPreview(): boolean {
    return !this.options.backgroundImage && !this.loading && this.imagePreviewAvailable();
  }

  /**
   * Check if background image is ready to be previewed
   *
   * @returns true or false
   */
  shouldShowBackgroundImg(): boolean {
    return this.options.backgroundImage && this.imagePreviewAvailable();
  }

  private imagePreviewAvailable(): boolean {
    return this.imageSrc && this.previewAvailable && !this.isProcessing;
  }

  private unlistenImageLoadingHelper(): void {
    if (this.imageLoadingHelper) {
      this.imageLoadingHelper.onload = null;
      this.imageLoadingHelper.onerror = null;
      this.imageLoadingHelper = null;
    }
  }

  private unsubscribeFileStatus(): void {
    if (this.previewStatusChangeSubscription) {
      this.previewStatusChangeSubscription.unsubscribe();
      this.previewStatusChangeSubscription = null;
    }
  }

  private reset(): void {
    this.unlistenImageLoadingHelper();
    this.imageSrc = undefined;
    this.conversionError = false;
    this.previewAvailable = false;
    this.isProcessing = false;
    this.loadPreview();
  }

  private loadPreview(): void {
    this.loading = true;
    this.previewAvailable = true;
    this.capabilitiesService.previewImageFormat(this.file.contentType).subscribe(format => {
      if (this.file.id !== undefined) {
        if (this.isGSuiteFile(this.file)) {
          this.imageSrc = this.createGSuiteImagePreviewUrl();
          this.setImageLoadingHelper();
        } else {
          this.fileService.getImagePreviewUrl(this.file.previewUrl || this.previewUrl, this.groupId, this.file, this.size)
            .subscribe(url => {
              this.imageSrc = url;
              this.setImageLoadingHelper();
              this.changeDetector.detectChanges();
            });
        }
      }
    });
  }

  private setImageLoadingHelper(): void {
    /**
     * The imageLoadingHelper loads the same resource that is also loaded in the html img tag.
     * Thus the browser does not load unnecessary resources and we can receive load and error events which
     * we do not have access to in the css backgroundImage variant of displaying the image.
     */
    this.imageLoadingHelper = new Image();
    this.imageLoadingHelper.src = this.imageSrc;
    this.imageLoadingHelper.onload = () => this.ngZone.run(() => this.onLoad());
    this.imageLoadingHelper.onerror = () => this.ngZone.run(() => this.onError());
  }

  private isGSuiteFile(file: { storage?: StorageType }): boolean {
    return file.storage === GSUITE;
  }

  private createGSuiteImagePreviewUrl(): string {
    // Google has no official way to export a preview image in a certain resolution, yet. Therefore we use
    // the thumbnail url and modify the tailing size parameter to our needs. For example: '=s220' would
    // result in an image which larger edge has 220px. =w220 would result in an image of 220px width.
    // Same, of course, for =h220 for a certain image height.
    const width = this.isMobile() ? this.previewImageWidthMobile : this.previewImageWidthDesktop;
    return this.file.previewUrl.replace(/s\d+$/, 'w' + width);
  }

  private isMobile(): boolean {
    return this.windowSizeService.isXs() || this.windowSizeService.isSm();
  }

  private onError(): void {
    this.previewStatusChangeSubscription =
      this.previewStatusService.getPreviewStatus$(this.file, this.previewUrl, this.groupId)
        .subscribe(status => this.updateStatus(status));
  }

  private onLoad(): void {
    this.loading = false;
    this.isProcessing = false;
    this.changeDetector.detectChanges();
  }

  private updateStatus(status: FilePreviewStatus): void {
    this.statusUpdated.emit(status);
    this.setStatusToComponent(status);
    if (status.previewAvailable) {
      this.unsubscribeFileStatus();
      const timestamp = Date.now();
      this.imageSrc += '&' + timestamp; // IE requires a reload with new url
      this.changeDetector.detectChanges();
    }
    if (status.conversionError) {
      this.unsubscribeFileStatus();
    }
    if (this.isStatusCannotProcess(status)) {
      this.unsubscribeFileStatus();
      this.cannotProcess.emit(this.file.id);
    }
  }

  private isStatusCannotProcess(status: FilePreviewStatus): boolean {
    return !status.loading && !status.previewAvailable && !status.conversionError && !status.isProcessing;
  }

  private setStatusToComponent(status: FilePreviewStatus): void {
    this.loading = status.loading;
    this.conversionError = status.conversionError;
    this.isProcessing = status.isProcessing;
    this.previewAvailable = status.previewAvailable;
  }
}

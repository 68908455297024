import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {App} from '@apps/api/app';
import {AppConfig} from '@apps/api/app-config';
import {AppSettings} from '@apps/api/app-settings/app-settings';
import {IndividualAppSettingsContainerComponent} from '@apps/api/individual-app-settings-container/individual-app-settings-container.component';
import {AppService} from '@domain/apps/app.service';
import {Sender} from '@domain/sender/sender';
import {SenderService} from '@domain/sender/sender/sender.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationDialogService} from '@shared/dialog/confirmation-dialog/confirmation-dialog.service';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {LanguagePickerLanguage} from '@shared/multilanguage/language-picker/language';
import * as _ from 'lodash';
import {merge, Observable, ReplaySubject, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';

/**
 * Component responsible for render the app settings.
 */
@Component({
  selector: 'coyo-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSettingsComponent implements OnInit, OnDestroy {

  /**
   * The sender of the app.
   */
  @Input() sender: Sender;

  /**
   * The app configuration.
   */
  @Input() config: AppConfig<AppSettings>;

  /**
   * The app belonging to the settings. If given the values of the form will be set according to the app.
   */
  @Input() app?: App<AppSettings>;

  /**
   * Flag if the app is should be created or is already created.
   * On new apps the slug input field is not shown.
   */
  @Input() isNew: boolean;

  /**
   * The parent form object to take the input values.
   */
  @Input() parentForm: FormGroup;

  /**
   * Legacy output for not migrated apps. Emits if the angularJS form is changed its validity.
   *
   * @Deprecated
   */
  @Output() legacyFormValidityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  pendingValidation: boolean;

  language: string;

  defaultLanguage: string;

  availableLanguages$: Observable<LanguagePickerLanguage[]>;

  translationGroup: FormGroup;

  settingsFormGroup: FormGroup;
  private controlAdded$: Subject<void> = new Subject<void>();
  private destroyed$: Subject<void> = new ReplaySubject();

  @ViewChild(IndividualAppSettingsContainerComponent) individualAppSettingsContainer: IndividualAppSettingsContainerComponent;

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private senderService: SenderService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
  }

  ngOnInit(): void {
    this.parentForm.setControl('name',
      this.fb.control(this.app.name || this.translateService.instant(this.config.name),
        [Validators.required, Validators.maxLength(255)]));
    this.parentForm.setControl('active',
      this.fb.control(this.app.active ?? true, []));
    this.parentForm.setControl('slug',
      this.fb.control(this.app.slug,
        this.isNew ? [] : [Validators.required, Validators.pattern('^[a-z0-9]+(?:-[a-z0-9]+)*$')],
        [CoyoValidators.checkSlug(this.sender.id, this.app.id, this.appService)]));

    this.settingsFormGroup = this.fb.group({});
    this.parentForm.addControl('settings', this.settingsFormGroup);

    this.language = this.getDefaultLanguage();
    this.defaultLanguage = this.language;

    if (this.defaultLanguage !== SenderService.NO_DEFAULT_LANGUAGE) {
      this.parentForm.setControl('defaultLanguage',
        this.fb.control(this.defaultLanguage, []));
    }

    if (this.showTranslations()) {
      this.addTranslationFormFields();
    }

    this.availableLanguages$ = merge(this.controlAdded$, this.parentForm.statusChanges.pipe(startWith('VALID')))
      .pipe(takeUntil(this.destroyed$), map(() => this.getLanguages().map(language => ({
            language,
            valid: this.validateLanguage(language),
            created: language === this.defaultLanguage || !!this.translationGroup.get(language)
          })
        )
      )
    );

    // Needs to be done as the change detection is not running for async validators
    // see: https://github.com/angular/angular/issues/12378
    this.parentForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(status => {
      if (status === 'PENDING') {
        this.pendingValidation = true;
      } else if (this.pendingValidation) {
        this.pendingValidation = false;
        this.cd.markForCheck();
      }
    });
  }

  showTranslations(): boolean {
    return this.senderService.isTranslated(this.sender);
  }

  setLanguage(language: string): void {
    this.language = language;
    if (!this.translationGroup.get(language) && !this.isDefaultLanguage(language)) {
      this.addFormFieldsForLanguage(language);
      this.controlAdded$.next();
      this.confirmationDialogService.open(
        'COMMONS.TRANSLATIONS.APPLY.MODAL.TITLE',
        'COMMONS.TRANSLATIONS.APPLY.MODAL.TEXT',
        {
          language: this.translateService.instant('LANGUAGE.LANGUAGES.' + language)
        })
        .subscribe(result => {
          if (result) {
            this.translationGroup.get(language).get('name').setValue(this.parentForm.get('name').value);
          }
        });
    }
  }

  isDefaultLanguageSelected(): boolean {
    return this.isDefaultLanguage(this.language);
  }

  ngOnDestroy(): void {
    this.controlAdded$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getDefaultLanguage(): string {
    return this.senderService.getDefaultLanguage(this.sender);
  }

  private getLanguages(): string[] {
    return this.senderService.getAvailableLanguages(this.sender);
  }

  private validateLanguage(language: string): boolean {
    if (this.isDefaultLanguage(language)) {
      return this.parentForm.get('name').valid || this.parentForm.get('name').pristine;
    } else {
      const formGroup = this.translationGroup.get(language);
      return !formGroup || formGroup.valid || formGroup.pristine;
    }
  }

  private isDefaultLanguage(language: string): boolean {
    return language === this.getDefaultLanguage();
  }

  private addTranslationFormFields(): void {
    this.translationGroup = this.fb.group({});
    this.parentForm.setControl('translations', this.translationGroup);
    _.keys(this.app.translations).filter(language => !_.isEmpty(this.app.translations[language])).forEach(language => {
      this.addFormFieldsForLanguage(language);
    });
  }

  private addFormFieldsForLanguage(language: string): void {
    const defaultValue = this.app.translations ? this.app.translations[language]?.name : null;
    this.translationGroup.setControl(language, this.fb.group({
      name: [defaultValue, [Validators.required, Validators.maxLength(255)]]
    }));
  }
}

import {Injectable} from '@angular/core';
import {PluginConfigField} from '@domain/plugin/plugin-config-field';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';

/**
 * Service to convert different formats to formly field config definitions.
 */
@Injectable({
  providedIn: 'root'
})
export class FormlyFieldConverterService {

  constructor(private translateService: TranslateService) {
  }

  /**
   * Converts a plugin config field definition to a formly field config definition.
   *
   * @param configField the config field definition
   * @param prefix a possible prefix for the key of the field
   * @return the formly field definition
   */
  fromPluginConfigField(configField: PluginConfigField, prefix: string = ''): FormlyFieldConfig {
    return {
      key: prefix + configField.key,
      type: configField.type,
      defaultValue: configField.default,
      className: this.noHint(configField) ? 'no-hint' : null,
      templateOptions: {
        label: configField.label,
        placeholder: configField.placeholder,
        description: configField.description,
        options: configField.options,
        multiple: configField.multiple,
        required: configField.required,
        min: configField.min,
        max: configField.max,
        minLength: configField.minLength,
        maxLength: configField.maxLength,
        pattern: configField.pattern
      },
      validation: {
        messages: {
          required: error => this.translateService.instant('VALIDATION.ERROR.REQUIRED', error),
          min: error => this.translateService.instant('VALIDATION.ERROR.MIN', error),
          max: error => this.translateService.instant('VALIDATION.ERROR.MAX', error),
          minlength: error => this.translateService.instant('VALIDATION.ERROR.MINLENGTH', error),
          maxlength: error => this.translateService.instant('VALIDATION.ERROR.MAXLENGTH', error),
          pattern: error => this.translateService.instant('VALIDATION.ERROR.PATTERN', error)
        }
      }
    };
  }

  private noHint(configField: PluginConfigField): boolean {
    return !configField.description
      && !configField.required
      && !configField.min && configField.min !== 0
      && !configField.max && configField.max !== 0
      && !configField.minLength && configField.minLength !== 0
      && !configField.maxLength && configField.maxLength !== 0
      && !configField.pattern;
  }
}

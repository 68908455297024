import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {Skeleton} from '@coyo/ui';
import {Observable} from 'rxjs';
import {ExternalSearchResultsService} from '../external-search-results.service';
import {SearchResultsList} from '../search-results-list';

@Component({
  selector: 'coyo-search-results-external-wrapper',
  templateUrl: './search-results-external-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultsExternalWrapperComponent implements OnInit {

  private readonly LIMIT: number = 3;

  /**
   * The search term, the user has entered.
   */
  @Input() searchQuery: string;

  available$: Observable<boolean>;
  searches$: Observable<SearchResultsList[]>;
  skeletons: Skeleton[] = [
    {top: 0, left: 8, width: 'calc(100% - 14px)', height: 42},
    {top: 50, left: 8, width: 'calc(33% - 8px)', height: 42},
    {top: 50, left: 'calc(33% + 8px)', width: 'calc(33% - 8px)', height: 42},
    {top: 50, left: 'calc(66% + 8px)', width: 'calc(33% - 8px)', height: 42}
  ];

  constructor(private externalSearchResultsService: ExternalSearchResultsService, private featureToggleService: FeatureToggleService) {
  }

  ngOnInit(): void {
    this.available$ = this.isExternalSearchAvailable();

    const params = {term: this.searchQuery, limit: this.LIMIT.toString()};
    this.searches$ = this.externalSearchResultsService.getExternalSearchesResults(params);
  }

  private isExternalSearchAvailable(): Observable<boolean> {
    return this.featureToggleService.isFeatureAvailable('external_search');
  }
}

(function (angular) {
  'use strict';

  registerApp.$inject = ["appRegistryProvider"];
  angular
      .module('coyo.apps.championship', [
        'coyo.base',
        'coyo.apps.api',
        'commons.i18n'
      ])
      .config(registerApp);

  function registerApp(appRegistryProvider) {
    appRegistryProvider.register({
      name: 'APP.CHAMPIONSHIP.NAME',
      description: 'APP.CHAMPIONSHIP.DESCRIPTION',
      key: 'championship',
      icon: 'zmdi-ticket-star',
      svgIcon: 'championship-app',
      states: [{
        abstract: true,
        templateUrl: 'app/apps/championship/views/championship-app.html',
        controller: 'ChampionshipAppController',
        controllerAs: '$ctrl'
      }, {
        default: true,
        name: 'championship',
        url: '',
        templateUrl: 'app/apps/championship/views/championship-games.html',
        controller: 'ChampionshipAppGamesController',
        controllerAs: '$ctrl'
      }, {
        name: 'championship.past',
        url: '/past',
        views: {
          '@$appRoot': {
            templateUrl: 'app/apps/championship/views/championship-games.html',
            controller: 'ChampionshipAppGamesController',
            controllerAs: '$ctrl'
          }
        }
      }, {
        name: 'championship.ranking',
        url: '/ranking',
        views: {
          '@$appRoot': {
            templateUrl: 'app/apps/championship/views/championship-ranking.html',
            controller: 'ChampionshipAppRankingController',
            controllerAs: '$ctrl',
            resolve: {
              currentUser: /*@ngInject*/ ["authService", function (authService) {
                return authService.getUser();
              }]
            }
          }
        }
      }]
    });
  }

})(angular);

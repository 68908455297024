import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import {TourService} from '@app/tour/tour/tour.service';
import Shepherd from 'shepherd.js';

type PopperPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

/* tslint:disable:no-input-rename */

/**
 * A directive to add register a new tour step.
 */
@Directive({
  selector: '[coyoTourStep]'
})
export class TourStepDirective implements AfterViewInit, OnDestroy {

  /**
   * A unique identifier for the step, e.g. search, notifications, ...
   * Steps can use the same identifier within different topics.
   */
  @Input('coyoTourStep') step: string;

  /**
   * A unique identifier for the step's topic, e.g. navigation, messaging, ...
   * Steps will be grouped by their topic identifier.
   */
  @Input('coyoTourTopic') topic: string;

  /**
   * The order of the tour step within the tour.
   * Steps will be sorted by ascending order value.
   */
  @Input('coyoTourOrder') order: number;

  /**
   * The step's title (HTML string).
   * It becomes an h3 at the top of the step.
   */
  @Input('coyoTourTitle') title: string;

  /**
   * The text in the body of the step (HTML string).
   */
  @Input('coyoTourText') text: string;

  /**
   * An (optional) additional condition to show this step.
   */
  @Input('coyoTourIf') condition: boolean = true;

  /**
   * An (optional) selector to use as a reference element for the step.
   * Defaults to the directive's native element.
   */
  @Input('coyoTourSelector') selector: string = null;

  /**
   * The placement of the tour with respect to the reference element.
   */
  @Input('coyoTourPlacement') placement: PopperPlacement = 'bottom';

  /**
   * Extra options to pass to the Shepherd.Step.
   * Possibly overrides other directive inputs.
   */
  @Input('coyoTourOptions') options: Shepherd.Step.StepOptions = {};

  constructor(private element: ElementRef<HTMLElement>,
              private tourService: TourService) {
  }

  ngAfterViewInit(): void {
    if (this.condition && this.addStep()) {
      this.tourService.startTour();
    }
  }

  ngOnDestroy(): void {
    this.tourService.removeStep(this.topic, this.step);
  }

  private addStep(): boolean {
    return this.tourService.addStep({
      name: this.step,
      topic: this.topic,
      order: this.order,
      options: {
        ...{
          title: this.title,
          text: this.text,
          attachTo: {
            element: this.selector
              ? this.element.nativeElement.querySelector<HTMLElement>(this.selector)
              : this.element.nativeElement,
            on: this.placement
          }
        }, ...this.options
      }
    });
  }
}

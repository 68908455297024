/* tslint:disable:ordered-imports */

// this must be imported first
import '../../build/cli/src/main/app';

import {enableProdMode, StaticProvider} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {downgradeModule, getAngularJSGlobal} from '@angular/upgrade/static';
import {AppModule} from '@app/app.module';
import {environment} from '@root/environments/environment';
import {initLocalStorageForEngageWebView, isEngageInitCall} from '@app/engage/engage.init';

declare var Browser: any;

if (environment.production) {
  enableProdMode();
}

if (isEngageInitCall()) {
  // Do this before bootstrapping angular app so we will not generate a clientId with a premature backend call when logging in via Engage.
  initLocalStorageForEngageWebView();
}

/*
 * Downgrade Angular application.
 */
getAngularJSGlobal().module('ng-upgrade', [
  'coyo.app',
  downgradeModule((extraProviders: StaticProvider[]) => {
    const platformRef = platformBrowserDynamic(extraProviders);
    (window as any).ngPlatform = platformRef;
    return platformRef.bootstrapModule(AppModule, {
      // https://github.com/angular/angular/issues/21049
      preserveWhitespaces: true
    });
  })
]);

/*
 * Manually bootstrap AngularJS application.
 */
if (!Browser.isPartiallySupported()) {
  Browser.showErrorPage();
} else {
  if (!Browser.isFullySupported()) {
    Browser.showErrorBar();
  }

  if (window['cordova']) {
    // tslint:disable-next-line:no-console
    console.log('Running in Cordova, starting the application once "deviceready" event fires.');
    document.addEventListener('deviceready', function(): void {
      // tslint:disable-next-line:no-console
      console.log('"deviceready" event has fired, starting COYO now.');
      getAngularJSGlobal().bootstrap(document.body, ['ng-upgrade'], {strictDi: true});
    }, false);
  } else {
    // tslint:disable-next-line:no-console
    console.log('Running in browser, starting COYO now.');
    getAngularJSGlobal().bootstrap(document.body, ['ng-upgrade'], {strictDi: true});
  }
}

function initExternals(): void {
  (window as any).coyo = {};
  (window as any).coyo.ui = require('@coyo/ui');
  (window as any).ng = {};
  (window as any).ng.animations = require('@angular/animations');
  (window as any).ng.animationsBrowser = require('@angular/platform-browser/animations');
  (window as any).ng.cdk = require('@angular/cdk');
  (window as any).ng.cdk.overlay = require('@angular/cdk/overlay');
  (window as any).ng.common = require('@angular/common');
  (window as any).ng.common.http = require('@angular/common/http');
  (window as any).ng.core = require('@angular/core');
  (window as any).ng.forms = require('@angular/forms');
  (window as any).ng.material = require('@angular/material');
  (window as any).ng.material.button = require('@angular/material/button');
  (window as any).ng.material.dialog = require('@angular/material/dialog');
  (window as any).ng.material.list = require('@angular/material/list');
  (window as any).ng.material.icon = require('@angular/material/icon');
  (window as any).ng.material.input = require('@angular/material/input');
  (window as any).ng.material.formField = require('@angular/material/form-field');
  (window as any).ng.platformBrowser = require('@angular/platform-browser');
  (window as any).ng.router = require('@angular/router');
  (window as any).ngSelect = require('@ng-select/ng-select');
  (window as any).ngxTranslate = require('@ngx-translate/core');
  (window as any).rxjs = require('rxjs');
}

initExternals();

(function (angular) {
  'use strict';

  translationsConfig.$inject = ["$translateProvider", "$localStorageProvider"];
  messageFormatIntlConfig.$inject = ["$translateMessageFormatInterpolationProvider"];
  numeralsConfig.$inject = ["$numeralProvider"];
  initInterfaceLanguage.$inject = ["authService", "i18nService", "$rootScope", "$document"];
  listenOnBackendChange.$inject = ["$rootScope", "$translate"];
  var momentMapping = {
    'en': 'en-gb',
    'zh': 'zh-cn',
    'no': 'nb',
    'hy': 'hy-am'
  };

  /**
   * @ngdoc overview
   * @name commons.i18n
   *
   * @description
   * # Internationalization (i18n) module #
   * The internationalization module initializes the translation module, provides an i18n service
   * and contains the message key files.
   *
   * @requires $translate
   * @requires $translateProvider
   * @requires commons.auth.authService
   * @requires commons.i18n.i18nService
   * @requires commons.i18n.custom.coyoTranslationLoader
   * @requires ngNumeraljs
   */
  angular
      .module('commons.i18n', [
        'commons.auth',
        'commons.i18n.custom',
        'coyo.base'
      ])
      .config(translationsConfig)
      .config(messageFormatIntlConfig)
      .config(numeralsConfig)
      .constant('numeralGlobal', numeral) // eslint-disable-line no-undef
      .constant('momentMapping', momentMapping)
      .run(initInterfaceLanguage)
      .run(listenOnBackendChange);

  /**
   * Translations configuration.
   */
  function translationsConfig($translateProvider, $localStorageProvider) {
    // set available languages and make en the default alias

    var languages = ['bg', 'cs', 'da', 'de', 'el', 'en', 'es', 'et', 'fi', 'fr', 'hr', 'hu', 'hy', 'is', 'it', 'ja',
      'lv', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'sr', 'sv', 'tr', 'zh'];

    // We are using the user language as default as we populate it inside the translation loader with the
    // english message keys anyway (if it is not german)
    var browserLanguage = (navigator.languages && navigator.languages.length ? navigator.languages[0] :
      (navigator.language || navigator.userLanguage)) || 'en';
    var userLanguage = $localStorageProvider.get('userLanguage') ?
      $localStorageProvider.get('userLanguage').toLowerCase() : browserLanguage.substring(0, 2).toLowerCase();

    $translateProvider.registerAvailableLanguageKeys(languages,
        {'en_*': 'en', 'de_*': 'de', '*': 'en'});

    // set a sanitize strategy (default: null)
    // should not set to null because of security reasons (XSS)
    // see: https://angular-translate.github.io/docs/#/guide/19_security
    $translateProvider.useSanitizeValueStrategy('sanitize');

    // log missing translations
    // $translateProvider.useMissingTranslationHandlerLog();

    // use $translate's default strategy to determine browser language
    $translateProvider.determinePreferredLanguage(function () {
      return userLanguage;
    });

    // use message interpolation
    $translateProvider.useMessageFormatInterpolation();

    // use custom coyo translation loader
    $translateProvider.useLoader('coyoTranslationLoader');
  }

  /**
   * Number formatting configurations
   */
  function numeralsConfig($numeralProvider) {
    // Register a new Locale
    $numeralProvider.registerLocale('de', {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal: function () {
        return '.';
      },
      currency: {
        symbol: '€'
      }
    });
  }

  /**
   * Enable Intl Formatters for message formatting
   * see https://messageformat.github.io/guide/
   */
  function messageFormatIntlConfig($translateMessageFormatInterpolationProvider) {
    $translateMessageFormatInterpolationProvider.messageFormatConfigurer(function (messageFormat) {
      messageFormat.setIntlSupport(true);
    });
  }

  /**
   * Set user interface language if a user is available.
   */
  function initInterfaceLanguage(authService, i18nService, $rootScope, $document) {
    function setInterfaceLanguage() {
      authService.getUser().then(function (user) {
        var momentKey = _.has(momentMapping, user.language.toLowerCase()) ?
          _.get(momentMapping, user.language.toLowerCase()) : user.language.toLowerCase();
        $document.find('body').append('<script type="application/javascript" src="/moment-locale/' + momentKey + '.js"></script>');

        i18nService.setInterfaceLanguage(user.language);
      });
    }

    if (authService.isAuthenticated()) {
      setInterfaceLanguage();
    }

    $rootScope.$on('authService:login:success', setInterfaceLanguage);
  }

  /**
   * Listens on backend URL changes.
   */
  function listenOnBackendChange($rootScope, $translate) {
    $translate.onReady(function () {
      $rootScope.translationsReady = true;
      angular.element(document).find('body').removeClass('translations-pending');
    });
  }

})(angular);

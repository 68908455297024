(function (angular) {
  'use strict';

  anchor.$inject = ["$window"];
  angular
      .module('commons.ui')
      .directive('coyoAnchor', anchor);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoAnchor:coyoAnchor
   * @element ANY
   * @restrict A
   *
   * @description
   * Directive to make anchor tags clickable every time.
   *
   * @requires $window
   */
  function anchor($window) {
    return {
      restrict: 'A',
      link: function ($scope, elem) {
        function onClick($event) {
          // reset to empty hash before setting it to the real value so scrolling works multiple times instead of just once
          $window.location.hash = '';
          $window.location.hash = $event.target.hash;
          // reset base URL so anchor links can be opened in a new tab
          $event.target.href = $window.location.href;
        }

        elem.bind('click', onClick);
        $scope.$on('$destroy', function () {
          elem.unbind('click', onClick);
        });
      }
    };
  }

})(angular);

import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {PluginEntryPoint} from '@domain/plugin/plugin-entry-point';
import {PluginService} from '@domain/plugin/plugin.service';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FormlyFieldConverterService} from '@shared/formly/formly-field-converter/formly-field-converter.service';
import {WidgetRegistryService} from '@widgets/api/widget-registry/widget-registry.service';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {PluginWidget} from '@widgets/plugin/plugin-widget';
import {CONFIG_KEY_PREFIX, ENTRY_POINT_ID, SRC_ID} from '@widgets/plugin/plugin-widget-settings.model';
import * as _ from 'lodash';
import {combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';

/**
 * The plugin widget's settings component.
 *
 * This component is used only in the context of the widget settings modal dialog.
 */
@Component({
  templateUrl: './plugin-widget-settings.component.html'
})
export class PluginWidgetSettingsComponent extends WidgetSettingsComponent<PluginWidget> implements OnInit {
  entryPoints$: Observable<PluginEntryPoint[]>;
  config$: Observable<FormlyFieldConfig[] | null>;

  constructor(private pluginService: PluginService,
              private widgetRegistryService: WidgetRegistryService,
              private formlyFieldConverterService: FormlyFieldConverterService) {
    super();
  }

  get entryPointControl(): AbstractControl {
    return this.parentForm.controls[ENTRY_POINT_ID];
  }

  ngOnInit(): void {
    this.parentForm.addControl(SRC_ID, new FormControl(this.widget.settings[SRC_ID],
      [Validators.required]));
    this.parentForm.addControl(ENTRY_POINT_ID, new FormControl(this.widget.settings[ENTRY_POINT_ID],
      [Validators.required]));

    this.entryPoints$ = this.widgetRegistryService.get(this.widget.key)
      .pipe(switchMap(widgetConfig => this.pluginService.get(PluginService.getPluginId(widgetConfig.key))))
      .pipe(map(plugin => plugin.manifest))
      .pipe(map(manifest => manifest.entryPoints));

    this.config$ = combineLatest([
      this.entryPoints$,
      this.entryPointControl.valueChanges
        .pipe(tap(() => this.resetConfig()))
        .pipe(startWith<string, string>(null))
    ]).pipe(map(([entryPoints, entryPointId]) => _.find(entryPoints, {id: entryPointId || this.entryPointControl.value}).config))
      .pipe(distinctUntilChanged())
      .pipe(map(config => config ? config.map(cf =>
        this.formlyFieldConverterService.fromPluginConfigField(cf, CONFIG_KEY_PREFIX)) : null));
  }

  private resetConfig(): void {
    for (const key in this.widget.settings) {
      if (this.widget.settings.hasOwnProperty(key) && key.startsWith(CONFIG_KEY_PREFIX)) {
        delete this.widget.settings[key]; // tslint:disable-line:no-dynamic-delete
      }
    }
  }
}

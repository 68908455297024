(function (angular) {
  'use strict';

  angular
      .module('coyo.messaging')
      .directive('oyocMessagingChannelHeader', messagingChannelHeader);

  /**
   * @ngdoc directive
   * @name coyo.messaging.oyocMessagingChannelHeader:oyocMessagingChannelHeader
   * @scope
   * @restrict 'E'
   * @element OWN
   *
   * @description
   * Renders the messaging channel header.
   *
   * @param {object} msgSidebar The main sidebar controller
   * @param {boolean} loading Binding for the loading indicator
   * @param {object} channel The channel
   * @param {object} currentUser The current user
   *
   * @requires domain.MessageChannelMemberModel
   */
  function messagingChannelHeader() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'app/modules/messaging/components/messaging-channel-header/messaging-channel-header.html',
      scope: {},
      bindToController: {
        msgSidebar: '=',
        loading: '=',
        channel: '<',
        currentUser: '<',
        channelLocked: '=?'
      },
      controller: 'MessageChannelHeaderController',
      controllerAs: '$ctrl'
    };
  }

})(angular);

<ng-container *ngIf="user$ | async as user; else error">
  <div class="user-profile-widget-header">
    <coyo-user-avatar [user]="user"></coyo-user-avatar>
    <div class="name">
      <h3><coyo-sender-link [sender]="user"></coyo-sender-link></h3>
      <p class="small text-muted">{{user.properties?.jobTitle}}</p>
    </div>
  </div>

  <dl class="small user-profile-widget-infos" *ngIf="widget.settings._showInfo">
    <dt *ngIf="user.email" translate="USER.EMAIL"></dt>
    <dd *ngIf="user.email"><a href="mailto:{{ user.email }}">{{ user.email }}</a></dd>
    <dt *ngIf="user.properties?.phone" translate="USER.PHONE"></dt>
    <dd *ngIf="user.properties?.phone"><a href="tel:{{ user.properties?.phone | cleanPhoneNumber}}">{{ user.properties?.phone }}</a></dd>
    <dt *ngIf="user.properties?.department" translate="USER.DEPARTMENT"></dt>
    <dd *ngIf="user.properties?.department">{{ user.properties?.department }}</dd>
  </dl>
</ng-container>
<ng-template #error>
  <span class="text-muted" translate>WIDGET.USERPROFILE.USER.NOTFOUND</span>
</ng-template>

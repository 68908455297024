<ng-container *ngIf="state$ | async; let state">
  <ng-container *ngIf="!state.loading; else loading">
    <ng-container *ngIf="state.article; else noArticle">
      <div class="headline">
        <coyo-sender-link [sender]="state.sender"></coyo-sender-link>
        <div *ngIf="state.article.created" class="created-date">{{state.article.created | date}}</div>
      </div>
      <a [attr.href]="getArticleLink(state.article.articleTarget)" class="article-link">
        {{state.article.title}}
      </a>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <ui-skeleton [skeletons]="skeletons"></ui-skeleton>
</ng-template>
<ng-template #noArticle>
  <span translate="WIDGETS.WIKIARTICLE.NO_ARTICLES" class="text-muted"></span>
</ng-template>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {User} from '@domain/user/user';
import {WidgetComponent} from '@widgets/api/widget-component';
import {PersonalTimelineWidget} from '@widgets/personal-timeline/personal-timeline-widget';
import {Observable} from 'rxjs';

/**
 * Personal timeline widget component.
 */
@Component({
  selector: 'coyo-personal-timeline-widget',
  templateUrl: './personal-timeline-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalTimelineWidgetComponent extends WidgetComponent<PersonalTimelineWidget> implements OnInit {
  currentUser$: Observable<User>;

  constructor(private authService: AuthService, cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.currentUser$ = this.authService.getUser();
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {LaunchpadCategory} from '@domain/launchpad/launchpad-category';
import {LaunchpadLink} from '@domain/launchpad/launchpad-link';

/**
 * A single launchpad category and all of its links.
 */
@Component({
  selector: 'coyo-launchpad-category',
  templateUrl: './launchpad-category.component.html',
  styleUrls: ['./launchpad-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadCategoryComponent {

  /**
   * The launchpad category.
   */
  @Input() category: LaunchpadCategory;

  /**
   * Event that is emitted when a link should be added to this category.
   */
  @Output() add: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Event that is emitted when a link should be edited.
   */
  @Output() edit: EventEmitter<LaunchpadLink> = new EventEmitter<LaunchpadLink>();

  /**
   * Event that is emitted when a link should be deleted.
   */
  @Output() delete: EventEmitter<LaunchpadLink> = new EventEmitter<LaunchpadLink>();
}

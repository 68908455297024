(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.profile
   *
   * @description
   * # Profile module #
   * The profile module renders the profile information and provides methods for accessing and manipulating the user
   * data.
   *
   * Available components are for example
   * * the profile fields,
   * * the push devices,
   * * the user avatar,
   * * the user avatar overlay for the image upload,
   * * the user follow button and
   * * the user list item.
   *
   * @requires $stateProvider
   */
  ModuleConfig.$inject = ["$stateProvider", "profileConfig"];
  registerTransitionHooks.$inject = ["$transitions"];
  angular
      .module('coyo.profile', [
        'coyo.base',
        'commons.auth',
        'commons.config',
        'commons.ui',
        'commons.i18n',
        'commons.target',
        'commons.messaging'
      ])
      .config(ModuleConfig)
      .constant('profileConfig', {
        templates: {
          main: 'app/modules/profile/views/profile.main.html',
          activity: 'app/modules/profile/views/profile.activity.html',
          info: 'app/modules/profile/views/profile.info.html'
        }
      }).constant('profileFieldTemplates', {
        'CHECKBOX': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-checkbox.html'},
        'DATE': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-date.html'},
        'BIRTHDAY': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-birthday.html'},
        'EMAIL': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-email.html'},
        'LINK': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-link.html'},
        'OPTIONS': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-options.html'},
        'PHONE': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-phone.html'},
        'TEXT': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-text.html'},
        'TEXTAREA': {templateUrl: 'app/modules/profile/components/profile-fields/views/profile-field-textarea.html'}
      }).run(registerTransitionHooks);

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, profileConfig) {
    $stateProvider.state('main.profile-self', {
      url: '/profile',
      resolve: {
        currentUser: ["authService", function (authService) {
          return authService.getUser();
        }]
      }
    }).state('main.profile', {
      url: '/profile/:userId',
      templateUrl: profileConfig.templates.main,
      controller: 'ProfileMainController',
      controllerAs: 'profileCtrl',
      data: {
        guide: 'user-profile',
        pageTitle: false
      },
      resolve: {
        currentUser: ["authService", function (authService) {
          return authService.getUser();
        }],
        user: ["$stateParams", "UserModel", "currentUser", function ($stateParams, UserModel, currentUser) {
          return $stateParams.userId !== currentUser.id && $stateParams.userId !== currentUser.slug
            ? UserModel.getWithPermissions({id: $stateParams.userId}, {with: 'subscriptionInfo'}, ['manage', 'accessProfile', 'accessTimeline'])
            : currentUser;
        }],
        profileFieldGroups: ["profileFieldsService", function (profileFieldsService) {
          return profileFieldsService.getGroups();
        }],
        linkPattern: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieveByKey('linkPattern');
        }],
        emailPattern: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieveByKey('emailPattern');
        }],
        phonePattern: ["SettingsModel", function (SettingsModel) {
          return SettingsModel.retrieveByKey('phonePattern');
        }]
      }
    }).state('main.profile.activity', {
      url: '/activity',
      templateUrl: profileConfig.templates.activity
    }).state('main.profile.info', {
      url: '/info',
      templateUrl: profileConfig.templates.info
    });
  }

  function registerTransitionHooks($transitions) {
    // resolve current user ID to redirect to own profile
    $transitions.onBefore({to: 'main.profile-self'}, function (transition) {
      var titleService = transition.injector().get('ngxPageTitleService');
      return transition.injector().getAsync('currentUser').then(function (currentUser) {
        titleService.setTitle(currentUser.displayName);
        return transition.router.stateService.target('main.profile', {userId: currentUser.slug}, {
          source: 'redirect',
          inherit: true
        });
      });
    });

    // check if user is allowed to see the profile
    $transitions.onBefore({to: 'main.profile.**'}, function (transition) {
      var titleService = transition.injector().get('ngxPageTitleService');
      return transition.injector().getAsync('user').then(function (user) {
        if (!user._permissions.accessProfile) {
          transition.injector().get('ngxNotificationService').error('ERRORS.FORBIDDEN');
          return false; // abort transition
        }
        titleService.setTitle(user.displayName);
        return true;
      });
    });

    // check where to redirect depending on the user's permission to access the sender's timeline
    $transitions.onBefore({to: 'main.profile'}, function (transition) {
      var titleService = transition.injector().get('ngxPageTitleService');
      return transition.injector().getAsync('user').then(function (user) {
        titleService.setTitle(user.displayName);
        var target = user._permissions.accessTimeline ? 'main.profile.activity' : 'main.profile.info';
        return transition.router.stateService.target(target, transition.params(), {
          source: 'redirect',
          inherit: true
        });
      });
    });
  }

})(angular);

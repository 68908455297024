import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ParticipantStatusChangedEvent} from '@app/shared/event-meta-data/event-participation/ParticipantStatusChangedEvent';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Skeleton} from '@coyo/ui/lib/ui/skeleton/skeleton';
import {ParticipantStatus} from '@domain/event/participant-status';
import {SenderEvent} from '@domain/event/sender-event';
import {Page} from '@domain/pagination/page';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'coyo-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListComponent implements OnInit {

  /**
   * The given page with the events.
   */
  @Input()
  events: Page<SenderEvent>;

  /**
   * Loading status.
   */
  @Input()
  loading: boolean;

  isMobile$: Observable<boolean>;
  eventDayName: string;
  eventFullDate: string;

  readonly SKELETON_REPEAT_COUNT: number = 10;
  readonly skeletonData: { [key: string]: Skeleton[] } = {
    dateHeader: [
      {top: 0, left: '33%', width: '33%', height: 20, radius: 0, color: 'lightgray'}
    ],
    eventListEntryMobile: [
      {top: 20, left: 0, width: 80, height: 80, radius: 0, color: 'lightgray'},
      {top: 20, left: 80, width: 'calc(100% - 80px)', height: 80, radius: 0, color: 'white'},
      {top: 35, left: 95, width: 120, height: 20, radius: 0},
      {top: 68, left: 95, width: 30, height: 20, radius: 0},
      {top: 68, left: 135, width: 90, height: 20, radius: 0},
      {top: 68, left: 235, width: 30, height: 20, radius: 0},
      {top: 45, left: 'calc(100% - 90px)', width: 70, height: 30}
    ],
    eventListEntry: [
      {top: 20, left: 0, width: 160, height: 160, radius: 0, color: 'lightgray'},
      {top: 20, left: 160, width: 'calc(100% - 160px)', height: 160, radius: 0, color: 'white'},
      {top: 40, left: 180, width: 120, height: 20, radius: 0},
      {top: 40, left: 'calc(100% - 140px)', width: 120, height: 20, radius: 0},
      {top: 80, left: 180, width: 'calc(100% - 200px)', height: 20, radius: 0},
      {top: 110, left: 180, width: 'calc(100% - 280px)', height: 20, radius: 0},
      {top: 145, left: 180, width: 50, height: 20, radius: 0},
      {top: 145, left: 250, width: 120, height: 20, radius: 0},
      {top: 145, left: 390, width: 120, height: 20, radius: 0},
      {top: 145, left: 530, width: 50, height: 20, radius: 0},
    ]
  };

  constructor(private windowSizeService: WindowSizeService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.isMobile$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS || screenSize === ScreenSize.SM));
    this.eventDayName = this.translateService.instant('DATE_FORMAT_DAY_NAME');
    this.eventFullDate = this.translateService.instant('DATE_FORMAT_FULL');
  }

  /**
   * Changes the current number of participants if the event is attended or cancelled by the current user.
   *
   * @param changeEvent participation status change event.
   * @param event       the event in the list on which the event was triggered.
   */
  onParticipationStatusChanged(changeEvent: ParticipantStatusChangedEvent, event: SenderEvent): void {
    const newIsAttending = changeEvent.newStatus === ParticipantStatus.ATTENDING;
    const oldIsAttending = changeEvent.oldStatus === ParticipantStatus.ATTENDING;
    if (newIsAttending !== oldIsAttending) {
      if (newIsAttending) {
        event.attendingCount++;
      } else {
        event.attendingCount--;
      }
    }
  }
}

import {Duration, DurationUnit} from './duration';

class UnitLimits {
  max: number | null;
  min: number | null = 0;
}

/**
 * The duration picker can run in different modes that influence in which way a unit rollover in processed.
 */
export enum DurationPickerMode {

  /**
   * No unit rollover, a unit's maximum is the highest value without rollover to the next enabled unit.
   * E.g. maximum for minutes is 59, or 3599 if hours are disabled.
   */
  STRICT,

  /**
   * With unit rollover, e.g. T1M59S -> increment seconds -> T2M0S
   */
  NORMALIZE,

  /**
   * No unit rollover, a unit's maximum is unlimited
   */
  LENIENT,
}

/**
 * Calculates the maximum and minimum values for the units that can be entered in the UI.
 */
export class DurationRangeChecker {

  private _mode: DurationPickerMode = DurationPickerMode.LENIENT;
  private _value: Duration | null;
  private _max: Duration | null;
  private _limits: Map<DurationUnit, UnitLimits> = new Map();

  constructor() {
    Object.keys(DurationUnit).map(key => DurationUnit[key]).filter(unit => !isNaN(Number(unit)))
      .forEach(unit => this._limits.set(unit, new UnitLimits()));
  }

  set mode(mode: DurationPickerMode) {
    this._mode = mode;
  }

  set value(value: Duration) {
    this._value = value;
    this.update();
  }

  set max(max: Duration) {
    this._max = max;
    this.update();
  }

  /**
   * Gets the max value of the duration unit
   *
   * @param unit
   * The unit
   *
   * @return The maximum value of the given unit
   */
  getMaxUnit(unit: DurationUnit): number {
    return this._limits.get(unit).max;
  }

  /**
   * Gets the min value of the duration unit
   *
   * @param unit
   * The unit
   *
   * @return The minimum value of the given unit
   */
  getMinUnit(unit: DurationUnit): number {
    return this._limits.get(unit).min;
  }

  private update(): void {
    this._limits.forEach((limit, unit) => limit.max = this.evalMaxUnit(unit));
  }

  private evalMaxUnit(unit: DurationUnit): number | null {
    if (this._max && this._value) {
      const otherUnits = this._value.set(unit, 0);
      const modeLimit = this.getModeLimit(unit);
      return Math.min(Math.floor((this._max.toMilliseconds() - otherUnits.toMilliseconds())
        / Duration.ZERO.set(unit, 1).toMilliseconds()), modeLimit);
    } else {
      return null;
    }
  }

  private getModeLimit(unit: DurationUnit): number {
    switch (this._mode) {
      case DurationPickerMode.STRICT:
        return this._value.getRollover(unit) - 1;
      case DurationPickerMode.NORMALIZE:
        return this._value.getRollover(unit);
      default:
        return Number.MAX_SAFE_INTEGER;
    }
  }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemServiceSelectorService} from '@app/file-picker/file-picker-item-service-selector.service';
import {SelectAll, UnselectAll} from '@app/file-picker/file-picker-selection/file-picker-selection.actions';
import {FilePickerSelectionsState} from '@app/file-picker/file-picker-selection/file-picker-selections-state';
import {FilePickerItemStateModel} from '@app/file-picker/file-picker-state/file-picker-state-model';
import {Select, Store} from '@ngxs/store';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Component rendering a material checkbox and
 * adding/removing all given items to the current selection state
 */
@Component({
  selector: 'coyo-file-picker-select-all',
  templateUrl: './file-picker-select-all.component.html',
  styleUrls: ['./file-picker-select-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerSelectAllComponent implements OnChanges {

  /**
   * The items currently shown by the file picker
   */
  @Input() availableItems: FilePickerItemStateModel[];

  state$: Observable<{
    anySelectable: boolean;
    allSelected: boolean;
    noneSelected: boolean;
  }>;

  @Select(FilePickerSelectionsState.isMultiSelect) isMultiSelect$: Observable<boolean>;

  constructor(
    private store: Store,
    private filePickerItemSelectorService: FilePickerItemServiceSelectorService
  ) {
  }

  ngOnChanges(): void {
    this.state$ = this.store.select(FilePickerSelectionsState).pipe(map(state => {
      const ids = this.getAvailableDocuments().filter(item => FilePickerSelectionsState.matchContentType(item, state)).map(item => item.id);
      const keys = _.keys(state.selection.selectedItems);
      return {
        anySelectable: ids.length > 0,
        allSelected: ids.length > 0 && _.intersection(ids, keys).length === ids.length,
        noneSelected: _.intersection(ids, keys).length === 0
      };
    }));
  }

  toggleAllFilesSelected(allSelected: MatCheckboxChange): void {
    if (allSelected.checked) {
      this.store.dispatch(new SelectAll(this.getAvailableDocuments()));
    } else {
      this.store.dispatch(new UnselectAll(this.getAvailableDocuments()));
    }
  }

  private getAvailableDocuments(): FilePickerItem[] {
    return this.availableItems
      .map(model => model.item)
      .filter(item => this.filePickerItemSelectorService.get(item).isSelectable(item));
  }

}

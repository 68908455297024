import {ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemStateModel} from '@app/file-picker/file-picker-state/file-picker-state-model';
import {MoveFileToFolder, OpenFolder} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {COYO_INTERNAL_FILE_DRAG_TYPE} from '@domain/file/file';
import {Store} from '@ngxs/store';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'coyo-file-picker-breadcrumb',
  templateUrl: './file-picker-breadcrumb.component.html',
  styleUrls: ['./file-picker-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerBreadcrumbComponent implements OnChanges {
  static readonly CLOSE_MENU_TIMEOUT: number = 1000;
  static readonly IDLE_TIMEOUT: number = 5000;
  static readonly REOPEN_TIMEOUT: number = 1000;

  /**
   * The items that should be displayed.
   */
  @Input() filePickerId: string;

  @ViewChild('trigger') trigger: MatMenuTrigger;

  breadcrumbs$: Observable<FilePickerItemStateModel[]>;
  root$: Observable<FilePickerItemStateModel>;
  currentLocation$: Observable<FilePickerItemStateModel>;
  contextMenuItems$: Observable<FilePickerItemStateModel[]>;
  hasContextMenu$: Observable<boolean>;
  hasBackdrop$: Subject<boolean> = new BehaviorSubject<boolean>(true);

  private closingTimeout: NodeJS.Timeout;
  private menuOpenedRecently: boolean;
  private menuClosedRecently: boolean;

  constructor(private readonly store: Store, private renderer: Renderer2) {
  }

  /**
   * Load the contents of the given folder item.
   *
   * @param item a folder item
   * @param event the event dispatched by the browser
   */
  openFolder(item: FilePickerItem, event?: Event): void {
    event.preventDefault();
    this.store.dispatch(new OpenFolder(this.filePickerId, item));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filePickerId) {
      this.breadcrumbs$ = this.store.select(FilePickerStateSelectors.breadcrumbs(this.filePickerId));
      this.root$ = this.breadcrumbs$.pipe(map(items => items?.length > 1 ? items[0] : null));
      this.currentLocation$ = this.breadcrumbs$.pipe(map(items => items?.length ? items[items.length - 1] : null));
      this.contextMenuItems$ = this.breadcrumbs$.pipe(map(items => items?.length ? items.slice(1, items.length - 1) : null));
      this.hasContextMenu$ = this.breadcrumbs$.pipe(map(items => items && items.length > 2));
    }
  }

  openMenu(): boolean {
    this.cancelTimeout();
    if (this.trigger && !this.menuClosedRecently && !this.trigger.menuOpen) {
      this.trigger.openMenu();
      this.menuOpenedRecently = true;
      this.closingTimeout = setTimeout(() => {
        this.closeMenuDelayed();
      }, FilePickerBreadcrumbComponent.IDLE_TIMEOUT);
    }
    return true;
  }

  onDragOverRoot(event: DragEvent, itemStateModel: FilePickerItemStateModel): boolean {
    this.closeMenu();
    return this.onDragOverMenuItem(event, itemStateModel);
  }

  onDragOverMenuItem(event: DragEvent, itemStateModel: FilePickerItemStateModel): boolean {
    if (this.canMoveFile(itemStateModel)) {
      this.renderer.addClass(event.currentTarget, 'dropping');
    }
    this.cancelTimeout();
    return false;
  }

  onDragLeave(event: DragEvent): boolean {
    this.renderer.removeClass(event.currentTarget, 'dropping');
    this.closeMenuDelayed();
    return false;
  }

  closeMenuDelayed(): void {
    if (!this.menuOpenedRecently) {
      this.cancelTimeout();
      this.closingTimeout = setTimeout(() => {
        this.closeMenu();
        this.menuClosedRecently = true;
        setTimeout(() => this.menuClosedRecently = false, FilePickerBreadcrumbComponent.REOPEN_TIMEOUT);
      }, FilePickerBreadcrumbComponent.CLOSE_MENU_TIMEOUT);
    } else {
      this.menuOpenedRecently = false;
    }
  }

  onDragEnter(itemStateModel: FilePickerItemStateModel, menuItem?: MatMenuItem): boolean {
    if (menuItem) {
      menuItem.focus();
    }
    return !this.canMoveFile(itemStateModel);
  }

  @HostListener('document:dragend')
  onDragEnd(): void {
    this.closeMenu();
    this.hasBackdrop$.next(true);
  }

  @HostListener('document:dragstart')
  onDragStart(): void {
    this.hasBackdrop$.next(false);
  }

  moveFile(event: DragEvent, item: FilePickerItem): void {
    this.renderer.removeClass(event.currentTarget, 'dropping');
    this.store.dispatch(new MoveFileToFolder(this.filePickerId, event.dataTransfer.getData(COYO_INTERNAL_FILE_DRAG_TYPE), item.id));
    this.closeMenu();
  }

  private closeMenu(): void {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }

  private cancelTimeout(): void {
    if (this.closingTimeout) {
      clearTimeout(this.closingTimeout);
      this.closingTimeout = null;
    }
  }

  private canMoveFile(itemStateModel: FilePickerItemStateModel): boolean {
    // tslint:disable-next-line:no-bitwise
    return !!(itemStateModel.capabilities & FilePickerItemServiceCapability.MOVE_FILE);
  }
}

import {Directive, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UIRouter} from '@uirouter/angular';
import {WidgetSettingsModalComponent} from '@widgets/api/widget-settings-modal/widget-settings-modal.component';
import {Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';

/**
 * Base class for all Modal types.
 *
 * Implements a close method which emits a result value of type `T`.
 */
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class Dialog<T> implements OnDestroy {

   private stateChangeSubscription: Subscription;

  constructor(public modal: MatDialogRef<WidgetSettingsModalComponent>, uiRouter: UIRouter) {
    // Need to skip the first because it's a shareReplay Observable which emits
    // the last transition change as first value
    this.stateChangeSubscription = uiRouter.globals.start$
      .pipe(skip(1))
      .subscribe(() => this.close());
  }

  /**
   * Closes the modal and emits the close value to the onClose subject.
   *
   * @param closeValue the value returned from the modal or `undefined`
   * if the modal does not return a value
   */
  close(closeValue?: T): void {
    this.modal.close(closeValue);
    this.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private unsubscribe(): void {
    if (this.stateChangeSubscription && !this.stateChangeSubscription.closed) {
      this.stateChangeSubscription.unsubscribe();
    }
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {BlogArticleWidget} from '@widgets/blog-article/blog-article-widget';
import {BlogArticleWidgetSettingsComponent} from './blog-article-widget-settings/blog-article-widget-settings.component';
import {BlogArticleWidgetComponent} from './blog-article-widget/blog-article-widget.component';

/**
 * The configuration for the blog article widget.
 */
export const BLOG_ARTICLE_WIDGET: WidgetConfig<BlogArticleWidget> = {
  key: 'blogarticle',
  name: 'WIDGETS.BLOGARTICLE.NAME',
  description: 'WIDGETS.BLOGARTICLE.DESCRIPTION',
  icon: 'zmdi-file-text',
  component: BlogArticleWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    skipOnCreate: false,
    component: BlogArticleWidgetSettingsComponent
  },
  renderOptions: {
    printable: true,
  },
  whitelistExternal: true
};

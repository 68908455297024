import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {TextModule} from '@shared/text/text.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {TEXT_WIDGET} from '@widgets/text/text-widget-config';
import {AutosizeModule} from 'ngx-autosize';
import {TextWidgetComponent} from './text-widget/text-widget.component';

/**
 * Module providing the text widget
 */
@NgModule({
  imports: [
    AutosizeModule,
    CoyoCommonsModule,
    CoyoFormsModule,
    TextModule,
    TranslateModule
  ],
  declarations: [
    TextWidgetComponent
  ],
  entryComponents: [
    TextWidgetComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: TEXT_WIDGET, multi: true},

  ]
})

export class TextWidgetModule {}

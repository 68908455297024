<form [formGroup]="form" class="row">
  <div class="col-md-4" [class.mat-error]="form.controls.email.touched && form.controls.email.invalid">
    <label class="mat-form-field-label" (click)="email.focus()">{{'SETTINGS.ACCOUNT.EMAIL' | translate}}</label>
  </div>
  <div class="col-md-8">
    <mat-form-field class="mb-0">
      <input matInput type="email" formControlName="email" (blur)="submit()" (keydown.enter)="submit()" #email>
      <coyo-save-indicator matSuffix [request]="request$"></coyo-save-indicator>
      <mat-error *coyoFieldErrors="form.controls.email.errors"></mat-error>
      <mat-hint *ngIf="form.controls.email.disabled" [innerHTML]="'SETTINGS.ACCOUNT.EMAIL.HINT' | translate | markdown"></mat-hint>
    </mat-form-field>
  </div>
</form>

<ng-container *ngIf="file$ | async as file">
  <ng-container *ngIf="canEditInOffice$ | async">
    <button
      [disabled]="file.locked"
      (click)="edit(file)"
      mat-button
      mat-link-button
      color="accent">
      <coyo-file-icon-by-mime-type [mimeType]="file.contentType"></coyo-file-icon-by-mime-type>
      {{'EDIT' | translate}}
    </button>
  </ng-container>
</ng-container>

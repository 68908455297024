import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {HelpModule} from '@shared/help/help.module';
import {IframeModule} from '@shared/iframe/iframe.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {IFRAME_WIDGET} from '@widgets/iframe/iframe-widget-config';
import {IframeWidgetSettingsComponent} from '@widgets/iframe/iframe-widget-settings/iframe-widget-settings.component';
import {IframeWidgetComponent} from '@widgets/iframe/iframe-widget/iframe-widget.component';

/**
 * Module for the iframe widget
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    HelpModule,
    IframeModule
  ],
  declarations: [
    IframeWidgetComponent,
    IframeWidgetSettingsComponent
  ],
  entryComponents: [
    IframeWidgetComponent,
    IframeWidgetSettingsComponent
  ],
  providers: [
    {provide: WIDGET_CONFIGS, useValue: IFRAME_WIDGET, multi: true}
  ]
})
export class IframeWidgetModule {}

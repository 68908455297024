import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {GuestSelection} from '@domain/guest/guest-selection';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';

export class SelectedGuestsDataSource extends DataSource<GuestSelection | null> {

  /**
   * Indicates whether the data source has content or not.
   */
  isEmpty$: Subject<boolean> = new Subject<boolean>();

  private dataStream: BehaviorSubject<(GuestSelection | null)[]> = new BehaviorSubject<(GuestSelection | null)[]>([]);
  private selectedGuestsSubscription: Subscription;

  constructor(private selectedGuests$: BehaviorSubject<GuestSelection[]>) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<(GuestSelection | null)[]> {
    this.selectedGuestsSubscription = this.selectedGuests$.subscribe(data => {
      this.isEmpty$.next(this.isEmpty(data));
      this.dataStream.next(data);
    });
    return this.dataStream;
  }

  disconnect(): void {
    if (this.selectedGuestsSubscription) {
      this.selectedGuestsSubscription.unsubscribe();
    }
  }

  private isEmpty(data: (GuestSelection | null)[]): boolean {
    return !data || data.length === 0;
  }
}

import {ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {OverlayRef} from '@angular/cdk/overlay';
import {ChangeDetectionStrategy, Component, Inject, InjectionToken, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SafeUrl} from '@angular/platform-browser';
import {LaunchpadLinkIconSrcPipe} from '@app/launchpad/launchpad-link-icon-src/launchpad-link-icon-src.pipe';
import {UrlService} from '@core/http/url/url.service';
import {LaunchpadCategory} from '@domain/launchpad/launchpad-category';
import {LaunchpadLink} from '@domain/launchpad/launchpad-link';
import {LaunchpadLinkService} from '@domain/launchpad/launchpad-link.service';
import {LinkPreview} from '@domain/preview/link-preview';
import {WebPreviewService} from '@domain/preview/web-preview/web-preview.service';
import {Ng1CsrfService} from '@root/typings';
import {FileUploadResponse} from '@shared/files/attachment-btn/types/file-upload-response';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {OverlayComponent} from '@shared/overlay/overlay-component';
import {NG1_CSRF_SERVICE} from '@upgrade/upgrade.module';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {BehaviorSubject} from 'rxjs';

/**
 * A custom injection token for the edited link in the overlay.
 */
export const LINK = new InjectionToken<LaunchpadLink>('Link');

/**
 * A custom injection token for the preselected category in the overlay.
 */
export const CATEGORY = new InjectionToken<LaunchpadCategory>('Category');

/**
 * A custom injection token for all available categories in the overlay.
 */
export const CATEGORIES = new InjectionToken<LaunchpadCategory[]>('Categories');

/**
 * The launchpad component that allows adding or editing links.
 */
@Component({
  selector: 'coyo-launchpad-link-manager',
  templateUrl: './launchpad-link-manager.component.html',
  styleUrls: ['./launchpad-link-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadLinkManagerComponent extends OverlayComponent<[LaunchpadCategory, LaunchpadLink]> implements OnInit {
  uploader: FileUploader;
  iconSrc$: BehaviorSubject<SafeUrl> = new BehaviorSubject(null);
  saving$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  linkForm: FormGroup = this.formBuilder.group({
    url: ['', [Validators.required, CoyoValidators.url]],
    name: ['', [Validators.required, Validators.maxLength(255)]],
    category: [null, Validators.required],
    file: [null],
    newIconUid: [null],
    newIconContentType: [null]
  });

  constructor(overlayRef: OverlayRef,
              focusTrapFactory: ConfigurableFocusTrapFactory,
              private formBuilder: FormBuilder,
              private urlService: UrlService,
              private launchpadLinkService: LaunchpadLinkService,
              private launchpadLinkIconSrcPipe: LaunchpadLinkIconSrcPipe,
              private webPreviewService: WebPreviewService,
              @Inject(NG1_CSRF_SERVICE) private csrfService: Ng1CsrfService,
              @Optional() @Inject(LINK) public link: LaunchpadLink,
              @Optional() @Inject(CATEGORY) public category: LaunchpadCategory,
              @Inject(CATEGORIES) public categories: LaunchpadCategory[]) {
    super(overlayRef, focusTrapFactory);
  }

  ngOnInit(): void {
    this.iconSrc$.next(this.launchpadLinkIconSrcPipe.transform(this.link));
    this.linkForm.patchValue({
      url: this.link ? this.link.url : '',
      name: this.link ? this.link.name : '',
      category: this.category || null
    });

    this.csrfService.getToken().then(csrfToken => {
      this.uploader = new FileUploader({
        url: this.urlService.getBackendUrl() + '/web/uploads/temp',
        method: 'POST',
        headers: [{name: 'X-CSRF-TOKEN', value: csrfToken}],
        additionalParameter: {for: 300}
      });
      this.uploader.onSuccessItem = (file: FileItem, response: string) => this.patchForm(file, response);
    });
  }

  /**
   * Triggers the upload of a new link icon.
   */
  uploadIcon(): void {
    this.uploader.queue
      .filter(fileItem => !fileItem.isUploading && !fileItem.isUploaded)
      .forEach(fileItem => fileItem.upload());
  }

  /**
   * Removes the link icon.
   */
  removeIcon(): void {
    this.uploader.queue
      .filter(fileItem => fileItem.isUploaded)
      .forEach(fileItem => fileItem.remove());
    this.iconSrc$.next(null);
    this.linkForm.patchValue({
      file: null,
      newIconUid: this.link ? '' : null,
      newIconContentType: this.link ? '' : null
    });
  }

  /**
   * Saves the managed link.
   */
  save(): void {
    if (this.saving$.getValue()) {
      return;
    }
    this.saving$.next(true);

    const formValue = this.linkForm.getRawValue();
    const context = {categoryId: formValue.category.id};
    const body = {
      url: formValue.url,
      name: formValue.name,
      newIconUid: formValue.newIconUid,
      newIconContentType: formValue.newIconContentType
    };
    const request = this.link
      ? this.launchpadLinkService.put(this.link.id, body, {context})
      : this.launchpadLinkService.post(body, {context});
    request.subscribe(link => this.close([formValue.category, link]), () => this.saving$.next(false));
  }

  /**
   * Fetches the website title and image from the entered URL.
   *
   * @param force boolean flag indicating if the existing user input should be overwritten
   */
  fetchUrlMetaData(force: boolean): void {
    const inputUrl: string = this.linkForm.get('url').value;
    if (inputUrl) {
      const validUrl = !inputUrl.match(/^[a-zA-Z]+:\/\//) ? 'https://' + inputUrl : inputUrl;
      if (force) {
        this.generatePreviews(validUrl, '', null, null, null, true);
      } else {
        this.generatePreviews(validUrl,
          this.linkForm.get('name').value,
          this.linkForm.get('newIconUid').value,
          this.linkForm.get('newIconContentType').value,
          this.iconSrc$.value,
          false);
      }
    }
  }

  private generatePreviews(validUrl: string, name: string, iconUid: string, iconContentType: string, iconSrc: SafeUrl,
                           removeIcon: boolean): void {
    this.webPreviewService.generateWebPreview(validUrl).subscribe(urlData => {
      if (urlData) {
        const linkPreviewData = urlData as LinkPreview;
        this.linkForm.patchValue({
          name: (linkPreviewData.title && name === '') ? linkPreviewData.title : name,
          newIconUid: (linkPreviewData.imageBlobUid && iconUid === null) ? linkPreviewData.imageBlobUid : iconUid,
          newIconContentType: (linkPreviewData.contentType && iconContentType === null) ? linkPreviewData.contentType : iconContentType
        });
        if (linkPreviewData.imageUrl && iconSrc === null) {
          this.iconSrc$.next(linkPreviewData.imageUrl);
        } else if (removeIcon) {
          this.removeIcon();
        }
      }
    });
  }

  private patchForm(file: FileItem, response: string): void {
    const uploadResponse: FileUploadResponse = JSON.parse(response);
    this.iconSrc$.next(window.URL
      ? window.URL.createObjectURL(file._file)
      : (window as any).webkitURL.createObjectURL(file._file));
    this.linkForm.patchValue({
      newIconUid: uploadResponse.uid,
      newIconContentType: uploadResponse.contentType
    });
  }
}

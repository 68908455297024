(function (angular) {
  'use strict';

  angular.module('coyo.admin.apps-widgets')
      .controller('LabController', LabController);

  function LabController() {
  }

})(angular);

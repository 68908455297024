import {Injectable} from '@angular/core';
import Shepherd from 'shepherd.js';

/**
 * A simple Shepherd service wrapper to ease testing.
 */
@Injectable({
  providedIn: 'root'
})
export class ShepherdService {

  /**
   * Creates a new Shepherd tour.
   *
   * @param options the shepherd tour options
   * @returns a new Shepherd tour
   */
  tour(options?: Shepherd.Tour.TourOptions): Shepherd.Tour {
    return new Shepherd.Tour(options);
  }
}

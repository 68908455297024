import {HttpParams} from '@angular/common/http';
import {SearchRequest} from '@domain/search/SearchRequest';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Moment} from 'moment';

/**
 * A event search request.
 */
export class EventSearch extends SearchRequest {

  static DATE_FORMAT: string = 'YYYY-MM-DD';
  static DATE_TIME_FORMAT: string = 'YYYY-MM-DDTHH:mm:ss';

  /**
   * Search parameter "from" (formatted as 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:mm:ss').
   */
  from: string;

  /**
   * Search parameter "to" (formatted as 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:mm:ss').
   */
  to: string;

  constructor(
    term: string = '',
    from: string | undefined,
    to: string | undefined,
    filters: { [key: string]: string | string[] } = {},
    aggregations: { [key: string]: string | string[]; } = {},
    searchFields: string[] = []
  ) {
    super(term, searchFields, filters, aggregations);
    this.from = from;
    this.to = to;
  }

  private static isValidFormattedDate(date: string): boolean {
    return (moment(date, EventSearch.DATE_FORMAT, true) as Moment).isValid();
  }

  private static isValidFormattedDateTime(date: string): boolean {
    return (moment(date, EventSearch.DATE_TIME_FORMAT, true) as Moment).isValid();
  }

  /**
   * Converts this search request to `HttpParams`.
   *
   * @returns a new set of parameters with appended values for this search request
   */
  toHttpParams(): HttpParams {
    let httpParams: HttpParams = super.toHttpParams();

    if (!_.isEmpty(this.from)) {
      if (EventSearch.isValidFormattedDate(this.from)) {
        httpParams = httpParams.append('from', this.from + 'T00:00:00');
      } else if (EventSearch.isValidFormattedDateTime(this.from)) {
        httpParams = httpParams.append('from', this.from);
      }
    }

    if (!_.isEmpty(this.to)) {
      if (EventSearch.isValidFormattedDate(this.to)) {
        httpParams = httpParams.append('to', this.to + 'T23:59:59');
      } else if (EventSearch.isValidFormattedDateTime(this.to)) {
        httpParams = httpParams.append('to', this.to);
      }
    }

    return httpParams;
  }
}

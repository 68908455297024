import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {LinkPreview} from '@domain/preview/link-preview';
import {VideoPreview} from '@domain/preview/video-preview';
import * as _ from 'lodash';

/**
 * Component for a list of link previews.
 */
@Component({
  selector: 'coyo-link-preview-list',
  templateUrl: './link-preview-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkPreviewListComponent {

  /**
   * The link preview to be shown by the component. Orders the previews by their position.
   */
  @Input() set previews(previews: VideoPreview[]) {
    this.sortedPreviews = _.sortBy(previews, ['position']);
  }

  /**
   * Flag for edit mode. If set the previews will render a delete button.
   */
  @Input() editMode: boolean = false;

  /**
   * Emits the link preview that should be deleted from the list.
   */
  @Output() previewDeleted: EventEmitter<LinkPreview> = new EventEmitter<LinkPreview>();

  sortedPreviews: VideoPreview[];

  constructor() {
  }

  /**
   * Tracks the items of the previews array by there URL.
   *
   * @param index the index of the preview in the list
   * @param item the link preview to track
   *
   * @return the URL of the link preview as it is unique for each preview
   */
  trackByUrl(index: number, item: LinkPreview): string {
    return item.url;
  }
}

<div *ngIf="shareState$ | async as shareState">
  <coyo-ribbon *ngIf="showRibbon && shareState.ribbonType" [type]="shareState.ribbonType"
               (click)="markShareAsRead()">
  </coyo-ribbon>
  <div class="timeline-share-header">
    <coyo-sender-avatar *ngIf="shareState.shownAuthor" [sender]="shareState.shownAuthor" class="share-avatar"></coyo-sender-avatar>
    <div class="timeline-share-header-content">
      <div class="timeline-share-header-meta" data-test="timeline-share-header-meta">
        <coyo-sender-link *ngIf="shareState.i18nAuthor === 'OTHER'" [sender]="shareState.shownAuthor"></coyo-sender-link>
        <span [translate]="'MODULE.TIMELINE.SHARE.HEADLINE.' + (itemType | uppercase) + '.' + shareState.i18nAuthor + '_WITH_' + shareState.i18nRecipient"></span>
        <coyo-sender-link *ngIf="shareState.i18nRecipient === 'OTHER'" [sender]="share.recipient"></coyo-sender-link>
        <span translate="MODULE.TIMELINE.SHARE.HEADLINE.SUFFIX"></span>
      </div>
      <div class="timeline-share-header-time">{{ share.created | timeAgo }}</div>
    </div>
  </div>

  <!--We have to consider extracting this to his own component, like in the comment-context-menu-->
  <button type="button" mat-icon-button [matMenuTriggerFor]="menu" size="small"
          [attr.aria-label]="'CONTEXT_MENU.SHOW' | translate"
          *ngIf="!showRibbon && (shareState.canToggleAuthor || shareState.canMakeUnsticky || shareState.canDeleteShare)" class="timeline-share-options">
    <mat-icon svgIcon="more-horizontal"></mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button type="button" mat-menu-item (click)="toggleAuthor()"
            *ngIf="shareState.canToggleAuthor">
      <mat-icon svgIcon="user"></mat-icon>
      <span>{{ 'MODULE.TIMELINE.ORIGINAL_AUTHOR.' + (shareState.shownAuthor?.id === share.author?.id ? '' : 'UN') + 'REVEAL' | translate}}</span>
    </button>
    <button type="button" mat-menu-item (click)="makeUnsticky()"
            *ngIf="shareState.canMakeUnsticky">
      <mat-icon svgIcon="pin"></mat-icon>
      <span>{{'MODULE.TIMELINE.UNSTICKY' | translate}}</span>
    </button>
    <button type="button" mat-menu-item (click)="deleteShare()"
            *ngIf="shareState.canDeleteShare">
      <mat-icon svgIcon="delete"></mat-icon>
      <span>{{'DELETE' | translate}}</span>
    </button>
  </mat-menu>

  <div *ngIf="share.data?.message && share.canAccessRecipient"
       class="timeline-share-message"
       data-test="timeline-share-message"
       [innerHTML]="share.data?.message | markdown | hashtags | mention">
  </div>
</div>

import {Component} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {CalloutWidget} from '@widgets/callout/callout-widget';

/**
 * This widget displays a callout with text.
 */
@Component({
  templateUrl: './callout-widget.component.html',
  styleUrls: ['./callout-widget.component.scss']
})
export class CalloutWidgetComponent extends WidgetComponent<CalloutWidget> {
}

(function (angular) {
  'use strict';

  senderNavigationModalService.$inject = ["modalService"];
  angular.module('commons.ui')
      .factory('senderNavigationModalService', senderNavigationModalService);

  /**
   * @ngdoc service
   * @name commons.ui.senderNavigationModalService
   *
   * @description
   * Navigate through app groups on mobile devices and use the sender related functions.
   *
   * @requires modalService
   */
  function senderNavigationModalService(modalService) {

    return {
      open: open
    };

    /**
     * @ngdoc method
     * @name commons.ui.senderNavigationModalService#open
     * @methodOf commons.ui.senderNavigationModalService
     *
     * @description
     * Method to open the sender navigation modal on mobile devices.
     *
     * @param {object} sender The sender which contains the navigation
     * @param {array} apps A list of th appNavigation apps
     * @param {object} app The currently selected/active app
     *
     * @returns {object} The sender with the updated navigation and apps
     */
    function open(sender, apps, currentApp) {
      return modalService.open({
        size: 'lg',
        templateUrl: 'app/commons/ui/components/sender-navigation/modal/sender-navigation-modal.html',
        controller: 'SenderNavigationModalController',
        controllerAs: '$ctrl',
        resolve: {
          sender: function () {
            return sender;
          },
          apps: function () {
            return apps;
          },
          currentApp: function () {
            return currentApp;
          }
        }
      }).result.then(function (result) {
        return result;
      }, function (error) {
        return error;
      });
    }
  }
})(angular);

import {downgradeInjectable, getAngularJSGlobal} from '@angular/upgrade/static';
import {StripHtmlService} from '@shared/strip-html/strip-html.service';

const angularJS = getAngularJSGlobal();

// downgrade module
angularJS
  .module('commons.ui');

// downgrade service
angularJS
  .module('commons.ui')
  .factory('ngxStripHtmlService', downgradeInjectable(StripHtmlService));

// downgrade filter
angularJS
  .module('commons.ui')
  .filter('stripHtml', ['$injector', stripHtmlFilter]);

function stripHtmlFilter($injector: any): (value: string) => string  {
  const stripHtmlService: StripHtmlService = $injector.get('ngxStripHtmlService');
  return value => stripHtmlService.strip(value);
}

(function () {
  'use strict';

  angular
      .module('commons.ui')
      .component('coyoPageEntryCount', pageEntryCount())
      .controller('PageEntryCountController', PageEntryCountController);

  /**
   * @ngdoc directive
   * @name coyo.commons.ui.pageEntryCount:pageEntryCount
   * @element ANY
   * @restrict AE
   * @scope
   *
   * @description
   * Creates a ui element which shows the total entry count of the given paging information
   *
   * @param {object} page
   * the pagination information
   */
  function pageEntryCount() {
    return {
      templateUrl: 'app/commons/ui/components/page-entry-count/page-entry-count.html',
      bindings: {
        page: '<'
      },
      controller: 'PageEntryCountController',
      controllerAs: '$ctrl'
    };
  }

  function PageEntryCountController() {
  }
})();

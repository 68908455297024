import {Input} from '@angular/core';
import {ChangeDetectionStrategy} from '@angular/core';
import {Component} from '@angular/core';
import {FeatureInfo} from '@app/admin/apps-widgets/coyo-lab/feature-info';

/**
 * Coyo Lab Configuration Item component displays single items in the lab item list
 */
@Component({
  selector: 'coyo-lab-item',
  templateUrl: './coyo-lab-item.component.html',
  styleUrls: ['./coyo-lab-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoyoLabItemComponent {
  /**
   * Information about the feature to be displayed
   */
  @Input() feature: FeatureInfo;
}

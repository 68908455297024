import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {FindOptions, SelectSenderOptions} from '@shared/sender-ui/select-sender/select-sender-options';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {UserProfileWidget} from '@widgets/user-profile/user-profile-widget';

/**
 * User profile widget settings component
 */
@Component({
  selector: 'coyo-user-profile-widget-settings',
  templateUrl: './user-profile-widget-settings.component.html',
  styleUrls: ['./user-profile-widget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileWidgetSettingsComponent extends WidgetSettingsComponent<UserProfileWidget> implements OnInit {

  readonly senderSelectOptions: SelectSenderOptions = {
    senderTypes: ['user'],
    findOptions: FindOptions.ALL
  };

  ngOnInit(): void {
    this.parentForm.addControl('_selectedUser', new FormControl(this.widget.settings._selectedUser, [Validators.required]));
    this.parentForm.addControl('_showInfo', new FormControl(this.widget.settings._showInfo));
  }
}

import {Injectable} from '@angular/core';
import {DomainService} from '@domain/domain/domain.service';
import {Document} from '../document';

interface DownloadableDocument {
  id: string;
  senderId: string;
  modified?: number;
}

/**
 * Service for document requests
 */
@Injectable({
  providedIn: 'root'
})
export class DocumentService extends DomainService<Document, Document> {

  protected getBaseUrl(): string {
    return '/web/senders/{senderId}/documents';
  }

  /**
   * Extracts the sender ID from the given document url.
   *
   * @param url
   * The url.
   *
   * @returns the sender ID or `null`.
   */
  extractSenderId(url: string): string | null {
    const regex = /.*\/web\/senders\/([a-z0-9-]+)\/documents.*/;
    const match = regex.exec(url);
    return match ? match[1] : null;
  }

  /**
   * Returns the download URL for the given document.
   *
   * @param document
   * The document.
   *
   * @returns the download url.
   */
  getDownloadUrl(document: Document | DownloadableDocument): string {
    const backendUrl = this.urlService.getBackendUrl();
    const url = this.getUrl({senderId: document.senderId}, document.id);
    const downloadUrl = !!backendUrl ? this.urlService.join(backendUrl, url) : url;
    return downloadUrl + (document.modified ? '?modified=' + document.modified : '');
  }

  /**
   * Gets the url for streaming the given document
   *
   * @param document
   * The document.
   *
   * @return the stream url.
   */
  getStreamUrl(document: Document): string {
    return this.urlService.join(
      this.urlService.getBackendUrl(),
      this.getUrl({senderId: document.senderId}, document.id)
    ) + '/stream';
  }
}

import {downgradeComponent, getAngularJSGlobal} from '@angular/upgrade/static';
import {SearchSortingMenuComponent} from '@app/search/search-sorting-menu/search-sorting-menu.component';

getAngularJSGlobal()
  .module('coyo.search')
  .directive('coyoSearchSortingMenu', downgradeComponent({
    component: SearchSortingMenuComponent,
    inputs: ['sortingOptions', 'activeSortingOption', 'disabled', 'buttonOnly'],
    outputs: ['sortingChanged']
  }));

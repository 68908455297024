(function (angular) {
  'use strict';

  PageSettingsController.$inject = ["$injector", "$state", "modalService", "pageOrigin", "members"];
  angular
      .module('coyo.pages')
      .controller('PageSettingsController', PageSettingsController);

  /**
   * Controller for the page edit view
   */
  function PageSettingsController($injector, $state, modalService, pageOrigin, members) {
    var vm = this;

    vm.save = savePage;
    vm.delete = deletePage;
    vm.isTranslationRequired = isTranslationRequired;
    vm.updateValidity = updateValidity;
    vm.$onInit = onInit;

    function savePage() {
      vm.pageOrigin.categoryIds = _.map(vm.pageOrigin.categories, 'id');

      // Add translations for default language to page model
      vm.pageOrigin.defaultLanguage = pageOrigin.defaultLanguage !== null ? pageOrigin.defaultLanguage : 'NONE';
      angular.forEach(vm.languages[vm.pageOrigin.defaultLanguage].translations, function (value, key) {
        vm.pageOrigin[key] = value;
      });

      // Add appNavigation translations again
      _.forEach(vm.pageOrigin.translations, function (pageTranslation, key) {
        if (_.has(pageTranslation, 'appNavigation') && angular.isDefined(vm.languages[key])) {
          vm.languages[key].translations.active = true;
          vm.languages[key].translations.appNavigation = pageTranslation.appNavigation;
        }
      });
      // Add additional translations to page model
      vm.pageOrigin.translations = {};
      angular.forEach(vm.languages, function (value, key) {
        if (value.active) {
          vm.pageOrigin.translations[key] = value.translations ? value.translations : {};
        }
      });
      delete vm.pageOrigin.translations[vm.pageOrigin.defaultLanguage];
      if (vm.pageOrigin.defaultLanguage === 'NONE') {
        vm.pageOrigin.defaultLanguage = null;
      }

      return vm.pageOrigin.update().then(function (pageOrigin) {
        $injector.get('ngxNotificationService').success('MODULE.PAGES.EDIT.SUCCESS');
        if ($state.is('main.page.show.settings')) {
          $state.go('main.page.show', {idOrSlug: pageOrigin.slug}, {reload: 'main.page.show'});
        }
      });
    }

    function deletePage() {
      $injector.get('ngxDeleteConfirmationService').openLegacy(
          'PAGE.DELETE.TITLE',
          'PAGE.DELETE.TEXT',
          'PAGE.DELETE.CONFIRM_BUTTON',
          'CANCEL',
          {},
          true
      ).then(function (deleted) {
        if (deleted) {
          vm.pageOrigin.delete().then(function () {
            $state.go('main.page');
            $injector.get('ngxNotificationService').success('PAGE.DELETE.SUCCESS');
          });
        }
      });
    }

    function isTranslationRequired(language) {
      return vm.pageOrigin.isTranslationRequired(vm.languages, vm.currentLanguage, language);
    }

    function updateValidity(key, valid) {
      vm.languages[key].valid = valid;
    }

    function onInit() {
      vm.pageOrigin = angular.extend(pageOrigin, members);
      vm.baseUrl = $state.href('main.page', {}, {inherit: false}) + '/';
      vm.oldName = pageOrigin.displayName;
      vm.oldSlug = pageOrigin.slug;
      vm.oldAutoSubscribeType = pageOrigin.autoSubscribeType;

      vm.currentLanguage = pageOrigin.defaultLanguage !== null ? pageOrigin.defaultLanguage : 'NONE';
      vm.pageOrigin.defaultLanguage = pageOrigin.defaultLanguage !== null ? pageOrigin.defaultLanguage : 'NONE';
      vm.languages = {};
      vm.languages[vm.currentLanguage] = {
        'active': true,
        'translations': {
          'name': pageOrigin.name,
          'description': pageOrigin.description
        }
      };
      _.forEach(pageOrigin.translations, function (value, key) {
        vm.languages[key] = {
          'active': true,
          'translations': _.has(value, 'name') ? value : {} // remove appNavigation translations during page translation
        };
      });

      _.forEach(vm.pageOrigin.categories, function (category) {
        category.displayName = category.name;
      });
    }
  }

})(angular);

import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Document} from '@domain/file/document';
import {Sender} from '@domain/sender/sender';
import {CropSettings, SelectFileOptions} from '@shared/select-file/select-file-options';
import * as _ from 'lodash';
import {Observable, ReplaySubject} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';

const valueAccessor = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectFileComponent), // tslint:disable-line:no-use-before-declare
  multi: true
};

interface SelectFileState {
  selectedFiles: Document[];
}

@Component({
  selector: 'coyo-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor]
})
export class SelectFileComponent implements OnInit, ControlValueAccessor {

  /**
   * The sender
   */
  @Input() sender: Sender;

  /**
   * The options
   */
  @Input() options: SelectFileOptions;

  /**
   * The crop settings
   */
  @Input() cropSettings: CropSettings;

  state$: Observable<SelectFileState>;

  private files$: ReplaySubject<Document[]> = new ReplaySubject<Document[]>(1);

  private defaultOptions: SelectFileOptions = {
    uploadMultiple: true,
    selectMode: 'multiple',
    showAuthors: false
  };

  private defaultCropSettings: CropSettings = {
    cropImage: false
  };

  private onChangeFn: (param: Document[] | Document) => void;

  ngOnInit(): void {
    this.options = {...this.defaultOptions, ...this.options};
    this.cropSettings = this.cropSettings || {} ? this.defaultCropSettings : this.cropSettings;

    this.state$ = this.files$.pipe(
      map(files => this.sortSelectedFiles(files)),
      tap(files => {
        if (this.options.selectMode === 'single') {
          this.onChangeFn(files.length === 1 ? files[0] : null);
        } else {
          this.onChangeFn(files);
        }
      }),
      map(selectedFiles => ({selectedFiles})),
      startWith({selectedFiles: []}));
  }

  /**
   * Select a file using the legacy COYO file picker.
   * @param selection the newly selected files
   * @param selectedFiles the old selected files
   */
  selectFile(selection: Document[], selectedFiles: Document[]): void {
    const newFiles = this.options.selectMode === 'single'
      ? selection : _.unionWith(selectedFiles, selection, (a, b) => a.id === b.id);
    this.files$.next(selection ? newFiles : []);
  }

  /**
   * Remove the given file from the selected files.
   * @param file the file
   * @param selectedFiles the selected files
   */
  removeFile(file: Document, selectedFiles: Document[]): void {
    this.files$.next(_.filter(selectedFiles, element => element.id !== file.id));
  }

  registerOnChange(fn: (param: Document[] | Document) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: (param: Document[] | Document) => void): void {
  }

  writeValue(value: Document[] | Document): void {
    if (this.sender) {
      if (value instanceof Array) {
        this.files$.next(value);
      } else if (value) {
        this.files$.next([value]);
      }
    }
  }

  private sortSelectedFiles(values: Document[]): Document[] {
    return values.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
}

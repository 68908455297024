import {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FeatureInfo} from '@app/admin/apps-widgets/coyo-lab/feature-info';
import {FeatureState} from '@core/feature/feature-toggle-service/feature-state';
import {FeatureToggleService} from '@core/feature/feature-toggle-service/feature-toggle.service';
import {Skeleton} from '@coyo/ui';
import {merge} from 'rxjs';
import {Subject} from 'rxjs';
import {forkJoin} from 'rxjs';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {switchMap} from 'rxjs/operators';

export const COYO_LAB_TAG = 'coyo_lab';

/**
 * Coyo Lab Configuration component
 */
@Component({
  selector: 'coyo-lab',
  templateUrl: './coyo-lab.component.html',
  styleUrls: ['./coyo-lab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoyoLabComponent implements OnInit {

  readonly skeletons: Skeleton[] = [
    {top: 0, left: 0, width: '40%', height: 16},
    {top: 25, left: 0, width: 64, height: 14},
    {top: 25, left: 72, width: 'calc(30% - 72px)', height: 14}
  ];
  features$: Observable<FeatureInfo[]>;
  private toggle$: Subject<FeatureInfo>;

  constructor(private readonly featureToggleService: FeatureToggleService) {
    this.toggle$ = new Subject<FeatureInfo>();

  }

  ngOnInit(): void {
    this.features$ = merge(
      this.toggle$.pipe(
        switchMap(featureInfo => this.featureToggleService.setFeatureEnabled(featureInfo.name, !featureInfo.states['enabled'])),
        switchMap(() => this.loadFeatures())
      ),
      this.loadFeatures()
    );
  }

  getFeatureIcon(feature: FeatureState): string {
    return `${feature.name.toLowerCase().split('_').join('-')}`;
  }

  getFeatureName(feature: FeatureState): string {
    return `ADMIN.APPS_WIDGETS.LAB.FEATURE.${feature.name.toUpperCase()}.NAME`;
  }

  getFeatureDescription(feature: FeatureState): string {
    return `ADMIN.APPS_WIDGETS.LAB.FEATURE.${feature.name.toUpperCase()}.DESCRIPTION`;
  }

  toggle(feature: FeatureInfo): void {
    this.toggle$.next(feature);
  }

  private collectFeatureStates(featureNames: string[]): Observable<FeatureInfo[]> {
    return forkJoin(
      featureNames.map(
        featureName => this.featureToggleService.getFeatureState(featureName)
      )
    )
      .pipe(
        map(states => states.map(state => this.extendFeatureState(state)
          )
        )
      );
  }

  private loadFeatures(): Observable<FeatureInfo[]> {
    return this.featureToggleService.getCategoryFeatures(COYO_LAB_TAG).pipe(
      switchMap(features => this.collectFeatureStates(features)),
      map(featureInfos => featureInfos.filter(featureInfo => featureInfo.states.available))
    );
  }

  private extendFeatureState(featureState: FeatureState): FeatureInfo {
    return {
      nameKey: this.getFeatureName(featureState),
      icon: this.getFeatureIcon(featureState),
      descriptionKey: this.getFeatureDescription(featureState),
      ...featureState
    };
  }
}

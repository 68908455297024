(function (angular) {
  'use strict';

  PageController.$inject = ["$scope", "$rootScope", "$state", "$transitions", "$location", "$urlRouter", "$injector", "page", "PageModel", "apps", "senderService", "backendUrlService", "senderNavigationUpdateService", "SenderModel", "currentUser", "appService"];
  angular
      .module('coyo.pages')
      .controller('PageController', PageController);

  /**
   * Controller for the pages details/show view
   */
  function PageController($scope, $rootScope, $state, $transitions, $location, $urlRouter, $injector, page, PageModel,
                          apps, senderService, backendUrlService, senderNavigationUpdateService, SenderModel,
                          currentUser, appService) {
    var vm = this;
    vm.page = page;
    vm.senderModel = new SenderModel({id: page.id});
    vm.apps = apps;
    vm.currentUser = currentUser;
    vm.appsStatus = {busy: false};
    vm.backendUrl = backendUrlService.getUrl();
    vm.displayMobileNavigation = vm.apps.length || (vm.page._permissions.manage) || vm.page._permissions.manageApps;

    vm.changeCover = senderService.changeCover({title: 'MODULE.PAGES.MODALS.CHANGE_BG_IMAGE.TITLE'});
    vm.changeAvatar = function (sender) {
      senderService.changeAvatar({title: 'MODULE.PAGES.MODALS.CHANGE_AVATAR.TITLE'})(sender).then(function () {
        $scope.$broadcast('pageAvatar:changed');
      });
    };
    vm.addApp = addApp;
    vm.addGroup = addGroup;
    vm.$onInit = _init;

    function addApp(sender, apps) {
      if (!vm.appsStatus.busy) {
        appService.addApp(sender, apps);
      }
    }

    function addGroup() {
      if (vm.appsStatus.busy) {
        return;
      }
      vm.appsStatus.busy = true;

      senderNavigationUpdateService.createNavigationGroup(vm.page.id).then(function (result) {
        vm.page.appNavigation = result;
      }).finally(function () {
        vm.appsStatus.busy = false;
      });
    }

    function _getCurrentApp(appIdOrSlug) {
      return _.find(vm.apps, function (app) {
        return appIdOrSlug === app.slug || appIdOrSlug === app.id;
      });
    }

    function _init() {
      if (vm.apps.length > 0 && $state.is('main.page.show') || $state.includes('main.page.show.apps')) {
        vm.currentApp = _getCurrentApp(appService.getCurrentAppIdOrSlug());
        if (!vm.currentApp) {
          appService.redirectToSender(vm.page, vm.apps);
        }
      }

      var unsubscribeTransition = $transitions.onBefore({to: 'main.page.show'}, function (transition) {
        var isReload = transition.from().name === transition.to().name;
        var params = transition.params();
        if (params.idOrSlug === vm.page.slug && !isReload && !params.appDeleted) {
          transition.abort();
        }
      });

      // Subscribe to events (and unsubscribe if not needed anymore)
      var unsubscribeAppChanged = appService.onAppChanged(function (appId) {
        vm.currentApp = _getCurrentApp(appId);
      });
      var unsubscribeAppUpdated = $rootScope.$on('app:updated', function (event, app, suppressReload) {
        suppressReload = suppressReload || false;
        appService.updateApp(app, vm.apps);
        if (vm.currentApp && vm.currentApp.id === app.id) {
          if (vm.currentApp.slug !== app.slug) {
            appService.redirectToApp(vm.page, app, false);
          } else if (!suppressReload) {
            $state.reload();
          }
          vm.currentApp = app;
        }
      });
      var unsubscribeAppDeleted = $rootScope.$on('app:deleted', function (event, appId) {
        appService.deleteApp(appId, vm.apps);
        if (vm.currentApp && vm.currentApp.id === appId) {
          appService.redirectToSender(vm.page, vm.apps);
        }
      });
      var unsubscribeAppNavigationUpdated = $scope.$on('appNavigation:updated', function () {
        PageModel.getWithPermissions({id: vm.page.id}, {},
            ['manage', 'delete', 'manageApps', 'manageSlots', 'createFile']).then(function (result) {
          vm.page.appNavigation = result.appNavigation;
        });
      });
      var unsubscribeAppsUpdated = $scope.$on('apps:updated', function () {
        vm.senderModel.getApps().then(function (data) {
          vm.apps = data;
          vm.currentApp = _getCurrentApp(appService.getCurrentAppIdOrSlug());
        });
      });

      $scope.$on('$destroy', unsubscribeTransition);
      $scope.$on('$destroy', unsubscribeAppChanged);
      $scope.$on('$destroy', unsubscribeAppUpdated);
      $scope.$on('$destroy', unsubscribeAppDeleted);
      $scope.$on('$destroy', unsubscribeAppNavigationUpdated);
      $scope.$on('$destroy', unsubscribeAppsUpdated);
    }
  }
})(angular);

(function (angular) {
  'use strict';

  angular
      .module('commons.layout')
      .directive('oyocScrollScope', scrollScope);

  /**
   * @ngdoc directive
   * @name coyo.app.oyocScrollScope:oyocScrollScope
   * @element ANY
   * @restrict A
   * @scope
   *
   * @description
   * Captures scroll events for the bound element and makes sure that the scroll distance is
   * applied only to this one element (and not to the body).
   */
  function scrollScope() {
    return {
      restrict: 'A',
      link: function (scope, element) {

        function _setScrollPosition(event) {
          event.preventDefault();
          element[0].scrollTop = _calculateScrollDistance(event, element[0].scrollTop);
        }

        function _calculateScrollDistance(event, currentScrollTop) {
          if (event.type === 'DOMMouseScroll') {
            return 40 * event.detail + currentScrollTop;
          }
          return (event.wheelDelta * -1) + currentScrollTop;
        }

        element.on('mousewheel DOMMouseScroll', _setScrollPosition);
        scope.$on('$destroy', function () {
          element.off('mousewheel DOMMouseScroll', _setScrollPosition);
        });
      }
    };
  }

})(angular);

(function (angular) {
  'use strict';

  BlogListController.$inject = ["$scope", "$state", "$q", "ngxPrintPreviewDialogService", "moment", "modalService", "BlogArticleModel", "app", "blogAppConfig", "currentUser"];
  angular
      .module('coyo.apps.blog')
      .controller('BlogListController', BlogListController);

  /**
   * Controller for blog article listing.
   *
   * @requires $scope
   * @requires $state
   * @requires $q
   * @requires moment
   * @requires modalService
   * @requires coyo.apps.blog.BlogArticleModel
   * @requires app
   * @requires blogAppConfig
   * @requires currentUser
   * @constructor
   */
  function BlogListController($scope, $state, $q, ngxPrintPreviewDialogService, moment, modalService, BlogArticleModel, app, blogAppConfig,
                              currentUser) {
    var vm = this;
    vm.app = app;
    vm.isNewMonth = isNewMonth;
    vm.filterActive = filterActive;
    vm.toggleIncludePublished = toggleIncludePublished;
    vm.toggleIncludeScheduled = toggleIncludeScheduled;
    vm.toggleIncludeDrafts = toggleIncludeDrafts;
    vm.toggleFilterYear = toggleFilterYear;
    vm.toggleFilterMonth = toggleFilterMonth;
    vm.deleteArticle = deleteArticle;
    vm.$onInit = onInit;
    vm.currentUser = currentUser;
    vm.addShare = addShare;
    vm.deleteShares = deleteShares;
    vm.printPreview = printPreview;

    vm.blogArticles = {
      content: [],
      _queryParams: {
        _page: 0,
        _pageSize: blogAppConfig.paging.pageSize,
        _orderBy: 'publishDate,created,desc',
        includePublished: true,
        includeScheduled: true,
        includeDrafts: true
      }
    };

    function isNewMonth(index) {
      if (index === 0) {
        return false;
      }
      var current = vm.blogArticles.content[index];
      var last = vm.blogArticles.content[index - 1];
      return !moment(current.publishDate).isSame(last.publishDate, 'month');
    }

    function filterActive() {
      return !(vm.blogArticles._queryParams.includePublished &&
          vm.blogArticles._queryParams.includeScheduled &&
          vm.blogArticles._queryParams.includeDrafts &&
          !vm.blogArticles._queryParams.limitDate);
    }

    function toggleIncludePublished() {
      vm.blogArticles._queryParams.includePublished = !vm.blogArticles._queryParams.includePublished;
      return $q.all([vm.blogArticles.page(0), initFilter()]);
    }

    function toggleIncludeScheduled() {
      vm.blogArticles._queryParams.includeScheduled = !vm.blogArticles._queryParams.includeScheduled;
      return $q.all([vm.blogArticles.page(0), initFilter()]);
    }

    function toggleIncludeDrafts() {
      vm.blogArticles._queryParams.includeDrafts = !vm.blogArticles._queryParams.includeDrafts;
      return $q.all([vm.blogArticles.page(0), initFilter()]);
    }

    function toggleFilterYear(year) {
      vm.filterYear = vm.filterYear === year ? null : year;
    }

    function toggleFilterMonth(yearMonth) {
      if (vm.filterMonth !== yearMonth) {
        vm.blogArticles._queryParams.limitDate = yearMonth;
        vm.filterMonth = yearMonth;
        vm.filterYearActive = yearMonth.substring(0, 4);
      } else {
        delete vm.blogArticles._queryParams.limitDate;
        vm.filterMonth = null;
        vm.filterYearActive = null;
      }
      vm.blogArticles.page(0);
    }

    function addShare(share, index) {
      $scope.$apply(function () {
        var article = angular.copy(vm.blogArticles.content[index]);
        if (!article.shares) {
          article.shares = [];
        }
        article.shares.push(share);
        vm.blogArticles.content[index] = article;
      });
    }

    function deleteShares(deletedShares, index) {
      $scope.$apply(function () {
        var article = angular.copy(vm.blogArticles.content[index]);
        deletedShares.forEach(function (share) {
          var idx = _.findIndex(article.shares, {id: share.id});
          if (idx > -1) {
            article.shares.splice(idx, 1);
          }
        });
        vm.blogArticles.content[index] = article;
      });
    }

    function initFilter() {
      var qp = vm.blogArticles._queryParams || {};
      return BlogArticleModel.count(vm.app, qp.includePublished, qp.includeScheduled, qp.includeDrafts)
          .then(function (data) {
            vm.timeFilter = _.chain(data).toPairs().orderBy(function (item) {
              return item[0];
            }).map(function (item) {
              return {
                year: item[0].split('-')[0],
                time: item[0],
                moment: moment(item[0] + '-01'),
                count: item[1]
              };
            }).groupBy('year').forEach(function (value) {
              value.sum = _.sumBy(value, 'count');
            }).value();
          });
    }

    function onInit() {
      vm.blogArticles.loading = true;
      return BlogArticleModel.pagedQueryWithPermissions(
          undefined, vm.blogArticles._queryParams, {senderId: app.senderId, appId: app.id},
          ['edit', 'delete', 'like', 'comment', 'share']
      ).then(function (result) {
        vm.blogArticles = result;
        initFilter();
      }).finally(function () {
        vm.blogArticles.loading = false;
      });
    }

    function deleteArticle(article) {
      var alerts = [];
      var translationContext = {title: article.title};
      var shareCount = article.shares.length;

      if (shareCount > 0) {
        alerts.push({
          level: 'danger',
          title: 'APP.BLOG.ARTICLE.DELETE.WARNING.TITLE',
          text: 'APP.BLOG.ARTICLE.DELETE.MULTIPLE.SHARE.TEXT'
        });
        translationContext.shareCount = shareCount;
      }

      modalService.confirmDelete({
        title: 'APP.BLOG.MODAL.DELETE.TITLE',
        text: 'APP.BLOG.MODAL.DELETE.TEXT',
        alerts: alerts,
        translationContext: translationContext
      }).result.then(function () {
        article.delete().then(function () {
          $state.go($state.current.name, null, {reload: $state.current.name});
        });
      });
    }

    function printPreview(article) {
      return ngxPrintPreviewDialogService.openLegacy({
        appId: vm.app.id,
        articles: rxjs.of([article]),
        pageTitle: article.title,
        articleTitle: article.title,
        selectedLanguage: article.usedLanguage,
        showLoadingBar: false
      });
    }

  }

})(angular);

<div class="welcome-widget panel panel-default panel-sm"
     [style.background-image]="coverStyle"
     [ngClass]="{'show-cover': showCover$ | async}">
  <div class="panel-body user-info" *ngIf="currentUser$ | async; let currentUser">
    <coyo-user-avatar [user]="currentUser" [showOnlineStatus]="true"></coyo-user-avatar>
    <div class="user-name">
      {{ widget.settings.welcomeText || ('WIDGET.WELCOME.TEXT' | translate)}}<br>
      <strong>{{currentUser.displayName }}</strong>
    </div>
  </div>
</div>

import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TooltipPosition} from '@angular/material/tooltip';
import {DialogComponent, DialogService, MatDialogSize} from '@coyo/ui';

/**
 * Renders a help icon with an info text in a tooltip.
 */
@Component({
  selector: 'coyo-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpComponent implements OnDestroy {
  private marked: any = require('marked');
  private dialogRef: MatDialogRef<DialogComponent, void>;

  /**
   * The tooltip text.
   */
  @Input() tip: string;

  /**
   * The placement of the tooltip.
   */
  @Input() placement?: TooltipPosition = 'left';

  /**
   * The title for a help modal.
   * The modal opens if the modalText @Input is set and when the button is clicked.
   */
  @Input() modalTitle?: string;

  /**
   * The text for a help modal. The text is interpreted as raw markdown.
   * The modal opens if the modalText @Input is set and when the button is clicked.
   */
  @Input() modalText?: string;

  /**
   * An ARIA label to use for the button.
   */
  @Input() modalAria?: string;

  constructor(private dialogService: DialogService) {
  }

  /**
   * Opens a modal.
   *
   * @param $event the click event
   */
  openModal($event: Event): void {
    $event.preventDefault();
    if (this.modalText) {
      this.marked.use(this.marked.getDefaults());
      this.dialogRef = this.dialogService.open({
        title: this.modalTitle,
        text: this.marked(this.modalText).replace(/\n$/g, ''),
        renderHtml: true,
        config: {
          width: MatDialogSize.Large,
          panelClass: 'help-dialog'
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}

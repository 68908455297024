<form name="form" [formGroup]="reportForm" (ngSubmit)="onSubmit()" novalidate>
  <h1 mat-dialog-title translate="MODULE.REPORT.MODAL.TITLE"></h1>
  <mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
  <div mat-dialog-content>
    <p translate="MODULE.REPORT.MODAL.TEXT"></p>
    <textarea id="message" name="message" formControlName="message"
              class="form-control resize-v resize-max"
              placeholder="{{'MODULE.REPORT.MODAL.PLACEHOLDER' | translate}}"
              autosize cdkFocusInitial>
    </textarea>
    <coyo-checkbox formControlName="anonymous" label="MODULE.REPORT.MODAL.ANONYMOUS.LABEL"></coyo-checkbox>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button type="submit" color="primary"
            [disabled]="!reportForm.valid || sendFormData">{{'MODULE.REPORT.MODAL.SUBMIT' | translate}}
    </button>
  </div>
</form>

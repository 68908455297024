import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {SingleFileWidget} from '@widgets/single-file/single-file-widget';
import {SingleFileWidgetSettingsComponent} from '@widgets/single-file/single-file-widget-settings/single-file-widget-settings.component';
import {SingleFileWidgetComponent} from '@widgets/single-file/single-file-widget/single-file-widget.component';

export const SINGLE_FILE_WIDGET: WidgetConfig<SingleFileWidget> = {
  name: 'WIDGET.SINGLEFILE.NAME',
  key: 'singlefile',
  description: 'WIDGET.SINGLEFILE.DESCRIPTION',
  icon: 'zmdi-file',
  categories: WidgetCategory.STATIC,
  component: SingleFileWidgetComponent,
  settings: {
    skipOnCreate: false,
    component: SingleFileWidgetSettingsComponent
  },
  renderOptions: {
    printable: true,
  },
  whitelistExternal: true
};

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {BookmarkingWidget} from '@widgets/bookmarking/bookmarking-widget';
import {BookmarkingComponent} from '@widgets/bookmarking/bookmarking-widget/bookmarking.component';

export const BOOKMARKING_WIDGET: WidgetConfig<BookmarkingWidget> = {
  key: 'bookmarking',
  name: 'WIDGETS.BOOKMARKING.NAME',
  description: 'WIDGETS.BOOKMARKING.DESCRIPTION',
  defaultTitle: 'WIDGETS.BOOKMARKING.NAME',
  icon: 'zmdi-collection-bookmark',
  component: BookmarkingComponent,
  categories: WidgetCategory.STATIC,
  renderOptions: {
    printable: true
  },
  whitelistExternal: true,
  mobileSupportTypes: ['landing-page']
};

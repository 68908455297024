<form class="form-horizontal share-form" [formGroup]="shareForm" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title translate="COMMONS.SHARES.CREATE.TITLE"></h1>
  <mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
  <div mat-dialog-content data-test="mat_dialog-content">
    <p class="alert alert-warning" role="alert" *ngIf="!data.parentIsPublic"
         translate="COMMONS.SHARES.WARNING.PRIVATE"></p>
    <div class="form-group row">
      <label class="col-sm-3 control-label" for="shareWith" translate="COMMONS.SHARES.TIMELINE.LABEL"></label>
      <div class="col-sm-9">
        <coyo-select-sender id="shareWith" name="shareWith" formControlName="shareWith"
                            placeholder="COMMONS.SHARES.PLACEHOLDER" data-test="share-with-sender-select"
                            [options]="shareWithOptions"></coyo-select-sender>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 control-label" for="message" translate="COMMONS.SHARES.MESSAGE.LABEL"></label>
      <div class="col-sm-9">
        <textarea id="message" name="message" formControlName="message"
                  class="form-control resize-v resize-max"
                  autosize coyoMention cdkFocusInitial data-test="share-message"></textarea>
      </div>
    </div>
    <coyo-divider>
      <button mat-button size="small" type="button" (click)="showExtendedOptions = !showExtendedOptions">
        {{'COMMONS.SHARES.EXTENDED_OPTIONS' | translate}}
        <mat-icon [svgIcon]="showExtendedOptions ? 'chevron-up' : 'chevron-down'" class="right"></mat-icon>
      </button>
    </coyo-divider>
    <div *ngIf="showExtendedOptions">
      <div class="form-group row">
        <label class="col-sm-3 control-label" for="shareAs" translate="COMMONS.SHARES.FROM.LABEL"></label>
        <div class="col-sm-9">
          <coyo-select-sender id="shareAs" name="shareAs" formControlName="shareAs"
                              placeholder="COMMONS.SHARES.PLACEHOLDER" data-test="share-as-sender-select"
                              [options]="shareAsOptions"></coyo-select-sender>
        </div>
      </div>
      <div class="form-group row" *ngIf="data.canCreateStickyShare | async">
        <label class="col-sm-3 control-label" for="stickyExpiry"
               translate="COMMONS.SHARES.STICKY.EXPIRY.LABEL"></label>
        <div class="col-sm-9">
          <ng-select id="stickyExpiry" name="stickyExpiry" formControlName="stickyExpiry"
                     appendTo=".ng-select-overlay-container" data-test="share-sticky-expiry"
                     [items]="stickyExpiryOptions" [searchable]="false" [clearable]="false">
            <ng-template ng-option-tmp let-item="item">{{item.label | translate}}</ng-template>
            <ng-template ng-label-tmp let-item="item">{{item.label | translate}}</ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button type="submit" color="primary" [disabled]="!shareForm.valid"
            data-test="share-submit">{{'COMMONS.SHARES.SUBMIT' | translate}}</button>
  </div>
</form>


import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {LOCAL_FILE_LIBRARY, StorageType} from '@domain/attachment/storage';
import {Sender} from '@domain/sender/sender';

/**
 * File picker item showing the the name and the sender avatar.
 */
export class SenderRootFilePickerItem implements FilePickerItem {
  readonly id: string;
  readonly name: string;
  readonly isFolder: boolean = true;
  readonly storageType: StorageType = LOCAL_FILE_LIBRARY;
  readonly permissions: { [permission: string]: boolean };

  constructor(readonly sender: Sender) {
    this.id = sender.id;
    this.name = sender.displayName;
    this.permissions = sender._permissions;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FileLibraryFileDetailsService} from '@app/file-library/file-library-file-details/file-library-file-details.service';
import {FileLibraryFilePickerItemPublicShareDialogComponent} from '@app/file-library/file-library-file-picker-item-public-share-dialog/file-library-file-picker-item-public-share-dialog.component';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemServiceSelectorService} from '@app/file-picker/file-picker-item-service-selector.service';
import {FilePickerItemStateModel} from '@app/file-picker/file-picker-state/file-picker-state-model';
import {
  DeleteFilePickerItem,
  ToggleRenamingFilePickerItem,
  UpdateFile
} from '@app/file-picker/file-picker-state/file-picker.actions';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {MatDialogSize} from '@coyo/ui';
import {FileUploadEvent} from '@domain/file/file/events/file-upload-event';
import {Store} from '@ngxs/store';
import {DeleteConfirmationService} from '@shared/dialog/delete-confirmation/delete-confirmation.service';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {EMPTY, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

/**
 * Context menu component for file library
 */
@Component({
  selector: 'coyo-file-library-file-picker-item-context-menu',
  templateUrl: './file-library-file-picker-item-context-menu.component.html',
  styleUrls: ['./file-library-file-picker-item-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryFilePickerItemContextMenuComponent implements OnChanges, OnDestroy {

  /**
   * The filepicker id
   */
  @Input() filePickerId: string;

  /**
   * The filepicker item id
   */
  @Input() itemId: string;

  @HostBinding('class.open') open: boolean = false;
  @ViewChild('files', {static: false}) files: ElementRef<HTMLInputElement>;

  item$: Observable<FilePickerItemStateModel>;
  deepLink$: Observable<string>;
  canRename$: Observable<boolean>;
  canDelete$: Observable<boolean>;
  canPublicLink$: Observable<boolean>;
  canEditWithOffice$: Observable<boolean>;
  canDownload$: Observable<boolean>;
  extension$: Observable<string>;
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService,
    private readonly filePickerItemServiceSelectorService: FilePickerItemServiceSelectorService,
    private readonly deleteConfirmationService: DeleteConfirmationService,
    private readonly fileLibraryFileDetailsService: FileLibraryFileDetailsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemId) {
      this.item$ = this.store.select(FilePickerStateSelectors.item(this.itemId));
      this.deepLink$ = this.getDeepLink();
      this.canRename$ = this.store.select(FilePickerStateSelectors.canRename(this.itemId));
      this.canDelete$ = this.store.select(FilePickerStateSelectors.canDelete(this.itemId));
      this.canPublicLink$ = this.store.select(FilePickerStateSelectors.canPublicLink(this.itemId));
      this.canEditWithOffice$ = this.store.select(FilePickerStateSelectors.canEditWithOffice(this.itemId));
      this.canDownload$ = this.store.select(FilePickerStateSelectors.canDownload(this.itemId));
      this.extension$ = this.item$.pipe(
        map(item => (item.item as FileLibraryFilePickerItem)?.file?.extension?.toLowerCase()),
        map(extension => !extension || extension.match(/[a-zA-Z0-9]/).length !== 1 ? '*' : extension),
        map(extension => `.${extension}`)
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openPublicShareDialog(item: FilePickerItem): void {
    if (item instanceof FileLibraryFilePickerItem) {
      this.dialog.open(FileLibraryFilePickerItemPublicShareDialogComponent, {
        width: MatDialogSize.Small,
        data: {
          hasPublicLink: item.hasPublicLink,
          id: item.file.id,
          senderId: item.file.senderId
        }
      });
    }
  }

  selectFile(): void {
    this.files.nativeElement.click();
  }

  showFileDetails(item: FilePickerItem): void {
    if (item instanceof FileLibraryFilePickerItem) {
      this.fileLibraryFileDetailsService.openFilePreview(this.filePickerId, item);
    }
  }

  showCopyLinkNotification(): void {
    this.notificationService.success('FILE_LIBRARY.COPY_LINK.SUCCESS');
  }

  setOpened(open: boolean): void {
    this.open = open;
  }

  startRenaming(): void {
    this.store.dispatch(new ToggleRenamingFilePickerItem(this.filePickerId, this.itemId));
  }

  editWithOffice(item: FilePickerItem): void {
    this.filePickerItemServiceSelectorService.get(item).editWithOffice(item).subscribe({
        next: () => {
        },
        error: () => {
        }
      }
    );
  }

  delete(item: FilePickerItem): void {
    if (item instanceof FileLibraryFilePickerItem) {
      const title = 'FILE_LIBRARY.MODAL.REMOVE.TITLE';
      const body = item.isFolder ? 'FILE_LIBRARY.MODAL.REMOVE.FOLDER.TEXT' : 'FILE_LIBRARY.MODAL.REMOVE.FILE.TEXT';
      const confirmText = 'FILE_LIBRARY.MODAL.REMOVE.CONFIRM';
      const cancelText = 'FILE_LIBRARY.MODAL.REMOVE.CANCEL';
      const translationContext = {
        isFolder: item.isFolder,
        filename: item.name,
        childCount: item.childCount
      };
      this.deleteConfirmationService.open(
        title,
        body,
        confirmText,
        cancelText,
        translationContext,
        item.isFolder
      )
        .subscribe(result => {
          if (result) {
            this.store.dispatch(new DeleteFilePickerItem(this.filePickerId, this.itemId));
          }
        });
    }
  }

  download(item: FilePickerItem): void {
    const service = this.filePickerItemServiceSelectorService.get(item);
    service.download(item);
  }

  uploadNewVersion(item: FilePickerItem): Observable<FileUploadEvent> {
    const fileList = this.files.nativeElement.files;
    if (fileList.length === 1) {
      const upload = this.store.dispatch(new UpdateFile(this.filePickerId, item, fileList[0]));
      upload.pipe(takeUntil(this.destroyed$)).subscribe({
        complete: () => {
          this.files.nativeElement.value = '';
        }
      });
      return upload;
    }
    return EMPTY;
  }

  @HostListener('click')
  preventClickPropagation(event: Event): void {
    event.stopImmediatePropagation();
  }

  private getDeepLink(): Observable<string> {
    return this.item$.pipe(map(itemStateModel => {
      const service = this.filePickerItemServiceSelectorService.get(itemStateModel.item);
      return service.getDeepLink(itemStateModel.item);
    }));
  }
}

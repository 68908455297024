<form class="form-horizontal message-form" role="form" [formGroup]="form" (submit)="onSubmit()">
  <div class="message-form-actions">
    <coyo-emoji-picker [input]="message" class="hidden-xs" data-test="button-emoji-picker"></coyo-emoji-picker>
    <coyo-attachment-btn formControlName="attachments" [context]="currentUser$ | async" [droppedFiles]="filesDropped"
                         data-test="button-chat-attachment"></coyo-attachment-btn>
    <button type="submit" tabindex="0"
            class="coyo-btn coyo-btn-link coyo-btn-icon coyo-btn-sm submit-btn"
            [disabled]="!form.valid"
            data-test="button-chat-message-send">
      <i class="zmdi zmdi-mail-send"></i>
      <span class="sr-only" translate="MODULE.MESSAGING.SEND_MESSAGE_TOOLTIP"></span>
    </button>
  </div>
  <coyo-attachment-list [selectedAttachments]="attachments$ | async" (attachmentRemoved)="removeAttachment($event)"></coyo-attachment-list>
  <textarea class="form-control"
            id="message"
            formControlName="message"
            #message
            autosize rows="1"
            [placeholder]="'MODULE.MESSAGING.MESSAGE.PLACEHOLDER' | translate"
            data-test="textarea-message-form"></textarea>
</form>


import {HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {ServiceRecognitionService} from '@core/http/service-recognition/service-recognition.service';
import {TranslateService} from '@ngx-translate/core';
import {Ng1CoyoConfig} from '@root/typings';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {NG1_COYO_CONFIG, NG1_STATE_SERVICE} from '@upgrade/upgrade.module';
import {IStateService} from 'angular-ui-router';
import * as _ from 'lodash';
import {NgxPermissionsService} from 'ngx-permissions';
import {from, Observable, Observer, of, throwError} from 'rxjs';
import {catchError, first, switchMap} from 'rxjs/operators';

/**
 * Turns an http error response into an error message to be presented to the user.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private translateService: TranslateService,
    private permissionsService: NgxPermissionsService,
    private serviceValidationService: ServiceRecognitionService,
    private notificationService: NotificationService,
    private authService: AuthService,
    @Inject(NG1_STATE_SERVICE) private stateService: IStateService,
    @Inject(NG1_COYO_CONFIG) private coyoConfig: Ng1CoyoConfig) {
  }

  private static readonly STATUS_CODE_NOT_MODIFIED: number = 304;
  private static readonly STATUS_CODE_UNAUTHORIZED: number = 401;
  private static readonly STATUS_CODE_FORBIDDEN: number = 403;
  private static readonly STATUS_CODE_SERVICE_UNAVAILABLE: number = 503;
  private static readonly STATUS_CODE_GATEWAY_TIMEOUT: number = 504;

  errorThrottle: { [key: string]: number } = {};

  private static hashCode(string: string): string {
    let result = 0;

    if (string.length <= 0) {
      return result + '';
    }

    for (let i = 0; i < string.length; i++) {
      result = Math.imul(31, result) + string.charCodeAt(i);
    }
    return result + '';
  }

  handleError(error: any, handleErrors: boolean): Observable<any> {
    if (error instanceof HttpErrorResponse) {
      if (error.status === ErrorService.STATUS_CODE_NOT_MODIFIED) { // not an error
        return of(error);
      } else if (error.status === ErrorService.STATUS_CODE_UNAUTHORIZED) {
        throwError(error);
      } else if ((error.status === ErrorService.STATUS_CODE_SERVICE_UNAVAILABLE || error.status === ErrorService.STATUS_CODE_GATEWAY_TIMEOUT) &&
        this.serviceValidationService.getTargetService(error.url) === null) {
        return this.handleMaintenance(error);
      } else {
        return new Observable((observer: Observer<HttpErrorResponse>) => {
          if (handleErrors) {
            this.getMessage(error).subscribe((translation: string) => {
              if (!this.handleInvalidTenant(error, translation)) {
                const now = new Date().getTime();
                this.cleanupErrorThrottle(now);
                const hash = ErrorService.hashCode(translation);
                if (!this.errorThrottle[hash]) {
                  this.errorThrottle[hash] = now;
                  this.notificationService.error(translation, null, false);
                }
              }
            });
          }
          observer.error(error);
        }).pipe(first());
      }
    }
    return of(error);
  }

  /**
   * Creates an error message. (The result is already translated).
   * Certain HTTP status codes are handled (401, 403, 503) as are coyo specific error codes.
   *
   * @param {HttpErrorResponse} error A response that represents an error or failure
   * @returns {Observable<string>} promise that resolves to a concrete message string
   */
  getMessage(error: HttpErrorResponse): Observable<string> {
    if (error.status === ErrorService.STATUS_CODE_UNAUTHORIZED) {
      return this.translateService.get('ERRORS.UNAUTHORIZED');
    }

    if (error.status === ErrorService.STATUS_CODE_FORBIDDEN) {
      return this.translateService.get('ERRORS.FORBIDDEN');
    }

    if (error.status === ErrorService.STATUS_CODE_SERVICE_UNAVAILABLE || error.status === -1) {
      return this.translateService.get('ERRORS.SERVER_UNAVAILABLE');
    }

    const statusCode = _.get(error, 'error.errorStatus');
    const context = _.get(error, 'error.context', {});

    let key = 'ERRORS.STATUSCODE.UNKNOWN';

    if (statusCode) {

      const errorStatusCodes = ['NOT_FOUND', 'DELETED', 'LOCKED'];
      if (errorStatusCodes.indexOf(statusCode) < 0) {
        key = 'ERRORS.STATUSCODE.' + statusCode;
        return this.terminateTranslation('ERRORS.STATUSCODE.' + statusCode, context)
          // If translation is not found use unknown message key
          .pipe(switchMap(
            translation => translation === key ?
              this.terminateTranslation('ERRORS.STATUSCODE.UNKNOWN') : of(translation)));
      }

      const entityType = this.coyoConfig.entityTypes[_.get(error, 'error.context.entityType')];

      if (entityType && entityType.label) {
        return this.translateService.get(entityType.label).pipe(switchMap(
          (label: string) => {
            const translationContext: object = {};
            Object.assign(translationContext, context, {entityType: label});
            key = 'ERRORS.STATUSCODE.' + statusCode;
            return this.terminateTranslation(key, translationContext);
          }));
      } else {
        key = 'ERRORS.STATUSCODE.' + statusCode + '.DEFAULT';
      }
    }
    return this.terminateTranslation(key);
  }

  private terminateTranslation(key: string | string[], interpolateParams?: object): Observable<string> {
    if (interpolateParams) {
      return this.translateService.get(key, interpolateParams);
    } else {
      return this.translateService.get(key);
    }
  }

  /**
   * Redirect to an error state without changing the url.
   *
   * @param message The (already translated) error message to be displayed.
   * @param status Http status code (will affect the icon displayed)
   * @param buttons Configure the buttons visible on the error page (by default a 'home' link will be displayed).
   * Elements may be objects to configure a button (see details below or a string referencing a standard button config
   * (one of 'RETRY', 'CONFIGURE_BACKEND').
   */
  showErrorPage(message: string, status: number, buttons: string[]): void {
    this.stateService.go('error', {message, status, buttons}, {location: false, inherit: false});
  }

  /**
   * Convert the list of validation field errors from a REST error response into an object tree that can be
   * used with ng-messages.
   *
   * @param {HttpErrorResponse} error The http error response
   *
   * @returns {object} tree where the keys at the top level represent the field names and the second level
   * represents the validation error code, empty object if the response contained no field errors.
   */
  getValidationErrors(error: HttpErrorResponse): object {
    const fieldErrors = _.get(error, 'error.fieldErrors');
    if (fieldErrors) {
      return _.mapValues(_.groupBy(fieldErrors, 'key'), (item: any[]) => {
        const result = {};
        if (item.length > 0) {
          result[item[0].code] = true;
          result['invalidValue'] = item[0].value;
        }
        return result;
      });
    }
    return {};
  }

  /**
   * Special handling when the response indicated the tenant is invalid or inactive.
   * In that case that it is invalid we always redirect to the error page and offer options to configure the url
   * (if configurable). If it is inactive we show the error message with a link to the customer center.
   *
   * @param error A response that represents an error or failure
   * @param translation The (already translated) error message to be displayed.
   * @returns return false if (error_status != (INVALID_TENANT ^ INACTIVE_TENANT)) else true
   */
  private handleInvalidTenant(error: HttpErrorResponse, translation: string): boolean {
    const status: string = _.get(error, 'error.errorStatus');

    if (status !== 'INVALID_TENANT' && status !== 'INACTIVE_TENANT') {
      return false;
    }

    const buttons = ['RETRY'];
    if (status === 'INACTIVE_TENANT') {
      buttons.push('CUSTOMER_CENTER');
    }

    this.showErrorPage(translation, error.status, buttons);
    return true;
  }

  /**
   * Special handling when the response indicated that a maintenance mode is active.
   * In case the maintenance mode is set globally also the admins that can manage maintenance cannot login anymore.
   * If the maintenance mode is not global the tenant's admins can login to edit maintenance mode.
   *
   * @param error A response that represents an error or failure
   * @returns promise that resolves
   */
  private handleMaintenance(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    const status: string = _.get(error, 'error.errorStatus');
    if (status === 'GLOBAL_MAINTENANCE') {
      this.stateService.go('front.maintenance', {global: true}, {
        location: false
      });
      return of(error);
    } else {
      return this.authService.getUser()
        .pipe(switchMap(() => from(this.permissionsService.hasPermission('MANAGE_MAINTENANCE'))))
        .pipe(switchMap(hasPermission => {
            if (!hasPermission) {
              this.stateService.go('front.maintenance', {global: false}, {location: false});
            }
            return of(error);
          }))
        .pipe(catchError(() => {
            if (this.stateService.current.name !== 'front.login') {
              this.stateService.go('front.login');
              return of(error);
            }
            return throwError(error);
          })
        );
    }
  }

  private cleanupErrorThrottle(now: number): void {
    const bufferObject: { [key: string]: number } = {};

    Object.keys(this.errorThrottle).forEach(key => {
      const timeValue: number = this.errorThrottle[key];

      if ((now - 2500) <= timeValue) {
        bufferObject[key] = timeValue;
      }
    });
    this.errorThrottle = bufferObject;
  }
}

import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Ng1CsrfService, Ng1ErrorService} from '@root/typings';
import {NG1_CSRF_SERVICE, NG1_ERROR_SERVICE} from '@upgrade/upgrade.module';
import {NGXLogger} from 'ngx-logger';
import {EMPTY, from, Observable} from 'rxjs';
import {catchError, flatMap} from 'rxjs/operators';
import {BackendInterceptor} from '../backend-interceptor/backend-interceptor';
import {UrlService} from '../url/url.service';

/**
 * Http interceptor that adds a csrf header to all non 'GET' requests to the '/web' path
 */
@Injectable()
export class CsrfInterceptor extends BackendInterceptor {

  constructor(@Inject(NG1_CSRF_SERVICE) private csrfService: Ng1CsrfService,
              @Inject(NG1_ERROR_SERVICE) private errorService: Ng1ErrorService,
              private translateService: TranslateService,
              private log: NGXLogger,
              protected urlService: UrlService) {
    super(urlService);
  }

  /**
   * Adds a Csrf header to all non 'GET' requests to the '/web' path
   *
   * @param req
   * The request
   *
   * @param next
   * The next http handler in the chain
   *
   * @return An Observable of the http event
   */
  interceptBackendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/web') && req.method !== 'GET') {
      return this.addCsrfHeader(req, next);
    } else {
      return next.handle(req);
    }
  }

  private addCsrfHeader(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.csrfService.getToken()).pipe(catchError(error => {
      this.log.error('Could not obtain csrf token:', error);
      const buttons = ['RETRY'];
      this.translateService.get('ERRORS.CSRF_TOKEN.FAILED_OBTAINING')
        .subscribe(translation => {
          this.errorService.showErrorPage(translation, null, buttons);
        });
      return EMPTY;
    })).pipe(flatMap((token: string) => {
      const newRequest = req.clone({
        setHeaders: {
          'X-CSRF-TOKEN': token
        }
      });
      return next.handle(newRequest);
    }));
  }
}

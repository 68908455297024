<form [formGroup]="form">
  <div class="card-body">
    <h2 class="mat-headline">{{'SETTINGS.PASSWORD.HEADLINE' | translate}}</h2>
    <div class="row">
      <div class="col-md-4" [class.mat-error]="form.controls.password.touched && form.controls.password.invalid">
        <label class="mat-form-field-label" (click)="password.focus()">{{'SETTINGS.PASSWORD.CURRENT' | translate}}</label>
      </div>
      <div class="col-md-8">
        <mat-form-field>
          <button mat-icon-button matPrefix tabindex="-1" type="button"
                  [color]="showPassword ? 'primary' : null"
                  (click)="showPassword = !showPassword"
                  [matTooltip]="'SETTINGS.PASSWORD.' + (showPassword ? 'HIDE' : 'SHOW') | translate">
            <mat-icon [svgIcon]="showPassword ? 'eye-solid' : 'eye-outlined'"></mat-icon>
          </button>
          <input matInput formControlName="password" [placeholder]="showPassword ? '' : '••••••••••••'"
                 [attr.type]="showPassword ? 'text' : 'password'"
                 (keydown.enter)="next($event, passwordNew)" #password>
          <coyo-save-indicator matSuffix [request]="request$"></coyo-save-indicator>
          <mat-hint [innerHTML]="'SETTINGS.PASSWORD.HELP' | translate | markdown"></mat-hint>
        </mat-form-field>
      </div>

      <div class="col-md-4" [class.mat-error]="form.controls.passwordNew.touched && form.controls.passwordNew.invalid">
        <label class="mat-form-field-label" (click)="passwordNew.focus()">{{'SETTINGS.PASSWORD.NEW' | translate}}</label>
      </div>
      <div class="col-md-8">
        <mat-form-field>
          <button mat-icon-button matPrefix tabindex="-1" type="button"
                  [color]="showPasswordNew ? 'primary' : null"
                  (click)="showPasswordNew = !showPasswordNew"
                  [matTooltip]="'SETTINGS.PASSWORD.' + (showPasswordNew ? 'HIDE' : 'SHOW') | translate">
            <mat-icon [svgIcon]="showPasswordNew ? 'eye-solid' : 'eye-outlined'"></mat-icon>
          </button>
          <input matInput formControlName="passwordNew"
                 [attr.type]="showPasswordNew ? 'text' : 'password'"
                 (keydown.enter)="next($event, passwordConfirm)" #passwordNew>
          <mat-error *coyoFieldErrors="form.controls.passwordNew.errors"></mat-error>
          <mat-hint [innerHTML]="'SETTINGS.PASSWORD.NEW.HELP' | translate | markdown"></mat-hint>
        </mat-form-field>
      </div>

      <div class="col-md-4" [class.mat-error]="form.controls.passwordConfirm.touched && form.controls.passwordConfirm.invalid">
        <label class="mat-form-field-label" (click)="passwordConfirm.focus()">{{'SETTINGS.PASSWORD.CONFIRM' | translate}}</label>
      </div>
      <div class="col-md-8">
        <mat-form-field>
          <button mat-icon-button matPrefix tabindex="-1" type="button"
                  [color]="showPasswordConfirm ? 'primary' : null"
                  (click)="showPasswordConfirm = !showPasswordConfirm"
                  [matTooltip]="'SETTINGS.PASSWORD.' + (showPasswordConfirm ? 'HIDE' : 'SHOW') | translate">
            <mat-icon [svgIcon]="showPasswordConfirm ? 'eye-solid' : 'eye-outlined'"></mat-icon>
          </button>
          <input matInput formControlName="passwordConfirm"
                 [attr.type]="showPasswordConfirm ? 'text' : 'password'"
                 (keydown.enter)="submit()" #passwordConfirm>
          <mat-error *coyoFieldErrors="form.controls.passwordConfirm.errors; overrides: {allEqual: 'VALIDATION.ERROR.PASSWORDCONFIRM'}"></mat-error>
          <mat-hint [innerHTML]="'SETTINGS.PASSWORD.CONFIRM.HELP' | translate | markdown"></mat-hint>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <div class="form-actions">
          <button mat-button type="reset" (click)="reset()" *ngIf="hasAnyValue">{{'RESET' | translate}}</button>
          <button mat-flat-button type="submit" color="primary" [disabled]="form.invalid || !hasAllValues" (click)="submit()">{{'SETTINGS.PASSWORD.SUBMIT' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</form>

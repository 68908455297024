import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {WIDGET_CONFIGS} from '@widgets/api/widget-config';
import {BIRTHDAY_WIDGET_CONFIG} from '@widgets/birthday/birthday-widget-config';
import {BirthdayWidgetState} from '@widgets/birthday/birthday-widget/birthday-widget.state';
import {AgePipe} from './age/age.pipe';
import {BirthdayWidgetSettingsComponent} from './birthday-widget-settings/birthday-widget-settings.component';
import {BirthdayWidgetComponent} from './birthday-widget/birthday-widget.component';

export const ngxsModule = NgxsModule.forFeature([BirthdayWidgetState]);

@NgModule({
  declarations: [BirthdayWidgetComponent, BirthdayWidgetSettingsComponent, AgePipe],
  entryComponents: [BirthdayWidgetComponent, BirthdayWidgetSettingsComponent],
  providers: [{
    provide: WIDGET_CONFIGS, useValue: BIRTHDAY_WIDGET_CONFIG, multi: true
  }],
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule,
    SenderUIModule,
    ngxsModule
  ]
})
export class BirthdayModule { }

import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';
import * as _ from 'lodash';
import {RtePlugin} from '../rte-plugin';

/**
 * A custom RTE plugin that adds alignment options to the Froala editor.
 */
@Injectable()
export class AlignPluginService extends RtePlugin {

  private static readonly ICONS: { [key: string]: string; } = {
    // tslint:disable:max-line-length
    coyoAlignIcon: `
      <path d="M4 11h3a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zm0 4h3a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zm16-6h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm0 4h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm0 4H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2zM4 7h16a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2z"/>
      <rect x="10" y="9" width="6" height="6" rx="1" ry="1"/>`,
    coyoAlignBlockLeftIcon: `
      <path d="M8 8h12a1 1 0 0 0 0-2H8a1 1 0 0 0 0 2zm0 5h4a1 1 0 0 0 0-2H8a1 1 0 0 0 0 2zm12 3H8a1 1 0 0 0 0 2h12a1 1 0 0 0 0-2zM3 5v14a1 1 0 0 0 2 0V5a1 1 0 0 0-2 0z"/>`,
    coyoAlignBlockCenterIcon: `
      <path d="M6 8h12a1 1 0 0 0 0-2H6a1 1 0 0 0 0 2zm3 5h6a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2zm9 3H6a1 1 0 0 0 0 2h12a1 1 0 0 0 0-2z"/>
      <path d="M11 5v14a1 1 0 0 0 2 0V5a1 1 0 0 0-2 0z"/>`,
    coyoAlignBlockRightIcon: `
      <path d="M4 8h12a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zm8 5h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zm4 3H4a1 1 0 0 0 0 2h12a1 1 0 0 0 0-2zm3-11v14a1 1 0 0 0 2 0V5a1 1 0 0 0-2 0z"/>`,
    coyoAlignInlineLeftIcon: `
      <path d="M20 9h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2zm0 4h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2zm0 4H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2zM16 7h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2z"/>
      <rect width="8" height="8" x="4" y="6" rx="1" ry="1"/>`,
    coyoAlignInlineRightIcon: `
      <path d="M8 9H4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2zm0 4H4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2zm12 4H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2zM4 7h4a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2z"/>
      <rect width="8" height="8" x="12" y="6" rx="1" ry="1"/>`
    // tslint:enable:max-line-length
  };

  constructor(@Inject(FROALA_EDITOR) private froala: any,
              private translateService: TranslateService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    this.registerIcons();
    this.registerCommand('coyoImageAlign', (froala: any) => froala.image.getEl(), (froala: any) => froala.image.display());
    this.registerCommand('coyoVideoAlign', (froala: any) => froala.video.get(), (froala: any) => froala.video.display());
    this.registerCommand('coyoTableAlign', (froala: any) => froala.table.selectedTable());
  }

  private registerIcons(): void {
    _.forEach(AlignPluginService.ICONS, (svg, name) => {
      const path = svg.replace(/^\s*<path d="/g, '').replace(/"\/>$/g, '');
      this.froala.DefineIcon(name, {PATH: path});
    });
  }

  private registerCommand(name: string, select: any, afterCallback: (froala: any) => void = froala => {}, options: object = {
                            'fr-cdbl': 'coyoAlignBlockLeftIcon',
                            'fr-cdbc': 'coyoAlignBlockCenterIcon',
                            'fr-cdbr': 'coyoAlignBlockRightIcon',
                            'fr-cdil': 'coyoAlignInlineLeftIcon',
                            'fr-cdir': 'coyoAlignInlineRightIcon'
                          }, i18nPrefix: string = 'RTE.DISPLAY.'
  ): void {
    const plugin = this;
    plugin.froala.RegisterCommand(name, {
      title: plugin.translateService.instant(i18nPrefix + 'ALIGN'),
      icon: 'coyoAlignIcon',
      plugin: 'coyoAlignPlugin',
      type: 'dropdown',
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      html: function(): string {
        const items = _.reduce(options, (acc: string, icn: string, cls: any) => {
          const titleKey = _.chain(icn).replace('coyo', '').replace('Icon', '').snakeCase().value().toUpperCase();
          const title = plugin.translateService.instant(i18nPrefix + titleKey);
          const icon = this.icon.create(icn);
          return acc +
            '<li role="presentation">' +
            `<a class="fr-command fr-title" tabIndex="-1" role="option" data-cmd="${name}" data-param1="${cls}" title="${title}">` +
            `${icon}<span class="fr-sr-only">${title}</span>` +
            '</a>' +
            '</li>';
        }, '');
        return `<ul class="fr-dropdown-list" role="presentation">${items}</ul>`;
      },
      callback: function(cmd: any, val: any): void {
        const $selection = select(this);
        _.reduce(options, (elem, option, optionClass) => elem.removeClass(optionClass), $selection)
          .css('margin-left', '')
          .css('margin-right', '')
          .addClass(val);
        afterCallback(this);
      },
      refresh: function($btn: any): void {
        const icn = _.find(options, (val, key) => select(this).hasClass(key)) || 'coyoAlignIcon';
        $btn.children().first().replaceWith(this.icon.create(icn));
      },
      refreshOnShow: function($btn: any, $dropdown: any): void {
        const $selection = select(this);
        $dropdown.find('.fr-command').each(function(): void {
          const active = $selection.hasClass(this.dataset.param1);
          this.classList[active ? 'add' : 'remove']('fr-active');
          this.setAttribute('aria-selected', active as string);
        });
      }
    });
  }
}

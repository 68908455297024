import {SenderFilesComponent} from '@app/file-library/sender-files/sender-files.component';
import {PageService} from '@domain/page/page.service';
import {NgHybridStateDeclaration} from '@uirouter/angular-hybrid';
import {Transition} from '@uirouter/core';

/**
 * State for rendering the files of the given page. E.g. used in the manage file state.
 */
export const pagesFileState: NgHybridStateDeclaration = {
  name: 'main.page.show.files',
  url: '/files',
  component: SenderFilesComponent,
  resolve: [
    {
      token: 'sender',
      policy: {when: 'EAGER'},
      deps: [PageService, Transition],
      resolveFn: (pageService: PageService, $transition$: Transition) => pageService.get(
        $transition$.params().idOrSlug,
        {
          permissions: ['manage', 'createFile']
        }).toPromise()
    },
  ]
};

<div [formGroup]="parentForm">
  <div class="form-group">
    <label for="selectedSender" class="col-sm-2 control-label" translate="WIDGETS.FACEGAME.SETTINGS.SENDER.LABEL"></label>
    <div class="col-sm-9">
      <coyo-select-sender id="selectedSender"
                          formControlName="_senderId"
                          [options]="selectSenderOptions"
                          placeholder="WIDGETS.FACEGAME.SETTINGS.SENDER.PLACEHOLDER"
                          data-test="facegame-sender-select"></coyo-select-sender>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help [tip]="'WIDGETS.FACEGAME.SETTINGS.SENDER.HELP'"></coyo-help>
    </div>
  </div>

  <div class="form-group" [class.has-error]="isInvalid('_time')">
    <label for="_time" class="col-sm-2 control-label" translate="WIDGETS.FACEGAME.SETTINGS.TIME.LABEL"></label>
    <div class="col-sm-9">
      <input type="number" id="_time" name="_time" class="form-control" formControlName="_time" />
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help [tip]="'WIDGETS.FACEGAME.SETTINGS.TIME.HELP'"></coyo-help>
    </div>
  </div>

  <div class="form-group" [class.has-error]="isInvalid('_includeExternal')">
    <label for="_includeExternal" class="col-sm-2 control-label" translate="WIDGETS.FACEGAME.SETTINGS.INCLUDE_EXTERNAL.LABEL"></label>
    <div class="col-sm-9">
      <coyo-checkbox id="_includeExternal" name="_includeExternal" formControlName="_includeExternal"></coyo-checkbox>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help [tip]="'WIDGETS.FACEGAME.SETTINGS.INCLUDE_EXTERNAL.HELP'"></coyo-help>
    </div>
  </div>
</div>

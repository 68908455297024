import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {EntityId} from '@domain/entity-id/entity-id';
import {WidgetRenderStyle} from '@widgets/api/widget-render-style';

/**
 * Component renders a widget layout for engage webview
 */
@Component({
  selector: 'coyo-engage-widget-layout',
  templateUrl: './engage-widget-layout.component.html',
  styleUrls: ['./engage-widget-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngageWidgetLayoutComponent {

  /**
   * The entity id of the parent of this widget layout
   */
  @Input() parent: EntityId;

  /**
   * The name of the widget layout. The name of the layout is calculated.
   *
   * ```
   * // for wiki articles
   * 'app-wiki-' + appId + '-' + articleId + '-' + revision-number;
   *
   * // for blog articles
   * 'app-blog-' + appId + '-' + articleId;
   *
   * // for landing pages
   * 'landing-page-' + landingPageId
   * ```
   */
  @Input() name: string;

  /**
   * The language that should be shown. If the default language of the sender should be shown or multi language is not
   * activated on the sender this has to be left empty.
   */
  @Input() language: string;

  /**
   * The render style of the widget layout.
   * On 'plain' a card will be rendered and the widget layout will be completly within this card.
   * On 'panel' every slot will have it's own card and every widget inside will be displayed in that card.
   * On 'panels' every widget will be rendered in it's own card.
   */
  @Input() renderStyle: WidgetRenderStyle = 'plain';

  constructor() {
  }
}

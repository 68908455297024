<ng-container *ngIf="state$ | async; let state">
  <section role="region" class="timeline-stream coyo-new" data-test="timeline-stream" *ngIf="visible$ | async"
           [coyoTourIf]="isPersonal"
           coyoTourStep="stream"
           coyoTourTopic="timeline"
           [coyoTourOrder]="210"
           [coyoTourTitle]="'TOUR.TIMELINE.STREAM.TITLE' | translate"
           [coyoTourText]="'TOUR.TIMELINE.STREAM.TEXT' | translate"
           coyoTourPlacement="left-start">
    <coyo-timeline-form *ngIf="canPost$ | async"
                        [type]="type"
                        [context]="context"></coyo-timeline-form>
    <button mat-flat-button type="button" size="small" color="primary" class="btn-load-new"
            (click)="loadNew()" *ngIf="!state.loadingNew && state.newItemsCount"
            data-test="show-new-timeline-items">{{'MORE.NEW' | translate:state}}</button>
    <ui-skeleton *ngIf="state.loadingNew" [skeletons]="skeletons"></ui-skeleton>
    <div *ngFor="let item of state.items;trackBy: trackItem">
      <div class="most-recent-items-divider" *ngIf="item.id === state.mostRecentItemId">
        <span class="line"></span>
        <span class="text">
          <span translate="MODULE.TIMELINE.MOST_RECENT_DIVIDER"></span>
        </span>
        <span class="line"></span>
      </div>
      <coyo-timeline-item data-test="timeline-item"
                          [timelineItem]="item"
                          [contextId]="context?.id"
                          [type]="type"></coyo-timeline-item>
    </div>
    <div *ngIf="state.loading">
      <ui-skeleton *ngFor="let item of repeatSkeletons(8)" [skeletons]="skeletons"></ui-skeleton>
    </div>
    <button mat-flat-button type="button" size="small" color="primary" class="btn-load-more"
            [disabled]="state.loading"
            (click)="loadMore()" *ngIf="!state.page?.last"
            data-test="load-more-timeline-items">{{'MORE' | translate}}</button>
  </section>
</ng-container>

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {UpcomingEventsWidget} from './upcoming-events-widget';
import {UpcomingEventsWidgetSettingsComponent} from './upcoming-events-widget-settings/upcoming-events-widget-settings.component';
import {UpcomingEventsWidgetComponent} from './upcoming-events-widget/upcoming-events-widget.component';

export const UPCOMING_EVENTS_WIDGET: WidgetConfig<UpcomingEventsWidget> = {
  key: 'upcoming-events',
  name: 'WIDGETS.UPCOMING.EVENTS.NAME',
  description: 'WIDGETS.UPCOMING.EVENTS.DESCRIPTION',
  defaultTitle: 'WIDGETS.UPCOMING.EVENTS.NAME',
  icon: 'zmdi-calendar',
  component: UpcomingEventsWidgetComponent,
  categories: WidgetCategory.DYNAMIC,
  settings: {
    skipOnCreate: false,
    component: UpcomingEventsWidgetSettingsComponent
  },
  renderOptions: {
    printable: false
  },
  whitelistExternal: false
};

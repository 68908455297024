<div class="coyo-btn coyo-btn-link btn-bookmark">
  <form [formGroup]="bookmarkForm" class="flex">
    <div class="icon-list" aria-hidden="true">
      <div class="bookmark-icon">
        <i class="zmdi zmdi-bookmark" [ngClass]="{'has-error': bookmarkForm.touched && bookmarkForm.invalid}"></i>
      </div>
    </div>

    <div class="bookmark">
      <div class="bookmark-url" *ngIf="step === 'URL'" [class.has-error]="url.touched && url.invalid">
        <input *ngIf="linkPattern" formControlName="url"
               class="inplace-edit"
               placeholder="https://"
               [attr.aria-label]="'WIDGETS.BOOKMARKING.URL.ARIA' | translate"
               type="url"
               required aria-required="true"
               autocomplete="off"
               [attr.aria-invalid]="bookmarkForm.invalid"
               (blur)="bookmarkForm.valid && bookmarkForm.dirty && saveBookmark()">
        <div class="sr-only" *ngIf="url.invalid">
          <coyo-validation-errors [errors]="url.errors"></coyo-validation-errors>
        </div>
      </div>

      <div class="bookmark-title" *ngIf="step === 'TITLE'">
        <input formControlName="title"
               class="inplace-edit"
               [attr.placeholder]="'WIDGETS.BOOKMARKING.TITLE.PLACEHOLDER' | translate"
               [attr.aria-label]="'WIDGETS.BOOKMARKING.TITLE.ARIA' | translate"
               type="text"
               maxlength="50"
               autocomplete="off"
               (blur)="bookmarkForm.valid && bookmarkForm.dirty && saveBookmark()">
      </div>
    </div>

    <div class="flex-for-edit icon-list">
      <div class="toggle-icon" (click)="bookmarkForm.valid && toggleStep()"
           [matTooltip]="'WIDGETS.BOOKMARKING.EDIT.TOGGLE_FROM.' + step | translate">
        <i class="zmdi" [ngClass]="{'zmdi-link': step === 'TITLE', 'zmdi-format-size': step === 'URL'}" aria-hidden="true"></i>
      </div>
      <div class="delete-icon" (click)="removeBookmark()"
           [matTooltip]="'WIDGETS.BOOKMARKING.EDIT.REMOVE' | translate">
        <i class="zmdi zmdi-delete" aria-hidden="true"></i>
      </div>
      <div class="drag-icon" cdkDragHandle
           [matTooltip]="'WIDGETS.BOOKMARKING.EDIT.DRAG_REORDER' | translate">
        <i class="zmdi zmdi-swap-vertical" aria-hidden="true"></i>
      </div>
    </div>
  </form>
</div>

<div [formGroup]="parentForm" class="single-file-widget-setting">
  <div class="form-group" [class.has-error]="isInvalid('_file')">
    <label class="col-sm-3 control-label file-input-label" translate="WIDGET.SINGLEFILE.SETTINGS.FILE.LABEL"></label>
    <coyo-select-file class="col-sm-8" *ngIf="sender$ | async; let sender"
                      id="select-file"
                      name="_file"
                      formControlName="_file"
                      [sender]="sender"
                      [options]="{selectMode: 'single'}"
                      [attr.aria-invalid]="isInvalid('_file')">
    </coyo-select-file>
    <div class="col-sm-1 hidden-xs file-input-help">
      <coyo-help tip="WIDGET.SINGLEFILE.SETTINGS.FILE.HELP"></coyo-help>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-3 control-label" translate="WIDGET.SINGLEFILE.SETTINGS.HIDE_PREVIEW.LABEL"></label>
    <div class="col-sm-8">
      <coyo-checkbox id="hide-preview-checkbox" name="_hidePreview" formControlName="_hidePreview">
      </coyo-checkbox>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGET.SINGLEFILE.SETTINGS.HIDE_PREVIEW.HELP"></coyo-help>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-3 control-label" translate="WIDGET.SINGLEFILE.SETTINGS.HIDE_DATE.LABEL"></label>
    <div class="col-sm-8">
      <coyo-checkbox id="hide-date-checkbox" name="_hideDate" formControlName="_hideDate">
      </coyo-checkbox>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGET.SINGLEFILE.SETTINGS.HIDE_DATE.HELP"></coyo-help>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-3 control-label" translate="WIDGET.SINGLEFILE.SETTINGS.HIDE_SENDER.LABEL"></label>
    <div class="col-sm-8">
      <coyo-checkbox id="hide-sender-checkbox" name="_hideSender" formControlName="_hideSender">
      </coyo-checkbox>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGET.SINGLEFILE.SETTINGS.HIDE_SENDER.HELP"></coyo-help>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-3 control-label" translate="WIDGET.SINGLEFILE.SETTINGS.HIDE_DOWNLOAD_LINK.LABEL"></label>
    <div class="col-sm-8">
      <coyo-checkbox id="hide-download-checkbox" name="_hideDownload" formControlName="_hideDownload">
      </coyo-checkbox>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGET.SINGLEFILE.SETTINGS.HIDE_DOWNLOAD_LINK.HELP"></coyo-help>
    </div>
  </div>
</div>

(function (angular) {
  'use strict';

  AdminNotificationSettingsController.$inject = ["$scope", "$injector", "SettingsModel", "settings"];
  angular.module('coyo.admin.settings')
      .controller('AdminNotificationSettingsController', AdminNotificationSettingsController);

  function AdminNotificationSettingsController($scope, $injector, SettingsModel, settings) {
    var vm = this;
    vm.settingsCopy = {};
    vm.settings = settings;
    vm.save = save;

    vm.channelMetadata = {
      BROWSER: {
        key: 'browser',
        id: 'BROWSER',
        model: 'browserNotificationsActive'
      },
      EMAIL: {
        key: 'email',
        id: 'EMAIL',
        model: 'emailNotificationsActive'
      },
      PUSH: {
        key: 'push',
        id: 'PUSH',
        model: 'pushNotificationsActive'
      },
      PUSH_ACTUAL_CONTENT: {
        key: 'pushActualContent',
        id: 'PUSH_ACTUAL_CONTENT',
        model: 'pushNotificationsActualContentEnabled'
      }
    };

    function save() {
      _normalize();

      return vm.settingsCopy.update().then(function () {
        SettingsModel.retrieve(true); // reset settings cache
        $injector.get('ngxNotificationService').success('ADMIN.SETTINGS.SAVE.SUCCESS');
        _normalize();
      });

    }

    function _normalize() {
      vm.settingsCopy = _.cloneDeep(settings);

      _(vm.settingsCopy).keys().difference(_.map(vm.channelMetadata, 'model')).forEach(function (key) {
        delete vm.settingsCopy[key];
      });

      // convert boolean values
      _(vm.channelMetadata).forEach(function (data) {
        vm.settings[data.model] = angular.fromJson(_.get(vm.settings, data.model, false));
      });
    }

    function _watch() {
      $scope.$watch(function () {
        return vm.settings[vm.channelMetadata.PUSH.model];
      }, function (newVal) {
        var metadata = vm.channelMetadata.PUSH_ACTUAL_CONTENT;
        vm.settings[metadata.model] = vm.settings[metadata.model] && newVal;
        metadata.disabled = !newVal;
      });
    }

    (function _init() {
      _normalize();
      _watch();
    })();
  }

})(angular);

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UrlService} from '../url/url.service';

/**
 * Http interceptor that executes only if the request target is the COYO backend.
 * Use this, when you modify the request with data that is intended for the COYO backend only (e.g. headers).
 */
export abstract class BackendInterceptor implements HttpInterceptor {
  private static BACKEND_PREFIX_WITHOUT_HOSTNAME: string = '/web';

  constructor(protected urlService: UrlService) {
  }

  /**
   * Executes the interceptBackendRequest() method, if the request targets to the backend.
   * Otherwise the request will not modified.
   *
   * @param req the request
   * @param next the next http handler in the chain
   * @return an Observable of the http event
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBackendRequest(req)) {
      return this.interceptBackendRequest(req, next);
    } else {
      return next.handle(req);
    }
  }

  private isBackendRequest(req: HttpRequest<any>): boolean {
    return req.url.startsWith(BackendInterceptor.BACKEND_PREFIX_WITHOUT_HOSTNAME)
      || (this.urlService.isBackendUrlSet() && req.url.startsWith(this.urlService.getBackendUrl()));
  }

  /**
   * Works the same as the intercept() method of the HttpInterceptor interface.
   * This method will only be executed, if the request targets to the backend.
   *
   * @param req the request
   * @param next the next http handler in the chain
   * @return an Observable of the http event
   */
  abstract interceptBackendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;
}

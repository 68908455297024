import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {PendingRequestsInterceptor} from '@core/http/pending-requests-interceptor/pending-requests.interceptor';
import {ServiceInterceptor} from '@core/http/service-interceptor/service-interceptor.service';
import {AuthInterceptor} from './auth-interceptor/auth-interceptor';
import {BackendUrlInterceptor} from './backend-url-interceptor/backend-url-interceptor';
import {CsrfInterceptor} from './csrf-interceptor/csrf-interceptor';
import {ErrorInterceptor} from './error-interceptor/error-interceptor';
import './etag-cache/etag-cache.service.downgrade';
import {EtagInterceptor} from './etag-interceptor/etag-interceptor';
import {SecurityInterceptor} from './security-interceptor/security-interceptor';
import './url/url.service.downgrade';

/**
 * Module provides services and interceptors for http communications
 */
@NgModule({
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: PendingRequestsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ServiceInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: BackendUrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: EtagInterceptor, multi: true},
  ]
})
export class HttpModule {
}

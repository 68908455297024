import {NgIf} from '@angular/common';
import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {FieldErrorsMessageComponent} from '@shared/forms/field-errors-message/field-errors-message.component';
import * as _ from 'lodash';

/**
 * A structural directive to display the map of errors returned from failed
 * validation checks within a `<mat-error>` component. Due to the internals
 * of Angular Material it is not possible to build this via a component.
 *
 * The directive will only display errors if they are available. It uses
 * i18n keys of the form 'VALIDATION.ERROR.{ERROR_KEY}'. The error value
 * is provided as an i18n context.
 *
 * ```
 * <mat-error *coyoFieldErrors="form.controls.field.errors"></mat-error>
 * ```
 *
 * A map of custom overrides can be provided, mapping error keys to i18n
 * keys. The error value will again be provided as a translation context.
 *
 * ```
 * <mat-error *coyoFieldErrors="form.controls.field.errors; overrides: {required: 'CUSTOM.REQUIRED.KEY'}"></mat-error>
 * ```
 */
@Directive({
  selector: '[coyoFieldErrors]'
})
export class FieldErrorsDirective implements OnChanges {
  private readonly ngIf: NgIf;
  private readonly messageFactory: ComponentFactory<FieldErrorsMessageComponent>;
  private message: ComponentRef<FieldErrorsMessageComponent>;

  /**
   * The map of errors returned from failed validation checks or `null`.
   */
  @Input() coyoFieldErrors: ValidationErrors | null;

  /**
   * A map of custom overrides of i18n keys.
   */
  @Input() coyoFieldErrorsOverrides: { [errorKey: string]: string; } = {};

  constructor(private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver) {
    this.ngIf = new NgIf(this.viewContainerRef, this.templateRef);
    this.messageFactory = this.componentFactoryResolver.resolveComponentFactory(FieldErrorsMessageComponent);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngIf.ngIf = this.coyoFieldErrors !== null;
    this.message?.destroy();
    if (this.coyoFieldErrors !== null) {
      const [[key, value]] = _.toPairs(this.coyoFieldErrors);
      this.message = this.viewContainerRef.createComponent(this.messageFactory);
      this.message.instance.key = this.coyoFieldErrorsOverrides[key] || `VALIDATION.ERROR.${key.toUpperCase()}`;
      this.message.instance.value = value;
    }
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {LanguagePickerLanguage} from '@shared/multilanguage/language-picker/language';

/**
 * Renders a language select which is shows validation errors if one of the languages is invalid
 * (for example in a form).
 */
@Component({
  selector: 'coyo-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent {

  /**
   * The default language of the sender (default value of the select on creation).
   */
  @Input() selectedLanguage: string;

  /**
   * The available languages and validation state
   */
  @Input() languages: LanguagePickerLanguage[];

  /**
   * The icon to show the success verification state for items
   */
  @Input() validationIconSuccess: string = 'check';

  /**
   * The icon to show the failed verification state for items
   */
  @Input() validationIconFailed: string = 'close';

  /**
   * If set to true, a failed validation will mark the entire component as invalid
   */
  @Input() validateAll: boolean = true;

  /**
   * Show a hint-text for the select?
   */
  @Input() showLabel: boolean = true;

  /**
   * Show flag icons ?
   */
  @Input() showFlags: boolean = true;

  /**
   * Event when an other language has been selected, resulting in the iso-short-name of the new language.
   */
  @Output() languageSwitched: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  setLanguage(selectedLanguage: string): void {
    if (selectedLanguage !== this.selectedLanguage) {
      this.selectedLanguage = selectedLanguage;
      this.languageSwitched.emit(selectedLanguage);
    }
  }

  allValid(): boolean {
    return this.languages.every(language => language.valid);
  }
}

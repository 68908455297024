import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Plugin} from '@domain/plugin/plugin';
import {Observable} from 'rxjs';

/**
 * Service to manage plug-ins.
 */
@Injectable({
  providedIn: 'root'
})
export class PluginAdminService {
  static readonly baseUrl: string = '/web/plugins/manage';

  constructor(private http: HttpClient) {
  }

  /**
   * Add a plug-in by its manifest URL.
   *
   * @param manifestUrl The URL of the plug-in's manifest
   * @returns The created Plug-in
   */
  add(manifestUrl: string): Observable<Plugin> {
    const params = {type: 'byUrl'};
    return this.http.post<Plugin>(PluginAdminService.baseUrl, {manifestUrl}, {params});
  }

  /**
   * Enable a plug-in.
   *
   * @param id The plug-in id
   * @param enabled Set to 'true' if the plug-in should be enabled or 'false' for disabled
   * @returns The updated plugin
   */
  setEnabled(id: string, enabled: boolean): Observable<Plugin> {
    return this.http.patch<Plugin>(`${PluginAdminService.baseUrl}/${id}`, {enabled});
  }

  /**
   * Restrict a plug-in to moderators.
   *
   * @param id The plug-in id
   * @param restricted Set to 'true' if the plug-in should be restricted or 'false' if not
   * @returns The updated plugin
   */
  setRestricted(id: string, restricted: boolean): Observable<Plugin> {
    return this.http.patch<Plugin>(`${PluginAdminService.baseUrl}/${id}`, {restricted});
  }

  /**
   * Updates the plug-in's global configuration values.
   *
   * @param id  The plug-in id
   * @param config The new global configuration values
   * @returns The updated plugin
   */
  setConfig(id: string, config: { [key: string]: any }): Observable<Plugin> {
    return this.http.patch<Plugin>(`${PluginAdminService.baseUrl}/${id}`, {config});
  }

  /**
   * Remove a plug-in
   *
   * @param id The plug-in id
   * @returns An Observable that indicates if the plug-in was removed
   */
  remove(id: string): Observable<void> {
    return this.http.delete<void>(`${PluginAdminService.baseUrl}/${id}`);
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import {DomainService} from '@domain/domain/domain.service';
import {Report} from '@domain/reports/report';
import {Observable} from 'rxjs';

/**
 * Service to manage reports.
 */
@Injectable({
  providedIn: 'root'
})
export class ReportsService extends DomainService<Report, Report> {

  constructor(http: HttpClient, urlService: UrlService) {
    super(http, urlService);
  }

  protected getBaseUrl(): string {
    return '/web/reports';
  }

  /**
   * Resolves a report.
   * @param report The report to resolve
   * @returns an Observable that completes when the request is done
   */
  resolve(report: Report): Observable<void> {
    return this.delete(report.id);
  }
}

import {FileUploadEvent} from './file-upload-event';

/**
 * Event that is emitted when a FileUpload starts uploading a file
 */
export class FileUploadNextFileEvent extends FileUploadEvent {
  constructor(files: FileList | File[], readonly fileId: string, readonly fileIndex: number) {
    super(files);
  }
}

import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {LOCAL_FILE_LIBRARY, StorageType} from '@domain/attachment/storage';

/**
 * File library file picker item
 */
export class FilePickerUploadItem implements FilePickerItem {
  readonly isFolder: boolean;
  readonly name: string;
  readonly sizeInBytes: number;
  readonly storageType: StorageType = LOCAL_FILE_LIBRARY;

  constructor(readonly id: string, file: File) {
    this.isFolder = false;
    this.name = file.name;
    this.sizeInBytes = file.size;
    this.storageType = LOCAL_FILE_LIBRARY;
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UrlService} from '@core/http/url/url.service';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {CapabilityModel} from './../capability-model';

/**
 * Capabilities service providing information on attachments previews
 */
@Injectable({
  providedIn: 'root'
})
export class CapabilitiesService {

  previewTypes$: Observable<CapabilityModel>;

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(UrlService) protected urlService: UrlService) {}

  /**
   * Determines if the preview of the content type could be displayed as png or jpeg
   * Example: text/plain could be displayed as application/pdf or as image/jpeg
   *
   * @param contentType The actual content type
   *
   * @returns an 'Observable' that emits the format which should be used for preview. If no format is available, an empty string will be emitted.
   */
  previewImageFormat(contentType: string): Observable<string> {
    if (!contentType) {
      return of('');
    }
    return this.getPreviewTypes().pipe(map(contentTypes => {
      const arrayOfTypes = _.find(contentTypes, contentType);
      if (arrayOfTypes) {
        const format = _.find(arrayOfTypes[contentType], {details: 'image/png'}) ||
        _.find(arrayOfTypes[contentType], {details: 'image/jpeg'});
        return format.details as any as string;
      }
      return '';
    }));
  }

  /**
   * Determines if an image preview is possible
   *
   * @param contentType Attached file contentType
   *
   * @returns an 'Observable' with the information if the preview image is possible or not
   */
  imgAvailable(contentType: string): Observable<boolean> {
    return this.getPreviewTypes().pipe(map(contentTypes => {
      const arrayOfTypes = _.find(contentTypes, contentType);
      return arrayOfTypes &&
              (_.find(arrayOfTypes[contentType], {details: 'image/jpeg'}) || _.find(arrayOfTypes[contentType], {details: 'image/png'})) !== undefined;
    }));
  }

  /**
   * Determines if a pdf preview is possible
   *
   * @param contentType Attached file contentType
   *
   * @returns an 'Observable' with the information if the pdf preview is possible or not
   */
  pdfAvailable(contentType: string): Observable<boolean> {
    return this.getPreviewTypes().pipe(map(contentTypes => {
      const arrayOfTypes = _.find(contentTypes, contentType);
      return arrayOfTypes && (_.find(arrayOfTypes[contentType], {details: 'application/pdf'}) !== undefined) || contentType === 'application/pdf';
    }));
  }

  private getPreviewTypes(): Observable<CapabilityModel> {
    if (this.previewTypes$) {
      return this.previewTypes$;
    }
    this.previewTypes$ = this.http.get<CapabilityModel>('/web/capabilities/preview').pipe(shareReplay({bufferSize: 1, refCount: false}));
    return this.previewTypes$;
  }
}

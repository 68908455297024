import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Language} from './language';

/**
 * Service to handle languages.
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsLanguageService {

  constructor(private http: HttpClient) { }

  /**
   * Returns a list of languages.
   *
   * @return an Observable of the enabled languages
   */
  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>('/web/languages');
  }
}

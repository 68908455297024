import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccountModule} from '@app/account/account.module';
import {AdminModule} from '@app/admin/admin.module';
import {AnalyticsModule} from '@app/analytics/analytics.module';
import {EngageModule} from '@app/engage/engage.module';
import {EventsModule} from '@app/events/events.module';
import {FileLibraryModule} from '@app/file-library/file-library.module';
import {FilterModule} from '@app/filter/filter.module';
import {O365Module} from '@app/integration/o365/o365.module';
import {LoginModule} from '@app/login/login.module';
import {MessagingModule} from '@app/messaging/messaging.module';
import {TeamsRedirectModule} from '@app/msteams/teams-redirect.module';
import {PagesModule} from '@app/pages/pages.module';
import {ReportsModule} from '@app/reports/reports.module';
import {SearchShellModule} from '@app/search-shell/search-shell.module';
import {SearchModule} from '@app/search/search.module';
import {SocketModule} from '@app/socket/socket.module';
import {TimelineModule} from '@app/timeline/timeline.module';
import {WorkspacesModule} from '@app/workspaces/workspaces.module';
import {AppsModule} from '@apps/apps.module';
import {TimelineAppModule} from '@apps/timeline/timeline-app.module';
import {CoreModule} from '@core/core.module';
import {TranslationCompilerService} from '@core/i18n/translation-compiler/translation-compiler.service';
import {TranslationLoaderService} from '@core/i18n/translation-loader/translation-loader.service';
import {FormlyModule} from '@ngx-formly/core';
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsModule} from '@ngxs/store';
import {environment} from '@root/environments/environment';
import {AboutCoyoModalModule} from '@shared/about-coyo/about-coyo-modal.module';
import {AnchorModule} from '@shared/anchor/anchor.module';
import {AutofocusModule} from '@shared/autofocus/autofocus.module';
import {CounterModule} from '@shared/counter/counter.module';
import {DateRangePickerModule} from '@shared/date-range-picker/date-range-picker.module';
import {DividerModule} from '@shared/divider/divider.module';
import {DownloadModule} from '@shared/download/download.module';
import {EmptyListNoticeModule} from '@shared/empty-list-notice/empty-list-notice.module';
import {EventMetaDataModule} from '@shared/event-meta-data/event-meta-data.module';
import {FileModule} from '@shared/files/file.module';
import {FloatingFooterModule} from '@shared/floating-modules/floating-footer/floating-footer.module';
import {FloatingHeaderModule} from '@shared/floating-modules/floating-header/floating-header.module';
import {ImageCropModule} from '@shared/image-crop/image-crop.module';
import {LicenseModule} from '@shared/license/license.module';
import {NotificationsModule} from '@shared/notifications/notifications.module';
import {SenderUIModule} from '@shared/sender-ui/sender-ui.module';
import {SocialModule} from '@shared/social/social.module';
import {SpinnerModule} from '@shared/spinner/spinner.module';
import {TextModule} from '@shared/text/text.module';
import {TimeModule} from '@shared/time/time.module';
import {TrustModule} from '@shared/trust/trust.module';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {WidgetsModule} from '@widgets/widgets.module';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import {CollapseModule} from 'ngx-bootstrap';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {MomentModule} from 'ngx-moment';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ToastrModule} from 'ngx-toastr';
import {BootstrapComponent} from './app.bootstrap.component';
import './app.bootstrap.component.downgrade';
import {GsuiteModule} from './integration/gsuite/gsuite.module';
import {IntegrationModule} from './integration/integration.module';
import {LaunchpadModule} from './launchpad/launchpad.module';
import {TourModule} from './tour/tour.module';

export const uiRouterModule = UIRouterUpgradeModule.forRoot();
export const buttonsModule = ButtonsModule.forRoot();
export const modalModule = ModalModule.forRoot();
export const tabsModule = TabsModule.forRoot();
export const froalaEditor = FroalaEditorModule.forRoot();
export const froalaView = FroalaViewModule.forRoot();
export const loggerModule = LoggerModule.forRoot({level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.DEBUG});
export const permissionsModule = NgxPermissionsModule.forRoot();
export const translateModule = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useClass: TranslationLoaderService
  },
  compiler: {
    provide: TranslateCompiler,
    useClass: TranslationCompilerService
  }
});
export const bsDropdownModule = BsDropdownModule.forRoot();
export const bsTimePickerModule = TimepickerModule.forRoot();
export const popoverModule = PopoverModule.forRoot();
export const collapseModule = CollapseModule.forRoot();
export const ngxsModule = NgxsModule.forRoot([], {developmentMode: !environment.production});
export const ngxsDevtools = NgxsReduxDevtoolsPluginModule.forRoot();
export const toastrModule = ToastrModule.forRoot({
  timeOut: 5000,
  positionClass: 'toast-bottom-left'
});

/**
 * Provides the current language.
 *
 * @param translateService
 * The translate service
 * @param appInit
 * The app initializer function array.
 * We need to use this as a dependency to wait for the language
 * of the user to be resolved before this factory is called.
 *
 * @returns The current locale
 */
export function localeProvider(translateService: TranslateService, appInit: Function[]): string {
  return translateService.currentLang;
}

/**
 * The main application module.
 */
@NgModule({
  imports: [
    AboutCoyoModalModule,
    AccountModule,
    AdminModule,
    AnchorModule,
    AppsModule,
    AutofocusModule,
    BrowserModule,
    bsDropdownModule,
    bsTimePickerModule,
    buttonsModule,
    CoreModule,
    CounterModule,
    DateRangePickerModule,
    DividerModule,
    DownloadModule,
    EmptyListNoticeModule,
    EngageModule,
    EventMetaDataModule,
    EventsModule,
    FileLibraryModule,
    FileModule,
    FilterModule,
    froalaEditor,
    froalaView,
    IntegrationModule,
    ImageCropModule,
    GsuiteModule,
    O365Module,
    LaunchpadModule,
    LoginModule,
    LicenseModule,
    loggerModule,
    TextModule,
    MessagingModule,
    NotificationsModule,
    SocketModule,
    modalModule,
    MomentModule,
    NotificationsModule,
    PagesModule,
    permissionsModule,
    popoverModule,
    ReportsModule,
    SearchModule,
    SearchShellModule,
    SenderUIModule,
    SocialModule,
    SpinnerModule,
    FloatingHeaderModule,
    FloatingFooterModule,
    tabsModule,
    TimelineModule,
    TimeModule,
    toastrModule,
    TourModule,
    translateModule,
    TrustModule,
    uiRouterModule,
    WidgetsModule,
    collapseModule,
    BrowserAnimationsModule,
    TeamsRedirectModule,
    ngxsModule,
    ngxsDevtools,
    TimelineAppModule,
    AnalyticsModule,
    FormlyModule.forRoot({
      extras: {checkExpressionOn: 'modelChange'}
    }),
    WorkspacesModule
  ],
  providers: [{
    provide: LOCALE_ID, useFactory: localeProvider, deps: [TranslateService, APP_INITIALIZER]
  }],
  declarations: [BootstrapComponent],
  entryComponents: [BootstrapComponent]
})
export class AppModule {

  /**
   * Function is called when bootstrapping the angular 2 part of the application.
   */
  ngDoBootstrap(): void {
  }
}

(function (angular) {
  'use strict';

  DatepickerController.$inject = ["datepickerService", "moment"];
  angular.module('commons.ui')
      .controller('DatepickerController', DatepickerController);

  function DatepickerController(datepickerService, moment) {
    var vm = this;

    vm.$onInit = onInit;
    vm.translateMonths = translateMonths;
    vm.translateTitle = translateTitle;
    vm.getLanguage = getLanguage;

    function translateMonths(dt) {
      return datepickerService.translateMonths(dt);
    }

    function translateTitle(title) {
      datepickerService.translateTitle(title);
      return datepickerService.getTitle();
    }

    function getLanguage() {
      return moment.locale();
    }

    function onInit() {
      datepickerService.update();
      vm.title = datepickerService.getTitle();
      vm.weekdays = datepickerService.getWeekdays();
    }
  }

})(angular);

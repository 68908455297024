import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SearchSortingOption} from '@app/search/search-sorting-menu/search-sorting-option';

/**
 * Displays a button that indicates the current sorting.
 * Button opens a menu to pick a sorting option for the search results from.
 */
@Component({
  selector: 'coyo-search-sorting-menu',
  templateUrl: './search-sorting-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchSortingMenuComponent {

  /**
   * Emits the selected sorting option
   */
  @Output()
  sortingChanged: EventEmitter<SearchSortingOption> = new EventEmitter<SearchSortingOption>();

  /**
   * Flag to disable the button that opens the menu
   */
  @Input()
  disabled: boolean;

  /**
   * A list of sorting options to display in the menu
   */
  @Input()
  sortingOptions: SearchSortingOption[];

  /**
   * The active sorting option to indicate the current sorting
   */
  @Input()
  activeSortingOption: SearchSortingOption;

  /**
   * Flag if the button to open the menu should show text
   */
  @Input()
  buttonOnly: boolean = false;

  /**
   * Emits the selected sorting option and sets it as active sorting option
   *
   * @param selectedSortingOption The selected sorting option
   */
  emitSortingChange(selectedSortingOption: SearchSortingOption): void {
    if (this.activeSortingOption !== selectedSortingOption) {
      this.activeSortingOption = selectedSortingOption;
      this.sortingChanged.emit(selectedSortingOption);
    }
  }
}

import {Component, Input} from '@angular/core';
import {App} from '@apps/api/app';
import {AppConfig} from '@apps/api/app-config';
import {AppSettings} from '@apps/api/app-settings/app-settings';

/**
 * A component that renders a specific app.
 */
@Component({
  template: ''
})
export abstract class AppComponent<Settings extends AppSettings> {

  /**
   * The underlying app config definition.
   */
  @Input() config: AppConfig<Settings>;

  /**
   * The actual app data.
   */
  @Input() app: App<Settings>;
}

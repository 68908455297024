import {Injectable, Type} from '@angular/core';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {FilePickerItem} from '@app/file-picker/file-picker-item';

import {FilePickerItemService} from '@app/file-picker/file-picker-item.service';
import {BaseDriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/base-drive-file-picker-item';
import {DriveFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/drive-file-picker-item';
import {DriveFolderItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/drive-folder-item';
import {DriveItemFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/drive-item-file-picker-item';
import {SiteFilePickerItem} from '@app/integration/o365/share-point-file-picker/file-picker-items/site-file-picker-item';
import {SinglePage} from '@domain/page/single-page';
import {Page} from '@domain/pagination/page';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Service responsible for handling all share point file picker items
 */
@Injectable({providedIn: 'root'})
export class SharePointFilePickerItemService extends FilePickerItemService {

  getChildren(filePickerItem: BaseDriveFilePickerItem): Observable<Page<FilePickerItem>> {
    return filePickerItem.getChildren().pipe(map(res => new SinglePage(res)));
  }

  rename(filePickerItem: FileLibraryFilePickerItem, newName: string): Observable<FileLibraryFilePickerItem> {
    return of(filePickerItem);
  }

  getCapabilities(filePickerItem: FilePickerItem): FilePickerItemServiceCapability {
    return FilePickerItemServiceCapability.NONE;
  }

  getResponsibilities(): Type<FilePickerItem>[] {
    return [DriveFilePickerItem, SiteFilePickerItem, DriveItemFilePickerItem, DriveFolderItem];
  }
}

import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '@root/injection-tokens';
import {Ng1BackendUrlService} from '@root/typings';
import {NG1_BACKEND_URL_SERVICE} from '@upgrade/upgrade.module';
import * as _ from 'lodash';

/**
 * Service for exposing the backend url and manipulating given urls
 */
@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private readonly URL_PATTERN: string = '(http|https)://(www.)?';
  private readonly URL_REGEXP: RegExp;
  private readonly PROTOCOL_REGEXP: RegExp;
  private readonly LOCATION_REGEXP: RegExp;

  constructor(@Inject(NG1_BACKEND_URL_SERVICE) private backendUrlService: Ng1BackendUrlService,
              @Inject(WINDOW) private window: Window) {
    this.URL_REGEXP = new RegExp(this.URL_PATTERN + '/*', 'i');
    this.PROTOCOL_REGEXP = new RegExp('^(?:[a-z]+:)?//', 'i');
    this.LOCATION_REGEXP = new RegExp(this.URL_PATTERN + this.window.location.host + '/*', 'i');
  }

  /**
   * Checks if the url is a valid url.
   *
   * @param url the url to check if it is valid
   * @returns true if the url addresses is valid
   */
  isValidUrl(url: string): boolean {
    return !_.isEmpty(url) ? this.URL_REGEXP.test(url) : false;
  }

  /**
   * Strips the given url of the protocol.
   *
   * @param url the url to strip of the protocol
   * @returns the url without the protocol
   */
  getUrlWithoutProtocol(url: string): string {
    if (url.length > 0) {
      return url.replace(this.URL_REGEXP, '');
    }
    return url;
  }

  /**
   * Retrieves the backend url belonging to the actual frontend
   *
   * @return The backend url.
   */
  getBackendUrl(): string {
    return this.backendUrlService.getUrl();
  }

  /**
   * Checks if set backend url is set
   *
   * @return true if the backend url is set
   */
  isBackendUrlSet(): boolean {
    return this.backendUrlService.isSet();
  }

  /**
   * Checks if the url string starts with the configured backend url or with the current host url.
   *
   * @param url the url to check if its addressed to the backend
   * @returns true if the url addresses the backend explicitly
   */
  isAbsoluteBackendUrl(url: string): boolean {
    if (!_.isEmpty(url)) {
      const backendUrl = this.backendUrlService.getUrl();
      if (backendUrl.length > 0) {
        return url.startsWith(backendUrl);
      } else {
        return this.LOCATION_REGEXP.test(url);
      }
    }

    return false;
  }

  /**
   * Checks if the path is relative to the current COYO host.
   * The path is considered relative if there is no protocol prefix ending with '//' or it starts with the current host.
   *
   * @param path the path to check if its relative
   * @return true if it is relative
   */
  isRelativePath(path: string): boolean {
    return !this.PROTOCOL_REGEXP.test(path) || this.LOCATION_REGEXP.test(path);
  }

  /**
   * Extract the relative path from the given url string without any parameters
   *
   * @param url to extract the path from
   * @return string of the relative path
   */
  getRelativePath(url: string): any {
    const urlObj = this.constructUrlObject(url);
    return _.isObject(urlObj) ? urlObj.pathname : undefined;
  }

  /**
   * Extract parameters from url string and creates a json object string
   *
   * @param url to extract the parameter from
   * @return string of parameter in json format
   */
  getUrlParameterAsJsonString(url: string): string {
    const urlObj: URL = this.constructUrlObject(url);
    const jsonObj: object = {};
    if (_.isObject(urlObj)) {
      urlObj.searchParams.forEach((value: string, key: string) => {
        const paramObj: object = JSON.parse(`{"${key}":"${value}"}`);
        _.isUndefined(_.get(jsonObj, key)) ? _.assign(jsonObj, paramObj) : _.set(jsonObj, key, _.concat(_.get(jsonObj, key), value));
      });
    }
    return JSON.stringify(jsonObj);
  }

  /**
   * Joins parts of an url to a valid url
   *
   * @param parts array of url parts to be joined.
   * @return The joined url.
   */
  join(...parts: string[]): string {
    return _.chain(parts)
      .map(part => _.trim(part, '/'))
      .filter(part => !_.isEmpty(part))
      .join('/').value();
  }

  /**
   * Creates a url param string.
   * E.g.: id=1&id=2&id=3
   *
   * @param key Parameter name.
   * @param values The values.
   * @return the url param string.
   */
  toUrlParamString(key: string, values: (string | number)[]): string {
    return values.filter(value => !!value || value === 0).map(value => key + '=' + value).join('&');
  }

  /**
   * Creates an url param string out of the given map
   *
   * @param params the params that should be joined
   *
   * @return The url param string
   */
  toMultiUrlParamString(params: { [key: string]: (string | number)[] }): string {
    return _.keys(params).map(key => this.toUrlParamString(key, params[key])).filter(value => !!value).join('&');
  }

  /**
   * Inserts path variables into an url.
   * @param url The url
   * @param parameters The map of parameters that should be inserted
   * @return The url with inserted parameters
   */
  insertPathVariablesIntoUrl(url: string, parameters: { [key: string]: string }): string {
    let modifiedUrl = url;
    Object.keys(parameters).forEach(key => {
      modifiedUrl = modifiedUrl.replace('{{' + key + '}}', parameters[key]);
    });
    return modifiedUrl;
  }

  /**
   * Gets the current domain as defined by the window object.
   *
   * @returns The current domain like https://someurl.com
   */
  getCurrentDomain(): string {
    return `${this.window.location.protocol}//${this.window.location.host}`;
  }

  private constructUrlObject(url: string): URL {
    let constructedUrl = url;
    if (!_.includes(url, this.window.location.host)) {
      constructedUrl = this.getCurrentDomain() + url;
    } else if (!_.includes(url, this.window.location.protocol)) {
      constructedUrl = `${this.window.location.protocol}//${url}`;
    }
    return this.isValidUrl(constructedUrl) ? new URL(constructedUrl) : undefined;
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SenderRootFilePickerItem} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';

/**
 * File picker item showing a list of senders.
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tr[coyo-sender-root-file-picker-item]',
  templateUrl: './sender-root-file-picker-item.component.html',
  styleUrls: ['./sender-root-file-picker-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderRootFilePickerItemComponent extends FilePickerItemBaseDirective<SenderRootFilePickerItem> {
  item$: Observable<SenderRootFilePickerItem>;

  constructor(store: Store) {
    super(store);
  }
}

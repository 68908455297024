<div class="panel panel-default panel-sm">
  <h3 class="question-title">{{widget.settings.question}}</h3>
  <p class="question-description text-muted">{{widget.settings.description}}</p>
  <p class="question-description-badges text-muted">
    <span *ngIf="widget.settings._frozen" class="badge badge-red" translate="WIDGET.POLL.WIDGET.FROZENREMARK"></span>
    <span *ngIf="!widget.settings._frozen && (widgetState$ | async)?.frozen" class="badge badge-red" translate="WIDGET.POLL.WIDGET.ALREADYCLOSED"></span>
    <span *ngIf="widget.settings._anonymous" class="badge badge-orange"
          translate="WIDGET.POLL.WIDGET.ANONYMOUSREMARK"></span>
    <span *ngIf="widget.settings._maxAnswers > 1" class="badge badge-orange"
          translate
          [translateParams]="{ 'count': widget.settings._maxAnswers}">WIDGET.POLL.WIDGET.MAXANSWERSREMARK</span>
  </p>
  <div class="question-container">
    <ng-container *ngFor="let option of options$ | async">
      <div class="question-row" *ngIf="option.answer || option.votes > 0">
        <div class="question-radio checkbox" *ngIf="!settingsMode">
          <label>
            <button type="button" class="coyo-checkbox btn btn-default"
                    [ngClass]="{'checked': option.answerId !== undefined}"
                    id="checkBoolean" (click)="toggleOption(option)" [disabled]="!(canVote$(option) | async)">
              <i class="zmdi zmdi-hc-fw" [ngClass]="{'zmdi-check': option.answerId !== undefined}"></i>
            </button>
          </label>
        </div>

        <div class="question-answer">
          <div class="vote-container" *ngIf="widget.settings._showResults || settingsMode">
            <div class="vote-bar" [ngStyle]="{'width': (getPercentage$(option) | async) + '%'}"></div>
          </div>
          <div>{{option.answer}}</div>
        </div>
        <div class="question-votes" [ngClass]="{'clickable': !widget.settings._anonymous}"
             *ngIf="widget.settings._showResults || settingsMode" (click)="showVoters(option)">
          <b>{{option.votes || 0}}</b>
          <div>
            <small>{{(option.votes === 1 ? 'WIDGET.POLL.WIDGET.VOTE' : 'WIDGET.POLL.WIDGET.VOTES') | translate}}</small>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

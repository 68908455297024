(function (angular) {
  'use strict';

  renderSkeleton.$inject = ["$compile"];
  angular
      .module('commons.ui')
      .directive('coyoRenderSkeleton', renderSkeleton);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoRenderSkeleton:coyoRenderSkeleton
   * @scope
   * @restrict 'E'
   * @element ANY
   *
   * @description
   * Renders a Skeleton by the bound name. Mainly used for widgets via the widget-status directive.
   *
   * @see component.skeleton.scss for available skeletons and their definition
   *
   * @param {string} container The name of the container and base element the actual skeleton content will be rendered on.
   * @param {string} name      The name of the skeleton that shall be rendered.
   */
  function renderSkeleton($compile) {
    return {
      restrict: 'E',
      link: function (scope, elem, attr) {
        var html = '<div class="' + attr.name + '"></div>';
        if (attr.container) {
          html = '<div class="' + attr.container + '">' + html + '</div>';
        }
        elem.append($compile(html)(scope));
      }
    };
  }
})(angular);

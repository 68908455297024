<div *ngIf="sender.imageUrls?.avatar && sender.active">
  <coyo-event-avatar [sender]="sender" [avatarSize]="avatarSize$ | async" [showOverlay]="true"></coyo-event-avatar>
</div>
<div class="share-item-message" *ngIf="sender.active">
  <a class="share-item-title" [uiSref]="'main.' + sender.typeName + '.show'" [uiParams]="{idOrSlug: sender.slug}">
    {{sender.title}}
  </a>
  <coyo-collapsible-content class="share-item-description" [content]="description">
  </coyo-collapsible-content>
  <div class="share-item-labels">
    <span class="label label-muted" *ngIf="sender.public"><i class="zmdi zmdi-globe"></i></span>
    <span class="label label-muted" *ngIf="sender.startDate"><i class="zmdi zmdi-time"></i>
      <span>
        &nbsp;{{sender | eventDate}}
      </span>
      <span *ngIf="!sender.fullDay">
        &nbsp;{{sender | eventTime}}
      </span>
    </span>
    <span class="label label-muted" *ngIf="sender.host"><i class="zmdi zmdi-account-circle"></i>&nbsp;{{sender.host}}</span>
    <span class="label label-muted" *ngIf="sender.place"><i class="zmdi zmdi-pin"></i>&nbsp;{{sender.place}}</span>
    <span class="label label-muted" *ngIf="sender.attendingCount || sender.settings.participantsLimit">
      <i class="zmdi zmdi-accounts"></i>&nbsp;{{sender.attendingCount}}<span *ngIf="sender.settings.participantsLimit">/{{sender.settings.participantsLimit}}</span>
    </span>
  </div>
</div>
<div class="share-item-message" *ngIf="!sender.active">
  <div class="alert alert-warning" [translate]="'MODULE.TIMELINE.EVENT.WARNING'"></div>
</div>

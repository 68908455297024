(function (angular) {
  'use strict';

  FolderModel.$inject = ["restResourceFactory", "coyoEndpoints", "backendUrlService"];
  angular
      .module('coyo.domain')
      .factory('FolderModel', FolderModel);

  /**
   * @ngdoc service
   * @name coyo.domain.FolderModel
   *
   * @description
   * Provides the Coyo folder model.
   *
   * @requires restResourceFactory
   * @requires commons.config.coyoEndpoints
   */
  function FolderModel(restResourceFactory, coyoEndpoints, backendUrlService) {
    var FolderModel = restResourceFactory({
      url: coyoEndpoints.sender.folders
    });

    angular.extend(FolderModel, {

      /**
       * @ngdoc method
       * @name coyo.domain.FolderModel#getSettings
       * @methodOf coyo.domain.FolderModel
       *
       * @description
       * Retrieves the settings for the folder with the given ID.
       *
       * @param {string} senderId The ID of the sender the folder's file library belongs to
       * @param {string} folderId The ID of the folder
       * @returns {object} Promise resolving to the folder settings
       */
      getSettings: function (senderId, folderId) {
        return new FolderModel({id: folderId, senderId: senderId}).get('settings');
      },

      /**
       * @ngdoc method
       * @name coyo.domain.FolderModel#hasChildren
       * @methodOf coyo.domain.FolderModel
       *
       * @description
       * Check whether or not the given folder has any children.
       *
       * @param {string} senderId The ID of the sender the folder's file library belongs to
       * @param {string} folderId The ID of the folder
       * @returns {object} Promise resolving to the folder settings
       */
      hasChildren: function (senderId, folderId) {
        return new FolderModel({id: folderId, senderId: senderId}).get('has-children');
      },

      /**
       * @ngdoc method
       * @name coyo.domain.FolderModel#download
       * @methodOf coyo.domain.FolderModel
       *
       * @description
       * Download the given folder and its children.
       *
       * @param {string} senderId The ID of the sender the folder's file library belongs to
       * @param {string} folderId The ID of the folder
       * @returns {file} the file to be downloaded
       */
      download: function (senderId, folderId) {
        return new FolderModel({id: folderId, senderId: senderId}).get('zip');
      },

      /**
       * @ngdoc method
       * @name coyo.domain.FolderModel#getDownloadUrl
       * @methodOf coyo.domain.FolderModel
       *
       * @description
       * Gets the url for the download of the given folder and its children.
       *
       * @param {string} senderId The ID of the sender the folder's file library belongs to
       * @param {string} folderId The ID of the folder
       * @returns {string} the download url
       */
      getDownloadUrl: function (senderId, folderId) {
        return backendUrlService.getUrl()
            + new FolderModel({id: folderId, senderId: senderId}).$url('zip');
      },

      /**
       * @ngdoc method
       * @name coyo.domain.FolderModel#status
       * @methodOf coyo.domain.FolderModel
       *
       * @description
       * Check whether the download of a folder is being prepared or failed or the zip file was successfully generated
       *
       * @param {string} senderId The ID of the sender the folder's file library belongs to
       * @param {string} folderId The ID of the folder
       * @returns {object} Promise resolving to the zip file generation status
       */
      status: function (senderId, folderId) {
        return new FolderModel({id: folderId, senderId: senderId}).get('zip/status');
      }
    });

    return FolderModel;
  }

})(angular);

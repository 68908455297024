import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NgModel, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Duration, DurationFormatter, DurationUnit} from './duration';
import {DurationPickerMode, DurationRangeChecker} from './duration-range-checker';

/**
 * Component presenting a duration picker
 */
@Component({
  selector: 'coyo-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DurationPickerComponent), multi: true}
  ]
})
export class DurationPickerComponent implements OnInit, ControlValueAccessor {

  /**
   * The ng model
   */
  @Input() ngModel: NgModel;

  /**
   * The maximum allowed value (The minimum is currently always zero).
   * You can also pass this value as an ISO 8601 formatted string.
   */
  @Input() max?: Duration | string;

  /**
   * The units that should be shown as input fields.
   * You can also pass this value as a comma-separated string of unit names.
   */
  @Input() showUnits: DurationUnit[] | string;

  /**
   * The picker mode. See there for a description how the modes influence the behaviour of the input fields.
   * Default ist STRICT mode.
   * You can also pass this value as a string.
   */
  @Input() mode?: DurationPickerMode | string;

  /**
   * The optional prefix for the i18n translations.
   */
  @Input() i18nPrefix: string | null = 'CHRONO_UNIT.';

  // make DurationUnit type available in component template
  unit: any = DurationUnit;

  isDisabled: boolean = false;

  private duration: Duration;
  private maxDuration: Duration;
  private disabledUnits: DurationUnit[] = [];
  private durationRangeChecker: DurationRangeChecker = new DurationRangeChecker();
  private fnOnChange: any;

  /**
   * Disables the duration picker
   *
   * @param isDisabled
   * if true the duration picker will be disabled
   */
  @Input()
  set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  ngOnInit(): void {
    this.mode = (typeof this.mode === 'string' ? DurationPickerMode[this.mode as string] : this.mode)
      || DurationPickerMode.STRICT;
    this.maxDuration = this.max
      ? (typeof this.max === 'string' ? DurationFormatter.parseIso8601(this.max) : this.max) : null;
    this.showUnits = (typeof this.showUnits === 'string'
      ? ((this.showUnits as string).split(',').map(strUnit => DurationUnit[strUnit]))
      : this.showUnits) || [];
    this.disabledUnits = Object.keys(DurationUnit)
      .map(key => DurationUnit[key])
      .filter(unit => !isNaN(Number(unit)) && !this.isShown(unit));
    this.durationRangeChecker.mode = this.mode as DurationPickerMode;
    this.durationRangeChecker.max = this.maxDuration ? this.maxDuration : null;
    this.update();
  }

  /* tslint:disable-next-line:completed-docs */
  writeValue(isoDuration: string): void {
    this.duration = isoDuration ? DurationFormatter.parseIso8601(isoDuration) : null;
    this.update();
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnChange(fnOnChange: any): void {
    this.fnOnChange = fnOnChange;
  }

  /* tslint:disable-next-line:completed-docs */
  registerOnTouched(fn: any): void {
  }

  /* tslint:disable-next-line:completed-docs */
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get months(): number | null {
    return this.duration ? this.duration.months : null;
  }

  set months(months: number) {
    this.duration = this.getOrCreateDuration().set(DurationUnit.MONTHS, Number(months));
    this.update();
  }

  get days(): number | null {
    return this.duration ? this.duration.days : null;
  }

  set days(days: number) {
    this.duration = this.getOrCreateDuration().set(DurationUnit.DAYS, Number(days));
    this.update();
  }

  get hours(): number | null  {
    return this.duration ? this.duration.hours : null;
  }

  set hours(hours: number) {
    this.duration = this.getOrCreateDuration().set(DurationUnit.HOURS, Number(hours));
    this.update();
  }

  get minutes(): number | null  {
    return this.duration ? this.duration.minutes : null;
  }

  set minutes(minutes: number) {
    this.duration = this.getOrCreateDuration().set(DurationUnit.MINUTES, Number(minutes));
    this.update();
  }

  get seconds(): number | null  {
    return this.duration ? this.duration.seconds : null;
  }

  set seconds(seconds: number) {
    this.duration = this.getOrCreateDuration().set(DurationUnit.SECONDS, Number(seconds));
    this.update();
  }

  /**
   * Gets the max value of the duration unit
   *
   * @param unit
   * The unit
   *
   * @return The maximum value of the given unit
   */
  getMax(unit: DurationUnit): number {
    return this.durationRangeChecker.getMaxUnit(unit);
  }

  /**
   * Gets the min value of the duration unit
   *
   * @param unit
   * The unit
   *
   * @return The minimum value of the given unit
   */
  getMin(unit: DurationUnit): number {
    return this.durationRangeChecker.getMinUnit(unit);
  }

  /**
   * Determines if the given DurationUnit should be shown in the template
   *
   * @param unit
   * The unit
   *
   * @return true if the unit should be shown in the template
   */
  isShown(unit: DurationUnit): boolean {
    return (this.showUnits as DurationUnit[]).includes(unit);
  }

  private getOrCreateDuration(): Duration {
    if (!this.duration) {
      this.duration = Duration.ZERO.disable(this.disabledUnits);
    }
    return this.duration;
  }

  private update(): void {
    if (this.duration) {
      this.duration = this.duration.disable(this.disabledUnits);
      if (this.mode === DurationPickerMode.STRICT || this.mode === DurationPickerMode.NORMALIZE) {
        this.duration = this.duration.normalize();
      }
    }
    this.durationRangeChecker.value = this.duration;
    const value = this.duration ? DurationFormatter.formatIso8601(this.duration) : null;
    if (this.fnOnChange) {
      this.fnOnChange(value);
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {CodeWidget} from '@widgets/code/code-widget';
import * as _ from 'lodash';

/**
 * The code widget's settings component.
 *
 * This component is used only in the context of the widget settings modal dialog.
 */
@Component({
  templateUrl: './code-widget-settings.component.html',
  styleUrls: ['./code-widget-settings.component.scss']
})
export class CodeWidgetSettingsComponent extends WidgetSettingsComponent<CodeWidget> implements OnInit {

  private nl: string = '\n';
  private t: string  = '\t';
  private nlt: string = this.nl + this.t;

  constructor() {
    super();
  }

  ngOnInit(): void {
    const htmlContent = _.get(this.widget.settings, '_html_content', '');
    const jsContent = _.get(this.widget.settings, '_js_content', '');
    const cssContent = _.get(this.widget.settings, '_css_content', '');
    this.parentForm.addControl('_html_content', new FormControl(htmlContent));
    this.parentForm.addControl('_js_content', new FormControl(jsContent));
    this.parentForm.addControl('_css_content', new FormControl(cssContent));
  }

  getPlaceholderHtml(): string {
    const wrapperStart = '<div class="code-widget-wrapper">';
    const wrapperEnd = '</div>';
    const helloWorldSpan = '<span class="code-widget-hello-world">Hello World!</span>';
    const greetingSpan = '<span id="code-widget-greeting"></span>';

    return wrapperStart + this.nlt + helloWorldSpan + this.nlt + greetingSpan + this.nl + wrapperEnd;
  }

  getPlaceholderJs(): string {
    const wrapperStart = 'function greeting() {';
    const wrapperEnd = '};';
    const functionExec = 'greeting();';
    const element = 'const element = this.document.getElementById(\'code-widget-greeting\'));';
    const text = 'element.text(\'Have a nice day!\');';

    return wrapperStart + this.nlt + element + this.nlt + text + this.nl + wrapperEnd + this.nl + functionExec;
  }

  getPlaceholderCss(): string {
    const wrapperStart = '#code-widget-greeting {';
    const wrapperEnd = '}';
    const content = 'background-color: #f5b6b6;';

    return wrapperStart + this.nlt + content + this.nl + wrapperEnd;
  }
}

import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {CoyoFormsModule} from '@shared/forms/forms.module';
import {DateRangePickerComponent} from './date-range-picker.component';
import './date-range-picker.component.downgrade';

/**
 * Module that contains a component used to display a date range picker.
 */
@NgModule({
  imports: [
    CoyoCommonsModule,
    CoyoFormsModule
  ],
  declarations: [
    DateRangePickerComponent
  ],
  exports: [
    DateRangePickerComponent
  ]
})
export class DateRangePickerModule {
}

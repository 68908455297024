(function (angular) {
  'use strict';

  /**
   * @ngdoc directive
   * @name commons.ui.maintenanceMessage:maintenanceMessage
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Displays a bar on the bottom of the screen when the system admin enables a system wide message.
   * This message can be hidden with a link on the bar.
   */
  var maintenanceMessageComponent = {
    templateUrl: 'app/commons/ui/components/maintenance-message/maintenance-message.html',
    controller: 'MaintenanceMessageController'
  };

  angular
      .module('commons.ui')
      .component('oyocMaintenanceMessage', maintenanceMessageComponent);

})(angular);

import {SizeOption} from '@widgets/headline/size-option';

/**
 * Available size options for the headline widget
 */
const sizeOptions: SizeOption[] = [
  {size: 'XL', previewClass: 'h1'},
  {size: 'L', previewClass: 'h2'},
  {size: 'M', previewClass: 'h3'},
  {size: 'S', previewClass: 'h4'}
];

export default sizeOptions;

(function (angular) {
  'use strict';

  TournamentAdminModel.$inject = ["restResourceFactory"];
  angular
      .module('coyo.admin.gamification')
      .factory('TournamentAdminModel', TournamentAdminModel);

  /**
   * @ngdoc service
   * @name coyo.admin.gamification.TournamentAdminModel
   *
   * @description
   * Domain model representing the tournament administration endpoint.
   *
   * @requires restResourceFactory
   */
  function TournamentAdminModel(restResourceFactory) {
    var Tournament = restResourceFactory({
      url: '/web/tournaments/{{id}}'
    });

    // class members
    angular.extend(Tournament, {

      /**
       * @ngdoc method
       * @name coyo.admin.gamification.TournamentAdminModel#getLatest
       * @methodOf coyo.admin.gamification.TournamentAdminModel
       *
       * @description
       * Returns the most current tournament
       *
       * @returns {object} A promise of the tournament
       */
      getLatest: function () {
        var url = Tournament.$url('latest');
        return Tournament.$get(url);
      }
    });

    return Tournament;
  }
})(angular);

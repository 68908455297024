import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {VideoWidget} from '@widgets/video/video-widget';
import {VideoWidgetSettingsComponent} from './video-widget-settings/video-widget-settings.component';
import {VideoWidgetComponent} from './video-widget/video-widget.component';

export const VIDEO_WIDGET: WidgetConfig<VideoWidget> = {
  key: 'video',
  name: 'WIDGETS.VIDEO.NAME',
  description: 'WIDGETS.VIDEO.DESCRIPTION',
  icon: 'zmdi-youtube-play',
  component: VideoWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    skipOnCreate: false,
    component: VideoWidgetSettingsComponent,
  },
  whitelistExternal: true,
  mobileSupportTypes: ['landing-page']
};

import {Inject, Injectable} from '@angular/core';
import {Document} from '@domain/file/document';
import {FileService} from '@domain/file/file/file.service';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {Ng1FileLibraryModalService} from '@root/typings';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';
import {NG1_FILE_LIBRARY_MODAL_SERVICE} from '@upgrade/upgrade.module';
import {from} from 'rxjs';
import {RtePlugin} from '../rte-plugin';

/**
 * A custom RTE plugin that connects the Froala editor to the COYO file library for file selection.
 */
@Injectable()
export class FileFromFileLibraryPlugin extends RtePlugin {

  static readonly KEY: string = 'coyoInsertFileFromFileLibrary';

  constructor(@Inject(FROALA_EDITOR) private froala: any,
              @Inject(NG1_FILE_LIBRARY_MODAL_SERVICE) private fileLibraryModalService: Ng1FileLibraryModalService,
              private translateService: TranslateService,
              private fileService: FileService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    if (!settings.canAccessFiles) {
      return; // user is not allowed to access the file library
    }

    const plugin = this;
    plugin.froala.RegisterCommand(FileFromFileLibraryPlugin.KEY, {
      title: plugin.translateService.instant('RTE.INSERT_FILE'),
      plugin: 'coyoFilePlugin',
      icon: 'insertFile',
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        plugin.openCoyoFileLibrary(this);
      }
    });
  }

  private openCoyoFileLibrary(editor: any): void {
    const plugin = this;
    const wrapper = editor.$oel.find('.fr-wrapper');
    const scrollPosition = wrapper.scrollTop();
    const prevText = editor.selection.text();

    editor.selection.save();
    from(plugin.fileLibraryModalService.open(editor.getSender(), {
      selectMode: 'single',
      initialFolder: editor.getApp() ? {id: editor.getApp().rootFolderId} : null
    })).subscribe((document: Document) => {
      if (document) {
        const name = prevText || document.displayName;
        editor.selection.restore();
        editor.link.insert(plugin.fileService.getRelativeDeepUrl(document), name);
      } else {
        editor.selection.restore();
      }
      wrapper.scrollTop(scrollPosition);
    }, () => {
      editor.selection.restore();
      wrapper.scrollTop(scrollPosition);
    });
  }
}

import {NgModule} from '@angular/core';
import {AboutCoyoModalComponent} from '@shared/about-coyo/about-coyo-modal.component';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import './about-coyo-modal.service.downgrade';

/**
 * Module that contains a component used to display the "About COYO" modal.
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    AboutCoyoModalComponent
  ],
  entryComponents: [
    AboutCoyoModalComponent
  ]
})
export class AboutCoyoModalModule {
}

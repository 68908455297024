import {InjectionToken, Type} from '@angular/core';
import {Widget} from '@domain/widget/widget';
import {WidgetSettings} from '@widgets/api/widget-settings/widget-settings';
import {Observable} from 'rxjs';
import {WidgetComponent} from './widget-component';
import {WidgetInlineSettingsComponent} from './widget-inline-settings-component';
import {WidgetSettingsComponent} from './widget-settings-component';

/**
 * The Widget configuration injection token.
 */
export const WIDGET_CONFIGS: InjectionToken<WidgetConfig<Widget<WidgetSettings>>[]> =
  new InjectionToken<WidgetConfig<Widget<WidgetSettings>>[]>('WIDGET_CONFIGS');

/**
 * Dynamic widget configuration service.
 * Widgets that support dynamic widget configs should implement this service and provide it via the injection token
 * below.
 * Do not confuse dynamic widget settings with the WidgetCategory DYNAMIC or STATIC.
 */
export interface DynamicWidgetConfigService {
  /**
   * Return the list of WidgetConfigs that the implementation supports. The Observable may not complete but emit
   * new lists when a widget was added or removed.
   *
   * @returns Observable with widget configurations for the widget type.
   */
  get$(): Observable<WidgetConfig<Widget<WidgetSettings>>[]>;
}

/**
 * Injection token for widgets that support dynamic widget configuration (can be added or removed on the fly).
 * Example: the plug-in widget will offer as much dynamic widgets as plug-ins exist. Since plug-ins can be added or
 * removed dynamically, the widget configs will change accordingly.
 */
export const WIDGET_DYNAMIC_CONFIG_SERVICES: InjectionToken<DynamicWidgetConfigService[]> =
  new InjectionToken<DynamicWidgetConfigService[]>('WIDGET_DYNAMIC_CONFIG_SERVICES');

/**
 * The configuration of a widget
 */
export class WidgetConfig<WidgetType extends Widget<WidgetSettingsType>, WidgetSettingsType = WidgetSettings> {

  /**
   * The registration key of the widget.
   */
  key: string;

  /**
   * The component definition of the widget.
   */
  component: Type<WidgetComponent<WidgetType>>;

  /**
   * The Material Design Iconic Font class (zmdi) for the widget.
   */
  icon: string;

  /**
   * The name of the widget, either an i18n key (static, dynamic or personal) or a translated string (plugin)
   */
  name: string;

  /**
   * The description of the widget, either an i18n key (static, dynamic or personal) or a translated string (plugin)
   */
  description?: string;

  /**
   * The category of the widget (static, dynamic, personal or plugin)
   */
  categories: WidgetCategory;

  /**
   * Options for the inline settings.
   */
  inlineOptions?: {

    /**
     * The component to be rendered as inline settings.
     */
    component: Type<WidgetInlineSettingsComponent<WidgetType>>;
  };

  /**
   * Options for the settings.
   */
  settings?: {

    /**
     * Flag indicating if the settings dialog should be shown on widget creation.
     */
    skipOnCreate: boolean;

    /**
     * The component to be rendered as settings.
     */
    component: Type<WidgetSettingsComponent<WidgetType>>;
  };

  /**
   * Generator function for default settings that will be used if other settings are not present.
   */
  defaultSettings?: () => Partial<WidgetSettingsType>;

  /**
   * The default title for the Widget
   */
  defaultTitle?: string;

  /**
   * Additional rendering options.
   */
  renderOptions?: {

    /**
     * Flag indicating if this widget is printable.
     */
    printable: boolean;

    /**
     * Render options for widgets in "panels" mode.
     */
    panels?: {

      /**
       * Flag indicating if this widget is rendered within a panel.
       */
      noPanel: boolean;
    },

    /**
     * Render options for widgets in "panel" mode.
     */
    panel?: {

      /**
       * Flag indicating if this widget is rendered within a panel.
       */
      noPanel: boolean;
    }
  };

  /**
   * Flag indicating whether the widget can be seen by an external workspace member
   */
  whitelistExternal?: boolean = false;

  /**
   * List of parent types for which the widget offers mobile support
   */
  mobileSupportTypes?: string[];
}

/**
 * The set of widget categories.
 */
export enum WidgetCategory {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
  PERSONAL = 'personal',
  PLUGIN = 'plugin'
}

import {InjectionToken, Type} from '@angular/core';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {CropSettings} from '@app/file-picker/crop-settings';
import {FilePickerHeaderBaseDirective} from '@app/file-picker/file-picker-header-base.directive';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {FilePickerUpload} from '@app/file-picker/file-picker-upload';
import {Page} from '@domain/pagination/page';
import {User} from '@domain/user/user';
import {Observable} from 'rxjs';

export const FILE_PICKER_ITEM_SERVICES = new InjectionToken<FilePickerItemService[]>('filePickerItemServices');

/**
 * Interface for services that handle file picker items
 */
export abstract class FilePickerItemService {

  /**
   * Returns a page of children for the given file picker item
   * @param filePickerItem The parent of the returned items
   * @param pageIdx index of the page
   * @returns An observable containing a page of child objects
   */
  abstract getChildren(filePickerItem: FilePickerItem, pageIdx: number): Observable<Page<FilePickerItem>>;

  /**
   * This methods is used to determine what types of file picker items this service is responsible for
   * @returns A list of file picker item types that this service is responsible for
   */
  abstract getResponsibilities(): Type<FilePickerItem>[];

  /**
   * Gets the component type for the given item. Inheriting classes can omit the parameter if it's unused
   * @returns A type of File Picker Component or null
   */
  getComponentType(): Type<FilePickerItemBaseDirective<FilePickerItem>> | null {
    return null;
  }

  /**
   * Gets the header component type for the given item. Inheriting classes can omit the parameter if it's unused
   * @returns A type of File Picker Header Component or null
   */
  getHeaderComponentType(): Type<FilePickerHeaderBaseDirective<FilePickerItem>> | null {
    return null;
  }

  /**
   * Delete the file represented file the given file picker item
   * @param item the item to be deleted
   * @returns An observable that emits the new item and completes when the file was renamed
   */
  delete(item: FilePickerItem): Observable<FilePickerItem> {
    throw new Error('not implemented');
  }

  /**
   * Renames the file represented file the given file picker item
   * @param filePickerItem The item of the file to be renamed
   * @param newName The new name
   * @returns An observable that emits the new item and completes when the file was renamed
   */
  rename(filePickerItem: FilePickerItem, newName: string): Observable<FilePickerItem> {
    throw new Error('not implemented');
  }

  /**
   * Starts a  file upload ta all given files
   * @param location The item of the file to upload
   * @param files The list of files to be uploaded
   * @param cropSettings Settings for image crop.
   * @returns The file picker upload data
   */
  upload(location: FilePickerItem, files: FileList | File[], cropSettings?: CropSettings): FilePickerUpload {
    throw new Error('not implemented');
  }

  /**
   * Upadtes the given file
   * @param location The item of the file to update
   * @param file The file to be uploaded
   * @returns The file picker upload data
   */
  update(location: FilePickerItem, file: File): FilePickerUpload {
    throw new Error('not implemented');
  }

  /**
   * Checks for service capabilities (bitwise flag enum)
   * @param filePickerItem The item to check
   * @returns a bitwise mask of the supported capabilities
   */
  abstract getCapabilities(filePickerItem: FilePickerItem): FilePickerItemServiceCapability;

  /**
   * Creates a folder
   *
   * @param parent The parent element of the new folder
   * @param name The name of the new folder
   *
   * @returns the file picker item of the new folder
   */
  createFolder(parent: FilePickerItem, name: string): Observable<FilePickerItem> {
    throw new Error('not implemented');
  }

  /**
   * Downloads the file
   *
   * @param filePickerItem The item that should be downloaded
   */
  download(filePickerItem: FilePickerItem): void {
    throw new Error('not implemented');
  }

  /**
   * Gets a deep link for the given file
   *
   * @param filePickerItem the file
   *
   * @returns The deep link
   */
  getDeepLink(filePickerItem: FilePickerItem): string {
    throw new Error('not implemented');
  }

  /**
   * Moves the file to the given destination.
   *
   * @param filePickerItem The filepicker item to move
   * @param destinationId The destination id
   *
   * @returns the updated destination file
   */
  moveFile(filePickerItem: FilePickerItem, destinationId: string): Observable<FilePickerItem> {
    throw new Error('not implemented');
  }

  /**
   * Get the authors of the given files
   * @param parent The parent folder
   * @param appId The current app id
   * @param childIds The ids of the children for which the authors should be fetched
   *
   * @return Mapping of authors to item id
   */
  getAuthors(parent: FilePickerItem, appId: string, childIds: string[]): Observable<{ [key: string]: User }> {
    throw new Error('not implemented');
  }

  /**
   * Edit a file with microsoft office over webdav.
   * @param item The file picker item
   */
  editWithOffice(item: FilePickerItem): Observable<any> {
    throw new Error('not implemented');
  }

  /**
   * Gets a file to update the view
   *
   * @param item the old file picker item
   *
   * @return The file picker item
   */
  getUpdatedItem(item: FilePickerItem): Observable<FilePickerItem> {
    throw new Error('not implemented');
  }

  /**
   * Retrieves whether the given item is selectable or not.
   * Per default all items are selectable that are no folder.
   *
   * @param item The file picker item
   *
   * @return True if item is no selectable.
   */
  isSelectable(item: FilePickerItem): boolean {
    return !item.isFolder;
  }
}

import {WidgetCategory, WidgetConfig} from '@widgets/api/widget-config';
import {ButtonWidget} from '@widgets/button/button-widget';
import {ButtonWidgetSettingsComponent} from './button-widget-settings/button-widget-settings.component';
import {ButtonWidgetComponent} from './button-widget/button-widget.component';

/**
 * The configuration for the button widget.
 */
export const BUTTON_WIDGET: WidgetConfig<ButtonWidget> = {
  key: 'button',
  name: 'WIDGET.BUTTON.NAME',
  description: 'WIDGET.BUTTON.DESCRIPTION',
  icon: 'zmdi-mail-reply zmdi-hc-flip-horizontal',
  component: ButtonWidgetComponent,
  categories: WidgetCategory.STATIC,
  settings: {
    skipOnCreate: false,
    component: ButtonWidgetSettingsComponent
  },
  renderOptions: {
    printable: true,
    panels: {
      noPanel: true
    }
  },
  whitelistExternal: true
};

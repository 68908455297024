import {Inject, Injectable, Type} from '@angular/core';
import {FileLibraryFilePickerItem} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item';
import {FileLibraryFilePickerItemComponent} from '@app/file-library/file-library-file-picker-item/file-library-file-picker-item.component';
import {OFFICE_EXT_APP_MAP} from '@app/file-library/file-library-file-picker-item/office-file-extensions';
import {FileLibraryHeaderComponent} from '@app/file-library/file-library-header/file-library-header.component';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {CropSettings} from '@app/file-picker/crop-settings';
import {FilePickerHeaderBaseDirective} from '@app/file-picker/file-picker-header-base.directive';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';
import {FilePickerItemService} from '@app/file-picker/file-picker-item.service';
import {FilePickerUpload} from '@app/file-picker/file-picker-upload';
import {AuthService} from '@core/auth/auth.service';
import {DownloadStatus} from '@domain/file/download/download-status';
import {DownloadService} from '@domain/file/download/download.service';
import {FileAuthorService} from '@domain/file/file-author/file-author.service';
import {FileService} from '@domain/file/file/file.service';
import {Direction} from '@domain/pagination/direction.enum';
import {Order} from '@domain/pagination/order';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {SenderService} from '@domain/sender/sender/sender.service';
import {User} from '@domain/user/user';
import {WebdavService} from '@domain/webdav/webdav.service';
import {WINDOW} from '@root/injection-tokens';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {Observable} from 'rxjs';
import {map, mergeMap, switchMap} from 'rxjs/operators';

/**
 * Service responsible for handling file library file picker items
 */
@Injectable({
  providedIn: 'root',
})
export class FileLibraryFilePickerItemService extends FilePickerItemService {

  constructor(
    protected readonly authService: AuthService,
    protected readonly fileService: FileService,
    protected readonly senderService: SenderService,
    private readonly downloadService: DownloadService,
    private readonly notificationService: NotificationService,
    @Inject(WINDOW) private readonly windowService: Window,
    private readonly fileAuthorService: FileAuthorService,
    private readonly webdavService: WebdavService
  ) {
    super();
  }

  createFolder(parent: FileLibraryFilePickerItem, name: string): Observable<FilePickerItem> {
    return this.senderService.createFolder(parent.file.senderId, name, parent.id)
      .pipe(map(file => new FileLibraryFilePickerItem(file)));
  }

  delete(item: FileLibraryFilePickerItem): Observable<FilePickerItem> {
    return this.fileService.delete(item.file.id, {
      context: {
        senderId: item.file.senderId
      }
    }).pipe(map(() => item));
  }

  upload(location: FileLibraryFilePickerItem, files: FileList | File[], cropSettings?: CropSettings): FilePickerUpload {
    const upload = this.fileService.upload(location.file.senderId, files, location.id, cropSettings);
    return {
      uploadEvent: upload,
      itemType: FileLibraryFilePickerItem
    };
  }

  update(location: FileLibraryFilePickerItem, file: File): FilePickerUpload {
    const update = this.fileService.update(location.file.senderId, location.id, file);
    return {
      uploadEvent: update,
      itemType: FileLibraryFilePickerItem
    };
  }

  getComponentType(): Type<FilePickerItemBaseDirective<FilePickerItem>> {
    return FileLibraryFilePickerItemComponent;
  }

  getHeaderComponentType(): Type<FilePickerHeaderBaseDirective<FilePickerItem>> {
    return FileLibraryHeaderComponent;
  }

  getChildren(filePickerItem: FileLibraryFilePickerItem, pageIdx: number): Observable<Page<FilePickerItem>> {
    return this.fileService
      .getChildren(filePickerItem.file.senderId, new Pageable(pageIdx, 20, null, new Order('folder', Direction.Desc), new Order('name')),
        filePickerItem.file).pipe(map(page => ({
        ...page,
        content: page.content.map(file => new FileLibraryFilePickerItem(file))
      })));
  }

  rename(filePickerItem: FileLibraryFilePickerItem, newName: string): Observable<FileLibraryFilePickerItem> {
    return this.fileService.rename(filePickerItem.file, newName, ['*']).pipe(map(file => new FileLibraryFilePickerItem(file)));
  }

  getCapabilities(item: FilePickerItem): FilePickerItemServiceCapability {
    // tslint:disable-next-line:no-bitwise
    return FilePickerItemServiceCapability.RENAME
      | FilePickerItemServiceCapability.CREATE_FILE
      | FilePickerItemServiceCapability.DELETE
      | FilePickerItemServiceCapability.AUTHOR
      | FilePickerItemServiceCapability.MOVE_FILE
      | FilePickerItemServiceCapability.PUBLIC_LINK
      | (OFFICE_EXT_APP_MAP[(item as FileLibraryFilePickerItem).file?.extension]
        ? FilePickerItemServiceCapability.EDIT_WITH_OFFICE
        : FilePickerItemServiceCapability.NONE);
  }

  getResponsibilities(): Type<FilePickerItem>[] {
    return [FileLibraryFilePickerItem];
  }

  download(item: FileLibraryFilePickerItem): void {
    this.downloadService.isDownloadAvailable(item.file).subscribe(status => {
      switch (status) {
        case DownloadStatus.CAN_DOWNLOAD:
          this.windowService.location.href = this.downloadService.createDownloadLink(item.file);
          break;
        case DownloadStatus.PROCESSING:
          this.notificationService.success('NOTIFICATIONS.ZIP_FILE.PROCESSING');
          break;
        case DownloadStatus.ALREADY_REQUESTED:
        case DownloadStatus.EMPTY:
        case DownloadStatus.TOO_LARGE:
          this.notificationService.warning('NOTIFICATIONS.ZIP_FILE.' + status);
          break;
        default:
          this.notificationService.error('NOTIFICATIONS.ZIP_FILE.FAILED');
      }
    });
  }

  getDeepLink(item: FileLibraryFilePickerItem): string {
    return this.fileService.getLink(item.file);
  }

  getAuthors(parent: FileLibraryFilePickerItem, appId: string, childIds: string[]): Observable<{ [key: string]: User }> {
    return this.fileAuthorService.getAuthors(parent.file.senderId, appId, childIds);
  }

  editWithOffice(item: FileLibraryFilePickerItem): Observable<any> {
    return this.webdavService
      .requestAccessToken(item.file.senderId, item.file.id)
      .pipe(
        mergeMap(token => this.webdavService.editFile(token,
          item.file.senderId, item.file.id,
          item.file.name, item.file.extension,
          item.file.subscriptionInfo.token)
        ));
  }

  moveFile(filePickerItem: FileLibraryFilePickerItem, destinationId: string): Observable<FileLibraryFilePickerItem> {
    return this.fileService.moveFile(filePickerItem.file, destinationId)
      .pipe(
        switchMap(
          () => this.fileService.getFile(
            destinationId,
            filePickerItem.file.senderId,
            ['createFile', 'manage']
          )
        ),
        map(destination => new FileLibraryFilePickerItem(destination))
      );
  }

  getUpdatedItem(item: FileLibraryFilePickerItem): Observable<FilePickerItem> {
    const permissions = Object.keys(item.file._permissions ?? {});
    return this.fileService.getFile(item.file.id, item.file.senderId, permissions)
      .pipe(map(file => new FileLibraryFilePickerItem(file)));
  }
}

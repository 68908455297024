<ng-container mat-dialog-float>
  <h3 mat-dialog-title class="dialog-title">{{title}}</h3>
  <mat-dialog-close [attr.aria-label]="'CLOSE' | translate"></mat-dialog-close>
  <form [formGroup]="widgetForm" class="form-horizontal" name="widgetForm" mat-dialog-content *ngIf="config">
    <coyo-widget-settings-container [widget]="widget" [component]="config.settings.component" [parentForm]="widgetForm" [onSubmit]="onSubmitSubject"></coyo-widget-settings-container>
  </form>
  <mat-dialog-actions *ngIf="config">
    <button type="submit" mat-flat-button color="primary" (click)="submit(widgetForm.getRawValue())" [disabled]="widgetForm.invalid">
      <mat-icon svgIcon="check"></mat-icon>{{'SAVE' | translate}}
    </button>
  </mat-dialog-actions>
</ng-container>

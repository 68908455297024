<ng-container *ngIf="(state$ | async) as state">
  <div class="social-actions" data-test="timeline-social-actions">
    <coyo-like-button [senderId]="author.id" [target]="target"
                      *ngIf="target?.socialPermissions?.likesShown && target?._permissions.like"></coyo-like-button>
    <coyo-share-button [target]="target" (shareCreated)="shareCreated.emit($event)"
                       *ngIf="!item.restricted"></coyo-share-button>
    <div class="right-container">
      <coyo-subscribe-button [target]="target" *ngIf="target && !item.restricted"></coyo-subscribe-button>
      <coyo-functional-user-chooser *ngIf="item._permissions?.actAsSender && !target?.socialPermissions?.commentsAndLikesNotAllowed"
                                    [author]="author" [recipientId]="context ? context.id : null"
                                    [timelineItemId]="item.id"
                                    (change)="authorChanged.emit($event)"
                                    headline="MODULE.TIMELINE.FORM.LIKE_COMMENT_AS"
                                    data-test="timeline-footer-functional-user"></coyo-functional-user-chooser>
      <coyo-help data-test="timeline-item-help" tip="MODULE.TIMELINE.ITEM.HELP"
                 *ngIf="target?.socialPermissions?.commentsAndLikesNotAllowed"></coyo-help>
    </div>
  </div>
  <div class="info-container" *ngIf="state.likeState.likingSenderCount || state.shareCount" data-test="info-container">
    <coyo-like-info [senderId]="author.id" [target]="target" [condensed]="isXs$ | async"
                    *ngIf="target?.socialPermissions?.likesShown"></coyo-like-info>
    <coyo-share-info *ngIf="state.shareCount"
                     [target]="target" [count]="state.shareCount"
                     (sharesDeleted)="sharesDeleted.emit($event)"></coyo-share-info>
  </div>
  <!-- tracking information (only if empty, else will be displayed by coyo-like-info) -->
  <div style="display: none"
       *ngIf="!state.likeState.likingSenderCount && !state.shareCount"
       [attr.data-like-target-id]="target.id"
       [attr.data-like-target-type]="target.typeName"
       [attr.data-like-count]="0"></div>
</ng-container>

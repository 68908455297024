import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActiveToast, IndividualConfig, ToastrService} from 'ngx-toastr';

type i18nParams = { [key: string]: any; };

/**
 * Service to display notification toasts.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService,
              private translateService: TranslateService) {
  }

  /**
   * Displays a success notification.
   *
   * @param message the message to display or a i18n key for the message
   * @param title the title to display or a i18n key for the title
   * @param i18n true or a i18n param object to use the translate service for the message and the title
   * @param config a custom configuration for the individual toast
   * @returns a toast reference
   */
  success(message: string, title?: string, i18n: boolean | i18nParams = true, config?: Partial<IndividualConfig>): ActiveToast<any> {
    const [toastTitle, toastMessage] = this.translate(title, message, i18n);
    return this.toastr.success(toastMessage, toastTitle, config);
  }

  /**
   * Displays an info notification.
   *
   * @param message the message to display or a i18n key for the message
   * @param title the title to display or a i18n key for the title
   * @param i18n true or a i18n param object to use the translate service for the message and the title
   * @param config a custom configuration for the individual toast
   * @returns a toast reference
   */
  info(message: string, title?: string, i18n: boolean | i18nParams = true, config?: Partial<IndividualConfig>): ActiveToast<any> {
    const [toastTitle, toastMessage] = this.translate(title, message, i18n);
    return this.toastr.info(toastMessage, toastTitle, config);
  }

  /**
   * Displays a warning notification.
   *
   * @param message the message to display or a i18n key for the message
   * @param title the title to display or a i18n key for the title
   * @param i18n true or a i18n param object to use the translate service for the message and the title
   * @param config a custom configuration for the individual toast
   * @returns a toast reference
   */
  warning(message: string, title?: string, i18n: boolean | i18nParams = true, config?: Partial<IndividualConfig>): ActiveToast<any> {
    const [toastTitle, toastMessage] = this.translate(title, message, i18n);
    return this.toastr.warning(toastMessage, toastTitle, config);
  }

  /**
   * Displays an error notification.
   *
   * @param message the message to display or a i18n key for the message
   * @param title the title to display or a i18n key for the title
   * @param i18n true or a i18n param object to use the translate service for the message and the title
   * @param config a custom configuration for the individual toast
   * @returns a toast reference
   */
  error(message: string, title?: string, i18n: boolean | i18nParams = true, config?: Partial<IndividualConfig>): ActiveToast<any> {
    const [toastTitle, toastMessage] = this.translate(title, message, i18n);
    return this.toastr.error(toastMessage, toastTitle, config);
  }

  /**
   * Clears all toasts.
   */
  clear(): void {
    this.toastr.clear();
  }

  private translate(title: string, message: string, i18n: boolean | i18nParams): [string, string] {
    const params = typeof i18n !== 'boolean' ? i18n : undefined;
    const toastTitle = title && i18n ? this.translateService.instant(title, params) : title;
    const toastMessage = message && i18n ? this.translateService.instant(message, params) : message;
    return [toastTitle, toastMessage];
  }
}

(function (angular) {
  'use strict';

  AdminRegistrationSettingsController.$inject = ["$injector", "SettingsModel", "settings"];
  angular.module('coyo.admin.settings')
      .controller('AdminRegistrationSettingsController', AdminRegistrationSettingsController);

  function AdminRegistrationSettingsController($injector, SettingsModel, settings) {
    var vm = this;
    vm.$onInit = onInit;
    vm.save = save;
    vm.generateAdminGeneralSettingsObject = generateAdminGeneralSettingsObject;
    vm.generateAdminGeneralSettingsObjectHelper = generateAdminGeneralSettingsObjectHelper;
    vm.transferObject = {};

    function onInit() {
      // Load the current settings from db
      return settings.get().then(function (currentSettingsFromBackEnd) {
        settings = currentSettingsFromBackEnd;
        vm.generateAdminGeneralSettingsObject(currentSettingsFromBackEnd);
      });
    }

    function save() {
      return vm.transferObject.update().then(function () {
        // reset settings cache
        SettingsModel.retrieve(true);
        $injector.get('ngxNotificationService').success('ADMIN.SETTINGS.SAVE.SUCCESS');
      });
    }

    /**
     * This method creates a deep copy of settings, but only with the keys that this component really has.
     * The new created copy will be stored in the variable transferObject.
     * Now we can communicate with the backend server via the transferObject variable, and only transfer the data that we need.
     *
     * @param {settings} - settings object, over this object we have a communication to the back-end-server
     */
    function generateAdminGeneralSettingsObject(settings) {
      vm.transferObject = vm.generateAdminGeneralSettingsObjectHelper(_.cloneDeep(settings));
    }

    /**
     * Helper function for generateAdminGeneralSettingsObject.
     * In first step we clear the deep copy variable.
     * In second step we fill the new copy with the necessary keys
     *
     * @param {settingsDeepCopy} - the deep copy object from settings
     * @returns {*}
     */
    function generateAdminGeneralSettingsObjectHelper(settingsDeepCopy) {
      _(settingsDeepCopy).keys().forEach(function (key) {
        delete settingsDeepCopy[key];
      });

      settingsDeepCopy.defaultLanguage = settings.defaultLanguage;
      settingsDeepCopy.defaultTimeZone = settings.defaultTimeZone;
      return settingsDeepCopy;
    }

  }

})(angular);

import {Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {Action, State, StateContext} from '@ngxs/store';
import {patch, removeItem} from '@ngxs/store/operators';
import {Observable} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {Session} from './session';
import {InitSessions, LogoutSession} from './sessions.action';
import {SettingsDevicesService} from './settings-devices.service';

export interface SessionsStateModel {
  sessions: Session[];
}

@State<SessionsStateModel>({
  name: 'settingsDevices',
  defaults: {sessions: []}
})
@Injectable()
export class SettingsDevicesState {
  constructor(private settingsDevicesService: SettingsDevicesService,
              private authService: AuthService) {
  }

  @Action(InitSessions)
  initSession(ctx: StateContext<SessionsStateModel>, action: InitSessions): Observable<Session[]> {
    return this.authService.getUser()
      .pipe(switchMap(user => this.settingsDevicesService.getPushDevices(user)))
      .pipe(tap({next: sessions => ctx.setState({sessions})}));
  }

  @Action(LogoutSession)
  logoutSession(ctx: StateContext<SessionsStateModel>, action: LogoutSession): Observable<void> {
    return this.settingsDevicesService.deletePushDevices(action.userId, action.deviceId)
      .pipe(finalize(() => this.removeSession(ctx, action.userId, action.deviceId)));
  }

  private removeSession(ctx: StateContext<SessionsStateModel>, userId: string, deviceId: string): void {
    ctx.setState(patch({
      sessions: removeItem((session: Session) => session.id === deviceId && session.userId === userId)
    }));
  }
}

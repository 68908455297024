(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name commons.target
   *
   * @description
   * # Target module #
   * The target module solves target URLs and redirects the user to the given target.
   *
   * @requires $stateProvider
   */
  targetsConfig.$inject = ["targetServiceProvider"];
  stateConfig.$inject = ["$stateProvider", "targetConfig"];
  angular
      .module('commons.target', [
        'coyo.base'
      ])
      .config(targetsConfig)
      .config(stateConfig)
      .constant('targetConfig', {
        targetPrefix: '/target',
        templates: {
          main: 'app/commons/target/views/target.html'
        }
      });

  /**
   * Registers the different targets.
   *
   * @param {object} targetServiceProvider
   */
  function targetsConfig(targetServiceProvider) {
    targetServiceProvider.register('user', /*@ngInject*/ ["params", "$state", function (params, $state) {
      return $state.href('main.profile', {userId: params.slug});
    }]);

    targetServiceProvider.register('page', /*@ngInject*/ ["params", "$state", function (params, $state) {
      var stateParams = {idOrSlug: params.slug || params.id};
      return $state.href('main.page.show', stateParams);
    }]);

    targetServiceProvider.register('workspace', /*@ngInject*/ ["params", "$state", function (params, $state) {
      var stateParams = {idOrSlug: params.slug || params.id};
      return $state.href('main.workspace.show', stateParams);
    }]);

    targetServiceProvider.register('app', /*@ngInject*/ ["params", "appService", function (params, appService) {
      return appService.getAppLinkForCreatedApp({
        id: params.senderId,
        slug: params.senderSlug,
        typeName: params.senderType
      }, {
        id: params.id,
        slug: params.slug,
        key: params.key
      });
    }]);

    targetServiceProvider.register('timeline_item', /*@ngInject*/ ["params", "$state", function (params, $state) {
      return $state.href('main.timeline-item', params);
    }]);

    targetServiceProvider.register('message-channel', /*@ngInject*/ ["params", "messagingService", function (params, messagingService) {
      return messagingService.open(params.id);
    }]);

    targetServiceProvider.register('email_activation', /*@ngInject*/ ["params", "$state", function (params, $state) {
      return $state.go('main.account-email-activation', params);
    }]);

    targetServiceProvider.register('landing-page', /*@ngInject*/ ["params", "$state", function (params, $state) {
      return $state.href('main.landing-page.show', {idOrSlug: params.slug});
    }]);

    targetServiceProvider.register('file', /*@ngInject*/ ["params", "fileDetailsModalService", "FileModel", function (params, fileDetailsModalService, FileModel) {
      return FileModel.get({
        id: params.id,
        senderId: params.senderId
      }).then(function (file) {
        return fileDetailsModalService.open([file], 0, true);
      });
    }]);
  }

  /**
   * State configuration.
   *
   * @param {object} $stateProvider
   * @param {object} targetConfig
   */
  function stateConfig($stateProvider, targetConfig) {
    $stateProvider.state('main.target', {
      url: targetConfig.targetPrefix + '/:targetType',
      params: {
        id: null,
        slug: null
      },
      templateUrl: targetConfig.templates.main,
      controller: 'TargetController',
      controllerAs: 'vm'
    });
  }

})(angular);

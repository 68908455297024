(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .filter('cleanPhoneNumber', cleanPhoneNumber);

  /**
   * @ngdoc filter
   * @name commons.ui:cleanPhoneNumber
   * @function
   *
   * @description
   * Filter that removes formatting signs from a phone number.
   *
   * @param {string} phoneNumber
   * The phone number to convert
   */
  function cleanPhoneNumber() {
    return function (phoneNumber) {
      return phoneNumber ? String(phoneNumber)
          .replace(/\(\s*0\s*\)/g, '')
          .replace(/[().\s-/A-Za-z]/g, '') : phoneNumber;
    };
  }

})(angular);

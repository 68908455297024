import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {Skeleton} from '@coyo/ui/lib/ui/skeleton/skeleton'; // TODO
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {LatestWikiArticlesWidget} from '@widgets/latest-wiki-articles/latest-wiki-articles-widget';
import {Load} from '@widgets/latest-wiki-articles/latest-wiki-articles-widget/latest-wiki-articles-widget.actions';
import {LatestWikiArticlesWidgetStateModel} from '@widgets/latest-wiki-articles/latest-wiki-articles-widget/latest-wiki-articles-widget.state';
import {Reset} from '@widgets/new-colleagues/new-colleagues-widget/new-colleagues.actions';
import * as _ from 'lodash';
import {Observable} from 'rxjs';

/**
 * The latest-wiki-articles widget component.
 */
@Component({
  selector: 'coyo-latest-wiki-articles-widget',
  templateUrl: './latest-wiki-articles-widget.component.html',
  styleUrls: ['./latest-wiki-articles-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestWikiArticlesWidgetComponent extends WidgetComponent<LatestWikiArticlesWidget>
  implements OnInit, OnChanges, OnDestroy {
  state$: Observable<LatestWikiArticlesWidgetStateModel>;
  skeletons: Skeleton[] = [
    {top: 1.75, left: 0, width: 200, height: 19},
    {top: 25.25, left: 25, width: 330, height: 15},
  ];
  private id: string;

  constructor(cd: ChangeDetectorRef, private store: Store) {
    super(cd);
  }

  ngOnInit(): void {
    this.id = this.widget.id || this.widget.tempId;
    this.state$ = this.store.select(state => state.latestWikiArticles[this.id]);
    this.store.dispatch(new Load(this.id, this.widget.settings));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const previousSettings = _.get(changes.widget, 'previousValue.settings');
    const currentSettings = _.get(changes.widget, 'currentValue.settings');
    if (!!currentSettings &&
      (!previousSettings ||
        currentSettings._appId !== previousSettings._appId ||
        currentSettings._senderId !== previousSettings._senderId ||
        currentSettings._articleCount !== previousSettings._articleCount
      )) {
      this.store.dispatch(new Load(this.id, this.widget.settings));
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new Reset(this.widget.id));
  }

  /**
   * @returns The title stored in the widget settings by the widget slot (if it exists)
   */
  getTitle(): string | undefined {
    return _.get(this.widget, 'settings._title');
  }

  /**
   * @returns An array with the length of the max. number or articles to be displayed according to the widget settings
   */
  getNeededSkeletons(): number[] {
    return new Array(this.widget.settings._articleCount).map((x, i) => i);
  }
}

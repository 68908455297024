(function (angular) {
  'use strict';

  messageChannelUserService.$inject = ["$http", "coyoEndpoints"];
  angular
      .module('coyo.messaging')
      .factory('messageChannelUserService', messageChannelUserService);

  function messageChannelUserService($http, coyoEndpoints) {

    return {
      fetchDisabledUsers: fetchDisabledUsers
    };

    function fetchDisabledUsers(channelId) {
      return $http({
        url: coyoEndpoints.messaging.users.replace('{{channelId}}', channelId),
        method: 'GET',
        params: {
          includeMembersWithMessagingPermission: false,
          includeDeletedMembers: true
        }
      });
    }
  }
})(angular);

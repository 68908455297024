import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {TimeProviderService} from '@core/time-provider/time-provider.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * Transforms a timestamp to a blog article publish date.
 *
 * Format small today: 12:00 PM
 * Format small older: Nov 12, 2020
 * Format large today: Today, 12:00 PM
 * Format large older: 12 November 2020
 *
 */
@Pipe({
  name: 'publishDate'
})
export class PublishDatePipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe,
    private timeProvider: TimeProviderService,
    private translateService: TranslateService
  ) {
  }

  transform(value: number, size: 'small' | 'large' = 'small'): string | null {
    const date = new Date(value);
    const today = this.timeProvider.getCurrentDate();
    const isToday = date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    if (isToday) {
      const time = this.datePipe.transform(value, 'shortTime');
      return size === 'large' ? this.translateService.instant('TIME_AGO.TODAY', {time}) : time;
    } else {
      const format = size === 'large' ? 'longDate' : 'mediumDate';
      return this.datePipe.transform(value, format);
    }
  }

}

<div class="blog-widget">
  <h3 class="widget-title">
    {{getTitle() || ("WIDGETS.BLOG.NAME" | translate)}}
  </h3>

  <ng-container *ngIf="(state$ | async) as state">
    <div *ngIf="!state.loading && !state.hasArticles" translate="WIDGETS.BLOG.NO_ARTICLES"></div>
    <ol>
      <li *ngFor="let article of state.articles">
        <div class="blog-article">
          <div class="image-wrapper" *ngIf="widget.settings._showTeaserImage && article.teaserImageWideFileId">
            <a href="{{state.links.articles[article.articleTarget.params.id]}}"
               aria-hidden="true"
               tabindex="-1">
              <coyo-internal-image
                [fileId]="article.teaserImageWideFileId"
                [senderId]="article.teaserImageWideSenderId"
                size="XL">
              </coyo-internal-image>
            </a>
          </div>
          <div class="title-wrapper">
            <div class="author-time-wrapper">
              <a href="{{state.links.apps[article.appTarget.params.id]}}" class="author">{{article.senderName}}</a>
              <div class="date-large">{{article.publishDate | publishDate: 'large'}}</div>
              <div class="date-small">{{article.publishDate | publishDate: 'small'}}</div>
            </div>
            <a href="{{state.links.articles[article.articleTarget.params.id]}}"
               class="title"
               [attr.aria-label]="'WIDGETS.BLOG.ARTICLE.LINK.ARIA' | translate: {title: article.title}">
              {{article.title}}
            </a>
          </div>
        </div>
      </li>
    </ol>
    <ol *ngIf="state.loading">
      <li *ngFor="let number of getNeededSkeletons()">
        <div class="blog-article skeleton-lg">
          <ui-skeleton [skeletons]="skeletonsLg"></ui-skeleton>
        </div>
        <div class="blog-article skeleton-sm">
          <ui-skeleton [skeletons]="skeletonsSm"></ui-skeleton>
        </div>
      </li>
    </ol>
  </ng-container>
</div>

import {ChangeDetectionStrategy, Component, OnChanges, SimpleChanges} from '@angular/core';
import {FilePickerHeaderBaseDirective} from '@app/file-picker/file-picker-header-base.directive';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerStateSelectors} from '@app/file-picker/file-picker-state/file-picker.state.selectors';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';

/**
 * Table header component for file library items.
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tr[coyo-file-library-header]',
  templateUrl: './file-library-header.component.html',
  styleUrls: ['./file-library-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryHeaderComponent extends FilePickerHeaderBaseDirective<FilePickerItem> implements OnChanges {

  isDownload$: Observable<boolean>;

  constructor(private store: Store) {
    super();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.filePickerId?.currentValue) {
      this.isDownload$ = this.store.select(FilePickerStateSelectors.isDownloadMode(this.filePickerId));
    }
  }
}

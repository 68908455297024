(function (angular) {
  'use strict';

  WikiArticleCreateController.$inject = ["$q", "$scope", "$state", "$timeout", "currentUser", "article", "app", "sender", "$injector", "wikiArticleTranslationService"];
  angular
      .module('coyo.apps.wiki')
      .controller('WikiArticleCreateController', WikiArticleCreateController);

  /**
   * Controller for creating a wiki article.
   *
   * @constructor
   */
  function WikiArticleCreateController($q, $scope, $state, $timeout, currentUser, article, app,
                                       sender, $injector, wikiArticleTranslationService) {
    var vm = this;

    vm.$onInit = init;
    vm.article = article;
    vm.app = app;
    vm.sender = sender;
    vm.originalArticle = angular.copy(vm.article);
    vm.editMode = true;
    vm.loading = true;
    vm.simpleMode = true;

    vm.cancel = cancel;
    vm.save = save;
    vm.onLanguageChange = _onLanguageChange;
    vm.onLanguageDeleted = _onLanguageDeleted;
    vm.updateValidity = _updateValidity;
    vm.isTranslationRequired = _isTranslationRequired;
    vm.getLayoutLanguageKey = getLayoutLanguageKey;

    function cancel() {
      $injector.get('ngxWidgetEditService').cancelEdit(vm.app.id);
      vm.loading = false;
      vm.editMode = false;

      $state.go('^');
    }

    function save() {
      wikiArticleTranslationService.prepareTranslations(vm);

      var deferred = $q.defer();
      if (vm.loading) {
        deferred.resolve();
      } else {
        vm.loading = true;

        if (article.id === article.parentId) {
          article.parentId = null;
        }

        vm.article.create().then(function () {
          $timeout(function () { // wait to sync id to widget layout and slots
            $injector.get('ngxWidgetEditService').save(vm.app.id).toPromise().then(function () {
              vm.editMode = false;
              $state.go('^.view', {id: vm.article.id, currentLanguage: vm.defaultLanguage}).then(function () {
                deferred.resolve();
              });
            }).catch(function () {
              $injector.get('ngxWidgetEditService').enableEditMode(vm.app.id);
              deferred.reject();
            }).finally(function () {
              vm.loading = false;
            });
          });
        }).catch(function () {
          deferred.reject();
          vm.loading = false;
        });
      }

      return deferred.promise;
    }

    function getLayoutLanguageKey(lang) {
      if (!!lang && !!sender.defaultLanguage && lang !== 'NONE' && sender.defaultLanguage !== lang) {
        return lang;
      }
      return null;
    }

    /* ===== PRIVATE METHODS ===== */

    function init() {
      $injector.get('ngxWidgetEditService').enableEditMode(vm.app.id);
      vm.loading = false;
      var parent = {id: vm.app.id, typeName: 'app'};
      var layoutName = vm.article.buildLayoutName(vm.app.id, 0);
      $injector.get('ngxWidgetEditService')
          .initializeEmptyLayoutForLanguage(vm.app.id, layoutName, vm.getLayoutLanguageKey(vm.currentLanguage), parent);

      wikiArticleTranslationService.initLanguages(vm, currentUser).then(function () {
        vm.languagesInitialized = true;
      });
      $scope.$on('$destroy', function () {
        $injector.get('ngxWidgetEditService').cancelEdit(vm.app.id);
      });
    }

    function _updateValidity(key, value) {
      wikiArticleTranslationService.updateValidity(vm, key, value);
    }

    function _isTranslationRequired(language) {
      return wikiArticleTranslationService.isTranslationRequired(vm, language);
    }

    function _onLanguageDeleted(language) {
      return wikiArticleTranslationService.onLanguageDeleted($scope, vm, language);
    }

    function _onLanguageChange(copyFromDefault) {
      wikiArticleTranslationService.onLanguageChange(vm, copyFromDefault);
    }
  }

})(angular);

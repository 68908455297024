import {Component} from '@angular/core';
import {WidgetComponent} from '@widgets/api/widget-component';
import {HtmlWidget} from '@widgets/html/html-widget';

@Component({
  selector: 'coyo-html-widget',
  templateUrl: './html-widget.component.html',
  styleUrls: ['./html-widget.component.scss']
})
export class HtmlWidgetComponent extends WidgetComponent<HtmlWidget> {
}

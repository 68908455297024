import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FROALA_EDITOR} from '@root/injection-tokens';
import {RtePlugin} from '@shared/rte/rte-plugin';
import {RteSettings} from '@shared/rte/rte/rte-settings/rte-settings';
import * as _ from 'lodash';

/**
 * Service providing the anchor plugin for the froala rte
 */
@Injectable()
export class AnchorPluginService extends RtePlugin {

  private static readonly ICONS: { [key: string]: string; } = {
    // tslint:disable:max-line-length
    coyoAnchorIcon: `
      <path d="M19.39 13.08a1 1 0 0 0-1.31.53 6.91 6.91 0 0 1-5.08 4.3V13h1a1 1 0 0 0 0-2h-1V9.82a3 3 0 1 0-2 0V11h-1a1 1 0 0 0 0 2h1v4.91a6.72 6.72 0 0 1-5.08-4.3 1 1 0 1 0-1.84.78A8.65 8.65 0 0 0 12 20a8.7 8.7 0 0 0 7.92-5.61 1 1 0 0 0-.53-1.31zM12 6a1 1 0 1 1-1 1 1 1 0 0 1 1-1z"/>`,
    coyoAnchorRemoveIcon: `
      <path d="M14.8 6c-.5-1.6-2.3-2.4-3.8-1.8-1.1.3-1.8 1.3-2 2.4l4 4v-.8c1.6-.5 2.4-2.3 1.8-3.8zM12 8c-.5 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.5 1-1 1zm2 3h-.6l1.5 1.5c.1-.2.1-.3.1-.5 0-.5-.5-1-1-1zm2 5.5c-.9.7-1.9 1.2-3 1.4v-4.5L10.6 11H10c-.5 0-1 .5-1 1s.5 1 1 1h1v4.9c-2.3-.4-4.3-2.1-5.1-4.3-.2-.5-.8-.8-1.3-.6-.5.2-.8.8-.6 1.3v.1c1.2 3.3 4.4 5.5 7.9 5.6 2 0 4-.8 5.5-2.1L16 16.5zm3.4-3.4c-.5-.2-1.1 0-1.3.5-.2.5-.4.9-.7 1.4l1.5 1.4c.5-.6.8-1.3 1.1-2 .1-.5-.1-1.1-.6-1.3z"/>
      <path d="M19.7 20.7c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-14-14c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l14 14c.4.4.4 1 0 1.4z"/>`
    // tslint:enable:max-line-length
  };

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(FROALA_EDITOR) private froala: any,
              private translateService: TranslateService) {
    super();
  }

  protected doInitialize(settings: RteSettings): void {
    const plugin = this;

    this.registerIcons();

    Object.assign(this.froala.POPUP_TEMPLATES, {
      'coyoAnchorPlugin.insertLinkPopup': '[_CUSTOM_LAYER_]',
      'coyoAnchorPlugin.insertAnchorPopup': '[_CUSTOM_LAYER_]',
      'coyoAnchorPlugin.editToolbarAnchorPopup': '[_BUTTONS_]',
      'coyoAnchorPlugin.editAnchorPopup': '[_CUSTOM_LAYER_]'
    });

    this.froala.PLUGINS.coyoAnchorPlugin = function(editor: any): unknown {
      return {
        _init: () => {
          editor.events.on('keyup', (event: any) => plugin.editSelectedAnchor(editor, event));
          editor.events.on('window.mouseup', (event: any) => plugin.editSelectedAnchor(editor, event));
          editor.events.on('click', (event: any) => {
            if (event.target.tagName.toLowerCase() === 'a') {
              event.preventDefault();
            }
          });
          if (editor.helpers.isMobile()) {
            editor.events.on('selectionchange', (event: any) => plugin.editSelectedAnchor(editor, event));
          }
        }
      };
    };

    this.froala.RegisterCommand('coyoInsertAnchor', {
      title: this.translateService.instant('RTE.ANCHORS'),
      icon: 'coyoAnchorIcon',
      plugin: 'coyoAnchorPlugin',
      type: 'dropdown',
      options: {
        coyoInsertAnchor: this.translateService.instant('RTE.ANCHORS.INSERT_ANCHOR'),
        coyoInsertAnchorLink: this.translateService.instant('RTE.ANCHORS.INSERT_LINK')
      },
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(cmd: any, val: string): void {
        switch (val) {
          case 'coyoInsertAnchor':
            plugin.showInsertAnchorPopup(this);
            break;
          case 'coyoInsertAnchorSubmit':
            plugin.submitInsertAnchorPopup(this);
            break;
          case 'coyoInsertAnchorLink':
            plugin.showInsertAnchorLinkPopup(this);
            break;
          case 'coyoInsertAnchorLinkSubmit':
            plugin.submitInsertAnchorLinkPopup(this);
            break;
        }
      }
    });

    this.froala.RegisterCommand('coyoInsertAnchorImage', {
      title: this.translateService.instant('RTE.ANCHORS.INSERT_ANCHOR'),
      icon: 'coyoAnchorIcon',
      plugin: 'coyoAnchorPlugin',
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        plugin.showInsertAnchorPopup(this);
      }
    });

    this.froala.RegisterCommand('coyoEditAnchor', {
      title: this.translateService.instant('RTE.ANCHORS.EDIT_ANCHOR'),
      icon: 'linkEdit',
      plugin: 'coyoAnchorPlugin',
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(cmd: string, val: string): void {
        if (val === 'coyoEditAnchorSubmit') {
          plugin.submitEditAnchorPopup(this);
        } else {
          plugin.showEditAnchorPopup(this);
        }
      }
    });

    this.froala.RegisterCommand('coyoRemoveAnchor', {
      title: this.translateService.instant('RTE.ANCHORS.REMOVE_ANCHOR'),
      icon: 'coyoAnchorRemoveIcon',
      plugin: 'coyoAnchorPlugin',
      undo: true,
      focus: false,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function(): void {
        this.link.remove();
        this.popups.hide('coyoAnchorPlugin.editToolbarAnchorPopup');
      }
    });
  }

  private registerIcons(): void {
    _.forEach(AnchorPluginService.ICONS, (svg, name) => {
      const path = svg.replace(/^\s*<path d="/g, '').replace(/"\/>$/g, '');
      this.froala.DefineIcon(name, {PATH: path});
    });
  }

  /*
   * ===== Insert Anchor Popup
   */

  private showInsertAnchorPopup(editor: any): void {
    const $popup = editor.popups.get('coyoAnchorPlugin.insertAnchorPopup');
    if ($popup) {
      $popup.remove();
    }

    this.initInsertAnchorPopup(editor, editor.selection.text().replace(/\n/g, ''));
    if (!editor.popups.isVisible('coyoAnchorPlugin.insertAnchorPopup')) {
      editor.popups.refresh('coyoAnchorPlugin.insertAnchorPopup');
      editor.selection.save();
    }

    editor.popups.setContainer('coyoAnchorPlugin.insertAnchorPopup', editor.$tb);

    let $imgRef = editor.image ? editor.image.getEl() : null;
    if ($imgRef && editor.image.hasCaption()) {
      $imgRef = $imgRef.find('.fr-img-wrap');
    }
    const $ref = $imgRef || editor.$tb.find('.fr-command[data-cmd="coyoInsertAnchor"]');
    const left = $imgRef
      ? $ref.offset().left + $ref.outerWidth() / 2
      : $ref.offset().left;
    const top = $imgRef
      ? $ref.offset().top + $ref.outerHeight()
      : $ref.offset().top + (editor.opts.toolbarBottom ? 10 : $ref.outerHeight() - 10);

    editor.popups.show('coyoAnchorPlugin.insertAnchorPopup', left, top, $ref.outerHeight(), !!$imgRef);
  }

  private initInsertAnchorPopup(editor: any, text: string): any {
    const $image = editor.image.get();
    const hasImage = $image && $image.length;
    const imageId = hasImage ? $image.attr('id') || '' : '';

    const anchorTextInput = hasImage ? '' :
      `<div class="fr-input-line">
         <input type="text" name="anchorText" tabindex="2"
                placeholder="${this.translateService.instant('RTE.ANCHORS.TEXT')}" value="${text}">
       </div>`;
    const custom_layer =
      `<div class="fr-layer fr-active">
         <div class="fr-input-line">
           <input type="text" name="anchorName" tabindex="1" value="${imageId}" maxlength="32"
                  placeholder="${this.translateService.instant('RTE.ANCHORS.NAME')}">
         </div>
         ${anchorTextInput}
         <div class="fr-action-buttons">
           <button class="fr-command fr-submit" role="button" data-cmd="coyoInsertAnchor" data-param1="coyoInsertAnchorSubmit"
                   href="#" tabindex="${hasImage ? 2 : 3}" type="button">${this.translateService.instant('RTE.ANCHORS.INSERT')}</button>
         </div>
       </div>`;

    return editor.popups.create('coyoAnchorPlugin.insertAnchorPopup', {custom_layer});
  }

  private submitInsertAnchorPopup(editor: any): void {
    const $popup = editor.popups.get('coyoAnchorPlugin.insertAnchorPopup');
    const $current_image = editor.image.get();

    const inputs = $popup.find('input[type="text"]');
    const nameInput = inputs.filter('[name="anchorName"]');
    const textInput = inputs.filter('[name="anchorText"]');
    const name = this.sanitizeId(nameInput.val() || '').trim() || '';

    const existingIds = this.textQuerySelectorAll(editor.html.get(), `*[id="${name}"]`);
    if (!name || existingIds.length) {
      nameInput.addClass('has-error');
      return;
    }

    const existingAnchors = this.textQuerySelectorAll(editor.selection.get().anchorNode, '*.fr-anchor[id]');
    if (existingAnchors.length) {
      existingAnchors.forEach((node: Element) => node.setAttribute('id', name));
    } else if (!$current_image || !$current_image.length) {
      const text = (textInput.val() || '').trim() || name;
      const selection = editor.selection.text().replace(/\n/g, '');
      if (editor.selection.get().isCollapsed) {
        const content = this.froala.START_MARKER + text.replace(/&/g, '&amp;').replace(/</, '&lt;').replace(/>/, '&gt;') + this.froala.END_MARKER;
        editor.html.insert(`<a id="${name}" name="${name}" class="fr-anchor">${content || name}</a>`);
        editor.selection.restore();
      } else if (text && this.sanitizeId(text) !== selection) {
        const content = this.froala.START_MARKER + text.replace(/&/g, '&amp;') + this.froala.END_MARKER;
        editor.selection.remove();
        editor.html.insert(`<a id="${name}" name="${name}" class="fr-anchor">${content}</a>`);
        editor.selection.restore();
      } else {
        editor.format.apply('a', {
          id: name,
          name: name,
          class: 'fr-anchor'
        });
      }
    } else {
      $current_image.attr('id', name);
      $current_image.addClass('fr-anchor');
    }

    editor.popups.hide('coyoAnchorPlugin.insertAnchorPopup');
  }

  /*
   * ===== Insert Anchor Link Popup
   */

  private showInsertAnchorLinkPopup(editor: any): void {
    const $popup = editor.popups.get('coyoAnchorPlugin.insertLinkPopup');
    if ($popup) {
      $popup.remove();
    }

    this.initInsertAnchorLinkPopup(editor, editor.selection.text().replace(/\n/g, ''));

    editor.popups.setContainer('coyoAnchorPlugin.insertLinkPopup', editor.$tb);

    const $btn = editor.$tb.find('.fr-command[data-cmd="coyoInsertAnchor"]');
    const left = $btn.offset().left + $btn.outerWidth() / 2;
    const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('coyoAnchorPlugin.insertLinkPopup', left, top, $btn.outerHeight());
  }

  private initInsertAnchorLinkPopup(editor: any, text: string): any {
    const anchors = this.document.querySelectorAll('.fr-anchor');
    const options = Array.from(anchors).map(anchor => `<option>${anchor.getAttribute('id')}</option>`);
    const custom_layer =
      `<div class="fr-layer fr-active">
         <div class="fr-input-line">
           <select id="anchorId" name="anchorId" tabindex="1">${options.join()}</select>
           <label for="anchorId">${this.translateService.instant('RTE.ANCHORS.SELECT')}</label>
         </div>
         <div class="fr-input-line">
           <input type="text" id="anchorText" name="anchorText" tabindex="2"
                  placeholder="${this.translateService.instant('RTE.ANCHORS.TEXT')}" value="${text}">
         </div>
         <div class="fr-action-buttons">
           <button class="fr-command fr-submit" role="button" data-cmd="coyoInsertAnchor" data-param1="coyoInsertAnchorLinkSubmit"
                   href="#" tabindex="3" type="button">${this.translateService.instant('RTE.ANCHORS.INSERT')}</button>
         </div>
       </div>`;

    return editor.popups.create('coyoAnchorPlugin.insertLinkPopup', {custom_layer});
  }

  private submitInsertAnchorLinkPopup(editor: any): void {
    const $popup = editor.popups.get('coyoAnchorPlugin.insertLinkPopup');

    const nameInput = $popup.find('select');
    const textInput = $popup.find('#anchorText');
    const name = this.sanitizeId(nameInput.val() || '').trim() || '';
    const text = (textInput.val() || '').trim() || name;

    const selection = editor.selection.text().replace(/\n/g, '');
    if (editor.selection.get().isCollapsed) {
      const content = this.froala.START_MARKER + text.replace(/&/g, '&amp;').replace(/</, '&lt;').replace(/>/, '&gt;') + this.froala.END_MARKER;
      editor.html.insert(`<a href="#${name}">${content || name}</a>`);
      editor.selection.element().setAttribute('coyo-anchor', '');
      editor.selection.restore();
    } else if (text && this.sanitizeId(text) !== selection) {
      const content = this.froala.START_MARKER + text.replace(/&/g, '&amp;') + this.froala.END_MARKER;
      editor.selection.remove();
      editor.html.insert(`<a href="#${name}">${content}</a>`);
      editor.selection.element().setAttribute('coyo-anchor', '');
      editor.selection.restore();
    } else {
      editor.format.apply('a', {
        'href': '#' + name,
        'coyo-anchor': ''
      });
    }

    editor.popups.hide('coyoAnchorPlugin.insertLinkPopup');
  }

  /*
   * ===== Edit Anchor Popup (editing popup)
   */

  private showEditAnchorPopup(editor: any): void {
    const $popup = editor.popups.get('coyoAnchorPlugin.editAnchorPopup');
    if ($popup) {
      $popup.remove();
    }

    const anchor = this.getSelectedAnchor(editor);
    this.initEditAnchorPopup(editor, anchor.getAttribute('id'));

    if (!editor.popups.isVisible('coyoAnchorPlugin.editAnchorPopup')) {
      editor.popups.refresh('coyoAnchorPlugin.editAnchorPopup');
      editor.selection.save();
    }

    editor.popups.setContainer('coyoAnchorPlugin.editAnchorPopup', editor.$sc);

    const {top, left} = this.getCoordinates(anchor);
    editor.popups.show('coyoAnchorPlugin.editAnchorPopup', left, top, anchor.offsetHeight, true);
  }

  private initEditAnchorPopup(editor: any, id: string): any {
    const custom_layer =
      `<div class="fr-layer fr-active">
         <div class="fr-input-line">
           <input type="text" name="anchorName" value="${id}" tabindex="1" maxlength="20"
                  placeholder="${this.translateService.instant('RTE.ANCHORS.NAME')}">
         </div>
         <div class="fr-action-buttons">
           <button class="fr-command fr-submit" role="button" data-cmd="coyoEditAnchor" data-param1="coyoEditAnchorSubmit"
                   href="#" tabindex="2" type="button">${this.translateService.instant('RTE.ANCHORS.UPDATE')}</button>
         </div>
       </div>`;

    return editor.popups.create('coyoAnchorPlugin.editAnchorPopup', {custom_layer});
  }

  private submitEditAnchorPopup(editor: any): void {
    if (editor.selection.get().isCollapsed) {
      editor.selection.restore();
    }
    const $popup = editor.popups.get('coyoAnchorPlugin.editAnchorPopup');
    const text_inputs = $popup.find('input[type="text"]');
    const name_input = text_inputs.filter('[name="anchorName"]');
    const name = this.sanitizeId((name_input.val() || '').trim());
    const anchor = this.getSelectedAnchor(editor);
    const existingId = this.textQuerySelectorAll(editor.html.get(), `*.fr-anchor[id="${name}"]`);

    if (existingId.length > 0) {
      editor.selection.save();
      name_input.addClass('has-error');
      return;
    }

    if (anchor && name) {
      anchor.setAttribute('id', name);
    } else if (anchor) {
      editor.link.remove();
    }

    editor.popups.hide('coyoAnchorPlugin.editAnchorPopup');
  }

  /*
   * ===== Edit Anchor Popup (button popup)
   */

  private showEditAnchorToolbarPopup(editor: any, anchor: HTMLElement): void {
    const $popup = editor.popups.get('coyoAnchorPlugin.editToolbarAnchorPopup');
    if (!$popup) {
      this.initEditToolbarPopup(editor);
    }

    editor.popups.setContainer('coyoAnchorPlugin.editToolbarAnchorPopup', editor.$sc);

    const {top, left} = this.getCoordinates(anchor);
    editor.popups.show('coyoAnchorPlugin.editToolbarAnchorPopup', left, top, anchor.offsetHeight, true);
  }

  private initEditToolbarPopup(editor: any): any {
    const buttons = `<div class="fr-buttons">${editor.button.buildList(['coyoEditAnchor', 'coyoRemoveAnchor'])}</div>`;
    return editor.popups.create('coyoAnchorPlugin.editToolbarAnchorPopup', {buttons});
  }

  /*
   * ===== Helpers
   */

  private sanitizeId(id: string): string {
    return (id || '').toString().toLowerCase()
      .replace(/\s+/g, '-')     // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-')   // Replace multiple - with single -
      .trim();
  }

  private getSelectedAnchor(editor: any): HTMLElement | null {
    const element = editor.selection.element();
    return _.get(element, 'id') && element.classList.contains('fr-anchor') ? element : null;
  }

  private editSelectedAnchor(editor: any, event: any): void {
    if (editor.core.hasFocus() && event.which !== this.froala.KEYCODE.ESC) {
      setTimeout(() => {
        const anchor = this.getSelectedAnchor(editor);
        if (anchor) {
          this.showEditAnchorToolbarPopup(editor, anchor);
        }
      }, editor.helpers.isIOS() ? 100 : 0);
    }
  }

  private getCoordinates(elem: HTMLElement): {top: number, left: number} {
    const rect = elem.getBoundingClientRect();
    const bodyScrollTop = this.document.documentElement.scrollTop;
    const bodyScrollLeft = this.document.documentElement.scrollLeft;
    const left = rect.left + bodyScrollLeft + elem.offsetWidth / 2;
    const top = rect.top + bodyScrollTop + elem.offsetHeight;
    return {top, left};
  }

  private textQuerySelectorAll(html: string, selector: string): NodeListOf<Element> {
    const elem = this.document.createElement('div');
    elem.innerHTML = html;
    return elem.querySelectorAll(selector);
  }
}

<ng-container *ngIf="asyncErrors | async; let errors">
  <div class="panel">
    <div class="panel-body" id="password-reset">
      <form (ngSubmit)="onSubmit()" [formGroup]="changePwForm" role="form"
            [class.password-confirm-error]="changePwForm.errors?.allEqual && changePwForm.get('newPasswordConfirm').dirty"
            [class.same-password-error]="changePwForm.errors?.notEqual && changePwForm.get('newPassword').dirty">
        <h2>{{'PASSWORD.CHANGE.TITLE' | translate}}</h2>
        <div class="form-group">
          <div class="input-wrapper">
            <input class="form-control"
                   formControlName="oldPassword"
                   id="oldPassword"
                   placeholder="{{'PASSWORD.CHANGE.OLD_PASSWORD.PLACEHOLDER' | translate }}"
                   type="password">
            <i class="zmdi zmdi-key"></i>
          </div>
          <small *ngIf="errors.error"
               class="text-muted"
               [translate]="errors.error"></small>
        </div>
        <div class="form-group">
          <div class="input-wrapper">
            <input class="form-control"
                   formControlName="newPassword"
                   id="password"
                   placeholder="{{'PASSWORD.CHANGE.NEW_PASSWORD.PLACEHOLDER' | translate }}"
                   type="password">
            <i class="zmdi zmdi-key"></i>
          </div>
          <small *ngIf="changePwForm.dirty && changePwForm.hasError('notEqual')"
               class="text-muted"
               translate="PASSWORD.CHANGE.ERRORS.OLD_EQUALS_NEW"></small>
          <small *ngIf="changePwForm.dirty && changePwForm.get('newPassword').hasError('pattern')"
                 class="text-muted"
                 translate="ERRORS.VALIDATION.INVALID_PASSWORD"></small>

        </div>
        <div class="form-group">
          <div class="input-wrapper">
            <input class="form-control"
                   formControlName="newPasswordConfirm"
                   id="passwordConfirm"
                   placeholder="{{'PASSWORD.CHANGE.CONFIRM_PASSWORD.PLACEHOLDER' | translate }}"
                   type="password">
            <i class="zmdi zmdi-key"></i>
          </div>
          <small *ngIf="changePwForm.get('newPasswordConfirm').dirty && changePwForm.hasError('allEqual')"
               class="text-muted"
               translate="MODULE.SETUP.FORM.PASSWORD.MISMATCH"></small>
        </div>
        <div class="form-actions">
          <button (click)="cancel()" mat-flat-button type="button">
            <span translate="CANCEL"></span>
          </button>
          <button [disabled]="changePwForm.invalid" color="primary" mat-flat-button type="submit">
            <span translate="PASSWORD.CHANGE.SUBMIT"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

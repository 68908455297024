import {NgModule} from '@angular/core';
import {CoyoCommonsModule} from '@shared/commons/commons.module';
import {TimeAgoComponent} from '@shared/time/time-ago/time-ago.component';
import './time-ago/time-ago.component.downgrade';
import {TimeAgoPipe} from './time-ago/time-ago.pipe';
import './time/time.service.downgrade';

/**
 * Module for date and time functionalities.
 */
@NgModule({
  imports: [
    CoyoCommonsModule
  ],
  declarations: [
    /* tslint:disable-next-line:deprecation */
    TimeAgoComponent,
    TimeAgoPipe
  ],
  exports: [
    /* tslint:disable-next-line:deprecation */
    TimeAgoComponent,
    TimeAgoPipe
  ],
  entryComponents: [
    /* tslint:disable-next-line:deprecation */
    TimeAgoComponent
  ]
})
export class TimeModule {}

(function (angular) {
  'use strict';

  LandingPageController.$inject = ["landingPages", "landingPage", "$injector"];
  angular
      .module('coyo.landing-pages')
      .controller('LandingPageController', LandingPageController);

  function LandingPageController(landingPages, landingPage, $injector) {
    var vm = this;

    vm.landingPages = landingPages;
    vm.landingPage = landingPage;

    vm.edit = edit;
    vm.save = save;
    vm.cancel = cancel;
    vm.isOnlyActiveOnMobile = isOnlyActiveOnMobile;
    vm.isInactive = isInactive;

    function edit() {
      $injector.get('ngxWidgetEditService').enableEditMode();
    }

    function save() {
      $injector.get('ngxWidgetEditService').save().toPromise();
    }

    function cancel() {
      $injector.get('ngxWidgetEditService').cancelEdit();
    }

    function isOnlyActiveOnMobile() {
      return _.includes(vm.landingPage.clientTypes, 'MOBILE') && vm.landingPage.clientTypes.length === 1;
    }

    function isInactive() {
      return !vm.landingPage.clientTypes || vm.landingPage.clientTypes.length === 0;
    }
  }

})(angular);

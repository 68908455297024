import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {CoyoValidators} from '@shared/forms/validators/validators';
import {WidgetSettingsComponent} from '@widgets/api/widget-settings-component';
import {IframeWidget} from '@widgets/iframe/iframe-widget';
import * as _ from 'lodash';

/**
 * The iframe widget's settings component.
 *
 * This component is used only in the context of the widget settings modal dialog.
 */
@Component({
  templateUrl: './iframe-widget-settings.component.html'
})
export class IframeWidgetSettingsComponent extends WidgetSettingsComponent<IframeWidget> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    const height = _.get(this.widget.settings, 'height', 240);
    const scrolling = _.get(this.widget.settings, 'scrolling', true);
    this.parentForm.addControl('height', new FormControl(height));
    this.parentForm.addControl('scrolling', new FormControl(scrolling));
    this.parentForm.addControl('url', new FormControl(this.widget.settings.url,
      [Validators.required, CoyoValidators.url]));
  }
}

import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

/**
 * Service hooking in Froala translation functionality to make it completely translatable for customers.
 */
@Injectable({
  providedIn: 'root'
})
export class FroalaTranslateService {

  constructor(private translateService: TranslateService) {
  }

  /**
   * Called internal by Froala and uses the translations defined in the coyo translation service.
   * If that translation is missing in coyo it will return the key instead which is the build in Froala translation.
   *
   * @param key The Froala message key (which is the english translation defined by Froala)
   * @return The translation or the key if the translation is missing in the coyo message files
   */
  translate(key: string): string {
    const messageKey = 'RTE.' + key.toUpperCase().replace(/[/\?]+/g, '').replace(/\s+/g, '_');
    const message = this.translateService.instant(messageKey);
    return message !== messageKey ? message : key;
  }
}

(function (angular) {
  'use strict';

  UserCardExternalController.$inject = ["$scope", "$transclude"];
  angular
      .module('commons.ui')
      .directive('coyoUserCardExternal', userCardExternal)
      .controller('UserCardExternalController', UserCardExternalController);

  /**
   * @ngdoc directive
   * @name commons.ui.coyoUserCard:coyoUserCardExternal
   * @element OWN
   * @restrict E
   * @scope
   *
   * @description
   * Displays a user card for external invitees. The card shows the name, a given subtitle and the user's avatar. If set, the card can be
   * clicked and references to the user's profile. One can use the two slots `cardHeader` and `cardBody` to add
   * additional information or options to the card.
   *
   *
   * @param {object} currentUser
   * It is necessary to pass the current user to the directive in order to evaluate permissions. For example whether
   * the current user can see the given user's profile or follow the given user.
   *
   * @param {string=} email
   * The emal address to display.
   *
   * @requires $scope
   * @requires $state
   * @requires $transclude
   * @requires authService
   */
  function userCardExternal() {
    return {
      restrict: 'E',
      transclude: {
        header: '?cardHeader',
        body: '?cardBody'
      },
      templateUrl: 'app/commons/ui/components/user-card-external/user-card-external.html',
      scope: {},
      bindToController: {
        email: '<'
      },
      controller: 'UserCardExternalController',
      controllerAs: '$ctrl'
    };
  }

  function UserCardExternalController($scope, $transclude) {
    var vm = this;
    vm.bodySlotPresent = $transclude.isSlotFilled('body');
  }

})(angular);

import {Observable, Subject} from 'rxjs';

/**
 * This helper class is a subject that emits on another subject when it is unsubscribed
 */
export class UnsubscribeEmittingSubject<T> extends Subject<T> {
  private unsubscribeEmitter: Subject<Subject<T>> = new Subject<Subject<T>>();

  constructor() {
    super();
  }

  error(err: any): void {
    super.error(err);
    this.unsubscribeEmitter.error(err);
  }

  complete(): void {
    super.complete();
    this.unsubscribeEmitter.complete();
  }

  unsubscribe(): void {
    super.unsubscribe();
    this.unsubscribeEmitter.next(this);
    this.unsubscribeEmitter.complete();
  }

  onUnsubscribe(): Observable<Subject<T>> {
    return this.unsubscribeEmitter;
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Message} from '@app/messaging/message';
import {AuthService} from '@core/auth/auth.service';
import {UserStorageService} from '@core/storage/user-storage/user-storage.service';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {Attachment} from '@domain/attachment/attachment';
import {LOCAL_FILE_LIBRARY} from '@domain/attachment/storage';
import {User} from '@domain/user/user';
import {CoyoValidators} from '@shared/forms/validators/validators';
import * as _ from 'lodash';
import {FileItem} from 'ng2-file-upload';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';

interface MessageFormValue {
  data: {
    message: string;
  };
  attachments: Attachment[];
  fileLibraryAttachments: Attachment[];
}

/**
 * Message form shown in chat channels. Stores the form value on destroy in the user storage and load it again for the
 * given channel id on initialize.
 */
@Component({
  selector: 'coyo-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageFormComponent implements OnInit, AfterViewInit, OnDestroy {

  static readonly MESSAGE_PREFIX: string = 'NEW_MESSAGE-';

  /**
   * The id of the chat channel. This is needed to store the form inputs on destroy.
   */
  @Input() channelId: string;

  /**
   * Observable that emits when a file was dropped and should be added as an attachment
   */
  @Input() filesDropped: Observable<File[]>;

  /**
   * Output emits the form value when the form is submitted
   */
  @Output() submit: EventEmitter<MessageFormValue> = new EventEmitter();

  @ViewChild('message') message: ElementRef;

  currentUser$: Observable<User>;

  attachments$: Observable<Attachment | FileItem>;

  form: FormGroup;

  private localStorageStoreSubscription: Subscription;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private userStorageService: UserStorageService,
              private windowSizeService: WindowSizeService) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
        attachments: [[], CoyoValidators.notUploading],
        message: ['']
      }, {validators: CoyoValidators.anyNotBlank('attachments', 'message')}
    );

    this.currentUser$ = this.authService.getUser().pipe(tap(user => {
      this.form.setValue(this.getFromLocalStorage());
      this.focusMessageForm();
    }));

    this.attachments$ = this.form.get('attachments').valueChanges;

    this.localStorageStoreSubscription =
      this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => this.setToLocalStorage());
  }

  ngAfterViewInit(): void {
    this.setTextareaMaxHeight(this.windowSizeService.getHeight());
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(target: Window): void {
    this.setTextareaMaxHeight(target.innerHeight);
  }

  /**
   * Removes the given attachment from the form.
   *
   * @param attachment the attachment to be removed.
   */
  removeAttachment(attachment: Attachment): void {
    this.form
      .get('attachments')
      .setValue(
        _.filter(this.form.get('attachments').value, a => !_.isEqual(attachment, a))
      );
  }

  ngOnDestroy(): void {

    this.setToLocalStorage();

    if (this.localStorageStoreSubscription && !this.localStorageStoreSubscription.closed) {
      this.localStorageStoreSubscription.unsubscribe();
    }
  }

  /**
   * Calculates the form value and emits it. Afterwards it clears the message and attachments and focus the message
   * input
   */
  onSubmit(): void {
    const formValue = this.form.getRawValue();
    const value = {
      data: {
        message: formValue.message
      },
      attachments: _.filter(formValue.attachments, attachment => attachment.storage !== LOCAL_FILE_LIBRARY),
      fileLibraryAttachments: _.filter(formValue.attachments, attachment => attachment.storage === LOCAL_FILE_LIBRARY)
    };
    this.submit.emit(value);
    this.form.reset({
      attachments: [],
      message: ''
    });
    this.setToLocalStorage();
    this.focusMessageForm();
  }

  /**
   * Submits the message when enter is clicked without shift key
   * @param $event the keyboard event
   */
  @HostListener('keydown.enter', ['$event'])
  onEnter($event: KeyboardEvent): void {
    $event.preventDefault();
    if (!$event.shiftKey && this.form.valid) {
      this.onSubmit();
    }
  }

  private setTextareaMaxHeight(windowHeight: number): void {
    if (this.message) {
      this.message.nativeElement.style.maxHeight = (windowHeight - 80) + 'px';
    }
  }

  private getFromLocalStorage(): Message {
    return {
      ...{message: '', attachments: []},
      ...(this.userStorageService.getValue(MessageFormComponent.MESSAGE_PREFIX + this.channelId) || {})
    };

  }

  private focusMessageForm(): void {
    this.message.nativeElement.focus();
  }

  private setToLocalStorage(): void {
    const key = MessageFormComponent.MESSAGE_PREFIX + this.channelId;
    const message: Message = this.form.getRawValue();
    message.attachments = _.filter(message.attachments, attachment => !(attachment instanceof FileItem));
    if (message.attachments.length || message.message) {
      this.userStorageService.setValue(key, message);
    } else {
      this.userStorageService.deleteEntry(key);
    }
  }
}

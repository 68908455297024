(function (angular) {
  'use strict';
  /**
   * @ngdoc overview
   * @name coyo.widgets
   *
   * @description
   * This module contains all widgets and the API used by widgets.
   */
  angular
      .module('coyo.widgets', [
        'coyo.widgets.api'
      ]);

  angular
      .module('coyo.widgets.api', []);
})(angular);

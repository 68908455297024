(function (angular) {
  'use strict';

  var errorObj = {e: {}};
  function tryCatch(tryCatchTarget) {
    return function tryCatcher() {
      try {
        return tryCatchTarget.apply(this, arguments);
      } catch (e) {
        errorObj.e = e;
        return errorObj;
      }
    };
  }

  angular
      .module('commons.rxjs')
      .config(["$provide", function ($provide) {

        /**
         * @ngdoc service
         * @name commons.rxjs.$rootScope
         *
         * @requires $delegate
         *
         * @description
         * `$rootScope` decorator that extends the existing `$rootScope` service
         * with additional methods. These methods are Rx related methods, such as
         * methods to create observables or observable functions.
         */
        $provide.decorator('$rootScope', ["$delegate", "rxjs", function ($delegate, rxjs) {

          Object.defineProperties($delegate.constructor.prototype, {

            /**
             * @ngdoc property
             * @name commons.rxjs.$rootScope.$eventToObservable
             *
             * @description
             * Provides a method to create observable methods.
             */
            '$eventToObservable': {

              /**
               * @ngdoc function
               * @name commons.rxjs.$rootScope.$eventToObservable#value
               *
               * @description
               * Creates an Observable from an event which is fired on the local $scope.
               * Expects an event name as the only input parameter.
               *
               * @param {string} event name
               *
               * @return {object} Observable object.
               */
              value: function (eventName, selector, ignoreErrors) {
                var scope = this;
                var subject = new rxjs.Subject();

                // connect subject to scope events
                var unsubscribe = scope.$on(eventName, function () {
                  var len = arguments.length, args = new Array(len);
                  for (var i = 0; i < len; i++) {
                    args[i] = arguments[i];
                  }
                  if (angular.isFunction(selector)) {
                    var result = tryCatch(selector).apply(null, args);
                    if (result === errorObj) {
                      if (!ignoreErrors) {
                        subject.error(result.e);
                      }
                    } else {
                      subject.next(result);
                    }
                  } else if (args.length === 1) {
                    subject.next(args[0]);
                  } else {
                    subject.next(args);
                  }
                });

                // disconnect subject from scope events
                scope.$on('$destroy', function () {
                  subject.complete();
                  unsubscribe();
                });

                // return subject's observable
                return subject.asObservable();
              },

              /**
               * @ngdoc property
               * @name commons.rxjs.$rootScope.$eventToObservable#enumerable
               *
               * @description
               * Enumerable flag.
               */
              enumerable: false,
              configurable: true,
              writable: true
            },
          });

          return $delegate;
        }]);
      }]);

})(angular);

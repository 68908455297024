import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthService} from '@core/auth/auth.service';
import {SocketService} from '@core/socket/socket.service';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {RichPage} from '@domain/pagination/richPage';
import {Search} from '@domain/pagination/search';
import {Sender} from '@domain/sender/sender';
import {SubscriptionInfo} from '@domain/subscription/subscription-info';
import {User} from '@domain/user/user';
import * as _ from 'lodash';
import {concat, EMPTY, Observable, Subject} from 'rxjs';
import {bufferTime, filter, map, mergeMap, share, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {Subscription} from './subscription';

interface SubscriptionRequests {
  requestParams: string[];
  subscriptions: Subscription[];
}

/**
 * Service to retrieve and manage user subscriptions.
 */
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  /**
   * The number of milliseconds to throttle GET requests of this service.
   */
  static readonly THROTTLE: number = 50;

  private targetIdSubject: Subject<string[]> = new Subject();
  private targetTypeSubject: Subject<string[]> = new Subject();

  private bulkSubscriptionByIdRequests: Observable<SubscriptionRequests> =
    this.createBulkSubscription('targetIds', this.targetIdSubject);
  private bulkSubscriptionByTypeRequests: Observable<SubscriptionRequests> =
    this.createBulkSubscription('targetType', this.targetTypeSubject);

  constructor(private http: HttpClient,
              private authService: AuthService,
              private socketService: SocketService) {
  }

  /**
   * Subscribes the current user to the given target.
   *
   * @param senderId the ID of the parent sender of the target
   * @param targetId the ID of the target
   * @param targetType the type of the target
   * @return an `Observable` holding the created subscription
   */
  subscribe(senderId: string, targetId: string, targetType: string): Observable<Subscription> {
    return this.authService.getUser()
      .pipe(switchMap(user => this.http.post<Subscription>(`/web/users/${user.id}/subscriptions`, {
        senderId,
        targetId,
        targetType
      })));
  }

  /**
   * Unsubscribes the current user from the given target.
   *
   * @param targetId the ID of the target
   * @return an empty `Observable`
   */
  unsubscribe(targetId: string): Observable<void> {
    return this.authService.getUser()
      .pipe(switchMap(user => this.http.delete(`/web/users/${user.id}/subscriptions`, {
        params: {
          targetId
        }
      }))).pipe(map(() => {
      }));
  }

  /**
   * Retrieves the subscriptions for the given target IDs.
   *
   * @param targetIds the IDs of the targets
   * @return an `Observable` holding either a single subscription or an array of subscriptions
   */
  getSubscriptionsById(...targetIds: string[]): Observable<Subscription | Subscription[]> {
    if (!targetIds.length) {
      return EMPTY;
    }
    setTimeout(() => this.targetIdSubject.next(targetIds));
    return this.bulkSubscriptionByIdRequests
      .pipe(filter(subscriptions => !_.difference(targetIds, subscriptions.requestParams).length))
      .pipe(map(subscriptions => targetIds.length === 1
        ? subscriptions.subscriptions.find(subscription => subscription.targetId === targetIds[0])
        : subscriptions.subscriptions.filter(subscription => targetIds.includes(subscription.targetId))
      )).pipe(take(1));
  }

  /**
   * Retrieves the subscriptions for the given target types.
   *
   * @param targetTypes the types of the targets
   * @return an `Observable` holding an array of subscriptions
   */
  getSubscriptionsByType(...targetTypes: string[]): Observable<Subscription[]> {
    if (!targetTypes.length) {
      return EMPTY;
    }
    setTimeout(() => this.targetTypeSubject.next(targetTypes));
    return this.bulkSubscriptionByTypeRequests
      .pipe(filter(subscriptions => !_.difference(targetTypes, subscriptions.requestParams).length))
      .pipe(map(subscriptions => subscriptions.subscriptions
        .filter(subscription => targetTypes.includes(subscription.targetType))))
      .pipe(take(1));
  }

  /**
   * Retrieves all senders the current user is subscribed to.
   *
   * @param search the search request
   * @param pageable the pagination data
   * @return an `Observable` holding a page of senders
   */
  getSubscribedSenders(search: Search, pageable: Pageable): Observable<RichPage<Sender, SubscriptionInfo>> {
    return this.authService.getUser()
      .pipe(switchMap(user => this.http.get<RichPage<Sender, SubscriptionInfo>>(`/web/users/${user.id}/subscriptions/senders`, {
        params: pageable.toHttpParams(search.toHttpParams())
      })));
  }

  /**
   * Sets the favorite state for the given sender.
   *
   * @param targetId the ID of the target sender
   * @param favorite the new favorite state
   * @return an empty `Observable`
   */
  setFavorite(targetId: string, favorite: boolean): Observable<void> {
    return this.authService.getUser()
      .pipe(switchMap(user => favorite
        ? this.http.post<void>(`/web/users/${user.id}/subscriptions/favorite`, null, {params: {targetId}})
        : this.http.delete<void>(`/web/users/${user.id}/subscriptions/favorite`, {params: {targetId}})
      ));
  }

  /**
   * Retrieves all users the current user is subscribed to.
   *
   * @param pageable the pagination data
   * @return an `Observable` holding a page of users
   */
  getSubscribedUsers(pageable: Pageable): Observable<Page<User>> {
    return this.authService.getUser()
      .pipe(switchMap(user => this.http.get<Page<User>>(`/web/users/${user.id}/subscriptions/users`, {
        params: pageable.toHttpParams()
      })));
  }

  /**
   * Retrieves all users against the given targetId.
   *
   * @param targetId the ID of the target
   * @param pageable the pagination data
   * @param term an optional search term to be contained in the user's name or department
   * @return an `Observable` holding a page of users
   */
  getSubscribersByTargetId(targetId: string, pageable: Pageable, term?: string): Observable<Page<User>> {
    return this.http.get<Page<User>>('/web/subscriptions', {
      params: pageable.toHttpParams()
        .append('targetId', targetId)
        .append('term', term || '')
    });
  }

  /**
   * Gets the initial subscriptions of a target and listens to changes on their subscriptions,
   * returning an Observable with a Subscriptions or an array of Subscriptions
   * @param targetId The ID of the target
   * @returns a concatenated 'Observable' with the initial subscription and the changed subscriptions values
   */
  onSubscriptionChange$(targetId: string): Observable<Subscription | Subscription[]> {
    const destination = '/user/topic/updated';
    const initialSubscriptions$ = this.getSubscriptionsById(targetId);
    const changedSubscriptions$ = this.socketService.listenTo$(destination).pipe(mergeMap(() => this.getSubscriptionsById(targetId)));
    return concat(initialSubscriptions$, changedSubscriptions$);
  }

  private request(user: User, param: string, values: string[]): Observable<Subscription[]> {
    const params = {};
    params[param] = values;
    return this.http.get<Subscription[]>(`/web/users/${user.id}/subscriptions`, {
      params
    });
  }

  private createBulkSubscription(byType: string, emitter: Observable<string[]>): Observable<SubscriptionRequests> {
    return emitter.pipe(
      bufferTime(SubscriptionService.THROTTLE),
      map(values => _.flatten(values)),
      filter(values => values && values.length > 0),
      withLatestFrom(this.authService.getUserAfterLogin()),
      mergeMap(([values, user]) => this.request(user, byType, values)
      .pipe(map(subscriptions => ({requestParams: values, subscriptions})))),
      share());
  }
}

import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {EntityId} from '@domain/entity-id/entity-id';
import {Guest} from '@domain/guest/guest';
import {GuestSelection} from '@domain/guest/guest-selection';
import {UserChooserSelectionConfig} from '@domain/guest/user-chooser-selection-config';
import {UserChooserService} from '@domain/guest/user-chooser.service';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

export class UserChooserDataSource extends DataSource<GuestSelection | null> {

  private static readonly PAGE_SIZE: number = 20;
  private static readonly DEBOUNCE_TIME: number = 250;
  private static readonly MAX_RESULT_LIMIT: number = 10_000;

  /**
   * Indicates whether the data source is called the first time.
   */
  hideInitialPage$: BehaviorSubject<boolean>;

  /**
   * Indicates whether the data source has content or not.
   */
  isEmpty$: Subject<boolean>;

  /**
   * Indicates whether the data source has received too many results to display.
   */
  tooManyResults$: Subject<boolean>;

  private dataStream: BehaviorSubject<(GuestSelection | null)[]> = new BehaviorSubject<(GuestSelection | null)[]>([]);
  private fetchedPages: Set<number> = new Set<number>();
  private subscription: Subscription;
  private selectedGuestsSubscription: Subscription;

  constructor(
    private userChooserService: UserChooserService,
    private searchTerm: string,
    private config: UserChooserSelectionConfig,
    private ignoreAlreadyInvited: boolean,
    private existingSender: EntityId,
    private selectedGuests$: BehaviorSubject<GuestSelection[]>,
    private hideInitialPage: boolean
  ) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<(GuestSelection | null)[]> {
    this.isEmpty$ = new Subject<boolean>();
    this.tooManyResults$ = new Subject<boolean>();
    this.hideInitialPage$ = new BehaviorSubject<boolean>(this.hideInitialPage);
    this.loadPage(0);
    this.selectedGuestsSubscription = this.selectedGuests$.subscribe(guests => this.updateDataStream(guests));
    this.subscription = collectionViewer.viewChange
      .pipe(debounceTime(UserChooserDataSource.DEBOUNCE_TIME))
      .subscribe(range => {
        const pageStart = Math.floor(range.start / UserChooserDataSource.PAGE_SIZE);
        const pageEnd = Math.floor(range.end / UserChooserDataSource.PAGE_SIZE);
        for (let i = pageStart; i <= pageEnd; ++i) {
          if (!this.fetchedPages.has(i)) {
            this.loadPage(i);
          }
        }
      });
    return this.dataStream;
  }

  disconnect(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.selectedGuestsSubscription) {
      this.selectedGuestsSubscription.unsubscribe();
    }
    if (this.isEmpty$) {
      this.isEmpty$.complete();
    }
    if (this.tooManyResults$) {
      this.tooManyResults$.complete();
    }
    if (this.hideInitialPage$) {
      this.hideInitialPage$.complete();
    }
  }

  private loadPage(pageNumber: number): void {
    this.fetchedPages.add(pageNumber);
    const pageable = new Pageable(pageNumber, UserChooserDataSource.PAGE_SIZE);
    this.userChooserService.searchGuests(this.searchTerm, this.config, this.existingSender, pageable)
      .subscribe(page => {
        const value = this.dataStream.getValue();
        const result = value.length ? value : Array.from({length: page.totalElements}).map(() => null);
        const updatedPageContent = this.setSelectedState(page.content, this.selectedGuests$.getValue());
        result.splice(pageNumber * UserChooserDataSource.PAGE_SIZE, page.numberOfElements, ...updatedPageContent);
        this.handleDataSourceStateUpdate(page, result);
      }, () => {
        this.fetchedPages.delete(pageNumber);
      });
  }

  private handleDataSourceStateUpdate(page: Page<Guest>, result: GuestSelection[]): void {
    this.isEmpty$.next(page.empty);
    if (page.totalElements >= UserChooserDataSource.MAX_RESULT_LIMIT) {
      this.tooManyResults$.next(true);
    } else {
      this.tooManyResults$.next(false);
      this.dataStream.next(result);
    }
    if (this.hideInitialPage$.getValue()) {
      this.hideInitialPage$.next(page.totalElements >= UserChooserDataSource.MAX_RESULT_LIMIT);
    }
  }

  private setSelectedState(content: Guest[], selectedGuests: GuestSelection[]): GuestSelection[] {
    content.forEach(guest => {
      if (guest) {
        const index = selectedGuests.findIndex(selectedGuest => selectedGuest.id === guest.id);
        const disableForSelection = !!guest.alreadyInvited && !this.ignoreAlreadyInvited;
        const disableForAdminSelection = !!guest.alreadyInvitedAsAdmin;
        guest['selected'] = index > -1 || disableForSelection || disableForAdminSelection;
        guest['disableForSelection'] = disableForSelection || disableForAdminSelection;
      }
    });
    return content as GuestSelection[];
  }

  private updateDataStream(guests: GuestSelection[]): void {
    this.dataStream.next(this.setSelectedState(this.dataStream.getValue(), guests));
  }
}

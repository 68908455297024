<ng-container mat-dialog-float>
<div mat-dialog-content>
  <div class="panel panel-default" #previewHolder [style.height.px]="previewHeight * previewScale">
    <div *ngIf="loading && !previewContent.showLoadingBar" class="text-center">
      <coyo-spinner></coyo-spinner>
    </div>
    <coyo-loading-bar *ngIf="loading && previewContent.showLoadingBar"
    [total]="articles?.length" [current]="articlesRendered$ | async"></coyo-loading-bar>
    <div class="print-preview"
         [style.display]="loading ? 'none' : 'block'"
         [style.transform]="'scale(' + previewScale + ')'">
      <ng-container *ngFor="let article of articles | slice:0:currentArticlesRenderingSize;">
        <div class="panel-heading panel-heading-main article-header">
          <div class="article-header-top article-header-top-main">
            <h1 class="panel-title panel-title-main">
              <span>{{previewContent.articleTitle ? previewContent.articleTitle : article.title}}</span>
            </h1>
          </div>
          <div class="text-muted article-header-bottom">
            <div class="author" *ngIf="article.author">
              <i class="zmdi zmdi-hc-fw zmdi-account-circle"></i>
              <coyo-sender-link *ngIf="article.author" [sender]="article.author"></coyo-sender-link>
            </div>
            <div class="time">
              <i class="zmdi zmdi-hc-fw zmdi-time"></i>
              {{article.modified | amDateFormat : 'DD/MM/YYYY HH:mm'}}
            </div>
          </div>
        </div>
        <div class="panel-body article-body panel-teaser-body" *ngIf="article.showTeaserWithText">
          <div class="article-teaser-body">
              <div *ngIf="article.teaserImage" class="article-teaser-image">
                <coyo-image-reference [fileId]="article.teaserImage.fileId" [senderId]="article.senderId"
                                      [sizeDefinitions]="{'default': 'M'}"></coyo-image-reference>
              </div>
            <div class="article-teaser-text" [innerHTML]="article.teaserText"></div>
          </div>
        </div>
        <div class="panel-body" coyoImagesComplete (completed)="onImagesComplete()">
          <coyo-widget-layout [name]="article.buildLayoutName(previewContent.appId)"
                              [language]="previewContent.selectedLanguage"
                              [parent]="{id: article.id, typeName: article.typeName}"
                              [canManage]="false"
                              [renderStyle]="'plain'"
                              [simpleMode]="true"
                              [layout]="article.widgetLayout">
          </coyo-widget-layout>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]>{{'CANCEL' | translate}}</button>
  <button mat-flat-button cdkFocusInitial color="accent" (click)="print()">{{'APP.WIKI.PRINT' | translate}}</button>
</div>
</ng-container>

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable, NgZone} from '@angular/core';
import {CropSettings} from '@app/file-picker/crop-settings';
import {UrlService} from '@core/http/url/url.service';
import {CapabilitiesService} from '@domain/capability/capabilities/capabilities.service';
import {DomainService} from '@domain/domain/domain.service';
import {FileUploadFactoryService} from '@domain/file/file-upload-factory/file-upload-factory.service';
import {FileUploadEvent} from '@domain/file/file/events/file-upload-event';
import {FileUpload} from '@domain/file/file/file-upload';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Ng1ImageCropModalService} from '@root/typings';
import {ImageSize} from '@shared/preview/file-preview/image-size';
import {UuidService} from '@shared/uuid/uuid.service';
import {attachToZone} from '@upgrade/attach-to-zone';
import {NG1_IMAGE_CROP_MODAL_SERVICE} from '@upgrade/upgrade.module';
import {EMPTY, from, generate, Observable, of} from 'rxjs';
import {catchError, concatMap, filter, map, shareReplay, switchMap, toArray} from 'rxjs/operators';
import {File as FileModel} from '../file';

/**
 * Service for file requests
 */
@Injectable({
  providedIn: 'root'
})
export class FileService extends DomainService<FileModel, FileModel> {

  constructor(private capabilitiesService: CapabilitiesService,
              readonly http: HttpClient,
              readonly urlService: UrlService,
              @Inject(NG1_IMAGE_CROP_MODAL_SERVICE) private readonly cropService: Ng1ImageCropModalService,
              private readonly ngZone: NgZone,
              private readonly fileUploadFactory: FileUploadFactoryService,
              private readonly uuidService: UuidService
  ) {
    super(http, urlService);
  }

  /**
   * Return the file by sender id and file id.
   * @param fileId the file id
   * @param senderId the sender id
   * @param permissions the permissions of the requested object
   *
   * @returns observable of the file
   */
  getFile(fileId: string, senderId: string, permissions?: string[]): Observable<FileModel> {
    return this.get(fileId, {permissions, context: {senderId}});
  }

  /**
   * Returns the deep link URL for the given file.
   *
   * @param file The file.
   *
   * @returns the deep link URL.
   */
  getRelativeDeepUrl(file: FileModel): string {
    return `/files/${file.senderId}/${file.id}/${this.encodeFileName(file.name)}`;
  }

  /**
   * Gets the file preview status
   *
   * @param url File previewUrl string to get parameters replaced
   * @param groupId Attached file groupId
   * @param fileId Attached file id
   *
   * @returns an 'Observable' with the attached file preview status
   */
  getPreviewStatus(url: string, groupId: string, fileId: string): Observable<string> {
    if (fileId !== undefined) {
      const httpOptions = {
        headers: new HttpHeaders({
          handleErrors: 'false'
        })
      };
      const processedUrl = url.replace('{{groupId}}', groupId)
        .replace('{{id}}', fileId) + '/preview-status';
      return this.http.get<{ status: string }>(processedUrl, httpOptions).pipe(map(data => data.status));
    }
    return of('PROCESSING');
  }

  getImagePreviewUrl(url: string, groupId: string, file: { id: string, modified?: Date, contentType?: string },
                     size: ImageSize): Observable<string> {
    return this.capabilitiesService.previewImageFormat(file.contentType).pipe(map(format =>
      this.createPreviewUrl(url, groupId, file.id, size, format, file.modified, file.contentType)));
  }

  /**
   * Gets the children of a specified file
   *
   * @param senderId The id of the sender
   * @param pageable The pagination information
   * @param file Optional file, if not given the children of the root folder of the sender will be loaded
   *
   * @returns a page of children
   */
  getChildren(senderId: string, pageable: Pageable, file?: FileModel): Observable<Page<FileModel>> {
    if (file && !file.folder) {
      return EMPTY;
    } else {
      const params = file ? {parentId: file.id} : undefined;
      return this.getPage(pageable, {context: {senderId}, params, permissions: ['*']});
    }
  }

  /**
   * Creates a deeplink to the given file
   * showing the file preview or the file library if the given file is a folder.
   *
   * @param file The file
   *
   * @returns the deeplink to that file
   */
  getLink(file: FileModel): string {
    return this.urlService.getCurrentDomain() + this.getRelativeDeepUrl(file);
  }

  /**
   * Renames a file and returns the new file object with the given permission information
   *
   * @param file The file to be renamed
   * @param newName The new name for the file
   * @param permissions The permissions that will be queried for the new file
   *
   * @returns An observable of the modified file
   */
  rename(file: FileModel, newName: string, permissions: string[] = ['*']): Observable<FileModel> {
    return this.http.put<FileModel>(
      `/web/senders/${file.senderId}/files/${file.id}/name`,
      {},
      {
        params: {
          _permissions: permissions.join(','),
          name: newName
        }
      });
  }

  /**
   * Moves a file to the given destination. If destination is empty it will be moved to the root folder of the sender of that file. Moving files to other
   * senders is not yet allowed
   *
   * @param file The file to move
   * @param destinationId The id of the destination folder
   *
   * @returns The updated file
   */
  moveFile(file: FileModel, destinationId?: string): Observable<FileModel> {
    const params = destinationId ? {destinationId} : {};
    return this.http.put<FileModel>(this.getUrl({
      senderId: file.senderId,
      fileId: file.id
    }, '/{fileId}/move'), null, {params});
  }

  /**
   *  Updates a single file
   *
   * @param senderId The id of the sender owning the given library/path
   * @param fileId The id of the file to be updated
   * @param file The file to be uploaded
   *
   * @returns An observable emitting FileUploadEvents. Will complete when all files are uploaded or aborted.
   */
  update(senderId: string, fileId: string, file: File): Observable<FileUploadEvent> {
    const path = `/web/senders/${senderId}/documents/${fileId}/versions`;
    const upload = new FileUpload(this.http, this.uuidService, [file], path).asObservable().pipe(shareReplay({refCount: true}));
    return attachToZone(this.ngZone, upload);
  }

  /**
   *  Starts a file upload for all given files
   *
   * @param senderId The id of the sender owning the given library/path
   * @param files The files to be uploaded
   * @param parentId The id of the parent folder. If not given, file will be uploaded to the sender root.
   * @param cropSettings Settings for image crop. If not given, images will not be cropped.
   *
   * @returns An observable emitting FileUploadEvents. Will complete when all files are uploaded or aborted.
   */
  upload(senderId: string, files: FileList | File[], parentId?: string, cropSettings?: CropSettings): Observable<FileUploadEvent> {
    const path = `/web/senders/${senderId}/documents`;

    let crop: Observable<FileList | File[]> = of(files);
    if (cropSettings?.cropImage) {
      crop = generate(0, x => x < files.length, x => x + 1)
        .pipe(map(x => files[x]))
        .pipe(concatMap(file => this.cropImages(file, cropSettings)))
        .pipe(toArray());
    }

    const upload = crop
      .pipe(filter(uploadableFiles => !!uploadableFiles?.length))
      .pipe(switchMap(uploadableFiles =>
        this.fileUploadFactory
          .createUpload(uploadableFiles, path, parentId, {_permissions: ['*']})
          .asObservable()))
      .pipe(shareReplay({refCount: true}));

    return attachToZone(this.ngZone, upload);
  }

  /**
   * Unlocks a file that is currently edited in office by a user
   * @param id The id of the file
   * @param senderId The id of the sender
   *
   * @return The updated file
   */
  unlock(id: string, senderId: string): Observable<File> {
    return this.http.post<File>(this.getUrl({
        senderId,
        id
      },
      '/{id}/unlock'), {}
    );
  }

  protected getBaseUrl(): string {
    return '/web/senders/{senderId}/files';
  }

  private cropImages(file: File, cropSettings: CropSettings): Observable<File> {
    if (file.type?.startsWith('image/')) {
      return from(this.cropService.open(file, {...cropSettings, windowTopClass: 'top-level-modal'}))
        .pipe(map(croppedFile => this.createFileFromDataUri(croppedFile, file.name)))
        .pipe(catchError(() => EMPTY));
    } else {
      return (of(file));
    }
  }

  private encodeFileName(name: string): string {
    const fileName = name.replace(/\//g, '');
    return encodeURIComponent(fileName.replace(new RegExp('\\.', 'g'), ' '));
  }

  private createPreviewUrl(url: string, groupId: string, documentId: string, size: string, format: string,
                           modified: Date, contentType: string): string {
    const baseUrl = this.urlService.getBackendUrl()
      + this.urlService.insertPathVariablesIntoUrl(url, {groupId, id: documentId});
    let cacheBuster: any = modified;
    if (modified instanceof Date) {
      cacheBuster = modified.getTime();
    }
    return baseUrl + (baseUrl.indexOf('?') < 0 ? '?' : '&') + this.urlService.toMultiUrlParamString({
      modified: cacheBuster ? [cacheBuster + ''] : [] as string[],
      type: size ? [size] : [],
      format: (!size && contentType === 'image/gif') ? [contentType] : [format]
    });
  }

  private createFileFromDataUri(dataUri: string, fileName: string): File {
    const byteString = atob(dataUri.split(',')[1]);
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
    const buffer = new ArrayBuffer(byteString.length);
    const base64Buffer = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
      base64Buffer[i] = byteString.charCodeAt(i);
    }
    return new File([buffer], fileName, {type: mimeString});
  }
}

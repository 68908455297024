<div [formGroup]="parentForm">
  <div class="form-group" [class.has-error]="parentForm.controls['_url'].touched && parentForm.controls['_url'].errors" [ngClass]="{'has-warning': !safeUrl}">
    <label class="col-sm-2 control-label" for="video-widget-url">{{'WIDGETS.VIDEO.SETTINGS.CONFIG.URL.LABEL' | translate}}</label>
    <div class="col-sm-9">
      <input class="form-control" type="url" name="_url" id="video-widget-url" formControlName="_url" (change)="checkUrl()" [attr.aria-label]="'WIDGETS.VIDEO.URL.ARIA' | translate" placeholder="http://">
      <coyo-validation-errors [errors]="parentForm.controls['_url'].errors"></coyo-validation-errors>
      <p class="help-block" *ngIf="!safeUrl">
        <i class="zmdi zmdi-alert-triangle"></i>&nbsp;{{'WIDGETS.VIDEO.SETTINGS.MESSAGE' | translate }}
      </p>
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.VIDEO.SETTINGS.CONFIG.URL.HELP"></coyo-help>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="video-widget-title">{{'WIDGETS.VIDEO.SETTINGS.CONFIG.TITLE.LABEL' | translate}}</label>
    <div class="col-sm-9">
      <input class="form-control" type="text" name="title" id="video-widget-title" formControlName="title" [attr.aria-label]="'WIDGETS.VIDEO.TITLE.ARIA' | translate">
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.VIDEO.SETTINGS.CONFIG.TITLE.HELP"></coyo-help>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="video-widget-description">{{'WIDGETS.VIDEO.SETTINGS.CONFIG.DESCRIPTION.LABEL' | translate}}</label>
    <div class="col-sm-9">
      <input class="form-control" type="text" name="description" id="video-widget-description" formControlName="description" [attr.aria-label]="'WIDGETS.VIDEO.DESCRIPTION.ARIA' | translate">
    </div>
    <div class="col-sm-1 hidden-xs">
      <coyo-help tip="WIDGETS.VIDEO.SETTINGS.CONFIG.DESCRIPTION.HELP"></coyo-help>
    </div>
  </div>
</div>

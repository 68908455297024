import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {StickyExpiryPeriod} from '@app/timeline/timeline-form/sticky-btn/sticky-expiry-period';
import {StickyExpiryPeriods} from '@app/timeline/timeline-form/sticky-btn/sticky-expiry-periods';
import {Sender} from '@domain/sender/sender';
import {ShareService} from '@domain/share/share.service';
import {User} from '@domain/user/user';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {FindOptions, SelectSenderOptions} from '@shared/sender-ui/select-sender/select-sender-options';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {ShareModalData} from './share-modal-data';

/**
 * Renders the share modal view.
 */
@Component({
  selector: 'coyo-share-modal',
  templateUrl: './share-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareModalComponent implements OnInit {

  readonly stickyExpiryOptions: StickyExpiryPeriod[] = StickyExpiryPeriods.all;

  readonly shareWithOptions: SelectSenderOptions = {
    senderTypes: ['user', 'page', 'workspace'],
    findOptions: FindOptions.SHARE_TARGETS_ONLY
  };

  shareAsOptions: SelectSenderOptions = {
    senderTypes: ['user', 'page', 'workspace', 'event'],
    findOptions: FindOptions.MANAGED_SENDERS_ONLY,
    showSender: sender => sender.id === this.data.currentUser.id
  };

  shareForm: FormGroup;
  showExtendedOptions: boolean = false;

  private shareWith: FormControl;
  private message: FormControl;
  private shareAs: FormControl;
  private stickyExpiry: FormControl;

  constructor(private shareService: ShareService,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<ShareModalComponent>,
              private notificationService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public data: ShareModalData) {
  }

  ngOnInit(): void {
    this.initializeShareForm();
    this.watchShareWithChanges();
  }

  private initializeShareForm(): void {
    this.shareWith = new FormControl(null, Validators.required);
    this.message = new FormControl(null);
    this.shareAs = new FormControl({value: this.data.currentUser, disabled: true}, Validators.required);
    this.stickyExpiry = new FormControl({value: StickyExpiryPeriods.none, disabled: true});
    this.shareForm = this.formBuilder.group({
      shareWith: this.shareWith,
      message: this.message,
      shareAs: this.shareAs,
      stickyExpiry: this.stickyExpiry
    });
  }

  /**
   * Will be called on a form submit, save the share form data and pass the response to the parent component.
   */
  onSubmit(): void {
    this.shareForm.disable();
    this.shareService
      .post(this.generateShare(), {path: '/' + this.data.typeName})
      .subscribe({
        next: createdShare => this.dialogRef.close(createdShare),
        error: () => this.notificationService.error('ERRORS.STATUSCODE.NOT_FOUND.DEFAULT'),
        complete: () => this.shareForm.enable()
      });
  }

  private generateShare(): any {
    return {
      itemId: this.data.targetId,
      recipientId: this.shareWith.value.id,
      data: {message: this.message.value},
      authorId: this.shareAs.value.id,
      stickyExpiry: this.getExpiryTime()
    };
  }

  private getExpiryTime(): number {
    const duration = this.stickyExpiry.value.expiry;
    return duration ? new Date().getTime() + duration : null;
  }

  private watchShareWithChanges(): void {
    this.shareWith.valueChanges.pipe(
      distinctUntilChanged(),
      filter(Boolean)
    ).subscribe((shareWithData: Sender) => this.onShareWithChange(shareWithData));
  }

  private onShareWithChange(shareTarget: Sender): void {
    this.setStickySelectionState(shareTarget);
    this.refreshShareAsSelection(shareTarget);
  }

  private setStickySelectionState(shareTarget: Sender): void {
    if (!shareTarget || shareTarget.typeName === 'user') {
      this.stickyExpiry.setValue(StickyExpiryPeriods.none);
      this.stickyExpiry.disable();
    } else {
      this.stickyExpiry.enable();
    }
  }

  private refreshShareAsSelection(shareWith: Sender): void {
    const currentUser: User = this.data.currentUser;
    this.shareAs.setValue(currentUser);
    this.shareAsOptions = {
      ...this.shareAsOptions,
      showSender: shareAsSender => this.canCreateShare(currentUser, shareAsSender, shareWith)
    };
    this.shareAs.enable();
  }

  private canCreateShare(user: User, shareAs: Sender, shareWith: Sender): boolean {
    if (shareAs.id === user.id) {
      return true;
    }

    if (user.globalPermissions.includes('ACT_AS_SENDER')) {
      return true;
    }

    if (user.globalPermissions.includes('ACT_AS_SENDER_LOCALLY')) {
      if (!!shareWith && shareAs.id === shareWith.id) {
        return true;
      }
    }

    return false;
  }
}

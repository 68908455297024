(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.pages
   *
   * @description
   * # Pages module #
   * The pages module renders the pages view and provides various page-related components such as
   * * the page avatar,
   * * the page follow button and
   * * the page name validator.
   */
  ModuleConfig.$inject = ["$stateProvider", "pagesConfig"];
  angular
      .module('coyo.pages', [
        'coyo.base',
        'commons.auth',
        'commons.ui'
      ])
      .config(ModuleConfig)
      .constant('pagesConfig', {
        templates: {
          list: 'app/modules/pages/views/pages.list.html',
          show: 'app/modules/pages/views/pages.show.html',
          create: 'app/modules/pages/views/pages.create.html',
          settings: 'app/modules/pages/views/pages.settings.html',
          imprint: 'app/modules/pages/views/pages.imprint.html'
        },
        list: {
          paging: {
            pageSize: 10
          }
        }
      });

  /**
   * Module configuration
   */
  function ModuleConfig($stateProvider, pagesConfig) {
    $stateProvider
        .state('main.page', {
          url: '/pages?:term&:categories[]&:status',
          templateUrl: pagesConfig.templates.list,
          controller: 'PagesListController',
          controllerAs: 'ctrl',
          resolve: {
            currentUser: ["authService", function (authService) {
              return authService.getUser();
            }],
            categories: ["PageCategoryModel", function (PageCategoryModel) {
              return PageCategoryModel.get('all');
            }]
          },
          params: {
            'term': {dynamic: true, inherit: false},
            'categories[]': {dynamic: true, inherit: false},
            'status': {dynamic: true, inherit: false}
          },
          data: {
            guide: 'pages',
            globalPermissions: 'ACCESS_PAGES',
            pageTitle: 'MODULE.PAGES.PAGE_TITLE'
          }
        })
        .state('main.page.create', {
          url: '/create',
          views: {
            '@main': {
              templateUrl: pagesConfig.templates.create,
              controller: 'PagesCreateController',
              controllerAs: '$ctrl'
            }
          },
          resolve: {
            settings: ["SettingsModel", function (SettingsModel) {
              return SettingsModel.retrieve();
            }]
          },
          data: {
            globalPermissions: 'CREATE_PAGE'
          }
        })
        .state('main.page.show', {
          url: '/:idOrSlug',
          views: {
            '@main': {
              templateUrl: pagesConfig.templates.show,
              controller: 'PageController',
              controllerAs: '$ctrl'
            }
          },
          params: {
            appDeleted: {dynamic: true, inherit: false}
          },
          data: {
            senderParam: 'idOrSlug',
            pageTitle: false
          },
          resolve: {
            page: ["PageModel", "$stateParams", function (PageModel, $stateParams) {
              return PageModel.getWithPermissions({id: $stateParams.idOrSlug}, {},
                  ['manage', 'delete', 'manageApps', 'manageSlots', 'createFile']);
            }],
            apps: ["SenderModel", "page", function (SenderModel, page) {
              var senderModel = new SenderModel({id: page.id});
              return senderModel.getApps();
            }],
            currentUser: ["authService", function (authService) {
              return authService.getUser();
            }],
            senderId: ["page", function (page) {
              return page.id;
            }]
          },
          onEnter: ["page", "$injector", function (page, $injector) {
            $injector.get('ngxPageTitleService').setTitle(page.displayName);
          }]
        })
        .state('main.page.show.settings', {
          url: '/settings',
          views: {
            '@main': {
              templateUrl: pagesConfig.templates.settings,
              controller: 'PageSettingsController',
              controllerAs: '$ctrl'
            }
          },
          resolve: {
            pageOrigin: ["PageModel", "$stateParams", "$state", function (PageModel, $stateParams, $state) {
              return PageModel.getWithPermissions({id: $stateParams.idOrSlug}, {origin: true},
                  ['manage', 'delete', 'manageApps', 'manageSlots', 'createFile']).catch(function () {
                $state.go('main.page');
              });
            }],
            members: ["pageOrigin", function (pageOrigin) {
              return pageOrigin.getMembers();
            }]
          }
        })
        .state('main.page.show.imprint', {
          url: '/imprint',
          templateUrl: pagesConfig.templates.imprint,
          controller: 'PageImprintController',
          controllerAs: '$ctrl',
          resolve: {
            sender: ["page", function (page) {
              return page;
            }]
          }
        });
  }

})(angular);

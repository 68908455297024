(function (angular) {
  'use strict';

  angular
      .module('commons.ui')
      .component('coyoRenderBirthday', renderBirthday());

  /**
   * @ngdoc directive
   * @name commons.ui.coyoRenderBirthday:coyoRenderBirthday
   * @scope
   * @restrict 'E'
   * @element ANY
   *
   * @description
   * Renders birthday value.
   *
   * @param {string} date The given date string.
   * @param {string} format The default display format without year.
   * @param {string} formatIncludingYear The display format including year.
   *
   * @requires moment
   * @requires commons.ui.birthdayService
   */
  function renderBirthday() {
    return {
      template: '{{ $ctrl.displayValue }}',
      bindings: {
        date: '=',
        format: '@?',
        formatIncludingYear: '@?'
      },
      controller: 'RenderBirthdayController',
      controllerAs: '$ctrl'
    };
  }

})(angular);

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {User} from '@domain/user/user';

/**
 * Shows user avatars as facegame teaser.
 */
@Component({
  selector: 'coyo-facegame-avatar-teaser',
  templateUrl: './facegame-avatar-teaser.component.html',
  styleUrls: ['./facegame-avatar-teaser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacegameAvatarTeaserComponent {

  /**
   * The users to display.
   */
  @Input() users: User[];
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '@core/auth/auth.service';
import {Share} from '@domain/share/share';
import {Shareable} from '@domain/share/shareable';
import {User} from '@domain/user/user';
import {NotificationService} from '@shared/notifications/notification/notification.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {from} from 'rxjs';
import {ShareModalData} from '../share-modal/share-modal-data';
import {ShareModalComponent} from '../share-modal/share-modal.component';

/**
 * Renders a share button and a share count from the perspective of the current user.
 */
@Component({
  selector: 'coyo-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareButtonComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ShareModalComponent, Share>;

  private currentUser: User;

  /**
   * Model with data.
   */
  @Input() target: Shareable;

  /**
   * Only show the icon.
   */
  @Input() condensed: boolean;

  /**
   * Event if a share was created.
   */
  @Output() shareCreated: EventEmitter<Share> = new EventEmitter<Share>();

  constructor(private authService: AuthService,
              private permissionService: NgxPermissionsService,
              private dialog: MatDialog,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.authService.getUser().subscribe(user => this.currentUser = user);
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  /**
   * Opens a modal with the share form.
   */
  openShareModal(): void {
    this.dialogRef = this.dialog.open<ShareModalComponent, ShareModalData>(ShareModalComponent, {
      data: {
        currentUser: this.currentUser,
        parentIsPublic: this.target.parentPublic,
        targetId: this.target.id,
        typeName: this.target.typeName,
        canCreateStickyShare: from(this.permissionService.hasPermission('CREATE_STICKY_TIMELINE_ITEM'))
      }
    });
    this.dialogRef.afterClosed().subscribe(createdShare => {
      if (createdShare) {
        this.shareCreated.emit(createdShare);
        this.notificationService.success('COMMONS.SHARES.SUCCESS');
      }
    });
  }
}

(function (angular) {
  'use strict';

  AdminGameDetailsController.$inject = ["$state", "$stateParams", "game", "moment"];
  angular
      .module('coyo.admin.gamification')
      .controller('AdminGameDetailsController', AdminGameDetailsController);

  function AdminGameDetailsController($state, $stateParams, game, moment) {
    var vm = this;

    vm.$onInit = onInit;
    vm.save = save;

    function onInit() {
      vm.game = angular.extend(game, {
        homeTeamId: _.get(game, 'homeTeam.id'),
        awayTeamId: _.get(game, 'awayTeam.id')
      });
      vm.game.date = vm.game.date ? moment(vm.game.date).toDate() : null;
    }

    function save() {
      vm.game.save().then(function () {
        $state.go('admin.gamification.games', {page: $stateParams.page || 0});
      });
    }
  }

})(angular);

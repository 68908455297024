<ng-container [formGroup]="parentForm">
  <mat-form-field>
    <mat-label translate="WIDGET.HTML.SETTINGS.TEXT"></mat-label>
    <textarea matInput formControlName="html_content"
              placeholder="<div>…</div>"
              rows="8"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"></textarea>
    <mat-error *ngIf="parentForm.controls.html_content.errors?.required"
               translate="VALIDATION.ERROR.REQUIRED"></mat-error>
  </mat-form-field>
</ng-container>

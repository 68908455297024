import {HttpClient} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ScreenSize} from '@core/window-size/screen-size';
import {WindowSizeService} from '@core/window-size/window-size.service';
import {ParticipantStatus} from '@domain/event/participant-status';
import {SenderEvent} from '@domain/event/sender-event';
import {ParticipantStatusChangedEvent} from '@shared/event-meta-data/event-participation/ParticipantStatusChangedEvent';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'coyo-event-participation',
  templateUrl: './event-participation.component.html',
  styleUrls: ['./event-participation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventParticipationComponent implements OnInit {

  /**
   * Whether the event is ongoing, meaning the select menu should be disabled.
   */
  @Input() isOngoing: boolean;

  /**
   * Whether the event has ended, meaning the select menu should be disabled.
   */
  @Input() hasEnded: boolean;

  /**
   * The affected event.
   */
  @Input() event: SenderEvent;

  /**
   * Emit handler for participant status changed event.
   */
  @Output() statusChanged: EventEmitter<ParticipantStatusChangedEvent> = new EventEmitter();

  options: EventParticipationOption[];

  selectedOption: EventParticipationOption;

  isXs$: Observable<boolean>;

  private selectableExtendedTranslationStatusTypes: ParticipantStatus[] = [
    ParticipantStatus.ATTENDING,
    ParticipantStatus.NOT_ATTENDING
  ];

  constructor(protected cd: ChangeDetectorRef,
              private http: HttpClient,
              private windowSizeService: WindowSizeService) {
  }

  ngOnInit(): void {
    this.initializeStatuses();
    this.selectedOption = (this.event.status
      && this.options.find(option => this.event.status === option.status))
        || {status: ParticipantStatus.PENDING, icon: ''};
    this.isXs$ = this.windowSizeService.observeScreenChange$()
      .pipe(map(screenSize => screenSize === ScreenSize.XS));
  }

  participantStatusWasChanged(newSelectedOption: EventParticipationOption): void {
    this.changeParticipantStatus(newSelectedOption.status).subscribe(() => {
      const participantStatusChangedEvent = {
        oldStatus: this.selectedOption.status,
        newStatus: newSelectedOption.status
      } as ParticipantStatusChangedEvent;
      this.statusChanged.emit(participantStatusChangedEvent);
      if (this.event.limitedParticipants) {
        this.updateNumberOfAttendingParticipants(participantStatusChangedEvent);
      }
      this.selectedOption = newSelectedOption;
      this.event.status = newSelectedOption.status;
      this.cd.detectChanges();
    });
  }

  updateNumberOfAttendingParticipants(participantStatusChangedEvent: ParticipantStatusChangedEvent): void {
    if (participantStatusChangedEvent.newStatus !== participantStatusChangedEvent.oldStatus) {
      if (participantStatusChangedEvent.newStatus === 'ATTENDING') {
        this.event.limitedParticipants.numberOfAttendingParticipants++;
      } else {
        this.event.limitedParticipants.numberOfAttendingParticipants--;
      }
    }
  }

  isParticipantStatusSelectable(option: EventParticipationOption): boolean {
    return option.status !== ParticipantStatus.PENDING &&
      !(option.status === ParticipantStatus.ATTENDING && this.isParticipantLimitReached());
  }

  isFullyBooked(): boolean {
    return this.selectedOption.status !== ParticipantStatus.ATTENDING && this.isParticipantLimitReached();
  }

  isMenuDisabled(): boolean {
    return this.isOngoing || this.hasEnded ||
      !_.includes([ParticipantStatus.ATTENDING, ParticipantStatus.MAYBE_ATTENDING], this.selectedOption.status)
      && this.isParticipantLimitReached();
  }

  isCurrentStatus(currentOption: EventParticipationOption): boolean {
    return this.selectedOption.status === currentOption.status;
  }

  buildStatusTranslationKey(currentOption: EventParticipationOption): string {
    if (this.isCurrentStatus(currentOption) && this.selectableExtendedTranslationStatusTypes.includes(currentOption.status)) {
      return 'EVENT.STATUS.' + currentOption.status + '.ACTIVE';
    }
    return 'EVENT.STATUS.' + currentOption.status;
  }

  private initializeStatuses(): void {
    this.options = [
      {status: ParticipantStatus.ATTENDING, icon: 'check'},
      {status: ParticipantStatus.NOT_ATTENDING, icon: 'close'}
    ];

    if (!this.event.requestDefiniteAnswer) {
      this.options.push({status: ParticipantStatus.MAYBE_ATTENDING, icon: 'help'});
    }
  }

  private changeParticipantStatus(status: ParticipantStatus): Observable<object> {
    return this.http.put('/web/events/' + this.event.id + '/status', {
      status: status
    } as ParticipantStatusUpdateRequest);
  }

  private isParticipantLimitReached(): boolean {
    return this.event.limitedParticipants
      && (this.event.limitedParticipants.numberOfAttendingParticipants
        >= this.event.limitedParticipants.participantsLimit);
  }

}

interface ParticipantStatusUpdateRequest {
  status: ParticipantStatus;
}

interface EventParticipationOption {
  status: ParticipantStatus;
  icon: string;
}

<coyo-filter [title]="title">
  <coyo-filter-entry
    (click)="resetSelection()"
    *ngIf="allOptionEnabled"
    [active]="allOption.active"
    [badge]="allOption.count"
    [icon]="allOption.icon"
    [text]="allOption.text ? allOption.text : allOption.textKey | translate"
  ></coyo-filter-entry>
  <coyo-filter-entry
    (click)="toggle(item.key)"
    *ngFor="let item of filterModel | slice:0:filterPageSize"
    [active]="item.active"
    [badge]="item.count"
    [iconColor]="item.iconColor"
    [icon]="item.icon || (item.key | lowercase)"
    [text]="textPrefix ? (textPrefix + '.' + item.key | uppercase | translate) : item.text"
  ></coyo-filter-entry>
  <coyo-filter-expand (click)="showMoreItems()" *ngIf="filterModel.length > filterPageSize"></coyo-filter-expand>
</coyo-filter>

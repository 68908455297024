<ng-container *ngIf="{isMobile: isMobile$ | async} as screenSize">

  <!-- event app view -->
  <ng-container *ngIf="isAppView">
    <button *ngIf="!screenSize.isMobile"
            mat-button
            color="accent"
            [attr.aria-label]="'MODULE.EVENTS.CREATE.TITLE' | translate"
            [matMenuTriggerFor]="eventCreateMenuBefore">
      <mat-icon svgIcon="plus-circled"></mat-icon>&nbsp;{{'MODULE.EVENTS.CREATE.TITLE' | translate}}
    </button>
    <ng-container *ngIf="screenSize.isMobile">
      <button *ngFor="let visibility of eventVisibilities"
              mat-button
              (click)="redirectToEventCreate(visibility)"
              [matTooltip]="'EVENT.' + visibility.name + '.LABEL' | translate"
              [matTooltipPosition]="'above'">
        <mat-icon [svgIcon]="visibility.icon"></mat-icon>&nbsp;{{'MODULE.EVENTS.CREATE.' + visibility.name + '.TITLE' | translate}}
      </button>
    </ng-container>
  </ng-container>

  <!-- events overview -->
  <ng-container *ngIf="!isAppView">
    <div class="button-container">
      <button *ngIf="!screenSize.isMobile"
              class="event-create-button"
              mat-flat-button
              color="primary"
              [matMenuTriggerFor]="eventCreateMenu">
        <mat-icon svgIcon="plus"></mat-icon> {{'MODULE.EVENTS.CREATE.TITLE' | translate}}
      </button>
      <button *ngIf="screenSize.isMobile"
              class="event-create-button-mobile"
              mat-icon-button
              [attr.aria-label]="'MODULE.EVENTS.CREATE.TITLE' | translate"
              [matMenuTriggerFor]="eventCreateMenu">
        <mat-icon svgIcon="plus-circled"></mat-icon>
      </button>
    </div>
  </ng-container>

  <mat-menu #eventCreateMenu="matMenu" class="event-create-menu-panel">
    <ng-container *ngTemplateOutlet="eventCreateMenuContent"></ng-container>
  </mat-menu>

  <mat-menu #eventCreateMenuBefore="matMenu" xPosition="before" class="event-create-menu-panel">
    <ng-container *ngTemplateOutlet="eventCreateMenuContent"></ng-container>
  </mat-menu>

  <ng-template #eventCreateMenuContent>
    <button *ngFor="let visibility of eventVisibilities"
            class="event-visibility-button"
            mat-menu-item
            (click)="redirectToEventCreate(visibility)">
      <span class="button-flex-container">
        <span class="icon-container">
          <mat-icon [svgIcon]="visibility.icon"></mat-icon>
        </span>
        <span class="text-container">
          <span class="name">{{'EVENT.' + visibility.name | translate}}</span>
          <span class="description">{{'EVENT.' + visibility.name + '.LABEL' | translate}}</span>
        </span>
      </span>
    </button>
  </ng-template>

</ng-container>

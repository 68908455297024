import {Injectable, Type} from '@angular/core';
import {FilePickerItemServiceCapability} from '@app/file-library/file-picker-item-service-capability.enum';
import {IconNameFilePickerItemComponent} from '@app/file-library/icon-name-file-picker-item/icon-name-file-picker-item.component';
import {PagesRootFilePickerItem} from '@app/file-library/pages-root-file-picker-item/pages-root-file-picker-item';
import {SenderRootFilePickerItem} from '@app/file-library/sender-root-file-picker-item/sender-root-file-picker-item';
import {FilePickerItem} from '@app/file-picker/file-picker-item';
import {FilePickerItemBaseDirective} from '@app/file-picker/file-picker-item-base.directive';

import {FilePickerItemService} from '@app/file-picker/file-picker-item.service';
import {PageService} from '@domain/page/page.service';
import {Order} from '@domain/pagination/order';
import {Page} from '@domain/pagination/page';
import {Pageable} from '@domain/pagination/pageable';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Service responsible for handling page file picker items
 */
@Injectable({
  providedIn: 'root'
})
export class PagesRootFilePickerItemService extends FilePickerItemService {

  constructor(private readonly pageService: PageService) {
    super();
  }

  getChildren(filePickerItem: PagesRootFilePickerItem, pageIdx: number): Observable<Page<FilePickerItem>> {
    return this.pageService.getPage(new Pageable(pageIdx, 20, null, new Order('displayName.sort')), {
      permissions: ['createFile'],
    }).pipe(map(page => ({...page, content: page.content.map(pageContent => new SenderRootFilePickerItem(pageContent))})));
  }

  getCapabilities(filePickerItem: FilePickerItem): FilePickerItemServiceCapability {
    return FilePickerItemServiceCapability.NONE;
  }

  getComponentType(): Type<FilePickerItemBaseDirective<FilePickerItem>> | null {
    return IconNameFilePickerItemComponent;
  }

  getResponsibilities(): Type<FilePickerItem>[] {
    return [PagesRootFilePickerItem];
  }
}

<h1 mat-dialog-title translate="EVENT.DELETE.MODAL.TITLE"></h1>
<mat-dialog-close [attr.aria-label]="'CLOSE'"></mat-dialog-close>
<div mat-dialog-content>
  <p translate="EVENT.DELETE.MODAL.TEXT"></p>
</div>
<div mat-dialog-actions class="form-action-buttons">
  <button mat-button
          [attr.aria-label]="'EVENT.DELETE.MODAL.CANCEL' | translate"
          [mat-dialog-close]="false"
  >
    <span translate="EVENT.DELETE.MODAL.CANCEL"></span>
  </button>
  <button mat-flat-button
          color="warn"
          [attr.aria-label]="'EVENT.DELETE.MODAL.SUBMIT' | translate"
          [mat-dialog-close]="true"
  >
    <span translate="EVENT.DELETE.MODAL.SUBMIT"></span>
  </button>
</div>

<section class="event-create">
  <div class="panel panel-default" [ngClass]="{'extended-panel': step === 2}">
    <form role="form"
          class="form-horizontal"
          [attr.aria-label]="'MODULE.TIMELINE.FORM.ARIA' | translate"
          [formGroup]="eventCreateForm">

      <input id="public" type="hidden" name="public" formControlName="public">
      <div *ngIf="step === 1">
        <div class="panel-heading">
          <h3 class="panel-title"
              [translate]="'MODULE.EVENTS.CREATE.' + (public ? 'PUBLIC' : 'PRIVATE') + '.TITLE'"
              data-test="events-create-panel-title">
          </h3>
        </div>
        <div class="panel-body">
          <div class="group">
            <div class="form-group">
              <div class="col-md-7">
                <label for="name" translate="EVENT.TITLE.LABEL"></label>
                <input id="name" class="form-control" type="text" name="name" formControlName="name"
                       data-test="event-create-input-event-name">
              </div>
              <div class="col-md-5">
                <label translate="EVENT.SENDER.LABEL"></label>
                <coyo-select-sender id="host"
                                    formControlName="host"
                                    placeholder="EVENT.SENDER.PLACEHOLDER"
                                    [options]="senderSelectOptions">
                </coyo-select-sender>
              </div>
            </div>
            <div class="form-group">
              <div class="col-md-12">
                <label for="place" translate="EVENT.PLACE.LABEL"></label>
                <input id="place" class="form-control" type="text" name="place" formControlName="place">
              </div>
            </div>
          </div>

          <div class="form-group group">
            <div class="col-md-12">
              <mat-checkbox id="fullDay"
                            name="fullDay"
                            formControlName="fullDay"
                            data-test="event-create-checkbox-full-day">{{'EVENT.FULL_DAY.LABEL' | translate}}
              </mat-checkbox>
            </div>
            <div class="input-group-date-picker col-md-6 col-xs-12">
              <label for="eventStartDate" translate="EVENT.DATE_START.LABEL"></label>
              <div class="input-group" [ngClass]="{'full-day': eventCreateForm.controls.fullDay.value === true}">
                <coyo-date-picker id="eventStartDate" class="date"
                                  [(date)]="eventDates.startDate" (dateChange)="updateStartDate()">
                </coyo-date-picker>
                <coyo-time-picker id="eventStartTime" class="time"
                                  [(time)]="eventDates.startTime" (timeChange)="updateStartTime()">
                </coyo-time-picker>
              </div>
            </div>
            <div class="input-group-date-picker col-md-6 col-xs-12">
              <label for="eventEndDate" translate="EVENT.DATE_END.LABEL"></label>
              <div class="input-group" [ngClass]="{'full-day': eventCreateForm.controls.fullDay.value === true}">
                <coyo-date-picker id="eventEndDate" class="date"
                                  [(date)]="eventDates.endDate" (dateChange)="updateEndDate()">
                </coyo-date-picker>
                <coyo-time-picker id="eventEndTime" class="time"
                                  [(time)]="eventDates.endTime" (timeChange)="updateEndTime()">
                </coyo-time-picker>
              </div>
            </div>
          </div>

          <div class="form-group group">
            <div class="col-md-12">
              <label for="description" translate="EVENT.DESCRIPTION.LABEL"></label>
              <coyo-rte id="description"
                        [content]="eventCreateForm.controls.description.value"
                        [optionOverrides]="eventDescriptionRteOptions"
                        (contentUpdated)="descriptionChanges($event)"></coyo-rte>
            </div>
          </div>
        </div>

        <div class="form-group advanced-settings" data-test="event-create-advanced-settings">
          <div class="col-md-12">
            <label class="headline" translate="MODULE.EVENTS.CREATE.ADVANCED_SETTINGS"></label>
          </div>
          <div class="col-md-12">
            <mat-checkbox id="showParticipants"
                          name="showParticipants"
                          formControlName="showParticipants"
                          data-test="event-create-show-participants-checkbox">
              {{'EVENT.SHOW_PARTICIPANTS.LABEL' | translate}}
              <mat-hint translate="EVENT.SHOW_PARTICIPANTS.HELP"></mat-hint>
            </mat-checkbox>
          </div>
          <div class="col-md-12 ">
            <mat-checkbox id="requestDefiniteAnswer"
                          name="requestDefiniteAnswer"
                          formControlName="requestDefiniteAnswer"
                          data-test="event-create-definite-answer-checkbox">
              {{'EVENT.DEFINITE_ANSWERS.LABEL' | translate}}
              <mat-hint translate="EVENT.DEFINITE_ANSWERS.HELP"></mat-hint>
            </mat-checkbox>
          </div>
          <div class="col-md-12">
            <mat-checkbox id="limitedParticipants"
                          name="limitedParticipants"
                          formControlName="limitedParticipants"
                          data-test="event-create-limited-participants-checkbox">
              {{'EVENT.LIMITED_PARTICIPANTS.LABEL' | translate}}
              <mat-hint translate="EVENT.LIMITED_PARTICIPANTS.HELP"></mat-hint>
            </mat-checkbox>
            <input id="participantsLimit" class="form-control participant-count" type="number" name="participantsLimit"
                   formControlName="participantsLimit" [min]="0" *ngIf="eventCreateForm.controls.limitedParticipants.value"
                   data-test="event-create-limited-participants-input">
          </div>
      </div>
      <div class="form-actions">
        <div class="form-action-buttons text-right">
          <button mat-button
                  type="button"
                  [attr.aria-label]="'CANCEL' | translate"
                  (click)="cancelForm()">
            <span translate="CANCEL"></span>
          </button>
          <button *ngIf="public"
                  mat-flat-button
                  color="accent"
                  type="submit"
                  [attr.aria-label]="'MODULE.EVENTS.CREATE.TITLE' | translate"
                  [disabled]="!eventCreateForm.valid"
                  (click)="submitForm()">
            <span translate="MODULE.EVENTS.CREATE.TITLE"></span>
          </button>
          <button *ngIf="!public"
                  mat-flat-button
                  color="accent"
                  type="button"
                  [attr.aria-label]="'EVENT.INVITE_COLLEAGUES' | translate"
                  [disabled]="!eventCreateForm.valid"
                  (click)="nextStep()">
            <span translate="EVENT.INVITE_COLLEAGUES"></span>
          </button>
          </div>
        </div>
      </div>
      <div *ngIf="{
        canInviteGroups: canInviteGroups$ | async,
        canInviteWorkspaces: canInviteWorkspaces$ | async,
        canInvitePages: canInvitePages$ | async
      } as permissions">
        <coyo-user-chooser-view *ngIf="step === 2"
                                [initialSelection]="selectedGuestsStorage"
                                [titleTranslationKey]="'EVENT.USER.CHOOSER.TITLE'"
                                [additionalEmptyListTranslationKey]="'USER.CHOOSER.GUEST_LIST.HELP.PART_THREE'"
                                [submitButtonTranslationKey]="'MODULE.EVENTS.CREATE.TITLE'"
                                [config]="{
                                  users: true,
                                  groups: permissions.canInviteGroups,
                                  pages: permissions.canInvitePages,
                                  workspaces: permissions.canInviteWorkspaces
                                }"
                                (result)="addInvitedUsers($event)"
                                (goBack)="saveUserChooserStateAndGoToPreviousStep($event)">
        </coyo-user-chooser-view>
      </div>
    </form>
  </div>
</section>

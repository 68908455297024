(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name coyo.admin.gamification
   *
   * @description
   * # Admin gamification module #
   */
  registerStates.$inject = ["$stateProvider"];
  angular
      .module('coyo.admin.gamification', [
        'commons.config',
        'commons.ui'
      ])
      .config(registerStates);

  function registerStates($stateProvider) {
    $stateProvider.state('admin.gamification', {
      url: '/gamification',
      templateUrl: 'app/modules/admin/gamification/admin.gamification.html',
      data: {
        globalPermissions: 'MANAGE_GAMIFICATION',
        pageTitle: 'ADMIN.MENU.GAMIFICATION'
      },
      redirect: 'admin.gamification.games'
    }).state('admin.gamification.games', {
      url: '/games',
      views: {
        '@admin.gamification': {
          templateUrl: 'app/modules/admin/gamification/views/game-list/admin.game-list.html',
          controller: 'AdminGameListController',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        tournament: ["TournamentAdminModel", function (TournamentAdminModel) {
          return TournamentAdminModel.getLatest();
        }]
      }
    }).state('admin.gamification.games.edit', {
      url: '/edit/{id}?page',
      views: {
        '@admin.gamification': {
          templateUrl: 'app/modules/admin/gamification/views/game-details/admin.game-details.html',
          controller: 'AdminGameDetailsController',
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        game: ["GameAdminModel", "$stateParams", "tournament", function (GameAdminModel, $stateParams, tournament) {
          return GameAdminModel.get({id: $stateParams.id, tournamentId: tournament.id});
        }]
      }
    });
  }

})(angular);

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

/**
 * The icon of a launchpad link.
 */
@Component({
  selector: 'coyo-launchpad-link-icon',
  templateUrl: './launchpad-link-icon.component.html',
  styleUrls: ['./launchpad-link-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadLinkIconComponent {

  /**
   * The category color.
   */
  @Input() color: string;

  /**
   * The source of the background image.
   */
  @Input() iconSrc: string | null;

  get backgroundColor(): string | null {
    return !this.iconSrc ? this.color : null;
  }

  get backgroundUrl(): SafeStyle | null {
    return this.iconSrc ? this.sanitizer.bypassSecurityTrustStyle(`url(${this.iconSrc})`) : null;
  }

  constructor(private sanitizer: DomSanitizer) {
  }
}

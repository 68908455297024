/**
 * A list of timezones supported by COYO.
 */
export const timezones: { key: string, offset: number }[] = [
  {key: 'Pacific/Pago_Pago', offset: -660},
  {key: 'Pacific/Honolulu', offset: -600},
  {key: 'America/Anchorage', offset: -540},
  {key: 'America/Los_Angeles', offset: -480},
  {key: 'America/Denver', offset: -420},
  {key: 'America/Chicago', offset: -360},
  {key: 'America/New_York', offset: -300},
  {key: 'America/Santiago', offset: -240},
  {key: 'America/Sao_Paulo', offset: -180},
  {key: 'America/Noronha', offset: -120},
  {key: 'America/Scoresbysund', offset: -60},
  {key: 'Europe/London', offset: 0},
  {key: 'Europe/Berlin', offset: 60},
  {key: 'Europe/Athens', offset: 120},
  {key: 'Europe/Moscow', offset: 180},
  {key: 'Asia/Dubai', offset: 240},
  {key: 'Asia/Karachi', offset: 300},
  {key: 'Asia/Dhaka', offset: 360},
  {key: 'Asia/Jakarta', offset: 420},
  {key: 'Asia/Singapore', offset: 480},
  {key: 'Asia/Tokyo', offset: 540},
  {key: 'Australia/Adelaide', offset: 570},
  {key: 'Australia/Sydney', offset: 600},
  {key: 'Pacific/Norfolk', offset: 660},
  {key: 'Pacific/Auckland', offset: 720}
];

(function (angular) {
  'use strict';

  BlogArticleViewController.$inject = ["$scope", "$state", "$window", "modalService", "app", "article", "articleContext", "BlogArticleModel", "sender", "currentUser"];
  angular
      .module('coyo.apps.blog')
      .controller('BlogArticleViewController', BlogArticleViewController);

  /**
   * Controller for viewing a blog article.
   *
   * @requires $scope
   * @requires $state
   * @requires $window
   * @requires modalService
   * @requires app
   * @requires article
   * @requires articleContext
   * @requires BlogArticleModel
   * @requires sender
   * @requires currentUser
   * @constructor
   */
  function BlogArticleViewController($scope, $state, $window, modalService, app, article, articleContext, BlogArticleModel,
                                     sender, currentUser) {
    var vm = this;

    vm.app = app;
    vm.article = article;
    vm.nrOfBlogArticles = articleContext.nrOfBlogArticles;
    vm.currentArticleNumber = articleContext.currentArticleNumber;
    vm.hasPreviousArticle = articleContext.previousArticleId !== null;
    vm.hasNextArticle = articleContext.nextArticleId !== null;
    vm.availableLanguages = extractAvailableLanguages();
    vm.currentUser = currentUser;

    vm.previousArticle = previousArticle;
    vm.nextArticle = nextArticle;
    vm.deleteArticle = deleteArticle;
    vm.fetchByLanguage = fetchByLanguage;
    vm.addShare = addShare;
    vm.deleteShares = deleteShares;
    vm.getLayoutLanguageKey = getLayoutLanguageKey;
    vm.print = print;

    function extractAvailableLanguages() {
      return _.concat(_.keys(_.omitBy(vm.article.translations, _.isEmpty)), vm.article.defaultLanguage);
    }

    function fetchByLanguage(language) {
      vm.article = null;
      BlogArticleModel.getWithPermissions({senderId: article.senderId, appId: article.appId, id: article.id},
          {origin: false, preferredLanguage: language}, ['edit', 'delete', 'like', 'comment', 'share'])
          .then(function (translatedArticle) {
            vm.article = translatedArticle;
          });
    }

    function previousArticle() {
      if (vm.hasPreviousArticle) {
        $state.go('^.view', {id: articleContext.previousArticleId});
      }
    }

    function nextArticle() {
      if (vm.hasNextArticle) {
        $state.go('^.view', {id: articleContext.nextArticleId});
      }
    }

    function addShare(share) {
      $scope.$apply(function () {
        var article = angular.copy(vm.article);
        if (!article.shares) {
          article.shares = [];
        }
        article.shares.push(share);
        vm.article = article;
      });
    }

    function deleteShares(deletedShares) {
      $scope.$apply(function () {
        var article = angular.copy(vm.article);
        deletedShares.forEach(function (share) {
          var idx = _.findIndex(article.shares, {id: share.id});
          if (idx > -1) {
            article.shares.splice(idx, 1);
          }
        });
        vm.article = article;
      });
    }

    function getLayoutLanguageKey(lang) {
      if (!!lang && !!sender.defaultLanguage && lang !== 'NONE' && sender.defaultLanguage !== lang) {
        return lang;
      }
      return null;
    }

    function deleteArticle() {
      var alerts = [];
      var translationContext = {title: vm.article.title};
      var shareCount = article.shares.length;

      if (shareCount > 0) {
        alerts.push({
          level: 'danger',
          title: 'APP.BLOG.ARTICLE.DELETE.WARNING.TITLE',
          text: 'APP.BLOG.ARTICLE.DELETE.MULTIPLE.SHARE.TEXT'
        });
        translationContext.shareCount = shareCount;
      }

      modalService.confirmDelete({
        title: 'APP.BLOG.MODAL.DELETE.TITLE',
        text: 'APP.BLOG.MODAL.DELETE.TEXT',
        alerts: alerts,
        translationContext: translationContext
      }).result.then(function () {
        vm.article.delete().then(function () {
          $state.go('^');
        });
      });
    }

    function print() {
      $window.print();
    }
  }

})(angular);

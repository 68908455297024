import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TimeProviderService} from '@core/time-provider/time-provider.service';
import {Skeleton} from '@coyo/ui';
import {Store} from '@ngxs/store';
import {WidgetComponent} from '@widgets/api/widget-component';
import {BirthdayWidget} from '@widgets/birthday/birthday-widget';
import {Init, LoadMore} from '@widgets/birthday/birthday-widget/birthday-widget.actions';
import {BirthdayWidgetStateModel} from '@widgets/birthday/birthday-widget/birthday-widget.state';
import {Observable} from 'rxjs';

@Component({
  selector: 'coyo-birthday-widget',
  templateUrl: './birthday-widget.component.html',
  styleUrls: ['./birthday-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BirthdayWidgetComponent extends WidgetComponent<BirthdayWidget> implements OnInit, OnChanges {
  state$: Observable<BirthdayWidgetStateModel>;
  skeletons: Skeleton[] = [
     { top: 0, left: 0, width: 120, height: 30 }, { top: 46, left: 30, width: 40, height: 40 },
     { top: 48, left: 78, width: 120, height: 18 }, { top: 70, left: 78, width: 150, height: 14 }
  ];

  private id: string;

  constructor(cd: ChangeDetectorRef, private store: Store, private timeProviderService: TimeProviderService) {
    super(cd);
  }

  ngOnInit(): void {
    this.id = this.widget.id || this.widget.tempId;
    this.state$ = this.store.select(state => state.birthdayWidget[this.id]);
    this.store.dispatch(new Init(this.widget.settings, this.id, this.editMode));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget && this.hasSettingsChanged(changes.widget.currentValue, changes.widget.previousValue)) {
      this.store.dispatch(new Init(this.widget.settings, this.id, this.editMode));
    }
  }

  private hasSettingsChanged(newValue: BirthdayWidget, oldValue: BirthdayWidget): boolean {
    if (!oldValue || !oldValue.settings) {
      return;
    }

    const oldSettings = oldValue.settings;
    const newSettings = newValue.settings;

    return oldSettings._birthdayNumber !== newSettings._birthdayNumber ||
      oldSettings._daysBeforeBirthday !== newSettings._daysBeforeBirthday ||
      oldSettings._fetchBirthdays !== newSettings._fetchBirthdays ||
      oldSettings._displayAge !== newSettings._displayAge;
  }

  getCurrentDate(): Date {
    return this.timeProviderService.getCurrentDate();
  }

  loadMore(): void {
    this.store.dispatch(new LoadMore(this.widget.settings, this.id, this.editMode));
  }

  getNeededSkeletons(state: BirthdayWidgetStateModel): number[] {
    return Array(state.page ? 1 : this.widget.settings._birthdayNumber).fill(0).map((x, i) => i);
  }
}

import {Injectable} from '@angular/core';
import {SessionStorageService} from '@core/storage/session-storage/session-storage.service';

/**
 * Service that is triggered when the heart in the about COYO modal is clicked 3 times.
 * Activated experimental features for the life span of the session.
 */
@Injectable({
  providedIn: 'root'
})
export class ExperimentalFeatureToggleService {
  readonly KEY: string = 'experimental-features';

  constructor(private sessionStorageService: SessionStorageService) {
  }

  /**
   * Gets the experimental feature activation status
   *
   * @returns true if experimental features are activated
   */
  experimentalFeaturesActive(): boolean {
    return this.sessionStorageService.getValue(this.KEY, false);
  }

  /**
   * Toggles the experimental feature activation status.
   *
   * @return true if experimental features are activated
   */
  toggleExperimentalFeatures(): boolean {
    this.sessionStorageService.setValue(this.KEY, !this.experimentalFeaturesActive());
    return this.experimentalFeaturesActive();
  }
}
